@charset "UTF-8";
/*!
 * Bootstrap  v5.3.3 (https://getbootstrap.com/)
 * Copyright 2011-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #a6b5cc;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-xs, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-xs + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23212529' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23052c65' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}
.accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")*/;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")*/;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-offcanvas-padding-y)) calc(-0.5 * var(--bs-offcanvas-padding-x)) calc(-0.5 * var(--bs-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/*!
 * Bootstrap Icons v1.11.3 (https://icons.getbootstrap.com/)
 * Copyright 2019-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/icons/blob/main/LICENSE)
 */
@font-face {
  font-display: block;
  font-family: "bootstrap-icons";
  src: url("./fonts/bootstrap-icons.woff2?24e3eb84d0bcaf83d77f904c78ac1f47") format("woff2"), url("./fonts/bootstrap-icons.woff?24e3eb84d0bcaf83d77f904c78ac1f47") format("woff");
}
.bi::before,
[class^=bi-]::before,
[class*=" bi-"]::before {
  display: inline-block;
  font-family: "bootstrap-icons" !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bi-123::before {
  content: "\f67f";
}

.bi-alarm-fill::before {
  content: "\f101";
}

.bi-alarm::before {
  content: "\f102";
}

.bi-align-bottom::before {
  content: "\f103";
}

.bi-align-center::before {
  content: "\f104";
}

.bi-align-end::before {
  content: "\f105";
}

.bi-align-middle::before {
  content: "\f106";
}

.bi-align-start::before {
  content: "\f107";
}

.bi-align-top::before {
  content: "\f108";
}

.bi-alt::before {
  content: "\f109";
}

.bi-app-indicator::before {
  content: "\f10a";
}

.bi-app::before {
  content: "\f10b";
}

.bi-archive-fill::before {
  content: "\f10c";
}

.bi-archive::before {
  content: "\f10d";
}

.bi-arrow-90deg-down::before {
  content: "\f10e";
}

.bi-arrow-90deg-left::before {
  content: "\f10f";
}

.bi-arrow-90deg-right::before {
  content: "\f110";
}

.bi-arrow-90deg-up::before {
  content: "\f111";
}

.bi-arrow-bar-down::before {
  content: "\f112";
}

.bi-arrow-bar-left::before {
  content: "\f113";
}

.bi-arrow-bar-right::before {
  content: "\f114";
}

.bi-arrow-bar-up::before {
  content: "\f115";
}

.bi-arrow-clockwise::before {
  content: "\f116";
}

.bi-arrow-counterclockwise::before {
  content: "\f117";
}

.bi-arrow-down-circle-fill::before {
  content: "\f118";
}

.bi-arrow-down-circle::before {
  content: "\f119";
}

.bi-arrow-down-left-circle-fill::before {
  content: "\f11a";
}

.bi-arrow-down-left-circle::before {
  content: "\f11b";
}

.bi-arrow-down-left-square-fill::before {
  content: "\f11c";
}

.bi-arrow-down-left-square::before {
  content: "\f11d";
}

.bi-arrow-down-left::before {
  content: "\f11e";
}

.bi-arrow-down-right-circle-fill::before {
  content: "\f11f";
}

.bi-arrow-down-right-circle::before {
  content: "\f120";
}

.bi-arrow-down-right-square-fill::before {
  content: "\f121";
}

.bi-arrow-down-right-square::before {
  content: "\f122";
}

.bi-arrow-down-right::before {
  content: "\f123";
}

.bi-arrow-down-short::before {
  content: "\f124";
}

.bi-arrow-down-square-fill::before {
  content: "\f125";
}

.bi-arrow-down-square::before {
  content: "\f126";
}

.bi-arrow-down-up::before {
  content: "\f127";
}

.bi-arrow-down::before {
  content: "\f128";
}

.bi-arrow-left-circle-fill::before {
  content: "\f129";
}

.bi-arrow-left-circle::before {
  content: "\f12a";
}

.bi-arrow-left-right::before {
  content: "\f12b";
}

.bi-arrow-left-short::before {
  content: "\f12c";
}

.bi-arrow-left-square-fill::before {
  content: "\f12d";
}

.bi-arrow-left-square::before {
  content: "\f12e";
}

.bi-arrow-left::before {
  content: "\f12f";
}

.bi-arrow-repeat::before {
  content: "\f130";
}

.bi-arrow-return-left::before {
  content: "\f131";
}

.bi-arrow-return-right::before {
  content: "\f132";
}

.bi-arrow-right-circle-fill::before {
  content: "\f133";
}

.bi-arrow-right-circle::before {
  content: "\f134";
}

.bi-arrow-right-short::before {
  content: "\f135";
}

.bi-arrow-right-square-fill::before {
  content: "\f136";
}

.bi-arrow-right-square::before {
  content: "\f137";
}

.bi-arrow-right::before {
  content: "\f138";
}

.bi-arrow-up-circle-fill::before {
  content: "\f139";
}

.bi-arrow-up-circle::before {
  content: "\f13a";
}

.bi-arrow-up-left-circle-fill::before {
  content: "\f13b";
}

.bi-arrow-up-left-circle::before {
  content: "\f13c";
}

.bi-arrow-up-left-square-fill::before {
  content: "\f13d";
}

.bi-arrow-up-left-square::before {
  content: "\f13e";
}

.bi-arrow-up-left::before {
  content: "\f13f";
}

.bi-arrow-up-right-circle-fill::before {
  content: "\f140";
}

.bi-arrow-up-right-circle::before {
  content: "\f141";
}

.bi-arrow-up-right-square-fill::before {
  content: "\f142";
}

.bi-arrow-up-right-square::before {
  content: "\f143";
}

.bi-arrow-up-right::before {
  content: "\f144";
}

.bi-arrow-up-short::before {
  content: "\f145";
}

.bi-arrow-up-square-fill::before {
  content: "\f146";
}

.bi-arrow-up-square::before {
  content: "\f147";
}

.bi-arrow-up::before {
  content: "\f148";
}

.bi-arrows-angle-contract::before {
  content: "\f149";
}

.bi-arrows-angle-expand::before {
  content: "\f14a";
}

.bi-arrows-collapse::before {
  content: "\f14b";
}

.bi-arrows-expand::before {
  content: "\f14c";
}

.bi-arrows-fullscreen::before {
  content: "\f14d";
}

.bi-arrows-move::before {
  content: "\f14e";
}

.bi-aspect-ratio-fill::before {
  content: "\f14f";
}

.bi-aspect-ratio::before {
  content: "\f150";
}

.bi-asterisk::before {
  content: "\f151";
}

.bi-at::before {
  content: "\f152";
}

.bi-award-fill::before {
  content: "\f153";
}

.bi-award::before {
  content: "\f154";
}

.bi-back::before {
  content: "\f155";
}

.bi-backspace-fill::before {
  content: "\f156";
}

.bi-backspace-reverse-fill::before {
  content: "\f157";
}

.bi-backspace-reverse::before {
  content: "\f158";
}

.bi-backspace::before {
  content: "\f159";
}

.bi-badge-3d-fill::before {
  content: "\f15a";
}

.bi-badge-3d::before {
  content: "\f15b";
}

.bi-badge-4k-fill::before {
  content: "\f15c";
}

.bi-badge-4k::before {
  content: "\f15d";
}

.bi-badge-8k-fill::before {
  content: "\f15e";
}

.bi-badge-8k::before {
  content: "\f15f";
}

.bi-badge-ad-fill::before {
  content: "\f160";
}

.bi-badge-ad::before {
  content: "\f161";
}

.bi-badge-ar-fill::before {
  content: "\f162";
}

.bi-badge-ar::before {
  content: "\f163";
}

.bi-badge-cc-fill::before {
  content: "\f164";
}

.bi-badge-cc::before {
  content: "\f165";
}

.bi-badge-hd-fill::before {
  content: "\f166";
}

.bi-badge-hd::before {
  content: "\f167";
}

.bi-badge-tm-fill::before {
  content: "\f168";
}

.bi-badge-tm::before {
  content: "\f169";
}

.bi-badge-vo-fill::before {
  content: "\f16a";
}

.bi-badge-vo::before {
  content: "\f16b";
}

.bi-badge-vr-fill::before {
  content: "\f16c";
}

.bi-badge-vr::before {
  content: "\f16d";
}

.bi-badge-wc-fill::before {
  content: "\f16e";
}

.bi-badge-wc::before {
  content: "\f16f";
}

.bi-bag-check-fill::before {
  content: "\f170";
}

.bi-bag-check::before {
  content: "\f171";
}

.bi-bag-dash-fill::before {
  content: "\f172";
}

.bi-bag-dash::before {
  content: "\f173";
}

.bi-bag-fill::before {
  content: "\f174";
}

.bi-bag-plus-fill::before {
  content: "\f175";
}

.bi-bag-plus::before {
  content: "\f176";
}

.bi-bag-x-fill::before {
  content: "\f177";
}

.bi-bag-x::before {
  content: "\f178";
}

.bi-bag::before {
  content: "\f179";
}

.bi-bar-chart-fill::before {
  content: "\f17a";
}

.bi-bar-chart-line-fill::before {
  content: "\f17b";
}

.bi-bar-chart-line::before {
  content: "\f17c";
}

.bi-bar-chart-steps::before {
  content: "\f17d";
}

.bi-bar-chart::before {
  content: "\f17e";
}

.bi-basket-fill::before {
  content: "\f17f";
}

.bi-basket::before {
  content: "\f180";
}

.bi-basket2-fill::before {
  content: "\f181";
}

.bi-basket2::before {
  content: "\f182";
}

.bi-basket3-fill::before {
  content: "\f183";
}

.bi-basket3::before {
  content: "\f184";
}

.bi-battery-charging::before {
  content: "\f185";
}

.bi-battery-full::before {
  content: "\f186";
}

.bi-battery-half::before {
  content: "\f187";
}

.bi-battery::before {
  content: "\f188";
}

.bi-bell-fill::before {
  content: "\f189";
}

.bi-bell::before {
  content: "\f18a";
}

.bi-bezier::before {
  content: "\f18b";
}

.bi-bezier2::before {
  content: "\f18c";
}

.bi-bicycle::before {
  content: "\f18d";
}

.bi-binoculars-fill::before {
  content: "\f18e";
}

.bi-binoculars::before {
  content: "\f18f";
}

.bi-blockquote-left::before {
  content: "\f190";
}

.bi-blockquote-right::before {
  content: "\f191";
}

.bi-book-fill::before {
  content: "\f192";
}

.bi-book-half::before {
  content: "\f193";
}

.bi-book::before {
  content: "\f194";
}

.bi-bookmark-check-fill::before {
  content: "\f195";
}

.bi-bookmark-check::before {
  content: "\f196";
}

.bi-bookmark-dash-fill::before {
  content: "\f197";
}

.bi-bookmark-dash::before {
  content: "\f198";
}

.bi-bookmark-fill::before {
  content: "\f199";
}

.bi-bookmark-heart-fill::before {
  content: "\f19a";
}

.bi-bookmark-heart::before {
  content: "\f19b";
}

.bi-bookmark-plus-fill::before {
  content: "\f19c";
}

.bi-bookmark-plus::before {
  content: "\f19d";
}

.bi-bookmark-star-fill::before {
  content: "\f19e";
}

.bi-bookmark-star::before {
  content: "\f19f";
}

.bi-bookmark-x-fill::before {
  content: "\f1a0";
}

.bi-bookmark-x::before {
  content: "\f1a1";
}

.bi-bookmark::before {
  content: "\f1a2";
}

.bi-bookmarks-fill::before {
  content: "\f1a3";
}

.bi-bookmarks::before {
  content: "\f1a4";
}

.bi-bookshelf::before {
  content: "\f1a5";
}

.bi-bootstrap-fill::before {
  content: "\f1a6";
}

.bi-bootstrap-reboot::before {
  content: "\f1a7";
}

.bi-bootstrap::before {
  content: "\f1a8";
}

.bi-border-all::before {
  content: "\f1a9";
}

.bi-border-bottom::before {
  content: "\f1aa";
}

.bi-border-center::before {
  content: "\f1ab";
}

.bi-border-inner::before {
  content: "\f1ac";
}

.bi-border-left::before {
  content: "\f1ad";
}

.bi-border-middle::before {
  content: "\f1ae";
}

.bi-border-outer::before {
  content: "\f1af";
}

.bi-border-right::before {
  content: "\f1b0";
}

.bi-border-style::before {
  content: "\f1b1";
}

.bi-border-top::before {
  content: "\f1b2";
}

.bi-border-width::before {
  content: "\f1b3";
}

.bi-border::before {
  content: "\f1b4";
}

.bi-bounding-box-circles::before {
  content: "\f1b5";
}

.bi-bounding-box::before {
  content: "\f1b6";
}

.bi-box-arrow-down-left::before {
  content: "\f1b7";
}

.bi-box-arrow-down-right::before {
  content: "\f1b8";
}

.bi-box-arrow-down::before {
  content: "\f1b9";
}

.bi-box-arrow-in-down-left::before {
  content: "\f1ba";
}

.bi-box-arrow-in-down-right::before {
  content: "\f1bb";
}

.bi-box-arrow-in-down::before {
  content: "\f1bc";
}

.bi-box-arrow-in-left::before {
  content: "\f1bd";
}

.bi-box-arrow-in-right::before {
  content: "\f1be";
}

.bi-box-arrow-in-up-left::before {
  content: "\f1bf";
}

.bi-box-arrow-in-up-right::before {
  content: "\f1c0";
}

.bi-box-arrow-in-up::before {
  content: "\f1c1";
}

.bi-box-arrow-left::before {
  content: "\f1c2";
}

.bi-box-arrow-right::before {
  content: "\f1c3";
}

.bi-box-arrow-up-left::before {
  content: "\f1c4";
}

.bi-box-arrow-up-right::before {
  content: "\f1c5";
}

.bi-box-arrow-up::before {
  content: "\f1c6";
}

.bi-box-seam::before {
  content: "\f1c7";
}

.bi-box::before {
  content: "\f1c8";
}

.bi-braces::before {
  content: "\f1c9";
}

.bi-bricks::before {
  content: "\f1ca";
}

.bi-briefcase-fill::before {
  content: "\f1cb";
}

.bi-briefcase::before {
  content: "\f1cc";
}

.bi-brightness-alt-high-fill::before {
  content: "\f1cd";
}

.bi-brightness-alt-high::before {
  content: "\f1ce";
}

.bi-brightness-alt-low-fill::before {
  content: "\f1cf";
}

.bi-brightness-alt-low::before {
  content: "\f1d0";
}

.bi-brightness-high-fill::before {
  content: "\f1d1";
}

.bi-brightness-high::before {
  content: "\f1d2";
}

.bi-brightness-low-fill::before {
  content: "\f1d3";
}

.bi-brightness-low::before {
  content: "\f1d4";
}

.bi-broadcast-pin::before {
  content: "\f1d5";
}

.bi-broadcast::before {
  content: "\f1d6";
}

.bi-brush-fill::before {
  content: "\f1d7";
}

.bi-brush::before {
  content: "\f1d8";
}

.bi-bucket-fill::before {
  content: "\f1d9";
}

.bi-bucket::before {
  content: "\f1da";
}

.bi-bug-fill::before {
  content: "\f1db";
}

.bi-bug::before {
  content: "\f1dc";
}

.bi-building::before {
  content: "\f1dd";
}

.bi-bullseye::before {
  content: "\f1de";
}

.bi-calculator-fill::before {
  content: "\f1df";
}

.bi-calculator::before {
  content: "\f1e0";
}

.bi-calendar-check-fill::before {
  content: "\f1e1";
}

.bi-calendar-check::before {
  content: "\f1e2";
}

.bi-calendar-date-fill::before {
  content: "\f1e3";
}

.bi-calendar-date::before {
  content: "\f1e4";
}

.bi-calendar-day-fill::before {
  content: "\f1e5";
}

.bi-calendar-day::before {
  content: "\f1e6";
}

.bi-calendar-event-fill::before {
  content: "\f1e7";
}

.bi-calendar-event::before {
  content: "\f1e8";
}

.bi-calendar-fill::before {
  content: "\f1e9";
}

.bi-calendar-minus-fill::before {
  content: "\f1ea";
}

.bi-calendar-minus::before {
  content: "\f1eb";
}

.bi-calendar-month-fill::before {
  content: "\f1ec";
}

.bi-calendar-month::before {
  content: "\f1ed";
}

.bi-calendar-plus-fill::before {
  content: "\f1ee";
}

.bi-calendar-plus::before {
  content: "\f1ef";
}

.bi-calendar-range-fill::before {
  content: "\f1f0";
}

.bi-calendar-range::before {
  content: "\f1f1";
}

.bi-calendar-week-fill::before {
  content: "\f1f2";
}

.bi-calendar-week::before {
  content: "\f1f3";
}

.bi-calendar-x-fill::before {
  content: "\f1f4";
}

.bi-calendar-x::before {
  content: "\f1f5";
}

.bi-calendar::before {
  content: "\f1f6";
}

.bi-calendar2-check-fill::before {
  content: "\f1f7";
}

.bi-calendar2-check::before {
  content: "\f1f8";
}

.bi-calendar2-date-fill::before {
  content: "\f1f9";
}

.bi-calendar2-date::before {
  content: "\f1fa";
}

.bi-calendar2-day-fill::before {
  content: "\f1fb";
}

.bi-calendar2-day::before {
  content: "\f1fc";
}

.bi-calendar2-event-fill::before {
  content: "\f1fd";
}

.bi-calendar2-event::before {
  content: "\f1fe";
}

.bi-calendar2-fill::before {
  content: "\f1ff";
}

.bi-calendar2-minus-fill::before {
  content: "\f200";
}

.bi-calendar2-minus::before {
  content: "\f201";
}

.bi-calendar2-month-fill::before {
  content: "\f202";
}

.bi-calendar2-month::before {
  content: "\f203";
}

.bi-calendar2-plus-fill::before {
  content: "\f204";
}

.bi-calendar2-plus::before {
  content: "\f205";
}

.bi-calendar2-range-fill::before {
  content: "\f206";
}

.bi-calendar2-range::before {
  content: "\f207";
}

.bi-calendar2-week-fill::before {
  content: "\f208";
}

.bi-calendar2-week::before {
  content: "\f209";
}

.bi-calendar2-x-fill::before {
  content: "\f20a";
}

.bi-calendar2-x::before {
  content: "\f20b";
}

.bi-calendar2::before {
  content: "\f20c";
}

.bi-calendar3-event-fill::before {
  content: "\f20d";
}

.bi-calendar3-event::before {
  content: "\f20e";
}

.bi-calendar3-fill::before {
  content: "\f20f";
}

.bi-calendar3-range-fill::before {
  content: "\f210";
}

.bi-calendar3-range::before {
  content: "\f211";
}

.bi-calendar3-week-fill::before {
  content: "\f212";
}

.bi-calendar3-week::before {
  content: "\f213";
}

.bi-calendar3::before {
  content: "\f214";
}

.bi-calendar4-event::before {
  content: "\f215";
}

.bi-calendar4-range::before {
  content: "\f216";
}

.bi-calendar4-week::before {
  content: "\f217";
}

.bi-calendar4::before {
  content: "\f218";
}

.bi-camera-fill::before {
  content: "\f219";
}

.bi-camera-reels-fill::before {
  content: "\f21a";
}

.bi-camera-reels::before {
  content: "\f21b";
}

.bi-camera-video-fill::before {
  content: "\f21c";
}

.bi-camera-video-off-fill::before {
  content: "\f21d";
}

.bi-camera-video-off::before {
  content: "\f21e";
}

.bi-camera-video::before {
  content: "\f21f";
}

.bi-camera::before {
  content: "\f220";
}

.bi-camera2::before {
  content: "\f221";
}

.bi-capslock-fill::before {
  content: "\f222";
}

.bi-capslock::before {
  content: "\f223";
}

.bi-card-checklist::before {
  content: "\f224";
}

.bi-card-heading::before {
  content: "\f225";
}

.bi-card-image::before {
  content: "\f226";
}

.bi-card-list::before {
  content: "\f227";
}

.bi-card-text::before {
  content: "\f228";
}

.bi-caret-down-fill::before {
  content: "\f229";
}

.bi-caret-down-square-fill::before {
  content: "\f22a";
}

.bi-caret-down-square::before {
  content: "\f22b";
}

.bi-caret-down::before {
  content: "\f22c";
}

.bi-caret-left-fill::before {
  content: "\f22d";
}

.bi-caret-left-square-fill::before {
  content: "\f22e";
}

.bi-caret-left-square::before {
  content: "\f22f";
}

.bi-caret-left::before {
  content: "\f230";
}

.bi-caret-right-fill::before {
  content: "\f231";
}

.bi-caret-right-square-fill::before {
  content: "\f232";
}

.bi-caret-right-square::before {
  content: "\f233";
}

.bi-caret-right::before {
  content: "\f234";
}

.bi-caret-up-fill::before {
  content: "\f235";
}

.bi-caret-up-square-fill::before {
  content: "\f236";
}

.bi-caret-up-square::before {
  content: "\f237";
}

.bi-caret-up::before {
  content: "\f238";
}

.bi-cart-check-fill::before {
  content: "\f239";
}

.bi-cart-check::before {
  content: "\f23a";
}

.bi-cart-dash-fill::before {
  content: "\f23b";
}

.bi-cart-dash::before {
  content: "\f23c";
}

.bi-cart-fill::before {
  content: "\f23d";
}

.bi-cart-plus-fill::before {
  content: "\f23e";
}

.bi-cart-plus::before {
  content: "\f23f";
}

.bi-cart-x-fill::before {
  content: "\f240";
}

.bi-cart-x::before {
  content: "\f241";
}

.bi-cart::before {
  content: "\f242";
}

.bi-cart2::before {
  content: "\f243";
}

.bi-cart3::before {
  content: "\f244";
}

.bi-cart4::before {
  content: "\f245";
}

.bi-cash-stack::before {
  content: "\f246";
}

.bi-cash::before {
  content: "\f247";
}

.bi-cast::before {
  content: "\f248";
}

.bi-chat-dots-fill::before {
  content: "\f249";
}

.bi-chat-dots::before {
  content: "\f24a";
}

.bi-chat-fill::before {
  content: "\f24b";
}

.bi-chat-left-dots-fill::before {
  content: "\f24c";
}

.bi-chat-left-dots::before {
  content: "\f24d";
}

.bi-chat-left-fill::before {
  content: "\f24e";
}

.bi-chat-left-quote-fill::before {
  content: "\f24f";
}

.bi-chat-left-quote::before {
  content: "\f250";
}

.bi-chat-left-text-fill::before {
  content: "\f251";
}

.bi-chat-left-text::before {
  content: "\f252";
}

.bi-chat-left::before {
  content: "\f253";
}

.bi-chat-quote-fill::before {
  content: "\f254";
}

.bi-chat-quote::before {
  content: "\f255";
}

.bi-chat-right-dots-fill::before {
  content: "\f256";
}

.bi-chat-right-dots::before {
  content: "\f257";
}

.bi-chat-right-fill::before {
  content: "\f258";
}

.bi-chat-right-quote-fill::before {
  content: "\f259";
}

.bi-chat-right-quote::before {
  content: "\f25a";
}

.bi-chat-right-text-fill::before {
  content: "\f25b";
}

.bi-chat-right-text::before {
  content: "\f25c";
}

.bi-chat-right::before {
  content: "\f25d";
}

.bi-chat-square-dots-fill::before {
  content: "\f25e";
}

.bi-chat-square-dots::before {
  content: "\f25f";
}

.bi-chat-square-fill::before {
  content: "\f260";
}

.bi-chat-square-quote-fill::before {
  content: "\f261";
}

.bi-chat-square-quote::before {
  content: "\f262";
}

.bi-chat-square-text-fill::before {
  content: "\f263";
}

.bi-chat-square-text::before {
  content: "\f264";
}

.bi-chat-square::before {
  content: "\f265";
}

.bi-chat-text-fill::before {
  content: "\f266";
}

.bi-chat-text::before {
  content: "\f267";
}

.bi-chat::before {
  content: "\f268";
}

.bi-check-all::before {
  content: "\f269";
}

.bi-check-circle-fill::before {
  content: "\f26a";
}

.bi-check-circle::before {
  content: "\f26b";
}

.bi-check-square-fill::before {
  content: "\f26c";
}

.bi-check-square::before {
  content: "\f26d";
}

.bi-check::before {
  content: "\f26e";
}

.bi-check2-all::before {
  content: "\f26f";
}

.bi-check2-circle::before {
  content: "\f270";
}

.bi-check2-square::before {
  content: "\f271";
}

.bi-check2::before {
  content: "\f272";
}

.bi-chevron-bar-contract::before {
  content: "\f273";
}

.bi-chevron-bar-down::before {
  content: "\f274";
}

.bi-chevron-bar-expand::before {
  content: "\f275";
}

.bi-chevron-bar-left::before {
  content: "\f276";
}

.bi-chevron-bar-right::before {
  content: "\f277";
}

.bi-chevron-bar-up::before {
  content: "\f278";
}

.bi-chevron-compact-down::before {
  content: "\f279";
}

.bi-chevron-compact-left::before {
  content: "\f27a";
}

.bi-chevron-compact-right::before {
  content: "\f27b";
}

.bi-chevron-compact-up::before {
  content: "\f27c";
}

.bi-chevron-contract::before {
  content: "\f27d";
}

.bi-chevron-double-down::before {
  content: "\f27e";
}

.bi-chevron-double-left::before {
  content: "\f27f";
}

.bi-chevron-double-right::before {
  content: "\f280";
}

.bi-chevron-double-up::before {
  content: "\f281";
}

.bi-chevron-down::before {
  content: "\f282";
}

.bi-chevron-expand::before {
  content: "\f283";
}

.bi-chevron-left::before {
  content: "\f284";
}

.bi-chevron-right::before {
  content: "\f285";
}

.bi-chevron-up::before {
  content: "\f286";
}

.bi-circle-fill::before {
  content: "\f287";
}

.bi-circle-half::before {
  content: "\f288";
}

.bi-circle-square::before {
  content: "\f289";
}

.bi-circle::before {
  content: "\f28a";
}

.bi-clipboard-check::before {
  content: "\f28b";
}

.bi-clipboard-data::before {
  content: "\f28c";
}

.bi-clipboard-minus::before {
  content: "\f28d";
}

.bi-clipboard-plus::before {
  content: "\f28e";
}

.bi-clipboard-x::before {
  content: "\f28f";
}

.bi-clipboard::before {
  content: "\f290";
}

.bi-clock-fill::before {
  content: "\f291";
}

.bi-clock-history::before {
  content: "\f292";
}

.bi-clock::before {
  content: "\f293";
}

.bi-cloud-arrow-down-fill::before {
  content: "\f294";
}

.bi-cloud-arrow-down::before {
  content: "\f295";
}

.bi-cloud-arrow-up-fill::before {
  content: "\f296";
}

.bi-cloud-arrow-up::before {
  content: "\f297";
}

.bi-cloud-check-fill::before {
  content: "\f298";
}

.bi-cloud-check::before {
  content: "\f299";
}

.bi-cloud-download-fill::before {
  content: "\f29a";
}

.bi-cloud-download::before {
  content: "\f29b";
}

.bi-cloud-drizzle-fill::before {
  content: "\f29c";
}

.bi-cloud-drizzle::before {
  content: "\f29d";
}

.bi-cloud-fill::before {
  content: "\f29e";
}

.bi-cloud-fog-fill::before {
  content: "\f29f";
}

.bi-cloud-fog::before {
  content: "\f2a0";
}

.bi-cloud-fog2-fill::before {
  content: "\f2a1";
}

.bi-cloud-fog2::before {
  content: "\f2a2";
}

.bi-cloud-hail-fill::before {
  content: "\f2a3";
}

.bi-cloud-hail::before {
  content: "\f2a4";
}

.bi-cloud-haze-fill::before {
  content: "\f2a6";
}

.bi-cloud-haze::before {
  content: "\f2a7";
}

.bi-cloud-haze2-fill::before {
  content: "\f2a8";
}

.bi-cloud-lightning-fill::before {
  content: "\f2a9";
}

.bi-cloud-lightning-rain-fill::before {
  content: "\f2aa";
}

.bi-cloud-lightning-rain::before {
  content: "\f2ab";
}

.bi-cloud-lightning::before {
  content: "\f2ac";
}

.bi-cloud-minus-fill::before {
  content: "\f2ad";
}

.bi-cloud-minus::before {
  content: "\f2ae";
}

.bi-cloud-moon-fill::before {
  content: "\f2af";
}

.bi-cloud-moon::before {
  content: "\f2b0";
}

.bi-cloud-plus-fill::before {
  content: "\f2b1";
}

.bi-cloud-plus::before {
  content: "\f2b2";
}

.bi-cloud-rain-fill::before {
  content: "\f2b3";
}

.bi-cloud-rain-heavy-fill::before {
  content: "\f2b4";
}

.bi-cloud-rain-heavy::before {
  content: "\f2b5";
}

.bi-cloud-rain::before {
  content: "\f2b6";
}

.bi-cloud-slash-fill::before {
  content: "\f2b7";
}

.bi-cloud-slash::before {
  content: "\f2b8";
}

.bi-cloud-sleet-fill::before {
  content: "\f2b9";
}

.bi-cloud-sleet::before {
  content: "\f2ba";
}

.bi-cloud-snow-fill::before {
  content: "\f2bb";
}

.bi-cloud-snow::before {
  content: "\f2bc";
}

.bi-cloud-sun-fill::before {
  content: "\f2bd";
}

.bi-cloud-sun::before {
  content: "\f2be";
}

.bi-cloud-upload-fill::before {
  content: "\f2bf";
}

.bi-cloud-upload::before {
  content: "\f2c0";
}

.bi-cloud::before {
  content: "\f2c1";
}

.bi-clouds-fill::before {
  content: "\f2c2";
}

.bi-clouds::before {
  content: "\f2c3";
}

.bi-cloudy-fill::before {
  content: "\f2c4";
}

.bi-cloudy::before {
  content: "\f2c5";
}

.bi-code-slash::before {
  content: "\f2c6";
}

.bi-code-square::before {
  content: "\f2c7";
}

.bi-code::before {
  content: "\f2c8";
}

.bi-collection-fill::before {
  content: "\f2c9";
}

.bi-collection-play-fill::before {
  content: "\f2ca";
}

.bi-collection-play::before {
  content: "\f2cb";
}

.bi-collection::before {
  content: "\f2cc";
}

.bi-columns-gap::before {
  content: "\f2cd";
}

.bi-columns::before {
  content: "\f2ce";
}

.bi-command::before {
  content: "\f2cf";
}

.bi-compass-fill::before {
  content: "\f2d0";
}

.bi-compass::before {
  content: "\f2d1";
}

.bi-cone-striped::before {
  content: "\f2d2";
}

.bi-cone::before {
  content: "\f2d3";
}

.bi-controller::before {
  content: "\f2d4";
}

.bi-cpu-fill::before {
  content: "\f2d5";
}

.bi-cpu::before {
  content: "\f2d6";
}

.bi-credit-card-2-back-fill::before {
  content: "\f2d7";
}

.bi-credit-card-2-back::before {
  content: "\f2d8";
}

.bi-credit-card-2-front-fill::before {
  content: "\f2d9";
}

.bi-credit-card-2-front::before {
  content: "\f2da";
}

.bi-credit-card-fill::before {
  content: "\f2db";
}

.bi-credit-card::before {
  content: "\f2dc";
}

.bi-crop::before {
  content: "\f2dd";
}

.bi-cup-fill::before {
  content: "\f2de";
}

.bi-cup-straw::before {
  content: "\f2df";
}

.bi-cup::before {
  content: "\f2e0";
}

.bi-cursor-fill::before {
  content: "\f2e1";
}

.bi-cursor-text::before {
  content: "\f2e2";
}

.bi-cursor::before {
  content: "\f2e3";
}

.bi-dash-circle-dotted::before {
  content: "\f2e4";
}

.bi-dash-circle-fill::before {
  content: "\f2e5";
}

.bi-dash-circle::before {
  content: "\f2e6";
}

.bi-dash-square-dotted::before {
  content: "\f2e7";
}

.bi-dash-square-fill::before {
  content: "\f2e8";
}

.bi-dash-square::before {
  content: "\f2e9";
}

.bi-dash::before {
  content: "\f2ea";
}

.bi-diagram-2-fill::before {
  content: "\f2eb";
}

.bi-diagram-2::before {
  content: "\f2ec";
}

.bi-diagram-3-fill::before {
  content: "\f2ed";
}

.bi-diagram-3::before {
  content: "\f2ee";
}

.bi-diamond-fill::before {
  content: "\f2ef";
}

.bi-diamond-half::before {
  content: "\f2f0";
}

.bi-diamond::before {
  content: "\f2f1";
}

.bi-dice-1-fill::before {
  content: "\f2f2";
}

.bi-dice-1::before {
  content: "\f2f3";
}

.bi-dice-2-fill::before {
  content: "\f2f4";
}

.bi-dice-2::before {
  content: "\f2f5";
}

.bi-dice-3-fill::before {
  content: "\f2f6";
}

.bi-dice-3::before {
  content: "\f2f7";
}

.bi-dice-4-fill::before {
  content: "\f2f8";
}

.bi-dice-4::before {
  content: "\f2f9";
}

.bi-dice-5-fill::before {
  content: "\f2fa";
}

.bi-dice-5::before {
  content: "\f2fb";
}

.bi-dice-6-fill::before {
  content: "\f2fc";
}

.bi-dice-6::before {
  content: "\f2fd";
}

.bi-disc-fill::before {
  content: "\f2fe";
}

.bi-disc::before {
  content: "\f2ff";
}

.bi-discord::before {
  content: "\f300";
}

.bi-display-fill::before {
  content: "\f301";
}

.bi-display::before {
  content: "\f302";
}

.bi-distribute-horizontal::before {
  content: "\f303";
}

.bi-distribute-vertical::before {
  content: "\f304";
}

.bi-door-closed-fill::before {
  content: "\f305";
}

.bi-door-closed::before {
  content: "\f306";
}

.bi-door-open-fill::before {
  content: "\f307";
}

.bi-door-open::before {
  content: "\f308";
}

.bi-dot::before {
  content: "\f309";
}

.bi-download::before {
  content: "\f30a";
}

.bi-droplet-fill::before {
  content: "\f30b";
}

.bi-droplet-half::before {
  content: "\f30c";
}

.bi-droplet::before {
  content: "\f30d";
}

.bi-earbuds::before {
  content: "\f30e";
}

.bi-easel-fill::before {
  content: "\f30f";
}

.bi-easel::before {
  content: "\f310";
}

.bi-egg-fill::before {
  content: "\f311";
}

.bi-egg-fried::before {
  content: "\f312";
}

.bi-egg::before {
  content: "\f313";
}

.bi-eject-fill::before {
  content: "\f314";
}

.bi-eject::before {
  content: "\f315";
}

.bi-emoji-angry-fill::before {
  content: "\f316";
}

.bi-emoji-angry::before {
  content: "\f317";
}

.bi-emoji-dizzy-fill::before {
  content: "\f318";
}

.bi-emoji-dizzy::before {
  content: "\f319";
}

.bi-emoji-expressionless-fill::before {
  content: "\f31a";
}

.bi-emoji-expressionless::before {
  content: "\f31b";
}

.bi-emoji-frown-fill::before {
  content: "\f31c";
}

.bi-emoji-frown::before {
  content: "\f31d";
}

.bi-emoji-heart-eyes-fill::before {
  content: "\f31e";
}

.bi-emoji-heart-eyes::before {
  content: "\f31f";
}

.bi-emoji-laughing-fill::before {
  content: "\f320";
}

.bi-emoji-laughing::before {
  content: "\f321";
}

.bi-emoji-neutral-fill::before {
  content: "\f322";
}

.bi-emoji-neutral::before {
  content: "\f323";
}

.bi-emoji-smile-fill::before {
  content: "\f324";
}

.bi-emoji-smile-upside-down-fill::before {
  content: "\f325";
}

.bi-emoji-smile-upside-down::before {
  content: "\f326";
}

.bi-emoji-smile::before {
  content: "\f327";
}

.bi-emoji-sunglasses-fill::before {
  content: "\f328";
}

.bi-emoji-sunglasses::before {
  content: "\f329";
}

.bi-emoji-wink-fill::before {
  content: "\f32a";
}

.bi-emoji-wink::before {
  content: "\f32b";
}

.bi-envelope-fill::before {
  content: "\f32c";
}

.bi-envelope-open-fill::before {
  content: "\f32d";
}

.bi-envelope-open::before {
  content: "\f32e";
}

.bi-envelope::before {
  content: "\f32f";
}

.bi-eraser-fill::before {
  content: "\f330";
}

.bi-eraser::before {
  content: "\f331";
}

.bi-exclamation-circle-fill::before {
  content: "\f332";
}

.bi-exclamation-circle::before {
  content: "\f333";
}

.bi-exclamation-diamond-fill::before {
  content: "\f334";
}

.bi-exclamation-diamond::before {
  content: "\f335";
}

.bi-exclamation-octagon-fill::before {
  content: "\f336";
}

.bi-exclamation-octagon::before {
  content: "\f337";
}

.bi-exclamation-square-fill::before {
  content: "\f338";
}

.bi-exclamation-square::before {
  content: "\f339";
}

.bi-exclamation-triangle-fill::before {
  content: "\f33a";
}

.bi-exclamation-triangle::before {
  content: "\f33b";
}

.bi-exclamation::before {
  content: "\f33c";
}

.bi-exclude::before {
  content: "\f33d";
}

.bi-eye-fill::before {
  content: "\f33e";
}

.bi-eye-slash-fill::before {
  content: "\f33f";
}

.bi-eye-slash::before {
  content: "\f340";
}

.bi-eye::before {
  content: "\f341";
}

.bi-eyedropper::before {
  content: "\f342";
}

.bi-eyeglasses::before {
  content: "\f343";
}

.bi-facebook::before {
  content: "\f344";
}

.bi-file-arrow-down-fill::before {
  content: "\f345";
}

.bi-file-arrow-down::before {
  content: "\f346";
}

.bi-file-arrow-up-fill::before {
  content: "\f347";
}

.bi-file-arrow-up::before {
  content: "\f348";
}

.bi-file-bar-graph-fill::before {
  content: "\f349";
}

.bi-file-bar-graph::before {
  content: "\f34a";
}

.bi-file-binary-fill::before {
  content: "\f34b";
}

.bi-file-binary::before {
  content: "\f34c";
}

.bi-file-break-fill::before {
  content: "\f34d";
}

.bi-file-break::before {
  content: "\f34e";
}

.bi-file-check-fill::before {
  content: "\f34f";
}

.bi-file-check::before {
  content: "\f350";
}

.bi-file-code-fill::before {
  content: "\f351";
}

.bi-file-code::before {
  content: "\f352";
}

.bi-file-diff-fill::before {
  content: "\f353";
}

.bi-file-diff::before {
  content: "\f354";
}

.bi-file-earmark-arrow-down-fill::before {
  content: "\f355";
}

.bi-file-earmark-arrow-down::before {
  content: "\f356";
}

.bi-file-earmark-arrow-up-fill::before {
  content: "\f357";
}

.bi-file-earmark-arrow-up::before {
  content: "\f358";
}

.bi-file-earmark-bar-graph-fill::before {
  content: "\f359";
}

.bi-file-earmark-bar-graph::before {
  content: "\f35a";
}

.bi-file-earmark-binary-fill::before {
  content: "\f35b";
}

.bi-file-earmark-binary::before {
  content: "\f35c";
}

.bi-file-earmark-break-fill::before {
  content: "\f35d";
}

.bi-file-earmark-break::before {
  content: "\f35e";
}

.bi-file-earmark-check-fill::before {
  content: "\f35f";
}

.bi-file-earmark-check::before {
  content: "\f360";
}

.bi-file-earmark-code-fill::before {
  content: "\f361";
}

.bi-file-earmark-code::before {
  content: "\f362";
}

.bi-file-earmark-diff-fill::before {
  content: "\f363";
}

.bi-file-earmark-diff::before {
  content: "\f364";
}

.bi-file-earmark-easel-fill::before {
  content: "\f365";
}

.bi-file-earmark-easel::before {
  content: "\f366";
}

.bi-file-earmark-excel-fill::before {
  content: "\f367";
}

.bi-file-earmark-excel::before {
  content: "\f368";
}

.bi-file-earmark-fill::before {
  content: "\f369";
}

.bi-file-earmark-font-fill::before {
  content: "\f36a";
}

.bi-file-earmark-font::before {
  content: "\f36b";
}

.bi-file-earmark-image-fill::before {
  content: "\f36c";
}

.bi-file-earmark-image::before {
  content: "\f36d";
}

.bi-file-earmark-lock-fill::before {
  content: "\f36e";
}

.bi-file-earmark-lock::before {
  content: "\f36f";
}

.bi-file-earmark-lock2-fill::before {
  content: "\f370";
}

.bi-file-earmark-lock2::before {
  content: "\f371";
}

.bi-file-earmark-medical-fill::before {
  content: "\f372";
}

.bi-file-earmark-medical::before {
  content: "\f373";
}

.bi-file-earmark-minus-fill::before {
  content: "\f374";
}

.bi-file-earmark-minus::before {
  content: "\f375";
}

.bi-file-earmark-music-fill::before {
  content: "\f376";
}

.bi-file-earmark-music::before {
  content: "\f377";
}

.bi-file-earmark-person-fill::before {
  content: "\f378";
}

.bi-file-earmark-person::before {
  content: "\f379";
}

.bi-file-earmark-play-fill::before {
  content: "\f37a";
}

.bi-file-earmark-play::before {
  content: "\f37b";
}

.bi-file-earmark-plus-fill::before {
  content: "\f37c";
}

.bi-file-earmark-plus::before {
  content: "\f37d";
}

.bi-file-earmark-post-fill::before {
  content: "\f37e";
}

.bi-file-earmark-post::before {
  content: "\f37f";
}

.bi-file-earmark-ppt-fill::before {
  content: "\f380";
}

.bi-file-earmark-ppt::before {
  content: "\f381";
}

.bi-file-earmark-richtext-fill::before {
  content: "\f382";
}

.bi-file-earmark-richtext::before {
  content: "\f383";
}

.bi-file-earmark-ruled-fill::before {
  content: "\f384";
}

.bi-file-earmark-ruled::before {
  content: "\f385";
}

.bi-file-earmark-slides-fill::before {
  content: "\f386";
}

.bi-file-earmark-slides::before {
  content: "\f387";
}

.bi-file-earmark-spreadsheet-fill::before {
  content: "\f388";
}

.bi-file-earmark-spreadsheet::before {
  content: "\f389";
}

.bi-file-earmark-text-fill::before {
  content: "\f38a";
}

.bi-file-earmark-text::before {
  content: "\f38b";
}

.bi-file-earmark-word-fill::before {
  content: "\f38c";
}

.bi-file-earmark-word::before {
  content: "\f38d";
}

.bi-file-earmark-x-fill::before {
  content: "\f38e";
}

.bi-file-earmark-x::before {
  content: "\f38f";
}

.bi-file-earmark-zip-fill::before {
  content: "\f390";
}

.bi-file-earmark-zip::before {
  content: "\f391";
}

.bi-file-earmark::before {
  content: "\f392";
}

.bi-file-easel-fill::before {
  content: "\f393";
}

.bi-file-easel::before {
  content: "\f394";
}

.bi-file-excel-fill::before {
  content: "\f395";
}

.bi-file-excel::before {
  content: "\f396";
}

.bi-file-fill::before {
  content: "\f397";
}

.bi-file-font-fill::before {
  content: "\f398";
}

.bi-file-font::before {
  content: "\f399";
}

.bi-file-image-fill::before {
  content: "\f39a";
}

.bi-file-image::before {
  content: "\f39b";
}

.bi-file-lock-fill::before {
  content: "\f39c";
}

.bi-file-lock::before {
  content: "\f39d";
}

.bi-file-lock2-fill::before {
  content: "\f39e";
}

.bi-file-lock2::before {
  content: "\f39f";
}

.bi-file-medical-fill::before {
  content: "\f3a0";
}

.bi-file-medical::before {
  content: "\f3a1";
}

.bi-file-minus-fill::before {
  content: "\f3a2";
}

.bi-file-minus::before {
  content: "\f3a3";
}

.bi-file-music-fill::before {
  content: "\f3a4";
}

.bi-file-music::before {
  content: "\f3a5";
}

.bi-file-person-fill::before {
  content: "\f3a6";
}

.bi-file-person::before {
  content: "\f3a7";
}

.bi-file-play-fill::before {
  content: "\f3a8";
}

.bi-file-play::before {
  content: "\f3a9";
}

.bi-file-plus-fill::before {
  content: "\f3aa";
}

.bi-file-plus::before {
  content: "\f3ab";
}

.bi-file-post-fill::before {
  content: "\f3ac";
}

.bi-file-post::before {
  content: "\f3ad";
}

.bi-file-ppt-fill::before {
  content: "\f3ae";
}

.bi-file-ppt::before {
  content: "\f3af";
}

.bi-file-richtext-fill::before {
  content: "\f3b0";
}

.bi-file-richtext::before {
  content: "\f3b1";
}

.bi-file-ruled-fill::before {
  content: "\f3b2";
}

.bi-file-ruled::before {
  content: "\f3b3";
}

.bi-file-slides-fill::before {
  content: "\f3b4";
}

.bi-file-slides::before {
  content: "\f3b5";
}

.bi-file-spreadsheet-fill::before {
  content: "\f3b6";
}

.bi-file-spreadsheet::before {
  content: "\f3b7";
}

.bi-file-text-fill::before {
  content: "\f3b8";
}

.bi-file-text::before {
  content: "\f3b9";
}

.bi-file-word-fill::before {
  content: "\f3ba";
}

.bi-file-word::before {
  content: "\f3bb";
}

.bi-file-x-fill::before {
  content: "\f3bc";
}

.bi-file-x::before {
  content: "\f3bd";
}

.bi-file-zip-fill::before {
  content: "\f3be";
}

.bi-file-zip::before {
  content: "\f3bf";
}

.bi-file::before {
  content: "\f3c0";
}

.bi-files-alt::before {
  content: "\f3c1";
}

.bi-files::before {
  content: "\f3c2";
}

.bi-film::before {
  content: "\f3c3";
}

.bi-filter-circle-fill::before {
  content: "\f3c4";
}

.bi-filter-circle::before {
  content: "\f3c5";
}

.bi-filter-left::before {
  content: "\f3c6";
}

.bi-filter-right::before {
  content: "\f3c7";
}

.bi-filter-square-fill::before {
  content: "\f3c8";
}

.bi-filter-square::before {
  content: "\f3c9";
}

.bi-filter::before {
  content: "\f3ca";
}

.bi-flag-fill::before {
  content: "\f3cb";
}

.bi-flag::before {
  content: "\f3cc";
}

.bi-flower1::before {
  content: "\f3cd";
}

.bi-flower2::before {
  content: "\f3ce";
}

.bi-flower3::before {
  content: "\f3cf";
}

.bi-folder-check::before {
  content: "\f3d0";
}

.bi-folder-fill::before {
  content: "\f3d1";
}

.bi-folder-minus::before {
  content: "\f3d2";
}

.bi-folder-plus::before {
  content: "\f3d3";
}

.bi-folder-symlink-fill::before {
  content: "\f3d4";
}

.bi-folder-symlink::before {
  content: "\f3d5";
}

.bi-folder-x::before {
  content: "\f3d6";
}

.bi-folder::before {
  content: "\f3d7";
}

.bi-folder2-open::before {
  content: "\f3d8";
}

.bi-folder2::before {
  content: "\f3d9";
}

.bi-fonts::before {
  content: "\f3da";
}

.bi-forward-fill::before {
  content: "\f3db";
}

.bi-forward::before {
  content: "\f3dc";
}

.bi-front::before {
  content: "\f3dd";
}

.bi-fullscreen-exit::before {
  content: "\f3de";
}

.bi-fullscreen::before {
  content: "\f3df";
}

.bi-funnel-fill::before {
  content: "\f3e0";
}

.bi-funnel::before {
  content: "\f3e1";
}

.bi-gear-fill::before {
  content: "\f3e2";
}

.bi-gear-wide-connected::before {
  content: "\f3e3";
}

.bi-gear-wide::before {
  content: "\f3e4";
}

.bi-gear::before {
  content: "\f3e5";
}

.bi-gem::before {
  content: "\f3e6";
}

.bi-geo-alt-fill::before {
  content: "\f3e7";
}

.bi-geo-alt::before {
  content: "\f3e8";
}

.bi-geo-fill::before {
  content: "\f3e9";
}

.bi-geo::before {
  content: "\f3ea";
}

.bi-gift-fill::before {
  content: "\f3eb";
}

.bi-gift::before {
  content: "\f3ec";
}

.bi-github::before {
  content: "\f3ed";
}

.bi-globe::before {
  content: "\f3ee";
}

.bi-globe2::before {
  content: "\f3ef";
}

.bi-google::before {
  content: "\f3f0";
}

.bi-graph-down::before {
  content: "\f3f1";
}

.bi-graph-up::before {
  content: "\f3f2";
}

.bi-grid-1x2-fill::before {
  content: "\f3f3";
}

.bi-grid-1x2::before {
  content: "\f3f4";
}

.bi-grid-3x2-gap-fill::before {
  content: "\f3f5";
}

.bi-grid-3x2-gap::before {
  content: "\f3f6";
}

.bi-grid-3x2::before {
  content: "\f3f7";
}

.bi-grid-3x3-gap-fill::before {
  content: "\f3f8";
}

.bi-grid-3x3-gap::before {
  content: "\f3f9";
}

.bi-grid-3x3::before {
  content: "\f3fa";
}

.bi-grid-fill::before {
  content: "\f3fb";
}

.bi-grid::before {
  content: "\f3fc";
}

.bi-grip-horizontal::before {
  content: "\f3fd";
}

.bi-grip-vertical::before {
  content: "\f3fe";
}

.bi-hammer::before {
  content: "\f3ff";
}

.bi-hand-index-fill::before {
  content: "\f400";
}

.bi-hand-index-thumb-fill::before {
  content: "\f401";
}

.bi-hand-index-thumb::before {
  content: "\f402";
}

.bi-hand-index::before {
  content: "\f403";
}

.bi-hand-thumbs-down-fill::before {
  content: "\f404";
}

.bi-hand-thumbs-down::before {
  content: "\f405";
}

.bi-hand-thumbs-up-fill::before {
  content: "\f406";
}

.bi-hand-thumbs-up::before {
  content: "\f407";
}

.bi-handbag-fill::before {
  content: "\f408";
}

.bi-handbag::before {
  content: "\f409";
}

.bi-hash::before {
  content: "\f40a";
}

.bi-hdd-fill::before {
  content: "\f40b";
}

.bi-hdd-network-fill::before {
  content: "\f40c";
}

.bi-hdd-network::before {
  content: "\f40d";
}

.bi-hdd-rack-fill::before {
  content: "\f40e";
}

.bi-hdd-rack::before {
  content: "\f40f";
}

.bi-hdd-stack-fill::before {
  content: "\f410";
}

.bi-hdd-stack::before {
  content: "\f411";
}

.bi-hdd::before {
  content: "\f412";
}

.bi-headphones::before {
  content: "\f413";
}

.bi-headset::before {
  content: "\f414";
}

.bi-heart-fill::before {
  content: "\f415";
}

.bi-heart-half::before {
  content: "\f416";
}

.bi-heart::before {
  content: "\f417";
}

.bi-heptagon-fill::before {
  content: "\f418";
}

.bi-heptagon-half::before {
  content: "\f419";
}

.bi-heptagon::before {
  content: "\f41a";
}

.bi-hexagon-fill::before {
  content: "\f41b";
}

.bi-hexagon-half::before {
  content: "\f41c";
}

.bi-hexagon::before {
  content: "\f41d";
}

.bi-hourglass-bottom::before {
  content: "\f41e";
}

.bi-hourglass-split::before {
  content: "\f41f";
}

.bi-hourglass-top::before {
  content: "\f420";
}

.bi-hourglass::before {
  content: "\f421";
}

.bi-house-door-fill::before {
  content: "\f422";
}

.bi-house-door::before {
  content: "\f423";
}

.bi-house-fill::before {
  content: "\f424";
}

.bi-house::before {
  content: "\f425";
}

.bi-hr::before {
  content: "\f426";
}

.bi-hurricane::before {
  content: "\f427";
}

.bi-image-alt::before {
  content: "\f428";
}

.bi-image-fill::before {
  content: "\f429";
}

.bi-image::before {
  content: "\f42a";
}

.bi-images::before {
  content: "\f42b";
}

.bi-inbox-fill::before {
  content: "\f42c";
}

.bi-inbox::before {
  content: "\f42d";
}

.bi-inboxes-fill::before {
  content: "\f42e";
}

.bi-inboxes::before {
  content: "\f42f";
}

.bi-info-circle-fill::before {
  content: "\f430";
}

.bi-info-circle::before {
  content: "\f431";
}

.bi-info-square-fill::before {
  content: "\f432";
}

.bi-info-square::before {
  content: "\f433";
}

.bi-info::before {
  content: "\f434";
}

.bi-input-cursor-text::before {
  content: "\f435";
}

.bi-input-cursor::before {
  content: "\f436";
}

.bi-instagram::before {
  content: "\f437";
}

.bi-intersect::before {
  content: "\f438";
}

.bi-journal-album::before {
  content: "\f439";
}

.bi-journal-arrow-down::before {
  content: "\f43a";
}

.bi-journal-arrow-up::before {
  content: "\f43b";
}

.bi-journal-bookmark-fill::before {
  content: "\f43c";
}

.bi-journal-bookmark::before {
  content: "\f43d";
}

.bi-journal-check::before {
  content: "\f43e";
}

.bi-journal-code::before {
  content: "\f43f";
}

.bi-journal-medical::before {
  content: "\f440";
}

.bi-journal-minus::before {
  content: "\f441";
}

.bi-journal-plus::before {
  content: "\f442";
}

.bi-journal-richtext::before {
  content: "\f443";
}

.bi-journal-text::before {
  content: "\f444";
}

.bi-journal-x::before {
  content: "\f445";
}

.bi-journal::before {
  content: "\f446";
}

.bi-journals::before {
  content: "\f447";
}

.bi-joystick::before {
  content: "\f448";
}

.bi-justify-left::before {
  content: "\f449";
}

.bi-justify-right::before {
  content: "\f44a";
}

.bi-justify::before {
  content: "\f44b";
}

.bi-kanban-fill::before {
  content: "\f44c";
}

.bi-kanban::before {
  content: "\f44d";
}

.bi-key-fill::before {
  content: "\f44e";
}

.bi-key::before {
  content: "\f44f";
}

.bi-keyboard-fill::before {
  content: "\f450";
}

.bi-keyboard::before {
  content: "\f451";
}

.bi-ladder::before {
  content: "\f452";
}

.bi-lamp-fill::before {
  content: "\f453";
}

.bi-lamp::before {
  content: "\f454";
}

.bi-laptop-fill::before {
  content: "\f455";
}

.bi-laptop::before {
  content: "\f456";
}

.bi-layer-backward::before {
  content: "\f457";
}

.bi-layer-forward::before {
  content: "\f458";
}

.bi-layers-fill::before {
  content: "\f459";
}

.bi-layers-half::before {
  content: "\f45a";
}

.bi-layers::before {
  content: "\f45b";
}

.bi-layout-sidebar-inset-reverse::before {
  content: "\f45c";
}

.bi-layout-sidebar-inset::before {
  content: "\f45d";
}

.bi-layout-sidebar-reverse::before {
  content: "\f45e";
}

.bi-layout-sidebar::before {
  content: "\f45f";
}

.bi-layout-split::before {
  content: "\f460";
}

.bi-layout-text-sidebar-reverse::before {
  content: "\f461";
}

.bi-layout-text-sidebar::before {
  content: "\f462";
}

.bi-layout-text-window-reverse::before {
  content: "\f463";
}

.bi-layout-text-window::before {
  content: "\f464";
}

.bi-layout-three-columns::before {
  content: "\f465";
}

.bi-layout-wtf::before {
  content: "\f466";
}

.bi-life-preserver::before {
  content: "\f467";
}

.bi-lightbulb-fill::before {
  content: "\f468";
}

.bi-lightbulb-off-fill::before {
  content: "\f469";
}

.bi-lightbulb-off::before {
  content: "\f46a";
}

.bi-lightbulb::before {
  content: "\f46b";
}

.bi-lightning-charge-fill::before {
  content: "\f46c";
}

.bi-lightning-charge::before {
  content: "\f46d";
}

.bi-lightning-fill::before {
  content: "\f46e";
}

.bi-lightning::before {
  content: "\f46f";
}

.bi-link-45deg::before {
  content: "\f470";
}

.bi-link::before {
  content: "\f471";
}

.bi-linkedin::before {
  content: "\f472";
}

.bi-list-check::before {
  content: "\f473";
}

.bi-list-nested::before {
  content: "\f474";
}

.bi-list-ol::before {
  content: "\f475";
}

.bi-list-stars::before {
  content: "\f476";
}

.bi-list-task::before {
  content: "\f477";
}

.bi-list-ul::before {
  content: "\f478";
}

.bi-list::before {
  content: "\f479";
}

.bi-lock-fill::before {
  content: "\f47a";
}

.bi-lock::before {
  content: "\f47b";
}

.bi-mailbox::before {
  content: "\f47c";
}

.bi-mailbox2::before {
  content: "\f47d";
}

.bi-map-fill::before {
  content: "\f47e";
}

.bi-map::before {
  content: "\f47f";
}

.bi-markdown-fill::before {
  content: "\f480";
}

.bi-markdown::before {
  content: "\f481";
}

.bi-mask::before {
  content: "\f482";
}

.bi-megaphone-fill::before {
  content: "\f483";
}

.bi-megaphone::before {
  content: "\f484";
}

.bi-menu-app-fill::before {
  content: "\f485";
}

.bi-menu-app::before {
  content: "\f486";
}

.bi-menu-button-fill::before {
  content: "\f487";
}

.bi-menu-button-wide-fill::before {
  content: "\f488";
}

.bi-menu-button-wide::before {
  content: "\f489";
}

.bi-menu-button::before {
  content: "\f48a";
}

.bi-menu-down::before {
  content: "\f48b";
}

.bi-menu-up::before {
  content: "\f48c";
}

.bi-mic-fill::before {
  content: "\f48d";
}

.bi-mic-mute-fill::before {
  content: "\f48e";
}

.bi-mic-mute::before {
  content: "\f48f";
}

.bi-mic::before {
  content: "\f490";
}

.bi-minecart-loaded::before {
  content: "\f491";
}

.bi-minecart::before {
  content: "\f492";
}

.bi-moisture::before {
  content: "\f493";
}

.bi-moon-fill::before {
  content: "\f494";
}

.bi-moon-stars-fill::before {
  content: "\f495";
}

.bi-moon-stars::before {
  content: "\f496";
}

.bi-moon::before {
  content: "\f497";
}

.bi-mouse-fill::before {
  content: "\f498";
}

.bi-mouse::before {
  content: "\f499";
}

.bi-mouse2-fill::before {
  content: "\f49a";
}

.bi-mouse2::before {
  content: "\f49b";
}

.bi-mouse3-fill::before {
  content: "\f49c";
}

.bi-mouse3::before {
  content: "\f49d";
}

.bi-music-note-beamed::before {
  content: "\f49e";
}

.bi-music-note-list::before {
  content: "\f49f";
}

.bi-music-note::before {
  content: "\f4a0";
}

.bi-music-player-fill::before {
  content: "\f4a1";
}

.bi-music-player::before {
  content: "\f4a2";
}

.bi-newspaper::before {
  content: "\f4a3";
}

.bi-node-minus-fill::before {
  content: "\f4a4";
}

.bi-node-minus::before {
  content: "\f4a5";
}

.bi-node-plus-fill::before {
  content: "\f4a6";
}

.bi-node-plus::before {
  content: "\f4a7";
}

.bi-nut-fill::before {
  content: "\f4a8";
}

.bi-nut::before {
  content: "\f4a9";
}

.bi-octagon-fill::before {
  content: "\f4aa";
}

.bi-octagon-half::before {
  content: "\f4ab";
}

.bi-octagon::before {
  content: "\f4ac";
}

.bi-option::before {
  content: "\f4ad";
}

.bi-outlet::before {
  content: "\f4ae";
}

.bi-paint-bucket::before {
  content: "\f4af";
}

.bi-palette-fill::before {
  content: "\f4b0";
}

.bi-palette::before {
  content: "\f4b1";
}

.bi-palette2::before {
  content: "\f4b2";
}

.bi-paperclip::before {
  content: "\f4b3";
}

.bi-paragraph::before {
  content: "\f4b4";
}

.bi-patch-check-fill::before {
  content: "\f4b5";
}

.bi-patch-check::before {
  content: "\f4b6";
}

.bi-patch-exclamation-fill::before {
  content: "\f4b7";
}

.bi-patch-exclamation::before {
  content: "\f4b8";
}

.bi-patch-minus-fill::before {
  content: "\f4b9";
}

.bi-patch-minus::before {
  content: "\f4ba";
}

.bi-patch-plus-fill::before {
  content: "\f4bb";
}

.bi-patch-plus::before {
  content: "\f4bc";
}

.bi-patch-question-fill::before {
  content: "\f4bd";
}

.bi-patch-question::before {
  content: "\f4be";
}

.bi-pause-btn-fill::before {
  content: "\f4bf";
}

.bi-pause-btn::before {
  content: "\f4c0";
}

.bi-pause-circle-fill::before {
  content: "\f4c1";
}

.bi-pause-circle::before {
  content: "\f4c2";
}

.bi-pause-fill::before {
  content: "\f4c3";
}

.bi-pause::before {
  content: "\f4c4";
}

.bi-peace-fill::before {
  content: "\f4c5";
}

.bi-peace::before {
  content: "\f4c6";
}

.bi-pen-fill::before {
  content: "\f4c7";
}

.bi-pen::before {
  content: "\f4c8";
}

.bi-pencil-fill::before {
  content: "\f4c9";
}

.bi-pencil-square::before {
  content: "\f4ca";
}

.bi-pencil::before {
  content: "\f4cb";
}

.bi-pentagon-fill::before {
  content: "\f4cc";
}

.bi-pentagon-half::before {
  content: "\f4cd";
}

.bi-pentagon::before {
  content: "\f4ce";
}

.bi-people-fill::before {
  content: "\f4cf";
}

.bi-people::before {
  content: "\f4d0";
}

.bi-percent::before {
  content: "\f4d1";
}

.bi-person-badge-fill::before {
  content: "\f4d2";
}

.bi-person-badge::before {
  content: "\f4d3";
}

.bi-person-bounding-box::before {
  content: "\f4d4";
}

.bi-person-check-fill::before {
  content: "\f4d5";
}

.bi-person-check::before {
  content: "\f4d6";
}

.bi-person-circle::before {
  content: "\f4d7";
}

.bi-person-dash-fill::before {
  content: "\f4d8";
}

.bi-person-dash::before {
  content: "\f4d9";
}

.bi-person-fill::before {
  content: "\f4da";
}

.bi-person-lines-fill::before {
  content: "\f4db";
}

.bi-person-plus-fill::before {
  content: "\f4dc";
}

.bi-person-plus::before {
  content: "\f4dd";
}

.bi-person-square::before {
  content: "\f4de";
}

.bi-person-x-fill::before {
  content: "\f4df";
}

.bi-person-x::before {
  content: "\f4e0";
}

.bi-person::before {
  content: "\f4e1";
}

.bi-phone-fill::before {
  content: "\f4e2";
}

.bi-phone-landscape-fill::before {
  content: "\f4e3";
}

.bi-phone-landscape::before {
  content: "\f4e4";
}

.bi-phone-vibrate-fill::before {
  content: "\f4e5";
}

.bi-phone-vibrate::before {
  content: "\f4e6";
}

.bi-phone::before {
  content: "\f4e7";
}

.bi-pie-chart-fill::before {
  content: "\f4e8";
}

.bi-pie-chart::before {
  content: "\f4e9";
}

.bi-pin-angle-fill::before {
  content: "\f4ea";
}

.bi-pin-angle::before {
  content: "\f4eb";
}

.bi-pin-fill::before {
  content: "\f4ec";
}

.bi-pin::before {
  content: "\f4ed";
}

.bi-pip-fill::before {
  content: "\f4ee";
}

.bi-pip::before {
  content: "\f4ef";
}

.bi-play-btn-fill::before {
  content: "\f4f0";
}

.bi-play-btn::before {
  content: "\f4f1";
}

.bi-play-circle-fill::before {
  content: "\f4f2";
}

.bi-play-circle::before {
  content: "\f4f3";
}

.bi-play-fill::before {
  content: "\f4f4";
}

.bi-play::before {
  content: "\f4f5";
}

.bi-plug-fill::before {
  content: "\f4f6";
}

.bi-plug::before {
  content: "\f4f7";
}

.bi-plus-circle-dotted::before {
  content: "\f4f8";
}

.bi-plus-circle-fill::before {
  content: "\f4f9";
}

.bi-plus-circle::before {
  content: "\f4fa";
}

.bi-plus-square-dotted::before {
  content: "\f4fb";
}

.bi-plus-square-fill::before {
  content: "\f4fc";
}

.bi-plus-square::before {
  content: "\f4fd";
}

.bi-plus::before {
  content: "\f4fe";
}

.bi-power::before {
  content: "\f4ff";
}

.bi-printer-fill::before {
  content: "\f500";
}

.bi-printer::before {
  content: "\f501";
}

.bi-puzzle-fill::before {
  content: "\f502";
}

.bi-puzzle::before {
  content: "\f503";
}

.bi-question-circle-fill::before {
  content: "\f504";
}

.bi-question-circle::before {
  content: "\f505";
}

.bi-question-diamond-fill::before {
  content: "\f506";
}

.bi-question-diamond::before {
  content: "\f507";
}

.bi-question-octagon-fill::before {
  content: "\f508";
}

.bi-question-octagon::before {
  content: "\f509";
}

.bi-question-square-fill::before {
  content: "\f50a";
}

.bi-question-square::before {
  content: "\f50b";
}

.bi-question::before {
  content: "\f50c";
}

.bi-rainbow::before {
  content: "\f50d";
}

.bi-receipt-cutoff::before {
  content: "\f50e";
}

.bi-receipt::before {
  content: "\f50f";
}

.bi-reception-0::before {
  content: "\f510";
}

.bi-reception-1::before {
  content: "\f511";
}

.bi-reception-2::before {
  content: "\f512";
}

.bi-reception-3::before {
  content: "\f513";
}

.bi-reception-4::before {
  content: "\f514";
}

.bi-record-btn-fill::before {
  content: "\f515";
}

.bi-record-btn::before {
  content: "\f516";
}

.bi-record-circle-fill::before {
  content: "\f517";
}

.bi-record-circle::before {
  content: "\f518";
}

.bi-record-fill::before {
  content: "\f519";
}

.bi-record::before {
  content: "\f51a";
}

.bi-record2-fill::before {
  content: "\f51b";
}

.bi-record2::before {
  content: "\f51c";
}

.bi-reply-all-fill::before {
  content: "\f51d";
}

.bi-reply-all::before {
  content: "\f51e";
}

.bi-reply-fill::before {
  content: "\f51f";
}

.bi-reply::before {
  content: "\f520";
}

.bi-rss-fill::before {
  content: "\f521";
}

.bi-rss::before {
  content: "\f522";
}

.bi-rulers::before {
  content: "\f523";
}

.bi-save-fill::before {
  content: "\f524";
}

.bi-save::before {
  content: "\f525";
}

.bi-save2-fill::before {
  content: "\f526";
}

.bi-save2::before {
  content: "\f527";
}

.bi-scissors::before {
  content: "\f528";
}

.bi-screwdriver::before {
  content: "\f529";
}

.bi-search::before {
  content: "\f52a";
}

.bi-segmented-nav::before {
  content: "\f52b";
}

.bi-server::before {
  content: "\f52c";
}

.bi-share-fill::before {
  content: "\f52d";
}

.bi-share::before {
  content: "\f52e";
}

.bi-shield-check::before {
  content: "\f52f";
}

.bi-shield-exclamation::before {
  content: "\f530";
}

.bi-shield-fill-check::before {
  content: "\f531";
}

.bi-shield-fill-exclamation::before {
  content: "\f532";
}

.bi-shield-fill-minus::before {
  content: "\f533";
}

.bi-shield-fill-plus::before {
  content: "\f534";
}

.bi-shield-fill-x::before {
  content: "\f535";
}

.bi-shield-fill::before {
  content: "\f536";
}

.bi-shield-lock-fill::before {
  content: "\f537";
}

.bi-shield-lock::before {
  content: "\f538";
}

.bi-shield-minus::before {
  content: "\f539";
}

.bi-shield-plus::before {
  content: "\f53a";
}

.bi-shield-shaded::before {
  content: "\f53b";
}

.bi-shield-slash-fill::before {
  content: "\f53c";
}

.bi-shield-slash::before {
  content: "\f53d";
}

.bi-shield-x::before {
  content: "\f53e";
}

.bi-shield::before {
  content: "\f53f";
}

.bi-shift-fill::before {
  content: "\f540";
}

.bi-shift::before {
  content: "\f541";
}

.bi-shop-window::before {
  content: "\f542";
}

.bi-shop::before {
  content: "\f543";
}

.bi-shuffle::before {
  content: "\f544";
}

.bi-signpost-2-fill::before {
  content: "\f545";
}

.bi-signpost-2::before {
  content: "\f546";
}

.bi-signpost-fill::before {
  content: "\f547";
}

.bi-signpost-split-fill::before {
  content: "\f548";
}

.bi-signpost-split::before {
  content: "\f549";
}

.bi-signpost::before {
  content: "\f54a";
}

.bi-sim-fill::before {
  content: "\f54b";
}

.bi-sim::before {
  content: "\f54c";
}

.bi-skip-backward-btn-fill::before {
  content: "\f54d";
}

.bi-skip-backward-btn::before {
  content: "\f54e";
}

.bi-skip-backward-circle-fill::before {
  content: "\f54f";
}

.bi-skip-backward-circle::before {
  content: "\f550";
}

.bi-skip-backward-fill::before {
  content: "\f551";
}

.bi-skip-backward::before {
  content: "\f552";
}

.bi-skip-end-btn-fill::before {
  content: "\f553";
}

.bi-skip-end-btn::before {
  content: "\f554";
}

.bi-skip-end-circle-fill::before {
  content: "\f555";
}

.bi-skip-end-circle::before {
  content: "\f556";
}

.bi-skip-end-fill::before {
  content: "\f557";
}

.bi-skip-end::before {
  content: "\f558";
}

.bi-skip-forward-btn-fill::before {
  content: "\f559";
}

.bi-skip-forward-btn::before {
  content: "\f55a";
}

.bi-skip-forward-circle-fill::before {
  content: "\f55b";
}

.bi-skip-forward-circle::before {
  content: "\f55c";
}

.bi-skip-forward-fill::before {
  content: "\f55d";
}

.bi-skip-forward::before {
  content: "\f55e";
}

.bi-skip-start-btn-fill::before {
  content: "\f55f";
}

.bi-skip-start-btn::before {
  content: "\f560";
}

.bi-skip-start-circle-fill::before {
  content: "\f561";
}

.bi-skip-start-circle::before {
  content: "\f562";
}

.bi-skip-start-fill::before {
  content: "\f563";
}

.bi-skip-start::before {
  content: "\f564";
}

.bi-slack::before {
  content: "\f565";
}

.bi-slash-circle-fill::before {
  content: "\f566";
}

.bi-slash-circle::before {
  content: "\f567";
}

.bi-slash-square-fill::before {
  content: "\f568";
}

.bi-slash-square::before {
  content: "\f569";
}

.bi-slash::before {
  content: "\f56a";
}

.bi-sliders::before {
  content: "\f56b";
}

.bi-smartwatch::before {
  content: "\f56c";
}

.bi-snow::before {
  content: "\f56d";
}

.bi-snow2::before {
  content: "\f56e";
}

.bi-snow3::before {
  content: "\f56f";
}

.bi-sort-alpha-down-alt::before {
  content: "\f570";
}

.bi-sort-alpha-down::before {
  content: "\f571";
}

.bi-sort-alpha-up-alt::before {
  content: "\f572";
}

.bi-sort-alpha-up::before {
  content: "\f573";
}

.bi-sort-down-alt::before {
  content: "\f574";
}

.bi-sort-down::before {
  content: "\f575";
}

.bi-sort-numeric-down-alt::before {
  content: "\f576";
}

.bi-sort-numeric-down::before {
  content: "\f577";
}

.bi-sort-numeric-up-alt::before {
  content: "\f578";
}

.bi-sort-numeric-up::before {
  content: "\f579";
}

.bi-sort-up-alt::before {
  content: "\f57a";
}

.bi-sort-up::before {
  content: "\f57b";
}

.bi-soundwave::before {
  content: "\f57c";
}

.bi-speaker-fill::before {
  content: "\f57d";
}

.bi-speaker::before {
  content: "\f57e";
}

.bi-speedometer::before {
  content: "\f57f";
}

.bi-speedometer2::before {
  content: "\f580";
}

.bi-spellcheck::before {
  content: "\f581";
}

.bi-square-fill::before {
  content: "\f582";
}

.bi-square-half::before {
  content: "\f583";
}

.bi-square::before {
  content: "\f584";
}

.bi-stack::before {
  content: "\f585";
}

.bi-star-fill::before {
  content: "\f586";
}

.bi-star-half::before {
  content: "\f587";
}

.bi-star::before {
  content: "\f588";
}

.bi-stars::before {
  content: "\f589";
}

.bi-stickies-fill::before {
  content: "\f58a";
}

.bi-stickies::before {
  content: "\f58b";
}

.bi-sticky-fill::before {
  content: "\f58c";
}

.bi-sticky::before {
  content: "\f58d";
}

.bi-stop-btn-fill::before {
  content: "\f58e";
}

.bi-stop-btn::before {
  content: "\f58f";
}

.bi-stop-circle-fill::before {
  content: "\f590";
}

.bi-stop-circle::before {
  content: "\f591";
}

.bi-stop-fill::before {
  content: "\f592";
}

.bi-stop::before {
  content: "\f593";
}

.bi-stoplights-fill::before {
  content: "\f594";
}

.bi-stoplights::before {
  content: "\f595";
}

.bi-stopwatch-fill::before {
  content: "\f596";
}

.bi-stopwatch::before {
  content: "\f597";
}

.bi-subtract::before {
  content: "\f598";
}

.bi-suit-club-fill::before {
  content: "\f599";
}

.bi-suit-club::before {
  content: "\f59a";
}

.bi-suit-diamond-fill::before {
  content: "\f59b";
}

.bi-suit-diamond::before {
  content: "\f59c";
}

.bi-suit-heart-fill::before {
  content: "\f59d";
}

.bi-suit-heart::before {
  content: "\f59e";
}

.bi-suit-spade-fill::before {
  content: "\f59f";
}

.bi-suit-spade::before {
  content: "\f5a0";
}

.bi-sun-fill::before {
  content: "\f5a1";
}

.bi-sun::before {
  content: "\f5a2";
}

.bi-sunglasses::before {
  content: "\f5a3";
}

.bi-sunrise-fill::before {
  content: "\f5a4";
}

.bi-sunrise::before {
  content: "\f5a5";
}

.bi-sunset-fill::before {
  content: "\f5a6";
}

.bi-sunset::before {
  content: "\f5a7";
}

.bi-symmetry-horizontal::before {
  content: "\f5a8";
}

.bi-symmetry-vertical::before {
  content: "\f5a9";
}

.bi-table::before {
  content: "\f5aa";
}

.bi-tablet-fill::before {
  content: "\f5ab";
}

.bi-tablet-landscape-fill::before {
  content: "\f5ac";
}

.bi-tablet-landscape::before {
  content: "\f5ad";
}

.bi-tablet::before {
  content: "\f5ae";
}

.bi-tag-fill::before {
  content: "\f5af";
}

.bi-tag::before {
  content: "\f5b0";
}

.bi-tags-fill::before {
  content: "\f5b1";
}

.bi-tags::before {
  content: "\f5b2";
}

.bi-telegram::before {
  content: "\f5b3";
}

.bi-telephone-fill::before {
  content: "\f5b4";
}

.bi-telephone-forward-fill::before {
  content: "\f5b5";
}

.bi-telephone-forward::before {
  content: "\f5b6";
}

.bi-telephone-inbound-fill::before {
  content: "\f5b7";
}

.bi-telephone-inbound::before {
  content: "\f5b8";
}

.bi-telephone-minus-fill::before {
  content: "\f5b9";
}

.bi-telephone-minus::before {
  content: "\f5ba";
}

.bi-telephone-outbound-fill::before {
  content: "\f5bb";
}

.bi-telephone-outbound::before {
  content: "\f5bc";
}

.bi-telephone-plus-fill::before {
  content: "\f5bd";
}

.bi-telephone-plus::before {
  content: "\f5be";
}

.bi-telephone-x-fill::before {
  content: "\f5bf";
}

.bi-telephone-x::before {
  content: "\f5c0";
}

.bi-telephone::before {
  content: "\f5c1";
}

.bi-terminal-fill::before {
  content: "\f5c2";
}

.bi-terminal::before {
  content: "\f5c3";
}

.bi-text-center::before {
  content: "\f5c4";
}

.bi-text-indent-left::before {
  content: "\f5c5";
}

.bi-text-indent-right::before {
  content: "\f5c6";
}

.bi-text-left::before {
  content: "\f5c7";
}

.bi-text-paragraph::before {
  content: "\f5c8";
}

.bi-text-right::before {
  content: "\f5c9";
}

.bi-textarea-resize::before {
  content: "\f5ca";
}

.bi-textarea-t::before {
  content: "\f5cb";
}

.bi-textarea::before {
  content: "\f5cc";
}

.bi-thermometer-half::before {
  content: "\f5cd";
}

.bi-thermometer-high::before {
  content: "\f5ce";
}

.bi-thermometer-low::before {
  content: "\f5cf";
}

.bi-thermometer-snow::before {
  content: "\f5d0";
}

.bi-thermometer-sun::before {
  content: "\f5d1";
}

.bi-thermometer::before {
  content: "\f5d2";
}

.bi-three-dots-vertical::before {
  content: "\f5d3";
}

.bi-three-dots::before {
  content: "\f5d4";
}

.bi-toggle-off::before {
  content: "\f5d5";
}

.bi-toggle-on::before {
  content: "\f5d6";
}

.bi-toggle2-off::before {
  content: "\f5d7";
}

.bi-toggle2-on::before {
  content: "\f5d8";
}

.bi-toggles::before {
  content: "\f5d9";
}

.bi-toggles2::before {
  content: "\f5da";
}

.bi-tools::before {
  content: "\f5db";
}

.bi-tornado::before {
  content: "\f5dc";
}

.bi-trash-fill::before {
  content: "\f5dd";
}

.bi-trash::before {
  content: "\f5de";
}

.bi-trash2-fill::before {
  content: "\f5df";
}

.bi-trash2::before {
  content: "\f5e0";
}

.bi-tree-fill::before {
  content: "\f5e1";
}

.bi-tree::before {
  content: "\f5e2";
}

.bi-triangle-fill::before {
  content: "\f5e3";
}

.bi-triangle-half::before {
  content: "\f5e4";
}

.bi-triangle::before {
  content: "\f5e5";
}

.bi-trophy-fill::before {
  content: "\f5e6";
}

.bi-trophy::before {
  content: "\f5e7";
}

.bi-tropical-storm::before {
  content: "\f5e8";
}

.bi-truck-flatbed::before {
  content: "\f5e9";
}

.bi-truck::before {
  content: "\f5ea";
}

.bi-tsunami::before {
  content: "\f5eb";
}

.bi-tv-fill::before {
  content: "\f5ec";
}

.bi-tv::before {
  content: "\f5ed";
}

.bi-twitch::before {
  content: "\f5ee";
}

.bi-twitter::before {
  content: "\f5ef";
}

.bi-type-bold::before {
  content: "\f5f0";
}

.bi-type-h1::before {
  content: "\f5f1";
}

.bi-type-h2::before {
  content: "\f5f2";
}

.bi-type-h3::before {
  content: "\f5f3";
}

.bi-type-italic::before {
  content: "\f5f4";
}

.bi-type-strikethrough::before {
  content: "\f5f5";
}

.bi-type-underline::before {
  content: "\f5f6";
}

.bi-type::before {
  content: "\f5f7";
}

.bi-ui-checks-grid::before {
  content: "\f5f8";
}

.bi-ui-checks::before {
  content: "\f5f9";
}

.bi-ui-radios-grid::before {
  content: "\f5fa";
}

.bi-ui-radios::before {
  content: "\f5fb";
}

.bi-umbrella-fill::before {
  content: "\f5fc";
}

.bi-umbrella::before {
  content: "\f5fd";
}

.bi-union::before {
  content: "\f5fe";
}

.bi-unlock-fill::before {
  content: "\f5ff";
}

.bi-unlock::before {
  content: "\f600";
}

.bi-upc-scan::before {
  content: "\f601";
}

.bi-upc::before {
  content: "\f602";
}

.bi-upload::before {
  content: "\f603";
}

.bi-vector-pen::before {
  content: "\f604";
}

.bi-view-list::before {
  content: "\f605";
}

.bi-view-stacked::before {
  content: "\f606";
}

.bi-vinyl-fill::before {
  content: "\f607";
}

.bi-vinyl::before {
  content: "\f608";
}

.bi-voicemail::before {
  content: "\f609";
}

.bi-volume-down-fill::before {
  content: "\f60a";
}

.bi-volume-down::before {
  content: "\f60b";
}

.bi-volume-mute-fill::before {
  content: "\f60c";
}

.bi-volume-mute::before {
  content: "\f60d";
}

.bi-volume-off-fill::before {
  content: "\f60e";
}

.bi-volume-off::before {
  content: "\f60f";
}

.bi-volume-up-fill::before {
  content: "\f610";
}

.bi-volume-up::before {
  content: "\f611";
}

.bi-vr::before {
  content: "\f612";
}

.bi-wallet-fill::before {
  content: "\f613";
}

.bi-wallet::before {
  content: "\f614";
}

.bi-wallet2::before {
  content: "\f615";
}

.bi-watch::before {
  content: "\f616";
}

.bi-water::before {
  content: "\f617";
}

.bi-whatsapp::before {
  content: "\f618";
}

.bi-wifi-1::before {
  content: "\f619";
}

.bi-wifi-2::before {
  content: "\f61a";
}

.bi-wifi-off::before {
  content: "\f61b";
}

.bi-wifi::before {
  content: "\f61c";
}

.bi-wind::before {
  content: "\f61d";
}

.bi-window-dock::before {
  content: "\f61e";
}

.bi-window-sidebar::before {
  content: "\f61f";
}

.bi-window::before {
  content: "\f620";
}

.bi-wrench::before {
  content: "\f621";
}

.bi-x-circle-fill::before {
  content: "\f622";
}

.bi-x-circle::before {
  content: "\f623";
}

.bi-x-diamond-fill::before {
  content: "\f624";
}

.bi-x-diamond::before {
  content: "\f625";
}

.bi-x-octagon-fill::before {
  content: "\f626";
}

.bi-x-octagon::before {
  content: "\f627";
}

.bi-x-square-fill::before {
  content: "\f628";
}

.bi-x-square::before {
  content: "\f629";
}

.bi-x::before {
  content: "\f62a";
}

.bi-youtube::before {
  content: "\f62b";
}

.bi-zoom-in::before {
  content: "\f62c";
}

.bi-zoom-out::before {
  content: "\f62d";
}

.bi-bank::before {
  content: "\f62e";
}

.bi-bank2::before {
  content: "\f62f";
}

.bi-bell-slash-fill::before {
  content: "\f630";
}

.bi-bell-slash::before {
  content: "\f631";
}

.bi-cash-coin::before {
  content: "\f632";
}

.bi-check-lg::before {
  content: "\f633";
}

.bi-coin::before {
  content: "\f634";
}

.bi-currency-bitcoin::before {
  content: "\f635";
}

.bi-currency-dollar::before {
  content: "\f636";
}

.bi-currency-euro::before {
  content: "\f637";
}

.bi-currency-exchange::before {
  content: "\f638";
}

.bi-currency-pound::before {
  content: "\f639";
}

.bi-currency-yen::before {
  content: "\f63a";
}

.bi-dash-lg::before {
  content: "\f63b";
}

.bi-exclamation-lg::before {
  content: "\f63c";
}

.bi-file-earmark-pdf-fill::before {
  content: "\f63d";
}

.bi-file-earmark-pdf::before {
  content: "\f63e";
}

.bi-file-pdf-fill::before {
  content: "\f63f";
}

.bi-file-pdf::before {
  content: "\f640";
}

.bi-gender-ambiguous::before {
  content: "\f641";
}

.bi-gender-female::before {
  content: "\f642";
}

.bi-gender-male::before {
  content: "\f643";
}

.bi-gender-trans::before {
  content: "\f644";
}

.bi-headset-vr::before {
  content: "\f645";
}

.bi-info-lg::before {
  content: "\f646";
}

.bi-mastodon::before {
  content: "\f647";
}

.bi-messenger::before {
  content: "\f648";
}

.bi-piggy-bank-fill::before {
  content: "\f649";
}

.bi-piggy-bank::before {
  content: "\f64a";
}

.bi-pin-map-fill::before {
  content: "\f64b";
}

.bi-pin-map::before {
  content: "\f64c";
}

.bi-plus-lg::before {
  content: "\f64d";
}

.bi-question-lg::before {
  content: "\f64e";
}

.bi-recycle::before {
  content: "\f64f";
}

.bi-reddit::before {
  content: "\f650";
}

.bi-safe-fill::before {
  content: "\f651";
}

.bi-safe2-fill::before {
  content: "\f652";
}

.bi-safe2::before {
  content: "\f653";
}

.bi-sd-card-fill::before {
  content: "\f654";
}

.bi-sd-card::before {
  content: "\f655";
}

.bi-skype::before {
  content: "\f656";
}

.bi-slash-lg::before {
  content: "\f657";
}

.bi-translate::before {
  content: "\f658";
}

.bi-x-lg::before {
  content: "\f659";
}

.bi-safe::before {
  content: "\f65a";
}

.bi-apple::before {
  content: "\f65b";
}

.bi-microsoft::before {
  content: "\f65d";
}

.bi-windows::before {
  content: "\f65e";
}

.bi-behance::before {
  content: "\f65c";
}

.bi-dribbble::before {
  content: "\f65f";
}

.bi-line::before {
  content: "\f660";
}

.bi-medium::before {
  content: "\f661";
}

.bi-paypal::before {
  content: "\f662";
}

.bi-pinterest::before {
  content: "\f663";
}

.bi-signal::before {
  content: "\f664";
}

.bi-snapchat::before {
  content: "\f665";
}

.bi-spotify::before {
  content: "\f666";
}

.bi-stack-overflow::before {
  content: "\f667";
}

.bi-strava::before {
  content: "\f668";
}

.bi-wordpress::before {
  content: "\f669";
}

.bi-vimeo::before {
  content: "\f66a";
}

.bi-activity::before {
  content: "\f66b";
}

.bi-easel2-fill::before {
  content: "\f66c";
}

.bi-easel2::before {
  content: "\f66d";
}

.bi-easel3-fill::before {
  content: "\f66e";
}

.bi-easel3::before {
  content: "\f66f";
}

.bi-fan::before {
  content: "\f670";
}

.bi-fingerprint::before {
  content: "\f671";
}

.bi-graph-down-arrow::before {
  content: "\f672";
}

.bi-graph-up-arrow::before {
  content: "\f673";
}

.bi-hypnotize::before {
  content: "\f674";
}

.bi-magic::before {
  content: "\f675";
}

.bi-person-rolodex::before {
  content: "\f676";
}

.bi-person-video::before {
  content: "\f677";
}

.bi-person-video2::before {
  content: "\f678";
}

.bi-person-video3::before {
  content: "\f679";
}

.bi-person-workspace::before {
  content: "\f67a";
}

.bi-radioactive::before {
  content: "\f67b";
}

.bi-webcam-fill::before {
  content: "\f67c";
}

.bi-webcam::before {
  content: "\f67d";
}

.bi-yin-yang::before {
  content: "\f67e";
}

.bi-bandaid-fill::before {
  content: "\f680";
}

.bi-bandaid::before {
  content: "\f681";
}

.bi-bluetooth::before {
  content: "\f682";
}

.bi-body-text::before {
  content: "\f683";
}

.bi-boombox::before {
  content: "\f684";
}

.bi-boxes::before {
  content: "\f685";
}

.bi-dpad-fill::before {
  content: "\f686";
}

.bi-dpad::before {
  content: "\f687";
}

.bi-ear-fill::before {
  content: "\f688";
}

.bi-ear::before {
  content: "\f689";
}

.bi-envelope-check-fill::before {
  content: "\f68b";
}

.bi-envelope-check::before {
  content: "\f68c";
}

.bi-envelope-dash-fill::before {
  content: "\f68e";
}

.bi-envelope-dash::before {
  content: "\f68f";
}

.bi-envelope-exclamation-fill::before {
  content: "\f691";
}

.bi-envelope-exclamation::before {
  content: "\f692";
}

.bi-envelope-plus-fill::before {
  content: "\f693";
}

.bi-envelope-plus::before {
  content: "\f694";
}

.bi-envelope-slash-fill::before {
  content: "\f696";
}

.bi-envelope-slash::before {
  content: "\f697";
}

.bi-envelope-x-fill::before {
  content: "\f699";
}

.bi-envelope-x::before {
  content: "\f69a";
}

.bi-explicit-fill::before {
  content: "\f69b";
}

.bi-explicit::before {
  content: "\f69c";
}

.bi-git::before {
  content: "\f69d";
}

.bi-infinity::before {
  content: "\f69e";
}

.bi-list-columns-reverse::before {
  content: "\f69f";
}

.bi-list-columns::before {
  content: "\f6a0";
}

.bi-meta::before {
  content: "\f6a1";
}

.bi-nintendo-switch::before {
  content: "\f6a4";
}

.bi-pc-display-horizontal::before {
  content: "\f6a5";
}

.bi-pc-display::before {
  content: "\f6a6";
}

.bi-pc-horizontal::before {
  content: "\f6a7";
}

.bi-pc::before {
  content: "\f6a8";
}

.bi-playstation::before {
  content: "\f6a9";
}

.bi-plus-slash-minus::before {
  content: "\f6aa";
}

.bi-projector-fill::before {
  content: "\f6ab";
}

.bi-projector::before {
  content: "\f6ac";
}

.bi-qr-code-scan::before {
  content: "\f6ad";
}

.bi-qr-code::before {
  content: "\f6ae";
}

.bi-quora::before {
  content: "\f6af";
}

.bi-quote::before {
  content: "\f6b0";
}

.bi-robot::before {
  content: "\f6b1";
}

.bi-send-check-fill::before {
  content: "\f6b2";
}

.bi-send-check::before {
  content: "\f6b3";
}

.bi-send-dash-fill::before {
  content: "\f6b4";
}

.bi-send-dash::before {
  content: "\f6b5";
}

.bi-send-exclamation-fill::before {
  content: "\f6b7";
}

.bi-send-exclamation::before {
  content: "\f6b8";
}

.bi-send-fill::before {
  content: "\f6b9";
}

.bi-send-plus-fill::before {
  content: "\f6ba";
}

.bi-send-plus::before {
  content: "\f6bb";
}

.bi-send-slash-fill::before {
  content: "\f6bc";
}

.bi-send-slash::before {
  content: "\f6bd";
}

.bi-send-x-fill::before {
  content: "\f6be";
}

.bi-send-x::before {
  content: "\f6bf";
}

.bi-send::before {
  content: "\f6c0";
}

.bi-steam::before {
  content: "\f6c1";
}

.bi-terminal-dash::before {
  content: "\f6c3";
}

.bi-terminal-plus::before {
  content: "\f6c4";
}

.bi-terminal-split::before {
  content: "\f6c5";
}

.bi-ticket-detailed-fill::before {
  content: "\f6c6";
}

.bi-ticket-detailed::before {
  content: "\f6c7";
}

.bi-ticket-fill::before {
  content: "\f6c8";
}

.bi-ticket-perforated-fill::before {
  content: "\f6c9";
}

.bi-ticket-perforated::before {
  content: "\f6ca";
}

.bi-ticket::before {
  content: "\f6cb";
}

.bi-tiktok::before {
  content: "\f6cc";
}

.bi-window-dash::before {
  content: "\f6cd";
}

.bi-window-desktop::before {
  content: "\f6ce";
}

.bi-window-fullscreen::before {
  content: "\f6cf";
}

.bi-window-plus::before {
  content: "\f6d0";
}

.bi-window-split::before {
  content: "\f6d1";
}

.bi-window-stack::before {
  content: "\f6d2";
}

.bi-window-x::before {
  content: "\f6d3";
}

.bi-xbox::before {
  content: "\f6d4";
}

.bi-ethernet::before {
  content: "\f6d5";
}

.bi-hdmi-fill::before {
  content: "\f6d6";
}

.bi-hdmi::before {
  content: "\f6d7";
}

.bi-usb-c-fill::before {
  content: "\f6d8";
}

.bi-usb-c::before {
  content: "\f6d9";
}

.bi-usb-fill::before {
  content: "\f6da";
}

.bi-usb-plug-fill::before {
  content: "\f6db";
}

.bi-usb-plug::before {
  content: "\f6dc";
}

.bi-usb-symbol::before {
  content: "\f6dd";
}

.bi-usb::before {
  content: "\f6de";
}

.bi-boombox-fill::before {
  content: "\f6df";
}

.bi-displayport::before {
  content: "\f6e1";
}

.bi-gpu-card::before {
  content: "\f6e2";
}

.bi-memory::before {
  content: "\f6e3";
}

.bi-modem-fill::before {
  content: "\f6e4";
}

.bi-modem::before {
  content: "\f6e5";
}

.bi-motherboard-fill::before {
  content: "\f6e6";
}

.bi-motherboard::before {
  content: "\f6e7";
}

.bi-optical-audio-fill::before {
  content: "\f6e8";
}

.bi-optical-audio::before {
  content: "\f6e9";
}

.bi-pci-card::before {
  content: "\f6ea";
}

.bi-router-fill::before {
  content: "\f6eb";
}

.bi-router::before {
  content: "\f6ec";
}

.bi-thunderbolt-fill::before {
  content: "\f6ef";
}

.bi-thunderbolt::before {
  content: "\f6f0";
}

.bi-usb-drive-fill::before {
  content: "\f6f1";
}

.bi-usb-drive::before {
  content: "\f6f2";
}

.bi-usb-micro-fill::before {
  content: "\f6f3";
}

.bi-usb-micro::before {
  content: "\f6f4";
}

.bi-usb-mini-fill::before {
  content: "\f6f5";
}

.bi-usb-mini::before {
  content: "\f6f6";
}

.bi-cloud-haze2::before {
  content: "\f6f7";
}

.bi-device-hdd-fill::before {
  content: "\f6f8";
}

.bi-device-hdd::before {
  content: "\f6f9";
}

.bi-device-ssd-fill::before {
  content: "\f6fa";
}

.bi-device-ssd::before {
  content: "\f6fb";
}

.bi-displayport-fill::before {
  content: "\f6fc";
}

.bi-mortarboard-fill::before {
  content: "\f6fd";
}

.bi-mortarboard::before {
  content: "\f6fe";
}

.bi-terminal-x::before {
  content: "\f6ff";
}

.bi-arrow-through-heart-fill::before {
  content: "\f700";
}

.bi-arrow-through-heart::before {
  content: "\f701";
}

.bi-badge-sd-fill::before {
  content: "\f702";
}

.bi-badge-sd::before {
  content: "\f703";
}

.bi-bag-heart-fill::before {
  content: "\f704";
}

.bi-bag-heart::before {
  content: "\f705";
}

.bi-balloon-fill::before {
  content: "\f706";
}

.bi-balloon-heart-fill::before {
  content: "\f707";
}

.bi-balloon-heart::before {
  content: "\f708";
}

.bi-balloon::before {
  content: "\f709";
}

.bi-box2-fill::before {
  content: "\f70a";
}

.bi-box2-heart-fill::before {
  content: "\f70b";
}

.bi-box2-heart::before {
  content: "\f70c";
}

.bi-box2::before {
  content: "\f70d";
}

.bi-braces-asterisk::before {
  content: "\f70e";
}

.bi-calendar-heart-fill::before {
  content: "\f70f";
}

.bi-calendar-heart::before {
  content: "\f710";
}

.bi-calendar2-heart-fill::before {
  content: "\f711";
}

.bi-calendar2-heart::before {
  content: "\f712";
}

.bi-chat-heart-fill::before {
  content: "\f713";
}

.bi-chat-heart::before {
  content: "\f714";
}

.bi-chat-left-heart-fill::before {
  content: "\f715";
}

.bi-chat-left-heart::before {
  content: "\f716";
}

.bi-chat-right-heart-fill::before {
  content: "\f717";
}

.bi-chat-right-heart::before {
  content: "\f718";
}

.bi-chat-square-heart-fill::before {
  content: "\f719";
}

.bi-chat-square-heart::before {
  content: "\f71a";
}

.bi-clipboard-check-fill::before {
  content: "\f71b";
}

.bi-clipboard-data-fill::before {
  content: "\f71c";
}

.bi-clipboard-fill::before {
  content: "\f71d";
}

.bi-clipboard-heart-fill::before {
  content: "\f71e";
}

.bi-clipboard-heart::before {
  content: "\f71f";
}

.bi-clipboard-minus-fill::before {
  content: "\f720";
}

.bi-clipboard-plus-fill::before {
  content: "\f721";
}

.bi-clipboard-pulse::before {
  content: "\f722";
}

.bi-clipboard-x-fill::before {
  content: "\f723";
}

.bi-clipboard2-check-fill::before {
  content: "\f724";
}

.bi-clipboard2-check::before {
  content: "\f725";
}

.bi-clipboard2-data-fill::before {
  content: "\f726";
}

.bi-clipboard2-data::before {
  content: "\f727";
}

.bi-clipboard2-fill::before {
  content: "\f728";
}

.bi-clipboard2-heart-fill::before {
  content: "\f729";
}

.bi-clipboard2-heart::before {
  content: "\f72a";
}

.bi-clipboard2-minus-fill::before {
  content: "\f72b";
}

.bi-clipboard2-minus::before {
  content: "\f72c";
}

.bi-clipboard2-plus-fill::before {
  content: "\f72d";
}

.bi-clipboard2-plus::before {
  content: "\f72e";
}

.bi-clipboard2-pulse-fill::before {
  content: "\f72f";
}

.bi-clipboard2-pulse::before {
  content: "\f730";
}

.bi-clipboard2-x-fill::before {
  content: "\f731";
}

.bi-clipboard2-x::before {
  content: "\f732";
}

.bi-clipboard2::before {
  content: "\f733";
}

.bi-emoji-kiss-fill::before {
  content: "\f734";
}

.bi-emoji-kiss::before {
  content: "\f735";
}

.bi-envelope-heart-fill::before {
  content: "\f736";
}

.bi-envelope-heart::before {
  content: "\f737";
}

.bi-envelope-open-heart-fill::before {
  content: "\f738";
}

.bi-envelope-open-heart::before {
  content: "\f739";
}

.bi-envelope-paper-fill::before {
  content: "\f73a";
}

.bi-envelope-paper-heart-fill::before {
  content: "\f73b";
}

.bi-envelope-paper-heart::before {
  content: "\f73c";
}

.bi-envelope-paper::before {
  content: "\f73d";
}

.bi-filetype-aac::before {
  content: "\f73e";
}

.bi-filetype-ai::before {
  content: "\f73f";
}

.bi-filetype-bmp::before {
  content: "\f740";
}

.bi-filetype-cs::before {
  content: "\f741";
}

.bi-filetype-css::before {
  content: "\f742";
}

.bi-filetype-csv::before {
  content: "\f743";
}

.bi-filetype-doc::before {
  content: "\f744";
}

.bi-filetype-docx::before {
  content: "\f745";
}

.bi-filetype-exe::before {
  content: "\f746";
}

.bi-filetype-gif::before {
  content: "\f747";
}

.bi-filetype-heic::before {
  content: "\f748";
}

.bi-filetype-html::before {
  content: "\f749";
}

.bi-filetype-java::before {
  content: "\f74a";
}

.bi-filetype-jpg::before {
  content: "\f74b";
}

.bi-filetype-js::before {
  content: "\f74c";
}

.bi-filetype-jsx::before {
  content: "\f74d";
}

.bi-filetype-key::before {
  content: "\f74e";
}

.bi-filetype-m4p::before {
  content: "\f74f";
}

.bi-filetype-md::before {
  content: "\f750";
}

.bi-filetype-mdx::before {
  content: "\f751";
}

.bi-filetype-mov::before {
  content: "\f752";
}

.bi-filetype-mp3::before {
  content: "\f753";
}

.bi-filetype-mp4::before {
  content: "\f754";
}

.bi-filetype-otf::before {
  content: "\f755";
}

.bi-filetype-pdf::before {
  content: "\f756";
}

.bi-filetype-php::before {
  content: "\f757";
}

.bi-filetype-png::before {
  content: "\f758";
}

.bi-filetype-ppt::before {
  content: "\f75a";
}

.bi-filetype-psd::before {
  content: "\f75b";
}

.bi-filetype-py::before {
  content: "\f75c";
}

.bi-filetype-raw::before {
  content: "\f75d";
}

.bi-filetype-rb::before {
  content: "\f75e";
}

.bi-filetype-sass::before {
  content: "\f75f";
}

.bi-filetype-scss::before {
  content: "\f760";
}

.bi-filetype-sh::before {
  content: "\f761";
}

.bi-filetype-svg::before {
  content: "\f762";
}

.bi-filetype-tiff::before {
  content: "\f763";
}

.bi-filetype-tsx::before {
  content: "\f764";
}

.bi-filetype-ttf::before {
  content: "\f765";
}

.bi-filetype-txt::before {
  content: "\f766";
}

.bi-filetype-wav::before {
  content: "\f767";
}

.bi-filetype-woff::before {
  content: "\f768";
}

.bi-filetype-xls::before {
  content: "\f76a";
}

.bi-filetype-xml::before {
  content: "\f76b";
}

.bi-filetype-yml::before {
  content: "\f76c";
}

.bi-heart-arrow::before {
  content: "\f76d";
}

.bi-heart-pulse-fill::before {
  content: "\f76e";
}

.bi-heart-pulse::before {
  content: "\f76f";
}

.bi-heartbreak-fill::before {
  content: "\f770";
}

.bi-heartbreak::before {
  content: "\f771";
}

.bi-hearts::before {
  content: "\f772";
}

.bi-hospital-fill::before {
  content: "\f773";
}

.bi-hospital::before {
  content: "\f774";
}

.bi-house-heart-fill::before {
  content: "\f775";
}

.bi-house-heart::before {
  content: "\f776";
}

.bi-incognito::before {
  content: "\f777";
}

.bi-magnet-fill::before {
  content: "\f778";
}

.bi-magnet::before {
  content: "\f779";
}

.bi-person-heart::before {
  content: "\f77a";
}

.bi-person-hearts::before {
  content: "\f77b";
}

.bi-phone-flip::before {
  content: "\f77c";
}

.bi-plugin::before {
  content: "\f77d";
}

.bi-postage-fill::before {
  content: "\f77e";
}

.bi-postage-heart-fill::before {
  content: "\f77f";
}

.bi-postage-heart::before {
  content: "\f780";
}

.bi-postage::before {
  content: "\f781";
}

.bi-postcard-fill::before {
  content: "\f782";
}

.bi-postcard-heart-fill::before {
  content: "\f783";
}

.bi-postcard-heart::before {
  content: "\f784";
}

.bi-postcard::before {
  content: "\f785";
}

.bi-search-heart-fill::before {
  content: "\f786";
}

.bi-search-heart::before {
  content: "\f787";
}

.bi-sliders2-vertical::before {
  content: "\f788";
}

.bi-sliders2::before {
  content: "\f789";
}

.bi-trash3-fill::before {
  content: "\f78a";
}

.bi-trash3::before {
  content: "\f78b";
}

.bi-valentine::before {
  content: "\f78c";
}

.bi-valentine2::before {
  content: "\f78d";
}

.bi-wrench-adjustable-circle-fill::before {
  content: "\f78e";
}

.bi-wrench-adjustable-circle::before {
  content: "\f78f";
}

.bi-wrench-adjustable::before {
  content: "\f790";
}

.bi-filetype-json::before {
  content: "\f791";
}

.bi-filetype-pptx::before {
  content: "\f792";
}

.bi-filetype-xlsx::before {
  content: "\f793";
}

.bi-1-circle-fill::before {
  content: "\f796";
}

.bi-1-circle::before {
  content: "\f797";
}

.bi-1-square-fill::before {
  content: "\f798";
}

.bi-1-square::before {
  content: "\f799";
}

.bi-2-circle-fill::before {
  content: "\f79c";
}

.bi-2-circle::before {
  content: "\f79d";
}

.bi-2-square-fill::before {
  content: "\f79e";
}

.bi-2-square::before {
  content: "\f79f";
}

.bi-3-circle-fill::before {
  content: "\f7a2";
}

.bi-3-circle::before {
  content: "\f7a3";
}

.bi-3-square-fill::before {
  content: "\f7a4";
}

.bi-3-square::before {
  content: "\f7a5";
}

.bi-4-circle-fill::before {
  content: "\f7a8";
}

.bi-4-circle::before {
  content: "\f7a9";
}

.bi-4-square-fill::before {
  content: "\f7aa";
}

.bi-4-square::before {
  content: "\f7ab";
}

.bi-5-circle-fill::before {
  content: "\f7ae";
}

.bi-5-circle::before {
  content: "\f7af";
}

.bi-5-square-fill::before {
  content: "\f7b0";
}

.bi-5-square::before {
  content: "\f7b1";
}

.bi-6-circle-fill::before {
  content: "\f7b4";
}

.bi-6-circle::before {
  content: "\f7b5";
}

.bi-6-square-fill::before {
  content: "\f7b6";
}

.bi-6-square::before {
  content: "\f7b7";
}

.bi-7-circle-fill::before {
  content: "\f7ba";
}

.bi-7-circle::before {
  content: "\f7bb";
}

.bi-7-square-fill::before {
  content: "\f7bc";
}

.bi-7-square::before {
  content: "\f7bd";
}

.bi-8-circle-fill::before {
  content: "\f7c0";
}

.bi-8-circle::before {
  content: "\f7c1";
}

.bi-8-square-fill::before {
  content: "\f7c2";
}

.bi-8-square::before {
  content: "\f7c3";
}

.bi-9-circle-fill::before {
  content: "\f7c6";
}

.bi-9-circle::before {
  content: "\f7c7";
}

.bi-9-square-fill::before {
  content: "\f7c8";
}

.bi-9-square::before {
  content: "\f7c9";
}

.bi-airplane-engines-fill::before {
  content: "\f7ca";
}

.bi-airplane-engines::before {
  content: "\f7cb";
}

.bi-airplane-fill::before {
  content: "\f7cc";
}

.bi-airplane::before {
  content: "\f7cd";
}

.bi-alexa::before {
  content: "\f7ce";
}

.bi-alipay::before {
  content: "\f7cf";
}

.bi-android::before {
  content: "\f7d0";
}

.bi-android2::before {
  content: "\f7d1";
}

.bi-box-fill::before {
  content: "\f7d2";
}

.bi-box-seam-fill::before {
  content: "\f7d3";
}

.bi-browser-chrome::before {
  content: "\f7d4";
}

.bi-browser-edge::before {
  content: "\f7d5";
}

.bi-browser-firefox::before {
  content: "\f7d6";
}

.bi-browser-safari::before {
  content: "\f7d7";
}

.bi-c-circle-fill::before {
  content: "\f7da";
}

.bi-c-circle::before {
  content: "\f7db";
}

.bi-c-square-fill::before {
  content: "\f7dc";
}

.bi-c-square::before {
  content: "\f7dd";
}

.bi-capsule-pill::before {
  content: "\f7de";
}

.bi-capsule::before {
  content: "\f7df";
}

.bi-car-front-fill::before {
  content: "\f7e0";
}

.bi-car-front::before {
  content: "\f7e1";
}

.bi-cassette-fill::before {
  content: "\f7e2";
}

.bi-cassette::before {
  content: "\f7e3";
}

.bi-cc-circle-fill::before {
  content: "\f7e6";
}

.bi-cc-circle::before {
  content: "\f7e7";
}

.bi-cc-square-fill::before {
  content: "\f7e8";
}

.bi-cc-square::before {
  content: "\f7e9";
}

.bi-cup-hot-fill::before {
  content: "\f7ea";
}

.bi-cup-hot::before {
  content: "\f7eb";
}

.bi-currency-rupee::before {
  content: "\f7ec";
}

.bi-dropbox::before {
  content: "\f7ed";
}

.bi-escape::before {
  content: "\f7ee";
}

.bi-fast-forward-btn-fill::before {
  content: "\f7ef";
}

.bi-fast-forward-btn::before {
  content: "\f7f0";
}

.bi-fast-forward-circle-fill::before {
  content: "\f7f1";
}

.bi-fast-forward-circle::before {
  content: "\f7f2";
}

.bi-fast-forward-fill::before {
  content: "\f7f3";
}

.bi-fast-forward::before {
  content: "\f7f4";
}

.bi-filetype-sql::before {
  content: "\f7f5";
}

.bi-fire::before {
  content: "\f7f6";
}

.bi-google-play::before {
  content: "\f7f7";
}

.bi-h-circle-fill::before {
  content: "\f7fa";
}

.bi-h-circle::before {
  content: "\f7fb";
}

.bi-h-square-fill::before {
  content: "\f7fc";
}

.bi-h-square::before {
  content: "\f7fd";
}

.bi-indent::before {
  content: "\f7fe";
}

.bi-lungs-fill::before {
  content: "\f7ff";
}

.bi-lungs::before {
  content: "\f800";
}

.bi-microsoft-teams::before {
  content: "\f801";
}

.bi-p-circle-fill::before {
  content: "\f804";
}

.bi-p-circle::before {
  content: "\f805";
}

.bi-p-square-fill::before {
  content: "\f806";
}

.bi-p-square::before {
  content: "\f807";
}

.bi-pass-fill::before {
  content: "\f808";
}

.bi-pass::before {
  content: "\f809";
}

.bi-prescription::before {
  content: "\f80a";
}

.bi-prescription2::before {
  content: "\f80b";
}

.bi-r-circle-fill::before {
  content: "\f80e";
}

.bi-r-circle::before {
  content: "\f80f";
}

.bi-r-square-fill::before {
  content: "\f810";
}

.bi-r-square::before {
  content: "\f811";
}

.bi-repeat-1::before {
  content: "\f812";
}

.bi-repeat::before {
  content: "\f813";
}

.bi-rewind-btn-fill::before {
  content: "\f814";
}

.bi-rewind-btn::before {
  content: "\f815";
}

.bi-rewind-circle-fill::before {
  content: "\f816";
}

.bi-rewind-circle::before {
  content: "\f817";
}

.bi-rewind-fill::before {
  content: "\f818";
}

.bi-rewind::before {
  content: "\f819";
}

.bi-train-freight-front-fill::before {
  content: "\f81a";
}

.bi-train-freight-front::before {
  content: "\f81b";
}

.bi-train-front-fill::before {
  content: "\f81c";
}

.bi-train-front::before {
  content: "\f81d";
}

.bi-train-lightrail-front-fill::before {
  content: "\f81e";
}

.bi-train-lightrail-front::before {
  content: "\f81f";
}

.bi-truck-front-fill::before {
  content: "\f820";
}

.bi-truck-front::before {
  content: "\f821";
}

.bi-ubuntu::before {
  content: "\f822";
}

.bi-unindent::before {
  content: "\f823";
}

.bi-unity::before {
  content: "\f824";
}

.bi-universal-access-circle::before {
  content: "\f825";
}

.bi-universal-access::before {
  content: "\f826";
}

.bi-virus::before {
  content: "\f827";
}

.bi-virus2::before {
  content: "\f828";
}

.bi-wechat::before {
  content: "\f829";
}

.bi-yelp::before {
  content: "\f82a";
}

.bi-sign-stop-fill::before {
  content: "\f82b";
}

.bi-sign-stop-lights-fill::before {
  content: "\f82c";
}

.bi-sign-stop-lights::before {
  content: "\f82d";
}

.bi-sign-stop::before {
  content: "\f82e";
}

.bi-sign-turn-left-fill::before {
  content: "\f82f";
}

.bi-sign-turn-left::before {
  content: "\f830";
}

.bi-sign-turn-right-fill::before {
  content: "\f831";
}

.bi-sign-turn-right::before {
  content: "\f832";
}

.bi-sign-turn-slight-left-fill::before {
  content: "\f833";
}

.bi-sign-turn-slight-left::before {
  content: "\f834";
}

.bi-sign-turn-slight-right-fill::before {
  content: "\f835";
}

.bi-sign-turn-slight-right::before {
  content: "\f836";
}

.bi-sign-yield-fill::before {
  content: "\f837";
}

.bi-sign-yield::before {
  content: "\f838";
}

.bi-ev-station-fill::before {
  content: "\f839";
}

.bi-ev-station::before {
  content: "\f83a";
}

.bi-fuel-pump-diesel-fill::before {
  content: "\f83b";
}

.bi-fuel-pump-diesel::before {
  content: "\f83c";
}

.bi-fuel-pump-fill::before {
  content: "\f83d";
}

.bi-fuel-pump::before {
  content: "\f83e";
}

.bi-0-circle-fill::before {
  content: "\f83f";
}

.bi-0-circle::before {
  content: "\f840";
}

.bi-0-square-fill::before {
  content: "\f841";
}

.bi-0-square::before {
  content: "\f842";
}

.bi-rocket-fill::before {
  content: "\f843";
}

.bi-rocket-takeoff-fill::before {
  content: "\f844";
}

.bi-rocket-takeoff::before {
  content: "\f845";
}

.bi-rocket::before {
  content: "\f846";
}

.bi-stripe::before {
  content: "\f847";
}

.bi-subscript::before {
  content: "\f848";
}

.bi-superscript::before {
  content: "\f849";
}

.bi-trello::before {
  content: "\f84a";
}

.bi-envelope-at-fill::before {
  content: "\f84b";
}

.bi-envelope-at::before {
  content: "\f84c";
}

.bi-regex::before {
  content: "\f84d";
}

.bi-text-wrap::before {
  content: "\f84e";
}

.bi-sign-dead-end-fill::before {
  content: "\f84f";
}

.bi-sign-dead-end::before {
  content: "\f850";
}

.bi-sign-do-not-enter-fill::before {
  content: "\f851";
}

.bi-sign-do-not-enter::before {
  content: "\f852";
}

.bi-sign-intersection-fill::before {
  content: "\f853";
}

.bi-sign-intersection-side-fill::before {
  content: "\f854";
}

.bi-sign-intersection-side::before {
  content: "\f855";
}

.bi-sign-intersection-t-fill::before {
  content: "\f856";
}

.bi-sign-intersection-t::before {
  content: "\f857";
}

.bi-sign-intersection-y-fill::before {
  content: "\f858";
}

.bi-sign-intersection-y::before {
  content: "\f859";
}

.bi-sign-intersection::before {
  content: "\f85a";
}

.bi-sign-merge-left-fill::before {
  content: "\f85b";
}

.bi-sign-merge-left::before {
  content: "\f85c";
}

.bi-sign-merge-right-fill::before {
  content: "\f85d";
}

.bi-sign-merge-right::before {
  content: "\f85e";
}

.bi-sign-no-left-turn-fill::before {
  content: "\f85f";
}

.bi-sign-no-left-turn::before {
  content: "\f860";
}

.bi-sign-no-parking-fill::before {
  content: "\f861";
}

.bi-sign-no-parking::before {
  content: "\f862";
}

.bi-sign-no-right-turn-fill::before {
  content: "\f863";
}

.bi-sign-no-right-turn::before {
  content: "\f864";
}

.bi-sign-railroad-fill::before {
  content: "\f865";
}

.bi-sign-railroad::before {
  content: "\f866";
}

.bi-building-add::before {
  content: "\f867";
}

.bi-building-check::before {
  content: "\f868";
}

.bi-building-dash::before {
  content: "\f869";
}

.bi-building-down::before {
  content: "\f86a";
}

.bi-building-exclamation::before {
  content: "\f86b";
}

.bi-building-fill-add::before {
  content: "\f86c";
}

.bi-building-fill-check::before {
  content: "\f86d";
}

.bi-building-fill-dash::before {
  content: "\f86e";
}

.bi-building-fill-down::before {
  content: "\f86f";
}

.bi-building-fill-exclamation::before {
  content: "\f870";
}

.bi-building-fill-gear::before {
  content: "\f871";
}

.bi-building-fill-lock::before {
  content: "\f872";
}

.bi-building-fill-slash::before {
  content: "\f873";
}

.bi-building-fill-up::before {
  content: "\f874";
}

.bi-building-fill-x::before {
  content: "\f875";
}

.bi-building-fill::before {
  content: "\f876";
}

.bi-building-gear::before {
  content: "\f877";
}

.bi-building-lock::before {
  content: "\f878";
}

.bi-building-slash::before {
  content: "\f879";
}

.bi-building-up::before {
  content: "\f87a";
}

.bi-building-x::before {
  content: "\f87b";
}

.bi-buildings-fill::before {
  content: "\f87c";
}

.bi-buildings::before {
  content: "\f87d";
}

.bi-bus-front-fill::before {
  content: "\f87e";
}

.bi-bus-front::before {
  content: "\f87f";
}

.bi-ev-front-fill::before {
  content: "\f880";
}

.bi-ev-front::before {
  content: "\f881";
}

.bi-globe-americas::before {
  content: "\f882";
}

.bi-globe-asia-australia::before {
  content: "\f883";
}

.bi-globe-central-south-asia::before {
  content: "\f884";
}

.bi-globe-europe-africa::before {
  content: "\f885";
}

.bi-house-add-fill::before {
  content: "\f886";
}

.bi-house-add::before {
  content: "\f887";
}

.bi-house-check-fill::before {
  content: "\f888";
}

.bi-house-check::before {
  content: "\f889";
}

.bi-house-dash-fill::before {
  content: "\f88a";
}

.bi-house-dash::before {
  content: "\f88b";
}

.bi-house-down-fill::before {
  content: "\f88c";
}

.bi-house-down::before {
  content: "\f88d";
}

.bi-house-exclamation-fill::before {
  content: "\f88e";
}

.bi-house-exclamation::before {
  content: "\f88f";
}

.bi-house-gear-fill::before {
  content: "\f890";
}

.bi-house-gear::before {
  content: "\f891";
}

.bi-house-lock-fill::before {
  content: "\f892";
}

.bi-house-lock::before {
  content: "\f893";
}

.bi-house-slash-fill::before {
  content: "\f894";
}

.bi-house-slash::before {
  content: "\f895";
}

.bi-house-up-fill::before {
  content: "\f896";
}

.bi-house-up::before {
  content: "\f897";
}

.bi-house-x-fill::before {
  content: "\f898";
}

.bi-house-x::before {
  content: "\f899";
}

.bi-person-add::before {
  content: "\f89a";
}

.bi-person-down::before {
  content: "\f89b";
}

.bi-person-exclamation::before {
  content: "\f89c";
}

.bi-person-fill-add::before {
  content: "\f89d";
}

.bi-person-fill-check::before {
  content: "\f89e";
}

.bi-person-fill-dash::before {
  content: "\f89f";
}

.bi-person-fill-down::before {
  content: "\f8a0";
}

.bi-person-fill-exclamation::before {
  content: "\f8a1";
}

.bi-person-fill-gear::before {
  content: "\f8a2";
}

.bi-person-fill-lock::before {
  content: "\f8a3";
}

.bi-person-fill-slash::before {
  content: "\f8a4";
}

.bi-person-fill-up::before {
  content: "\f8a5";
}

.bi-person-fill-x::before {
  content: "\f8a6";
}

.bi-person-gear::before {
  content: "\f8a7";
}

.bi-person-lock::before {
  content: "\f8a8";
}

.bi-person-slash::before {
  content: "\f8a9";
}

.bi-person-up::before {
  content: "\f8aa";
}

.bi-scooter::before {
  content: "\f8ab";
}

.bi-taxi-front-fill::before {
  content: "\f8ac";
}

.bi-taxi-front::before {
  content: "\f8ad";
}

.bi-amd::before {
  content: "\f8ae";
}

.bi-database-add::before {
  content: "\f8af";
}

.bi-database-check::before {
  content: "\f8b0";
}

.bi-database-dash::before {
  content: "\f8b1";
}

.bi-database-down::before {
  content: "\f8b2";
}

.bi-database-exclamation::before {
  content: "\f8b3";
}

.bi-database-fill-add::before {
  content: "\f8b4";
}

.bi-database-fill-check::before {
  content: "\f8b5";
}

.bi-database-fill-dash::before {
  content: "\f8b6";
}

.bi-database-fill-down::before {
  content: "\f8b7";
}

.bi-database-fill-exclamation::before {
  content: "\f8b8";
}

.bi-database-fill-gear::before {
  content: "\f8b9";
}

.bi-database-fill-lock::before {
  content: "\f8ba";
}

.bi-database-fill-slash::before {
  content: "\f8bb";
}

.bi-database-fill-up::before {
  content: "\f8bc";
}

.bi-database-fill-x::before {
  content: "\f8bd";
}

.bi-database-fill::before {
  content: "\f8be";
}

.bi-database-gear::before {
  content: "\f8bf";
}

.bi-database-lock::before {
  content: "\f8c0";
}

.bi-database-slash::before {
  content: "\f8c1";
}

.bi-database-up::before {
  content: "\f8c2";
}

.bi-database-x::before {
  content: "\f8c3";
}

.bi-database::before {
  content: "\f8c4";
}

.bi-houses-fill::before {
  content: "\f8c5";
}

.bi-houses::before {
  content: "\f8c6";
}

.bi-nvidia::before {
  content: "\f8c7";
}

.bi-person-vcard-fill::before {
  content: "\f8c8";
}

.bi-person-vcard::before {
  content: "\f8c9";
}

.bi-sina-weibo::before {
  content: "\f8ca";
}

.bi-tencent-qq::before {
  content: "\f8cb";
}

.bi-wikipedia::before {
  content: "\f8cc";
}

.bi-alphabet-uppercase::before {
  content: "\f2a5";
}

.bi-alphabet::before {
  content: "\f68a";
}

.bi-amazon::before {
  content: "\f68d";
}

.bi-arrows-collapse-vertical::before {
  content: "\f690";
}

.bi-arrows-expand-vertical::before {
  content: "\f695";
}

.bi-arrows-vertical::before {
  content: "\f698";
}

.bi-arrows::before {
  content: "\f6a2";
}

.bi-ban-fill::before {
  content: "\f6a3";
}

.bi-ban::before {
  content: "\f6b6";
}

.bi-bing::before {
  content: "\f6c2";
}

.bi-cake::before {
  content: "\f6e0";
}

.bi-cake2::before {
  content: "\f6ed";
}

.bi-cookie::before {
  content: "\f6ee";
}

.bi-copy::before {
  content: "\f759";
}

.bi-crosshair::before {
  content: "\f769";
}

.bi-crosshair2::before {
  content: "\f794";
}

.bi-emoji-astonished-fill::before {
  content: "\f795";
}

.bi-emoji-astonished::before {
  content: "\f79a";
}

.bi-emoji-grimace-fill::before {
  content: "\f79b";
}

.bi-emoji-grimace::before {
  content: "\f7a0";
}

.bi-emoji-grin-fill::before {
  content: "\f7a1";
}

.bi-emoji-grin::before {
  content: "\f7a6";
}

.bi-emoji-surprise-fill::before {
  content: "\f7a7";
}

.bi-emoji-surprise::before {
  content: "\f7ac";
}

.bi-emoji-tear-fill::before {
  content: "\f7ad";
}

.bi-emoji-tear::before {
  content: "\f7b2";
}

.bi-envelope-arrow-down-fill::before {
  content: "\f7b3";
}

.bi-envelope-arrow-down::before {
  content: "\f7b8";
}

.bi-envelope-arrow-up-fill::before {
  content: "\f7b9";
}

.bi-envelope-arrow-up::before {
  content: "\f7be";
}

.bi-feather::before {
  content: "\f7bf";
}

.bi-feather2::before {
  content: "\f7c4";
}

.bi-floppy-fill::before {
  content: "\f7c5";
}

.bi-floppy::before {
  content: "\f7d8";
}

.bi-floppy2-fill::before {
  content: "\f7d9";
}

.bi-floppy2::before {
  content: "\f7e4";
}

.bi-gitlab::before {
  content: "\f7e5";
}

.bi-highlighter::before {
  content: "\f7f8";
}

.bi-marker-tip::before {
  content: "\f802";
}

.bi-nvme-fill::before {
  content: "\f803";
}

.bi-nvme::before {
  content: "\f80c";
}

.bi-opencollective::before {
  content: "\f80d";
}

.bi-pci-card-network::before {
  content: "\f8cd";
}

.bi-pci-card-sound::before {
  content: "\f8ce";
}

.bi-radar::before {
  content: "\f8cf";
}

.bi-send-arrow-down-fill::before {
  content: "\f8d0";
}

.bi-send-arrow-down::before {
  content: "\f8d1";
}

.bi-send-arrow-up-fill::before {
  content: "\f8d2";
}

.bi-send-arrow-up::before {
  content: "\f8d3";
}

.bi-sim-slash-fill::before {
  content: "\f8d4";
}

.bi-sim-slash::before {
  content: "\f8d5";
}

.bi-sourceforge::before {
  content: "\f8d6";
}

.bi-substack::before {
  content: "\f8d7";
}

.bi-threads-fill::before {
  content: "\f8d8";
}

.bi-threads::before {
  content: "\f8d9";
}

.bi-transparency::before {
  content: "\f8da";
}

.bi-twitter-x::before {
  content: "\f8db";
}

.bi-type-h4::before {
  content: "\f8dc";
}

.bi-type-h5::before {
  content: "\f8dd";
}

.bi-type-h6::before {
  content: "\f8de";
}

.bi-backpack-fill::before {
  content: "\f8df";
}

.bi-backpack::before {
  content: "\f8e0";
}

.bi-backpack2-fill::before {
  content: "\f8e1";
}

.bi-backpack2::before {
  content: "\f8e2";
}

.bi-backpack3-fill::before {
  content: "\f8e3";
}

.bi-backpack3::before {
  content: "\f8e4";
}

.bi-backpack4-fill::before {
  content: "\f8e5";
}

.bi-backpack4::before {
  content: "\f8e6";
}

.bi-brilliance::before {
  content: "\f8e7";
}

.bi-cake-fill::before {
  content: "\f8e8";
}

.bi-cake2-fill::before {
  content: "\f8e9";
}

.bi-duffle-fill::before {
  content: "\f8ea";
}

.bi-duffle::before {
  content: "\f8eb";
}

.bi-exposure::before {
  content: "\f8ec";
}

.bi-gender-neuter::before {
  content: "\f8ed";
}

.bi-highlights::before {
  content: "\f8ee";
}

.bi-luggage-fill::before {
  content: "\f8ef";
}

.bi-luggage::before {
  content: "\f8f0";
}

.bi-mailbox-flag::before {
  content: "\f8f1";
}

.bi-mailbox2-flag::before {
  content: "\f8f2";
}

.bi-noise-reduction::before {
  content: "\f8f3";
}

.bi-passport-fill::before {
  content: "\f8f4";
}

.bi-passport::before {
  content: "\f8f5";
}

.bi-person-arms-up::before {
  content: "\f8f6";
}

.bi-person-raised-hand::before {
  content: "\f8f7";
}

.bi-person-standing-dress::before {
  content: "\f8f8";
}

.bi-person-standing::before {
  content: "\f8f9";
}

.bi-person-walking::before {
  content: "\f8fa";
}

.bi-person-wheelchair::before {
  content: "\f8fb";
}

.bi-shadows::before {
  content: "\f8fc";
}

.bi-suitcase-fill::before {
  content: "\f8fd";
}

.bi-suitcase-lg-fill::before {
  content: "\f8fe";
}

.bi-suitcase-lg::before {
  content: "\f8ff";
}

.bi-suitcase::before {
  content: "豈";
}

.bi-suitcase2-fill::before {
  content: "更";
}

.bi-suitcase2::before {
  content: "車";
}

.bi-vignette::before {
  content: "賈";
}

@font-face {
  font-family: "Phosphor";
  src: url("./Phosphor.woff2") format("woff2"), url("./Phosphor.woff") format("woff"), url("./Phosphor.ttf") format("truetype"), url("./Phosphor.svg#Phosphor") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.ph, i.icon-dead, i.icon-alive, i.icon-warn, i.icon-sign-out, i.icon-substratum, i.icon-environment, i.icon-user, i.icon-person, i.icon-terrain, i.icon-taxon, i.icon-unknown, i.icon-nothing, i.icon-sampling, i.icon-isolation, i.icon-strain, i.icon-gps, i.icon-location, i.icon-add, i.icon-left, i.icon-dna, i.icon-delete, i.icon-edit, i.icon-info {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Phosphor" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ph.ph-acorn:before, i.ph-acorn.icon-dead:before, i.ph-acorn.icon-alive:before, i.ph-acorn.icon-warn:before, i.ph-acorn.icon-sign-out:before, i.ph-acorn.icon-substratum:before, i.ph-acorn.icon-environment:before, i.ph-acorn.icon-user:before, i.ph-acorn.icon-person:before, i.ph-acorn.icon-terrain:before, i.ph-acorn.icon-taxon:before, i.ph-acorn.icon-unknown:before, i.ph-acorn.icon-nothing:before, i.ph-acorn.icon-sampling:before, i.ph-acorn.icon-isolation:before, i.ph-acorn.icon-strain:before, i.ph-acorn.icon-gps:before, i.ph-acorn.icon-location:before, i.ph-acorn.icon-add:before, i.ph-acorn.icon-left:before, i.ph-acorn.icon-dna:before, i.ph-acorn.icon-delete:before, i.ph-acorn.icon-edit:before, i.ph-acorn.icon-info:before {
  content: "\eb9a";
}

.ph.ph-address-book:before, i.ph-address-book.icon-dead:before, i.ph-address-book.icon-alive:before, i.ph-address-book.icon-warn:before, i.ph-address-book.icon-sign-out:before, i.ph-address-book.icon-substratum:before, i.ph-address-book.icon-environment:before, i.ph-address-book.icon-user:before, i.ph-address-book.icon-person:before, i.ph-address-book.icon-terrain:before, i.ph-address-book.icon-taxon:before, i.ph-address-book.icon-unknown:before, i.ph-address-book.icon-nothing:before, i.ph-address-book.icon-sampling:before, i.ph-address-book.icon-isolation:before, i.ph-address-book.icon-strain:before, i.ph-address-book.icon-gps:before, i.ph-address-book.icon-location:before, i.ph-address-book.icon-add:before, i.ph-address-book.icon-left:before, i.ph-address-book.icon-dna:before, i.ph-address-book.icon-delete:before, i.ph-address-book.icon-edit:before, i.ph-address-book.icon-info:before {
  content: "\e6f8";
}

.ph.ph-address-book-tabs:before, i.ph-address-book-tabs.icon-dead:before, i.ph-address-book-tabs.icon-alive:before, i.ph-address-book-tabs.icon-warn:before, i.ph-address-book-tabs.icon-sign-out:before, i.ph-address-book-tabs.icon-substratum:before, i.ph-address-book-tabs.icon-environment:before, i.ph-address-book-tabs.icon-user:before, i.ph-address-book-tabs.icon-person:before, i.ph-address-book-tabs.icon-terrain:before, i.ph-address-book-tabs.icon-taxon:before, i.ph-address-book-tabs.icon-unknown:before, i.ph-address-book-tabs.icon-nothing:before, i.ph-address-book-tabs.icon-sampling:before, i.ph-address-book-tabs.icon-isolation:before, i.ph-address-book-tabs.icon-strain:before, i.ph-address-book-tabs.icon-gps:before, i.ph-address-book-tabs.icon-location:before, i.ph-address-book-tabs.icon-add:before, i.ph-address-book-tabs.icon-left:before, i.ph-address-book-tabs.icon-dna:before, i.ph-address-book-tabs.icon-delete:before, i.ph-address-book-tabs.icon-edit:before, i.ph-address-book-tabs.icon-info:before {
  content: "\ee4e";
}

.ph.ph-air-traffic-control:before, i.ph-air-traffic-control.icon-dead:before, i.ph-air-traffic-control.icon-alive:before, i.ph-air-traffic-control.icon-warn:before, i.ph-air-traffic-control.icon-sign-out:before, i.ph-air-traffic-control.icon-substratum:before, i.ph-air-traffic-control.icon-environment:before, i.ph-air-traffic-control.icon-user:before, i.ph-air-traffic-control.icon-person:before, i.ph-air-traffic-control.icon-terrain:before, i.ph-air-traffic-control.icon-taxon:before, i.ph-air-traffic-control.icon-unknown:before, i.ph-air-traffic-control.icon-nothing:before, i.ph-air-traffic-control.icon-sampling:before, i.ph-air-traffic-control.icon-isolation:before, i.ph-air-traffic-control.icon-strain:before, i.ph-air-traffic-control.icon-gps:before, i.ph-air-traffic-control.icon-location:before, i.ph-air-traffic-control.icon-add:before, i.ph-air-traffic-control.icon-left:before, i.ph-air-traffic-control.icon-dna:before, i.ph-air-traffic-control.icon-delete:before, i.ph-air-traffic-control.icon-edit:before, i.ph-air-traffic-control.icon-info:before {
  content: "\ecd8";
}

.ph.ph-airplane:before, i.ph-airplane.icon-dead:before, i.ph-airplane.icon-alive:before, i.ph-airplane.icon-warn:before, i.ph-airplane.icon-sign-out:before, i.ph-airplane.icon-substratum:before, i.ph-airplane.icon-environment:before, i.ph-airplane.icon-user:before, i.ph-airplane.icon-person:before, i.ph-airplane.icon-terrain:before, i.ph-airplane.icon-taxon:before, i.ph-airplane.icon-unknown:before, i.ph-airplane.icon-nothing:before, i.ph-airplane.icon-sampling:before, i.ph-airplane.icon-isolation:before, i.ph-airplane.icon-strain:before, i.ph-airplane.icon-gps:before, i.ph-airplane.icon-location:before, i.ph-airplane.icon-add:before, i.ph-airplane.icon-left:before, i.ph-airplane.icon-dna:before, i.ph-airplane.icon-delete:before, i.ph-airplane.icon-edit:before, i.ph-airplane.icon-info:before {
  content: "\e002";
}

.ph.ph-airplane-in-flight:before, i.ph-airplane-in-flight.icon-dead:before, i.ph-airplane-in-flight.icon-alive:before, i.ph-airplane-in-flight.icon-warn:before, i.ph-airplane-in-flight.icon-sign-out:before, i.ph-airplane-in-flight.icon-substratum:before, i.ph-airplane-in-flight.icon-environment:before, i.ph-airplane-in-flight.icon-user:before, i.ph-airplane-in-flight.icon-person:before, i.ph-airplane-in-flight.icon-terrain:before, i.ph-airplane-in-flight.icon-taxon:before, i.ph-airplane-in-flight.icon-unknown:before, i.ph-airplane-in-flight.icon-nothing:before, i.ph-airplane-in-flight.icon-sampling:before, i.ph-airplane-in-flight.icon-isolation:before, i.ph-airplane-in-flight.icon-strain:before, i.ph-airplane-in-flight.icon-gps:before, i.ph-airplane-in-flight.icon-location:before, i.ph-airplane-in-flight.icon-add:before, i.ph-airplane-in-flight.icon-left:before, i.ph-airplane-in-flight.icon-dna:before, i.ph-airplane-in-flight.icon-delete:before, i.ph-airplane-in-flight.icon-edit:before, i.ph-airplane-in-flight.icon-info:before {
  content: "\e4fe";
}

.ph.ph-airplane-landing:before, i.ph-airplane-landing.icon-dead:before, i.ph-airplane-landing.icon-alive:before, i.ph-airplane-landing.icon-warn:before, i.ph-airplane-landing.icon-sign-out:before, i.ph-airplane-landing.icon-substratum:before, i.ph-airplane-landing.icon-environment:before, i.ph-airplane-landing.icon-user:before, i.ph-airplane-landing.icon-person:before, i.ph-airplane-landing.icon-terrain:before, i.ph-airplane-landing.icon-taxon:before, i.ph-airplane-landing.icon-unknown:before, i.ph-airplane-landing.icon-nothing:before, i.ph-airplane-landing.icon-sampling:before, i.ph-airplane-landing.icon-isolation:before, i.ph-airplane-landing.icon-strain:before, i.ph-airplane-landing.icon-gps:before, i.ph-airplane-landing.icon-location:before, i.ph-airplane-landing.icon-add:before, i.ph-airplane-landing.icon-left:before, i.ph-airplane-landing.icon-dna:before, i.ph-airplane-landing.icon-delete:before, i.ph-airplane-landing.icon-edit:before, i.ph-airplane-landing.icon-info:before {
  content: "\e502";
}

.ph.ph-airplane-takeoff:before, i.ph-airplane-takeoff.icon-dead:before, i.ph-airplane-takeoff.icon-alive:before, i.ph-airplane-takeoff.icon-warn:before, i.ph-airplane-takeoff.icon-sign-out:before, i.ph-airplane-takeoff.icon-substratum:before, i.ph-airplane-takeoff.icon-environment:before, i.ph-airplane-takeoff.icon-user:before, i.ph-airplane-takeoff.icon-person:before, i.ph-airplane-takeoff.icon-terrain:before, i.ph-airplane-takeoff.icon-taxon:before, i.ph-airplane-takeoff.icon-unknown:before, i.ph-airplane-takeoff.icon-nothing:before, i.ph-airplane-takeoff.icon-sampling:before, i.ph-airplane-takeoff.icon-isolation:before, i.ph-airplane-takeoff.icon-strain:before, i.ph-airplane-takeoff.icon-gps:before, i.ph-airplane-takeoff.icon-location:before, i.ph-airplane-takeoff.icon-add:before, i.ph-airplane-takeoff.icon-left:before, i.ph-airplane-takeoff.icon-dna:before, i.ph-airplane-takeoff.icon-delete:before, i.ph-airplane-takeoff.icon-edit:before, i.ph-airplane-takeoff.icon-info:before {
  content: "\e504";
}

.ph.ph-airplane-taxiing:before, i.ph-airplane-taxiing.icon-dead:before, i.ph-airplane-taxiing.icon-alive:before, i.ph-airplane-taxiing.icon-warn:before, i.ph-airplane-taxiing.icon-sign-out:before, i.ph-airplane-taxiing.icon-substratum:before, i.ph-airplane-taxiing.icon-environment:before, i.ph-airplane-taxiing.icon-user:before, i.ph-airplane-taxiing.icon-person:before, i.ph-airplane-taxiing.icon-terrain:before, i.ph-airplane-taxiing.icon-taxon:before, i.ph-airplane-taxiing.icon-unknown:before, i.ph-airplane-taxiing.icon-nothing:before, i.ph-airplane-taxiing.icon-sampling:before, i.ph-airplane-taxiing.icon-isolation:before, i.ph-airplane-taxiing.icon-strain:before, i.ph-airplane-taxiing.icon-gps:before, i.ph-airplane-taxiing.icon-location:before, i.ph-airplane-taxiing.icon-add:before, i.ph-airplane-taxiing.icon-left:before, i.ph-airplane-taxiing.icon-dna:before, i.ph-airplane-taxiing.icon-delete:before, i.ph-airplane-taxiing.icon-edit:before, i.ph-airplane-taxiing.icon-info:before {
  content: "\e500";
}

.ph.ph-airplane-tilt:before, i.ph-airplane-tilt.icon-dead:before, i.ph-airplane-tilt.icon-alive:before, i.ph-airplane-tilt.icon-warn:before, i.ph-airplane-tilt.icon-sign-out:before, i.ph-airplane-tilt.icon-substratum:before, i.ph-airplane-tilt.icon-environment:before, i.ph-airplane-tilt.icon-user:before, i.ph-airplane-tilt.icon-person:before, i.ph-airplane-tilt.icon-terrain:before, i.ph-airplane-tilt.icon-taxon:before, i.ph-airplane-tilt.icon-unknown:before, i.ph-airplane-tilt.icon-nothing:before, i.ph-airplane-tilt.icon-sampling:before, i.ph-airplane-tilt.icon-isolation:before, i.ph-airplane-tilt.icon-strain:before, i.ph-airplane-tilt.icon-gps:before, i.ph-airplane-tilt.icon-location:before, i.ph-airplane-tilt.icon-add:before, i.ph-airplane-tilt.icon-left:before, i.ph-airplane-tilt.icon-dna:before, i.ph-airplane-tilt.icon-delete:before, i.ph-airplane-tilt.icon-edit:before, i.ph-airplane-tilt.icon-info:before {
  content: "\e5d6";
}

.ph.ph-airplay:before, i.ph-airplay.icon-dead:before, i.ph-airplay.icon-alive:before, i.ph-airplay.icon-warn:before, i.ph-airplay.icon-sign-out:before, i.ph-airplay.icon-substratum:before, i.ph-airplay.icon-environment:before, i.ph-airplay.icon-user:before, i.ph-airplay.icon-person:before, i.ph-airplay.icon-terrain:before, i.ph-airplay.icon-taxon:before, i.ph-airplay.icon-unknown:before, i.ph-airplay.icon-nothing:before, i.ph-airplay.icon-sampling:before, i.ph-airplay.icon-isolation:before, i.ph-airplay.icon-strain:before, i.ph-airplay.icon-gps:before, i.ph-airplay.icon-location:before, i.ph-airplay.icon-add:before, i.ph-airplay.icon-left:before, i.ph-airplay.icon-dna:before, i.ph-airplay.icon-delete:before, i.ph-airplay.icon-edit:before, i.ph-airplay.icon-info:before {
  content: "\e004";
}

.ph.ph-alarm:before, i.ph-alarm.icon-dead:before, i.ph-alarm.icon-alive:before, i.ph-alarm.icon-warn:before, i.ph-alarm.icon-sign-out:before, i.ph-alarm.icon-substratum:before, i.ph-alarm.icon-environment:before, i.ph-alarm.icon-user:before, i.ph-alarm.icon-person:before, i.ph-alarm.icon-terrain:before, i.ph-alarm.icon-taxon:before, i.ph-alarm.icon-unknown:before, i.ph-alarm.icon-nothing:before, i.ph-alarm.icon-sampling:before, i.ph-alarm.icon-isolation:before, i.ph-alarm.icon-strain:before, i.ph-alarm.icon-gps:before, i.ph-alarm.icon-location:before, i.ph-alarm.icon-add:before, i.ph-alarm.icon-left:before, i.ph-alarm.icon-dna:before, i.ph-alarm.icon-delete:before, i.ph-alarm.icon-edit:before, i.ph-alarm.icon-info:before {
  content: "\e006";
}

.ph.ph-alien:before, i.ph-alien.icon-dead:before, i.ph-alien.icon-alive:before, i.ph-alien.icon-warn:before, i.ph-alien.icon-sign-out:before, i.ph-alien.icon-substratum:before, i.ph-alien.icon-environment:before, i.ph-alien.icon-user:before, i.ph-alien.icon-person:before, i.ph-alien.icon-terrain:before, i.ph-alien.icon-taxon:before, i.ph-alien.icon-unknown:before, i.ph-alien.icon-nothing:before, i.ph-alien.icon-sampling:before, i.ph-alien.icon-isolation:before, i.ph-alien.icon-strain:before, i.ph-alien.icon-gps:before, i.ph-alien.icon-location:before, i.ph-alien.icon-add:before, i.ph-alien.icon-left:before, i.ph-alien.icon-dna:before, i.ph-alien.icon-delete:before, i.ph-alien.icon-edit:before, i.ph-alien.icon-info:before {
  content: "\e8a6";
}

.ph.ph-align-bottom:before, i.ph-align-bottom.icon-dead:before, i.ph-align-bottom.icon-alive:before, i.ph-align-bottom.icon-warn:before, i.ph-align-bottom.icon-sign-out:before, i.ph-align-bottom.icon-substratum:before, i.ph-align-bottom.icon-environment:before, i.ph-align-bottom.icon-user:before, i.ph-align-bottom.icon-person:before, i.ph-align-bottom.icon-terrain:before, i.ph-align-bottom.icon-taxon:before, i.ph-align-bottom.icon-unknown:before, i.ph-align-bottom.icon-nothing:before, i.ph-align-bottom.icon-sampling:before, i.ph-align-bottom.icon-isolation:before, i.ph-align-bottom.icon-strain:before, i.ph-align-bottom.icon-gps:before, i.ph-align-bottom.icon-location:before, i.ph-align-bottom.icon-add:before, i.ph-align-bottom.icon-left:before, i.ph-align-bottom.icon-dna:before, i.ph-align-bottom.icon-delete:before, i.ph-align-bottom.icon-edit:before, i.ph-align-bottom.icon-info:before {
  content: "\e506";
}

.ph.ph-align-bottom-simple:before, i.ph-align-bottom-simple.icon-dead:before, i.ph-align-bottom-simple.icon-alive:before, i.ph-align-bottom-simple.icon-warn:before, i.ph-align-bottom-simple.icon-sign-out:before, i.ph-align-bottom-simple.icon-substratum:before, i.ph-align-bottom-simple.icon-environment:before, i.ph-align-bottom-simple.icon-user:before, i.ph-align-bottom-simple.icon-person:before, i.ph-align-bottom-simple.icon-terrain:before, i.ph-align-bottom-simple.icon-taxon:before, i.ph-align-bottom-simple.icon-unknown:before, i.ph-align-bottom-simple.icon-nothing:before, i.ph-align-bottom-simple.icon-sampling:before, i.ph-align-bottom-simple.icon-isolation:before, i.ph-align-bottom-simple.icon-strain:before, i.ph-align-bottom-simple.icon-gps:before, i.ph-align-bottom-simple.icon-location:before, i.ph-align-bottom-simple.icon-add:before, i.ph-align-bottom-simple.icon-left:before, i.ph-align-bottom-simple.icon-dna:before, i.ph-align-bottom-simple.icon-delete:before, i.ph-align-bottom-simple.icon-edit:before, i.ph-align-bottom-simple.icon-info:before {
  content: "\eb0c";
}

.ph.ph-align-center-horizontal:before, i.ph-align-center-horizontal.icon-dead:before, i.ph-align-center-horizontal.icon-alive:before, i.ph-align-center-horizontal.icon-warn:before, i.ph-align-center-horizontal.icon-sign-out:before, i.ph-align-center-horizontal.icon-substratum:before, i.ph-align-center-horizontal.icon-environment:before, i.ph-align-center-horizontal.icon-user:before, i.ph-align-center-horizontal.icon-person:before, i.ph-align-center-horizontal.icon-terrain:before, i.ph-align-center-horizontal.icon-taxon:before, i.ph-align-center-horizontal.icon-unknown:before, i.ph-align-center-horizontal.icon-nothing:before, i.ph-align-center-horizontal.icon-sampling:before, i.ph-align-center-horizontal.icon-isolation:before, i.ph-align-center-horizontal.icon-strain:before, i.ph-align-center-horizontal.icon-gps:before, i.ph-align-center-horizontal.icon-location:before, i.ph-align-center-horizontal.icon-add:before, i.ph-align-center-horizontal.icon-left:before, i.ph-align-center-horizontal.icon-dna:before, i.ph-align-center-horizontal.icon-delete:before, i.ph-align-center-horizontal.icon-edit:before, i.ph-align-center-horizontal.icon-info:before {
  content: "\e50a";
}

.ph.ph-align-center-horizontal-simple:before, i.ph-align-center-horizontal-simple.icon-dead:before, i.ph-align-center-horizontal-simple.icon-alive:before, i.ph-align-center-horizontal-simple.icon-warn:before, i.ph-align-center-horizontal-simple.icon-sign-out:before, i.ph-align-center-horizontal-simple.icon-substratum:before, i.ph-align-center-horizontal-simple.icon-environment:before, i.ph-align-center-horizontal-simple.icon-user:before, i.ph-align-center-horizontal-simple.icon-person:before, i.ph-align-center-horizontal-simple.icon-terrain:before, i.ph-align-center-horizontal-simple.icon-taxon:before, i.ph-align-center-horizontal-simple.icon-unknown:before, i.ph-align-center-horizontal-simple.icon-nothing:before, i.ph-align-center-horizontal-simple.icon-sampling:before, i.ph-align-center-horizontal-simple.icon-isolation:before, i.ph-align-center-horizontal-simple.icon-strain:before, i.ph-align-center-horizontal-simple.icon-gps:before, i.ph-align-center-horizontal-simple.icon-location:before, i.ph-align-center-horizontal-simple.icon-add:before, i.ph-align-center-horizontal-simple.icon-left:before, i.ph-align-center-horizontal-simple.icon-dna:before, i.ph-align-center-horizontal-simple.icon-delete:before, i.ph-align-center-horizontal-simple.icon-edit:before, i.ph-align-center-horizontal-simple.icon-info:before {
  content: "\eb0e";
}

.ph.ph-align-center-vertical:before, i.ph-align-center-vertical.icon-dead:before, i.ph-align-center-vertical.icon-alive:before, i.ph-align-center-vertical.icon-warn:before, i.ph-align-center-vertical.icon-sign-out:before, i.ph-align-center-vertical.icon-substratum:before, i.ph-align-center-vertical.icon-environment:before, i.ph-align-center-vertical.icon-user:before, i.ph-align-center-vertical.icon-person:before, i.ph-align-center-vertical.icon-terrain:before, i.ph-align-center-vertical.icon-taxon:before, i.ph-align-center-vertical.icon-unknown:before, i.ph-align-center-vertical.icon-nothing:before, i.ph-align-center-vertical.icon-sampling:before, i.ph-align-center-vertical.icon-isolation:before, i.ph-align-center-vertical.icon-strain:before, i.ph-align-center-vertical.icon-gps:before, i.ph-align-center-vertical.icon-location:before, i.ph-align-center-vertical.icon-add:before, i.ph-align-center-vertical.icon-left:before, i.ph-align-center-vertical.icon-dna:before, i.ph-align-center-vertical.icon-delete:before, i.ph-align-center-vertical.icon-edit:before, i.ph-align-center-vertical.icon-info:before {
  content: "\e50c";
}

.ph.ph-align-center-vertical-simple:before, i.ph-align-center-vertical-simple.icon-dead:before, i.ph-align-center-vertical-simple.icon-alive:before, i.ph-align-center-vertical-simple.icon-warn:before, i.ph-align-center-vertical-simple.icon-sign-out:before, i.ph-align-center-vertical-simple.icon-substratum:before, i.ph-align-center-vertical-simple.icon-environment:before, i.ph-align-center-vertical-simple.icon-user:before, i.ph-align-center-vertical-simple.icon-person:before, i.ph-align-center-vertical-simple.icon-terrain:before, i.ph-align-center-vertical-simple.icon-taxon:before, i.ph-align-center-vertical-simple.icon-unknown:before, i.ph-align-center-vertical-simple.icon-nothing:before, i.ph-align-center-vertical-simple.icon-sampling:before, i.ph-align-center-vertical-simple.icon-isolation:before, i.ph-align-center-vertical-simple.icon-strain:before, i.ph-align-center-vertical-simple.icon-gps:before, i.ph-align-center-vertical-simple.icon-location:before, i.ph-align-center-vertical-simple.icon-add:before, i.ph-align-center-vertical-simple.icon-left:before, i.ph-align-center-vertical-simple.icon-dna:before, i.ph-align-center-vertical-simple.icon-delete:before, i.ph-align-center-vertical-simple.icon-edit:before, i.ph-align-center-vertical-simple.icon-info:before {
  content: "\eb10";
}

.ph.ph-align-left:before, i.ph-align-left.icon-dead:before, i.ph-align-left.icon-alive:before, i.ph-align-left.icon-warn:before, i.ph-align-left.icon-sign-out:before, i.ph-align-left.icon-substratum:before, i.ph-align-left.icon-environment:before, i.ph-align-left.icon-user:before, i.ph-align-left.icon-person:before, i.ph-align-left.icon-terrain:before, i.ph-align-left.icon-taxon:before, i.ph-align-left.icon-unknown:before, i.ph-align-left.icon-nothing:before, i.ph-align-left.icon-sampling:before, i.ph-align-left.icon-isolation:before, i.ph-align-left.icon-strain:before, i.ph-align-left.icon-gps:before, i.ph-align-left.icon-location:before, i.ph-align-left.icon-add:before, i.ph-align-left.icon-left:before, i.ph-align-left.icon-dna:before, i.ph-align-left.icon-delete:before, i.ph-align-left.icon-edit:before, i.ph-align-left.icon-info:before {
  content: "\e50e";
}

.ph.ph-align-left-simple:before, i.ph-align-left-simple.icon-dead:before, i.ph-align-left-simple.icon-alive:before, i.ph-align-left-simple.icon-warn:before, i.ph-align-left-simple.icon-sign-out:before, i.ph-align-left-simple.icon-substratum:before, i.ph-align-left-simple.icon-environment:before, i.ph-align-left-simple.icon-user:before, i.ph-align-left-simple.icon-person:before, i.ph-align-left-simple.icon-terrain:before, i.ph-align-left-simple.icon-taxon:before, i.ph-align-left-simple.icon-unknown:before, i.ph-align-left-simple.icon-nothing:before, i.ph-align-left-simple.icon-sampling:before, i.ph-align-left-simple.icon-isolation:before, i.ph-align-left-simple.icon-strain:before, i.ph-align-left-simple.icon-gps:before, i.ph-align-left-simple.icon-location:before, i.ph-align-left-simple.icon-add:before, i.ph-align-left-simple.icon-left:before, i.ph-align-left-simple.icon-dna:before, i.ph-align-left-simple.icon-delete:before, i.ph-align-left-simple.icon-edit:before, i.ph-align-left-simple.icon-info:before {
  content: "\eaee";
}

.ph.ph-align-right:before, i.ph-align-right.icon-dead:before, i.ph-align-right.icon-alive:before, i.ph-align-right.icon-warn:before, i.ph-align-right.icon-sign-out:before, i.ph-align-right.icon-substratum:before, i.ph-align-right.icon-environment:before, i.ph-align-right.icon-user:before, i.ph-align-right.icon-person:before, i.ph-align-right.icon-terrain:before, i.ph-align-right.icon-taxon:before, i.ph-align-right.icon-unknown:before, i.ph-align-right.icon-nothing:before, i.ph-align-right.icon-sampling:before, i.ph-align-right.icon-isolation:before, i.ph-align-right.icon-strain:before, i.ph-align-right.icon-gps:before, i.ph-align-right.icon-location:before, i.ph-align-right.icon-add:before, i.ph-align-right.icon-left:before, i.ph-align-right.icon-dna:before, i.ph-align-right.icon-delete:before, i.ph-align-right.icon-edit:before, i.ph-align-right.icon-info:before {
  content: "\e510";
}

.ph.ph-align-right-simple:before, i.ph-align-right-simple.icon-dead:before, i.ph-align-right-simple.icon-alive:before, i.ph-align-right-simple.icon-warn:before, i.ph-align-right-simple.icon-sign-out:before, i.ph-align-right-simple.icon-substratum:before, i.ph-align-right-simple.icon-environment:before, i.ph-align-right-simple.icon-user:before, i.ph-align-right-simple.icon-person:before, i.ph-align-right-simple.icon-terrain:before, i.ph-align-right-simple.icon-taxon:before, i.ph-align-right-simple.icon-unknown:before, i.ph-align-right-simple.icon-nothing:before, i.ph-align-right-simple.icon-sampling:before, i.ph-align-right-simple.icon-isolation:before, i.ph-align-right-simple.icon-strain:before, i.ph-align-right-simple.icon-gps:before, i.ph-align-right-simple.icon-location:before, i.ph-align-right-simple.icon-add:before, i.ph-align-right-simple.icon-left:before, i.ph-align-right-simple.icon-dna:before, i.ph-align-right-simple.icon-delete:before, i.ph-align-right-simple.icon-edit:before, i.ph-align-right-simple.icon-info:before {
  content: "\eb12";
}

.ph.ph-align-top:before, i.ph-align-top.icon-dead:before, i.ph-align-top.icon-alive:before, i.ph-align-top.icon-warn:before, i.ph-align-top.icon-sign-out:before, i.ph-align-top.icon-substratum:before, i.ph-align-top.icon-environment:before, i.ph-align-top.icon-user:before, i.ph-align-top.icon-person:before, i.ph-align-top.icon-terrain:before, i.ph-align-top.icon-taxon:before, i.ph-align-top.icon-unknown:before, i.ph-align-top.icon-nothing:before, i.ph-align-top.icon-sampling:before, i.ph-align-top.icon-isolation:before, i.ph-align-top.icon-strain:before, i.ph-align-top.icon-gps:before, i.ph-align-top.icon-location:before, i.ph-align-top.icon-add:before, i.ph-align-top.icon-left:before, i.ph-align-top.icon-dna:before, i.ph-align-top.icon-delete:before, i.ph-align-top.icon-edit:before, i.ph-align-top.icon-info:before {
  content: "\e512";
}

.ph.ph-align-top-simple:before, i.ph-align-top-simple.icon-dead:before, i.ph-align-top-simple.icon-alive:before, i.ph-align-top-simple.icon-warn:before, i.ph-align-top-simple.icon-sign-out:before, i.ph-align-top-simple.icon-substratum:before, i.ph-align-top-simple.icon-environment:before, i.ph-align-top-simple.icon-user:before, i.ph-align-top-simple.icon-person:before, i.ph-align-top-simple.icon-terrain:before, i.ph-align-top-simple.icon-taxon:before, i.ph-align-top-simple.icon-unknown:before, i.ph-align-top-simple.icon-nothing:before, i.ph-align-top-simple.icon-sampling:before, i.ph-align-top-simple.icon-isolation:before, i.ph-align-top-simple.icon-strain:before, i.ph-align-top-simple.icon-gps:before, i.ph-align-top-simple.icon-location:before, i.ph-align-top-simple.icon-add:before, i.ph-align-top-simple.icon-left:before, i.ph-align-top-simple.icon-dna:before, i.ph-align-top-simple.icon-delete:before, i.ph-align-top-simple.icon-edit:before, i.ph-align-top-simple.icon-info:before {
  content: "\eb14";
}

.ph.ph-amazon-logo:before, i.ph-amazon-logo.icon-dead:before, i.ph-amazon-logo.icon-alive:before, i.ph-amazon-logo.icon-warn:before, i.ph-amazon-logo.icon-sign-out:before, i.ph-amazon-logo.icon-substratum:before, i.ph-amazon-logo.icon-environment:before, i.ph-amazon-logo.icon-user:before, i.ph-amazon-logo.icon-person:before, i.ph-amazon-logo.icon-terrain:before, i.ph-amazon-logo.icon-taxon:before, i.ph-amazon-logo.icon-unknown:before, i.ph-amazon-logo.icon-nothing:before, i.ph-amazon-logo.icon-sampling:before, i.ph-amazon-logo.icon-isolation:before, i.ph-amazon-logo.icon-strain:before, i.ph-amazon-logo.icon-gps:before, i.ph-amazon-logo.icon-location:before, i.ph-amazon-logo.icon-add:before, i.ph-amazon-logo.icon-left:before, i.ph-amazon-logo.icon-dna:before, i.ph-amazon-logo.icon-delete:before, i.ph-amazon-logo.icon-edit:before, i.ph-amazon-logo.icon-info:before {
  content: "\e96c";
}

.ph.ph-ambulance:before, i.ph-ambulance.icon-dead:before, i.ph-ambulance.icon-alive:before, i.ph-ambulance.icon-warn:before, i.ph-ambulance.icon-sign-out:before, i.ph-ambulance.icon-substratum:before, i.ph-ambulance.icon-environment:before, i.ph-ambulance.icon-user:before, i.ph-ambulance.icon-person:before, i.ph-ambulance.icon-terrain:before, i.ph-ambulance.icon-taxon:before, i.ph-ambulance.icon-unknown:before, i.ph-ambulance.icon-nothing:before, i.ph-ambulance.icon-sampling:before, i.ph-ambulance.icon-isolation:before, i.ph-ambulance.icon-strain:before, i.ph-ambulance.icon-gps:before, i.ph-ambulance.icon-location:before, i.ph-ambulance.icon-add:before, i.ph-ambulance.icon-left:before, i.ph-ambulance.icon-dna:before, i.ph-ambulance.icon-delete:before, i.ph-ambulance.icon-edit:before, i.ph-ambulance.icon-info:before {
  content: "\e572";
}

.ph.ph-anchor:before, i.ph-anchor.icon-dead:before, i.ph-anchor.icon-alive:before, i.ph-anchor.icon-warn:before, i.ph-anchor.icon-sign-out:before, i.ph-anchor.icon-substratum:before, i.ph-anchor.icon-environment:before, i.ph-anchor.icon-user:before, i.ph-anchor.icon-person:before, i.ph-anchor.icon-terrain:before, i.ph-anchor.icon-taxon:before, i.ph-anchor.icon-unknown:before, i.ph-anchor.icon-nothing:before, i.ph-anchor.icon-sampling:before, i.ph-anchor.icon-isolation:before, i.ph-anchor.icon-strain:before, i.ph-anchor.icon-gps:before, i.ph-anchor.icon-location:before, i.ph-anchor.icon-add:before, i.ph-anchor.icon-left:before, i.ph-anchor.icon-dna:before, i.ph-anchor.icon-delete:before, i.ph-anchor.icon-edit:before, i.ph-anchor.icon-info:before {
  content: "\e514";
}

.ph.ph-anchor-simple:before, i.ph-anchor-simple.icon-dead:before, i.ph-anchor-simple.icon-alive:before, i.ph-anchor-simple.icon-warn:before, i.ph-anchor-simple.icon-sign-out:before, i.ph-anchor-simple.icon-substratum:before, i.ph-anchor-simple.icon-environment:before, i.ph-anchor-simple.icon-user:before, i.ph-anchor-simple.icon-person:before, i.ph-anchor-simple.icon-terrain:before, i.ph-anchor-simple.icon-taxon:before, i.ph-anchor-simple.icon-unknown:before, i.ph-anchor-simple.icon-nothing:before, i.ph-anchor-simple.icon-sampling:before, i.ph-anchor-simple.icon-isolation:before, i.ph-anchor-simple.icon-strain:before, i.ph-anchor-simple.icon-gps:before, i.ph-anchor-simple.icon-location:before, i.ph-anchor-simple.icon-add:before, i.ph-anchor-simple.icon-left:before, i.ph-anchor-simple.icon-dna:before, i.ph-anchor-simple.icon-delete:before, i.ph-anchor-simple.icon-edit:before, i.ph-anchor-simple.icon-info:before {
  content: "\e5d8";
}

.ph.ph-android-logo:before, i.ph-android-logo.icon-dead:before, i.ph-android-logo.icon-alive:before, i.ph-android-logo.icon-warn:before, i.ph-android-logo.icon-sign-out:before, i.ph-android-logo.icon-substratum:before, i.ph-android-logo.icon-environment:before, i.ph-android-logo.icon-user:before, i.ph-android-logo.icon-person:before, i.ph-android-logo.icon-terrain:before, i.ph-android-logo.icon-taxon:before, i.ph-android-logo.icon-unknown:before, i.ph-android-logo.icon-nothing:before, i.ph-android-logo.icon-sampling:before, i.ph-android-logo.icon-isolation:before, i.ph-android-logo.icon-strain:before, i.ph-android-logo.icon-gps:before, i.ph-android-logo.icon-location:before, i.ph-android-logo.icon-add:before, i.ph-android-logo.icon-left:before, i.ph-android-logo.icon-dna:before, i.ph-android-logo.icon-delete:before, i.ph-android-logo.icon-edit:before, i.ph-android-logo.icon-info:before {
  content: "\e008";
}

.ph.ph-angle:before, i.ph-angle.icon-dead:before, i.ph-angle.icon-alive:before, i.ph-angle.icon-warn:before, i.ph-angle.icon-sign-out:before, i.ph-angle.icon-substratum:before, i.ph-angle.icon-environment:before, i.ph-angle.icon-user:before, i.ph-angle.icon-person:before, i.ph-angle.icon-terrain:before, i.ph-angle.icon-taxon:before, i.ph-angle.icon-unknown:before, i.ph-angle.icon-nothing:before, i.ph-angle.icon-sampling:before, i.ph-angle.icon-isolation:before, i.ph-angle.icon-strain:before, i.ph-angle.icon-gps:before, i.ph-angle.icon-location:before, i.ph-angle.icon-add:before, i.ph-angle.icon-left:before, i.ph-angle.icon-dna:before, i.ph-angle.icon-delete:before, i.ph-angle.icon-edit:before, i.ph-angle.icon-info:before {
  content: "\e7bc";
}

.ph.ph-angular-logo:before, i.ph-angular-logo.icon-dead:before, i.ph-angular-logo.icon-alive:before, i.ph-angular-logo.icon-warn:before, i.ph-angular-logo.icon-sign-out:before, i.ph-angular-logo.icon-substratum:before, i.ph-angular-logo.icon-environment:before, i.ph-angular-logo.icon-user:before, i.ph-angular-logo.icon-person:before, i.ph-angular-logo.icon-terrain:before, i.ph-angular-logo.icon-taxon:before, i.ph-angular-logo.icon-unknown:before, i.ph-angular-logo.icon-nothing:before, i.ph-angular-logo.icon-sampling:before, i.ph-angular-logo.icon-isolation:before, i.ph-angular-logo.icon-strain:before, i.ph-angular-logo.icon-gps:before, i.ph-angular-logo.icon-location:before, i.ph-angular-logo.icon-add:before, i.ph-angular-logo.icon-left:before, i.ph-angular-logo.icon-dna:before, i.ph-angular-logo.icon-delete:before, i.ph-angular-logo.icon-edit:before, i.ph-angular-logo.icon-info:before {
  content: "\eb80";
}

.ph.ph-aperture:before, i.ph-aperture.icon-dead:before, i.ph-aperture.icon-alive:before, i.ph-aperture.icon-warn:before, i.ph-aperture.icon-sign-out:before, i.ph-aperture.icon-substratum:before, i.ph-aperture.icon-environment:before, i.ph-aperture.icon-user:before, i.ph-aperture.icon-person:before, i.ph-aperture.icon-terrain:before, i.ph-aperture.icon-taxon:before, i.ph-aperture.icon-unknown:before, i.ph-aperture.icon-nothing:before, i.ph-aperture.icon-sampling:before, i.ph-aperture.icon-isolation:before, i.ph-aperture.icon-strain:before, i.ph-aperture.icon-gps:before, i.ph-aperture.icon-location:before, i.ph-aperture.icon-add:before, i.ph-aperture.icon-left:before, i.ph-aperture.icon-dna:before, i.ph-aperture.icon-delete:before, i.ph-aperture.icon-edit:before, i.ph-aperture.icon-info:before {
  content: "\e00a";
}

.ph.ph-app-store-logo:before, i.ph-app-store-logo.icon-dead:before, i.ph-app-store-logo.icon-alive:before, i.ph-app-store-logo.icon-warn:before, i.ph-app-store-logo.icon-sign-out:before, i.ph-app-store-logo.icon-substratum:before, i.ph-app-store-logo.icon-environment:before, i.ph-app-store-logo.icon-user:before, i.ph-app-store-logo.icon-person:before, i.ph-app-store-logo.icon-terrain:before, i.ph-app-store-logo.icon-taxon:before, i.ph-app-store-logo.icon-unknown:before, i.ph-app-store-logo.icon-nothing:before, i.ph-app-store-logo.icon-sampling:before, i.ph-app-store-logo.icon-isolation:before, i.ph-app-store-logo.icon-strain:before, i.ph-app-store-logo.icon-gps:before, i.ph-app-store-logo.icon-location:before, i.ph-app-store-logo.icon-add:before, i.ph-app-store-logo.icon-left:before, i.ph-app-store-logo.icon-dna:before, i.ph-app-store-logo.icon-delete:before, i.ph-app-store-logo.icon-edit:before, i.ph-app-store-logo.icon-info:before {
  content: "\e974";
}

.ph.ph-app-window:before, i.ph-app-window.icon-dead:before, i.ph-app-window.icon-alive:before, i.ph-app-window.icon-warn:before, i.ph-app-window.icon-sign-out:before, i.ph-app-window.icon-substratum:before, i.ph-app-window.icon-environment:before, i.ph-app-window.icon-user:before, i.ph-app-window.icon-person:before, i.ph-app-window.icon-terrain:before, i.ph-app-window.icon-taxon:before, i.ph-app-window.icon-unknown:before, i.ph-app-window.icon-nothing:before, i.ph-app-window.icon-sampling:before, i.ph-app-window.icon-isolation:before, i.ph-app-window.icon-strain:before, i.ph-app-window.icon-gps:before, i.ph-app-window.icon-location:before, i.ph-app-window.icon-add:before, i.ph-app-window.icon-left:before, i.ph-app-window.icon-dna:before, i.ph-app-window.icon-delete:before, i.ph-app-window.icon-edit:before, i.ph-app-window.icon-info:before {
  content: "\e5da";
}

.ph.ph-apple-logo:before, i.ph-apple-logo.icon-dead:before, i.ph-apple-logo.icon-alive:before, i.ph-apple-logo.icon-warn:before, i.ph-apple-logo.icon-sign-out:before, i.ph-apple-logo.icon-substratum:before, i.ph-apple-logo.icon-environment:before, i.ph-apple-logo.icon-user:before, i.ph-apple-logo.icon-person:before, i.ph-apple-logo.icon-terrain:before, i.ph-apple-logo.icon-taxon:before, i.ph-apple-logo.icon-unknown:before, i.ph-apple-logo.icon-nothing:before, i.ph-apple-logo.icon-sampling:before, i.ph-apple-logo.icon-isolation:before, i.ph-apple-logo.icon-strain:before, i.ph-apple-logo.icon-gps:before, i.ph-apple-logo.icon-location:before, i.ph-apple-logo.icon-add:before, i.ph-apple-logo.icon-left:before, i.ph-apple-logo.icon-dna:before, i.ph-apple-logo.icon-delete:before, i.ph-apple-logo.icon-edit:before, i.ph-apple-logo.icon-info:before {
  content: "\e516";
}

.ph.ph-apple-podcasts-logo:before, i.ph-apple-podcasts-logo.icon-dead:before, i.ph-apple-podcasts-logo.icon-alive:before, i.ph-apple-podcasts-logo.icon-warn:before, i.ph-apple-podcasts-logo.icon-sign-out:before, i.ph-apple-podcasts-logo.icon-substratum:before, i.ph-apple-podcasts-logo.icon-environment:before, i.ph-apple-podcasts-logo.icon-user:before, i.ph-apple-podcasts-logo.icon-person:before, i.ph-apple-podcasts-logo.icon-terrain:before, i.ph-apple-podcasts-logo.icon-taxon:before, i.ph-apple-podcasts-logo.icon-unknown:before, i.ph-apple-podcasts-logo.icon-nothing:before, i.ph-apple-podcasts-logo.icon-sampling:before, i.ph-apple-podcasts-logo.icon-isolation:before, i.ph-apple-podcasts-logo.icon-strain:before, i.ph-apple-podcasts-logo.icon-gps:before, i.ph-apple-podcasts-logo.icon-location:before, i.ph-apple-podcasts-logo.icon-add:before, i.ph-apple-podcasts-logo.icon-left:before, i.ph-apple-podcasts-logo.icon-dna:before, i.ph-apple-podcasts-logo.icon-delete:before, i.ph-apple-podcasts-logo.icon-edit:before, i.ph-apple-podcasts-logo.icon-info:before {
  content: "\eb96";
}

.ph.ph-approximate-equals:before, i.ph-approximate-equals.icon-dead:before, i.ph-approximate-equals.icon-alive:before, i.ph-approximate-equals.icon-warn:before, i.ph-approximate-equals.icon-sign-out:before, i.ph-approximate-equals.icon-substratum:before, i.ph-approximate-equals.icon-environment:before, i.ph-approximate-equals.icon-user:before, i.ph-approximate-equals.icon-person:before, i.ph-approximate-equals.icon-terrain:before, i.ph-approximate-equals.icon-taxon:before, i.ph-approximate-equals.icon-unknown:before, i.ph-approximate-equals.icon-nothing:before, i.ph-approximate-equals.icon-sampling:before, i.ph-approximate-equals.icon-isolation:before, i.ph-approximate-equals.icon-strain:before, i.ph-approximate-equals.icon-gps:before, i.ph-approximate-equals.icon-location:before, i.ph-approximate-equals.icon-add:before, i.ph-approximate-equals.icon-left:before, i.ph-approximate-equals.icon-dna:before, i.ph-approximate-equals.icon-delete:before, i.ph-approximate-equals.icon-edit:before, i.ph-approximate-equals.icon-info:before {
  content: "\edaa";
}

.ph.ph-archive:before, i.ph-archive.icon-dead:before, i.ph-archive.icon-alive:before, i.ph-archive.icon-warn:before, i.ph-archive.icon-sign-out:before, i.ph-archive.icon-substratum:before, i.ph-archive.icon-environment:before, i.ph-archive.icon-user:before, i.ph-archive.icon-person:before, i.ph-archive.icon-terrain:before, i.ph-archive.icon-taxon:before, i.ph-archive.icon-unknown:before, i.ph-archive.icon-nothing:before, i.ph-archive.icon-sampling:before, i.ph-archive.icon-isolation:before, i.ph-archive.icon-strain:before, i.ph-archive.icon-gps:before, i.ph-archive.icon-location:before, i.ph-archive.icon-add:before, i.ph-archive.icon-left:before, i.ph-archive.icon-dna:before, i.ph-archive.icon-delete:before, i.ph-archive.icon-edit:before, i.ph-archive.icon-info:before {
  content: "\e00c";
}

.ph.ph-armchair:before, i.ph-armchair.icon-dead:before, i.ph-armchair.icon-alive:before, i.ph-armchair.icon-warn:before, i.ph-armchair.icon-sign-out:before, i.ph-armchair.icon-substratum:before, i.ph-armchair.icon-environment:before, i.ph-armchair.icon-user:before, i.ph-armchair.icon-person:before, i.ph-armchair.icon-terrain:before, i.ph-armchair.icon-taxon:before, i.ph-armchair.icon-unknown:before, i.ph-armchair.icon-nothing:before, i.ph-armchair.icon-sampling:before, i.ph-armchair.icon-isolation:before, i.ph-armchair.icon-strain:before, i.ph-armchair.icon-gps:before, i.ph-armchair.icon-location:before, i.ph-armchair.icon-add:before, i.ph-armchair.icon-left:before, i.ph-armchair.icon-dna:before, i.ph-armchair.icon-delete:before, i.ph-armchair.icon-edit:before, i.ph-armchair.icon-info:before {
  content: "\e012";
}

.ph.ph-arrow-arc-left:before, i.ph-arrow-arc-left.icon-dead:before, i.ph-arrow-arc-left.icon-alive:before, i.ph-arrow-arc-left.icon-warn:before, i.ph-arrow-arc-left.icon-sign-out:before, i.ph-arrow-arc-left.icon-substratum:before, i.ph-arrow-arc-left.icon-environment:before, i.ph-arrow-arc-left.icon-user:before, i.ph-arrow-arc-left.icon-person:before, i.ph-arrow-arc-left.icon-terrain:before, i.ph-arrow-arc-left.icon-taxon:before, i.ph-arrow-arc-left.icon-unknown:before, i.ph-arrow-arc-left.icon-nothing:before, i.ph-arrow-arc-left.icon-sampling:before, i.ph-arrow-arc-left.icon-isolation:before, i.ph-arrow-arc-left.icon-strain:before, i.ph-arrow-arc-left.icon-gps:before, i.ph-arrow-arc-left.icon-location:before, i.ph-arrow-arc-left.icon-add:before, i.ph-arrow-arc-left.icon-left:before, i.ph-arrow-arc-left.icon-dna:before, i.ph-arrow-arc-left.icon-delete:before, i.ph-arrow-arc-left.icon-edit:before, i.ph-arrow-arc-left.icon-info:before {
  content: "\e014";
}

.ph.ph-arrow-arc-right:before, i.ph-arrow-arc-right.icon-dead:before, i.ph-arrow-arc-right.icon-alive:before, i.ph-arrow-arc-right.icon-warn:before, i.ph-arrow-arc-right.icon-sign-out:before, i.ph-arrow-arc-right.icon-substratum:before, i.ph-arrow-arc-right.icon-environment:before, i.ph-arrow-arc-right.icon-user:before, i.ph-arrow-arc-right.icon-person:before, i.ph-arrow-arc-right.icon-terrain:before, i.ph-arrow-arc-right.icon-taxon:before, i.ph-arrow-arc-right.icon-unknown:before, i.ph-arrow-arc-right.icon-nothing:before, i.ph-arrow-arc-right.icon-sampling:before, i.ph-arrow-arc-right.icon-isolation:before, i.ph-arrow-arc-right.icon-strain:before, i.ph-arrow-arc-right.icon-gps:before, i.ph-arrow-arc-right.icon-location:before, i.ph-arrow-arc-right.icon-add:before, i.ph-arrow-arc-right.icon-left:before, i.ph-arrow-arc-right.icon-dna:before, i.ph-arrow-arc-right.icon-delete:before, i.ph-arrow-arc-right.icon-edit:before, i.ph-arrow-arc-right.icon-info:before {
  content: "\e016";
}

.ph.ph-arrow-bend-double-up-left:before, i.ph-arrow-bend-double-up-left.icon-dead:before, i.ph-arrow-bend-double-up-left.icon-alive:before, i.ph-arrow-bend-double-up-left.icon-warn:before, i.ph-arrow-bend-double-up-left.icon-sign-out:before, i.ph-arrow-bend-double-up-left.icon-substratum:before, i.ph-arrow-bend-double-up-left.icon-environment:before, i.ph-arrow-bend-double-up-left.icon-user:before, i.ph-arrow-bend-double-up-left.icon-person:before, i.ph-arrow-bend-double-up-left.icon-terrain:before, i.ph-arrow-bend-double-up-left.icon-taxon:before, i.ph-arrow-bend-double-up-left.icon-unknown:before, i.ph-arrow-bend-double-up-left.icon-nothing:before, i.ph-arrow-bend-double-up-left.icon-sampling:before, i.ph-arrow-bend-double-up-left.icon-isolation:before, i.ph-arrow-bend-double-up-left.icon-strain:before, i.ph-arrow-bend-double-up-left.icon-gps:before, i.ph-arrow-bend-double-up-left.icon-location:before, i.ph-arrow-bend-double-up-left.icon-add:before, i.ph-arrow-bend-double-up-left.icon-left:before, i.ph-arrow-bend-double-up-left.icon-dna:before, i.ph-arrow-bend-double-up-left.icon-delete:before, i.ph-arrow-bend-double-up-left.icon-edit:before, i.ph-arrow-bend-double-up-left.icon-info:before {
  content: "\e03a";
}

.ph.ph-arrow-bend-double-up-right:before, i.ph-arrow-bend-double-up-right.icon-dead:before, i.ph-arrow-bend-double-up-right.icon-alive:before, i.ph-arrow-bend-double-up-right.icon-warn:before, i.ph-arrow-bend-double-up-right.icon-sign-out:before, i.ph-arrow-bend-double-up-right.icon-substratum:before, i.ph-arrow-bend-double-up-right.icon-environment:before, i.ph-arrow-bend-double-up-right.icon-user:before, i.ph-arrow-bend-double-up-right.icon-person:before, i.ph-arrow-bend-double-up-right.icon-terrain:before, i.ph-arrow-bend-double-up-right.icon-taxon:before, i.ph-arrow-bend-double-up-right.icon-unknown:before, i.ph-arrow-bend-double-up-right.icon-nothing:before, i.ph-arrow-bend-double-up-right.icon-sampling:before, i.ph-arrow-bend-double-up-right.icon-isolation:before, i.ph-arrow-bend-double-up-right.icon-strain:before, i.ph-arrow-bend-double-up-right.icon-gps:before, i.ph-arrow-bend-double-up-right.icon-location:before, i.ph-arrow-bend-double-up-right.icon-add:before, i.ph-arrow-bend-double-up-right.icon-left:before, i.ph-arrow-bend-double-up-right.icon-dna:before, i.ph-arrow-bend-double-up-right.icon-delete:before, i.ph-arrow-bend-double-up-right.icon-edit:before, i.ph-arrow-bend-double-up-right.icon-info:before {
  content: "\e03c";
}

.ph.ph-arrow-bend-down-left:before, i.ph-arrow-bend-down-left.icon-dead:before, i.ph-arrow-bend-down-left.icon-alive:before, i.ph-arrow-bend-down-left.icon-warn:before, i.ph-arrow-bend-down-left.icon-sign-out:before, i.ph-arrow-bend-down-left.icon-substratum:before, i.ph-arrow-bend-down-left.icon-environment:before, i.ph-arrow-bend-down-left.icon-user:before, i.ph-arrow-bend-down-left.icon-person:before, i.ph-arrow-bend-down-left.icon-terrain:before, i.ph-arrow-bend-down-left.icon-taxon:before, i.ph-arrow-bend-down-left.icon-unknown:before, i.ph-arrow-bend-down-left.icon-nothing:before, i.ph-arrow-bend-down-left.icon-sampling:before, i.ph-arrow-bend-down-left.icon-isolation:before, i.ph-arrow-bend-down-left.icon-strain:before, i.ph-arrow-bend-down-left.icon-gps:before, i.ph-arrow-bend-down-left.icon-location:before, i.ph-arrow-bend-down-left.icon-add:before, i.ph-arrow-bend-down-left.icon-left:before, i.ph-arrow-bend-down-left.icon-dna:before, i.ph-arrow-bend-down-left.icon-delete:before, i.ph-arrow-bend-down-left.icon-edit:before, i.ph-arrow-bend-down-left.icon-info:before {
  content: "\e018";
}

.ph.ph-arrow-bend-down-right:before, i.ph-arrow-bend-down-right.icon-dead:before, i.ph-arrow-bend-down-right.icon-alive:before, i.ph-arrow-bend-down-right.icon-warn:before, i.ph-arrow-bend-down-right.icon-sign-out:before, i.ph-arrow-bend-down-right.icon-substratum:before, i.ph-arrow-bend-down-right.icon-environment:before, i.ph-arrow-bend-down-right.icon-user:before, i.ph-arrow-bend-down-right.icon-person:before, i.ph-arrow-bend-down-right.icon-terrain:before, i.ph-arrow-bend-down-right.icon-taxon:before, i.ph-arrow-bend-down-right.icon-unknown:before, i.ph-arrow-bend-down-right.icon-nothing:before, i.ph-arrow-bend-down-right.icon-sampling:before, i.ph-arrow-bend-down-right.icon-isolation:before, i.ph-arrow-bend-down-right.icon-strain:before, i.ph-arrow-bend-down-right.icon-gps:before, i.ph-arrow-bend-down-right.icon-location:before, i.ph-arrow-bend-down-right.icon-add:before, i.ph-arrow-bend-down-right.icon-left:before, i.ph-arrow-bend-down-right.icon-dna:before, i.ph-arrow-bend-down-right.icon-delete:before, i.ph-arrow-bend-down-right.icon-edit:before, i.ph-arrow-bend-down-right.icon-info:before {
  content: "\e01a";
}

.ph.ph-arrow-bend-left-down:before, i.ph-arrow-bend-left-down.icon-dead:before, i.ph-arrow-bend-left-down.icon-alive:before, i.ph-arrow-bend-left-down.icon-warn:before, i.ph-arrow-bend-left-down.icon-sign-out:before, i.ph-arrow-bend-left-down.icon-substratum:before, i.ph-arrow-bend-left-down.icon-environment:before, i.ph-arrow-bend-left-down.icon-user:before, i.ph-arrow-bend-left-down.icon-person:before, i.ph-arrow-bend-left-down.icon-terrain:before, i.ph-arrow-bend-left-down.icon-taxon:before, i.ph-arrow-bend-left-down.icon-unknown:before, i.ph-arrow-bend-left-down.icon-nothing:before, i.ph-arrow-bend-left-down.icon-sampling:before, i.ph-arrow-bend-left-down.icon-isolation:before, i.ph-arrow-bend-left-down.icon-strain:before, i.ph-arrow-bend-left-down.icon-gps:before, i.ph-arrow-bend-left-down.icon-location:before, i.ph-arrow-bend-left-down.icon-add:before, i.ph-arrow-bend-left-down.icon-left:before, i.ph-arrow-bend-left-down.icon-dna:before, i.ph-arrow-bend-left-down.icon-delete:before, i.ph-arrow-bend-left-down.icon-edit:before, i.ph-arrow-bend-left-down.icon-info:before {
  content: "\e01c";
}

.ph.ph-arrow-bend-left-up:before, i.ph-arrow-bend-left-up.icon-dead:before, i.ph-arrow-bend-left-up.icon-alive:before, i.ph-arrow-bend-left-up.icon-warn:before, i.ph-arrow-bend-left-up.icon-sign-out:before, i.ph-arrow-bend-left-up.icon-substratum:before, i.ph-arrow-bend-left-up.icon-environment:before, i.ph-arrow-bend-left-up.icon-user:before, i.ph-arrow-bend-left-up.icon-person:before, i.ph-arrow-bend-left-up.icon-terrain:before, i.ph-arrow-bend-left-up.icon-taxon:before, i.ph-arrow-bend-left-up.icon-unknown:before, i.ph-arrow-bend-left-up.icon-nothing:before, i.ph-arrow-bend-left-up.icon-sampling:before, i.ph-arrow-bend-left-up.icon-isolation:before, i.ph-arrow-bend-left-up.icon-strain:before, i.ph-arrow-bend-left-up.icon-gps:before, i.ph-arrow-bend-left-up.icon-location:before, i.ph-arrow-bend-left-up.icon-add:before, i.ph-arrow-bend-left-up.icon-left:before, i.ph-arrow-bend-left-up.icon-dna:before, i.ph-arrow-bend-left-up.icon-delete:before, i.ph-arrow-bend-left-up.icon-edit:before, i.ph-arrow-bend-left-up.icon-info:before {
  content: "\e01e";
}

.ph.ph-arrow-bend-right-down:before, i.ph-arrow-bend-right-down.icon-dead:before, i.ph-arrow-bend-right-down.icon-alive:before, i.ph-arrow-bend-right-down.icon-warn:before, i.ph-arrow-bend-right-down.icon-sign-out:before, i.ph-arrow-bend-right-down.icon-substratum:before, i.ph-arrow-bend-right-down.icon-environment:before, i.ph-arrow-bend-right-down.icon-user:before, i.ph-arrow-bend-right-down.icon-person:before, i.ph-arrow-bend-right-down.icon-terrain:before, i.ph-arrow-bend-right-down.icon-taxon:before, i.ph-arrow-bend-right-down.icon-unknown:before, i.ph-arrow-bend-right-down.icon-nothing:before, i.ph-arrow-bend-right-down.icon-sampling:before, i.ph-arrow-bend-right-down.icon-isolation:before, i.ph-arrow-bend-right-down.icon-strain:before, i.ph-arrow-bend-right-down.icon-gps:before, i.ph-arrow-bend-right-down.icon-location:before, i.ph-arrow-bend-right-down.icon-add:before, i.ph-arrow-bend-right-down.icon-left:before, i.ph-arrow-bend-right-down.icon-dna:before, i.ph-arrow-bend-right-down.icon-delete:before, i.ph-arrow-bend-right-down.icon-edit:before, i.ph-arrow-bend-right-down.icon-info:before {
  content: "\e020";
}

.ph.ph-arrow-bend-right-up:before, i.ph-arrow-bend-right-up.icon-dead:before, i.ph-arrow-bend-right-up.icon-alive:before, i.ph-arrow-bend-right-up.icon-warn:before, i.ph-arrow-bend-right-up.icon-sign-out:before, i.ph-arrow-bend-right-up.icon-substratum:before, i.ph-arrow-bend-right-up.icon-environment:before, i.ph-arrow-bend-right-up.icon-user:before, i.ph-arrow-bend-right-up.icon-person:before, i.ph-arrow-bend-right-up.icon-terrain:before, i.ph-arrow-bend-right-up.icon-taxon:before, i.ph-arrow-bend-right-up.icon-unknown:before, i.ph-arrow-bend-right-up.icon-nothing:before, i.ph-arrow-bend-right-up.icon-sampling:before, i.ph-arrow-bend-right-up.icon-isolation:before, i.ph-arrow-bend-right-up.icon-strain:before, i.ph-arrow-bend-right-up.icon-gps:before, i.ph-arrow-bend-right-up.icon-location:before, i.ph-arrow-bend-right-up.icon-add:before, i.ph-arrow-bend-right-up.icon-left:before, i.ph-arrow-bend-right-up.icon-dna:before, i.ph-arrow-bend-right-up.icon-delete:before, i.ph-arrow-bend-right-up.icon-edit:before, i.ph-arrow-bend-right-up.icon-info:before {
  content: "\e022";
}

.ph.ph-arrow-bend-up-left:before, i.ph-arrow-bend-up-left.icon-dead:before, i.ph-arrow-bend-up-left.icon-alive:before, i.ph-arrow-bend-up-left.icon-warn:before, i.ph-arrow-bend-up-left.icon-sign-out:before, i.ph-arrow-bend-up-left.icon-substratum:before, i.ph-arrow-bend-up-left.icon-environment:before, i.ph-arrow-bend-up-left.icon-user:before, i.ph-arrow-bend-up-left.icon-person:before, i.ph-arrow-bend-up-left.icon-terrain:before, i.ph-arrow-bend-up-left.icon-taxon:before, i.ph-arrow-bend-up-left.icon-unknown:before, i.ph-arrow-bend-up-left.icon-nothing:before, i.ph-arrow-bend-up-left.icon-sampling:before, i.ph-arrow-bend-up-left.icon-isolation:before, i.ph-arrow-bend-up-left.icon-strain:before, i.ph-arrow-bend-up-left.icon-gps:before, i.ph-arrow-bend-up-left.icon-location:before, i.ph-arrow-bend-up-left.icon-add:before, i.ph-arrow-bend-up-left.icon-left:before, i.ph-arrow-bend-up-left.icon-dna:before, i.ph-arrow-bend-up-left.icon-delete:before, i.ph-arrow-bend-up-left.icon-edit:before, i.ph-arrow-bend-up-left.icon-info:before {
  content: "\e024";
}

.ph.ph-arrow-bend-up-right:before, i.ph-arrow-bend-up-right.icon-dead:before, i.ph-arrow-bend-up-right.icon-alive:before, i.ph-arrow-bend-up-right.icon-warn:before, i.ph-arrow-bend-up-right.icon-sign-out:before, i.ph-arrow-bend-up-right.icon-substratum:before, i.ph-arrow-bend-up-right.icon-environment:before, i.ph-arrow-bend-up-right.icon-user:before, i.ph-arrow-bend-up-right.icon-person:before, i.ph-arrow-bend-up-right.icon-terrain:before, i.ph-arrow-bend-up-right.icon-taxon:before, i.ph-arrow-bend-up-right.icon-unknown:before, i.ph-arrow-bend-up-right.icon-nothing:before, i.ph-arrow-bend-up-right.icon-sampling:before, i.ph-arrow-bend-up-right.icon-isolation:before, i.ph-arrow-bend-up-right.icon-strain:before, i.ph-arrow-bend-up-right.icon-gps:before, i.ph-arrow-bend-up-right.icon-location:before, i.ph-arrow-bend-up-right.icon-add:before, i.ph-arrow-bend-up-right.icon-left:before, i.ph-arrow-bend-up-right.icon-dna:before, i.ph-arrow-bend-up-right.icon-delete:before, i.ph-arrow-bend-up-right.icon-edit:before, i.ph-arrow-bend-up-right.icon-info:before {
  content: "\e026";
}

.ph.ph-arrow-circle-down:before, i.ph-arrow-circle-down.icon-dead:before, i.ph-arrow-circle-down.icon-alive:before, i.ph-arrow-circle-down.icon-warn:before, i.ph-arrow-circle-down.icon-sign-out:before, i.ph-arrow-circle-down.icon-substratum:before, i.ph-arrow-circle-down.icon-environment:before, i.ph-arrow-circle-down.icon-user:before, i.ph-arrow-circle-down.icon-person:before, i.ph-arrow-circle-down.icon-terrain:before, i.ph-arrow-circle-down.icon-taxon:before, i.ph-arrow-circle-down.icon-unknown:before, i.ph-arrow-circle-down.icon-nothing:before, i.ph-arrow-circle-down.icon-sampling:before, i.ph-arrow-circle-down.icon-isolation:before, i.ph-arrow-circle-down.icon-strain:before, i.ph-arrow-circle-down.icon-gps:before, i.ph-arrow-circle-down.icon-location:before, i.ph-arrow-circle-down.icon-add:before, i.ph-arrow-circle-down.icon-left:before, i.ph-arrow-circle-down.icon-dna:before, i.ph-arrow-circle-down.icon-delete:before, i.ph-arrow-circle-down.icon-edit:before, i.ph-arrow-circle-down.icon-info:before {
  content: "\e028";
}

.ph.ph-arrow-circle-down-left:before, i.ph-arrow-circle-down-left.icon-dead:before, i.ph-arrow-circle-down-left.icon-alive:before, i.ph-arrow-circle-down-left.icon-warn:before, i.ph-arrow-circle-down-left.icon-sign-out:before, i.ph-arrow-circle-down-left.icon-substratum:before, i.ph-arrow-circle-down-left.icon-environment:before, i.ph-arrow-circle-down-left.icon-user:before, i.ph-arrow-circle-down-left.icon-person:before, i.ph-arrow-circle-down-left.icon-terrain:before, i.ph-arrow-circle-down-left.icon-taxon:before, i.ph-arrow-circle-down-left.icon-unknown:before, i.ph-arrow-circle-down-left.icon-nothing:before, i.ph-arrow-circle-down-left.icon-sampling:before, i.ph-arrow-circle-down-left.icon-isolation:before, i.ph-arrow-circle-down-left.icon-strain:before, i.ph-arrow-circle-down-left.icon-gps:before, i.ph-arrow-circle-down-left.icon-location:before, i.ph-arrow-circle-down-left.icon-add:before, i.ph-arrow-circle-down-left.icon-left:before, i.ph-arrow-circle-down-left.icon-dna:before, i.ph-arrow-circle-down-left.icon-delete:before, i.ph-arrow-circle-down-left.icon-edit:before, i.ph-arrow-circle-down-left.icon-info:before {
  content: "\e02a";
}

.ph.ph-arrow-circle-down-right:before, i.ph-arrow-circle-down-right.icon-dead:before, i.ph-arrow-circle-down-right.icon-alive:before, i.ph-arrow-circle-down-right.icon-warn:before, i.ph-arrow-circle-down-right.icon-sign-out:before, i.ph-arrow-circle-down-right.icon-substratum:before, i.ph-arrow-circle-down-right.icon-environment:before, i.ph-arrow-circle-down-right.icon-user:before, i.ph-arrow-circle-down-right.icon-person:before, i.ph-arrow-circle-down-right.icon-terrain:before, i.ph-arrow-circle-down-right.icon-taxon:before, i.ph-arrow-circle-down-right.icon-unknown:before, i.ph-arrow-circle-down-right.icon-nothing:before, i.ph-arrow-circle-down-right.icon-sampling:before, i.ph-arrow-circle-down-right.icon-isolation:before, i.ph-arrow-circle-down-right.icon-strain:before, i.ph-arrow-circle-down-right.icon-gps:before, i.ph-arrow-circle-down-right.icon-location:before, i.ph-arrow-circle-down-right.icon-add:before, i.ph-arrow-circle-down-right.icon-left:before, i.ph-arrow-circle-down-right.icon-dna:before, i.ph-arrow-circle-down-right.icon-delete:before, i.ph-arrow-circle-down-right.icon-edit:before, i.ph-arrow-circle-down-right.icon-info:before {
  content: "\e02c";
}

.ph.ph-arrow-circle-left:before, i.ph-arrow-circle-left.icon-dead:before, i.ph-arrow-circle-left.icon-alive:before, i.ph-arrow-circle-left.icon-warn:before, i.ph-arrow-circle-left.icon-sign-out:before, i.ph-arrow-circle-left.icon-substratum:before, i.ph-arrow-circle-left.icon-environment:before, i.ph-arrow-circle-left.icon-user:before, i.ph-arrow-circle-left.icon-person:before, i.ph-arrow-circle-left.icon-terrain:before, i.ph-arrow-circle-left.icon-taxon:before, i.ph-arrow-circle-left.icon-unknown:before, i.ph-arrow-circle-left.icon-nothing:before, i.ph-arrow-circle-left.icon-sampling:before, i.ph-arrow-circle-left.icon-isolation:before, i.ph-arrow-circle-left.icon-strain:before, i.ph-arrow-circle-left.icon-gps:before, i.ph-arrow-circle-left.icon-location:before, i.ph-arrow-circle-left.icon-add:before, i.ph-arrow-circle-left.icon-left:before, i.ph-arrow-circle-left.icon-dna:before, i.ph-arrow-circle-left.icon-delete:before, i.ph-arrow-circle-left.icon-edit:before, i.ph-arrow-circle-left.icon-info:before {
  content: "\e05a";
}

.ph.ph-arrow-circle-right:before, i.ph-arrow-circle-right.icon-dead:before, i.ph-arrow-circle-right.icon-alive:before, i.ph-arrow-circle-right.icon-warn:before, i.ph-arrow-circle-right.icon-sign-out:before, i.ph-arrow-circle-right.icon-substratum:before, i.ph-arrow-circle-right.icon-environment:before, i.ph-arrow-circle-right.icon-user:before, i.ph-arrow-circle-right.icon-person:before, i.ph-arrow-circle-right.icon-terrain:before, i.ph-arrow-circle-right.icon-taxon:before, i.ph-arrow-circle-right.icon-unknown:before, i.ph-arrow-circle-right.icon-nothing:before, i.ph-arrow-circle-right.icon-sampling:before, i.ph-arrow-circle-right.icon-isolation:before, i.ph-arrow-circle-right.icon-strain:before, i.ph-arrow-circle-right.icon-gps:before, i.ph-arrow-circle-right.icon-location:before, i.ph-arrow-circle-right.icon-add:before, i.ph-arrow-circle-right.icon-left:before, i.ph-arrow-circle-right.icon-dna:before, i.ph-arrow-circle-right.icon-delete:before, i.ph-arrow-circle-right.icon-edit:before, i.ph-arrow-circle-right.icon-info:before {
  content: "\e02e";
}

.ph.ph-arrow-circle-up:before, i.ph-arrow-circle-up.icon-dead:before, i.ph-arrow-circle-up.icon-alive:before, i.ph-arrow-circle-up.icon-warn:before, i.ph-arrow-circle-up.icon-sign-out:before, i.ph-arrow-circle-up.icon-substratum:before, i.ph-arrow-circle-up.icon-environment:before, i.ph-arrow-circle-up.icon-user:before, i.ph-arrow-circle-up.icon-person:before, i.ph-arrow-circle-up.icon-terrain:before, i.ph-arrow-circle-up.icon-taxon:before, i.ph-arrow-circle-up.icon-unknown:before, i.ph-arrow-circle-up.icon-nothing:before, i.ph-arrow-circle-up.icon-sampling:before, i.ph-arrow-circle-up.icon-isolation:before, i.ph-arrow-circle-up.icon-strain:before, i.ph-arrow-circle-up.icon-gps:before, i.ph-arrow-circle-up.icon-location:before, i.ph-arrow-circle-up.icon-add:before, i.ph-arrow-circle-up.icon-left:before, i.ph-arrow-circle-up.icon-dna:before, i.ph-arrow-circle-up.icon-delete:before, i.ph-arrow-circle-up.icon-edit:before, i.ph-arrow-circle-up.icon-info:before {
  content: "\e030";
}

.ph.ph-arrow-circle-up-left:before, i.ph-arrow-circle-up-left.icon-dead:before, i.ph-arrow-circle-up-left.icon-alive:before, i.ph-arrow-circle-up-left.icon-warn:before, i.ph-arrow-circle-up-left.icon-sign-out:before, i.ph-arrow-circle-up-left.icon-substratum:before, i.ph-arrow-circle-up-left.icon-environment:before, i.ph-arrow-circle-up-left.icon-user:before, i.ph-arrow-circle-up-left.icon-person:before, i.ph-arrow-circle-up-left.icon-terrain:before, i.ph-arrow-circle-up-left.icon-taxon:before, i.ph-arrow-circle-up-left.icon-unknown:before, i.ph-arrow-circle-up-left.icon-nothing:before, i.ph-arrow-circle-up-left.icon-sampling:before, i.ph-arrow-circle-up-left.icon-isolation:before, i.ph-arrow-circle-up-left.icon-strain:before, i.ph-arrow-circle-up-left.icon-gps:before, i.ph-arrow-circle-up-left.icon-location:before, i.ph-arrow-circle-up-left.icon-add:before, i.ph-arrow-circle-up-left.icon-left:before, i.ph-arrow-circle-up-left.icon-dna:before, i.ph-arrow-circle-up-left.icon-delete:before, i.ph-arrow-circle-up-left.icon-edit:before, i.ph-arrow-circle-up-left.icon-info:before {
  content: "\e032";
}

.ph.ph-arrow-circle-up-right:before, i.ph-arrow-circle-up-right.icon-dead:before, i.ph-arrow-circle-up-right.icon-alive:before, i.ph-arrow-circle-up-right.icon-warn:before, i.ph-arrow-circle-up-right.icon-sign-out:before, i.ph-arrow-circle-up-right.icon-substratum:before, i.ph-arrow-circle-up-right.icon-environment:before, i.ph-arrow-circle-up-right.icon-user:before, i.ph-arrow-circle-up-right.icon-person:before, i.ph-arrow-circle-up-right.icon-terrain:before, i.ph-arrow-circle-up-right.icon-taxon:before, i.ph-arrow-circle-up-right.icon-unknown:before, i.ph-arrow-circle-up-right.icon-nothing:before, i.ph-arrow-circle-up-right.icon-sampling:before, i.ph-arrow-circle-up-right.icon-isolation:before, i.ph-arrow-circle-up-right.icon-strain:before, i.ph-arrow-circle-up-right.icon-gps:before, i.ph-arrow-circle-up-right.icon-location:before, i.ph-arrow-circle-up-right.icon-add:before, i.ph-arrow-circle-up-right.icon-left:before, i.ph-arrow-circle-up-right.icon-dna:before, i.ph-arrow-circle-up-right.icon-delete:before, i.ph-arrow-circle-up-right.icon-edit:before, i.ph-arrow-circle-up-right.icon-info:before {
  content: "\e034";
}

.ph.ph-arrow-clockwise:before, i.ph-arrow-clockwise.icon-dead:before, i.ph-arrow-clockwise.icon-alive:before, i.ph-arrow-clockwise.icon-warn:before, i.ph-arrow-clockwise.icon-sign-out:before, i.ph-arrow-clockwise.icon-substratum:before, i.ph-arrow-clockwise.icon-environment:before, i.ph-arrow-clockwise.icon-user:before, i.ph-arrow-clockwise.icon-person:before, i.ph-arrow-clockwise.icon-terrain:before, i.ph-arrow-clockwise.icon-taxon:before, i.ph-arrow-clockwise.icon-unknown:before, i.ph-arrow-clockwise.icon-nothing:before, i.ph-arrow-clockwise.icon-sampling:before, i.ph-arrow-clockwise.icon-isolation:before, i.ph-arrow-clockwise.icon-strain:before, i.ph-arrow-clockwise.icon-gps:before, i.ph-arrow-clockwise.icon-location:before, i.ph-arrow-clockwise.icon-add:before, i.ph-arrow-clockwise.icon-left:before, i.ph-arrow-clockwise.icon-dna:before, i.ph-arrow-clockwise.icon-delete:before, i.ph-arrow-clockwise.icon-edit:before, i.ph-arrow-clockwise.icon-info:before {
  content: "\e036";
}

.ph.ph-arrow-counter-clockwise:before, i.ph-arrow-counter-clockwise.icon-dead:before, i.ph-arrow-counter-clockwise.icon-alive:before, i.ph-arrow-counter-clockwise.icon-warn:before, i.ph-arrow-counter-clockwise.icon-sign-out:before, i.ph-arrow-counter-clockwise.icon-substratum:before, i.ph-arrow-counter-clockwise.icon-environment:before, i.ph-arrow-counter-clockwise.icon-user:before, i.ph-arrow-counter-clockwise.icon-person:before, i.ph-arrow-counter-clockwise.icon-terrain:before, i.ph-arrow-counter-clockwise.icon-taxon:before, i.ph-arrow-counter-clockwise.icon-unknown:before, i.ph-arrow-counter-clockwise.icon-nothing:before, i.ph-arrow-counter-clockwise.icon-sampling:before, i.ph-arrow-counter-clockwise.icon-isolation:before, i.ph-arrow-counter-clockwise.icon-strain:before, i.ph-arrow-counter-clockwise.icon-gps:before, i.ph-arrow-counter-clockwise.icon-location:before, i.ph-arrow-counter-clockwise.icon-add:before, i.ph-arrow-counter-clockwise.icon-left:before, i.ph-arrow-counter-clockwise.icon-dna:before, i.ph-arrow-counter-clockwise.icon-delete:before, i.ph-arrow-counter-clockwise.icon-edit:before, i.ph-arrow-counter-clockwise.icon-info:before {
  content: "\e038";
}

.ph.ph-arrow-down:before, i.ph-arrow-down.icon-dead:before, i.ph-arrow-down.icon-alive:before, i.ph-arrow-down.icon-warn:before, i.ph-arrow-down.icon-sign-out:before, i.ph-arrow-down.icon-substratum:before, i.ph-arrow-down.icon-environment:before, i.ph-arrow-down.icon-user:before, i.ph-arrow-down.icon-person:before, i.ph-arrow-down.icon-terrain:before, i.ph-arrow-down.icon-taxon:before, i.ph-arrow-down.icon-unknown:before, i.ph-arrow-down.icon-nothing:before, i.ph-arrow-down.icon-sampling:before, i.ph-arrow-down.icon-isolation:before, i.ph-arrow-down.icon-strain:before, i.ph-arrow-down.icon-gps:before, i.ph-arrow-down.icon-location:before, i.ph-arrow-down.icon-add:before, i.ph-arrow-down.icon-left:before, i.ph-arrow-down.icon-dna:before, i.ph-arrow-down.icon-delete:before, i.ph-arrow-down.icon-edit:before, i.ph-arrow-down.icon-info:before {
  content: "\e03e";
}

.ph.ph-arrow-down-left:before, i.ph-arrow-down-left.icon-dead:before, i.ph-arrow-down-left.icon-alive:before, i.ph-arrow-down-left.icon-warn:before, i.ph-arrow-down-left.icon-sign-out:before, i.ph-arrow-down-left.icon-substratum:before, i.ph-arrow-down-left.icon-environment:before, i.ph-arrow-down-left.icon-user:before, i.ph-arrow-down-left.icon-person:before, i.ph-arrow-down-left.icon-terrain:before, i.ph-arrow-down-left.icon-taxon:before, i.ph-arrow-down-left.icon-unknown:before, i.ph-arrow-down-left.icon-nothing:before, i.ph-arrow-down-left.icon-sampling:before, i.ph-arrow-down-left.icon-isolation:before, i.ph-arrow-down-left.icon-strain:before, i.ph-arrow-down-left.icon-gps:before, i.ph-arrow-down-left.icon-location:before, i.ph-arrow-down-left.icon-add:before, i.ph-arrow-down-left.icon-left:before, i.ph-arrow-down-left.icon-dna:before, i.ph-arrow-down-left.icon-delete:before, i.ph-arrow-down-left.icon-edit:before, i.ph-arrow-down-left.icon-info:before {
  content: "\e040";
}

.ph.ph-arrow-down-right:before, i.ph-arrow-down-right.icon-dead:before, i.ph-arrow-down-right.icon-alive:before, i.ph-arrow-down-right.icon-warn:before, i.ph-arrow-down-right.icon-sign-out:before, i.ph-arrow-down-right.icon-substratum:before, i.ph-arrow-down-right.icon-environment:before, i.ph-arrow-down-right.icon-user:before, i.ph-arrow-down-right.icon-person:before, i.ph-arrow-down-right.icon-terrain:before, i.ph-arrow-down-right.icon-taxon:before, i.ph-arrow-down-right.icon-unknown:before, i.ph-arrow-down-right.icon-nothing:before, i.ph-arrow-down-right.icon-sampling:before, i.ph-arrow-down-right.icon-isolation:before, i.ph-arrow-down-right.icon-strain:before, i.ph-arrow-down-right.icon-gps:before, i.ph-arrow-down-right.icon-location:before, i.ph-arrow-down-right.icon-add:before, i.ph-arrow-down-right.icon-left:before, i.ph-arrow-down-right.icon-dna:before, i.ph-arrow-down-right.icon-delete:before, i.ph-arrow-down-right.icon-edit:before, i.ph-arrow-down-right.icon-info:before {
  content: "\e042";
}

.ph.ph-arrow-elbow-down-left:before, i.ph-arrow-elbow-down-left.icon-dead:before, i.ph-arrow-elbow-down-left.icon-alive:before, i.ph-arrow-elbow-down-left.icon-warn:before, i.ph-arrow-elbow-down-left.icon-sign-out:before, i.ph-arrow-elbow-down-left.icon-substratum:before, i.ph-arrow-elbow-down-left.icon-environment:before, i.ph-arrow-elbow-down-left.icon-user:before, i.ph-arrow-elbow-down-left.icon-person:before, i.ph-arrow-elbow-down-left.icon-terrain:before, i.ph-arrow-elbow-down-left.icon-taxon:before, i.ph-arrow-elbow-down-left.icon-unknown:before, i.ph-arrow-elbow-down-left.icon-nothing:before, i.ph-arrow-elbow-down-left.icon-sampling:before, i.ph-arrow-elbow-down-left.icon-isolation:before, i.ph-arrow-elbow-down-left.icon-strain:before, i.ph-arrow-elbow-down-left.icon-gps:before, i.ph-arrow-elbow-down-left.icon-location:before, i.ph-arrow-elbow-down-left.icon-add:before, i.ph-arrow-elbow-down-left.icon-left:before, i.ph-arrow-elbow-down-left.icon-dna:before, i.ph-arrow-elbow-down-left.icon-delete:before, i.ph-arrow-elbow-down-left.icon-edit:before, i.ph-arrow-elbow-down-left.icon-info:before {
  content: "\e044";
}

.ph.ph-arrow-elbow-down-right:before, i.ph-arrow-elbow-down-right.icon-dead:before, i.ph-arrow-elbow-down-right.icon-alive:before, i.ph-arrow-elbow-down-right.icon-warn:before, i.ph-arrow-elbow-down-right.icon-sign-out:before, i.ph-arrow-elbow-down-right.icon-substratum:before, i.ph-arrow-elbow-down-right.icon-environment:before, i.ph-arrow-elbow-down-right.icon-user:before, i.ph-arrow-elbow-down-right.icon-person:before, i.ph-arrow-elbow-down-right.icon-terrain:before, i.ph-arrow-elbow-down-right.icon-taxon:before, i.ph-arrow-elbow-down-right.icon-unknown:before, i.ph-arrow-elbow-down-right.icon-nothing:before, i.ph-arrow-elbow-down-right.icon-sampling:before, i.ph-arrow-elbow-down-right.icon-isolation:before, i.ph-arrow-elbow-down-right.icon-strain:before, i.ph-arrow-elbow-down-right.icon-gps:before, i.ph-arrow-elbow-down-right.icon-location:before, i.ph-arrow-elbow-down-right.icon-add:before, i.ph-arrow-elbow-down-right.icon-left:before, i.ph-arrow-elbow-down-right.icon-dna:before, i.ph-arrow-elbow-down-right.icon-delete:before, i.ph-arrow-elbow-down-right.icon-edit:before, i.ph-arrow-elbow-down-right.icon-info:before {
  content: "\e046";
}

.ph.ph-arrow-elbow-left:before, i.ph-arrow-elbow-left.icon-dead:before, i.ph-arrow-elbow-left.icon-alive:before, i.ph-arrow-elbow-left.icon-warn:before, i.ph-arrow-elbow-left.icon-sign-out:before, i.ph-arrow-elbow-left.icon-substratum:before, i.ph-arrow-elbow-left.icon-environment:before, i.ph-arrow-elbow-left.icon-user:before, i.ph-arrow-elbow-left.icon-person:before, i.ph-arrow-elbow-left.icon-terrain:before, i.ph-arrow-elbow-left.icon-taxon:before, i.ph-arrow-elbow-left.icon-unknown:before, i.ph-arrow-elbow-left.icon-nothing:before, i.ph-arrow-elbow-left.icon-sampling:before, i.ph-arrow-elbow-left.icon-isolation:before, i.ph-arrow-elbow-left.icon-strain:before, i.ph-arrow-elbow-left.icon-gps:before, i.ph-arrow-elbow-left.icon-location:before, i.ph-arrow-elbow-left.icon-add:before, i.ph-arrow-elbow-left.icon-left:before, i.ph-arrow-elbow-left.icon-dna:before, i.ph-arrow-elbow-left.icon-delete:before, i.ph-arrow-elbow-left.icon-edit:before, i.ph-arrow-elbow-left.icon-info:before {
  content: "\e048";
}

.ph.ph-arrow-elbow-left-down:before, i.ph-arrow-elbow-left-down.icon-dead:before, i.ph-arrow-elbow-left-down.icon-alive:before, i.ph-arrow-elbow-left-down.icon-warn:before, i.ph-arrow-elbow-left-down.icon-sign-out:before, i.ph-arrow-elbow-left-down.icon-substratum:before, i.ph-arrow-elbow-left-down.icon-environment:before, i.ph-arrow-elbow-left-down.icon-user:before, i.ph-arrow-elbow-left-down.icon-person:before, i.ph-arrow-elbow-left-down.icon-terrain:before, i.ph-arrow-elbow-left-down.icon-taxon:before, i.ph-arrow-elbow-left-down.icon-unknown:before, i.ph-arrow-elbow-left-down.icon-nothing:before, i.ph-arrow-elbow-left-down.icon-sampling:before, i.ph-arrow-elbow-left-down.icon-isolation:before, i.ph-arrow-elbow-left-down.icon-strain:before, i.ph-arrow-elbow-left-down.icon-gps:before, i.ph-arrow-elbow-left-down.icon-location:before, i.ph-arrow-elbow-left-down.icon-add:before, i.ph-arrow-elbow-left-down.icon-left:before, i.ph-arrow-elbow-left-down.icon-dna:before, i.ph-arrow-elbow-left-down.icon-delete:before, i.ph-arrow-elbow-left-down.icon-edit:before, i.ph-arrow-elbow-left-down.icon-info:before {
  content: "\e04a";
}

.ph.ph-arrow-elbow-left-up:before, i.ph-arrow-elbow-left-up.icon-dead:before, i.ph-arrow-elbow-left-up.icon-alive:before, i.ph-arrow-elbow-left-up.icon-warn:before, i.ph-arrow-elbow-left-up.icon-sign-out:before, i.ph-arrow-elbow-left-up.icon-substratum:before, i.ph-arrow-elbow-left-up.icon-environment:before, i.ph-arrow-elbow-left-up.icon-user:before, i.ph-arrow-elbow-left-up.icon-person:before, i.ph-arrow-elbow-left-up.icon-terrain:before, i.ph-arrow-elbow-left-up.icon-taxon:before, i.ph-arrow-elbow-left-up.icon-unknown:before, i.ph-arrow-elbow-left-up.icon-nothing:before, i.ph-arrow-elbow-left-up.icon-sampling:before, i.ph-arrow-elbow-left-up.icon-isolation:before, i.ph-arrow-elbow-left-up.icon-strain:before, i.ph-arrow-elbow-left-up.icon-gps:before, i.ph-arrow-elbow-left-up.icon-location:before, i.ph-arrow-elbow-left-up.icon-add:before, i.ph-arrow-elbow-left-up.icon-left:before, i.ph-arrow-elbow-left-up.icon-dna:before, i.ph-arrow-elbow-left-up.icon-delete:before, i.ph-arrow-elbow-left-up.icon-edit:before, i.ph-arrow-elbow-left-up.icon-info:before {
  content: "\e04c";
}

.ph.ph-arrow-elbow-right:before, i.ph-arrow-elbow-right.icon-dead:before, i.ph-arrow-elbow-right.icon-alive:before, i.ph-arrow-elbow-right.icon-warn:before, i.ph-arrow-elbow-right.icon-sign-out:before, i.ph-arrow-elbow-right.icon-substratum:before, i.ph-arrow-elbow-right.icon-environment:before, i.ph-arrow-elbow-right.icon-user:before, i.ph-arrow-elbow-right.icon-person:before, i.ph-arrow-elbow-right.icon-terrain:before, i.ph-arrow-elbow-right.icon-taxon:before, i.ph-arrow-elbow-right.icon-unknown:before, i.ph-arrow-elbow-right.icon-nothing:before, i.ph-arrow-elbow-right.icon-sampling:before, i.ph-arrow-elbow-right.icon-isolation:before, i.ph-arrow-elbow-right.icon-strain:before, i.ph-arrow-elbow-right.icon-gps:before, i.ph-arrow-elbow-right.icon-location:before, i.ph-arrow-elbow-right.icon-add:before, i.ph-arrow-elbow-right.icon-left:before, i.ph-arrow-elbow-right.icon-dna:before, i.ph-arrow-elbow-right.icon-delete:before, i.ph-arrow-elbow-right.icon-edit:before, i.ph-arrow-elbow-right.icon-info:before {
  content: "\e04e";
}

.ph.ph-arrow-elbow-right-down:before, i.ph-arrow-elbow-right-down.icon-dead:before, i.ph-arrow-elbow-right-down.icon-alive:before, i.ph-arrow-elbow-right-down.icon-warn:before, i.ph-arrow-elbow-right-down.icon-sign-out:before, i.ph-arrow-elbow-right-down.icon-substratum:before, i.ph-arrow-elbow-right-down.icon-environment:before, i.ph-arrow-elbow-right-down.icon-user:before, i.ph-arrow-elbow-right-down.icon-person:before, i.ph-arrow-elbow-right-down.icon-terrain:before, i.ph-arrow-elbow-right-down.icon-taxon:before, i.ph-arrow-elbow-right-down.icon-unknown:before, i.ph-arrow-elbow-right-down.icon-nothing:before, i.ph-arrow-elbow-right-down.icon-sampling:before, i.ph-arrow-elbow-right-down.icon-isolation:before, i.ph-arrow-elbow-right-down.icon-strain:before, i.ph-arrow-elbow-right-down.icon-gps:before, i.ph-arrow-elbow-right-down.icon-location:before, i.ph-arrow-elbow-right-down.icon-add:before, i.ph-arrow-elbow-right-down.icon-left:before, i.ph-arrow-elbow-right-down.icon-dna:before, i.ph-arrow-elbow-right-down.icon-delete:before, i.ph-arrow-elbow-right-down.icon-edit:before, i.ph-arrow-elbow-right-down.icon-info:before {
  content: "\e050";
}

.ph.ph-arrow-elbow-right-up:before, i.ph-arrow-elbow-right-up.icon-dead:before, i.ph-arrow-elbow-right-up.icon-alive:before, i.ph-arrow-elbow-right-up.icon-warn:before, i.ph-arrow-elbow-right-up.icon-sign-out:before, i.ph-arrow-elbow-right-up.icon-substratum:before, i.ph-arrow-elbow-right-up.icon-environment:before, i.ph-arrow-elbow-right-up.icon-user:before, i.ph-arrow-elbow-right-up.icon-person:before, i.ph-arrow-elbow-right-up.icon-terrain:before, i.ph-arrow-elbow-right-up.icon-taxon:before, i.ph-arrow-elbow-right-up.icon-unknown:before, i.ph-arrow-elbow-right-up.icon-nothing:before, i.ph-arrow-elbow-right-up.icon-sampling:before, i.ph-arrow-elbow-right-up.icon-isolation:before, i.ph-arrow-elbow-right-up.icon-strain:before, i.ph-arrow-elbow-right-up.icon-gps:before, i.ph-arrow-elbow-right-up.icon-location:before, i.ph-arrow-elbow-right-up.icon-add:before, i.ph-arrow-elbow-right-up.icon-left:before, i.ph-arrow-elbow-right-up.icon-dna:before, i.ph-arrow-elbow-right-up.icon-delete:before, i.ph-arrow-elbow-right-up.icon-edit:before, i.ph-arrow-elbow-right-up.icon-info:before {
  content: "\e052";
}

.ph.ph-arrow-elbow-up-left:before, i.ph-arrow-elbow-up-left.icon-dead:before, i.ph-arrow-elbow-up-left.icon-alive:before, i.ph-arrow-elbow-up-left.icon-warn:before, i.ph-arrow-elbow-up-left.icon-sign-out:before, i.ph-arrow-elbow-up-left.icon-substratum:before, i.ph-arrow-elbow-up-left.icon-environment:before, i.ph-arrow-elbow-up-left.icon-user:before, i.ph-arrow-elbow-up-left.icon-person:before, i.ph-arrow-elbow-up-left.icon-terrain:before, i.ph-arrow-elbow-up-left.icon-taxon:before, i.ph-arrow-elbow-up-left.icon-unknown:before, i.ph-arrow-elbow-up-left.icon-nothing:before, i.ph-arrow-elbow-up-left.icon-sampling:before, i.ph-arrow-elbow-up-left.icon-isolation:before, i.ph-arrow-elbow-up-left.icon-strain:before, i.ph-arrow-elbow-up-left.icon-gps:before, i.ph-arrow-elbow-up-left.icon-location:before, i.ph-arrow-elbow-up-left.icon-add:before, i.ph-arrow-elbow-up-left.icon-left:before, i.ph-arrow-elbow-up-left.icon-dna:before, i.ph-arrow-elbow-up-left.icon-delete:before, i.ph-arrow-elbow-up-left.icon-edit:before, i.ph-arrow-elbow-up-left.icon-info:before {
  content: "\e054";
}

.ph.ph-arrow-elbow-up-right:before, i.ph-arrow-elbow-up-right.icon-dead:before, i.ph-arrow-elbow-up-right.icon-alive:before, i.ph-arrow-elbow-up-right.icon-warn:before, i.ph-arrow-elbow-up-right.icon-sign-out:before, i.ph-arrow-elbow-up-right.icon-substratum:before, i.ph-arrow-elbow-up-right.icon-environment:before, i.ph-arrow-elbow-up-right.icon-user:before, i.ph-arrow-elbow-up-right.icon-person:before, i.ph-arrow-elbow-up-right.icon-terrain:before, i.ph-arrow-elbow-up-right.icon-taxon:before, i.ph-arrow-elbow-up-right.icon-unknown:before, i.ph-arrow-elbow-up-right.icon-nothing:before, i.ph-arrow-elbow-up-right.icon-sampling:before, i.ph-arrow-elbow-up-right.icon-isolation:before, i.ph-arrow-elbow-up-right.icon-strain:before, i.ph-arrow-elbow-up-right.icon-gps:before, i.ph-arrow-elbow-up-right.icon-location:before, i.ph-arrow-elbow-up-right.icon-add:before, i.ph-arrow-elbow-up-right.icon-left:before, i.ph-arrow-elbow-up-right.icon-dna:before, i.ph-arrow-elbow-up-right.icon-delete:before, i.ph-arrow-elbow-up-right.icon-edit:before, i.ph-arrow-elbow-up-right.icon-info:before {
  content: "\e056";
}

.ph.ph-arrow-fat-down:before, i.ph-arrow-fat-down.icon-dead:before, i.ph-arrow-fat-down.icon-alive:before, i.ph-arrow-fat-down.icon-warn:before, i.ph-arrow-fat-down.icon-sign-out:before, i.ph-arrow-fat-down.icon-substratum:before, i.ph-arrow-fat-down.icon-environment:before, i.ph-arrow-fat-down.icon-user:before, i.ph-arrow-fat-down.icon-person:before, i.ph-arrow-fat-down.icon-terrain:before, i.ph-arrow-fat-down.icon-taxon:before, i.ph-arrow-fat-down.icon-unknown:before, i.ph-arrow-fat-down.icon-nothing:before, i.ph-arrow-fat-down.icon-sampling:before, i.ph-arrow-fat-down.icon-isolation:before, i.ph-arrow-fat-down.icon-strain:before, i.ph-arrow-fat-down.icon-gps:before, i.ph-arrow-fat-down.icon-location:before, i.ph-arrow-fat-down.icon-add:before, i.ph-arrow-fat-down.icon-left:before, i.ph-arrow-fat-down.icon-dna:before, i.ph-arrow-fat-down.icon-delete:before, i.ph-arrow-fat-down.icon-edit:before, i.ph-arrow-fat-down.icon-info:before {
  content: "\e518";
}

.ph.ph-arrow-fat-left:before, i.ph-arrow-fat-left.icon-dead:before, i.ph-arrow-fat-left.icon-alive:before, i.ph-arrow-fat-left.icon-warn:before, i.ph-arrow-fat-left.icon-sign-out:before, i.ph-arrow-fat-left.icon-substratum:before, i.ph-arrow-fat-left.icon-environment:before, i.ph-arrow-fat-left.icon-user:before, i.ph-arrow-fat-left.icon-person:before, i.ph-arrow-fat-left.icon-terrain:before, i.ph-arrow-fat-left.icon-taxon:before, i.ph-arrow-fat-left.icon-unknown:before, i.ph-arrow-fat-left.icon-nothing:before, i.ph-arrow-fat-left.icon-sampling:before, i.ph-arrow-fat-left.icon-isolation:before, i.ph-arrow-fat-left.icon-strain:before, i.ph-arrow-fat-left.icon-gps:before, i.ph-arrow-fat-left.icon-location:before, i.ph-arrow-fat-left.icon-add:before, i.ph-arrow-fat-left.icon-left:before, i.ph-arrow-fat-left.icon-dna:before, i.ph-arrow-fat-left.icon-delete:before, i.ph-arrow-fat-left.icon-edit:before, i.ph-arrow-fat-left.icon-info:before {
  content: "\e51a";
}

.ph.ph-arrow-fat-line-down:before, i.ph-arrow-fat-line-down.icon-dead:before, i.ph-arrow-fat-line-down.icon-alive:before, i.ph-arrow-fat-line-down.icon-warn:before, i.ph-arrow-fat-line-down.icon-sign-out:before, i.ph-arrow-fat-line-down.icon-substratum:before, i.ph-arrow-fat-line-down.icon-environment:before, i.ph-arrow-fat-line-down.icon-user:before, i.ph-arrow-fat-line-down.icon-person:before, i.ph-arrow-fat-line-down.icon-terrain:before, i.ph-arrow-fat-line-down.icon-taxon:before, i.ph-arrow-fat-line-down.icon-unknown:before, i.ph-arrow-fat-line-down.icon-nothing:before, i.ph-arrow-fat-line-down.icon-sampling:before, i.ph-arrow-fat-line-down.icon-isolation:before, i.ph-arrow-fat-line-down.icon-strain:before, i.ph-arrow-fat-line-down.icon-gps:before, i.ph-arrow-fat-line-down.icon-location:before, i.ph-arrow-fat-line-down.icon-add:before, i.ph-arrow-fat-line-down.icon-left:before, i.ph-arrow-fat-line-down.icon-dna:before, i.ph-arrow-fat-line-down.icon-delete:before, i.ph-arrow-fat-line-down.icon-edit:before, i.ph-arrow-fat-line-down.icon-info:before {
  content: "\e51c";
}

.ph.ph-arrow-fat-line-left:before, i.ph-arrow-fat-line-left.icon-dead:before, i.ph-arrow-fat-line-left.icon-alive:before, i.ph-arrow-fat-line-left.icon-warn:before, i.ph-arrow-fat-line-left.icon-sign-out:before, i.ph-arrow-fat-line-left.icon-substratum:before, i.ph-arrow-fat-line-left.icon-environment:before, i.ph-arrow-fat-line-left.icon-user:before, i.ph-arrow-fat-line-left.icon-person:before, i.ph-arrow-fat-line-left.icon-terrain:before, i.ph-arrow-fat-line-left.icon-taxon:before, i.ph-arrow-fat-line-left.icon-unknown:before, i.ph-arrow-fat-line-left.icon-nothing:before, i.ph-arrow-fat-line-left.icon-sampling:before, i.ph-arrow-fat-line-left.icon-isolation:before, i.ph-arrow-fat-line-left.icon-strain:before, i.ph-arrow-fat-line-left.icon-gps:before, i.ph-arrow-fat-line-left.icon-location:before, i.ph-arrow-fat-line-left.icon-add:before, i.ph-arrow-fat-line-left.icon-left:before, i.ph-arrow-fat-line-left.icon-dna:before, i.ph-arrow-fat-line-left.icon-delete:before, i.ph-arrow-fat-line-left.icon-edit:before, i.ph-arrow-fat-line-left.icon-info:before {
  content: "\e51e";
}

.ph.ph-arrow-fat-line-right:before, i.ph-arrow-fat-line-right.icon-dead:before, i.ph-arrow-fat-line-right.icon-alive:before, i.ph-arrow-fat-line-right.icon-warn:before, i.ph-arrow-fat-line-right.icon-sign-out:before, i.ph-arrow-fat-line-right.icon-substratum:before, i.ph-arrow-fat-line-right.icon-environment:before, i.ph-arrow-fat-line-right.icon-user:before, i.ph-arrow-fat-line-right.icon-person:before, i.ph-arrow-fat-line-right.icon-terrain:before, i.ph-arrow-fat-line-right.icon-taxon:before, i.ph-arrow-fat-line-right.icon-unknown:before, i.ph-arrow-fat-line-right.icon-nothing:before, i.ph-arrow-fat-line-right.icon-sampling:before, i.ph-arrow-fat-line-right.icon-isolation:before, i.ph-arrow-fat-line-right.icon-strain:before, i.ph-arrow-fat-line-right.icon-gps:before, i.ph-arrow-fat-line-right.icon-location:before, i.ph-arrow-fat-line-right.icon-add:before, i.ph-arrow-fat-line-right.icon-left:before, i.ph-arrow-fat-line-right.icon-dna:before, i.ph-arrow-fat-line-right.icon-delete:before, i.ph-arrow-fat-line-right.icon-edit:before, i.ph-arrow-fat-line-right.icon-info:before {
  content: "\e520";
}

.ph.ph-arrow-fat-line-up:before, i.ph-arrow-fat-line-up.icon-dead:before, i.ph-arrow-fat-line-up.icon-alive:before, i.ph-arrow-fat-line-up.icon-warn:before, i.ph-arrow-fat-line-up.icon-sign-out:before, i.ph-arrow-fat-line-up.icon-substratum:before, i.ph-arrow-fat-line-up.icon-environment:before, i.ph-arrow-fat-line-up.icon-user:before, i.ph-arrow-fat-line-up.icon-person:before, i.ph-arrow-fat-line-up.icon-terrain:before, i.ph-arrow-fat-line-up.icon-taxon:before, i.ph-arrow-fat-line-up.icon-unknown:before, i.ph-arrow-fat-line-up.icon-nothing:before, i.ph-arrow-fat-line-up.icon-sampling:before, i.ph-arrow-fat-line-up.icon-isolation:before, i.ph-arrow-fat-line-up.icon-strain:before, i.ph-arrow-fat-line-up.icon-gps:before, i.ph-arrow-fat-line-up.icon-location:before, i.ph-arrow-fat-line-up.icon-add:before, i.ph-arrow-fat-line-up.icon-left:before, i.ph-arrow-fat-line-up.icon-dna:before, i.ph-arrow-fat-line-up.icon-delete:before, i.ph-arrow-fat-line-up.icon-edit:before, i.ph-arrow-fat-line-up.icon-info:before {
  content: "\e522";
}

.ph.ph-arrow-fat-lines-down:before, i.ph-arrow-fat-lines-down.icon-dead:before, i.ph-arrow-fat-lines-down.icon-alive:before, i.ph-arrow-fat-lines-down.icon-warn:before, i.ph-arrow-fat-lines-down.icon-sign-out:before, i.ph-arrow-fat-lines-down.icon-substratum:before, i.ph-arrow-fat-lines-down.icon-environment:before, i.ph-arrow-fat-lines-down.icon-user:before, i.ph-arrow-fat-lines-down.icon-person:before, i.ph-arrow-fat-lines-down.icon-terrain:before, i.ph-arrow-fat-lines-down.icon-taxon:before, i.ph-arrow-fat-lines-down.icon-unknown:before, i.ph-arrow-fat-lines-down.icon-nothing:before, i.ph-arrow-fat-lines-down.icon-sampling:before, i.ph-arrow-fat-lines-down.icon-isolation:before, i.ph-arrow-fat-lines-down.icon-strain:before, i.ph-arrow-fat-lines-down.icon-gps:before, i.ph-arrow-fat-lines-down.icon-location:before, i.ph-arrow-fat-lines-down.icon-add:before, i.ph-arrow-fat-lines-down.icon-left:before, i.ph-arrow-fat-lines-down.icon-dna:before, i.ph-arrow-fat-lines-down.icon-delete:before, i.ph-arrow-fat-lines-down.icon-edit:before, i.ph-arrow-fat-lines-down.icon-info:before {
  content: "\e524";
}

.ph.ph-arrow-fat-lines-left:before, i.ph-arrow-fat-lines-left.icon-dead:before, i.ph-arrow-fat-lines-left.icon-alive:before, i.ph-arrow-fat-lines-left.icon-warn:before, i.ph-arrow-fat-lines-left.icon-sign-out:before, i.ph-arrow-fat-lines-left.icon-substratum:before, i.ph-arrow-fat-lines-left.icon-environment:before, i.ph-arrow-fat-lines-left.icon-user:before, i.ph-arrow-fat-lines-left.icon-person:before, i.ph-arrow-fat-lines-left.icon-terrain:before, i.ph-arrow-fat-lines-left.icon-taxon:before, i.ph-arrow-fat-lines-left.icon-unknown:before, i.ph-arrow-fat-lines-left.icon-nothing:before, i.ph-arrow-fat-lines-left.icon-sampling:before, i.ph-arrow-fat-lines-left.icon-isolation:before, i.ph-arrow-fat-lines-left.icon-strain:before, i.ph-arrow-fat-lines-left.icon-gps:before, i.ph-arrow-fat-lines-left.icon-location:before, i.ph-arrow-fat-lines-left.icon-add:before, i.ph-arrow-fat-lines-left.icon-left:before, i.ph-arrow-fat-lines-left.icon-dna:before, i.ph-arrow-fat-lines-left.icon-delete:before, i.ph-arrow-fat-lines-left.icon-edit:before, i.ph-arrow-fat-lines-left.icon-info:before {
  content: "\e526";
}

.ph.ph-arrow-fat-lines-right:before, i.ph-arrow-fat-lines-right.icon-dead:before, i.ph-arrow-fat-lines-right.icon-alive:before, i.ph-arrow-fat-lines-right.icon-warn:before, i.ph-arrow-fat-lines-right.icon-sign-out:before, i.ph-arrow-fat-lines-right.icon-substratum:before, i.ph-arrow-fat-lines-right.icon-environment:before, i.ph-arrow-fat-lines-right.icon-user:before, i.ph-arrow-fat-lines-right.icon-person:before, i.ph-arrow-fat-lines-right.icon-terrain:before, i.ph-arrow-fat-lines-right.icon-taxon:before, i.ph-arrow-fat-lines-right.icon-unknown:before, i.ph-arrow-fat-lines-right.icon-nothing:before, i.ph-arrow-fat-lines-right.icon-sampling:before, i.ph-arrow-fat-lines-right.icon-isolation:before, i.ph-arrow-fat-lines-right.icon-strain:before, i.ph-arrow-fat-lines-right.icon-gps:before, i.ph-arrow-fat-lines-right.icon-location:before, i.ph-arrow-fat-lines-right.icon-add:before, i.ph-arrow-fat-lines-right.icon-left:before, i.ph-arrow-fat-lines-right.icon-dna:before, i.ph-arrow-fat-lines-right.icon-delete:before, i.ph-arrow-fat-lines-right.icon-edit:before, i.ph-arrow-fat-lines-right.icon-info:before {
  content: "\e528";
}

.ph.ph-arrow-fat-lines-up:before, i.ph-arrow-fat-lines-up.icon-dead:before, i.ph-arrow-fat-lines-up.icon-alive:before, i.ph-arrow-fat-lines-up.icon-warn:before, i.ph-arrow-fat-lines-up.icon-sign-out:before, i.ph-arrow-fat-lines-up.icon-substratum:before, i.ph-arrow-fat-lines-up.icon-environment:before, i.ph-arrow-fat-lines-up.icon-user:before, i.ph-arrow-fat-lines-up.icon-person:before, i.ph-arrow-fat-lines-up.icon-terrain:before, i.ph-arrow-fat-lines-up.icon-taxon:before, i.ph-arrow-fat-lines-up.icon-unknown:before, i.ph-arrow-fat-lines-up.icon-nothing:before, i.ph-arrow-fat-lines-up.icon-sampling:before, i.ph-arrow-fat-lines-up.icon-isolation:before, i.ph-arrow-fat-lines-up.icon-strain:before, i.ph-arrow-fat-lines-up.icon-gps:before, i.ph-arrow-fat-lines-up.icon-location:before, i.ph-arrow-fat-lines-up.icon-add:before, i.ph-arrow-fat-lines-up.icon-left:before, i.ph-arrow-fat-lines-up.icon-dna:before, i.ph-arrow-fat-lines-up.icon-delete:before, i.ph-arrow-fat-lines-up.icon-edit:before, i.ph-arrow-fat-lines-up.icon-info:before {
  content: "\e52a";
}

.ph.ph-arrow-fat-right:before, i.ph-arrow-fat-right.icon-dead:before, i.ph-arrow-fat-right.icon-alive:before, i.ph-arrow-fat-right.icon-warn:before, i.ph-arrow-fat-right.icon-sign-out:before, i.ph-arrow-fat-right.icon-substratum:before, i.ph-arrow-fat-right.icon-environment:before, i.ph-arrow-fat-right.icon-user:before, i.ph-arrow-fat-right.icon-person:before, i.ph-arrow-fat-right.icon-terrain:before, i.ph-arrow-fat-right.icon-taxon:before, i.ph-arrow-fat-right.icon-unknown:before, i.ph-arrow-fat-right.icon-nothing:before, i.ph-arrow-fat-right.icon-sampling:before, i.ph-arrow-fat-right.icon-isolation:before, i.ph-arrow-fat-right.icon-strain:before, i.ph-arrow-fat-right.icon-gps:before, i.ph-arrow-fat-right.icon-location:before, i.ph-arrow-fat-right.icon-add:before, i.ph-arrow-fat-right.icon-left:before, i.ph-arrow-fat-right.icon-dna:before, i.ph-arrow-fat-right.icon-delete:before, i.ph-arrow-fat-right.icon-edit:before, i.ph-arrow-fat-right.icon-info:before {
  content: "\e52c";
}

.ph.ph-arrow-fat-up:before, i.ph-arrow-fat-up.icon-dead:before, i.ph-arrow-fat-up.icon-alive:before, i.ph-arrow-fat-up.icon-warn:before, i.ph-arrow-fat-up.icon-sign-out:before, i.ph-arrow-fat-up.icon-substratum:before, i.ph-arrow-fat-up.icon-environment:before, i.ph-arrow-fat-up.icon-user:before, i.ph-arrow-fat-up.icon-person:before, i.ph-arrow-fat-up.icon-terrain:before, i.ph-arrow-fat-up.icon-taxon:before, i.ph-arrow-fat-up.icon-unknown:before, i.ph-arrow-fat-up.icon-nothing:before, i.ph-arrow-fat-up.icon-sampling:before, i.ph-arrow-fat-up.icon-isolation:before, i.ph-arrow-fat-up.icon-strain:before, i.ph-arrow-fat-up.icon-gps:before, i.ph-arrow-fat-up.icon-location:before, i.ph-arrow-fat-up.icon-add:before, i.ph-arrow-fat-up.icon-left:before, i.ph-arrow-fat-up.icon-dna:before, i.ph-arrow-fat-up.icon-delete:before, i.ph-arrow-fat-up.icon-edit:before, i.ph-arrow-fat-up.icon-info:before {
  content: "\e52e";
}

.ph.ph-arrow-left:before, i.ph-arrow-left.icon-dead:before, i.ph-arrow-left.icon-alive:before, i.ph-arrow-left.icon-warn:before, i.ph-arrow-left.icon-sign-out:before, i.ph-arrow-left.icon-substratum:before, i.ph-arrow-left.icon-environment:before, i.ph-arrow-left.icon-user:before, i.ph-arrow-left.icon-person:before, i.ph-arrow-left.icon-terrain:before, i.ph-arrow-left.icon-taxon:before, i.ph-arrow-left.icon-unknown:before, i.ph-arrow-left.icon-nothing:before, i.ph-arrow-left.icon-sampling:before, i.ph-arrow-left.icon-isolation:before, i.ph-arrow-left.icon-strain:before, i.ph-arrow-left.icon-gps:before, i.ph-arrow-left.icon-location:before, i.ph-arrow-left.icon-add:before, i.ph-arrow-left.icon-left:before, i.ph-arrow-left.icon-dna:before, i.ph-arrow-left.icon-delete:before, i.ph-arrow-left.icon-edit:before, i.ph-arrow-left.icon-info:before {
  content: "\e058";
}

.ph.ph-arrow-line-down:before, i.ph-arrow-line-down.icon-dead:before, i.ph-arrow-line-down.icon-alive:before, i.ph-arrow-line-down.icon-warn:before, i.ph-arrow-line-down.icon-sign-out:before, i.ph-arrow-line-down.icon-substratum:before, i.ph-arrow-line-down.icon-environment:before, i.ph-arrow-line-down.icon-user:before, i.ph-arrow-line-down.icon-person:before, i.ph-arrow-line-down.icon-terrain:before, i.ph-arrow-line-down.icon-taxon:before, i.ph-arrow-line-down.icon-unknown:before, i.ph-arrow-line-down.icon-nothing:before, i.ph-arrow-line-down.icon-sampling:before, i.ph-arrow-line-down.icon-isolation:before, i.ph-arrow-line-down.icon-strain:before, i.ph-arrow-line-down.icon-gps:before, i.ph-arrow-line-down.icon-location:before, i.ph-arrow-line-down.icon-add:before, i.ph-arrow-line-down.icon-left:before, i.ph-arrow-line-down.icon-dna:before, i.ph-arrow-line-down.icon-delete:before, i.ph-arrow-line-down.icon-edit:before, i.ph-arrow-line-down.icon-info:before {
  content: "\e05c";
}

.ph.ph-arrow-line-down-left:before, i.ph-arrow-line-down-left.icon-dead:before, i.ph-arrow-line-down-left.icon-alive:before, i.ph-arrow-line-down-left.icon-warn:before, i.ph-arrow-line-down-left.icon-sign-out:before, i.ph-arrow-line-down-left.icon-substratum:before, i.ph-arrow-line-down-left.icon-environment:before, i.ph-arrow-line-down-left.icon-user:before, i.ph-arrow-line-down-left.icon-person:before, i.ph-arrow-line-down-left.icon-terrain:before, i.ph-arrow-line-down-left.icon-taxon:before, i.ph-arrow-line-down-left.icon-unknown:before, i.ph-arrow-line-down-left.icon-nothing:before, i.ph-arrow-line-down-left.icon-sampling:before, i.ph-arrow-line-down-left.icon-isolation:before, i.ph-arrow-line-down-left.icon-strain:before, i.ph-arrow-line-down-left.icon-gps:before, i.ph-arrow-line-down-left.icon-location:before, i.ph-arrow-line-down-left.icon-add:before, i.ph-arrow-line-down-left.icon-left:before, i.ph-arrow-line-down-left.icon-dna:before, i.ph-arrow-line-down-left.icon-delete:before, i.ph-arrow-line-down-left.icon-edit:before, i.ph-arrow-line-down-left.icon-info:before {
  content: "\e05e";
}

.ph.ph-arrow-line-down-right:before, i.ph-arrow-line-down-right.icon-dead:before, i.ph-arrow-line-down-right.icon-alive:before, i.ph-arrow-line-down-right.icon-warn:before, i.ph-arrow-line-down-right.icon-sign-out:before, i.ph-arrow-line-down-right.icon-substratum:before, i.ph-arrow-line-down-right.icon-environment:before, i.ph-arrow-line-down-right.icon-user:before, i.ph-arrow-line-down-right.icon-person:before, i.ph-arrow-line-down-right.icon-terrain:before, i.ph-arrow-line-down-right.icon-taxon:before, i.ph-arrow-line-down-right.icon-unknown:before, i.ph-arrow-line-down-right.icon-nothing:before, i.ph-arrow-line-down-right.icon-sampling:before, i.ph-arrow-line-down-right.icon-isolation:before, i.ph-arrow-line-down-right.icon-strain:before, i.ph-arrow-line-down-right.icon-gps:before, i.ph-arrow-line-down-right.icon-location:before, i.ph-arrow-line-down-right.icon-add:before, i.ph-arrow-line-down-right.icon-left:before, i.ph-arrow-line-down-right.icon-dna:before, i.ph-arrow-line-down-right.icon-delete:before, i.ph-arrow-line-down-right.icon-edit:before, i.ph-arrow-line-down-right.icon-info:before {
  content: "\e060";
}

.ph.ph-arrow-line-left:before, i.ph-arrow-line-left.icon-dead:before, i.ph-arrow-line-left.icon-alive:before, i.ph-arrow-line-left.icon-warn:before, i.ph-arrow-line-left.icon-sign-out:before, i.ph-arrow-line-left.icon-substratum:before, i.ph-arrow-line-left.icon-environment:before, i.ph-arrow-line-left.icon-user:before, i.ph-arrow-line-left.icon-person:before, i.ph-arrow-line-left.icon-terrain:before, i.ph-arrow-line-left.icon-taxon:before, i.ph-arrow-line-left.icon-unknown:before, i.ph-arrow-line-left.icon-nothing:before, i.ph-arrow-line-left.icon-sampling:before, i.ph-arrow-line-left.icon-isolation:before, i.ph-arrow-line-left.icon-strain:before, i.ph-arrow-line-left.icon-gps:before, i.ph-arrow-line-left.icon-location:before, i.ph-arrow-line-left.icon-add:before, i.ph-arrow-line-left.icon-left:before, i.ph-arrow-line-left.icon-dna:before, i.ph-arrow-line-left.icon-delete:before, i.ph-arrow-line-left.icon-edit:before, i.ph-arrow-line-left.icon-info:before {
  content: "\e062";
}

.ph.ph-arrow-line-right:before, i.ph-arrow-line-right.icon-dead:before, i.ph-arrow-line-right.icon-alive:before, i.ph-arrow-line-right.icon-warn:before, i.ph-arrow-line-right.icon-sign-out:before, i.ph-arrow-line-right.icon-substratum:before, i.ph-arrow-line-right.icon-environment:before, i.ph-arrow-line-right.icon-user:before, i.ph-arrow-line-right.icon-person:before, i.ph-arrow-line-right.icon-terrain:before, i.ph-arrow-line-right.icon-taxon:before, i.ph-arrow-line-right.icon-unknown:before, i.ph-arrow-line-right.icon-nothing:before, i.ph-arrow-line-right.icon-sampling:before, i.ph-arrow-line-right.icon-isolation:before, i.ph-arrow-line-right.icon-strain:before, i.ph-arrow-line-right.icon-gps:before, i.ph-arrow-line-right.icon-location:before, i.ph-arrow-line-right.icon-add:before, i.ph-arrow-line-right.icon-left:before, i.ph-arrow-line-right.icon-dna:before, i.ph-arrow-line-right.icon-delete:before, i.ph-arrow-line-right.icon-edit:before, i.ph-arrow-line-right.icon-info:before {
  content: "\e064";
}

.ph.ph-arrow-line-up:before, i.ph-arrow-line-up.icon-dead:before, i.ph-arrow-line-up.icon-alive:before, i.ph-arrow-line-up.icon-warn:before, i.ph-arrow-line-up.icon-sign-out:before, i.ph-arrow-line-up.icon-substratum:before, i.ph-arrow-line-up.icon-environment:before, i.ph-arrow-line-up.icon-user:before, i.ph-arrow-line-up.icon-person:before, i.ph-arrow-line-up.icon-terrain:before, i.ph-arrow-line-up.icon-taxon:before, i.ph-arrow-line-up.icon-unknown:before, i.ph-arrow-line-up.icon-nothing:before, i.ph-arrow-line-up.icon-sampling:before, i.ph-arrow-line-up.icon-isolation:before, i.ph-arrow-line-up.icon-strain:before, i.ph-arrow-line-up.icon-gps:before, i.ph-arrow-line-up.icon-location:before, i.ph-arrow-line-up.icon-add:before, i.ph-arrow-line-up.icon-left:before, i.ph-arrow-line-up.icon-dna:before, i.ph-arrow-line-up.icon-delete:before, i.ph-arrow-line-up.icon-edit:before, i.ph-arrow-line-up.icon-info:before {
  content: "\e066";
}

.ph.ph-arrow-line-up-left:before, i.ph-arrow-line-up-left.icon-dead:before, i.ph-arrow-line-up-left.icon-alive:before, i.ph-arrow-line-up-left.icon-warn:before, i.ph-arrow-line-up-left.icon-sign-out:before, i.ph-arrow-line-up-left.icon-substratum:before, i.ph-arrow-line-up-left.icon-environment:before, i.ph-arrow-line-up-left.icon-user:before, i.ph-arrow-line-up-left.icon-person:before, i.ph-arrow-line-up-left.icon-terrain:before, i.ph-arrow-line-up-left.icon-taxon:before, i.ph-arrow-line-up-left.icon-unknown:before, i.ph-arrow-line-up-left.icon-nothing:before, i.ph-arrow-line-up-left.icon-sampling:before, i.ph-arrow-line-up-left.icon-isolation:before, i.ph-arrow-line-up-left.icon-strain:before, i.ph-arrow-line-up-left.icon-gps:before, i.ph-arrow-line-up-left.icon-location:before, i.ph-arrow-line-up-left.icon-add:before, i.ph-arrow-line-up-left.icon-left:before, i.ph-arrow-line-up-left.icon-dna:before, i.ph-arrow-line-up-left.icon-delete:before, i.ph-arrow-line-up-left.icon-edit:before, i.ph-arrow-line-up-left.icon-info:before {
  content: "\e068";
}

.ph.ph-arrow-line-up-right:before, i.ph-arrow-line-up-right.icon-dead:before, i.ph-arrow-line-up-right.icon-alive:before, i.ph-arrow-line-up-right.icon-warn:before, i.ph-arrow-line-up-right.icon-sign-out:before, i.ph-arrow-line-up-right.icon-substratum:before, i.ph-arrow-line-up-right.icon-environment:before, i.ph-arrow-line-up-right.icon-user:before, i.ph-arrow-line-up-right.icon-person:before, i.ph-arrow-line-up-right.icon-terrain:before, i.ph-arrow-line-up-right.icon-taxon:before, i.ph-arrow-line-up-right.icon-unknown:before, i.ph-arrow-line-up-right.icon-nothing:before, i.ph-arrow-line-up-right.icon-sampling:before, i.ph-arrow-line-up-right.icon-isolation:before, i.ph-arrow-line-up-right.icon-strain:before, i.ph-arrow-line-up-right.icon-gps:before, i.ph-arrow-line-up-right.icon-location:before, i.ph-arrow-line-up-right.icon-add:before, i.ph-arrow-line-up-right.icon-left:before, i.ph-arrow-line-up-right.icon-dna:before, i.ph-arrow-line-up-right.icon-delete:before, i.ph-arrow-line-up-right.icon-edit:before, i.ph-arrow-line-up-right.icon-info:before {
  content: "\e06a";
}

.ph.ph-arrow-right:before, i.ph-arrow-right.icon-dead:before, i.ph-arrow-right.icon-alive:before, i.ph-arrow-right.icon-warn:before, i.ph-arrow-right.icon-sign-out:before, i.ph-arrow-right.icon-substratum:before, i.ph-arrow-right.icon-environment:before, i.ph-arrow-right.icon-user:before, i.ph-arrow-right.icon-person:before, i.ph-arrow-right.icon-terrain:before, i.ph-arrow-right.icon-taxon:before, i.ph-arrow-right.icon-unknown:before, i.ph-arrow-right.icon-nothing:before, i.ph-arrow-right.icon-sampling:before, i.ph-arrow-right.icon-isolation:before, i.ph-arrow-right.icon-strain:before, i.ph-arrow-right.icon-gps:before, i.ph-arrow-right.icon-location:before, i.ph-arrow-right.icon-add:before, i.ph-arrow-right.icon-left:before, i.ph-arrow-right.icon-dna:before, i.ph-arrow-right.icon-delete:before, i.ph-arrow-right.icon-edit:before, i.ph-arrow-right.icon-info:before {
  content: "\e06c";
}

.ph.ph-arrow-square-down:before, i.ph-arrow-square-down.icon-dead:before, i.ph-arrow-square-down.icon-alive:before, i.ph-arrow-square-down.icon-warn:before, i.ph-arrow-square-down.icon-sign-out:before, i.ph-arrow-square-down.icon-substratum:before, i.ph-arrow-square-down.icon-environment:before, i.ph-arrow-square-down.icon-user:before, i.ph-arrow-square-down.icon-person:before, i.ph-arrow-square-down.icon-terrain:before, i.ph-arrow-square-down.icon-taxon:before, i.ph-arrow-square-down.icon-unknown:before, i.ph-arrow-square-down.icon-nothing:before, i.ph-arrow-square-down.icon-sampling:before, i.ph-arrow-square-down.icon-isolation:before, i.ph-arrow-square-down.icon-strain:before, i.ph-arrow-square-down.icon-gps:before, i.ph-arrow-square-down.icon-location:before, i.ph-arrow-square-down.icon-add:before, i.ph-arrow-square-down.icon-left:before, i.ph-arrow-square-down.icon-dna:before, i.ph-arrow-square-down.icon-delete:before, i.ph-arrow-square-down.icon-edit:before, i.ph-arrow-square-down.icon-info:before {
  content: "\e06e";
}

.ph.ph-arrow-square-down-left:before, i.ph-arrow-square-down-left.icon-dead:before, i.ph-arrow-square-down-left.icon-alive:before, i.ph-arrow-square-down-left.icon-warn:before, i.ph-arrow-square-down-left.icon-sign-out:before, i.ph-arrow-square-down-left.icon-substratum:before, i.ph-arrow-square-down-left.icon-environment:before, i.ph-arrow-square-down-left.icon-user:before, i.ph-arrow-square-down-left.icon-person:before, i.ph-arrow-square-down-left.icon-terrain:before, i.ph-arrow-square-down-left.icon-taxon:before, i.ph-arrow-square-down-left.icon-unknown:before, i.ph-arrow-square-down-left.icon-nothing:before, i.ph-arrow-square-down-left.icon-sampling:before, i.ph-arrow-square-down-left.icon-isolation:before, i.ph-arrow-square-down-left.icon-strain:before, i.ph-arrow-square-down-left.icon-gps:before, i.ph-arrow-square-down-left.icon-location:before, i.ph-arrow-square-down-left.icon-add:before, i.ph-arrow-square-down-left.icon-left:before, i.ph-arrow-square-down-left.icon-dna:before, i.ph-arrow-square-down-left.icon-delete:before, i.ph-arrow-square-down-left.icon-edit:before, i.ph-arrow-square-down-left.icon-info:before {
  content: "\e070";
}

.ph.ph-arrow-square-down-right:before, i.ph-arrow-square-down-right.icon-dead:before, i.ph-arrow-square-down-right.icon-alive:before, i.ph-arrow-square-down-right.icon-warn:before, i.ph-arrow-square-down-right.icon-sign-out:before, i.ph-arrow-square-down-right.icon-substratum:before, i.ph-arrow-square-down-right.icon-environment:before, i.ph-arrow-square-down-right.icon-user:before, i.ph-arrow-square-down-right.icon-person:before, i.ph-arrow-square-down-right.icon-terrain:before, i.ph-arrow-square-down-right.icon-taxon:before, i.ph-arrow-square-down-right.icon-unknown:before, i.ph-arrow-square-down-right.icon-nothing:before, i.ph-arrow-square-down-right.icon-sampling:before, i.ph-arrow-square-down-right.icon-isolation:before, i.ph-arrow-square-down-right.icon-strain:before, i.ph-arrow-square-down-right.icon-gps:before, i.ph-arrow-square-down-right.icon-location:before, i.ph-arrow-square-down-right.icon-add:before, i.ph-arrow-square-down-right.icon-left:before, i.ph-arrow-square-down-right.icon-dna:before, i.ph-arrow-square-down-right.icon-delete:before, i.ph-arrow-square-down-right.icon-edit:before, i.ph-arrow-square-down-right.icon-info:before {
  content: "\e072";
}

.ph.ph-arrow-square-in:before, i.ph-arrow-square-in.icon-dead:before, i.ph-arrow-square-in.icon-alive:before, i.ph-arrow-square-in.icon-warn:before, i.ph-arrow-square-in.icon-sign-out:before, i.ph-arrow-square-in.icon-substratum:before, i.ph-arrow-square-in.icon-environment:before, i.ph-arrow-square-in.icon-user:before, i.ph-arrow-square-in.icon-person:before, i.ph-arrow-square-in.icon-terrain:before, i.ph-arrow-square-in.icon-taxon:before, i.ph-arrow-square-in.icon-unknown:before, i.ph-arrow-square-in.icon-nothing:before, i.ph-arrow-square-in.icon-sampling:before, i.ph-arrow-square-in.icon-isolation:before, i.ph-arrow-square-in.icon-strain:before, i.ph-arrow-square-in.icon-gps:before, i.ph-arrow-square-in.icon-location:before, i.ph-arrow-square-in.icon-add:before, i.ph-arrow-square-in.icon-left:before, i.ph-arrow-square-in.icon-dna:before, i.ph-arrow-square-in.icon-delete:before, i.ph-arrow-square-in.icon-edit:before, i.ph-arrow-square-in.icon-info:before {
  content: "\e5dc";
}

.ph.ph-arrow-square-left:before, i.ph-arrow-square-left.icon-dead:before, i.ph-arrow-square-left.icon-alive:before, i.ph-arrow-square-left.icon-warn:before, i.ph-arrow-square-left.icon-sign-out:before, i.ph-arrow-square-left.icon-substratum:before, i.ph-arrow-square-left.icon-environment:before, i.ph-arrow-square-left.icon-user:before, i.ph-arrow-square-left.icon-person:before, i.ph-arrow-square-left.icon-terrain:before, i.ph-arrow-square-left.icon-taxon:before, i.ph-arrow-square-left.icon-unknown:before, i.ph-arrow-square-left.icon-nothing:before, i.ph-arrow-square-left.icon-sampling:before, i.ph-arrow-square-left.icon-isolation:before, i.ph-arrow-square-left.icon-strain:before, i.ph-arrow-square-left.icon-gps:before, i.ph-arrow-square-left.icon-location:before, i.ph-arrow-square-left.icon-add:before, i.ph-arrow-square-left.icon-left:before, i.ph-arrow-square-left.icon-dna:before, i.ph-arrow-square-left.icon-delete:before, i.ph-arrow-square-left.icon-edit:before, i.ph-arrow-square-left.icon-info:before {
  content: "\e074";
}

.ph.ph-arrow-square-out:before, i.ph-arrow-square-out.icon-dead:before, i.ph-arrow-square-out.icon-alive:before, i.ph-arrow-square-out.icon-warn:before, i.ph-arrow-square-out.icon-sign-out:before, i.ph-arrow-square-out.icon-substratum:before, i.ph-arrow-square-out.icon-environment:before, i.ph-arrow-square-out.icon-user:before, i.ph-arrow-square-out.icon-person:before, i.ph-arrow-square-out.icon-terrain:before, i.ph-arrow-square-out.icon-taxon:before, i.ph-arrow-square-out.icon-unknown:before, i.ph-arrow-square-out.icon-nothing:before, i.ph-arrow-square-out.icon-sampling:before, i.ph-arrow-square-out.icon-isolation:before, i.ph-arrow-square-out.icon-strain:before, i.ph-arrow-square-out.icon-gps:before, i.ph-arrow-square-out.icon-location:before, i.ph-arrow-square-out.icon-add:before, i.ph-arrow-square-out.icon-left:before, i.ph-arrow-square-out.icon-dna:before, i.ph-arrow-square-out.icon-delete:before, i.ph-arrow-square-out.icon-edit:before, i.ph-arrow-square-out.icon-info:before {
  content: "\e5de";
}

.ph.ph-arrow-square-right:before, i.ph-arrow-square-right.icon-dead:before, i.ph-arrow-square-right.icon-alive:before, i.ph-arrow-square-right.icon-warn:before, i.ph-arrow-square-right.icon-sign-out:before, i.ph-arrow-square-right.icon-substratum:before, i.ph-arrow-square-right.icon-environment:before, i.ph-arrow-square-right.icon-user:before, i.ph-arrow-square-right.icon-person:before, i.ph-arrow-square-right.icon-terrain:before, i.ph-arrow-square-right.icon-taxon:before, i.ph-arrow-square-right.icon-unknown:before, i.ph-arrow-square-right.icon-nothing:before, i.ph-arrow-square-right.icon-sampling:before, i.ph-arrow-square-right.icon-isolation:before, i.ph-arrow-square-right.icon-strain:before, i.ph-arrow-square-right.icon-gps:before, i.ph-arrow-square-right.icon-location:before, i.ph-arrow-square-right.icon-add:before, i.ph-arrow-square-right.icon-left:before, i.ph-arrow-square-right.icon-dna:before, i.ph-arrow-square-right.icon-delete:before, i.ph-arrow-square-right.icon-edit:before, i.ph-arrow-square-right.icon-info:before {
  content: "\e076";
}

.ph.ph-arrow-square-up:before, i.ph-arrow-square-up.icon-dead:before, i.ph-arrow-square-up.icon-alive:before, i.ph-arrow-square-up.icon-warn:before, i.ph-arrow-square-up.icon-sign-out:before, i.ph-arrow-square-up.icon-substratum:before, i.ph-arrow-square-up.icon-environment:before, i.ph-arrow-square-up.icon-user:before, i.ph-arrow-square-up.icon-person:before, i.ph-arrow-square-up.icon-terrain:before, i.ph-arrow-square-up.icon-taxon:before, i.ph-arrow-square-up.icon-unknown:before, i.ph-arrow-square-up.icon-nothing:before, i.ph-arrow-square-up.icon-sampling:before, i.ph-arrow-square-up.icon-isolation:before, i.ph-arrow-square-up.icon-strain:before, i.ph-arrow-square-up.icon-gps:before, i.ph-arrow-square-up.icon-location:before, i.ph-arrow-square-up.icon-add:before, i.ph-arrow-square-up.icon-left:before, i.ph-arrow-square-up.icon-dna:before, i.ph-arrow-square-up.icon-delete:before, i.ph-arrow-square-up.icon-edit:before, i.ph-arrow-square-up.icon-info:before {
  content: "\e078";
}

.ph.ph-arrow-square-up-left:before, i.ph-arrow-square-up-left.icon-dead:before, i.ph-arrow-square-up-left.icon-alive:before, i.ph-arrow-square-up-left.icon-warn:before, i.ph-arrow-square-up-left.icon-sign-out:before, i.ph-arrow-square-up-left.icon-substratum:before, i.ph-arrow-square-up-left.icon-environment:before, i.ph-arrow-square-up-left.icon-user:before, i.ph-arrow-square-up-left.icon-person:before, i.ph-arrow-square-up-left.icon-terrain:before, i.ph-arrow-square-up-left.icon-taxon:before, i.ph-arrow-square-up-left.icon-unknown:before, i.ph-arrow-square-up-left.icon-nothing:before, i.ph-arrow-square-up-left.icon-sampling:before, i.ph-arrow-square-up-left.icon-isolation:before, i.ph-arrow-square-up-left.icon-strain:before, i.ph-arrow-square-up-left.icon-gps:before, i.ph-arrow-square-up-left.icon-location:before, i.ph-arrow-square-up-left.icon-add:before, i.ph-arrow-square-up-left.icon-left:before, i.ph-arrow-square-up-left.icon-dna:before, i.ph-arrow-square-up-left.icon-delete:before, i.ph-arrow-square-up-left.icon-edit:before, i.ph-arrow-square-up-left.icon-info:before {
  content: "\e07a";
}

.ph.ph-arrow-square-up-right:before, i.ph-arrow-square-up-right.icon-dead:before, i.ph-arrow-square-up-right.icon-alive:before, i.ph-arrow-square-up-right.icon-warn:before, i.ph-arrow-square-up-right.icon-sign-out:before, i.ph-arrow-square-up-right.icon-substratum:before, i.ph-arrow-square-up-right.icon-environment:before, i.ph-arrow-square-up-right.icon-user:before, i.ph-arrow-square-up-right.icon-person:before, i.ph-arrow-square-up-right.icon-terrain:before, i.ph-arrow-square-up-right.icon-taxon:before, i.ph-arrow-square-up-right.icon-unknown:before, i.ph-arrow-square-up-right.icon-nothing:before, i.ph-arrow-square-up-right.icon-sampling:before, i.ph-arrow-square-up-right.icon-isolation:before, i.ph-arrow-square-up-right.icon-strain:before, i.ph-arrow-square-up-right.icon-gps:before, i.ph-arrow-square-up-right.icon-location:before, i.ph-arrow-square-up-right.icon-add:before, i.ph-arrow-square-up-right.icon-left:before, i.ph-arrow-square-up-right.icon-dna:before, i.ph-arrow-square-up-right.icon-delete:before, i.ph-arrow-square-up-right.icon-edit:before, i.ph-arrow-square-up-right.icon-info:before {
  content: "\e07c";
}

.ph.ph-arrow-u-down-left:before, i.ph-arrow-u-down-left.icon-dead:before, i.ph-arrow-u-down-left.icon-alive:before, i.ph-arrow-u-down-left.icon-warn:before, i.ph-arrow-u-down-left.icon-sign-out:before, i.ph-arrow-u-down-left.icon-substratum:before, i.ph-arrow-u-down-left.icon-environment:before, i.ph-arrow-u-down-left.icon-user:before, i.ph-arrow-u-down-left.icon-person:before, i.ph-arrow-u-down-left.icon-terrain:before, i.ph-arrow-u-down-left.icon-taxon:before, i.ph-arrow-u-down-left.icon-unknown:before, i.ph-arrow-u-down-left.icon-nothing:before, i.ph-arrow-u-down-left.icon-sampling:before, i.ph-arrow-u-down-left.icon-isolation:before, i.ph-arrow-u-down-left.icon-strain:before, i.ph-arrow-u-down-left.icon-gps:before, i.ph-arrow-u-down-left.icon-location:before, i.ph-arrow-u-down-left.icon-add:before, i.ph-arrow-u-down-left.icon-left:before, i.ph-arrow-u-down-left.icon-dna:before, i.ph-arrow-u-down-left.icon-delete:before, i.ph-arrow-u-down-left.icon-edit:before, i.ph-arrow-u-down-left.icon-info:before {
  content: "\e07e";
}

.ph.ph-arrow-u-down-right:before, i.ph-arrow-u-down-right.icon-dead:before, i.ph-arrow-u-down-right.icon-alive:before, i.ph-arrow-u-down-right.icon-warn:before, i.ph-arrow-u-down-right.icon-sign-out:before, i.ph-arrow-u-down-right.icon-substratum:before, i.ph-arrow-u-down-right.icon-environment:before, i.ph-arrow-u-down-right.icon-user:before, i.ph-arrow-u-down-right.icon-person:before, i.ph-arrow-u-down-right.icon-terrain:before, i.ph-arrow-u-down-right.icon-taxon:before, i.ph-arrow-u-down-right.icon-unknown:before, i.ph-arrow-u-down-right.icon-nothing:before, i.ph-arrow-u-down-right.icon-sampling:before, i.ph-arrow-u-down-right.icon-isolation:before, i.ph-arrow-u-down-right.icon-strain:before, i.ph-arrow-u-down-right.icon-gps:before, i.ph-arrow-u-down-right.icon-location:before, i.ph-arrow-u-down-right.icon-add:before, i.ph-arrow-u-down-right.icon-left:before, i.ph-arrow-u-down-right.icon-dna:before, i.ph-arrow-u-down-right.icon-delete:before, i.ph-arrow-u-down-right.icon-edit:before, i.ph-arrow-u-down-right.icon-info:before {
  content: "\e080";
}

.ph.ph-arrow-u-left-down:before, i.ph-arrow-u-left-down.icon-dead:before, i.ph-arrow-u-left-down.icon-alive:before, i.ph-arrow-u-left-down.icon-warn:before, i.ph-arrow-u-left-down.icon-sign-out:before, i.ph-arrow-u-left-down.icon-substratum:before, i.ph-arrow-u-left-down.icon-environment:before, i.ph-arrow-u-left-down.icon-user:before, i.ph-arrow-u-left-down.icon-person:before, i.ph-arrow-u-left-down.icon-terrain:before, i.ph-arrow-u-left-down.icon-taxon:before, i.ph-arrow-u-left-down.icon-unknown:before, i.ph-arrow-u-left-down.icon-nothing:before, i.ph-arrow-u-left-down.icon-sampling:before, i.ph-arrow-u-left-down.icon-isolation:before, i.ph-arrow-u-left-down.icon-strain:before, i.ph-arrow-u-left-down.icon-gps:before, i.ph-arrow-u-left-down.icon-location:before, i.ph-arrow-u-left-down.icon-add:before, i.ph-arrow-u-left-down.icon-left:before, i.ph-arrow-u-left-down.icon-dna:before, i.ph-arrow-u-left-down.icon-delete:before, i.ph-arrow-u-left-down.icon-edit:before, i.ph-arrow-u-left-down.icon-info:before {
  content: "\e082";
}

.ph.ph-arrow-u-left-up:before, i.ph-arrow-u-left-up.icon-dead:before, i.ph-arrow-u-left-up.icon-alive:before, i.ph-arrow-u-left-up.icon-warn:before, i.ph-arrow-u-left-up.icon-sign-out:before, i.ph-arrow-u-left-up.icon-substratum:before, i.ph-arrow-u-left-up.icon-environment:before, i.ph-arrow-u-left-up.icon-user:before, i.ph-arrow-u-left-up.icon-person:before, i.ph-arrow-u-left-up.icon-terrain:before, i.ph-arrow-u-left-up.icon-taxon:before, i.ph-arrow-u-left-up.icon-unknown:before, i.ph-arrow-u-left-up.icon-nothing:before, i.ph-arrow-u-left-up.icon-sampling:before, i.ph-arrow-u-left-up.icon-isolation:before, i.ph-arrow-u-left-up.icon-strain:before, i.ph-arrow-u-left-up.icon-gps:before, i.ph-arrow-u-left-up.icon-location:before, i.ph-arrow-u-left-up.icon-add:before, i.ph-arrow-u-left-up.icon-left:before, i.ph-arrow-u-left-up.icon-dna:before, i.ph-arrow-u-left-up.icon-delete:before, i.ph-arrow-u-left-up.icon-edit:before, i.ph-arrow-u-left-up.icon-info:before {
  content: "\e084";
}

.ph.ph-arrow-u-right-down:before, i.ph-arrow-u-right-down.icon-dead:before, i.ph-arrow-u-right-down.icon-alive:before, i.ph-arrow-u-right-down.icon-warn:before, i.ph-arrow-u-right-down.icon-sign-out:before, i.ph-arrow-u-right-down.icon-substratum:before, i.ph-arrow-u-right-down.icon-environment:before, i.ph-arrow-u-right-down.icon-user:before, i.ph-arrow-u-right-down.icon-person:before, i.ph-arrow-u-right-down.icon-terrain:before, i.ph-arrow-u-right-down.icon-taxon:before, i.ph-arrow-u-right-down.icon-unknown:before, i.ph-arrow-u-right-down.icon-nothing:before, i.ph-arrow-u-right-down.icon-sampling:before, i.ph-arrow-u-right-down.icon-isolation:before, i.ph-arrow-u-right-down.icon-strain:before, i.ph-arrow-u-right-down.icon-gps:before, i.ph-arrow-u-right-down.icon-location:before, i.ph-arrow-u-right-down.icon-add:before, i.ph-arrow-u-right-down.icon-left:before, i.ph-arrow-u-right-down.icon-dna:before, i.ph-arrow-u-right-down.icon-delete:before, i.ph-arrow-u-right-down.icon-edit:before, i.ph-arrow-u-right-down.icon-info:before {
  content: "\e086";
}

.ph.ph-arrow-u-right-up:before, i.ph-arrow-u-right-up.icon-dead:before, i.ph-arrow-u-right-up.icon-alive:before, i.ph-arrow-u-right-up.icon-warn:before, i.ph-arrow-u-right-up.icon-sign-out:before, i.ph-arrow-u-right-up.icon-substratum:before, i.ph-arrow-u-right-up.icon-environment:before, i.ph-arrow-u-right-up.icon-user:before, i.ph-arrow-u-right-up.icon-person:before, i.ph-arrow-u-right-up.icon-terrain:before, i.ph-arrow-u-right-up.icon-taxon:before, i.ph-arrow-u-right-up.icon-unknown:before, i.ph-arrow-u-right-up.icon-nothing:before, i.ph-arrow-u-right-up.icon-sampling:before, i.ph-arrow-u-right-up.icon-isolation:before, i.ph-arrow-u-right-up.icon-strain:before, i.ph-arrow-u-right-up.icon-gps:before, i.ph-arrow-u-right-up.icon-location:before, i.ph-arrow-u-right-up.icon-add:before, i.ph-arrow-u-right-up.icon-left:before, i.ph-arrow-u-right-up.icon-dna:before, i.ph-arrow-u-right-up.icon-delete:before, i.ph-arrow-u-right-up.icon-edit:before, i.ph-arrow-u-right-up.icon-info:before {
  content: "\e088";
}

.ph.ph-arrow-u-up-left:before, i.ph-arrow-u-up-left.icon-dead:before, i.ph-arrow-u-up-left.icon-alive:before, i.ph-arrow-u-up-left.icon-warn:before, i.ph-arrow-u-up-left.icon-sign-out:before, i.ph-arrow-u-up-left.icon-substratum:before, i.ph-arrow-u-up-left.icon-environment:before, i.ph-arrow-u-up-left.icon-user:before, i.ph-arrow-u-up-left.icon-person:before, i.ph-arrow-u-up-left.icon-terrain:before, i.ph-arrow-u-up-left.icon-taxon:before, i.ph-arrow-u-up-left.icon-unknown:before, i.ph-arrow-u-up-left.icon-nothing:before, i.ph-arrow-u-up-left.icon-sampling:before, i.ph-arrow-u-up-left.icon-isolation:before, i.ph-arrow-u-up-left.icon-strain:before, i.ph-arrow-u-up-left.icon-gps:before, i.ph-arrow-u-up-left.icon-location:before, i.ph-arrow-u-up-left.icon-add:before, i.ph-arrow-u-up-left.icon-left:before, i.ph-arrow-u-up-left.icon-dna:before, i.ph-arrow-u-up-left.icon-delete:before, i.ph-arrow-u-up-left.icon-edit:before, i.ph-arrow-u-up-left.icon-info:before {
  content: "\e08a";
}

.ph.ph-arrow-u-up-right:before, i.ph-arrow-u-up-right.icon-dead:before, i.ph-arrow-u-up-right.icon-alive:before, i.ph-arrow-u-up-right.icon-warn:before, i.ph-arrow-u-up-right.icon-sign-out:before, i.ph-arrow-u-up-right.icon-substratum:before, i.ph-arrow-u-up-right.icon-environment:before, i.ph-arrow-u-up-right.icon-user:before, i.ph-arrow-u-up-right.icon-person:before, i.ph-arrow-u-up-right.icon-terrain:before, i.ph-arrow-u-up-right.icon-taxon:before, i.ph-arrow-u-up-right.icon-unknown:before, i.ph-arrow-u-up-right.icon-nothing:before, i.ph-arrow-u-up-right.icon-sampling:before, i.ph-arrow-u-up-right.icon-isolation:before, i.ph-arrow-u-up-right.icon-strain:before, i.ph-arrow-u-up-right.icon-gps:before, i.ph-arrow-u-up-right.icon-location:before, i.ph-arrow-u-up-right.icon-add:before, i.ph-arrow-u-up-right.icon-left:before, i.ph-arrow-u-up-right.icon-dna:before, i.ph-arrow-u-up-right.icon-delete:before, i.ph-arrow-u-up-right.icon-edit:before, i.ph-arrow-u-up-right.icon-info:before {
  content: "\e08c";
}

.ph.ph-arrow-up:before, i.ph-arrow-up.icon-dead:before, i.ph-arrow-up.icon-alive:before, i.ph-arrow-up.icon-warn:before, i.ph-arrow-up.icon-sign-out:before, i.ph-arrow-up.icon-substratum:before, i.ph-arrow-up.icon-environment:before, i.ph-arrow-up.icon-user:before, i.ph-arrow-up.icon-person:before, i.ph-arrow-up.icon-terrain:before, i.ph-arrow-up.icon-taxon:before, i.ph-arrow-up.icon-unknown:before, i.ph-arrow-up.icon-nothing:before, i.ph-arrow-up.icon-sampling:before, i.ph-arrow-up.icon-isolation:before, i.ph-arrow-up.icon-strain:before, i.ph-arrow-up.icon-gps:before, i.ph-arrow-up.icon-location:before, i.ph-arrow-up.icon-add:before, i.ph-arrow-up.icon-left:before, i.ph-arrow-up.icon-dna:before, i.ph-arrow-up.icon-delete:before, i.ph-arrow-up.icon-edit:before, i.ph-arrow-up.icon-info:before {
  content: "\e08e";
}

.ph.ph-arrow-up-left:before, i.ph-arrow-up-left.icon-dead:before, i.ph-arrow-up-left.icon-alive:before, i.ph-arrow-up-left.icon-warn:before, i.ph-arrow-up-left.icon-sign-out:before, i.ph-arrow-up-left.icon-substratum:before, i.ph-arrow-up-left.icon-environment:before, i.ph-arrow-up-left.icon-user:before, i.ph-arrow-up-left.icon-person:before, i.ph-arrow-up-left.icon-terrain:before, i.ph-arrow-up-left.icon-taxon:before, i.ph-arrow-up-left.icon-unknown:before, i.ph-arrow-up-left.icon-nothing:before, i.ph-arrow-up-left.icon-sampling:before, i.ph-arrow-up-left.icon-isolation:before, i.ph-arrow-up-left.icon-strain:before, i.ph-arrow-up-left.icon-gps:before, i.ph-arrow-up-left.icon-location:before, i.ph-arrow-up-left.icon-add:before, i.ph-arrow-up-left.icon-left:before, i.ph-arrow-up-left.icon-dna:before, i.ph-arrow-up-left.icon-delete:before, i.ph-arrow-up-left.icon-edit:before, i.ph-arrow-up-left.icon-info:before {
  content: "\e090";
}

.ph.ph-arrow-up-right:before, i.ph-arrow-up-right.icon-dead:before, i.ph-arrow-up-right.icon-alive:before, i.ph-arrow-up-right.icon-warn:before, i.ph-arrow-up-right.icon-sign-out:before, i.ph-arrow-up-right.icon-substratum:before, i.ph-arrow-up-right.icon-environment:before, i.ph-arrow-up-right.icon-user:before, i.ph-arrow-up-right.icon-person:before, i.ph-arrow-up-right.icon-terrain:before, i.ph-arrow-up-right.icon-taxon:before, i.ph-arrow-up-right.icon-unknown:before, i.ph-arrow-up-right.icon-nothing:before, i.ph-arrow-up-right.icon-sampling:before, i.ph-arrow-up-right.icon-isolation:before, i.ph-arrow-up-right.icon-strain:before, i.ph-arrow-up-right.icon-gps:before, i.ph-arrow-up-right.icon-location:before, i.ph-arrow-up-right.icon-add:before, i.ph-arrow-up-right.icon-left:before, i.ph-arrow-up-right.icon-dna:before, i.ph-arrow-up-right.icon-delete:before, i.ph-arrow-up-right.icon-edit:before, i.ph-arrow-up-right.icon-info:before {
  content: "\e092";
}

.ph.ph-arrows-clockwise:before, i.ph-arrows-clockwise.icon-dead:before, i.ph-arrows-clockwise.icon-alive:before, i.ph-arrows-clockwise.icon-warn:before, i.ph-arrows-clockwise.icon-sign-out:before, i.ph-arrows-clockwise.icon-substratum:before, i.ph-arrows-clockwise.icon-environment:before, i.ph-arrows-clockwise.icon-user:before, i.ph-arrows-clockwise.icon-person:before, i.ph-arrows-clockwise.icon-terrain:before, i.ph-arrows-clockwise.icon-taxon:before, i.ph-arrows-clockwise.icon-unknown:before, i.ph-arrows-clockwise.icon-nothing:before, i.ph-arrows-clockwise.icon-sampling:before, i.ph-arrows-clockwise.icon-isolation:before, i.ph-arrows-clockwise.icon-strain:before, i.ph-arrows-clockwise.icon-gps:before, i.ph-arrows-clockwise.icon-location:before, i.ph-arrows-clockwise.icon-add:before, i.ph-arrows-clockwise.icon-left:before, i.ph-arrows-clockwise.icon-dna:before, i.ph-arrows-clockwise.icon-delete:before, i.ph-arrows-clockwise.icon-edit:before, i.ph-arrows-clockwise.icon-info:before {
  content: "\e094";
}

.ph.ph-arrows-counter-clockwise:before, i.ph-arrows-counter-clockwise.icon-dead:before, i.ph-arrows-counter-clockwise.icon-alive:before, i.ph-arrows-counter-clockwise.icon-warn:before, i.ph-arrows-counter-clockwise.icon-sign-out:before, i.ph-arrows-counter-clockwise.icon-substratum:before, i.ph-arrows-counter-clockwise.icon-environment:before, i.ph-arrows-counter-clockwise.icon-user:before, i.ph-arrows-counter-clockwise.icon-person:before, i.ph-arrows-counter-clockwise.icon-terrain:before, i.ph-arrows-counter-clockwise.icon-taxon:before, i.ph-arrows-counter-clockwise.icon-unknown:before, i.ph-arrows-counter-clockwise.icon-nothing:before, i.ph-arrows-counter-clockwise.icon-sampling:before, i.ph-arrows-counter-clockwise.icon-isolation:before, i.ph-arrows-counter-clockwise.icon-strain:before, i.ph-arrows-counter-clockwise.icon-gps:before, i.ph-arrows-counter-clockwise.icon-location:before, i.ph-arrows-counter-clockwise.icon-add:before, i.ph-arrows-counter-clockwise.icon-left:before, i.ph-arrows-counter-clockwise.icon-dna:before, i.ph-arrows-counter-clockwise.icon-delete:before, i.ph-arrows-counter-clockwise.icon-edit:before, i.ph-arrows-counter-clockwise.icon-info:before {
  content: "\e096";
}

.ph.ph-arrows-down-up:before, i.ph-arrows-down-up.icon-dead:before, i.ph-arrows-down-up.icon-alive:before, i.ph-arrows-down-up.icon-warn:before, i.ph-arrows-down-up.icon-sign-out:before, i.ph-arrows-down-up.icon-substratum:before, i.ph-arrows-down-up.icon-environment:before, i.ph-arrows-down-up.icon-user:before, i.ph-arrows-down-up.icon-person:before, i.ph-arrows-down-up.icon-terrain:before, i.ph-arrows-down-up.icon-taxon:before, i.ph-arrows-down-up.icon-unknown:before, i.ph-arrows-down-up.icon-nothing:before, i.ph-arrows-down-up.icon-sampling:before, i.ph-arrows-down-up.icon-isolation:before, i.ph-arrows-down-up.icon-strain:before, i.ph-arrows-down-up.icon-gps:before, i.ph-arrows-down-up.icon-location:before, i.ph-arrows-down-up.icon-add:before, i.ph-arrows-down-up.icon-left:before, i.ph-arrows-down-up.icon-dna:before, i.ph-arrows-down-up.icon-delete:before, i.ph-arrows-down-up.icon-edit:before, i.ph-arrows-down-up.icon-info:before {
  content: "\e098";
}

.ph.ph-arrows-horizontal:before, i.ph-arrows-horizontal.icon-dead:before, i.ph-arrows-horizontal.icon-alive:before, i.ph-arrows-horizontal.icon-warn:before, i.ph-arrows-horizontal.icon-sign-out:before, i.ph-arrows-horizontal.icon-substratum:before, i.ph-arrows-horizontal.icon-environment:before, i.ph-arrows-horizontal.icon-user:before, i.ph-arrows-horizontal.icon-person:before, i.ph-arrows-horizontal.icon-terrain:before, i.ph-arrows-horizontal.icon-taxon:before, i.ph-arrows-horizontal.icon-unknown:before, i.ph-arrows-horizontal.icon-nothing:before, i.ph-arrows-horizontal.icon-sampling:before, i.ph-arrows-horizontal.icon-isolation:before, i.ph-arrows-horizontal.icon-strain:before, i.ph-arrows-horizontal.icon-gps:before, i.ph-arrows-horizontal.icon-location:before, i.ph-arrows-horizontal.icon-add:before, i.ph-arrows-horizontal.icon-left:before, i.ph-arrows-horizontal.icon-dna:before, i.ph-arrows-horizontal.icon-delete:before, i.ph-arrows-horizontal.icon-edit:before, i.ph-arrows-horizontal.icon-info:before {
  content: "\eb06";
}

.ph.ph-arrows-in:before, i.ph-arrows-in.icon-dead:before, i.ph-arrows-in.icon-alive:before, i.ph-arrows-in.icon-warn:before, i.ph-arrows-in.icon-sign-out:before, i.ph-arrows-in.icon-substratum:before, i.ph-arrows-in.icon-environment:before, i.ph-arrows-in.icon-user:before, i.ph-arrows-in.icon-person:before, i.ph-arrows-in.icon-terrain:before, i.ph-arrows-in.icon-taxon:before, i.ph-arrows-in.icon-unknown:before, i.ph-arrows-in.icon-nothing:before, i.ph-arrows-in.icon-sampling:before, i.ph-arrows-in.icon-isolation:before, i.ph-arrows-in.icon-strain:before, i.ph-arrows-in.icon-gps:before, i.ph-arrows-in.icon-location:before, i.ph-arrows-in.icon-add:before, i.ph-arrows-in.icon-left:before, i.ph-arrows-in.icon-dna:before, i.ph-arrows-in.icon-delete:before, i.ph-arrows-in.icon-edit:before, i.ph-arrows-in.icon-info:before {
  content: "\e09a";
}

.ph.ph-arrows-in-cardinal:before, i.ph-arrows-in-cardinal.icon-dead:before, i.ph-arrows-in-cardinal.icon-alive:before, i.ph-arrows-in-cardinal.icon-warn:before, i.ph-arrows-in-cardinal.icon-sign-out:before, i.ph-arrows-in-cardinal.icon-substratum:before, i.ph-arrows-in-cardinal.icon-environment:before, i.ph-arrows-in-cardinal.icon-user:before, i.ph-arrows-in-cardinal.icon-person:before, i.ph-arrows-in-cardinal.icon-terrain:before, i.ph-arrows-in-cardinal.icon-taxon:before, i.ph-arrows-in-cardinal.icon-unknown:before, i.ph-arrows-in-cardinal.icon-nothing:before, i.ph-arrows-in-cardinal.icon-sampling:before, i.ph-arrows-in-cardinal.icon-isolation:before, i.ph-arrows-in-cardinal.icon-strain:before, i.ph-arrows-in-cardinal.icon-gps:before, i.ph-arrows-in-cardinal.icon-location:before, i.ph-arrows-in-cardinal.icon-add:before, i.ph-arrows-in-cardinal.icon-left:before, i.ph-arrows-in-cardinal.icon-dna:before, i.ph-arrows-in-cardinal.icon-delete:before, i.ph-arrows-in-cardinal.icon-edit:before, i.ph-arrows-in-cardinal.icon-info:before {
  content: "\e09c";
}

.ph.ph-arrows-in-line-horizontal:before, i.ph-arrows-in-line-horizontal.icon-dead:before, i.ph-arrows-in-line-horizontal.icon-alive:before, i.ph-arrows-in-line-horizontal.icon-warn:before, i.ph-arrows-in-line-horizontal.icon-sign-out:before, i.ph-arrows-in-line-horizontal.icon-substratum:before, i.ph-arrows-in-line-horizontal.icon-environment:before, i.ph-arrows-in-line-horizontal.icon-user:before, i.ph-arrows-in-line-horizontal.icon-person:before, i.ph-arrows-in-line-horizontal.icon-terrain:before, i.ph-arrows-in-line-horizontal.icon-taxon:before, i.ph-arrows-in-line-horizontal.icon-unknown:before, i.ph-arrows-in-line-horizontal.icon-nothing:before, i.ph-arrows-in-line-horizontal.icon-sampling:before, i.ph-arrows-in-line-horizontal.icon-isolation:before, i.ph-arrows-in-line-horizontal.icon-strain:before, i.ph-arrows-in-line-horizontal.icon-gps:before, i.ph-arrows-in-line-horizontal.icon-location:before, i.ph-arrows-in-line-horizontal.icon-add:before, i.ph-arrows-in-line-horizontal.icon-left:before, i.ph-arrows-in-line-horizontal.icon-dna:before, i.ph-arrows-in-line-horizontal.icon-delete:before, i.ph-arrows-in-line-horizontal.icon-edit:before, i.ph-arrows-in-line-horizontal.icon-info:before {
  content: "\e530";
}

.ph.ph-arrows-in-line-vertical:before, i.ph-arrows-in-line-vertical.icon-dead:before, i.ph-arrows-in-line-vertical.icon-alive:before, i.ph-arrows-in-line-vertical.icon-warn:before, i.ph-arrows-in-line-vertical.icon-sign-out:before, i.ph-arrows-in-line-vertical.icon-substratum:before, i.ph-arrows-in-line-vertical.icon-environment:before, i.ph-arrows-in-line-vertical.icon-user:before, i.ph-arrows-in-line-vertical.icon-person:before, i.ph-arrows-in-line-vertical.icon-terrain:before, i.ph-arrows-in-line-vertical.icon-taxon:before, i.ph-arrows-in-line-vertical.icon-unknown:before, i.ph-arrows-in-line-vertical.icon-nothing:before, i.ph-arrows-in-line-vertical.icon-sampling:before, i.ph-arrows-in-line-vertical.icon-isolation:before, i.ph-arrows-in-line-vertical.icon-strain:before, i.ph-arrows-in-line-vertical.icon-gps:before, i.ph-arrows-in-line-vertical.icon-location:before, i.ph-arrows-in-line-vertical.icon-add:before, i.ph-arrows-in-line-vertical.icon-left:before, i.ph-arrows-in-line-vertical.icon-dna:before, i.ph-arrows-in-line-vertical.icon-delete:before, i.ph-arrows-in-line-vertical.icon-edit:before, i.ph-arrows-in-line-vertical.icon-info:before {
  content: "\e532";
}

.ph.ph-arrows-in-simple:before, i.ph-arrows-in-simple.icon-dead:before, i.ph-arrows-in-simple.icon-alive:before, i.ph-arrows-in-simple.icon-warn:before, i.ph-arrows-in-simple.icon-sign-out:before, i.ph-arrows-in-simple.icon-substratum:before, i.ph-arrows-in-simple.icon-environment:before, i.ph-arrows-in-simple.icon-user:before, i.ph-arrows-in-simple.icon-person:before, i.ph-arrows-in-simple.icon-terrain:before, i.ph-arrows-in-simple.icon-taxon:before, i.ph-arrows-in-simple.icon-unknown:before, i.ph-arrows-in-simple.icon-nothing:before, i.ph-arrows-in-simple.icon-sampling:before, i.ph-arrows-in-simple.icon-isolation:before, i.ph-arrows-in-simple.icon-strain:before, i.ph-arrows-in-simple.icon-gps:before, i.ph-arrows-in-simple.icon-location:before, i.ph-arrows-in-simple.icon-add:before, i.ph-arrows-in-simple.icon-left:before, i.ph-arrows-in-simple.icon-dna:before, i.ph-arrows-in-simple.icon-delete:before, i.ph-arrows-in-simple.icon-edit:before, i.ph-arrows-in-simple.icon-info:before {
  content: "\e09e";
}

.ph.ph-arrows-left-right:before, i.ph-arrows-left-right.icon-dead:before, i.ph-arrows-left-right.icon-alive:before, i.ph-arrows-left-right.icon-warn:before, i.ph-arrows-left-right.icon-sign-out:before, i.ph-arrows-left-right.icon-substratum:before, i.ph-arrows-left-right.icon-environment:before, i.ph-arrows-left-right.icon-user:before, i.ph-arrows-left-right.icon-person:before, i.ph-arrows-left-right.icon-terrain:before, i.ph-arrows-left-right.icon-taxon:before, i.ph-arrows-left-right.icon-unknown:before, i.ph-arrows-left-right.icon-nothing:before, i.ph-arrows-left-right.icon-sampling:before, i.ph-arrows-left-right.icon-isolation:before, i.ph-arrows-left-right.icon-strain:before, i.ph-arrows-left-right.icon-gps:before, i.ph-arrows-left-right.icon-location:before, i.ph-arrows-left-right.icon-add:before, i.ph-arrows-left-right.icon-left:before, i.ph-arrows-left-right.icon-dna:before, i.ph-arrows-left-right.icon-delete:before, i.ph-arrows-left-right.icon-edit:before, i.ph-arrows-left-right.icon-info:before {
  content: "\e0a0";
}

.ph.ph-arrows-merge:before, i.ph-arrows-merge.icon-dead:before, i.ph-arrows-merge.icon-alive:before, i.ph-arrows-merge.icon-warn:before, i.ph-arrows-merge.icon-sign-out:before, i.ph-arrows-merge.icon-substratum:before, i.ph-arrows-merge.icon-environment:before, i.ph-arrows-merge.icon-user:before, i.ph-arrows-merge.icon-person:before, i.ph-arrows-merge.icon-terrain:before, i.ph-arrows-merge.icon-taxon:before, i.ph-arrows-merge.icon-unknown:before, i.ph-arrows-merge.icon-nothing:before, i.ph-arrows-merge.icon-sampling:before, i.ph-arrows-merge.icon-isolation:before, i.ph-arrows-merge.icon-strain:before, i.ph-arrows-merge.icon-gps:before, i.ph-arrows-merge.icon-location:before, i.ph-arrows-merge.icon-add:before, i.ph-arrows-merge.icon-left:before, i.ph-arrows-merge.icon-dna:before, i.ph-arrows-merge.icon-delete:before, i.ph-arrows-merge.icon-edit:before, i.ph-arrows-merge.icon-info:before {
  content: "\ed3e";
}

.ph.ph-arrows-out:before, i.ph-arrows-out.icon-dead:before, i.ph-arrows-out.icon-alive:before, i.ph-arrows-out.icon-warn:before, i.ph-arrows-out.icon-sign-out:before, i.ph-arrows-out.icon-substratum:before, i.ph-arrows-out.icon-environment:before, i.ph-arrows-out.icon-user:before, i.ph-arrows-out.icon-person:before, i.ph-arrows-out.icon-terrain:before, i.ph-arrows-out.icon-taxon:before, i.ph-arrows-out.icon-unknown:before, i.ph-arrows-out.icon-nothing:before, i.ph-arrows-out.icon-sampling:before, i.ph-arrows-out.icon-isolation:before, i.ph-arrows-out.icon-strain:before, i.ph-arrows-out.icon-gps:before, i.ph-arrows-out.icon-location:before, i.ph-arrows-out.icon-add:before, i.ph-arrows-out.icon-left:before, i.ph-arrows-out.icon-dna:before, i.ph-arrows-out.icon-delete:before, i.ph-arrows-out.icon-edit:before, i.ph-arrows-out.icon-info:before {
  content: "\e0a2";
}

.ph.ph-arrows-out-cardinal:before, i.ph-arrows-out-cardinal.icon-dead:before, i.ph-arrows-out-cardinal.icon-alive:before, i.ph-arrows-out-cardinal.icon-warn:before, i.ph-arrows-out-cardinal.icon-sign-out:before, i.ph-arrows-out-cardinal.icon-substratum:before, i.ph-arrows-out-cardinal.icon-environment:before, i.ph-arrows-out-cardinal.icon-user:before, i.ph-arrows-out-cardinal.icon-person:before, i.ph-arrows-out-cardinal.icon-terrain:before, i.ph-arrows-out-cardinal.icon-taxon:before, i.ph-arrows-out-cardinal.icon-unknown:before, i.ph-arrows-out-cardinal.icon-nothing:before, i.ph-arrows-out-cardinal.icon-sampling:before, i.ph-arrows-out-cardinal.icon-isolation:before, i.ph-arrows-out-cardinal.icon-strain:before, i.ph-arrows-out-cardinal.icon-gps:before, i.ph-arrows-out-cardinal.icon-location:before, i.ph-arrows-out-cardinal.icon-add:before, i.ph-arrows-out-cardinal.icon-left:before, i.ph-arrows-out-cardinal.icon-dna:before, i.ph-arrows-out-cardinal.icon-delete:before, i.ph-arrows-out-cardinal.icon-edit:before, i.ph-arrows-out-cardinal.icon-info:before {
  content: "\e0a4";
}

.ph.ph-arrows-out-line-horizontal:before, i.ph-arrows-out-line-horizontal.icon-dead:before, i.ph-arrows-out-line-horizontal.icon-alive:before, i.ph-arrows-out-line-horizontal.icon-warn:before, i.ph-arrows-out-line-horizontal.icon-sign-out:before, i.ph-arrows-out-line-horizontal.icon-substratum:before, i.ph-arrows-out-line-horizontal.icon-environment:before, i.ph-arrows-out-line-horizontal.icon-user:before, i.ph-arrows-out-line-horizontal.icon-person:before, i.ph-arrows-out-line-horizontal.icon-terrain:before, i.ph-arrows-out-line-horizontal.icon-taxon:before, i.ph-arrows-out-line-horizontal.icon-unknown:before, i.ph-arrows-out-line-horizontal.icon-nothing:before, i.ph-arrows-out-line-horizontal.icon-sampling:before, i.ph-arrows-out-line-horizontal.icon-isolation:before, i.ph-arrows-out-line-horizontal.icon-strain:before, i.ph-arrows-out-line-horizontal.icon-gps:before, i.ph-arrows-out-line-horizontal.icon-location:before, i.ph-arrows-out-line-horizontal.icon-add:before, i.ph-arrows-out-line-horizontal.icon-left:before, i.ph-arrows-out-line-horizontal.icon-dna:before, i.ph-arrows-out-line-horizontal.icon-delete:before, i.ph-arrows-out-line-horizontal.icon-edit:before, i.ph-arrows-out-line-horizontal.icon-info:before {
  content: "\e534";
}

.ph.ph-arrows-out-line-vertical:before, i.ph-arrows-out-line-vertical.icon-dead:before, i.ph-arrows-out-line-vertical.icon-alive:before, i.ph-arrows-out-line-vertical.icon-warn:before, i.ph-arrows-out-line-vertical.icon-sign-out:before, i.ph-arrows-out-line-vertical.icon-substratum:before, i.ph-arrows-out-line-vertical.icon-environment:before, i.ph-arrows-out-line-vertical.icon-user:before, i.ph-arrows-out-line-vertical.icon-person:before, i.ph-arrows-out-line-vertical.icon-terrain:before, i.ph-arrows-out-line-vertical.icon-taxon:before, i.ph-arrows-out-line-vertical.icon-unknown:before, i.ph-arrows-out-line-vertical.icon-nothing:before, i.ph-arrows-out-line-vertical.icon-sampling:before, i.ph-arrows-out-line-vertical.icon-isolation:before, i.ph-arrows-out-line-vertical.icon-strain:before, i.ph-arrows-out-line-vertical.icon-gps:before, i.ph-arrows-out-line-vertical.icon-location:before, i.ph-arrows-out-line-vertical.icon-add:before, i.ph-arrows-out-line-vertical.icon-left:before, i.ph-arrows-out-line-vertical.icon-dna:before, i.ph-arrows-out-line-vertical.icon-delete:before, i.ph-arrows-out-line-vertical.icon-edit:before, i.ph-arrows-out-line-vertical.icon-info:before {
  content: "\e536";
}

.ph.ph-arrows-out-simple:before, i.ph-arrows-out-simple.icon-dead:before, i.ph-arrows-out-simple.icon-alive:before, i.ph-arrows-out-simple.icon-warn:before, i.ph-arrows-out-simple.icon-sign-out:before, i.ph-arrows-out-simple.icon-substratum:before, i.ph-arrows-out-simple.icon-environment:before, i.ph-arrows-out-simple.icon-user:before, i.ph-arrows-out-simple.icon-person:before, i.ph-arrows-out-simple.icon-terrain:before, i.ph-arrows-out-simple.icon-taxon:before, i.ph-arrows-out-simple.icon-unknown:before, i.ph-arrows-out-simple.icon-nothing:before, i.ph-arrows-out-simple.icon-sampling:before, i.ph-arrows-out-simple.icon-isolation:before, i.ph-arrows-out-simple.icon-strain:before, i.ph-arrows-out-simple.icon-gps:before, i.ph-arrows-out-simple.icon-location:before, i.ph-arrows-out-simple.icon-add:before, i.ph-arrows-out-simple.icon-left:before, i.ph-arrows-out-simple.icon-dna:before, i.ph-arrows-out-simple.icon-delete:before, i.ph-arrows-out-simple.icon-edit:before, i.ph-arrows-out-simple.icon-info:before {
  content: "\e0a6";
}

.ph.ph-arrows-split:before, i.ph-arrows-split.icon-dead:before, i.ph-arrows-split.icon-alive:before, i.ph-arrows-split.icon-warn:before, i.ph-arrows-split.icon-sign-out:before, i.ph-arrows-split.icon-substratum:before, i.ph-arrows-split.icon-environment:before, i.ph-arrows-split.icon-user:before, i.ph-arrows-split.icon-person:before, i.ph-arrows-split.icon-terrain:before, i.ph-arrows-split.icon-taxon:before, i.ph-arrows-split.icon-unknown:before, i.ph-arrows-split.icon-nothing:before, i.ph-arrows-split.icon-sampling:before, i.ph-arrows-split.icon-isolation:before, i.ph-arrows-split.icon-strain:before, i.ph-arrows-split.icon-gps:before, i.ph-arrows-split.icon-location:before, i.ph-arrows-split.icon-add:before, i.ph-arrows-split.icon-left:before, i.ph-arrows-split.icon-dna:before, i.ph-arrows-split.icon-delete:before, i.ph-arrows-split.icon-edit:before, i.ph-arrows-split.icon-info:before {
  content: "\ed3c";
}

.ph.ph-arrows-vertical:before, i.ph-arrows-vertical.icon-dead:before, i.ph-arrows-vertical.icon-alive:before, i.ph-arrows-vertical.icon-warn:before, i.ph-arrows-vertical.icon-sign-out:before, i.ph-arrows-vertical.icon-substratum:before, i.ph-arrows-vertical.icon-environment:before, i.ph-arrows-vertical.icon-user:before, i.ph-arrows-vertical.icon-person:before, i.ph-arrows-vertical.icon-terrain:before, i.ph-arrows-vertical.icon-taxon:before, i.ph-arrows-vertical.icon-unknown:before, i.ph-arrows-vertical.icon-nothing:before, i.ph-arrows-vertical.icon-sampling:before, i.ph-arrows-vertical.icon-isolation:before, i.ph-arrows-vertical.icon-strain:before, i.ph-arrows-vertical.icon-gps:before, i.ph-arrows-vertical.icon-location:before, i.ph-arrows-vertical.icon-add:before, i.ph-arrows-vertical.icon-left:before, i.ph-arrows-vertical.icon-dna:before, i.ph-arrows-vertical.icon-delete:before, i.ph-arrows-vertical.icon-edit:before, i.ph-arrows-vertical.icon-info:before {
  content: "\eb04";
}

.ph.ph-article:before, i.ph-article.icon-dead:before, i.ph-article.icon-alive:before, i.ph-article.icon-warn:before, i.ph-article.icon-sign-out:before, i.ph-article.icon-substratum:before, i.ph-article.icon-environment:before, i.ph-article.icon-user:before, i.ph-article.icon-person:before, i.ph-article.icon-terrain:before, i.ph-article.icon-taxon:before, i.ph-article.icon-unknown:before, i.ph-article.icon-nothing:before, i.ph-article.icon-sampling:before, i.ph-article.icon-isolation:before, i.ph-article.icon-strain:before, i.ph-article.icon-gps:before, i.ph-article.icon-location:before, i.ph-article.icon-add:before, i.ph-article.icon-left:before, i.ph-article.icon-dna:before, i.ph-article.icon-delete:before, i.ph-article.icon-edit:before, i.ph-article.icon-info:before {
  content: "\e0a8";
}

.ph.ph-article-medium:before, i.ph-article-medium.icon-dead:before, i.ph-article-medium.icon-alive:before, i.ph-article-medium.icon-warn:before, i.ph-article-medium.icon-sign-out:before, i.ph-article-medium.icon-substratum:before, i.ph-article-medium.icon-environment:before, i.ph-article-medium.icon-user:before, i.ph-article-medium.icon-person:before, i.ph-article-medium.icon-terrain:before, i.ph-article-medium.icon-taxon:before, i.ph-article-medium.icon-unknown:before, i.ph-article-medium.icon-nothing:before, i.ph-article-medium.icon-sampling:before, i.ph-article-medium.icon-isolation:before, i.ph-article-medium.icon-strain:before, i.ph-article-medium.icon-gps:before, i.ph-article-medium.icon-location:before, i.ph-article-medium.icon-add:before, i.ph-article-medium.icon-left:before, i.ph-article-medium.icon-dna:before, i.ph-article-medium.icon-delete:before, i.ph-article-medium.icon-edit:before, i.ph-article-medium.icon-info:before {
  content: "\e5e0";
}

.ph.ph-article-ny-times:before, i.ph-article-ny-times.icon-dead:before, i.ph-article-ny-times.icon-alive:before, i.ph-article-ny-times.icon-warn:before, i.ph-article-ny-times.icon-sign-out:before, i.ph-article-ny-times.icon-substratum:before, i.ph-article-ny-times.icon-environment:before, i.ph-article-ny-times.icon-user:before, i.ph-article-ny-times.icon-person:before, i.ph-article-ny-times.icon-terrain:before, i.ph-article-ny-times.icon-taxon:before, i.ph-article-ny-times.icon-unknown:before, i.ph-article-ny-times.icon-nothing:before, i.ph-article-ny-times.icon-sampling:before, i.ph-article-ny-times.icon-isolation:before, i.ph-article-ny-times.icon-strain:before, i.ph-article-ny-times.icon-gps:before, i.ph-article-ny-times.icon-location:before, i.ph-article-ny-times.icon-add:before, i.ph-article-ny-times.icon-left:before, i.ph-article-ny-times.icon-dna:before, i.ph-article-ny-times.icon-delete:before, i.ph-article-ny-times.icon-edit:before, i.ph-article-ny-times.icon-info:before {
  content: "\e5e2";
}

.ph.ph-asclepius:before, i.ph-asclepius.icon-dead:before, i.ph-asclepius.icon-alive:before, i.ph-asclepius.icon-warn:before, i.ph-asclepius.icon-sign-out:before, i.ph-asclepius.icon-substratum:before, i.ph-asclepius.icon-environment:before, i.ph-asclepius.icon-user:before, i.ph-asclepius.icon-person:before, i.ph-asclepius.icon-terrain:before, i.ph-asclepius.icon-taxon:before, i.ph-asclepius.icon-unknown:before, i.ph-asclepius.icon-nothing:before, i.ph-asclepius.icon-sampling:before, i.ph-asclepius.icon-isolation:before, i.ph-asclepius.icon-strain:before, i.ph-asclepius.icon-gps:before, i.ph-asclepius.icon-location:before, i.ph-asclepius.icon-add:before, i.ph-asclepius.icon-left:before, i.ph-asclepius.icon-dna:before, i.ph-asclepius.icon-delete:before, i.ph-asclepius.icon-edit:before, i.ph-asclepius.icon-info:before {
  content: "\ee34";
}

.ph.ph-caduceus:before, i.ph-caduceus.icon-dead:before, i.ph-caduceus.icon-alive:before, i.ph-caduceus.icon-warn:before, i.ph-caduceus.icon-sign-out:before, i.ph-caduceus.icon-substratum:before, i.ph-caduceus.icon-environment:before, i.ph-caduceus.icon-user:before, i.ph-caduceus.icon-person:before, i.ph-caduceus.icon-terrain:before, i.ph-caduceus.icon-taxon:before, i.ph-caduceus.icon-unknown:before, i.ph-caduceus.icon-nothing:before, i.ph-caduceus.icon-sampling:before, i.ph-caduceus.icon-isolation:before, i.ph-caduceus.icon-strain:before, i.ph-caduceus.icon-gps:before, i.ph-caduceus.icon-location:before, i.ph-caduceus.icon-add:before, i.ph-caduceus.icon-left:before, i.ph-caduceus.icon-dna:before, i.ph-caduceus.icon-delete:before, i.ph-caduceus.icon-edit:before, i.ph-caduceus.icon-info:before {
  content: "\ee34";
}

.ph.ph-asterisk:before, i.ph-asterisk.icon-dead:before, i.ph-asterisk.icon-alive:before, i.ph-asterisk.icon-warn:before, i.ph-asterisk.icon-sign-out:before, i.ph-asterisk.icon-substratum:before, i.ph-asterisk.icon-environment:before, i.ph-asterisk.icon-user:before, i.ph-asterisk.icon-person:before, i.ph-asterisk.icon-terrain:before, i.ph-asterisk.icon-taxon:before, i.ph-asterisk.icon-unknown:before, i.ph-asterisk.icon-nothing:before, i.ph-asterisk.icon-sampling:before, i.ph-asterisk.icon-isolation:before, i.ph-asterisk.icon-strain:before, i.ph-asterisk.icon-gps:before, i.ph-asterisk.icon-location:before, i.ph-asterisk.icon-add:before, i.ph-asterisk.icon-left:before, i.ph-asterisk.icon-dna:before, i.ph-asterisk.icon-delete:before, i.ph-asterisk.icon-edit:before, i.ph-asterisk.icon-info:before {
  content: "\e0aa";
}

.ph.ph-asterisk-simple:before, i.ph-asterisk-simple.icon-dead:before, i.ph-asterisk-simple.icon-alive:before, i.ph-asterisk-simple.icon-warn:before, i.ph-asterisk-simple.icon-sign-out:before, i.ph-asterisk-simple.icon-substratum:before, i.ph-asterisk-simple.icon-environment:before, i.ph-asterisk-simple.icon-user:before, i.ph-asterisk-simple.icon-person:before, i.ph-asterisk-simple.icon-terrain:before, i.ph-asterisk-simple.icon-taxon:before, i.ph-asterisk-simple.icon-unknown:before, i.ph-asterisk-simple.icon-nothing:before, i.ph-asterisk-simple.icon-sampling:before, i.ph-asterisk-simple.icon-isolation:before, i.ph-asterisk-simple.icon-strain:before, i.ph-asterisk-simple.icon-gps:before, i.ph-asterisk-simple.icon-location:before, i.ph-asterisk-simple.icon-add:before, i.ph-asterisk-simple.icon-left:before, i.ph-asterisk-simple.icon-dna:before, i.ph-asterisk-simple.icon-delete:before, i.ph-asterisk-simple.icon-edit:before, i.ph-asterisk-simple.icon-info:before {
  content: "\e832";
}

.ph.ph-at:before, i.ph-at.icon-dead:before, i.ph-at.icon-alive:before, i.ph-at.icon-warn:before, i.ph-at.icon-sign-out:before, i.ph-at.icon-substratum:before, i.ph-at.icon-environment:before, i.ph-at.icon-user:before, i.ph-at.icon-person:before, i.ph-at.icon-terrain:before, i.ph-at.icon-taxon:before, i.ph-at.icon-unknown:before, i.ph-at.icon-nothing:before, i.ph-at.icon-sampling:before, i.ph-at.icon-isolation:before, i.ph-at.icon-strain:before, i.ph-at.icon-gps:before, i.ph-at.icon-location:before, i.ph-at.icon-add:before, i.ph-at.icon-left:before, i.ph-at.icon-dna:before, i.ph-at.icon-delete:before, i.ph-at.icon-edit:before, i.ph-at.icon-info:before {
  content: "\e0ac";
}

.ph.ph-atom:before, i.ph-atom.icon-dead:before, i.ph-atom.icon-alive:before, i.ph-atom.icon-warn:before, i.ph-atom.icon-sign-out:before, i.ph-atom.icon-substratum:before, i.ph-atom.icon-environment:before, i.ph-atom.icon-user:before, i.ph-atom.icon-person:before, i.ph-atom.icon-terrain:before, i.ph-atom.icon-taxon:before, i.ph-atom.icon-unknown:before, i.ph-atom.icon-nothing:before, i.ph-atom.icon-sampling:before, i.ph-atom.icon-isolation:before, i.ph-atom.icon-strain:before, i.ph-atom.icon-gps:before, i.ph-atom.icon-location:before, i.ph-atom.icon-add:before, i.ph-atom.icon-left:before, i.ph-atom.icon-dna:before, i.ph-atom.icon-delete:before, i.ph-atom.icon-edit:before, i.ph-atom.icon-info:before {
  content: "\e5e4";
}

.ph.ph-avocado:before, i.ph-avocado.icon-dead:before, i.ph-avocado.icon-alive:before, i.ph-avocado.icon-warn:before, i.ph-avocado.icon-sign-out:before, i.ph-avocado.icon-substratum:before, i.ph-avocado.icon-environment:before, i.ph-avocado.icon-user:before, i.ph-avocado.icon-person:before, i.ph-avocado.icon-terrain:before, i.ph-avocado.icon-taxon:before, i.ph-avocado.icon-unknown:before, i.ph-avocado.icon-nothing:before, i.ph-avocado.icon-sampling:before, i.ph-avocado.icon-isolation:before, i.ph-avocado.icon-strain:before, i.ph-avocado.icon-gps:before, i.ph-avocado.icon-location:before, i.ph-avocado.icon-add:before, i.ph-avocado.icon-left:before, i.ph-avocado.icon-dna:before, i.ph-avocado.icon-delete:before, i.ph-avocado.icon-edit:before, i.ph-avocado.icon-info:before {
  content: "\ee04";
}

.ph.ph-axe:before, i.ph-axe.icon-dead:before, i.ph-axe.icon-alive:before, i.ph-axe.icon-warn:before, i.ph-axe.icon-sign-out:before, i.ph-axe.icon-substratum:before, i.ph-axe.icon-environment:before, i.ph-axe.icon-user:before, i.ph-axe.icon-person:before, i.ph-axe.icon-terrain:before, i.ph-axe.icon-taxon:before, i.ph-axe.icon-unknown:before, i.ph-axe.icon-nothing:before, i.ph-axe.icon-sampling:before, i.ph-axe.icon-isolation:before, i.ph-axe.icon-strain:before, i.ph-axe.icon-gps:before, i.ph-axe.icon-location:before, i.ph-axe.icon-add:before, i.ph-axe.icon-left:before, i.ph-axe.icon-dna:before, i.ph-axe.icon-delete:before, i.ph-axe.icon-edit:before, i.ph-axe.icon-info:before {
  content: "\e9fc";
}

.ph.ph-baby:before, i.ph-baby.icon-dead:before, i.ph-baby.icon-alive:before, i.ph-baby.icon-warn:before, i.ph-baby.icon-sign-out:before, i.ph-baby.icon-substratum:before, i.ph-baby.icon-environment:before, i.ph-baby.icon-user:before, i.ph-baby.icon-person:before, i.ph-baby.icon-terrain:before, i.ph-baby.icon-taxon:before, i.ph-baby.icon-unknown:before, i.ph-baby.icon-nothing:before, i.ph-baby.icon-sampling:before, i.ph-baby.icon-isolation:before, i.ph-baby.icon-strain:before, i.ph-baby.icon-gps:before, i.ph-baby.icon-location:before, i.ph-baby.icon-add:before, i.ph-baby.icon-left:before, i.ph-baby.icon-dna:before, i.ph-baby.icon-delete:before, i.ph-baby.icon-edit:before, i.ph-baby.icon-info:before {
  content: "\e774";
}

.ph.ph-baby-carriage:before, i.ph-baby-carriage.icon-dead:before, i.ph-baby-carriage.icon-alive:before, i.ph-baby-carriage.icon-warn:before, i.ph-baby-carriage.icon-sign-out:before, i.ph-baby-carriage.icon-substratum:before, i.ph-baby-carriage.icon-environment:before, i.ph-baby-carriage.icon-user:before, i.ph-baby-carriage.icon-person:before, i.ph-baby-carriage.icon-terrain:before, i.ph-baby-carriage.icon-taxon:before, i.ph-baby-carriage.icon-unknown:before, i.ph-baby-carriage.icon-nothing:before, i.ph-baby-carriage.icon-sampling:before, i.ph-baby-carriage.icon-isolation:before, i.ph-baby-carriage.icon-strain:before, i.ph-baby-carriage.icon-gps:before, i.ph-baby-carriage.icon-location:before, i.ph-baby-carriage.icon-add:before, i.ph-baby-carriage.icon-left:before, i.ph-baby-carriage.icon-dna:before, i.ph-baby-carriage.icon-delete:before, i.ph-baby-carriage.icon-edit:before, i.ph-baby-carriage.icon-info:before {
  content: "\e818";
}

.ph.ph-backpack:before, i.ph-backpack.icon-dead:before, i.ph-backpack.icon-alive:before, i.ph-backpack.icon-warn:before, i.ph-backpack.icon-sign-out:before, i.ph-backpack.icon-substratum:before, i.ph-backpack.icon-environment:before, i.ph-backpack.icon-user:before, i.ph-backpack.icon-person:before, i.ph-backpack.icon-terrain:before, i.ph-backpack.icon-taxon:before, i.ph-backpack.icon-unknown:before, i.ph-backpack.icon-nothing:before, i.ph-backpack.icon-sampling:before, i.ph-backpack.icon-isolation:before, i.ph-backpack.icon-strain:before, i.ph-backpack.icon-gps:before, i.ph-backpack.icon-location:before, i.ph-backpack.icon-add:before, i.ph-backpack.icon-left:before, i.ph-backpack.icon-dna:before, i.ph-backpack.icon-delete:before, i.ph-backpack.icon-edit:before, i.ph-backpack.icon-info:before {
  content: "\e922";
}

.ph.ph-backspace:before, i.ph-backspace.icon-dead:before, i.ph-backspace.icon-alive:before, i.ph-backspace.icon-warn:before, i.ph-backspace.icon-sign-out:before, i.ph-backspace.icon-substratum:before, i.ph-backspace.icon-environment:before, i.ph-backspace.icon-user:before, i.ph-backspace.icon-person:before, i.ph-backspace.icon-terrain:before, i.ph-backspace.icon-taxon:before, i.ph-backspace.icon-unknown:before, i.ph-backspace.icon-nothing:before, i.ph-backspace.icon-sampling:before, i.ph-backspace.icon-isolation:before, i.ph-backspace.icon-strain:before, i.ph-backspace.icon-gps:before, i.ph-backspace.icon-location:before, i.ph-backspace.icon-add:before, i.ph-backspace.icon-left:before, i.ph-backspace.icon-dna:before, i.ph-backspace.icon-delete:before, i.ph-backspace.icon-edit:before, i.ph-backspace.icon-info:before {
  content: "\e0ae";
}

.ph.ph-bag:before, i.ph-bag.icon-dead:before, i.ph-bag.icon-alive:before, i.ph-bag.icon-warn:before, i.ph-bag.icon-sign-out:before, i.ph-bag.icon-substratum:before, i.ph-bag.icon-environment:before, i.ph-bag.icon-user:before, i.ph-bag.icon-person:before, i.ph-bag.icon-terrain:before, i.ph-bag.icon-taxon:before, i.ph-bag.icon-unknown:before, i.ph-bag.icon-nothing:before, i.ph-bag.icon-sampling:before, i.ph-bag.icon-isolation:before, i.ph-bag.icon-strain:before, i.ph-bag.icon-gps:before, i.ph-bag.icon-location:before, i.ph-bag.icon-add:before, i.ph-bag.icon-left:before, i.ph-bag.icon-dna:before, i.ph-bag.icon-delete:before, i.ph-bag.icon-edit:before, i.ph-bag.icon-info:before {
  content: "\e0b0";
}

.ph.ph-bag-simple:before, i.ph-bag-simple.icon-dead:before, i.ph-bag-simple.icon-alive:before, i.ph-bag-simple.icon-warn:before, i.ph-bag-simple.icon-sign-out:before, i.ph-bag-simple.icon-substratum:before, i.ph-bag-simple.icon-environment:before, i.ph-bag-simple.icon-user:before, i.ph-bag-simple.icon-person:before, i.ph-bag-simple.icon-terrain:before, i.ph-bag-simple.icon-taxon:before, i.ph-bag-simple.icon-unknown:before, i.ph-bag-simple.icon-nothing:before, i.ph-bag-simple.icon-sampling:before, i.ph-bag-simple.icon-isolation:before, i.ph-bag-simple.icon-strain:before, i.ph-bag-simple.icon-gps:before, i.ph-bag-simple.icon-location:before, i.ph-bag-simple.icon-add:before, i.ph-bag-simple.icon-left:before, i.ph-bag-simple.icon-dna:before, i.ph-bag-simple.icon-delete:before, i.ph-bag-simple.icon-edit:before, i.ph-bag-simple.icon-info:before {
  content: "\e5e6";
}

.ph.ph-balloon:before, i.ph-balloon.icon-dead:before, i.ph-balloon.icon-alive:before, i.ph-balloon.icon-warn:before, i.ph-balloon.icon-sign-out:before, i.ph-balloon.icon-substratum:before, i.ph-balloon.icon-environment:before, i.ph-balloon.icon-user:before, i.ph-balloon.icon-person:before, i.ph-balloon.icon-terrain:before, i.ph-balloon.icon-taxon:before, i.ph-balloon.icon-unknown:before, i.ph-balloon.icon-nothing:before, i.ph-balloon.icon-sampling:before, i.ph-balloon.icon-isolation:before, i.ph-balloon.icon-strain:before, i.ph-balloon.icon-gps:before, i.ph-balloon.icon-location:before, i.ph-balloon.icon-add:before, i.ph-balloon.icon-left:before, i.ph-balloon.icon-dna:before, i.ph-balloon.icon-delete:before, i.ph-balloon.icon-edit:before, i.ph-balloon.icon-info:before {
  content: "\e76c";
}

.ph.ph-bandaids:before, i.ph-bandaids.icon-dead:before, i.ph-bandaids.icon-alive:before, i.ph-bandaids.icon-warn:before, i.ph-bandaids.icon-sign-out:before, i.ph-bandaids.icon-substratum:before, i.ph-bandaids.icon-environment:before, i.ph-bandaids.icon-user:before, i.ph-bandaids.icon-person:before, i.ph-bandaids.icon-terrain:before, i.ph-bandaids.icon-taxon:before, i.ph-bandaids.icon-unknown:before, i.ph-bandaids.icon-nothing:before, i.ph-bandaids.icon-sampling:before, i.ph-bandaids.icon-isolation:before, i.ph-bandaids.icon-strain:before, i.ph-bandaids.icon-gps:before, i.ph-bandaids.icon-location:before, i.ph-bandaids.icon-add:before, i.ph-bandaids.icon-left:before, i.ph-bandaids.icon-dna:before, i.ph-bandaids.icon-delete:before, i.ph-bandaids.icon-edit:before, i.ph-bandaids.icon-info:before {
  content: "\e0b2";
}

.ph.ph-bank:before, i.ph-bank.icon-dead:before, i.ph-bank.icon-alive:before, i.ph-bank.icon-warn:before, i.ph-bank.icon-sign-out:before, i.ph-bank.icon-substratum:before, i.ph-bank.icon-environment:before, i.ph-bank.icon-user:before, i.ph-bank.icon-person:before, i.ph-bank.icon-terrain:before, i.ph-bank.icon-taxon:before, i.ph-bank.icon-unknown:before, i.ph-bank.icon-nothing:before, i.ph-bank.icon-sampling:before, i.ph-bank.icon-isolation:before, i.ph-bank.icon-strain:before, i.ph-bank.icon-gps:before, i.ph-bank.icon-location:before, i.ph-bank.icon-add:before, i.ph-bank.icon-left:before, i.ph-bank.icon-dna:before, i.ph-bank.icon-delete:before, i.ph-bank.icon-edit:before, i.ph-bank.icon-info:before {
  content: "\e0b4";
}

.ph.ph-barbell:before, i.ph-barbell.icon-dead:before, i.ph-barbell.icon-alive:before, i.ph-barbell.icon-warn:before, i.ph-barbell.icon-sign-out:before, i.ph-barbell.icon-substratum:before, i.ph-barbell.icon-environment:before, i.ph-barbell.icon-user:before, i.ph-barbell.icon-person:before, i.ph-barbell.icon-terrain:before, i.ph-barbell.icon-taxon:before, i.ph-barbell.icon-unknown:before, i.ph-barbell.icon-nothing:before, i.ph-barbell.icon-sampling:before, i.ph-barbell.icon-isolation:before, i.ph-barbell.icon-strain:before, i.ph-barbell.icon-gps:before, i.ph-barbell.icon-location:before, i.ph-barbell.icon-add:before, i.ph-barbell.icon-left:before, i.ph-barbell.icon-dna:before, i.ph-barbell.icon-delete:before, i.ph-barbell.icon-edit:before, i.ph-barbell.icon-info:before {
  content: "\e0b6";
}

.ph.ph-barcode:before, i.ph-barcode.icon-dead:before, i.ph-barcode.icon-alive:before, i.ph-barcode.icon-warn:before, i.ph-barcode.icon-sign-out:before, i.ph-barcode.icon-substratum:before, i.ph-barcode.icon-environment:before, i.ph-barcode.icon-user:before, i.ph-barcode.icon-person:before, i.ph-barcode.icon-terrain:before, i.ph-barcode.icon-taxon:before, i.ph-barcode.icon-unknown:before, i.ph-barcode.icon-nothing:before, i.ph-barcode.icon-sampling:before, i.ph-barcode.icon-isolation:before, i.ph-barcode.icon-strain:before, i.ph-barcode.icon-gps:before, i.ph-barcode.icon-location:before, i.ph-barcode.icon-add:before, i.ph-barcode.icon-left:before, i.ph-barcode.icon-dna:before, i.ph-barcode.icon-delete:before, i.ph-barcode.icon-edit:before, i.ph-barcode.icon-info:before {
  content: "\e0b8";
}

.ph.ph-barn:before, i.ph-barn.icon-dead:before, i.ph-barn.icon-alive:before, i.ph-barn.icon-warn:before, i.ph-barn.icon-sign-out:before, i.ph-barn.icon-substratum:before, i.ph-barn.icon-environment:before, i.ph-barn.icon-user:before, i.ph-barn.icon-person:before, i.ph-barn.icon-terrain:before, i.ph-barn.icon-taxon:before, i.ph-barn.icon-unknown:before, i.ph-barn.icon-nothing:before, i.ph-barn.icon-sampling:before, i.ph-barn.icon-isolation:before, i.ph-barn.icon-strain:before, i.ph-barn.icon-gps:before, i.ph-barn.icon-location:before, i.ph-barn.icon-add:before, i.ph-barn.icon-left:before, i.ph-barn.icon-dna:before, i.ph-barn.icon-delete:before, i.ph-barn.icon-edit:before, i.ph-barn.icon-info:before {
  content: "\ec72";
}

.ph.ph-barricade:before, i.ph-barricade.icon-dead:before, i.ph-barricade.icon-alive:before, i.ph-barricade.icon-warn:before, i.ph-barricade.icon-sign-out:before, i.ph-barricade.icon-substratum:before, i.ph-barricade.icon-environment:before, i.ph-barricade.icon-user:before, i.ph-barricade.icon-person:before, i.ph-barricade.icon-terrain:before, i.ph-barricade.icon-taxon:before, i.ph-barricade.icon-unknown:before, i.ph-barricade.icon-nothing:before, i.ph-barricade.icon-sampling:before, i.ph-barricade.icon-isolation:before, i.ph-barricade.icon-strain:before, i.ph-barricade.icon-gps:before, i.ph-barricade.icon-location:before, i.ph-barricade.icon-add:before, i.ph-barricade.icon-left:before, i.ph-barricade.icon-dna:before, i.ph-barricade.icon-delete:before, i.ph-barricade.icon-edit:before, i.ph-barricade.icon-info:before {
  content: "\e948";
}

.ph.ph-baseball:before, i.ph-baseball.icon-dead:before, i.ph-baseball.icon-alive:before, i.ph-baseball.icon-warn:before, i.ph-baseball.icon-sign-out:before, i.ph-baseball.icon-substratum:before, i.ph-baseball.icon-environment:before, i.ph-baseball.icon-user:before, i.ph-baseball.icon-person:before, i.ph-baseball.icon-terrain:before, i.ph-baseball.icon-taxon:before, i.ph-baseball.icon-unknown:before, i.ph-baseball.icon-nothing:before, i.ph-baseball.icon-sampling:before, i.ph-baseball.icon-isolation:before, i.ph-baseball.icon-strain:before, i.ph-baseball.icon-gps:before, i.ph-baseball.icon-location:before, i.ph-baseball.icon-add:before, i.ph-baseball.icon-left:before, i.ph-baseball.icon-dna:before, i.ph-baseball.icon-delete:before, i.ph-baseball.icon-edit:before, i.ph-baseball.icon-info:before {
  content: "\e71a";
}

.ph.ph-baseball-cap:before, i.ph-baseball-cap.icon-dead:before, i.ph-baseball-cap.icon-alive:before, i.ph-baseball-cap.icon-warn:before, i.ph-baseball-cap.icon-sign-out:before, i.ph-baseball-cap.icon-substratum:before, i.ph-baseball-cap.icon-environment:before, i.ph-baseball-cap.icon-user:before, i.ph-baseball-cap.icon-person:before, i.ph-baseball-cap.icon-terrain:before, i.ph-baseball-cap.icon-taxon:before, i.ph-baseball-cap.icon-unknown:before, i.ph-baseball-cap.icon-nothing:before, i.ph-baseball-cap.icon-sampling:before, i.ph-baseball-cap.icon-isolation:before, i.ph-baseball-cap.icon-strain:before, i.ph-baseball-cap.icon-gps:before, i.ph-baseball-cap.icon-location:before, i.ph-baseball-cap.icon-add:before, i.ph-baseball-cap.icon-left:before, i.ph-baseball-cap.icon-dna:before, i.ph-baseball-cap.icon-delete:before, i.ph-baseball-cap.icon-edit:before, i.ph-baseball-cap.icon-info:before {
  content: "\ea28";
}

.ph.ph-baseball-helmet:before, i.ph-baseball-helmet.icon-dead:before, i.ph-baseball-helmet.icon-alive:before, i.ph-baseball-helmet.icon-warn:before, i.ph-baseball-helmet.icon-sign-out:before, i.ph-baseball-helmet.icon-substratum:before, i.ph-baseball-helmet.icon-environment:before, i.ph-baseball-helmet.icon-user:before, i.ph-baseball-helmet.icon-person:before, i.ph-baseball-helmet.icon-terrain:before, i.ph-baseball-helmet.icon-taxon:before, i.ph-baseball-helmet.icon-unknown:before, i.ph-baseball-helmet.icon-nothing:before, i.ph-baseball-helmet.icon-sampling:before, i.ph-baseball-helmet.icon-isolation:before, i.ph-baseball-helmet.icon-strain:before, i.ph-baseball-helmet.icon-gps:before, i.ph-baseball-helmet.icon-location:before, i.ph-baseball-helmet.icon-add:before, i.ph-baseball-helmet.icon-left:before, i.ph-baseball-helmet.icon-dna:before, i.ph-baseball-helmet.icon-delete:before, i.ph-baseball-helmet.icon-edit:before, i.ph-baseball-helmet.icon-info:before {
  content: "\ee4a";
}

.ph.ph-basket:before, i.ph-basket.icon-dead:before, i.ph-basket.icon-alive:before, i.ph-basket.icon-warn:before, i.ph-basket.icon-sign-out:before, i.ph-basket.icon-substratum:before, i.ph-basket.icon-environment:before, i.ph-basket.icon-user:before, i.ph-basket.icon-person:before, i.ph-basket.icon-terrain:before, i.ph-basket.icon-taxon:before, i.ph-basket.icon-unknown:before, i.ph-basket.icon-nothing:before, i.ph-basket.icon-sampling:before, i.ph-basket.icon-isolation:before, i.ph-basket.icon-strain:before, i.ph-basket.icon-gps:before, i.ph-basket.icon-location:before, i.ph-basket.icon-add:before, i.ph-basket.icon-left:before, i.ph-basket.icon-dna:before, i.ph-basket.icon-delete:before, i.ph-basket.icon-edit:before, i.ph-basket.icon-info:before {
  content: "\e964";
}

.ph.ph-basketball:before, i.ph-basketball.icon-dead:before, i.ph-basketball.icon-alive:before, i.ph-basketball.icon-warn:before, i.ph-basketball.icon-sign-out:before, i.ph-basketball.icon-substratum:before, i.ph-basketball.icon-environment:before, i.ph-basketball.icon-user:before, i.ph-basketball.icon-person:before, i.ph-basketball.icon-terrain:before, i.ph-basketball.icon-taxon:before, i.ph-basketball.icon-unknown:before, i.ph-basketball.icon-nothing:before, i.ph-basketball.icon-sampling:before, i.ph-basketball.icon-isolation:before, i.ph-basketball.icon-strain:before, i.ph-basketball.icon-gps:before, i.ph-basketball.icon-location:before, i.ph-basketball.icon-add:before, i.ph-basketball.icon-left:before, i.ph-basketball.icon-dna:before, i.ph-basketball.icon-delete:before, i.ph-basketball.icon-edit:before, i.ph-basketball.icon-info:before {
  content: "\e724";
}

.ph.ph-bathtub:before, i.ph-bathtub.icon-dead:before, i.ph-bathtub.icon-alive:before, i.ph-bathtub.icon-warn:before, i.ph-bathtub.icon-sign-out:before, i.ph-bathtub.icon-substratum:before, i.ph-bathtub.icon-environment:before, i.ph-bathtub.icon-user:before, i.ph-bathtub.icon-person:before, i.ph-bathtub.icon-terrain:before, i.ph-bathtub.icon-taxon:before, i.ph-bathtub.icon-unknown:before, i.ph-bathtub.icon-nothing:before, i.ph-bathtub.icon-sampling:before, i.ph-bathtub.icon-isolation:before, i.ph-bathtub.icon-strain:before, i.ph-bathtub.icon-gps:before, i.ph-bathtub.icon-location:before, i.ph-bathtub.icon-add:before, i.ph-bathtub.icon-left:before, i.ph-bathtub.icon-dna:before, i.ph-bathtub.icon-delete:before, i.ph-bathtub.icon-edit:before, i.ph-bathtub.icon-info:before {
  content: "\e81e";
}

.ph.ph-battery-charging:before, i.ph-battery-charging.icon-dead:before, i.ph-battery-charging.icon-alive:before, i.ph-battery-charging.icon-warn:before, i.ph-battery-charging.icon-sign-out:before, i.ph-battery-charging.icon-substratum:before, i.ph-battery-charging.icon-environment:before, i.ph-battery-charging.icon-user:before, i.ph-battery-charging.icon-person:before, i.ph-battery-charging.icon-terrain:before, i.ph-battery-charging.icon-taxon:before, i.ph-battery-charging.icon-unknown:before, i.ph-battery-charging.icon-nothing:before, i.ph-battery-charging.icon-sampling:before, i.ph-battery-charging.icon-isolation:before, i.ph-battery-charging.icon-strain:before, i.ph-battery-charging.icon-gps:before, i.ph-battery-charging.icon-location:before, i.ph-battery-charging.icon-add:before, i.ph-battery-charging.icon-left:before, i.ph-battery-charging.icon-dna:before, i.ph-battery-charging.icon-delete:before, i.ph-battery-charging.icon-edit:before, i.ph-battery-charging.icon-info:before {
  content: "\e0ba";
}

.ph.ph-battery-charging-vertical:before, i.ph-battery-charging-vertical.icon-dead:before, i.ph-battery-charging-vertical.icon-alive:before, i.ph-battery-charging-vertical.icon-warn:before, i.ph-battery-charging-vertical.icon-sign-out:before, i.ph-battery-charging-vertical.icon-substratum:before, i.ph-battery-charging-vertical.icon-environment:before, i.ph-battery-charging-vertical.icon-user:before, i.ph-battery-charging-vertical.icon-person:before, i.ph-battery-charging-vertical.icon-terrain:before, i.ph-battery-charging-vertical.icon-taxon:before, i.ph-battery-charging-vertical.icon-unknown:before, i.ph-battery-charging-vertical.icon-nothing:before, i.ph-battery-charging-vertical.icon-sampling:before, i.ph-battery-charging-vertical.icon-isolation:before, i.ph-battery-charging-vertical.icon-strain:before, i.ph-battery-charging-vertical.icon-gps:before, i.ph-battery-charging-vertical.icon-location:before, i.ph-battery-charging-vertical.icon-add:before, i.ph-battery-charging-vertical.icon-left:before, i.ph-battery-charging-vertical.icon-dna:before, i.ph-battery-charging-vertical.icon-delete:before, i.ph-battery-charging-vertical.icon-edit:before, i.ph-battery-charging-vertical.icon-info:before {
  content: "\e0bc";
}

.ph.ph-battery-empty:before, i.ph-battery-empty.icon-dead:before, i.ph-battery-empty.icon-alive:before, i.ph-battery-empty.icon-warn:before, i.ph-battery-empty.icon-sign-out:before, i.ph-battery-empty.icon-substratum:before, i.ph-battery-empty.icon-environment:before, i.ph-battery-empty.icon-user:before, i.ph-battery-empty.icon-person:before, i.ph-battery-empty.icon-terrain:before, i.ph-battery-empty.icon-taxon:before, i.ph-battery-empty.icon-unknown:before, i.ph-battery-empty.icon-nothing:before, i.ph-battery-empty.icon-sampling:before, i.ph-battery-empty.icon-isolation:before, i.ph-battery-empty.icon-strain:before, i.ph-battery-empty.icon-gps:before, i.ph-battery-empty.icon-location:before, i.ph-battery-empty.icon-add:before, i.ph-battery-empty.icon-left:before, i.ph-battery-empty.icon-dna:before, i.ph-battery-empty.icon-delete:before, i.ph-battery-empty.icon-edit:before, i.ph-battery-empty.icon-info:before {
  content: "\e0be";
}

.ph.ph-battery-full:before, i.ph-battery-full.icon-dead:before, i.ph-battery-full.icon-alive:before, i.ph-battery-full.icon-warn:before, i.ph-battery-full.icon-sign-out:before, i.ph-battery-full.icon-substratum:before, i.ph-battery-full.icon-environment:before, i.ph-battery-full.icon-user:before, i.ph-battery-full.icon-person:before, i.ph-battery-full.icon-terrain:before, i.ph-battery-full.icon-taxon:before, i.ph-battery-full.icon-unknown:before, i.ph-battery-full.icon-nothing:before, i.ph-battery-full.icon-sampling:before, i.ph-battery-full.icon-isolation:before, i.ph-battery-full.icon-strain:before, i.ph-battery-full.icon-gps:before, i.ph-battery-full.icon-location:before, i.ph-battery-full.icon-add:before, i.ph-battery-full.icon-left:before, i.ph-battery-full.icon-dna:before, i.ph-battery-full.icon-delete:before, i.ph-battery-full.icon-edit:before, i.ph-battery-full.icon-info:before {
  content: "\e0c0";
}

.ph.ph-battery-high:before, i.ph-battery-high.icon-dead:before, i.ph-battery-high.icon-alive:before, i.ph-battery-high.icon-warn:before, i.ph-battery-high.icon-sign-out:before, i.ph-battery-high.icon-substratum:before, i.ph-battery-high.icon-environment:before, i.ph-battery-high.icon-user:before, i.ph-battery-high.icon-person:before, i.ph-battery-high.icon-terrain:before, i.ph-battery-high.icon-taxon:before, i.ph-battery-high.icon-unknown:before, i.ph-battery-high.icon-nothing:before, i.ph-battery-high.icon-sampling:before, i.ph-battery-high.icon-isolation:before, i.ph-battery-high.icon-strain:before, i.ph-battery-high.icon-gps:before, i.ph-battery-high.icon-location:before, i.ph-battery-high.icon-add:before, i.ph-battery-high.icon-left:before, i.ph-battery-high.icon-dna:before, i.ph-battery-high.icon-delete:before, i.ph-battery-high.icon-edit:before, i.ph-battery-high.icon-info:before {
  content: "\e0c2";
}

.ph.ph-battery-low:before, i.ph-battery-low.icon-dead:before, i.ph-battery-low.icon-alive:before, i.ph-battery-low.icon-warn:before, i.ph-battery-low.icon-sign-out:before, i.ph-battery-low.icon-substratum:before, i.ph-battery-low.icon-environment:before, i.ph-battery-low.icon-user:before, i.ph-battery-low.icon-person:before, i.ph-battery-low.icon-terrain:before, i.ph-battery-low.icon-taxon:before, i.ph-battery-low.icon-unknown:before, i.ph-battery-low.icon-nothing:before, i.ph-battery-low.icon-sampling:before, i.ph-battery-low.icon-isolation:before, i.ph-battery-low.icon-strain:before, i.ph-battery-low.icon-gps:before, i.ph-battery-low.icon-location:before, i.ph-battery-low.icon-add:before, i.ph-battery-low.icon-left:before, i.ph-battery-low.icon-dna:before, i.ph-battery-low.icon-delete:before, i.ph-battery-low.icon-edit:before, i.ph-battery-low.icon-info:before {
  content: "\e0c4";
}

.ph.ph-battery-medium:before, i.ph-battery-medium.icon-dead:before, i.ph-battery-medium.icon-alive:before, i.ph-battery-medium.icon-warn:before, i.ph-battery-medium.icon-sign-out:before, i.ph-battery-medium.icon-substratum:before, i.ph-battery-medium.icon-environment:before, i.ph-battery-medium.icon-user:before, i.ph-battery-medium.icon-person:before, i.ph-battery-medium.icon-terrain:before, i.ph-battery-medium.icon-taxon:before, i.ph-battery-medium.icon-unknown:before, i.ph-battery-medium.icon-nothing:before, i.ph-battery-medium.icon-sampling:before, i.ph-battery-medium.icon-isolation:before, i.ph-battery-medium.icon-strain:before, i.ph-battery-medium.icon-gps:before, i.ph-battery-medium.icon-location:before, i.ph-battery-medium.icon-add:before, i.ph-battery-medium.icon-left:before, i.ph-battery-medium.icon-dna:before, i.ph-battery-medium.icon-delete:before, i.ph-battery-medium.icon-edit:before, i.ph-battery-medium.icon-info:before {
  content: "\e0c6";
}

.ph.ph-battery-plus:before, i.ph-battery-plus.icon-dead:before, i.ph-battery-plus.icon-alive:before, i.ph-battery-plus.icon-warn:before, i.ph-battery-plus.icon-sign-out:before, i.ph-battery-plus.icon-substratum:before, i.ph-battery-plus.icon-environment:before, i.ph-battery-plus.icon-user:before, i.ph-battery-plus.icon-person:before, i.ph-battery-plus.icon-terrain:before, i.ph-battery-plus.icon-taxon:before, i.ph-battery-plus.icon-unknown:before, i.ph-battery-plus.icon-nothing:before, i.ph-battery-plus.icon-sampling:before, i.ph-battery-plus.icon-isolation:before, i.ph-battery-plus.icon-strain:before, i.ph-battery-plus.icon-gps:before, i.ph-battery-plus.icon-location:before, i.ph-battery-plus.icon-add:before, i.ph-battery-plus.icon-left:before, i.ph-battery-plus.icon-dna:before, i.ph-battery-plus.icon-delete:before, i.ph-battery-plus.icon-edit:before, i.ph-battery-plus.icon-info:before {
  content: "\e808";
}

.ph.ph-battery-plus-vertical:before, i.ph-battery-plus-vertical.icon-dead:before, i.ph-battery-plus-vertical.icon-alive:before, i.ph-battery-plus-vertical.icon-warn:before, i.ph-battery-plus-vertical.icon-sign-out:before, i.ph-battery-plus-vertical.icon-substratum:before, i.ph-battery-plus-vertical.icon-environment:before, i.ph-battery-plus-vertical.icon-user:before, i.ph-battery-plus-vertical.icon-person:before, i.ph-battery-plus-vertical.icon-terrain:before, i.ph-battery-plus-vertical.icon-taxon:before, i.ph-battery-plus-vertical.icon-unknown:before, i.ph-battery-plus-vertical.icon-nothing:before, i.ph-battery-plus-vertical.icon-sampling:before, i.ph-battery-plus-vertical.icon-isolation:before, i.ph-battery-plus-vertical.icon-strain:before, i.ph-battery-plus-vertical.icon-gps:before, i.ph-battery-plus-vertical.icon-location:before, i.ph-battery-plus-vertical.icon-add:before, i.ph-battery-plus-vertical.icon-left:before, i.ph-battery-plus-vertical.icon-dna:before, i.ph-battery-plus-vertical.icon-delete:before, i.ph-battery-plus-vertical.icon-edit:before, i.ph-battery-plus-vertical.icon-info:before {
  content: "\ec50";
}

.ph.ph-battery-vertical-empty:before, i.ph-battery-vertical-empty.icon-dead:before, i.ph-battery-vertical-empty.icon-alive:before, i.ph-battery-vertical-empty.icon-warn:before, i.ph-battery-vertical-empty.icon-sign-out:before, i.ph-battery-vertical-empty.icon-substratum:before, i.ph-battery-vertical-empty.icon-environment:before, i.ph-battery-vertical-empty.icon-user:before, i.ph-battery-vertical-empty.icon-person:before, i.ph-battery-vertical-empty.icon-terrain:before, i.ph-battery-vertical-empty.icon-taxon:before, i.ph-battery-vertical-empty.icon-unknown:before, i.ph-battery-vertical-empty.icon-nothing:before, i.ph-battery-vertical-empty.icon-sampling:before, i.ph-battery-vertical-empty.icon-isolation:before, i.ph-battery-vertical-empty.icon-strain:before, i.ph-battery-vertical-empty.icon-gps:before, i.ph-battery-vertical-empty.icon-location:before, i.ph-battery-vertical-empty.icon-add:before, i.ph-battery-vertical-empty.icon-left:before, i.ph-battery-vertical-empty.icon-dna:before, i.ph-battery-vertical-empty.icon-delete:before, i.ph-battery-vertical-empty.icon-edit:before, i.ph-battery-vertical-empty.icon-info:before {
  content: "\e7c6";
}

.ph.ph-battery-vertical-full:before, i.ph-battery-vertical-full.icon-dead:before, i.ph-battery-vertical-full.icon-alive:before, i.ph-battery-vertical-full.icon-warn:before, i.ph-battery-vertical-full.icon-sign-out:before, i.ph-battery-vertical-full.icon-substratum:before, i.ph-battery-vertical-full.icon-environment:before, i.ph-battery-vertical-full.icon-user:before, i.ph-battery-vertical-full.icon-person:before, i.ph-battery-vertical-full.icon-terrain:before, i.ph-battery-vertical-full.icon-taxon:before, i.ph-battery-vertical-full.icon-unknown:before, i.ph-battery-vertical-full.icon-nothing:before, i.ph-battery-vertical-full.icon-sampling:before, i.ph-battery-vertical-full.icon-isolation:before, i.ph-battery-vertical-full.icon-strain:before, i.ph-battery-vertical-full.icon-gps:before, i.ph-battery-vertical-full.icon-location:before, i.ph-battery-vertical-full.icon-add:before, i.ph-battery-vertical-full.icon-left:before, i.ph-battery-vertical-full.icon-dna:before, i.ph-battery-vertical-full.icon-delete:before, i.ph-battery-vertical-full.icon-edit:before, i.ph-battery-vertical-full.icon-info:before {
  content: "\e7c4";
}

.ph.ph-battery-vertical-high:before, i.ph-battery-vertical-high.icon-dead:before, i.ph-battery-vertical-high.icon-alive:before, i.ph-battery-vertical-high.icon-warn:before, i.ph-battery-vertical-high.icon-sign-out:before, i.ph-battery-vertical-high.icon-substratum:before, i.ph-battery-vertical-high.icon-environment:before, i.ph-battery-vertical-high.icon-user:before, i.ph-battery-vertical-high.icon-person:before, i.ph-battery-vertical-high.icon-terrain:before, i.ph-battery-vertical-high.icon-taxon:before, i.ph-battery-vertical-high.icon-unknown:before, i.ph-battery-vertical-high.icon-nothing:before, i.ph-battery-vertical-high.icon-sampling:before, i.ph-battery-vertical-high.icon-isolation:before, i.ph-battery-vertical-high.icon-strain:before, i.ph-battery-vertical-high.icon-gps:before, i.ph-battery-vertical-high.icon-location:before, i.ph-battery-vertical-high.icon-add:before, i.ph-battery-vertical-high.icon-left:before, i.ph-battery-vertical-high.icon-dna:before, i.ph-battery-vertical-high.icon-delete:before, i.ph-battery-vertical-high.icon-edit:before, i.ph-battery-vertical-high.icon-info:before {
  content: "\e7c2";
}

.ph.ph-battery-vertical-low:before, i.ph-battery-vertical-low.icon-dead:before, i.ph-battery-vertical-low.icon-alive:before, i.ph-battery-vertical-low.icon-warn:before, i.ph-battery-vertical-low.icon-sign-out:before, i.ph-battery-vertical-low.icon-substratum:before, i.ph-battery-vertical-low.icon-environment:before, i.ph-battery-vertical-low.icon-user:before, i.ph-battery-vertical-low.icon-person:before, i.ph-battery-vertical-low.icon-terrain:before, i.ph-battery-vertical-low.icon-taxon:before, i.ph-battery-vertical-low.icon-unknown:before, i.ph-battery-vertical-low.icon-nothing:before, i.ph-battery-vertical-low.icon-sampling:before, i.ph-battery-vertical-low.icon-isolation:before, i.ph-battery-vertical-low.icon-strain:before, i.ph-battery-vertical-low.icon-gps:before, i.ph-battery-vertical-low.icon-location:before, i.ph-battery-vertical-low.icon-add:before, i.ph-battery-vertical-low.icon-left:before, i.ph-battery-vertical-low.icon-dna:before, i.ph-battery-vertical-low.icon-delete:before, i.ph-battery-vertical-low.icon-edit:before, i.ph-battery-vertical-low.icon-info:before {
  content: "\e7be";
}

.ph.ph-battery-vertical-medium:before, i.ph-battery-vertical-medium.icon-dead:before, i.ph-battery-vertical-medium.icon-alive:before, i.ph-battery-vertical-medium.icon-warn:before, i.ph-battery-vertical-medium.icon-sign-out:before, i.ph-battery-vertical-medium.icon-substratum:before, i.ph-battery-vertical-medium.icon-environment:before, i.ph-battery-vertical-medium.icon-user:before, i.ph-battery-vertical-medium.icon-person:before, i.ph-battery-vertical-medium.icon-terrain:before, i.ph-battery-vertical-medium.icon-taxon:before, i.ph-battery-vertical-medium.icon-unknown:before, i.ph-battery-vertical-medium.icon-nothing:before, i.ph-battery-vertical-medium.icon-sampling:before, i.ph-battery-vertical-medium.icon-isolation:before, i.ph-battery-vertical-medium.icon-strain:before, i.ph-battery-vertical-medium.icon-gps:before, i.ph-battery-vertical-medium.icon-location:before, i.ph-battery-vertical-medium.icon-add:before, i.ph-battery-vertical-medium.icon-left:before, i.ph-battery-vertical-medium.icon-dna:before, i.ph-battery-vertical-medium.icon-delete:before, i.ph-battery-vertical-medium.icon-edit:before, i.ph-battery-vertical-medium.icon-info:before {
  content: "\e7c0";
}

.ph.ph-battery-warning:before, i.ph-battery-warning.icon-dead:before, i.ph-battery-warning.icon-alive:before, i.ph-battery-warning.icon-warn:before, i.ph-battery-warning.icon-sign-out:before, i.ph-battery-warning.icon-substratum:before, i.ph-battery-warning.icon-environment:before, i.ph-battery-warning.icon-user:before, i.ph-battery-warning.icon-person:before, i.ph-battery-warning.icon-terrain:before, i.ph-battery-warning.icon-taxon:before, i.ph-battery-warning.icon-unknown:before, i.ph-battery-warning.icon-nothing:before, i.ph-battery-warning.icon-sampling:before, i.ph-battery-warning.icon-isolation:before, i.ph-battery-warning.icon-strain:before, i.ph-battery-warning.icon-gps:before, i.ph-battery-warning.icon-location:before, i.ph-battery-warning.icon-add:before, i.ph-battery-warning.icon-left:before, i.ph-battery-warning.icon-dna:before, i.ph-battery-warning.icon-delete:before, i.ph-battery-warning.icon-edit:before, i.ph-battery-warning.icon-info:before {
  content: "\e0c8";
}

.ph.ph-battery-warning-vertical:before, i.ph-battery-warning-vertical.icon-dead:before, i.ph-battery-warning-vertical.icon-alive:before, i.ph-battery-warning-vertical.icon-warn:before, i.ph-battery-warning-vertical.icon-sign-out:before, i.ph-battery-warning-vertical.icon-substratum:before, i.ph-battery-warning-vertical.icon-environment:before, i.ph-battery-warning-vertical.icon-user:before, i.ph-battery-warning-vertical.icon-person:before, i.ph-battery-warning-vertical.icon-terrain:before, i.ph-battery-warning-vertical.icon-taxon:before, i.ph-battery-warning-vertical.icon-unknown:before, i.ph-battery-warning-vertical.icon-nothing:before, i.ph-battery-warning-vertical.icon-sampling:before, i.ph-battery-warning-vertical.icon-isolation:before, i.ph-battery-warning-vertical.icon-strain:before, i.ph-battery-warning-vertical.icon-gps:before, i.ph-battery-warning-vertical.icon-location:before, i.ph-battery-warning-vertical.icon-add:before, i.ph-battery-warning-vertical.icon-left:before, i.ph-battery-warning-vertical.icon-dna:before, i.ph-battery-warning-vertical.icon-delete:before, i.ph-battery-warning-vertical.icon-edit:before, i.ph-battery-warning-vertical.icon-info:before {
  content: "\e0ca";
}

.ph.ph-beach-ball:before, i.ph-beach-ball.icon-dead:before, i.ph-beach-ball.icon-alive:before, i.ph-beach-ball.icon-warn:before, i.ph-beach-ball.icon-sign-out:before, i.ph-beach-ball.icon-substratum:before, i.ph-beach-ball.icon-environment:before, i.ph-beach-ball.icon-user:before, i.ph-beach-ball.icon-person:before, i.ph-beach-ball.icon-terrain:before, i.ph-beach-ball.icon-taxon:before, i.ph-beach-ball.icon-unknown:before, i.ph-beach-ball.icon-nothing:before, i.ph-beach-ball.icon-sampling:before, i.ph-beach-ball.icon-isolation:before, i.ph-beach-ball.icon-strain:before, i.ph-beach-ball.icon-gps:before, i.ph-beach-ball.icon-location:before, i.ph-beach-ball.icon-add:before, i.ph-beach-ball.icon-left:before, i.ph-beach-ball.icon-dna:before, i.ph-beach-ball.icon-delete:before, i.ph-beach-ball.icon-edit:before, i.ph-beach-ball.icon-info:before {
  content: "\ed24";
}

.ph.ph-beanie:before, i.ph-beanie.icon-dead:before, i.ph-beanie.icon-alive:before, i.ph-beanie.icon-warn:before, i.ph-beanie.icon-sign-out:before, i.ph-beanie.icon-substratum:before, i.ph-beanie.icon-environment:before, i.ph-beanie.icon-user:before, i.ph-beanie.icon-person:before, i.ph-beanie.icon-terrain:before, i.ph-beanie.icon-taxon:before, i.ph-beanie.icon-unknown:before, i.ph-beanie.icon-nothing:before, i.ph-beanie.icon-sampling:before, i.ph-beanie.icon-isolation:before, i.ph-beanie.icon-strain:before, i.ph-beanie.icon-gps:before, i.ph-beanie.icon-location:before, i.ph-beanie.icon-add:before, i.ph-beanie.icon-left:before, i.ph-beanie.icon-dna:before, i.ph-beanie.icon-delete:before, i.ph-beanie.icon-edit:before, i.ph-beanie.icon-info:before {
  content: "\ea2a";
}

.ph.ph-bed:before, i.ph-bed.icon-dead:before, i.ph-bed.icon-alive:before, i.ph-bed.icon-warn:before, i.ph-bed.icon-sign-out:before, i.ph-bed.icon-substratum:before, i.ph-bed.icon-environment:before, i.ph-bed.icon-user:before, i.ph-bed.icon-person:before, i.ph-bed.icon-terrain:before, i.ph-bed.icon-taxon:before, i.ph-bed.icon-unknown:before, i.ph-bed.icon-nothing:before, i.ph-bed.icon-sampling:before, i.ph-bed.icon-isolation:before, i.ph-bed.icon-strain:before, i.ph-bed.icon-gps:before, i.ph-bed.icon-location:before, i.ph-bed.icon-add:before, i.ph-bed.icon-left:before, i.ph-bed.icon-dna:before, i.ph-bed.icon-delete:before, i.ph-bed.icon-edit:before, i.ph-bed.icon-info:before {
  content: "\e0cc";
}

.ph.ph-beer-bottle:before, i.ph-beer-bottle.icon-dead:before, i.ph-beer-bottle.icon-alive:before, i.ph-beer-bottle.icon-warn:before, i.ph-beer-bottle.icon-sign-out:before, i.ph-beer-bottle.icon-substratum:before, i.ph-beer-bottle.icon-environment:before, i.ph-beer-bottle.icon-user:before, i.ph-beer-bottle.icon-person:before, i.ph-beer-bottle.icon-terrain:before, i.ph-beer-bottle.icon-taxon:before, i.ph-beer-bottle.icon-unknown:before, i.ph-beer-bottle.icon-nothing:before, i.ph-beer-bottle.icon-sampling:before, i.ph-beer-bottle.icon-isolation:before, i.ph-beer-bottle.icon-strain:before, i.ph-beer-bottle.icon-gps:before, i.ph-beer-bottle.icon-location:before, i.ph-beer-bottle.icon-add:before, i.ph-beer-bottle.icon-left:before, i.ph-beer-bottle.icon-dna:before, i.ph-beer-bottle.icon-delete:before, i.ph-beer-bottle.icon-edit:before, i.ph-beer-bottle.icon-info:before {
  content: "\e7b0";
}

.ph.ph-beer-stein:before, i.ph-beer-stein.icon-dead:before, i.ph-beer-stein.icon-alive:before, i.ph-beer-stein.icon-warn:before, i.ph-beer-stein.icon-sign-out:before, i.ph-beer-stein.icon-substratum:before, i.ph-beer-stein.icon-environment:before, i.ph-beer-stein.icon-user:before, i.ph-beer-stein.icon-person:before, i.ph-beer-stein.icon-terrain:before, i.ph-beer-stein.icon-taxon:before, i.ph-beer-stein.icon-unknown:before, i.ph-beer-stein.icon-nothing:before, i.ph-beer-stein.icon-sampling:before, i.ph-beer-stein.icon-isolation:before, i.ph-beer-stein.icon-strain:before, i.ph-beer-stein.icon-gps:before, i.ph-beer-stein.icon-location:before, i.ph-beer-stein.icon-add:before, i.ph-beer-stein.icon-left:before, i.ph-beer-stein.icon-dna:before, i.ph-beer-stein.icon-delete:before, i.ph-beer-stein.icon-edit:before, i.ph-beer-stein.icon-info:before {
  content: "\eb62";
}

.ph.ph-behance-logo:before, i.ph-behance-logo.icon-dead:before, i.ph-behance-logo.icon-alive:before, i.ph-behance-logo.icon-warn:before, i.ph-behance-logo.icon-sign-out:before, i.ph-behance-logo.icon-substratum:before, i.ph-behance-logo.icon-environment:before, i.ph-behance-logo.icon-user:before, i.ph-behance-logo.icon-person:before, i.ph-behance-logo.icon-terrain:before, i.ph-behance-logo.icon-taxon:before, i.ph-behance-logo.icon-unknown:before, i.ph-behance-logo.icon-nothing:before, i.ph-behance-logo.icon-sampling:before, i.ph-behance-logo.icon-isolation:before, i.ph-behance-logo.icon-strain:before, i.ph-behance-logo.icon-gps:before, i.ph-behance-logo.icon-location:before, i.ph-behance-logo.icon-add:before, i.ph-behance-logo.icon-left:before, i.ph-behance-logo.icon-dna:before, i.ph-behance-logo.icon-delete:before, i.ph-behance-logo.icon-edit:before, i.ph-behance-logo.icon-info:before {
  content: "\e7f4";
}

.ph.ph-bell:before, i.ph-bell.icon-dead:before, i.ph-bell.icon-alive:before, i.ph-bell.icon-warn:before, i.ph-bell.icon-sign-out:before, i.ph-bell.icon-substratum:before, i.ph-bell.icon-environment:before, i.ph-bell.icon-user:before, i.ph-bell.icon-person:before, i.ph-bell.icon-terrain:before, i.ph-bell.icon-taxon:before, i.ph-bell.icon-unknown:before, i.ph-bell.icon-nothing:before, i.ph-bell.icon-sampling:before, i.ph-bell.icon-isolation:before, i.ph-bell.icon-strain:before, i.ph-bell.icon-gps:before, i.ph-bell.icon-location:before, i.ph-bell.icon-add:before, i.ph-bell.icon-left:before, i.ph-bell.icon-dna:before, i.ph-bell.icon-delete:before, i.ph-bell.icon-edit:before, i.ph-bell.icon-info:before {
  content: "\e0ce";
}

.ph.ph-bell-ringing:before, i.ph-bell-ringing.icon-dead:before, i.ph-bell-ringing.icon-alive:before, i.ph-bell-ringing.icon-warn:before, i.ph-bell-ringing.icon-sign-out:before, i.ph-bell-ringing.icon-substratum:before, i.ph-bell-ringing.icon-environment:before, i.ph-bell-ringing.icon-user:before, i.ph-bell-ringing.icon-person:before, i.ph-bell-ringing.icon-terrain:before, i.ph-bell-ringing.icon-taxon:before, i.ph-bell-ringing.icon-unknown:before, i.ph-bell-ringing.icon-nothing:before, i.ph-bell-ringing.icon-sampling:before, i.ph-bell-ringing.icon-isolation:before, i.ph-bell-ringing.icon-strain:before, i.ph-bell-ringing.icon-gps:before, i.ph-bell-ringing.icon-location:before, i.ph-bell-ringing.icon-add:before, i.ph-bell-ringing.icon-left:before, i.ph-bell-ringing.icon-dna:before, i.ph-bell-ringing.icon-delete:before, i.ph-bell-ringing.icon-edit:before, i.ph-bell-ringing.icon-info:before {
  content: "\e5e8";
}

.ph.ph-bell-simple:before, i.ph-bell-simple.icon-dead:before, i.ph-bell-simple.icon-alive:before, i.ph-bell-simple.icon-warn:before, i.ph-bell-simple.icon-sign-out:before, i.ph-bell-simple.icon-substratum:before, i.ph-bell-simple.icon-environment:before, i.ph-bell-simple.icon-user:before, i.ph-bell-simple.icon-person:before, i.ph-bell-simple.icon-terrain:before, i.ph-bell-simple.icon-taxon:before, i.ph-bell-simple.icon-unknown:before, i.ph-bell-simple.icon-nothing:before, i.ph-bell-simple.icon-sampling:before, i.ph-bell-simple.icon-isolation:before, i.ph-bell-simple.icon-strain:before, i.ph-bell-simple.icon-gps:before, i.ph-bell-simple.icon-location:before, i.ph-bell-simple.icon-add:before, i.ph-bell-simple.icon-left:before, i.ph-bell-simple.icon-dna:before, i.ph-bell-simple.icon-delete:before, i.ph-bell-simple.icon-edit:before, i.ph-bell-simple.icon-info:before {
  content: "\e0d0";
}

.ph.ph-bell-simple-ringing:before, i.ph-bell-simple-ringing.icon-dead:before, i.ph-bell-simple-ringing.icon-alive:before, i.ph-bell-simple-ringing.icon-warn:before, i.ph-bell-simple-ringing.icon-sign-out:before, i.ph-bell-simple-ringing.icon-substratum:before, i.ph-bell-simple-ringing.icon-environment:before, i.ph-bell-simple-ringing.icon-user:before, i.ph-bell-simple-ringing.icon-person:before, i.ph-bell-simple-ringing.icon-terrain:before, i.ph-bell-simple-ringing.icon-taxon:before, i.ph-bell-simple-ringing.icon-unknown:before, i.ph-bell-simple-ringing.icon-nothing:before, i.ph-bell-simple-ringing.icon-sampling:before, i.ph-bell-simple-ringing.icon-isolation:before, i.ph-bell-simple-ringing.icon-strain:before, i.ph-bell-simple-ringing.icon-gps:before, i.ph-bell-simple-ringing.icon-location:before, i.ph-bell-simple-ringing.icon-add:before, i.ph-bell-simple-ringing.icon-left:before, i.ph-bell-simple-ringing.icon-dna:before, i.ph-bell-simple-ringing.icon-delete:before, i.ph-bell-simple-ringing.icon-edit:before, i.ph-bell-simple-ringing.icon-info:before {
  content: "\e5ea";
}

.ph.ph-bell-simple-slash:before, i.ph-bell-simple-slash.icon-dead:before, i.ph-bell-simple-slash.icon-alive:before, i.ph-bell-simple-slash.icon-warn:before, i.ph-bell-simple-slash.icon-sign-out:before, i.ph-bell-simple-slash.icon-substratum:before, i.ph-bell-simple-slash.icon-environment:before, i.ph-bell-simple-slash.icon-user:before, i.ph-bell-simple-slash.icon-person:before, i.ph-bell-simple-slash.icon-terrain:before, i.ph-bell-simple-slash.icon-taxon:before, i.ph-bell-simple-slash.icon-unknown:before, i.ph-bell-simple-slash.icon-nothing:before, i.ph-bell-simple-slash.icon-sampling:before, i.ph-bell-simple-slash.icon-isolation:before, i.ph-bell-simple-slash.icon-strain:before, i.ph-bell-simple-slash.icon-gps:before, i.ph-bell-simple-slash.icon-location:before, i.ph-bell-simple-slash.icon-add:before, i.ph-bell-simple-slash.icon-left:before, i.ph-bell-simple-slash.icon-dna:before, i.ph-bell-simple-slash.icon-delete:before, i.ph-bell-simple-slash.icon-edit:before, i.ph-bell-simple-slash.icon-info:before {
  content: "\e0d2";
}

.ph.ph-bell-simple-z:before, i.ph-bell-simple-z.icon-dead:before, i.ph-bell-simple-z.icon-alive:before, i.ph-bell-simple-z.icon-warn:before, i.ph-bell-simple-z.icon-sign-out:before, i.ph-bell-simple-z.icon-substratum:before, i.ph-bell-simple-z.icon-environment:before, i.ph-bell-simple-z.icon-user:before, i.ph-bell-simple-z.icon-person:before, i.ph-bell-simple-z.icon-terrain:before, i.ph-bell-simple-z.icon-taxon:before, i.ph-bell-simple-z.icon-unknown:before, i.ph-bell-simple-z.icon-nothing:before, i.ph-bell-simple-z.icon-sampling:before, i.ph-bell-simple-z.icon-isolation:before, i.ph-bell-simple-z.icon-strain:before, i.ph-bell-simple-z.icon-gps:before, i.ph-bell-simple-z.icon-location:before, i.ph-bell-simple-z.icon-add:before, i.ph-bell-simple-z.icon-left:before, i.ph-bell-simple-z.icon-dna:before, i.ph-bell-simple-z.icon-delete:before, i.ph-bell-simple-z.icon-edit:before, i.ph-bell-simple-z.icon-info:before {
  content: "\e5ec";
}

.ph.ph-bell-slash:before, i.ph-bell-slash.icon-dead:before, i.ph-bell-slash.icon-alive:before, i.ph-bell-slash.icon-warn:before, i.ph-bell-slash.icon-sign-out:before, i.ph-bell-slash.icon-substratum:before, i.ph-bell-slash.icon-environment:before, i.ph-bell-slash.icon-user:before, i.ph-bell-slash.icon-person:before, i.ph-bell-slash.icon-terrain:before, i.ph-bell-slash.icon-taxon:before, i.ph-bell-slash.icon-unknown:before, i.ph-bell-slash.icon-nothing:before, i.ph-bell-slash.icon-sampling:before, i.ph-bell-slash.icon-isolation:before, i.ph-bell-slash.icon-strain:before, i.ph-bell-slash.icon-gps:before, i.ph-bell-slash.icon-location:before, i.ph-bell-slash.icon-add:before, i.ph-bell-slash.icon-left:before, i.ph-bell-slash.icon-dna:before, i.ph-bell-slash.icon-delete:before, i.ph-bell-slash.icon-edit:before, i.ph-bell-slash.icon-info:before {
  content: "\e0d4";
}

.ph.ph-bell-z:before, i.ph-bell-z.icon-dead:before, i.ph-bell-z.icon-alive:before, i.ph-bell-z.icon-warn:before, i.ph-bell-z.icon-sign-out:before, i.ph-bell-z.icon-substratum:before, i.ph-bell-z.icon-environment:before, i.ph-bell-z.icon-user:before, i.ph-bell-z.icon-person:before, i.ph-bell-z.icon-terrain:before, i.ph-bell-z.icon-taxon:before, i.ph-bell-z.icon-unknown:before, i.ph-bell-z.icon-nothing:before, i.ph-bell-z.icon-sampling:before, i.ph-bell-z.icon-isolation:before, i.ph-bell-z.icon-strain:before, i.ph-bell-z.icon-gps:before, i.ph-bell-z.icon-location:before, i.ph-bell-z.icon-add:before, i.ph-bell-z.icon-left:before, i.ph-bell-z.icon-dna:before, i.ph-bell-z.icon-delete:before, i.ph-bell-z.icon-edit:before, i.ph-bell-z.icon-info:before {
  content: "\e5ee";
}

.ph.ph-belt:before, i.ph-belt.icon-dead:before, i.ph-belt.icon-alive:before, i.ph-belt.icon-warn:before, i.ph-belt.icon-sign-out:before, i.ph-belt.icon-substratum:before, i.ph-belt.icon-environment:before, i.ph-belt.icon-user:before, i.ph-belt.icon-person:before, i.ph-belt.icon-terrain:before, i.ph-belt.icon-taxon:before, i.ph-belt.icon-unknown:before, i.ph-belt.icon-nothing:before, i.ph-belt.icon-sampling:before, i.ph-belt.icon-isolation:before, i.ph-belt.icon-strain:before, i.ph-belt.icon-gps:before, i.ph-belt.icon-location:before, i.ph-belt.icon-add:before, i.ph-belt.icon-left:before, i.ph-belt.icon-dna:before, i.ph-belt.icon-delete:before, i.ph-belt.icon-edit:before, i.ph-belt.icon-info:before {
  content: "\ea2c";
}

.ph.ph-bezier-curve:before, i.ph-bezier-curve.icon-dead:before, i.ph-bezier-curve.icon-alive:before, i.ph-bezier-curve.icon-warn:before, i.ph-bezier-curve.icon-sign-out:before, i.ph-bezier-curve.icon-substratum:before, i.ph-bezier-curve.icon-environment:before, i.ph-bezier-curve.icon-user:before, i.ph-bezier-curve.icon-person:before, i.ph-bezier-curve.icon-terrain:before, i.ph-bezier-curve.icon-taxon:before, i.ph-bezier-curve.icon-unknown:before, i.ph-bezier-curve.icon-nothing:before, i.ph-bezier-curve.icon-sampling:before, i.ph-bezier-curve.icon-isolation:before, i.ph-bezier-curve.icon-strain:before, i.ph-bezier-curve.icon-gps:before, i.ph-bezier-curve.icon-location:before, i.ph-bezier-curve.icon-add:before, i.ph-bezier-curve.icon-left:before, i.ph-bezier-curve.icon-dna:before, i.ph-bezier-curve.icon-delete:before, i.ph-bezier-curve.icon-edit:before, i.ph-bezier-curve.icon-info:before {
  content: "\eb00";
}

.ph.ph-bicycle:before, i.ph-bicycle.icon-dead:before, i.ph-bicycle.icon-alive:before, i.ph-bicycle.icon-warn:before, i.ph-bicycle.icon-sign-out:before, i.ph-bicycle.icon-substratum:before, i.ph-bicycle.icon-environment:before, i.ph-bicycle.icon-user:before, i.ph-bicycle.icon-person:before, i.ph-bicycle.icon-terrain:before, i.ph-bicycle.icon-taxon:before, i.ph-bicycle.icon-unknown:before, i.ph-bicycle.icon-nothing:before, i.ph-bicycle.icon-sampling:before, i.ph-bicycle.icon-isolation:before, i.ph-bicycle.icon-strain:before, i.ph-bicycle.icon-gps:before, i.ph-bicycle.icon-location:before, i.ph-bicycle.icon-add:before, i.ph-bicycle.icon-left:before, i.ph-bicycle.icon-dna:before, i.ph-bicycle.icon-delete:before, i.ph-bicycle.icon-edit:before, i.ph-bicycle.icon-info:before {
  content: "\e0d6";
}

.ph.ph-binary:before, i.ph-binary.icon-dead:before, i.ph-binary.icon-alive:before, i.ph-binary.icon-warn:before, i.ph-binary.icon-sign-out:before, i.ph-binary.icon-substratum:before, i.ph-binary.icon-environment:before, i.ph-binary.icon-user:before, i.ph-binary.icon-person:before, i.ph-binary.icon-terrain:before, i.ph-binary.icon-taxon:before, i.ph-binary.icon-unknown:before, i.ph-binary.icon-nothing:before, i.ph-binary.icon-sampling:before, i.ph-binary.icon-isolation:before, i.ph-binary.icon-strain:before, i.ph-binary.icon-gps:before, i.ph-binary.icon-location:before, i.ph-binary.icon-add:before, i.ph-binary.icon-left:before, i.ph-binary.icon-dna:before, i.ph-binary.icon-delete:before, i.ph-binary.icon-edit:before, i.ph-binary.icon-info:before {
  content: "\ee60";
}

.ph.ph-binoculars:before, i.ph-binoculars.icon-dead:before, i.ph-binoculars.icon-alive:before, i.ph-binoculars.icon-warn:before, i.ph-binoculars.icon-sign-out:before, i.ph-binoculars.icon-substratum:before, i.ph-binoculars.icon-environment:before, i.ph-binoculars.icon-user:before, i.ph-binoculars.icon-person:before, i.ph-binoculars.icon-terrain:before, i.ph-binoculars.icon-taxon:before, i.ph-binoculars.icon-unknown:before, i.ph-binoculars.icon-nothing:before, i.ph-binoculars.icon-sampling:before, i.icon-isolation:before, i.ph-binoculars.icon-strain:before, i.ph-binoculars.icon-gps:before, i.ph-binoculars.icon-location:before, i.ph-binoculars.icon-add:before, i.ph-binoculars.icon-left:before, i.ph-binoculars.icon-dna:before, i.ph-binoculars.icon-delete:before, i.ph-binoculars.icon-edit:before, i.ph-binoculars.icon-info:before {
  content: "\ea64";
}

.ph.ph-biohazard:before, i.ph-biohazard.icon-dead:before, i.ph-biohazard.icon-alive:before, i.ph-biohazard.icon-warn:before, i.ph-biohazard.icon-sign-out:before, i.ph-biohazard.icon-substratum:before, i.ph-biohazard.icon-environment:before, i.ph-biohazard.icon-user:before, i.ph-biohazard.icon-person:before, i.ph-biohazard.icon-terrain:before, i.ph-biohazard.icon-taxon:before, i.ph-biohazard.icon-unknown:before, i.ph-biohazard.icon-nothing:before, i.ph-biohazard.icon-sampling:before, i.ph-biohazard.icon-isolation:before, i.ph-biohazard.icon-strain:before, i.ph-biohazard.icon-gps:before, i.ph-biohazard.icon-location:before, i.ph-biohazard.icon-add:before, i.ph-biohazard.icon-left:before, i.ph-biohazard.icon-dna:before, i.ph-biohazard.icon-delete:before, i.ph-biohazard.icon-edit:before, i.ph-biohazard.icon-info:before {
  content: "\e9e0";
}

.ph.ph-bird:before, i.ph-bird.icon-dead:before, i.ph-bird.icon-alive:before, i.ph-bird.icon-warn:before, i.ph-bird.icon-sign-out:before, i.ph-bird.icon-substratum:before, i.ph-bird.icon-environment:before, i.ph-bird.icon-user:before, i.ph-bird.icon-person:before, i.ph-bird.icon-terrain:before, i.ph-bird.icon-taxon:before, i.ph-bird.icon-unknown:before, i.ph-bird.icon-nothing:before, i.ph-bird.icon-sampling:before, i.ph-bird.icon-isolation:before, i.ph-bird.icon-strain:before, i.ph-bird.icon-gps:before, i.ph-bird.icon-location:before, i.ph-bird.icon-add:before, i.ph-bird.icon-left:before, i.ph-bird.icon-dna:before, i.ph-bird.icon-delete:before, i.ph-bird.icon-edit:before, i.ph-bird.icon-info:before {
  content: "\e72c";
}

.ph.ph-blueprint:before, i.ph-blueprint.icon-dead:before, i.ph-blueprint.icon-alive:before, i.ph-blueprint.icon-warn:before, i.ph-blueprint.icon-sign-out:before, i.ph-blueprint.icon-substratum:before, i.ph-blueprint.icon-environment:before, i.ph-blueprint.icon-user:before, i.ph-blueprint.icon-person:before, i.ph-blueprint.icon-terrain:before, i.ph-blueprint.icon-taxon:before, i.ph-blueprint.icon-unknown:before, i.ph-blueprint.icon-nothing:before, i.ph-blueprint.icon-sampling:before, i.ph-blueprint.icon-isolation:before, i.ph-blueprint.icon-strain:before, i.ph-blueprint.icon-gps:before, i.ph-blueprint.icon-location:before, i.ph-blueprint.icon-add:before, i.ph-blueprint.icon-left:before, i.ph-blueprint.icon-dna:before, i.ph-blueprint.icon-delete:before, i.ph-blueprint.icon-edit:before, i.ph-blueprint.icon-info:before {
  content: "\eda0";
}

.ph.ph-bluetooth:before, i.ph-bluetooth.icon-dead:before, i.ph-bluetooth.icon-alive:before, i.ph-bluetooth.icon-warn:before, i.ph-bluetooth.icon-sign-out:before, i.ph-bluetooth.icon-substratum:before, i.ph-bluetooth.icon-environment:before, i.ph-bluetooth.icon-user:before, i.ph-bluetooth.icon-person:before, i.ph-bluetooth.icon-terrain:before, i.ph-bluetooth.icon-taxon:before, i.ph-bluetooth.icon-unknown:before, i.ph-bluetooth.icon-nothing:before, i.ph-bluetooth.icon-sampling:before, i.ph-bluetooth.icon-isolation:before, i.ph-bluetooth.icon-strain:before, i.ph-bluetooth.icon-gps:before, i.ph-bluetooth.icon-location:before, i.ph-bluetooth.icon-add:before, i.ph-bluetooth.icon-left:before, i.ph-bluetooth.icon-dna:before, i.ph-bluetooth.icon-delete:before, i.ph-bluetooth.icon-edit:before, i.ph-bluetooth.icon-info:before {
  content: "\e0da";
}

.ph.ph-bluetooth-connected:before, i.ph-bluetooth-connected.icon-dead:before, i.ph-bluetooth-connected.icon-alive:before, i.ph-bluetooth-connected.icon-warn:before, i.ph-bluetooth-connected.icon-sign-out:before, i.ph-bluetooth-connected.icon-substratum:before, i.ph-bluetooth-connected.icon-environment:before, i.ph-bluetooth-connected.icon-user:before, i.ph-bluetooth-connected.icon-person:before, i.ph-bluetooth-connected.icon-terrain:before, i.ph-bluetooth-connected.icon-taxon:before, i.ph-bluetooth-connected.icon-unknown:before, i.ph-bluetooth-connected.icon-nothing:before, i.ph-bluetooth-connected.icon-sampling:before, i.ph-bluetooth-connected.icon-isolation:before, i.ph-bluetooth-connected.icon-strain:before, i.ph-bluetooth-connected.icon-gps:before, i.ph-bluetooth-connected.icon-location:before, i.ph-bluetooth-connected.icon-add:before, i.ph-bluetooth-connected.icon-left:before, i.ph-bluetooth-connected.icon-dna:before, i.ph-bluetooth-connected.icon-delete:before, i.ph-bluetooth-connected.icon-edit:before, i.ph-bluetooth-connected.icon-info:before {
  content: "\e0dc";
}

.ph.ph-bluetooth-slash:before, i.ph-bluetooth-slash.icon-dead:before, i.ph-bluetooth-slash.icon-alive:before, i.ph-bluetooth-slash.icon-warn:before, i.ph-bluetooth-slash.icon-sign-out:before, i.ph-bluetooth-slash.icon-substratum:before, i.ph-bluetooth-slash.icon-environment:before, i.ph-bluetooth-slash.icon-user:before, i.ph-bluetooth-slash.icon-person:before, i.ph-bluetooth-slash.icon-terrain:before, i.ph-bluetooth-slash.icon-taxon:before, i.ph-bluetooth-slash.icon-unknown:before, i.ph-bluetooth-slash.icon-nothing:before, i.ph-bluetooth-slash.icon-sampling:before, i.ph-bluetooth-slash.icon-isolation:before, i.ph-bluetooth-slash.icon-strain:before, i.ph-bluetooth-slash.icon-gps:before, i.ph-bluetooth-slash.icon-location:before, i.ph-bluetooth-slash.icon-add:before, i.ph-bluetooth-slash.icon-left:before, i.ph-bluetooth-slash.icon-dna:before, i.ph-bluetooth-slash.icon-delete:before, i.ph-bluetooth-slash.icon-edit:before, i.ph-bluetooth-slash.icon-info:before {
  content: "\e0de";
}

.ph.ph-bluetooth-x:before, i.ph-bluetooth-x.icon-dead:before, i.ph-bluetooth-x.icon-alive:before, i.ph-bluetooth-x.icon-warn:before, i.ph-bluetooth-x.icon-sign-out:before, i.ph-bluetooth-x.icon-substratum:before, i.ph-bluetooth-x.icon-environment:before, i.ph-bluetooth-x.icon-user:before, i.ph-bluetooth-x.icon-person:before, i.ph-bluetooth-x.icon-terrain:before, i.ph-bluetooth-x.icon-taxon:before, i.ph-bluetooth-x.icon-unknown:before, i.ph-bluetooth-x.icon-nothing:before, i.ph-bluetooth-x.icon-sampling:before, i.ph-bluetooth-x.icon-isolation:before, i.ph-bluetooth-x.icon-strain:before, i.ph-bluetooth-x.icon-gps:before, i.ph-bluetooth-x.icon-location:before, i.ph-bluetooth-x.icon-add:before, i.ph-bluetooth-x.icon-left:before, i.ph-bluetooth-x.icon-dna:before, i.ph-bluetooth-x.icon-delete:before, i.ph-bluetooth-x.icon-edit:before, i.ph-bluetooth-x.icon-info:before {
  content: "\e0e0";
}

.ph.ph-boat:before, i.ph-boat.icon-dead:before, i.ph-boat.icon-alive:before, i.ph-boat.icon-warn:before, i.ph-boat.icon-sign-out:before, i.ph-boat.icon-substratum:before, i.ph-boat.icon-environment:before, i.ph-boat.icon-user:before, i.ph-boat.icon-person:before, i.ph-boat.icon-terrain:before, i.ph-boat.icon-taxon:before, i.ph-boat.icon-unknown:before, i.ph-boat.icon-nothing:before, i.ph-boat.icon-sampling:before, i.ph-boat.icon-isolation:before, i.ph-boat.icon-strain:before, i.ph-boat.icon-gps:before, i.ph-boat.icon-location:before, i.ph-boat.icon-add:before, i.ph-boat.icon-left:before, i.ph-boat.icon-dna:before, i.ph-boat.icon-delete:before, i.ph-boat.icon-edit:before, i.ph-boat.icon-info:before {
  content: "\e786";
}

.ph.ph-bomb:before, i.ph-bomb.icon-dead:before, i.ph-bomb.icon-alive:before, i.ph-bomb.icon-warn:before, i.ph-bomb.icon-sign-out:before, i.ph-bomb.icon-substratum:before, i.ph-bomb.icon-environment:before, i.ph-bomb.icon-user:before, i.ph-bomb.icon-person:before, i.ph-bomb.icon-terrain:before, i.ph-bomb.icon-taxon:before, i.ph-bomb.icon-unknown:before, i.ph-bomb.icon-nothing:before, i.ph-bomb.icon-sampling:before, i.ph-bomb.icon-isolation:before, i.ph-bomb.icon-strain:before, i.ph-bomb.icon-gps:before, i.ph-bomb.icon-location:before, i.ph-bomb.icon-add:before, i.ph-bomb.icon-left:before, i.ph-bomb.icon-dna:before, i.ph-bomb.icon-delete:before, i.ph-bomb.icon-edit:before, i.ph-bomb.icon-info:before {
  content: "\ee0a";
}

.ph.ph-bone:before, i.ph-bone.icon-dead:before, i.ph-bone.icon-alive:before, i.ph-bone.icon-warn:before, i.ph-bone.icon-sign-out:before, i.ph-bone.icon-substratum:before, i.ph-bone.icon-environment:before, i.ph-bone.icon-user:before, i.ph-bone.icon-person:before, i.ph-bone.icon-terrain:before, i.ph-bone.icon-taxon:before, i.ph-bone.icon-unknown:before, i.ph-bone.icon-nothing:before, i.ph-bone.icon-sampling:before, i.ph-bone.icon-isolation:before, i.ph-bone.icon-strain:before, i.ph-bone.icon-gps:before, i.ph-bone.icon-location:before, i.ph-bone.icon-add:before, i.ph-bone.icon-left:before, i.ph-bone.icon-dna:before, i.ph-bone.icon-delete:before, i.ph-bone.icon-edit:before, i.ph-bone.icon-info:before {
  content: "\e7f2";
}

.ph.ph-book:before, i.ph-book.icon-dead:before, i.ph-book.icon-alive:before, i.ph-book.icon-warn:before, i.ph-book.icon-sign-out:before, i.ph-book.icon-substratum:before, i.ph-book.icon-environment:before, i.ph-book.icon-user:before, i.ph-book.icon-person:before, i.ph-book.icon-terrain:before, i.ph-book.icon-taxon:before, i.ph-book.icon-unknown:before, i.ph-book.icon-nothing:before, i.ph-book.icon-sampling:before, i.ph-book.icon-isolation:before, i.ph-book.icon-strain:before, i.ph-book.icon-gps:before, i.ph-book.icon-location:before, i.ph-book.icon-add:before, i.ph-book.icon-left:before, i.ph-book.icon-dna:before, i.ph-book.icon-delete:before, i.ph-book.icon-edit:before, i.ph-book.icon-info:before {
  content: "\e0e2";
}

.ph.ph-book-bookmark:before, i.ph-book-bookmark.icon-dead:before, i.ph-book-bookmark.icon-alive:before, i.ph-book-bookmark.icon-warn:before, i.ph-book-bookmark.icon-sign-out:before, i.ph-book-bookmark.icon-substratum:before, i.ph-book-bookmark.icon-environment:before, i.ph-book-bookmark.icon-user:before, i.ph-book-bookmark.icon-person:before, i.ph-book-bookmark.icon-terrain:before, i.ph-book-bookmark.icon-taxon:before, i.ph-book-bookmark.icon-unknown:before, i.ph-book-bookmark.icon-nothing:before, i.ph-book-bookmark.icon-sampling:before, i.ph-book-bookmark.icon-isolation:before, i.ph-book-bookmark.icon-strain:before, i.ph-book-bookmark.icon-gps:before, i.ph-book-bookmark.icon-location:before, i.ph-book-bookmark.icon-add:before, i.ph-book-bookmark.icon-left:before, i.ph-book-bookmark.icon-dna:before, i.ph-book-bookmark.icon-delete:before, i.ph-book-bookmark.icon-edit:before, i.ph-book-bookmark.icon-info:before {
  content: "\e0e4";
}

.ph.ph-book-open:before, i.ph-book-open.icon-dead:before, i.ph-book-open.icon-alive:before, i.ph-book-open.icon-warn:before, i.ph-book-open.icon-sign-out:before, i.ph-book-open.icon-substratum:before, i.ph-book-open.icon-environment:before, i.ph-book-open.icon-user:before, i.ph-book-open.icon-person:before, i.ph-book-open.icon-terrain:before, i.ph-book-open.icon-taxon:before, i.ph-book-open.icon-unknown:before, i.ph-book-open.icon-nothing:before, i.ph-book-open.icon-sampling:before, i.ph-book-open.icon-isolation:before, i.ph-book-open.icon-strain:before, i.ph-book-open.icon-gps:before, i.ph-book-open.icon-location:before, i.ph-book-open.icon-add:before, i.ph-book-open.icon-left:before, i.ph-book-open.icon-dna:before, i.ph-book-open.icon-delete:before, i.ph-book-open.icon-edit:before, i.ph-book-open.icon-info:before {
  content: "\e0e6";
}

.ph.ph-book-open-text:before, i.ph-book-open-text.icon-dead:before, i.ph-book-open-text.icon-alive:before, i.ph-book-open-text.icon-warn:before, i.ph-book-open-text.icon-sign-out:before, i.ph-book-open-text.icon-substratum:before, i.ph-book-open-text.icon-environment:before, i.ph-book-open-text.icon-user:before, i.ph-book-open-text.icon-person:before, i.ph-book-open-text.icon-terrain:before, i.ph-book-open-text.icon-taxon:before, i.ph-book-open-text.icon-unknown:before, i.ph-book-open-text.icon-nothing:before, i.ph-book-open-text.icon-sampling:before, i.ph-book-open-text.icon-isolation:before, i.ph-book-open-text.icon-strain:before, i.ph-book-open-text.icon-gps:before, i.ph-book-open-text.icon-location:before, i.ph-book-open-text.icon-add:before, i.ph-book-open-text.icon-left:before, i.ph-book-open-text.icon-dna:before, i.ph-book-open-text.icon-delete:before, i.ph-book-open-text.icon-edit:before, i.ph-book-open-text.icon-info:before {
  content: "\e8f2";
}

.ph.ph-book-open-user:before, i.ph-book-open-user.icon-dead:before, i.ph-book-open-user.icon-alive:before, i.ph-book-open-user.icon-warn:before, i.ph-book-open-user.icon-sign-out:before, i.ph-book-open-user.icon-substratum:before, i.ph-book-open-user.icon-environment:before, i.ph-book-open-user.icon-user:before, i.ph-book-open-user.icon-person:before, i.ph-book-open-user.icon-terrain:before, i.ph-book-open-user.icon-taxon:before, i.ph-book-open-user.icon-unknown:before, i.ph-book-open-user.icon-nothing:before, i.ph-book-open-user.icon-sampling:before, i.ph-book-open-user.icon-isolation:before, i.ph-book-open-user.icon-strain:before, i.ph-book-open-user.icon-gps:before, i.ph-book-open-user.icon-location:before, i.ph-book-open-user.icon-add:before, i.ph-book-open-user.icon-left:before, i.ph-book-open-user.icon-dna:before, i.ph-book-open-user.icon-delete:before, i.ph-book-open-user.icon-edit:before, i.ph-book-open-user.icon-info:before {
  content: "\ede0";
}

.ph.ph-bookmark:before, i.ph-bookmark.icon-dead:before, i.ph-bookmark.icon-alive:before, i.ph-bookmark.icon-warn:before, i.ph-bookmark.icon-sign-out:before, i.ph-bookmark.icon-substratum:before, i.ph-bookmark.icon-environment:before, i.ph-bookmark.icon-user:before, i.ph-bookmark.icon-person:before, i.ph-bookmark.icon-terrain:before, i.ph-bookmark.icon-taxon:before, i.ph-bookmark.icon-unknown:before, i.ph-bookmark.icon-nothing:before, i.ph-bookmark.icon-sampling:before, i.ph-bookmark.icon-isolation:before, i.ph-bookmark.icon-strain:before, i.ph-bookmark.icon-gps:before, i.ph-bookmark.icon-location:before, i.ph-bookmark.icon-add:before, i.ph-bookmark.icon-left:before, i.ph-bookmark.icon-dna:before, i.ph-bookmark.icon-delete:before, i.ph-bookmark.icon-edit:before, i.ph-bookmark.icon-info:before {
  content: "\e0e8";
}

.ph.ph-bookmark-simple:before, i.ph-bookmark-simple.icon-dead:before, i.ph-bookmark-simple.icon-alive:before, i.ph-bookmark-simple.icon-warn:before, i.ph-bookmark-simple.icon-sign-out:before, i.ph-bookmark-simple.icon-substratum:before, i.ph-bookmark-simple.icon-environment:before, i.ph-bookmark-simple.icon-user:before, i.ph-bookmark-simple.icon-person:before, i.ph-bookmark-simple.icon-terrain:before, i.ph-bookmark-simple.icon-taxon:before, i.ph-bookmark-simple.icon-unknown:before, i.ph-bookmark-simple.icon-nothing:before, i.ph-bookmark-simple.icon-sampling:before, i.ph-bookmark-simple.icon-isolation:before, i.ph-bookmark-simple.icon-strain:before, i.ph-bookmark-simple.icon-gps:before, i.ph-bookmark-simple.icon-location:before, i.ph-bookmark-simple.icon-add:before, i.ph-bookmark-simple.icon-left:before, i.ph-bookmark-simple.icon-dna:before, i.ph-bookmark-simple.icon-delete:before, i.ph-bookmark-simple.icon-edit:before, i.ph-bookmark-simple.icon-info:before {
  content: "\e0ea";
}

.ph.ph-bookmarks:before, i.ph-bookmarks.icon-dead:before, i.ph-bookmarks.icon-alive:before, i.ph-bookmarks.icon-warn:before, i.ph-bookmarks.icon-sign-out:before, i.ph-bookmarks.icon-substratum:before, i.ph-bookmarks.icon-environment:before, i.ph-bookmarks.icon-user:before, i.ph-bookmarks.icon-person:before, i.ph-bookmarks.icon-terrain:before, i.ph-bookmarks.icon-taxon:before, i.ph-bookmarks.icon-unknown:before, i.ph-bookmarks.icon-nothing:before, i.ph-bookmarks.icon-sampling:before, i.ph-bookmarks.icon-isolation:before, i.ph-bookmarks.icon-strain:before, i.ph-bookmarks.icon-gps:before, i.ph-bookmarks.icon-location:before, i.ph-bookmarks.icon-add:before, i.ph-bookmarks.icon-left:before, i.ph-bookmarks.icon-dna:before, i.ph-bookmarks.icon-delete:before, i.ph-bookmarks.icon-edit:before, i.ph-bookmarks.icon-info:before {
  content: "\e0ec";
}

.ph.ph-bookmarks-simple:before, i.ph-bookmarks-simple.icon-dead:before, i.ph-bookmarks-simple.icon-alive:before, i.ph-bookmarks-simple.icon-warn:before, i.ph-bookmarks-simple.icon-sign-out:before, i.ph-bookmarks-simple.icon-substratum:before, i.ph-bookmarks-simple.icon-environment:before, i.ph-bookmarks-simple.icon-user:before, i.ph-bookmarks-simple.icon-person:before, i.ph-bookmarks-simple.icon-terrain:before, i.ph-bookmarks-simple.icon-taxon:before, i.ph-bookmarks-simple.icon-unknown:before, i.ph-bookmarks-simple.icon-nothing:before, i.ph-bookmarks-simple.icon-sampling:before, i.ph-bookmarks-simple.icon-isolation:before, i.ph-bookmarks-simple.icon-strain:before, i.ph-bookmarks-simple.icon-gps:before, i.ph-bookmarks-simple.icon-location:before, i.ph-bookmarks-simple.icon-add:before, i.ph-bookmarks-simple.icon-left:before, i.ph-bookmarks-simple.icon-dna:before, i.ph-bookmarks-simple.icon-delete:before, i.ph-bookmarks-simple.icon-edit:before, i.ph-bookmarks-simple.icon-info:before {
  content: "\e5f0";
}

.ph.ph-books:before, i.ph-books.icon-dead:before, i.ph-books.icon-alive:before, i.ph-books.icon-warn:before, i.ph-books.icon-sign-out:before, i.ph-books.icon-substratum:before, i.ph-books.icon-environment:before, i.ph-books.icon-user:before, i.ph-books.icon-person:before, i.ph-books.icon-terrain:before, i.ph-books.icon-taxon:before, i.ph-books.icon-unknown:before, i.ph-books.icon-nothing:before, i.ph-books.icon-sampling:before, i.ph-books.icon-isolation:before, i.ph-books.icon-strain:before, i.ph-books.icon-gps:before, i.ph-books.icon-location:before, i.ph-books.icon-add:before, i.ph-books.icon-left:before, i.ph-books.icon-dna:before, i.ph-books.icon-delete:before, i.ph-books.icon-edit:before, i.ph-books.icon-info:before {
  content: "\e758";
}

.ph.ph-boot:before, i.ph-boot.icon-dead:before, i.ph-boot.icon-alive:before, i.ph-boot.icon-warn:before, i.ph-boot.icon-sign-out:before, i.ph-boot.icon-substratum:before, i.ph-boot.icon-environment:before, i.ph-boot.icon-user:before, i.ph-boot.icon-person:before, i.ph-boot.icon-terrain:before, i.ph-boot.icon-taxon:before, i.ph-boot.icon-unknown:before, i.ph-boot.icon-nothing:before, i.ph-boot.icon-sampling:before, i.ph-boot.icon-isolation:before, i.ph-boot.icon-strain:before, i.ph-boot.icon-gps:before, i.ph-boot.icon-location:before, i.ph-boot.icon-add:before, i.ph-boot.icon-left:before, i.ph-boot.icon-dna:before, i.ph-boot.icon-delete:before, i.ph-boot.icon-edit:before, i.ph-boot.icon-info:before {
  content: "\ecca";
}

.ph.ph-boules:before, i.ph-boules.icon-dead:before, i.ph-boules.icon-alive:before, i.ph-boules.icon-warn:before, i.ph-boules.icon-sign-out:before, i.ph-boules.icon-substratum:before, i.ph-boules.icon-environment:before, i.ph-boules.icon-user:before, i.ph-boules.icon-person:before, i.ph-boules.icon-terrain:before, i.ph-boules.icon-taxon:before, i.ph-boules.icon-unknown:before, i.ph-boules.icon-nothing:before, i.ph-boules.icon-sampling:before, i.ph-boules.icon-isolation:before, i.ph-boules.icon-strain:before, i.ph-boules.icon-gps:before, i.ph-boules.icon-location:before, i.ph-boules.icon-add:before, i.ph-boules.icon-left:before, i.ph-boules.icon-dna:before, i.ph-boules.icon-delete:before, i.ph-boules.icon-edit:before, i.ph-boules.icon-info:before {
  content: "\e722";
}

.ph.ph-bounding-box:before, i.ph-bounding-box.icon-dead:before, i.ph-bounding-box.icon-alive:before, i.ph-bounding-box.icon-warn:before, i.ph-bounding-box.icon-sign-out:before, i.ph-bounding-box.icon-substratum:before, i.ph-bounding-box.icon-environment:before, i.ph-bounding-box.icon-user:before, i.ph-bounding-box.icon-person:before, i.ph-bounding-box.icon-terrain:before, i.ph-bounding-box.icon-taxon:before, i.ph-bounding-box.icon-unknown:before, i.ph-bounding-box.icon-nothing:before, i.ph-bounding-box.icon-sampling:before, i.ph-bounding-box.icon-isolation:before, i.ph-bounding-box.icon-strain:before, i.ph-bounding-box.icon-gps:before, i.ph-bounding-box.icon-location:before, i.ph-bounding-box.icon-add:before, i.ph-bounding-box.icon-left:before, i.ph-bounding-box.icon-dna:before, i.ph-bounding-box.icon-delete:before, i.ph-bounding-box.icon-edit:before, i.ph-bounding-box.icon-info:before {
  content: "\e6ce";
}

.ph.ph-bowl-food:before, i.ph-bowl-food.icon-dead:before, i.ph-bowl-food.icon-alive:before, i.ph-bowl-food.icon-warn:before, i.ph-bowl-food.icon-sign-out:before, i.ph-bowl-food.icon-substratum:before, i.ph-bowl-food.icon-environment:before, i.ph-bowl-food.icon-user:before, i.ph-bowl-food.icon-person:before, i.ph-bowl-food.icon-terrain:before, i.ph-bowl-food.icon-taxon:before, i.ph-bowl-food.icon-unknown:before, i.ph-bowl-food.icon-nothing:before, i.ph-bowl-food.icon-sampling:before, i.ph-bowl-food.icon-isolation:before, i.ph-bowl-food.icon-strain:before, i.ph-bowl-food.icon-gps:before, i.ph-bowl-food.icon-location:before, i.ph-bowl-food.icon-add:before, i.ph-bowl-food.icon-left:before, i.ph-bowl-food.icon-dna:before, i.ph-bowl-food.icon-delete:before, i.ph-bowl-food.icon-edit:before, i.ph-bowl-food.icon-info:before {
  content: "\eaa4";
}

.ph.ph-bowl-steam:before, i.ph-bowl-steam.icon-dead:before, i.ph-bowl-steam.icon-alive:before, i.ph-bowl-steam.icon-warn:before, i.ph-bowl-steam.icon-sign-out:before, i.ph-bowl-steam.icon-substratum:before, i.ph-bowl-steam.icon-environment:before, i.ph-bowl-steam.icon-user:before, i.ph-bowl-steam.icon-person:before, i.ph-bowl-steam.icon-terrain:before, i.ph-bowl-steam.icon-taxon:before, i.ph-bowl-steam.icon-unknown:before, i.ph-bowl-steam.icon-nothing:before, i.ph-bowl-steam.icon-sampling:before, i.ph-bowl-steam.icon-isolation:before, i.ph-bowl-steam.icon-strain:before, i.ph-bowl-steam.icon-gps:before, i.ph-bowl-steam.icon-location:before, i.ph-bowl-steam.icon-add:before, i.ph-bowl-steam.icon-left:before, i.ph-bowl-steam.icon-dna:before, i.ph-bowl-steam.icon-delete:before, i.ph-bowl-steam.icon-edit:before, i.ph-bowl-steam.icon-info:before {
  content: "\e8e4";
}

.ph.ph-bowling-ball:before, i.ph-bowling-ball.icon-dead:before, i.ph-bowling-ball.icon-alive:before, i.ph-bowling-ball.icon-warn:before, i.ph-bowling-ball.icon-sign-out:before, i.ph-bowling-ball.icon-substratum:before, i.ph-bowling-ball.icon-environment:before, i.ph-bowling-ball.icon-user:before, i.ph-bowling-ball.icon-person:before, i.ph-bowling-ball.icon-terrain:before, i.ph-bowling-ball.icon-taxon:before, i.ph-bowling-ball.icon-unknown:before, i.ph-bowling-ball.icon-nothing:before, i.ph-bowling-ball.icon-sampling:before, i.ph-bowling-ball.icon-isolation:before, i.ph-bowling-ball.icon-strain:before, i.ph-bowling-ball.icon-gps:before, i.ph-bowling-ball.icon-location:before, i.ph-bowling-ball.icon-add:before, i.ph-bowling-ball.icon-left:before, i.ph-bowling-ball.icon-dna:before, i.ph-bowling-ball.icon-delete:before, i.ph-bowling-ball.icon-edit:before, i.ph-bowling-ball.icon-info:before {
  content: "\ea34";
}

.ph.ph-box-arrow-down:before, i.ph-box-arrow-down.icon-dead:before, i.ph-box-arrow-down.icon-alive:before, i.ph-box-arrow-down.icon-warn:before, i.ph-box-arrow-down.icon-sign-out:before, i.ph-box-arrow-down.icon-substratum:before, i.ph-box-arrow-down.icon-environment:before, i.ph-box-arrow-down.icon-user:before, i.ph-box-arrow-down.icon-person:before, i.ph-box-arrow-down.icon-terrain:before, i.ph-box-arrow-down.icon-taxon:before, i.ph-box-arrow-down.icon-unknown:before, i.ph-box-arrow-down.icon-nothing:before, i.ph-box-arrow-down.icon-sampling:before, i.ph-box-arrow-down.icon-isolation:before, i.ph-box-arrow-down.icon-strain:before, i.ph-box-arrow-down.icon-gps:before, i.ph-box-arrow-down.icon-location:before, i.ph-box-arrow-down.icon-add:before, i.ph-box-arrow-down.icon-left:before, i.ph-box-arrow-down.icon-dna:before, i.ph-box-arrow-down.icon-delete:before, i.ph-box-arrow-down.icon-edit:before, i.ph-box-arrow-down.icon-info:before {
  content: "\e00e";
}

.ph.ph-archive-box:before, i.ph-archive-box.icon-dead:before, i.ph-archive-box.icon-alive:before, i.ph-archive-box.icon-warn:before, i.ph-archive-box.icon-sign-out:before, i.ph-archive-box.icon-substratum:before, i.ph-archive-box.icon-environment:before, i.ph-archive-box.icon-user:before, i.ph-archive-box.icon-person:before, i.ph-archive-box.icon-terrain:before, i.ph-archive-box.icon-taxon:before, i.ph-archive-box.icon-unknown:before, i.ph-archive-box.icon-nothing:before, i.ph-archive-box.icon-sampling:before, i.ph-archive-box.icon-isolation:before, i.ph-archive-box.icon-strain:before, i.ph-archive-box.icon-gps:before, i.ph-archive-box.icon-location:before, i.ph-archive-box.icon-add:before, i.ph-archive-box.icon-left:before, i.ph-archive-box.icon-dna:before, i.ph-archive-box.icon-delete:before, i.ph-archive-box.icon-edit:before, i.ph-archive-box.icon-info:before {
  content: "\e00e";
}

.ph.ph-box-arrow-up:before, i.ph-box-arrow-up.icon-dead:before, i.ph-box-arrow-up.icon-alive:before, i.ph-box-arrow-up.icon-warn:before, i.ph-box-arrow-up.icon-sign-out:before, i.ph-box-arrow-up.icon-substratum:before, i.ph-box-arrow-up.icon-environment:before, i.ph-box-arrow-up.icon-user:before, i.ph-box-arrow-up.icon-person:before, i.ph-box-arrow-up.icon-terrain:before, i.ph-box-arrow-up.icon-taxon:before, i.ph-box-arrow-up.icon-unknown:before, i.ph-box-arrow-up.icon-nothing:before, i.ph-box-arrow-up.icon-sampling:before, i.ph-box-arrow-up.icon-isolation:before, i.ph-box-arrow-up.icon-strain:before, i.ph-box-arrow-up.icon-gps:before, i.ph-box-arrow-up.icon-location:before, i.ph-box-arrow-up.icon-add:before, i.ph-box-arrow-up.icon-left:before, i.ph-box-arrow-up.icon-dna:before, i.ph-box-arrow-up.icon-delete:before, i.ph-box-arrow-up.icon-edit:before, i.ph-box-arrow-up.icon-info:before {
  content: "\ee54";
}

.ph.ph-boxing-glove:before, i.ph-boxing-glove.icon-dead:before, i.ph-boxing-glove.icon-alive:before, i.ph-boxing-glove.icon-warn:before, i.ph-boxing-glove.icon-sign-out:before, i.ph-boxing-glove.icon-substratum:before, i.ph-boxing-glove.icon-environment:before, i.ph-boxing-glove.icon-user:before, i.ph-boxing-glove.icon-person:before, i.ph-boxing-glove.icon-terrain:before, i.ph-boxing-glove.icon-taxon:before, i.ph-boxing-glove.icon-unknown:before, i.ph-boxing-glove.icon-nothing:before, i.ph-boxing-glove.icon-sampling:before, i.ph-boxing-glove.icon-isolation:before, i.ph-boxing-glove.icon-strain:before, i.ph-boxing-glove.icon-gps:before, i.ph-boxing-glove.icon-location:before, i.ph-boxing-glove.icon-add:before, i.ph-boxing-glove.icon-left:before, i.ph-boxing-glove.icon-dna:before, i.ph-boxing-glove.icon-delete:before, i.ph-boxing-glove.icon-edit:before, i.ph-boxing-glove.icon-info:before {
  content: "\ea36";
}

.ph.ph-brackets-angle:before, i.ph-brackets-angle.icon-dead:before, i.ph-brackets-angle.icon-alive:before, i.ph-brackets-angle.icon-warn:before, i.ph-brackets-angle.icon-sign-out:before, i.ph-brackets-angle.icon-substratum:before, i.ph-brackets-angle.icon-environment:before, i.ph-brackets-angle.icon-user:before, i.ph-brackets-angle.icon-person:before, i.ph-brackets-angle.icon-terrain:before, i.ph-brackets-angle.icon-taxon:before, i.ph-brackets-angle.icon-unknown:before, i.ph-brackets-angle.icon-nothing:before, i.ph-brackets-angle.icon-sampling:before, i.ph-brackets-angle.icon-isolation:before, i.ph-brackets-angle.icon-strain:before, i.ph-brackets-angle.icon-gps:before, i.ph-brackets-angle.icon-location:before, i.ph-brackets-angle.icon-add:before, i.ph-brackets-angle.icon-left:before, i.ph-brackets-angle.icon-dna:before, i.ph-brackets-angle.icon-delete:before, i.ph-brackets-angle.icon-edit:before, i.ph-brackets-angle.icon-info:before {
  content: "\e862";
}

.ph.ph-brackets-curly:before, i.ph-brackets-curly.icon-dead:before, i.ph-brackets-curly.icon-alive:before, i.ph-brackets-curly.icon-warn:before, i.ph-brackets-curly.icon-sign-out:before, i.ph-brackets-curly.icon-substratum:before, i.ph-brackets-curly.icon-environment:before, i.ph-brackets-curly.icon-user:before, i.ph-brackets-curly.icon-person:before, i.ph-brackets-curly.icon-terrain:before, i.ph-brackets-curly.icon-taxon:before, i.ph-brackets-curly.icon-unknown:before, i.ph-brackets-curly.icon-nothing:before, i.ph-brackets-curly.icon-sampling:before, i.ph-brackets-curly.icon-isolation:before, i.ph-brackets-curly.icon-strain:before, i.ph-brackets-curly.icon-gps:before, i.ph-brackets-curly.icon-location:before, i.ph-brackets-curly.icon-add:before, i.ph-brackets-curly.icon-left:before, i.ph-brackets-curly.icon-dna:before, i.ph-brackets-curly.icon-delete:before, i.ph-brackets-curly.icon-edit:before, i.ph-brackets-curly.icon-info:before {
  content: "\e860";
}

.ph.ph-brackets-round:before, i.ph-brackets-round.icon-dead:before, i.ph-brackets-round.icon-alive:before, i.ph-brackets-round.icon-warn:before, i.ph-brackets-round.icon-sign-out:before, i.ph-brackets-round.icon-substratum:before, i.ph-brackets-round.icon-environment:before, i.ph-brackets-round.icon-user:before, i.ph-brackets-round.icon-person:before, i.ph-brackets-round.icon-terrain:before, i.ph-brackets-round.icon-taxon:before, i.ph-brackets-round.icon-unknown:before, i.ph-brackets-round.icon-nothing:before, i.ph-brackets-round.icon-sampling:before, i.ph-brackets-round.icon-isolation:before, i.ph-brackets-round.icon-strain:before, i.ph-brackets-round.icon-gps:before, i.ph-brackets-round.icon-location:before, i.ph-brackets-round.icon-add:before, i.ph-brackets-round.icon-left:before, i.ph-brackets-round.icon-dna:before, i.ph-brackets-round.icon-delete:before, i.ph-brackets-round.icon-edit:before, i.ph-brackets-round.icon-info:before {
  content: "\e864";
}

.ph.ph-brackets-square:before, i.ph-brackets-square.icon-dead:before, i.ph-brackets-square.icon-alive:before, i.ph-brackets-square.icon-warn:before, i.ph-brackets-square.icon-sign-out:before, i.ph-brackets-square.icon-substratum:before, i.ph-brackets-square.icon-environment:before, i.ph-brackets-square.icon-user:before, i.ph-brackets-square.icon-person:before, i.ph-brackets-square.icon-terrain:before, i.ph-brackets-square.icon-taxon:before, i.ph-brackets-square.icon-unknown:before, i.ph-brackets-square.icon-nothing:before, i.ph-brackets-square.icon-sampling:before, i.ph-brackets-square.icon-isolation:before, i.ph-brackets-square.icon-strain:before, i.ph-brackets-square.icon-gps:before, i.ph-brackets-square.icon-location:before, i.ph-brackets-square.icon-add:before, i.ph-brackets-square.icon-left:before, i.ph-brackets-square.icon-dna:before, i.ph-brackets-square.icon-delete:before, i.ph-brackets-square.icon-edit:before, i.ph-brackets-square.icon-info:before {
  content: "\e85e";
}

.ph.ph-brain:before, i.ph-brain.icon-dead:before, i.ph-brain.icon-alive:before, i.ph-brain.icon-warn:before, i.ph-brain.icon-sign-out:before, i.ph-brain.icon-substratum:before, i.ph-brain.icon-environment:before, i.ph-brain.icon-user:before, i.ph-brain.icon-person:before, i.ph-brain.icon-terrain:before, i.ph-brain.icon-taxon:before, i.ph-brain.icon-unknown:before, i.ph-brain.icon-nothing:before, i.ph-brain.icon-sampling:before, i.ph-brain.icon-isolation:before, i.ph-brain.icon-strain:before, i.ph-brain.icon-gps:before, i.ph-brain.icon-location:before, i.ph-brain.icon-add:before, i.ph-brain.icon-left:before, i.ph-brain.icon-dna:before, i.ph-brain.icon-delete:before, i.ph-brain.icon-edit:before, i.ph-brain.icon-info:before {
  content: "\e74e";
}

.ph.ph-brandy:before, i.ph-brandy.icon-dead:before, i.ph-brandy.icon-alive:before, i.ph-brandy.icon-warn:before, i.ph-brandy.icon-sign-out:before, i.ph-brandy.icon-substratum:before, i.ph-brandy.icon-environment:before, i.ph-brandy.icon-user:before, i.ph-brandy.icon-person:before, i.ph-brandy.icon-terrain:before, i.ph-brandy.icon-taxon:before, i.ph-brandy.icon-unknown:before, i.ph-brandy.icon-nothing:before, i.ph-brandy.icon-sampling:before, i.ph-brandy.icon-isolation:before, i.ph-brandy.icon-strain:before, i.ph-brandy.icon-gps:before, i.ph-brandy.icon-location:before, i.ph-brandy.icon-add:before, i.ph-brandy.icon-left:before, i.ph-brandy.icon-dna:before, i.ph-brandy.icon-delete:before, i.ph-brandy.icon-edit:before, i.ph-brandy.icon-info:before {
  content: "\e6b4";
}

.ph.ph-bread:before, i.ph-bread.icon-dead:before, i.ph-bread.icon-alive:before, i.ph-bread.icon-warn:before, i.ph-bread.icon-sign-out:before, i.ph-bread.icon-substratum:before, i.ph-bread.icon-environment:before, i.ph-bread.icon-user:before, i.ph-bread.icon-person:before, i.ph-bread.icon-terrain:before, i.ph-bread.icon-taxon:before, i.ph-bread.icon-unknown:before, i.ph-bread.icon-nothing:before, i.ph-bread.icon-sampling:before, i.ph-bread.icon-isolation:before, i.ph-bread.icon-strain:before, i.ph-bread.icon-gps:before, i.ph-bread.icon-location:before, i.ph-bread.icon-add:before, i.ph-bread.icon-left:before, i.ph-bread.icon-dna:before, i.ph-bread.icon-delete:before, i.ph-bread.icon-edit:before, i.ph-bread.icon-info:before {
  content: "\e81c";
}

.ph.ph-bridge:before, i.ph-bridge.icon-dead:before, i.ph-bridge.icon-alive:before, i.ph-bridge.icon-warn:before, i.ph-bridge.icon-sign-out:before, i.ph-bridge.icon-substratum:before, i.ph-bridge.icon-environment:before, i.ph-bridge.icon-user:before, i.ph-bridge.icon-person:before, i.ph-bridge.icon-terrain:before, i.ph-bridge.icon-taxon:before, i.ph-bridge.icon-unknown:before, i.ph-bridge.icon-nothing:before, i.ph-bridge.icon-sampling:before, i.ph-bridge.icon-isolation:before, i.ph-bridge.icon-strain:before, i.ph-bridge.icon-gps:before, i.ph-bridge.icon-location:before, i.ph-bridge.icon-add:before, i.ph-bridge.icon-left:before, i.ph-bridge.icon-dna:before, i.ph-bridge.icon-delete:before, i.ph-bridge.icon-edit:before, i.ph-bridge.icon-info:before {
  content: "\ea68";
}

.ph.ph-briefcase:before, i.ph-briefcase.icon-dead:before, i.ph-briefcase.icon-alive:before, i.ph-briefcase.icon-warn:before, i.ph-briefcase.icon-sign-out:before, i.ph-briefcase.icon-substratum:before, i.ph-briefcase.icon-environment:before, i.ph-briefcase.icon-user:before, i.ph-briefcase.icon-person:before, i.ph-briefcase.icon-terrain:before, i.ph-briefcase.icon-taxon:before, i.ph-briefcase.icon-unknown:before, i.ph-briefcase.icon-nothing:before, i.ph-briefcase.icon-sampling:before, i.ph-briefcase.icon-isolation:before, i.ph-briefcase.icon-strain:before, i.ph-briefcase.icon-gps:before, i.ph-briefcase.icon-location:before, i.ph-briefcase.icon-add:before, i.ph-briefcase.icon-left:before, i.ph-briefcase.icon-dna:before, i.ph-briefcase.icon-delete:before, i.ph-briefcase.icon-edit:before, i.ph-briefcase.icon-info:before {
  content: "\e0ee";
}

.ph.ph-briefcase-metal:before, i.ph-briefcase-metal.icon-dead:before, i.ph-briefcase-metal.icon-alive:before, i.ph-briefcase-metal.icon-warn:before, i.ph-briefcase-metal.icon-sign-out:before, i.ph-briefcase-metal.icon-substratum:before, i.ph-briefcase-metal.icon-environment:before, i.ph-briefcase-metal.icon-user:before, i.ph-briefcase-metal.icon-person:before, i.ph-briefcase-metal.icon-terrain:before, i.ph-briefcase-metal.icon-taxon:before, i.ph-briefcase-metal.icon-unknown:before, i.ph-briefcase-metal.icon-nothing:before, i.ph-briefcase-metal.icon-sampling:before, i.ph-briefcase-metal.icon-isolation:before, i.ph-briefcase-metal.icon-strain:before, i.ph-briefcase-metal.icon-gps:before, i.ph-briefcase-metal.icon-location:before, i.ph-briefcase-metal.icon-add:before, i.ph-briefcase-metal.icon-left:before, i.ph-briefcase-metal.icon-dna:before, i.ph-briefcase-metal.icon-delete:before, i.ph-briefcase-metal.icon-edit:before, i.ph-briefcase-metal.icon-info:before {
  content: "\e5f2";
}

.ph.ph-broadcast:before, i.ph-broadcast.icon-dead:before, i.ph-broadcast.icon-alive:before, i.ph-broadcast.icon-warn:before, i.ph-broadcast.icon-sign-out:before, i.ph-broadcast.icon-substratum:before, i.ph-broadcast.icon-environment:before, i.ph-broadcast.icon-user:before, i.ph-broadcast.icon-person:before, i.ph-broadcast.icon-terrain:before, i.ph-broadcast.icon-taxon:before, i.ph-broadcast.icon-unknown:before, i.ph-broadcast.icon-nothing:before, i.ph-broadcast.icon-sampling:before, i.ph-broadcast.icon-isolation:before, i.ph-broadcast.icon-strain:before, i.ph-broadcast.icon-gps:before, i.ph-broadcast.icon-location:before, i.ph-broadcast.icon-add:before, i.ph-broadcast.icon-left:before, i.ph-broadcast.icon-dna:before, i.ph-broadcast.icon-delete:before, i.ph-broadcast.icon-edit:before, i.ph-broadcast.icon-info:before {
  content: "\e0f2";
}

.ph.ph-broom:before, i.ph-broom.icon-dead:before, i.ph-broom.icon-alive:before, i.ph-broom.icon-warn:before, i.ph-broom.icon-sign-out:before, i.ph-broom.icon-substratum:before, i.ph-broom.icon-environment:before, i.ph-broom.icon-user:before, i.ph-broom.icon-person:before, i.ph-broom.icon-terrain:before, i.ph-broom.icon-taxon:before, i.ph-broom.icon-unknown:before, i.ph-broom.icon-nothing:before, i.ph-broom.icon-sampling:before, i.ph-broom.icon-isolation:before, i.ph-broom.icon-strain:before, i.ph-broom.icon-gps:before, i.ph-broom.icon-location:before, i.ph-broom.icon-add:before, i.ph-broom.icon-left:before, i.ph-broom.icon-dna:before, i.ph-broom.icon-delete:before, i.ph-broom.icon-edit:before, i.ph-broom.icon-info:before {
  content: "\ec54";
}

.ph.ph-browser:before, i.ph-browser.icon-dead:before, i.ph-browser.icon-alive:before, i.ph-browser.icon-warn:before, i.ph-browser.icon-sign-out:before, i.ph-browser.icon-substratum:before, i.ph-browser.icon-environment:before, i.ph-browser.icon-user:before, i.ph-browser.icon-person:before, i.ph-browser.icon-terrain:before, i.ph-browser.icon-taxon:before, i.ph-browser.icon-unknown:before, i.ph-browser.icon-nothing:before, i.ph-browser.icon-sampling:before, i.ph-browser.icon-isolation:before, i.ph-browser.icon-strain:before, i.ph-browser.icon-gps:before, i.ph-browser.icon-location:before, i.ph-browser.icon-add:before, i.ph-browser.icon-left:before, i.ph-browser.icon-dna:before, i.ph-browser.icon-delete:before, i.ph-browser.icon-edit:before, i.ph-browser.icon-info:before {
  content: "\e0f4";
}

.ph.ph-browsers:before, i.ph-browsers.icon-dead:before, i.ph-browsers.icon-alive:before, i.ph-browsers.icon-warn:before, i.ph-browsers.icon-sign-out:before, i.ph-browsers.icon-substratum:before, i.ph-browsers.icon-environment:before, i.ph-browsers.icon-user:before, i.ph-browsers.icon-person:before, i.ph-browsers.icon-terrain:before, i.ph-browsers.icon-taxon:before, i.ph-browsers.icon-unknown:before, i.ph-browsers.icon-nothing:before, i.ph-browsers.icon-sampling:before, i.ph-browsers.icon-isolation:before, i.ph-browsers.icon-strain:before, i.ph-browsers.icon-gps:before, i.ph-browsers.icon-location:before, i.ph-browsers.icon-add:before, i.ph-browsers.icon-left:before, i.ph-browsers.icon-dna:before, i.ph-browsers.icon-delete:before, i.ph-browsers.icon-edit:before, i.ph-browsers.icon-info:before {
  content: "\e0f6";
}

.ph.ph-bug:before, i.ph-bug.icon-dead:before, i.ph-bug.icon-alive:before, i.ph-bug.icon-warn:before, i.ph-bug.icon-sign-out:before, i.ph-bug.icon-substratum:before, i.ph-bug.icon-environment:before, i.ph-bug.icon-user:before, i.ph-bug.icon-person:before, i.ph-bug.icon-terrain:before, i.ph-bug.icon-taxon:before, i.ph-bug.icon-unknown:before, i.ph-bug.icon-nothing:before, i.ph-bug.icon-sampling:before, i.ph-bug.icon-isolation:before, i.ph-bug.icon-strain:before, i.ph-bug.icon-gps:before, i.ph-bug.icon-location:before, i.ph-bug.icon-add:before, i.ph-bug.icon-left:before, i.ph-bug.icon-dna:before, i.ph-bug.icon-delete:before, i.ph-bug.icon-edit:before, i.ph-bug.icon-info:before {
  content: "\e5f4";
}

.ph.ph-bug-beetle:before, i.ph-bug-beetle.icon-dead:before, i.ph-bug-beetle.icon-alive:before, i.ph-bug-beetle.icon-warn:before, i.ph-bug-beetle.icon-sign-out:before, i.ph-bug-beetle.icon-substratum:before, i.ph-bug-beetle.icon-environment:before, i.ph-bug-beetle.icon-user:before, i.ph-bug-beetle.icon-person:before, i.ph-bug-beetle.icon-terrain:before, i.ph-bug-beetle.icon-taxon:before, i.ph-bug-beetle.icon-unknown:before, i.ph-bug-beetle.icon-nothing:before, i.ph-bug-beetle.icon-sampling:before, i.ph-bug-beetle.icon-isolation:before, i.ph-bug-beetle.icon-strain:before, i.ph-bug-beetle.icon-gps:before, i.ph-bug-beetle.icon-location:before, i.ph-bug-beetle.icon-add:before, i.ph-bug-beetle.icon-left:before, i.ph-bug-beetle.icon-dna:before, i.ph-bug-beetle.icon-delete:before, i.ph-bug-beetle.icon-edit:before, i.ph-bug-beetle.icon-info:before {
  content: "\e5f6";
}

.ph.ph-bug-droid:before, i.ph-bug-droid.icon-dead:before, i.ph-bug-droid.icon-alive:before, i.ph-bug-droid.icon-warn:before, i.ph-bug-droid.icon-sign-out:before, i.ph-bug-droid.icon-substratum:before, i.ph-bug-droid.icon-environment:before, i.ph-bug-droid.icon-user:before, i.ph-bug-droid.icon-person:before, i.ph-bug-droid.icon-terrain:before, i.ph-bug-droid.icon-taxon:before, i.ph-bug-droid.icon-unknown:before, i.ph-bug-droid.icon-nothing:before, i.ph-bug-droid.icon-sampling:before, i.ph-bug-droid.icon-isolation:before, i.ph-bug-droid.icon-strain:before, i.ph-bug-droid.icon-gps:before, i.ph-bug-droid.icon-location:before, i.ph-bug-droid.icon-add:before, i.ph-bug-droid.icon-left:before, i.ph-bug-droid.icon-dna:before, i.ph-bug-droid.icon-delete:before, i.ph-bug-droid.icon-edit:before, i.ph-bug-droid.icon-info:before {
  content: "\e5f8";
}

.ph.ph-building:before, i.ph-building.icon-dead:before, i.ph-building.icon-alive:before, i.ph-building.icon-warn:before, i.ph-building.icon-sign-out:before, i.ph-building.icon-substratum:before, i.ph-building.icon-environment:before, i.ph-building.icon-user:before, i.ph-building.icon-person:before, i.ph-building.icon-terrain:before, i.ph-building.icon-taxon:before, i.ph-building.icon-unknown:before, i.ph-building.icon-nothing:before, i.ph-building.icon-sampling:before, i.ph-building.icon-isolation:before, i.ph-building.icon-strain:before, i.ph-building.icon-gps:before, i.ph-building.icon-location:before, i.ph-building.icon-add:before, i.ph-building.icon-left:before, i.ph-building.icon-dna:before, i.ph-building.icon-delete:before, i.ph-building.icon-edit:before, i.ph-building.icon-info:before {
  content: "\e100";
}

.ph.ph-building-apartment:before, i.ph-building-apartment.icon-dead:before, i.ph-building-apartment.icon-alive:before, i.ph-building-apartment.icon-warn:before, i.ph-building-apartment.icon-sign-out:before, i.ph-building-apartment.icon-substratum:before, i.ph-building-apartment.icon-environment:before, i.ph-building-apartment.icon-user:before, i.ph-building-apartment.icon-person:before, i.ph-building-apartment.icon-terrain:before, i.ph-building-apartment.icon-taxon:before, i.ph-building-apartment.icon-unknown:before, i.ph-building-apartment.icon-nothing:before, i.ph-building-apartment.icon-sampling:before, i.ph-building-apartment.icon-isolation:before, i.ph-building-apartment.icon-strain:before, i.ph-building-apartment.icon-gps:before, i.ph-building-apartment.icon-location:before, i.ph-building-apartment.icon-add:before, i.ph-building-apartment.icon-left:before, i.ph-building-apartment.icon-dna:before, i.ph-building-apartment.icon-delete:before, i.ph-building-apartment.icon-edit:before, i.ph-building-apartment.icon-info:before {
  content: "\e0fe";
}

.ph.ph-building-office:before, i.ph-building-office.icon-dead:before, i.ph-building-office.icon-alive:before, i.ph-building-office.icon-warn:before, i.ph-building-office.icon-sign-out:before, i.ph-building-office.icon-substratum:before, i.ph-building-office.icon-environment:before, i.ph-building-office.icon-user:before, i.ph-building-office.icon-person:before, i.ph-building-office.icon-terrain:before, i.ph-building-office.icon-taxon:before, i.ph-building-office.icon-unknown:before, i.ph-building-office.icon-nothing:before, i.ph-building-office.icon-sampling:before, i.ph-building-office.icon-isolation:before, i.ph-building-office.icon-strain:before, i.ph-building-office.icon-gps:before, i.ph-building-office.icon-location:before, i.ph-building-office.icon-add:before, i.ph-building-office.icon-left:before, i.ph-building-office.icon-dna:before, i.ph-building-office.icon-delete:before, i.ph-building-office.icon-edit:before, i.ph-building-office.icon-info:before {
  content: "\e0ff";
}

.ph.ph-buildings:before, i.ph-buildings.icon-dead:before, i.ph-buildings.icon-alive:before, i.ph-buildings.icon-warn:before, i.ph-buildings.icon-sign-out:before, i.ph-buildings.icon-substratum:before, i.ph-buildings.icon-environment:before, i.ph-buildings.icon-user:before, i.ph-buildings.icon-person:before, i.ph-buildings.icon-terrain:before, i.ph-buildings.icon-taxon:before, i.ph-buildings.icon-unknown:before, i.ph-buildings.icon-nothing:before, i.ph-buildings.icon-sampling:before, i.ph-buildings.icon-isolation:before, i.ph-buildings.icon-strain:before, i.ph-buildings.icon-gps:before, i.ph-buildings.icon-location:before, i.ph-buildings.icon-add:before, i.ph-buildings.icon-left:before, i.ph-buildings.icon-dna:before, i.ph-buildings.icon-delete:before, i.ph-buildings.icon-edit:before, i.ph-buildings.icon-info:before {
  content: "\e102";
}

.ph.ph-bulldozer:before, i.ph-bulldozer.icon-dead:before, i.ph-bulldozer.icon-alive:before, i.ph-bulldozer.icon-warn:before, i.ph-bulldozer.icon-sign-out:before, i.ph-bulldozer.icon-substratum:before, i.ph-bulldozer.icon-environment:before, i.ph-bulldozer.icon-user:before, i.ph-bulldozer.icon-person:before, i.ph-bulldozer.icon-terrain:before, i.ph-bulldozer.icon-taxon:before, i.ph-bulldozer.icon-unknown:before, i.ph-bulldozer.icon-nothing:before, i.ph-bulldozer.icon-sampling:before, i.ph-bulldozer.icon-isolation:before, i.ph-bulldozer.icon-strain:before, i.ph-bulldozer.icon-gps:before, i.ph-bulldozer.icon-location:before, i.ph-bulldozer.icon-add:before, i.ph-bulldozer.icon-left:before, i.ph-bulldozer.icon-dna:before, i.ph-bulldozer.icon-delete:before, i.ph-bulldozer.icon-edit:before, i.ph-bulldozer.icon-info:before {
  content: "\ec6c";
}

.ph.ph-bus:before, i.ph-bus.icon-dead:before, i.ph-bus.icon-alive:before, i.ph-bus.icon-warn:before, i.ph-bus.icon-sign-out:before, i.ph-bus.icon-substratum:before, i.ph-bus.icon-environment:before, i.ph-bus.icon-user:before, i.ph-bus.icon-person:before, i.ph-bus.icon-terrain:before, i.ph-bus.icon-taxon:before, i.ph-bus.icon-unknown:before, i.ph-bus.icon-nothing:before, i.ph-bus.icon-sampling:before, i.ph-bus.icon-isolation:before, i.ph-bus.icon-strain:before, i.ph-bus.icon-gps:before, i.ph-bus.icon-location:before, i.ph-bus.icon-add:before, i.ph-bus.icon-left:before, i.ph-bus.icon-dna:before, i.ph-bus.icon-delete:before, i.ph-bus.icon-edit:before, i.ph-bus.icon-info:before {
  content: "\e106";
}

.ph.ph-butterfly:before, i.ph-butterfly.icon-dead:before, i.ph-butterfly.icon-alive:before, i.ph-butterfly.icon-warn:before, i.ph-butterfly.icon-sign-out:before, i.ph-butterfly.icon-substratum:before, i.ph-butterfly.icon-environment:before, i.ph-butterfly.icon-user:before, i.ph-butterfly.icon-person:before, i.ph-butterfly.icon-terrain:before, i.ph-butterfly.icon-taxon:before, i.ph-butterfly.icon-unknown:before, i.ph-butterfly.icon-nothing:before, i.ph-butterfly.icon-sampling:before, i.ph-butterfly.icon-isolation:before, i.ph-butterfly.icon-strain:before, i.ph-butterfly.icon-gps:before, i.ph-butterfly.icon-location:before, i.ph-butterfly.icon-add:before, i.ph-butterfly.icon-left:before, i.ph-butterfly.icon-dna:before, i.ph-butterfly.icon-delete:before, i.ph-butterfly.icon-edit:before, i.ph-butterfly.icon-info:before {
  content: "\ea6e";
}

.ph.ph-cable-car:before, i.ph-cable-car.icon-dead:before, i.ph-cable-car.icon-alive:before, i.ph-cable-car.icon-warn:before, i.ph-cable-car.icon-sign-out:before, i.ph-cable-car.icon-substratum:before, i.ph-cable-car.icon-environment:before, i.ph-cable-car.icon-user:before, i.ph-cable-car.icon-person:before, i.ph-cable-car.icon-terrain:before, i.ph-cable-car.icon-taxon:before, i.ph-cable-car.icon-unknown:before, i.ph-cable-car.icon-nothing:before, i.ph-cable-car.icon-sampling:before, i.ph-cable-car.icon-isolation:before, i.ph-cable-car.icon-strain:before, i.ph-cable-car.icon-gps:before, i.ph-cable-car.icon-location:before, i.ph-cable-car.icon-add:before, i.ph-cable-car.icon-left:before, i.ph-cable-car.icon-dna:before, i.ph-cable-car.icon-delete:before, i.ph-cable-car.icon-edit:before, i.ph-cable-car.icon-info:before {
  content: "\e49c";
}

.ph.ph-cactus:before, i.ph-cactus.icon-dead:before, i.ph-cactus.icon-alive:before, i.ph-cactus.icon-warn:before, i.ph-cactus.icon-sign-out:before, i.ph-cactus.icon-substratum:before, i.ph-cactus.icon-environment:before, i.ph-cactus.icon-user:before, i.ph-cactus.icon-person:before, i.ph-cactus.icon-terrain:before, i.ph-cactus.icon-taxon:before, i.ph-cactus.icon-unknown:before, i.ph-cactus.icon-nothing:before, i.ph-cactus.icon-sampling:before, i.ph-cactus.icon-isolation:before, i.ph-cactus.icon-strain:before, i.ph-cactus.icon-gps:before, i.ph-cactus.icon-location:before, i.ph-cactus.icon-add:before, i.ph-cactus.icon-left:before, i.ph-cactus.icon-dna:before, i.ph-cactus.icon-delete:before, i.ph-cactus.icon-edit:before, i.ph-cactus.icon-info:before {
  content: "\e918";
}

.ph.ph-cake:before, i.ph-cake.icon-dead:before, i.ph-cake.icon-alive:before, i.ph-cake.icon-warn:before, i.ph-cake.icon-sign-out:before, i.ph-cake.icon-substratum:before, i.ph-cake.icon-environment:before, i.ph-cake.icon-user:before, i.ph-cake.icon-person:before, i.ph-cake.icon-terrain:before, i.ph-cake.icon-taxon:before, i.ph-cake.icon-unknown:before, i.ph-cake.icon-nothing:before, i.ph-cake.icon-sampling:before, i.ph-cake.icon-isolation:before, i.ph-cake.icon-strain:before, i.ph-cake.icon-gps:before, i.ph-cake.icon-location:before, i.ph-cake.icon-add:before, i.ph-cake.icon-left:before, i.ph-cake.icon-dna:before, i.ph-cake.icon-delete:before, i.ph-cake.icon-edit:before, i.ph-cake.icon-info:before {
  content: "\e780";
}

.ph.ph-calculator:before, i.ph-calculator.icon-dead:before, i.ph-calculator.icon-alive:before, i.ph-calculator.icon-warn:before, i.ph-calculator.icon-sign-out:before, i.ph-calculator.icon-substratum:before, i.ph-calculator.icon-environment:before, i.ph-calculator.icon-user:before, i.ph-calculator.icon-person:before, i.ph-calculator.icon-terrain:before, i.ph-calculator.icon-taxon:before, i.ph-calculator.icon-unknown:before, i.ph-calculator.icon-nothing:before, i.ph-calculator.icon-sampling:before, i.ph-calculator.icon-isolation:before, i.ph-calculator.icon-strain:before, i.ph-calculator.icon-gps:before, i.ph-calculator.icon-location:before, i.ph-calculator.icon-add:before, i.ph-calculator.icon-left:before, i.ph-calculator.icon-dna:before, i.ph-calculator.icon-delete:before, i.ph-calculator.icon-edit:before, i.ph-calculator.icon-info:before {
  content: "\e538";
}

.ph.ph-calendar:before, i.ph-calendar.icon-dead:before, i.ph-calendar.icon-alive:before, i.ph-calendar.icon-warn:before, i.ph-calendar.icon-sign-out:before, i.ph-calendar.icon-substratum:before, i.ph-calendar.icon-environment:before, i.ph-calendar.icon-user:before, i.ph-calendar.icon-person:before, i.ph-calendar.icon-terrain:before, i.ph-calendar.icon-taxon:before, i.ph-calendar.icon-unknown:before, i.ph-calendar.icon-nothing:before, i.ph-calendar.icon-sampling:before, i.ph-calendar.icon-isolation:before, i.ph-calendar.icon-strain:before, i.ph-calendar.icon-gps:before, i.ph-calendar.icon-location:before, i.ph-calendar.icon-add:before, i.ph-calendar.icon-left:before, i.ph-calendar.icon-dna:before, i.ph-calendar.icon-delete:before, i.ph-calendar.icon-edit:before, i.ph-calendar.icon-info:before {
  content: "\e108";
}

.ph.ph-calendar-blank:before, i.ph-calendar-blank.icon-dead:before, i.ph-calendar-blank.icon-alive:before, i.ph-calendar-blank.icon-warn:before, i.ph-calendar-blank.icon-sign-out:before, i.ph-calendar-blank.icon-substratum:before, i.ph-calendar-blank.icon-environment:before, i.ph-calendar-blank.icon-user:before, i.ph-calendar-blank.icon-person:before, i.ph-calendar-blank.icon-terrain:before, i.ph-calendar-blank.icon-taxon:before, i.ph-calendar-blank.icon-unknown:before, i.ph-calendar-blank.icon-nothing:before, i.ph-calendar-blank.icon-sampling:before, i.ph-calendar-blank.icon-isolation:before, i.ph-calendar-blank.icon-strain:before, i.ph-calendar-blank.icon-gps:before, i.ph-calendar-blank.icon-location:before, i.ph-calendar-blank.icon-add:before, i.ph-calendar-blank.icon-left:before, i.ph-calendar-blank.icon-dna:before, i.ph-calendar-blank.icon-delete:before, i.ph-calendar-blank.icon-edit:before, i.ph-calendar-blank.icon-info:before {
  content: "\e10a";
}

.ph.ph-calendar-check:before, i.ph-calendar-check.icon-dead:before, i.ph-calendar-check.icon-alive:before, i.ph-calendar-check.icon-warn:before, i.ph-calendar-check.icon-sign-out:before, i.ph-calendar-check.icon-substratum:before, i.ph-calendar-check.icon-environment:before, i.ph-calendar-check.icon-user:before, i.ph-calendar-check.icon-person:before, i.ph-calendar-check.icon-terrain:before, i.ph-calendar-check.icon-taxon:before, i.ph-calendar-check.icon-unknown:before, i.ph-calendar-check.icon-nothing:before, i.ph-calendar-check.icon-sampling:before, i.ph-calendar-check.icon-isolation:before, i.ph-calendar-check.icon-strain:before, i.ph-calendar-check.icon-gps:before, i.ph-calendar-check.icon-location:before, i.ph-calendar-check.icon-add:before, i.ph-calendar-check.icon-left:before, i.ph-calendar-check.icon-dna:before, i.ph-calendar-check.icon-delete:before, i.ph-calendar-check.icon-edit:before, i.ph-calendar-check.icon-info:before {
  content: "\e712";
}

.ph.ph-calendar-dot:before, i.ph-calendar-dot.icon-dead:before, i.ph-calendar-dot.icon-alive:before, i.ph-calendar-dot.icon-warn:before, i.ph-calendar-dot.icon-sign-out:before, i.ph-calendar-dot.icon-substratum:before, i.ph-calendar-dot.icon-environment:before, i.ph-calendar-dot.icon-user:before, i.ph-calendar-dot.icon-person:before, i.ph-calendar-dot.icon-terrain:before, i.ph-calendar-dot.icon-taxon:before, i.ph-calendar-dot.icon-unknown:before, i.ph-calendar-dot.icon-nothing:before, i.ph-calendar-dot.icon-sampling:before, i.ph-calendar-dot.icon-isolation:before, i.ph-calendar-dot.icon-strain:before, i.ph-calendar-dot.icon-gps:before, i.ph-calendar-dot.icon-location:before, i.ph-calendar-dot.icon-add:before, i.ph-calendar-dot.icon-left:before, i.ph-calendar-dot.icon-dna:before, i.ph-calendar-dot.icon-delete:before, i.ph-calendar-dot.icon-edit:before, i.ph-calendar-dot.icon-info:before {
  content: "\e7b2";
}

.ph.ph-calendar-dots:before, i.ph-calendar-dots.icon-dead:before, i.ph-calendar-dots.icon-alive:before, i.ph-calendar-dots.icon-warn:before, i.ph-calendar-dots.icon-sign-out:before, i.ph-calendar-dots.icon-substratum:before, i.ph-calendar-dots.icon-environment:before, i.ph-calendar-dots.icon-user:before, i.ph-calendar-dots.icon-person:before, i.ph-calendar-dots.icon-terrain:before, i.ph-calendar-dots.icon-taxon:before, i.ph-calendar-dots.icon-unknown:before, i.ph-calendar-dots.icon-nothing:before, i.ph-calendar-dots.icon-sampling:before, i.ph-calendar-dots.icon-isolation:before, i.ph-calendar-dots.icon-strain:before, i.ph-calendar-dots.icon-gps:before, i.ph-calendar-dots.icon-location:before, i.ph-calendar-dots.icon-add:before, i.ph-calendar-dots.icon-left:before, i.ph-calendar-dots.icon-dna:before, i.ph-calendar-dots.icon-delete:before, i.ph-calendar-dots.icon-edit:before, i.ph-calendar-dots.icon-info:before {
  content: "\e7b4";
}

.ph.ph-calendar-heart:before, i.ph-calendar-heart.icon-dead:before, i.ph-calendar-heart.icon-alive:before, i.ph-calendar-heart.icon-warn:before, i.ph-calendar-heart.icon-sign-out:before, i.ph-calendar-heart.icon-substratum:before, i.ph-calendar-heart.icon-environment:before, i.ph-calendar-heart.icon-user:before, i.ph-calendar-heart.icon-person:before, i.ph-calendar-heart.icon-terrain:before, i.ph-calendar-heart.icon-taxon:before, i.ph-calendar-heart.icon-unknown:before, i.ph-calendar-heart.icon-nothing:before, i.ph-calendar-heart.icon-sampling:before, i.ph-calendar-heart.icon-isolation:before, i.ph-calendar-heart.icon-strain:before, i.ph-calendar-heart.icon-gps:before, i.ph-calendar-heart.icon-location:before, i.ph-calendar-heart.icon-add:before, i.ph-calendar-heart.icon-left:before, i.ph-calendar-heart.icon-dna:before, i.ph-calendar-heart.icon-delete:before, i.ph-calendar-heart.icon-edit:before, i.ph-calendar-heart.icon-info:before {
  content: "\e8b0";
}

.ph.ph-calendar-minus:before, i.ph-calendar-minus.icon-dead:before, i.ph-calendar-minus.icon-alive:before, i.ph-calendar-minus.icon-warn:before, i.ph-calendar-minus.icon-sign-out:before, i.ph-calendar-minus.icon-substratum:before, i.ph-calendar-minus.icon-environment:before, i.ph-calendar-minus.icon-user:before, i.ph-calendar-minus.icon-person:before, i.ph-calendar-minus.icon-terrain:before, i.ph-calendar-minus.icon-taxon:before, i.ph-calendar-minus.icon-unknown:before, i.ph-calendar-minus.icon-nothing:before, i.ph-calendar-minus.icon-sampling:before, i.ph-calendar-minus.icon-isolation:before, i.ph-calendar-minus.icon-strain:before, i.ph-calendar-minus.icon-gps:before, i.ph-calendar-minus.icon-location:before, i.ph-calendar-minus.icon-add:before, i.ph-calendar-minus.icon-left:before, i.ph-calendar-minus.icon-dna:before, i.ph-calendar-minus.icon-delete:before, i.ph-calendar-minus.icon-edit:before, i.ph-calendar-minus.icon-info:before {
  content: "\ea14";
}

.ph.ph-calendar-plus:before, i.ph-calendar-plus.icon-dead:before, i.ph-calendar-plus.icon-alive:before, i.ph-calendar-plus.icon-warn:before, i.ph-calendar-plus.icon-sign-out:before, i.ph-calendar-plus.icon-substratum:before, i.ph-calendar-plus.icon-environment:before, i.ph-calendar-plus.icon-user:before, i.ph-calendar-plus.icon-person:before, i.ph-calendar-plus.icon-terrain:before, i.ph-calendar-plus.icon-taxon:before, i.ph-calendar-plus.icon-unknown:before, i.ph-calendar-plus.icon-nothing:before, i.ph-calendar-plus.icon-sampling:before, i.ph-calendar-plus.icon-isolation:before, i.ph-calendar-plus.icon-strain:before, i.ph-calendar-plus.icon-gps:before, i.ph-calendar-plus.icon-location:before, i.ph-calendar-plus.icon-add:before, i.ph-calendar-plus.icon-left:before, i.ph-calendar-plus.icon-dna:before, i.ph-calendar-plus.icon-delete:before, i.ph-calendar-plus.icon-edit:before, i.ph-calendar-plus.icon-info:before {
  content: "\e714";
}

.ph.ph-calendar-slash:before, i.ph-calendar-slash.icon-dead:before, i.ph-calendar-slash.icon-alive:before, i.ph-calendar-slash.icon-warn:before, i.ph-calendar-slash.icon-sign-out:before, i.ph-calendar-slash.icon-substratum:before, i.ph-calendar-slash.icon-environment:before, i.ph-calendar-slash.icon-user:before, i.ph-calendar-slash.icon-person:before, i.ph-calendar-slash.icon-terrain:before, i.ph-calendar-slash.icon-taxon:before, i.ph-calendar-slash.icon-unknown:before, i.ph-calendar-slash.icon-nothing:before, i.ph-calendar-slash.icon-sampling:before, i.ph-calendar-slash.icon-isolation:before, i.ph-calendar-slash.icon-strain:before, i.ph-calendar-slash.icon-gps:before, i.ph-calendar-slash.icon-location:before, i.ph-calendar-slash.icon-add:before, i.ph-calendar-slash.icon-left:before, i.ph-calendar-slash.icon-dna:before, i.ph-calendar-slash.icon-delete:before, i.ph-calendar-slash.icon-edit:before, i.ph-calendar-slash.icon-info:before {
  content: "\ea12";
}

.ph.ph-calendar-star:before, i.ph-calendar-star.icon-dead:before, i.ph-calendar-star.icon-alive:before, i.ph-calendar-star.icon-warn:before, i.ph-calendar-star.icon-sign-out:before, i.ph-calendar-star.icon-substratum:before, i.ph-calendar-star.icon-environment:before, i.ph-calendar-star.icon-user:before, i.ph-calendar-star.icon-person:before, i.ph-calendar-star.icon-terrain:before, i.ph-calendar-star.icon-taxon:before, i.ph-calendar-star.icon-unknown:before, i.ph-calendar-star.icon-nothing:before, i.ph-calendar-star.icon-sampling:before, i.ph-calendar-star.icon-isolation:before, i.ph-calendar-star.icon-strain:before, i.ph-calendar-star.icon-gps:before, i.ph-calendar-star.icon-location:before, i.ph-calendar-star.icon-add:before, i.ph-calendar-star.icon-left:before, i.ph-calendar-star.icon-dna:before, i.ph-calendar-star.icon-delete:before, i.ph-calendar-star.icon-edit:before, i.ph-calendar-star.icon-info:before {
  content: "\e8b2";
}

.ph.ph-calendar-x:before, i.ph-calendar-x.icon-dead:before, i.ph-calendar-x.icon-alive:before, i.ph-calendar-x.icon-warn:before, i.ph-calendar-x.icon-sign-out:before, i.ph-calendar-x.icon-substratum:before, i.ph-calendar-x.icon-environment:before, i.ph-calendar-x.icon-user:before, i.ph-calendar-x.icon-person:before, i.ph-calendar-x.icon-terrain:before, i.ph-calendar-x.icon-taxon:before, i.ph-calendar-x.icon-unknown:before, i.ph-calendar-x.icon-nothing:before, i.ph-calendar-x.icon-sampling:before, i.ph-calendar-x.icon-isolation:before, i.ph-calendar-x.icon-strain:before, i.ph-calendar-x.icon-gps:before, i.ph-calendar-x.icon-location:before, i.ph-calendar-x.icon-add:before, i.ph-calendar-x.icon-left:before, i.ph-calendar-x.icon-dna:before, i.ph-calendar-x.icon-delete:before, i.ph-calendar-x.icon-edit:before, i.ph-calendar-x.icon-info:before {
  content: "\e10c";
}

.ph.ph-call-bell:before, i.ph-call-bell.icon-dead:before, i.ph-call-bell.icon-alive:before, i.ph-call-bell.icon-warn:before, i.ph-call-bell.icon-sign-out:before, i.ph-call-bell.icon-substratum:before, i.ph-call-bell.icon-environment:before, i.ph-call-bell.icon-user:before, i.ph-call-bell.icon-person:before, i.ph-call-bell.icon-terrain:before, i.ph-call-bell.icon-taxon:before, i.ph-call-bell.icon-unknown:before, i.ph-call-bell.icon-nothing:before, i.ph-call-bell.icon-sampling:before, i.ph-call-bell.icon-isolation:before, i.ph-call-bell.icon-strain:before, i.ph-call-bell.icon-gps:before, i.ph-call-bell.icon-location:before, i.ph-call-bell.icon-add:before, i.ph-call-bell.icon-left:before, i.ph-call-bell.icon-dna:before, i.ph-call-bell.icon-delete:before, i.ph-call-bell.icon-edit:before, i.ph-call-bell.icon-info:before {
  content: "\e7de";
}

.ph.ph-camera:before, i.ph-camera.icon-dead:before, i.ph-camera.icon-alive:before, i.ph-camera.icon-warn:before, i.ph-camera.icon-sign-out:before, i.ph-camera.icon-substratum:before, i.ph-camera.icon-environment:before, i.ph-camera.icon-user:before, i.ph-camera.icon-person:before, i.ph-camera.icon-terrain:before, i.ph-camera.icon-taxon:before, i.ph-camera.icon-unknown:before, i.ph-camera.icon-nothing:before, i.ph-camera.icon-sampling:before, i.ph-camera.icon-isolation:before, i.ph-camera.icon-strain:before, i.ph-camera.icon-gps:before, i.ph-camera.icon-location:before, i.ph-camera.icon-add:before, i.ph-camera.icon-left:before, i.ph-camera.icon-dna:before, i.ph-camera.icon-delete:before, i.ph-camera.icon-edit:before, i.ph-camera.icon-info:before {
  content: "\e10e";
}

.ph.ph-camera-plus:before, i.ph-camera-plus.icon-dead:before, i.ph-camera-plus.icon-alive:before, i.ph-camera-plus.icon-warn:before, i.ph-camera-plus.icon-sign-out:before, i.ph-camera-plus.icon-substratum:before, i.ph-camera-plus.icon-environment:before, i.ph-camera-plus.icon-user:before, i.ph-camera-plus.icon-person:before, i.ph-camera-plus.icon-terrain:before, i.ph-camera-plus.icon-taxon:before, i.ph-camera-plus.icon-unknown:before, i.ph-camera-plus.icon-nothing:before, i.ph-camera-plus.icon-sampling:before, i.ph-camera-plus.icon-isolation:before, i.ph-camera-plus.icon-strain:before, i.ph-camera-plus.icon-gps:before, i.ph-camera-plus.icon-location:before, i.ph-camera-plus.icon-add:before, i.ph-camera-plus.icon-left:before, i.ph-camera-plus.icon-dna:before, i.ph-camera-plus.icon-delete:before, i.ph-camera-plus.icon-edit:before, i.ph-camera-plus.icon-info:before {
  content: "\ec58";
}

.ph.ph-camera-rotate:before, i.ph-camera-rotate.icon-dead:before, i.ph-camera-rotate.icon-alive:before, i.ph-camera-rotate.icon-warn:before, i.ph-camera-rotate.icon-sign-out:before, i.ph-camera-rotate.icon-substratum:before, i.ph-camera-rotate.icon-environment:before, i.ph-camera-rotate.icon-user:before, i.ph-camera-rotate.icon-person:before, i.ph-camera-rotate.icon-terrain:before, i.ph-camera-rotate.icon-taxon:before, i.ph-camera-rotate.icon-unknown:before, i.ph-camera-rotate.icon-nothing:before, i.ph-camera-rotate.icon-sampling:before, i.ph-camera-rotate.icon-isolation:before, i.ph-camera-rotate.icon-strain:before, i.ph-camera-rotate.icon-gps:before, i.ph-camera-rotate.icon-location:before, i.ph-camera-rotate.icon-add:before, i.ph-camera-rotate.icon-left:before, i.ph-camera-rotate.icon-dna:before, i.ph-camera-rotate.icon-delete:before, i.ph-camera-rotate.icon-edit:before, i.ph-camera-rotate.icon-info:before {
  content: "\e7a4";
}

.ph.ph-camera-slash:before, i.ph-camera-slash.icon-dead:before, i.ph-camera-slash.icon-alive:before, i.ph-camera-slash.icon-warn:before, i.ph-camera-slash.icon-sign-out:before, i.ph-camera-slash.icon-substratum:before, i.ph-camera-slash.icon-environment:before, i.ph-camera-slash.icon-user:before, i.ph-camera-slash.icon-person:before, i.ph-camera-slash.icon-terrain:before, i.ph-camera-slash.icon-taxon:before, i.ph-camera-slash.icon-unknown:before, i.ph-camera-slash.icon-nothing:before, i.ph-camera-slash.icon-sampling:before, i.ph-camera-slash.icon-isolation:before, i.ph-camera-slash.icon-strain:before, i.ph-camera-slash.icon-gps:before, i.ph-camera-slash.icon-location:before, i.ph-camera-slash.icon-add:before, i.ph-camera-slash.icon-left:before, i.ph-camera-slash.icon-dna:before, i.ph-camera-slash.icon-delete:before, i.ph-camera-slash.icon-edit:before, i.ph-camera-slash.icon-info:before {
  content: "\e110";
}

.ph.ph-campfire:before, i.ph-campfire.icon-dead:before, i.ph-campfire.icon-alive:before, i.ph-campfire.icon-warn:before, i.ph-campfire.icon-sign-out:before, i.ph-campfire.icon-substratum:before, i.ph-campfire.icon-environment:before, i.ph-campfire.icon-user:before, i.ph-campfire.icon-person:before, i.ph-campfire.icon-terrain:before, i.ph-campfire.icon-taxon:before, i.ph-campfire.icon-unknown:before, i.ph-campfire.icon-nothing:before, i.ph-campfire.icon-sampling:before, i.ph-campfire.icon-isolation:before, i.ph-campfire.icon-strain:before, i.ph-campfire.icon-gps:before, i.ph-campfire.icon-location:before, i.ph-campfire.icon-add:before, i.ph-campfire.icon-left:before, i.ph-campfire.icon-dna:before, i.ph-campfire.icon-delete:before, i.ph-campfire.icon-edit:before, i.ph-campfire.icon-info:before {
  content: "\e9d8";
}

.ph.ph-car:before, i.ph-car.icon-dead:before, i.ph-car.icon-alive:before, i.ph-car.icon-warn:before, i.ph-car.icon-sign-out:before, i.ph-car.icon-substratum:before, i.ph-car.icon-environment:before, i.ph-car.icon-user:before, i.ph-car.icon-person:before, i.ph-car.icon-terrain:before, i.ph-car.icon-taxon:before, i.ph-car.icon-unknown:before, i.ph-car.icon-nothing:before, i.ph-car.icon-sampling:before, i.ph-car.icon-isolation:before, i.ph-car.icon-strain:before, i.ph-car.icon-gps:before, i.ph-car.icon-location:before, i.ph-car.icon-add:before, i.ph-car.icon-left:before, i.ph-car.icon-dna:before, i.ph-car.icon-delete:before, i.ph-car.icon-edit:before, i.ph-car.icon-info:before {
  content: "\e112";
}

.ph.ph-car-battery:before, i.ph-car-battery.icon-dead:before, i.ph-car-battery.icon-alive:before, i.ph-car-battery.icon-warn:before, i.ph-car-battery.icon-sign-out:before, i.ph-car-battery.icon-substratum:before, i.ph-car-battery.icon-environment:before, i.ph-car-battery.icon-user:before, i.ph-car-battery.icon-person:before, i.ph-car-battery.icon-terrain:before, i.ph-car-battery.icon-taxon:before, i.ph-car-battery.icon-unknown:before, i.ph-car-battery.icon-nothing:before, i.ph-car-battery.icon-sampling:before, i.ph-car-battery.icon-isolation:before, i.ph-car-battery.icon-strain:before, i.ph-car-battery.icon-gps:before, i.ph-car-battery.icon-location:before, i.ph-car-battery.icon-add:before, i.ph-car-battery.icon-left:before, i.ph-car-battery.icon-dna:before, i.ph-car-battery.icon-delete:before, i.ph-car-battery.icon-edit:before, i.ph-car-battery.icon-info:before {
  content: "\ee30";
}

.ph.ph-car-profile:before, i.ph-car-profile.icon-dead:before, i.ph-car-profile.icon-alive:before, i.ph-car-profile.icon-warn:before, i.ph-car-profile.icon-sign-out:before, i.ph-car-profile.icon-substratum:before, i.ph-car-profile.icon-environment:before, i.ph-car-profile.icon-user:before, i.ph-car-profile.icon-person:before, i.ph-car-profile.icon-terrain:before, i.ph-car-profile.icon-taxon:before, i.ph-car-profile.icon-unknown:before, i.ph-car-profile.icon-nothing:before, i.ph-car-profile.icon-sampling:before, i.ph-car-profile.icon-isolation:before, i.ph-car-profile.icon-strain:before, i.ph-car-profile.icon-gps:before, i.ph-car-profile.icon-location:before, i.ph-car-profile.icon-add:before, i.ph-car-profile.icon-left:before, i.ph-car-profile.icon-dna:before, i.ph-car-profile.icon-delete:before, i.ph-car-profile.icon-edit:before, i.ph-car-profile.icon-info:before {
  content: "\e8cc";
}

.ph.ph-car-simple:before, i.ph-car-simple.icon-dead:before, i.ph-car-simple.icon-alive:before, i.ph-car-simple.icon-warn:before, i.ph-car-simple.icon-sign-out:before, i.ph-car-simple.icon-substratum:before, i.ph-car-simple.icon-environment:before, i.ph-car-simple.icon-user:before, i.ph-car-simple.icon-person:before, i.ph-car-simple.icon-terrain:before, i.ph-car-simple.icon-taxon:before, i.ph-car-simple.icon-unknown:before, i.ph-car-simple.icon-nothing:before, i.ph-car-simple.icon-sampling:before, i.ph-car-simple.icon-isolation:before, i.ph-car-simple.icon-strain:before, i.ph-car-simple.icon-gps:before, i.ph-car-simple.icon-location:before, i.ph-car-simple.icon-add:before, i.ph-car-simple.icon-left:before, i.ph-car-simple.icon-dna:before, i.ph-car-simple.icon-delete:before, i.ph-car-simple.icon-edit:before, i.ph-car-simple.icon-info:before {
  content: "\e114";
}

.ph.ph-cardholder:before, i.ph-cardholder.icon-dead:before, i.ph-cardholder.icon-alive:before, i.ph-cardholder.icon-warn:before, i.ph-cardholder.icon-sign-out:before, i.ph-cardholder.icon-substratum:before, i.ph-cardholder.icon-environment:before, i.ph-cardholder.icon-user:before, i.ph-cardholder.icon-person:before, i.ph-cardholder.icon-terrain:before, i.ph-cardholder.icon-taxon:before, i.ph-cardholder.icon-unknown:before, i.ph-cardholder.icon-nothing:before, i.ph-cardholder.icon-sampling:before, i.ph-cardholder.icon-isolation:before, i.ph-cardholder.icon-strain:before, i.ph-cardholder.icon-gps:before, i.ph-cardholder.icon-location:before, i.ph-cardholder.icon-add:before, i.ph-cardholder.icon-left:before, i.ph-cardholder.icon-dna:before, i.ph-cardholder.icon-delete:before, i.ph-cardholder.icon-edit:before, i.ph-cardholder.icon-info:before {
  content: "\e5fa";
}

.ph.ph-cards:before, i.ph-cards.icon-dead:before, i.ph-cards.icon-alive:before, i.ph-cards.icon-warn:before, i.ph-cards.icon-sign-out:before, i.ph-cards.icon-substratum:before, i.ph-cards.icon-environment:before, i.ph-cards.icon-user:before, i.ph-cards.icon-person:before, i.ph-cards.icon-terrain:before, i.ph-cards.icon-taxon:before, i.ph-cards.icon-unknown:before, i.ph-cards.icon-nothing:before, i.ph-cards.icon-sampling:before, i.ph-cards.icon-isolation:before, i.ph-cards.icon-strain:before, i.ph-cards.icon-gps:before, i.ph-cards.icon-location:before, i.ph-cards.icon-add:before, i.ph-cards.icon-left:before, i.ph-cards.icon-dna:before, i.ph-cards.icon-delete:before, i.ph-cards.icon-edit:before, i.ph-cards.icon-info:before {
  content: "\e0f8";
}

.ph.ph-cards-three:before, i.ph-cards-three.icon-dead:before, i.ph-cards-three.icon-alive:before, i.ph-cards-three.icon-warn:before, i.ph-cards-three.icon-sign-out:before, i.ph-cards-three.icon-substratum:before, i.ph-cards-three.icon-environment:before, i.ph-cards-three.icon-user:before, i.ph-cards-three.icon-person:before, i.ph-cards-three.icon-terrain:before, i.ph-cards-three.icon-taxon:before, i.ph-cards-three.icon-unknown:before, i.ph-cards-three.icon-nothing:before, i.ph-cards-three.icon-sampling:before, i.ph-cards-three.icon-isolation:before, i.ph-cards-three.icon-strain:before, i.ph-cards-three.icon-gps:before, i.ph-cards-three.icon-location:before, i.ph-cards-three.icon-add:before, i.ph-cards-three.icon-left:before, i.ph-cards-three.icon-dna:before, i.ph-cards-three.icon-delete:before, i.ph-cards-three.icon-edit:before, i.ph-cards-three.icon-info:before {
  content: "\ee50";
}

.ph.ph-caret-circle-double-down:before, i.ph-caret-circle-double-down.icon-dead:before, i.ph-caret-circle-double-down.icon-alive:before, i.ph-caret-circle-double-down.icon-warn:before, i.ph-caret-circle-double-down.icon-sign-out:before, i.ph-caret-circle-double-down.icon-substratum:before, i.ph-caret-circle-double-down.icon-environment:before, i.ph-caret-circle-double-down.icon-user:before, i.ph-caret-circle-double-down.icon-person:before, i.ph-caret-circle-double-down.icon-terrain:before, i.ph-caret-circle-double-down.icon-taxon:before, i.ph-caret-circle-double-down.icon-unknown:before, i.ph-caret-circle-double-down.icon-nothing:before, i.ph-caret-circle-double-down.icon-sampling:before, i.ph-caret-circle-double-down.icon-isolation:before, i.ph-caret-circle-double-down.icon-strain:before, i.ph-caret-circle-double-down.icon-gps:before, i.ph-caret-circle-double-down.icon-location:before, i.ph-caret-circle-double-down.icon-add:before, i.ph-caret-circle-double-down.icon-left:before, i.ph-caret-circle-double-down.icon-dna:before, i.ph-caret-circle-double-down.icon-delete:before, i.ph-caret-circle-double-down.icon-edit:before, i.ph-caret-circle-double-down.icon-info:before {
  content: "\e116";
}

.ph.ph-caret-circle-double-left:before, i.ph-caret-circle-double-left.icon-dead:before, i.ph-caret-circle-double-left.icon-alive:before, i.ph-caret-circle-double-left.icon-warn:before, i.ph-caret-circle-double-left.icon-sign-out:before, i.ph-caret-circle-double-left.icon-substratum:before, i.ph-caret-circle-double-left.icon-environment:before, i.ph-caret-circle-double-left.icon-user:before, i.ph-caret-circle-double-left.icon-person:before, i.ph-caret-circle-double-left.icon-terrain:before, i.ph-caret-circle-double-left.icon-taxon:before, i.ph-caret-circle-double-left.icon-unknown:before, i.ph-caret-circle-double-left.icon-nothing:before, i.ph-caret-circle-double-left.icon-sampling:before, i.ph-caret-circle-double-left.icon-isolation:before, i.ph-caret-circle-double-left.icon-strain:before, i.ph-caret-circle-double-left.icon-gps:before, i.ph-caret-circle-double-left.icon-location:before, i.ph-caret-circle-double-left.icon-add:before, i.ph-caret-circle-double-left.icon-left:before, i.ph-caret-circle-double-left.icon-dna:before, i.ph-caret-circle-double-left.icon-delete:before, i.ph-caret-circle-double-left.icon-edit:before, i.ph-caret-circle-double-left.icon-info:before {
  content: "\e118";
}

.ph.ph-caret-circle-double-right:before, i.ph-caret-circle-double-right.icon-dead:before, i.ph-caret-circle-double-right.icon-alive:before, i.ph-caret-circle-double-right.icon-warn:before, i.ph-caret-circle-double-right.icon-sign-out:before, i.ph-caret-circle-double-right.icon-substratum:before, i.ph-caret-circle-double-right.icon-environment:before, i.ph-caret-circle-double-right.icon-user:before, i.ph-caret-circle-double-right.icon-person:before, i.ph-caret-circle-double-right.icon-terrain:before, i.ph-caret-circle-double-right.icon-taxon:before, i.ph-caret-circle-double-right.icon-unknown:before, i.ph-caret-circle-double-right.icon-nothing:before, i.ph-caret-circle-double-right.icon-sampling:before, i.ph-caret-circle-double-right.icon-isolation:before, i.ph-caret-circle-double-right.icon-strain:before, i.ph-caret-circle-double-right.icon-gps:before, i.ph-caret-circle-double-right.icon-location:before, i.ph-caret-circle-double-right.icon-add:before, i.ph-caret-circle-double-right.icon-left:before, i.ph-caret-circle-double-right.icon-dna:before, i.ph-caret-circle-double-right.icon-delete:before, i.ph-caret-circle-double-right.icon-edit:before, i.ph-caret-circle-double-right.icon-info:before {
  content: "\e11a";
}

.ph.ph-caret-circle-double-up:before, i.ph-caret-circle-double-up.icon-dead:before, i.ph-caret-circle-double-up.icon-alive:before, i.ph-caret-circle-double-up.icon-warn:before, i.ph-caret-circle-double-up.icon-sign-out:before, i.ph-caret-circle-double-up.icon-substratum:before, i.ph-caret-circle-double-up.icon-environment:before, i.ph-caret-circle-double-up.icon-user:before, i.ph-caret-circle-double-up.icon-person:before, i.ph-caret-circle-double-up.icon-terrain:before, i.ph-caret-circle-double-up.icon-taxon:before, i.ph-caret-circle-double-up.icon-unknown:before, i.ph-caret-circle-double-up.icon-nothing:before, i.ph-caret-circle-double-up.icon-sampling:before, i.ph-caret-circle-double-up.icon-isolation:before, i.ph-caret-circle-double-up.icon-strain:before, i.ph-caret-circle-double-up.icon-gps:before, i.ph-caret-circle-double-up.icon-location:before, i.ph-caret-circle-double-up.icon-add:before, i.ph-caret-circle-double-up.icon-left:before, i.ph-caret-circle-double-up.icon-dna:before, i.ph-caret-circle-double-up.icon-delete:before, i.ph-caret-circle-double-up.icon-edit:before, i.ph-caret-circle-double-up.icon-info:before {
  content: "\e11c";
}

.ph.ph-caret-circle-down:before, i.ph-caret-circle-down.icon-dead:before, i.ph-caret-circle-down.icon-alive:before, i.ph-caret-circle-down.icon-warn:before, i.ph-caret-circle-down.icon-sign-out:before, i.ph-caret-circle-down.icon-substratum:before, i.ph-caret-circle-down.icon-environment:before, i.ph-caret-circle-down.icon-user:before, i.ph-caret-circle-down.icon-person:before, i.ph-caret-circle-down.icon-terrain:before, i.ph-caret-circle-down.icon-taxon:before, i.ph-caret-circle-down.icon-unknown:before, i.ph-caret-circle-down.icon-nothing:before, i.ph-caret-circle-down.icon-sampling:before, i.ph-caret-circle-down.icon-isolation:before, i.ph-caret-circle-down.icon-strain:before, i.ph-caret-circle-down.icon-gps:before, i.ph-caret-circle-down.icon-location:before, i.ph-caret-circle-down.icon-add:before, i.ph-caret-circle-down.icon-left:before, i.ph-caret-circle-down.icon-dna:before, i.ph-caret-circle-down.icon-delete:before, i.ph-caret-circle-down.icon-edit:before, i.ph-caret-circle-down.icon-info:before {
  content: "\e11e";
}

.ph.ph-caret-circle-left:before, i.ph-caret-circle-left.icon-dead:before, i.ph-caret-circle-left.icon-alive:before, i.ph-caret-circle-left.icon-warn:before, i.ph-caret-circle-left.icon-sign-out:before, i.ph-caret-circle-left.icon-substratum:before, i.ph-caret-circle-left.icon-environment:before, i.ph-caret-circle-left.icon-user:before, i.ph-caret-circle-left.icon-person:before, i.ph-caret-circle-left.icon-terrain:before, i.ph-caret-circle-left.icon-taxon:before, i.ph-caret-circle-left.icon-unknown:before, i.ph-caret-circle-left.icon-nothing:before, i.ph-caret-circle-left.icon-sampling:before, i.ph-caret-circle-left.icon-isolation:before, i.ph-caret-circle-left.icon-strain:before, i.ph-caret-circle-left.icon-gps:before, i.ph-caret-circle-left.icon-location:before, i.ph-caret-circle-left.icon-add:before, i.ph-caret-circle-left.icon-left:before, i.ph-caret-circle-left.icon-dna:before, i.ph-caret-circle-left.icon-delete:before, i.ph-caret-circle-left.icon-edit:before, i.ph-caret-circle-left.icon-info:before {
  content: "\e120";
}

.ph.ph-caret-circle-right:before, i.ph-caret-circle-right.icon-dead:before, i.ph-caret-circle-right.icon-alive:before, i.ph-caret-circle-right.icon-warn:before, i.ph-caret-circle-right.icon-sign-out:before, i.ph-caret-circle-right.icon-substratum:before, i.ph-caret-circle-right.icon-environment:before, i.ph-caret-circle-right.icon-user:before, i.ph-caret-circle-right.icon-person:before, i.ph-caret-circle-right.icon-terrain:before, i.ph-caret-circle-right.icon-taxon:before, i.ph-caret-circle-right.icon-unknown:before, i.ph-caret-circle-right.icon-nothing:before, i.ph-caret-circle-right.icon-sampling:before, i.ph-caret-circle-right.icon-isolation:before, i.ph-caret-circle-right.icon-strain:before, i.ph-caret-circle-right.icon-gps:before, i.ph-caret-circle-right.icon-location:before, i.ph-caret-circle-right.icon-add:before, i.ph-caret-circle-right.icon-left:before, i.ph-caret-circle-right.icon-dna:before, i.ph-caret-circle-right.icon-delete:before, i.ph-caret-circle-right.icon-edit:before, i.ph-caret-circle-right.icon-info:before {
  content: "\e122";
}

.ph.ph-caret-circle-up:before, i.ph-caret-circle-up.icon-dead:before, i.ph-caret-circle-up.icon-alive:before, i.ph-caret-circle-up.icon-warn:before, i.ph-caret-circle-up.icon-sign-out:before, i.ph-caret-circle-up.icon-substratum:before, i.ph-caret-circle-up.icon-environment:before, i.ph-caret-circle-up.icon-user:before, i.ph-caret-circle-up.icon-person:before, i.ph-caret-circle-up.icon-terrain:before, i.ph-caret-circle-up.icon-taxon:before, i.ph-caret-circle-up.icon-unknown:before, i.ph-caret-circle-up.icon-nothing:before, i.ph-caret-circle-up.icon-sampling:before, i.ph-caret-circle-up.icon-isolation:before, i.ph-caret-circle-up.icon-strain:before, i.ph-caret-circle-up.icon-gps:before, i.ph-caret-circle-up.icon-location:before, i.ph-caret-circle-up.icon-add:before, i.ph-caret-circle-up.icon-left:before, i.ph-caret-circle-up.icon-dna:before, i.ph-caret-circle-up.icon-delete:before, i.ph-caret-circle-up.icon-edit:before, i.ph-caret-circle-up.icon-info:before {
  content: "\e124";
}

.ph.ph-caret-circle-up-down:before, i.ph-caret-circle-up-down.icon-dead:before, i.ph-caret-circle-up-down.icon-alive:before, i.ph-caret-circle-up-down.icon-warn:before, i.ph-caret-circle-up-down.icon-sign-out:before, i.ph-caret-circle-up-down.icon-substratum:before, i.ph-caret-circle-up-down.icon-environment:before, i.ph-caret-circle-up-down.icon-user:before, i.ph-caret-circle-up-down.icon-person:before, i.ph-caret-circle-up-down.icon-terrain:before, i.ph-caret-circle-up-down.icon-taxon:before, i.ph-caret-circle-up-down.icon-unknown:before, i.ph-caret-circle-up-down.icon-nothing:before, i.ph-caret-circle-up-down.icon-sampling:before, i.ph-caret-circle-up-down.icon-isolation:before, i.ph-caret-circle-up-down.icon-strain:before, i.ph-caret-circle-up-down.icon-gps:before, i.ph-caret-circle-up-down.icon-location:before, i.ph-caret-circle-up-down.icon-add:before, i.ph-caret-circle-up-down.icon-left:before, i.ph-caret-circle-up-down.icon-dna:before, i.ph-caret-circle-up-down.icon-delete:before, i.ph-caret-circle-up-down.icon-edit:before, i.ph-caret-circle-up-down.icon-info:before {
  content: "\e13e";
}

.ph.ph-caret-double-down:before, i.ph-caret-double-down.icon-dead:before, i.ph-caret-double-down.icon-alive:before, i.ph-caret-double-down.icon-warn:before, i.ph-caret-double-down.icon-sign-out:before, i.ph-caret-double-down.icon-substratum:before, i.ph-caret-double-down.icon-environment:before, i.ph-caret-double-down.icon-user:before, i.ph-caret-double-down.icon-person:before, i.ph-caret-double-down.icon-terrain:before, i.ph-caret-double-down.icon-taxon:before, i.ph-caret-double-down.icon-unknown:before, i.ph-caret-double-down.icon-nothing:before, i.ph-caret-double-down.icon-sampling:before, i.ph-caret-double-down.icon-isolation:before, i.ph-caret-double-down.icon-strain:before, i.ph-caret-double-down.icon-gps:before, i.ph-caret-double-down.icon-location:before, i.ph-caret-double-down.icon-add:before, i.ph-caret-double-down.icon-left:before, i.ph-caret-double-down.icon-dna:before, i.ph-caret-double-down.icon-delete:before, i.ph-caret-double-down.icon-edit:before, i.ph-caret-double-down.icon-info:before {
  content: "\e126";
}

.ph.ph-caret-double-left:before, i.ph-caret-double-left.icon-dead:before, i.ph-caret-double-left.icon-alive:before, i.ph-caret-double-left.icon-warn:before, i.ph-caret-double-left.icon-sign-out:before, i.ph-caret-double-left.icon-substratum:before, i.ph-caret-double-left.icon-environment:before, i.ph-caret-double-left.icon-user:before, i.ph-caret-double-left.icon-person:before, i.ph-caret-double-left.icon-terrain:before, i.ph-caret-double-left.icon-taxon:before, i.ph-caret-double-left.icon-unknown:before, i.ph-caret-double-left.icon-nothing:before, i.ph-caret-double-left.icon-sampling:before, i.ph-caret-double-left.icon-isolation:before, i.ph-caret-double-left.icon-strain:before, i.ph-caret-double-left.icon-gps:before, i.ph-caret-double-left.icon-location:before, i.ph-caret-double-left.icon-add:before, i.ph-caret-double-left.icon-left:before, i.ph-caret-double-left.icon-dna:before, i.ph-caret-double-left.icon-delete:before, i.ph-caret-double-left.icon-edit:before, i.ph-caret-double-left.icon-info:before {
  content: "\e128";
}

.ph.ph-caret-double-right:before, i.ph-caret-double-right.icon-dead:before, i.ph-caret-double-right.icon-alive:before, i.ph-caret-double-right.icon-warn:before, i.ph-caret-double-right.icon-sign-out:before, i.ph-caret-double-right.icon-substratum:before, i.ph-caret-double-right.icon-environment:before, i.ph-caret-double-right.icon-user:before, i.ph-caret-double-right.icon-person:before, i.ph-caret-double-right.icon-terrain:before, i.ph-caret-double-right.icon-taxon:before, i.ph-caret-double-right.icon-unknown:before, i.ph-caret-double-right.icon-nothing:before, i.ph-caret-double-right.icon-sampling:before, i.ph-caret-double-right.icon-isolation:before, i.ph-caret-double-right.icon-strain:before, i.ph-caret-double-right.icon-gps:before, i.ph-caret-double-right.icon-location:before, i.ph-caret-double-right.icon-add:before, i.ph-caret-double-right.icon-left:before, i.ph-caret-double-right.icon-dna:before, i.ph-caret-double-right.icon-delete:before, i.ph-caret-double-right.icon-edit:before, i.ph-caret-double-right.icon-info:before {
  content: "\e12a";
}

.ph.ph-caret-double-up:before, i.ph-caret-double-up.icon-dead:before, i.ph-caret-double-up.icon-alive:before, i.ph-caret-double-up.icon-warn:before, i.ph-caret-double-up.icon-sign-out:before, i.ph-caret-double-up.icon-substratum:before, i.ph-caret-double-up.icon-environment:before, i.ph-caret-double-up.icon-user:before, i.ph-caret-double-up.icon-person:before, i.ph-caret-double-up.icon-terrain:before, i.ph-caret-double-up.icon-taxon:before, i.ph-caret-double-up.icon-unknown:before, i.ph-caret-double-up.icon-nothing:before, i.ph-caret-double-up.icon-sampling:before, i.ph-caret-double-up.icon-isolation:before, i.ph-caret-double-up.icon-strain:before, i.ph-caret-double-up.icon-gps:before, i.ph-caret-double-up.icon-location:before, i.ph-caret-double-up.icon-add:before, i.ph-caret-double-up.icon-left:before, i.ph-caret-double-up.icon-dna:before, i.ph-caret-double-up.icon-delete:before, i.ph-caret-double-up.icon-edit:before, i.ph-caret-double-up.icon-info:before {
  content: "\e12c";
}

.ph.ph-caret-down:before, i.ph-caret-down.icon-dead:before, i.ph-caret-down.icon-alive:before, i.ph-caret-down.icon-warn:before, i.ph-caret-down.icon-sign-out:before, i.ph-caret-down.icon-substratum:before, i.ph-caret-down.icon-environment:before, i.ph-caret-down.icon-user:before, i.ph-caret-down.icon-person:before, i.ph-caret-down.icon-terrain:before, i.ph-caret-down.icon-taxon:before, i.ph-caret-down.icon-unknown:before, i.ph-caret-down.icon-nothing:before, i.ph-caret-down.icon-sampling:before, i.ph-caret-down.icon-isolation:before, i.ph-caret-down.icon-strain:before, i.ph-caret-down.icon-gps:before, i.ph-caret-down.icon-location:before, i.ph-caret-down.icon-add:before, i.ph-caret-down.icon-left:before, i.ph-caret-down.icon-dna:before, i.ph-caret-down.icon-delete:before, i.ph-caret-down.icon-edit:before, i.ph-caret-down.icon-info:before {
  content: "\e136";
}

.ph.ph-caret-left:before, i.ph-caret-left.icon-dead:before, i.ph-caret-left.icon-alive:before, i.ph-caret-left.icon-warn:before, i.ph-caret-left.icon-sign-out:before, i.ph-caret-left.icon-substratum:before, i.ph-caret-left.icon-environment:before, i.ph-caret-left.icon-user:before, i.ph-caret-left.icon-person:before, i.ph-caret-left.icon-terrain:before, i.ph-caret-left.icon-taxon:before, i.ph-caret-left.icon-unknown:before, i.ph-caret-left.icon-nothing:before, i.ph-caret-left.icon-sampling:before, i.ph-caret-left.icon-isolation:before, i.ph-caret-left.icon-strain:before, i.ph-caret-left.icon-gps:before, i.ph-caret-left.icon-location:before, i.ph-caret-left.icon-add:before, i.icon-left:before, i.ph-caret-left.icon-dna:before, i.ph-caret-left.icon-delete:before, i.ph-caret-left.icon-edit:before, i.ph-caret-left.icon-info:before {
  content: "\e138";
}

.ph.ph-caret-line-down:before, i.ph-caret-line-down.icon-dead:before, i.ph-caret-line-down.icon-alive:before, i.ph-caret-line-down.icon-warn:before, i.ph-caret-line-down.icon-sign-out:before, i.ph-caret-line-down.icon-substratum:before, i.ph-caret-line-down.icon-environment:before, i.ph-caret-line-down.icon-user:before, i.ph-caret-line-down.icon-person:before, i.ph-caret-line-down.icon-terrain:before, i.ph-caret-line-down.icon-taxon:before, i.ph-caret-line-down.icon-unknown:before, i.ph-caret-line-down.icon-nothing:before, i.ph-caret-line-down.icon-sampling:before, i.ph-caret-line-down.icon-isolation:before, i.ph-caret-line-down.icon-strain:before, i.ph-caret-line-down.icon-gps:before, i.ph-caret-line-down.icon-location:before, i.ph-caret-line-down.icon-add:before, i.ph-caret-line-down.icon-left:before, i.ph-caret-line-down.icon-dna:before, i.ph-caret-line-down.icon-delete:before, i.ph-caret-line-down.icon-edit:before, i.ph-caret-line-down.icon-info:before {
  content: "\e134";
}

.ph.ph-caret-line-left:before, i.ph-caret-line-left.icon-dead:before, i.ph-caret-line-left.icon-alive:before, i.ph-caret-line-left.icon-warn:before, i.ph-caret-line-left.icon-sign-out:before, i.ph-caret-line-left.icon-substratum:before, i.ph-caret-line-left.icon-environment:before, i.ph-caret-line-left.icon-user:before, i.ph-caret-line-left.icon-person:before, i.ph-caret-line-left.icon-terrain:before, i.ph-caret-line-left.icon-taxon:before, i.ph-caret-line-left.icon-unknown:before, i.ph-caret-line-left.icon-nothing:before, i.ph-caret-line-left.icon-sampling:before, i.ph-caret-line-left.icon-isolation:before, i.ph-caret-line-left.icon-strain:before, i.ph-caret-line-left.icon-gps:before, i.ph-caret-line-left.icon-location:before, i.ph-caret-line-left.icon-add:before, i.ph-caret-line-left.icon-left:before, i.ph-caret-line-left.icon-dna:before, i.ph-caret-line-left.icon-delete:before, i.ph-caret-line-left.icon-edit:before, i.ph-caret-line-left.icon-info:before {
  content: "\e132";
}

.ph.ph-caret-line-right:before, i.ph-caret-line-right.icon-dead:before, i.ph-caret-line-right.icon-alive:before, i.ph-caret-line-right.icon-warn:before, i.ph-caret-line-right.icon-sign-out:before, i.ph-caret-line-right.icon-substratum:before, i.ph-caret-line-right.icon-environment:before, i.ph-caret-line-right.icon-user:before, i.ph-caret-line-right.icon-person:before, i.ph-caret-line-right.icon-terrain:before, i.ph-caret-line-right.icon-taxon:before, i.ph-caret-line-right.icon-unknown:before, i.ph-caret-line-right.icon-nothing:before, i.ph-caret-line-right.icon-sampling:before, i.ph-caret-line-right.icon-isolation:before, i.ph-caret-line-right.icon-strain:before, i.ph-caret-line-right.icon-gps:before, i.ph-caret-line-right.icon-location:before, i.ph-caret-line-right.icon-add:before, i.ph-caret-line-right.icon-left:before, i.ph-caret-line-right.icon-dna:before, i.ph-caret-line-right.icon-delete:before, i.ph-caret-line-right.icon-edit:before, i.ph-caret-line-right.icon-info:before {
  content: "\e130";
}

.ph.ph-caret-line-up:before, i.ph-caret-line-up.icon-dead:before, i.ph-caret-line-up.icon-alive:before, i.ph-caret-line-up.icon-warn:before, i.ph-caret-line-up.icon-sign-out:before, i.ph-caret-line-up.icon-substratum:before, i.ph-caret-line-up.icon-environment:before, i.ph-caret-line-up.icon-user:before, i.ph-caret-line-up.icon-person:before, i.ph-caret-line-up.icon-terrain:before, i.ph-caret-line-up.icon-taxon:before, i.ph-caret-line-up.icon-unknown:before, i.ph-caret-line-up.icon-nothing:before, i.ph-caret-line-up.icon-sampling:before, i.ph-caret-line-up.icon-isolation:before, i.ph-caret-line-up.icon-strain:before, i.ph-caret-line-up.icon-gps:before, i.ph-caret-line-up.icon-location:before, i.ph-caret-line-up.icon-add:before, i.ph-caret-line-up.icon-left:before, i.ph-caret-line-up.icon-dna:before, i.ph-caret-line-up.icon-delete:before, i.ph-caret-line-up.icon-edit:before, i.ph-caret-line-up.icon-info:before {
  content: "\e12e";
}

.ph.ph-caret-right:before, i.ph-caret-right.icon-dead:before, i.ph-caret-right.icon-alive:before, i.ph-caret-right.icon-warn:before, i.ph-caret-right.icon-sign-out:before, i.ph-caret-right.icon-substratum:before, i.ph-caret-right.icon-environment:before, i.ph-caret-right.icon-user:before, i.ph-caret-right.icon-person:before, i.ph-caret-right.icon-terrain:before, i.ph-caret-right.icon-taxon:before, i.ph-caret-right.icon-unknown:before, i.ph-caret-right.icon-nothing:before, i.ph-caret-right.icon-sampling:before, i.ph-caret-right.icon-isolation:before, i.ph-caret-right.icon-strain:before, i.ph-caret-right.icon-gps:before, i.ph-caret-right.icon-location:before, i.ph-caret-right.icon-add:before, i.ph-caret-right.icon-left:before, i.ph-caret-right.icon-dna:before, i.ph-caret-right.icon-delete:before, i.ph-caret-right.icon-edit:before, i.ph-caret-right.icon-info:before {
  content: "\e13a";
}

.ph.ph-caret-up:before, i.ph-caret-up.icon-dead:before, i.ph-caret-up.icon-alive:before, i.ph-caret-up.icon-warn:before, i.ph-caret-up.icon-sign-out:before, i.ph-caret-up.icon-substratum:before, i.ph-caret-up.icon-environment:before, i.ph-caret-up.icon-user:before, i.ph-caret-up.icon-person:before, i.ph-caret-up.icon-terrain:before, i.ph-caret-up.icon-taxon:before, i.ph-caret-up.icon-unknown:before, i.ph-caret-up.icon-nothing:before, i.ph-caret-up.icon-sampling:before, i.ph-caret-up.icon-isolation:before, i.ph-caret-up.icon-strain:before, i.ph-caret-up.icon-gps:before, i.ph-caret-up.icon-location:before, i.ph-caret-up.icon-add:before, i.ph-caret-up.icon-left:before, i.ph-caret-up.icon-dna:before, i.ph-caret-up.icon-delete:before, i.ph-caret-up.icon-edit:before, i.ph-caret-up.icon-info:before {
  content: "\e13c";
}

.ph.ph-caret-up-down:before, i.ph-caret-up-down.icon-dead:before, i.ph-caret-up-down.icon-alive:before, i.ph-caret-up-down.icon-warn:before, i.ph-caret-up-down.icon-sign-out:before, i.ph-caret-up-down.icon-substratum:before, i.ph-caret-up-down.icon-environment:before, i.ph-caret-up-down.icon-user:before, i.ph-caret-up-down.icon-person:before, i.ph-caret-up-down.icon-terrain:before, i.ph-caret-up-down.icon-taxon:before, i.ph-caret-up-down.icon-unknown:before, i.ph-caret-up-down.icon-nothing:before, i.ph-caret-up-down.icon-sampling:before, i.ph-caret-up-down.icon-isolation:before, i.ph-caret-up-down.icon-strain:before, i.ph-caret-up-down.icon-gps:before, i.ph-caret-up-down.icon-location:before, i.ph-caret-up-down.icon-add:before, i.ph-caret-up-down.icon-left:before, i.ph-caret-up-down.icon-dna:before, i.ph-caret-up-down.icon-delete:before, i.ph-caret-up-down.icon-edit:before, i.ph-caret-up-down.icon-info:before {
  content: "\e140";
}

.ph.ph-carrot:before, i.ph-carrot.icon-dead:before, i.ph-carrot.icon-alive:before, i.ph-carrot.icon-warn:before, i.ph-carrot.icon-sign-out:before, i.ph-carrot.icon-substratum:before, i.ph-carrot.icon-environment:before, i.ph-carrot.icon-user:before, i.ph-carrot.icon-person:before, i.ph-carrot.icon-terrain:before, i.ph-carrot.icon-taxon:before, i.ph-carrot.icon-unknown:before, i.ph-carrot.icon-nothing:before, i.ph-carrot.icon-sampling:before, i.ph-carrot.icon-isolation:before, i.ph-carrot.icon-strain:before, i.ph-carrot.icon-gps:before, i.ph-carrot.icon-location:before, i.ph-carrot.icon-add:before, i.ph-carrot.icon-left:before, i.ph-carrot.icon-dna:before, i.ph-carrot.icon-delete:before, i.ph-carrot.icon-edit:before, i.ph-carrot.icon-info:before {
  content: "\ed38";
}

.ph.ph-cash-register:before, i.ph-cash-register.icon-dead:before, i.ph-cash-register.icon-alive:before, i.ph-cash-register.icon-warn:before, i.ph-cash-register.icon-sign-out:before, i.ph-cash-register.icon-substratum:before, i.ph-cash-register.icon-environment:before, i.ph-cash-register.icon-user:before, i.ph-cash-register.icon-person:before, i.ph-cash-register.icon-terrain:before, i.ph-cash-register.icon-taxon:before, i.ph-cash-register.icon-unknown:before, i.ph-cash-register.icon-nothing:before, i.ph-cash-register.icon-sampling:before, i.ph-cash-register.icon-isolation:before, i.ph-cash-register.icon-strain:before, i.ph-cash-register.icon-gps:before, i.ph-cash-register.icon-location:before, i.ph-cash-register.icon-add:before, i.ph-cash-register.icon-left:before, i.ph-cash-register.icon-dna:before, i.ph-cash-register.icon-delete:before, i.ph-cash-register.icon-edit:before, i.ph-cash-register.icon-info:before {
  content: "\ed80";
}

.ph.ph-cassette-tape:before, i.ph-cassette-tape.icon-dead:before, i.ph-cassette-tape.icon-alive:before, i.ph-cassette-tape.icon-warn:before, i.ph-cassette-tape.icon-sign-out:before, i.ph-cassette-tape.icon-substratum:before, i.ph-cassette-tape.icon-environment:before, i.ph-cassette-tape.icon-user:before, i.ph-cassette-tape.icon-person:before, i.ph-cassette-tape.icon-terrain:before, i.ph-cassette-tape.icon-taxon:before, i.ph-cassette-tape.icon-unknown:before, i.ph-cassette-tape.icon-nothing:before, i.ph-cassette-tape.icon-sampling:before, i.ph-cassette-tape.icon-isolation:before, i.ph-cassette-tape.icon-strain:before, i.ph-cassette-tape.icon-gps:before, i.ph-cassette-tape.icon-location:before, i.ph-cassette-tape.icon-add:before, i.ph-cassette-tape.icon-left:before, i.ph-cassette-tape.icon-dna:before, i.ph-cassette-tape.icon-delete:before, i.ph-cassette-tape.icon-edit:before, i.ph-cassette-tape.icon-info:before {
  content: "\ed2e";
}

.ph.ph-castle-turret:before, i.ph-castle-turret.icon-dead:before, i.ph-castle-turret.icon-alive:before, i.ph-castle-turret.icon-warn:before, i.ph-castle-turret.icon-sign-out:before, i.ph-castle-turret.icon-substratum:before, i.ph-castle-turret.icon-environment:before, i.ph-castle-turret.icon-user:before, i.ph-castle-turret.icon-person:before, i.ph-castle-turret.icon-terrain:before, i.ph-castle-turret.icon-taxon:before, i.ph-castle-turret.icon-unknown:before, i.ph-castle-turret.icon-nothing:before, i.ph-castle-turret.icon-sampling:before, i.ph-castle-turret.icon-isolation:before, i.ph-castle-turret.icon-strain:before, i.ph-castle-turret.icon-gps:before, i.ph-castle-turret.icon-location:before, i.ph-castle-turret.icon-add:before, i.ph-castle-turret.icon-left:before, i.ph-castle-turret.icon-dna:before, i.ph-castle-turret.icon-delete:before, i.ph-castle-turret.icon-edit:before, i.ph-castle-turret.icon-info:before {
  content: "\e9d0";
}

.ph.ph-cat:before, i.ph-cat.icon-dead:before, i.ph-cat.icon-alive:before, i.ph-cat.icon-warn:before, i.ph-cat.icon-sign-out:before, i.ph-cat.icon-substratum:before, i.ph-cat.icon-environment:before, i.ph-cat.icon-user:before, i.ph-cat.icon-person:before, i.ph-cat.icon-terrain:before, i.ph-cat.icon-taxon:before, i.ph-cat.icon-unknown:before, i.ph-cat.icon-nothing:before, i.ph-cat.icon-sampling:before, i.ph-cat.icon-isolation:before, i.ph-cat.icon-strain:before, i.ph-cat.icon-gps:before, i.ph-cat.icon-location:before, i.ph-cat.icon-add:before, i.ph-cat.icon-left:before, i.ph-cat.icon-dna:before, i.ph-cat.icon-delete:before, i.ph-cat.icon-edit:before, i.ph-cat.icon-info:before {
  content: "\e748";
}

.ph.ph-cell-signal-full:before, i.ph-cell-signal-full.icon-dead:before, i.ph-cell-signal-full.icon-alive:before, i.ph-cell-signal-full.icon-warn:before, i.ph-cell-signal-full.icon-sign-out:before, i.ph-cell-signal-full.icon-substratum:before, i.ph-cell-signal-full.icon-environment:before, i.ph-cell-signal-full.icon-user:before, i.ph-cell-signal-full.icon-person:before, i.ph-cell-signal-full.icon-terrain:before, i.ph-cell-signal-full.icon-taxon:before, i.ph-cell-signal-full.icon-unknown:before, i.ph-cell-signal-full.icon-nothing:before, i.ph-cell-signal-full.icon-sampling:before, i.ph-cell-signal-full.icon-isolation:before, i.ph-cell-signal-full.icon-strain:before, i.ph-cell-signal-full.icon-gps:before, i.ph-cell-signal-full.icon-location:before, i.ph-cell-signal-full.icon-add:before, i.ph-cell-signal-full.icon-left:before, i.ph-cell-signal-full.icon-dna:before, i.ph-cell-signal-full.icon-delete:before, i.ph-cell-signal-full.icon-edit:before, i.ph-cell-signal-full.icon-info:before {
  content: "\e142";
}

.ph.ph-cell-signal-high:before, i.ph-cell-signal-high.icon-dead:before, i.ph-cell-signal-high.icon-alive:before, i.ph-cell-signal-high.icon-warn:before, i.ph-cell-signal-high.icon-sign-out:before, i.ph-cell-signal-high.icon-substratum:before, i.ph-cell-signal-high.icon-environment:before, i.ph-cell-signal-high.icon-user:before, i.ph-cell-signal-high.icon-person:before, i.ph-cell-signal-high.icon-terrain:before, i.ph-cell-signal-high.icon-taxon:before, i.ph-cell-signal-high.icon-unknown:before, i.ph-cell-signal-high.icon-nothing:before, i.ph-cell-signal-high.icon-sampling:before, i.ph-cell-signal-high.icon-isolation:before, i.ph-cell-signal-high.icon-strain:before, i.ph-cell-signal-high.icon-gps:before, i.ph-cell-signal-high.icon-location:before, i.ph-cell-signal-high.icon-add:before, i.ph-cell-signal-high.icon-left:before, i.ph-cell-signal-high.icon-dna:before, i.ph-cell-signal-high.icon-delete:before, i.ph-cell-signal-high.icon-edit:before, i.ph-cell-signal-high.icon-info:before {
  content: "\e144";
}

.ph.ph-cell-signal-low:before, i.ph-cell-signal-low.icon-dead:before, i.ph-cell-signal-low.icon-alive:before, i.ph-cell-signal-low.icon-warn:before, i.ph-cell-signal-low.icon-sign-out:before, i.ph-cell-signal-low.icon-substratum:before, i.ph-cell-signal-low.icon-environment:before, i.ph-cell-signal-low.icon-user:before, i.ph-cell-signal-low.icon-person:before, i.ph-cell-signal-low.icon-terrain:before, i.ph-cell-signal-low.icon-taxon:before, i.ph-cell-signal-low.icon-unknown:before, i.ph-cell-signal-low.icon-nothing:before, i.ph-cell-signal-low.icon-sampling:before, i.ph-cell-signal-low.icon-isolation:before, i.ph-cell-signal-low.icon-strain:before, i.ph-cell-signal-low.icon-gps:before, i.ph-cell-signal-low.icon-location:before, i.ph-cell-signal-low.icon-add:before, i.ph-cell-signal-low.icon-left:before, i.ph-cell-signal-low.icon-dna:before, i.ph-cell-signal-low.icon-delete:before, i.ph-cell-signal-low.icon-edit:before, i.ph-cell-signal-low.icon-info:before {
  content: "\e146";
}

.ph.ph-cell-signal-medium:before, i.ph-cell-signal-medium.icon-dead:before, i.ph-cell-signal-medium.icon-alive:before, i.ph-cell-signal-medium.icon-warn:before, i.ph-cell-signal-medium.icon-sign-out:before, i.ph-cell-signal-medium.icon-substratum:before, i.ph-cell-signal-medium.icon-environment:before, i.ph-cell-signal-medium.icon-user:before, i.ph-cell-signal-medium.icon-person:before, i.ph-cell-signal-medium.icon-terrain:before, i.ph-cell-signal-medium.icon-taxon:before, i.ph-cell-signal-medium.icon-unknown:before, i.ph-cell-signal-medium.icon-nothing:before, i.ph-cell-signal-medium.icon-sampling:before, i.ph-cell-signal-medium.icon-isolation:before, i.ph-cell-signal-medium.icon-strain:before, i.ph-cell-signal-medium.icon-gps:before, i.ph-cell-signal-medium.icon-location:before, i.ph-cell-signal-medium.icon-add:before, i.ph-cell-signal-medium.icon-left:before, i.ph-cell-signal-medium.icon-dna:before, i.ph-cell-signal-medium.icon-delete:before, i.ph-cell-signal-medium.icon-edit:before, i.ph-cell-signal-medium.icon-info:before {
  content: "\e148";
}

.ph.ph-cell-signal-none:before, i.ph-cell-signal-none.icon-dead:before, i.ph-cell-signal-none.icon-alive:before, i.ph-cell-signal-none.icon-warn:before, i.ph-cell-signal-none.icon-sign-out:before, i.ph-cell-signal-none.icon-substratum:before, i.ph-cell-signal-none.icon-environment:before, i.ph-cell-signal-none.icon-user:before, i.ph-cell-signal-none.icon-person:before, i.ph-cell-signal-none.icon-terrain:before, i.ph-cell-signal-none.icon-taxon:before, i.ph-cell-signal-none.icon-unknown:before, i.ph-cell-signal-none.icon-nothing:before, i.ph-cell-signal-none.icon-sampling:before, i.ph-cell-signal-none.icon-isolation:before, i.ph-cell-signal-none.icon-strain:before, i.ph-cell-signal-none.icon-gps:before, i.ph-cell-signal-none.icon-location:before, i.ph-cell-signal-none.icon-add:before, i.ph-cell-signal-none.icon-left:before, i.ph-cell-signal-none.icon-dna:before, i.ph-cell-signal-none.icon-delete:before, i.ph-cell-signal-none.icon-edit:before, i.ph-cell-signal-none.icon-info:before {
  content: "\e14a";
}

.ph.ph-cell-signal-slash:before, i.ph-cell-signal-slash.icon-dead:before, i.ph-cell-signal-slash.icon-alive:before, i.ph-cell-signal-slash.icon-warn:before, i.ph-cell-signal-slash.icon-sign-out:before, i.ph-cell-signal-slash.icon-substratum:before, i.ph-cell-signal-slash.icon-environment:before, i.ph-cell-signal-slash.icon-user:before, i.ph-cell-signal-slash.icon-person:before, i.ph-cell-signal-slash.icon-terrain:before, i.ph-cell-signal-slash.icon-taxon:before, i.ph-cell-signal-slash.icon-unknown:before, i.ph-cell-signal-slash.icon-nothing:before, i.ph-cell-signal-slash.icon-sampling:before, i.ph-cell-signal-slash.icon-isolation:before, i.ph-cell-signal-slash.icon-strain:before, i.ph-cell-signal-slash.icon-gps:before, i.ph-cell-signal-slash.icon-location:before, i.ph-cell-signal-slash.icon-add:before, i.ph-cell-signal-slash.icon-left:before, i.ph-cell-signal-slash.icon-dna:before, i.ph-cell-signal-slash.icon-delete:before, i.ph-cell-signal-slash.icon-edit:before, i.ph-cell-signal-slash.icon-info:before {
  content: "\e14c";
}

.ph.ph-cell-signal-x:before, i.ph-cell-signal-x.icon-dead:before, i.ph-cell-signal-x.icon-alive:before, i.ph-cell-signal-x.icon-warn:before, i.ph-cell-signal-x.icon-sign-out:before, i.ph-cell-signal-x.icon-substratum:before, i.ph-cell-signal-x.icon-environment:before, i.ph-cell-signal-x.icon-user:before, i.ph-cell-signal-x.icon-person:before, i.ph-cell-signal-x.icon-terrain:before, i.ph-cell-signal-x.icon-taxon:before, i.ph-cell-signal-x.icon-unknown:before, i.ph-cell-signal-x.icon-nothing:before, i.ph-cell-signal-x.icon-sampling:before, i.ph-cell-signal-x.icon-isolation:before, i.ph-cell-signal-x.icon-strain:before, i.ph-cell-signal-x.icon-gps:before, i.ph-cell-signal-x.icon-location:before, i.ph-cell-signal-x.icon-add:before, i.ph-cell-signal-x.icon-left:before, i.ph-cell-signal-x.icon-dna:before, i.ph-cell-signal-x.icon-delete:before, i.ph-cell-signal-x.icon-edit:before, i.ph-cell-signal-x.icon-info:before {
  content: "\e14e";
}

.ph.ph-cell-tower:before, i.ph-cell-tower.icon-dead:before, i.ph-cell-tower.icon-alive:before, i.ph-cell-tower.icon-warn:before, i.ph-cell-tower.icon-sign-out:before, i.ph-cell-tower.icon-substratum:before, i.ph-cell-tower.icon-environment:before, i.ph-cell-tower.icon-user:before, i.ph-cell-tower.icon-person:before, i.ph-cell-tower.icon-terrain:before, i.ph-cell-tower.icon-taxon:before, i.ph-cell-tower.icon-unknown:before, i.ph-cell-tower.icon-nothing:before, i.ph-cell-tower.icon-sampling:before, i.ph-cell-tower.icon-isolation:before, i.ph-cell-tower.icon-strain:before, i.ph-cell-tower.icon-gps:before, i.ph-cell-tower.icon-location:before, i.ph-cell-tower.icon-add:before, i.ph-cell-tower.icon-left:before, i.ph-cell-tower.icon-dna:before, i.ph-cell-tower.icon-delete:before, i.ph-cell-tower.icon-edit:before, i.ph-cell-tower.icon-info:before {
  content: "\ebaa";
}

.ph.ph-certificate:before, i.ph-certificate.icon-dead:before, i.ph-certificate.icon-alive:before, i.ph-certificate.icon-warn:before, i.ph-certificate.icon-sign-out:before, i.ph-certificate.icon-substratum:before, i.ph-certificate.icon-environment:before, i.ph-certificate.icon-user:before, i.ph-certificate.icon-person:before, i.ph-certificate.icon-terrain:before, i.ph-certificate.icon-taxon:before, i.ph-certificate.icon-unknown:before, i.ph-certificate.icon-nothing:before, i.ph-certificate.icon-sampling:before, i.ph-certificate.icon-isolation:before, i.ph-certificate.icon-strain:before, i.ph-certificate.icon-gps:before, i.ph-certificate.icon-location:before, i.ph-certificate.icon-add:before, i.ph-certificate.icon-left:before, i.ph-certificate.icon-dna:before, i.ph-certificate.icon-delete:before, i.ph-certificate.icon-edit:before, i.ph-certificate.icon-info:before {
  content: "\e766";
}

.ph.ph-chair:before, i.ph-chair.icon-dead:before, i.ph-chair.icon-alive:before, i.ph-chair.icon-warn:before, i.ph-chair.icon-sign-out:before, i.ph-chair.icon-substratum:before, i.ph-chair.icon-environment:before, i.ph-chair.icon-user:before, i.ph-chair.icon-person:before, i.ph-chair.icon-terrain:before, i.ph-chair.icon-taxon:before, i.ph-chair.icon-unknown:before, i.ph-chair.icon-nothing:before, i.ph-chair.icon-sampling:before, i.ph-chair.icon-isolation:before, i.ph-chair.icon-strain:before, i.ph-chair.icon-gps:before, i.ph-chair.icon-location:before, i.ph-chair.icon-add:before, i.ph-chair.icon-left:before, i.ph-chair.icon-dna:before, i.ph-chair.icon-delete:before, i.ph-chair.icon-edit:before, i.ph-chair.icon-info:before {
  content: "\e950";
}

.ph.ph-chalkboard:before, i.ph-chalkboard.icon-dead:before, i.ph-chalkboard.icon-alive:before, i.ph-chalkboard.icon-warn:before, i.ph-chalkboard.icon-sign-out:before, i.ph-chalkboard.icon-substratum:before, i.ph-chalkboard.icon-environment:before, i.ph-chalkboard.icon-user:before, i.ph-chalkboard.icon-person:before, i.ph-chalkboard.icon-terrain:before, i.ph-chalkboard.icon-taxon:before, i.ph-chalkboard.icon-unknown:before, i.ph-chalkboard.icon-nothing:before, i.ph-chalkboard.icon-sampling:before, i.ph-chalkboard.icon-isolation:before, i.ph-chalkboard.icon-strain:before, i.ph-chalkboard.icon-gps:before, i.ph-chalkboard.icon-location:before, i.ph-chalkboard.icon-add:before, i.ph-chalkboard.icon-left:before, i.ph-chalkboard.icon-dna:before, i.ph-chalkboard.icon-delete:before, i.ph-chalkboard.icon-edit:before, i.ph-chalkboard.icon-info:before {
  content: "\e5fc";
}

.ph.ph-chalkboard-simple:before, i.ph-chalkboard-simple.icon-dead:before, i.ph-chalkboard-simple.icon-alive:before, i.ph-chalkboard-simple.icon-warn:before, i.ph-chalkboard-simple.icon-sign-out:before, i.ph-chalkboard-simple.icon-substratum:before, i.ph-chalkboard-simple.icon-environment:before, i.ph-chalkboard-simple.icon-user:before, i.ph-chalkboard-simple.icon-person:before, i.ph-chalkboard-simple.icon-terrain:before, i.ph-chalkboard-simple.icon-taxon:before, i.ph-chalkboard-simple.icon-unknown:before, i.ph-chalkboard-simple.icon-nothing:before, i.ph-chalkboard-simple.icon-sampling:before, i.ph-chalkboard-simple.icon-isolation:before, i.ph-chalkboard-simple.icon-strain:before, i.ph-chalkboard-simple.icon-gps:before, i.ph-chalkboard-simple.icon-location:before, i.ph-chalkboard-simple.icon-add:before, i.ph-chalkboard-simple.icon-left:before, i.ph-chalkboard-simple.icon-dna:before, i.ph-chalkboard-simple.icon-delete:before, i.ph-chalkboard-simple.icon-edit:before, i.ph-chalkboard-simple.icon-info:before {
  content: "\e5fe";
}

.ph.ph-chalkboard-teacher:before, i.ph-chalkboard-teacher.icon-dead:before, i.ph-chalkboard-teacher.icon-alive:before, i.ph-chalkboard-teacher.icon-warn:before, i.ph-chalkboard-teacher.icon-sign-out:before, i.ph-chalkboard-teacher.icon-substratum:before, i.ph-chalkboard-teacher.icon-environment:before, i.ph-chalkboard-teacher.icon-user:before, i.ph-chalkboard-teacher.icon-person:before, i.ph-chalkboard-teacher.icon-terrain:before, i.ph-chalkboard-teacher.icon-taxon:before, i.ph-chalkboard-teacher.icon-unknown:before, i.ph-chalkboard-teacher.icon-nothing:before, i.ph-chalkboard-teacher.icon-sampling:before, i.ph-chalkboard-teacher.icon-isolation:before, i.ph-chalkboard-teacher.icon-strain:before, i.ph-chalkboard-teacher.icon-gps:before, i.ph-chalkboard-teacher.icon-location:before, i.ph-chalkboard-teacher.icon-add:before, i.ph-chalkboard-teacher.icon-left:before, i.ph-chalkboard-teacher.icon-dna:before, i.ph-chalkboard-teacher.icon-delete:before, i.ph-chalkboard-teacher.icon-edit:before, i.ph-chalkboard-teacher.icon-info:before {
  content: "\e600";
}

.ph.ph-champagne:before, i.ph-champagne.icon-dead:before, i.ph-champagne.icon-alive:before, i.ph-champagne.icon-warn:before, i.ph-champagne.icon-sign-out:before, i.ph-champagne.icon-substratum:before, i.ph-champagne.icon-environment:before, i.ph-champagne.icon-user:before, i.ph-champagne.icon-person:before, i.ph-champagne.icon-terrain:before, i.ph-champagne.icon-taxon:before, i.ph-champagne.icon-unknown:before, i.ph-champagne.icon-nothing:before, i.ph-champagne.icon-sampling:before, i.ph-champagne.icon-isolation:before, i.ph-champagne.icon-strain:before, i.ph-champagne.icon-gps:before, i.ph-champagne.icon-location:before, i.ph-champagne.icon-add:before, i.ph-champagne.icon-left:before, i.ph-champagne.icon-dna:before, i.ph-champagne.icon-delete:before, i.ph-champagne.icon-edit:before, i.ph-champagne.icon-info:before {
  content: "\eaca";
}

.ph.ph-charging-station:before, i.ph-charging-station.icon-dead:before, i.ph-charging-station.icon-alive:before, i.ph-charging-station.icon-warn:before, i.ph-charging-station.icon-sign-out:before, i.ph-charging-station.icon-substratum:before, i.ph-charging-station.icon-environment:before, i.ph-charging-station.icon-user:before, i.ph-charging-station.icon-person:before, i.ph-charging-station.icon-terrain:before, i.ph-charging-station.icon-taxon:before, i.ph-charging-station.icon-unknown:before, i.ph-charging-station.icon-nothing:before, i.ph-charging-station.icon-sampling:before, i.ph-charging-station.icon-isolation:before, i.ph-charging-station.icon-strain:before, i.ph-charging-station.icon-gps:before, i.ph-charging-station.icon-location:before, i.ph-charging-station.icon-add:before, i.ph-charging-station.icon-left:before, i.ph-charging-station.icon-dna:before, i.ph-charging-station.icon-delete:before, i.ph-charging-station.icon-edit:before, i.ph-charging-station.icon-info:before {
  content: "\e8d0";
}

.ph.ph-chart-bar:before, i.ph-chart-bar.icon-dead:before, i.ph-chart-bar.icon-alive:before, i.ph-chart-bar.icon-warn:before, i.ph-chart-bar.icon-sign-out:before, i.ph-chart-bar.icon-substratum:before, i.ph-chart-bar.icon-environment:before, i.ph-chart-bar.icon-user:before, i.ph-chart-bar.icon-person:before, i.ph-chart-bar.icon-terrain:before, i.ph-chart-bar.icon-taxon:before, i.ph-chart-bar.icon-unknown:before, i.ph-chart-bar.icon-nothing:before, i.ph-chart-bar.icon-sampling:before, i.ph-chart-bar.icon-isolation:before, i.ph-chart-bar.icon-strain:before, i.ph-chart-bar.icon-gps:before, i.ph-chart-bar.icon-location:before, i.ph-chart-bar.icon-add:before, i.ph-chart-bar.icon-left:before, i.ph-chart-bar.icon-dna:before, i.ph-chart-bar.icon-delete:before, i.ph-chart-bar.icon-edit:before, i.ph-chart-bar.icon-info:before {
  content: "\e150";
}

.ph.ph-chart-bar-horizontal:before, i.ph-chart-bar-horizontal.icon-dead:before, i.ph-chart-bar-horizontal.icon-alive:before, i.ph-chart-bar-horizontal.icon-warn:before, i.ph-chart-bar-horizontal.icon-sign-out:before, i.ph-chart-bar-horizontal.icon-substratum:before, i.ph-chart-bar-horizontal.icon-environment:before, i.ph-chart-bar-horizontal.icon-user:before, i.ph-chart-bar-horizontal.icon-person:before, i.ph-chart-bar-horizontal.icon-terrain:before, i.ph-chart-bar-horizontal.icon-taxon:before, i.ph-chart-bar-horizontal.icon-unknown:before, i.ph-chart-bar-horizontal.icon-nothing:before, i.ph-chart-bar-horizontal.icon-sampling:before, i.ph-chart-bar-horizontal.icon-isolation:before, i.ph-chart-bar-horizontal.icon-strain:before, i.ph-chart-bar-horizontal.icon-gps:before, i.ph-chart-bar-horizontal.icon-location:before, i.ph-chart-bar-horizontal.icon-add:before, i.ph-chart-bar-horizontal.icon-left:before, i.ph-chart-bar-horizontal.icon-dna:before, i.ph-chart-bar-horizontal.icon-delete:before, i.ph-chart-bar-horizontal.icon-edit:before, i.ph-chart-bar-horizontal.icon-info:before {
  content: "\e152";
}

.ph.ph-chart-donut:before, i.ph-chart-donut.icon-dead:before, i.ph-chart-donut.icon-alive:before, i.ph-chart-donut.icon-warn:before, i.ph-chart-donut.icon-sign-out:before, i.ph-chart-donut.icon-substratum:before, i.ph-chart-donut.icon-environment:before, i.ph-chart-donut.icon-user:before, i.ph-chart-donut.icon-person:before, i.ph-chart-donut.icon-terrain:before, i.ph-chart-donut.icon-taxon:before, i.ph-chart-donut.icon-unknown:before, i.ph-chart-donut.icon-nothing:before, i.ph-chart-donut.icon-sampling:before, i.ph-chart-donut.icon-isolation:before, i.ph-chart-donut.icon-strain:before, i.ph-chart-donut.icon-gps:before, i.ph-chart-donut.icon-location:before, i.ph-chart-donut.icon-add:before, i.ph-chart-donut.icon-left:before, i.ph-chart-donut.icon-dna:before, i.ph-chart-donut.icon-delete:before, i.ph-chart-donut.icon-edit:before, i.ph-chart-donut.icon-info:before {
  content: "\eaa6";
}

.ph.ph-chart-line:before, i.ph-chart-line.icon-dead:before, i.ph-chart-line.icon-alive:before, i.ph-chart-line.icon-warn:before, i.ph-chart-line.icon-sign-out:before, i.ph-chart-line.icon-substratum:before, i.ph-chart-line.icon-environment:before, i.ph-chart-line.icon-user:before, i.ph-chart-line.icon-person:before, i.ph-chart-line.icon-terrain:before, i.ph-chart-line.icon-taxon:before, i.ph-chart-line.icon-unknown:before, i.ph-chart-line.icon-nothing:before, i.ph-chart-line.icon-sampling:before, i.ph-chart-line.icon-isolation:before, i.ph-chart-line.icon-strain:before, i.ph-chart-line.icon-gps:before, i.ph-chart-line.icon-location:before, i.ph-chart-line.icon-add:before, i.ph-chart-line.icon-left:before, i.ph-chart-line.icon-dna:before, i.ph-chart-line.icon-delete:before, i.ph-chart-line.icon-edit:before, i.ph-chart-line.icon-info:before {
  content: "\e154";
}

.ph.ph-chart-line-down:before, i.ph-chart-line-down.icon-dead:before, i.ph-chart-line-down.icon-alive:before, i.ph-chart-line-down.icon-warn:before, i.ph-chart-line-down.icon-sign-out:before, i.ph-chart-line-down.icon-substratum:before, i.ph-chart-line-down.icon-environment:before, i.ph-chart-line-down.icon-user:before, i.ph-chart-line-down.icon-person:before, i.ph-chart-line-down.icon-terrain:before, i.ph-chart-line-down.icon-taxon:before, i.ph-chart-line-down.icon-unknown:before, i.ph-chart-line-down.icon-nothing:before, i.ph-chart-line-down.icon-sampling:before, i.ph-chart-line-down.icon-isolation:before, i.ph-chart-line-down.icon-strain:before, i.ph-chart-line-down.icon-gps:before, i.ph-chart-line-down.icon-location:before, i.ph-chart-line-down.icon-add:before, i.ph-chart-line-down.icon-left:before, i.ph-chart-line-down.icon-dna:before, i.ph-chart-line-down.icon-delete:before, i.ph-chart-line-down.icon-edit:before, i.ph-chart-line-down.icon-info:before {
  content: "\e8b6";
}

.ph.ph-chart-line-up:before, i.ph-chart-line-up.icon-dead:before, i.ph-chart-line-up.icon-alive:before, i.ph-chart-line-up.icon-warn:before, i.ph-chart-line-up.icon-sign-out:before, i.ph-chart-line-up.icon-substratum:before, i.ph-chart-line-up.icon-environment:before, i.ph-chart-line-up.icon-user:before, i.ph-chart-line-up.icon-person:before, i.ph-chart-line-up.icon-terrain:before, i.ph-chart-line-up.icon-taxon:before, i.ph-chart-line-up.icon-unknown:before, i.ph-chart-line-up.icon-nothing:before, i.ph-chart-line-up.icon-sampling:before, i.ph-chart-line-up.icon-isolation:before, i.ph-chart-line-up.icon-strain:before, i.ph-chart-line-up.icon-gps:before, i.ph-chart-line-up.icon-location:before, i.ph-chart-line-up.icon-add:before, i.ph-chart-line-up.icon-left:before, i.ph-chart-line-up.icon-dna:before, i.ph-chart-line-up.icon-delete:before, i.ph-chart-line-up.icon-edit:before, i.ph-chart-line-up.icon-info:before {
  content: "\e156";
}

.ph.ph-chart-pie:before, i.ph-chart-pie.icon-dead:before, i.ph-chart-pie.icon-alive:before, i.ph-chart-pie.icon-warn:before, i.ph-chart-pie.icon-sign-out:before, i.ph-chart-pie.icon-substratum:before, i.ph-chart-pie.icon-environment:before, i.ph-chart-pie.icon-user:before, i.ph-chart-pie.icon-person:before, i.ph-chart-pie.icon-terrain:before, i.ph-chart-pie.icon-taxon:before, i.ph-chart-pie.icon-unknown:before, i.ph-chart-pie.icon-nothing:before, i.ph-chart-pie.icon-sampling:before, i.ph-chart-pie.icon-isolation:before, i.ph-chart-pie.icon-strain:before, i.ph-chart-pie.icon-gps:before, i.ph-chart-pie.icon-location:before, i.ph-chart-pie.icon-add:before, i.ph-chart-pie.icon-left:before, i.ph-chart-pie.icon-dna:before, i.ph-chart-pie.icon-delete:before, i.ph-chart-pie.icon-edit:before, i.ph-chart-pie.icon-info:before {
  content: "\e158";
}

.ph.ph-chart-pie-slice:before, i.ph-chart-pie-slice.icon-dead:before, i.ph-chart-pie-slice.icon-alive:before, i.ph-chart-pie-slice.icon-warn:before, i.ph-chart-pie-slice.icon-sign-out:before, i.ph-chart-pie-slice.icon-substratum:before, i.ph-chart-pie-slice.icon-environment:before, i.ph-chart-pie-slice.icon-user:before, i.ph-chart-pie-slice.icon-person:before, i.ph-chart-pie-slice.icon-terrain:before, i.ph-chart-pie-slice.icon-taxon:before, i.ph-chart-pie-slice.icon-unknown:before, i.ph-chart-pie-slice.icon-nothing:before, i.ph-chart-pie-slice.icon-sampling:before, i.ph-chart-pie-slice.icon-isolation:before, i.ph-chart-pie-slice.icon-strain:before, i.ph-chart-pie-slice.icon-gps:before, i.ph-chart-pie-slice.icon-location:before, i.ph-chart-pie-slice.icon-add:before, i.ph-chart-pie-slice.icon-left:before, i.ph-chart-pie-slice.icon-dna:before, i.ph-chart-pie-slice.icon-delete:before, i.ph-chart-pie-slice.icon-edit:before, i.ph-chart-pie-slice.icon-info:before {
  content: "\e15a";
}

.ph.ph-chart-polar:before, i.ph-chart-polar.icon-dead:before, i.ph-chart-polar.icon-alive:before, i.ph-chart-polar.icon-warn:before, i.ph-chart-polar.icon-sign-out:before, i.ph-chart-polar.icon-substratum:before, i.ph-chart-polar.icon-environment:before, i.ph-chart-polar.icon-user:before, i.ph-chart-polar.icon-person:before, i.ph-chart-polar.icon-terrain:before, i.ph-chart-polar.icon-taxon:before, i.ph-chart-polar.icon-unknown:before, i.ph-chart-polar.icon-nothing:before, i.ph-chart-polar.icon-sampling:before, i.ph-chart-polar.icon-isolation:before, i.ph-chart-polar.icon-strain:before, i.ph-chart-polar.icon-gps:before, i.ph-chart-polar.icon-location:before, i.ph-chart-polar.icon-add:before, i.ph-chart-polar.icon-left:before, i.ph-chart-polar.icon-dna:before, i.ph-chart-polar.icon-delete:before, i.ph-chart-polar.icon-edit:before, i.ph-chart-polar.icon-info:before {
  content: "\eaa8";
}

.ph.ph-chart-scatter:before, i.ph-chart-scatter.icon-dead:before, i.ph-chart-scatter.icon-alive:before, i.ph-chart-scatter.icon-warn:before, i.ph-chart-scatter.icon-sign-out:before, i.ph-chart-scatter.icon-substratum:before, i.ph-chart-scatter.icon-environment:before, i.ph-chart-scatter.icon-user:before, i.ph-chart-scatter.icon-person:before, i.ph-chart-scatter.icon-terrain:before, i.ph-chart-scatter.icon-taxon:before, i.ph-chart-scatter.icon-unknown:before, i.ph-chart-scatter.icon-nothing:before, i.ph-chart-scatter.icon-sampling:before, i.ph-chart-scatter.icon-isolation:before, i.ph-chart-scatter.icon-strain:before, i.ph-chart-scatter.icon-gps:before, i.ph-chart-scatter.icon-location:before, i.ph-chart-scatter.icon-add:before, i.ph-chart-scatter.icon-left:before, i.ph-chart-scatter.icon-dna:before, i.ph-chart-scatter.icon-delete:before, i.ph-chart-scatter.icon-edit:before, i.ph-chart-scatter.icon-info:before {
  content: "\eaac";
}

.ph.ph-chat:before, i.ph-chat.icon-dead:before, i.ph-chat.icon-alive:before, i.ph-chat.icon-warn:before, i.ph-chat.icon-sign-out:before, i.ph-chat.icon-substratum:before, i.ph-chat.icon-environment:before, i.ph-chat.icon-user:before, i.ph-chat.icon-person:before, i.ph-chat.icon-terrain:before, i.ph-chat.icon-taxon:before, i.ph-chat.icon-unknown:before, i.ph-chat.icon-nothing:before, i.ph-chat.icon-sampling:before, i.ph-chat.icon-isolation:before, i.ph-chat.icon-strain:before, i.ph-chat.icon-gps:before, i.ph-chat.icon-location:before, i.ph-chat.icon-add:before, i.ph-chat.icon-left:before, i.ph-chat.icon-dna:before, i.ph-chat.icon-delete:before, i.ph-chat.icon-edit:before, i.ph-chat.icon-info:before {
  content: "\e15c";
}

.ph.ph-chat-centered:before, i.ph-chat-centered.icon-dead:before, i.ph-chat-centered.icon-alive:before, i.ph-chat-centered.icon-warn:before, i.ph-chat-centered.icon-sign-out:before, i.ph-chat-centered.icon-substratum:before, i.ph-chat-centered.icon-environment:before, i.ph-chat-centered.icon-user:before, i.ph-chat-centered.icon-person:before, i.ph-chat-centered.icon-terrain:before, i.ph-chat-centered.icon-taxon:before, i.ph-chat-centered.icon-unknown:before, i.ph-chat-centered.icon-nothing:before, i.ph-chat-centered.icon-sampling:before, i.ph-chat-centered.icon-isolation:before, i.ph-chat-centered.icon-strain:before, i.ph-chat-centered.icon-gps:before, i.ph-chat-centered.icon-location:before, i.ph-chat-centered.icon-add:before, i.ph-chat-centered.icon-left:before, i.ph-chat-centered.icon-dna:before, i.ph-chat-centered.icon-delete:before, i.ph-chat-centered.icon-edit:before, i.ph-chat-centered.icon-info:before {
  content: "\e160";
}

.ph.ph-chat-centered-dots:before, i.ph-chat-centered-dots.icon-dead:before, i.ph-chat-centered-dots.icon-alive:before, i.ph-chat-centered-dots.icon-warn:before, i.ph-chat-centered-dots.icon-sign-out:before, i.ph-chat-centered-dots.icon-substratum:before, i.ph-chat-centered-dots.icon-environment:before, i.ph-chat-centered-dots.icon-user:before, i.ph-chat-centered-dots.icon-person:before, i.ph-chat-centered-dots.icon-terrain:before, i.ph-chat-centered-dots.icon-taxon:before, i.ph-chat-centered-dots.icon-unknown:before, i.ph-chat-centered-dots.icon-nothing:before, i.ph-chat-centered-dots.icon-sampling:before, i.ph-chat-centered-dots.icon-isolation:before, i.ph-chat-centered-dots.icon-strain:before, i.ph-chat-centered-dots.icon-gps:before, i.ph-chat-centered-dots.icon-location:before, i.ph-chat-centered-dots.icon-add:before, i.ph-chat-centered-dots.icon-left:before, i.ph-chat-centered-dots.icon-dna:before, i.ph-chat-centered-dots.icon-delete:before, i.ph-chat-centered-dots.icon-edit:before, i.ph-chat-centered-dots.icon-info:before {
  content: "\e164";
}

.ph.ph-chat-centered-slash:before, i.ph-chat-centered-slash.icon-dead:before, i.ph-chat-centered-slash.icon-alive:before, i.ph-chat-centered-slash.icon-warn:before, i.ph-chat-centered-slash.icon-sign-out:before, i.ph-chat-centered-slash.icon-substratum:before, i.ph-chat-centered-slash.icon-environment:before, i.ph-chat-centered-slash.icon-user:before, i.ph-chat-centered-slash.icon-person:before, i.ph-chat-centered-slash.icon-terrain:before, i.ph-chat-centered-slash.icon-taxon:before, i.ph-chat-centered-slash.icon-unknown:before, i.ph-chat-centered-slash.icon-nothing:before, i.ph-chat-centered-slash.icon-sampling:before, i.ph-chat-centered-slash.icon-isolation:before, i.ph-chat-centered-slash.icon-strain:before, i.ph-chat-centered-slash.icon-gps:before, i.ph-chat-centered-slash.icon-location:before, i.ph-chat-centered-slash.icon-add:before, i.ph-chat-centered-slash.icon-left:before, i.ph-chat-centered-slash.icon-dna:before, i.ph-chat-centered-slash.icon-delete:before, i.ph-chat-centered-slash.icon-edit:before, i.ph-chat-centered-slash.icon-info:before {
  content: "\e162";
}

.ph.ph-chat-centered-text:before, i.ph-chat-centered-text.icon-dead:before, i.ph-chat-centered-text.icon-alive:before, i.ph-chat-centered-text.icon-warn:before, i.ph-chat-centered-text.icon-sign-out:before, i.ph-chat-centered-text.icon-substratum:before, i.ph-chat-centered-text.icon-environment:before, i.ph-chat-centered-text.icon-user:before, i.ph-chat-centered-text.icon-person:before, i.ph-chat-centered-text.icon-terrain:before, i.ph-chat-centered-text.icon-taxon:before, i.ph-chat-centered-text.icon-unknown:before, i.ph-chat-centered-text.icon-nothing:before, i.ph-chat-centered-text.icon-sampling:before, i.ph-chat-centered-text.icon-isolation:before, i.ph-chat-centered-text.icon-strain:before, i.ph-chat-centered-text.icon-gps:before, i.ph-chat-centered-text.icon-location:before, i.ph-chat-centered-text.icon-add:before, i.ph-chat-centered-text.icon-left:before, i.ph-chat-centered-text.icon-dna:before, i.ph-chat-centered-text.icon-delete:before, i.ph-chat-centered-text.icon-edit:before, i.ph-chat-centered-text.icon-info:before {
  content: "\e166";
}

.ph.ph-chat-circle:before, i.ph-chat-circle.icon-dead:before, i.ph-chat-circle.icon-alive:before, i.ph-chat-circle.icon-warn:before, i.ph-chat-circle.icon-sign-out:before, i.ph-chat-circle.icon-substratum:before, i.ph-chat-circle.icon-environment:before, i.ph-chat-circle.icon-user:before, i.ph-chat-circle.icon-person:before, i.ph-chat-circle.icon-terrain:before, i.ph-chat-circle.icon-taxon:before, i.ph-chat-circle.icon-unknown:before, i.ph-chat-circle.icon-nothing:before, i.ph-chat-circle.icon-sampling:before, i.ph-chat-circle.icon-isolation:before, i.ph-chat-circle.icon-strain:before, i.ph-chat-circle.icon-gps:before, i.ph-chat-circle.icon-location:before, i.ph-chat-circle.icon-add:before, i.ph-chat-circle.icon-left:before, i.ph-chat-circle.icon-dna:before, i.ph-chat-circle.icon-delete:before, i.ph-chat-circle.icon-edit:before, i.ph-chat-circle.icon-info:before {
  content: "\e168";
}

.ph.ph-chat-circle-dots:before, i.ph-chat-circle-dots.icon-dead:before, i.ph-chat-circle-dots.icon-alive:before, i.ph-chat-circle-dots.icon-warn:before, i.ph-chat-circle-dots.icon-sign-out:before, i.ph-chat-circle-dots.icon-substratum:before, i.ph-chat-circle-dots.icon-environment:before, i.ph-chat-circle-dots.icon-user:before, i.ph-chat-circle-dots.icon-person:before, i.ph-chat-circle-dots.icon-terrain:before, i.ph-chat-circle-dots.icon-taxon:before, i.ph-chat-circle-dots.icon-unknown:before, i.ph-chat-circle-dots.icon-nothing:before, i.ph-chat-circle-dots.icon-sampling:before, i.ph-chat-circle-dots.icon-isolation:before, i.ph-chat-circle-dots.icon-strain:before, i.ph-chat-circle-dots.icon-gps:before, i.ph-chat-circle-dots.icon-location:before, i.ph-chat-circle-dots.icon-add:before, i.ph-chat-circle-dots.icon-left:before, i.ph-chat-circle-dots.icon-dna:before, i.ph-chat-circle-dots.icon-delete:before, i.ph-chat-circle-dots.icon-edit:before, i.ph-chat-circle-dots.icon-info:before {
  content: "\e16c";
}

.ph.ph-chat-circle-slash:before, i.ph-chat-circle-slash.icon-dead:before, i.ph-chat-circle-slash.icon-alive:before, i.ph-chat-circle-slash.icon-warn:before, i.ph-chat-circle-slash.icon-sign-out:before, i.ph-chat-circle-slash.icon-substratum:before, i.ph-chat-circle-slash.icon-environment:before, i.ph-chat-circle-slash.icon-user:before, i.ph-chat-circle-slash.icon-person:before, i.ph-chat-circle-slash.icon-terrain:before, i.ph-chat-circle-slash.icon-taxon:before, i.ph-chat-circle-slash.icon-unknown:before, i.ph-chat-circle-slash.icon-nothing:before, i.ph-chat-circle-slash.icon-sampling:before, i.ph-chat-circle-slash.icon-isolation:before, i.ph-chat-circle-slash.icon-strain:before, i.ph-chat-circle-slash.icon-gps:before, i.ph-chat-circle-slash.icon-location:before, i.ph-chat-circle-slash.icon-add:before, i.ph-chat-circle-slash.icon-left:before, i.ph-chat-circle-slash.icon-dna:before, i.ph-chat-circle-slash.icon-delete:before, i.ph-chat-circle-slash.icon-edit:before, i.ph-chat-circle-slash.icon-info:before {
  content: "\e16a";
}

.ph.ph-chat-circle-text:before, i.ph-chat-circle-text.icon-dead:before, i.ph-chat-circle-text.icon-alive:before, i.ph-chat-circle-text.icon-warn:before, i.ph-chat-circle-text.icon-sign-out:before, i.ph-chat-circle-text.icon-substratum:before, i.ph-chat-circle-text.icon-environment:before, i.ph-chat-circle-text.icon-user:before, i.ph-chat-circle-text.icon-person:before, i.ph-chat-circle-text.icon-terrain:before, i.ph-chat-circle-text.icon-taxon:before, i.ph-chat-circle-text.icon-unknown:before, i.ph-chat-circle-text.icon-nothing:before, i.ph-chat-circle-text.icon-sampling:before, i.ph-chat-circle-text.icon-isolation:before, i.ph-chat-circle-text.icon-strain:before, i.ph-chat-circle-text.icon-gps:before, i.ph-chat-circle-text.icon-location:before, i.ph-chat-circle-text.icon-add:before, i.ph-chat-circle-text.icon-left:before, i.ph-chat-circle-text.icon-dna:before, i.ph-chat-circle-text.icon-delete:before, i.ph-chat-circle-text.icon-edit:before, i.ph-chat-circle-text.icon-info:before {
  content: "\e16e";
}

.ph.ph-chat-dots:before, i.ph-chat-dots.icon-dead:before, i.ph-chat-dots.icon-alive:before, i.ph-chat-dots.icon-warn:before, i.ph-chat-dots.icon-sign-out:before, i.ph-chat-dots.icon-substratum:before, i.ph-chat-dots.icon-environment:before, i.ph-chat-dots.icon-user:before, i.ph-chat-dots.icon-person:before, i.ph-chat-dots.icon-terrain:before, i.ph-chat-dots.icon-taxon:before, i.ph-chat-dots.icon-unknown:before, i.ph-chat-dots.icon-nothing:before, i.ph-chat-dots.icon-sampling:before, i.ph-chat-dots.icon-isolation:before, i.ph-chat-dots.icon-strain:before, i.ph-chat-dots.icon-gps:before, i.ph-chat-dots.icon-location:before, i.ph-chat-dots.icon-add:before, i.ph-chat-dots.icon-left:before, i.ph-chat-dots.icon-dna:before, i.ph-chat-dots.icon-delete:before, i.ph-chat-dots.icon-edit:before, i.ph-chat-dots.icon-info:before {
  content: "\e170";
}

.ph.ph-chat-slash:before, i.ph-chat-slash.icon-dead:before, i.ph-chat-slash.icon-alive:before, i.ph-chat-slash.icon-warn:before, i.ph-chat-slash.icon-sign-out:before, i.ph-chat-slash.icon-substratum:before, i.ph-chat-slash.icon-environment:before, i.ph-chat-slash.icon-user:before, i.ph-chat-slash.icon-person:before, i.ph-chat-slash.icon-terrain:before, i.ph-chat-slash.icon-taxon:before, i.ph-chat-slash.icon-unknown:before, i.ph-chat-slash.icon-nothing:before, i.ph-chat-slash.icon-sampling:before, i.ph-chat-slash.icon-isolation:before, i.ph-chat-slash.icon-strain:before, i.ph-chat-slash.icon-gps:before, i.ph-chat-slash.icon-location:before, i.ph-chat-slash.icon-add:before, i.ph-chat-slash.icon-left:before, i.ph-chat-slash.icon-dna:before, i.ph-chat-slash.icon-delete:before, i.ph-chat-slash.icon-edit:before, i.ph-chat-slash.icon-info:before {
  content: "\e15e";
}

.ph.ph-chat-teardrop:before, i.ph-chat-teardrop.icon-dead:before, i.ph-chat-teardrop.icon-alive:before, i.ph-chat-teardrop.icon-warn:before, i.ph-chat-teardrop.icon-sign-out:before, i.ph-chat-teardrop.icon-substratum:before, i.ph-chat-teardrop.icon-environment:before, i.ph-chat-teardrop.icon-user:before, i.ph-chat-teardrop.icon-person:before, i.ph-chat-teardrop.icon-terrain:before, i.ph-chat-teardrop.icon-taxon:before, i.ph-chat-teardrop.icon-unknown:before, i.ph-chat-teardrop.icon-nothing:before, i.ph-chat-teardrop.icon-sampling:before, i.ph-chat-teardrop.icon-isolation:before, i.ph-chat-teardrop.icon-strain:before, i.ph-chat-teardrop.icon-gps:before, i.ph-chat-teardrop.icon-location:before, i.ph-chat-teardrop.icon-add:before, i.ph-chat-teardrop.icon-left:before, i.ph-chat-teardrop.icon-dna:before, i.ph-chat-teardrop.icon-delete:before, i.ph-chat-teardrop.icon-edit:before, i.ph-chat-teardrop.icon-info:before {
  content: "\e172";
}

.ph.ph-chat-teardrop-dots:before, i.ph-chat-teardrop-dots.icon-dead:before, i.ph-chat-teardrop-dots.icon-alive:before, i.ph-chat-teardrop-dots.icon-warn:before, i.ph-chat-teardrop-dots.icon-sign-out:before, i.ph-chat-teardrop-dots.icon-substratum:before, i.ph-chat-teardrop-dots.icon-environment:before, i.ph-chat-teardrop-dots.icon-user:before, i.ph-chat-teardrop-dots.icon-person:before, i.ph-chat-teardrop-dots.icon-terrain:before, i.ph-chat-teardrop-dots.icon-taxon:before, i.ph-chat-teardrop-dots.icon-unknown:before, i.ph-chat-teardrop-dots.icon-nothing:before, i.ph-chat-teardrop-dots.icon-sampling:before, i.ph-chat-teardrop-dots.icon-isolation:before, i.ph-chat-teardrop-dots.icon-strain:before, i.ph-chat-teardrop-dots.icon-gps:before, i.ph-chat-teardrop-dots.icon-location:before, i.ph-chat-teardrop-dots.icon-add:before, i.ph-chat-teardrop-dots.icon-left:before, i.ph-chat-teardrop-dots.icon-dna:before, i.ph-chat-teardrop-dots.icon-delete:before, i.ph-chat-teardrop-dots.icon-edit:before, i.ph-chat-teardrop-dots.icon-info:before {
  content: "\e176";
}

.ph.ph-chat-teardrop-slash:before, i.ph-chat-teardrop-slash.icon-dead:before, i.ph-chat-teardrop-slash.icon-alive:before, i.ph-chat-teardrop-slash.icon-warn:before, i.ph-chat-teardrop-slash.icon-sign-out:before, i.ph-chat-teardrop-slash.icon-substratum:before, i.ph-chat-teardrop-slash.icon-environment:before, i.ph-chat-teardrop-slash.icon-user:before, i.ph-chat-teardrop-slash.icon-person:before, i.ph-chat-teardrop-slash.icon-terrain:before, i.ph-chat-teardrop-slash.icon-taxon:before, i.ph-chat-teardrop-slash.icon-unknown:before, i.ph-chat-teardrop-slash.icon-nothing:before, i.ph-chat-teardrop-slash.icon-sampling:before, i.ph-chat-teardrop-slash.icon-isolation:before, i.ph-chat-teardrop-slash.icon-strain:before, i.ph-chat-teardrop-slash.icon-gps:before, i.ph-chat-teardrop-slash.icon-location:before, i.ph-chat-teardrop-slash.icon-add:before, i.ph-chat-teardrop-slash.icon-left:before, i.ph-chat-teardrop-slash.icon-dna:before, i.ph-chat-teardrop-slash.icon-delete:before, i.ph-chat-teardrop-slash.icon-edit:before, i.ph-chat-teardrop-slash.icon-info:before {
  content: "\e174";
}

.ph.ph-chat-teardrop-text:before, i.ph-chat-teardrop-text.icon-dead:before, i.ph-chat-teardrop-text.icon-alive:before, i.ph-chat-teardrop-text.icon-warn:before, i.ph-chat-teardrop-text.icon-sign-out:before, i.ph-chat-teardrop-text.icon-substratum:before, i.ph-chat-teardrop-text.icon-environment:before, i.ph-chat-teardrop-text.icon-user:before, i.ph-chat-teardrop-text.icon-person:before, i.ph-chat-teardrop-text.icon-terrain:before, i.ph-chat-teardrop-text.icon-taxon:before, i.ph-chat-teardrop-text.icon-unknown:before, i.ph-chat-teardrop-text.icon-nothing:before, i.ph-chat-teardrop-text.icon-sampling:before, i.ph-chat-teardrop-text.icon-isolation:before, i.ph-chat-teardrop-text.icon-strain:before, i.ph-chat-teardrop-text.icon-gps:before, i.ph-chat-teardrop-text.icon-location:before, i.ph-chat-teardrop-text.icon-add:before, i.ph-chat-teardrop-text.icon-left:before, i.ph-chat-teardrop-text.icon-dna:before, i.ph-chat-teardrop-text.icon-delete:before, i.ph-chat-teardrop-text.icon-edit:before, i.ph-chat-teardrop-text.icon-info:before {
  content: "\e178";
}

.ph.ph-chat-text:before, i.ph-chat-text.icon-dead:before, i.ph-chat-text.icon-alive:before, i.ph-chat-text.icon-warn:before, i.ph-chat-text.icon-sign-out:before, i.ph-chat-text.icon-substratum:before, i.ph-chat-text.icon-environment:before, i.ph-chat-text.icon-user:before, i.ph-chat-text.icon-person:before, i.ph-chat-text.icon-terrain:before, i.ph-chat-text.icon-taxon:before, i.ph-chat-text.icon-unknown:before, i.ph-chat-text.icon-nothing:before, i.ph-chat-text.icon-sampling:before, i.ph-chat-text.icon-isolation:before, i.ph-chat-text.icon-strain:before, i.ph-chat-text.icon-gps:before, i.ph-chat-text.icon-location:before, i.ph-chat-text.icon-add:before, i.ph-chat-text.icon-left:before, i.ph-chat-text.icon-dna:before, i.ph-chat-text.icon-delete:before, i.ph-chat-text.icon-edit:before, i.ph-chat-text.icon-info:before {
  content: "\e17a";
}

.ph.ph-chats:before, i.ph-chats.icon-dead:before, i.ph-chats.icon-alive:before, i.ph-chats.icon-warn:before, i.ph-chats.icon-sign-out:before, i.ph-chats.icon-substratum:before, i.ph-chats.icon-environment:before, i.ph-chats.icon-user:before, i.ph-chats.icon-person:before, i.ph-chats.icon-terrain:before, i.ph-chats.icon-taxon:before, i.ph-chats.icon-unknown:before, i.ph-chats.icon-nothing:before, i.ph-chats.icon-sampling:before, i.ph-chats.icon-isolation:before, i.ph-chats.icon-strain:before, i.ph-chats.icon-gps:before, i.ph-chats.icon-location:before, i.ph-chats.icon-add:before, i.ph-chats.icon-left:before, i.ph-chats.icon-dna:before, i.ph-chats.icon-delete:before, i.ph-chats.icon-edit:before, i.ph-chats.icon-info:before {
  content: "\e17c";
}

.ph.ph-chats-circle:before, i.ph-chats-circle.icon-dead:before, i.ph-chats-circle.icon-alive:before, i.ph-chats-circle.icon-warn:before, i.ph-chats-circle.icon-sign-out:before, i.ph-chats-circle.icon-substratum:before, i.ph-chats-circle.icon-environment:before, i.ph-chats-circle.icon-user:before, i.ph-chats-circle.icon-person:before, i.ph-chats-circle.icon-terrain:before, i.ph-chats-circle.icon-taxon:before, i.ph-chats-circle.icon-unknown:before, i.ph-chats-circle.icon-nothing:before, i.ph-chats-circle.icon-sampling:before, i.ph-chats-circle.icon-isolation:before, i.ph-chats-circle.icon-strain:before, i.ph-chats-circle.icon-gps:before, i.ph-chats-circle.icon-location:before, i.ph-chats-circle.icon-add:before, i.ph-chats-circle.icon-left:before, i.ph-chats-circle.icon-dna:before, i.ph-chats-circle.icon-delete:before, i.ph-chats-circle.icon-edit:before, i.ph-chats-circle.icon-info:before {
  content: "\e17e";
}

.ph.ph-chats-teardrop:before, i.ph-chats-teardrop.icon-dead:before, i.ph-chats-teardrop.icon-alive:before, i.ph-chats-teardrop.icon-warn:before, i.ph-chats-teardrop.icon-sign-out:before, i.ph-chats-teardrop.icon-substratum:before, i.ph-chats-teardrop.icon-environment:before, i.ph-chats-teardrop.icon-user:before, i.ph-chats-teardrop.icon-person:before, i.ph-chats-teardrop.icon-terrain:before, i.ph-chats-teardrop.icon-taxon:before, i.ph-chats-teardrop.icon-unknown:before, i.ph-chats-teardrop.icon-nothing:before, i.ph-chats-teardrop.icon-sampling:before, i.ph-chats-teardrop.icon-isolation:before, i.ph-chats-teardrop.icon-strain:before, i.ph-chats-teardrop.icon-gps:before, i.ph-chats-teardrop.icon-location:before, i.ph-chats-teardrop.icon-add:before, i.ph-chats-teardrop.icon-left:before, i.ph-chats-teardrop.icon-dna:before, i.ph-chats-teardrop.icon-delete:before, i.ph-chats-teardrop.icon-edit:before, i.ph-chats-teardrop.icon-info:before {
  content: "\e180";
}

.ph.ph-check:before, i.ph-check.icon-dead:before, i.ph-check.icon-alive:before, i.ph-check.icon-warn:before, i.ph-check.icon-sign-out:before, i.ph-check.icon-substratum:before, i.ph-check.icon-environment:before, i.ph-check.icon-user:before, i.ph-check.icon-person:before, i.ph-check.icon-terrain:before, i.ph-check.icon-taxon:before, i.ph-check.icon-unknown:before, i.ph-check.icon-nothing:before, i.ph-check.icon-sampling:before, i.ph-check.icon-isolation:before, i.ph-check.icon-strain:before, i.ph-check.icon-gps:before, i.ph-check.icon-location:before, i.ph-check.icon-add:before, i.ph-check.icon-left:before, i.ph-check.icon-dna:before, i.ph-check.icon-delete:before, i.ph-check.icon-edit:before, i.ph-check.icon-info:before {
  content: "\e182";
}

.ph.ph-check-circle:before, i.ph-check-circle.icon-dead:before, i.ph-check-circle.icon-alive:before, i.ph-check-circle.icon-warn:before, i.ph-check-circle.icon-sign-out:before, i.ph-check-circle.icon-substratum:before, i.ph-check-circle.icon-environment:before, i.ph-check-circle.icon-user:before, i.ph-check-circle.icon-person:before, i.ph-check-circle.icon-terrain:before, i.ph-check-circle.icon-taxon:before, i.ph-check-circle.icon-unknown:before, i.ph-check-circle.icon-nothing:before, i.ph-check-circle.icon-sampling:before, i.ph-check-circle.icon-isolation:before, i.ph-check-circle.icon-strain:before, i.ph-check-circle.icon-gps:before, i.ph-check-circle.icon-location:before, i.ph-check-circle.icon-add:before, i.ph-check-circle.icon-left:before, i.ph-check-circle.icon-dna:before, i.ph-check-circle.icon-delete:before, i.ph-check-circle.icon-edit:before, i.ph-check-circle.icon-info:before {
  content: "\e184";
}

.ph.ph-check-fat:before, i.ph-check-fat.icon-dead:before, i.ph-check-fat.icon-alive:before, i.ph-check-fat.icon-warn:before, i.ph-check-fat.icon-sign-out:before, i.ph-check-fat.icon-substratum:before, i.ph-check-fat.icon-environment:before, i.ph-check-fat.icon-user:before, i.ph-check-fat.icon-person:before, i.ph-check-fat.icon-terrain:before, i.ph-check-fat.icon-taxon:before, i.ph-check-fat.icon-unknown:before, i.ph-check-fat.icon-nothing:before, i.ph-check-fat.icon-sampling:before, i.ph-check-fat.icon-isolation:before, i.ph-check-fat.icon-strain:before, i.ph-check-fat.icon-gps:before, i.ph-check-fat.icon-location:before, i.ph-check-fat.icon-add:before, i.ph-check-fat.icon-left:before, i.ph-check-fat.icon-dna:before, i.ph-check-fat.icon-delete:before, i.ph-check-fat.icon-edit:before, i.ph-check-fat.icon-info:before {
  content: "\eba6";
}

.ph.ph-check-square:before, i.ph-check-square.icon-dead:before, i.ph-check-square.icon-alive:before, i.ph-check-square.icon-warn:before, i.ph-check-square.icon-sign-out:before, i.ph-check-square.icon-substratum:before, i.ph-check-square.icon-environment:before, i.ph-check-square.icon-user:before, i.ph-check-square.icon-person:before, i.ph-check-square.icon-terrain:before, i.ph-check-square.icon-taxon:before, i.ph-check-square.icon-unknown:before, i.ph-check-square.icon-nothing:before, i.ph-check-square.icon-sampling:before, i.ph-check-square.icon-isolation:before, i.ph-check-square.icon-strain:before, i.ph-check-square.icon-gps:before, i.ph-check-square.icon-location:before, i.ph-check-square.icon-add:before, i.ph-check-square.icon-left:before, i.ph-check-square.icon-dna:before, i.ph-check-square.icon-delete:before, i.ph-check-square.icon-edit:before, i.ph-check-square.icon-info:before {
  content: "\e186";
}

.ph.ph-check-square-offset:before, i.ph-check-square-offset.icon-dead:before, i.ph-check-square-offset.icon-alive:before, i.ph-check-square-offset.icon-warn:before, i.ph-check-square-offset.icon-sign-out:before, i.ph-check-square-offset.icon-substratum:before, i.ph-check-square-offset.icon-environment:before, i.ph-check-square-offset.icon-user:before, i.ph-check-square-offset.icon-person:before, i.ph-check-square-offset.icon-terrain:before, i.ph-check-square-offset.icon-taxon:before, i.ph-check-square-offset.icon-unknown:before, i.ph-check-square-offset.icon-nothing:before, i.ph-check-square-offset.icon-sampling:before, i.ph-check-square-offset.icon-isolation:before, i.ph-check-square-offset.icon-strain:before, i.ph-check-square-offset.icon-gps:before, i.ph-check-square-offset.icon-location:before, i.ph-check-square-offset.icon-add:before, i.ph-check-square-offset.icon-left:before, i.ph-check-square-offset.icon-dna:before, i.ph-check-square-offset.icon-delete:before, i.ph-check-square-offset.icon-edit:before, i.ph-check-square-offset.icon-info:before {
  content: "\e188";
}

.ph.ph-checkerboard:before, i.ph-checkerboard.icon-dead:before, i.ph-checkerboard.icon-alive:before, i.ph-checkerboard.icon-warn:before, i.ph-checkerboard.icon-sign-out:before, i.ph-checkerboard.icon-substratum:before, i.ph-checkerboard.icon-environment:before, i.ph-checkerboard.icon-user:before, i.ph-checkerboard.icon-person:before, i.ph-checkerboard.icon-terrain:before, i.ph-checkerboard.icon-taxon:before, i.ph-checkerboard.icon-unknown:before, i.ph-checkerboard.icon-nothing:before, i.ph-checkerboard.icon-sampling:before, i.ph-checkerboard.icon-isolation:before, i.ph-checkerboard.icon-strain:before, i.ph-checkerboard.icon-gps:before, i.ph-checkerboard.icon-location:before, i.ph-checkerboard.icon-add:before, i.ph-checkerboard.icon-left:before, i.ph-checkerboard.icon-dna:before, i.ph-checkerboard.icon-delete:before, i.ph-checkerboard.icon-edit:before, i.ph-checkerboard.icon-info:before {
  content: "\e8c4";
}

.ph.ph-checks:before, i.ph-checks.icon-dead:before, i.ph-checks.icon-alive:before, i.ph-checks.icon-warn:before, i.ph-checks.icon-sign-out:before, i.ph-checks.icon-substratum:before, i.ph-checks.icon-environment:before, i.ph-checks.icon-user:before, i.ph-checks.icon-person:before, i.ph-checks.icon-terrain:before, i.ph-checks.icon-taxon:before, i.ph-checks.icon-unknown:before, i.ph-checks.icon-nothing:before, i.ph-checks.icon-sampling:before, i.ph-checks.icon-isolation:before, i.ph-checks.icon-strain:before, i.ph-checks.icon-gps:before, i.ph-checks.icon-location:before, i.ph-checks.icon-add:before, i.ph-checks.icon-left:before, i.ph-checks.icon-dna:before, i.ph-checks.icon-delete:before, i.ph-checks.icon-edit:before, i.ph-checks.icon-info:before {
  content: "\e53a";
}

.ph.ph-cheers:before, i.ph-cheers.icon-dead:before, i.ph-cheers.icon-alive:before, i.ph-cheers.icon-warn:before, i.ph-cheers.icon-sign-out:before, i.ph-cheers.icon-substratum:before, i.ph-cheers.icon-environment:before, i.ph-cheers.icon-user:before, i.ph-cheers.icon-person:before, i.ph-cheers.icon-terrain:before, i.ph-cheers.icon-taxon:before, i.ph-cheers.icon-unknown:before, i.ph-cheers.icon-nothing:before, i.ph-cheers.icon-sampling:before, i.ph-cheers.icon-isolation:before, i.ph-cheers.icon-strain:before, i.ph-cheers.icon-gps:before, i.ph-cheers.icon-location:before, i.ph-cheers.icon-add:before, i.ph-cheers.icon-left:before, i.ph-cheers.icon-dna:before, i.ph-cheers.icon-delete:before, i.ph-cheers.icon-edit:before, i.ph-cheers.icon-info:before {
  content: "\ea4a";
}

.ph.ph-cheese:before, i.ph-cheese.icon-dead:before, i.ph-cheese.icon-alive:before, i.ph-cheese.icon-warn:before, i.ph-cheese.icon-sign-out:before, i.ph-cheese.icon-substratum:before, i.ph-cheese.icon-environment:before, i.ph-cheese.icon-user:before, i.ph-cheese.icon-person:before, i.ph-cheese.icon-terrain:before, i.ph-cheese.icon-taxon:before, i.ph-cheese.icon-unknown:before, i.ph-cheese.icon-nothing:before, i.ph-cheese.icon-sampling:before, i.ph-cheese.icon-isolation:before, i.ph-cheese.icon-strain:before, i.ph-cheese.icon-gps:before, i.ph-cheese.icon-location:before, i.ph-cheese.icon-add:before, i.ph-cheese.icon-left:before, i.ph-cheese.icon-dna:before, i.ph-cheese.icon-delete:before, i.ph-cheese.icon-edit:before, i.ph-cheese.icon-info:before {
  content: "\e9fe";
}

.ph.ph-chef-hat:before, i.ph-chef-hat.icon-dead:before, i.ph-chef-hat.icon-alive:before, i.ph-chef-hat.icon-warn:before, i.ph-chef-hat.icon-sign-out:before, i.ph-chef-hat.icon-substratum:before, i.ph-chef-hat.icon-environment:before, i.ph-chef-hat.icon-user:before, i.ph-chef-hat.icon-person:before, i.ph-chef-hat.icon-terrain:before, i.ph-chef-hat.icon-taxon:before, i.ph-chef-hat.icon-unknown:before, i.ph-chef-hat.icon-nothing:before, i.ph-chef-hat.icon-sampling:before, i.ph-chef-hat.icon-isolation:before, i.ph-chef-hat.icon-strain:before, i.ph-chef-hat.icon-gps:before, i.ph-chef-hat.icon-location:before, i.ph-chef-hat.icon-add:before, i.ph-chef-hat.icon-left:before, i.ph-chef-hat.icon-dna:before, i.ph-chef-hat.icon-delete:before, i.ph-chef-hat.icon-edit:before, i.ph-chef-hat.icon-info:before {
  content: "\ed8e";
}

.ph.ph-cherries:before, i.ph-cherries.icon-dead:before, i.ph-cherries.icon-alive:before, i.ph-cherries.icon-warn:before, i.ph-cherries.icon-sign-out:before, i.ph-cherries.icon-substratum:before, i.ph-cherries.icon-environment:before, i.ph-cherries.icon-user:before, i.ph-cherries.icon-person:before, i.ph-cherries.icon-terrain:before, i.ph-cherries.icon-taxon:before, i.ph-cherries.icon-unknown:before, i.ph-cherries.icon-nothing:before, i.ph-cherries.icon-sampling:before, i.ph-cherries.icon-isolation:before, i.ph-cherries.icon-strain:before, i.ph-cherries.icon-gps:before, i.ph-cherries.icon-location:before, i.ph-cherries.icon-add:before, i.ph-cherries.icon-left:before, i.ph-cherries.icon-dna:before, i.ph-cherries.icon-delete:before, i.ph-cherries.icon-edit:before, i.ph-cherries.icon-info:before {
  content: "\e830";
}

.ph.ph-church:before, i.ph-church.icon-dead:before, i.ph-church.icon-alive:before, i.ph-church.icon-warn:before, i.ph-church.icon-sign-out:before, i.ph-church.icon-substratum:before, i.ph-church.icon-environment:before, i.ph-church.icon-user:before, i.ph-church.icon-person:before, i.ph-church.icon-terrain:before, i.ph-church.icon-taxon:before, i.ph-church.icon-unknown:before, i.ph-church.icon-nothing:before, i.ph-church.icon-sampling:before, i.ph-church.icon-isolation:before, i.ph-church.icon-strain:before, i.ph-church.icon-gps:before, i.ph-church.icon-location:before, i.ph-church.icon-add:before, i.ph-church.icon-left:before, i.ph-church.icon-dna:before, i.ph-church.icon-delete:before, i.ph-church.icon-edit:before, i.ph-church.icon-info:before {
  content: "\ecea";
}

.ph.ph-cigarette:before, i.ph-cigarette.icon-dead:before, i.ph-cigarette.icon-alive:before, i.ph-cigarette.icon-warn:before, i.ph-cigarette.icon-sign-out:before, i.ph-cigarette.icon-substratum:before, i.ph-cigarette.icon-environment:before, i.ph-cigarette.icon-user:before, i.ph-cigarette.icon-person:before, i.ph-cigarette.icon-terrain:before, i.ph-cigarette.icon-taxon:before, i.ph-cigarette.icon-unknown:before, i.ph-cigarette.icon-nothing:before, i.ph-cigarette.icon-sampling:before, i.ph-cigarette.icon-isolation:before, i.ph-cigarette.icon-strain:before, i.ph-cigarette.icon-gps:before, i.ph-cigarette.icon-location:before, i.ph-cigarette.icon-add:before, i.ph-cigarette.icon-left:before, i.ph-cigarette.icon-dna:before, i.ph-cigarette.icon-delete:before, i.ph-cigarette.icon-edit:before, i.ph-cigarette.icon-info:before {
  content: "\ed90";
}

.ph.ph-cigarette-slash:before, i.ph-cigarette-slash.icon-dead:before, i.ph-cigarette-slash.icon-alive:before, i.ph-cigarette-slash.icon-warn:before, i.ph-cigarette-slash.icon-sign-out:before, i.ph-cigarette-slash.icon-substratum:before, i.ph-cigarette-slash.icon-environment:before, i.ph-cigarette-slash.icon-user:before, i.ph-cigarette-slash.icon-person:before, i.ph-cigarette-slash.icon-terrain:before, i.ph-cigarette-slash.icon-taxon:before, i.ph-cigarette-slash.icon-unknown:before, i.ph-cigarette-slash.icon-nothing:before, i.ph-cigarette-slash.icon-sampling:before, i.ph-cigarette-slash.icon-isolation:before, i.ph-cigarette-slash.icon-strain:before, i.ph-cigarette-slash.icon-gps:before, i.ph-cigarette-slash.icon-location:before, i.ph-cigarette-slash.icon-add:before, i.ph-cigarette-slash.icon-left:before, i.ph-cigarette-slash.icon-dna:before, i.ph-cigarette-slash.icon-delete:before, i.ph-cigarette-slash.icon-edit:before, i.ph-cigarette-slash.icon-info:before {
  content: "\ed92";
}

.ph.ph-circle:before, i.ph-circle.icon-dead:before, i.ph-circle.icon-alive:before, i.ph-circle.icon-warn:before, i.ph-circle.icon-sign-out:before, i.ph-circle.icon-substratum:before, i.ph-circle.icon-environment:before, i.ph-circle.icon-user:before, i.ph-circle.icon-person:before, i.ph-circle.icon-terrain:before, i.ph-circle.icon-taxon:before, i.ph-circle.icon-unknown:before, i.ph-circle.icon-nothing:before, i.ph-circle.icon-sampling:before, i.ph-circle.icon-isolation:before, i.ph-circle.icon-strain:before, i.ph-circle.icon-gps:before, i.ph-circle.icon-location:before, i.ph-circle.icon-add:before, i.ph-circle.icon-left:before, i.ph-circle.icon-dna:before, i.ph-circle.icon-delete:before, i.ph-circle.icon-edit:before, i.ph-circle.icon-info:before {
  content: "\e18a";
}

.ph.ph-circle-dashed:before, i.ph-circle-dashed.icon-dead:before, i.ph-circle-dashed.icon-alive:before, i.ph-circle-dashed.icon-warn:before, i.ph-circle-dashed.icon-sign-out:before, i.ph-circle-dashed.icon-substratum:before, i.ph-circle-dashed.icon-environment:before, i.ph-circle-dashed.icon-user:before, i.ph-circle-dashed.icon-person:before, i.ph-circle-dashed.icon-terrain:before, i.ph-circle-dashed.icon-taxon:before, i.ph-circle-dashed.icon-unknown:before, i.ph-circle-dashed.icon-nothing:before, i.ph-circle-dashed.icon-sampling:before, i.ph-circle-dashed.icon-isolation:before, i.ph-circle-dashed.icon-strain:before, i.ph-circle-dashed.icon-gps:before, i.ph-circle-dashed.icon-location:before, i.ph-circle-dashed.icon-add:before, i.ph-circle-dashed.icon-left:before, i.ph-circle-dashed.icon-dna:before, i.ph-circle-dashed.icon-delete:before, i.ph-circle-dashed.icon-edit:before, i.ph-circle-dashed.icon-info:before {
  content: "\e602";
}

.ph.ph-circle-half:before, i.ph-circle-half.icon-dead:before, i.ph-circle-half.icon-alive:before, i.ph-circle-half.icon-warn:before, i.ph-circle-half.icon-sign-out:before, i.ph-circle-half.icon-substratum:before, i.ph-circle-half.icon-environment:before, i.ph-circle-half.icon-user:before, i.ph-circle-half.icon-person:before, i.ph-circle-half.icon-terrain:before, i.ph-circle-half.icon-taxon:before, i.ph-circle-half.icon-unknown:before, i.ph-circle-half.icon-nothing:before, i.ph-circle-half.icon-sampling:before, i.ph-circle-half.icon-isolation:before, i.ph-circle-half.icon-strain:before, i.ph-circle-half.icon-gps:before, i.ph-circle-half.icon-location:before, i.ph-circle-half.icon-add:before, i.ph-circle-half.icon-left:before, i.ph-circle-half.icon-dna:before, i.ph-circle-half.icon-delete:before, i.ph-circle-half.icon-edit:before, i.ph-circle-half.icon-info:before {
  content: "\e18c";
}

.ph.ph-circle-half-tilt:before, i.ph-circle-half-tilt.icon-dead:before, i.ph-circle-half-tilt.icon-alive:before, i.ph-circle-half-tilt.icon-warn:before, i.ph-circle-half-tilt.icon-sign-out:before, i.ph-circle-half-tilt.icon-substratum:before, i.ph-circle-half-tilt.icon-environment:before, i.ph-circle-half-tilt.icon-user:before, i.ph-circle-half-tilt.icon-person:before, i.ph-circle-half-tilt.icon-terrain:before, i.ph-circle-half-tilt.icon-taxon:before, i.ph-circle-half-tilt.icon-unknown:before, i.ph-circle-half-tilt.icon-nothing:before, i.ph-circle-half-tilt.icon-sampling:before, i.ph-circle-half-tilt.icon-isolation:before, i.ph-circle-half-tilt.icon-strain:before, i.ph-circle-half-tilt.icon-gps:before, i.ph-circle-half-tilt.icon-location:before, i.ph-circle-half-tilt.icon-add:before, i.ph-circle-half-tilt.icon-left:before, i.ph-circle-half-tilt.icon-dna:before, i.ph-circle-half-tilt.icon-delete:before, i.ph-circle-half-tilt.icon-edit:before, i.ph-circle-half-tilt.icon-info:before {
  content: "\e18e";
}

.ph.ph-circle-notch:before, i.ph-circle-notch.icon-dead:before, i.ph-circle-notch.icon-alive:before, i.ph-circle-notch.icon-warn:before, i.ph-circle-notch.icon-sign-out:before, i.ph-circle-notch.icon-substratum:before, i.ph-circle-notch.icon-environment:before, i.ph-circle-notch.icon-user:before, i.ph-circle-notch.icon-person:before, i.ph-circle-notch.icon-terrain:before, i.ph-circle-notch.icon-taxon:before, i.ph-circle-notch.icon-unknown:before, i.ph-circle-notch.icon-nothing:before, i.ph-circle-notch.icon-sampling:before, i.ph-circle-notch.icon-isolation:before, i.ph-circle-notch.icon-strain:before, i.ph-circle-notch.icon-gps:before, i.ph-circle-notch.icon-location:before, i.ph-circle-notch.icon-add:before, i.ph-circle-notch.icon-left:before, i.ph-circle-notch.icon-dna:before, i.ph-circle-notch.icon-delete:before, i.ph-circle-notch.icon-edit:before, i.ph-circle-notch.icon-info:before {
  content: "\eb44";
}

.ph.ph-circles-four:before, i.ph-circles-four.icon-dead:before, i.ph-circles-four.icon-alive:before, i.ph-circles-four.icon-warn:before, i.ph-circles-four.icon-sign-out:before, i.ph-circles-four.icon-substratum:before, i.ph-circles-four.icon-environment:before, i.ph-circles-four.icon-user:before, i.ph-circles-four.icon-person:before, i.ph-circles-four.icon-terrain:before, i.ph-circles-four.icon-taxon:before, i.ph-circles-four.icon-unknown:before, i.ph-circles-four.icon-nothing:before, i.ph-circles-four.icon-sampling:before, i.ph-circles-four.icon-isolation:before, i.ph-circles-four.icon-strain:before, i.ph-circles-four.icon-gps:before, i.ph-circles-four.icon-location:before, i.ph-circles-four.icon-add:before, i.ph-circles-four.icon-left:before, i.ph-circles-four.icon-dna:before, i.ph-circles-four.icon-delete:before, i.ph-circles-four.icon-edit:before, i.ph-circles-four.icon-info:before {
  content: "\e190";
}

.ph.ph-circles-three:before, i.ph-circles-three.icon-dead:before, i.ph-circles-three.icon-alive:before, i.ph-circles-three.icon-warn:before, i.ph-circles-three.icon-sign-out:before, i.ph-circles-three.icon-substratum:before, i.ph-circles-three.icon-environment:before, i.ph-circles-three.icon-user:before, i.ph-circles-three.icon-person:before, i.ph-circles-three.icon-terrain:before, i.ph-circles-three.icon-taxon:before, i.ph-circles-three.icon-unknown:before, i.ph-circles-three.icon-nothing:before, i.ph-circles-three.icon-sampling:before, i.ph-circles-three.icon-isolation:before, i.ph-circles-three.icon-strain:before, i.ph-circles-three.icon-gps:before, i.ph-circles-three.icon-location:before, i.ph-circles-three.icon-add:before, i.ph-circles-three.icon-left:before, i.ph-circles-three.icon-dna:before, i.ph-circles-three.icon-delete:before, i.ph-circles-three.icon-edit:before, i.ph-circles-three.icon-info:before {
  content: "\e192";
}

.ph.ph-circles-three-plus:before, i.ph-circles-three-plus.icon-dead:before, i.ph-circles-three-plus.icon-alive:before, i.ph-circles-three-plus.icon-warn:before, i.ph-circles-three-plus.icon-sign-out:before, i.ph-circles-three-plus.icon-substratum:before, i.ph-circles-three-plus.icon-environment:before, i.ph-circles-three-plus.icon-user:before, i.ph-circles-three-plus.icon-person:before, i.ph-circles-three-plus.icon-terrain:before, i.ph-circles-three-plus.icon-taxon:before, i.ph-circles-three-plus.icon-unknown:before, i.ph-circles-three-plus.icon-nothing:before, i.ph-circles-three-plus.icon-sampling:before, i.ph-circles-three-plus.icon-isolation:before, i.ph-circles-three-plus.icon-strain:before, i.ph-circles-three-plus.icon-gps:before, i.ph-circles-three-plus.icon-location:before, i.ph-circles-three-plus.icon-add:before, i.ph-circles-three-plus.icon-left:before, i.ph-circles-three-plus.icon-dna:before, i.ph-circles-three-plus.icon-delete:before, i.ph-circles-three-plus.icon-edit:before, i.ph-circles-three-plus.icon-info:before {
  content: "\e194";
}

.ph.ph-circuitry:before, i.ph-circuitry.icon-dead:before, i.ph-circuitry.icon-alive:before, i.ph-circuitry.icon-warn:before, i.ph-circuitry.icon-sign-out:before, i.ph-circuitry.icon-substratum:before, i.ph-circuitry.icon-environment:before, i.ph-circuitry.icon-user:before, i.ph-circuitry.icon-person:before, i.ph-circuitry.icon-terrain:before, i.ph-circuitry.icon-taxon:before, i.ph-circuitry.icon-unknown:before, i.ph-circuitry.icon-nothing:before, i.ph-circuitry.icon-sampling:before, i.ph-circuitry.icon-isolation:before, i.ph-circuitry.icon-strain:before, i.ph-circuitry.icon-gps:before, i.ph-circuitry.icon-location:before, i.ph-circuitry.icon-add:before, i.ph-circuitry.icon-left:before, i.ph-circuitry.icon-dna:before, i.ph-circuitry.icon-delete:before, i.ph-circuitry.icon-edit:before, i.ph-circuitry.icon-info:before {
  content: "\e9c2";
}

.ph.ph-city:before, i.ph-city.icon-dead:before, i.ph-city.icon-alive:before, i.ph-city.icon-warn:before, i.ph-city.icon-sign-out:before, i.ph-city.icon-substratum:before, i.ph-city.icon-environment:before, i.ph-city.icon-user:before, i.ph-city.icon-person:before, i.ph-city.icon-terrain:before, i.ph-city.icon-taxon:before, i.ph-city.icon-unknown:before, i.ph-city.icon-nothing:before, i.ph-city.icon-sampling:before, i.ph-city.icon-isolation:before, i.ph-city.icon-strain:before, i.ph-city.icon-gps:before, i.ph-city.icon-location:before, i.ph-city.icon-add:before, i.ph-city.icon-left:before, i.ph-city.icon-dna:before, i.ph-city.icon-delete:before, i.ph-city.icon-edit:before, i.ph-city.icon-info:before {
  content: "\ea6a";
}

.ph.ph-clipboard:before, i.ph-clipboard.icon-dead:before, i.ph-clipboard.icon-alive:before, i.ph-clipboard.icon-warn:before, i.ph-clipboard.icon-sign-out:before, i.ph-clipboard.icon-substratum:before, i.ph-clipboard.icon-environment:before, i.ph-clipboard.icon-user:before, i.ph-clipboard.icon-person:before, i.ph-clipboard.icon-terrain:before, i.ph-clipboard.icon-taxon:before, i.ph-clipboard.icon-unknown:before, i.ph-clipboard.icon-nothing:before, i.ph-clipboard.icon-sampling:before, i.ph-clipboard.icon-isolation:before, i.ph-clipboard.icon-strain:before, i.ph-clipboard.icon-gps:before, i.ph-clipboard.icon-location:before, i.ph-clipboard.icon-add:before, i.ph-clipboard.icon-left:before, i.ph-clipboard.icon-dna:before, i.ph-clipboard.icon-delete:before, i.ph-clipboard.icon-edit:before, i.ph-clipboard.icon-info:before {
  content: "\e196";
}

.ph.ph-clipboard-text:before, i.ph-clipboard-text.icon-dead:before, i.ph-clipboard-text.icon-alive:before, i.ph-clipboard-text.icon-warn:before, i.ph-clipboard-text.icon-sign-out:before, i.ph-clipboard-text.icon-substratum:before, i.ph-clipboard-text.icon-environment:before, i.ph-clipboard-text.icon-user:before, i.ph-clipboard-text.icon-person:before, i.ph-clipboard-text.icon-terrain:before, i.ph-clipboard-text.icon-taxon:before, i.ph-clipboard-text.icon-unknown:before, i.ph-clipboard-text.icon-nothing:before, i.ph-clipboard-text.icon-sampling:before, i.ph-clipboard-text.icon-isolation:before, i.ph-clipboard-text.icon-strain:before, i.ph-clipboard-text.icon-gps:before, i.ph-clipboard-text.icon-location:before, i.ph-clipboard-text.icon-add:before, i.ph-clipboard-text.icon-left:before, i.ph-clipboard-text.icon-dna:before, i.ph-clipboard-text.icon-delete:before, i.ph-clipboard-text.icon-edit:before, i.ph-clipboard-text.icon-info:before {
  content: "\e198";
}

.ph.ph-clock:before, i.ph-clock.icon-dead:before, i.ph-clock.icon-alive:before, i.ph-clock.icon-warn:before, i.ph-clock.icon-sign-out:before, i.ph-clock.icon-substratum:before, i.ph-clock.icon-environment:before, i.ph-clock.icon-user:before, i.ph-clock.icon-person:before, i.ph-clock.icon-terrain:before, i.ph-clock.icon-taxon:before, i.ph-clock.icon-unknown:before, i.ph-clock.icon-nothing:before, i.ph-clock.icon-sampling:before, i.ph-clock.icon-isolation:before, i.ph-clock.icon-strain:before, i.ph-clock.icon-gps:before, i.ph-clock.icon-location:before, i.ph-clock.icon-add:before, i.ph-clock.icon-left:before, i.ph-clock.icon-dna:before, i.ph-clock.icon-delete:before, i.ph-clock.icon-edit:before, i.ph-clock.icon-info:before {
  content: "\e19a";
}

.ph.ph-clock-afternoon:before, i.ph-clock-afternoon.icon-dead:before, i.ph-clock-afternoon.icon-alive:before, i.ph-clock-afternoon.icon-warn:before, i.ph-clock-afternoon.icon-sign-out:before, i.ph-clock-afternoon.icon-substratum:before, i.ph-clock-afternoon.icon-environment:before, i.ph-clock-afternoon.icon-user:before, i.ph-clock-afternoon.icon-person:before, i.ph-clock-afternoon.icon-terrain:before, i.ph-clock-afternoon.icon-taxon:before, i.ph-clock-afternoon.icon-unknown:before, i.ph-clock-afternoon.icon-nothing:before, i.ph-clock-afternoon.icon-sampling:before, i.ph-clock-afternoon.icon-isolation:before, i.ph-clock-afternoon.icon-strain:before, i.ph-clock-afternoon.icon-gps:before, i.ph-clock-afternoon.icon-location:before, i.ph-clock-afternoon.icon-add:before, i.ph-clock-afternoon.icon-left:before, i.ph-clock-afternoon.icon-dna:before, i.ph-clock-afternoon.icon-delete:before, i.ph-clock-afternoon.icon-edit:before, i.ph-clock-afternoon.icon-info:before {
  content: "\e19c";
}

.ph.ph-clock-clockwise:before, i.ph-clock-clockwise.icon-dead:before, i.ph-clock-clockwise.icon-alive:before, i.ph-clock-clockwise.icon-warn:before, i.ph-clock-clockwise.icon-sign-out:before, i.ph-clock-clockwise.icon-substratum:before, i.ph-clock-clockwise.icon-environment:before, i.ph-clock-clockwise.icon-user:before, i.ph-clock-clockwise.icon-person:before, i.ph-clock-clockwise.icon-terrain:before, i.ph-clock-clockwise.icon-taxon:before, i.ph-clock-clockwise.icon-unknown:before, i.ph-clock-clockwise.icon-nothing:before, i.ph-clock-clockwise.icon-sampling:before, i.ph-clock-clockwise.icon-isolation:before, i.ph-clock-clockwise.icon-strain:before, i.ph-clock-clockwise.icon-gps:before, i.ph-clock-clockwise.icon-location:before, i.ph-clock-clockwise.icon-add:before, i.ph-clock-clockwise.icon-left:before, i.ph-clock-clockwise.icon-dna:before, i.ph-clock-clockwise.icon-delete:before, i.ph-clock-clockwise.icon-edit:before, i.ph-clock-clockwise.icon-info:before {
  content: "\e19e";
}

.ph.ph-clock-countdown:before, i.ph-clock-countdown.icon-dead:before, i.ph-clock-countdown.icon-alive:before, i.ph-clock-countdown.icon-warn:before, i.ph-clock-countdown.icon-sign-out:before, i.ph-clock-countdown.icon-substratum:before, i.ph-clock-countdown.icon-environment:before, i.ph-clock-countdown.icon-user:before, i.ph-clock-countdown.icon-person:before, i.ph-clock-countdown.icon-terrain:before, i.ph-clock-countdown.icon-taxon:before, i.ph-clock-countdown.icon-unknown:before, i.ph-clock-countdown.icon-nothing:before, i.ph-clock-countdown.icon-sampling:before, i.ph-clock-countdown.icon-isolation:before, i.ph-clock-countdown.icon-strain:before, i.ph-clock-countdown.icon-gps:before, i.ph-clock-countdown.icon-location:before, i.ph-clock-countdown.icon-add:before, i.ph-clock-countdown.icon-left:before, i.ph-clock-countdown.icon-dna:before, i.ph-clock-countdown.icon-delete:before, i.ph-clock-countdown.icon-edit:before, i.ph-clock-countdown.icon-info:before {
  content: "\ed2c";
}

.ph.ph-clock-counter-clockwise:before, i.ph-clock-counter-clockwise.icon-dead:before, i.ph-clock-counter-clockwise.icon-alive:before, i.ph-clock-counter-clockwise.icon-warn:before, i.ph-clock-counter-clockwise.icon-sign-out:before, i.ph-clock-counter-clockwise.icon-substratum:before, i.ph-clock-counter-clockwise.icon-environment:before, i.ph-clock-counter-clockwise.icon-user:before, i.ph-clock-counter-clockwise.icon-person:before, i.ph-clock-counter-clockwise.icon-terrain:before, i.ph-clock-counter-clockwise.icon-taxon:before, i.ph-clock-counter-clockwise.icon-unknown:before, i.ph-clock-counter-clockwise.icon-nothing:before, i.ph-clock-counter-clockwise.icon-sampling:before, i.ph-clock-counter-clockwise.icon-isolation:before, i.ph-clock-counter-clockwise.icon-strain:before, i.ph-clock-counter-clockwise.icon-gps:before, i.ph-clock-counter-clockwise.icon-location:before, i.ph-clock-counter-clockwise.icon-add:before, i.ph-clock-counter-clockwise.icon-left:before, i.ph-clock-counter-clockwise.icon-dna:before, i.ph-clock-counter-clockwise.icon-delete:before, i.ph-clock-counter-clockwise.icon-edit:before, i.ph-clock-counter-clockwise.icon-info:before {
  content: "\e1a0";
}

.ph.ph-clock-user:before, i.ph-clock-user.icon-dead:before, i.ph-clock-user.icon-alive:before, i.ph-clock-user.icon-warn:before, i.ph-clock-user.icon-sign-out:before, i.ph-clock-user.icon-substratum:before, i.ph-clock-user.icon-environment:before, i.ph-clock-user.icon-user:before, i.ph-clock-user.icon-person:before, i.ph-clock-user.icon-terrain:before, i.ph-clock-user.icon-taxon:before, i.ph-clock-user.icon-unknown:before, i.ph-clock-user.icon-nothing:before, i.ph-clock-user.icon-sampling:before, i.ph-clock-user.icon-isolation:before, i.ph-clock-user.icon-strain:before, i.ph-clock-user.icon-gps:before, i.ph-clock-user.icon-location:before, i.ph-clock-user.icon-add:before, i.ph-clock-user.icon-left:before, i.ph-clock-user.icon-dna:before, i.ph-clock-user.icon-delete:before, i.ph-clock-user.icon-edit:before, i.ph-clock-user.icon-info:before {
  content: "\edec";
}

.ph.ph-closed-captioning:before, i.ph-closed-captioning.icon-dead:before, i.ph-closed-captioning.icon-alive:before, i.ph-closed-captioning.icon-warn:before, i.ph-closed-captioning.icon-sign-out:before, i.ph-closed-captioning.icon-substratum:before, i.ph-closed-captioning.icon-environment:before, i.ph-closed-captioning.icon-user:before, i.ph-closed-captioning.icon-person:before, i.ph-closed-captioning.icon-terrain:before, i.ph-closed-captioning.icon-taxon:before, i.ph-closed-captioning.icon-unknown:before, i.ph-closed-captioning.icon-nothing:before, i.ph-closed-captioning.icon-sampling:before, i.ph-closed-captioning.icon-isolation:before, i.ph-closed-captioning.icon-strain:before, i.ph-closed-captioning.icon-gps:before, i.ph-closed-captioning.icon-location:before, i.ph-closed-captioning.icon-add:before, i.ph-closed-captioning.icon-left:before, i.ph-closed-captioning.icon-dna:before, i.ph-closed-captioning.icon-delete:before, i.ph-closed-captioning.icon-edit:before, i.ph-closed-captioning.icon-info:before {
  content: "\e1a4";
}

.ph.ph-cloud:before, i.ph-cloud.icon-dead:before, i.ph-cloud.icon-alive:before, i.ph-cloud.icon-warn:before, i.ph-cloud.icon-sign-out:before, i.ph-cloud.icon-substratum:before, i.ph-cloud.icon-environment:before, i.ph-cloud.icon-user:before, i.ph-cloud.icon-person:before, i.ph-cloud.icon-terrain:before, i.ph-cloud.icon-taxon:before, i.ph-cloud.icon-unknown:before, i.ph-cloud.icon-nothing:before, i.ph-cloud.icon-sampling:before, i.ph-cloud.icon-isolation:before, i.ph-cloud.icon-strain:before, i.ph-cloud.icon-gps:before, i.ph-cloud.icon-location:before, i.ph-cloud.icon-add:before, i.ph-cloud.icon-left:before, i.ph-cloud.icon-dna:before, i.ph-cloud.icon-delete:before, i.ph-cloud.icon-edit:before, i.ph-cloud.icon-info:before {
  content: "\e1aa";
}

.ph.ph-cloud-arrow-down:before, i.ph-cloud-arrow-down.icon-dead:before, i.ph-cloud-arrow-down.icon-alive:before, i.ph-cloud-arrow-down.icon-warn:before, i.ph-cloud-arrow-down.icon-sign-out:before, i.ph-cloud-arrow-down.icon-substratum:before, i.ph-cloud-arrow-down.icon-environment:before, i.ph-cloud-arrow-down.icon-user:before, i.ph-cloud-arrow-down.icon-person:before, i.ph-cloud-arrow-down.icon-terrain:before, i.ph-cloud-arrow-down.icon-taxon:before, i.ph-cloud-arrow-down.icon-unknown:before, i.ph-cloud-arrow-down.icon-nothing:before, i.ph-cloud-arrow-down.icon-sampling:before, i.ph-cloud-arrow-down.icon-isolation:before, i.ph-cloud-arrow-down.icon-strain:before, i.ph-cloud-arrow-down.icon-gps:before, i.ph-cloud-arrow-down.icon-location:before, i.ph-cloud-arrow-down.icon-add:before, i.ph-cloud-arrow-down.icon-left:before, i.ph-cloud-arrow-down.icon-dna:before, i.ph-cloud-arrow-down.icon-delete:before, i.ph-cloud-arrow-down.icon-edit:before, i.ph-cloud-arrow-down.icon-info:before {
  content: "\e1ac";
}

.ph.ph-cloud-arrow-up:before, i.ph-cloud-arrow-up.icon-dead:before, i.ph-cloud-arrow-up.icon-alive:before, i.ph-cloud-arrow-up.icon-warn:before, i.ph-cloud-arrow-up.icon-sign-out:before, i.ph-cloud-arrow-up.icon-substratum:before, i.ph-cloud-arrow-up.icon-environment:before, i.ph-cloud-arrow-up.icon-user:before, i.ph-cloud-arrow-up.icon-person:before, i.ph-cloud-arrow-up.icon-terrain:before, i.ph-cloud-arrow-up.icon-taxon:before, i.ph-cloud-arrow-up.icon-unknown:before, i.ph-cloud-arrow-up.icon-nothing:before, i.ph-cloud-arrow-up.icon-sampling:before, i.ph-cloud-arrow-up.icon-isolation:before, i.ph-cloud-arrow-up.icon-strain:before, i.ph-cloud-arrow-up.icon-gps:before, i.ph-cloud-arrow-up.icon-location:before, i.ph-cloud-arrow-up.icon-add:before, i.ph-cloud-arrow-up.icon-left:before, i.ph-cloud-arrow-up.icon-dna:before, i.ph-cloud-arrow-up.icon-delete:before, i.ph-cloud-arrow-up.icon-edit:before, i.ph-cloud-arrow-up.icon-info:before {
  content: "\e1ae";
}

.ph.ph-cloud-check:before, i.ph-cloud-check.icon-dead:before, i.ph-cloud-check.icon-alive:before, i.ph-cloud-check.icon-warn:before, i.ph-cloud-check.icon-sign-out:before, i.ph-cloud-check.icon-substratum:before, i.ph-cloud-check.icon-environment:before, i.ph-cloud-check.icon-user:before, i.ph-cloud-check.icon-person:before, i.ph-cloud-check.icon-terrain:before, i.ph-cloud-check.icon-taxon:before, i.ph-cloud-check.icon-unknown:before, i.ph-cloud-check.icon-nothing:before, i.ph-cloud-check.icon-sampling:before, i.ph-cloud-check.icon-isolation:before, i.ph-cloud-check.icon-strain:before, i.ph-cloud-check.icon-gps:before, i.ph-cloud-check.icon-location:before, i.ph-cloud-check.icon-add:before, i.ph-cloud-check.icon-left:before, i.ph-cloud-check.icon-dna:before, i.ph-cloud-check.icon-delete:before, i.ph-cloud-check.icon-edit:before, i.ph-cloud-check.icon-info:before {
  content: "\e1b0";
}

.ph.ph-cloud-fog:before, i.ph-cloud-fog.icon-dead:before, i.ph-cloud-fog.icon-alive:before, i.ph-cloud-fog.icon-warn:before, i.ph-cloud-fog.icon-sign-out:before, i.ph-cloud-fog.icon-substratum:before, i.ph-cloud-fog.icon-environment:before, i.ph-cloud-fog.icon-user:before, i.ph-cloud-fog.icon-person:before, i.ph-cloud-fog.icon-terrain:before, i.ph-cloud-fog.icon-taxon:before, i.ph-cloud-fog.icon-unknown:before, i.ph-cloud-fog.icon-nothing:before, i.ph-cloud-fog.icon-sampling:before, i.ph-cloud-fog.icon-isolation:before, i.ph-cloud-fog.icon-strain:before, i.ph-cloud-fog.icon-gps:before, i.ph-cloud-fog.icon-location:before, i.ph-cloud-fog.icon-add:before, i.ph-cloud-fog.icon-left:before, i.ph-cloud-fog.icon-dna:before, i.ph-cloud-fog.icon-delete:before, i.ph-cloud-fog.icon-edit:before, i.ph-cloud-fog.icon-info:before {
  content: "\e53c";
}

.ph.ph-cloud-lightning:before, i.ph-cloud-lightning.icon-dead:before, i.ph-cloud-lightning.icon-alive:before, i.ph-cloud-lightning.icon-warn:before, i.ph-cloud-lightning.icon-sign-out:before, i.ph-cloud-lightning.icon-substratum:before, i.ph-cloud-lightning.icon-environment:before, i.ph-cloud-lightning.icon-user:before, i.ph-cloud-lightning.icon-person:before, i.ph-cloud-lightning.icon-terrain:before, i.ph-cloud-lightning.icon-taxon:before, i.ph-cloud-lightning.icon-unknown:before, i.ph-cloud-lightning.icon-nothing:before, i.ph-cloud-lightning.icon-sampling:before, i.ph-cloud-lightning.icon-isolation:before, i.ph-cloud-lightning.icon-strain:before, i.ph-cloud-lightning.icon-gps:before, i.ph-cloud-lightning.icon-location:before, i.ph-cloud-lightning.icon-add:before, i.ph-cloud-lightning.icon-left:before, i.ph-cloud-lightning.icon-dna:before, i.ph-cloud-lightning.icon-delete:before, i.ph-cloud-lightning.icon-edit:before, i.ph-cloud-lightning.icon-info:before {
  content: "\e1b2";
}

.ph.ph-cloud-moon:before, i.ph-cloud-moon.icon-dead:before, i.ph-cloud-moon.icon-alive:before, i.ph-cloud-moon.icon-warn:before, i.ph-cloud-moon.icon-sign-out:before, i.ph-cloud-moon.icon-substratum:before, i.ph-cloud-moon.icon-environment:before, i.ph-cloud-moon.icon-user:before, i.ph-cloud-moon.icon-person:before, i.ph-cloud-moon.icon-terrain:before, i.ph-cloud-moon.icon-taxon:before, i.ph-cloud-moon.icon-unknown:before, i.ph-cloud-moon.icon-nothing:before, i.ph-cloud-moon.icon-sampling:before, i.ph-cloud-moon.icon-isolation:before, i.ph-cloud-moon.icon-strain:before, i.ph-cloud-moon.icon-gps:before, i.ph-cloud-moon.icon-location:before, i.ph-cloud-moon.icon-add:before, i.ph-cloud-moon.icon-left:before, i.ph-cloud-moon.icon-dna:before, i.ph-cloud-moon.icon-delete:before, i.ph-cloud-moon.icon-edit:before, i.ph-cloud-moon.icon-info:before {
  content: "\e53e";
}

.ph.ph-cloud-rain:before, i.ph-cloud-rain.icon-dead:before, i.ph-cloud-rain.icon-alive:before, i.ph-cloud-rain.icon-warn:before, i.ph-cloud-rain.icon-sign-out:before, i.ph-cloud-rain.icon-substratum:before, i.ph-cloud-rain.icon-environment:before, i.ph-cloud-rain.icon-user:before, i.ph-cloud-rain.icon-person:before, i.ph-cloud-rain.icon-terrain:before, i.ph-cloud-rain.icon-taxon:before, i.ph-cloud-rain.icon-unknown:before, i.ph-cloud-rain.icon-nothing:before, i.ph-cloud-rain.icon-sampling:before, i.ph-cloud-rain.icon-isolation:before, i.ph-cloud-rain.icon-strain:before, i.ph-cloud-rain.icon-gps:before, i.ph-cloud-rain.icon-location:before, i.ph-cloud-rain.icon-add:before, i.ph-cloud-rain.icon-left:before, i.ph-cloud-rain.icon-dna:before, i.ph-cloud-rain.icon-delete:before, i.ph-cloud-rain.icon-edit:before, i.ph-cloud-rain.icon-info:before {
  content: "\e1b4";
}

.ph.ph-cloud-slash:before, i.ph-cloud-slash.icon-dead:before, i.ph-cloud-slash.icon-alive:before, i.ph-cloud-slash.icon-warn:before, i.ph-cloud-slash.icon-sign-out:before, i.ph-cloud-slash.icon-substratum:before, i.ph-cloud-slash.icon-environment:before, i.ph-cloud-slash.icon-user:before, i.ph-cloud-slash.icon-person:before, i.ph-cloud-slash.icon-terrain:before, i.ph-cloud-slash.icon-taxon:before, i.ph-cloud-slash.icon-unknown:before, i.ph-cloud-slash.icon-nothing:before, i.ph-cloud-slash.icon-sampling:before, i.ph-cloud-slash.icon-isolation:before, i.ph-cloud-slash.icon-strain:before, i.ph-cloud-slash.icon-gps:before, i.ph-cloud-slash.icon-location:before, i.ph-cloud-slash.icon-add:before, i.ph-cloud-slash.icon-left:before, i.ph-cloud-slash.icon-dna:before, i.ph-cloud-slash.icon-delete:before, i.ph-cloud-slash.icon-edit:before, i.ph-cloud-slash.icon-info:before {
  content: "\e1b6";
}

.ph.ph-cloud-snow:before, i.ph-cloud-snow.icon-dead:before, i.ph-cloud-snow.icon-alive:before, i.ph-cloud-snow.icon-warn:before, i.ph-cloud-snow.icon-sign-out:before, i.ph-cloud-snow.icon-substratum:before, i.ph-cloud-snow.icon-environment:before, i.ph-cloud-snow.icon-user:before, i.ph-cloud-snow.icon-person:before, i.ph-cloud-snow.icon-terrain:before, i.ph-cloud-snow.icon-taxon:before, i.ph-cloud-snow.icon-unknown:before, i.ph-cloud-snow.icon-nothing:before, i.ph-cloud-snow.icon-sampling:before, i.ph-cloud-snow.icon-isolation:before, i.ph-cloud-snow.icon-strain:before, i.ph-cloud-snow.icon-gps:before, i.ph-cloud-snow.icon-location:before, i.ph-cloud-snow.icon-add:before, i.ph-cloud-snow.icon-left:before, i.ph-cloud-snow.icon-dna:before, i.ph-cloud-snow.icon-delete:before, i.ph-cloud-snow.icon-edit:before, i.ph-cloud-snow.icon-info:before {
  content: "\e1b8";
}

.ph.ph-cloud-sun:before, i.ph-cloud-sun.icon-dead:before, i.ph-cloud-sun.icon-alive:before, i.ph-cloud-sun.icon-warn:before, i.ph-cloud-sun.icon-sign-out:before, i.ph-cloud-sun.icon-substratum:before, i.ph-cloud-sun.icon-environment:before, i.ph-cloud-sun.icon-user:before, i.ph-cloud-sun.icon-person:before, i.ph-cloud-sun.icon-terrain:before, i.ph-cloud-sun.icon-taxon:before, i.ph-cloud-sun.icon-unknown:before, i.ph-cloud-sun.icon-nothing:before, i.ph-cloud-sun.icon-sampling:before, i.ph-cloud-sun.icon-isolation:before, i.ph-cloud-sun.icon-strain:before, i.ph-cloud-sun.icon-gps:before, i.ph-cloud-sun.icon-location:before, i.ph-cloud-sun.icon-add:before, i.ph-cloud-sun.icon-left:before, i.ph-cloud-sun.icon-dna:before, i.ph-cloud-sun.icon-delete:before, i.ph-cloud-sun.icon-edit:before, i.ph-cloud-sun.icon-info:before {
  content: "\e540";
}

.ph.ph-cloud-warning:before, i.ph-cloud-warning.icon-dead:before, i.ph-cloud-warning.icon-alive:before, i.ph-cloud-warning.icon-warn:before, i.ph-cloud-warning.icon-sign-out:before, i.ph-cloud-warning.icon-substratum:before, i.ph-cloud-warning.icon-environment:before, i.ph-cloud-warning.icon-user:before, i.ph-cloud-warning.icon-person:before, i.ph-cloud-warning.icon-terrain:before, i.ph-cloud-warning.icon-taxon:before, i.ph-cloud-warning.icon-unknown:before, i.ph-cloud-warning.icon-nothing:before, i.ph-cloud-warning.icon-sampling:before, i.ph-cloud-warning.icon-isolation:before, i.ph-cloud-warning.icon-strain:before, i.ph-cloud-warning.icon-gps:before, i.ph-cloud-warning.icon-location:before, i.ph-cloud-warning.icon-add:before, i.ph-cloud-warning.icon-left:before, i.ph-cloud-warning.icon-dna:before, i.ph-cloud-warning.icon-delete:before, i.ph-cloud-warning.icon-edit:before, i.ph-cloud-warning.icon-info:before {
  content: "\ea98";
}

.ph.ph-cloud-x:before, i.ph-cloud-x.icon-dead:before, i.ph-cloud-x.icon-alive:before, i.ph-cloud-x.icon-warn:before, i.ph-cloud-x.icon-sign-out:before, i.ph-cloud-x.icon-substratum:before, i.ph-cloud-x.icon-environment:before, i.ph-cloud-x.icon-user:before, i.ph-cloud-x.icon-person:before, i.ph-cloud-x.icon-terrain:before, i.ph-cloud-x.icon-taxon:before, i.ph-cloud-x.icon-unknown:before, i.ph-cloud-x.icon-nothing:before, i.ph-cloud-x.icon-sampling:before, i.ph-cloud-x.icon-isolation:before, i.ph-cloud-x.icon-strain:before, i.ph-cloud-x.icon-gps:before, i.ph-cloud-x.icon-location:before, i.ph-cloud-x.icon-add:before, i.ph-cloud-x.icon-left:before, i.ph-cloud-x.icon-dna:before, i.ph-cloud-x.icon-delete:before, i.ph-cloud-x.icon-edit:before, i.ph-cloud-x.icon-info:before {
  content: "\ea96";
}

.ph.ph-clover:before, i.ph-clover.icon-dead:before, i.ph-clover.icon-alive:before, i.ph-clover.icon-warn:before, i.ph-clover.icon-sign-out:before, i.ph-clover.icon-substratum:before, i.ph-clover.icon-environment:before, i.ph-clover.icon-user:before, i.ph-clover.icon-person:before, i.ph-clover.icon-terrain:before, i.ph-clover.icon-taxon:before, i.ph-clover.icon-unknown:before, i.ph-clover.icon-nothing:before, i.ph-clover.icon-sampling:before, i.ph-clover.icon-isolation:before, i.ph-clover.icon-strain:before, i.ph-clover.icon-gps:before, i.ph-clover.icon-location:before, i.ph-clover.icon-add:before, i.ph-clover.icon-left:before, i.ph-clover.icon-dna:before, i.ph-clover.icon-delete:before, i.ph-clover.icon-edit:before, i.ph-clover.icon-info:before {
  content: "\edc8";
}

.ph.ph-club:before, i.ph-club.icon-dead:before, i.ph-club.icon-alive:before, i.ph-club.icon-warn:before, i.ph-club.icon-sign-out:before, i.ph-club.icon-substratum:before, i.ph-club.icon-environment:before, i.ph-club.icon-user:before, i.ph-club.icon-person:before, i.ph-club.icon-terrain:before, i.ph-club.icon-taxon:before, i.ph-club.icon-unknown:before, i.ph-club.icon-nothing:before, i.ph-club.icon-sampling:before, i.ph-club.icon-isolation:before, i.ph-club.icon-strain:before, i.ph-club.icon-gps:before, i.ph-club.icon-location:before, i.ph-club.icon-add:before, i.ph-club.icon-left:before, i.ph-club.icon-dna:before, i.ph-club.icon-delete:before, i.ph-club.icon-edit:before, i.ph-club.icon-info:before {
  content: "\e1ba";
}

.ph.ph-coat-hanger:before, i.ph-coat-hanger.icon-dead:before, i.ph-coat-hanger.icon-alive:before, i.ph-coat-hanger.icon-warn:before, i.ph-coat-hanger.icon-sign-out:before, i.ph-coat-hanger.icon-substratum:before, i.ph-coat-hanger.icon-environment:before, i.ph-coat-hanger.icon-user:before, i.ph-coat-hanger.icon-person:before, i.ph-coat-hanger.icon-terrain:before, i.ph-coat-hanger.icon-taxon:before, i.ph-coat-hanger.icon-unknown:before, i.ph-coat-hanger.icon-nothing:before, i.ph-coat-hanger.icon-sampling:before, i.ph-coat-hanger.icon-isolation:before, i.ph-coat-hanger.icon-strain:before, i.ph-coat-hanger.icon-gps:before, i.ph-coat-hanger.icon-location:before, i.ph-coat-hanger.icon-add:before, i.ph-coat-hanger.icon-left:before, i.ph-coat-hanger.icon-dna:before, i.ph-coat-hanger.icon-delete:before, i.ph-coat-hanger.icon-edit:before, i.ph-coat-hanger.icon-info:before {
  content: "\e7fe";
}

.ph.ph-coda-logo:before, i.ph-coda-logo.icon-dead:before, i.ph-coda-logo.icon-alive:before, i.ph-coda-logo.icon-warn:before, i.ph-coda-logo.icon-sign-out:before, i.ph-coda-logo.icon-substratum:before, i.ph-coda-logo.icon-environment:before, i.ph-coda-logo.icon-user:before, i.ph-coda-logo.icon-person:before, i.ph-coda-logo.icon-terrain:before, i.ph-coda-logo.icon-taxon:before, i.ph-coda-logo.icon-unknown:before, i.ph-coda-logo.icon-nothing:before, i.ph-coda-logo.icon-sampling:before, i.ph-coda-logo.icon-isolation:before, i.ph-coda-logo.icon-strain:before, i.ph-coda-logo.icon-gps:before, i.ph-coda-logo.icon-location:before, i.ph-coda-logo.icon-add:before, i.ph-coda-logo.icon-left:before, i.ph-coda-logo.icon-dna:before, i.ph-coda-logo.icon-delete:before, i.ph-coda-logo.icon-edit:before, i.ph-coda-logo.icon-info:before {
  content: "\e7ce";
}

.ph.ph-code:before, i.ph-code.icon-dead:before, i.ph-code.icon-alive:before, i.ph-code.icon-warn:before, i.ph-code.icon-sign-out:before, i.ph-code.icon-substratum:before, i.ph-code.icon-environment:before, i.ph-code.icon-user:before, i.ph-code.icon-person:before, i.ph-code.icon-terrain:before, i.ph-code.icon-taxon:before, i.ph-code.icon-unknown:before, i.ph-code.icon-nothing:before, i.ph-code.icon-sampling:before, i.ph-code.icon-isolation:before, i.ph-code.icon-strain:before, i.ph-code.icon-gps:before, i.ph-code.icon-location:before, i.ph-code.icon-add:before, i.ph-code.icon-left:before, i.ph-code.icon-dna:before, i.ph-code.icon-delete:before, i.ph-code.icon-edit:before, i.ph-code.icon-info:before {
  content: "\e1bc";
}

.ph.ph-code-block:before, i.ph-code-block.icon-dead:before, i.ph-code-block.icon-alive:before, i.ph-code-block.icon-warn:before, i.ph-code-block.icon-sign-out:before, i.ph-code-block.icon-substratum:before, i.ph-code-block.icon-environment:before, i.ph-code-block.icon-user:before, i.ph-code-block.icon-person:before, i.ph-code-block.icon-terrain:before, i.ph-code-block.icon-taxon:before, i.ph-code-block.icon-unknown:before, i.ph-code-block.icon-nothing:before, i.ph-code-block.icon-sampling:before, i.ph-code-block.icon-isolation:before, i.ph-code-block.icon-strain:before, i.ph-code-block.icon-gps:before, i.ph-code-block.icon-location:before, i.ph-code-block.icon-add:before, i.ph-code-block.icon-left:before, i.ph-code-block.icon-dna:before, i.ph-code-block.icon-delete:before, i.ph-code-block.icon-edit:before, i.ph-code-block.icon-info:before {
  content: "\eafe";
}

.ph.ph-code-simple:before, i.ph-code-simple.icon-dead:before, i.ph-code-simple.icon-alive:before, i.ph-code-simple.icon-warn:before, i.ph-code-simple.icon-sign-out:before, i.ph-code-simple.icon-substratum:before, i.ph-code-simple.icon-environment:before, i.ph-code-simple.icon-user:before, i.ph-code-simple.icon-person:before, i.ph-code-simple.icon-terrain:before, i.ph-code-simple.icon-taxon:before, i.ph-code-simple.icon-unknown:before, i.ph-code-simple.icon-nothing:before, i.ph-code-simple.icon-sampling:before, i.ph-code-simple.icon-isolation:before, i.ph-code-simple.icon-strain:before, i.ph-code-simple.icon-gps:before, i.ph-code-simple.icon-location:before, i.ph-code-simple.icon-add:before, i.ph-code-simple.icon-left:before, i.ph-code-simple.icon-dna:before, i.ph-code-simple.icon-delete:before, i.ph-code-simple.icon-edit:before, i.ph-code-simple.icon-info:before {
  content: "\e1be";
}

.ph.ph-codepen-logo:before, i.ph-codepen-logo.icon-dead:before, i.ph-codepen-logo.icon-alive:before, i.ph-codepen-logo.icon-warn:before, i.ph-codepen-logo.icon-sign-out:before, i.ph-codepen-logo.icon-substratum:before, i.ph-codepen-logo.icon-environment:before, i.ph-codepen-logo.icon-user:before, i.ph-codepen-logo.icon-person:before, i.ph-codepen-logo.icon-terrain:before, i.ph-codepen-logo.icon-taxon:before, i.ph-codepen-logo.icon-unknown:before, i.ph-codepen-logo.icon-nothing:before, i.ph-codepen-logo.icon-sampling:before, i.ph-codepen-logo.icon-isolation:before, i.ph-codepen-logo.icon-strain:before, i.ph-codepen-logo.icon-gps:before, i.ph-codepen-logo.icon-location:before, i.ph-codepen-logo.icon-add:before, i.ph-codepen-logo.icon-left:before, i.ph-codepen-logo.icon-dna:before, i.ph-codepen-logo.icon-delete:before, i.ph-codepen-logo.icon-edit:before, i.ph-codepen-logo.icon-info:before {
  content: "\e978";
}

.ph.ph-codesandbox-logo:before, i.ph-codesandbox-logo.icon-dead:before, i.ph-codesandbox-logo.icon-alive:before, i.ph-codesandbox-logo.icon-warn:before, i.ph-codesandbox-logo.icon-sign-out:before, i.ph-codesandbox-logo.icon-substratum:before, i.ph-codesandbox-logo.icon-environment:before, i.ph-codesandbox-logo.icon-user:before, i.ph-codesandbox-logo.icon-person:before, i.ph-codesandbox-logo.icon-terrain:before, i.ph-codesandbox-logo.icon-taxon:before, i.ph-codesandbox-logo.icon-unknown:before, i.ph-codesandbox-logo.icon-nothing:before, i.ph-codesandbox-logo.icon-sampling:before, i.ph-codesandbox-logo.icon-isolation:before, i.ph-codesandbox-logo.icon-strain:before, i.ph-codesandbox-logo.icon-gps:before, i.ph-codesandbox-logo.icon-location:before, i.ph-codesandbox-logo.icon-add:before, i.ph-codesandbox-logo.icon-left:before, i.ph-codesandbox-logo.icon-dna:before, i.ph-codesandbox-logo.icon-delete:before, i.ph-codesandbox-logo.icon-edit:before, i.ph-codesandbox-logo.icon-info:before {
  content: "\ea06";
}

.ph.ph-coffee:before, i.ph-coffee.icon-dead:before, i.ph-coffee.icon-alive:before, i.ph-coffee.icon-warn:before, i.ph-coffee.icon-sign-out:before, i.ph-coffee.icon-substratum:before, i.ph-coffee.icon-environment:before, i.ph-coffee.icon-user:before, i.ph-coffee.icon-person:before, i.ph-coffee.icon-terrain:before, i.ph-coffee.icon-taxon:before, i.ph-coffee.icon-unknown:before, i.ph-coffee.icon-nothing:before, i.ph-coffee.icon-sampling:before, i.ph-coffee.icon-isolation:before, i.ph-coffee.icon-strain:before, i.ph-coffee.icon-gps:before, i.ph-coffee.icon-location:before, i.ph-coffee.icon-add:before, i.ph-coffee.icon-left:before, i.ph-coffee.icon-dna:before, i.ph-coffee.icon-delete:before, i.ph-coffee.icon-edit:before, i.ph-coffee.icon-info:before {
  content: "\e1c2";
}

.ph.ph-coffee-bean:before, i.ph-coffee-bean.icon-dead:before, i.ph-coffee-bean.icon-alive:before, i.ph-coffee-bean.icon-warn:before, i.ph-coffee-bean.icon-sign-out:before, i.ph-coffee-bean.icon-substratum:before, i.ph-coffee-bean.icon-environment:before, i.ph-coffee-bean.icon-user:before, i.ph-coffee-bean.icon-person:before, i.ph-coffee-bean.icon-terrain:before, i.ph-coffee-bean.icon-taxon:before, i.ph-coffee-bean.icon-unknown:before, i.ph-coffee-bean.icon-nothing:before, i.ph-coffee-bean.icon-sampling:before, i.ph-coffee-bean.icon-isolation:before, i.ph-coffee-bean.icon-strain:before, i.ph-coffee-bean.icon-gps:before, i.ph-coffee-bean.icon-location:before, i.ph-coffee-bean.icon-add:before, i.ph-coffee-bean.icon-left:before, i.ph-coffee-bean.icon-dna:before, i.ph-coffee-bean.icon-delete:before, i.ph-coffee-bean.icon-edit:before, i.ph-coffee-bean.icon-info:before {
  content: "\e1c0";
}

.ph.ph-coin:before, i.ph-coin.icon-dead:before, i.ph-coin.icon-alive:before, i.ph-coin.icon-warn:before, i.ph-coin.icon-sign-out:before, i.ph-coin.icon-substratum:before, i.ph-coin.icon-environment:before, i.ph-coin.icon-user:before, i.ph-coin.icon-person:before, i.ph-coin.icon-terrain:before, i.ph-coin.icon-taxon:before, i.ph-coin.icon-unknown:before, i.ph-coin.icon-nothing:before, i.ph-coin.icon-sampling:before, i.ph-coin.icon-isolation:before, i.ph-coin.icon-strain:before, i.ph-coin.icon-gps:before, i.ph-coin.icon-location:before, i.ph-coin.icon-add:before, i.ph-coin.icon-left:before, i.ph-coin.icon-dna:before, i.ph-coin.icon-delete:before, i.ph-coin.icon-edit:before, i.ph-coin.icon-info:before {
  content: "\e60e";
}

.ph.ph-coin-vertical:before, i.ph-coin-vertical.icon-dead:before, i.ph-coin-vertical.icon-alive:before, i.ph-coin-vertical.icon-warn:before, i.ph-coin-vertical.icon-sign-out:before, i.ph-coin-vertical.icon-substratum:before, i.ph-coin-vertical.icon-environment:before, i.ph-coin-vertical.icon-user:before, i.ph-coin-vertical.icon-person:before, i.ph-coin-vertical.icon-terrain:before, i.ph-coin-vertical.icon-taxon:before, i.ph-coin-vertical.icon-unknown:before, i.ph-coin-vertical.icon-nothing:before, i.ph-coin-vertical.icon-sampling:before, i.ph-coin-vertical.icon-isolation:before, i.ph-coin-vertical.icon-strain:before, i.ph-coin-vertical.icon-gps:before, i.ph-coin-vertical.icon-location:before, i.ph-coin-vertical.icon-add:before, i.ph-coin-vertical.icon-left:before, i.ph-coin-vertical.icon-dna:before, i.ph-coin-vertical.icon-delete:before, i.ph-coin-vertical.icon-edit:before, i.ph-coin-vertical.icon-info:before {
  content: "\eb48";
}

.ph.ph-coins:before, i.ph-coins.icon-dead:before, i.ph-coins.icon-alive:before, i.ph-coins.icon-warn:before, i.ph-coins.icon-sign-out:before, i.ph-coins.icon-substratum:before, i.ph-coins.icon-environment:before, i.ph-coins.icon-user:before, i.ph-coins.icon-person:before, i.ph-coins.icon-terrain:before, i.ph-coins.icon-taxon:before, i.ph-coins.icon-unknown:before, i.ph-coins.icon-nothing:before, i.ph-coins.icon-sampling:before, i.ph-coins.icon-isolation:before, i.ph-coins.icon-strain:before, i.ph-coins.icon-gps:before, i.ph-coins.icon-location:before, i.ph-coins.icon-add:before, i.ph-coins.icon-left:before, i.ph-coins.icon-dna:before, i.ph-coins.icon-delete:before, i.ph-coins.icon-edit:before, i.ph-coins.icon-info:before {
  content: "\e78e";
}

.ph.ph-columns:before, i.ph-columns.icon-dead:before, i.ph-columns.icon-alive:before, i.ph-columns.icon-warn:before, i.ph-columns.icon-sign-out:before, i.ph-columns.icon-substratum:before, i.ph-columns.icon-environment:before, i.ph-columns.icon-user:before, i.ph-columns.icon-person:before, i.ph-columns.icon-terrain:before, i.ph-columns.icon-taxon:before, i.ph-columns.icon-unknown:before, i.ph-columns.icon-nothing:before, i.ph-columns.icon-sampling:before, i.ph-columns.icon-isolation:before, i.ph-columns.icon-strain:before, i.ph-columns.icon-gps:before, i.ph-columns.icon-location:before, i.ph-columns.icon-add:before, i.ph-columns.icon-left:before, i.ph-columns.icon-dna:before, i.ph-columns.icon-delete:before, i.ph-columns.icon-edit:before, i.ph-columns.icon-info:before {
  content: "\e546";
}

.ph.ph-columns-plus-left:before, i.ph-columns-plus-left.icon-dead:before, i.ph-columns-plus-left.icon-alive:before, i.ph-columns-plus-left.icon-warn:before, i.ph-columns-plus-left.icon-sign-out:before, i.ph-columns-plus-left.icon-substratum:before, i.ph-columns-plus-left.icon-environment:before, i.ph-columns-plus-left.icon-user:before, i.ph-columns-plus-left.icon-person:before, i.ph-columns-plus-left.icon-terrain:before, i.ph-columns-plus-left.icon-taxon:before, i.ph-columns-plus-left.icon-unknown:before, i.ph-columns-plus-left.icon-nothing:before, i.ph-columns-plus-left.icon-sampling:before, i.ph-columns-plus-left.icon-isolation:before, i.ph-columns-plus-left.icon-strain:before, i.ph-columns-plus-left.icon-gps:before, i.ph-columns-plus-left.icon-location:before, i.ph-columns-plus-left.icon-add:before, i.ph-columns-plus-left.icon-left:before, i.ph-columns-plus-left.icon-dna:before, i.ph-columns-plus-left.icon-delete:before, i.ph-columns-plus-left.icon-edit:before, i.ph-columns-plus-left.icon-info:before {
  content: "\e544";
}

.ph.ph-columns-plus-right:before, i.ph-columns-plus-right.icon-dead:before, i.ph-columns-plus-right.icon-alive:before, i.ph-columns-plus-right.icon-warn:before, i.ph-columns-plus-right.icon-sign-out:before, i.ph-columns-plus-right.icon-substratum:before, i.ph-columns-plus-right.icon-environment:before, i.ph-columns-plus-right.icon-user:before, i.ph-columns-plus-right.icon-person:before, i.ph-columns-plus-right.icon-terrain:before, i.ph-columns-plus-right.icon-taxon:before, i.ph-columns-plus-right.icon-unknown:before, i.ph-columns-plus-right.icon-nothing:before, i.ph-columns-plus-right.icon-sampling:before, i.ph-columns-plus-right.icon-isolation:before, i.ph-columns-plus-right.icon-strain:before, i.ph-columns-plus-right.icon-gps:before, i.ph-columns-plus-right.icon-location:before, i.ph-columns-plus-right.icon-add:before, i.ph-columns-plus-right.icon-left:before, i.ph-columns-plus-right.icon-dna:before, i.ph-columns-plus-right.icon-delete:before, i.ph-columns-plus-right.icon-edit:before, i.ph-columns-plus-right.icon-info:before {
  content: "\e542";
}

.ph.ph-command:before, i.ph-command.icon-dead:before, i.ph-command.icon-alive:before, i.ph-command.icon-warn:before, i.ph-command.icon-sign-out:before, i.ph-command.icon-substratum:before, i.ph-command.icon-environment:before, i.ph-command.icon-user:before, i.ph-command.icon-person:before, i.ph-command.icon-terrain:before, i.ph-command.icon-taxon:before, i.ph-command.icon-unknown:before, i.ph-command.icon-nothing:before, i.ph-command.icon-sampling:before, i.ph-command.icon-isolation:before, i.ph-command.icon-strain:before, i.ph-command.icon-gps:before, i.ph-command.icon-location:before, i.ph-command.icon-add:before, i.ph-command.icon-left:before, i.ph-command.icon-dna:before, i.ph-command.icon-delete:before, i.ph-command.icon-edit:before, i.ph-command.icon-info:before {
  content: "\e1c4";
}

.ph.ph-compass:before, i.ph-compass.icon-dead:before, i.ph-compass.icon-alive:before, i.ph-compass.icon-warn:before, i.ph-compass.icon-sign-out:before, i.ph-compass.icon-substratum:before, i.ph-compass.icon-environment:before, i.ph-compass.icon-user:before, i.ph-compass.icon-person:before, i.ph-compass.icon-terrain:before, i.ph-compass.icon-taxon:before, i.ph-compass.icon-unknown:before, i.ph-compass.icon-nothing:before, i.ph-compass.icon-sampling:before, i.ph-compass.icon-isolation:before, i.ph-compass.icon-strain:before, i.ph-compass.icon-gps:before, i.ph-compass.icon-location:before, i.ph-compass.icon-add:before, i.ph-compass.icon-left:before, i.ph-compass.icon-dna:before, i.ph-compass.icon-delete:before, i.ph-compass.icon-edit:before, i.ph-compass.icon-info:before {
  content: "\e1c8";
}

.ph.ph-compass-rose:before, i.ph-compass-rose.icon-dead:before, i.ph-compass-rose.icon-alive:before, i.ph-compass-rose.icon-warn:before, i.ph-compass-rose.icon-sign-out:before, i.ph-compass-rose.icon-substratum:before, i.ph-compass-rose.icon-environment:before, i.ph-compass-rose.icon-user:before, i.ph-compass-rose.icon-person:before, i.ph-compass-rose.icon-terrain:before, i.ph-compass-rose.icon-taxon:before, i.ph-compass-rose.icon-unknown:before, i.ph-compass-rose.icon-nothing:before, i.ph-compass-rose.icon-sampling:before, i.ph-compass-rose.icon-isolation:before, i.ph-compass-rose.icon-strain:before, i.ph-compass-rose.icon-gps:before, i.ph-compass-rose.icon-location:before, i.ph-compass-rose.icon-add:before, i.ph-compass-rose.icon-left:before, i.ph-compass-rose.icon-dna:before, i.ph-compass-rose.icon-delete:before, i.ph-compass-rose.icon-edit:before, i.ph-compass-rose.icon-info:before {
  content: "\e1c6";
}

.ph.ph-compass-tool:before, i.ph-compass-tool.icon-dead:before, i.ph-compass-tool.icon-alive:before, i.ph-compass-tool.icon-warn:before, i.ph-compass-tool.icon-sign-out:before, i.ph-compass-tool.icon-substratum:before, i.ph-compass-tool.icon-environment:before, i.ph-compass-tool.icon-user:before, i.ph-compass-tool.icon-person:before, i.ph-compass-tool.icon-terrain:before, i.ph-compass-tool.icon-taxon:before, i.ph-compass-tool.icon-unknown:before, i.ph-compass-tool.icon-nothing:before, i.ph-compass-tool.icon-sampling:before, i.ph-compass-tool.icon-isolation:before, i.ph-compass-tool.icon-strain:before, i.ph-compass-tool.icon-gps:before, i.ph-compass-tool.icon-location:before, i.ph-compass-tool.icon-add:before, i.ph-compass-tool.icon-left:before, i.ph-compass-tool.icon-dna:before, i.ph-compass-tool.icon-delete:before, i.ph-compass-tool.icon-edit:before, i.ph-compass-tool.icon-info:before {
  content: "\ea0e";
}

.ph.ph-computer-tower:before, i.ph-computer-tower.icon-dead:before, i.ph-computer-tower.icon-alive:before, i.ph-computer-tower.icon-warn:before, i.ph-computer-tower.icon-sign-out:before, i.ph-computer-tower.icon-substratum:before, i.ph-computer-tower.icon-environment:before, i.ph-computer-tower.icon-user:before, i.ph-computer-tower.icon-person:before, i.ph-computer-tower.icon-terrain:before, i.ph-computer-tower.icon-taxon:before, i.ph-computer-tower.icon-unknown:before, i.ph-computer-tower.icon-nothing:before, i.ph-computer-tower.icon-sampling:before, i.ph-computer-tower.icon-isolation:before, i.ph-computer-tower.icon-strain:before, i.ph-computer-tower.icon-gps:before, i.ph-computer-tower.icon-location:before, i.ph-computer-tower.icon-add:before, i.ph-computer-tower.icon-left:before, i.ph-computer-tower.icon-dna:before, i.ph-computer-tower.icon-delete:before, i.ph-computer-tower.icon-edit:before, i.ph-computer-tower.icon-info:before {
  content: "\e548";
}

.ph.ph-confetti:before, i.ph-confetti.icon-dead:before, i.ph-confetti.icon-alive:before, i.ph-confetti.icon-warn:before, i.ph-confetti.icon-sign-out:before, i.ph-confetti.icon-substratum:before, i.ph-confetti.icon-environment:before, i.ph-confetti.icon-user:before, i.ph-confetti.icon-person:before, i.ph-confetti.icon-terrain:before, i.ph-confetti.icon-taxon:before, i.ph-confetti.icon-unknown:before, i.ph-confetti.icon-nothing:before, i.ph-confetti.icon-sampling:before, i.ph-confetti.icon-isolation:before, i.ph-confetti.icon-strain:before, i.ph-confetti.icon-gps:before, i.ph-confetti.icon-location:before, i.ph-confetti.icon-add:before, i.ph-confetti.icon-left:before, i.ph-confetti.icon-dna:before, i.ph-confetti.icon-delete:before, i.ph-confetti.icon-edit:before, i.ph-confetti.icon-info:before {
  content: "\e81a";
}

.ph.ph-contactless-payment:before, i.ph-contactless-payment.icon-dead:before, i.ph-contactless-payment.icon-alive:before, i.ph-contactless-payment.icon-warn:before, i.ph-contactless-payment.icon-sign-out:before, i.ph-contactless-payment.icon-substratum:before, i.ph-contactless-payment.icon-environment:before, i.ph-contactless-payment.icon-user:before, i.ph-contactless-payment.icon-person:before, i.ph-contactless-payment.icon-terrain:before, i.ph-contactless-payment.icon-taxon:before, i.ph-contactless-payment.icon-unknown:before, i.ph-contactless-payment.icon-nothing:before, i.ph-contactless-payment.icon-sampling:before, i.ph-contactless-payment.icon-isolation:before, i.ph-contactless-payment.icon-strain:before, i.ph-contactless-payment.icon-gps:before, i.ph-contactless-payment.icon-location:before, i.ph-contactless-payment.icon-add:before, i.ph-contactless-payment.icon-left:before, i.ph-contactless-payment.icon-dna:before, i.ph-contactless-payment.icon-delete:before, i.ph-contactless-payment.icon-edit:before, i.ph-contactless-payment.icon-info:before {
  content: "\ed42";
}

.ph.ph-control:before, i.ph-control.icon-dead:before, i.ph-control.icon-alive:before, i.ph-control.icon-warn:before, i.ph-control.icon-sign-out:before, i.ph-control.icon-substratum:before, i.ph-control.icon-environment:before, i.ph-control.icon-user:before, i.ph-control.icon-person:before, i.ph-control.icon-terrain:before, i.ph-control.icon-taxon:before, i.ph-control.icon-unknown:before, i.ph-control.icon-nothing:before, i.ph-control.icon-sampling:before, i.ph-control.icon-isolation:before, i.ph-control.icon-strain:before, i.ph-control.icon-gps:before, i.ph-control.icon-location:before, i.ph-control.icon-add:before, i.ph-control.icon-left:before, i.ph-control.icon-dna:before, i.ph-control.icon-delete:before, i.ph-control.icon-edit:before, i.ph-control.icon-info:before {
  content: "\eca6";
}

.ph.ph-cookie:before, i.ph-cookie.icon-dead:before, i.ph-cookie.icon-alive:before, i.ph-cookie.icon-warn:before, i.ph-cookie.icon-sign-out:before, i.ph-cookie.icon-substratum:before, i.ph-cookie.icon-environment:before, i.ph-cookie.icon-user:before, i.ph-cookie.icon-person:before, i.ph-cookie.icon-terrain:before, i.ph-cookie.icon-taxon:before, i.ph-cookie.icon-unknown:before, i.ph-cookie.icon-nothing:before, i.ph-cookie.icon-sampling:before, i.ph-cookie.icon-isolation:before, i.ph-cookie.icon-strain:before, i.ph-cookie.icon-gps:before, i.ph-cookie.icon-location:before, i.ph-cookie.icon-add:before, i.ph-cookie.icon-left:before, i.ph-cookie.icon-dna:before, i.ph-cookie.icon-delete:before, i.ph-cookie.icon-edit:before, i.ph-cookie.icon-info:before {
  content: "\e6ca";
}

.ph.ph-cooking-pot:before, i.ph-cooking-pot.icon-dead:before, i.ph-cooking-pot.icon-alive:before, i.ph-cooking-pot.icon-warn:before, i.ph-cooking-pot.icon-sign-out:before, i.ph-cooking-pot.icon-substratum:before, i.ph-cooking-pot.icon-environment:before, i.ph-cooking-pot.icon-user:before, i.ph-cooking-pot.icon-person:before, i.ph-cooking-pot.icon-terrain:before, i.ph-cooking-pot.icon-taxon:before, i.ph-cooking-pot.icon-unknown:before, i.ph-cooking-pot.icon-nothing:before, i.ph-cooking-pot.icon-sampling:before, i.ph-cooking-pot.icon-isolation:before, i.ph-cooking-pot.icon-strain:before, i.ph-cooking-pot.icon-gps:before, i.ph-cooking-pot.icon-location:before, i.ph-cooking-pot.icon-add:before, i.ph-cooking-pot.icon-left:before, i.ph-cooking-pot.icon-dna:before, i.ph-cooking-pot.icon-delete:before, i.ph-cooking-pot.icon-edit:before, i.ph-cooking-pot.icon-info:before {
  content: "\e764";
}

.ph.ph-copy:before, i.ph-copy.icon-dead:before, i.ph-copy.icon-alive:before, i.ph-copy.icon-warn:before, i.ph-copy.icon-sign-out:before, i.ph-copy.icon-substratum:before, i.ph-copy.icon-environment:before, i.ph-copy.icon-user:before, i.ph-copy.icon-person:before, i.ph-copy.icon-terrain:before, i.ph-copy.icon-taxon:before, i.ph-copy.icon-unknown:before, i.ph-copy.icon-nothing:before, i.ph-copy.icon-sampling:before, i.ph-copy.icon-isolation:before, i.ph-copy.icon-strain:before, i.ph-copy.icon-gps:before, i.ph-copy.icon-location:before, i.ph-copy.icon-add:before, i.ph-copy.icon-left:before, i.ph-copy.icon-dna:before, i.ph-copy.icon-delete:before, i.ph-copy.icon-edit:before, i.ph-copy.icon-info:before {
  content: "\e1ca";
}

.ph.ph-copy-simple:before, i.ph-copy-simple.icon-dead:before, i.ph-copy-simple.icon-alive:before, i.ph-copy-simple.icon-warn:before, i.ph-copy-simple.icon-sign-out:before, i.ph-copy-simple.icon-substratum:before, i.ph-copy-simple.icon-environment:before, i.ph-copy-simple.icon-user:before, i.ph-copy-simple.icon-person:before, i.ph-copy-simple.icon-terrain:before, i.ph-copy-simple.icon-taxon:before, i.ph-copy-simple.icon-unknown:before, i.ph-copy-simple.icon-nothing:before, i.ph-copy-simple.icon-sampling:before, i.ph-copy-simple.icon-isolation:before, i.ph-copy-simple.icon-strain:before, i.ph-copy-simple.icon-gps:before, i.ph-copy-simple.icon-location:before, i.ph-copy-simple.icon-add:before, i.ph-copy-simple.icon-left:before, i.ph-copy-simple.icon-dna:before, i.ph-copy-simple.icon-delete:before, i.ph-copy-simple.icon-edit:before, i.ph-copy-simple.icon-info:before {
  content: "\e1cc";
}

.ph.ph-copyleft:before, i.ph-copyleft.icon-dead:before, i.ph-copyleft.icon-alive:before, i.ph-copyleft.icon-warn:before, i.ph-copyleft.icon-sign-out:before, i.ph-copyleft.icon-substratum:before, i.ph-copyleft.icon-environment:before, i.ph-copyleft.icon-user:before, i.ph-copyleft.icon-person:before, i.ph-copyleft.icon-terrain:before, i.ph-copyleft.icon-taxon:before, i.ph-copyleft.icon-unknown:before, i.ph-copyleft.icon-nothing:before, i.ph-copyleft.icon-sampling:before, i.ph-copyleft.icon-isolation:before, i.ph-copyleft.icon-strain:before, i.ph-copyleft.icon-gps:before, i.ph-copyleft.icon-location:before, i.ph-copyleft.icon-add:before, i.ph-copyleft.icon-left:before, i.ph-copyleft.icon-dna:before, i.ph-copyleft.icon-delete:before, i.ph-copyleft.icon-edit:before, i.ph-copyleft.icon-info:before {
  content: "\e86a";
}

.ph.ph-copyright:before, i.ph-copyright.icon-dead:before, i.ph-copyright.icon-alive:before, i.ph-copyright.icon-warn:before, i.ph-copyright.icon-sign-out:before, i.ph-copyright.icon-substratum:before, i.ph-copyright.icon-environment:before, i.ph-copyright.icon-user:before, i.ph-copyright.icon-person:before, i.ph-copyright.icon-terrain:before, i.ph-copyright.icon-taxon:before, i.ph-copyright.icon-unknown:before, i.ph-copyright.icon-nothing:before, i.ph-copyright.icon-sampling:before, i.ph-copyright.icon-isolation:before, i.ph-copyright.icon-strain:before, i.ph-copyright.icon-gps:before, i.ph-copyright.icon-location:before, i.ph-copyright.icon-add:before, i.ph-copyright.icon-left:before, i.ph-copyright.icon-dna:before, i.ph-copyright.icon-delete:before, i.ph-copyright.icon-edit:before, i.ph-copyright.icon-info:before {
  content: "\e54a";
}

.ph.ph-corners-in:before, i.ph-corners-in.icon-dead:before, i.ph-corners-in.icon-alive:before, i.ph-corners-in.icon-warn:before, i.ph-corners-in.icon-sign-out:before, i.ph-corners-in.icon-substratum:before, i.ph-corners-in.icon-environment:before, i.ph-corners-in.icon-user:before, i.ph-corners-in.icon-person:before, i.ph-corners-in.icon-terrain:before, i.ph-corners-in.icon-taxon:before, i.ph-corners-in.icon-unknown:before, i.ph-corners-in.icon-nothing:before, i.ph-corners-in.icon-sampling:before, i.ph-corners-in.icon-isolation:before, i.ph-corners-in.icon-strain:before, i.ph-corners-in.icon-gps:before, i.ph-corners-in.icon-location:before, i.ph-corners-in.icon-add:before, i.ph-corners-in.icon-left:before, i.ph-corners-in.icon-dna:before, i.ph-corners-in.icon-delete:before, i.ph-corners-in.icon-edit:before, i.ph-corners-in.icon-info:before {
  content: "\e1ce";
}

.ph.ph-corners-out:before, i.ph-corners-out.icon-dead:before, i.ph-corners-out.icon-alive:before, i.ph-corners-out.icon-warn:before, i.ph-corners-out.icon-sign-out:before, i.ph-corners-out.icon-substratum:before, i.ph-corners-out.icon-environment:before, i.ph-corners-out.icon-user:before, i.ph-corners-out.icon-person:before, i.ph-corners-out.icon-terrain:before, i.ph-corners-out.icon-taxon:before, i.ph-corners-out.icon-unknown:before, i.ph-corners-out.icon-nothing:before, i.ph-corners-out.icon-sampling:before, i.ph-corners-out.icon-isolation:before, i.ph-corners-out.icon-strain:before, i.ph-corners-out.icon-gps:before, i.ph-corners-out.icon-location:before, i.ph-corners-out.icon-add:before, i.ph-corners-out.icon-left:before, i.ph-corners-out.icon-dna:before, i.ph-corners-out.icon-delete:before, i.ph-corners-out.icon-edit:before, i.ph-corners-out.icon-info:before {
  content: "\e1d0";
}

.ph.ph-couch:before, i.ph-couch.icon-dead:before, i.ph-couch.icon-alive:before, i.ph-couch.icon-warn:before, i.ph-couch.icon-sign-out:before, i.ph-couch.icon-substratum:before, i.ph-couch.icon-environment:before, i.ph-couch.icon-user:before, i.ph-couch.icon-person:before, i.ph-couch.icon-terrain:before, i.ph-couch.icon-taxon:before, i.ph-couch.icon-unknown:before, i.ph-couch.icon-nothing:before, i.ph-couch.icon-sampling:before, i.ph-couch.icon-isolation:before, i.ph-couch.icon-strain:before, i.ph-couch.icon-gps:before, i.ph-couch.icon-location:before, i.ph-couch.icon-add:before, i.ph-couch.icon-left:before, i.ph-couch.icon-dna:before, i.ph-couch.icon-delete:before, i.ph-couch.icon-edit:before, i.ph-couch.icon-info:before {
  content: "\e7f6";
}

.ph.ph-court-basketball:before, i.ph-court-basketball.icon-dead:before, i.ph-court-basketball.icon-alive:before, i.ph-court-basketball.icon-warn:before, i.ph-court-basketball.icon-sign-out:before, i.ph-court-basketball.icon-substratum:before, i.ph-court-basketball.icon-environment:before, i.ph-court-basketball.icon-user:before, i.ph-court-basketball.icon-person:before, i.ph-court-basketball.icon-terrain:before, i.ph-court-basketball.icon-taxon:before, i.ph-court-basketball.icon-unknown:before, i.ph-court-basketball.icon-nothing:before, i.ph-court-basketball.icon-sampling:before, i.ph-court-basketball.icon-isolation:before, i.ph-court-basketball.icon-strain:before, i.ph-court-basketball.icon-gps:before, i.ph-court-basketball.icon-location:before, i.ph-court-basketball.icon-add:before, i.ph-court-basketball.icon-left:before, i.ph-court-basketball.icon-dna:before, i.ph-court-basketball.icon-delete:before, i.ph-court-basketball.icon-edit:before, i.ph-court-basketball.icon-info:before {
  content: "\ee36";
}

.ph.ph-cow:before, i.ph-cow.icon-dead:before, i.ph-cow.icon-alive:before, i.ph-cow.icon-warn:before, i.ph-cow.icon-sign-out:before, i.ph-cow.icon-substratum:before, i.ph-cow.icon-environment:before, i.ph-cow.icon-user:before, i.ph-cow.icon-person:before, i.ph-cow.icon-terrain:before, i.ph-cow.icon-taxon:before, i.ph-cow.icon-unknown:before, i.ph-cow.icon-nothing:before, i.ph-cow.icon-sampling:before, i.ph-cow.icon-isolation:before, i.ph-cow.icon-strain:before, i.ph-cow.icon-gps:before, i.ph-cow.icon-location:before, i.ph-cow.icon-add:before, i.ph-cow.icon-left:before, i.ph-cow.icon-dna:before, i.ph-cow.icon-delete:before, i.ph-cow.icon-edit:before, i.ph-cow.icon-info:before {
  content: "\eabe";
}

.ph.ph-cowboy-hat:before, i.ph-cowboy-hat.icon-dead:before, i.ph-cowboy-hat.icon-alive:before, i.ph-cowboy-hat.icon-warn:before, i.ph-cowboy-hat.icon-sign-out:before, i.ph-cowboy-hat.icon-substratum:before, i.ph-cowboy-hat.icon-environment:before, i.ph-cowboy-hat.icon-user:before, i.ph-cowboy-hat.icon-person:before, i.ph-cowboy-hat.icon-terrain:before, i.ph-cowboy-hat.icon-taxon:before, i.ph-cowboy-hat.icon-unknown:before, i.ph-cowboy-hat.icon-nothing:before, i.ph-cowboy-hat.icon-sampling:before, i.ph-cowboy-hat.icon-isolation:before, i.ph-cowboy-hat.icon-strain:before, i.ph-cowboy-hat.icon-gps:before, i.ph-cowboy-hat.icon-location:before, i.ph-cowboy-hat.icon-add:before, i.ph-cowboy-hat.icon-left:before, i.ph-cowboy-hat.icon-dna:before, i.ph-cowboy-hat.icon-delete:before, i.ph-cowboy-hat.icon-edit:before, i.ph-cowboy-hat.icon-info:before {
  content: "\ed12";
}

.ph.ph-cpu:before, i.ph-cpu.icon-dead:before, i.ph-cpu.icon-alive:before, i.ph-cpu.icon-warn:before, i.ph-cpu.icon-sign-out:before, i.ph-cpu.icon-substratum:before, i.ph-cpu.icon-environment:before, i.ph-cpu.icon-user:before, i.ph-cpu.icon-person:before, i.ph-cpu.icon-terrain:before, i.ph-cpu.icon-taxon:before, i.ph-cpu.icon-unknown:before, i.ph-cpu.icon-nothing:before, i.ph-cpu.icon-sampling:before, i.ph-cpu.icon-isolation:before, i.ph-cpu.icon-strain:before, i.ph-cpu.icon-gps:before, i.ph-cpu.icon-location:before, i.ph-cpu.icon-add:before, i.ph-cpu.icon-left:before, i.ph-cpu.icon-dna:before, i.ph-cpu.icon-delete:before, i.ph-cpu.icon-edit:before, i.ph-cpu.icon-info:before {
  content: "\e610";
}

.ph.ph-crane:before, i.ph-crane.icon-dead:before, i.ph-crane.icon-alive:before, i.ph-crane.icon-warn:before, i.ph-crane.icon-sign-out:before, i.ph-crane.icon-substratum:before, i.ph-crane.icon-environment:before, i.ph-crane.icon-user:before, i.ph-crane.icon-person:before, i.ph-crane.icon-terrain:before, i.ph-crane.icon-taxon:before, i.ph-crane.icon-unknown:before, i.ph-crane.icon-nothing:before, i.ph-crane.icon-sampling:before, i.ph-crane.icon-isolation:before, i.ph-crane.icon-strain:before, i.ph-crane.icon-gps:before, i.ph-crane.icon-location:before, i.ph-crane.icon-add:before, i.ph-crane.icon-left:before, i.ph-crane.icon-dna:before, i.ph-crane.icon-delete:before, i.ph-crane.icon-edit:before, i.ph-crane.icon-info:before {
  content: "\ed48";
}

.ph.ph-crane-tower:before, i.ph-crane-tower.icon-dead:before, i.ph-crane-tower.icon-alive:before, i.ph-crane-tower.icon-warn:before, i.ph-crane-tower.icon-sign-out:before, i.ph-crane-tower.icon-substratum:before, i.ph-crane-tower.icon-environment:before, i.ph-crane-tower.icon-user:before, i.ph-crane-tower.icon-person:before, i.ph-crane-tower.icon-terrain:before, i.ph-crane-tower.icon-taxon:before, i.ph-crane-tower.icon-unknown:before, i.ph-crane-tower.icon-nothing:before, i.ph-crane-tower.icon-sampling:before, i.ph-crane-tower.icon-isolation:before, i.ph-crane-tower.icon-strain:before, i.ph-crane-tower.icon-gps:before, i.ph-crane-tower.icon-location:before, i.ph-crane-tower.icon-add:before, i.ph-crane-tower.icon-left:before, i.ph-crane-tower.icon-dna:before, i.ph-crane-tower.icon-delete:before, i.ph-crane-tower.icon-edit:before, i.ph-crane-tower.icon-info:before {
  content: "\ed49";
}

.ph.ph-credit-card:before, i.ph-credit-card.icon-dead:before, i.ph-credit-card.icon-alive:before, i.ph-credit-card.icon-warn:before, i.ph-credit-card.icon-sign-out:before, i.ph-credit-card.icon-substratum:before, i.ph-credit-card.icon-environment:before, i.ph-credit-card.icon-user:before, i.ph-credit-card.icon-person:before, i.ph-credit-card.icon-terrain:before, i.ph-credit-card.icon-taxon:before, i.ph-credit-card.icon-unknown:before, i.ph-credit-card.icon-nothing:before, i.ph-credit-card.icon-sampling:before, i.ph-credit-card.icon-isolation:before, i.ph-credit-card.icon-strain:before, i.ph-credit-card.icon-gps:before, i.ph-credit-card.icon-location:before, i.ph-credit-card.icon-add:before, i.ph-credit-card.icon-left:before, i.ph-credit-card.icon-dna:before, i.ph-credit-card.icon-delete:before, i.ph-credit-card.icon-edit:before, i.ph-credit-card.icon-info:before {
  content: "\e1d2";
}

.ph.ph-cricket:before, i.ph-cricket.icon-dead:before, i.ph-cricket.icon-alive:before, i.ph-cricket.icon-warn:before, i.ph-cricket.icon-sign-out:before, i.ph-cricket.icon-substratum:before, i.ph-cricket.icon-environment:before, i.ph-cricket.icon-user:before, i.ph-cricket.icon-person:before, i.ph-cricket.icon-terrain:before, i.ph-cricket.icon-taxon:before, i.ph-cricket.icon-unknown:before, i.ph-cricket.icon-nothing:before, i.ph-cricket.icon-sampling:before, i.ph-cricket.icon-isolation:before, i.ph-cricket.icon-strain:before, i.ph-cricket.icon-gps:before, i.ph-cricket.icon-location:before, i.ph-cricket.icon-add:before, i.ph-cricket.icon-left:before, i.ph-cricket.icon-dna:before, i.ph-cricket.icon-delete:before, i.ph-cricket.icon-edit:before, i.ph-cricket.icon-info:before {
  content: "\ee12";
}

.ph.ph-crop:before, i.ph-crop.icon-dead:before, i.ph-crop.icon-alive:before, i.ph-crop.icon-warn:before, i.ph-crop.icon-sign-out:before, i.ph-crop.icon-substratum:before, i.ph-crop.icon-environment:before, i.ph-crop.icon-user:before, i.ph-crop.icon-person:before, i.ph-crop.icon-terrain:before, i.ph-crop.icon-taxon:before, i.ph-crop.icon-unknown:before, i.ph-crop.icon-nothing:before, i.ph-crop.icon-sampling:before, i.ph-crop.icon-isolation:before, i.ph-crop.icon-strain:before, i.ph-crop.icon-gps:before, i.ph-crop.icon-location:before, i.ph-crop.icon-add:before, i.ph-crop.icon-left:before, i.ph-crop.icon-dna:before, i.ph-crop.icon-delete:before, i.ph-crop.icon-edit:before, i.ph-crop.icon-info:before {
  content: "\e1d4";
}

.ph.ph-cross:before, i.ph-cross.icon-dead:before, i.ph-cross.icon-alive:before, i.ph-cross.icon-warn:before, i.ph-cross.icon-sign-out:before, i.ph-cross.icon-substratum:before, i.ph-cross.icon-environment:before, i.ph-cross.icon-user:before, i.ph-cross.icon-person:before, i.ph-cross.icon-terrain:before, i.ph-cross.icon-taxon:before, i.ph-cross.icon-unknown:before, i.ph-cross.icon-nothing:before, i.ph-cross.icon-sampling:before, i.ph-cross.icon-isolation:before, i.ph-cross.icon-strain:before, i.ph-cross.icon-gps:before, i.ph-cross.icon-location:before, i.ph-cross.icon-add:before, i.ph-cross.icon-left:before, i.ph-cross.icon-dna:before, i.ph-cross.icon-delete:before, i.ph-cross.icon-edit:before, i.ph-cross.icon-info:before {
  content: "\e8a0";
}

.ph.ph-crosshair:before, i.ph-crosshair.icon-dead:before, i.ph-crosshair.icon-alive:before, i.ph-crosshair.icon-warn:before, i.ph-crosshair.icon-sign-out:before, i.ph-crosshair.icon-substratum:before, i.ph-crosshair.icon-environment:before, i.ph-crosshair.icon-user:before, i.ph-crosshair.icon-person:before, i.ph-crosshair.icon-terrain:before, i.ph-crosshair.icon-taxon:before, i.ph-crosshair.icon-unknown:before, i.ph-crosshair.icon-nothing:before, i.ph-crosshair.icon-sampling:before, i.ph-crosshair.icon-isolation:before, i.ph-crosshair.icon-strain:before, i.ph-crosshair.icon-gps:before, i.ph-crosshair.icon-location:before, i.ph-crosshair.icon-add:before, i.ph-crosshair.icon-left:before, i.ph-crosshair.icon-dna:before, i.ph-crosshair.icon-delete:before, i.ph-crosshair.icon-edit:before, i.ph-crosshair.icon-info:before {
  content: "\e1d6";
}

.ph.ph-crosshair-simple:before, i.ph-crosshair-simple.icon-dead:before, i.ph-crosshair-simple.icon-alive:before, i.ph-crosshair-simple.icon-warn:before, i.ph-crosshair-simple.icon-sign-out:before, i.ph-crosshair-simple.icon-substratum:before, i.ph-crosshair-simple.icon-environment:before, i.ph-crosshair-simple.icon-user:before, i.ph-crosshair-simple.icon-person:before, i.ph-crosshair-simple.icon-terrain:before, i.ph-crosshair-simple.icon-taxon:before, i.ph-crosshair-simple.icon-unknown:before, i.ph-crosshair-simple.icon-nothing:before, i.ph-crosshair-simple.icon-sampling:before, i.ph-crosshair-simple.icon-isolation:before, i.ph-crosshair-simple.icon-strain:before, i.ph-crosshair-simple.icon-gps:before, i.ph-crosshair-simple.icon-location:before, i.ph-crosshair-simple.icon-add:before, i.ph-crosshair-simple.icon-left:before, i.ph-crosshair-simple.icon-dna:before, i.ph-crosshair-simple.icon-delete:before, i.ph-crosshair-simple.icon-edit:before, i.ph-crosshair-simple.icon-info:before {
  content: "\e1d8";
}

.ph.ph-crown:before, i.ph-crown.icon-dead:before, i.ph-crown.icon-alive:before, i.ph-crown.icon-warn:before, i.ph-crown.icon-sign-out:before, i.ph-crown.icon-substratum:before, i.ph-crown.icon-environment:before, i.ph-crown.icon-user:before, i.ph-crown.icon-person:before, i.ph-crown.icon-terrain:before, i.ph-crown.icon-taxon:before, i.ph-crown.icon-unknown:before, i.ph-crown.icon-nothing:before, i.ph-crown.icon-sampling:before, i.ph-crown.icon-isolation:before, i.ph-crown.icon-strain:before, i.ph-crown.icon-gps:before, i.ph-crown.icon-location:before, i.ph-crown.icon-add:before, i.ph-crown.icon-left:before, i.ph-crown.icon-dna:before, i.ph-crown.icon-delete:before, i.ph-crown.icon-edit:before, i.ph-crown.icon-info:before {
  content: "\e614";
}

.ph.ph-crown-cross:before, i.ph-crown-cross.icon-dead:before, i.ph-crown-cross.icon-alive:before, i.ph-crown-cross.icon-warn:before, i.ph-crown-cross.icon-sign-out:before, i.ph-crown-cross.icon-substratum:before, i.ph-crown-cross.icon-environment:before, i.ph-crown-cross.icon-user:before, i.ph-crown-cross.icon-person:before, i.ph-crown-cross.icon-terrain:before, i.ph-crown-cross.icon-taxon:before, i.ph-crown-cross.icon-unknown:before, i.ph-crown-cross.icon-nothing:before, i.ph-crown-cross.icon-sampling:before, i.ph-crown-cross.icon-isolation:before, i.ph-crown-cross.icon-strain:before, i.ph-crown-cross.icon-gps:before, i.ph-crown-cross.icon-location:before, i.ph-crown-cross.icon-add:before, i.ph-crown-cross.icon-left:before, i.ph-crown-cross.icon-dna:before, i.ph-crown-cross.icon-delete:before, i.ph-crown-cross.icon-edit:before, i.ph-crown-cross.icon-info:before {
  content: "\ee5e";
}

.ph.ph-crown-simple:before, i.ph-crown-simple.icon-dead:before, i.ph-crown-simple.icon-alive:before, i.ph-crown-simple.icon-warn:before, i.ph-crown-simple.icon-sign-out:before, i.ph-crown-simple.icon-substratum:before, i.ph-crown-simple.icon-environment:before, i.ph-crown-simple.icon-user:before, i.ph-crown-simple.icon-person:before, i.ph-crown-simple.icon-terrain:before, i.ph-crown-simple.icon-taxon:before, i.ph-crown-simple.icon-unknown:before, i.ph-crown-simple.icon-nothing:before, i.ph-crown-simple.icon-sampling:before, i.ph-crown-simple.icon-isolation:before, i.ph-crown-simple.icon-strain:before, i.ph-crown-simple.icon-gps:before, i.ph-crown-simple.icon-location:before, i.ph-crown-simple.icon-add:before, i.ph-crown-simple.icon-left:before, i.ph-crown-simple.icon-dna:before, i.ph-crown-simple.icon-delete:before, i.ph-crown-simple.icon-edit:before, i.ph-crown-simple.icon-info:before {
  content: "\e616";
}

.ph.ph-cube:before, i.ph-cube.icon-dead:before, i.ph-cube.icon-alive:before, i.ph-cube.icon-warn:before, i.ph-cube.icon-sign-out:before, i.ph-cube.icon-substratum:before, i.ph-cube.icon-environment:before, i.ph-cube.icon-user:before, i.ph-cube.icon-person:before, i.ph-cube.icon-terrain:before, i.ph-cube.icon-taxon:before, i.ph-cube.icon-unknown:before, i.ph-cube.icon-nothing:before, i.ph-cube.icon-sampling:before, i.ph-cube.icon-isolation:before, i.ph-cube.icon-strain:before, i.ph-cube.icon-gps:before, i.ph-cube.icon-location:before, i.ph-cube.icon-add:before, i.ph-cube.icon-left:before, i.ph-cube.icon-dna:before, i.ph-cube.icon-delete:before, i.ph-cube.icon-edit:before, i.ph-cube.icon-info:before {
  content: "\e1da";
}

.ph.ph-cube-focus:before, i.ph-cube-focus.icon-dead:before, i.ph-cube-focus.icon-alive:before, i.ph-cube-focus.icon-warn:before, i.ph-cube-focus.icon-sign-out:before, i.ph-cube-focus.icon-substratum:before, i.ph-cube-focus.icon-environment:before, i.ph-cube-focus.icon-user:before, i.ph-cube-focus.icon-person:before, i.ph-cube-focus.icon-terrain:before, i.ph-cube-focus.icon-taxon:before, i.ph-cube-focus.icon-unknown:before, i.ph-cube-focus.icon-nothing:before, i.ph-cube-focus.icon-sampling:before, i.ph-cube-focus.icon-isolation:before, i.ph-cube-focus.icon-strain:before, i.ph-cube-focus.icon-gps:before, i.ph-cube-focus.icon-location:before, i.ph-cube-focus.icon-add:before, i.ph-cube-focus.icon-left:before, i.ph-cube-focus.icon-dna:before, i.ph-cube-focus.icon-delete:before, i.ph-cube-focus.icon-edit:before, i.ph-cube-focus.icon-info:before {
  content: "\ed0a";
}

.ph.ph-cube-transparent:before, i.ph-cube-transparent.icon-dead:before, i.ph-cube-transparent.icon-alive:before, i.ph-cube-transparent.icon-warn:before, i.ph-cube-transparent.icon-sign-out:before, i.ph-cube-transparent.icon-substratum:before, i.ph-cube-transparent.icon-environment:before, i.ph-cube-transparent.icon-user:before, i.ph-cube-transparent.icon-person:before, i.ph-cube-transparent.icon-terrain:before, i.ph-cube-transparent.icon-taxon:before, i.ph-cube-transparent.icon-unknown:before, i.ph-cube-transparent.icon-nothing:before, i.ph-cube-transparent.icon-sampling:before, i.ph-cube-transparent.icon-isolation:before, i.ph-cube-transparent.icon-strain:before, i.ph-cube-transparent.icon-gps:before, i.ph-cube-transparent.icon-location:before, i.ph-cube-transparent.icon-add:before, i.ph-cube-transparent.icon-left:before, i.ph-cube-transparent.icon-dna:before, i.ph-cube-transparent.icon-delete:before, i.ph-cube-transparent.icon-edit:before, i.ph-cube-transparent.icon-info:before {
  content: "\ec7c";
}

.ph.ph-currency-btc:before, i.ph-currency-btc.icon-dead:before, i.ph-currency-btc.icon-alive:before, i.ph-currency-btc.icon-warn:before, i.ph-currency-btc.icon-sign-out:before, i.ph-currency-btc.icon-substratum:before, i.ph-currency-btc.icon-environment:before, i.ph-currency-btc.icon-user:before, i.ph-currency-btc.icon-person:before, i.ph-currency-btc.icon-terrain:before, i.ph-currency-btc.icon-taxon:before, i.ph-currency-btc.icon-unknown:before, i.ph-currency-btc.icon-nothing:before, i.ph-currency-btc.icon-sampling:before, i.ph-currency-btc.icon-isolation:before, i.ph-currency-btc.icon-strain:before, i.ph-currency-btc.icon-gps:before, i.ph-currency-btc.icon-location:before, i.ph-currency-btc.icon-add:before, i.ph-currency-btc.icon-left:before, i.ph-currency-btc.icon-dna:before, i.ph-currency-btc.icon-delete:before, i.ph-currency-btc.icon-edit:before, i.ph-currency-btc.icon-info:before {
  content: "\e618";
}

.ph.ph-currency-circle-dollar:before, i.ph-currency-circle-dollar.icon-dead:before, i.ph-currency-circle-dollar.icon-alive:before, i.ph-currency-circle-dollar.icon-warn:before, i.ph-currency-circle-dollar.icon-sign-out:before, i.ph-currency-circle-dollar.icon-substratum:before, i.ph-currency-circle-dollar.icon-environment:before, i.ph-currency-circle-dollar.icon-user:before, i.ph-currency-circle-dollar.icon-person:before, i.ph-currency-circle-dollar.icon-terrain:before, i.ph-currency-circle-dollar.icon-taxon:before, i.ph-currency-circle-dollar.icon-unknown:before, i.ph-currency-circle-dollar.icon-nothing:before, i.ph-currency-circle-dollar.icon-sampling:before, i.ph-currency-circle-dollar.icon-isolation:before, i.ph-currency-circle-dollar.icon-strain:before, i.ph-currency-circle-dollar.icon-gps:before, i.ph-currency-circle-dollar.icon-location:before, i.ph-currency-circle-dollar.icon-add:before, i.ph-currency-circle-dollar.icon-left:before, i.ph-currency-circle-dollar.icon-dna:before, i.ph-currency-circle-dollar.icon-delete:before, i.ph-currency-circle-dollar.icon-edit:before, i.ph-currency-circle-dollar.icon-info:before {
  content: "\e54c";
}

.ph.ph-currency-cny:before, i.ph-currency-cny.icon-dead:before, i.ph-currency-cny.icon-alive:before, i.ph-currency-cny.icon-warn:before, i.ph-currency-cny.icon-sign-out:before, i.ph-currency-cny.icon-substratum:before, i.ph-currency-cny.icon-environment:before, i.ph-currency-cny.icon-user:before, i.ph-currency-cny.icon-person:before, i.ph-currency-cny.icon-terrain:before, i.ph-currency-cny.icon-taxon:before, i.ph-currency-cny.icon-unknown:before, i.ph-currency-cny.icon-nothing:before, i.ph-currency-cny.icon-sampling:before, i.ph-currency-cny.icon-isolation:before, i.ph-currency-cny.icon-strain:before, i.ph-currency-cny.icon-gps:before, i.ph-currency-cny.icon-location:before, i.ph-currency-cny.icon-add:before, i.ph-currency-cny.icon-left:before, i.ph-currency-cny.icon-dna:before, i.ph-currency-cny.icon-delete:before, i.ph-currency-cny.icon-edit:before, i.ph-currency-cny.icon-info:before {
  content: "\e54e";
}

.ph.ph-currency-dollar:before, i.ph-currency-dollar.icon-dead:before, i.ph-currency-dollar.icon-alive:before, i.ph-currency-dollar.icon-warn:before, i.ph-currency-dollar.icon-sign-out:before, i.ph-currency-dollar.icon-substratum:before, i.ph-currency-dollar.icon-environment:before, i.ph-currency-dollar.icon-user:before, i.ph-currency-dollar.icon-person:before, i.ph-currency-dollar.icon-terrain:before, i.ph-currency-dollar.icon-taxon:before, i.ph-currency-dollar.icon-unknown:before, i.ph-currency-dollar.icon-nothing:before, i.ph-currency-dollar.icon-sampling:before, i.ph-currency-dollar.icon-isolation:before, i.ph-currency-dollar.icon-strain:before, i.ph-currency-dollar.icon-gps:before, i.ph-currency-dollar.icon-location:before, i.ph-currency-dollar.icon-add:before, i.ph-currency-dollar.icon-left:before, i.ph-currency-dollar.icon-dna:before, i.ph-currency-dollar.icon-delete:before, i.ph-currency-dollar.icon-edit:before, i.ph-currency-dollar.icon-info:before {
  content: "\e550";
}

.ph.ph-currency-dollar-simple:before, i.ph-currency-dollar-simple.icon-dead:before, i.ph-currency-dollar-simple.icon-alive:before, i.ph-currency-dollar-simple.icon-warn:before, i.ph-currency-dollar-simple.icon-sign-out:before, i.ph-currency-dollar-simple.icon-substratum:before, i.ph-currency-dollar-simple.icon-environment:before, i.ph-currency-dollar-simple.icon-user:before, i.ph-currency-dollar-simple.icon-person:before, i.ph-currency-dollar-simple.icon-terrain:before, i.ph-currency-dollar-simple.icon-taxon:before, i.ph-currency-dollar-simple.icon-unknown:before, i.ph-currency-dollar-simple.icon-nothing:before, i.ph-currency-dollar-simple.icon-sampling:before, i.ph-currency-dollar-simple.icon-isolation:before, i.ph-currency-dollar-simple.icon-strain:before, i.ph-currency-dollar-simple.icon-gps:before, i.ph-currency-dollar-simple.icon-location:before, i.ph-currency-dollar-simple.icon-add:before, i.ph-currency-dollar-simple.icon-left:before, i.ph-currency-dollar-simple.icon-dna:before, i.ph-currency-dollar-simple.icon-delete:before, i.ph-currency-dollar-simple.icon-edit:before, i.ph-currency-dollar-simple.icon-info:before {
  content: "\e552";
}

.ph.ph-currency-eth:before, i.ph-currency-eth.icon-dead:before, i.ph-currency-eth.icon-alive:before, i.ph-currency-eth.icon-warn:before, i.ph-currency-eth.icon-sign-out:before, i.ph-currency-eth.icon-substratum:before, i.ph-currency-eth.icon-environment:before, i.ph-currency-eth.icon-user:before, i.ph-currency-eth.icon-person:before, i.ph-currency-eth.icon-terrain:before, i.ph-currency-eth.icon-taxon:before, i.ph-currency-eth.icon-unknown:before, i.ph-currency-eth.icon-nothing:before, i.ph-currency-eth.icon-sampling:before, i.ph-currency-eth.icon-isolation:before, i.ph-currency-eth.icon-strain:before, i.ph-currency-eth.icon-gps:before, i.ph-currency-eth.icon-location:before, i.ph-currency-eth.icon-add:before, i.ph-currency-eth.icon-left:before, i.ph-currency-eth.icon-dna:before, i.ph-currency-eth.icon-delete:before, i.ph-currency-eth.icon-edit:before, i.ph-currency-eth.icon-info:before {
  content: "\eada";
}

.ph.ph-currency-eur:before, i.ph-currency-eur.icon-dead:before, i.ph-currency-eur.icon-alive:before, i.ph-currency-eur.icon-warn:before, i.ph-currency-eur.icon-sign-out:before, i.ph-currency-eur.icon-substratum:before, i.ph-currency-eur.icon-environment:before, i.ph-currency-eur.icon-user:before, i.ph-currency-eur.icon-person:before, i.ph-currency-eur.icon-terrain:before, i.ph-currency-eur.icon-taxon:before, i.ph-currency-eur.icon-unknown:before, i.ph-currency-eur.icon-nothing:before, i.ph-currency-eur.icon-sampling:before, i.ph-currency-eur.icon-isolation:before, i.ph-currency-eur.icon-strain:before, i.ph-currency-eur.icon-gps:before, i.ph-currency-eur.icon-location:before, i.ph-currency-eur.icon-add:before, i.ph-currency-eur.icon-left:before, i.ph-currency-eur.icon-dna:before, i.ph-currency-eur.icon-delete:before, i.ph-currency-eur.icon-edit:before, i.ph-currency-eur.icon-info:before {
  content: "\e554";
}

.ph.ph-currency-gbp:before, i.ph-currency-gbp.icon-dead:before, i.ph-currency-gbp.icon-alive:before, i.ph-currency-gbp.icon-warn:before, i.ph-currency-gbp.icon-sign-out:before, i.ph-currency-gbp.icon-substratum:before, i.ph-currency-gbp.icon-environment:before, i.ph-currency-gbp.icon-user:before, i.ph-currency-gbp.icon-person:before, i.ph-currency-gbp.icon-terrain:before, i.ph-currency-gbp.icon-taxon:before, i.ph-currency-gbp.icon-unknown:before, i.ph-currency-gbp.icon-nothing:before, i.ph-currency-gbp.icon-sampling:before, i.ph-currency-gbp.icon-isolation:before, i.ph-currency-gbp.icon-strain:before, i.ph-currency-gbp.icon-gps:before, i.ph-currency-gbp.icon-location:before, i.ph-currency-gbp.icon-add:before, i.ph-currency-gbp.icon-left:before, i.ph-currency-gbp.icon-dna:before, i.ph-currency-gbp.icon-delete:before, i.ph-currency-gbp.icon-edit:before, i.ph-currency-gbp.icon-info:before {
  content: "\e556";
}

.ph.ph-currency-inr:before, i.ph-currency-inr.icon-dead:before, i.ph-currency-inr.icon-alive:before, i.ph-currency-inr.icon-warn:before, i.ph-currency-inr.icon-sign-out:before, i.ph-currency-inr.icon-substratum:before, i.ph-currency-inr.icon-environment:before, i.ph-currency-inr.icon-user:before, i.ph-currency-inr.icon-person:before, i.ph-currency-inr.icon-terrain:before, i.ph-currency-inr.icon-taxon:before, i.ph-currency-inr.icon-unknown:before, i.ph-currency-inr.icon-nothing:before, i.ph-currency-inr.icon-sampling:before, i.ph-currency-inr.icon-isolation:before, i.ph-currency-inr.icon-strain:before, i.ph-currency-inr.icon-gps:before, i.ph-currency-inr.icon-location:before, i.ph-currency-inr.icon-add:before, i.ph-currency-inr.icon-left:before, i.ph-currency-inr.icon-dna:before, i.ph-currency-inr.icon-delete:before, i.ph-currency-inr.icon-edit:before, i.ph-currency-inr.icon-info:before {
  content: "\e558";
}

.ph.ph-currency-jpy:before, i.ph-currency-jpy.icon-dead:before, i.ph-currency-jpy.icon-alive:before, i.ph-currency-jpy.icon-warn:before, i.ph-currency-jpy.icon-sign-out:before, i.ph-currency-jpy.icon-substratum:before, i.ph-currency-jpy.icon-environment:before, i.ph-currency-jpy.icon-user:before, i.ph-currency-jpy.icon-person:before, i.ph-currency-jpy.icon-terrain:before, i.ph-currency-jpy.icon-taxon:before, i.ph-currency-jpy.icon-unknown:before, i.ph-currency-jpy.icon-nothing:before, i.ph-currency-jpy.icon-sampling:before, i.ph-currency-jpy.icon-isolation:before, i.ph-currency-jpy.icon-strain:before, i.ph-currency-jpy.icon-gps:before, i.ph-currency-jpy.icon-location:before, i.ph-currency-jpy.icon-add:before, i.ph-currency-jpy.icon-left:before, i.ph-currency-jpy.icon-dna:before, i.ph-currency-jpy.icon-delete:before, i.ph-currency-jpy.icon-edit:before, i.ph-currency-jpy.icon-info:before {
  content: "\e55a";
}

.ph.ph-currency-krw:before, i.ph-currency-krw.icon-dead:before, i.ph-currency-krw.icon-alive:before, i.ph-currency-krw.icon-warn:before, i.ph-currency-krw.icon-sign-out:before, i.ph-currency-krw.icon-substratum:before, i.ph-currency-krw.icon-environment:before, i.ph-currency-krw.icon-user:before, i.ph-currency-krw.icon-person:before, i.ph-currency-krw.icon-terrain:before, i.ph-currency-krw.icon-taxon:before, i.ph-currency-krw.icon-unknown:before, i.ph-currency-krw.icon-nothing:before, i.ph-currency-krw.icon-sampling:before, i.ph-currency-krw.icon-isolation:before, i.ph-currency-krw.icon-strain:before, i.ph-currency-krw.icon-gps:before, i.ph-currency-krw.icon-location:before, i.ph-currency-krw.icon-add:before, i.ph-currency-krw.icon-left:before, i.ph-currency-krw.icon-dna:before, i.ph-currency-krw.icon-delete:before, i.ph-currency-krw.icon-edit:before, i.ph-currency-krw.icon-info:before {
  content: "\e55c";
}

.ph.ph-currency-kzt:before, i.ph-currency-kzt.icon-dead:before, i.ph-currency-kzt.icon-alive:before, i.ph-currency-kzt.icon-warn:before, i.ph-currency-kzt.icon-sign-out:before, i.ph-currency-kzt.icon-substratum:before, i.ph-currency-kzt.icon-environment:before, i.ph-currency-kzt.icon-user:before, i.ph-currency-kzt.icon-person:before, i.ph-currency-kzt.icon-terrain:before, i.ph-currency-kzt.icon-taxon:before, i.ph-currency-kzt.icon-unknown:before, i.ph-currency-kzt.icon-nothing:before, i.ph-currency-kzt.icon-sampling:before, i.ph-currency-kzt.icon-isolation:before, i.ph-currency-kzt.icon-strain:before, i.ph-currency-kzt.icon-gps:before, i.ph-currency-kzt.icon-location:before, i.ph-currency-kzt.icon-add:before, i.ph-currency-kzt.icon-left:before, i.ph-currency-kzt.icon-dna:before, i.ph-currency-kzt.icon-delete:before, i.ph-currency-kzt.icon-edit:before, i.ph-currency-kzt.icon-info:before {
  content: "\ec4c";
}

.ph.ph-currency-ngn:before, i.ph-currency-ngn.icon-dead:before, i.ph-currency-ngn.icon-alive:before, i.ph-currency-ngn.icon-warn:before, i.ph-currency-ngn.icon-sign-out:before, i.ph-currency-ngn.icon-substratum:before, i.ph-currency-ngn.icon-environment:before, i.ph-currency-ngn.icon-user:before, i.ph-currency-ngn.icon-person:before, i.ph-currency-ngn.icon-terrain:before, i.ph-currency-ngn.icon-taxon:before, i.ph-currency-ngn.icon-unknown:before, i.ph-currency-ngn.icon-nothing:before, i.ph-currency-ngn.icon-sampling:before, i.ph-currency-ngn.icon-isolation:before, i.ph-currency-ngn.icon-strain:before, i.ph-currency-ngn.icon-gps:before, i.ph-currency-ngn.icon-location:before, i.ph-currency-ngn.icon-add:before, i.ph-currency-ngn.icon-left:before, i.ph-currency-ngn.icon-dna:before, i.ph-currency-ngn.icon-delete:before, i.ph-currency-ngn.icon-edit:before, i.ph-currency-ngn.icon-info:before {
  content: "\eb52";
}

.ph.ph-currency-rub:before, i.ph-currency-rub.icon-dead:before, i.ph-currency-rub.icon-alive:before, i.ph-currency-rub.icon-warn:before, i.ph-currency-rub.icon-sign-out:before, i.ph-currency-rub.icon-substratum:before, i.ph-currency-rub.icon-environment:before, i.ph-currency-rub.icon-user:before, i.ph-currency-rub.icon-person:before, i.ph-currency-rub.icon-terrain:before, i.ph-currency-rub.icon-taxon:before, i.ph-currency-rub.icon-unknown:before, i.ph-currency-rub.icon-nothing:before, i.ph-currency-rub.icon-sampling:before, i.ph-currency-rub.icon-isolation:before, i.ph-currency-rub.icon-strain:before, i.ph-currency-rub.icon-gps:before, i.ph-currency-rub.icon-location:before, i.ph-currency-rub.icon-add:before, i.ph-currency-rub.icon-left:before, i.ph-currency-rub.icon-dna:before, i.ph-currency-rub.icon-delete:before, i.ph-currency-rub.icon-edit:before, i.ph-currency-rub.icon-info:before {
  content: "\e55e";
}

.ph.ph-cursor:before, i.ph-cursor.icon-dead:before, i.ph-cursor.icon-alive:before, i.ph-cursor.icon-warn:before, i.ph-cursor.icon-sign-out:before, i.ph-cursor.icon-substratum:before, i.ph-cursor.icon-environment:before, i.ph-cursor.icon-user:before, i.ph-cursor.icon-person:before, i.ph-cursor.icon-terrain:before, i.ph-cursor.icon-taxon:before, i.ph-cursor.icon-unknown:before, i.ph-cursor.icon-nothing:before, i.ph-cursor.icon-sampling:before, i.ph-cursor.icon-isolation:before, i.ph-cursor.icon-strain:before, i.ph-cursor.icon-gps:before, i.ph-cursor.icon-location:before, i.ph-cursor.icon-add:before, i.ph-cursor.icon-left:before, i.ph-cursor.icon-dna:before, i.ph-cursor.icon-delete:before, i.ph-cursor.icon-edit:before, i.ph-cursor.icon-info:before {
  content: "\e1dc";
}

.ph.ph-cursor-click:before, i.ph-cursor-click.icon-dead:before, i.ph-cursor-click.icon-alive:before, i.ph-cursor-click.icon-warn:before, i.ph-cursor-click.icon-sign-out:before, i.ph-cursor-click.icon-substratum:before, i.ph-cursor-click.icon-environment:before, i.ph-cursor-click.icon-user:before, i.ph-cursor-click.icon-person:before, i.ph-cursor-click.icon-terrain:before, i.ph-cursor-click.icon-taxon:before, i.ph-cursor-click.icon-unknown:before, i.ph-cursor-click.icon-nothing:before, i.ph-cursor-click.icon-sampling:before, i.ph-cursor-click.icon-isolation:before, i.ph-cursor-click.icon-strain:before, i.ph-cursor-click.icon-gps:before, i.ph-cursor-click.icon-location:before, i.ph-cursor-click.icon-add:before, i.ph-cursor-click.icon-left:before, i.ph-cursor-click.icon-dna:before, i.ph-cursor-click.icon-delete:before, i.ph-cursor-click.icon-edit:before, i.ph-cursor-click.icon-info:before {
  content: "\e7c8";
}

.ph.ph-cursor-text:before, i.ph-cursor-text.icon-dead:before, i.ph-cursor-text.icon-alive:before, i.ph-cursor-text.icon-warn:before, i.ph-cursor-text.icon-sign-out:before, i.ph-cursor-text.icon-substratum:before, i.ph-cursor-text.icon-environment:before, i.ph-cursor-text.icon-user:before, i.ph-cursor-text.icon-person:before, i.ph-cursor-text.icon-terrain:before, i.ph-cursor-text.icon-taxon:before, i.ph-cursor-text.icon-unknown:before, i.ph-cursor-text.icon-nothing:before, i.ph-cursor-text.icon-sampling:before, i.ph-cursor-text.icon-isolation:before, i.ph-cursor-text.icon-strain:before, i.ph-cursor-text.icon-gps:before, i.ph-cursor-text.icon-location:before, i.ph-cursor-text.icon-add:before, i.ph-cursor-text.icon-left:before, i.ph-cursor-text.icon-dna:before, i.ph-cursor-text.icon-delete:before, i.ph-cursor-text.icon-edit:before, i.ph-cursor-text.icon-info:before {
  content: "\e7d8";
}

.ph.ph-cylinder:before, i.ph-cylinder.icon-dead:before, i.ph-cylinder.icon-alive:before, i.ph-cylinder.icon-warn:before, i.ph-cylinder.icon-sign-out:before, i.ph-cylinder.icon-substratum:before, i.ph-cylinder.icon-environment:before, i.ph-cylinder.icon-user:before, i.ph-cylinder.icon-person:before, i.ph-cylinder.icon-terrain:before, i.ph-cylinder.icon-taxon:before, i.ph-cylinder.icon-unknown:before, i.ph-cylinder.icon-nothing:before, i.ph-cylinder.icon-sampling:before, i.ph-cylinder.icon-isolation:before, i.ph-cylinder.icon-strain:before, i.ph-cylinder.icon-gps:before, i.ph-cylinder.icon-location:before, i.ph-cylinder.icon-add:before, i.ph-cylinder.icon-left:before, i.ph-cylinder.icon-dna:before, i.ph-cylinder.icon-delete:before, i.ph-cylinder.icon-edit:before, i.ph-cylinder.icon-info:before {
  content: "\e8fc";
}

.ph.ph-database:before, i.ph-database.icon-dead:before, i.ph-database.icon-alive:before, i.ph-database.icon-warn:before, i.ph-database.icon-sign-out:before, i.ph-database.icon-substratum:before, i.ph-database.icon-environment:before, i.ph-database.icon-user:before, i.ph-database.icon-person:before, i.ph-database.icon-terrain:before, i.ph-database.icon-taxon:before, i.ph-database.icon-unknown:before, i.ph-database.icon-nothing:before, i.ph-database.icon-sampling:before, i.ph-database.icon-isolation:before, i.ph-database.icon-strain:before, i.ph-database.icon-gps:before, i.ph-database.icon-location:before, i.ph-database.icon-add:before, i.ph-database.icon-left:before, i.ph-database.icon-dna:before, i.ph-database.icon-delete:before, i.ph-database.icon-edit:before, i.ph-database.icon-info:before {
  content: "\e1de";
}

.ph.ph-desk:before, i.ph-desk.icon-dead:before, i.ph-desk.icon-alive:before, i.ph-desk.icon-warn:before, i.ph-desk.icon-sign-out:before, i.ph-desk.icon-substratum:before, i.ph-desk.icon-environment:before, i.ph-desk.icon-user:before, i.ph-desk.icon-person:before, i.ph-desk.icon-terrain:before, i.ph-desk.icon-taxon:before, i.ph-desk.icon-unknown:before, i.ph-desk.icon-nothing:before, i.ph-desk.icon-sampling:before, i.ph-desk.icon-isolation:before, i.ph-desk.icon-strain:before, i.ph-desk.icon-gps:before, i.ph-desk.icon-location:before, i.ph-desk.icon-add:before, i.ph-desk.icon-left:before, i.ph-desk.icon-dna:before, i.ph-desk.icon-delete:before, i.ph-desk.icon-edit:before, i.ph-desk.icon-info:before {
  content: "\ed16";
}

.ph.ph-desktop:before, i.ph-desktop.icon-dead:before, i.ph-desktop.icon-alive:before, i.ph-desktop.icon-warn:before, i.ph-desktop.icon-sign-out:before, i.ph-desktop.icon-substratum:before, i.ph-desktop.icon-environment:before, i.ph-desktop.icon-user:before, i.ph-desktop.icon-person:before, i.ph-desktop.icon-terrain:before, i.ph-desktop.icon-taxon:before, i.ph-desktop.icon-unknown:before, i.ph-desktop.icon-nothing:before, i.ph-desktop.icon-sampling:before, i.ph-desktop.icon-isolation:before, i.ph-desktop.icon-strain:before, i.ph-desktop.icon-gps:before, i.ph-desktop.icon-location:before, i.ph-desktop.icon-add:before, i.ph-desktop.icon-left:before, i.ph-desktop.icon-dna:before, i.ph-desktop.icon-delete:before, i.ph-desktop.icon-edit:before, i.ph-desktop.icon-info:before {
  content: "\e560";
}

.ph.ph-desktop-tower:before, i.ph-desktop-tower.icon-dead:before, i.ph-desktop-tower.icon-alive:before, i.ph-desktop-tower.icon-warn:before, i.ph-desktop-tower.icon-sign-out:before, i.ph-desktop-tower.icon-substratum:before, i.ph-desktop-tower.icon-environment:before, i.ph-desktop-tower.icon-user:before, i.ph-desktop-tower.icon-person:before, i.ph-desktop-tower.icon-terrain:before, i.ph-desktop-tower.icon-taxon:before, i.ph-desktop-tower.icon-unknown:before, i.ph-desktop-tower.icon-nothing:before, i.ph-desktop-tower.icon-sampling:before, i.ph-desktop-tower.icon-isolation:before, i.ph-desktop-tower.icon-strain:before, i.ph-desktop-tower.icon-gps:before, i.ph-desktop-tower.icon-location:before, i.ph-desktop-tower.icon-add:before, i.ph-desktop-tower.icon-left:before, i.ph-desktop-tower.icon-dna:before, i.ph-desktop-tower.icon-delete:before, i.ph-desktop-tower.icon-edit:before, i.ph-desktop-tower.icon-info:before {
  content: "\e562";
}

.ph.ph-detective:before, i.ph-detective.icon-dead:before, i.ph-detective.icon-alive:before, i.ph-detective.icon-warn:before, i.ph-detective.icon-sign-out:before, i.ph-detective.icon-substratum:before, i.ph-detective.icon-environment:before, i.ph-detective.icon-user:before, i.ph-detective.icon-person:before, i.ph-detective.icon-terrain:before, i.ph-detective.icon-taxon:before, i.ph-detective.icon-unknown:before, i.ph-detective.icon-nothing:before, i.ph-detective.icon-sampling:before, i.ph-detective.icon-isolation:before, i.ph-detective.icon-strain:before, i.ph-detective.icon-gps:before, i.ph-detective.icon-location:before, i.ph-detective.icon-add:before, i.ph-detective.icon-left:before, i.ph-detective.icon-dna:before, i.ph-detective.icon-delete:before, i.ph-detective.icon-edit:before, i.ph-detective.icon-info:before {
  content: "\e83e";
}

.ph.ph-dev-to-logo:before, i.ph-dev-to-logo.icon-dead:before, i.ph-dev-to-logo.icon-alive:before, i.ph-dev-to-logo.icon-warn:before, i.ph-dev-to-logo.icon-sign-out:before, i.ph-dev-to-logo.icon-substratum:before, i.ph-dev-to-logo.icon-environment:before, i.ph-dev-to-logo.icon-user:before, i.ph-dev-to-logo.icon-person:before, i.ph-dev-to-logo.icon-terrain:before, i.ph-dev-to-logo.icon-taxon:before, i.ph-dev-to-logo.icon-unknown:before, i.ph-dev-to-logo.icon-nothing:before, i.ph-dev-to-logo.icon-sampling:before, i.ph-dev-to-logo.icon-isolation:before, i.ph-dev-to-logo.icon-strain:before, i.ph-dev-to-logo.icon-gps:before, i.ph-dev-to-logo.icon-location:before, i.ph-dev-to-logo.icon-add:before, i.ph-dev-to-logo.icon-left:before, i.ph-dev-to-logo.icon-dna:before, i.ph-dev-to-logo.icon-delete:before, i.ph-dev-to-logo.icon-edit:before, i.ph-dev-to-logo.icon-info:before {
  content: "\ed0e";
}

.ph.ph-device-mobile:before, i.ph-device-mobile.icon-dead:before, i.ph-device-mobile.icon-alive:before, i.ph-device-mobile.icon-warn:before, i.ph-device-mobile.icon-sign-out:before, i.ph-device-mobile.icon-substratum:before, i.ph-device-mobile.icon-environment:before, i.ph-device-mobile.icon-user:before, i.ph-device-mobile.icon-person:before, i.ph-device-mobile.icon-terrain:before, i.ph-device-mobile.icon-taxon:before, i.ph-device-mobile.icon-unknown:before, i.ph-device-mobile.icon-nothing:before, i.ph-device-mobile.icon-sampling:before, i.ph-device-mobile.icon-isolation:before, i.ph-device-mobile.icon-strain:before, i.ph-device-mobile.icon-gps:before, i.ph-device-mobile.icon-location:before, i.ph-device-mobile.icon-add:before, i.ph-device-mobile.icon-left:before, i.ph-device-mobile.icon-dna:before, i.ph-device-mobile.icon-delete:before, i.ph-device-mobile.icon-edit:before, i.ph-device-mobile.icon-info:before {
  content: "\e1e0";
}

.ph.ph-device-mobile-camera:before, i.ph-device-mobile-camera.icon-dead:before, i.ph-device-mobile-camera.icon-alive:before, i.ph-device-mobile-camera.icon-warn:before, i.ph-device-mobile-camera.icon-sign-out:before, i.ph-device-mobile-camera.icon-substratum:before, i.ph-device-mobile-camera.icon-environment:before, i.ph-device-mobile-camera.icon-user:before, i.ph-device-mobile-camera.icon-person:before, i.ph-device-mobile-camera.icon-terrain:before, i.ph-device-mobile-camera.icon-taxon:before, i.ph-device-mobile-camera.icon-unknown:before, i.ph-device-mobile-camera.icon-nothing:before, i.ph-device-mobile-camera.icon-sampling:before, i.ph-device-mobile-camera.icon-isolation:before, i.ph-device-mobile-camera.icon-strain:before, i.ph-device-mobile-camera.icon-gps:before, i.ph-device-mobile-camera.icon-location:before, i.ph-device-mobile-camera.icon-add:before, i.ph-device-mobile-camera.icon-left:before, i.ph-device-mobile-camera.icon-dna:before, i.ph-device-mobile-camera.icon-delete:before, i.ph-device-mobile-camera.icon-edit:before, i.ph-device-mobile-camera.icon-info:before {
  content: "\e1e2";
}

.ph.ph-device-mobile-slash:before, i.ph-device-mobile-slash.icon-dead:before, i.ph-device-mobile-slash.icon-alive:before, i.ph-device-mobile-slash.icon-warn:before, i.ph-device-mobile-slash.icon-sign-out:before, i.ph-device-mobile-slash.icon-substratum:before, i.ph-device-mobile-slash.icon-environment:before, i.ph-device-mobile-slash.icon-user:before, i.ph-device-mobile-slash.icon-person:before, i.ph-device-mobile-slash.icon-terrain:before, i.ph-device-mobile-slash.icon-taxon:before, i.ph-device-mobile-slash.icon-unknown:before, i.ph-device-mobile-slash.icon-nothing:before, i.ph-device-mobile-slash.icon-sampling:before, i.ph-device-mobile-slash.icon-isolation:before, i.ph-device-mobile-slash.icon-strain:before, i.ph-device-mobile-slash.icon-gps:before, i.ph-device-mobile-slash.icon-location:before, i.ph-device-mobile-slash.icon-add:before, i.ph-device-mobile-slash.icon-left:before, i.ph-device-mobile-slash.icon-dna:before, i.ph-device-mobile-slash.icon-delete:before, i.ph-device-mobile-slash.icon-edit:before, i.ph-device-mobile-slash.icon-info:before {
  content: "\ee46";
}

.ph.ph-device-mobile-speaker:before, i.ph-device-mobile-speaker.icon-dead:before, i.ph-device-mobile-speaker.icon-alive:before, i.ph-device-mobile-speaker.icon-warn:before, i.ph-device-mobile-speaker.icon-sign-out:before, i.ph-device-mobile-speaker.icon-substratum:before, i.ph-device-mobile-speaker.icon-environment:before, i.ph-device-mobile-speaker.icon-user:before, i.ph-device-mobile-speaker.icon-person:before, i.ph-device-mobile-speaker.icon-terrain:before, i.ph-device-mobile-speaker.icon-taxon:before, i.ph-device-mobile-speaker.icon-unknown:before, i.ph-device-mobile-speaker.icon-nothing:before, i.ph-device-mobile-speaker.icon-sampling:before, i.ph-device-mobile-speaker.icon-isolation:before, i.ph-device-mobile-speaker.icon-strain:before, i.ph-device-mobile-speaker.icon-gps:before, i.ph-device-mobile-speaker.icon-location:before, i.ph-device-mobile-speaker.icon-add:before, i.ph-device-mobile-speaker.icon-left:before, i.ph-device-mobile-speaker.icon-dna:before, i.ph-device-mobile-speaker.icon-delete:before, i.ph-device-mobile-speaker.icon-edit:before, i.ph-device-mobile-speaker.icon-info:before {
  content: "\e1e4";
}

.ph.ph-device-rotate:before, i.ph-device-rotate.icon-dead:before, i.ph-device-rotate.icon-alive:before, i.ph-device-rotate.icon-warn:before, i.ph-device-rotate.icon-sign-out:before, i.ph-device-rotate.icon-substratum:before, i.ph-device-rotate.icon-environment:before, i.ph-device-rotate.icon-user:before, i.ph-device-rotate.icon-person:before, i.ph-device-rotate.icon-terrain:before, i.ph-device-rotate.icon-taxon:before, i.ph-device-rotate.icon-unknown:before, i.ph-device-rotate.icon-nothing:before, i.ph-device-rotate.icon-sampling:before, i.ph-device-rotate.icon-isolation:before, i.ph-device-rotate.icon-strain:before, i.ph-device-rotate.icon-gps:before, i.ph-device-rotate.icon-location:before, i.ph-device-rotate.icon-add:before, i.ph-device-rotate.icon-left:before, i.ph-device-rotate.icon-dna:before, i.ph-device-rotate.icon-delete:before, i.ph-device-rotate.icon-edit:before, i.ph-device-rotate.icon-info:before {
  content: "\edf2";
}

.ph.ph-device-tablet:before, i.ph-device-tablet.icon-dead:before, i.ph-device-tablet.icon-alive:before, i.ph-device-tablet.icon-warn:before, i.ph-device-tablet.icon-sign-out:before, i.ph-device-tablet.icon-substratum:before, i.ph-device-tablet.icon-environment:before, i.ph-device-tablet.icon-user:before, i.ph-device-tablet.icon-person:before, i.ph-device-tablet.icon-terrain:before, i.ph-device-tablet.icon-taxon:before, i.ph-device-tablet.icon-unknown:before, i.ph-device-tablet.icon-nothing:before, i.ph-device-tablet.icon-sampling:before, i.ph-device-tablet.icon-isolation:before, i.ph-device-tablet.icon-strain:before, i.ph-device-tablet.icon-gps:before, i.ph-device-tablet.icon-location:before, i.ph-device-tablet.icon-add:before, i.ph-device-tablet.icon-left:before, i.ph-device-tablet.icon-dna:before, i.ph-device-tablet.icon-delete:before, i.ph-device-tablet.icon-edit:before, i.ph-device-tablet.icon-info:before {
  content: "\e1e6";
}

.ph.ph-device-tablet-camera:before, i.ph-device-tablet-camera.icon-dead:before, i.ph-device-tablet-camera.icon-alive:before, i.ph-device-tablet-camera.icon-warn:before, i.ph-device-tablet-camera.icon-sign-out:before, i.ph-device-tablet-camera.icon-substratum:before, i.ph-device-tablet-camera.icon-environment:before, i.ph-device-tablet-camera.icon-user:before, i.ph-device-tablet-camera.icon-person:before, i.ph-device-tablet-camera.icon-terrain:before, i.ph-device-tablet-camera.icon-taxon:before, i.ph-device-tablet-camera.icon-unknown:before, i.ph-device-tablet-camera.icon-nothing:before, i.ph-device-tablet-camera.icon-sampling:before, i.ph-device-tablet-camera.icon-isolation:before, i.ph-device-tablet-camera.icon-strain:before, i.ph-device-tablet-camera.icon-gps:before, i.ph-device-tablet-camera.icon-location:before, i.ph-device-tablet-camera.icon-add:before, i.ph-device-tablet-camera.icon-left:before, i.ph-device-tablet-camera.icon-dna:before, i.ph-device-tablet-camera.icon-delete:before, i.ph-device-tablet-camera.icon-edit:before, i.ph-device-tablet-camera.icon-info:before {
  content: "\e1e8";
}

.ph.ph-device-tablet-speaker:before, i.ph-device-tablet-speaker.icon-dead:before, i.ph-device-tablet-speaker.icon-alive:before, i.ph-device-tablet-speaker.icon-warn:before, i.ph-device-tablet-speaker.icon-sign-out:before, i.ph-device-tablet-speaker.icon-substratum:before, i.ph-device-tablet-speaker.icon-environment:before, i.ph-device-tablet-speaker.icon-user:before, i.ph-device-tablet-speaker.icon-person:before, i.ph-device-tablet-speaker.icon-terrain:before, i.ph-device-tablet-speaker.icon-taxon:before, i.ph-device-tablet-speaker.icon-unknown:before, i.ph-device-tablet-speaker.icon-nothing:before, i.ph-device-tablet-speaker.icon-sampling:before, i.ph-device-tablet-speaker.icon-isolation:before, i.ph-device-tablet-speaker.icon-strain:before, i.ph-device-tablet-speaker.icon-gps:before, i.ph-device-tablet-speaker.icon-location:before, i.ph-device-tablet-speaker.icon-add:before, i.ph-device-tablet-speaker.icon-left:before, i.ph-device-tablet-speaker.icon-dna:before, i.ph-device-tablet-speaker.icon-delete:before, i.ph-device-tablet-speaker.icon-edit:before, i.ph-device-tablet-speaker.icon-info:before {
  content: "\e1ea";
}

.ph.ph-devices:before, i.ph-devices.icon-dead:before, i.ph-devices.icon-alive:before, i.ph-devices.icon-warn:before, i.ph-devices.icon-sign-out:before, i.ph-devices.icon-substratum:before, i.ph-devices.icon-environment:before, i.ph-devices.icon-user:before, i.ph-devices.icon-person:before, i.ph-devices.icon-terrain:before, i.ph-devices.icon-taxon:before, i.ph-devices.icon-unknown:before, i.ph-devices.icon-nothing:before, i.ph-devices.icon-sampling:before, i.ph-devices.icon-isolation:before, i.ph-devices.icon-strain:before, i.ph-devices.icon-gps:before, i.ph-devices.icon-location:before, i.ph-devices.icon-add:before, i.ph-devices.icon-left:before, i.ph-devices.icon-dna:before, i.ph-devices.icon-delete:before, i.ph-devices.icon-edit:before, i.ph-devices.icon-info:before {
  content: "\eba4";
}

.ph.ph-diamond:before, i.ph-diamond.icon-dead:before, i.ph-diamond.icon-alive:before, i.ph-diamond.icon-warn:before, i.ph-diamond.icon-sign-out:before, i.ph-diamond.icon-substratum:before, i.ph-diamond.icon-environment:before, i.ph-diamond.icon-user:before, i.ph-diamond.icon-person:before, i.ph-diamond.icon-terrain:before, i.ph-diamond.icon-taxon:before, i.ph-diamond.icon-unknown:before, i.ph-diamond.icon-nothing:before, i.ph-diamond.icon-sampling:before, i.ph-diamond.icon-isolation:before, i.ph-diamond.icon-strain:before, i.ph-diamond.icon-gps:before, i.ph-diamond.icon-location:before, i.ph-diamond.icon-add:before, i.ph-diamond.icon-left:before, i.ph-diamond.icon-dna:before, i.ph-diamond.icon-delete:before, i.ph-diamond.icon-edit:before, i.ph-diamond.icon-info:before {
  content: "\e1ec";
}

.ph.ph-diamonds-four:before, i.ph-diamonds-four.icon-dead:before, i.ph-diamonds-four.icon-alive:before, i.ph-diamonds-four.icon-warn:before, i.ph-diamonds-four.icon-sign-out:before, i.ph-diamonds-four.icon-substratum:before, i.ph-diamonds-four.icon-environment:before, i.ph-diamonds-four.icon-user:before, i.ph-diamonds-four.icon-person:before, i.ph-diamonds-four.icon-terrain:before, i.ph-diamonds-four.icon-taxon:before, i.ph-diamonds-four.icon-unknown:before, i.ph-diamonds-four.icon-nothing:before, i.ph-diamonds-four.icon-sampling:before, i.ph-diamonds-four.icon-isolation:before, i.ph-diamonds-four.icon-strain:before, i.ph-diamonds-four.icon-gps:before, i.ph-diamonds-four.icon-location:before, i.ph-diamonds-four.icon-add:before, i.ph-diamonds-four.icon-left:before, i.ph-diamonds-four.icon-dna:before, i.ph-diamonds-four.icon-delete:before, i.ph-diamonds-four.icon-edit:before, i.ph-diamonds-four.icon-info:before {
  content: "\e8f4";
}

.ph.ph-dice-five:before, i.ph-dice-five.icon-dead:before, i.ph-dice-five.icon-alive:before, i.ph-dice-five.icon-warn:before, i.ph-dice-five.icon-sign-out:before, i.ph-dice-five.icon-substratum:before, i.ph-dice-five.icon-environment:before, i.ph-dice-five.icon-user:before, i.ph-dice-five.icon-person:before, i.ph-dice-five.icon-terrain:before, i.ph-dice-five.icon-taxon:before, i.ph-dice-five.icon-unknown:before, i.ph-dice-five.icon-nothing:before, i.ph-dice-five.icon-sampling:before, i.ph-dice-five.icon-isolation:before, i.ph-dice-five.icon-strain:before, i.ph-dice-five.icon-gps:before, i.ph-dice-five.icon-location:before, i.ph-dice-five.icon-add:before, i.ph-dice-five.icon-left:before, i.ph-dice-five.icon-dna:before, i.ph-dice-five.icon-delete:before, i.ph-dice-five.icon-edit:before, i.ph-dice-five.icon-info:before {
  content: "\e1ee";
}

.ph.ph-dice-four:before, i.ph-dice-four.icon-dead:before, i.ph-dice-four.icon-alive:before, i.ph-dice-four.icon-warn:before, i.ph-dice-four.icon-sign-out:before, i.ph-dice-four.icon-substratum:before, i.ph-dice-four.icon-environment:before, i.ph-dice-four.icon-user:before, i.ph-dice-four.icon-person:before, i.ph-dice-four.icon-terrain:before, i.ph-dice-four.icon-taxon:before, i.ph-dice-four.icon-unknown:before, i.ph-dice-four.icon-nothing:before, i.ph-dice-four.icon-sampling:before, i.ph-dice-four.icon-isolation:before, i.ph-dice-four.icon-strain:before, i.ph-dice-four.icon-gps:before, i.ph-dice-four.icon-location:before, i.ph-dice-four.icon-add:before, i.ph-dice-four.icon-left:before, i.ph-dice-four.icon-dna:before, i.ph-dice-four.icon-delete:before, i.ph-dice-four.icon-edit:before, i.ph-dice-four.icon-info:before {
  content: "\e1f0";
}

.ph.ph-dice-one:before, i.ph-dice-one.icon-dead:before, i.ph-dice-one.icon-alive:before, i.ph-dice-one.icon-warn:before, i.ph-dice-one.icon-sign-out:before, i.ph-dice-one.icon-substratum:before, i.ph-dice-one.icon-environment:before, i.ph-dice-one.icon-user:before, i.ph-dice-one.icon-person:before, i.ph-dice-one.icon-terrain:before, i.ph-dice-one.icon-taxon:before, i.ph-dice-one.icon-unknown:before, i.ph-dice-one.icon-nothing:before, i.ph-dice-one.icon-sampling:before, i.ph-dice-one.icon-isolation:before, i.ph-dice-one.icon-strain:before, i.ph-dice-one.icon-gps:before, i.ph-dice-one.icon-location:before, i.ph-dice-one.icon-add:before, i.ph-dice-one.icon-left:before, i.ph-dice-one.icon-dna:before, i.ph-dice-one.icon-delete:before, i.ph-dice-one.icon-edit:before, i.ph-dice-one.icon-info:before {
  content: "\e1f2";
}

.ph.ph-dice-six:before, i.ph-dice-six.icon-dead:before, i.ph-dice-six.icon-alive:before, i.ph-dice-six.icon-warn:before, i.ph-dice-six.icon-sign-out:before, i.ph-dice-six.icon-substratum:before, i.ph-dice-six.icon-environment:before, i.ph-dice-six.icon-user:before, i.ph-dice-six.icon-person:before, i.ph-dice-six.icon-terrain:before, i.ph-dice-six.icon-taxon:before, i.ph-dice-six.icon-unknown:before, i.ph-dice-six.icon-nothing:before, i.ph-dice-six.icon-sampling:before, i.ph-dice-six.icon-isolation:before, i.ph-dice-six.icon-strain:before, i.ph-dice-six.icon-gps:before, i.ph-dice-six.icon-location:before, i.ph-dice-six.icon-add:before, i.ph-dice-six.icon-left:before, i.ph-dice-six.icon-dna:before, i.ph-dice-six.icon-delete:before, i.ph-dice-six.icon-edit:before, i.ph-dice-six.icon-info:before {
  content: "\e1f4";
}

.ph.ph-dice-three:before, i.ph-dice-three.icon-dead:before, i.ph-dice-three.icon-alive:before, i.ph-dice-three.icon-warn:before, i.ph-dice-three.icon-sign-out:before, i.ph-dice-three.icon-substratum:before, i.ph-dice-three.icon-environment:before, i.ph-dice-three.icon-user:before, i.ph-dice-three.icon-person:before, i.ph-dice-three.icon-terrain:before, i.ph-dice-three.icon-taxon:before, i.ph-dice-three.icon-unknown:before, i.ph-dice-three.icon-nothing:before, i.ph-dice-three.icon-sampling:before, i.ph-dice-three.icon-isolation:before, i.ph-dice-three.icon-strain:before, i.ph-dice-three.icon-gps:before, i.ph-dice-three.icon-location:before, i.ph-dice-three.icon-add:before, i.ph-dice-three.icon-left:before, i.ph-dice-three.icon-dna:before, i.ph-dice-three.icon-delete:before, i.ph-dice-three.icon-edit:before, i.ph-dice-three.icon-info:before {
  content: "\e1f6";
}

.ph.ph-dice-two:before, i.ph-dice-two.icon-dead:before, i.ph-dice-two.icon-alive:before, i.ph-dice-two.icon-warn:before, i.ph-dice-two.icon-sign-out:before, i.ph-dice-two.icon-substratum:before, i.ph-dice-two.icon-environment:before, i.ph-dice-two.icon-user:before, i.ph-dice-two.icon-person:before, i.ph-dice-two.icon-terrain:before, i.ph-dice-two.icon-taxon:before, i.ph-dice-two.icon-unknown:before, i.ph-dice-two.icon-nothing:before, i.ph-dice-two.icon-sampling:before, i.ph-dice-two.icon-isolation:before, i.ph-dice-two.icon-strain:before, i.ph-dice-two.icon-gps:before, i.ph-dice-two.icon-location:before, i.ph-dice-two.icon-add:before, i.ph-dice-two.icon-left:before, i.ph-dice-two.icon-dna:before, i.ph-dice-two.icon-delete:before, i.ph-dice-two.icon-edit:before, i.ph-dice-two.icon-info:before {
  content: "\e1f8";
}

.ph.ph-disc:before, i.ph-disc.icon-dead:before, i.ph-disc.icon-alive:before, i.ph-disc.icon-warn:before, i.ph-disc.icon-sign-out:before, i.ph-disc.icon-substratum:before, i.ph-disc.icon-environment:before, i.ph-disc.icon-user:before, i.ph-disc.icon-person:before, i.ph-disc.icon-terrain:before, i.ph-disc.icon-taxon:before, i.ph-disc.icon-unknown:before, i.ph-disc.icon-nothing:before, i.ph-disc.icon-sampling:before, i.ph-disc.icon-isolation:before, i.ph-disc.icon-strain:before, i.ph-disc.icon-gps:before, i.ph-disc.icon-location:before, i.ph-disc.icon-add:before, i.ph-disc.icon-left:before, i.ph-disc.icon-dna:before, i.ph-disc.icon-delete:before, i.ph-disc.icon-edit:before, i.ph-disc.icon-info:before {
  content: "\e564";
}

.ph.ph-disco-ball:before, i.ph-disco-ball.icon-dead:before, i.ph-disco-ball.icon-alive:before, i.ph-disco-ball.icon-warn:before, i.ph-disco-ball.icon-sign-out:before, i.ph-disco-ball.icon-substratum:before, i.ph-disco-ball.icon-environment:before, i.ph-disco-ball.icon-user:before, i.ph-disco-ball.icon-person:before, i.ph-disco-ball.icon-terrain:before, i.ph-disco-ball.icon-taxon:before, i.ph-disco-ball.icon-unknown:before, i.ph-disco-ball.icon-nothing:before, i.ph-disco-ball.icon-sampling:before, i.ph-disco-ball.icon-isolation:before, i.ph-disco-ball.icon-strain:before, i.ph-disco-ball.icon-gps:before, i.ph-disco-ball.icon-location:before, i.ph-disco-ball.icon-add:before, i.ph-disco-ball.icon-left:before, i.ph-disco-ball.icon-dna:before, i.ph-disco-ball.icon-delete:before, i.ph-disco-ball.icon-edit:before, i.ph-disco-ball.icon-info:before {
  content: "\ed98";
}

.ph.ph-discord-logo:before, i.ph-discord-logo.icon-dead:before, i.ph-discord-logo.icon-alive:before, i.ph-discord-logo.icon-warn:before, i.ph-discord-logo.icon-sign-out:before, i.ph-discord-logo.icon-substratum:before, i.ph-discord-logo.icon-environment:before, i.ph-discord-logo.icon-user:before, i.ph-discord-logo.icon-person:before, i.ph-discord-logo.icon-terrain:before, i.ph-discord-logo.icon-taxon:before, i.ph-discord-logo.icon-unknown:before, i.ph-discord-logo.icon-nothing:before, i.ph-discord-logo.icon-sampling:before, i.ph-discord-logo.icon-isolation:before, i.ph-discord-logo.icon-strain:before, i.ph-discord-logo.icon-gps:before, i.ph-discord-logo.icon-location:before, i.ph-discord-logo.icon-add:before, i.ph-discord-logo.icon-left:before, i.ph-discord-logo.icon-dna:before, i.ph-discord-logo.icon-delete:before, i.ph-discord-logo.icon-edit:before, i.ph-discord-logo.icon-info:before {
  content: "\e61a";
}

.ph.ph-divide:before, i.ph-divide.icon-dead:before, i.ph-divide.icon-alive:before, i.ph-divide.icon-warn:before, i.ph-divide.icon-sign-out:before, i.ph-divide.icon-substratum:before, i.ph-divide.icon-environment:before, i.ph-divide.icon-user:before, i.ph-divide.icon-person:before, i.ph-divide.icon-terrain:before, i.ph-divide.icon-taxon:before, i.ph-divide.icon-unknown:before, i.ph-divide.icon-nothing:before, i.ph-divide.icon-sampling:before, i.ph-divide.icon-isolation:before, i.ph-divide.icon-strain:before, i.ph-divide.icon-gps:before, i.ph-divide.icon-location:before, i.ph-divide.icon-add:before, i.ph-divide.icon-left:before, i.ph-divide.icon-dna:before, i.ph-divide.icon-delete:before, i.ph-divide.icon-edit:before, i.ph-divide.icon-info:before {
  content: "\e1fa";
}

.ph.ph-dna:before, i.ph-dna.icon-dead:before, i.ph-dna.icon-alive:before, i.ph-dna.icon-warn:before, i.ph-dna.icon-sign-out:before, i.ph-dna.icon-substratum:before, i.ph-dna.icon-environment:before, i.ph-dna.icon-user:before, i.ph-dna.icon-person:before, i.ph-dna.icon-terrain:before, i.ph-dna.icon-taxon:before, i.ph-dna.icon-unknown:before, i.ph-dna.icon-nothing:before, i.ph-dna.icon-sampling:before, i.ph-dna.icon-isolation:before, i.ph-dna.icon-strain:before, i.ph-dna.icon-gps:before, i.ph-dna.icon-location:before, i.ph-dna.icon-add:before, i.ph-dna.icon-left:before, i.icon-dna:before, i.ph-dna.icon-delete:before, i.ph-dna.icon-edit:before, i.ph-dna.icon-info:before {
  content: "\e924";
}

.ph.ph-dog:before, i.ph-dog.icon-dead:before, i.ph-dog.icon-alive:before, i.ph-dog.icon-warn:before, i.ph-dog.icon-sign-out:before, i.ph-dog.icon-substratum:before, i.ph-dog.icon-environment:before, i.ph-dog.icon-user:before, i.ph-dog.icon-person:before, i.ph-dog.icon-terrain:before, i.ph-dog.icon-taxon:before, i.ph-dog.icon-unknown:before, i.ph-dog.icon-nothing:before, i.ph-dog.icon-sampling:before, i.ph-dog.icon-isolation:before, i.ph-dog.icon-strain:before, i.ph-dog.icon-gps:before, i.ph-dog.icon-location:before, i.ph-dog.icon-add:before, i.ph-dog.icon-left:before, i.ph-dog.icon-dna:before, i.ph-dog.icon-delete:before, i.ph-dog.icon-edit:before, i.ph-dog.icon-info:before {
  content: "\e74a";
}

.ph.ph-door:before, i.ph-door.icon-dead:before, i.ph-door.icon-alive:before, i.ph-door.icon-warn:before, i.ph-door.icon-sign-out:before, i.ph-door.icon-substratum:before, i.ph-door.icon-environment:before, i.ph-door.icon-user:before, i.ph-door.icon-person:before, i.ph-door.icon-terrain:before, i.ph-door.icon-taxon:before, i.ph-door.icon-unknown:before, i.ph-door.icon-nothing:before, i.ph-door.icon-sampling:before, i.ph-door.icon-isolation:before, i.ph-door.icon-strain:before, i.ph-door.icon-gps:before, i.ph-door.icon-location:before, i.ph-door.icon-add:before, i.ph-door.icon-left:before, i.ph-door.icon-dna:before, i.ph-door.icon-delete:before, i.ph-door.icon-edit:before, i.ph-door.icon-info:before {
  content: "\e61c";
}

.ph.ph-door-open:before, i.ph-door-open.icon-dead:before, i.ph-door-open.icon-alive:before, i.ph-door-open.icon-warn:before, i.ph-door-open.icon-sign-out:before, i.ph-door-open.icon-substratum:before, i.ph-door-open.icon-environment:before, i.ph-door-open.icon-user:before, i.ph-door-open.icon-person:before, i.ph-door-open.icon-terrain:before, i.ph-door-open.icon-taxon:before, i.ph-door-open.icon-unknown:before, i.ph-door-open.icon-nothing:before, i.ph-door-open.icon-sampling:before, i.ph-door-open.icon-isolation:before, i.ph-door-open.icon-strain:before, i.ph-door-open.icon-gps:before, i.ph-door-open.icon-location:before, i.ph-door-open.icon-add:before, i.ph-door-open.icon-left:before, i.ph-door-open.icon-dna:before, i.ph-door-open.icon-delete:before, i.ph-door-open.icon-edit:before, i.ph-door-open.icon-info:before {
  content: "\e7e6";
}

.ph.ph-dot:before, i.ph-dot.icon-dead:before, i.ph-dot.icon-alive:before, i.ph-dot.icon-warn:before, i.ph-dot.icon-sign-out:before, i.ph-dot.icon-substratum:before, i.ph-dot.icon-environment:before, i.ph-dot.icon-user:before, i.ph-dot.icon-person:before, i.ph-dot.icon-terrain:before, i.ph-dot.icon-taxon:before, i.ph-dot.icon-unknown:before, i.ph-dot.icon-nothing:before, i.ph-dot.icon-sampling:before, i.ph-dot.icon-isolation:before, i.ph-dot.icon-strain:before, i.ph-dot.icon-gps:before, i.ph-dot.icon-location:before, i.ph-dot.icon-add:before, i.ph-dot.icon-left:before, i.ph-dot.icon-dna:before, i.ph-dot.icon-delete:before, i.ph-dot.icon-edit:before, i.ph-dot.icon-info:before {
  content: "\ecde";
}

.ph.ph-dot-outline:before, i.ph-dot-outline.icon-dead:before, i.ph-dot-outline.icon-alive:before, i.ph-dot-outline.icon-warn:before, i.ph-dot-outline.icon-sign-out:before, i.ph-dot-outline.icon-substratum:before, i.ph-dot-outline.icon-environment:before, i.ph-dot-outline.icon-user:before, i.ph-dot-outline.icon-person:before, i.ph-dot-outline.icon-terrain:before, i.ph-dot-outline.icon-taxon:before, i.ph-dot-outline.icon-unknown:before, i.ph-dot-outline.icon-nothing:before, i.ph-dot-outline.icon-sampling:before, i.ph-dot-outline.icon-isolation:before, i.ph-dot-outline.icon-strain:before, i.ph-dot-outline.icon-gps:before, i.ph-dot-outline.icon-location:before, i.ph-dot-outline.icon-add:before, i.ph-dot-outline.icon-left:before, i.ph-dot-outline.icon-dna:before, i.ph-dot-outline.icon-delete:before, i.ph-dot-outline.icon-edit:before, i.ph-dot-outline.icon-info:before {
  content: "\ece0";
}

.ph.ph-dots-nine:before, i.ph-dots-nine.icon-dead:before, i.ph-dots-nine.icon-alive:before, i.ph-dots-nine.icon-warn:before, i.ph-dots-nine.icon-sign-out:before, i.ph-dots-nine.icon-substratum:before, i.ph-dots-nine.icon-environment:before, i.ph-dots-nine.icon-user:before, i.ph-dots-nine.icon-person:before, i.ph-dots-nine.icon-terrain:before, i.ph-dots-nine.icon-taxon:before, i.ph-dots-nine.icon-unknown:before, i.ph-dots-nine.icon-nothing:before, i.ph-dots-nine.icon-sampling:before, i.ph-dots-nine.icon-isolation:before, i.ph-dots-nine.icon-strain:before, i.ph-dots-nine.icon-gps:before, i.ph-dots-nine.icon-location:before, i.ph-dots-nine.icon-add:before, i.ph-dots-nine.icon-left:before, i.ph-dots-nine.icon-dna:before, i.ph-dots-nine.icon-delete:before, i.ph-dots-nine.icon-edit:before, i.ph-dots-nine.icon-info:before {
  content: "\e1fc";
}

.ph.ph-dots-six:before, i.ph-dots-six.icon-dead:before, i.ph-dots-six.icon-alive:before, i.ph-dots-six.icon-warn:before, i.ph-dots-six.icon-sign-out:before, i.ph-dots-six.icon-substratum:before, i.ph-dots-six.icon-environment:before, i.ph-dots-six.icon-user:before, i.ph-dots-six.icon-person:before, i.ph-dots-six.icon-terrain:before, i.ph-dots-six.icon-taxon:before, i.ph-dots-six.icon-unknown:before, i.ph-dots-six.icon-nothing:before, i.ph-dots-six.icon-sampling:before, i.ph-dots-six.icon-isolation:before, i.ph-dots-six.icon-strain:before, i.ph-dots-six.icon-gps:before, i.ph-dots-six.icon-location:before, i.ph-dots-six.icon-add:before, i.ph-dots-six.icon-left:before, i.ph-dots-six.icon-dna:before, i.ph-dots-six.icon-delete:before, i.ph-dots-six.icon-edit:before, i.ph-dots-six.icon-info:before {
  content: "\e794";
}

.ph.ph-dots-six-vertical:before, i.ph-dots-six-vertical.icon-dead:before, i.ph-dots-six-vertical.icon-alive:before, i.ph-dots-six-vertical.icon-warn:before, i.ph-dots-six-vertical.icon-sign-out:before, i.ph-dots-six-vertical.icon-substratum:before, i.ph-dots-six-vertical.icon-environment:before, i.ph-dots-six-vertical.icon-user:before, i.ph-dots-six-vertical.icon-person:before, i.ph-dots-six-vertical.icon-terrain:before, i.ph-dots-six-vertical.icon-taxon:before, i.ph-dots-six-vertical.icon-unknown:before, i.ph-dots-six-vertical.icon-nothing:before, i.ph-dots-six-vertical.icon-sampling:before, i.ph-dots-six-vertical.icon-isolation:before, i.ph-dots-six-vertical.icon-strain:before, i.ph-dots-six-vertical.icon-gps:before, i.ph-dots-six-vertical.icon-location:before, i.ph-dots-six-vertical.icon-add:before, i.ph-dots-six-vertical.icon-left:before, i.ph-dots-six-vertical.icon-dna:before, i.ph-dots-six-vertical.icon-delete:before, i.ph-dots-six-vertical.icon-edit:before, i.ph-dots-six-vertical.icon-info:before {
  content: "\eae2";
}

.ph.ph-dots-three:before, i.ph-dots-three.icon-dead:before, i.ph-dots-three.icon-alive:before, i.ph-dots-three.icon-warn:before, i.ph-dots-three.icon-sign-out:before, i.ph-dots-three.icon-substratum:before, i.ph-dots-three.icon-environment:before, i.ph-dots-three.icon-user:before, i.ph-dots-three.icon-person:before, i.ph-dots-three.icon-terrain:before, i.ph-dots-three.icon-taxon:before, i.ph-dots-three.icon-unknown:before, i.ph-dots-three.icon-nothing:before, i.ph-dots-three.icon-sampling:before, i.ph-dots-three.icon-isolation:before, i.ph-dots-three.icon-strain:before, i.ph-dots-three.icon-gps:before, i.ph-dots-three.icon-location:before, i.ph-dots-three.icon-add:before, i.ph-dots-three.icon-left:before, i.ph-dots-three.icon-dna:before, i.ph-dots-three.icon-delete:before, i.ph-dots-three.icon-edit:before, i.ph-dots-three.icon-info:before {
  content: "\e1fe";
}

.ph.ph-dots-three-circle:before, i.ph-dots-three-circle.icon-dead:before, i.ph-dots-three-circle.icon-alive:before, i.ph-dots-three-circle.icon-warn:before, i.ph-dots-three-circle.icon-sign-out:before, i.ph-dots-three-circle.icon-substratum:before, i.ph-dots-three-circle.icon-environment:before, i.ph-dots-three-circle.icon-user:before, i.ph-dots-three-circle.icon-person:before, i.ph-dots-three-circle.icon-terrain:before, i.ph-dots-three-circle.icon-taxon:before, i.ph-dots-three-circle.icon-unknown:before, i.ph-dots-three-circle.icon-nothing:before, i.ph-dots-three-circle.icon-sampling:before, i.ph-dots-three-circle.icon-isolation:before, i.ph-dots-three-circle.icon-strain:before, i.ph-dots-three-circle.icon-gps:before, i.ph-dots-three-circle.icon-location:before, i.ph-dots-three-circle.icon-add:before, i.ph-dots-three-circle.icon-left:before, i.ph-dots-three-circle.icon-dna:before, i.ph-dots-three-circle.icon-delete:before, i.ph-dots-three-circle.icon-edit:before, i.ph-dots-three-circle.icon-info:before {
  content: "\e200";
}

.ph.ph-dots-three-circle-vertical:before, i.ph-dots-three-circle-vertical.icon-dead:before, i.ph-dots-three-circle-vertical.icon-alive:before, i.ph-dots-three-circle-vertical.icon-warn:before, i.ph-dots-three-circle-vertical.icon-sign-out:before, i.ph-dots-three-circle-vertical.icon-substratum:before, i.ph-dots-three-circle-vertical.icon-environment:before, i.ph-dots-three-circle-vertical.icon-user:before, i.ph-dots-three-circle-vertical.icon-person:before, i.ph-dots-three-circle-vertical.icon-terrain:before, i.ph-dots-three-circle-vertical.icon-taxon:before, i.ph-dots-three-circle-vertical.icon-unknown:before, i.ph-dots-three-circle-vertical.icon-nothing:before, i.ph-dots-three-circle-vertical.icon-sampling:before, i.ph-dots-three-circle-vertical.icon-isolation:before, i.ph-dots-three-circle-vertical.icon-strain:before, i.ph-dots-three-circle-vertical.icon-gps:before, i.ph-dots-three-circle-vertical.icon-location:before, i.ph-dots-three-circle-vertical.icon-add:before, i.ph-dots-three-circle-vertical.icon-left:before, i.ph-dots-three-circle-vertical.icon-dna:before, i.ph-dots-three-circle-vertical.icon-delete:before, i.ph-dots-three-circle-vertical.icon-edit:before, i.ph-dots-three-circle-vertical.icon-info:before {
  content: "\e202";
}

.ph.ph-dots-three-outline:before, i.ph-dots-three-outline.icon-dead:before, i.ph-dots-three-outline.icon-alive:before, i.ph-dots-three-outline.icon-warn:before, i.ph-dots-three-outline.icon-sign-out:before, i.ph-dots-three-outline.icon-substratum:before, i.ph-dots-three-outline.icon-environment:before, i.ph-dots-three-outline.icon-user:before, i.ph-dots-three-outline.icon-person:before, i.ph-dots-three-outline.icon-terrain:before, i.ph-dots-three-outline.icon-taxon:before, i.ph-dots-three-outline.icon-unknown:before, i.ph-dots-three-outline.icon-nothing:before, i.ph-dots-three-outline.icon-sampling:before, i.ph-dots-three-outline.icon-isolation:before, i.ph-dots-three-outline.icon-strain:before, i.ph-dots-three-outline.icon-gps:before, i.ph-dots-three-outline.icon-location:before, i.ph-dots-three-outline.icon-add:before, i.ph-dots-three-outline.icon-left:before, i.ph-dots-three-outline.icon-dna:before, i.ph-dots-three-outline.icon-delete:before, i.ph-dots-three-outline.icon-edit:before, i.ph-dots-three-outline.icon-info:before {
  content: "\e204";
}

.ph.ph-dots-three-outline-vertical:before, i.ph-dots-three-outline-vertical.icon-dead:before, i.ph-dots-three-outline-vertical.icon-alive:before, i.ph-dots-three-outline-vertical.icon-warn:before, i.ph-dots-three-outline-vertical.icon-sign-out:before, i.ph-dots-three-outline-vertical.icon-substratum:before, i.ph-dots-three-outline-vertical.icon-environment:before, i.ph-dots-three-outline-vertical.icon-user:before, i.ph-dots-three-outline-vertical.icon-person:before, i.ph-dots-three-outline-vertical.icon-terrain:before, i.ph-dots-three-outline-vertical.icon-taxon:before, i.ph-dots-three-outline-vertical.icon-unknown:before, i.ph-dots-three-outline-vertical.icon-nothing:before, i.ph-dots-three-outline-vertical.icon-sampling:before, i.ph-dots-three-outline-vertical.icon-isolation:before, i.ph-dots-three-outline-vertical.icon-strain:before, i.ph-dots-three-outline-vertical.icon-gps:before, i.ph-dots-three-outline-vertical.icon-location:before, i.ph-dots-three-outline-vertical.icon-add:before, i.ph-dots-three-outline-vertical.icon-left:before, i.ph-dots-three-outline-vertical.icon-dna:before, i.ph-dots-three-outline-vertical.icon-delete:before, i.ph-dots-three-outline-vertical.icon-edit:before, i.ph-dots-three-outline-vertical.icon-info:before {
  content: "\e206";
}

.ph.ph-dots-three-vertical:before, i.ph-dots-three-vertical.icon-dead:before, i.ph-dots-three-vertical.icon-alive:before, i.ph-dots-three-vertical.icon-warn:before, i.ph-dots-three-vertical.icon-sign-out:before, i.ph-dots-three-vertical.icon-substratum:before, i.ph-dots-three-vertical.icon-environment:before, i.ph-dots-three-vertical.icon-user:before, i.ph-dots-three-vertical.icon-person:before, i.ph-dots-three-vertical.icon-terrain:before, i.ph-dots-three-vertical.icon-taxon:before, i.ph-dots-three-vertical.icon-unknown:before, i.ph-dots-three-vertical.icon-nothing:before, i.ph-dots-three-vertical.icon-sampling:before, i.ph-dots-three-vertical.icon-isolation:before, i.ph-dots-three-vertical.icon-strain:before, i.ph-dots-three-vertical.icon-gps:before, i.ph-dots-three-vertical.icon-location:before, i.ph-dots-three-vertical.icon-add:before, i.ph-dots-three-vertical.icon-left:before, i.ph-dots-three-vertical.icon-dna:before, i.ph-dots-three-vertical.icon-delete:before, i.ph-dots-three-vertical.icon-edit:before, i.ph-dots-three-vertical.icon-info:before {
  content: "\e208";
}

.ph.ph-download:before, i.ph-download.icon-dead:before, i.ph-download.icon-alive:before, i.ph-download.icon-warn:before, i.ph-download.icon-sign-out:before, i.ph-download.icon-substratum:before, i.ph-download.icon-environment:before, i.ph-download.icon-user:before, i.ph-download.icon-person:before, i.ph-download.icon-terrain:before, i.ph-download.icon-taxon:before, i.ph-download.icon-unknown:before, i.ph-download.icon-nothing:before, i.ph-download.icon-sampling:before, i.ph-download.icon-isolation:before, i.ph-download.icon-strain:before, i.ph-download.icon-gps:before, i.ph-download.icon-location:before, i.ph-download.icon-add:before, i.ph-download.icon-left:before, i.ph-download.icon-dna:before, i.ph-download.icon-delete:before, i.ph-download.icon-edit:before, i.ph-download.icon-info:before {
  content: "\e20a";
}

.ph.ph-download-simple:before, i.ph-download-simple.icon-dead:before, i.ph-download-simple.icon-alive:before, i.ph-download-simple.icon-warn:before, i.ph-download-simple.icon-sign-out:before, i.ph-download-simple.icon-substratum:before, i.ph-download-simple.icon-environment:before, i.ph-download-simple.icon-user:before, i.ph-download-simple.icon-person:before, i.ph-download-simple.icon-terrain:before, i.ph-download-simple.icon-taxon:before, i.ph-download-simple.icon-unknown:before, i.ph-download-simple.icon-nothing:before, i.ph-download-simple.icon-sampling:before, i.ph-download-simple.icon-isolation:before, i.ph-download-simple.icon-strain:before, i.ph-download-simple.icon-gps:before, i.ph-download-simple.icon-location:before, i.ph-download-simple.icon-add:before, i.ph-download-simple.icon-left:before, i.ph-download-simple.icon-dna:before, i.ph-download-simple.icon-delete:before, i.ph-download-simple.icon-edit:before, i.ph-download-simple.icon-info:before {
  content: "\e20c";
}

.ph.ph-dress:before, i.ph-dress.icon-dead:before, i.ph-dress.icon-alive:before, i.ph-dress.icon-warn:before, i.ph-dress.icon-sign-out:before, i.ph-dress.icon-substratum:before, i.ph-dress.icon-environment:before, i.ph-dress.icon-user:before, i.ph-dress.icon-person:before, i.ph-dress.icon-terrain:before, i.ph-dress.icon-taxon:before, i.ph-dress.icon-unknown:before, i.ph-dress.icon-nothing:before, i.ph-dress.icon-sampling:before, i.ph-dress.icon-isolation:before, i.ph-dress.icon-strain:before, i.ph-dress.icon-gps:before, i.ph-dress.icon-location:before, i.ph-dress.icon-add:before, i.ph-dress.icon-left:before, i.ph-dress.icon-dna:before, i.ph-dress.icon-delete:before, i.ph-dress.icon-edit:before, i.ph-dress.icon-info:before {
  content: "\ea7e";
}

.ph.ph-dresser:before, i.ph-dresser.icon-dead:before, i.ph-dresser.icon-alive:before, i.ph-dresser.icon-warn:before, i.ph-dresser.icon-sign-out:before, i.ph-dresser.icon-substratum:before, i.ph-dresser.icon-environment:before, i.ph-dresser.icon-user:before, i.ph-dresser.icon-person:before, i.ph-dresser.icon-terrain:before, i.ph-dresser.icon-taxon:before, i.ph-dresser.icon-unknown:before, i.ph-dresser.icon-nothing:before, i.ph-dresser.icon-sampling:before, i.ph-dresser.icon-isolation:before, i.ph-dresser.icon-strain:before, i.ph-dresser.icon-gps:before, i.ph-dresser.icon-location:before, i.ph-dresser.icon-add:before, i.ph-dresser.icon-left:before, i.ph-dresser.icon-dna:before, i.ph-dresser.icon-delete:before, i.ph-dresser.icon-edit:before, i.ph-dresser.icon-info:before {
  content: "\e94e";
}

.ph.ph-dribbble-logo:before, i.ph-dribbble-logo.icon-dead:before, i.ph-dribbble-logo.icon-alive:before, i.ph-dribbble-logo.icon-warn:before, i.ph-dribbble-logo.icon-sign-out:before, i.ph-dribbble-logo.icon-substratum:before, i.ph-dribbble-logo.icon-environment:before, i.ph-dribbble-logo.icon-user:before, i.ph-dribbble-logo.icon-person:before, i.ph-dribbble-logo.icon-terrain:before, i.ph-dribbble-logo.icon-taxon:before, i.ph-dribbble-logo.icon-unknown:before, i.ph-dribbble-logo.icon-nothing:before, i.ph-dribbble-logo.icon-sampling:before, i.ph-dribbble-logo.icon-isolation:before, i.ph-dribbble-logo.icon-strain:before, i.ph-dribbble-logo.icon-gps:before, i.ph-dribbble-logo.icon-location:before, i.ph-dribbble-logo.icon-add:before, i.ph-dribbble-logo.icon-left:before, i.ph-dribbble-logo.icon-dna:before, i.ph-dribbble-logo.icon-delete:before, i.ph-dribbble-logo.icon-edit:before, i.ph-dribbble-logo.icon-info:before {
  content: "\e20e";
}

.ph.ph-drone:before, i.ph-drone.icon-dead:before, i.ph-drone.icon-alive:before, i.ph-drone.icon-warn:before, i.ph-drone.icon-sign-out:before, i.ph-drone.icon-substratum:before, i.ph-drone.icon-environment:before, i.ph-drone.icon-user:before, i.ph-drone.icon-person:before, i.ph-drone.icon-terrain:before, i.ph-drone.icon-taxon:before, i.ph-drone.icon-unknown:before, i.ph-drone.icon-nothing:before, i.ph-drone.icon-sampling:before, i.ph-drone.icon-isolation:before, i.ph-drone.icon-strain:before, i.ph-drone.icon-gps:before, i.ph-drone.icon-location:before, i.ph-drone.icon-add:before, i.ph-drone.icon-left:before, i.ph-drone.icon-dna:before, i.ph-drone.icon-delete:before, i.ph-drone.icon-edit:before, i.ph-drone.icon-info:before {
  content: "\ed74";
}

.ph.ph-drop:before, i.ph-drop.icon-dead:before, i.ph-drop.icon-alive:before, i.ph-drop.icon-warn:before, i.ph-drop.icon-sign-out:before, i.ph-drop.icon-substratum:before, i.ph-drop.icon-environment:before, i.ph-drop.icon-user:before, i.ph-drop.icon-person:before, i.ph-drop.icon-terrain:before, i.ph-drop.icon-taxon:before, i.ph-drop.icon-unknown:before, i.ph-drop.icon-nothing:before, i.ph-drop.icon-sampling:before, i.ph-drop.icon-isolation:before, i.ph-drop.icon-strain:before, i.ph-drop.icon-gps:before, i.ph-drop.icon-location:before, i.ph-drop.icon-add:before, i.ph-drop.icon-left:before, i.ph-drop.icon-dna:before, i.ph-drop.icon-delete:before, i.ph-drop.icon-edit:before, i.ph-drop.icon-info:before {
  content: "\e210";
}

.ph.ph-drop-half:before, i.ph-drop-half.icon-dead:before, i.ph-drop-half.icon-alive:before, i.ph-drop-half.icon-warn:before, i.ph-drop-half.icon-sign-out:before, i.ph-drop-half.icon-substratum:before, i.ph-drop-half.icon-environment:before, i.ph-drop-half.icon-user:before, i.ph-drop-half.icon-person:before, i.ph-drop-half.icon-terrain:before, i.ph-drop-half.icon-taxon:before, i.ph-drop-half.icon-unknown:before, i.ph-drop-half.icon-nothing:before, i.ph-drop-half.icon-sampling:before, i.ph-drop-half.icon-isolation:before, i.ph-drop-half.icon-strain:before, i.ph-drop-half.icon-gps:before, i.ph-drop-half.icon-location:before, i.ph-drop-half.icon-add:before, i.ph-drop-half.icon-left:before, i.ph-drop-half.icon-dna:before, i.ph-drop-half.icon-delete:before, i.ph-drop-half.icon-edit:before, i.ph-drop-half.icon-info:before {
  content: "\e566";
}

.ph.ph-drop-half-bottom:before, i.ph-drop-half-bottom.icon-dead:before, i.ph-drop-half-bottom.icon-alive:before, i.ph-drop-half-bottom.icon-warn:before, i.ph-drop-half-bottom.icon-sign-out:before, i.ph-drop-half-bottom.icon-substratum:before, i.ph-drop-half-bottom.icon-environment:before, i.ph-drop-half-bottom.icon-user:before, i.ph-drop-half-bottom.icon-person:before, i.ph-drop-half-bottom.icon-terrain:before, i.ph-drop-half-bottom.icon-taxon:before, i.ph-drop-half-bottom.icon-unknown:before, i.ph-drop-half-bottom.icon-nothing:before, i.ph-drop-half-bottom.icon-sampling:before, i.ph-drop-half-bottom.icon-isolation:before, i.ph-drop-half-bottom.icon-strain:before, i.ph-drop-half-bottom.icon-gps:before, i.ph-drop-half-bottom.icon-location:before, i.ph-drop-half-bottom.icon-add:before, i.ph-drop-half-bottom.icon-left:before, i.ph-drop-half-bottom.icon-dna:before, i.ph-drop-half-bottom.icon-delete:before, i.ph-drop-half-bottom.icon-edit:before, i.ph-drop-half-bottom.icon-info:before {
  content: "\eb40";
}

.ph.ph-drop-simple:before, i.ph-drop-simple.icon-dead:before, i.ph-drop-simple.icon-alive:before, i.ph-drop-simple.icon-warn:before, i.ph-drop-simple.icon-sign-out:before, i.ph-drop-simple.icon-substratum:before, i.ph-drop-simple.icon-environment:before, i.ph-drop-simple.icon-user:before, i.ph-drop-simple.icon-person:before, i.ph-drop-simple.icon-terrain:before, i.ph-drop-simple.icon-taxon:before, i.ph-drop-simple.icon-unknown:before, i.ph-drop-simple.icon-nothing:before, i.ph-drop-simple.icon-sampling:before, i.ph-drop-simple.icon-isolation:before, i.ph-drop-simple.icon-strain:before, i.ph-drop-simple.icon-gps:before, i.ph-drop-simple.icon-location:before, i.ph-drop-simple.icon-add:before, i.ph-drop-simple.icon-left:before, i.ph-drop-simple.icon-dna:before, i.ph-drop-simple.icon-delete:before, i.ph-drop-simple.icon-edit:before, i.ph-drop-simple.icon-info:before {
  content: "\ee32";
}

.ph.ph-drop-slash:before, i.ph-drop-slash.icon-dead:before, i.ph-drop-slash.icon-alive:before, i.ph-drop-slash.icon-warn:before, i.ph-drop-slash.icon-sign-out:before, i.ph-drop-slash.icon-substratum:before, i.ph-drop-slash.icon-environment:before, i.ph-drop-slash.icon-user:before, i.ph-drop-slash.icon-person:before, i.ph-drop-slash.icon-terrain:before, i.ph-drop-slash.icon-taxon:before, i.ph-drop-slash.icon-unknown:before, i.ph-drop-slash.icon-nothing:before, i.ph-drop-slash.icon-sampling:before, i.ph-drop-slash.icon-isolation:before, i.ph-drop-slash.icon-strain:before, i.ph-drop-slash.icon-gps:before, i.ph-drop-slash.icon-location:before, i.ph-drop-slash.icon-add:before, i.ph-drop-slash.icon-left:before, i.ph-drop-slash.icon-dna:before, i.ph-drop-slash.icon-delete:before, i.ph-drop-slash.icon-edit:before, i.ph-drop-slash.icon-info:before {
  content: "\e954";
}

.ph.ph-dropbox-logo:before, i.ph-dropbox-logo.icon-dead:before, i.ph-dropbox-logo.icon-alive:before, i.ph-dropbox-logo.icon-warn:before, i.ph-dropbox-logo.icon-sign-out:before, i.ph-dropbox-logo.icon-substratum:before, i.ph-dropbox-logo.icon-environment:before, i.ph-dropbox-logo.icon-user:before, i.ph-dropbox-logo.icon-person:before, i.ph-dropbox-logo.icon-terrain:before, i.ph-dropbox-logo.icon-taxon:before, i.ph-dropbox-logo.icon-unknown:before, i.ph-dropbox-logo.icon-nothing:before, i.ph-dropbox-logo.icon-sampling:before, i.ph-dropbox-logo.icon-isolation:before, i.ph-dropbox-logo.icon-strain:before, i.ph-dropbox-logo.icon-gps:before, i.ph-dropbox-logo.icon-location:before, i.ph-dropbox-logo.icon-add:before, i.ph-dropbox-logo.icon-left:before, i.ph-dropbox-logo.icon-dna:before, i.ph-dropbox-logo.icon-delete:before, i.ph-dropbox-logo.icon-edit:before, i.ph-dropbox-logo.icon-info:before {
  content: "\e7d0";
}

.ph.ph-ear:before, i.ph-ear.icon-dead:before, i.ph-ear.icon-alive:before, i.ph-ear.icon-warn:before, i.ph-ear.icon-sign-out:before, i.ph-ear.icon-substratum:before, i.ph-ear.icon-environment:before, i.ph-ear.icon-user:before, i.ph-ear.icon-person:before, i.ph-ear.icon-terrain:before, i.ph-ear.icon-taxon:before, i.ph-ear.icon-unknown:before, i.ph-ear.icon-nothing:before, i.ph-ear.icon-sampling:before, i.ph-ear.icon-isolation:before, i.ph-ear.icon-strain:before, i.ph-ear.icon-gps:before, i.ph-ear.icon-location:before, i.ph-ear.icon-add:before, i.ph-ear.icon-left:before, i.ph-ear.icon-dna:before, i.ph-ear.icon-delete:before, i.ph-ear.icon-edit:before, i.ph-ear.icon-info:before {
  content: "\e70c";
}

.ph.ph-ear-slash:before, i.ph-ear-slash.icon-dead:before, i.ph-ear-slash.icon-alive:before, i.ph-ear-slash.icon-warn:before, i.ph-ear-slash.icon-sign-out:before, i.ph-ear-slash.icon-substratum:before, i.ph-ear-slash.icon-environment:before, i.ph-ear-slash.icon-user:before, i.ph-ear-slash.icon-person:before, i.ph-ear-slash.icon-terrain:before, i.ph-ear-slash.icon-taxon:before, i.ph-ear-slash.icon-unknown:before, i.ph-ear-slash.icon-nothing:before, i.ph-ear-slash.icon-sampling:before, i.ph-ear-slash.icon-isolation:before, i.ph-ear-slash.icon-strain:before, i.ph-ear-slash.icon-gps:before, i.ph-ear-slash.icon-location:before, i.ph-ear-slash.icon-add:before, i.ph-ear-slash.icon-left:before, i.ph-ear-slash.icon-dna:before, i.ph-ear-slash.icon-delete:before, i.ph-ear-slash.icon-edit:before, i.ph-ear-slash.icon-info:before {
  content: "\e70e";
}

.ph.ph-egg:before, i.ph-egg.icon-dead:before, i.ph-egg.icon-alive:before, i.ph-egg.icon-warn:before, i.ph-egg.icon-sign-out:before, i.ph-egg.icon-substratum:before, i.ph-egg.icon-environment:before, i.ph-egg.icon-user:before, i.ph-egg.icon-person:before, i.ph-egg.icon-terrain:before, i.ph-egg.icon-taxon:before, i.ph-egg.icon-unknown:before, i.ph-egg.icon-nothing:before, i.ph-egg.icon-sampling:before, i.ph-egg.icon-isolation:before, i.ph-egg.icon-strain:before, i.ph-egg.icon-gps:before, i.ph-egg.icon-location:before, i.ph-egg.icon-add:before, i.ph-egg.icon-left:before, i.ph-egg.icon-dna:before, i.ph-egg.icon-delete:before, i.ph-egg.icon-edit:before, i.ph-egg.icon-info:before {
  content: "\e812";
}

.ph.ph-egg-crack:before, i.ph-egg-crack.icon-dead:before, i.ph-egg-crack.icon-alive:before, i.ph-egg-crack.icon-warn:before, i.ph-egg-crack.icon-sign-out:before, i.ph-egg-crack.icon-substratum:before, i.ph-egg-crack.icon-environment:before, i.ph-egg-crack.icon-user:before, i.ph-egg-crack.icon-person:before, i.ph-egg-crack.icon-terrain:before, i.ph-egg-crack.icon-taxon:before, i.ph-egg-crack.icon-unknown:before, i.ph-egg-crack.icon-nothing:before, i.ph-egg-crack.icon-sampling:before, i.ph-egg-crack.icon-isolation:before, i.ph-egg-crack.icon-strain:before, i.ph-egg-crack.icon-gps:before, i.ph-egg-crack.icon-location:before, i.ph-egg-crack.icon-add:before, i.ph-egg-crack.icon-left:before, i.ph-egg-crack.icon-dna:before, i.ph-egg-crack.icon-delete:before, i.ph-egg-crack.icon-edit:before, i.ph-egg-crack.icon-info:before {
  content: "\eb64";
}

.ph.ph-eject:before, i.ph-eject.icon-dead:before, i.ph-eject.icon-alive:before, i.ph-eject.icon-warn:before, i.ph-eject.icon-sign-out:before, i.ph-eject.icon-substratum:before, i.ph-eject.icon-environment:before, i.ph-eject.icon-user:before, i.ph-eject.icon-person:before, i.ph-eject.icon-terrain:before, i.ph-eject.icon-taxon:before, i.ph-eject.icon-unknown:before, i.ph-eject.icon-nothing:before, i.ph-eject.icon-sampling:before, i.ph-eject.icon-isolation:before, i.ph-eject.icon-strain:before, i.ph-eject.icon-gps:before, i.ph-eject.icon-location:before, i.ph-eject.icon-add:before, i.ph-eject.icon-left:before, i.ph-eject.icon-dna:before, i.ph-eject.icon-delete:before, i.ph-eject.icon-edit:before, i.ph-eject.icon-info:before {
  content: "\e212";
}

.ph.ph-eject-simple:before, i.ph-eject-simple.icon-dead:before, i.ph-eject-simple.icon-alive:before, i.ph-eject-simple.icon-warn:before, i.ph-eject-simple.icon-sign-out:before, i.ph-eject-simple.icon-substratum:before, i.ph-eject-simple.icon-environment:before, i.ph-eject-simple.icon-user:before, i.ph-eject-simple.icon-person:before, i.ph-eject-simple.icon-terrain:before, i.ph-eject-simple.icon-taxon:before, i.ph-eject-simple.icon-unknown:before, i.ph-eject-simple.icon-nothing:before, i.ph-eject-simple.icon-sampling:before, i.ph-eject-simple.icon-isolation:before, i.ph-eject-simple.icon-strain:before, i.ph-eject-simple.icon-gps:before, i.ph-eject-simple.icon-location:before, i.ph-eject-simple.icon-add:before, i.ph-eject-simple.icon-left:before, i.ph-eject-simple.icon-dna:before, i.ph-eject-simple.icon-delete:before, i.ph-eject-simple.icon-edit:before, i.ph-eject-simple.icon-info:before {
  content: "\e6ae";
}

.ph.ph-elevator:before, i.ph-elevator.icon-dead:before, i.ph-elevator.icon-alive:before, i.ph-elevator.icon-warn:before, i.ph-elevator.icon-sign-out:before, i.ph-elevator.icon-substratum:before, i.ph-elevator.icon-environment:before, i.ph-elevator.icon-user:before, i.ph-elevator.icon-person:before, i.ph-elevator.icon-terrain:before, i.ph-elevator.icon-taxon:before, i.ph-elevator.icon-unknown:before, i.ph-elevator.icon-nothing:before, i.ph-elevator.icon-sampling:before, i.ph-elevator.icon-isolation:before, i.ph-elevator.icon-strain:before, i.ph-elevator.icon-gps:before, i.ph-elevator.icon-location:before, i.ph-elevator.icon-add:before, i.ph-elevator.icon-left:before, i.ph-elevator.icon-dna:before, i.ph-elevator.icon-delete:before, i.ph-elevator.icon-edit:before, i.ph-elevator.icon-info:before {
  content: "\ecc0";
}

.ph.ph-empty:before, i.ph-empty.icon-dead:before, i.ph-empty.icon-alive:before, i.ph-empty.icon-warn:before, i.ph-empty.icon-sign-out:before, i.ph-empty.icon-substratum:before, i.ph-empty.icon-environment:before, i.ph-empty.icon-user:before, i.ph-empty.icon-person:before, i.ph-empty.icon-terrain:before, i.ph-empty.icon-taxon:before, i.ph-empty.icon-unknown:before, i.icon-nothing:before, i.ph-empty.icon-sampling:before, i.ph-empty.icon-isolation:before, i.ph-empty.icon-strain:before, i.ph-empty.icon-gps:before, i.ph-empty.icon-location:before, i.ph-empty.icon-add:before, i.ph-empty.icon-left:before, i.ph-empty.icon-dna:before, i.ph-empty.icon-delete:before, i.ph-empty.icon-edit:before, i.ph-empty.icon-info:before {
  content: "\edbc";
}

.ph.ph-engine:before, i.ph-engine.icon-dead:before, i.ph-engine.icon-alive:before, i.ph-engine.icon-warn:before, i.ph-engine.icon-sign-out:before, i.ph-engine.icon-substratum:before, i.ph-engine.icon-environment:before, i.ph-engine.icon-user:before, i.ph-engine.icon-person:before, i.ph-engine.icon-terrain:before, i.ph-engine.icon-taxon:before, i.ph-engine.icon-unknown:before, i.ph-engine.icon-nothing:before, i.ph-engine.icon-sampling:before, i.ph-engine.icon-isolation:before, i.ph-engine.icon-strain:before, i.ph-engine.icon-gps:before, i.ph-engine.icon-location:before, i.ph-engine.icon-add:before, i.ph-engine.icon-left:before, i.ph-engine.icon-dna:before, i.ph-engine.icon-delete:before, i.ph-engine.icon-edit:before, i.ph-engine.icon-info:before {
  content: "\ea80";
}

.ph.ph-envelope:before, i.ph-envelope.icon-dead:before, i.ph-envelope.icon-alive:before, i.ph-envelope.icon-warn:before, i.ph-envelope.icon-sign-out:before, i.ph-envelope.icon-substratum:before, i.ph-envelope.icon-environment:before, i.ph-envelope.icon-user:before, i.ph-envelope.icon-person:before, i.ph-envelope.icon-terrain:before, i.ph-envelope.icon-taxon:before, i.ph-envelope.icon-unknown:before, i.ph-envelope.icon-nothing:before, i.ph-envelope.icon-sampling:before, i.ph-envelope.icon-isolation:before, i.ph-envelope.icon-strain:before, i.ph-envelope.icon-gps:before, i.ph-envelope.icon-location:before, i.ph-envelope.icon-add:before, i.ph-envelope.icon-left:before, i.ph-envelope.icon-dna:before, i.ph-envelope.icon-delete:before, i.ph-envelope.icon-edit:before, i.ph-envelope.icon-info:before {
  content: "\e214";
}

.ph.ph-envelope-open:before, i.ph-envelope-open.icon-dead:before, i.ph-envelope-open.icon-alive:before, i.ph-envelope-open.icon-warn:before, i.ph-envelope-open.icon-sign-out:before, i.ph-envelope-open.icon-substratum:before, i.ph-envelope-open.icon-environment:before, i.ph-envelope-open.icon-user:before, i.ph-envelope-open.icon-person:before, i.ph-envelope-open.icon-terrain:before, i.ph-envelope-open.icon-taxon:before, i.ph-envelope-open.icon-unknown:before, i.ph-envelope-open.icon-nothing:before, i.ph-envelope-open.icon-sampling:before, i.ph-envelope-open.icon-isolation:before, i.ph-envelope-open.icon-strain:before, i.ph-envelope-open.icon-gps:before, i.ph-envelope-open.icon-location:before, i.ph-envelope-open.icon-add:before, i.ph-envelope-open.icon-left:before, i.ph-envelope-open.icon-dna:before, i.ph-envelope-open.icon-delete:before, i.ph-envelope-open.icon-edit:before, i.ph-envelope-open.icon-info:before {
  content: "\e216";
}

.ph.ph-envelope-simple:before, i.ph-envelope-simple.icon-dead:before, i.ph-envelope-simple.icon-alive:before, i.ph-envelope-simple.icon-warn:before, i.ph-envelope-simple.icon-sign-out:before, i.ph-envelope-simple.icon-substratum:before, i.ph-envelope-simple.icon-environment:before, i.ph-envelope-simple.icon-user:before, i.ph-envelope-simple.icon-person:before, i.ph-envelope-simple.icon-terrain:before, i.ph-envelope-simple.icon-taxon:before, i.ph-envelope-simple.icon-unknown:before, i.ph-envelope-simple.icon-nothing:before, i.ph-envelope-simple.icon-sampling:before, i.ph-envelope-simple.icon-isolation:before, i.ph-envelope-simple.icon-strain:before, i.ph-envelope-simple.icon-gps:before, i.ph-envelope-simple.icon-location:before, i.ph-envelope-simple.icon-add:before, i.ph-envelope-simple.icon-left:before, i.ph-envelope-simple.icon-dna:before, i.ph-envelope-simple.icon-delete:before, i.ph-envelope-simple.icon-edit:before, i.ph-envelope-simple.icon-info:before {
  content: "\e218";
}

.ph.ph-envelope-simple-open:before, i.ph-envelope-simple-open.icon-dead:before, i.ph-envelope-simple-open.icon-alive:before, i.ph-envelope-simple-open.icon-warn:before, i.ph-envelope-simple-open.icon-sign-out:before, i.ph-envelope-simple-open.icon-substratum:before, i.ph-envelope-simple-open.icon-environment:before, i.ph-envelope-simple-open.icon-user:before, i.ph-envelope-simple-open.icon-person:before, i.ph-envelope-simple-open.icon-terrain:before, i.ph-envelope-simple-open.icon-taxon:before, i.ph-envelope-simple-open.icon-unknown:before, i.ph-envelope-simple-open.icon-nothing:before, i.ph-envelope-simple-open.icon-sampling:before, i.ph-envelope-simple-open.icon-isolation:before, i.ph-envelope-simple-open.icon-strain:before, i.ph-envelope-simple-open.icon-gps:before, i.ph-envelope-simple-open.icon-location:before, i.ph-envelope-simple-open.icon-add:before, i.ph-envelope-simple-open.icon-left:before, i.ph-envelope-simple-open.icon-dna:before, i.ph-envelope-simple-open.icon-delete:before, i.ph-envelope-simple-open.icon-edit:before, i.ph-envelope-simple-open.icon-info:before {
  content: "\e21a";
}

.ph.ph-equalizer:before, i.ph-equalizer.icon-dead:before, i.ph-equalizer.icon-alive:before, i.ph-equalizer.icon-warn:before, i.ph-equalizer.icon-sign-out:before, i.ph-equalizer.icon-substratum:before, i.ph-equalizer.icon-environment:before, i.ph-equalizer.icon-user:before, i.ph-equalizer.icon-person:before, i.ph-equalizer.icon-terrain:before, i.ph-equalizer.icon-taxon:before, i.ph-equalizer.icon-unknown:before, i.ph-equalizer.icon-nothing:before, i.ph-equalizer.icon-sampling:before, i.ph-equalizer.icon-isolation:before, i.ph-equalizer.icon-strain:before, i.ph-equalizer.icon-gps:before, i.ph-equalizer.icon-location:before, i.ph-equalizer.icon-add:before, i.ph-equalizer.icon-left:before, i.ph-equalizer.icon-dna:before, i.ph-equalizer.icon-delete:before, i.ph-equalizer.icon-edit:before, i.ph-equalizer.icon-info:before {
  content: "\ebbc";
}

.ph.ph-equals:before, i.ph-equals.icon-dead:before, i.ph-equals.icon-alive:before, i.ph-equals.icon-warn:before, i.ph-equals.icon-sign-out:before, i.ph-equals.icon-substratum:before, i.ph-equals.icon-environment:before, i.ph-equals.icon-user:before, i.ph-equals.icon-person:before, i.ph-equals.icon-terrain:before, i.ph-equals.icon-taxon:before, i.ph-equals.icon-unknown:before, i.ph-equals.icon-nothing:before, i.ph-equals.icon-sampling:before, i.ph-equals.icon-isolation:before, i.ph-equals.icon-strain:before, i.ph-equals.icon-gps:before, i.ph-equals.icon-location:before, i.ph-equals.icon-add:before, i.ph-equals.icon-left:before, i.ph-equals.icon-dna:before, i.ph-equals.icon-delete:before, i.ph-equals.icon-edit:before, i.ph-equals.icon-info:before {
  content: "\e21c";
}

.ph.ph-eraser:before, i.ph-eraser.icon-dead:before, i.ph-eraser.icon-alive:before, i.ph-eraser.icon-warn:before, i.ph-eraser.icon-sign-out:before, i.ph-eraser.icon-substratum:before, i.ph-eraser.icon-environment:before, i.ph-eraser.icon-user:before, i.ph-eraser.icon-person:before, i.ph-eraser.icon-terrain:before, i.ph-eraser.icon-taxon:before, i.ph-eraser.icon-unknown:before, i.ph-eraser.icon-nothing:before, i.ph-eraser.icon-sampling:before, i.ph-eraser.icon-isolation:before, i.ph-eraser.icon-strain:before, i.ph-eraser.icon-gps:before, i.ph-eraser.icon-location:before, i.ph-eraser.icon-add:before, i.ph-eraser.icon-left:before, i.ph-eraser.icon-dna:before, i.ph-eraser.icon-delete:before, i.ph-eraser.icon-edit:before, i.ph-eraser.icon-info:before {
  content: "\e21e";
}

.ph.ph-escalator-down:before, i.ph-escalator-down.icon-dead:before, i.ph-escalator-down.icon-alive:before, i.ph-escalator-down.icon-warn:before, i.ph-escalator-down.icon-sign-out:before, i.ph-escalator-down.icon-substratum:before, i.ph-escalator-down.icon-environment:before, i.ph-escalator-down.icon-user:before, i.ph-escalator-down.icon-person:before, i.ph-escalator-down.icon-terrain:before, i.ph-escalator-down.icon-taxon:before, i.ph-escalator-down.icon-unknown:before, i.ph-escalator-down.icon-nothing:before, i.ph-escalator-down.icon-sampling:before, i.ph-escalator-down.icon-isolation:before, i.ph-escalator-down.icon-strain:before, i.ph-escalator-down.icon-gps:before, i.ph-escalator-down.icon-location:before, i.ph-escalator-down.icon-add:before, i.ph-escalator-down.icon-left:before, i.ph-escalator-down.icon-dna:before, i.ph-escalator-down.icon-delete:before, i.ph-escalator-down.icon-edit:before, i.ph-escalator-down.icon-info:before {
  content: "\ecba";
}

.ph.ph-escalator-up:before, i.ph-escalator-up.icon-dead:before, i.ph-escalator-up.icon-alive:before, i.ph-escalator-up.icon-warn:before, i.ph-escalator-up.icon-sign-out:before, i.ph-escalator-up.icon-substratum:before, i.ph-escalator-up.icon-environment:before, i.ph-escalator-up.icon-user:before, i.ph-escalator-up.icon-person:before, i.ph-escalator-up.icon-terrain:before, i.ph-escalator-up.icon-taxon:before, i.ph-escalator-up.icon-unknown:before, i.ph-escalator-up.icon-nothing:before, i.ph-escalator-up.icon-sampling:before, i.ph-escalator-up.icon-isolation:before, i.ph-escalator-up.icon-strain:before, i.ph-escalator-up.icon-gps:before, i.ph-escalator-up.icon-location:before, i.ph-escalator-up.icon-add:before, i.ph-escalator-up.icon-left:before, i.ph-escalator-up.icon-dna:before, i.ph-escalator-up.icon-delete:before, i.ph-escalator-up.icon-edit:before, i.ph-escalator-up.icon-info:before {
  content: "\ecbc";
}

.ph.ph-exam:before, i.ph-exam.icon-dead:before, i.ph-exam.icon-alive:before, i.ph-exam.icon-warn:before, i.ph-exam.icon-sign-out:before, i.ph-exam.icon-substratum:before, i.ph-exam.icon-environment:before, i.ph-exam.icon-user:before, i.ph-exam.icon-person:before, i.ph-exam.icon-terrain:before, i.ph-exam.icon-taxon:before, i.ph-exam.icon-unknown:before, i.ph-exam.icon-nothing:before, i.ph-exam.icon-sampling:before, i.ph-exam.icon-isolation:before, i.ph-exam.icon-strain:before, i.ph-exam.icon-gps:before, i.ph-exam.icon-location:before, i.ph-exam.icon-add:before, i.ph-exam.icon-left:before, i.ph-exam.icon-dna:before, i.ph-exam.icon-delete:before, i.ph-exam.icon-edit:before, i.ph-exam.icon-info:before {
  content: "\e742";
}

.ph.ph-exclamation-mark:before, i.ph-exclamation-mark.icon-dead:before, i.ph-exclamation-mark.icon-alive:before, i.ph-exclamation-mark.icon-warn:before, i.ph-exclamation-mark.icon-sign-out:before, i.ph-exclamation-mark.icon-substratum:before, i.ph-exclamation-mark.icon-environment:before, i.ph-exclamation-mark.icon-user:before, i.ph-exclamation-mark.icon-person:before, i.ph-exclamation-mark.icon-terrain:before, i.ph-exclamation-mark.icon-taxon:before, i.ph-exclamation-mark.icon-unknown:before, i.ph-exclamation-mark.icon-nothing:before, i.ph-exclamation-mark.icon-sampling:before, i.ph-exclamation-mark.icon-isolation:before, i.ph-exclamation-mark.icon-strain:before, i.ph-exclamation-mark.icon-gps:before, i.ph-exclamation-mark.icon-location:before, i.ph-exclamation-mark.icon-add:before, i.ph-exclamation-mark.icon-left:before, i.ph-exclamation-mark.icon-dna:before, i.ph-exclamation-mark.icon-delete:before, i.ph-exclamation-mark.icon-edit:before, i.ph-exclamation-mark.icon-info:before {
  content: "\ee44";
}

.ph.ph-exclude:before, i.ph-exclude.icon-dead:before, i.ph-exclude.icon-alive:before, i.ph-exclude.icon-warn:before, i.ph-exclude.icon-sign-out:before, i.ph-exclude.icon-substratum:before, i.ph-exclude.icon-environment:before, i.ph-exclude.icon-user:before, i.ph-exclude.icon-person:before, i.ph-exclude.icon-terrain:before, i.ph-exclude.icon-taxon:before, i.ph-exclude.icon-unknown:before, i.ph-exclude.icon-nothing:before, i.ph-exclude.icon-sampling:before, i.ph-exclude.icon-isolation:before, i.ph-exclude.icon-strain:before, i.ph-exclude.icon-gps:before, i.ph-exclude.icon-location:before, i.ph-exclude.icon-add:before, i.ph-exclude.icon-left:before, i.ph-exclude.icon-dna:before, i.ph-exclude.icon-delete:before, i.ph-exclude.icon-edit:before, i.ph-exclude.icon-info:before {
  content: "\e882";
}

.ph.ph-exclude-square:before, i.ph-exclude-square.icon-dead:before, i.ph-exclude-square.icon-alive:before, i.ph-exclude-square.icon-warn:before, i.ph-exclude-square.icon-sign-out:before, i.ph-exclude-square.icon-substratum:before, i.ph-exclude-square.icon-environment:before, i.ph-exclude-square.icon-user:before, i.ph-exclude-square.icon-person:before, i.ph-exclude-square.icon-terrain:before, i.ph-exclude-square.icon-taxon:before, i.ph-exclude-square.icon-unknown:before, i.ph-exclude-square.icon-nothing:before, i.ph-exclude-square.icon-sampling:before, i.ph-exclude-square.icon-isolation:before, i.ph-exclude-square.icon-strain:before, i.ph-exclude-square.icon-gps:before, i.ph-exclude-square.icon-location:before, i.ph-exclude-square.icon-add:before, i.ph-exclude-square.icon-left:before, i.ph-exclude-square.icon-dna:before, i.ph-exclude-square.icon-delete:before, i.ph-exclude-square.icon-edit:before, i.ph-exclude-square.icon-info:before {
  content: "\e880";
}

.ph.ph-export:before, i.ph-export.icon-dead:before, i.ph-export.icon-alive:before, i.ph-export.icon-warn:before, i.ph-export.icon-sign-out:before, i.ph-export.icon-substratum:before, i.ph-export.icon-environment:before, i.ph-export.icon-user:before, i.ph-export.icon-person:before, i.ph-export.icon-terrain:before, i.ph-export.icon-taxon:before, i.ph-export.icon-unknown:before, i.ph-export.icon-nothing:before, i.ph-export.icon-sampling:before, i.ph-export.icon-isolation:before, i.ph-export.icon-strain:before, i.ph-export.icon-gps:before, i.ph-export.icon-location:before, i.ph-export.icon-add:before, i.ph-export.icon-left:before, i.ph-export.icon-dna:before, i.ph-export.icon-delete:before, i.ph-export.icon-edit:before, i.ph-export.icon-info:before {
  content: "\eaf0";
}

.ph.ph-eye:before, i.ph-eye.icon-dead:before, i.ph-eye.icon-alive:before, i.ph-eye.icon-warn:before, i.ph-eye.icon-sign-out:before, i.ph-eye.icon-substratum:before, i.ph-eye.icon-environment:before, i.ph-eye.icon-user:before, i.ph-eye.icon-person:before, i.ph-eye.icon-terrain:before, i.ph-eye.icon-taxon:before, i.ph-eye.icon-unknown:before, i.ph-eye.icon-nothing:before, i.ph-eye.icon-sampling:before, i.ph-eye.icon-isolation:before, i.ph-eye.icon-strain:before, i.ph-eye.icon-gps:before, i.ph-eye.icon-location:before, i.ph-eye.icon-add:before, i.ph-eye.icon-left:before, i.ph-eye.icon-dna:before, i.ph-eye.icon-delete:before, i.ph-eye.icon-edit:before, i.ph-eye.icon-info:before {
  content: "\e220";
}

.ph.ph-eye-closed:before, i.ph-eye-closed.icon-dead:before, i.ph-eye-closed.icon-alive:before, i.ph-eye-closed.icon-warn:before, i.ph-eye-closed.icon-sign-out:before, i.ph-eye-closed.icon-substratum:before, i.ph-eye-closed.icon-environment:before, i.ph-eye-closed.icon-user:before, i.ph-eye-closed.icon-person:before, i.ph-eye-closed.icon-terrain:before, i.ph-eye-closed.icon-taxon:before, i.ph-eye-closed.icon-unknown:before, i.ph-eye-closed.icon-nothing:before, i.ph-eye-closed.icon-sampling:before, i.ph-eye-closed.icon-isolation:before, i.ph-eye-closed.icon-strain:before, i.ph-eye-closed.icon-gps:before, i.ph-eye-closed.icon-location:before, i.ph-eye-closed.icon-add:before, i.ph-eye-closed.icon-left:before, i.ph-eye-closed.icon-dna:before, i.ph-eye-closed.icon-delete:before, i.ph-eye-closed.icon-edit:before, i.ph-eye-closed.icon-info:before {
  content: "\e222";
}

.ph.ph-eye-slash:before, i.ph-eye-slash.icon-dead:before, i.ph-eye-slash.icon-alive:before, i.ph-eye-slash.icon-warn:before, i.ph-eye-slash.icon-sign-out:before, i.ph-eye-slash.icon-substratum:before, i.ph-eye-slash.icon-environment:before, i.ph-eye-slash.icon-user:before, i.ph-eye-slash.icon-person:before, i.ph-eye-slash.icon-terrain:before, i.ph-eye-slash.icon-taxon:before, i.ph-eye-slash.icon-unknown:before, i.ph-eye-slash.icon-nothing:before, i.ph-eye-slash.icon-sampling:before, i.ph-eye-slash.icon-isolation:before, i.ph-eye-slash.icon-strain:before, i.ph-eye-slash.icon-gps:before, i.ph-eye-slash.icon-location:before, i.ph-eye-slash.icon-add:before, i.ph-eye-slash.icon-left:before, i.ph-eye-slash.icon-dna:before, i.ph-eye-slash.icon-delete:before, i.ph-eye-slash.icon-edit:before, i.ph-eye-slash.icon-info:before {
  content: "\e224";
}

.ph.ph-eyedropper:before, i.ph-eyedropper.icon-dead:before, i.ph-eyedropper.icon-alive:before, i.ph-eyedropper.icon-warn:before, i.ph-eyedropper.icon-sign-out:before, i.ph-eyedropper.icon-substratum:before, i.ph-eyedropper.icon-environment:before, i.ph-eyedropper.icon-user:before, i.ph-eyedropper.icon-person:before, i.ph-eyedropper.icon-terrain:before, i.ph-eyedropper.icon-taxon:before, i.ph-eyedropper.icon-unknown:before, i.ph-eyedropper.icon-nothing:before, i.icon-sampling:before, i.ph-eyedropper.icon-isolation:before, i.ph-eyedropper.icon-strain:before, i.ph-eyedropper.icon-gps:before, i.ph-eyedropper.icon-location:before, i.ph-eyedropper.icon-add:before, i.ph-eyedropper.icon-left:before, i.ph-eyedropper.icon-dna:before, i.ph-eyedropper.icon-delete:before, i.ph-eyedropper.icon-edit:before, i.ph-eyedropper.icon-info:before {
  content: "\e568";
}

.ph.ph-eyedropper-sample:before, i.ph-eyedropper-sample.icon-dead:before, i.ph-eyedropper-sample.icon-alive:before, i.ph-eyedropper-sample.icon-warn:before, i.ph-eyedropper-sample.icon-sign-out:before, i.ph-eyedropper-sample.icon-substratum:before, i.ph-eyedropper-sample.icon-environment:before, i.ph-eyedropper-sample.icon-user:before, i.ph-eyedropper-sample.icon-person:before, i.ph-eyedropper-sample.icon-terrain:before, i.ph-eyedropper-sample.icon-taxon:before, i.ph-eyedropper-sample.icon-unknown:before, i.ph-eyedropper-sample.icon-nothing:before, i.ph-eyedropper-sample.icon-sampling:before, i.ph-eyedropper-sample.icon-isolation:before, i.ph-eyedropper-sample.icon-strain:before, i.ph-eyedropper-sample.icon-gps:before, i.ph-eyedropper-sample.icon-location:before, i.ph-eyedropper-sample.icon-add:before, i.ph-eyedropper-sample.icon-left:before, i.ph-eyedropper-sample.icon-dna:before, i.ph-eyedropper-sample.icon-delete:before, i.ph-eyedropper-sample.icon-edit:before, i.ph-eyedropper-sample.icon-info:before {
  content: "\eac4";
}

.ph.ph-eyeglasses:before, i.ph-eyeglasses.icon-dead:before, i.ph-eyeglasses.icon-alive:before, i.ph-eyeglasses.icon-warn:before, i.ph-eyeglasses.icon-sign-out:before, i.ph-eyeglasses.icon-substratum:before, i.ph-eyeglasses.icon-environment:before, i.ph-eyeglasses.icon-user:before, i.ph-eyeglasses.icon-person:before, i.ph-eyeglasses.icon-terrain:before, i.ph-eyeglasses.icon-taxon:before, i.ph-eyeglasses.icon-unknown:before, i.ph-eyeglasses.icon-nothing:before, i.ph-eyeglasses.icon-sampling:before, i.ph-eyeglasses.icon-isolation:before, i.ph-eyeglasses.icon-strain:before, i.ph-eyeglasses.icon-gps:before, i.ph-eyeglasses.icon-location:before, i.ph-eyeglasses.icon-add:before, i.ph-eyeglasses.icon-left:before, i.ph-eyeglasses.icon-dna:before, i.ph-eyeglasses.icon-delete:before, i.ph-eyeglasses.icon-edit:before, i.ph-eyeglasses.icon-info:before {
  content: "\e7ba";
}

.ph.ph-eyes:before, i.ph-eyes.icon-dead:before, i.ph-eyes.icon-alive:before, i.ph-eyes.icon-warn:before, i.ph-eyes.icon-sign-out:before, i.ph-eyes.icon-substratum:before, i.ph-eyes.icon-environment:before, i.ph-eyes.icon-user:before, i.ph-eyes.icon-person:before, i.ph-eyes.icon-terrain:before, i.ph-eyes.icon-taxon:before, i.ph-eyes.icon-unknown:before, i.ph-eyes.icon-nothing:before, i.ph-eyes.icon-sampling:before, i.ph-eyes.icon-isolation:before, i.ph-eyes.icon-strain:before, i.ph-eyes.icon-gps:before, i.ph-eyes.icon-location:before, i.ph-eyes.icon-add:before, i.ph-eyes.icon-left:before, i.ph-eyes.icon-dna:before, i.ph-eyes.icon-delete:before, i.ph-eyes.icon-edit:before, i.ph-eyes.icon-info:before {
  content: "\ee5c";
}

.ph.ph-face-mask:before, i.ph-face-mask.icon-dead:before, i.ph-face-mask.icon-alive:before, i.ph-face-mask.icon-warn:before, i.ph-face-mask.icon-sign-out:before, i.ph-face-mask.icon-substratum:before, i.ph-face-mask.icon-environment:before, i.ph-face-mask.icon-user:before, i.ph-face-mask.icon-person:before, i.ph-face-mask.icon-terrain:before, i.ph-face-mask.icon-taxon:before, i.ph-face-mask.icon-unknown:before, i.ph-face-mask.icon-nothing:before, i.ph-face-mask.icon-sampling:before, i.ph-face-mask.icon-isolation:before, i.ph-face-mask.icon-strain:before, i.ph-face-mask.icon-gps:before, i.ph-face-mask.icon-location:before, i.ph-face-mask.icon-add:before, i.ph-face-mask.icon-left:before, i.ph-face-mask.icon-dna:before, i.ph-face-mask.icon-delete:before, i.ph-face-mask.icon-edit:before, i.ph-face-mask.icon-info:before {
  content: "\e56a";
}

.ph.ph-facebook-logo:before, i.ph-facebook-logo.icon-dead:before, i.ph-facebook-logo.icon-alive:before, i.ph-facebook-logo.icon-warn:before, i.ph-facebook-logo.icon-sign-out:before, i.ph-facebook-logo.icon-substratum:before, i.ph-facebook-logo.icon-environment:before, i.ph-facebook-logo.icon-user:before, i.ph-facebook-logo.icon-person:before, i.ph-facebook-logo.icon-terrain:before, i.ph-facebook-logo.icon-taxon:before, i.ph-facebook-logo.icon-unknown:before, i.ph-facebook-logo.icon-nothing:before, i.ph-facebook-logo.icon-sampling:before, i.ph-facebook-logo.icon-isolation:before, i.ph-facebook-logo.icon-strain:before, i.ph-facebook-logo.icon-gps:before, i.ph-facebook-logo.icon-location:before, i.ph-facebook-logo.icon-add:before, i.ph-facebook-logo.icon-left:before, i.ph-facebook-logo.icon-dna:before, i.ph-facebook-logo.icon-delete:before, i.ph-facebook-logo.icon-edit:before, i.ph-facebook-logo.icon-info:before {
  content: "\e226";
}

.ph.ph-factory:before, i.ph-factory.icon-dead:before, i.ph-factory.icon-alive:before, i.ph-factory.icon-warn:before, i.ph-factory.icon-sign-out:before, i.ph-factory.icon-substratum:before, i.ph-factory.icon-environment:before, i.ph-factory.icon-user:before, i.ph-factory.icon-person:before, i.ph-factory.icon-terrain:before, i.ph-factory.icon-taxon:before, i.ph-factory.icon-unknown:before, i.ph-factory.icon-nothing:before, i.ph-factory.icon-sampling:before, i.ph-factory.icon-isolation:before, i.ph-factory.icon-strain:before, i.ph-factory.icon-gps:before, i.ph-factory.icon-location:before, i.ph-factory.icon-add:before, i.ph-factory.icon-left:before, i.ph-factory.icon-dna:before, i.ph-factory.icon-delete:before, i.ph-factory.icon-edit:before, i.ph-factory.icon-info:before {
  content: "\e760";
}

.ph.ph-faders:before, i.ph-faders.icon-dead:before, i.ph-faders.icon-alive:before, i.ph-faders.icon-warn:before, i.ph-faders.icon-sign-out:before, i.ph-faders.icon-substratum:before, i.ph-faders.icon-environment:before, i.ph-faders.icon-user:before, i.ph-faders.icon-person:before, i.ph-faders.icon-terrain:before, i.ph-faders.icon-taxon:before, i.ph-faders.icon-unknown:before, i.ph-faders.icon-nothing:before, i.ph-faders.icon-sampling:before, i.ph-faders.icon-isolation:before, i.ph-faders.icon-strain:before, i.ph-faders.icon-gps:before, i.ph-faders.icon-location:before, i.ph-faders.icon-add:before, i.ph-faders.icon-left:before, i.ph-faders.icon-dna:before, i.ph-faders.icon-delete:before, i.ph-faders.icon-edit:before, i.ph-faders.icon-info:before {
  content: "\e228";
}

.ph.ph-faders-horizontal:before, i.ph-faders-horizontal.icon-dead:before, i.ph-faders-horizontal.icon-alive:before, i.ph-faders-horizontal.icon-warn:before, i.ph-faders-horizontal.icon-sign-out:before, i.ph-faders-horizontal.icon-substratum:before, i.ph-faders-horizontal.icon-environment:before, i.ph-faders-horizontal.icon-user:before, i.ph-faders-horizontal.icon-person:before, i.ph-faders-horizontal.icon-terrain:before, i.ph-faders-horizontal.icon-taxon:before, i.ph-faders-horizontal.icon-unknown:before, i.ph-faders-horizontal.icon-nothing:before, i.ph-faders-horizontal.icon-sampling:before, i.ph-faders-horizontal.icon-isolation:before, i.ph-faders-horizontal.icon-strain:before, i.ph-faders-horizontal.icon-gps:before, i.ph-faders-horizontal.icon-location:before, i.ph-faders-horizontal.icon-add:before, i.ph-faders-horizontal.icon-left:before, i.ph-faders-horizontal.icon-dna:before, i.ph-faders-horizontal.icon-delete:before, i.ph-faders-horizontal.icon-edit:before, i.ph-faders-horizontal.icon-info:before {
  content: "\e22a";
}

.ph.ph-fallout-shelter:before, i.ph-fallout-shelter.icon-dead:before, i.ph-fallout-shelter.icon-alive:before, i.ph-fallout-shelter.icon-warn:before, i.ph-fallout-shelter.icon-sign-out:before, i.ph-fallout-shelter.icon-substratum:before, i.ph-fallout-shelter.icon-environment:before, i.ph-fallout-shelter.icon-user:before, i.ph-fallout-shelter.icon-person:before, i.ph-fallout-shelter.icon-terrain:before, i.ph-fallout-shelter.icon-taxon:before, i.ph-fallout-shelter.icon-unknown:before, i.ph-fallout-shelter.icon-nothing:before, i.ph-fallout-shelter.icon-sampling:before, i.ph-fallout-shelter.icon-isolation:before, i.ph-fallout-shelter.icon-strain:before, i.ph-fallout-shelter.icon-gps:before, i.ph-fallout-shelter.icon-location:before, i.ph-fallout-shelter.icon-add:before, i.ph-fallout-shelter.icon-left:before, i.ph-fallout-shelter.icon-dna:before, i.ph-fallout-shelter.icon-delete:before, i.ph-fallout-shelter.icon-edit:before, i.ph-fallout-shelter.icon-info:before {
  content: "\e9de";
}

.ph.ph-fan:before, i.ph-fan.icon-dead:before, i.ph-fan.icon-alive:before, i.ph-fan.icon-warn:before, i.ph-fan.icon-sign-out:before, i.ph-fan.icon-substratum:before, i.ph-fan.icon-environment:before, i.ph-fan.icon-user:before, i.ph-fan.icon-person:before, i.ph-fan.icon-terrain:before, i.ph-fan.icon-taxon:before, i.ph-fan.icon-unknown:before, i.ph-fan.icon-nothing:before, i.ph-fan.icon-sampling:before, i.ph-fan.icon-isolation:before, i.ph-fan.icon-strain:before, i.ph-fan.icon-gps:before, i.ph-fan.icon-location:before, i.ph-fan.icon-add:before, i.ph-fan.icon-left:before, i.ph-fan.icon-dna:before, i.ph-fan.icon-delete:before, i.ph-fan.icon-edit:before, i.ph-fan.icon-info:before {
  content: "\e9f2";
}

.ph.ph-farm:before, i.ph-farm.icon-dead:before, i.ph-farm.icon-alive:before, i.ph-farm.icon-warn:before, i.ph-farm.icon-sign-out:before, i.ph-farm.icon-substratum:before, i.ph-farm.icon-environment:before, i.ph-farm.icon-user:before, i.ph-farm.icon-person:before, i.icon-terrain:before, i.ph-farm.icon-taxon:before, i.ph-farm.icon-unknown:before, i.ph-farm.icon-nothing:before, i.ph-farm.icon-sampling:before, i.ph-farm.icon-isolation:before, i.ph-farm.icon-strain:before, i.ph-farm.icon-gps:before, i.ph-farm.icon-location:before, i.ph-farm.icon-add:before, i.ph-farm.icon-left:before, i.ph-farm.icon-dna:before, i.ph-farm.icon-delete:before, i.ph-farm.icon-edit:before, i.ph-farm.icon-info:before {
  content: "\ec70";
}

.ph.ph-fast-forward:before, i.ph-fast-forward.icon-dead:before, i.ph-fast-forward.icon-alive:before, i.ph-fast-forward.icon-warn:before, i.ph-fast-forward.icon-sign-out:before, i.ph-fast-forward.icon-substratum:before, i.ph-fast-forward.icon-environment:before, i.ph-fast-forward.icon-user:before, i.ph-fast-forward.icon-person:before, i.ph-fast-forward.icon-terrain:before, i.ph-fast-forward.icon-taxon:before, i.ph-fast-forward.icon-unknown:before, i.ph-fast-forward.icon-nothing:before, i.ph-fast-forward.icon-sampling:before, i.ph-fast-forward.icon-isolation:before, i.ph-fast-forward.icon-strain:before, i.ph-fast-forward.icon-gps:before, i.ph-fast-forward.icon-location:before, i.ph-fast-forward.icon-add:before, i.ph-fast-forward.icon-left:before, i.ph-fast-forward.icon-dna:before, i.ph-fast-forward.icon-delete:before, i.ph-fast-forward.icon-edit:before, i.ph-fast-forward.icon-info:before {
  content: "\e6a6";
}

.ph.ph-fast-forward-circle:before, i.ph-fast-forward-circle.icon-dead:before, i.ph-fast-forward-circle.icon-alive:before, i.ph-fast-forward-circle.icon-warn:before, i.ph-fast-forward-circle.icon-sign-out:before, i.ph-fast-forward-circle.icon-substratum:before, i.ph-fast-forward-circle.icon-environment:before, i.ph-fast-forward-circle.icon-user:before, i.ph-fast-forward-circle.icon-person:before, i.ph-fast-forward-circle.icon-terrain:before, i.ph-fast-forward-circle.icon-taxon:before, i.ph-fast-forward-circle.icon-unknown:before, i.ph-fast-forward-circle.icon-nothing:before, i.ph-fast-forward-circle.icon-sampling:before, i.ph-fast-forward-circle.icon-isolation:before, i.ph-fast-forward-circle.icon-strain:before, i.ph-fast-forward-circle.icon-gps:before, i.ph-fast-forward-circle.icon-location:before, i.ph-fast-forward-circle.icon-add:before, i.ph-fast-forward-circle.icon-left:before, i.ph-fast-forward-circle.icon-dna:before, i.ph-fast-forward-circle.icon-delete:before, i.ph-fast-forward-circle.icon-edit:before, i.ph-fast-forward-circle.icon-info:before {
  content: "\e22c";
}

.ph.ph-feather:before, i.ph-feather.icon-dead:before, i.ph-feather.icon-alive:before, i.ph-feather.icon-warn:before, i.ph-feather.icon-sign-out:before, i.ph-feather.icon-substratum:before, i.ph-feather.icon-environment:before, i.ph-feather.icon-user:before, i.ph-feather.icon-person:before, i.ph-feather.icon-terrain:before, i.ph-feather.icon-taxon:before, i.ph-feather.icon-unknown:before, i.ph-feather.icon-nothing:before, i.ph-feather.icon-sampling:before, i.ph-feather.icon-isolation:before, i.ph-feather.icon-strain:before, i.ph-feather.icon-gps:before, i.ph-feather.icon-location:before, i.ph-feather.icon-add:before, i.ph-feather.icon-left:before, i.ph-feather.icon-dna:before, i.ph-feather.icon-delete:before, i.ph-feather.icon-edit:before, i.ph-feather.icon-info:before {
  content: "\e9c0";
}

.ph.ph-fediverse-logo:before, i.ph-fediverse-logo.icon-dead:before, i.ph-fediverse-logo.icon-alive:before, i.ph-fediverse-logo.icon-warn:before, i.ph-fediverse-logo.icon-sign-out:before, i.ph-fediverse-logo.icon-substratum:before, i.ph-fediverse-logo.icon-environment:before, i.ph-fediverse-logo.icon-user:before, i.ph-fediverse-logo.icon-person:before, i.ph-fediverse-logo.icon-terrain:before, i.ph-fediverse-logo.icon-taxon:before, i.ph-fediverse-logo.icon-unknown:before, i.ph-fediverse-logo.icon-nothing:before, i.ph-fediverse-logo.icon-sampling:before, i.ph-fediverse-logo.icon-isolation:before, i.ph-fediverse-logo.icon-strain:before, i.ph-fediverse-logo.icon-gps:before, i.ph-fediverse-logo.icon-location:before, i.ph-fediverse-logo.icon-add:before, i.ph-fediverse-logo.icon-left:before, i.ph-fediverse-logo.icon-dna:before, i.ph-fediverse-logo.icon-delete:before, i.ph-fediverse-logo.icon-edit:before, i.ph-fediverse-logo.icon-info:before {
  content: "\ed66";
}

.ph.ph-figma-logo:before, i.ph-figma-logo.icon-dead:before, i.ph-figma-logo.icon-alive:before, i.ph-figma-logo.icon-warn:before, i.ph-figma-logo.icon-sign-out:before, i.ph-figma-logo.icon-substratum:before, i.ph-figma-logo.icon-environment:before, i.ph-figma-logo.icon-user:before, i.ph-figma-logo.icon-person:before, i.ph-figma-logo.icon-terrain:before, i.ph-figma-logo.icon-taxon:before, i.ph-figma-logo.icon-unknown:before, i.ph-figma-logo.icon-nothing:before, i.ph-figma-logo.icon-sampling:before, i.ph-figma-logo.icon-isolation:before, i.ph-figma-logo.icon-strain:before, i.ph-figma-logo.icon-gps:before, i.ph-figma-logo.icon-location:before, i.ph-figma-logo.icon-add:before, i.ph-figma-logo.icon-left:before, i.ph-figma-logo.icon-dna:before, i.ph-figma-logo.icon-delete:before, i.ph-figma-logo.icon-edit:before, i.ph-figma-logo.icon-info:before {
  content: "\e22e";
}

.ph.ph-file:before, i.ph-file.icon-dead:before, i.ph-file.icon-alive:before, i.ph-file.icon-warn:before, i.ph-file.icon-sign-out:before, i.ph-file.icon-substratum:before, i.ph-file.icon-environment:before, i.ph-file.icon-user:before, i.ph-file.icon-person:before, i.ph-file.icon-terrain:before, i.ph-file.icon-taxon:before, i.ph-file.icon-unknown:before, i.ph-file.icon-nothing:before, i.ph-file.icon-sampling:before, i.ph-file.icon-isolation:before, i.ph-file.icon-strain:before, i.ph-file.icon-gps:before, i.ph-file.icon-location:before, i.ph-file.icon-add:before, i.ph-file.icon-left:before, i.ph-file.icon-dna:before, i.ph-file.icon-delete:before, i.ph-file.icon-edit:before, i.ph-file.icon-info:before {
  content: "\e230";
}

.ph.ph-file-archive:before, i.ph-file-archive.icon-dead:before, i.ph-file-archive.icon-alive:before, i.ph-file-archive.icon-warn:before, i.ph-file-archive.icon-sign-out:before, i.ph-file-archive.icon-substratum:before, i.ph-file-archive.icon-environment:before, i.ph-file-archive.icon-user:before, i.ph-file-archive.icon-person:before, i.ph-file-archive.icon-terrain:before, i.ph-file-archive.icon-taxon:before, i.ph-file-archive.icon-unknown:before, i.ph-file-archive.icon-nothing:before, i.ph-file-archive.icon-sampling:before, i.ph-file-archive.icon-isolation:before, i.ph-file-archive.icon-strain:before, i.ph-file-archive.icon-gps:before, i.ph-file-archive.icon-location:before, i.ph-file-archive.icon-add:before, i.ph-file-archive.icon-left:before, i.ph-file-archive.icon-dna:before, i.ph-file-archive.icon-delete:before, i.ph-file-archive.icon-edit:before, i.ph-file-archive.icon-info:before {
  content: "\eb2a";
}

.ph.ph-file-arrow-down:before, i.ph-file-arrow-down.icon-dead:before, i.ph-file-arrow-down.icon-alive:before, i.ph-file-arrow-down.icon-warn:before, i.ph-file-arrow-down.icon-sign-out:before, i.ph-file-arrow-down.icon-substratum:before, i.ph-file-arrow-down.icon-environment:before, i.ph-file-arrow-down.icon-user:before, i.ph-file-arrow-down.icon-person:before, i.ph-file-arrow-down.icon-terrain:before, i.ph-file-arrow-down.icon-taxon:before, i.ph-file-arrow-down.icon-unknown:before, i.ph-file-arrow-down.icon-nothing:before, i.ph-file-arrow-down.icon-sampling:before, i.ph-file-arrow-down.icon-isolation:before, i.ph-file-arrow-down.icon-strain:before, i.ph-file-arrow-down.icon-gps:before, i.ph-file-arrow-down.icon-location:before, i.ph-file-arrow-down.icon-add:before, i.ph-file-arrow-down.icon-left:before, i.ph-file-arrow-down.icon-dna:before, i.ph-file-arrow-down.icon-delete:before, i.ph-file-arrow-down.icon-edit:before, i.ph-file-arrow-down.icon-info:before {
  content: "\e232";
}

.ph.ph-file-arrow-up:before, i.ph-file-arrow-up.icon-dead:before, i.ph-file-arrow-up.icon-alive:before, i.ph-file-arrow-up.icon-warn:before, i.ph-file-arrow-up.icon-sign-out:before, i.ph-file-arrow-up.icon-substratum:before, i.ph-file-arrow-up.icon-environment:before, i.ph-file-arrow-up.icon-user:before, i.ph-file-arrow-up.icon-person:before, i.ph-file-arrow-up.icon-terrain:before, i.ph-file-arrow-up.icon-taxon:before, i.ph-file-arrow-up.icon-unknown:before, i.ph-file-arrow-up.icon-nothing:before, i.ph-file-arrow-up.icon-sampling:before, i.ph-file-arrow-up.icon-isolation:before, i.ph-file-arrow-up.icon-strain:before, i.ph-file-arrow-up.icon-gps:before, i.ph-file-arrow-up.icon-location:before, i.ph-file-arrow-up.icon-add:before, i.ph-file-arrow-up.icon-left:before, i.ph-file-arrow-up.icon-dna:before, i.ph-file-arrow-up.icon-delete:before, i.ph-file-arrow-up.icon-edit:before, i.ph-file-arrow-up.icon-info:before {
  content: "\e61e";
}

.ph.ph-file-audio:before, i.ph-file-audio.icon-dead:before, i.ph-file-audio.icon-alive:before, i.ph-file-audio.icon-warn:before, i.ph-file-audio.icon-sign-out:before, i.ph-file-audio.icon-substratum:before, i.ph-file-audio.icon-environment:before, i.ph-file-audio.icon-user:before, i.ph-file-audio.icon-person:before, i.ph-file-audio.icon-terrain:before, i.ph-file-audio.icon-taxon:before, i.ph-file-audio.icon-unknown:before, i.ph-file-audio.icon-nothing:before, i.ph-file-audio.icon-sampling:before, i.ph-file-audio.icon-isolation:before, i.ph-file-audio.icon-strain:before, i.ph-file-audio.icon-gps:before, i.ph-file-audio.icon-location:before, i.ph-file-audio.icon-add:before, i.ph-file-audio.icon-left:before, i.ph-file-audio.icon-dna:before, i.ph-file-audio.icon-delete:before, i.ph-file-audio.icon-edit:before, i.ph-file-audio.icon-info:before {
  content: "\ea20";
}

.ph.ph-file-c:before, i.ph-file-c.icon-dead:before, i.ph-file-c.icon-alive:before, i.ph-file-c.icon-warn:before, i.ph-file-c.icon-sign-out:before, i.ph-file-c.icon-substratum:before, i.ph-file-c.icon-environment:before, i.ph-file-c.icon-user:before, i.ph-file-c.icon-person:before, i.ph-file-c.icon-terrain:before, i.ph-file-c.icon-taxon:before, i.ph-file-c.icon-unknown:before, i.ph-file-c.icon-nothing:before, i.ph-file-c.icon-sampling:before, i.ph-file-c.icon-isolation:before, i.ph-file-c.icon-strain:before, i.ph-file-c.icon-gps:before, i.ph-file-c.icon-location:before, i.ph-file-c.icon-add:before, i.ph-file-c.icon-left:before, i.ph-file-c.icon-dna:before, i.ph-file-c.icon-delete:before, i.ph-file-c.icon-edit:before, i.ph-file-c.icon-info:before {
  content: "\eb32";
}

.ph.ph-file-c-sharp:before, i.ph-file-c-sharp.icon-dead:before, i.ph-file-c-sharp.icon-alive:before, i.ph-file-c-sharp.icon-warn:before, i.ph-file-c-sharp.icon-sign-out:before, i.ph-file-c-sharp.icon-substratum:before, i.ph-file-c-sharp.icon-environment:before, i.ph-file-c-sharp.icon-user:before, i.ph-file-c-sharp.icon-person:before, i.ph-file-c-sharp.icon-terrain:before, i.ph-file-c-sharp.icon-taxon:before, i.ph-file-c-sharp.icon-unknown:before, i.ph-file-c-sharp.icon-nothing:before, i.ph-file-c-sharp.icon-sampling:before, i.ph-file-c-sharp.icon-isolation:before, i.ph-file-c-sharp.icon-strain:before, i.ph-file-c-sharp.icon-gps:before, i.ph-file-c-sharp.icon-location:before, i.ph-file-c-sharp.icon-add:before, i.ph-file-c-sharp.icon-left:before, i.ph-file-c-sharp.icon-dna:before, i.ph-file-c-sharp.icon-delete:before, i.ph-file-c-sharp.icon-edit:before, i.ph-file-c-sharp.icon-info:before {
  content: "\eb30";
}

.ph.ph-file-cloud:before, i.ph-file-cloud.icon-dead:before, i.ph-file-cloud.icon-alive:before, i.ph-file-cloud.icon-warn:before, i.ph-file-cloud.icon-sign-out:before, i.ph-file-cloud.icon-substratum:before, i.ph-file-cloud.icon-environment:before, i.ph-file-cloud.icon-user:before, i.ph-file-cloud.icon-person:before, i.ph-file-cloud.icon-terrain:before, i.ph-file-cloud.icon-taxon:before, i.ph-file-cloud.icon-unknown:before, i.ph-file-cloud.icon-nothing:before, i.ph-file-cloud.icon-sampling:before, i.ph-file-cloud.icon-isolation:before, i.ph-file-cloud.icon-strain:before, i.ph-file-cloud.icon-gps:before, i.ph-file-cloud.icon-location:before, i.ph-file-cloud.icon-add:before, i.ph-file-cloud.icon-left:before, i.ph-file-cloud.icon-dna:before, i.ph-file-cloud.icon-delete:before, i.ph-file-cloud.icon-edit:before, i.ph-file-cloud.icon-info:before {
  content: "\e95e";
}

.ph.ph-file-code:before, i.ph-file-code.icon-dead:before, i.ph-file-code.icon-alive:before, i.ph-file-code.icon-warn:before, i.ph-file-code.icon-sign-out:before, i.ph-file-code.icon-substratum:before, i.ph-file-code.icon-environment:before, i.ph-file-code.icon-user:before, i.ph-file-code.icon-person:before, i.ph-file-code.icon-terrain:before, i.ph-file-code.icon-taxon:before, i.ph-file-code.icon-unknown:before, i.ph-file-code.icon-nothing:before, i.ph-file-code.icon-sampling:before, i.ph-file-code.icon-isolation:before, i.ph-file-code.icon-strain:before, i.ph-file-code.icon-gps:before, i.ph-file-code.icon-location:before, i.ph-file-code.icon-add:before, i.ph-file-code.icon-left:before, i.ph-file-code.icon-dna:before, i.ph-file-code.icon-delete:before, i.ph-file-code.icon-edit:before, i.ph-file-code.icon-info:before {
  content: "\e914";
}

.ph.ph-file-cpp:before, i.ph-file-cpp.icon-dead:before, i.ph-file-cpp.icon-alive:before, i.ph-file-cpp.icon-warn:before, i.ph-file-cpp.icon-sign-out:before, i.ph-file-cpp.icon-substratum:before, i.ph-file-cpp.icon-environment:before, i.ph-file-cpp.icon-user:before, i.ph-file-cpp.icon-person:before, i.ph-file-cpp.icon-terrain:before, i.ph-file-cpp.icon-taxon:before, i.ph-file-cpp.icon-unknown:before, i.ph-file-cpp.icon-nothing:before, i.ph-file-cpp.icon-sampling:before, i.ph-file-cpp.icon-isolation:before, i.ph-file-cpp.icon-strain:before, i.ph-file-cpp.icon-gps:before, i.ph-file-cpp.icon-location:before, i.ph-file-cpp.icon-add:before, i.ph-file-cpp.icon-left:before, i.ph-file-cpp.icon-dna:before, i.ph-file-cpp.icon-delete:before, i.ph-file-cpp.icon-edit:before, i.ph-file-cpp.icon-info:before {
  content: "\eb2e";
}

.ph.ph-file-css:before, i.ph-file-css.icon-dead:before, i.ph-file-css.icon-alive:before, i.ph-file-css.icon-warn:before, i.ph-file-css.icon-sign-out:before, i.ph-file-css.icon-substratum:before, i.ph-file-css.icon-environment:before, i.ph-file-css.icon-user:before, i.ph-file-css.icon-person:before, i.ph-file-css.icon-terrain:before, i.ph-file-css.icon-taxon:before, i.ph-file-css.icon-unknown:before, i.ph-file-css.icon-nothing:before, i.ph-file-css.icon-sampling:before, i.ph-file-css.icon-isolation:before, i.ph-file-css.icon-strain:before, i.ph-file-css.icon-gps:before, i.ph-file-css.icon-location:before, i.ph-file-css.icon-add:before, i.ph-file-css.icon-left:before, i.ph-file-css.icon-dna:before, i.ph-file-css.icon-delete:before, i.ph-file-css.icon-edit:before, i.ph-file-css.icon-info:before {
  content: "\eb34";
}

.ph.ph-file-csv:before, i.ph-file-csv.icon-dead:before, i.ph-file-csv.icon-alive:before, i.ph-file-csv.icon-warn:before, i.ph-file-csv.icon-sign-out:before, i.ph-file-csv.icon-substratum:before, i.ph-file-csv.icon-environment:before, i.ph-file-csv.icon-user:before, i.ph-file-csv.icon-person:before, i.ph-file-csv.icon-terrain:before, i.ph-file-csv.icon-taxon:before, i.ph-file-csv.icon-unknown:before, i.ph-file-csv.icon-nothing:before, i.ph-file-csv.icon-sampling:before, i.ph-file-csv.icon-isolation:before, i.ph-file-csv.icon-strain:before, i.ph-file-csv.icon-gps:before, i.ph-file-csv.icon-location:before, i.ph-file-csv.icon-add:before, i.ph-file-csv.icon-left:before, i.ph-file-csv.icon-dna:before, i.ph-file-csv.icon-delete:before, i.ph-file-csv.icon-edit:before, i.ph-file-csv.icon-info:before {
  content: "\eb1c";
}

.ph.ph-file-dashed:before, i.ph-file-dashed.icon-dead:before, i.ph-file-dashed.icon-alive:before, i.ph-file-dashed.icon-warn:before, i.ph-file-dashed.icon-sign-out:before, i.ph-file-dashed.icon-substratum:before, i.ph-file-dashed.icon-environment:before, i.ph-file-dashed.icon-user:before, i.ph-file-dashed.icon-person:before, i.ph-file-dashed.icon-terrain:before, i.ph-file-dashed.icon-taxon:before, i.ph-file-dashed.icon-unknown:before, i.ph-file-dashed.icon-nothing:before, i.ph-file-dashed.icon-sampling:before, i.ph-file-dashed.icon-isolation:before, i.ph-file-dashed.icon-strain:before, i.ph-file-dashed.icon-gps:before, i.ph-file-dashed.icon-location:before, i.ph-file-dashed.icon-add:before, i.ph-file-dashed.icon-left:before, i.ph-file-dashed.icon-dna:before, i.ph-file-dashed.icon-delete:before, i.ph-file-dashed.icon-edit:before, i.ph-file-dashed.icon-info:before {
  content: "\e704";
}

.ph.ph-file-dotted:before, i.ph-file-dotted.icon-dead:before, i.ph-file-dotted.icon-alive:before, i.ph-file-dotted.icon-warn:before, i.ph-file-dotted.icon-sign-out:before, i.ph-file-dotted.icon-substratum:before, i.ph-file-dotted.icon-environment:before, i.ph-file-dotted.icon-user:before, i.ph-file-dotted.icon-person:before, i.ph-file-dotted.icon-terrain:before, i.ph-file-dotted.icon-taxon:before, i.ph-file-dotted.icon-unknown:before, i.ph-file-dotted.icon-nothing:before, i.ph-file-dotted.icon-sampling:before, i.ph-file-dotted.icon-isolation:before, i.ph-file-dotted.icon-strain:before, i.ph-file-dotted.icon-gps:before, i.ph-file-dotted.icon-location:before, i.ph-file-dotted.icon-add:before, i.ph-file-dotted.icon-left:before, i.ph-file-dotted.icon-dna:before, i.ph-file-dotted.icon-delete:before, i.ph-file-dotted.icon-edit:before, i.ph-file-dotted.icon-info:before {
  content: "\e704";
}

.ph.ph-file-doc:before, i.ph-file-doc.icon-dead:before, i.ph-file-doc.icon-alive:before, i.ph-file-doc.icon-warn:before, i.ph-file-doc.icon-sign-out:before, i.ph-file-doc.icon-substratum:before, i.ph-file-doc.icon-environment:before, i.ph-file-doc.icon-user:before, i.ph-file-doc.icon-person:before, i.ph-file-doc.icon-terrain:before, i.ph-file-doc.icon-taxon:before, i.ph-file-doc.icon-unknown:before, i.ph-file-doc.icon-nothing:before, i.ph-file-doc.icon-sampling:before, i.ph-file-doc.icon-isolation:before, i.ph-file-doc.icon-strain:before, i.ph-file-doc.icon-gps:before, i.ph-file-doc.icon-location:before, i.ph-file-doc.icon-add:before, i.ph-file-doc.icon-left:before, i.ph-file-doc.icon-dna:before, i.ph-file-doc.icon-delete:before, i.ph-file-doc.icon-edit:before, i.ph-file-doc.icon-info:before {
  content: "\eb1e";
}

.ph.ph-file-html:before, i.ph-file-html.icon-dead:before, i.ph-file-html.icon-alive:before, i.ph-file-html.icon-warn:before, i.ph-file-html.icon-sign-out:before, i.ph-file-html.icon-substratum:before, i.ph-file-html.icon-environment:before, i.ph-file-html.icon-user:before, i.ph-file-html.icon-person:before, i.ph-file-html.icon-terrain:before, i.ph-file-html.icon-taxon:before, i.ph-file-html.icon-unknown:before, i.ph-file-html.icon-nothing:before, i.ph-file-html.icon-sampling:before, i.ph-file-html.icon-isolation:before, i.ph-file-html.icon-strain:before, i.ph-file-html.icon-gps:before, i.ph-file-html.icon-location:before, i.ph-file-html.icon-add:before, i.ph-file-html.icon-left:before, i.ph-file-html.icon-dna:before, i.ph-file-html.icon-delete:before, i.ph-file-html.icon-edit:before, i.ph-file-html.icon-info:before {
  content: "\eb38";
}

.ph.ph-file-image:before, i.ph-file-image.icon-dead:before, i.ph-file-image.icon-alive:before, i.ph-file-image.icon-warn:before, i.ph-file-image.icon-sign-out:before, i.ph-file-image.icon-substratum:before, i.ph-file-image.icon-environment:before, i.ph-file-image.icon-user:before, i.ph-file-image.icon-person:before, i.ph-file-image.icon-terrain:before, i.ph-file-image.icon-taxon:before, i.ph-file-image.icon-unknown:before, i.ph-file-image.icon-nothing:before, i.ph-file-image.icon-sampling:before, i.ph-file-image.icon-isolation:before, i.ph-file-image.icon-strain:before, i.ph-file-image.icon-gps:before, i.ph-file-image.icon-location:before, i.ph-file-image.icon-add:before, i.ph-file-image.icon-left:before, i.ph-file-image.icon-dna:before, i.ph-file-image.icon-delete:before, i.ph-file-image.icon-edit:before, i.ph-file-image.icon-info:before {
  content: "\ea24";
}

.ph.ph-file-ini:before, i.ph-file-ini.icon-dead:before, i.ph-file-ini.icon-alive:before, i.ph-file-ini.icon-warn:before, i.ph-file-ini.icon-sign-out:before, i.ph-file-ini.icon-substratum:before, i.ph-file-ini.icon-environment:before, i.ph-file-ini.icon-user:before, i.ph-file-ini.icon-person:before, i.ph-file-ini.icon-terrain:before, i.ph-file-ini.icon-taxon:before, i.ph-file-ini.icon-unknown:before, i.ph-file-ini.icon-nothing:before, i.ph-file-ini.icon-sampling:before, i.ph-file-ini.icon-isolation:before, i.ph-file-ini.icon-strain:before, i.ph-file-ini.icon-gps:before, i.ph-file-ini.icon-location:before, i.ph-file-ini.icon-add:before, i.ph-file-ini.icon-left:before, i.ph-file-ini.icon-dna:before, i.ph-file-ini.icon-delete:before, i.ph-file-ini.icon-edit:before, i.ph-file-ini.icon-info:before {
  content: "\eb33";
}

.ph.ph-file-jpg:before, i.ph-file-jpg.icon-dead:before, i.ph-file-jpg.icon-alive:before, i.ph-file-jpg.icon-warn:before, i.ph-file-jpg.icon-sign-out:before, i.ph-file-jpg.icon-substratum:before, i.ph-file-jpg.icon-environment:before, i.ph-file-jpg.icon-user:before, i.ph-file-jpg.icon-person:before, i.ph-file-jpg.icon-terrain:before, i.ph-file-jpg.icon-taxon:before, i.ph-file-jpg.icon-unknown:before, i.ph-file-jpg.icon-nothing:before, i.ph-file-jpg.icon-sampling:before, i.ph-file-jpg.icon-isolation:before, i.ph-file-jpg.icon-strain:before, i.ph-file-jpg.icon-gps:before, i.ph-file-jpg.icon-location:before, i.ph-file-jpg.icon-add:before, i.ph-file-jpg.icon-left:before, i.ph-file-jpg.icon-dna:before, i.ph-file-jpg.icon-delete:before, i.ph-file-jpg.icon-edit:before, i.ph-file-jpg.icon-info:before {
  content: "\eb1a";
}

.ph.ph-file-js:before, i.ph-file-js.icon-dead:before, i.ph-file-js.icon-alive:before, i.ph-file-js.icon-warn:before, i.ph-file-js.icon-sign-out:before, i.ph-file-js.icon-substratum:before, i.ph-file-js.icon-environment:before, i.ph-file-js.icon-user:before, i.ph-file-js.icon-person:before, i.ph-file-js.icon-terrain:before, i.ph-file-js.icon-taxon:before, i.ph-file-js.icon-unknown:before, i.ph-file-js.icon-nothing:before, i.ph-file-js.icon-sampling:before, i.ph-file-js.icon-isolation:before, i.ph-file-js.icon-strain:before, i.ph-file-js.icon-gps:before, i.ph-file-js.icon-location:before, i.ph-file-js.icon-add:before, i.ph-file-js.icon-left:before, i.ph-file-js.icon-dna:before, i.ph-file-js.icon-delete:before, i.ph-file-js.icon-edit:before, i.ph-file-js.icon-info:before {
  content: "\eb24";
}

.ph.ph-file-jsx:before, i.ph-file-jsx.icon-dead:before, i.ph-file-jsx.icon-alive:before, i.ph-file-jsx.icon-warn:before, i.ph-file-jsx.icon-sign-out:before, i.ph-file-jsx.icon-substratum:before, i.ph-file-jsx.icon-environment:before, i.ph-file-jsx.icon-user:before, i.ph-file-jsx.icon-person:before, i.ph-file-jsx.icon-terrain:before, i.ph-file-jsx.icon-taxon:before, i.ph-file-jsx.icon-unknown:before, i.ph-file-jsx.icon-nothing:before, i.ph-file-jsx.icon-sampling:before, i.ph-file-jsx.icon-isolation:before, i.ph-file-jsx.icon-strain:before, i.ph-file-jsx.icon-gps:before, i.ph-file-jsx.icon-location:before, i.ph-file-jsx.icon-add:before, i.ph-file-jsx.icon-left:before, i.ph-file-jsx.icon-dna:before, i.ph-file-jsx.icon-delete:before, i.ph-file-jsx.icon-edit:before, i.ph-file-jsx.icon-info:before {
  content: "\eb3a";
}

.ph.ph-file-lock:before, i.ph-file-lock.icon-dead:before, i.ph-file-lock.icon-alive:before, i.ph-file-lock.icon-warn:before, i.ph-file-lock.icon-sign-out:before, i.ph-file-lock.icon-substratum:before, i.ph-file-lock.icon-environment:before, i.ph-file-lock.icon-user:before, i.ph-file-lock.icon-person:before, i.ph-file-lock.icon-terrain:before, i.ph-file-lock.icon-taxon:before, i.ph-file-lock.icon-unknown:before, i.ph-file-lock.icon-nothing:before, i.ph-file-lock.icon-sampling:before, i.ph-file-lock.icon-isolation:before, i.ph-file-lock.icon-strain:before, i.ph-file-lock.icon-gps:before, i.ph-file-lock.icon-location:before, i.ph-file-lock.icon-add:before, i.ph-file-lock.icon-left:before, i.ph-file-lock.icon-dna:before, i.ph-file-lock.icon-delete:before, i.ph-file-lock.icon-edit:before, i.ph-file-lock.icon-info:before {
  content: "\e95c";
}

.ph.ph-file-magnifying-glass:before, i.ph-file-magnifying-glass.icon-dead:before, i.ph-file-magnifying-glass.icon-alive:before, i.ph-file-magnifying-glass.icon-warn:before, i.ph-file-magnifying-glass.icon-sign-out:before, i.ph-file-magnifying-glass.icon-substratum:before, i.ph-file-magnifying-glass.icon-environment:before, i.ph-file-magnifying-glass.icon-user:before, i.ph-file-magnifying-glass.icon-person:before, i.ph-file-magnifying-glass.icon-terrain:before, i.ph-file-magnifying-glass.icon-taxon:before, i.ph-file-magnifying-glass.icon-unknown:before, i.ph-file-magnifying-glass.icon-nothing:before, i.ph-file-magnifying-glass.icon-sampling:before, i.ph-file-magnifying-glass.icon-isolation:before, i.ph-file-magnifying-glass.icon-strain:before, i.ph-file-magnifying-glass.icon-gps:before, i.ph-file-magnifying-glass.icon-location:before, i.ph-file-magnifying-glass.icon-add:before, i.ph-file-magnifying-glass.icon-left:before, i.ph-file-magnifying-glass.icon-dna:before, i.ph-file-magnifying-glass.icon-delete:before, i.ph-file-magnifying-glass.icon-edit:before, i.ph-file-magnifying-glass.icon-info:before {
  content: "\e238";
}

.ph.ph-file-search:before, i.ph-file-search.icon-dead:before, i.ph-file-search.icon-alive:before, i.ph-file-search.icon-warn:before, i.ph-file-search.icon-sign-out:before, i.ph-file-search.icon-substratum:before, i.ph-file-search.icon-environment:before, i.ph-file-search.icon-user:before, i.ph-file-search.icon-person:before, i.ph-file-search.icon-terrain:before, i.ph-file-search.icon-taxon:before, i.ph-file-search.icon-unknown:before, i.ph-file-search.icon-nothing:before, i.ph-file-search.icon-sampling:before, i.ph-file-search.icon-isolation:before, i.ph-file-search.icon-strain:before, i.ph-file-search.icon-gps:before, i.ph-file-search.icon-location:before, i.ph-file-search.icon-add:before, i.ph-file-search.icon-left:before, i.ph-file-search.icon-dna:before, i.ph-file-search.icon-delete:before, i.ph-file-search.icon-edit:before, i.ph-file-search.icon-info:before {
  content: "\e238";
}

.ph.ph-file-md:before, i.ph-file-md.icon-dead:before, i.ph-file-md.icon-alive:before, i.ph-file-md.icon-warn:before, i.ph-file-md.icon-sign-out:before, i.ph-file-md.icon-substratum:before, i.ph-file-md.icon-environment:before, i.ph-file-md.icon-user:before, i.ph-file-md.icon-person:before, i.ph-file-md.icon-terrain:before, i.ph-file-md.icon-taxon:before, i.ph-file-md.icon-unknown:before, i.ph-file-md.icon-nothing:before, i.ph-file-md.icon-sampling:before, i.ph-file-md.icon-isolation:before, i.ph-file-md.icon-strain:before, i.ph-file-md.icon-gps:before, i.ph-file-md.icon-location:before, i.ph-file-md.icon-add:before, i.ph-file-md.icon-left:before, i.ph-file-md.icon-dna:before, i.ph-file-md.icon-delete:before, i.ph-file-md.icon-edit:before, i.ph-file-md.icon-info:before {
  content: "\ed50";
}

.ph.ph-file-minus:before, i.ph-file-minus.icon-dead:before, i.ph-file-minus.icon-alive:before, i.ph-file-minus.icon-warn:before, i.ph-file-minus.icon-sign-out:before, i.ph-file-minus.icon-substratum:before, i.ph-file-minus.icon-environment:before, i.ph-file-minus.icon-user:before, i.ph-file-minus.icon-person:before, i.ph-file-minus.icon-terrain:before, i.ph-file-minus.icon-taxon:before, i.ph-file-minus.icon-unknown:before, i.ph-file-minus.icon-nothing:before, i.ph-file-minus.icon-sampling:before, i.ph-file-minus.icon-isolation:before, i.ph-file-minus.icon-strain:before, i.ph-file-minus.icon-gps:before, i.ph-file-minus.icon-location:before, i.ph-file-minus.icon-add:before, i.ph-file-minus.icon-left:before, i.ph-file-minus.icon-dna:before, i.ph-file-minus.icon-delete:before, i.ph-file-minus.icon-edit:before, i.ph-file-minus.icon-info:before {
  content: "\e234";
}

.ph.ph-file-pdf:before, i.ph-file-pdf.icon-dead:before, i.ph-file-pdf.icon-alive:before, i.ph-file-pdf.icon-warn:before, i.ph-file-pdf.icon-sign-out:before, i.ph-file-pdf.icon-substratum:before, i.ph-file-pdf.icon-environment:before, i.ph-file-pdf.icon-user:before, i.ph-file-pdf.icon-person:before, i.ph-file-pdf.icon-terrain:before, i.ph-file-pdf.icon-taxon:before, i.ph-file-pdf.icon-unknown:before, i.ph-file-pdf.icon-nothing:before, i.ph-file-pdf.icon-sampling:before, i.ph-file-pdf.icon-isolation:before, i.ph-file-pdf.icon-strain:before, i.ph-file-pdf.icon-gps:before, i.ph-file-pdf.icon-location:before, i.ph-file-pdf.icon-add:before, i.ph-file-pdf.icon-left:before, i.ph-file-pdf.icon-dna:before, i.ph-file-pdf.icon-delete:before, i.ph-file-pdf.icon-edit:before, i.ph-file-pdf.icon-info:before {
  content: "\e702";
}

.ph.ph-file-plus:before, i.ph-file-plus.icon-dead:before, i.ph-file-plus.icon-alive:before, i.ph-file-plus.icon-warn:before, i.ph-file-plus.icon-sign-out:before, i.ph-file-plus.icon-substratum:before, i.ph-file-plus.icon-environment:before, i.ph-file-plus.icon-user:before, i.ph-file-plus.icon-person:before, i.ph-file-plus.icon-terrain:before, i.ph-file-plus.icon-taxon:before, i.ph-file-plus.icon-unknown:before, i.ph-file-plus.icon-nothing:before, i.ph-file-plus.icon-sampling:before, i.ph-file-plus.icon-isolation:before, i.ph-file-plus.icon-strain:before, i.ph-file-plus.icon-gps:before, i.ph-file-plus.icon-location:before, i.ph-file-plus.icon-add:before, i.ph-file-plus.icon-left:before, i.ph-file-plus.icon-dna:before, i.ph-file-plus.icon-delete:before, i.ph-file-plus.icon-edit:before, i.ph-file-plus.icon-info:before {
  content: "\e236";
}

.ph.ph-file-png:before, i.ph-file-png.icon-dead:before, i.ph-file-png.icon-alive:before, i.ph-file-png.icon-warn:before, i.ph-file-png.icon-sign-out:before, i.ph-file-png.icon-substratum:before, i.ph-file-png.icon-environment:before, i.ph-file-png.icon-user:before, i.ph-file-png.icon-person:before, i.ph-file-png.icon-terrain:before, i.ph-file-png.icon-taxon:before, i.ph-file-png.icon-unknown:before, i.ph-file-png.icon-nothing:before, i.ph-file-png.icon-sampling:before, i.ph-file-png.icon-isolation:before, i.ph-file-png.icon-strain:before, i.ph-file-png.icon-gps:before, i.ph-file-png.icon-location:before, i.ph-file-png.icon-add:before, i.ph-file-png.icon-left:before, i.ph-file-png.icon-dna:before, i.ph-file-png.icon-delete:before, i.ph-file-png.icon-edit:before, i.ph-file-png.icon-info:before {
  content: "\eb18";
}

.ph.ph-file-ppt:before, i.ph-file-ppt.icon-dead:before, i.ph-file-ppt.icon-alive:before, i.ph-file-ppt.icon-warn:before, i.ph-file-ppt.icon-sign-out:before, i.ph-file-ppt.icon-substratum:before, i.ph-file-ppt.icon-environment:before, i.ph-file-ppt.icon-user:before, i.ph-file-ppt.icon-person:before, i.ph-file-ppt.icon-terrain:before, i.ph-file-ppt.icon-taxon:before, i.ph-file-ppt.icon-unknown:before, i.ph-file-ppt.icon-nothing:before, i.ph-file-ppt.icon-sampling:before, i.ph-file-ppt.icon-isolation:before, i.ph-file-ppt.icon-strain:before, i.ph-file-ppt.icon-gps:before, i.ph-file-ppt.icon-location:before, i.ph-file-ppt.icon-add:before, i.ph-file-ppt.icon-left:before, i.ph-file-ppt.icon-dna:before, i.ph-file-ppt.icon-delete:before, i.ph-file-ppt.icon-edit:before, i.ph-file-ppt.icon-info:before {
  content: "\eb20";
}

.ph.ph-file-py:before, i.ph-file-py.icon-dead:before, i.ph-file-py.icon-alive:before, i.ph-file-py.icon-warn:before, i.ph-file-py.icon-sign-out:before, i.ph-file-py.icon-substratum:before, i.ph-file-py.icon-environment:before, i.ph-file-py.icon-user:before, i.ph-file-py.icon-person:before, i.ph-file-py.icon-terrain:before, i.ph-file-py.icon-taxon:before, i.ph-file-py.icon-unknown:before, i.ph-file-py.icon-nothing:before, i.ph-file-py.icon-sampling:before, i.ph-file-py.icon-isolation:before, i.ph-file-py.icon-strain:before, i.ph-file-py.icon-gps:before, i.ph-file-py.icon-location:before, i.ph-file-py.icon-add:before, i.ph-file-py.icon-left:before, i.ph-file-py.icon-dna:before, i.ph-file-py.icon-delete:before, i.ph-file-py.icon-edit:before, i.ph-file-py.icon-info:before {
  content: "\eb2c";
}

.ph.ph-file-rs:before, i.ph-file-rs.icon-dead:before, i.ph-file-rs.icon-alive:before, i.ph-file-rs.icon-warn:before, i.ph-file-rs.icon-sign-out:before, i.ph-file-rs.icon-substratum:before, i.ph-file-rs.icon-environment:before, i.ph-file-rs.icon-user:before, i.ph-file-rs.icon-person:before, i.ph-file-rs.icon-terrain:before, i.ph-file-rs.icon-taxon:before, i.ph-file-rs.icon-unknown:before, i.ph-file-rs.icon-nothing:before, i.ph-file-rs.icon-sampling:before, i.ph-file-rs.icon-isolation:before, i.ph-file-rs.icon-strain:before, i.ph-file-rs.icon-gps:before, i.ph-file-rs.icon-location:before, i.ph-file-rs.icon-add:before, i.ph-file-rs.icon-left:before, i.ph-file-rs.icon-dna:before, i.ph-file-rs.icon-delete:before, i.ph-file-rs.icon-edit:before, i.ph-file-rs.icon-info:before {
  content: "\eb28";
}

.ph.ph-file-sql:before, i.ph-file-sql.icon-dead:before, i.ph-file-sql.icon-alive:before, i.ph-file-sql.icon-warn:before, i.ph-file-sql.icon-sign-out:before, i.ph-file-sql.icon-substratum:before, i.ph-file-sql.icon-environment:before, i.ph-file-sql.icon-user:before, i.ph-file-sql.icon-person:before, i.ph-file-sql.icon-terrain:before, i.ph-file-sql.icon-taxon:before, i.ph-file-sql.icon-unknown:before, i.ph-file-sql.icon-nothing:before, i.ph-file-sql.icon-sampling:before, i.ph-file-sql.icon-isolation:before, i.ph-file-sql.icon-strain:before, i.ph-file-sql.icon-gps:before, i.ph-file-sql.icon-location:before, i.ph-file-sql.icon-add:before, i.ph-file-sql.icon-left:before, i.ph-file-sql.icon-dna:before, i.ph-file-sql.icon-delete:before, i.ph-file-sql.icon-edit:before, i.ph-file-sql.icon-info:before {
  content: "\ed4e";
}

.ph.ph-file-svg:before, i.ph-file-svg.icon-dead:before, i.ph-file-svg.icon-alive:before, i.ph-file-svg.icon-warn:before, i.ph-file-svg.icon-sign-out:before, i.ph-file-svg.icon-substratum:before, i.ph-file-svg.icon-environment:before, i.ph-file-svg.icon-user:before, i.ph-file-svg.icon-person:before, i.ph-file-svg.icon-terrain:before, i.ph-file-svg.icon-taxon:before, i.ph-file-svg.icon-unknown:before, i.ph-file-svg.icon-nothing:before, i.ph-file-svg.icon-sampling:before, i.ph-file-svg.icon-isolation:before, i.ph-file-svg.icon-strain:before, i.ph-file-svg.icon-gps:before, i.ph-file-svg.icon-location:before, i.ph-file-svg.icon-add:before, i.ph-file-svg.icon-left:before, i.ph-file-svg.icon-dna:before, i.ph-file-svg.icon-delete:before, i.ph-file-svg.icon-edit:before, i.ph-file-svg.icon-info:before {
  content: "\ed08";
}

.ph.ph-file-text:before, i.ph-file-text.icon-dead:before, i.ph-file-text.icon-alive:before, i.ph-file-text.icon-warn:before, i.ph-file-text.icon-sign-out:before, i.ph-file-text.icon-substratum:before, i.ph-file-text.icon-environment:before, i.ph-file-text.icon-user:before, i.ph-file-text.icon-person:before, i.ph-file-text.icon-terrain:before, i.ph-file-text.icon-taxon:before, i.ph-file-text.icon-unknown:before, i.ph-file-text.icon-nothing:before, i.ph-file-text.icon-sampling:before, i.ph-file-text.icon-isolation:before, i.ph-file-text.icon-strain:before, i.ph-file-text.icon-gps:before, i.ph-file-text.icon-location:before, i.ph-file-text.icon-add:before, i.ph-file-text.icon-left:before, i.ph-file-text.icon-dna:before, i.ph-file-text.icon-delete:before, i.ph-file-text.icon-edit:before, i.ph-file-text.icon-info:before {
  content: "\e23a";
}

.ph.ph-file-ts:before, i.ph-file-ts.icon-dead:before, i.ph-file-ts.icon-alive:before, i.ph-file-ts.icon-warn:before, i.ph-file-ts.icon-sign-out:before, i.ph-file-ts.icon-substratum:before, i.ph-file-ts.icon-environment:before, i.ph-file-ts.icon-user:before, i.ph-file-ts.icon-person:before, i.ph-file-ts.icon-terrain:before, i.ph-file-ts.icon-taxon:before, i.ph-file-ts.icon-unknown:before, i.ph-file-ts.icon-nothing:before, i.ph-file-ts.icon-sampling:before, i.ph-file-ts.icon-isolation:before, i.ph-file-ts.icon-strain:before, i.ph-file-ts.icon-gps:before, i.ph-file-ts.icon-location:before, i.ph-file-ts.icon-add:before, i.ph-file-ts.icon-left:before, i.ph-file-ts.icon-dna:before, i.ph-file-ts.icon-delete:before, i.ph-file-ts.icon-edit:before, i.ph-file-ts.icon-info:before {
  content: "\eb26";
}

.ph.ph-file-tsx:before, i.ph-file-tsx.icon-dead:before, i.ph-file-tsx.icon-alive:before, i.ph-file-tsx.icon-warn:before, i.ph-file-tsx.icon-sign-out:before, i.ph-file-tsx.icon-substratum:before, i.ph-file-tsx.icon-environment:before, i.ph-file-tsx.icon-user:before, i.ph-file-tsx.icon-person:before, i.ph-file-tsx.icon-terrain:before, i.ph-file-tsx.icon-taxon:before, i.ph-file-tsx.icon-unknown:before, i.ph-file-tsx.icon-nothing:before, i.ph-file-tsx.icon-sampling:before, i.ph-file-tsx.icon-isolation:before, i.ph-file-tsx.icon-strain:before, i.ph-file-tsx.icon-gps:before, i.ph-file-tsx.icon-location:before, i.ph-file-tsx.icon-add:before, i.ph-file-tsx.icon-left:before, i.ph-file-tsx.icon-dna:before, i.ph-file-tsx.icon-delete:before, i.ph-file-tsx.icon-edit:before, i.ph-file-tsx.icon-info:before {
  content: "\eb3c";
}

.ph.ph-file-txt:before, i.ph-file-txt.icon-dead:before, i.ph-file-txt.icon-alive:before, i.ph-file-txt.icon-warn:before, i.ph-file-txt.icon-sign-out:before, i.ph-file-txt.icon-substratum:before, i.ph-file-txt.icon-environment:before, i.ph-file-txt.icon-user:before, i.ph-file-txt.icon-person:before, i.ph-file-txt.icon-terrain:before, i.ph-file-txt.icon-taxon:before, i.ph-file-txt.icon-unknown:before, i.ph-file-txt.icon-nothing:before, i.ph-file-txt.icon-sampling:before, i.ph-file-txt.icon-isolation:before, i.ph-file-txt.icon-strain:before, i.ph-file-txt.icon-gps:before, i.ph-file-txt.icon-location:before, i.ph-file-txt.icon-add:before, i.ph-file-txt.icon-left:before, i.ph-file-txt.icon-dna:before, i.ph-file-txt.icon-delete:before, i.ph-file-txt.icon-edit:before, i.ph-file-txt.icon-info:before {
  content: "\eb35";
}

.ph.ph-file-video:before, i.ph-file-video.icon-dead:before, i.ph-file-video.icon-alive:before, i.ph-file-video.icon-warn:before, i.ph-file-video.icon-sign-out:before, i.ph-file-video.icon-substratum:before, i.ph-file-video.icon-environment:before, i.ph-file-video.icon-user:before, i.ph-file-video.icon-person:before, i.ph-file-video.icon-terrain:before, i.ph-file-video.icon-taxon:before, i.ph-file-video.icon-unknown:before, i.ph-file-video.icon-nothing:before, i.ph-file-video.icon-sampling:before, i.ph-file-video.icon-isolation:before, i.ph-file-video.icon-strain:before, i.ph-file-video.icon-gps:before, i.ph-file-video.icon-location:before, i.ph-file-video.icon-add:before, i.ph-file-video.icon-left:before, i.ph-file-video.icon-dna:before, i.ph-file-video.icon-delete:before, i.ph-file-video.icon-edit:before, i.ph-file-video.icon-info:before {
  content: "\ea22";
}

.ph.ph-file-vue:before, i.ph-file-vue.icon-dead:before, i.ph-file-vue.icon-alive:before, i.ph-file-vue.icon-warn:before, i.ph-file-vue.icon-sign-out:before, i.ph-file-vue.icon-substratum:before, i.ph-file-vue.icon-environment:before, i.ph-file-vue.icon-user:before, i.ph-file-vue.icon-person:before, i.ph-file-vue.icon-terrain:before, i.ph-file-vue.icon-taxon:before, i.ph-file-vue.icon-unknown:before, i.ph-file-vue.icon-nothing:before, i.ph-file-vue.icon-sampling:before, i.ph-file-vue.icon-isolation:before, i.ph-file-vue.icon-strain:before, i.ph-file-vue.icon-gps:before, i.ph-file-vue.icon-location:before, i.ph-file-vue.icon-add:before, i.ph-file-vue.icon-left:before, i.ph-file-vue.icon-dna:before, i.ph-file-vue.icon-delete:before, i.ph-file-vue.icon-edit:before, i.ph-file-vue.icon-info:before {
  content: "\eb3e";
}

.ph.ph-file-x:before, i.ph-file-x.icon-dead:before, i.ph-file-x.icon-alive:before, i.ph-file-x.icon-warn:before, i.ph-file-x.icon-sign-out:before, i.ph-file-x.icon-substratum:before, i.ph-file-x.icon-environment:before, i.ph-file-x.icon-user:before, i.ph-file-x.icon-person:before, i.ph-file-x.icon-terrain:before, i.ph-file-x.icon-taxon:before, i.ph-file-x.icon-unknown:before, i.ph-file-x.icon-nothing:before, i.ph-file-x.icon-sampling:before, i.ph-file-x.icon-isolation:before, i.ph-file-x.icon-strain:before, i.ph-file-x.icon-gps:before, i.ph-file-x.icon-location:before, i.ph-file-x.icon-add:before, i.ph-file-x.icon-left:before, i.ph-file-x.icon-dna:before, i.ph-file-x.icon-delete:before, i.ph-file-x.icon-edit:before, i.ph-file-x.icon-info:before {
  content: "\e23c";
}

.ph.ph-file-xls:before, i.ph-file-xls.icon-dead:before, i.ph-file-xls.icon-alive:before, i.ph-file-xls.icon-warn:before, i.ph-file-xls.icon-sign-out:before, i.ph-file-xls.icon-substratum:before, i.ph-file-xls.icon-environment:before, i.ph-file-xls.icon-user:before, i.ph-file-xls.icon-person:before, i.ph-file-xls.icon-terrain:before, i.ph-file-xls.icon-taxon:before, i.ph-file-xls.icon-unknown:before, i.ph-file-xls.icon-nothing:before, i.ph-file-xls.icon-sampling:before, i.ph-file-xls.icon-isolation:before, i.ph-file-xls.icon-strain:before, i.ph-file-xls.icon-gps:before, i.ph-file-xls.icon-location:before, i.ph-file-xls.icon-add:before, i.ph-file-xls.icon-left:before, i.ph-file-xls.icon-dna:before, i.ph-file-xls.icon-delete:before, i.ph-file-xls.icon-edit:before, i.ph-file-xls.icon-info:before {
  content: "\eb22";
}

.ph.ph-file-zip:before, i.ph-file-zip.icon-dead:before, i.ph-file-zip.icon-alive:before, i.ph-file-zip.icon-warn:before, i.ph-file-zip.icon-sign-out:before, i.ph-file-zip.icon-substratum:before, i.ph-file-zip.icon-environment:before, i.ph-file-zip.icon-user:before, i.ph-file-zip.icon-person:before, i.ph-file-zip.icon-terrain:before, i.ph-file-zip.icon-taxon:before, i.ph-file-zip.icon-unknown:before, i.ph-file-zip.icon-nothing:before, i.ph-file-zip.icon-sampling:before, i.ph-file-zip.icon-isolation:before, i.ph-file-zip.icon-strain:before, i.ph-file-zip.icon-gps:before, i.ph-file-zip.icon-location:before, i.ph-file-zip.icon-add:before, i.ph-file-zip.icon-left:before, i.ph-file-zip.icon-dna:before, i.ph-file-zip.icon-delete:before, i.ph-file-zip.icon-edit:before, i.ph-file-zip.icon-info:before {
  content: "\e958";
}

.ph.ph-files:before, i.ph-files.icon-dead:before, i.ph-files.icon-alive:before, i.ph-files.icon-warn:before, i.ph-files.icon-sign-out:before, i.ph-files.icon-substratum:before, i.ph-files.icon-environment:before, i.ph-files.icon-user:before, i.ph-files.icon-person:before, i.ph-files.icon-terrain:before, i.ph-files.icon-taxon:before, i.ph-files.icon-unknown:before, i.ph-files.icon-nothing:before, i.ph-files.icon-sampling:before, i.ph-files.icon-isolation:before, i.ph-files.icon-strain:before, i.ph-files.icon-gps:before, i.ph-files.icon-location:before, i.ph-files.icon-add:before, i.ph-files.icon-left:before, i.ph-files.icon-dna:before, i.ph-files.icon-delete:before, i.ph-files.icon-edit:before, i.ph-files.icon-info:before {
  content: "\e710";
}

.ph.ph-film-reel:before, i.ph-film-reel.icon-dead:before, i.ph-film-reel.icon-alive:before, i.ph-film-reel.icon-warn:before, i.ph-film-reel.icon-sign-out:before, i.ph-film-reel.icon-substratum:before, i.ph-film-reel.icon-environment:before, i.ph-film-reel.icon-user:before, i.ph-film-reel.icon-person:before, i.ph-film-reel.icon-terrain:before, i.ph-film-reel.icon-taxon:before, i.ph-film-reel.icon-unknown:before, i.ph-film-reel.icon-nothing:before, i.ph-film-reel.icon-sampling:before, i.ph-film-reel.icon-isolation:before, i.ph-film-reel.icon-strain:before, i.ph-film-reel.icon-gps:before, i.ph-film-reel.icon-location:before, i.ph-film-reel.icon-add:before, i.ph-film-reel.icon-left:before, i.ph-film-reel.icon-dna:before, i.ph-film-reel.icon-delete:before, i.ph-film-reel.icon-edit:before, i.ph-film-reel.icon-info:before {
  content: "\e8c0";
}

.ph.ph-film-script:before, i.ph-film-script.icon-dead:before, i.ph-film-script.icon-alive:before, i.ph-film-script.icon-warn:before, i.ph-film-script.icon-sign-out:before, i.ph-film-script.icon-substratum:before, i.ph-film-script.icon-environment:before, i.ph-film-script.icon-user:before, i.ph-film-script.icon-person:before, i.ph-film-script.icon-terrain:before, i.ph-film-script.icon-taxon:before, i.ph-film-script.icon-unknown:before, i.ph-film-script.icon-nothing:before, i.ph-film-script.icon-sampling:before, i.ph-film-script.icon-isolation:before, i.ph-film-script.icon-strain:before, i.ph-film-script.icon-gps:before, i.ph-film-script.icon-location:before, i.ph-film-script.icon-add:before, i.ph-film-script.icon-left:before, i.ph-film-script.icon-dna:before, i.ph-film-script.icon-delete:before, i.ph-film-script.icon-edit:before, i.ph-film-script.icon-info:before {
  content: "\eb50";
}

.ph.ph-film-slate:before, i.ph-film-slate.icon-dead:before, i.ph-film-slate.icon-alive:before, i.ph-film-slate.icon-warn:before, i.ph-film-slate.icon-sign-out:before, i.ph-film-slate.icon-substratum:before, i.ph-film-slate.icon-environment:before, i.ph-film-slate.icon-user:before, i.ph-film-slate.icon-person:before, i.ph-film-slate.icon-terrain:before, i.ph-film-slate.icon-taxon:before, i.ph-film-slate.icon-unknown:before, i.ph-film-slate.icon-nothing:before, i.ph-film-slate.icon-sampling:before, i.ph-film-slate.icon-isolation:before, i.ph-film-slate.icon-strain:before, i.ph-film-slate.icon-gps:before, i.ph-film-slate.icon-location:before, i.ph-film-slate.icon-add:before, i.ph-film-slate.icon-left:before, i.ph-film-slate.icon-dna:before, i.ph-film-slate.icon-delete:before, i.ph-film-slate.icon-edit:before, i.ph-film-slate.icon-info:before {
  content: "\e8c2";
}

.ph.ph-film-strip:before, i.ph-film-strip.icon-dead:before, i.ph-film-strip.icon-alive:before, i.ph-film-strip.icon-warn:before, i.ph-film-strip.icon-sign-out:before, i.ph-film-strip.icon-substratum:before, i.ph-film-strip.icon-environment:before, i.ph-film-strip.icon-user:before, i.ph-film-strip.icon-person:before, i.ph-film-strip.icon-terrain:before, i.ph-film-strip.icon-taxon:before, i.ph-film-strip.icon-unknown:before, i.ph-film-strip.icon-nothing:before, i.ph-film-strip.icon-sampling:before, i.ph-film-strip.icon-isolation:before, i.ph-film-strip.icon-strain:before, i.ph-film-strip.icon-gps:before, i.ph-film-strip.icon-location:before, i.ph-film-strip.icon-add:before, i.ph-film-strip.icon-left:before, i.ph-film-strip.icon-dna:before, i.ph-film-strip.icon-delete:before, i.ph-film-strip.icon-edit:before, i.ph-film-strip.icon-info:before {
  content: "\e792";
}

.ph.ph-fingerprint:before, i.ph-fingerprint.icon-dead:before, i.ph-fingerprint.icon-alive:before, i.ph-fingerprint.icon-warn:before, i.ph-fingerprint.icon-sign-out:before, i.ph-fingerprint.icon-substratum:before, i.ph-fingerprint.icon-environment:before, i.ph-fingerprint.icon-user:before, i.ph-fingerprint.icon-person:before, i.ph-fingerprint.icon-terrain:before, i.ph-fingerprint.icon-taxon:before, i.ph-fingerprint.icon-unknown:before, i.ph-fingerprint.icon-nothing:before, i.ph-fingerprint.icon-sampling:before, i.ph-fingerprint.icon-isolation:before, i.ph-fingerprint.icon-strain:before, i.ph-fingerprint.icon-gps:before, i.ph-fingerprint.icon-location:before, i.ph-fingerprint.icon-add:before, i.ph-fingerprint.icon-left:before, i.ph-fingerprint.icon-dna:before, i.ph-fingerprint.icon-delete:before, i.ph-fingerprint.icon-edit:before, i.ph-fingerprint.icon-info:before {
  content: "\e23e";
}

.ph.ph-fingerprint-simple:before, i.ph-fingerprint-simple.icon-dead:before, i.ph-fingerprint-simple.icon-alive:before, i.ph-fingerprint-simple.icon-warn:before, i.ph-fingerprint-simple.icon-sign-out:before, i.ph-fingerprint-simple.icon-substratum:before, i.ph-fingerprint-simple.icon-environment:before, i.ph-fingerprint-simple.icon-user:before, i.ph-fingerprint-simple.icon-person:before, i.ph-fingerprint-simple.icon-terrain:before, i.ph-fingerprint-simple.icon-taxon:before, i.ph-fingerprint-simple.icon-unknown:before, i.ph-fingerprint-simple.icon-nothing:before, i.ph-fingerprint-simple.icon-sampling:before, i.ph-fingerprint-simple.icon-isolation:before, i.ph-fingerprint-simple.icon-strain:before, i.ph-fingerprint-simple.icon-gps:before, i.ph-fingerprint-simple.icon-location:before, i.ph-fingerprint-simple.icon-add:before, i.ph-fingerprint-simple.icon-left:before, i.ph-fingerprint-simple.icon-dna:before, i.ph-fingerprint-simple.icon-delete:before, i.ph-fingerprint-simple.icon-edit:before, i.ph-fingerprint-simple.icon-info:before {
  content: "\e240";
}

.ph.ph-finn-the-human:before, i.ph-finn-the-human.icon-dead:before, i.ph-finn-the-human.icon-alive:before, i.ph-finn-the-human.icon-warn:before, i.ph-finn-the-human.icon-sign-out:before, i.ph-finn-the-human.icon-substratum:before, i.ph-finn-the-human.icon-environment:before, i.ph-finn-the-human.icon-user:before, i.ph-finn-the-human.icon-person:before, i.ph-finn-the-human.icon-terrain:before, i.ph-finn-the-human.icon-taxon:before, i.ph-finn-the-human.icon-unknown:before, i.ph-finn-the-human.icon-nothing:before, i.ph-finn-the-human.icon-sampling:before, i.ph-finn-the-human.icon-isolation:before, i.ph-finn-the-human.icon-strain:before, i.ph-finn-the-human.icon-gps:before, i.ph-finn-the-human.icon-location:before, i.ph-finn-the-human.icon-add:before, i.ph-finn-the-human.icon-left:before, i.ph-finn-the-human.icon-dna:before, i.ph-finn-the-human.icon-delete:before, i.ph-finn-the-human.icon-edit:before, i.ph-finn-the-human.icon-info:before {
  content: "\e56c";
}

.ph.ph-fire:before, i.ph-fire.icon-dead:before, i.ph-fire.icon-alive:before, i.ph-fire.icon-warn:before, i.ph-fire.icon-sign-out:before, i.ph-fire.icon-substratum:before, i.ph-fire.icon-environment:before, i.ph-fire.icon-user:before, i.ph-fire.icon-person:before, i.ph-fire.icon-terrain:before, i.ph-fire.icon-taxon:before, i.ph-fire.icon-unknown:before, i.ph-fire.icon-nothing:before, i.ph-fire.icon-sampling:before, i.ph-fire.icon-isolation:before, i.ph-fire.icon-strain:before, i.ph-fire.icon-gps:before, i.ph-fire.icon-location:before, i.ph-fire.icon-add:before, i.ph-fire.icon-left:before, i.ph-fire.icon-dna:before, i.ph-fire.icon-delete:before, i.ph-fire.icon-edit:before, i.ph-fire.icon-info:before {
  content: "\e242";
}

.ph.ph-fire-extinguisher:before, i.ph-fire-extinguisher.icon-dead:before, i.ph-fire-extinguisher.icon-alive:before, i.ph-fire-extinguisher.icon-warn:before, i.ph-fire-extinguisher.icon-sign-out:before, i.ph-fire-extinguisher.icon-substratum:before, i.ph-fire-extinguisher.icon-environment:before, i.ph-fire-extinguisher.icon-user:before, i.ph-fire-extinguisher.icon-person:before, i.ph-fire-extinguisher.icon-terrain:before, i.ph-fire-extinguisher.icon-taxon:before, i.ph-fire-extinguisher.icon-unknown:before, i.ph-fire-extinguisher.icon-nothing:before, i.ph-fire-extinguisher.icon-sampling:before, i.ph-fire-extinguisher.icon-isolation:before, i.ph-fire-extinguisher.icon-strain:before, i.ph-fire-extinguisher.icon-gps:before, i.ph-fire-extinguisher.icon-location:before, i.ph-fire-extinguisher.icon-add:before, i.ph-fire-extinguisher.icon-left:before, i.ph-fire-extinguisher.icon-dna:before, i.ph-fire-extinguisher.icon-delete:before, i.ph-fire-extinguisher.icon-edit:before, i.ph-fire-extinguisher.icon-info:before {
  content: "\e9e8";
}

.ph.ph-fire-simple:before, i.ph-fire-simple.icon-dead:before, i.ph-fire-simple.icon-alive:before, i.ph-fire-simple.icon-warn:before, i.ph-fire-simple.icon-sign-out:before, i.ph-fire-simple.icon-substratum:before, i.ph-fire-simple.icon-environment:before, i.ph-fire-simple.icon-user:before, i.ph-fire-simple.icon-person:before, i.ph-fire-simple.icon-terrain:before, i.ph-fire-simple.icon-taxon:before, i.ph-fire-simple.icon-unknown:before, i.ph-fire-simple.icon-nothing:before, i.ph-fire-simple.icon-sampling:before, i.ph-fire-simple.icon-isolation:before, i.ph-fire-simple.icon-strain:before, i.ph-fire-simple.icon-gps:before, i.ph-fire-simple.icon-location:before, i.ph-fire-simple.icon-add:before, i.ph-fire-simple.icon-left:before, i.ph-fire-simple.icon-dna:before, i.ph-fire-simple.icon-delete:before, i.ph-fire-simple.icon-edit:before, i.ph-fire-simple.icon-info:before {
  content: "\e620";
}

.ph.ph-fire-truck:before, i.ph-fire-truck.icon-dead:before, i.ph-fire-truck.icon-alive:before, i.ph-fire-truck.icon-warn:before, i.ph-fire-truck.icon-sign-out:before, i.ph-fire-truck.icon-substratum:before, i.ph-fire-truck.icon-environment:before, i.ph-fire-truck.icon-user:before, i.ph-fire-truck.icon-person:before, i.ph-fire-truck.icon-terrain:before, i.ph-fire-truck.icon-taxon:before, i.ph-fire-truck.icon-unknown:before, i.ph-fire-truck.icon-nothing:before, i.ph-fire-truck.icon-sampling:before, i.ph-fire-truck.icon-isolation:before, i.ph-fire-truck.icon-strain:before, i.ph-fire-truck.icon-gps:before, i.ph-fire-truck.icon-location:before, i.ph-fire-truck.icon-add:before, i.ph-fire-truck.icon-left:before, i.ph-fire-truck.icon-dna:before, i.ph-fire-truck.icon-delete:before, i.ph-fire-truck.icon-edit:before, i.ph-fire-truck.icon-info:before {
  content: "\e574";
}

.ph.ph-first-aid:before, i.ph-first-aid.icon-dead:before, i.ph-first-aid.icon-alive:before, i.ph-first-aid.icon-warn:before, i.ph-first-aid.icon-sign-out:before, i.ph-first-aid.icon-substratum:before, i.ph-first-aid.icon-environment:before, i.ph-first-aid.icon-user:before, i.ph-first-aid.icon-person:before, i.ph-first-aid.icon-terrain:before, i.ph-first-aid.icon-taxon:before, i.ph-first-aid.icon-unknown:before, i.ph-first-aid.icon-nothing:before, i.ph-first-aid.icon-sampling:before, i.ph-first-aid.icon-isolation:before, i.ph-first-aid.icon-strain:before, i.ph-first-aid.icon-gps:before, i.ph-first-aid.icon-location:before, i.ph-first-aid.icon-add:before, i.ph-first-aid.icon-left:before, i.ph-first-aid.icon-dna:before, i.ph-first-aid.icon-delete:before, i.ph-first-aid.icon-edit:before, i.ph-first-aid.icon-info:before {
  content: "\e56e";
}

.ph.ph-first-aid-kit:before, i.ph-first-aid-kit.icon-dead:before, i.ph-first-aid-kit.icon-alive:before, i.ph-first-aid-kit.icon-warn:before, i.ph-first-aid-kit.icon-sign-out:before, i.ph-first-aid-kit.icon-substratum:before, i.ph-first-aid-kit.icon-environment:before, i.ph-first-aid-kit.icon-user:before, i.ph-first-aid-kit.icon-person:before, i.ph-first-aid-kit.icon-terrain:before, i.ph-first-aid-kit.icon-taxon:before, i.ph-first-aid-kit.icon-unknown:before, i.ph-first-aid-kit.icon-nothing:before, i.ph-first-aid-kit.icon-sampling:before, i.ph-first-aid-kit.icon-isolation:before, i.ph-first-aid-kit.icon-strain:before, i.ph-first-aid-kit.icon-gps:before, i.ph-first-aid-kit.icon-location:before, i.ph-first-aid-kit.icon-add:before, i.ph-first-aid-kit.icon-left:before, i.ph-first-aid-kit.icon-dna:before, i.ph-first-aid-kit.icon-delete:before, i.ph-first-aid-kit.icon-edit:before, i.ph-first-aid-kit.icon-info:before {
  content: "\e570";
}

.ph.ph-fish:before, i.ph-fish.icon-dead:before, i.ph-fish.icon-alive:before, i.ph-fish.icon-warn:before, i.ph-fish.icon-sign-out:before, i.ph-fish.icon-substratum:before, i.ph-fish.icon-environment:before, i.ph-fish.icon-user:before, i.ph-fish.icon-person:before, i.ph-fish.icon-terrain:before, i.ph-fish.icon-taxon:before, i.ph-fish.icon-unknown:before, i.ph-fish.icon-nothing:before, i.ph-fish.icon-sampling:before, i.ph-fish.icon-isolation:before, i.ph-fish.icon-strain:before, i.ph-fish.icon-gps:before, i.ph-fish.icon-location:before, i.ph-fish.icon-add:before, i.ph-fish.icon-left:before, i.ph-fish.icon-dna:before, i.ph-fish.icon-delete:before, i.ph-fish.icon-edit:before, i.ph-fish.icon-info:before {
  content: "\e728";
}

.ph.ph-fish-simple:before, i.ph-fish-simple.icon-dead:before, i.ph-fish-simple.icon-alive:before, i.ph-fish-simple.icon-warn:before, i.ph-fish-simple.icon-sign-out:before, i.ph-fish-simple.icon-substratum:before, i.ph-fish-simple.icon-environment:before, i.ph-fish-simple.icon-user:before, i.ph-fish-simple.icon-person:before, i.ph-fish-simple.icon-terrain:before, i.ph-fish-simple.icon-taxon:before, i.ph-fish-simple.icon-unknown:before, i.ph-fish-simple.icon-nothing:before, i.ph-fish-simple.icon-sampling:before, i.ph-fish-simple.icon-isolation:before, i.ph-fish-simple.icon-strain:before, i.ph-fish-simple.icon-gps:before, i.ph-fish-simple.icon-location:before, i.ph-fish-simple.icon-add:before, i.ph-fish-simple.icon-left:before, i.ph-fish-simple.icon-dna:before, i.ph-fish-simple.icon-delete:before, i.ph-fish-simple.icon-edit:before, i.ph-fish-simple.icon-info:before {
  content: "\e72a";
}

.ph.ph-flag:before, i.ph-flag.icon-dead:before, i.ph-flag.icon-alive:before, i.ph-flag.icon-warn:before, i.ph-flag.icon-sign-out:before, i.ph-flag.icon-substratum:before, i.ph-flag.icon-environment:before, i.ph-flag.icon-user:before, i.ph-flag.icon-person:before, i.ph-flag.icon-terrain:before, i.ph-flag.icon-taxon:before, i.ph-flag.icon-unknown:before, i.ph-flag.icon-nothing:before, i.ph-flag.icon-sampling:before, i.ph-flag.icon-isolation:before, i.ph-flag.icon-strain:before, i.ph-flag.icon-gps:before, i.ph-flag.icon-location:before, i.ph-flag.icon-add:before, i.ph-flag.icon-left:before, i.ph-flag.icon-dna:before, i.ph-flag.icon-delete:before, i.ph-flag.icon-edit:before, i.ph-flag.icon-info:before {
  content: "\e244";
}

.ph.ph-flag-banner:before, i.ph-flag-banner.icon-dead:before, i.ph-flag-banner.icon-alive:before, i.ph-flag-banner.icon-warn:before, i.ph-flag-banner.icon-sign-out:before, i.ph-flag-banner.icon-substratum:before, i.ph-flag-banner.icon-environment:before, i.ph-flag-banner.icon-user:before, i.ph-flag-banner.icon-person:before, i.ph-flag-banner.icon-terrain:before, i.ph-flag-banner.icon-taxon:before, i.ph-flag-banner.icon-unknown:before, i.ph-flag-banner.icon-nothing:before, i.ph-flag-banner.icon-sampling:before, i.ph-flag-banner.icon-isolation:before, i.ph-flag-banner.icon-strain:before, i.ph-flag-banner.icon-gps:before, i.ph-flag-banner.icon-location:before, i.ph-flag-banner.icon-add:before, i.ph-flag-banner.icon-left:before, i.ph-flag-banner.icon-dna:before, i.ph-flag-banner.icon-delete:before, i.ph-flag-banner.icon-edit:before, i.ph-flag-banner.icon-info:before {
  content: "\e622";
}

.ph.ph-flag-banner-fold:before, i.ph-flag-banner-fold.icon-dead:before, i.ph-flag-banner-fold.icon-alive:before, i.ph-flag-banner-fold.icon-warn:before, i.ph-flag-banner-fold.icon-sign-out:before, i.ph-flag-banner-fold.icon-substratum:before, i.ph-flag-banner-fold.icon-environment:before, i.ph-flag-banner-fold.icon-user:before, i.ph-flag-banner-fold.icon-person:before, i.ph-flag-banner-fold.icon-terrain:before, i.ph-flag-banner-fold.icon-taxon:before, i.ph-flag-banner-fold.icon-unknown:before, i.ph-flag-banner-fold.icon-nothing:before, i.ph-flag-banner-fold.icon-sampling:before, i.ph-flag-banner-fold.icon-isolation:before, i.ph-flag-banner-fold.icon-strain:before, i.ph-flag-banner-fold.icon-gps:before, i.ph-flag-banner-fold.icon-location:before, i.ph-flag-banner-fold.icon-add:before, i.ph-flag-banner-fold.icon-left:before, i.ph-flag-banner-fold.icon-dna:before, i.ph-flag-banner-fold.icon-delete:before, i.ph-flag-banner-fold.icon-edit:before, i.ph-flag-banner-fold.icon-info:before {
  content: "\ecf2";
}

.ph.ph-flag-checkered:before, i.ph-flag-checkered.icon-dead:before, i.ph-flag-checkered.icon-alive:before, i.ph-flag-checkered.icon-warn:before, i.ph-flag-checkered.icon-sign-out:before, i.ph-flag-checkered.icon-substratum:before, i.ph-flag-checkered.icon-environment:before, i.ph-flag-checkered.icon-user:before, i.ph-flag-checkered.icon-person:before, i.ph-flag-checkered.icon-terrain:before, i.ph-flag-checkered.icon-taxon:before, i.ph-flag-checkered.icon-unknown:before, i.ph-flag-checkered.icon-nothing:before, i.ph-flag-checkered.icon-sampling:before, i.ph-flag-checkered.icon-isolation:before, i.ph-flag-checkered.icon-strain:before, i.ph-flag-checkered.icon-gps:before, i.ph-flag-checkered.icon-location:before, i.ph-flag-checkered.icon-add:before, i.ph-flag-checkered.icon-left:before, i.ph-flag-checkered.icon-dna:before, i.ph-flag-checkered.icon-delete:before, i.ph-flag-checkered.icon-edit:before, i.ph-flag-checkered.icon-info:before {
  content: "\ea38";
}

.ph.ph-flag-pennant:before, i.ph-flag-pennant.icon-dead:before, i.ph-flag-pennant.icon-alive:before, i.ph-flag-pennant.icon-warn:before, i.ph-flag-pennant.icon-sign-out:before, i.ph-flag-pennant.icon-substratum:before, i.ph-flag-pennant.icon-environment:before, i.ph-flag-pennant.icon-user:before, i.ph-flag-pennant.icon-person:before, i.ph-flag-pennant.icon-terrain:before, i.ph-flag-pennant.icon-taxon:before, i.ph-flag-pennant.icon-unknown:before, i.ph-flag-pennant.icon-nothing:before, i.ph-flag-pennant.icon-sampling:before, i.ph-flag-pennant.icon-isolation:before, i.ph-flag-pennant.icon-strain:before, i.ph-flag-pennant.icon-gps:before, i.ph-flag-pennant.icon-location:before, i.ph-flag-pennant.icon-add:before, i.ph-flag-pennant.icon-left:before, i.ph-flag-pennant.icon-dna:before, i.ph-flag-pennant.icon-delete:before, i.ph-flag-pennant.icon-edit:before, i.ph-flag-pennant.icon-info:before {
  content: "\ecf0";
}

.ph.ph-flame:before, i.ph-flame.icon-dead:before, i.ph-flame.icon-alive:before, i.ph-flame.icon-warn:before, i.ph-flame.icon-sign-out:before, i.ph-flame.icon-substratum:before, i.ph-flame.icon-environment:before, i.ph-flame.icon-user:before, i.ph-flame.icon-person:before, i.ph-flame.icon-terrain:before, i.ph-flame.icon-taxon:before, i.ph-flame.icon-unknown:before, i.ph-flame.icon-nothing:before, i.ph-flame.icon-sampling:before, i.ph-flame.icon-isolation:before, i.ph-flame.icon-strain:before, i.ph-flame.icon-gps:before, i.ph-flame.icon-location:before, i.ph-flame.icon-add:before, i.ph-flame.icon-left:before, i.ph-flame.icon-dna:before, i.ph-flame.icon-delete:before, i.ph-flame.icon-edit:before, i.ph-flame.icon-info:before {
  content: "\e624";
}

.ph.ph-flashlight:before, i.ph-flashlight.icon-dead:before, i.ph-flashlight.icon-alive:before, i.ph-flashlight.icon-warn:before, i.ph-flashlight.icon-sign-out:before, i.ph-flashlight.icon-substratum:before, i.ph-flashlight.icon-environment:before, i.ph-flashlight.icon-user:before, i.ph-flashlight.icon-person:before, i.ph-flashlight.icon-terrain:before, i.ph-flashlight.icon-taxon:before, i.ph-flashlight.icon-unknown:before, i.ph-flashlight.icon-nothing:before, i.ph-flashlight.icon-sampling:before, i.ph-flashlight.icon-isolation:before, i.ph-flashlight.icon-strain:before, i.ph-flashlight.icon-gps:before, i.ph-flashlight.icon-location:before, i.ph-flashlight.icon-add:before, i.ph-flashlight.icon-left:before, i.ph-flashlight.icon-dna:before, i.ph-flashlight.icon-delete:before, i.ph-flashlight.icon-edit:before, i.ph-flashlight.icon-info:before {
  content: "\e246";
}

.ph.ph-flask:before, i.ph-flask.icon-dead:before, i.ph-flask.icon-alive:before, i.ph-flask.icon-warn:before, i.ph-flask.icon-sign-out:before, i.ph-flask.icon-substratum:before, i.ph-flask.icon-environment:before, i.ph-flask.icon-user:before, i.ph-flask.icon-person:before, i.ph-flask.icon-terrain:before, i.ph-flask.icon-taxon:before, i.ph-flask.icon-unknown:before, i.ph-flask.icon-nothing:before, i.ph-flask.icon-sampling:before, i.ph-flask.icon-isolation:before, i.ph-flask.icon-strain:before, i.ph-flask.icon-gps:before, i.ph-flask.icon-location:before, i.ph-flask.icon-add:before, i.ph-flask.icon-left:before, i.ph-flask.icon-dna:before, i.ph-flask.icon-delete:before, i.ph-flask.icon-edit:before, i.ph-flask.icon-info:before {
  content: "\e79e";
}

.ph.ph-flip-horizontal:before, i.ph-flip-horizontal.icon-dead:before, i.ph-flip-horizontal.icon-alive:before, i.ph-flip-horizontal.icon-warn:before, i.ph-flip-horizontal.icon-sign-out:before, i.ph-flip-horizontal.icon-substratum:before, i.ph-flip-horizontal.icon-environment:before, i.ph-flip-horizontal.icon-user:before, i.ph-flip-horizontal.icon-person:before, i.ph-flip-horizontal.icon-terrain:before, i.ph-flip-horizontal.icon-taxon:before, i.ph-flip-horizontal.icon-unknown:before, i.ph-flip-horizontal.icon-nothing:before, i.ph-flip-horizontal.icon-sampling:before, i.ph-flip-horizontal.icon-isolation:before, i.ph-flip-horizontal.icon-strain:before, i.ph-flip-horizontal.icon-gps:before, i.ph-flip-horizontal.icon-location:before, i.ph-flip-horizontal.icon-add:before, i.ph-flip-horizontal.icon-left:before, i.ph-flip-horizontal.icon-dna:before, i.ph-flip-horizontal.icon-delete:before, i.ph-flip-horizontal.icon-edit:before, i.ph-flip-horizontal.icon-info:before {
  content: "\ed6a";
}

.ph.ph-flip-vertical:before, i.ph-flip-vertical.icon-dead:before, i.ph-flip-vertical.icon-alive:before, i.ph-flip-vertical.icon-warn:before, i.ph-flip-vertical.icon-sign-out:before, i.ph-flip-vertical.icon-substratum:before, i.ph-flip-vertical.icon-environment:before, i.ph-flip-vertical.icon-user:before, i.ph-flip-vertical.icon-person:before, i.ph-flip-vertical.icon-terrain:before, i.ph-flip-vertical.icon-taxon:before, i.ph-flip-vertical.icon-unknown:before, i.ph-flip-vertical.icon-nothing:before, i.ph-flip-vertical.icon-sampling:before, i.ph-flip-vertical.icon-isolation:before, i.ph-flip-vertical.icon-strain:before, i.ph-flip-vertical.icon-gps:before, i.ph-flip-vertical.icon-location:before, i.ph-flip-vertical.icon-add:before, i.ph-flip-vertical.icon-left:before, i.ph-flip-vertical.icon-dna:before, i.ph-flip-vertical.icon-delete:before, i.ph-flip-vertical.icon-edit:before, i.ph-flip-vertical.icon-info:before {
  content: "\ed6c";
}

.ph.ph-floppy-disk:before, i.ph-floppy-disk.icon-dead:before, i.ph-floppy-disk.icon-alive:before, i.ph-floppy-disk.icon-warn:before, i.ph-floppy-disk.icon-sign-out:before, i.ph-floppy-disk.icon-substratum:before, i.ph-floppy-disk.icon-environment:before, i.ph-floppy-disk.icon-user:before, i.ph-floppy-disk.icon-person:before, i.ph-floppy-disk.icon-terrain:before, i.ph-floppy-disk.icon-taxon:before, i.ph-floppy-disk.icon-unknown:before, i.ph-floppy-disk.icon-nothing:before, i.ph-floppy-disk.icon-sampling:before, i.ph-floppy-disk.icon-isolation:before, i.ph-floppy-disk.icon-strain:before, i.ph-floppy-disk.icon-gps:before, i.ph-floppy-disk.icon-location:before, i.ph-floppy-disk.icon-add:before, i.ph-floppy-disk.icon-left:before, i.ph-floppy-disk.icon-dna:before, i.ph-floppy-disk.icon-delete:before, i.ph-floppy-disk.icon-edit:before, i.ph-floppy-disk.icon-info:before {
  content: "\e248";
}

.ph.ph-floppy-disk-back:before, i.ph-floppy-disk-back.icon-dead:before, i.ph-floppy-disk-back.icon-alive:before, i.ph-floppy-disk-back.icon-warn:before, i.ph-floppy-disk-back.icon-sign-out:before, i.ph-floppy-disk-back.icon-substratum:before, i.ph-floppy-disk-back.icon-environment:before, i.ph-floppy-disk-back.icon-user:before, i.ph-floppy-disk-back.icon-person:before, i.ph-floppy-disk-back.icon-terrain:before, i.ph-floppy-disk-back.icon-taxon:before, i.ph-floppy-disk-back.icon-unknown:before, i.ph-floppy-disk-back.icon-nothing:before, i.ph-floppy-disk-back.icon-sampling:before, i.ph-floppy-disk-back.icon-isolation:before, i.ph-floppy-disk-back.icon-strain:before, i.ph-floppy-disk-back.icon-gps:before, i.ph-floppy-disk-back.icon-location:before, i.ph-floppy-disk-back.icon-add:before, i.ph-floppy-disk-back.icon-left:before, i.ph-floppy-disk-back.icon-dna:before, i.ph-floppy-disk-back.icon-delete:before, i.ph-floppy-disk-back.icon-edit:before, i.ph-floppy-disk-back.icon-info:before {
  content: "\eaf4";
}

.ph.ph-flow-arrow:before, i.ph-flow-arrow.icon-dead:before, i.ph-flow-arrow.icon-alive:before, i.ph-flow-arrow.icon-warn:before, i.ph-flow-arrow.icon-sign-out:before, i.ph-flow-arrow.icon-substratum:before, i.ph-flow-arrow.icon-environment:before, i.ph-flow-arrow.icon-user:before, i.ph-flow-arrow.icon-person:before, i.ph-flow-arrow.icon-terrain:before, i.ph-flow-arrow.icon-taxon:before, i.ph-flow-arrow.icon-unknown:before, i.ph-flow-arrow.icon-nothing:before, i.ph-flow-arrow.icon-sampling:before, i.ph-flow-arrow.icon-isolation:before, i.ph-flow-arrow.icon-strain:before, i.ph-flow-arrow.icon-gps:before, i.ph-flow-arrow.icon-location:before, i.ph-flow-arrow.icon-add:before, i.ph-flow-arrow.icon-left:before, i.ph-flow-arrow.icon-dna:before, i.ph-flow-arrow.icon-delete:before, i.ph-flow-arrow.icon-edit:before, i.ph-flow-arrow.icon-info:before {
  content: "\e6ec";
}

.ph.ph-flower:before, i.ph-flower.icon-dead:before, i.ph-flower.icon-alive:before, i.ph-flower.icon-warn:before, i.ph-flower.icon-sign-out:before, i.ph-flower.icon-substratum:before, i.ph-flower.icon-environment:before, i.ph-flower.icon-user:before, i.ph-flower.icon-person:before, i.ph-flower.icon-terrain:before, i.ph-flower.icon-taxon:before, i.ph-flower.icon-unknown:before, i.ph-flower.icon-nothing:before, i.ph-flower.icon-sampling:before, i.ph-flower.icon-isolation:before, i.ph-flower.icon-strain:before, i.ph-flower.icon-gps:before, i.ph-flower.icon-location:before, i.ph-flower.icon-add:before, i.ph-flower.icon-left:before, i.ph-flower.icon-dna:before, i.ph-flower.icon-delete:before, i.ph-flower.icon-edit:before, i.ph-flower.icon-info:before {
  content: "\e75e";
}

.ph.ph-flower-lotus:before, i.ph-flower-lotus.icon-dead:before, i.ph-flower-lotus.icon-alive:before, i.ph-flower-lotus.icon-warn:before, i.ph-flower-lotus.icon-sign-out:before, i.ph-flower-lotus.icon-substratum:before, i.ph-flower-lotus.icon-environment:before, i.ph-flower-lotus.icon-user:before, i.ph-flower-lotus.icon-person:before, i.ph-flower-lotus.icon-terrain:before, i.ph-flower-lotus.icon-taxon:before, i.ph-flower-lotus.icon-unknown:before, i.ph-flower-lotus.icon-nothing:before, i.ph-flower-lotus.icon-sampling:before, i.ph-flower-lotus.icon-isolation:before, i.ph-flower-lotus.icon-strain:before, i.ph-flower-lotus.icon-gps:before, i.ph-flower-lotus.icon-location:before, i.ph-flower-lotus.icon-add:before, i.ph-flower-lotus.icon-left:before, i.ph-flower-lotus.icon-dna:before, i.ph-flower-lotus.icon-delete:before, i.ph-flower-lotus.icon-edit:before, i.ph-flower-lotus.icon-info:before {
  content: "\e6cc";
}

.ph.ph-flower-tulip:before, i.ph-flower-tulip.icon-dead:before, i.ph-flower-tulip.icon-alive:before, i.ph-flower-tulip.icon-warn:before, i.ph-flower-tulip.icon-sign-out:before, i.ph-flower-tulip.icon-substratum:before, i.ph-flower-tulip.icon-environment:before, i.ph-flower-tulip.icon-user:before, i.ph-flower-tulip.icon-person:before, i.ph-flower-tulip.icon-terrain:before, i.ph-flower-tulip.icon-taxon:before, i.ph-flower-tulip.icon-unknown:before, i.ph-flower-tulip.icon-nothing:before, i.ph-flower-tulip.icon-sampling:before, i.ph-flower-tulip.icon-isolation:before, i.ph-flower-tulip.icon-strain:before, i.ph-flower-tulip.icon-gps:before, i.ph-flower-tulip.icon-location:before, i.ph-flower-tulip.icon-add:before, i.ph-flower-tulip.icon-left:before, i.ph-flower-tulip.icon-dna:before, i.ph-flower-tulip.icon-delete:before, i.ph-flower-tulip.icon-edit:before, i.ph-flower-tulip.icon-info:before {
  content: "\eacc";
}

.ph.ph-flying-saucer:before, i.ph-flying-saucer.icon-dead:before, i.ph-flying-saucer.icon-alive:before, i.ph-flying-saucer.icon-warn:before, i.ph-flying-saucer.icon-sign-out:before, i.ph-flying-saucer.icon-substratum:before, i.ph-flying-saucer.icon-environment:before, i.ph-flying-saucer.icon-user:before, i.ph-flying-saucer.icon-person:before, i.ph-flying-saucer.icon-terrain:before, i.ph-flying-saucer.icon-taxon:before, i.ph-flying-saucer.icon-unknown:before, i.ph-flying-saucer.icon-nothing:before, i.ph-flying-saucer.icon-sampling:before, i.ph-flying-saucer.icon-isolation:before, i.ph-flying-saucer.icon-strain:before, i.ph-flying-saucer.icon-gps:before, i.ph-flying-saucer.icon-location:before, i.ph-flying-saucer.icon-add:before, i.ph-flying-saucer.icon-left:before, i.ph-flying-saucer.icon-dna:before, i.ph-flying-saucer.icon-delete:before, i.ph-flying-saucer.icon-edit:before, i.ph-flying-saucer.icon-info:before {
  content: "\eb4a";
}

.ph.ph-folder:before, i.ph-folder.icon-dead:before, i.ph-folder.icon-alive:before, i.ph-folder.icon-warn:before, i.ph-folder.icon-sign-out:before, i.ph-folder.icon-substratum:before, i.ph-folder.icon-environment:before, i.ph-folder.icon-user:before, i.ph-folder.icon-person:before, i.ph-folder.icon-terrain:before, i.ph-folder.icon-taxon:before, i.ph-folder.icon-unknown:before, i.ph-folder.icon-nothing:before, i.ph-folder.icon-sampling:before, i.ph-folder.icon-isolation:before, i.ph-folder.icon-strain:before, i.ph-folder.icon-gps:before, i.ph-folder.icon-location:before, i.ph-folder.icon-add:before, i.ph-folder.icon-left:before, i.ph-folder.icon-dna:before, i.ph-folder.icon-delete:before, i.ph-folder.icon-edit:before, i.ph-folder.icon-info:before {
  content: "\e24a";
}

.ph.ph-folder-notch:before, i.ph-folder-notch.icon-dead:before, i.ph-folder-notch.icon-alive:before, i.ph-folder-notch.icon-warn:before, i.ph-folder-notch.icon-sign-out:before, i.ph-folder-notch.icon-substratum:before, i.ph-folder-notch.icon-environment:before, i.ph-folder-notch.icon-user:before, i.ph-folder-notch.icon-person:before, i.ph-folder-notch.icon-terrain:before, i.ph-folder-notch.icon-taxon:before, i.ph-folder-notch.icon-unknown:before, i.ph-folder-notch.icon-nothing:before, i.ph-folder-notch.icon-sampling:before, i.ph-folder-notch.icon-isolation:before, i.ph-folder-notch.icon-strain:before, i.ph-folder-notch.icon-gps:before, i.ph-folder-notch.icon-location:before, i.ph-folder-notch.icon-add:before, i.ph-folder-notch.icon-left:before, i.ph-folder-notch.icon-dna:before, i.ph-folder-notch.icon-delete:before, i.ph-folder-notch.icon-edit:before, i.ph-folder-notch.icon-info:before {
  content: "\e24a";
}

.ph.ph-folder-dashed:before, i.ph-folder-dashed.icon-dead:before, i.ph-folder-dashed.icon-alive:before, i.ph-folder-dashed.icon-warn:before, i.ph-folder-dashed.icon-sign-out:before, i.ph-folder-dashed.icon-substratum:before, i.ph-folder-dashed.icon-environment:before, i.ph-folder-dashed.icon-user:before, i.ph-folder-dashed.icon-person:before, i.ph-folder-dashed.icon-terrain:before, i.ph-folder-dashed.icon-taxon:before, i.ph-folder-dashed.icon-unknown:before, i.ph-folder-dashed.icon-nothing:before, i.ph-folder-dashed.icon-sampling:before, i.ph-folder-dashed.icon-isolation:before, i.ph-folder-dashed.icon-strain:before, i.ph-folder-dashed.icon-gps:before, i.ph-folder-dashed.icon-location:before, i.ph-folder-dashed.icon-add:before, i.ph-folder-dashed.icon-left:before, i.ph-folder-dashed.icon-dna:before, i.ph-folder-dashed.icon-delete:before, i.ph-folder-dashed.icon-edit:before, i.ph-folder-dashed.icon-info:before {
  content: "\e8f8";
}

.ph.ph-folder-dotted:before, i.ph-folder-dotted.icon-dead:before, i.ph-folder-dotted.icon-alive:before, i.ph-folder-dotted.icon-warn:before, i.ph-folder-dotted.icon-sign-out:before, i.ph-folder-dotted.icon-substratum:before, i.ph-folder-dotted.icon-environment:before, i.ph-folder-dotted.icon-user:before, i.ph-folder-dotted.icon-person:before, i.ph-folder-dotted.icon-terrain:before, i.ph-folder-dotted.icon-taxon:before, i.ph-folder-dotted.icon-unknown:before, i.ph-folder-dotted.icon-nothing:before, i.ph-folder-dotted.icon-sampling:before, i.ph-folder-dotted.icon-isolation:before, i.ph-folder-dotted.icon-strain:before, i.ph-folder-dotted.icon-gps:before, i.ph-folder-dotted.icon-location:before, i.ph-folder-dotted.icon-add:before, i.ph-folder-dotted.icon-left:before, i.ph-folder-dotted.icon-dna:before, i.ph-folder-dotted.icon-delete:before, i.ph-folder-dotted.icon-edit:before, i.ph-folder-dotted.icon-info:before {
  content: "\e8f8";
}

.ph.ph-folder-lock:before, i.ph-folder-lock.icon-dead:before, i.ph-folder-lock.icon-alive:before, i.ph-folder-lock.icon-warn:before, i.ph-folder-lock.icon-sign-out:before, i.ph-folder-lock.icon-substratum:before, i.ph-folder-lock.icon-environment:before, i.ph-folder-lock.icon-user:before, i.ph-folder-lock.icon-person:before, i.ph-folder-lock.icon-terrain:before, i.ph-folder-lock.icon-taxon:before, i.ph-folder-lock.icon-unknown:before, i.ph-folder-lock.icon-nothing:before, i.ph-folder-lock.icon-sampling:before, i.ph-folder-lock.icon-isolation:before, i.ph-folder-lock.icon-strain:before, i.ph-folder-lock.icon-gps:before, i.ph-folder-lock.icon-location:before, i.ph-folder-lock.icon-add:before, i.ph-folder-lock.icon-left:before, i.ph-folder-lock.icon-dna:before, i.ph-folder-lock.icon-delete:before, i.ph-folder-lock.icon-edit:before, i.ph-folder-lock.icon-info:before {
  content: "\ea3c";
}

.ph.ph-folder-minus:before, i.ph-folder-minus.icon-dead:before, i.ph-folder-minus.icon-alive:before, i.ph-folder-minus.icon-warn:before, i.ph-folder-minus.icon-sign-out:before, i.ph-folder-minus.icon-substratum:before, i.ph-folder-minus.icon-environment:before, i.ph-folder-minus.icon-user:before, i.ph-folder-minus.icon-person:before, i.ph-folder-minus.icon-terrain:before, i.ph-folder-minus.icon-taxon:before, i.ph-folder-minus.icon-unknown:before, i.ph-folder-minus.icon-nothing:before, i.ph-folder-minus.icon-sampling:before, i.ph-folder-minus.icon-isolation:before, i.ph-folder-minus.icon-strain:before, i.ph-folder-minus.icon-gps:before, i.ph-folder-minus.icon-location:before, i.ph-folder-minus.icon-add:before, i.ph-folder-minus.icon-left:before, i.ph-folder-minus.icon-dna:before, i.ph-folder-minus.icon-delete:before, i.ph-folder-minus.icon-edit:before, i.ph-folder-minus.icon-info:before {
  content: "\e254";
}

.ph.ph-folder-notch-minus:before, i.ph-folder-notch-minus.icon-dead:before, i.ph-folder-notch-minus.icon-alive:before, i.ph-folder-notch-minus.icon-warn:before, i.ph-folder-notch-minus.icon-sign-out:before, i.ph-folder-notch-minus.icon-substratum:before, i.ph-folder-notch-minus.icon-environment:before, i.ph-folder-notch-minus.icon-user:before, i.ph-folder-notch-minus.icon-person:before, i.ph-folder-notch-minus.icon-terrain:before, i.ph-folder-notch-minus.icon-taxon:before, i.ph-folder-notch-minus.icon-unknown:before, i.ph-folder-notch-minus.icon-nothing:before, i.ph-folder-notch-minus.icon-sampling:before, i.ph-folder-notch-minus.icon-isolation:before, i.ph-folder-notch-minus.icon-strain:before, i.ph-folder-notch-minus.icon-gps:before, i.ph-folder-notch-minus.icon-location:before, i.ph-folder-notch-minus.icon-add:before, i.ph-folder-notch-minus.icon-left:before, i.ph-folder-notch-minus.icon-dna:before, i.ph-folder-notch-minus.icon-delete:before, i.ph-folder-notch-minus.icon-edit:before, i.ph-folder-notch-minus.icon-info:before {
  content: "\e254";
}

.ph.ph-folder-open:before, i.ph-folder-open.icon-dead:before, i.ph-folder-open.icon-alive:before, i.ph-folder-open.icon-warn:before, i.ph-folder-open.icon-sign-out:before, i.ph-folder-open.icon-substratum:before, i.ph-folder-open.icon-environment:before, i.ph-folder-open.icon-user:before, i.ph-folder-open.icon-person:before, i.ph-folder-open.icon-terrain:before, i.ph-folder-open.icon-taxon:before, i.ph-folder-open.icon-unknown:before, i.ph-folder-open.icon-nothing:before, i.ph-folder-open.icon-sampling:before, i.ph-folder-open.icon-isolation:before, i.ph-folder-open.icon-strain:before, i.ph-folder-open.icon-gps:before, i.ph-folder-open.icon-location:before, i.ph-folder-open.icon-add:before, i.ph-folder-open.icon-left:before, i.ph-folder-open.icon-dna:before, i.ph-folder-open.icon-delete:before, i.ph-folder-open.icon-edit:before, i.ph-folder-open.icon-info:before {
  content: "\e256";
}

.ph.ph-folder-notch-open:before, i.ph-folder-notch-open.icon-dead:before, i.ph-folder-notch-open.icon-alive:before, i.ph-folder-notch-open.icon-warn:before, i.ph-folder-notch-open.icon-sign-out:before, i.ph-folder-notch-open.icon-substratum:before, i.ph-folder-notch-open.icon-environment:before, i.ph-folder-notch-open.icon-user:before, i.ph-folder-notch-open.icon-person:before, i.ph-folder-notch-open.icon-terrain:before, i.ph-folder-notch-open.icon-taxon:before, i.ph-folder-notch-open.icon-unknown:before, i.ph-folder-notch-open.icon-nothing:before, i.ph-folder-notch-open.icon-sampling:before, i.ph-folder-notch-open.icon-isolation:before, i.ph-folder-notch-open.icon-strain:before, i.ph-folder-notch-open.icon-gps:before, i.ph-folder-notch-open.icon-location:before, i.ph-folder-notch-open.icon-add:before, i.ph-folder-notch-open.icon-left:before, i.ph-folder-notch-open.icon-dna:before, i.ph-folder-notch-open.icon-delete:before, i.ph-folder-notch-open.icon-edit:before, i.ph-folder-notch-open.icon-info:before {
  content: "\e256";
}

.ph.ph-folder-plus:before, i.ph-folder-plus.icon-dead:before, i.ph-folder-plus.icon-alive:before, i.ph-folder-plus.icon-warn:before, i.ph-folder-plus.icon-sign-out:before, i.ph-folder-plus.icon-substratum:before, i.ph-folder-plus.icon-environment:before, i.ph-folder-plus.icon-user:before, i.ph-folder-plus.icon-person:before, i.ph-folder-plus.icon-terrain:before, i.ph-folder-plus.icon-taxon:before, i.ph-folder-plus.icon-unknown:before, i.ph-folder-plus.icon-nothing:before, i.ph-folder-plus.icon-sampling:before, i.ph-folder-plus.icon-isolation:before, i.ph-folder-plus.icon-strain:before, i.ph-folder-plus.icon-gps:before, i.ph-folder-plus.icon-location:before, i.ph-folder-plus.icon-add:before, i.ph-folder-plus.icon-left:before, i.ph-folder-plus.icon-dna:before, i.ph-folder-plus.icon-delete:before, i.ph-folder-plus.icon-edit:before, i.ph-folder-plus.icon-info:before {
  content: "\e258";
}

.ph.ph-folder-notch-plus:before, i.ph-folder-notch-plus.icon-dead:before, i.ph-folder-notch-plus.icon-alive:before, i.ph-folder-notch-plus.icon-warn:before, i.ph-folder-notch-plus.icon-sign-out:before, i.ph-folder-notch-plus.icon-substratum:before, i.ph-folder-notch-plus.icon-environment:before, i.ph-folder-notch-plus.icon-user:before, i.ph-folder-notch-plus.icon-person:before, i.ph-folder-notch-plus.icon-terrain:before, i.ph-folder-notch-plus.icon-taxon:before, i.ph-folder-notch-plus.icon-unknown:before, i.ph-folder-notch-plus.icon-nothing:before, i.ph-folder-notch-plus.icon-sampling:before, i.ph-folder-notch-plus.icon-isolation:before, i.ph-folder-notch-plus.icon-strain:before, i.ph-folder-notch-plus.icon-gps:before, i.ph-folder-notch-plus.icon-location:before, i.ph-folder-notch-plus.icon-add:before, i.ph-folder-notch-plus.icon-left:before, i.ph-folder-notch-plus.icon-dna:before, i.ph-folder-notch-plus.icon-delete:before, i.ph-folder-notch-plus.icon-edit:before, i.ph-folder-notch-plus.icon-info:before {
  content: "\e258";
}

.ph.ph-folder-simple:before, i.ph-folder-simple.icon-dead:before, i.ph-folder-simple.icon-alive:before, i.ph-folder-simple.icon-warn:before, i.ph-folder-simple.icon-sign-out:before, i.ph-folder-simple.icon-substratum:before, i.ph-folder-simple.icon-environment:before, i.ph-folder-simple.icon-user:before, i.ph-folder-simple.icon-person:before, i.ph-folder-simple.icon-terrain:before, i.ph-folder-simple.icon-taxon:before, i.ph-folder-simple.icon-unknown:before, i.ph-folder-simple.icon-nothing:before, i.ph-folder-simple.icon-sampling:before, i.ph-folder-simple.icon-isolation:before, i.ph-folder-simple.icon-strain:before, i.ph-folder-simple.icon-gps:before, i.ph-folder-simple.icon-location:before, i.ph-folder-simple.icon-add:before, i.ph-folder-simple.icon-left:before, i.ph-folder-simple.icon-dna:before, i.ph-folder-simple.icon-delete:before, i.ph-folder-simple.icon-edit:before, i.ph-folder-simple.icon-info:before {
  content: "\e25a";
}

.ph.ph-folder-simple-dashed:before, i.ph-folder-simple-dashed.icon-dead:before, i.ph-folder-simple-dashed.icon-alive:before, i.ph-folder-simple-dashed.icon-warn:before, i.ph-folder-simple-dashed.icon-sign-out:before, i.ph-folder-simple-dashed.icon-substratum:before, i.ph-folder-simple-dashed.icon-environment:before, i.ph-folder-simple-dashed.icon-user:before, i.ph-folder-simple-dashed.icon-person:before, i.ph-folder-simple-dashed.icon-terrain:before, i.ph-folder-simple-dashed.icon-taxon:before, i.ph-folder-simple-dashed.icon-unknown:before, i.ph-folder-simple-dashed.icon-nothing:before, i.ph-folder-simple-dashed.icon-sampling:before, i.ph-folder-simple-dashed.icon-isolation:before, i.ph-folder-simple-dashed.icon-strain:before, i.ph-folder-simple-dashed.icon-gps:before, i.ph-folder-simple-dashed.icon-location:before, i.ph-folder-simple-dashed.icon-add:before, i.ph-folder-simple-dashed.icon-left:before, i.ph-folder-simple-dashed.icon-dna:before, i.ph-folder-simple-dashed.icon-delete:before, i.ph-folder-simple-dashed.icon-edit:before, i.ph-folder-simple-dashed.icon-info:before {
  content: "\ec2a";
}

.ph.ph-folder-simple-dotted:before, i.ph-folder-simple-dotted.icon-dead:before, i.ph-folder-simple-dotted.icon-alive:before, i.ph-folder-simple-dotted.icon-warn:before, i.ph-folder-simple-dotted.icon-sign-out:before, i.ph-folder-simple-dotted.icon-substratum:before, i.ph-folder-simple-dotted.icon-environment:before, i.ph-folder-simple-dotted.icon-user:before, i.ph-folder-simple-dotted.icon-person:before, i.ph-folder-simple-dotted.icon-terrain:before, i.ph-folder-simple-dotted.icon-taxon:before, i.ph-folder-simple-dotted.icon-unknown:before, i.ph-folder-simple-dotted.icon-nothing:before, i.ph-folder-simple-dotted.icon-sampling:before, i.ph-folder-simple-dotted.icon-isolation:before, i.ph-folder-simple-dotted.icon-strain:before, i.ph-folder-simple-dotted.icon-gps:before, i.ph-folder-simple-dotted.icon-location:before, i.ph-folder-simple-dotted.icon-add:before, i.ph-folder-simple-dotted.icon-left:before, i.ph-folder-simple-dotted.icon-dna:before, i.ph-folder-simple-dotted.icon-delete:before, i.ph-folder-simple-dotted.icon-edit:before, i.ph-folder-simple-dotted.icon-info:before {
  content: "\ec2a";
}

.ph.ph-folder-simple-lock:before, i.ph-folder-simple-lock.icon-dead:before, i.ph-folder-simple-lock.icon-alive:before, i.ph-folder-simple-lock.icon-warn:before, i.ph-folder-simple-lock.icon-sign-out:before, i.ph-folder-simple-lock.icon-substratum:before, i.ph-folder-simple-lock.icon-environment:before, i.ph-folder-simple-lock.icon-user:before, i.ph-folder-simple-lock.icon-person:before, i.ph-folder-simple-lock.icon-terrain:before, i.ph-folder-simple-lock.icon-taxon:before, i.ph-folder-simple-lock.icon-unknown:before, i.ph-folder-simple-lock.icon-nothing:before, i.ph-folder-simple-lock.icon-sampling:before, i.ph-folder-simple-lock.icon-isolation:before, i.ph-folder-simple-lock.icon-strain:before, i.ph-folder-simple-lock.icon-gps:before, i.ph-folder-simple-lock.icon-location:before, i.ph-folder-simple-lock.icon-add:before, i.ph-folder-simple-lock.icon-left:before, i.ph-folder-simple-lock.icon-dna:before, i.ph-folder-simple-lock.icon-delete:before, i.ph-folder-simple-lock.icon-edit:before, i.ph-folder-simple-lock.icon-info:before {
  content: "\eb5e";
}

.ph.ph-folder-simple-minus:before, i.ph-folder-simple-minus.icon-dead:before, i.ph-folder-simple-minus.icon-alive:before, i.ph-folder-simple-minus.icon-warn:before, i.ph-folder-simple-minus.icon-sign-out:before, i.ph-folder-simple-minus.icon-substratum:before, i.ph-folder-simple-minus.icon-environment:before, i.ph-folder-simple-minus.icon-user:before, i.ph-folder-simple-minus.icon-person:before, i.ph-folder-simple-minus.icon-terrain:before, i.ph-folder-simple-minus.icon-taxon:before, i.ph-folder-simple-minus.icon-unknown:before, i.ph-folder-simple-minus.icon-nothing:before, i.ph-folder-simple-minus.icon-sampling:before, i.ph-folder-simple-minus.icon-isolation:before, i.ph-folder-simple-minus.icon-strain:before, i.ph-folder-simple-minus.icon-gps:before, i.ph-folder-simple-minus.icon-location:before, i.ph-folder-simple-minus.icon-add:before, i.ph-folder-simple-minus.icon-left:before, i.ph-folder-simple-minus.icon-dna:before, i.ph-folder-simple-minus.icon-delete:before, i.ph-folder-simple-minus.icon-edit:before, i.ph-folder-simple-minus.icon-info:before {
  content: "\e25c";
}

.ph.ph-folder-simple-plus:before, i.ph-folder-simple-plus.icon-dead:before, i.ph-folder-simple-plus.icon-alive:before, i.ph-folder-simple-plus.icon-warn:before, i.ph-folder-simple-plus.icon-sign-out:before, i.ph-folder-simple-plus.icon-substratum:before, i.ph-folder-simple-plus.icon-environment:before, i.ph-folder-simple-plus.icon-user:before, i.ph-folder-simple-plus.icon-person:before, i.ph-folder-simple-plus.icon-terrain:before, i.ph-folder-simple-plus.icon-taxon:before, i.ph-folder-simple-plus.icon-unknown:before, i.ph-folder-simple-plus.icon-nothing:before, i.ph-folder-simple-plus.icon-sampling:before, i.ph-folder-simple-plus.icon-isolation:before, i.ph-folder-simple-plus.icon-strain:before, i.ph-folder-simple-plus.icon-gps:before, i.ph-folder-simple-plus.icon-location:before, i.ph-folder-simple-plus.icon-add:before, i.ph-folder-simple-plus.icon-left:before, i.ph-folder-simple-plus.icon-dna:before, i.ph-folder-simple-plus.icon-delete:before, i.ph-folder-simple-plus.icon-edit:before, i.ph-folder-simple-plus.icon-info:before {
  content: "\e25e";
}

.ph.ph-folder-simple-star:before, i.ph-folder-simple-star.icon-dead:before, i.ph-folder-simple-star.icon-alive:before, i.ph-folder-simple-star.icon-warn:before, i.ph-folder-simple-star.icon-sign-out:before, i.ph-folder-simple-star.icon-substratum:before, i.ph-folder-simple-star.icon-environment:before, i.ph-folder-simple-star.icon-user:before, i.ph-folder-simple-star.icon-person:before, i.ph-folder-simple-star.icon-terrain:before, i.ph-folder-simple-star.icon-taxon:before, i.ph-folder-simple-star.icon-unknown:before, i.ph-folder-simple-star.icon-nothing:before, i.ph-folder-simple-star.icon-sampling:before, i.ph-folder-simple-star.icon-isolation:before, i.ph-folder-simple-star.icon-strain:before, i.ph-folder-simple-star.icon-gps:before, i.ph-folder-simple-star.icon-location:before, i.ph-folder-simple-star.icon-add:before, i.ph-folder-simple-star.icon-left:before, i.ph-folder-simple-star.icon-dna:before, i.ph-folder-simple-star.icon-delete:before, i.ph-folder-simple-star.icon-edit:before, i.ph-folder-simple-star.icon-info:before {
  content: "\ec2e";
}

.ph.ph-folder-simple-user:before, i.ph-folder-simple-user.icon-dead:before, i.ph-folder-simple-user.icon-alive:before, i.ph-folder-simple-user.icon-warn:before, i.ph-folder-simple-user.icon-sign-out:before, i.ph-folder-simple-user.icon-substratum:before, i.ph-folder-simple-user.icon-environment:before, i.ph-folder-simple-user.icon-user:before, i.ph-folder-simple-user.icon-person:before, i.ph-folder-simple-user.icon-terrain:before, i.ph-folder-simple-user.icon-taxon:before, i.ph-folder-simple-user.icon-unknown:before, i.ph-folder-simple-user.icon-nothing:before, i.ph-folder-simple-user.icon-sampling:before, i.ph-folder-simple-user.icon-isolation:before, i.ph-folder-simple-user.icon-strain:before, i.ph-folder-simple-user.icon-gps:before, i.ph-folder-simple-user.icon-location:before, i.ph-folder-simple-user.icon-add:before, i.ph-folder-simple-user.icon-left:before, i.ph-folder-simple-user.icon-dna:before, i.ph-folder-simple-user.icon-delete:before, i.ph-folder-simple-user.icon-edit:before, i.ph-folder-simple-user.icon-info:before {
  content: "\eb60";
}

.ph.ph-folder-star:before, i.ph-folder-star.icon-dead:before, i.ph-folder-star.icon-alive:before, i.ph-folder-star.icon-warn:before, i.ph-folder-star.icon-sign-out:before, i.ph-folder-star.icon-substratum:before, i.ph-folder-star.icon-environment:before, i.ph-folder-star.icon-user:before, i.ph-folder-star.icon-person:before, i.ph-folder-star.icon-terrain:before, i.ph-folder-star.icon-taxon:before, i.ph-folder-star.icon-unknown:before, i.ph-folder-star.icon-nothing:before, i.ph-folder-star.icon-sampling:before, i.ph-folder-star.icon-isolation:before, i.ph-folder-star.icon-strain:before, i.ph-folder-star.icon-gps:before, i.ph-folder-star.icon-location:before, i.ph-folder-star.icon-add:before, i.ph-folder-star.icon-left:before, i.ph-folder-star.icon-dna:before, i.ph-folder-star.icon-delete:before, i.ph-folder-star.icon-edit:before, i.ph-folder-star.icon-info:before {
  content: "\ea86";
}

.ph.ph-folder-user:before, i.ph-folder-user.icon-dead:before, i.ph-folder-user.icon-alive:before, i.ph-folder-user.icon-warn:before, i.ph-folder-user.icon-sign-out:before, i.ph-folder-user.icon-substratum:before, i.ph-folder-user.icon-environment:before, i.ph-folder-user.icon-user:before, i.ph-folder-user.icon-person:before, i.ph-folder-user.icon-terrain:before, i.ph-folder-user.icon-taxon:before, i.ph-folder-user.icon-unknown:before, i.ph-folder-user.icon-nothing:before, i.ph-folder-user.icon-sampling:before, i.ph-folder-user.icon-isolation:before, i.ph-folder-user.icon-strain:before, i.ph-folder-user.icon-gps:before, i.ph-folder-user.icon-location:before, i.ph-folder-user.icon-add:before, i.ph-folder-user.icon-left:before, i.ph-folder-user.icon-dna:before, i.ph-folder-user.icon-delete:before, i.ph-folder-user.icon-edit:before, i.ph-folder-user.icon-info:before {
  content: "\eb46";
}

.ph.ph-folders:before, i.ph-folders.icon-dead:before, i.ph-folders.icon-alive:before, i.ph-folders.icon-warn:before, i.ph-folders.icon-sign-out:before, i.ph-folders.icon-substratum:before, i.ph-folders.icon-environment:before, i.ph-folders.icon-user:before, i.ph-folders.icon-person:before, i.ph-folders.icon-terrain:before, i.ph-folders.icon-taxon:before, i.ph-folders.icon-unknown:before, i.ph-folders.icon-nothing:before, i.ph-folders.icon-sampling:before, i.ph-folders.icon-isolation:before, i.ph-folders.icon-strain:before, i.ph-folders.icon-gps:before, i.ph-folders.icon-location:before, i.ph-folders.icon-add:before, i.ph-folders.icon-left:before, i.ph-folders.icon-dna:before, i.ph-folders.icon-delete:before, i.ph-folders.icon-edit:before, i.ph-folders.icon-info:before {
  content: "\e260";
}

.ph.ph-football:before, i.ph-football.icon-dead:before, i.ph-football.icon-alive:before, i.ph-football.icon-warn:before, i.ph-football.icon-sign-out:before, i.ph-football.icon-substratum:before, i.ph-football.icon-environment:before, i.ph-football.icon-user:before, i.ph-football.icon-person:before, i.ph-football.icon-terrain:before, i.ph-football.icon-taxon:before, i.ph-football.icon-unknown:before, i.ph-football.icon-nothing:before, i.ph-football.icon-sampling:before, i.ph-football.icon-isolation:before, i.ph-football.icon-strain:before, i.ph-football.icon-gps:before, i.ph-football.icon-location:before, i.ph-football.icon-add:before, i.ph-football.icon-left:before, i.ph-football.icon-dna:before, i.ph-football.icon-delete:before, i.ph-football.icon-edit:before, i.ph-football.icon-info:before {
  content: "\e718";
}

.ph.ph-football-helmet:before, i.ph-football-helmet.icon-dead:before, i.ph-football-helmet.icon-alive:before, i.ph-football-helmet.icon-warn:before, i.ph-football-helmet.icon-sign-out:before, i.ph-football-helmet.icon-substratum:before, i.ph-football-helmet.icon-environment:before, i.ph-football-helmet.icon-user:before, i.ph-football-helmet.icon-person:before, i.ph-football-helmet.icon-terrain:before, i.ph-football-helmet.icon-taxon:before, i.ph-football-helmet.icon-unknown:before, i.ph-football-helmet.icon-nothing:before, i.ph-football-helmet.icon-sampling:before, i.ph-football-helmet.icon-isolation:before, i.ph-football-helmet.icon-strain:before, i.ph-football-helmet.icon-gps:before, i.ph-football-helmet.icon-location:before, i.ph-football-helmet.icon-add:before, i.ph-football-helmet.icon-left:before, i.ph-football-helmet.icon-dna:before, i.ph-football-helmet.icon-delete:before, i.ph-football-helmet.icon-edit:before, i.ph-football-helmet.icon-info:before {
  content: "\ee4c";
}

.ph.ph-footprints:before, i.ph-footprints.icon-dead:before, i.ph-footprints.icon-alive:before, i.ph-footprints.icon-warn:before, i.ph-footprints.icon-sign-out:before, i.ph-footprints.icon-substratum:before, i.ph-footprints.icon-environment:before, i.ph-footprints.icon-user:before, i.ph-footprints.icon-person:before, i.ph-footprints.icon-terrain:before, i.ph-footprints.icon-taxon:before, i.ph-footprints.icon-unknown:before, i.ph-footprints.icon-nothing:before, i.ph-footprints.icon-sampling:before, i.ph-footprints.icon-isolation:before, i.ph-footprints.icon-strain:before, i.ph-footprints.icon-gps:before, i.ph-footprints.icon-location:before, i.ph-footprints.icon-add:before, i.ph-footprints.icon-left:before, i.ph-footprints.icon-dna:before, i.ph-footprints.icon-delete:before, i.ph-footprints.icon-edit:before, i.ph-footprints.icon-info:before {
  content: "\ea88";
}

.ph.ph-fork-knife:before, i.ph-fork-knife.icon-dead:before, i.ph-fork-knife.icon-alive:before, i.ph-fork-knife.icon-warn:before, i.ph-fork-knife.icon-sign-out:before, i.ph-fork-knife.icon-substratum:before, i.ph-fork-knife.icon-environment:before, i.ph-fork-knife.icon-user:before, i.ph-fork-knife.icon-person:before, i.ph-fork-knife.icon-terrain:before, i.ph-fork-knife.icon-taxon:before, i.ph-fork-knife.icon-unknown:before, i.ph-fork-knife.icon-nothing:before, i.ph-fork-knife.icon-sampling:before, i.ph-fork-knife.icon-isolation:before, i.ph-fork-knife.icon-strain:before, i.ph-fork-knife.icon-gps:before, i.ph-fork-knife.icon-location:before, i.ph-fork-knife.icon-add:before, i.ph-fork-knife.icon-left:before, i.ph-fork-knife.icon-dna:before, i.ph-fork-knife.icon-delete:before, i.ph-fork-knife.icon-edit:before, i.ph-fork-knife.icon-info:before {
  content: "\e262";
}

.ph.ph-four-k:before, i.ph-four-k.icon-dead:before, i.ph-four-k.icon-alive:before, i.ph-four-k.icon-warn:before, i.ph-four-k.icon-sign-out:before, i.ph-four-k.icon-substratum:before, i.ph-four-k.icon-environment:before, i.ph-four-k.icon-user:before, i.ph-four-k.icon-person:before, i.ph-four-k.icon-terrain:before, i.ph-four-k.icon-taxon:before, i.ph-four-k.icon-unknown:before, i.ph-four-k.icon-nothing:before, i.ph-four-k.icon-sampling:before, i.ph-four-k.icon-isolation:before, i.ph-four-k.icon-strain:before, i.ph-four-k.icon-gps:before, i.ph-four-k.icon-location:before, i.ph-four-k.icon-add:before, i.ph-four-k.icon-left:before, i.ph-four-k.icon-dna:before, i.ph-four-k.icon-delete:before, i.ph-four-k.icon-edit:before, i.ph-four-k.icon-info:before {
  content: "\ea5c";
}

.ph.ph-frame-corners:before, i.ph-frame-corners.icon-dead:before, i.ph-frame-corners.icon-alive:before, i.ph-frame-corners.icon-warn:before, i.ph-frame-corners.icon-sign-out:before, i.ph-frame-corners.icon-substratum:before, i.ph-frame-corners.icon-environment:before, i.ph-frame-corners.icon-user:before, i.ph-frame-corners.icon-person:before, i.ph-frame-corners.icon-terrain:before, i.ph-frame-corners.icon-taxon:before, i.ph-frame-corners.icon-unknown:before, i.ph-frame-corners.icon-nothing:before, i.ph-frame-corners.icon-sampling:before, i.ph-frame-corners.icon-isolation:before, i.ph-frame-corners.icon-strain:before, i.ph-frame-corners.icon-gps:before, i.ph-frame-corners.icon-location:before, i.ph-frame-corners.icon-add:before, i.ph-frame-corners.icon-left:before, i.ph-frame-corners.icon-dna:before, i.ph-frame-corners.icon-delete:before, i.ph-frame-corners.icon-edit:before, i.ph-frame-corners.icon-info:before {
  content: "\e626";
}

.ph.ph-framer-logo:before, i.ph-framer-logo.icon-dead:before, i.ph-framer-logo.icon-alive:before, i.ph-framer-logo.icon-warn:before, i.ph-framer-logo.icon-sign-out:before, i.ph-framer-logo.icon-substratum:before, i.ph-framer-logo.icon-environment:before, i.ph-framer-logo.icon-user:before, i.ph-framer-logo.icon-person:before, i.ph-framer-logo.icon-terrain:before, i.ph-framer-logo.icon-taxon:before, i.ph-framer-logo.icon-unknown:before, i.ph-framer-logo.icon-nothing:before, i.ph-framer-logo.icon-sampling:before, i.ph-framer-logo.icon-isolation:before, i.ph-framer-logo.icon-strain:before, i.ph-framer-logo.icon-gps:before, i.ph-framer-logo.icon-location:before, i.ph-framer-logo.icon-add:before, i.ph-framer-logo.icon-left:before, i.ph-framer-logo.icon-dna:before, i.ph-framer-logo.icon-delete:before, i.ph-framer-logo.icon-edit:before, i.ph-framer-logo.icon-info:before {
  content: "\e264";
}

.ph.ph-function:before, i.ph-function.icon-dead:before, i.ph-function.icon-alive:before, i.ph-function.icon-warn:before, i.ph-function.icon-sign-out:before, i.ph-function.icon-substratum:before, i.ph-function.icon-environment:before, i.ph-function.icon-user:before, i.ph-function.icon-person:before, i.ph-function.icon-terrain:before, i.ph-function.icon-taxon:before, i.ph-function.icon-unknown:before, i.ph-function.icon-nothing:before, i.ph-function.icon-sampling:before, i.ph-function.icon-isolation:before, i.ph-function.icon-strain:before, i.ph-function.icon-gps:before, i.ph-function.icon-location:before, i.ph-function.icon-add:before, i.ph-function.icon-left:before, i.ph-function.icon-dna:before, i.ph-function.icon-delete:before, i.ph-function.icon-edit:before, i.ph-function.icon-info:before {
  content: "\ebe4";
}

.ph.ph-funnel:before, i.ph-funnel.icon-dead:before, i.ph-funnel.icon-alive:before, i.ph-funnel.icon-warn:before, i.ph-funnel.icon-sign-out:before, i.ph-funnel.icon-substratum:before, i.ph-funnel.icon-environment:before, i.ph-funnel.icon-user:before, i.ph-funnel.icon-person:before, i.ph-funnel.icon-terrain:before, i.ph-funnel.icon-taxon:before, i.ph-funnel.icon-unknown:before, i.ph-funnel.icon-nothing:before, i.ph-funnel.icon-sampling:before, i.ph-funnel.icon-isolation:before, i.ph-funnel.icon-strain:before, i.ph-funnel.icon-gps:before, i.ph-funnel.icon-location:before, i.ph-funnel.icon-add:before, i.ph-funnel.icon-left:before, i.ph-funnel.icon-dna:before, i.ph-funnel.icon-delete:before, i.ph-funnel.icon-edit:before, i.ph-funnel.icon-info:before {
  content: "\e266";
}

.ph.ph-funnel-simple:before, i.ph-funnel-simple.icon-dead:before, i.ph-funnel-simple.icon-alive:before, i.ph-funnel-simple.icon-warn:before, i.ph-funnel-simple.icon-sign-out:before, i.ph-funnel-simple.icon-substratum:before, i.ph-funnel-simple.icon-environment:before, i.ph-funnel-simple.icon-user:before, i.ph-funnel-simple.icon-person:before, i.ph-funnel-simple.icon-terrain:before, i.ph-funnel-simple.icon-taxon:before, i.ph-funnel-simple.icon-unknown:before, i.ph-funnel-simple.icon-nothing:before, i.ph-funnel-simple.icon-sampling:before, i.ph-funnel-simple.icon-isolation:before, i.ph-funnel-simple.icon-strain:before, i.ph-funnel-simple.icon-gps:before, i.ph-funnel-simple.icon-location:before, i.ph-funnel-simple.icon-add:before, i.ph-funnel-simple.icon-left:before, i.ph-funnel-simple.icon-dna:before, i.ph-funnel-simple.icon-delete:before, i.ph-funnel-simple.icon-edit:before, i.ph-funnel-simple.icon-info:before {
  content: "\e268";
}

.ph.ph-funnel-simple-x:before, i.ph-funnel-simple-x.icon-dead:before, i.ph-funnel-simple-x.icon-alive:before, i.ph-funnel-simple-x.icon-warn:before, i.ph-funnel-simple-x.icon-sign-out:before, i.ph-funnel-simple-x.icon-substratum:before, i.ph-funnel-simple-x.icon-environment:before, i.ph-funnel-simple-x.icon-user:before, i.ph-funnel-simple-x.icon-person:before, i.ph-funnel-simple-x.icon-terrain:before, i.ph-funnel-simple-x.icon-taxon:before, i.ph-funnel-simple-x.icon-unknown:before, i.ph-funnel-simple-x.icon-nothing:before, i.ph-funnel-simple-x.icon-sampling:before, i.ph-funnel-simple-x.icon-isolation:before, i.ph-funnel-simple-x.icon-strain:before, i.ph-funnel-simple-x.icon-gps:before, i.ph-funnel-simple-x.icon-location:before, i.ph-funnel-simple-x.icon-add:before, i.ph-funnel-simple-x.icon-left:before, i.ph-funnel-simple-x.icon-dna:before, i.ph-funnel-simple-x.icon-delete:before, i.ph-funnel-simple-x.icon-edit:before, i.ph-funnel-simple-x.icon-info:before {
  content: "\e26a";
}

.ph.ph-funnel-x:before, i.ph-funnel-x.icon-dead:before, i.ph-funnel-x.icon-alive:before, i.ph-funnel-x.icon-warn:before, i.ph-funnel-x.icon-sign-out:before, i.ph-funnel-x.icon-substratum:before, i.ph-funnel-x.icon-environment:before, i.ph-funnel-x.icon-user:before, i.ph-funnel-x.icon-person:before, i.ph-funnel-x.icon-terrain:before, i.ph-funnel-x.icon-taxon:before, i.ph-funnel-x.icon-unknown:before, i.ph-funnel-x.icon-nothing:before, i.ph-funnel-x.icon-sampling:before, i.ph-funnel-x.icon-isolation:before, i.ph-funnel-x.icon-strain:before, i.ph-funnel-x.icon-gps:before, i.ph-funnel-x.icon-location:before, i.ph-funnel-x.icon-add:before, i.ph-funnel-x.icon-left:before, i.ph-funnel-x.icon-dna:before, i.ph-funnel-x.icon-delete:before, i.ph-funnel-x.icon-edit:before, i.ph-funnel-x.icon-info:before {
  content: "\e26c";
}

.ph.ph-game-controller:before, i.ph-game-controller.icon-dead:before, i.ph-game-controller.icon-alive:before, i.ph-game-controller.icon-warn:before, i.ph-game-controller.icon-sign-out:before, i.ph-game-controller.icon-substratum:before, i.ph-game-controller.icon-environment:before, i.ph-game-controller.icon-user:before, i.ph-game-controller.icon-person:before, i.ph-game-controller.icon-terrain:before, i.ph-game-controller.icon-taxon:before, i.ph-game-controller.icon-unknown:before, i.ph-game-controller.icon-nothing:before, i.ph-game-controller.icon-sampling:before, i.ph-game-controller.icon-isolation:before, i.ph-game-controller.icon-strain:before, i.ph-game-controller.icon-gps:before, i.ph-game-controller.icon-location:before, i.ph-game-controller.icon-add:before, i.ph-game-controller.icon-left:before, i.ph-game-controller.icon-dna:before, i.ph-game-controller.icon-delete:before, i.ph-game-controller.icon-edit:before, i.ph-game-controller.icon-info:before {
  content: "\e26e";
}

.ph.ph-garage:before, i.ph-garage.icon-dead:before, i.ph-garage.icon-alive:before, i.ph-garage.icon-warn:before, i.ph-garage.icon-sign-out:before, i.ph-garage.icon-substratum:before, i.ph-garage.icon-environment:before, i.ph-garage.icon-user:before, i.ph-garage.icon-person:before, i.ph-garage.icon-terrain:before, i.ph-garage.icon-taxon:before, i.ph-garage.icon-unknown:before, i.ph-garage.icon-nothing:before, i.ph-garage.icon-sampling:before, i.ph-garage.icon-isolation:before, i.ph-garage.icon-strain:before, i.ph-garage.icon-gps:before, i.ph-garage.icon-location:before, i.ph-garage.icon-add:before, i.ph-garage.icon-left:before, i.ph-garage.icon-dna:before, i.ph-garage.icon-delete:before, i.ph-garage.icon-edit:before, i.ph-garage.icon-info:before {
  content: "\ecd6";
}

.ph.ph-gas-can:before, i.ph-gas-can.icon-dead:before, i.ph-gas-can.icon-alive:before, i.ph-gas-can.icon-warn:before, i.ph-gas-can.icon-sign-out:before, i.ph-gas-can.icon-substratum:before, i.ph-gas-can.icon-environment:before, i.ph-gas-can.icon-user:before, i.ph-gas-can.icon-person:before, i.ph-gas-can.icon-terrain:before, i.ph-gas-can.icon-taxon:before, i.ph-gas-can.icon-unknown:before, i.ph-gas-can.icon-nothing:before, i.ph-gas-can.icon-sampling:before, i.ph-gas-can.icon-isolation:before, i.ph-gas-can.icon-strain:before, i.ph-gas-can.icon-gps:before, i.ph-gas-can.icon-location:before, i.ph-gas-can.icon-add:before, i.ph-gas-can.icon-left:before, i.ph-gas-can.icon-dna:before, i.ph-gas-can.icon-delete:before, i.ph-gas-can.icon-edit:before, i.ph-gas-can.icon-info:before {
  content: "\e8ce";
}

.ph.ph-gas-pump:before, i.ph-gas-pump.icon-dead:before, i.ph-gas-pump.icon-alive:before, i.ph-gas-pump.icon-warn:before, i.ph-gas-pump.icon-sign-out:before, i.ph-gas-pump.icon-substratum:before, i.ph-gas-pump.icon-environment:before, i.ph-gas-pump.icon-user:before, i.ph-gas-pump.icon-person:before, i.ph-gas-pump.icon-terrain:before, i.ph-gas-pump.icon-taxon:before, i.ph-gas-pump.icon-unknown:before, i.ph-gas-pump.icon-nothing:before, i.ph-gas-pump.icon-sampling:before, i.ph-gas-pump.icon-isolation:before, i.ph-gas-pump.icon-strain:before, i.ph-gas-pump.icon-gps:before, i.ph-gas-pump.icon-location:before, i.ph-gas-pump.icon-add:before, i.ph-gas-pump.icon-left:before, i.ph-gas-pump.icon-dna:before, i.ph-gas-pump.icon-delete:before, i.ph-gas-pump.icon-edit:before, i.ph-gas-pump.icon-info:before {
  content: "\e768";
}

.ph.ph-gauge:before, i.ph-gauge.icon-dead:before, i.ph-gauge.icon-alive:before, i.ph-gauge.icon-warn:before, i.ph-gauge.icon-sign-out:before, i.ph-gauge.icon-substratum:before, i.ph-gauge.icon-environment:before, i.ph-gauge.icon-user:before, i.ph-gauge.icon-person:before, i.ph-gauge.icon-terrain:before, i.ph-gauge.icon-taxon:before, i.ph-gauge.icon-unknown:before, i.ph-gauge.icon-nothing:before, i.ph-gauge.icon-sampling:before, i.ph-gauge.icon-isolation:before, i.ph-gauge.icon-strain:before, i.ph-gauge.icon-gps:before, i.ph-gauge.icon-location:before, i.ph-gauge.icon-add:before, i.ph-gauge.icon-left:before, i.ph-gauge.icon-dna:before, i.ph-gauge.icon-delete:before, i.ph-gauge.icon-edit:before, i.ph-gauge.icon-info:before {
  content: "\e628";
}

.ph.ph-gavel:before, i.ph-gavel.icon-dead:before, i.ph-gavel.icon-alive:before, i.ph-gavel.icon-warn:before, i.ph-gavel.icon-sign-out:before, i.ph-gavel.icon-substratum:before, i.ph-gavel.icon-environment:before, i.ph-gavel.icon-user:before, i.ph-gavel.icon-person:before, i.ph-gavel.icon-terrain:before, i.ph-gavel.icon-taxon:before, i.ph-gavel.icon-unknown:before, i.ph-gavel.icon-nothing:before, i.ph-gavel.icon-sampling:before, i.ph-gavel.icon-isolation:before, i.ph-gavel.icon-strain:before, i.ph-gavel.icon-gps:before, i.ph-gavel.icon-location:before, i.ph-gavel.icon-add:before, i.ph-gavel.icon-left:before, i.ph-gavel.icon-dna:before, i.ph-gavel.icon-delete:before, i.ph-gavel.icon-edit:before, i.ph-gavel.icon-info:before {
  content: "\ea32";
}

.ph.ph-gear:before, i.ph-gear.icon-dead:before, i.ph-gear.icon-alive:before, i.ph-gear.icon-warn:before, i.ph-gear.icon-sign-out:before, i.ph-gear.icon-substratum:before, i.ph-gear.icon-environment:before, i.ph-gear.icon-user:before, i.ph-gear.icon-person:before, i.ph-gear.icon-terrain:before, i.ph-gear.icon-taxon:before, i.ph-gear.icon-unknown:before, i.ph-gear.icon-nothing:before, i.ph-gear.icon-sampling:before, i.ph-gear.icon-isolation:before, i.ph-gear.icon-strain:before, i.ph-gear.icon-gps:before, i.ph-gear.icon-location:before, i.ph-gear.icon-add:before, i.ph-gear.icon-left:before, i.ph-gear.icon-dna:before, i.ph-gear.icon-delete:before, i.ph-gear.icon-edit:before, i.ph-gear.icon-info:before {
  content: "\e270";
}

.ph.ph-gear-fine:before, i.ph-gear-fine.icon-dead:before, i.ph-gear-fine.icon-alive:before, i.ph-gear-fine.icon-warn:before, i.ph-gear-fine.icon-sign-out:before, i.ph-gear-fine.icon-substratum:before, i.ph-gear-fine.icon-environment:before, i.ph-gear-fine.icon-user:before, i.ph-gear-fine.icon-person:before, i.ph-gear-fine.icon-terrain:before, i.ph-gear-fine.icon-taxon:before, i.ph-gear-fine.icon-unknown:before, i.ph-gear-fine.icon-nothing:before, i.ph-gear-fine.icon-sampling:before, i.ph-gear-fine.icon-isolation:before, i.ph-gear-fine.icon-strain:before, i.ph-gear-fine.icon-gps:before, i.ph-gear-fine.icon-location:before, i.ph-gear-fine.icon-add:before, i.ph-gear-fine.icon-left:before, i.ph-gear-fine.icon-dna:before, i.ph-gear-fine.icon-delete:before, i.ph-gear-fine.icon-edit:before, i.ph-gear-fine.icon-info:before {
  content: "\e87c";
}

.ph.ph-gear-six:before, i.ph-gear-six.icon-dead:before, i.ph-gear-six.icon-alive:before, i.ph-gear-six.icon-warn:before, i.ph-gear-six.icon-sign-out:before, i.ph-gear-six.icon-substratum:before, i.ph-gear-six.icon-environment:before, i.ph-gear-six.icon-user:before, i.ph-gear-six.icon-person:before, i.ph-gear-six.icon-terrain:before, i.ph-gear-six.icon-taxon:before, i.ph-gear-six.icon-unknown:before, i.ph-gear-six.icon-nothing:before, i.ph-gear-six.icon-sampling:before, i.ph-gear-six.icon-isolation:before, i.ph-gear-six.icon-strain:before, i.ph-gear-six.icon-gps:before, i.ph-gear-six.icon-location:before, i.ph-gear-six.icon-add:before, i.ph-gear-six.icon-left:before, i.ph-gear-six.icon-dna:before, i.ph-gear-six.icon-delete:before, i.ph-gear-six.icon-edit:before, i.ph-gear-six.icon-info:before {
  content: "\e272";
}

.ph.ph-gender-female:before, i.ph-gender-female.icon-dead:before, i.ph-gender-female.icon-alive:before, i.ph-gender-female.icon-warn:before, i.ph-gender-female.icon-sign-out:before, i.ph-gender-female.icon-substratum:before, i.ph-gender-female.icon-environment:before, i.ph-gender-female.icon-user:before, i.ph-gender-female.icon-person:before, i.ph-gender-female.icon-terrain:before, i.ph-gender-female.icon-taxon:before, i.ph-gender-female.icon-unknown:before, i.ph-gender-female.icon-nothing:before, i.ph-gender-female.icon-sampling:before, i.ph-gender-female.icon-isolation:before, i.ph-gender-female.icon-strain:before, i.ph-gender-female.icon-gps:before, i.ph-gender-female.icon-location:before, i.ph-gender-female.icon-add:before, i.ph-gender-female.icon-left:before, i.ph-gender-female.icon-dna:before, i.ph-gender-female.icon-delete:before, i.ph-gender-female.icon-edit:before, i.ph-gender-female.icon-info:before {
  content: "\e6e0";
}

.ph.ph-gender-intersex:before, i.ph-gender-intersex.icon-dead:before, i.ph-gender-intersex.icon-alive:before, i.ph-gender-intersex.icon-warn:before, i.ph-gender-intersex.icon-sign-out:before, i.ph-gender-intersex.icon-substratum:before, i.ph-gender-intersex.icon-environment:before, i.ph-gender-intersex.icon-user:before, i.ph-gender-intersex.icon-person:before, i.ph-gender-intersex.icon-terrain:before, i.ph-gender-intersex.icon-taxon:before, i.ph-gender-intersex.icon-unknown:before, i.ph-gender-intersex.icon-nothing:before, i.ph-gender-intersex.icon-sampling:before, i.ph-gender-intersex.icon-isolation:before, i.ph-gender-intersex.icon-strain:before, i.ph-gender-intersex.icon-gps:before, i.ph-gender-intersex.icon-location:before, i.ph-gender-intersex.icon-add:before, i.ph-gender-intersex.icon-left:before, i.ph-gender-intersex.icon-dna:before, i.ph-gender-intersex.icon-delete:before, i.ph-gender-intersex.icon-edit:before, i.ph-gender-intersex.icon-info:before {
  content: "\e6e6";
}

.ph.ph-gender-male:before, i.ph-gender-male.icon-dead:before, i.ph-gender-male.icon-alive:before, i.ph-gender-male.icon-warn:before, i.ph-gender-male.icon-sign-out:before, i.ph-gender-male.icon-substratum:before, i.ph-gender-male.icon-environment:before, i.ph-gender-male.icon-user:before, i.ph-gender-male.icon-person:before, i.ph-gender-male.icon-terrain:before, i.ph-gender-male.icon-taxon:before, i.ph-gender-male.icon-unknown:before, i.ph-gender-male.icon-nothing:before, i.ph-gender-male.icon-sampling:before, i.ph-gender-male.icon-isolation:before, i.ph-gender-male.icon-strain:before, i.ph-gender-male.icon-gps:before, i.ph-gender-male.icon-location:before, i.ph-gender-male.icon-add:before, i.ph-gender-male.icon-left:before, i.ph-gender-male.icon-dna:before, i.ph-gender-male.icon-delete:before, i.ph-gender-male.icon-edit:before, i.ph-gender-male.icon-info:before {
  content: "\e6e2";
}

.ph.ph-gender-neuter:before, i.ph-gender-neuter.icon-dead:before, i.ph-gender-neuter.icon-alive:before, i.ph-gender-neuter.icon-warn:before, i.ph-gender-neuter.icon-sign-out:before, i.ph-gender-neuter.icon-substratum:before, i.ph-gender-neuter.icon-environment:before, i.ph-gender-neuter.icon-user:before, i.ph-gender-neuter.icon-person:before, i.ph-gender-neuter.icon-terrain:before, i.ph-gender-neuter.icon-taxon:before, i.ph-gender-neuter.icon-unknown:before, i.ph-gender-neuter.icon-nothing:before, i.ph-gender-neuter.icon-sampling:before, i.ph-gender-neuter.icon-isolation:before, i.ph-gender-neuter.icon-strain:before, i.ph-gender-neuter.icon-gps:before, i.ph-gender-neuter.icon-location:before, i.ph-gender-neuter.icon-add:before, i.ph-gender-neuter.icon-left:before, i.ph-gender-neuter.icon-dna:before, i.ph-gender-neuter.icon-delete:before, i.ph-gender-neuter.icon-edit:before, i.ph-gender-neuter.icon-info:before {
  content: "\e6ea";
}

.ph.ph-gender-nonbinary:before, i.ph-gender-nonbinary.icon-dead:before, i.ph-gender-nonbinary.icon-alive:before, i.ph-gender-nonbinary.icon-warn:before, i.ph-gender-nonbinary.icon-sign-out:before, i.ph-gender-nonbinary.icon-substratum:before, i.ph-gender-nonbinary.icon-environment:before, i.ph-gender-nonbinary.icon-user:before, i.ph-gender-nonbinary.icon-person:before, i.ph-gender-nonbinary.icon-terrain:before, i.ph-gender-nonbinary.icon-taxon:before, i.ph-gender-nonbinary.icon-unknown:before, i.ph-gender-nonbinary.icon-nothing:before, i.ph-gender-nonbinary.icon-sampling:before, i.ph-gender-nonbinary.icon-isolation:before, i.ph-gender-nonbinary.icon-strain:before, i.ph-gender-nonbinary.icon-gps:before, i.ph-gender-nonbinary.icon-location:before, i.ph-gender-nonbinary.icon-add:before, i.ph-gender-nonbinary.icon-left:before, i.ph-gender-nonbinary.icon-dna:before, i.ph-gender-nonbinary.icon-delete:before, i.ph-gender-nonbinary.icon-edit:before, i.ph-gender-nonbinary.icon-info:before {
  content: "\e6e4";
}

.ph.ph-gender-transgender:before, i.ph-gender-transgender.icon-dead:before, i.ph-gender-transgender.icon-alive:before, i.ph-gender-transgender.icon-warn:before, i.ph-gender-transgender.icon-sign-out:before, i.ph-gender-transgender.icon-substratum:before, i.ph-gender-transgender.icon-environment:before, i.ph-gender-transgender.icon-user:before, i.ph-gender-transgender.icon-person:before, i.ph-gender-transgender.icon-terrain:before, i.ph-gender-transgender.icon-taxon:before, i.ph-gender-transgender.icon-unknown:before, i.ph-gender-transgender.icon-nothing:before, i.ph-gender-transgender.icon-sampling:before, i.ph-gender-transgender.icon-isolation:before, i.ph-gender-transgender.icon-strain:before, i.ph-gender-transgender.icon-gps:before, i.ph-gender-transgender.icon-location:before, i.ph-gender-transgender.icon-add:before, i.ph-gender-transgender.icon-left:before, i.ph-gender-transgender.icon-dna:before, i.ph-gender-transgender.icon-delete:before, i.ph-gender-transgender.icon-edit:before, i.ph-gender-transgender.icon-info:before {
  content: "\e6e8";
}

.ph.ph-ghost:before, i.ph-ghost.icon-dead:before, i.ph-ghost.icon-alive:before, i.ph-ghost.icon-warn:before, i.ph-ghost.icon-sign-out:before, i.ph-ghost.icon-substratum:before, i.ph-ghost.icon-environment:before, i.ph-ghost.icon-user:before, i.ph-ghost.icon-person:before, i.ph-ghost.icon-terrain:before, i.ph-ghost.icon-taxon:before, i.ph-ghost.icon-unknown:before, i.ph-ghost.icon-nothing:before, i.ph-ghost.icon-sampling:before, i.ph-ghost.icon-isolation:before, i.ph-ghost.icon-strain:before, i.ph-ghost.icon-gps:before, i.ph-ghost.icon-location:before, i.ph-ghost.icon-add:before, i.ph-ghost.icon-left:before, i.ph-ghost.icon-dna:before, i.ph-ghost.icon-delete:before, i.ph-ghost.icon-edit:before, i.ph-ghost.icon-info:before {
  content: "\e62a";
}

.ph.ph-gif:before, i.ph-gif.icon-dead:before, i.ph-gif.icon-alive:before, i.ph-gif.icon-warn:before, i.ph-gif.icon-sign-out:before, i.ph-gif.icon-substratum:before, i.ph-gif.icon-environment:before, i.ph-gif.icon-user:before, i.ph-gif.icon-person:before, i.ph-gif.icon-terrain:before, i.ph-gif.icon-taxon:before, i.ph-gif.icon-unknown:before, i.ph-gif.icon-nothing:before, i.ph-gif.icon-sampling:before, i.ph-gif.icon-isolation:before, i.ph-gif.icon-strain:before, i.ph-gif.icon-gps:before, i.ph-gif.icon-location:before, i.ph-gif.icon-add:before, i.ph-gif.icon-left:before, i.ph-gif.icon-dna:before, i.ph-gif.icon-delete:before, i.ph-gif.icon-edit:before, i.ph-gif.icon-info:before {
  content: "\e274";
}

.ph.ph-gift:before, i.ph-gift.icon-dead:before, i.ph-gift.icon-alive:before, i.ph-gift.icon-warn:before, i.ph-gift.icon-sign-out:before, i.ph-gift.icon-substratum:before, i.ph-gift.icon-environment:before, i.ph-gift.icon-user:before, i.ph-gift.icon-person:before, i.ph-gift.icon-terrain:before, i.ph-gift.icon-taxon:before, i.ph-gift.icon-unknown:before, i.ph-gift.icon-nothing:before, i.ph-gift.icon-sampling:before, i.ph-gift.icon-isolation:before, i.ph-gift.icon-strain:before, i.ph-gift.icon-gps:before, i.ph-gift.icon-location:before, i.ph-gift.icon-add:before, i.ph-gift.icon-left:before, i.ph-gift.icon-dna:before, i.ph-gift.icon-delete:before, i.ph-gift.icon-edit:before, i.ph-gift.icon-info:before {
  content: "\e276";
}

.ph.ph-git-branch:before, i.ph-git-branch.icon-dead:before, i.ph-git-branch.icon-alive:before, i.ph-git-branch.icon-warn:before, i.ph-git-branch.icon-sign-out:before, i.ph-git-branch.icon-substratum:before, i.ph-git-branch.icon-environment:before, i.ph-git-branch.icon-user:before, i.ph-git-branch.icon-person:before, i.ph-git-branch.icon-terrain:before, i.ph-git-branch.icon-taxon:before, i.ph-git-branch.icon-unknown:before, i.ph-git-branch.icon-nothing:before, i.ph-git-branch.icon-sampling:before, i.ph-git-branch.icon-isolation:before, i.ph-git-branch.icon-strain:before, i.ph-git-branch.icon-gps:before, i.ph-git-branch.icon-location:before, i.ph-git-branch.icon-add:before, i.ph-git-branch.icon-left:before, i.ph-git-branch.icon-dna:before, i.ph-git-branch.icon-delete:before, i.ph-git-branch.icon-edit:before, i.ph-git-branch.icon-info:before {
  content: "\e278";
}

.ph.ph-git-commit:before, i.ph-git-commit.icon-dead:before, i.ph-git-commit.icon-alive:before, i.ph-git-commit.icon-warn:before, i.ph-git-commit.icon-sign-out:before, i.ph-git-commit.icon-substratum:before, i.ph-git-commit.icon-environment:before, i.ph-git-commit.icon-user:before, i.ph-git-commit.icon-person:before, i.ph-git-commit.icon-terrain:before, i.ph-git-commit.icon-taxon:before, i.ph-git-commit.icon-unknown:before, i.ph-git-commit.icon-nothing:before, i.ph-git-commit.icon-sampling:before, i.ph-git-commit.icon-isolation:before, i.ph-git-commit.icon-strain:before, i.ph-git-commit.icon-gps:before, i.ph-git-commit.icon-location:before, i.ph-git-commit.icon-add:before, i.ph-git-commit.icon-left:before, i.ph-git-commit.icon-dna:before, i.ph-git-commit.icon-delete:before, i.ph-git-commit.icon-edit:before, i.ph-git-commit.icon-info:before {
  content: "\e27a";
}

.ph.ph-git-diff:before, i.ph-git-diff.icon-dead:before, i.ph-git-diff.icon-alive:before, i.ph-git-diff.icon-warn:before, i.ph-git-diff.icon-sign-out:before, i.ph-git-diff.icon-substratum:before, i.ph-git-diff.icon-environment:before, i.ph-git-diff.icon-user:before, i.ph-git-diff.icon-person:before, i.ph-git-diff.icon-terrain:before, i.ph-git-diff.icon-taxon:before, i.ph-git-diff.icon-unknown:before, i.ph-git-diff.icon-nothing:before, i.ph-git-diff.icon-sampling:before, i.ph-git-diff.icon-isolation:before, i.ph-git-diff.icon-strain:before, i.ph-git-diff.icon-gps:before, i.ph-git-diff.icon-location:before, i.ph-git-diff.icon-add:before, i.ph-git-diff.icon-left:before, i.ph-git-diff.icon-dna:before, i.ph-git-diff.icon-delete:before, i.ph-git-diff.icon-edit:before, i.ph-git-diff.icon-info:before {
  content: "\e27c";
}

.ph.ph-git-fork:before, i.ph-git-fork.icon-dead:before, i.ph-git-fork.icon-alive:before, i.ph-git-fork.icon-warn:before, i.ph-git-fork.icon-sign-out:before, i.ph-git-fork.icon-substratum:before, i.ph-git-fork.icon-environment:before, i.ph-git-fork.icon-user:before, i.ph-git-fork.icon-person:before, i.ph-git-fork.icon-terrain:before, i.ph-git-fork.icon-taxon:before, i.ph-git-fork.icon-unknown:before, i.ph-git-fork.icon-nothing:before, i.ph-git-fork.icon-sampling:before, i.ph-git-fork.icon-isolation:before, i.ph-git-fork.icon-strain:before, i.ph-git-fork.icon-gps:before, i.ph-git-fork.icon-location:before, i.ph-git-fork.icon-add:before, i.ph-git-fork.icon-left:before, i.ph-git-fork.icon-dna:before, i.ph-git-fork.icon-delete:before, i.ph-git-fork.icon-edit:before, i.ph-git-fork.icon-info:before {
  content: "\e27e";
}

.ph.ph-git-merge:before, i.ph-git-merge.icon-dead:before, i.ph-git-merge.icon-alive:before, i.ph-git-merge.icon-warn:before, i.ph-git-merge.icon-sign-out:before, i.ph-git-merge.icon-substratum:before, i.ph-git-merge.icon-environment:before, i.ph-git-merge.icon-user:before, i.ph-git-merge.icon-person:before, i.ph-git-merge.icon-terrain:before, i.ph-git-merge.icon-taxon:before, i.ph-git-merge.icon-unknown:before, i.ph-git-merge.icon-nothing:before, i.ph-git-merge.icon-sampling:before, i.ph-git-merge.icon-isolation:before, i.ph-git-merge.icon-strain:before, i.ph-git-merge.icon-gps:before, i.ph-git-merge.icon-location:before, i.ph-git-merge.icon-add:before, i.ph-git-merge.icon-left:before, i.ph-git-merge.icon-dna:before, i.ph-git-merge.icon-delete:before, i.ph-git-merge.icon-edit:before, i.ph-git-merge.icon-info:before {
  content: "\e280";
}

.ph.ph-git-pull-request:before, i.ph-git-pull-request.icon-dead:before, i.ph-git-pull-request.icon-alive:before, i.ph-git-pull-request.icon-warn:before, i.ph-git-pull-request.icon-sign-out:before, i.ph-git-pull-request.icon-substratum:before, i.ph-git-pull-request.icon-environment:before, i.ph-git-pull-request.icon-user:before, i.ph-git-pull-request.icon-person:before, i.ph-git-pull-request.icon-terrain:before, i.ph-git-pull-request.icon-taxon:before, i.ph-git-pull-request.icon-unknown:before, i.ph-git-pull-request.icon-nothing:before, i.ph-git-pull-request.icon-sampling:before, i.ph-git-pull-request.icon-isolation:before, i.ph-git-pull-request.icon-strain:before, i.ph-git-pull-request.icon-gps:before, i.ph-git-pull-request.icon-location:before, i.ph-git-pull-request.icon-add:before, i.ph-git-pull-request.icon-left:before, i.ph-git-pull-request.icon-dna:before, i.ph-git-pull-request.icon-delete:before, i.ph-git-pull-request.icon-edit:before, i.ph-git-pull-request.icon-info:before {
  content: "\e282";
}

.ph.ph-github-logo:before, i.ph-github-logo.icon-dead:before, i.ph-github-logo.icon-alive:before, i.ph-github-logo.icon-warn:before, i.ph-github-logo.icon-sign-out:before, i.ph-github-logo.icon-substratum:before, i.ph-github-logo.icon-environment:before, i.ph-github-logo.icon-user:before, i.ph-github-logo.icon-person:before, i.ph-github-logo.icon-terrain:before, i.ph-github-logo.icon-taxon:before, i.ph-github-logo.icon-unknown:before, i.ph-github-logo.icon-nothing:before, i.ph-github-logo.icon-sampling:before, i.ph-github-logo.icon-isolation:before, i.ph-github-logo.icon-strain:before, i.ph-github-logo.icon-gps:before, i.ph-github-logo.icon-location:before, i.ph-github-logo.icon-add:before, i.ph-github-logo.icon-left:before, i.ph-github-logo.icon-dna:before, i.ph-github-logo.icon-delete:before, i.ph-github-logo.icon-edit:before, i.ph-github-logo.icon-info:before {
  content: "\e576";
}

.ph.ph-gitlab-logo:before, i.ph-gitlab-logo.icon-dead:before, i.ph-gitlab-logo.icon-alive:before, i.ph-gitlab-logo.icon-warn:before, i.ph-gitlab-logo.icon-sign-out:before, i.ph-gitlab-logo.icon-substratum:before, i.ph-gitlab-logo.icon-environment:before, i.ph-gitlab-logo.icon-user:before, i.ph-gitlab-logo.icon-person:before, i.ph-gitlab-logo.icon-terrain:before, i.ph-gitlab-logo.icon-taxon:before, i.ph-gitlab-logo.icon-unknown:before, i.ph-gitlab-logo.icon-nothing:before, i.ph-gitlab-logo.icon-sampling:before, i.ph-gitlab-logo.icon-isolation:before, i.ph-gitlab-logo.icon-strain:before, i.ph-gitlab-logo.icon-gps:before, i.ph-gitlab-logo.icon-location:before, i.ph-gitlab-logo.icon-add:before, i.ph-gitlab-logo.icon-left:before, i.ph-gitlab-logo.icon-dna:before, i.ph-gitlab-logo.icon-delete:before, i.ph-gitlab-logo.icon-edit:before, i.ph-gitlab-logo.icon-info:before {
  content: "\e694";
}

.ph.ph-gitlab-logo-simple:before, i.ph-gitlab-logo-simple.icon-dead:before, i.ph-gitlab-logo-simple.icon-alive:before, i.ph-gitlab-logo-simple.icon-warn:before, i.ph-gitlab-logo-simple.icon-sign-out:before, i.ph-gitlab-logo-simple.icon-substratum:before, i.ph-gitlab-logo-simple.icon-environment:before, i.ph-gitlab-logo-simple.icon-user:before, i.ph-gitlab-logo-simple.icon-person:before, i.ph-gitlab-logo-simple.icon-terrain:before, i.ph-gitlab-logo-simple.icon-taxon:before, i.ph-gitlab-logo-simple.icon-unknown:before, i.ph-gitlab-logo-simple.icon-nothing:before, i.ph-gitlab-logo-simple.icon-sampling:before, i.ph-gitlab-logo-simple.icon-isolation:before, i.ph-gitlab-logo-simple.icon-strain:before, i.ph-gitlab-logo-simple.icon-gps:before, i.ph-gitlab-logo-simple.icon-location:before, i.ph-gitlab-logo-simple.icon-add:before, i.ph-gitlab-logo-simple.icon-left:before, i.ph-gitlab-logo-simple.icon-dna:before, i.ph-gitlab-logo-simple.icon-delete:before, i.ph-gitlab-logo-simple.icon-edit:before, i.ph-gitlab-logo-simple.icon-info:before {
  content: "\e696";
}

.ph.ph-globe:before, i.ph-globe.icon-dead:before, i.ph-globe.icon-alive:before, i.ph-globe.icon-warn:before, i.ph-globe.icon-sign-out:before, i.ph-globe.icon-substratum:before, i.ph-globe.icon-environment:before, i.ph-globe.icon-user:before, i.ph-globe.icon-person:before, i.ph-globe.icon-terrain:before, i.ph-globe.icon-taxon:before, i.ph-globe.icon-unknown:before, i.ph-globe.icon-nothing:before, i.ph-globe.icon-sampling:before, i.ph-globe.icon-isolation:before, i.ph-globe.icon-strain:before, i.ph-globe.icon-gps:before, i.ph-globe.icon-location:before, i.ph-globe.icon-add:before, i.ph-globe.icon-left:before, i.ph-globe.icon-dna:before, i.ph-globe.icon-delete:before, i.ph-globe.icon-edit:before, i.ph-globe.icon-info:before {
  content: "\e288";
}

.ph.ph-globe-hemisphere-east:before, i.ph-globe-hemisphere-east.icon-dead:before, i.ph-globe-hemisphere-east.icon-alive:before, i.ph-globe-hemisphere-east.icon-warn:before, i.ph-globe-hemisphere-east.icon-sign-out:before, i.ph-globe-hemisphere-east.icon-substratum:before, i.ph-globe-hemisphere-east.icon-environment:before, i.ph-globe-hemisphere-east.icon-user:before, i.ph-globe-hemisphere-east.icon-person:before, i.ph-globe-hemisphere-east.icon-terrain:before, i.ph-globe-hemisphere-east.icon-taxon:before, i.ph-globe-hemisphere-east.icon-unknown:before, i.ph-globe-hemisphere-east.icon-nothing:before, i.ph-globe-hemisphere-east.icon-sampling:before, i.ph-globe-hemisphere-east.icon-isolation:before, i.ph-globe-hemisphere-east.icon-strain:before, i.ph-globe-hemisphere-east.icon-gps:before, i.ph-globe-hemisphere-east.icon-location:before, i.ph-globe-hemisphere-east.icon-add:before, i.ph-globe-hemisphere-east.icon-left:before, i.ph-globe-hemisphere-east.icon-dna:before, i.ph-globe-hemisphere-east.icon-delete:before, i.ph-globe-hemisphere-east.icon-edit:before, i.ph-globe-hemisphere-east.icon-info:before {
  content: "\e28a";
}

.ph.ph-globe-hemisphere-west:before, i.ph-globe-hemisphere-west.icon-dead:before, i.ph-globe-hemisphere-west.icon-alive:before, i.ph-globe-hemisphere-west.icon-warn:before, i.ph-globe-hemisphere-west.icon-sign-out:before, i.ph-globe-hemisphere-west.icon-substratum:before, i.ph-globe-hemisphere-west.icon-environment:before, i.ph-globe-hemisphere-west.icon-user:before, i.ph-globe-hemisphere-west.icon-person:before, i.ph-globe-hemisphere-west.icon-terrain:before, i.ph-globe-hemisphere-west.icon-taxon:before, i.ph-globe-hemisphere-west.icon-unknown:before, i.ph-globe-hemisphere-west.icon-nothing:before, i.ph-globe-hemisphere-west.icon-sampling:before, i.ph-globe-hemisphere-west.icon-isolation:before, i.ph-globe-hemisphere-west.icon-strain:before, i.ph-globe-hemisphere-west.icon-gps:before, i.ph-globe-hemisphere-west.icon-location:before, i.ph-globe-hemisphere-west.icon-add:before, i.ph-globe-hemisphere-west.icon-left:before, i.ph-globe-hemisphere-west.icon-dna:before, i.ph-globe-hemisphere-west.icon-delete:before, i.ph-globe-hemisphere-west.icon-edit:before, i.ph-globe-hemisphere-west.icon-info:before {
  content: "\e28c";
}

.ph.ph-globe-simple:before, i.ph-globe-simple.icon-dead:before, i.ph-globe-simple.icon-alive:before, i.ph-globe-simple.icon-warn:before, i.ph-globe-simple.icon-sign-out:before, i.ph-globe-simple.icon-substratum:before, i.ph-globe-simple.icon-environment:before, i.ph-globe-simple.icon-user:before, i.ph-globe-simple.icon-person:before, i.ph-globe-simple.icon-terrain:before, i.ph-globe-simple.icon-taxon:before, i.ph-globe-simple.icon-unknown:before, i.ph-globe-simple.icon-nothing:before, i.ph-globe-simple.icon-sampling:before, i.ph-globe-simple.icon-isolation:before, i.ph-globe-simple.icon-strain:before, i.ph-globe-simple.icon-gps:before, i.ph-globe-simple.icon-location:before, i.ph-globe-simple.icon-add:before, i.ph-globe-simple.icon-left:before, i.ph-globe-simple.icon-dna:before, i.ph-globe-simple.icon-delete:before, i.ph-globe-simple.icon-edit:before, i.ph-globe-simple.icon-info:before {
  content: "\e28e";
}

.ph.ph-globe-simple-x:before, i.ph-globe-simple-x.icon-dead:before, i.ph-globe-simple-x.icon-alive:before, i.ph-globe-simple-x.icon-warn:before, i.ph-globe-simple-x.icon-sign-out:before, i.ph-globe-simple-x.icon-substratum:before, i.ph-globe-simple-x.icon-environment:before, i.ph-globe-simple-x.icon-user:before, i.ph-globe-simple-x.icon-person:before, i.ph-globe-simple-x.icon-terrain:before, i.ph-globe-simple-x.icon-taxon:before, i.ph-globe-simple-x.icon-unknown:before, i.ph-globe-simple-x.icon-nothing:before, i.ph-globe-simple-x.icon-sampling:before, i.ph-globe-simple-x.icon-isolation:before, i.ph-globe-simple-x.icon-strain:before, i.ph-globe-simple-x.icon-gps:before, i.ph-globe-simple-x.icon-location:before, i.ph-globe-simple-x.icon-add:before, i.ph-globe-simple-x.icon-left:before, i.ph-globe-simple-x.icon-dna:before, i.ph-globe-simple-x.icon-delete:before, i.ph-globe-simple-x.icon-edit:before, i.ph-globe-simple-x.icon-info:before {
  content: "\e284";
}

.ph.ph-globe-stand:before, i.ph-globe-stand.icon-dead:before, i.ph-globe-stand.icon-alive:before, i.ph-globe-stand.icon-warn:before, i.ph-globe-stand.icon-sign-out:before, i.ph-globe-stand.icon-substratum:before, i.ph-globe-stand.icon-environment:before, i.ph-globe-stand.icon-user:before, i.ph-globe-stand.icon-person:before, i.ph-globe-stand.icon-terrain:before, i.ph-globe-stand.icon-taxon:before, i.ph-globe-stand.icon-unknown:before, i.ph-globe-stand.icon-nothing:before, i.ph-globe-stand.icon-sampling:before, i.ph-globe-stand.icon-isolation:before, i.ph-globe-stand.icon-strain:before, i.ph-globe-stand.icon-gps:before, i.ph-globe-stand.icon-location:before, i.ph-globe-stand.icon-add:before, i.ph-globe-stand.icon-left:before, i.ph-globe-stand.icon-dna:before, i.ph-globe-stand.icon-delete:before, i.ph-globe-stand.icon-edit:before, i.ph-globe-stand.icon-info:before {
  content: "\e290";
}

.ph.ph-globe-x:before, i.ph-globe-x.icon-dead:before, i.ph-globe-x.icon-alive:before, i.ph-globe-x.icon-warn:before, i.ph-globe-x.icon-sign-out:before, i.ph-globe-x.icon-substratum:before, i.ph-globe-x.icon-environment:before, i.ph-globe-x.icon-user:before, i.ph-globe-x.icon-person:before, i.ph-globe-x.icon-terrain:before, i.ph-globe-x.icon-taxon:before, i.ph-globe-x.icon-unknown:before, i.ph-globe-x.icon-nothing:before, i.ph-globe-x.icon-sampling:before, i.ph-globe-x.icon-isolation:before, i.ph-globe-x.icon-strain:before, i.ph-globe-x.icon-gps:before, i.ph-globe-x.icon-location:before, i.ph-globe-x.icon-add:before, i.ph-globe-x.icon-left:before, i.ph-globe-x.icon-dna:before, i.ph-globe-x.icon-delete:before, i.ph-globe-x.icon-edit:before, i.ph-globe-x.icon-info:before {
  content: "\e286";
}

.ph.ph-goggles:before, i.ph-goggles.icon-dead:before, i.ph-goggles.icon-alive:before, i.ph-goggles.icon-warn:before, i.ph-goggles.icon-sign-out:before, i.ph-goggles.icon-substratum:before, i.ph-goggles.icon-environment:before, i.ph-goggles.icon-user:before, i.ph-goggles.icon-person:before, i.ph-goggles.icon-terrain:before, i.ph-goggles.icon-taxon:before, i.ph-goggles.icon-unknown:before, i.ph-goggles.icon-nothing:before, i.ph-goggles.icon-sampling:before, i.ph-goggles.icon-isolation:before, i.ph-goggles.icon-strain:before, i.ph-goggles.icon-gps:before, i.ph-goggles.icon-location:before, i.ph-goggles.icon-add:before, i.ph-goggles.icon-left:before, i.ph-goggles.icon-dna:before, i.ph-goggles.icon-delete:before, i.ph-goggles.icon-edit:before, i.ph-goggles.icon-info:before {
  content: "\ecb4";
}

.ph.ph-golf:before, i.ph-golf.icon-dead:before, i.ph-golf.icon-alive:before, i.ph-golf.icon-warn:before, i.ph-golf.icon-sign-out:before, i.ph-golf.icon-substratum:before, i.ph-golf.icon-environment:before, i.ph-golf.icon-user:before, i.ph-golf.icon-person:before, i.ph-golf.icon-terrain:before, i.ph-golf.icon-taxon:before, i.ph-golf.icon-unknown:before, i.ph-golf.icon-nothing:before, i.ph-golf.icon-sampling:before, i.ph-golf.icon-isolation:before, i.ph-golf.icon-strain:before, i.ph-golf.icon-gps:before, i.ph-golf.icon-location:before, i.ph-golf.icon-add:before, i.ph-golf.icon-left:before, i.ph-golf.icon-dna:before, i.ph-golf.icon-delete:before, i.ph-golf.icon-edit:before, i.ph-golf.icon-info:before {
  content: "\ea3e";
}

.ph.ph-goodreads-logo:before, i.ph-goodreads-logo.icon-dead:before, i.ph-goodreads-logo.icon-alive:before, i.ph-goodreads-logo.icon-warn:before, i.ph-goodreads-logo.icon-sign-out:before, i.ph-goodreads-logo.icon-substratum:before, i.ph-goodreads-logo.icon-environment:before, i.ph-goodreads-logo.icon-user:before, i.ph-goodreads-logo.icon-person:before, i.ph-goodreads-logo.icon-terrain:before, i.ph-goodreads-logo.icon-taxon:before, i.ph-goodreads-logo.icon-unknown:before, i.ph-goodreads-logo.icon-nothing:before, i.ph-goodreads-logo.icon-sampling:before, i.ph-goodreads-logo.icon-isolation:before, i.ph-goodreads-logo.icon-strain:before, i.ph-goodreads-logo.icon-gps:before, i.ph-goodreads-logo.icon-location:before, i.ph-goodreads-logo.icon-add:before, i.ph-goodreads-logo.icon-left:before, i.ph-goodreads-logo.icon-dna:before, i.ph-goodreads-logo.icon-delete:before, i.ph-goodreads-logo.icon-edit:before, i.ph-goodreads-logo.icon-info:before {
  content: "\ed10";
}

.ph.ph-google-cardboard-logo:before, i.ph-google-cardboard-logo.icon-dead:before, i.ph-google-cardboard-logo.icon-alive:before, i.ph-google-cardboard-logo.icon-warn:before, i.ph-google-cardboard-logo.icon-sign-out:before, i.ph-google-cardboard-logo.icon-substratum:before, i.ph-google-cardboard-logo.icon-environment:before, i.ph-google-cardboard-logo.icon-user:before, i.ph-google-cardboard-logo.icon-person:before, i.ph-google-cardboard-logo.icon-terrain:before, i.ph-google-cardboard-logo.icon-taxon:before, i.ph-google-cardboard-logo.icon-unknown:before, i.ph-google-cardboard-logo.icon-nothing:before, i.ph-google-cardboard-logo.icon-sampling:before, i.ph-google-cardboard-logo.icon-isolation:before, i.ph-google-cardboard-logo.icon-strain:before, i.ph-google-cardboard-logo.icon-gps:before, i.ph-google-cardboard-logo.icon-location:before, i.ph-google-cardboard-logo.icon-add:before, i.ph-google-cardboard-logo.icon-left:before, i.ph-google-cardboard-logo.icon-dna:before, i.ph-google-cardboard-logo.icon-delete:before, i.ph-google-cardboard-logo.icon-edit:before, i.ph-google-cardboard-logo.icon-info:before {
  content: "\e7b6";
}

.ph.ph-google-chrome-logo:before, i.ph-google-chrome-logo.icon-dead:before, i.ph-google-chrome-logo.icon-alive:before, i.ph-google-chrome-logo.icon-warn:before, i.ph-google-chrome-logo.icon-sign-out:before, i.ph-google-chrome-logo.icon-substratum:before, i.ph-google-chrome-logo.icon-environment:before, i.ph-google-chrome-logo.icon-user:before, i.ph-google-chrome-logo.icon-person:before, i.ph-google-chrome-logo.icon-terrain:before, i.ph-google-chrome-logo.icon-taxon:before, i.ph-google-chrome-logo.icon-unknown:before, i.ph-google-chrome-logo.icon-nothing:before, i.ph-google-chrome-logo.icon-sampling:before, i.ph-google-chrome-logo.icon-isolation:before, i.ph-google-chrome-logo.icon-strain:before, i.ph-google-chrome-logo.icon-gps:before, i.ph-google-chrome-logo.icon-location:before, i.ph-google-chrome-logo.icon-add:before, i.ph-google-chrome-logo.icon-left:before, i.ph-google-chrome-logo.icon-dna:before, i.ph-google-chrome-logo.icon-delete:before, i.ph-google-chrome-logo.icon-edit:before, i.ph-google-chrome-logo.icon-info:before {
  content: "\e976";
}

.ph.ph-google-drive-logo:before, i.ph-google-drive-logo.icon-dead:before, i.ph-google-drive-logo.icon-alive:before, i.ph-google-drive-logo.icon-warn:before, i.ph-google-drive-logo.icon-sign-out:before, i.ph-google-drive-logo.icon-substratum:before, i.ph-google-drive-logo.icon-environment:before, i.ph-google-drive-logo.icon-user:before, i.ph-google-drive-logo.icon-person:before, i.ph-google-drive-logo.icon-terrain:before, i.ph-google-drive-logo.icon-taxon:before, i.ph-google-drive-logo.icon-unknown:before, i.ph-google-drive-logo.icon-nothing:before, i.ph-google-drive-logo.icon-sampling:before, i.ph-google-drive-logo.icon-isolation:before, i.ph-google-drive-logo.icon-strain:before, i.ph-google-drive-logo.icon-gps:before, i.ph-google-drive-logo.icon-location:before, i.ph-google-drive-logo.icon-add:before, i.ph-google-drive-logo.icon-left:before, i.ph-google-drive-logo.icon-dna:before, i.ph-google-drive-logo.icon-delete:before, i.ph-google-drive-logo.icon-edit:before, i.ph-google-drive-logo.icon-info:before {
  content: "\e8f6";
}

.ph.ph-google-logo:before, i.ph-google-logo.icon-dead:before, i.ph-google-logo.icon-alive:before, i.ph-google-logo.icon-warn:before, i.ph-google-logo.icon-sign-out:before, i.ph-google-logo.icon-substratum:before, i.ph-google-logo.icon-environment:before, i.ph-google-logo.icon-user:before, i.ph-google-logo.icon-person:before, i.ph-google-logo.icon-terrain:before, i.ph-google-logo.icon-taxon:before, i.ph-google-logo.icon-unknown:before, i.ph-google-logo.icon-nothing:before, i.ph-google-logo.icon-sampling:before, i.ph-google-logo.icon-isolation:before, i.ph-google-logo.icon-strain:before, i.ph-google-logo.icon-gps:before, i.ph-google-logo.icon-location:before, i.ph-google-logo.icon-add:before, i.ph-google-logo.icon-left:before, i.ph-google-logo.icon-dna:before, i.ph-google-logo.icon-delete:before, i.ph-google-logo.icon-edit:before, i.ph-google-logo.icon-info:before {
  content: "\e292";
}

.ph.ph-google-photos-logo:before, i.ph-google-photos-logo.icon-dead:before, i.ph-google-photos-logo.icon-alive:before, i.ph-google-photos-logo.icon-warn:before, i.ph-google-photos-logo.icon-sign-out:before, i.ph-google-photos-logo.icon-substratum:before, i.ph-google-photos-logo.icon-environment:before, i.ph-google-photos-logo.icon-user:before, i.ph-google-photos-logo.icon-person:before, i.ph-google-photos-logo.icon-terrain:before, i.ph-google-photos-logo.icon-taxon:before, i.ph-google-photos-logo.icon-unknown:before, i.ph-google-photos-logo.icon-nothing:before, i.ph-google-photos-logo.icon-sampling:before, i.ph-google-photos-logo.icon-isolation:before, i.ph-google-photos-logo.icon-strain:before, i.ph-google-photos-logo.icon-gps:before, i.ph-google-photos-logo.icon-location:before, i.ph-google-photos-logo.icon-add:before, i.ph-google-photos-logo.icon-left:before, i.ph-google-photos-logo.icon-dna:before, i.ph-google-photos-logo.icon-delete:before, i.ph-google-photos-logo.icon-edit:before, i.ph-google-photos-logo.icon-info:before {
  content: "\eb92";
}

.ph.ph-google-play-logo:before, i.ph-google-play-logo.icon-dead:before, i.ph-google-play-logo.icon-alive:before, i.ph-google-play-logo.icon-warn:before, i.ph-google-play-logo.icon-sign-out:before, i.ph-google-play-logo.icon-substratum:before, i.ph-google-play-logo.icon-environment:before, i.ph-google-play-logo.icon-user:before, i.ph-google-play-logo.icon-person:before, i.ph-google-play-logo.icon-terrain:before, i.ph-google-play-logo.icon-taxon:before, i.ph-google-play-logo.icon-unknown:before, i.ph-google-play-logo.icon-nothing:before, i.ph-google-play-logo.icon-sampling:before, i.ph-google-play-logo.icon-isolation:before, i.ph-google-play-logo.icon-strain:before, i.ph-google-play-logo.icon-gps:before, i.ph-google-play-logo.icon-location:before, i.ph-google-play-logo.icon-add:before, i.ph-google-play-logo.icon-left:before, i.ph-google-play-logo.icon-dna:before, i.ph-google-play-logo.icon-delete:before, i.ph-google-play-logo.icon-edit:before, i.ph-google-play-logo.icon-info:before {
  content: "\e294";
}

.ph.ph-google-podcasts-logo:before, i.ph-google-podcasts-logo.icon-dead:before, i.ph-google-podcasts-logo.icon-alive:before, i.ph-google-podcasts-logo.icon-warn:before, i.ph-google-podcasts-logo.icon-sign-out:before, i.ph-google-podcasts-logo.icon-substratum:before, i.ph-google-podcasts-logo.icon-environment:before, i.ph-google-podcasts-logo.icon-user:before, i.ph-google-podcasts-logo.icon-person:before, i.ph-google-podcasts-logo.icon-terrain:before, i.ph-google-podcasts-logo.icon-taxon:before, i.ph-google-podcasts-logo.icon-unknown:before, i.ph-google-podcasts-logo.icon-nothing:before, i.ph-google-podcasts-logo.icon-sampling:before, i.ph-google-podcasts-logo.icon-isolation:before, i.ph-google-podcasts-logo.icon-strain:before, i.ph-google-podcasts-logo.icon-gps:before, i.ph-google-podcasts-logo.icon-location:before, i.ph-google-podcasts-logo.icon-add:before, i.ph-google-podcasts-logo.icon-left:before, i.ph-google-podcasts-logo.icon-dna:before, i.ph-google-podcasts-logo.icon-delete:before, i.ph-google-podcasts-logo.icon-edit:before, i.ph-google-podcasts-logo.icon-info:before {
  content: "\eb94";
}

.ph.ph-gps:before, i.ph-gps.icon-dead:before, i.ph-gps.icon-alive:before, i.ph-gps.icon-warn:before, i.ph-gps.icon-sign-out:before, i.ph-gps.icon-substratum:before, i.ph-gps.icon-environment:before, i.ph-gps.icon-user:before, i.ph-gps.icon-person:before, i.ph-gps.icon-terrain:before, i.ph-gps.icon-taxon:before, i.ph-gps.icon-unknown:before, i.ph-gps.icon-nothing:before, i.ph-gps.icon-sampling:before, i.ph-gps.icon-isolation:before, i.ph-gps.icon-strain:before, i.ph-gps.icon-gps:before, i.ph-gps.icon-location:before, i.ph-gps.icon-add:before, i.ph-gps.icon-left:before, i.ph-gps.icon-dna:before, i.ph-gps.icon-delete:before, i.ph-gps.icon-edit:before, i.ph-gps.icon-info:before {
  content: "\edd8";
}

.ph.ph-gps-fix:before, i.ph-gps-fix.icon-dead:before, i.ph-gps-fix.icon-alive:before, i.ph-gps-fix.icon-warn:before, i.ph-gps-fix.icon-sign-out:before, i.ph-gps-fix.icon-substratum:before, i.ph-gps-fix.icon-environment:before, i.ph-gps-fix.icon-user:before, i.ph-gps-fix.icon-person:before, i.ph-gps-fix.icon-terrain:before, i.ph-gps-fix.icon-taxon:before, i.ph-gps-fix.icon-unknown:before, i.ph-gps-fix.icon-nothing:before, i.ph-gps-fix.icon-sampling:before, i.ph-gps-fix.icon-isolation:before, i.ph-gps-fix.icon-strain:before, i.icon-gps:before, i.ph-gps-fix.icon-location:before, i.ph-gps-fix.icon-add:before, i.ph-gps-fix.icon-left:before, i.ph-gps-fix.icon-dna:before, i.ph-gps-fix.icon-delete:before, i.ph-gps-fix.icon-edit:before, i.ph-gps-fix.icon-info:before {
  content: "\edd6";
}

.ph.ph-gps-slash:before, i.ph-gps-slash.icon-dead:before, i.ph-gps-slash.icon-alive:before, i.ph-gps-slash.icon-warn:before, i.ph-gps-slash.icon-sign-out:before, i.ph-gps-slash.icon-substratum:before, i.ph-gps-slash.icon-environment:before, i.ph-gps-slash.icon-user:before, i.ph-gps-slash.icon-person:before, i.ph-gps-slash.icon-terrain:before, i.ph-gps-slash.icon-taxon:before, i.ph-gps-slash.icon-unknown:before, i.ph-gps-slash.icon-nothing:before, i.ph-gps-slash.icon-sampling:before, i.ph-gps-slash.icon-isolation:before, i.ph-gps-slash.icon-strain:before, i.ph-gps-slash.icon-gps:before, i.ph-gps-slash.icon-location:before, i.ph-gps-slash.icon-add:before, i.ph-gps-slash.icon-left:before, i.ph-gps-slash.icon-dna:before, i.ph-gps-slash.icon-delete:before, i.ph-gps-slash.icon-edit:before, i.ph-gps-slash.icon-info:before {
  content: "\edd4";
}

.ph.ph-gradient:before, i.ph-gradient.icon-dead:before, i.ph-gradient.icon-alive:before, i.ph-gradient.icon-warn:before, i.ph-gradient.icon-sign-out:before, i.ph-gradient.icon-substratum:before, i.ph-gradient.icon-environment:before, i.ph-gradient.icon-user:before, i.ph-gradient.icon-person:before, i.ph-gradient.icon-terrain:before, i.ph-gradient.icon-taxon:before, i.ph-gradient.icon-unknown:before, i.ph-gradient.icon-nothing:before, i.ph-gradient.icon-sampling:before, i.ph-gradient.icon-isolation:before, i.ph-gradient.icon-strain:before, i.ph-gradient.icon-gps:before, i.ph-gradient.icon-location:before, i.ph-gradient.icon-add:before, i.ph-gradient.icon-left:before, i.ph-gradient.icon-dna:before, i.ph-gradient.icon-delete:before, i.ph-gradient.icon-edit:before, i.ph-gradient.icon-info:before {
  content: "\eb42";
}

.ph.ph-graduation-cap:before, i.ph-graduation-cap.icon-dead:before, i.ph-graduation-cap.icon-alive:before, i.ph-graduation-cap.icon-warn:before, i.ph-graduation-cap.icon-sign-out:before, i.ph-graduation-cap.icon-substratum:before, i.ph-graduation-cap.icon-environment:before, i.ph-graduation-cap.icon-user:before, i.ph-graduation-cap.icon-person:before, i.ph-graduation-cap.icon-terrain:before, i.ph-graduation-cap.icon-taxon:before, i.ph-graduation-cap.icon-unknown:before, i.ph-graduation-cap.icon-nothing:before, i.ph-graduation-cap.icon-sampling:before, i.ph-graduation-cap.icon-isolation:before, i.ph-graduation-cap.icon-strain:before, i.ph-graduation-cap.icon-gps:before, i.ph-graduation-cap.icon-location:before, i.ph-graduation-cap.icon-add:before, i.ph-graduation-cap.icon-left:before, i.ph-graduation-cap.icon-dna:before, i.ph-graduation-cap.icon-delete:before, i.ph-graduation-cap.icon-edit:before, i.ph-graduation-cap.icon-info:before {
  content: "\e62c";
}

.ph.ph-grains:before, i.ph-grains.icon-dead:before, i.ph-grains.icon-alive:before, i.ph-grains.icon-warn:before, i.ph-grains.icon-sign-out:before, i.ph-grains.icon-substratum:before, i.ph-grains.icon-environment:before, i.ph-grains.icon-user:before, i.ph-grains.icon-person:before, i.ph-grains.icon-terrain:before, i.ph-grains.icon-taxon:before, i.ph-grains.icon-unknown:before, i.ph-grains.icon-nothing:before, i.ph-grains.icon-sampling:before, i.ph-grains.icon-isolation:before, i.ph-grains.icon-strain:before, i.ph-grains.icon-gps:before, i.ph-grains.icon-location:before, i.ph-grains.icon-add:before, i.ph-grains.icon-left:before, i.ph-grains.icon-dna:before, i.ph-grains.icon-delete:before, i.ph-grains.icon-edit:before, i.ph-grains.icon-info:before {
  content: "\ec68";
}

.ph.ph-grains-slash:before, i.ph-grains-slash.icon-dead:before, i.ph-grains-slash.icon-alive:before, i.ph-grains-slash.icon-warn:before, i.ph-grains-slash.icon-sign-out:before, i.ph-grains-slash.icon-substratum:before, i.ph-grains-slash.icon-environment:before, i.ph-grains-slash.icon-user:before, i.ph-grains-slash.icon-person:before, i.ph-grains-slash.icon-terrain:before, i.ph-grains-slash.icon-taxon:before, i.ph-grains-slash.icon-unknown:before, i.ph-grains-slash.icon-nothing:before, i.ph-grains-slash.icon-sampling:before, i.ph-grains-slash.icon-isolation:before, i.ph-grains-slash.icon-strain:before, i.ph-grains-slash.icon-gps:before, i.ph-grains-slash.icon-location:before, i.ph-grains-slash.icon-add:before, i.ph-grains-slash.icon-left:before, i.ph-grains-slash.icon-dna:before, i.ph-grains-slash.icon-delete:before, i.ph-grains-slash.icon-edit:before, i.ph-grains-slash.icon-info:before {
  content: "\ec6a";
}

.ph.ph-graph:before, i.ph-graph.icon-dead:before, i.ph-graph.icon-alive:before, i.ph-graph.icon-warn:before, i.ph-graph.icon-sign-out:before, i.ph-graph.icon-substratum:before, i.ph-graph.icon-environment:before, i.ph-graph.icon-user:before, i.ph-graph.icon-person:before, i.ph-graph.icon-terrain:before, i.ph-graph.icon-taxon:before, i.ph-graph.icon-unknown:before, i.ph-graph.icon-nothing:before, i.ph-graph.icon-sampling:before, i.ph-graph.icon-isolation:before, i.ph-graph.icon-strain:before, i.ph-graph.icon-gps:before, i.ph-graph.icon-location:before, i.ph-graph.icon-add:before, i.ph-graph.icon-left:before, i.ph-graph.icon-dna:before, i.ph-graph.icon-delete:before, i.ph-graph.icon-edit:before, i.ph-graph.icon-info:before {
  content: "\eb58";
}

.ph.ph-graphics-card:before, i.ph-graphics-card.icon-dead:before, i.ph-graphics-card.icon-alive:before, i.ph-graphics-card.icon-warn:before, i.ph-graphics-card.icon-sign-out:before, i.ph-graphics-card.icon-substratum:before, i.ph-graphics-card.icon-environment:before, i.ph-graphics-card.icon-user:before, i.ph-graphics-card.icon-person:before, i.ph-graphics-card.icon-terrain:before, i.ph-graphics-card.icon-taxon:before, i.ph-graphics-card.icon-unknown:before, i.ph-graphics-card.icon-nothing:before, i.ph-graphics-card.icon-sampling:before, i.ph-graphics-card.icon-isolation:before, i.ph-graphics-card.icon-strain:before, i.ph-graphics-card.icon-gps:before, i.ph-graphics-card.icon-location:before, i.ph-graphics-card.icon-add:before, i.ph-graphics-card.icon-left:before, i.ph-graphics-card.icon-dna:before, i.ph-graphics-card.icon-delete:before, i.ph-graphics-card.icon-edit:before, i.ph-graphics-card.icon-info:before {
  content: "\e612";
}

.ph.ph-greater-than:before, i.ph-greater-than.icon-dead:before, i.ph-greater-than.icon-alive:before, i.ph-greater-than.icon-warn:before, i.ph-greater-than.icon-sign-out:before, i.ph-greater-than.icon-substratum:before, i.ph-greater-than.icon-environment:before, i.ph-greater-than.icon-user:before, i.ph-greater-than.icon-person:before, i.ph-greater-than.icon-terrain:before, i.ph-greater-than.icon-taxon:before, i.ph-greater-than.icon-unknown:before, i.ph-greater-than.icon-nothing:before, i.ph-greater-than.icon-sampling:before, i.ph-greater-than.icon-isolation:before, i.ph-greater-than.icon-strain:before, i.ph-greater-than.icon-gps:before, i.ph-greater-than.icon-location:before, i.ph-greater-than.icon-add:before, i.ph-greater-than.icon-left:before, i.ph-greater-than.icon-dna:before, i.ph-greater-than.icon-delete:before, i.ph-greater-than.icon-edit:before, i.ph-greater-than.icon-info:before {
  content: "\edc4";
}

.ph.ph-greater-than-or-equal:before, i.ph-greater-than-or-equal.icon-dead:before, i.ph-greater-than-or-equal.icon-alive:before, i.ph-greater-than-or-equal.icon-warn:before, i.ph-greater-than-or-equal.icon-sign-out:before, i.ph-greater-than-or-equal.icon-substratum:before, i.ph-greater-than-or-equal.icon-environment:before, i.ph-greater-than-or-equal.icon-user:before, i.ph-greater-than-or-equal.icon-person:before, i.ph-greater-than-or-equal.icon-terrain:before, i.ph-greater-than-or-equal.icon-taxon:before, i.ph-greater-than-or-equal.icon-unknown:before, i.ph-greater-than-or-equal.icon-nothing:before, i.ph-greater-than-or-equal.icon-sampling:before, i.ph-greater-than-or-equal.icon-isolation:before, i.ph-greater-than-or-equal.icon-strain:before, i.ph-greater-than-or-equal.icon-gps:before, i.ph-greater-than-or-equal.icon-location:before, i.ph-greater-than-or-equal.icon-add:before, i.ph-greater-than-or-equal.icon-left:before, i.ph-greater-than-or-equal.icon-dna:before, i.ph-greater-than-or-equal.icon-delete:before, i.ph-greater-than-or-equal.icon-edit:before, i.ph-greater-than-or-equal.icon-info:before {
  content: "\eda2";
}

.ph.ph-grid-four:before, i.ph-grid-four.icon-dead:before, i.ph-grid-four.icon-alive:before, i.ph-grid-four.icon-warn:before, i.ph-grid-four.icon-sign-out:before, i.ph-grid-four.icon-substratum:before, i.ph-grid-four.icon-environment:before, i.ph-grid-four.icon-user:before, i.ph-grid-four.icon-person:before, i.ph-grid-four.icon-terrain:before, i.ph-grid-four.icon-taxon:before, i.ph-grid-four.icon-unknown:before, i.ph-grid-four.icon-nothing:before, i.ph-grid-four.icon-sampling:before, i.ph-grid-four.icon-isolation:before, i.ph-grid-four.icon-strain:before, i.ph-grid-four.icon-gps:before, i.ph-grid-four.icon-location:before, i.ph-grid-four.icon-add:before, i.ph-grid-four.icon-left:before, i.ph-grid-four.icon-dna:before, i.ph-grid-four.icon-delete:before, i.ph-grid-four.icon-edit:before, i.ph-grid-four.icon-info:before {
  content: "\e296";
}

.ph.ph-grid-nine:before, i.ph-grid-nine.icon-dead:before, i.ph-grid-nine.icon-alive:before, i.ph-grid-nine.icon-warn:before, i.ph-grid-nine.icon-sign-out:before, i.ph-grid-nine.icon-substratum:before, i.ph-grid-nine.icon-environment:before, i.ph-grid-nine.icon-user:before, i.ph-grid-nine.icon-person:before, i.ph-grid-nine.icon-terrain:before, i.ph-grid-nine.icon-taxon:before, i.ph-grid-nine.icon-unknown:before, i.ph-grid-nine.icon-nothing:before, i.ph-grid-nine.icon-sampling:before, i.ph-grid-nine.icon-isolation:before, i.ph-grid-nine.icon-strain:before, i.ph-grid-nine.icon-gps:before, i.ph-grid-nine.icon-location:before, i.ph-grid-nine.icon-add:before, i.ph-grid-nine.icon-left:before, i.ph-grid-nine.icon-dna:before, i.ph-grid-nine.icon-delete:before, i.ph-grid-nine.icon-edit:before, i.ph-grid-nine.icon-info:before {
  content: "\ec8c";
}

.ph.ph-guitar:before, i.ph-guitar.icon-dead:before, i.ph-guitar.icon-alive:before, i.ph-guitar.icon-warn:before, i.ph-guitar.icon-sign-out:before, i.ph-guitar.icon-substratum:before, i.ph-guitar.icon-environment:before, i.ph-guitar.icon-user:before, i.ph-guitar.icon-person:before, i.ph-guitar.icon-terrain:before, i.ph-guitar.icon-taxon:before, i.ph-guitar.icon-unknown:before, i.ph-guitar.icon-nothing:before, i.ph-guitar.icon-sampling:before, i.ph-guitar.icon-isolation:before, i.ph-guitar.icon-strain:before, i.ph-guitar.icon-gps:before, i.ph-guitar.icon-location:before, i.ph-guitar.icon-add:before, i.ph-guitar.icon-left:before, i.ph-guitar.icon-dna:before, i.ph-guitar.icon-delete:before, i.ph-guitar.icon-edit:before, i.ph-guitar.icon-info:before {
  content: "\ea8a";
}

.ph.ph-hair-dryer:before, i.ph-hair-dryer.icon-dead:before, i.ph-hair-dryer.icon-alive:before, i.ph-hair-dryer.icon-warn:before, i.ph-hair-dryer.icon-sign-out:before, i.ph-hair-dryer.icon-substratum:before, i.ph-hair-dryer.icon-environment:before, i.ph-hair-dryer.icon-user:before, i.ph-hair-dryer.icon-person:before, i.ph-hair-dryer.icon-terrain:before, i.ph-hair-dryer.icon-taxon:before, i.ph-hair-dryer.icon-unknown:before, i.ph-hair-dryer.icon-nothing:before, i.ph-hair-dryer.icon-sampling:before, i.ph-hair-dryer.icon-isolation:before, i.ph-hair-dryer.icon-strain:before, i.ph-hair-dryer.icon-gps:before, i.ph-hair-dryer.icon-location:before, i.ph-hair-dryer.icon-add:before, i.ph-hair-dryer.icon-left:before, i.ph-hair-dryer.icon-dna:before, i.ph-hair-dryer.icon-delete:before, i.ph-hair-dryer.icon-edit:before, i.ph-hair-dryer.icon-info:before {
  content: "\ea66";
}

.ph.ph-hamburger:before, i.ph-hamburger.icon-dead:before, i.ph-hamburger.icon-alive:before, i.ph-hamburger.icon-warn:before, i.ph-hamburger.icon-sign-out:before, i.ph-hamburger.icon-substratum:before, i.ph-hamburger.icon-environment:before, i.ph-hamburger.icon-user:before, i.ph-hamburger.icon-person:before, i.ph-hamburger.icon-terrain:before, i.ph-hamburger.icon-taxon:before, i.ph-hamburger.icon-unknown:before, i.ph-hamburger.icon-nothing:before, i.ph-hamburger.icon-sampling:before, i.ph-hamburger.icon-isolation:before, i.ph-hamburger.icon-strain:before, i.ph-hamburger.icon-gps:before, i.ph-hamburger.icon-location:before, i.ph-hamburger.icon-add:before, i.ph-hamburger.icon-left:before, i.ph-hamburger.icon-dna:before, i.ph-hamburger.icon-delete:before, i.ph-hamburger.icon-edit:before, i.ph-hamburger.icon-info:before {
  content: "\e790";
}

.ph.ph-hammer:before, i.ph-hammer.icon-dead:before, i.ph-hammer.icon-alive:before, i.ph-hammer.icon-warn:before, i.ph-hammer.icon-sign-out:before, i.ph-hammer.icon-substratum:before, i.ph-hammer.icon-environment:before, i.ph-hammer.icon-user:before, i.ph-hammer.icon-person:before, i.ph-hammer.icon-terrain:before, i.ph-hammer.icon-taxon:before, i.ph-hammer.icon-unknown:before, i.ph-hammer.icon-nothing:before, i.ph-hammer.icon-sampling:before, i.ph-hammer.icon-isolation:before, i.ph-hammer.icon-strain:before, i.ph-hammer.icon-gps:before, i.ph-hammer.icon-location:before, i.ph-hammer.icon-add:before, i.ph-hammer.icon-left:before, i.ph-hammer.icon-dna:before, i.ph-hammer.icon-delete:before, i.ph-hammer.icon-edit:before, i.ph-hammer.icon-info:before {
  content: "\e80e";
}

.ph.ph-hand:before, i.ph-hand.icon-dead:before, i.ph-hand.icon-alive:before, i.ph-hand.icon-warn:before, i.ph-hand.icon-sign-out:before, i.ph-hand.icon-substratum:before, i.ph-hand.icon-environment:before, i.ph-hand.icon-user:before, i.ph-hand.icon-person:before, i.ph-hand.icon-terrain:before, i.ph-hand.icon-taxon:before, i.ph-hand.icon-unknown:before, i.ph-hand.icon-nothing:before, i.ph-hand.icon-sampling:before, i.ph-hand.icon-isolation:before, i.ph-hand.icon-strain:before, i.ph-hand.icon-gps:before, i.ph-hand.icon-location:before, i.ph-hand.icon-add:before, i.ph-hand.icon-left:before, i.ph-hand.icon-dna:before, i.ph-hand.icon-delete:before, i.ph-hand.icon-edit:before, i.ph-hand.icon-info:before {
  content: "\e298";
}

.ph.ph-hand-arrow-down:before, i.ph-hand-arrow-down.icon-dead:before, i.ph-hand-arrow-down.icon-alive:before, i.ph-hand-arrow-down.icon-warn:before, i.ph-hand-arrow-down.icon-sign-out:before, i.ph-hand-arrow-down.icon-substratum:before, i.ph-hand-arrow-down.icon-environment:before, i.ph-hand-arrow-down.icon-user:before, i.ph-hand-arrow-down.icon-person:before, i.ph-hand-arrow-down.icon-terrain:before, i.ph-hand-arrow-down.icon-taxon:before, i.ph-hand-arrow-down.icon-unknown:before, i.ph-hand-arrow-down.icon-nothing:before, i.ph-hand-arrow-down.icon-sampling:before, i.ph-hand-arrow-down.icon-isolation:before, i.ph-hand-arrow-down.icon-strain:before, i.ph-hand-arrow-down.icon-gps:before, i.ph-hand-arrow-down.icon-location:before, i.ph-hand-arrow-down.icon-add:before, i.ph-hand-arrow-down.icon-left:before, i.ph-hand-arrow-down.icon-dna:before, i.ph-hand-arrow-down.icon-delete:before, i.ph-hand-arrow-down.icon-edit:before, i.ph-hand-arrow-down.icon-info:before {
  content: "\ea4e";
}

.ph.ph-hand-arrow-up:before, i.ph-hand-arrow-up.icon-dead:before, i.ph-hand-arrow-up.icon-alive:before, i.ph-hand-arrow-up.icon-warn:before, i.ph-hand-arrow-up.icon-sign-out:before, i.ph-hand-arrow-up.icon-substratum:before, i.ph-hand-arrow-up.icon-environment:before, i.ph-hand-arrow-up.icon-user:before, i.ph-hand-arrow-up.icon-person:before, i.ph-hand-arrow-up.icon-terrain:before, i.ph-hand-arrow-up.icon-taxon:before, i.ph-hand-arrow-up.icon-unknown:before, i.ph-hand-arrow-up.icon-nothing:before, i.ph-hand-arrow-up.icon-sampling:before, i.ph-hand-arrow-up.icon-isolation:before, i.ph-hand-arrow-up.icon-strain:before, i.ph-hand-arrow-up.icon-gps:before, i.ph-hand-arrow-up.icon-location:before, i.ph-hand-arrow-up.icon-add:before, i.ph-hand-arrow-up.icon-left:before, i.ph-hand-arrow-up.icon-dna:before, i.ph-hand-arrow-up.icon-delete:before, i.ph-hand-arrow-up.icon-edit:before, i.ph-hand-arrow-up.icon-info:before {
  content: "\ee5a";
}

.ph.ph-hand-coins:before, i.ph-hand-coins.icon-dead:before, i.ph-hand-coins.icon-alive:before, i.ph-hand-coins.icon-warn:before, i.ph-hand-coins.icon-sign-out:before, i.ph-hand-coins.icon-substratum:before, i.ph-hand-coins.icon-environment:before, i.ph-hand-coins.icon-user:before, i.ph-hand-coins.icon-person:before, i.ph-hand-coins.icon-terrain:before, i.ph-hand-coins.icon-taxon:before, i.ph-hand-coins.icon-unknown:before, i.ph-hand-coins.icon-nothing:before, i.ph-hand-coins.icon-sampling:before, i.ph-hand-coins.icon-isolation:before, i.ph-hand-coins.icon-strain:before, i.ph-hand-coins.icon-gps:before, i.ph-hand-coins.icon-location:before, i.ph-hand-coins.icon-add:before, i.ph-hand-coins.icon-left:before, i.ph-hand-coins.icon-dna:before, i.ph-hand-coins.icon-delete:before, i.ph-hand-coins.icon-edit:before, i.ph-hand-coins.icon-info:before {
  content: "\ea8c";
}

.ph.ph-hand-deposit:before, i.ph-hand-deposit.icon-dead:before, i.ph-hand-deposit.icon-alive:before, i.ph-hand-deposit.icon-warn:before, i.ph-hand-deposit.icon-sign-out:before, i.ph-hand-deposit.icon-substratum:before, i.ph-hand-deposit.icon-environment:before, i.ph-hand-deposit.icon-user:before, i.ph-hand-deposit.icon-person:before, i.ph-hand-deposit.icon-terrain:before, i.ph-hand-deposit.icon-taxon:before, i.ph-hand-deposit.icon-unknown:before, i.ph-hand-deposit.icon-nothing:before, i.ph-hand-deposit.icon-sampling:before, i.ph-hand-deposit.icon-isolation:before, i.ph-hand-deposit.icon-strain:before, i.ph-hand-deposit.icon-gps:before, i.ph-hand-deposit.icon-location:before, i.ph-hand-deposit.icon-add:before, i.ph-hand-deposit.icon-left:before, i.ph-hand-deposit.icon-dna:before, i.ph-hand-deposit.icon-delete:before, i.ph-hand-deposit.icon-edit:before, i.ph-hand-deposit.icon-info:before {
  content: "\ee82";
}

.ph.ph-hand-eye:before, i.ph-hand-eye.icon-dead:before, i.ph-hand-eye.icon-alive:before, i.ph-hand-eye.icon-warn:before, i.ph-hand-eye.icon-sign-out:before, i.ph-hand-eye.icon-substratum:before, i.ph-hand-eye.icon-environment:before, i.ph-hand-eye.icon-user:before, i.ph-hand-eye.icon-person:before, i.ph-hand-eye.icon-terrain:before, i.ph-hand-eye.icon-taxon:before, i.ph-hand-eye.icon-unknown:before, i.ph-hand-eye.icon-nothing:before, i.ph-hand-eye.icon-sampling:before, i.ph-hand-eye.icon-isolation:before, i.ph-hand-eye.icon-strain:before, i.ph-hand-eye.icon-gps:before, i.ph-hand-eye.icon-location:before, i.ph-hand-eye.icon-add:before, i.ph-hand-eye.icon-left:before, i.ph-hand-eye.icon-dna:before, i.ph-hand-eye.icon-delete:before, i.ph-hand-eye.icon-edit:before, i.ph-hand-eye.icon-info:before {
  content: "\ea4c";
}

.ph.ph-hand-fist:before, i.ph-hand-fist.icon-dead:before, i.ph-hand-fist.icon-alive:before, i.ph-hand-fist.icon-warn:before, i.ph-hand-fist.icon-sign-out:before, i.ph-hand-fist.icon-substratum:before, i.ph-hand-fist.icon-environment:before, i.ph-hand-fist.icon-user:before, i.ph-hand-fist.icon-person:before, i.ph-hand-fist.icon-terrain:before, i.ph-hand-fist.icon-taxon:before, i.ph-hand-fist.icon-unknown:before, i.ph-hand-fist.icon-nothing:before, i.ph-hand-fist.icon-sampling:before, i.ph-hand-fist.icon-isolation:before, i.ph-hand-fist.icon-strain:before, i.ph-hand-fist.icon-gps:before, i.ph-hand-fist.icon-location:before, i.ph-hand-fist.icon-add:before, i.ph-hand-fist.icon-left:before, i.ph-hand-fist.icon-dna:before, i.ph-hand-fist.icon-delete:before, i.ph-hand-fist.icon-edit:before, i.ph-hand-fist.icon-info:before {
  content: "\e57a";
}

.ph.ph-hand-grabbing:before, i.ph-hand-grabbing.icon-dead:before, i.ph-hand-grabbing.icon-alive:before, i.ph-hand-grabbing.icon-warn:before, i.ph-hand-grabbing.icon-sign-out:before, i.ph-hand-grabbing.icon-substratum:before, i.ph-hand-grabbing.icon-environment:before, i.ph-hand-grabbing.icon-user:before, i.ph-hand-grabbing.icon-person:before, i.ph-hand-grabbing.icon-terrain:before, i.ph-hand-grabbing.icon-taxon:before, i.ph-hand-grabbing.icon-unknown:before, i.ph-hand-grabbing.icon-nothing:before, i.ph-hand-grabbing.icon-sampling:before, i.ph-hand-grabbing.icon-isolation:before, i.ph-hand-grabbing.icon-strain:before, i.ph-hand-grabbing.icon-gps:before, i.ph-hand-grabbing.icon-location:before, i.ph-hand-grabbing.icon-add:before, i.ph-hand-grabbing.icon-left:before, i.ph-hand-grabbing.icon-dna:before, i.ph-hand-grabbing.icon-delete:before, i.ph-hand-grabbing.icon-edit:before, i.ph-hand-grabbing.icon-info:before {
  content: "\e57c";
}

.ph.ph-hand-heart:before, i.ph-hand-heart.icon-dead:before, i.ph-hand-heart.icon-alive:before, i.ph-hand-heart.icon-warn:before, i.ph-hand-heart.icon-sign-out:before, i.ph-hand-heart.icon-substratum:before, i.ph-hand-heart.icon-environment:before, i.ph-hand-heart.icon-user:before, i.ph-hand-heart.icon-person:before, i.ph-hand-heart.icon-terrain:before, i.ph-hand-heart.icon-taxon:before, i.ph-hand-heart.icon-unknown:before, i.ph-hand-heart.icon-nothing:before, i.ph-hand-heart.icon-sampling:before, i.ph-hand-heart.icon-isolation:before, i.ph-hand-heart.icon-strain:before, i.ph-hand-heart.icon-gps:before, i.ph-hand-heart.icon-location:before, i.ph-hand-heart.icon-add:before, i.ph-hand-heart.icon-left:before, i.ph-hand-heart.icon-dna:before, i.ph-hand-heart.icon-delete:before, i.ph-hand-heart.icon-edit:before, i.ph-hand-heart.icon-info:before {
  content: "\e810";
}

.ph.ph-hand-palm:before, i.ph-hand-palm.icon-dead:before, i.ph-hand-palm.icon-alive:before, i.ph-hand-palm.icon-warn:before, i.ph-hand-palm.icon-sign-out:before, i.ph-hand-palm.icon-substratum:before, i.ph-hand-palm.icon-environment:before, i.ph-hand-palm.icon-user:before, i.ph-hand-palm.icon-person:before, i.ph-hand-palm.icon-terrain:before, i.ph-hand-palm.icon-taxon:before, i.ph-hand-palm.icon-unknown:before, i.ph-hand-palm.icon-nothing:before, i.ph-hand-palm.icon-sampling:before, i.ph-hand-palm.icon-isolation:before, i.ph-hand-palm.icon-strain:before, i.ph-hand-palm.icon-gps:before, i.ph-hand-palm.icon-location:before, i.ph-hand-palm.icon-add:before, i.ph-hand-palm.icon-left:before, i.ph-hand-palm.icon-dna:before, i.ph-hand-palm.icon-delete:before, i.ph-hand-palm.icon-edit:before, i.ph-hand-palm.icon-info:before {
  content: "\e57e";
}

.ph.ph-hand-peace:before, i.ph-hand-peace.icon-dead:before, i.ph-hand-peace.icon-alive:before, i.ph-hand-peace.icon-warn:before, i.ph-hand-peace.icon-sign-out:before, i.ph-hand-peace.icon-substratum:before, i.ph-hand-peace.icon-environment:before, i.ph-hand-peace.icon-user:before, i.ph-hand-peace.icon-person:before, i.ph-hand-peace.icon-terrain:before, i.ph-hand-peace.icon-taxon:before, i.ph-hand-peace.icon-unknown:before, i.ph-hand-peace.icon-nothing:before, i.ph-hand-peace.icon-sampling:before, i.ph-hand-peace.icon-isolation:before, i.ph-hand-peace.icon-strain:before, i.ph-hand-peace.icon-gps:before, i.ph-hand-peace.icon-location:before, i.ph-hand-peace.icon-add:before, i.ph-hand-peace.icon-left:before, i.ph-hand-peace.icon-dna:before, i.ph-hand-peace.icon-delete:before, i.ph-hand-peace.icon-edit:before, i.ph-hand-peace.icon-info:before {
  content: "\e7cc";
}

.ph.ph-hand-pointing:before, i.ph-hand-pointing.icon-dead:before, i.ph-hand-pointing.icon-alive:before, i.ph-hand-pointing.icon-warn:before, i.ph-hand-pointing.icon-sign-out:before, i.ph-hand-pointing.icon-substratum:before, i.ph-hand-pointing.icon-environment:before, i.ph-hand-pointing.icon-user:before, i.ph-hand-pointing.icon-person:before, i.ph-hand-pointing.icon-terrain:before, i.ph-hand-pointing.icon-taxon:before, i.ph-hand-pointing.icon-unknown:before, i.ph-hand-pointing.icon-nothing:before, i.ph-hand-pointing.icon-sampling:before, i.ph-hand-pointing.icon-isolation:before, i.ph-hand-pointing.icon-strain:before, i.ph-hand-pointing.icon-gps:before, i.ph-hand-pointing.icon-location:before, i.ph-hand-pointing.icon-add:before, i.ph-hand-pointing.icon-left:before, i.ph-hand-pointing.icon-dna:before, i.ph-hand-pointing.icon-delete:before, i.ph-hand-pointing.icon-edit:before, i.ph-hand-pointing.icon-info:before {
  content: "\e29a";
}

.ph.ph-hand-soap:before, i.ph-hand-soap.icon-dead:before, i.ph-hand-soap.icon-alive:before, i.ph-hand-soap.icon-warn:before, i.ph-hand-soap.icon-sign-out:before, i.ph-hand-soap.icon-substratum:before, i.ph-hand-soap.icon-environment:before, i.ph-hand-soap.icon-user:before, i.ph-hand-soap.icon-person:before, i.ph-hand-soap.icon-terrain:before, i.ph-hand-soap.icon-taxon:before, i.ph-hand-soap.icon-unknown:before, i.ph-hand-soap.icon-nothing:before, i.ph-hand-soap.icon-sampling:before, i.ph-hand-soap.icon-isolation:before, i.ph-hand-soap.icon-strain:before, i.ph-hand-soap.icon-gps:before, i.ph-hand-soap.icon-location:before, i.ph-hand-soap.icon-add:before, i.ph-hand-soap.icon-left:before, i.ph-hand-soap.icon-dna:before, i.ph-hand-soap.icon-delete:before, i.ph-hand-soap.icon-edit:before, i.ph-hand-soap.icon-info:before {
  content: "\e630";
}

.ph.ph-hand-swipe-left:before, i.ph-hand-swipe-left.icon-dead:before, i.ph-hand-swipe-left.icon-alive:before, i.ph-hand-swipe-left.icon-warn:before, i.ph-hand-swipe-left.icon-sign-out:before, i.ph-hand-swipe-left.icon-substratum:before, i.ph-hand-swipe-left.icon-environment:before, i.ph-hand-swipe-left.icon-user:before, i.ph-hand-swipe-left.icon-person:before, i.ph-hand-swipe-left.icon-terrain:before, i.ph-hand-swipe-left.icon-taxon:before, i.ph-hand-swipe-left.icon-unknown:before, i.ph-hand-swipe-left.icon-nothing:before, i.ph-hand-swipe-left.icon-sampling:before, i.ph-hand-swipe-left.icon-isolation:before, i.ph-hand-swipe-left.icon-strain:before, i.ph-hand-swipe-left.icon-gps:before, i.ph-hand-swipe-left.icon-location:before, i.ph-hand-swipe-left.icon-add:before, i.ph-hand-swipe-left.icon-left:before, i.ph-hand-swipe-left.icon-dna:before, i.ph-hand-swipe-left.icon-delete:before, i.ph-hand-swipe-left.icon-edit:before, i.ph-hand-swipe-left.icon-info:before {
  content: "\ec94";
}

.ph.ph-hand-swipe-right:before, i.ph-hand-swipe-right.icon-dead:before, i.ph-hand-swipe-right.icon-alive:before, i.ph-hand-swipe-right.icon-warn:before, i.ph-hand-swipe-right.icon-sign-out:before, i.ph-hand-swipe-right.icon-substratum:before, i.ph-hand-swipe-right.icon-environment:before, i.ph-hand-swipe-right.icon-user:before, i.ph-hand-swipe-right.icon-person:before, i.ph-hand-swipe-right.icon-terrain:before, i.ph-hand-swipe-right.icon-taxon:before, i.ph-hand-swipe-right.icon-unknown:before, i.ph-hand-swipe-right.icon-nothing:before, i.ph-hand-swipe-right.icon-sampling:before, i.ph-hand-swipe-right.icon-isolation:before, i.ph-hand-swipe-right.icon-strain:before, i.ph-hand-swipe-right.icon-gps:before, i.ph-hand-swipe-right.icon-location:before, i.ph-hand-swipe-right.icon-add:before, i.ph-hand-swipe-right.icon-left:before, i.ph-hand-swipe-right.icon-dna:before, i.ph-hand-swipe-right.icon-delete:before, i.ph-hand-swipe-right.icon-edit:before, i.ph-hand-swipe-right.icon-info:before {
  content: "\ec92";
}

.ph.ph-hand-tap:before, i.ph-hand-tap.icon-dead:before, i.ph-hand-tap.icon-alive:before, i.ph-hand-tap.icon-warn:before, i.ph-hand-tap.icon-sign-out:before, i.ph-hand-tap.icon-substratum:before, i.ph-hand-tap.icon-environment:before, i.ph-hand-tap.icon-user:before, i.ph-hand-tap.icon-person:before, i.ph-hand-tap.icon-terrain:before, i.ph-hand-tap.icon-taxon:before, i.ph-hand-tap.icon-unknown:before, i.ph-hand-tap.icon-nothing:before, i.ph-hand-tap.icon-sampling:before, i.ph-hand-tap.icon-isolation:before, i.ph-hand-tap.icon-strain:before, i.ph-hand-tap.icon-gps:before, i.ph-hand-tap.icon-location:before, i.ph-hand-tap.icon-add:before, i.ph-hand-tap.icon-left:before, i.ph-hand-tap.icon-dna:before, i.ph-hand-tap.icon-delete:before, i.ph-hand-tap.icon-edit:before, i.ph-hand-tap.icon-info:before {
  content: "\ec90";
}

.ph.ph-hand-waving:before, i.ph-hand-waving.icon-dead:before, i.ph-hand-waving.icon-alive:before, i.ph-hand-waving.icon-warn:before, i.ph-hand-waving.icon-sign-out:before, i.ph-hand-waving.icon-substratum:before, i.ph-hand-waving.icon-environment:before, i.ph-hand-waving.icon-user:before, i.ph-hand-waving.icon-person:before, i.ph-hand-waving.icon-terrain:before, i.ph-hand-waving.icon-taxon:before, i.ph-hand-waving.icon-unknown:before, i.ph-hand-waving.icon-nothing:before, i.ph-hand-waving.icon-sampling:before, i.ph-hand-waving.icon-isolation:before, i.ph-hand-waving.icon-strain:before, i.ph-hand-waving.icon-gps:before, i.ph-hand-waving.icon-location:before, i.ph-hand-waving.icon-add:before, i.ph-hand-waving.icon-left:before, i.ph-hand-waving.icon-dna:before, i.ph-hand-waving.icon-delete:before, i.ph-hand-waving.icon-edit:before, i.ph-hand-waving.icon-info:before {
  content: "\e580";
}

.ph.ph-hand-withdraw:before, i.ph-hand-withdraw.icon-dead:before, i.ph-hand-withdraw.icon-alive:before, i.ph-hand-withdraw.icon-warn:before, i.ph-hand-withdraw.icon-sign-out:before, i.ph-hand-withdraw.icon-substratum:before, i.ph-hand-withdraw.icon-environment:before, i.ph-hand-withdraw.icon-user:before, i.ph-hand-withdraw.icon-person:before, i.ph-hand-withdraw.icon-terrain:before, i.ph-hand-withdraw.icon-taxon:before, i.ph-hand-withdraw.icon-unknown:before, i.ph-hand-withdraw.icon-nothing:before, i.ph-hand-withdraw.icon-sampling:before, i.ph-hand-withdraw.icon-isolation:before, i.ph-hand-withdraw.icon-strain:before, i.ph-hand-withdraw.icon-gps:before, i.ph-hand-withdraw.icon-location:before, i.ph-hand-withdraw.icon-add:before, i.ph-hand-withdraw.icon-left:before, i.ph-hand-withdraw.icon-dna:before, i.ph-hand-withdraw.icon-delete:before, i.ph-hand-withdraw.icon-edit:before, i.ph-hand-withdraw.icon-info:before {
  content: "\ee80";
}

.ph.ph-handbag:before, i.ph-handbag.icon-dead:before, i.ph-handbag.icon-alive:before, i.ph-handbag.icon-warn:before, i.ph-handbag.icon-sign-out:before, i.ph-handbag.icon-substratum:before, i.ph-handbag.icon-environment:before, i.ph-handbag.icon-user:before, i.ph-handbag.icon-person:before, i.ph-handbag.icon-terrain:before, i.ph-handbag.icon-taxon:before, i.ph-handbag.icon-unknown:before, i.ph-handbag.icon-nothing:before, i.ph-handbag.icon-sampling:before, i.ph-handbag.icon-isolation:before, i.ph-handbag.icon-strain:before, i.ph-handbag.icon-gps:before, i.ph-handbag.icon-location:before, i.ph-handbag.icon-add:before, i.ph-handbag.icon-left:before, i.ph-handbag.icon-dna:before, i.ph-handbag.icon-delete:before, i.ph-handbag.icon-edit:before, i.ph-handbag.icon-info:before {
  content: "\e29c";
}

.ph.ph-handbag-simple:before, i.ph-handbag-simple.icon-dead:before, i.ph-handbag-simple.icon-alive:before, i.ph-handbag-simple.icon-warn:before, i.ph-handbag-simple.icon-sign-out:before, i.ph-handbag-simple.icon-substratum:before, i.ph-handbag-simple.icon-environment:before, i.ph-handbag-simple.icon-user:before, i.ph-handbag-simple.icon-person:before, i.ph-handbag-simple.icon-terrain:before, i.ph-handbag-simple.icon-taxon:before, i.ph-handbag-simple.icon-unknown:before, i.ph-handbag-simple.icon-nothing:before, i.ph-handbag-simple.icon-sampling:before, i.ph-handbag-simple.icon-isolation:before, i.ph-handbag-simple.icon-strain:before, i.ph-handbag-simple.icon-gps:before, i.ph-handbag-simple.icon-location:before, i.ph-handbag-simple.icon-add:before, i.ph-handbag-simple.icon-left:before, i.ph-handbag-simple.icon-dna:before, i.ph-handbag-simple.icon-delete:before, i.ph-handbag-simple.icon-edit:before, i.ph-handbag-simple.icon-info:before {
  content: "\e62e";
}

.ph.ph-hands-clapping:before, i.ph-hands-clapping.icon-dead:before, i.ph-hands-clapping.icon-alive:before, i.ph-hands-clapping.icon-warn:before, i.ph-hands-clapping.icon-sign-out:before, i.ph-hands-clapping.icon-substratum:before, i.ph-hands-clapping.icon-environment:before, i.ph-hands-clapping.icon-user:before, i.ph-hands-clapping.icon-person:before, i.ph-hands-clapping.icon-terrain:before, i.ph-hands-clapping.icon-taxon:before, i.ph-hands-clapping.icon-unknown:before, i.ph-hands-clapping.icon-nothing:before, i.ph-hands-clapping.icon-sampling:before, i.ph-hands-clapping.icon-isolation:before, i.ph-hands-clapping.icon-strain:before, i.ph-hands-clapping.icon-gps:before, i.ph-hands-clapping.icon-location:before, i.ph-hands-clapping.icon-add:before, i.ph-hands-clapping.icon-left:before, i.ph-hands-clapping.icon-dna:before, i.ph-hands-clapping.icon-delete:before, i.ph-hands-clapping.icon-edit:before, i.ph-hands-clapping.icon-info:before {
  content: "\e6a0";
}

.ph.ph-hands-praying:before, i.ph-hands-praying.icon-dead:before, i.ph-hands-praying.icon-alive:before, i.ph-hands-praying.icon-warn:before, i.ph-hands-praying.icon-sign-out:before, i.ph-hands-praying.icon-substratum:before, i.ph-hands-praying.icon-environment:before, i.ph-hands-praying.icon-user:before, i.ph-hands-praying.icon-person:before, i.ph-hands-praying.icon-terrain:before, i.ph-hands-praying.icon-taxon:before, i.ph-hands-praying.icon-unknown:before, i.ph-hands-praying.icon-nothing:before, i.ph-hands-praying.icon-sampling:before, i.ph-hands-praying.icon-isolation:before, i.ph-hands-praying.icon-strain:before, i.ph-hands-praying.icon-gps:before, i.ph-hands-praying.icon-location:before, i.ph-hands-praying.icon-add:before, i.ph-hands-praying.icon-left:before, i.ph-hands-praying.icon-dna:before, i.ph-hands-praying.icon-delete:before, i.ph-hands-praying.icon-edit:before, i.ph-hands-praying.icon-info:before {
  content: "\ecc8";
}

.ph.ph-handshake:before, i.ph-handshake.icon-dead:before, i.ph-handshake.icon-alive:before, i.ph-handshake.icon-warn:before, i.ph-handshake.icon-sign-out:before, i.ph-handshake.icon-substratum:before, i.ph-handshake.icon-environment:before, i.ph-handshake.icon-user:before, i.ph-handshake.icon-person:before, i.ph-handshake.icon-terrain:before, i.ph-handshake.icon-taxon:before, i.ph-handshake.icon-unknown:before, i.ph-handshake.icon-nothing:before, i.ph-handshake.icon-sampling:before, i.ph-handshake.icon-isolation:before, i.ph-handshake.icon-strain:before, i.ph-handshake.icon-gps:before, i.ph-handshake.icon-location:before, i.ph-handshake.icon-add:before, i.ph-handshake.icon-left:before, i.ph-handshake.icon-dna:before, i.ph-handshake.icon-delete:before, i.ph-handshake.icon-edit:before, i.ph-handshake.icon-info:before {
  content: "\e582";
}

.ph.ph-hard-drive:before, i.ph-hard-drive.icon-dead:before, i.ph-hard-drive.icon-alive:before, i.ph-hard-drive.icon-warn:before, i.ph-hard-drive.icon-sign-out:before, i.ph-hard-drive.icon-substratum:before, i.ph-hard-drive.icon-environment:before, i.ph-hard-drive.icon-user:before, i.ph-hard-drive.icon-person:before, i.ph-hard-drive.icon-terrain:before, i.ph-hard-drive.icon-taxon:before, i.ph-hard-drive.icon-unknown:before, i.ph-hard-drive.icon-nothing:before, i.ph-hard-drive.icon-sampling:before, i.ph-hard-drive.icon-isolation:before, i.ph-hard-drive.icon-strain:before, i.ph-hard-drive.icon-gps:before, i.ph-hard-drive.icon-location:before, i.ph-hard-drive.icon-add:before, i.ph-hard-drive.icon-left:before, i.ph-hard-drive.icon-dna:before, i.ph-hard-drive.icon-delete:before, i.ph-hard-drive.icon-edit:before, i.ph-hard-drive.icon-info:before {
  content: "\e29e";
}

.ph.ph-hard-drives:before, i.ph-hard-drives.icon-dead:before, i.ph-hard-drives.icon-alive:before, i.ph-hard-drives.icon-warn:before, i.ph-hard-drives.icon-sign-out:before, i.ph-hard-drives.icon-substratum:before, i.ph-hard-drives.icon-environment:before, i.ph-hard-drives.icon-user:before, i.ph-hard-drives.icon-person:before, i.ph-hard-drives.icon-terrain:before, i.ph-hard-drives.icon-taxon:before, i.ph-hard-drives.icon-unknown:before, i.ph-hard-drives.icon-nothing:before, i.ph-hard-drives.icon-sampling:before, i.ph-hard-drives.icon-isolation:before, i.ph-hard-drives.icon-strain:before, i.ph-hard-drives.icon-gps:before, i.ph-hard-drives.icon-location:before, i.ph-hard-drives.icon-add:before, i.ph-hard-drives.icon-left:before, i.ph-hard-drives.icon-dna:before, i.ph-hard-drives.icon-delete:before, i.ph-hard-drives.icon-edit:before, i.ph-hard-drives.icon-info:before {
  content: "\e2a0";
}

.ph.ph-hard-hat:before, i.ph-hard-hat.icon-dead:before, i.ph-hard-hat.icon-alive:before, i.ph-hard-hat.icon-warn:before, i.ph-hard-hat.icon-sign-out:before, i.ph-hard-hat.icon-substratum:before, i.ph-hard-hat.icon-environment:before, i.ph-hard-hat.icon-user:before, i.ph-hard-hat.icon-person:before, i.ph-hard-hat.icon-terrain:before, i.ph-hard-hat.icon-taxon:before, i.ph-hard-hat.icon-unknown:before, i.ph-hard-hat.icon-nothing:before, i.ph-hard-hat.icon-sampling:before, i.ph-hard-hat.icon-isolation:before, i.ph-hard-hat.icon-strain:before, i.ph-hard-hat.icon-gps:before, i.ph-hard-hat.icon-location:before, i.ph-hard-hat.icon-add:before, i.ph-hard-hat.icon-left:before, i.ph-hard-hat.icon-dna:before, i.ph-hard-hat.icon-delete:before, i.ph-hard-hat.icon-edit:before, i.ph-hard-hat.icon-info:before {
  content: "\ed46";
}

.ph.ph-hash:before, i.ph-hash.icon-dead:before, i.ph-hash.icon-alive:before, i.ph-hash.icon-warn:before, i.ph-hash.icon-sign-out:before, i.ph-hash.icon-substratum:before, i.ph-hash.icon-environment:before, i.ph-hash.icon-user:before, i.ph-hash.icon-person:before, i.ph-hash.icon-terrain:before, i.ph-hash.icon-taxon:before, i.ph-hash.icon-unknown:before, i.ph-hash.icon-nothing:before, i.ph-hash.icon-sampling:before, i.ph-hash.icon-isolation:before, i.ph-hash.icon-strain:before, i.ph-hash.icon-gps:before, i.ph-hash.icon-location:before, i.ph-hash.icon-add:before, i.ph-hash.icon-left:before, i.ph-hash.icon-dna:before, i.ph-hash.icon-delete:before, i.ph-hash.icon-edit:before, i.ph-hash.icon-info:before {
  content: "\e2a2";
}

.ph.ph-hash-straight:before, i.ph-hash-straight.icon-dead:before, i.ph-hash-straight.icon-alive:before, i.ph-hash-straight.icon-warn:before, i.ph-hash-straight.icon-sign-out:before, i.ph-hash-straight.icon-substratum:before, i.ph-hash-straight.icon-environment:before, i.ph-hash-straight.icon-user:before, i.ph-hash-straight.icon-person:before, i.ph-hash-straight.icon-terrain:before, i.ph-hash-straight.icon-taxon:before, i.ph-hash-straight.icon-unknown:before, i.ph-hash-straight.icon-nothing:before, i.ph-hash-straight.icon-sampling:before, i.ph-hash-straight.icon-isolation:before, i.ph-hash-straight.icon-strain:before, i.ph-hash-straight.icon-gps:before, i.ph-hash-straight.icon-location:before, i.ph-hash-straight.icon-add:before, i.ph-hash-straight.icon-left:before, i.ph-hash-straight.icon-dna:before, i.ph-hash-straight.icon-delete:before, i.ph-hash-straight.icon-edit:before, i.ph-hash-straight.icon-info:before {
  content: "\e2a4";
}

.ph.ph-head-circuit:before, i.ph-head-circuit.icon-dead:before, i.ph-head-circuit.icon-alive:before, i.ph-head-circuit.icon-warn:before, i.ph-head-circuit.icon-sign-out:before, i.ph-head-circuit.icon-substratum:before, i.ph-head-circuit.icon-environment:before, i.ph-head-circuit.icon-user:before, i.ph-head-circuit.icon-person:before, i.ph-head-circuit.icon-terrain:before, i.ph-head-circuit.icon-taxon:before, i.ph-head-circuit.icon-unknown:before, i.ph-head-circuit.icon-nothing:before, i.ph-head-circuit.icon-sampling:before, i.ph-head-circuit.icon-isolation:before, i.ph-head-circuit.icon-strain:before, i.ph-head-circuit.icon-gps:before, i.ph-head-circuit.icon-location:before, i.ph-head-circuit.icon-add:before, i.ph-head-circuit.icon-left:before, i.ph-head-circuit.icon-dna:before, i.ph-head-circuit.icon-delete:before, i.ph-head-circuit.icon-edit:before, i.ph-head-circuit.icon-info:before {
  content: "\e7d4";
}

.ph.ph-headlights:before, i.ph-headlights.icon-dead:before, i.ph-headlights.icon-alive:before, i.ph-headlights.icon-warn:before, i.ph-headlights.icon-sign-out:before, i.ph-headlights.icon-substratum:before, i.ph-headlights.icon-environment:before, i.ph-headlights.icon-user:before, i.ph-headlights.icon-person:before, i.ph-headlights.icon-terrain:before, i.ph-headlights.icon-taxon:before, i.ph-headlights.icon-unknown:before, i.ph-headlights.icon-nothing:before, i.ph-headlights.icon-sampling:before, i.ph-headlights.icon-isolation:before, i.ph-headlights.icon-strain:before, i.ph-headlights.icon-gps:before, i.ph-headlights.icon-location:before, i.ph-headlights.icon-add:before, i.ph-headlights.icon-left:before, i.ph-headlights.icon-dna:before, i.ph-headlights.icon-delete:before, i.ph-headlights.icon-edit:before, i.ph-headlights.icon-info:before {
  content: "\e6fe";
}

.ph.ph-headphones:before, i.ph-headphones.icon-dead:before, i.ph-headphones.icon-alive:before, i.ph-headphones.icon-warn:before, i.ph-headphones.icon-sign-out:before, i.ph-headphones.icon-substratum:before, i.ph-headphones.icon-environment:before, i.ph-headphones.icon-user:before, i.ph-headphones.icon-person:before, i.ph-headphones.icon-terrain:before, i.ph-headphones.icon-taxon:before, i.ph-headphones.icon-unknown:before, i.ph-headphones.icon-nothing:before, i.ph-headphones.icon-sampling:before, i.ph-headphones.icon-isolation:before, i.ph-headphones.icon-strain:before, i.ph-headphones.icon-gps:before, i.ph-headphones.icon-location:before, i.ph-headphones.icon-add:before, i.ph-headphones.icon-left:before, i.ph-headphones.icon-dna:before, i.ph-headphones.icon-delete:before, i.ph-headphones.icon-edit:before, i.ph-headphones.icon-info:before {
  content: "\e2a6";
}

.ph.ph-headset:before, i.ph-headset.icon-dead:before, i.ph-headset.icon-alive:before, i.ph-headset.icon-warn:before, i.ph-headset.icon-sign-out:before, i.ph-headset.icon-substratum:before, i.ph-headset.icon-environment:before, i.ph-headset.icon-user:before, i.ph-headset.icon-person:before, i.ph-headset.icon-terrain:before, i.ph-headset.icon-taxon:before, i.ph-headset.icon-unknown:before, i.ph-headset.icon-nothing:before, i.ph-headset.icon-sampling:before, i.ph-headset.icon-isolation:before, i.ph-headset.icon-strain:before, i.ph-headset.icon-gps:before, i.ph-headset.icon-location:before, i.ph-headset.icon-add:before, i.ph-headset.icon-left:before, i.ph-headset.icon-dna:before, i.ph-headset.icon-delete:before, i.ph-headset.icon-edit:before, i.ph-headset.icon-info:before {
  content: "\e584";
}

.ph.ph-heart:before, i.ph-heart.icon-dead:before, i.ph-heart.icon-alive:before, i.ph-heart.icon-warn:before, i.ph-heart.icon-sign-out:before, i.ph-heart.icon-substratum:before, i.ph-heart.icon-environment:before, i.ph-heart.icon-user:before, i.ph-heart.icon-person:before, i.ph-heart.icon-terrain:before, i.ph-heart.icon-taxon:before, i.ph-heart.icon-unknown:before, i.ph-heart.icon-nothing:before, i.ph-heart.icon-sampling:before, i.ph-heart.icon-isolation:before, i.ph-heart.icon-strain:before, i.ph-heart.icon-gps:before, i.ph-heart.icon-location:before, i.ph-heart.icon-add:before, i.ph-heart.icon-left:before, i.ph-heart.icon-dna:before, i.ph-heart.icon-delete:before, i.ph-heart.icon-edit:before, i.ph-heart.icon-info:before {
  content: "\e2a8";
}

.ph.ph-heart-break:before, i.ph-heart-break.icon-dead:before, i.ph-heart-break.icon-alive:before, i.ph-heart-break.icon-warn:before, i.ph-heart-break.icon-sign-out:before, i.ph-heart-break.icon-substratum:before, i.ph-heart-break.icon-environment:before, i.ph-heart-break.icon-user:before, i.ph-heart-break.icon-person:before, i.ph-heart-break.icon-terrain:before, i.ph-heart-break.icon-taxon:before, i.ph-heart-break.icon-unknown:before, i.ph-heart-break.icon-nothing:before, i.ph-heart-break.icon-sampling:before, i.ph-heart-break.icon-isolation:before, i.ph-heart-break.icon-strain:before, i.ph-heart-break.icon-gps:before, i.ph-heart-break.icon-location:before, i.ph-heart-break.icon-add:before, i.ph-heart-break.icon-left:before, i.ph-heart-break.icon-dna:before, i.ph-heart-break.icon-delete:before, i.ph-heart-break.icon-edit:before, i.ph-heart-break.icon-info:before {
  content: "\ebe8";
}

.ph.ph-heart-half:before, i.ph-heart-half.icon-dead:before, i.ph-heart-half.icon-alive:before, i.ph-heart-half.icon-warn:before, i.ph-heart-half.icon-sign-out:before, i.ph-heart-half.icon-substratum:before, i.ph-heart-half.icon-environment:before, i.ph-heart-half.icon-user:before, i.ph-heart-half.icon-person:before, i.ph-heart-half.icon-terrain:before, i.ph-heart-half.icon-taxon:before, i.ph-heart-half.icon-unknown:before, i.ph-heart-half.icon-nothing:before, i.ph-heart-half.icon-sampling:before, i.ph-heart-half.icon-isolation:before, i.ph-heart-half.icon-strain:before, i.ph-heart-half.icon-gps:before, i.ph-heart-half.icon-location:before, i.ph-heart-half.icon-add:before, i.ph-heart-half.icon-left:before, i.ph-heart-half.icon-dna:before, i.ph-heart-half.icon-delete:before, i.ph-heart-half.icon-edit:before, i.ph-heart-half.icon-info:before {
  content: "\ec48";
}

.ph.ph-heart-straight:before, i.ph-heart-straight.icon-dead:before, i.ph-heart-straight.icon-alive:before, i.ph-heart-straight.icon-warn:before, i.ph-heart-straight.icon-sign-out:before, i.ph-heart-straight.icon-substratum:before, i.ph-heart-straight.icon-environment:before, i.ph-heart-straight.icon-user:before, i.ph-heart-straight.icon-person:before, i.ph-heart-straight.icon-terrain:before, i.ph-heart-straight.icon-taxon:before, i.ph-heart-straight.icon-unknown:before, i.ph-heart-straight.icon-nothing:before, i.ph-heart-straight.icon-sampling:before, i.ph-heart-straight.icon-isolation:before, i.ph-heart-straight.icon-strain:before, i.ph-heart-straight.icon-gps:before, i.ph-heart-straight.icon-location:before, i.ph-heart-straight.icon-add:before, i.ph-heart-straight.icon-left:before, i.ph-heart-straight.icon-dna:before, i.ph-heart-straight.icon-delete:before, i.ph-heart-straight.icon-edit:before, i.ph-heart-straight.icon-info:before {
  content: "\e2aa";
}

.ph.ph-heart-straight-break:before, i.ph-heart-straight-break.icon-dead:before, i.ph-heart-straight-break.icon-alive:before, i.ph-heart-straight-break.icon-warn:before, i.ph-heart-straight-break.icon-sign-out:before, i.ph-heart-straight-break.icon-substratum:before, i.ph-heart-straight-break.icon-environment:before, i.ph-heart-straight-break.icon-user:before, i.ph-heart-straight-break.icon-person:before, i.ph-heart-straight-break.icon-terrain:before, i.ph-heart-straight-break.icon-taxon:before, i.ph-heart-straight-break.icon-unknown:before, i.ph-heart-straight-break.icon-nothing:before, i.ph-heart-straight-break.icon-sampling:before, i.ph-heart-straight-break.icon-isolation:before, i.ph-heart-straight-break.icon-strain:before, i.ph-heart-straight-break.icon-gps:before, i.ph-heart-straight-break.icon-location:before, i.ph-heart-straight-break.icon-add:before, i.ph-heart-straight-break.icon-left:before, i.ph-heart-straight-break.icon-dna:before, i.ph-heart-straight-break.icon-delete:before, i.ph-heart-straight-break.icon-edit:before, i.ph-heart-straight-break.icon-info:before {
  content: "\eb98";
}

.ph.ph-heartbeat:before, i.ph-heartbeat.icon-dead:before, i.ph-heartbeat.icon-alive:before, i.ph-heartbeat.icon-warn:before, i.ph-heartbeat.icon-sign-out:before, i.ph-heartbeat.icon-substratum:before, i.ph-heartbeat.icon-environment:before, i.ph-heartbeat.icon-user:before, i.ph-heartbeat.icon-person:before, i.ph-heartbeat.icon-terrain:before, i.ph-heartbeat.icon-taxon:before, i.ph-heartbeat.icon-unknown:before, i.ph-heartbeat.icon-nothing:before, i.ph-heartbeat.icon-sampling:before, i.ph-heartbeat.icon-isolation:before, i.ph-heartbeat.icon-strain:before, i.ph-heartbeat.icon-gps:before, i.ph-heartbeat.icon-location:before, i.ph-heartbeat.icon-add:before, i.ph-heartbeat.icon-left:before, i.ph-heartbeat.icon-dna:before, i.ph-heartbeat.icon-delete:before, i.ph-heartbeat.icon-edit:before, i.ph-heartbeat.icon-info:before {
  content: "\e2ac";
}

.ph.ph-hexagon:before, i.ph-hexagon.icon-dead:before, i.ph-hexagon.icon-alive:before, i.ph-hexagon.icon-warn:before, i.ph-hexagon.icon-sign-out:before, i.ph-hexagon.icon-substratum:before, i.ph-hexagon.icon-environment:before, i.ph-hexagon.icon-user:before, i.ph-hexagon.icon-person:before, i.ph-hexagon.icon-terrain:before, i.ph-hexagon.icon-taxon:before, i.ph-hexagon.icon-unknown:before, i.ph-hexagon.icon-nothing:before, i.ph-hexagon.icon-sampling:before, i.ph-hexagon.icon-isolation:before, i.ph-hexagon.icon-strain:before, i.ph-hexagon.icon-gps:before, i.ph-hexagon.icon-location:before, i.ph-hexagon.icon-add:before, i.ph-hexagon.icon-left:before, i.ph-hexagon.icon-dna:before, i.ph-hexagon.icon-delete:before, i.ph-hexagon.icon-edit:before, i.ph-hexagon.icon-info:before {
  content: "\e2ae";
}

.ph.ph-high-definition:before, i.ph-high-definition.icon-dead:before, i.ph-high-definition.icon-alive:before, i.ph-high-definition.icon-warn:before, i.ph-high-definition.icon-sign-out:before, i.ph-high-definition.icon-substratum:before, i.ph-high-definition.icon-environment:before, i.ph-high-definition.icon-user:before, i.ph-high-definition.icon-person:before, i.ph-high-definition.icon-terrain:before, i.ph-high-definition.icon-taxon:before, i.ph-high-definition.icon-unknown:before, i.ph-high-definition.icon-nothing:before, i.ph-high-definition.icon-sampling:before, i.ph-high-definition.icon-isolation:before, i.ph-high-definition.icon-strain:before, i.ph-high-definition.icon-gps:before, i.ph-high-definition.icon-location:before, i.ph-high-definition.icon-add:before, i.ph-high-definition.icon-left:before, i.ph-high-definition.icon-dna:before, i.ph-high-definition.icon-delete:before, i.ph-high-definition.icon-edit:before, i.ph-high-definition.icon-info:before {
  content: "\ea8e";
}

.ph.ph-high-heel:before, i.ph-high-heel.icon-dead:before, i.ph-high-heel.icon-alive:before, i.ph-high-heel.icon-warn:before, i.ph-high-heel.icon-sign-out:before, i.ph-high-heel.icon-substratum:before, i.ph-high-heel.icon-environment:before, i.ph-high-heel.icon-user:before, i.ph-high-heel.icon-person:before, i.ph-high-heel.icon-terrain:before, i.ph-high-heel.icon-taxon:before, i.ph-high-heel.icon-unknown:before, i.ph-high-heel.icon-nothing:before, i.ph-high-heel.icon-sampling:before, i.ph-high-heel.icon-isolation:before, i.ph-high-heel.icon-strain:before, i.ph-high-heel.icon-gps:before, i.ph-high-heel.icon-location:before, i.ph-high-heel.icon-add:before, i.ph-high-heel.icon-left:before, i.ph-high-heel.icon-dna:before, i.ph-high-heel.icon-delete:before, i.ph-high-heel.icon-edit:before, i.ph-high-heel.icon-info:before {
  content: "\e8e8";
}

.ph.ph-highlighter:before, i.ph-highlighter.icon-dead:before, i.ph-highlighter.icon-alive:before, i.ph-highlighter.icon-warn:before, i.ph-highlighter.icon-sign-out:before, i.ph-highlighter.icon-substratum:before, i.ph-highlighter.icon-environment:before, i.ph-highlighter.icon-user:before, i.ph-highlighter.icon-person:before, i.ph-highlighter.icon-terrain:before, i.ph-highlighter.icon-taxon:before, i.ph-highlighter.icon-unknown:before, i.ph-highlighter.icon-nothing:before, i.ph-highlighter.icon-sampling:before, i.ph-highlighter.icon-isolation:before, i.ph-highlighter.icon-strain:before, i.ph-highlighter.icon-gps:before, i.ph-highlighter.icon-location:before, i.ph-highlighter.icon-add:before, i.ph-highlighter.icon-left:before, i.ph-highlighter.icon-dna:before, i.ph-highlighter.icon-delete:before, i.ph-highlighter.icon-edit:before, i.ph-highlighter.icon-info:before {
  content: "\ec76";
}

.ph.ph-highlighter-circle:before, i.ph-highlighter-circle.icon-dead:before, i.ph-highlighter-circle.icon-alive:before, i.ph-highlighter-circle.icon-warn:before, i.ph-highlighter-circle.icon-sign-out:before, i.ph-highlighter-circle.icon-substratum:before, i.ph-highlighter-circle.icon-environment:before, i.ph-highlighter-circle.icon-user:before, i.ph-highlighter-circle.icon-person:before, i.ph-highlighter-circle.icon-terrain:before, i.ph-highlighter-circle.icon-taxon:before, i.ph-highlighter-circle.icon-unknown:before, i.ph-highlighter-circle.icon-nothing:before, i.ph-highlighter-circle.icon-sampling:before, i.ph-highlighter-circle.icon-isolation:before, i.ph-highlighter-circle.icon-strain:before, i.ph-highlighter-circle.icon-gps:before, i.ph-highlighter-circle.icon-location:before, i.ph-highlighter-circle.icon-add:before, i.ph-highlighter-circle.icon-left:before, i.ph-highlighter-circle.icon-dna:before, i.ph-highlighter-circle.icon-delete:before, i.ph-highlighter-circle.icon-edit:before, i.ph-highlighter-circle.icon-info:before {
  content: "\e632";
}

.ph.ph-hockey:before, i.ph-hockey.icon-dead:before, i.ph-hockey.icon-alive:before, i.ph-hockey.icon-warn:before, i.ph-hockey.icon-sign-out:before, i.ph-hockey.icon-substratum:before, i.ph-hockey.icon-environment:before, i.ph-hockey.icon-user:before, i.ph-hockey.icon-person:before, i.ph-hockey.icon-terrain:before, i.ph-hockey.icon-taxon:before, i.ph-hockey.icon-unknown:before, i.ph-hockey.icon-nothing:before, i.ph-hockey.icon-sampling:before, i.ph-hockey.icon-isolation:before, i.ph-hockey.icon-strain:before, i.ph-hockey.icon-gps:before, i.ph-hockey.icon-location:before, i.ph-hockey.icon-add:before, i.ph-hockey.icon-left:before, i.ph-hockey.icon-dna:before, i.ph-hockey.icon-delete:before, i.ph-hockey.icon-edit:before, i.ph-hockey.icon-info:before {
  content: "\ec86";
}

.ph.ph-hoodie:before, i.ph-hoodie.icon-dead:before, i.ph-hoodie.icon-alive:before, i.ph-hoodie.icon-warn:before, i.ph-hoodie.icon-sign-out:before, i.ph-hoodie.icon-substratum:before, i.ph-hoodie.icon-environment:before, i.ph-hoodie.icon-user:before, i.ph-hoodie.icon-person:before, i.ph-hoodie.icon-terrain:before, i.ph-hoodie.icon-taxon:before, i.ph-hoodie.icon-unknown:before, i.ph-hoodie.icon-nothing:before, i.ph-hoodie.icon-sampling:before, i.ph-hoodie.icon-isolation:before, i.ph-hoodie.icon-strain:before, i.ph-hoodie.icon-gps:before, i.ph-hoodie.icon-location:before, i.ph-hoodie.icon-add:before, i.ph-hoodie.icon-left:before, i.ph-hoodie.icon-dna:before, i.ph-hoodie.icon-delete:before, i.ph-hoodie.icon-edit:before, i.ph-hoodie.icon-info:before {
  content: "\ecd0";
}

.ph.ph-horse:before, i.ph-horse.icon-dead:before, i.ph-horse.icon-alive:before, i.ph-horse.icon-warn:before, i.ph-horse.icon-sign-out:before, i.ph-horse.icon-substratum:before, i.ph-horse.icon-environment:before, i.ph-horse.icon-user:before, i.ph-horse.icon-person:before, i.ph-horse.icon-terrain:before, i.ph-horse.icon-taxon:before, i.ph-horse.icon-unknown:before, i.ph-horse.icon-nothing:before, i.ph-horse.icon-sampling:before, i.ph-horse.icon-isolation:before, i.ph-horse.icon-strain:before, i.ph-horse.icon-gps:before, i.ph-horse.icon-location:before, i.ph-horse.icon-add:before, i.ph-horse.icon-left:before, i.ph-horse.icon-dna:before, i.ph-horse.icon-delete:before, i.ph-horse.icon-edit:before, i.ph-horse.icon-info:before {
  content: "\e2b0";
}

.ph.ph-hospital:before, i.ph-hospital.icon-dead:before, i.ph-hospital.icon-alive:before, i.ph-hospital.icon-warn:before, i.ph-hospital.icon-sign-out:before, i.ph-hospital.icon-substratum:before, i.ph-hospital.icon-environment:before, i.ph-hospital.icon-user:before, i.ph-hospital.icon-person:before, i.ph-hospital.icon-terrain:before, i.ph-hospital.icon-taxon:before, i.ph-hospital.icon-unknown:before, i.ph-hospital.icon-nothing:before, i.ph-hospital.icon-sampling:before, i.ph-hospital.icon-isolation:before, i.ph-hospital.icon-strain:before, i.ph-hospital.icon-gps:before, i.ph-hospital.icon-location:before, i.ph-hospital.icon-add:before, i.ph-hospital.icon-left:before, i.ph-hospital.icon-dna:before, i.ph-hospital.icon-delete:before, i.ph-hospital.icon-edit:before, i.ph-hospital.icon-info:before {
  content: "\e844";
}

.ph.ph-hourglass:before, i.ph-hourglass.icon-dead:before, i.ph-hourglass.icon-alive:before, i.ph-hourglass.icon-warn:before, i.ph-hourglass.icon-sign-out:before, i.ph-hourglass.icon-substratum:before, i.ph-hourglass.icon-environment:before, i.ph-hourglass.icon-user:before, i.ph-hourglass.icon-person:before, i.ph-hourglass.icon-terrain:before, i.ph-hourglass.icon-taxon:before, i.ph-hourglass.icon-unknown:before, i.ph-hourglass.icon-nothing:before, i.ph-hourglass.icon-sampling:before, i.ph-hourglass.icon-isolation:before, i.ph-hourglass.icon-strain:before, i.ph-hourglass.icon-gps:before, i.ph-hourglass.icon-location:before, i.ph-hourglass.icon-add:before, i.ph-hourglass.icon-left:before, i.ph-hourglass.icon-dna:before, i.ph-hourglass.icon-delete:before, i.ph-hourglass.icon-edit:before, i.ph-hourglass.icon-info:before {
  content: "\e2b2";
}

.ph.ph-hourglass-high:before, i.ph-hourglass-high.icon-dead:before, i.ph-hourglass-high.icon-alive:before, i.ph-hourglass-high.icon-warn:before, i.ph-hourglass-high.icon-sign-out:before, i.ph-hourglass-high.icon-substratum:before, i.ph-hourglass-high.icon-environment:before, i.ph-hourglass-high.icon-user:before, i.ph-hourglass-high.icon-person:before, i.ph-hourglass-high.icon-terrain:before, i.ph-hourglass-high.icon-taxon:before, i.ph-hourglass-high.icon-unknown:before, i.ph-hourglass-high.icon-nothing:before, i.ph-hourglass-high.icon-sampling:before, i.ph-hourglass-high.icon-isolation:before, i.ph-hourglass-high.icon-strain:before, i.ph-hourglass-high.icon-gps:before, i.ph-hourglass-high.icon-location:before, i.ph-hourglass-high.icon-add:before, i.ph-hourglass-high.icon-left:before, i.ph-hourglass-high.icon-dna:before, i.ph-hourglass-high.icon-delete:before, i.ph-hourglass-high.icon-edit:before, i.ph-hourglass-high.icon-info:before {
  content: "\e2b4";
}

.ph.ph-hourglass-low:before, i.ph-hourglass-low.icon-dead:before, i.ph-hourglass-low.icon-alive:before, i.ph-hourglass-low.icon-warn:before, i.ph-hourglass-low.icon-sign-out:before, i.ph-hourglass-low.icon-substratum:before, i.ph-hourglass-low.icon-environment:before, i.ph-hourglass-low.icon-user:before, i.ph-hourglass-low.icon-person:before, i.ph-hourglass-low.icon-terrain:before, i.ph-hourglass-low.icon-taxon:before, i.ph-hourglass-low.icon-unknown:before, i.ph-hourglass-low.icon-nothing:before, i.ph-hourglass-low.icon-sampling:before, i.ph-hourglass-low.icon-isolation:before, i.ph-hourglass-low.icon-strain:before, i.ph-hourglass-low.icon-gps:before, i.ph-hourglass-low.icon-location:before, i.ph-hourglass-low.icon-add:before, i.ph-hourglass-low.icon-left:before, i.ph-hourglass-low.icon-dna:before, i.ph-hourglass-low.icon-delete:before, i.ph-hourglass-low.icon-edit:before, i.ph-hourglass-low.icon-info:before {
  content: "\e2b6";
}

.ph.ph-hourglass-medium:before, i.ph-hourglass-medium.icon-dead:before, i.ph-hourglass-medium.icon-alive:before, i.ph-hourglass-medium.icon-warn:before, i.ph-hourglass-medium.icon-sign-out:before, i.ph-hourglass-medium.icon-substratum:before, i.ph-hourglass-medium.icon-environment:before, i.ph-hourglass-medium.icon-user:before, i.ph-hourglass-medium.icon-person:before, i.ph-hourglass-medium.icon-terrain:before, i.ph-hourglass-medium.icon-taxon:before, i.ph-hourglass-medium.icon-unknown:before, i.ph-hourglass-medium.icon-nothing:before, i.ph-hourglass-medium.icon-sampling:before, i.ph-hourglass-medium.icon-isolation:before, i.ph-hourglass-medium.icon-strain:before, i.ph-hourglass-medium.icon-gps:before, i.ph-hourglass-medium.icon-location:before, i.ph-hourglass-medium.icon-add:before, i.ph-hourglass-medium.icon-left:before, i.ph-hourglass-medium.icon-dna:before, i.ph-hourglass-medium.icon-delete:before, i.ph-hourglass-medium.icon-edit:before, i.ph-hourglass-medium.icon-info:before {
  content: "\e2b8";
}

.ph.ph-hourglass-simple:before, i.ph-hourglass-simple.icon-dead:before, i.ph-hourglass-simple.icon-alive:before, i.ph-hourglass-simple.icon-warn:before, i.ph-hourglass-simple.icon-sign-out:before, i.ph-hourglass-simple.icon-substratum:before, i.ph-hourglass-simple.icon-environment:before, i.ph-hourglass-simple.icon-user:before, i.ph-hourglass-simple.icon-person:before, i.ph-hourglass-simple.icon-terrain:before, i.ph-hourglass-simple.icon-taxon:before, i.ph-hourglass-simple.icon-unknown:before, i.ph-hourglass-simple.icon-nothing:before, i.ph-hourglass-simple.icon-sampling:before, i.ph-hourglass-simple.icon-isolation:before, i.ph-hourglass-simple.icon-strain:before, i.ph-hourglass-simple.icon-gps:before, i.ph-hourglass-simple.icon-location:before, i.ph-hourglass-simple.icon-add:before, i.ph-hourglass-simple.icon-left:before, i.ph-hourglass-simple.icon-dna:before, i.ph-hourglass-simple.icon-delete:before, i.ph-hourglass-simple.icon-edit:before, i.ph-hourglass-simple.icon-info:before {
  content: "\e2ba";
}

.ph.ph-hourglass-simple-high:before, i.ph-hourglass-simple-high.icon-dead:before, i.ph-hourglass-simple-high.icon-alive:before, i.ph-hourglass-simple-high.icon-warn:before, i.ph-hourglass-simple-high.icon-sign-out:before, i.ph-hourglass-simple-high.icon-substratum:before, i.ph-hourglass-simple-high.icon-environment:before, i.ph-hourglass-simple-high.icon-user:before, i.ph-hourglass-simple-high.icon-person:before, i.ph-hourglass-simple-high.icon-terrain:before, i.ph-hourglass-simple-high.icon-taxon:before, i.ph-hourglass-simple-high.icon-unknown:before, i.ph-hourglass-simple-high.icon-nothing:before, i.ph-hourglass-simple-high.icon-sampling:before, i.ph-hourglass-simple-high.icon-isolation:before, i.ph-hourglass-simple-high.icon-strain:before, i.ph-hourglass-simple-high.icon-gps:before, i.ph-hourglass-simple-high.icon-location:before, i.ph-hourglass-simple-high.icon-add:before, i.ph-hourglass-simple-high.icon-left:before, i.ph-hourglass-simple-high.icon-dna:before, i.ph-hourglass-simple-high.icon-delete:before, i.ph-hourglass-simple-high.icon-edit:before, i.ph-hourglass-simple-high.icon-info:before {
  content: "\e2bc";
}

.ph.ph-hourglass-simple-low:before, i.ph-hourglass-simple-low.icon-dead:before, i.ph-hourglass-simple-low.icon-alive:before, i.ph-hourglass-simple-low.icon-warn:before, i.ph-hourglass-simple-low.icon-sign-out:before, i.ph-hourglass-simple-low.icon-substratum:before, i.ph-hourglass-simple-low.icon-environment:before, i.ph-hourglass-simple-low.icon-user:before, i.ph-hourglass-simple-low.icon-person:before, i.ph-hourglass-simple-low.icon-terrain:before, i.ph-hourglass-simple-low.icon-taxon:before, i.ph-hourglass-simple-low.icon-unknown:before, i.ph-hourglass-simple-low.icon-nothing:before, i.ph-hourglass-simple-low.icon-sampling:before, i.ph-hourglass-simple-low.icon-isolation:before, i.ph-hourglass-simple-low.icon-strain:before, i.ph-hourglass-simple-low.icon-gps:before, i.ph-hourglass-simple-low.icon-location:before, i.ph-hourglass-simple-low.icon-add:before, i.ph-hourglass-simple-low.icon-left:before, i.ph-hourglass-simple-low.icon-dna:before, i.ph-hourglass-simple-low.icon-delete:before, i.ph-hourglass-simple-low.icon-edit:before, i.ph-hourglass-simple-low.icon-info:before {
  content: "\e2be";
}

.ph.ph-hourglass-simple-medium:before, i.ph-hourglass-simple-medium.icon-dead:before, i.ph-hourglass-simple-medium.icon-alive:before, i.ph-hourglass-simple-medium.icon-warn:before, i.ph-hourglass-simple-medium.icon-sign-out:before, i.ph-hourglass-simple-medium.icon-substratum:before, i.ph-hourglass-simple-medium.icon-environment:before, i.ph-hourglass-simple-medium.icon-user:before, i.ph-hourglass-simple-medium.icon-person:before, i.ph-hourglass-simple-medium.icon-terrain:before, i.ph-hourglass-simple-medium.icon-taxon:before, i.ph-hourglass-simple-medium.icon-unknown:before, i.ph-hourglass-simple-medium.icon-nothing:before, i.ph-hourglass-simple-medium.icon-sampling:before, i.ph-hourglass-simple-medium.icon-isolation:before, i.ph-hourglass-simple-medium.icon-strain:before, i.ph-hourglass-simple-medium.icon-gps:before, i.ph-hourglass-simple-medium.icon-location:before, i.ph-hourglass-simple-medium.icon-add:before, i.ph-hourglass-simple-medium.icon-left:before, i.ph-hourglass-simple-medium.icon-dna:before, i.ph-hourglass-simple-medium.icon-delete:before, i.ph-hourglass-simple-medium.icon-edit:before, i.ph-hourglass-simple-medium.icon-info:before {
  content: "\e2c0";
}

.ph.ph-house:before, i.ph-house.icon-dead:before, i.ph-house.icon-alive:before, i.ph-house.icon-warn:before, i.ph-house.icon-sign-out:before, i.ph-house.icon-substratum:before, i.ph-house.icon-environment:before, i.ph-house.icon-user:before, i.ph-house.icon-person:before, i.ph-house.icon-terrain:before, i.ph-house.icon-taxon:before, i.ph-house.icon-unknown:before, i.ph-house.icon-nothing:before, i.ph-house.icon-sampling:before, i.ph-house.icon-isolation:before, i.ph-house.icon-strain:before, i.ph-house.icon-gps:before, i.ph-house.icon-location:before, i.ph-house.icon-add:before, i.ph-house.icon-left:before, i.ph-house.icon-dna:before, i.ph-house.icon-delete:before, i.ph-house.icon-edit:before, i.ph-house.icon-info:before {
  content: "\e2c2";
}

.ph.ph-house-line:before, i.ph-house-line.icon-dead:before, i.ph-house-line.icon-alive:before, i.ph-house-line.icon-warn:before, i.ph-house-line.icon-sign-out:before, i.ph-house-line.icon-substratum:before, i.ph-house-line.icon-environment:before, i.ph-house-line.icon-user:before, i.ph-house-line.icon-person:before, i.ph-house-line.icon-terrain:before, i.ph-house-line.icon-taxon:before, i.ph-house-line.icon-unknown:before, i.ph-house-line.icon-nothing:before, i.ph-house-line.icon-sampling:before, i.ph-house-line.icon-isolation:before, i.ph-house-line.icon-strain:before, i.ph-house-line.icon-gps:before, i.ph-house-line.icon-location:before, i.ph-house-line.icon-add:before, i.ph-house-line.icon-left:before, i.ph-house-line.icon-dna:before, i.ph-house-line.icon-delete:before, i.ph-house-line.icon-edit:before, i.ph-house-line.icon-info:before {
  content: "\e2c4";
}

.ph.ph-house-simple:before, i.ph-house-simple.icon-dead:before, i.ph-house-simple.icon-alive:before, i.ph-house-simple.icon-warn:before, i.ph-house-simple.icon-sign-out:before, i.ph-house-simple.icon-substratum:before, i.ph-house-simple.icon-environment:before, i.ph-house-simple.icon-user:before, i.ph-house-simple.icon-person:before, i.ph-house-simple.icon-terrain:before, i.ph-house-simple.icon-taxon:before, i.ph-house-simple.icon-unknown:before, i.ph-house-simple.icon-nothing:before, i.ph-house-simple.icon-sampling:before, i.ph-house-simple.icon-isolation:before, i.ph-house-simple.icon-strain:before, i.ph-house-simple.icon-gps:before, i.ph-house-simple.icon-location:before, i.ph-house-simple.icon-add:before, i.ph-house-simple.icon-left:before, i.ph-house-simple.icon-dna:before, i.ph-house-simple.icon-delete:before, i.ph-house-simple.icon-edit:before, i.ph-house-simple.icon-info:before {
  content: "\e2c6";
}

.ph.ph-hurricane:before, i.ph-hurricane.icon-dead:before, i.ph-hurricane.icon-alive:before, i.ph-hurricane.icon-warn:before, i.ph-hurricane.icon-sign-out:before, i.ph-hurricane.icon-substratum:before, i.ph-hurricane.icon-environment:before, i.ph-hurricane.icon-user:before, i.ph-hurricane.icon-person:before, i.ph-hurricane.icon-terrain:before, i.ph-hurricane.icon-taxon:before, i.ph-hurricane.icon-unknown:before, i.ph-hurricane.icon-nothing:before, i.ph-hurricane.icon-sampling:before, i.ph-hurricane.icon-isolation:before, i.ph-hurricane.icon-strain:before, i.ph-hurricane.icon-gps:before, i.ph-hurricane.icon-location:before, i.ph-hurricane.icon-add:before, i.ph-hurricane.icon-left:before, i.ph-hurricane.icon-dna:before, i.ph-hurricane.icon-delete:before, i.ph-hurricane.icon-edit:before, i.ph-hurricane.icon-info:before {
  content: "\e88e";
}

.ph.ph-ice-cream:before, i.ph-ice-cream.icon-dead:before, i.ph-ice-cream.icon-alive:before, i.ph-ice-cream.icon-warn:before, i.ph-ice-cream.icon-sign-out:before, i.ph-ice-cream.icon-substratum:before, i.ph-ice-cream.icon-environment:before, i.ph-ice-cream.icon-user:before, i.ph-ice-cream.icon-person:before, i.ph-ice-cream.icon-terrain:before, i.ph-ice-cream.icon-taxon:before, i.ph-ice-cream.icon-unknown:before, i.ph-ice-cream.icon-nothing:before, i.ph-ice-cream.icon-sampling:before, i.ph-ice-cream.icon-isolation:before, i.ph-ice-cream.icon-strain:before, i.ph-ice-cream.icon-gps:before, i.ph-ice-cream.icon-location:before, i.ph-ice-cream.icon-add:before, i.ph-ice-cream.icon-left:before, i.ph-ice-cream.icon-dna:before, i.ph-ice-cream.icon-delete:before, i.ph-ice-cream.icon-edit:before, i.ph-ice-cream.icon-info:before {
  content: "\e804";
}

.ph.ph-identification-badge:before, i.ph-identification-badge.icon-dead:before, i.ph-identification-badge.icon-alive:before, i.ph-identification-badge.icon-warn:before, i.ph-identification-badge.icon-sign-out:before, i.ph-identification-badge.icon-substratum:before, i.ph-identification-badge.icon-environment:before, i.ph-identification-badge.icon-user:before, i.ph-identification-badge.icon-person:before, i.ph-identification-badge.icon-terrain:before, i.ph-identification-badge.icon-taxon:before, i.ph-identification-badge.icon-unknown:before, i.ph-identification-badge.icon-nothing:before, i.ph-identification-badge.icon-sampling:before, i.ph-identification-badge.icon-isolation:before, i.ph-identification-badge.icon-strain:before, i.ph-identification-badge.icon-gps:before, i.ph-identification-badge.icon-location:before, i.ph-identification-badge.icon-add:before, i.ph-identification-badge.icon-left:before, i.ph-identification-badge.icon-dna:before, i.ph-identification-badge.icon-delete:before, i.ph-identification-badge.icon-edit:before, i.ph-identification-badge.icon-info:before {
  content: "\e6f6";
}

.ph.ph-identification-card:before, i.ph-identification-card.icon-dead:before, i.ph-identification-card.icon-alive:before, i.ph-identification-card.icon-warn:before, i.ph-identification-card.icon-sign-out:before, i.ph-identification-card.icon-substratum:before, i.ph-identification-card.icon-environment:before, i.ph-identification-card.icon-user:before, i.ph-identification-card.icon-person:before, i.ph-identification-card.icon-terrain:before, i.ph-identification-card.icon-taxon:before, i.ph-identification-card.icon-unknown:before, i.ph-identification-card.icon-nothing:before, i.ph-identification-card.icon-sampling:before, i.ph-identification-card.icon-isolation:before, i.ph-identification-card.icon-strain:before, i.ph-identification-card.icon-gps:before, i.ph-identification-card.icon-location:before, i.ph-identification-card.icon-add:before, i.ph-identification-card.icon-left:before, i.ph-identification-card.icon-dna:before, i.ph-identification-card.icon-delete:before, i.ph-identification-card.icon-edit:before, i.ph-identification-card.icon-info:before {
  content: "\e2c8";
}

.ph.ph-image:before, i.ph-image.icon-dead:before, i.ph-image.icon-alive:before, i.ph-image.icon-warn:before, i.ph-image.icon-sign-out:before, i.ph-image.icon-substratum:before, i.ph-image.icon-environment:before, i.ph-image.icon-user:before, i.ph-image.icon-person:before, i.ph-image.icon-terrain:before, i.ph-image.icon-taxon:before, i.ph-image.icon-unknown:before, i.ph-image.icon-nothing:before, i.ph-image.icon-sampling:before, i.ph-image.icon-isolation:before, i.ph-image.icon-strain:before, i.ph-image.icon-gps:before, i.ph-image.icon-location:before, i.ph-image.icon-add:before, i.ph-image.icon-left:before, i.ph-image.icon-dna:before, i.ph-image.icon-delete:before, i.ph-image.icon-edit:before, i.ph-image.icon-info:before {
  content: "\e2ca";
}

.ph.ph-image-broken:before, i.ph-image-broken.icon-dead:before, i.ph-image-broken.icon-alive:before, i.ph-image-broken.icon-warn:before, i.ph-image-broken.icon-sign-out:before, i.ph-image-broken.icon-substratum:before, i.ph-image-broken.icon-environment:before, i.ph-image-broken.icon-user:before, i.ph-image-broken.icon-person:before, i.ph-image-broken.icon-terrain:before, i.ph-image-broken.icon-taxon:before, i.ph-image-broken.icon-unknown:before, i.ph-image-broken.icon-nothing:before, i.ph-image-broken.icon-sampling:before, i.ph-image-broken.icon-isolation:before, i.ph-image-broken.icon-strain:before, i.ph-image-broken.icon-gps:before, i.ph-image-broken.icon-location:before, i.ph-image-broken.icon-add:before, i.ph-image-broken.icon-left:before, i.ph-image-broken.icon-dna:before, i.ph-image-broken.icon-delete:before, i.ph-image-broken.icon-edit:before, i.ph-image-broken.icon-info:before {
  content: "\e7a8";
}

.ph.ph-image-square:before, i.ph-image-square.icon-dead:before, i.ph-image-square.icon-alive:before, i.ph-image-square.icon-warn:before, i.ph-image-square.icon-sign-out:before, i.ph-image-square.icon-substratum:before, i.ph-image-square.icon-environment:before, i.ph-image-square.icon-user:before, i.ph-image-square.icon-person:before, i.ph-image-square.icon-terrain:before, i.ph-image-square.icon-taxon:before, i.ph-image-square.icon-unknown:before, i.ph-image-square.icon-nothing:before, i.ph-image-square.icon-sampling:before, i.ph-image-square.icon-isolation:before, i.ph-image-square.icon-strain:before, i.ph-image-square.icon-gps:before, i.ph-image-square.icon-location:before, i.ph-image-square.icon-add:before, i.ph-image-square.icon-left:before, i.ph-image-square.icon-dna:before, i.ph-image-square.icon-delete:before, i.ph-image-square.icon-edit:before, i.ph-image-square.icon-info:before {
  content: "\e2cc";
}

.ph.ph-images:before, i.ph-images.icon-dead:before, i.ph-images.icon-alive:before, i.ph-images.icon-warn:before, i.ph-images.icon-sign-out:before, i.ph-images.icon-substratum:before, i.ph-images.icon-environment:before, i.ph-images.icon-user:before, i.ph-images.icon-person:before, i.ph-images.icon-terrain:before, i.ph-images.icon-taxon:before, i.ph-images.icon-unknown:before, i.ph-images.icon-nothing:before, i.ph-images.icon-sampling:before, i.ph-images.icon-isolation:before, i.ph-images.icon-strain:before, i.ph-images.icon-gps:before, i.ph-images.icon-location:before, i.ph-images.icon-add:before, i.ph-images.icon-left:before, i.ph-images.icon-dna:before, i.ph-images.icon-delete:before, i.ph-images.icon-edit:before, i.ph-images.icon-info:before {
  content: "\e836";
}

.ph.ph-images-square:before, i.ph-images-square.icon-dead:before, i.ph-images-square.icon-alive:before, i.ph-images-square.icon-warn:before, i.ph-images-square.icon-sign-out:before, i.ph-images-square.icon-substratum:before, i.ph-images-square.icon-environment:before, i.ph-images-square.icon-user:before, i.ph-images-square.icon-person:before, i.ph-images-square.icon-terrain:before, i.ph-images-square.icon-taxon:before, i.ph-images-square.icon-unknown:before, i.ph-images-square.icon-nothing:before, i.ph-images-square.icon-sampling:before, i.ph-images-square.icon-isolation:before, i.ph-images-square.icon-strain:before, i.ph-images-square.icon-gps:before, i.ph-images-square.icon-location:before, i.ph-images-square.icon-add:before, i.ph-images-square.icon-left:before, i.ph-images-square.icon-dna:before, i.ph-images-square.icon-delete:before, i.ph-images-square.icon-edit:before, i.ph-images-square.icon-info:before {
  content: "\e834";
}

.ph.ph-infinity:before, i.ph-infinity.icon-dead:before, i.ph-infinity.icon-alive:before, i.ph-infinity.icon-warn:before, i.ph-infinity.icon-sign-out:before, i.ph-infinity.icon-substratum:before, i.ph-infinity.icon-environment:before, i.ph-infinity.icon-user:before, i.ph-infinity.icon-person:before, i.ph-infinity.icon-terrain:before, i.ph-infinity.icon-taxon:before, i.ph-infinity.icon-unknown:before, i.ph-infinity.icon-nothing:before, i.ph-infinity.icon-sampling:before, i.ph-infinity.icon-isolation:before, i.ph-infinity.icon-strain:before, i.ph-infinity.icon-gps:before, i.ph-infinity.icon-location:before, i.ph-infinity.icon-add:before, i.ph-infinity.icon-left:before, i.ph-infinity.icon-dna:before, i.ph-infinity.icon-delete:before, i.ph-infinity.icon-edit:before, i.ph-infinity.icon-info:before {
  content: "\e634";
}

.ph.ph-lemniscate:before, i.ph-lemniscate.icon-dead:before, i.ph-lemniscate.icon-alive:before, i.ph-lemniscate.icon-warn:before, i.ph-lemniscate.icon-sign-out:before, i.ph-lemniscate.icon-substratum:before, i.ph-lemniscate.icon-environment:before, i.ph-lemniscate.icon-user:before, i.ph-lemniscate.icon-person:before, i.ph-lemniscate.icon-terrain:before, i.ph-lemniscate.icon-taxon:before, i.ph-lemniscate.icon-unknown:before, i.ph-lemniscate.icon-nothing:before, i.ph-lemniscate.icon-sampling:before, i.ph-lemniscate.icon-isolation:before, i.ph-lemniscate.icon-strain:before, i.ph-lemniscate.icon-gps:before, i.ph-lemniscate.icon-location:before, i.ph-lemniscate.icon-add:before, i.ph-lemniscate.icon-left:before, i.ph-lemniscate.icon-dna:before, i.ph-lemniscate.icon-delete:before, i.ph-lemniscate.icon-edit:before, i.ph-lemniscate.icon-info:before {
  content: "\e634";
}

.ph.ph-info:before, i.ph-info.icon-dead:before, i.ph-info.icon-alive:before, i.ph-info.icon-warn:before, i.ph-info.icon-sign-out:before, i.ph-info.icon-substratum:before, i.ph-info.icon-environment:before, i.ph-info.icon-user:before, i.ph-info.icon-person:before, i.ph-info.icon-terrain:before, i.ph-info.icon-taxon:before, i.ph-info.icon-unknown:before, i.ph-info.icon-nothing:before, i.ph-info.icon-sampling:before, i.ph-info.icon-isolation:before, i.ph-info.icon-strain:before, i.ph-info.icon-gps:before, i.ph-info.icon-location:before, i.ph-info.icon-add:before, i.ph-info.icon-left:before, i.ph-info.icon-dna:before, i.ph-info.icon-delete:before, i.ph-info.icon-edit:before, i.icon-info:before {
  content: "\e2ce";
}

.ph.ph-instagram-logo:before, i.ph-instagram-logo.icon-dead:before, i.ph-instagram-logo.icon-alive:before, i.ph-instagram-logo.icon-warn:before, i.ph-instagram-logo.icon-sign-out:before, i.ph-instagram-logo.icon-substratum:before, i.ph-instagram-logo.icon-environment:before, i.ph-instagram-logo.icon-user:before, i.ph-instagram-logo.icon-person:before, i.ph-instagram-logo.icon-terrain:before, i.ph-instagram-logo.icon-taxon:before, i.ph-instagram-logo.icon-unknown:before, i.ph-instagram-logo.icon-nothing:before, i.ph-instagram-logo.icon-sampling:before, i.ph-instagram-logo.icon-isolation:before, i.ph-instagram-logo.icon-strain:before, i.ph-instagram-logo.icon-gps:before, i.ph-instagram-logo.icon-location:before, i.ph-instagram-logo.icon-add:before, i.ph-instagram-logo.icon-left:before, i.ph-instagram-logo.icon-dna:before, i.ph-instagram-logo.icon-delete:before, i.ph-instagram-logo.icon-edit:before, i.ph-instagram-logo.icon-info:before {
  content: "\e2d0";
}

.ph.ph-intersect:before, i.ph-intersect.icon-dead:before, i.ph-intersect.icon-alive:before, i.ph-intersect.icon-warn:before, i.ph-intersect.icon-sign-out:before, i.ph-intersect.icon-substratum:before, i.ph-intersect.icon-environment:before, i.ph-intersect.icon-user:before, i.ph-intersect.icon-person:before, i.ph-intersect.icon-terrain:before, i.ph-intersect.icon-taxon:before, i.ph-intersect.icon-unknown:before, i.ph-intersect.icon-nothing:before, i.ph-intersect.icon-sampling:before, i.ph-intersect.icon-isolation:before, i.ph-intersect.icon-strain:before, i.ph-intersect.icon-gps:before, i.ph-intersect.icon-location:before, i.ph-intersect.icon-add:before, i.ph-intersect.icon-left:before, i.ph-intersect.icon-dna:before, i.ph-intersect.icon-delete:before, i.ph-intersect.icon-edit:before, i.ph-intersect.icon-info:before {
  content: "\e2d2";
}

.ph.ph-intersect-square:before, i.ph-intersect-square.icon-dead:before, i.ph-intersect-square.icon-alive:before, i.ph-intersect-square.icon-warn:before, i.ph-intersect-square.icon-sign-out:before, i.ph-intersect-square.icon-substratum:before, i.ph-intersect-square.icon-environment:before, i.ph-intersect-square.icon-user:before, i.ph-intersect-square.icon-person:before, i.ph-intersect-square.icon-terrain:before, i.ph-intersect-square.icon-taxon:before, i.ph-intersect-square.icon-unknown:before, i.ph-intersect-square.icon-nothing:before, i.ph-intersect-square.icon-sampling:before, i.ph-intersect-square.icon-isolation:before, i.ph-intersect-square.icon-strain:before, i.ph-intersect-square.icon-gps:before, i.ph-intersect-square.icon-location:before, i.ph-intersect-square.icon-add:before, i.ph-intersect-square.icon-left:before, i.ph-intersect-square.icon-dna:before, i.ph-intersect-square.icon-delete:before, i.ph-intersect-square.icon-edit:before, i.ph-intersect-square.icon-info:before {
  content: "\e87a";
}

.ph.ph-intersect-three:before, i.ph-intersect-three.icon-dead:before, i.ph-intersect-three.icon-alive:before, i.ph-intersect-three.icon-warn:before, i.ph-intersect-three.icon-sign-out:before, i.ph-intersect-three.icon-substratum:before, i.ph-intersect-three.icon-environment:before, i.ph-intersect-three.icon-user:before, i.ph-intersect-three.icon-person:before, i.ph-intersect-three.icon-terrain:before, i.ph-intersect-three.icon-taxon:before, i.ph-intersect-three.icon-unknown:before, i.ph-intersect-three.icon-nothing:before, i.ph-intersect-three.icon-sampling:before, i.ph-intersect-three.icon-isolation:before, i.ph-intersect-three.icon-strain:before, i.ph-intersect-three.icon-gps:before, i.ph-intersect-three.icon-location:before, i.ph-intersect-three.icon-add:before, i.ph-intersect-three.icon-left:before, i.ph-intersect-three.icon-dna:before, i.ph-intersect-three.icon-delete:before, i.ph-intersect-three.icon-edit:before, i.ph-intersect-three.icon-info:before {
  content: "\ecc4";
}

.ph.ph-intersection:before, i.ph-intersection.icon-dead:before, i.ph-intersection.icon-alive:before, i.ph-intersection.icon-warn:before, i.ph-intersection.icon-sign-out:before, i.ph-intersection.icon-substratum:before, i.ph-intersection.icon-environment:before, i.ph-intersection.icon-user:before, i.ph-intersection.icon-person:before, i.ph-intersection.icon-terrain:before, i.ph-intersection.icon-taxon:before, i.ph-intersection.icon-unknown:before, i.ph-intersection.icon-nothing:before, i.ph-intersection.icon-sampling:before, i.ph-intersection.icon-isolation:before, i.ph-intersection.icon-strain:before, i.ph-intersection.icon-gps:before, i.ph-intersection.icon-location:before, i.ph-intersection.icon-add:before, i.ph-intersection.icon-left:before, i.ph-intersection.icon-dna:before, i.ph-intersection.icon-delete:before, i.ph-intersection.icon-edit:before, i.ph-intersection.icon-info:before {
  content: "\edba";
}

.ph.ph-invoice:before, i.ph-invoice.icon-dead:before, i.ph-invoice.icon-alive:before, i.ph-invoice.icon-warn:before, i.ph-invoice.icon-sign-out:before, i.ph-invoice.icon-substratum:before, i.ph-invoice.icon-environment:before, i.ph-invoice.icon-user:before, i.ph-invoice.icon-person:before, i.ph-invoice.icon-terrain:before, i.ph-invoice.icon-taxon:before, i.ph-invoice.icon-unknown:before, i.ph-invoice.icon-nothing:before, i.ph-invoice.icon-sampling:before, i.ph-invoice.icon-isolation:before, i.ph-invoice.icon-strain:before, i.ph-invoice.icon-gps:before, i.ph-invoice.icon-location:before, i.ph-invoice.icon-add:before, i.ph-invoice.icon-left:before, i.ph-invoice.icon-dna:before, i.ph-invoice.icon-delete:before, i.ph-invoice.icon-edit:before, i.ph-invoice.icon-info:before {
  content: "\ee42";
}

.ph.ph-island:before, i.ph-island.icon-dead:before, i.ph-island.icon-alive:before, i.ph-island.icon-warn:before, i.ph-island.icon-sign-out:before, i.ph-island.icon-substratum:before, i.ph-island.icon-environment:before, i.ph-island.icon-user:before, i.ph-island.icon-person:before, i.ph-island.icon-terrain:before, i.ph-island.icon-taxon:before, i.ph-island.icon-unknown:before, i.ph-island.icon-nothing:before, i.ph-island.icon-sampling:before, i.ph-island.icon-isolation:before, i.ph-island.icon-strain:before, i.ph-island.icon-gps:before, i.ph-island.icon-location:before, i.ph-island.icon-add:before, i.ph-island.icon-left:before, i.ph-island.icon-dna:before, i.ph-island.icon-delete:before, i.ph-island.icon-edit:before, i.ph-island.icon-info:before {
  content: "\ee06";
}

.ph.ph-jar:before, i.ph-jar.icon-dead:before, i.ph-jar.icon-alive:before, i.ph-jar.icon-warn:before, i.ph-jar.icon-sign-out:before, i.ph-jar.icon-substratum:before, i.ph-jar.icon-environment:before, i.ph-jar.icon-user:before, i.ph-jar.icon-person:before, i.ph-jar.icon-terrain:before, i.ph-jar.icon-taxon:before, i.ph-jar.icon-unknown:before, i.ph-jar.icon-nothing:before, i.ph-jar.icon-sampling:before, i.ph-jar.icon-isolation:before, i.ph-jar.icon-strain:before, i.ph-jar.icon-gps:before, i.ph-jar.icon-location:before, i.ph-jar.icon-add:before, i.ph-jar.icon-left:before, i.ph-jar.icon-dna:before, i.ph-jar.icon-delete:before, i.ph-jar.icon-edit:before, i.ph-jar.icon-info:before {
  content: "\e7e0";
}

.ph.ph-jar-label:before, i.ph-jar-label.icon-dead:before, i.ph-jar-label.icon-alive:before, i.ph-jar-label.icon-warn:before, i.ph-jar-label.icon-sign-out:before, i.ph-jar-label.icon-substratum:before, i.ph-jar-label.icon-environment:before, i.ph-jar-label.icon-user:before, i.ph-jar-label.icon-person:before, i.ph-jar-label.icon-terrain:before, i.ph-jar-label.icon-taxon:before, i.ph-jar-label.icon-unknown:before, i.ph-jar-label.icon-nothing:before, i.ph-jar-label.icon-sampling:before, i.ph-jar-label.icon-isolation:before, i.ph-jar-label.icon-strain:before, i.ph-jar-label.icon-gps:before, i.ph-jar-label.icon-location:before, i.ph-jar-label.icon-add:before, i.ph-jar-label.icon-left:before, i.ph-jar-label.icon-dna:before, i.ph-jar-label.icon-delete:before, i.ph-jar-label.icon-edit:before, i.ph-jar-label.icon-info:before {
  content: "\e7e1";
}

.ph.ph-jeep:before, i.ph-jeep.icon-dead:before, i.ph-jeep.icon-alive:before, i.ph-jeep.icon-warn:before, i.ph-jeep.icon-sign-out:before, i.ph-jeep.icon-substratum:before, i.ph-jeep.icon-environment:before, i.ph-jeep.icon-user:before, i.ph-jeep.icon-person:before, i.ph-jeep.icon-terrain:before, i.ph-jeep.icon-taxon:before, i.ph-jeep.icon-unknown:before, i.ph-jeep.icon-nothing:before, i.ph-jeep.icon-sampling:before, i.ph-jeep.icon-isolation:before, i.ph-jeep.icon-strain:before, i.ph-jeep.icon-gps:before, i.ph-jeep.icon-location:before, i.ph-jeep.icon-add:before, i.ph-jeep.icon-left:before, i.ph-jeep.icon-dna:before, i.ph-jeep.icon-delete:before, i.ph-jeep.icon-edit:before, i.ph-jeep.icon-info:before {
  content: "\e2d4";
}

.ph.ph-joystick:before, i.ph-joystick.icon-dead:before, i.ph-joystick.icon-alive:before, i.ph-joystick.icon-warn:before, i.ph-joystick.icon-sign-out:before, i.ph-joystick.icon-substratum:before, i.ph-joystick.icon-environment:before, i.ph-joystick.icon-user:before, i.ph-joystick.icon-person:before, i.ph-joystick.icon-terrain:before, i.ph-joystick.icon-taxon:before, i.ph-joystick.icon-unknown:before, i.ph-joystick.icon-nothing:before, i.ph-joystick.icon-sampling:before, i.ph-joystick.icon-isolation:before, i.ph-joystick.icon-strain:before, i.ph-joystick.icon-gps:before, i.ph-joystick.icon-location:before, i.ph-joystick.icon-add:before, i.ph-joystick.icon-left:before, i.ph-joystick.icon-dna:before, i.ph-joystick.icon-delete:before, i.ph-joystick.icon-edit:before, i.ph-joystick.icon-info:before {
  content: "\ea5e";
}

.ph.ph-kanban:before, i.ph-kanban.icon-dead:before, i.ph-kanban.icon-alive:before, i.ph-kanban.icon-warn:before, i.ph-kanban.icon-sign-out:before, i.ph-kanban.icon-substratum:before, i.ph-kanban.icon-environment:before, i.ph-kanban.icon-user:before, i.ph-kanban.icon-person:before, i.ph-kanban.icon-terrain:before, i.ph-kanban.icon-taxon:before, i.ph-kanban.icon-unknown:before, i.ph-kanban.icon-nothing:before, i.ph-kanban.icon-sampling:before, i.ph-kanban.icon-isolation:before, i.ph-kanban.icon-strain:before, i.ph-kanban.icon-gps:before, i.ph-kanban.icon-location:before, i.ph-kanban.icon-add:before, i.ph-kanban.icon-left:before, i.ph-kanban.icon-dna:before, i.ph-kanban.icon-delete:before, i.ph-kanban.icon-edit:before, i.ph-kanban.icon-info:before {
  content: "\eb54";
}

.ph.ph-key:before, i.ph-key.icon-dead:before, i.ph-key.icon-alive:before, i.ph-key.icon-warn:before, i.ph-key.icon-sign-out:before, i.ph-key.icon-substratum:before, i.ph-key.icon-environment:before, i.ph-key.icon-user:before, i.ph-key.icon-person:before, i.ph-key.icon-terrain:before, i.ph-key.icon-taxon:before, i.ph-key.icon-unknown:before, i.ph-key.icon-nothing:before, i.ph-key.icon-sampling:before, i.ph-key.icon-isolation:before, i.ph-key.icon-strain:before, i.ph-key.icon-gps:before, i.ph-key.icon-location:before, i.ph-key.icon-add:before, i.ph-key.icon-left:before, i.ph-key.icon-dna:before, i.ph-key.icon-delete:before, i.ph-key.icon-edit:before, i.ph-key.icon-info:before {
  content: "\e2d6";
}

.ph.ph-key-return:before, i.ph-key-return.icon-dead:before, i.ph-key-return.icon-alive:before, i.ph-key-return.icon-warn:before, i.ph-key-return.icon-sign-out:before, i.ph-key-return.icon-substratum:before, i.ph-key-return.icon-environment:before, i.ph-key-return.icon-user:before, i.ph-key-return.icon-person:before, i.ph-key-return.icon-terrain:before, i.ph-key-return.icon-taxon:before, i.ph-key-return.icon-unknown:before, i.ph-key-return.icon-nothing:before, i.ph-key-return.icon-sampling:before, i.ph-key-return.icon-isolation:before, i.ph-key-return.icon-strain:before, i.ph-key-return.icon-gps:before, i.ph-key-return.icon-location:before, i.ph-key-return.icon-add:before, i.ph-key-return.icon-left:before, i.ph-key-return.icon-dna:before, i.ph-key-return.icon-delete:before, i.ph-key-return.icon-edit:before, i.ph-key-return.icon-info:before {
  content: "\e782";
}

.ph.ph-keyboard:before, i.ph-keyboard.icon-dead:before, i.ph-keyboard.icon-alive:before, i.ph-keyboard.icon-warn:before, i.ph-keyboard.icon-sign-out:before, i.ph-keyboard.icon-substratum:before, i.ph-keyboard.icon-environment:before, i.ph-keyboard.icon-user:before, i.ph-keyboard.icon-person:before, i.ph-keyboard.icon-terrain:before, i.ph-keyboard.icon-taxon:before, i.ph-keyboard.icon-unknown:before, i.ph-keyboard.icon-nothing:before, i.ph-keyboard.icon-sampling:before, i.ph-keyboard.icon-isolation:before, i.ph-keyboard.icon-strain:before, i.ph-keyboard.icon-gps:before, i.ph-keyboard.icon-location:before, i.ph-keyboard.icon-add:before, i.ph-keyboard.icon-left:before, i.ph-keyboard.icon-dna:before, i.ph-keyboard.icon-delete:before, i.ph-keyboard.icon-edit:before, i.ph-keyboard.icon-info:before {
  content: "\e2d8";
}

.ph.ph-keyhole:before, i.ph-keyhole.icon-dead:before, i.ph-keyhole.icon-alive:before, i.ph-keyhole.icon-warn:before, i.ph-keyhole.icon-sign-out:before, i.ph-keyhole.icon-substratum:before, i.ph-keyhole.icon-environment:before, i.ph-keyhole.icon-user:before, i.ph-keyhole.icon-person:before, i.ph-keyhole.icon-terrain:before, i.ph-keyhole.icon-taxon:before, i.ph-keyhole.icon-unknown:before, i.ph-keyhole.icon-nothing:before, i.ph-keyhole.icon-sampling:before, i.ph-keyhole.icon-isolation:before, i.ph-keyhole.icon-strain:before, i.ph-keyhole.icon-gps:before, i.ph-keyhole.icon-location:before, i.ph-keyhole.icon-add:before, i.ph-keyhole.icon-left:before, i.ph-keyhole.icon-dna:before, i.ph-keyhole.icon-delete:before, i.ph-keyhole.icon-edit:before, i.ph-keyhole.icon-info:before {
  content: "\ea78";
}

.ph.ph-knife:before, i.ph-knife.icon-dead:before, i.ph-knife.icon-alive:before, i.ph-knife.icon-warn:before, i.ph-knife.icon-sign-out:before, i.ph-knife.icon-substratum:before, i.ph-knife.icon-environment:before, i.ph-knife.icon-user:before, i.ph-knife.icon-person:before, i.ph-knife.icon-terrain:before, i.ph-knife.icon-taxon:before, i.ph-knife.icon-unknown:before, i.ph-knife.icon-nothing:before, i.ph-knife.icon-sampling:before, i.ph-knife.icon-isolation:before, i.ph-knife.icon-strain:before, i.ph-knife.icon-gps:before, i.ph-knife.icon-location:before, i.ph-knife.icon-add:before, i.ph-knife.icon-left:before, i.ph-knife.icon-dna:before, i.ph-knife.icon-delete:before, i.ph-knife.icon-edit:before, i.ph-knife.icon-info:before {
  content: "\e636";
}

.ph.ph-ladder:before, i.ph-ladder.icon-dead:before, i.ph-ladder.icon-alive:before, i.ph-ladder.icon-warn:before, i.ph-ladder.icon-sign-out:before, i.ph-ladder.icon-substratum:before, i.ph-ladder.icon-environment:before, i.ph-ladder.icon-user:before, i.ph-ladder.icon-person:before, i.ph-ladder.icon-terrain:before, i.ph-ladder.icon-taxon:before, i.ph-ladder.icon-unknown:before, i.ph-ladder.icon-nothing:before, i.ph-ladder.icon-sampling:before, i.ph-ladder.icon-isolation:before, i.ph-ladder.icon-strain:before, i.ph-ladder.icon-gps:before, i.ph-ladder.icon-location:before, i.ph-ladder.icon-add:before, i.ph-ladder.icon-left:before, i.ph-ladder.icon-dna:before, i.ph-ladder.icon-delete:before, i.ph-ladder.icon-edit:before, i.ph-ladder.icon-info:before {
  content: "\e9e4";
}

.ph.ph-ladder-simple:before, i.ph-ladder-simple.icon-dead:before, i.ph-ladder-simple.icon-alive:before, i.ph-ladder-simple.icon-warn:before, i.ph-ladder-simple.icon-sign-out:before, i.ph-ladder-simple.icon-substratum:before, i.ph-ladder-simple.icon-environment:before, i.ph-ladder-simple.icon-user:before, i.ph-ladder-simple.icon-person:before, i.ph-ladder-simple.icon-terrain:before, i.ph-ladder-simple.icon-taxon:before, i.ph-ladder-simple.icon-unknown:before, i.ph-ladder-simple.icon-nothing:before, i.ph-ladder-simple.icon-sampling:before, i.ph-ladder-simple.icon-isolation:before, i.ph-ladder-simple.icon-strain:before, i.ph-ladder-simple.icon-gps:before, i.ph-ladder-simple.icon-location:before, i.ph-ladder-simple.icon-add:before, i.ph-ladder-simple.icon-left:before, i.ph-ladder-simple.icon-dna:before, i.ph-ladder-simple.icon-delete:before, i.ph-ladder-simple.icon-edit:before, i.ph-ladder-simple.icon-info:before {
  content: "\ec26";
}

.ph.ph-lamp:before, i.ph-lamp.icon-dead:before, i.ph-lamp.icon-alive:before, i.ph-lamp.icon-warn:before, i.ph-lamp.icon-sign-out:before, i.ph-lamp.icon-substratum:before, i.ph-lamp.icon-environment:before, i.ph-lamp.icon-user:before, i.ph-lamp.icon-person:before, i.ph-lamp.icon-terrain:before, i.ph-lamp.icon-taxon:before, i.ph-lamp.icon-unknown:before, i.ph-lamp.icon-nothing:before, i.ph-lamp.icon-sampling:before, i.ph-lamp.icon-isolation:before, i.ph-lamp.icon-strain:before, i.ph-lamp.icon-gps:before, i.ph-lamp.icon-location:before, i.ph-lamp.icon-add:before, i.ph-lamp.icon-left:before, i.ph-lamp.icon-dna:before, i.ph-lamp.icon-delete:before, i.ph-lamp.icon-edit:before, i.ph-lamp.icon-info:before {
  content: "\e638";
}

.ph.ph-lamp-pendant:before, i.ph-lamp-pendant.icon-dead:before, i.ph-lamp-pendant.icon-alive:before, i.ph-lamp-pendant.icon-warn:before, i.ph-lamp-pendant.icon-sign-out:before, i.ph-lamp-pendant.icon-substratum:before, i.ph-lamp-pendant.icon-environment:before, i.ph-lamp-pendant.icon-user:before, i.ph-lamp-pendant.icon-person:before, i.ph-lamp-pendant.icon-terrain:before, i.ph-lamp-pendant.icon-taxon:before, i.ph-lamp-pendant.icon-unknown:before, i.ph-lamp-pendant.icon-nothing:before, i.ph-lamp-pendant.icon-sampling:before, i.ph-lamp-pendant.icon-isolation:before, i.ph-lamp-pendant.icon-strain:before, i.ph-lamp-pendant.icon-gps:before, i.ph-lamp-pendant.icon-location:before, i.ph-lamp-pendant.icon-add:before, i.ph-lamp-pendant.icon-left:before, i.ph-lamp-pendant.icon-dna:before, i.ph-lamp-pendant.icon-delete:before, i.ph-lamp-pendant.icon-edit:before, i.ph-lamp-pendant.icon-info:before {
  content: "\ee2e";
}

.ph.ph-laptop:before, i.ph-laptop.icon-dead:before, i.ph-laptop.icon-alive:before, i.ph-laptop.icon-warn:before, i.ph-laptop.icon-sign-out:before, i.ph-laptop.icon-substratum:before, i.ph-laptop.icon-environment:before, i.ph-laptop.icon-user:before, i.ph-laptop.icon-person:before, i.ph-laptop.icon-terrain:before, i.ph-laptop.icon-taxon:before, i.ph-laptop.icon-unknown:before, i.ph-laptop.icon-nothing:before, i.ph-laptop.icon-sampling:before, i.ph-laptop.icon-isolation:before, i.ph-laptop.icon-strain:before, i.ph-laptop.icon-gps:before, i.ph-laptop.icon-location:before, i.ph-laptop.icon-add:before, i.ph-laptop.icon-left:before, i.ph-laptop.icon-dna:before, i.ph-laptop.icon-delete:before, i.ph-laptop.icon-edit:before, i.ph-laptop.icon-info:before {
  content: "\e586";
}

.ph.ph-lasso:before, i.ph-lasso.icon-dead:before, i.ph-lasso.icon-alive:before, i.ph-lasso.icon-warn:before, i.ph-lasso.icon-sign-out:before, i.ph-lasso.icon-substratum:before, i.ph-lasso.icon-environment:before, i.ph-lasso.icon-user:before, i.ph-lasso.icon-person:before, i.ph-lasso.icon-terrain:before, i.ph-lasso.icon-taxon:before, i.ph-lasso.icon-unknown:before, i.ph-lasso.icon-nothing:before, i.ph-lasso.icon-sampling:before, i.ph-lasso.icon-isolation:before, i.ph-lasso.icon-strain:before, i.ph-lasso.icon-gps:before, i.ph-lasso.icon-location:before, i.ph-lasso.icon-add:before, i.ph-lasso.icon-left:before, i.ph-lasso.icon-dna:before, i.ph-lasso.icon-delete:before, i.ph-lasso.icon-edit:before, i.ph-lasso.icon-info:before {
  content: "\edc6";
}

.ph.ph-lastfm-logo:before, i.ph-lastfm-logo.icon-dead:before, i.ph-lastfm-logo.icon-alive:before, i.ph-lastfm-logo.icon-warn:before, i.ph-lastfm-logo.icon-sign-out:before, i.ph-lastfm-logo.icon-substratum:before, i.ph-lastfm-logo.icon-environment:before, i.ph-lastfm-logo.icon-user:before, i.ph-lastfm-logo.icon-person:before, i.ph-lastfm-logo.icon-terrain:before, i.ph-lastfm-logo.icon-taxon:before, i.ph-lastfm-logo.icon-unknown:before, i.ph-lastfm-logo.icon-nothing:before, i.ph-lastfm-logo.icon-sampling:before, i.ph-lastfm-logo.icon-isolation:before, i.ph-lastfm-logo.icon-strain:before, i.ph-lastfm-logo.icon-gps:before, i.ph-lastfm-logo.icon-location:before, i.ph-lastfm-logo.icon-add:before, i.ph-lastfm-logo.icon-left:before, i.ph-lastfm-logo.icon-dna:before, i.ph-lastfm-logo.icon-delete:before, i.ph-lastfm-logo.icon-edit:before, i.ph-lastfm-logo.icon-info:before {
  content: "\e842";
}

.ph.ph-layout:before, i.ph-layout.icon-dead:before, i.ph-layout.icon-alive:before, i.ph-layout.icon-warn:before, i.ph-layout.icon-sign-out:before, i.ph-layout.icon-substratum:before, i.ph-layout.icon-environment:before, i.ph-layout.icon-user:before, i.ph-layout.icon-person:before, i.ph-layout.icon-terrain:before, i.ph-layout.icon-taxon:before, i.ph-layout.icon-unknown:before, i.ph-layout.icon-nothing:before, i.ph-layout.icon-sampling:before, i.ph-layout.icon-isolation:before, i.ph-layout.icon-strain:before, i.ph-layout.icon-gps:before, i.ph-layout.icon-location:before, i.ph-layout.icon-add:before, i.ph-layout.icon-left:before, i.ph-layout.icon-dna:before, i.ph-layout.icon-delete:before, i.ph-layout.icon-edit:before, i.ph-layout.icon-info:before {
  content: "\e6d6";
}

.ph.ph-leaf:before, i.ph-leaf.icon-dead:before, i.ph-leaf.icon-alive:before, i.ph-leaf.icon-warn:before, i.ph-leaf.icon-sign-out:before, i.ph-leaf.icon-substratum:before, i.ph-leaf.icon-environment:before, i.ph-leaf.icon-user:before, i.ph-leaf.icon-person:before, i.ph-leaf.icon-terrain:before, i.ph-leaf.icon-taxon:before, i.ph-leaf.icon-unknown:before, i.ph-leaf.icon-nothing:before, i.ph-leaf.icon-sampling:before, i.ph-leaf.icon-isolation:before, i.ph-leaf.icon-strain:before, i.ph-leaf.icon-gps:before, i.ph-leaf.icon-location:before, i.ph-leaf.icon-add:before, i.ph-leaf.icon-left:before, i.ph-leaf.icon-dna:before, i.ph-leaf.icon-delete:before, i.ph-leaf.icon-edit:before, i.ph-leaf.icon-info:before {
  content: "\e2da";
}

.ph.ph-lectern:before, i.ph-lectern.icon-dead:before, i.ph-lectern.icon-alive:before, i.ph-lectern.icon-warn:before, i.ph-lectern.icon-sign-out:before, i.ph-lectern.icon-substratum:before, i.ph-lectern.icon-environment:before, i.ph-lectern.icon-user:before, i.ph-lectern.icon-person:before, i.ph-lectern.icon-terrain:before, i.ph-lectern.icon-taxon:before, i.ph-lectern.icon-unknown:before, i.ph-lectern.icon-nothing:before, i.ph-lectern.icon-sampling:before, i.ph-lectern.icon-isolation:before, i.ph-lectern.icon-strain:before, i.ph-lectern.icon-gps:before, i.ph-lectern.icon-location:before, i.ph-lectern.icon-add:before, i.ph-lectern.icon-left:before, i.ph-lectern.icon-dna:before, i.ph-lectern.icon-delete:before, i.ph-lectern.icon-edit:before, i.ph-lectern.icon-info:before {
  content: "\e95a";
}

.ph.ph-lego:before, i.ph-lego.icon-dead:before, i.ph-lego.icon-alive:before, i.ph-lego.icon-warn:before, i.ph-lego.icon-sign-out:before, i.ph-lego.icon-substratum:before, i.ph-lego.icon-environment:before, i.ph-lego.icon-user:before, i.ph-lego.icon-person:before, i.ph-lego.icon-terrain:before, i.ph-lego.icon-taxon:before, i.ph-lego.icon-unknown:before, i.ph-lego.icon-nothing:before, i.ph-lego.icon-sampling:before, i.ph-lego.icon-isolation:before, i.ph-lego.icon-strain:before, i.ph-lego.icon-gps:before, i.ph-lego.icon-location:before, i.ph-lego.icon-add:before, i.ph-lego.icon-left:before, i.ph-lego.icon-dna:before, i.ph-lego.icon-delete:before, i.ph-lego.icon-edit:before, i.ph-lego.icon-info:before {
  content: "\e8c6";
}

.ph.ph-lego-smiley:before, i.ph-lego-smiley.icon-dead:before, i.ph-lego-smiley.icon-alive:before, i.ph-lego-smiley.icon-warn:before, i.ph-lego-smiley.icon-sign-out:before, i.ph-lego-smiley.icon-substratum:before, i.ph-lego-smiley.icon-environment:before, i.ph-lego-smiley.icon-user:before, i.ph-lego-smiley.icon-person:before, i.ph-lego-smiley.icon-terrain:before, i.ph-lego-smiley.icon-taxon:before, i.ph-lego-smiley.icon-unknown:before, i.ph-lego-smiley.icon-nothing:before, i.ph-lego-smiley.icon-sampling:before, i.ph-lego-smiley.icon-isolation:before, i.ph-lego-smiley.icon-strain:before, i.ph-lego-smiley.icon-gps:before, i.ph-lego-smiley.icon-location:before, i.ph-lego-smiley.icon-add:before, i.ph-lego-smiley.icon-left:before, i.ph-lego-smiley.icon-dna:before, i.ph-lego-smiley.icon-delete:before, i.ph-lego-smiley.icon-edit:before, i.ph-lego-smiley.icon-info:before {
  content: "\e8c7";
}

.ph.ph-less-than:before, i.ph-less-than.icon-dead:before, i.ph-less-than.icon-alive:before, i.ph-less-than.icon-warn:before, i.ph-less-than.icon-sign-out:before, i.ph-less-than.icon-substratum:before, i.ph-less-than.icon-environment:before, i.ph-less-than.icon-user:before, i.ph-less-than.icon-person:before, i.ph-less-than.icon-terrain:before, i.ph-less-than.icon-taxon:before, i.ph-less-than.icon-unknown:before, i.ph-less-than.icon-nothing:before, i.ph-less-than.icon-sampling:before, i.ph-less-than.icon-isolation:before, i.ph-less-than.icon-strain:before, i.ph-less-than.icon-gps:before, i.ph-less-than.icon-location:before, i.ph-less-than.icon-add:before, i.ph-less-than.icon-left:before, i.ph-less-than.icon-dna:before, i.ph-less-than.icon-delete:before, i.ph-less-than.icon-edit:before, i.ph-less-than.icon-info:before {
  content: "\edac";
}

.ph.ph-less-than-or-equal:before, i.ph-less-than-or-equal.icon-dead:before, i.ph-less-than-or-equal.icon-alive:before, i.ph-less-than-or-equal.icon-warn:before, i.ph-less-than-or-equal.icon-sign-out:before, i.ph-less-than-or-equal.icon-substratum:before, i.ph-less-than-or-equal.icon-environment:before, i.ph-less-than-or-equal.icon-user:before, i.ph-less-than-or-equal.icon-person:before, i.ph-less-than-or-equal.icon-terrain:before, i.ph-less-than-or-equal.icon-taxon:before, i.ph-less-than-or-equal.icon-unknown:before, i.ph-less-than-or-equal.icon-nothing:before, i.ph-less-than-or-equal.icon-sampling:before, i.ph-less-than-or-equal.icon-isolation:before, i.ph-less-than-or-equal.icon-strain:before, i.ph-less-than-or-equal.icon-gps:before, i.ph-less-than-or-equal.icon-location:before, i.ph-less-than-or-equal.icon-add:before, i.ph-less-than-or-equal.icon-left:before, i.ph-less-than-or-equal.icon-dna:before, i.ph-less-than-or-equal.icon-delete:before, i.ph-less-than-or-equal.icon-edit:before, i.ph-less-than-or-equal.icon-info:before {
  content: "\eda4";
}

.ph.ph-letter-circle-h:before, i.ph-letter-circle-h.icon-dead:before, i.ph-letter-circle-h.icon-alive:before, i.ph-letter-circle-h.icon-warn:before, i.ph-letter-circle-h.icon-sign-out:before, i.ph-letter-circle-h.icon-substratum:before, i.ph-letter-circle-h.icon-environment:before, i.ph-letter-circle-h.icon-user:before, i.ph-letter-circle-h.icon-person:before, i.ph-letter-circle-h.icon-terrain:before, i.ph-letter-circle-h.icon-taxon:before, i.ph-letter-circle-h.icon-unknown:before, i.ph-letter-circle-h.icon-nothing:before, i.ph-letter-circle-h.icon-sampling:before, i.ph-letter-circle-h.icon-isolation:before, i.ph-letter-circle-h.icon-strain:before, i.ph-letter-circle-h.icon-gps:before, i.ph-letter-circle-h.icon-location:before, i.ph-letter-circle-h.icon-add:before, i.ph-letter-circle-h.icon-left:before, i.ph-letter-circle-h.icon-dna:before, i.ph-letter-circle-h.icon-delete:before, i.ph-letter-circle-h.icon-edit:before, i.ph-letter-circle-h.icon-info:before {
  content: "\ebf8";
}

.ph.ph-letter-circle-p:before, i.ph-letter-circle-p.icon-dead:before, i.ph-letter-circle-p.icon-alive:before, i.ph-letter-circle-p.icon-warn:before, i.ph-letter-circle-p.icon-sign-out:before, i.ph-letter-circle-p.icon-substratum:before, i.ph-letter-circle-p.icon-environment:before, i.ph-letter-circle-p.icon-user:before, i.ph-letter-circle-p.icon-person:before, i.ph-letter-circle-p.icon-terrain:before, i.ph-letter-circle-p.icon-taxon:before, i.ph-letter-circle-p.icon-unknown:before, i.ph-letter-circle-p.icon-nothing:before, i.ph-letter-circle-p.icon-sampling:before, i.ph-letter-circle-p.icon-isolation:before, i.ph-letter-circle-p.icon-strain:before, i.ph-letter-circle-p.icon-gps:before, i.ph-letter-circle-p.icon-location:before, i.ph-letter-circle-p.icon-add:before, i.ph-letter-circle-p.icon-left:before, i.ph-letter-circle-p.icon-dna:before, i.ph-letter-circle-p.icon-delete:before, i.ph-letter-circle-p.icon-edit:before, i.ph-letter-circle-p.icon-info:before {
  content: "\ec08";
}

.ph.ph-letter-circle-v:before, i.ph-letter-circle-v.icon-dead:before, i.ph-letter-circle-v.icon-alive:before, i.ph-letter-circle-v.icon-warn:before, i.ph-letter-circle-v.icon-sign-out:before, i.ph-letter-circle-v.icon-substratum:before, i.ph-letter-circle-v.icon-environment:before, i.ph-letter-circle-v.icon-user:before, i.ph-letter-circle-v.icon-person:before, i.ph-letter-circle-v.icon-terrain:before, i.ph-letter-circle-v.icon-taxon:before, i.ph-letter-circle-v.icon-unknown:before, i.ph-letter-circle-v.icon-nothing:before, i.ph-letter-circle-v.icon-sampling:before, i.ph-letter-circle-v.icon-isolation:before, i.ph-letter-circle-v.icon-strain:before, i.ph-letter-circle-v.icon-gps:before, i.ph-letter-circle-v.icon-location:before, i.ph-letter-circle-v.icon-add:before, i.ph-letter-circle-v.icon-left:before, i.ph-letter-circle-v.icon-dna:before, i.ph-letter-circle-v.icon-delete:before, i.ph-letter-circle-v.icon-edit:before, i.ph-letter-circle-v.icon-info:before {
  content: "\ec14";
}

.ph.ph-lifebuoy:before, i.ph-lifebuoy.icon-dead:before, i.ph-lifebuoy.icon-alive:before, i.ph-lifebuoy.icon-warn:before, i.ph-lifebuoy.icon-sign-out:before, i.ph-lifebuoy.icon-substratum:before, i.ph-lifebuoy.icon-environment:before, i.ph-lifebuoy.icon-user:before, i.ph-lifebuoy.icon-person:before, i.ph-lifebuoy.icon-terrain:before, i.ph-lifebuoy.icon-taxon:before, i.ph-lifebuoy.icon-unknown:before, i.ph-lifebuoy.icon-nothing:before, i.ph-lifebuoy.icon-sampling:before, i.ph-lifebuoy.icon-isolation:before, i.ph-lifebuoy.icon-strain:before, i.ph-lifebuoy.icon-gps:before, i.ph-lifebuoy.icon-location:before, i.ph-lifebuoy.icon-add:before, i.ph-lifebuoy.icon-left:before, i.ph-lifebuoy.icon-dna:before, i.ph-lifebuoy.icon-delete:before, i.ph-lifebuoy.icon-edit:before, i.ph-lifebuoy.icon-info:before {
  content: "\e63a";
}

.ph.ph-lightbulb:before, i.ph-lightbulb.icon-dead:before, i.ph-lightbulb.icon-alive:before, i.ph-lightbulb.icon-warn:before, i.ph-lightbulb.icon-sign-out:before, i.ph-lightbulb.icon-substratum:before, i.ph-lightbulb.icon-environment:before, i.ph-lightbulb.icon-user:before, i.ph-lightbulb.icon-person:before, i.ph-lightbulb.icon-terrain:before, i.ph-lightbulb.icon-taxon:before, i.ph-lightbulb.icon-unknown:before, i.ph-lightbulb.icon-nothing:before, i.ph-lightbulb.icon-sampling:before, i.ph-lightbulb.icon-isolation:before, i.ph-lightbulb.icon-strain:before, i.ph-lightbulb.icon-gps:before, i.ph-lightbulb.icon-location:before, i.ph-lightbulb.icon-add:before, i.ph-lightbulb.icon-left:before, i.ph-lightbulb.icon-dna:before, i.ph-lightbulb.icon-delete:before, i.ph-lightbulb.icon-edit:before, i.ph-lightbulb.icon-info:before {
  content: "\e2dc";
}

.ph.ph-lightbulb-filament:before, i.ph-lightbulb-filament.icon-dead:before, i.ph-lightbulb-filament.icon-alive:before, i.ph-lightbulb-filament.icon-warn:before, i.ph-lightbulb-filament.icon-sign-out:before, i.ph-lightbulb-filament.icon-substratum:before, i.ph-lightbulb-filament.icon-environment:before, i.ph-lightbulb-filament.icon-user:before, i.ph-lightbulb-filament.icon-person:before, i.ph-lightbulb-filament.icon-terrain:before, i.ph-lightbulb-filament.icon-taxon:before, i.ph-lightbulb-filament.icon-unknown:before, i.ph-lightbulb-filament.icon-nothing:before, i.ph-lightbulb-filament.icon-sampling:before, i.ph-lightbulb-filament.icon-isolation:before, i.ph-lightbulb-filament.icon-strain:before, i.ph-lightbulb-filament.icon-gps:before, i.ph-lightbulb-filament.icon-location:before, i.ph-lightbulb-filament.icon-add:before, i.ph-lightbulb-filament.icon-left:before, i.ph-lightbulb-filament.icon-dna:before, i.ph-lightbulb-filament.icon-delete:before, i.ph-lightbulb-filament.icon-edit:before, i.ph-lightbulb-filament.icon-info:before {
  content: "\e63c";
}

.ph.ph-lighthouse:before, i.ph-lighthouse.icon-dead:before, i.ph-lighthouse.icon-alive:before, i.ph-lighthouse.icon-warn:before, i.ph-lighthouse.icon-sign-out:before, i.ph-lighthouse.icon-substratum:before, i.ph-lighthouse.icon-environment:before, i.ph-lighthouse.icon-user:before, i.ph-lighthouse.icon-person:before, i.ph-lighthouse.icon-terrain:before, i.ph-lighthouse.icon-taxon:before, i.ph-lighthouse.icon-unknown:before, i.ph-lighthouse.icon-nothing:before, i.ph-lighthouse.icon-sampling:before, i.ph-lighthouse.icon-isolation:before, i.ph-lighthouse.icon-strain:before, i.ph-lighthouse.icon-gps:before, i.ph-lighthouse.icon-location:before, i.ph-lighthouse.icon-add:before, i.ph-lighthouse.icon-left:before, i.ph-lighthouse.icon-dna:before, i.ph-lighthouse.icon-delete:before, i.ph-lighthouse.icon-edit:before, i.ph-lighthouse.icon-info:before {
  content: "\e9f6";
}

.ph.ph-lightning:before, i.ph-lightning.icon-dead:before, i.ph-lightning.icon-alive:before, i.ph-lightning.icon-warn:before, i.ph-lightning.icon-sign-out:before, i.ph-lightning.icon-substratum:before, i.ph-lightning.icon-environment:before, i.ph-lightning.icon-user:before, i.ph-lightning.icon-person:before, i.ph-lightning.icon-terrain:before, i.ph-lightning.icon-taxon:before, i.ph-lightning.icon-unknown:before, i.ph-lightning.icon-nothing:before, i.ph-lightning.icon-sampling:before, i.ph-lightning.icon-isolation:before, i.ph-lightning.icon-strain:before, i.ph-lightning.icon-gps:before, i.ph-lightning.icon-location:before, i.ph-lightning.icon-add:before, i.ph-lightning.icon-left:before, i.ph-lightning.icon-dna:before, i.ph-lightning.icon-delete:before, i.ph-lightning.icon-edit:before, i.ph-lightning.icon-info:before {
  content: "\e2de";
}

.ph.ph-lightning-a:before, i.ph-lightning-a.icon-dead:before, i.ph-lightning-a.icon-alive:before, i.ph-lightning-a.icon-warn:before, i.ph-lightning-a.icon-sign-out:before, i.ph-lightning-a.icon-substratum:before, i.ph-lightning-a.icon-environment:before, i.ph-lightning-a.icon-user:before, i.ph-lightning-a.icon-person:before, i.ph-lightning-a.icon-terrain:before, i.ph-lightning-a.icon-taxon:before, i.ph-lightning-a.icon-unknown:before, i.ph-lightning-a.icon-nothing:before, i.ph-lightning-a.icon-sampling:before, i.ph-lightning-a.icon-isolation:before, i.ph-lightning-a.icon-strain:before, i.ph-lightning-a.icon-gps:before, i.ph-lightning-a.icon-location:before, i.ph-lightning-a.icon-add:before, i.ph-lightning-a.icon-left:before, i.ph-lightning-a.icon-dna:before, i.ph-lightning-a.icon-delete:before, i.ph-lightning-a.icon-edit:before, i.ph-lightning-a.icon-info:before {
  content: "\ea84";
}

.ph.ph-lightning-slash:before, i.ph-lightning-slash.icon-dead:before, i.ph-lightning-slash.icon-alive:before, i.ph-lightning-slash.icon-warn:before, i.ph-lightning-slash.icon-sign-out:before, i.ph-lightning-slash.icon-substratum:before, i.ph-lightning-slash.icon-environment:before, i.ph-lightning-slash.icon-user:before, i.ph-lightning-slash.icon-person:before, i.ph-lightning-slash.icon-terrain:before, i.ph-lightning-slash.icon-taxon:before, i.ph-lightning-slash.icon-unknown:before, i.ph-lightning-slash.icon-nothing:before, i.ph-lightning-slash.icon-sampling:before, i.ph-lightning-slash.icon-isolation:before, i.ph-lightning-slash.icon-strain:before, i.ph-lightning-slash.icon-gps:before, i.ph-lightning-slash.icon-location:before, i.ph-lightning-slash.icon-add:before, i.ph-lightning-slash.icon-left:before, i.ph-lightning-slash.icon-dna:before, i.ph-lightning-slash.icon-delete:before, i.ph-lightning-slash.icon-edit:before, i.ph-lightning-slash.icon-info:before {
  content: "\e2e0";
}

.ph.ph-line-segment:before, i.ph-line-segment.icon-dead:before, i.ph-line-segment.icon-alive:before, i.ph-line-segment.icon-warn:before, i.ph-line-segment.icon-sign-out:before, i.ph-line-segment.icon-substratum:before, i.ph-line-segment.icon-environment:before, i.ph-line-segment.icon-user:before, i.ph-line-segment.icon-person:before, i.ph-line-segment.icon-terrain:before, i.ph-line-segment.icon-taxon:before, i.ph-line-segment.icon-unknown:before, i.ph-line-segment.icon-nothing:before, i.ph-line-segment.icon-sampling:before, i.ph-line-segment.icon-isolation:before, i.ph-line-segment.icon-strain:before, i.ph-line-segment.icon-gps:before, i.ph-line-segment.icon-location:before, i.ph-line-segment.icon-add:before, i.ph-line-segment.icon-left:before, i.ph-line-segment.icon-dna:before, i.ph-line-segment.icon-delete:before, i.ph-line-segment.icon-edit:before, i.ph-line-segment.icon-info:before {
  content: "\e6d2";
}

.ph.ph-line-segments:before, i.ph-line-segments.icon-dead:before, i.ph-line-segments.icon-alive:before, i.ph-line-segments.icon-warn:before, i.ph-line-segments.icon-sign-out:before, i.ph-line-segments.icon-substratum:before, i.ph-line-segments.icon-environment:before, i.ph-line-segments.icon-user:before, i.ph-line-segments.icon-person:before, i.ph-line-segments.icon-terrain:before, i.ph-line-segments.icon-taxon:before, i.ph-line-segments.icon-unknown:before, i.ph-line-segments.icon-nothing:before, i.ph-line-segments.icon-sampling:before, i.ph-line-segments.icon-isolation:before, i.ph-line-segments.icon-strain:before, i.ph-line-segments.icon-gps:before, i.ph-line-segments.icon-location:before, i.ph-line-segments.icon-add:before, i.ph-line-segments.icon-left:before, i.ph-line-segments.icon-dna:before, i.ph-line-segments.icon-delete:before, i.ph-line-segments.icon-edit:before, i.ph-line-segments.icon-info:before {
  content: "\e6d4";
}

.ph.ph-line-vertical:before, i.ph-line-vertical.icon-dead:before, i.ph-line-vertical.icon-alive:before, i.ph-line-vertical.icon-warn:before, i.ph-line-vertical.icon-sign-out:before, i.ph-line-vertical.icon-substratum:before, i.ph-line-vertical.icon-environment:before, i.ph-line-vertical.icon-user:before, i.ph-line-vertical.icon-person:before, i.ph-line-vertical.icon-terrain:before, i.ph-line-vertical.icon-taxon:before, i.ph-line-vertical.icon-unknown:before, i.ph-line-vertical.icon-nothing:before, i.ph-line-vertical.icon-sampling:before, i.ph-line-vertical.icon-isolation:before, i.ph-line-vertical.icon-strain:before, i.ph-line-vertical.icon-gps:before, i.ph-line-vertical.icon-location:before, i.ph-line-vertical.icon-add:before, i.ph-line-vertical.icon-left:before, i.ph-line-vertical.icon-dna:before, i.ph-line-vertical.icon-delete:before, i.ph-line-vertical.icon-edit:before, i.ph-line-vertical.icon-info:before {
  content: "\ed70";
}

.ph.ph-link:before, i.ph-link.icon-dead:before, i.ph-link.icon-alive:before, i.ph-link.icon-warn:before, i.ph-link.icon-sign-out:before, i.ph-link.icon-substratum:before, i.ph-link.icon-environment:before, i.ph-link.icon-user:before, i.ph-link.icon-person:before, i.ph-link.icon-terrain:before, i.ph-link.icon-taxon:before, i.ph-link.icon-unknown:before, i.ph-link.icon-nothing:before, i.ph-link.icon-sampling:before, i.ph-link.icon-isolation:before, i.ph-link.icon-strain:before, i.ph-link.icon-gps:before, i.ph-link.icon-location:before, i.ph-link.icon-add:before, i.ph-link.icon-left:before, i.ph-link.icon-dna:before, i.ph-link.icon-delete:before, i.ph-link.icon-edit:before, i.ph-link.icon-info:before {
  content: "\e2e2";
}

.ph.ph-link-break:before, i.ph-link-break.icon-dead:before, i.ph-link-break.icon-alive:before, i.ph-link-break.icon-warn:before, i.ph-link-break.icon-sign-out:before, i.ph-link-break.icon-substratum:before, i.ph-link-break.icon-environment:before, i.ph-link-break.icon-user:before, i.ph-link-break.icon-person:before, i.ph-link-break.icon-terrain:before, i.ph-link-break.icon-taxon:before, i.ph-link-break.icon-unknown:before, i.ph-link-break.icon-nothing:before, i.ph-link-break.icon-sampling:before, i.ph-link-break.icon-isolation:before, i.ph-link-break.icon-strain:before, i.ph-link-break.icon-gps:before, i.ph-link-break.icon-location:before, i.ph-link-break.icon-add:before, i.ph-link-break.icon-left:before, i.ph-link-break.icon-dna:before, i.ph-link-break.icon-delete:before, i.ph-link-break.icon-edit:before, i.ph-link-break.icon-info:before {
  content: "\e2e4";
}

.ph.ph-link-simple:before, i.ph-link-simple.icon-dead:before, i.ph-link-simple.icon-alive:before, i.ph-link-simple.icon-warn:before, i.ph-link-simple.icon-sign-out:before, i.ph-link-simple.icon-substratum:before, i.ph-link-simple.icon-environment:before, i.ph-link-simple.icon-user:before, i.ph-link-simple.icon-person:before, i.ph-link-simple.icon-terrain:before, i.ph-link-simple.icon-taxon:before, i.ph-link-simple.icon-unknown:before, i.ph-link-simple.icon-nothing:before, i.ph-link-simple.icon-sampling:before, i.ph-link-simple.icon-isolation:before, i.ph-link-simple.icon-strain:before, i.ph-link-simple.icon-gps:before, i.ph-link-simple.icon-location:before, i.ph-link-simple.icon-add:before, i.ph-link-simple.icon-left:before, i.ph-link-simple.icon-dna:before, i.ph-link-simple.icon-delete:before, i.ph-link-simple.icon-edit:before, i.ph-link-simple.icon-info:before {
  content: "\e2e6";
}

.ph.ph-link-simple-break:before, i.ph-link-simple-break.icon-dead:before, i.ph-link-simple-break.icon-alive:before, i.ph-link-simple-break.icon-warn:before, i.ph-link-simple-break.icon-sign-out:before, i.ph-link-simple-break.icon-substratum:before, i.ph-link-simple-break.icon-environment:before, i.ph-link-simple-break.icon-user:before, i.ph-link-simple-break.icon-person:before, i.ph-link-simple-break.icon-terrain:before, i.ph-link-simple-break.icon-taxon:before, i.ph-link-simple-break.icon-unknown:before, i.ph-link-simple-break.icon-nothing:before, i.ph-link-simple-break.icon-sampling:before, i.ph-link-simple-break.icon-isolation:before, i.ph-link-simple-break.icon-strain:before, i.ph-link-simple-break.icon-gps:before, i.ph-link-simple-break.icon-location:before, i.ph-link-simple-break.icon-add:before, i.ph-link-simple-break.icon-left:before, i.ph-link-simple-break.icon-dna:before, i.ph-link-simple-break.icon-delete:before, i.ph-link-simple-break.icon-edit:before, i.ph-link-simple-break.icon-info:before {
  content: "\e2e8";
}

.ph.ph-link-simple-horizontal:before, i.ph-link-simple-horizontal.icon-dead:before, i.ph-link-simple-horizontal.icon-alive:before, i.ph-link-simple-horizontal.icon-warn:before, i.ph-link-simple-horizontal.icon-sign-out:before, i.ph-link-simple-horizontal.icon-substratum:before, i.ph-link-simple-horizontal.icon-environment:before, i.ph-link-simple-horizontal.icon-user:before, i.ph-link-simple-horizontal.icon-person:before, i.ph-link-simple-horizontal.icon-terrain:before, i.ph-link-simple-horizontal.icon-taxon:before, i.ph-link-simple-horizontal.icon-unknown:before, i.ph-link-simple-horizontal.icon-nothing:before, i.ph-link-simple-horizontal.icon-sampling:before, i.ph-link-simple-horizontal.icon-isolation:before, i.ph-link-simple-horizontal.icon-strain:before, i.ph-link-simple-horizontal.icon-gps:before, i.ph-link-simple-horizontal.icon-location:before, i.ph-link-simple-horizontal.icon-add:before, i.ph-link-simple-horizontal.icon-left:before, i.ph-link-simple-horizontal.icon-dna:before, i.ph-link-simple-horizontal.icon-delete:before, i.ph-link-simple-horizontal.icon-edit:before, i.ph-link-simple-horizontal.icon-info:before {
  content: "\e2ea";
}

.ph.ph-link-simple-horizontal-break:before, i.ph-link-simple-horizontal-break.icon-dead:before, i.ph-link-simple-horizontal-break.icon-alive:before, i.ph-link-simple-horizontal-break.icon-warn:before, i.ph-link-simple-horizontal-break.icon-sign-out:before, i.ph-link-simple-horizontal-break.icon-substratum:before, i.ph-link-simple-horizontal-break.icon-environment:before, i.ph-link-simple-horizontal-break.icon-user:before, i.ph-link-simple-horizontal-break.icon-person:before, i.ph-link-simple-horizontal-break.icon-terrain:before, i.ph-link-simple-horizontal-break.icon-taxon:before, i.ph-link-simple-horizontal-break.icon-unknown:before, i.ph-link-simple-horizontal-break.icon-nothing:before, i.ph-link-simple-horizontal-break.icon-sampling:before, i.ph-link-simple-horizontal-break.icon-isolation:before, i.ph-link-simple-horizontal-break.icon-strain:before, i.ph-link-simple-horizontal-break.icon-gps:before, i.ph-link-simple-horizontal-break.icon-location:before, i.ph-link-simple-horizontal-break.icon-add:before, i.ph-link-simple-horizontal-break.icon-left:before, i.ph-link-simple-horizontal-break.icon-dna:before, i.ph-link-simple-horizontal-break.icon-delete:before, i.ph-link-simple-horizontal-break.icon-edit:before, i.ph-link-simple-horizontal-break.icon-info:before {
  content: "\e2ec";
}

.ph.ph-linkedin-logo:before, i.ph-linkedin-logo.icon-dead:before, i.ph-linkedin-logo.icon-alive:before, i.ph-linkedin-logo.icon-warn:before, i.ph-linkedin-logo.icon-sign-out:before, i.ph-linkedin-logo.icon-substratum:before, i.ph-linkedin-logo.icon-environment:before, i.ph-linkedin-logo.icon-user:before, i.ph-linkedin-logo.icon-person:before, i.ph-linkedin-logo.icon-terrain:before, i.ph-linkedin-logo.icon-taxon:before, i.ph-linkedin-logo.icon-unknown:before, i.ph-linkedin-logo.icon-nothing:before, i.ph-linkedin-logo.icon-sampling:before, i.ph-linkedin-logo.icon-isolation:before, i.ph-linkedin-logo.icon-strain:before, i.ph-linkedin-logo.icon-gps:before, i.ph-linkedin-logo.icon-location:before, i.ph-linkedin-logo.icon-add:before, i.ph-linkedin-logo.icon-left:before, i.ph-linkedin-logo.icon-dna:before, i.ph-linkedin-logo.icon-delete:before, i.ph-linkedin-logo.icon-edit:before, i.ph-linkedin-logo.icon-info:before {
  content: "\e2ee";
}

.ph.ph-linktree-logo:before, i.ph-linktree-logo.icon-dead:before, i.ph-linktree-logo.icon-alive:before, i.ph-linktree-logo.icon-warn:before, i.ph-linktree-logo.icon-sign-out:before, i.ph-linktree-logo.icon-substratum:before, i.ph-linktree-logo.icon-environment:before, i.ph-linktree-logo.icon-user:before, i.ph-linktree-logo.icon-person:before, i.ph-linktree-logo.icon-terrain:before, i.ph-linktree-logo.icon-taxon:before, i.ph-linktree-logo.icon-unknown:before, i.ph-linktree-logo.icon-nothing:before, i.ph-linktree-logo.icon-sampling:before, i.ph-linktree-logo.icon-isolation:before, i.ph-linktree-logo.icon-strain:before, i.ph-linktree-logo.icon-gps:before, i.ph-linktree-logo.icon-location:before, i.ph-linktree-logo.icon-add:before, i.ph-linktree-logo.icon-left:before, i.ph-linktree-logo.icon-dna:before, i.ph-linktree-logo.icon-delete:before, i.ph-linktree-logo.icon-edit:before, i.ph-linktree-logo.icon-info:before {
  content: "\edee";
}

.ph.ph-linux-logo:before, i.ph-linux-logo.icon-dead:before, i.ph-linux-logo.icon-alive:before, i.ph-linux-logo.icon-warn:before, i.ph-linux-logo.icon-sign-out:before, i.ph-linux-logo.icon-substratum:before, i.ph-linux-logo.icon-environment:before, i.ph-linux-logo.icon-user:before, i.ph-linux-logo.icon-person:before, i.ph-linux-logo.icon-terrain:before, i.ph-linux-logo.icon-taxon:before, i.ph-linux-logo.icon-unknown:before, i.ph-linux-logo.icon-nothing:before, i.ph-linux-logo.icon-sampling:before, i.ph-linux-logo.icon-isolation:before, i.ph-linux-logo.icon-strain:before, i.ph-linux-logo.icon-gps:before, i.ph-linux-logo.icon-location:before, i.ph-linux-logo.icon-add:before, i.ph-linux-logo.icon-left:before, i.ph-linux-logo.icon-dna:before, i.ph-linux-logo.icon-delete:before, i.ph-linux-logo.icon-edit:before, i.ph-linux-logo.icon-info:before {
  content: "\eb02";
}

.ph.ph-list:before, i.ph-list.icon-dead:before, i.ph-list.icon-alive:before, i.ph-list.icon-warn:before, i.ph-list.icon-sign-out:before, i.ph-list.icon-substratum:before, i.ph-list.icon-environment:before, i.ph-list.icon-user:before, i.ph-list.icon-person:before, i.ph-list.icon-terrain:before, i.ph-list.icon-taxon:before, i.ph-list.icon-unknown:before, i.ph-list.icon-nothing:before, i.ph-list.icon-sampling:before, i.ph-list.icon-isolation:before, i.ph-list.icon-strain:before, i.ph-list.icon-gps:before, i.ph-list.icon-location:before, i.ph-list.icon-add:before, i.ph-list.icon-left:before, i.ph-list.icon-dna:before, i.ph-list.icon-delete:before, i.ph-list.icon-edit:before, i.ph-list.icon-info:before {
  content: "\e2f0";
}

.ph.ph-list-bullets:before, i.ph-list-bullets.icon-dead:before, i.ph-list-bullets.icon-alive:before, i.ph-list-bullets.icon-warn:before, i.ph-list-bullets.icon-sign-out:before, i.ph-list-bullets.icon-substratum:before, i.ph-list-bullets.icon-environment:before, i.ph-list-bullets.icon-user:before, i.ph-list-bullets.icon-person:before, i.ph-list-bullets.icon-terrain:before, i.ph-list-bullets.icon-taxon:before, i.ph-list-bullets.icon-unknown:before, i.ph-list-bullets.icon-nothing:before, i.ph-list-bullets.icon-sampling:before, i.ph-list-bullets.icon-isolation:before, i.ph-list-bullets.icon-strain:before, i.ph-list-bullets.icon-gps:before, i.ph-list-bullets.icon-location:before, i.ph-list-bullets.icon-add:before, i.ph-list-bullets.icon-left:before, i.ph-list-bullets.icon-dna:before, i.ph-list-bullets.icon-delete:before, i.ph-list-bullets.icon-edit:before, i.ph-list-bullets.icon-info:before {
  content: "\e2f2";
}

.ph.ph-list-checks:before, i.ph-list-checks.icon-dead:before, i.ph-list-checks.icon-alive:before, i.ph-list-checks.icon-warn:before, i.ph-list-checks.icon-sign-out:before, i.ph-list-checks.icon-substratum:before, i.ph-list-checks.icon-environment:before, i.ph-list-checks.icon-user:before, i.ph-list-checks.icon-person:before, i.ph-list-checks.icon-terrain:before, i.ph-list-checks.icon-taxon:before, i.ph-list-checks.icon-unknown:before, i.ph-list-checks.icon-nothing:before, i.ph-list-checks.icon-sampling:before, i.ph-list-checks.icon-isolation:before, i.ph-list-checks.icon-strain:before, i.ph-list-checks.icon-gps:before, i.ph-list-checks.icon-location:before, i.ph-list-checks.icon-add:before, i.ph-list-checks.icon-left:before, i.ph-list-checks.icon-dna:before, i.ph-list-checks.icon-delete:before, i.ph-list-checks.icon-edit:before, i.ph-list-checks.icon-info:before {
  content: "\eadc";
}

.ph.ph-list-dashes:before, i.ph-list-dashes.icon-dead:before, i.ph-list-dashes.icon-alive:before, i.ph-list-dashes.icon-warn:before, i.ph-list-dashes.icon-sign-out:before, i.ph-list-dashes.icon-substratum:before, i.ph-list-dashes.icon-environment:before, i.ph-list-dashes.icon-user:before, i.ph-list-dashes.icon-person:before, i.ph-list-dashes.icon-terrain:before, i.ph-list-dashes.icon-taxon:before, i.ph-list-dashes.icon-unknown:before, i.ph-list-dashes.icon-nothing:before, i.ph-list-dashes.icon-sampling:before, i.ph-list-dashes.icon-isolation:before, i.ph-list-dashes.icon-strain:before, i.ph-list-dashes.icon-gps:before, i.ph-list-dashes.icon-location:before, i.ph-list-dashes.icon-add:before, i.ph-list-dashes.icon-left:before, i.ph-list-dashes.icon-dna:before, i.ph-list-dashes.icon-delete:before, i.ph-list-dashes.icon-edit:before, i.ph-list-dashes.icon-info:before {
  content: "\e2f4";
}

.ph.ph-list-heart:before, i.ph-list-heart.icon-dead:before, i.ph-list-heart.icon-alive:before, i.ph-list-heart.icon-warn:before, i.ph-list-heart.icon-sign-out:before, i.ph-list-heart.icon-substratum:before, i.ph-list-heart.icon-environment:before, i.ph-list-heart.icon-user:before, i.ph-list-heart.icon-person:before, i.ph-list-heart.icon-terrain:before, i.ph-list-heart.icon-taxon:before, i.ph-list-heart.icon-unknown:before, i.ph-list-heart.icon-nothing:before, i.ph-list-heart.icon-sampling:before, i.ph-list-heart.icon-isolation:before, i.ph-list-heart.icon-strain:before, i.ph-list-heart.icon-gps:before, i.ph-list-heart.icon-location:before, i.ph-list-heart.icon-add:before, i.ph-list-heart.icon-left:before, i.ph-list-heart.icon-dna:before, i.ph-list-heart.icon-delete:before, i.ph-list-heart.icon-edit:before, i.ph-list-heart.icon-info:before {
  content: "\ebde";
}

.ph.ph-list-magnifying-glass:before, i.ph-list-magnifying-glass.icon-dead:before, i.ph-list-magnifying-glass.icon-alive:before, i.ph-list-magnifying-glass.icon-warn:before, i.ph-list-magnifying-glass.icon-sign-out:before, i.ph-list-magnifying-glass.icon-substratum:before, i.ph-list-magnifying-glass.icon-environment:before, i.ph-list-magnifying-glass.icon-user:before, i.ph-list-magnifying-glass.icon-person:before, i.ph-list-magnifying-glass.icon-terrain:before, i.ph-list-magnifying-glass.icon-taxon:before, i.ph-list-magnifying-glass.icon-unknown:before, i.ph-list-magnifying-glass.icon-nothing:before, i.ph-list-magnifying-glass.icon-sampling:before, i.ph-list-magnifying-glass.icon-isolation:before, i.ph-list-magnifying-glass.icon-strain:before, i.ph-list-magnifying-glass.icon-gps:before, i.ph-list-magnifying-glass.icon-location:before, i.ph-list-magnifying-glass.icon-add:before, i.ph-list-magnifying-glass.icon-left:before, i.ph-list-magnifying-glass.icon-dna:before, i.ph-list-magnifying-glass.icon-delete:before, i.ph-list-magnifying-glass.icon-edit:before, i.ph-list-magnifying-glass.icon-info:before {
  content: "\ebe0";
}

.ph.ph-list-numbers:before, i.ph-list-numbers.icon-dead:before, i.ph-list-numbers.icon-alive:before, i.ph-list-numbers.icon-warn:before, i.ph-list-numbers.icon-sign-out:before, i.ph-list-numbers.icon-substratum:before, i.ph-list-numbers.icon-environment:before, i.ph-list-numbers.icon-user:before, i.ph-list-numbers.icon-person:before, i.ph-list-numbers.icon-terrain:before, i.ph-list-numbers.icon-taxon:before, i.ph-list-numbers.icon-unknown:before, i.ph-list-numbers.icon-nothing:before, i.ph-list-numbers.icon-sampling:before, i.ph-list-numbers.icon-isolation:before, i.ph-list-numbers.icon-strain:before, i.ph-list-numbers.icon-gps:before, i.ph-list-numbers.icon-location:before, i.ph-list-numbers.icon-add:before, i.ph-list-numbers.icon-left:before, i.ph-list-numbers.icon-dna:before, i.ph-list-numbers.icon-delete:before, i.ph-list-numbers.icon-edit:before, i.ph-list-numbers.icon-info:before {
  content: "\e2f6";
}

.ph.ph-list-plus:before, i.ph-list-plus.icon-dead:before, i.ph-list-plus.icon-alive:before, i.ph-list-plus.icon-warn:before, i.ph-list-plus.icon-sign-out:before, i.ph-list-plus.icon-substratum:before, i.ph-list-plus.icon-environment:before, i.ph-list-plus.icon-user:before, i.ph-list-plus.icon-person:before, i.ph-list-plus.icon-terrain:before, i.ph-list-plus.icon-taxon:before, i.ph-list-plus.icon-unknown:before, i.ph-list-plus.icon-nothing:before, i.ph-list-plus.icon-sampling:before, i.ph-list-plus.icon-isolation:before, i.ph-list-plus.icon-strain:before, i.ph-list-plus.icon-gps:before, i.ph-list-plus.icon-location:before, i.ph-list-plus.icon-add:before, i.ph-list-plus.icon-left:before, i.ph-list-plus.icon-dna:before, i.ph-list-plus.icon-delete:before, i.ph-list-plus.icon-edit:before, i.ph-list-plus.icon-info:before {
  content: "\e2f8";
}

.ph.ph-list-star:before, i.ph-list-star.icon-dead:before, i.ph-list-star.icon-alive:before, i.ph-list-star.icon-warn:before, i.ph-list-star.icon-sign-out:before, i.ph-list-star.icon-substratum:before, i.ph-list-star.icon-environment:before, i.ph-list-star.icon-user:before, i.ph-list-star.icon-person:before, i.ph-list-star.icon-terrain:before, i.ph-list-star.icon-taxon:before, i.ph-list-star.icon-unknown:before, i.ph-list-star.icon-nothing:before, i.ph-list-star.icon-sampling:before, i.ph-list-star.icon-isolation:before, i.ph-list-star.icon-strain:before, i.ph-list-star.icon-gps:before, i.ph-list-star.icon-location:before, i.ph-list-star.icon-add:before, i.ph-list-star.icon-left:before, i.ph-list-star.icon-dna:before, i.ph-list-star.icon-delete:before, i.ph-list-star.icon-edit:before, i.ph-list-star.icon-info:before {
  content: "\ebdc";
}

.ph.ph-lock:before, i.ph-lock.icon-dead:before, i.ph-lock.icon-alive:before, i.ph-lock.icon-warn:before, i.ph-lock.icon-sign-out:before, i.ph-lock.icon-substratum:before, i.ph-lock.icon-environment:before, i.ph-lock.icon-user:before, i.ph-lock.icon-person:before, i.ph-lock.icon-terrain:before, i.ph-lock.icon-taxon:before, i.ph-lock.icon-unknown:before, i.ph-lock.icon-nothing:before, i.ph-lock.icon-sampling:before, i.ph-lock.icon-isolation:before, i.ph-lock.icon-strain:before, i.ph-lock.icon-gps:before, i.ph-lock.icon-location:before, i.ph-lock.icon-add:before, i.ph-lock.icon-left:before, i.ph-lock.icon-dna:before, i.ph-lock.icon-delete:before, i.ph-lock.icon-edit:before, i.ph-lock.icon-info:before {
  content: "\e2fa";
}

.ph.ph-lock-key:before, i.ph-lock-key.icon-dead:before, i.ph-lock-key.icon-alive:before, i.ph-lock-key.icon-warn:before, i.ph-lock-key.icon-sign-out:before, i.ph-lock-key.icon-substratum:before, i.ph-lock-key.icon-environment:before, i.ph-lock-key.icon-user:before, i.ph-lock-key.icon-person:before, i.ph-lock-key.icon-terrain:before, i.ph-lock-key.icon-taxon:before, i.ph-lock-key.icon-unknown:before, i.ph-lock-key.icon-nothing:before, i.ph-lock-key.icon-sampling:before, i.ph-lock-key.icon-isolation:before, i.ph-lock-key.icon-strain:before, i.ph-lock-key.icon-gps:before, i.ph-lock-key.icon-location:before, i.ph-lock-key.icon-add:before, i.ph-lock-key.icon-left:before, i.ph-lock-key.icon-dna:before, i.ph-lock-key.icon-delete:before, i.ph-lock-key.icon-edit:before, i.ph-lock-key.icon-info:before {
  content: "\e2fe";
}

.ph.ph-lock-key-open:before, i.ph-lock-key-open.icon-dead:before, i.ph-lock-key-open.icon-alive:before, i.ph-lock-key-open.icon-warn:before, i.ph-lock-key-open.icon-sign-out:before, i.ph-lock-key-open.icon-substratum:before, i.ph-lock-key-open.icon-environment:before, i.ph-lock-key-open.icon-user:before, i.ph-lock-key-open.icon-person:before, i.ph-lock-key-open.icon-terrain:before, i.ph-lock-key-open.icon-taxon:before, i.ph-lock-key-open.icon-unknown:before, i.ph-lock-key-open.icon-nothing:before, i.ph-lock-key-open.icon-sampling:before, i.ph-lock-key-open.icon-isolation:before, i.ph-lock-key-open.icon-strain:before, i.ph-lock-key-open.icon-gps:before, i.ph-lock-key-open.icon-location:before, i.ph-lock-key-open.icon-add:before, i.ph-lock-key-open.icon-left:before, i.ph-lock-key-open.icon-dna:before, i.ph-lock-key-open.icon-delete:before, i.ph-lock-key-open.icon-edit:before, i.ph-lock-key-open.icon-info:before {
  content: "\e300";
}

.ph.ph-lock-laminated:before, i.ph-lock-laminated.icon-dead:before, i.ph-lock-laminated.icon-alive:before, i.ph-lock-laminated.icon-warn:before, i.ph-lock-laminated.icon-sign-out:before, i.ph-lock-laminated.icon-substratum:before, i.ph-lock-laminated.icon-environment:before, i.ph-lock-laminated.icon-user:before, i.ph-lock-laminated.icon-person:before, i.ph-lock-laminated.icon-terrain:before, i.ph-lock-laminated.icon-taxon:before, i.ph-lock-laminated.icon-unknown:before, i.ph-lock-laminated.icon-nothing:before, i.ph-lock-laminated.icon-sampling:before, i.ph-lock-laminated.icon-isolation:before, i.ph-lock-laminated.icon-strain:before, i.ph-lock-laminated.icon-gps:before, i.ph-lock-laminated.icon-location:before, i.ph-lock-laminated.icon-add:before, i.ph-lock-laminated.icon-left:before, i.ph-lock-laminated.icon-dna:before, i.ph-lock-laminated.icon-delete:before, i.ph-lock-laminated.icon-edit:before, i.ph-lock-laminated.icon-info:before {
  content: "\e302";
}

.ph.ph-lock-laminated-open:before, i.ph-lock-laminated-open.icon-dead:before, i.ph-lock-laminated-open.icon-alive:before, i.ph-lock-laminated-open.icon-warn:before, i.ph-lock-laminated-open.icon-sign-out:before, i.ph-lock-laminated-open.icon-substratum:before, i.ph-lock-laminated-open.icon-environment:before, i.ph-lock-laminated-open.icon-user:before, i.ph-lock-laminated-open.icon-person:before, i.ph-lock-laminated-open.icon-terrain:before, i.ph-lock-laminated-open.icon-taxon:before, i.ph-lock-laminated-open.icon-unknown:before, i.ph-lock-laminated-open.icon-nothing:before, i.ph-lock-laminated-open.icon-sampling:before, i.ph-lock-laminated-open.icon-isolation:before, i.ph-lock-laminated-open.icon-strain:before, i.ph-lock-laminated-open.icon-gps:before, i.ph-lock-laminated-open.icon-location:before, i.ph-lock-laminated-open.icon-add:before, i.ph-lock-laminated-open.icon-left:before, i.ph-lock-laminated-open.icon-dna:before, i.ph-lock-laminated-open.icon-delete:before, i.ph-lock-laminated-open.icon-edit:before, i.ph-lock-laminated-open.icon-info:before {
  content: "\e304";
}

.ph.ph-lock-open:before, i.ph-lock-open.icon-dead:before, i.ph-lock-open.icon-alive:before, i.ph-lock-open.icon-warn:before, i.ph-lock-open.icon-sign-out:before, i.ph-lock-open.icon-substratum:before, i.ph-lock-open.icon-environment:before, i.ph-lock-open.icon-user:before, i.ph-lock-open.icon-person:before, i.ph-lock-open.icon-terrain:before, i.ph-lock-open.icon-taxon:before, i.ph-lock-open.icon-unknown:before, i.ph-lock-open.icon-nothing:before, i.ph-lock-open.icon-sampling:before, i.ph-lock-open.icon-isolation:before, i.ph-lock-open.icon-strain:before, i.ph-lock-open.icon-gps:before, i.ph-lock-open.icon-location:before, i.ph-lock-open.icon-add:before, i.ph-lock-open.icon-left:before, i.ph-lock-open.icon-dna:before, i.ph-lock-open.icon-delete:before, i.ph-lock-open.icon-edit:before, i.ph-lock-open.icon-info:before {
  content: "\e306";
}

.ph.ph-lock-simple:before, i.ph-lock-simple.icon-dead:before, i.ph-lock-simple.icon-alive:before, i.ph-lock-simple.icon-warn:before, i.ph-lock-simple.icon-sign-out:before, i.ph-lock-simple.icon-substratum:before, i.ph-lock-simple.icon-environment:before, i.ph-lock-simple.icon-user:before, i.ph-lock-simple.icon-person:before, i.ph-lock-simple.icon-terrain:before, i.ph-lock-simple.icon-taxon:before, i.ph-lock-simple.icon-unknown:before, i.ph-lock-simple.icon-nothing:before, i.ph-lock-simple.icon-sampling:before, i.ph-lock-simple.icon-isolation:before, i.ph-lock-simple.icon-strain:before, i.ph-lock-simple.icon-gps:before, i.ph-lock-simple.icon-location:before, i.ph-lock-simple.icon-add:before, i.ph-lock-simple.icon-left:before, i.ph-lock-simple.icon-dna:before, i.ph-lock-simple.icon-delete:before, i.ph-lock-simple.icon-edit:before, i.ph-lock-simple.icon-info:before {
  content: "\e308";
}

.ph.ph-lock-simple-open:before, i.ph-lock-simple-open.icon-dead:before, i.ph-lock-simple-open.icon-alive:before, i.ph-lock-simple-open.icon-warn:before, i.ph-lock-simple-open.icon-sign-out:before, i.ph-lock-simple-open.icon-substratum:before, i.ph-lock-simple-open.icon-environment:before, i.ph-lock-simple-open.icon-user:before, i.ph-lock-simple-open.icon-person:before, i.ph-lock-simple-open.icon-terrain:before, i.ph-lock-simple-open.icon-taxon:before, i.ph-lock-simple-open.icon-unknown:before, i.ph-lock-simple-open.icon-nothing:before, i.ph-lock-simple-open.icon-sampling:before, i.ph-lock-simple-open.icon-isolation:before, i.ph-lock-simple-open.icon-strain:before, i.ph-lock-simple-open.icon-gps:before, i.ph-lock-simple-open.icon-location:before, i.ph-lock-simple-open.icon-add:before, i.ph-lock-simple-open.icon-left:before, i.ph-lock-simple-open.icon-dna:before, i.ph-lock-simple-open.icon-delete:before, i.ph-lock-simple-open.icon-edit:before, i.ph-lock-simple-open.icon-info:before {
  content: "\e30a";
}

.ph.ph-lockers:before, i.ph-lockers.icon-dead:before, i.ph-lockers.icon-alive:before, i.ph-lockers.icon-warn:before, i.ph-lockers.icon-sign-out:before, i.ph-lockers.icon-substratum:before, i.ph-lockers.icon-environment:before, i.ph-lockers.icon-user:before, i.ph-lockers.icon-person:before, i.ph-lockers.icon-terrain:before, i.ph-lockers.icon-taxon:before, i.ph-lockers.icon-unknown:before, i.ph-lockers.icon-nothing:before, i.ph-lockers.icon-sampling:before, i.ph-lockers.icon-isolation:before, i.ph-lockers.icon-strain:before, i.ph-lockers.icon-gps:before, i.ph-lockers.icon-location:before, i.ph-lockers.icon-add:before, i.ph-lockers.icon-left:before, i.ph-lockers.icon-dna:before, i.ph-lockers.icon-delete:before, i.ph-lockers.icon-edit:before, i.ph-lockers.icon-info:before {
  content: "\ecb8";
}

.ph.ph-log:before, i.ph-log.icon-dead:before, i.ph-log.icon-alive:before, i.ph-log.icon-warn:before, i.ph-log.icon-sign-out:before, i.ph-log.icon-substratum:before, i.ph-log.icon-environment:before, i.ph-log.icon-user:before, i.ph-log.icon-person:before, i.ph-log.icon-terrain:before, i.ph-log.icon-taxon:before, i.ph-log.icon-unknown:before, i.ph-log.icon-nothing:before, i.ph-log.icon-sampling:before, i.ph-log.icon-isolation:before, i.ph-log.icon-strain:before, i.ph-log.icon-gps:before, i.ph-log.icon-location:before, i.ph-log.icon-add:before, i.ph-log.icon-left:before, i.ph-log.icon-dna:before, i.ph-log.icon-delete:before, i.ph-log.icon-edit:before, i.ph-log.icon-info:before {
  content: "\ed82";
}

.ph.ph-magic-wand:before, i.ph-magic-wand.icon-dead:before, i.ph-magic-wand.icon-alive:before, i.ph-magic-wand.icon-warn:before, i.ph-magic-wand.icon-sign-out:before, i.ph-magic-wand.icon-substratum:before, i.ph-magic-wand.icon-environment:before, i.ph-magic-wand.icon-user:before, i.ph-magic-wand.icon-person:before, i.ph-magic-wand.icon-terrain:before, i.ph-magic-wand.icon-taxon:before, i.ph-magic-wand.icon-unknown:before, i.ph-magic-wand.icon-nothing:before, i.ph-magic-wand.icon-sampling:before, i.ph-magic-wand.icon-isolation:before, i.ph-magic-wand.icon-strain:before, i.ph-magic-wand.icon-gps:before, i.ph-magic-wand.icon-location:before, i.ph-magic-wand.icon-add:before, i.ph-magic-wand.icon-left:before, i.ph-magic-wand.icon-dna:before, i.ph-magic-wand.icon-delete:before, i.ph-magic-wand.icon-edit:before, i.ph-magic-wand.icon-info:before {
  content: "\e6b6";
}

.ph.ph-magnet:before, i.ph-magnet.icon-dead:before, i.ph-magnet.icon-alive:before, i.ph-magnet.icon-warn:before, i.ph-magnet.icon-sign-out:before, i.ph-magnet.icon-substratum:before, i.ph-magnet.icon-environment:before, i.ph-magnet.icon-user:before, i.ph-magnet.icon-person:before, i.ph-magnet.icon-terrain:before, i.ph-magnet.icon-taxon:before, i.ph-magnet.icon-unknown:before, i.ph-magnet.icon-nothing:before, i.ph-magnet.icon-sampling:before, i.ph-magnet.icon-isolation:before, i.ph-magnet.icon-strain:before, i.ph-magnet.icon-gps:before, i.ph-magnet.icon-location:before, i.ph-magnet.icon-add:before, i.ph-magnet.icon-left:before, i.ph-magnet.icon-dna:before, i.ph-magnet.icon-delete:before, i.ph-magnet.icon-edit:before, i.ph-magnet.icon-info:before {
  content: "\e680";
}

.ph.ph-magnet-straight:before, i.ph-magnet-straight.icon-dead:before, i.ph-magnet-straight.icon-alive:before, i.ph-magnet-straight.icon-warn:before, i.ph-magnet-straight.icon-sign-out:before, i.ph-magnet-straight.icon-substratum:before, i.ph-magnet-straight.icon-environment:before, i.ph-magnet-straight.icon-user:before, i.ph-magnet-straight.icon-person:before, i.ph-magnet-straight.icon-terrain:before, i.ph-magnet-straight.icon-taxon:before, i.ph-magnet-straight.icon-unknown:before, i.ph-magnet-straight.icon-nothing:before, i.ph-magnet-straight.icon-sampling:before, i.ph-magnet-straight.icon-isolation:before, i.ph-magnet-straight.icon-strain:before, i.ph-magnet-straight.icon-gps:before, i.ph-magnet-straight.icon-location:before, i.ph-magnet-straight.icon-add:before, i.ph-magnet-straight.icon-left:before, i.ph-magnet-straight.icon-dna:before, i.ph-magnet-straight.icon-delete:before, i.ph-magnet-straight.icon-edit:before, i.ph-magnet-straight.icon-info:before {
  content: "\e682";
}

.ph.ph-magnifying-glass:before, i.ph-magnifying-glass.icon-dead:before, i.ph-magnifying-glass.icon-alive:before, i.ph-magnifying-glass.icon-warn:before, i.ph-magnifying-glass.icon-sign-out:before, i.ph-magnifying-glass.icon-substratum:before, i.ph-magnifying-glass.icon-environment:before, i.ph-magnifying-glass.icon-user:before, i.ph-magnifying-glass.icon-person:before, i.ph-magnifying-glass.icon-terrain:before, i.ph-magnifying-glass.icon-taxon:before, i.ph-magnifying-glass.icon-unknown:before, i.ph-magnifying-glass.icon-nothing:before, i.ph-magnifying-glass.icon-sampling:before, i.ph-magnifying-glass.icon-isolation:before, i.ph-magnifying-glass.icon-strain:before, i.ph-magnifying-glass.icon-gps:before, i.ph-magnifying-glass.icon-location:before, i.ph-magnifying-glass.icon-add:before, i.ph-magnifying-glass.icon-left:before, i.ph-magnifying-glass.icon-dna:before, i.ph-magnifying-glass.icon-delete:before, i.ph-magnifying-glass.icon-edit:before, i.ph-magnifying-glass.icon-info:before {
  content: "\e30c";
}

.ph.ph-magnifying-glass-minus:before, i.ph-magnifying-glass-minus.icon-dead:before, i.ph-magnifying-glass-minus.icon-alive:before, i.ph-magnifying-glass-minus.icon-warn:before, i.ph-magnifying-glass-minus.icon-sign-out:before, i.ph-magnifying-glass-minus.icon-substratum:before, i.ph-magnifying-glass-minus.icon-environment:before, i.ph-magnifying-glass-minus.icon-user:before, i.ph-magnifying-glass-minus.icon-person:before, i.ph-magnifying-glass-minus.icon-terrain:before, i.ph-magnifying-glass-minus.icon-taxon:before, i.ph-magnifying-glass-minus.icon-unknown:before, i.ph-magnifying-glass-minus.icon-nothing:before, i.ph-magnifying-glass-minus.icon-sampling:before, i.ph-magnifying-glass-minus.icon-isolation:before, i.ph-magnifying-glass-minus.icon-strain:before, i.ph-magnifying-glass-minus.icon-gps:before, i.ph-magnifying-glass-minus.icon-location:before, i.ph-magnifying-glass-minus.icon-add:before, i.ph-magnifying-glass-minus.icon-left:before, i.ph-magnifying-glass-minus.icon-dna:before, i.ph-magnifying-glass-minus.icon-delete:before, i.ph-magnifying-glass-minus.icon-edit:before, i.ph-magnifying-glass-minus.icon-info:before {
  content: "\e30e";
}

.ph.ph-magnifying-glass-plus:before, i.ph-magnifying-glass-plus.icon-dead:before, i.ph-magnifying-glass-plus.icon-alive:before, i.ph-magnifying-glass-plus.icon-warn:before, i.ph-magnifying-glass-plus.icon-sign-out:before, i.ph-magnifying-glass-plus.icon-substratum:before, i.ph-magnifying-glass-plus.icon-environment:before, i.ph-magnifying-glass-plus.icon-user:before, i.ph-magnifying-glass-plus.icon-person:before, i.ph-magnifying-glass-plus.icon-terrain:before, i.ph-magnifying-glass-plus.icon-taxon:before, i.ph-magnifying-glass-plus.icon-unknown:before, i.ph-magnifying-glass-plus.icon-nothing:before, i.ph-magnifying-glass-plus.icon-sampling:before, i.ph-magnifying-glass-plus.icon-isolation:before, i.ph-magnifying-glass-plus.icon-strain:before, i.ph-magnifying-glass-plus.icon-gps:before, i.ph-magnifying-glass-plus.icon-location:before, i.ph-magnifying-glass-plus.icon-add:before, i.ph-magnifying-glass-plus.icon-left:before, i.ph-magnifying-glass-plus.icon-dna:before, i.ph-magnifying-glass-plus.icon-delete:before, i.ph-magnifying-glass-plus.icon-edit:before, i.ph-magnifying-glass-plus.icon-info:before {
  content: "\e310";
}

.ph.ph-mailbox:before, i.ph-mailbox.icon-dead:before, i.ph-mailbox.icon-alive:before, i.ph-mailbox.icon-warn:before, i.ph-mailbox.icon-sign-out:before, i.ph-mailbox.icon-substratum:before, i.ph-mailbox.icon-environment:before, i.ph-mailbox.icon-user:before, i.ph-mailbox.icon-person:before, i.ph-mailbox.icon-terrain:before, i.ph-mailbox.icon-taxon:before, i.ph-mailbox.icon-unknown:before, i.ph-mailbox.icon-nothing:before, i.ph-mailbox.icon-sampling:before, i.ph-mailbox.icon-isolation:before, i.ph-mailbox.icon-strain:before, i.ph-mailbox.icon-gps:before, i.ph-mailbox.icon-location:before, i.ph-mailbox.icon-add:before, i.ph-mailbox.icon-left:before, i.ph-mailbox.icon-dna:before, i.ph-mailbox.icon-delete:before, i.ph-mailbox.icon-edit:before, i.ph-mailbox.icon-info:before {
  content: "\ec1e";
}

.ph.ph-map-pin:before, i.ph-map-pin.icon-dead:before, i.ph-map-pin.icon-alive:before, i.ph-map-pin.icon-warn:before, i.ph-map-pin.icon-sign-out:before, i.ph-map-pin.icon-substratum:before, i.ph-map-pin.icon-environment:before, i.ph-map-pin.icon-user:before, i.ph-map-pin.icon-person:before, i.ph-map-pin.icon-terrain:before, i.ph-map-pin.icon-taxon:before, i.ph-map-pin.icon-unknown:before, i.ph-map-pin.icon-nothing:before, i.ph-map-pin.icon-sampling:before, i.ph-map-pin.icon-isolation:before, i.ph-map-pin.icon-strain:before, i.ph-map-pin.icon-gps:before, i.icon-location:before, i.ph-map-pin.icon-add:before, i.ph-map-pin.icon-left:before, i.ph-map-pin.icon-dna:before, i.ph-map-pin.icon-delete:before, i.ph-map-pin.icon-edit:before, i.ph-map-pin.icon-info:before {
  content: "\e316";
}

.ph.ph-map-pin-area:before, i.ph-map-pin-area.icon-dead:before, i.ph-map-pin-area.icon-alive:before, i.ph-map-pin-area.icon-warn:before, i.ph-map-pin-area.icon-sign-out:before, i.ph-map-pin-area.icon-substratum:before, i.ph-map-pin-area.icon-environment:before, i.ph-map-pin-area.icon-user:before, i.ph-map-pin-area.icon-person:before, i.ph-map-pin-area.icon-terrain:before, i.ph-map-pin-area.icon-taxon:before, i.ph-map-pin-area.icon-unknown:before, i.ph-map-pin-area.icon-nothing:before, i.ph-map-pin-area.icon-sampling:before, i.ph-map-pin-area.icon-isolation:before, i.ph-map-pin-area.icon-strain:before, i.ph-map-pin-area.icon-gps:before, i.ph-map-pin-area.icon-location:before, i.ph-map-pin-area.icon-add:before, i.ph-map-pin-area.icon-left:before, i.ph-map-pin-area.icon-dna:before, i.ph-map-pin-area.icon-delete:before, i.ph-map-pin-area.icon-edit:before, i.ph-map-pin-area.icon-info:before {
  content: "\ee3a";
}

.ph.ph-map-pin-line:before, i.ph-map-pin-line.icon-dead:before, i.ph-map-pin-line.icon-alive:before, i.ph-map-pin-line.icon-warn:before, i.ph-map-pin-line.icon-sign-out:before, i.ph-map-pin-line.icon-substratum:before, i.ph-map-pin-line.icon-environment:before, i.ph-map-pin-line.icon-user:before, i.ph-map-pin-line.icon-person:before, i.ph-map-pin-line.icon-terrain:before, i.ph-map-pin-line.icon-taxon:before, i.ph-map-pin-line.icon-unknown:before, i.ph-map-pin-line.icon-nothing:before, i.ph-map-pin-line.icon-sampling:before, i.ph-map-pin-line.icon-isolation:before, i.ph-map-pin-line.icon-strain:before, i.ph-map-pin-line.icon-gps:before, i.ph-map-pin-line.icon-location:before, i.ph-map-pin-line.icon-add:before, i.ph-map-pin-line.icon-left:before, i.ph-map-pin-line.icon-dna:before, i.ph-map-pin-line.icon-delete:before, i.ph-map-pin-line.icon-edit:before, i.ph-map-pin-line.icon-info:before {
  content: "\e318";
}

.ph.ph-map-pin-plus:before, i.ph-map-pin-plus.icon-dead:before, i.ph-map-pin-plus.icon-alive:before, i.ph-map-pin-plus.icon-warn:before, i.ph-map-pin-plus.icon-sign-out:before, i.ph-map-pin-plus.icon-substratum:before, i.ph-map-pin-plus.icon-environment:before, i.ph-map-pin-plus.icon-user:before, i.ph-map-pin-plus.icon-person:before, i.ph-map-pin-plus.icon-terrain:before, i.ph-map-pin-plus.icon-taxon:before, i.ph-map-pin-plus.icon-unknown:before, i.ph-map-pin-plus.icon-nothing:before, i.ph-map-pin-plus.icon-sampling:before, i.ph-map-pin-plus.icon-isolation:before, i.ph-map-pin-plus.icon-strain:before, i.ph-map-pin-plus.icon-gps:before, i.ph-map-pin-plus.icon-location:before, i.ph-map-pin-plus.icon-add:before, i.ph-map-pin-plus.icon-left:before, i.ph-map-pin-plus.icon-dna:before, i.ph-map-pin-plus.icon-delete:before, i.ph-map-pin-plus.icon-edit:before, i.ph-map-pin-plus.icon-info:before {
  content: "\e314";
}

.ph.ph-map-pin-simple:before, i.ph-map-pin-simple.icon-dead:before, i.ph-map-pin-simple.icon-alive:before, i.ph-map-pin-simple.icon-warn:before, i.ph-map-pin-simple.icon-sign-out:before, i.ph-map-pin-simple.icon-substratum:before, i.ph-map-pin-simple.icon-environment:before, i.ph-map-pin-simple.icon-user:before, i.ph-map-pin-simple.icon-person:before, i.ph-map-pin-simple.icon-terrain:before, i.ph-map-pin-simple.icon-taxon:before, i.ph-map-pin-simple.icon-unknown:before, i.ph-map-pin-simple.icon-nothing:before, i.ph-map-pin-simple.icon-sampling:before, i.ph-map-pin-simple.icon-isolation:before, i.ph-map-pin-simple.icon-strain:before, i.ph-map-pin-simple.icon-gps:before, i.ph-map-pin-simple.icon-location:before, i.ph-map-pin-simple.icon-add:before, i.ph-map-pin-simple.icon-left:before, i.ph-map-pin-simple.icon-dna:before, i.ph-map-pin-simple.icon-delete:before, i.ph-map-pin-simple.icon-edit:before, i.ph-map-pin-simple.icon-info:before {
  content: "\ee3e";
}

.ph.ph-map-pin-simple-area:before, i.ph-map-pin-simple-area.icon-dead:before, i.ph-map-pin-simple-area.icon-alive:before, i.ph-map-pin-simple-area.icon-warn:before, i.ph-map-pin-simple-area.icon-sign-out:before, i.ph-map-pin-simple-area.icon-substratum:before, i.ph-map-pin-simple-area.icon-environment:before, i.ph-map-pin-simple-area.icon-user:before, i.ph-map-pin-simple-area.icon-person:before, i.ph-map-pin-simple-area.icon-terrain:before, i.ph-map-pin-simple-area.icon-taxon:before, i.ph-map-pin-simple-area.icon-unknown:before, i.ph-map-pin-simple-area.icon-nothing:before, i.ph-map-pin-simple-area.icon-sampling:before, i.ph-map-pin-simple-area.icon-isolation:before, i.ph-map-pin-simple-area.icon-strain:before, i.ph-map-pin-simple-area.icon-gps:before, i.ph-map-pin-simple-area.icon-location:before, i.ph-map-pin-simple-area.icon-add:before, i.ph-map-pin-simple-area.icon-left:before, i.ph-map-pin-simple-area.icon-dna:before, i.ph-map-pin-simple-area.icon-delete:before, i.ph-map-pin-simple-area.icon-edit:before, i.ph-map-pin-simple-area.icon-info:before {
  content: "\ee3c";
}

.ph.ph-map-pin-simple-line:before, i.ph-map-pin-simple-line.icon-dead:before, i.ph-map-pin-simple-line.icon-alive:before, i.ph-map-pin-simple-line.icon-warn:before, i.ph-map-pin-simple-line.icon-sign-out:before, i.ph-map-pin-simple-line.icon-substratum:before, i.ph-map-pin-simple-line.icon-environment:before, i.ph-map-pin-simple-line.icon-user:before, i.ph-map-pin-simple-line.icon-person:before, i.ph-map-pin-simple-line.icon-terrain:before, i.ph-map-pin-simple-line.icon-taxon:before, i.ph-map-pin-simple-line.icon-unknown:before, i.ph-map-pin-simple-line.icon-nothing:before, i.ph-map-pin-simple-line.icon-sampling:before, i.ph-map-pin-simple-line.icon-isolation:before, i.ph-map-pin-simple-line.icon-strain:before, i.ph-map-pin-simple-line.icon-gps:before, i.ph-map-pin-simple-line.icon-location:before, i.ph-map-pin-simple-line.icon-add:before, i.ph-map-pin-simple-line.icon-left:before, i.ph-map-pin-simple-line.icon-dna:before, i.ph-map-pin-simple-line.icon-delete:before, i.ph-map-pin-simple-line.icon-edit:before, i.ph-map-pin-simple-line.icon-info:before {
  content: "\ee38";
}

.ph.ph-map-trifold:before, i.ph-map-trifold.icon-dead:before, i.ph-map-trifold.icon-alive:before, i.ph-map-trifold.icon-warn:before, i.ph-map-trifold.icon-sign-out:before, i.ph-map-trifold.icon-substratum:before, i.ph-map-trifold.icon-environment:before, i.ph-map-trifold.icon-user:before, i.ph-map-trifold.icon-person:before, i.ph-map-trifold.icon-terrain:before, i.ph-map-trifold.icon-taxon:before, i.ph-map-trifold.icon-unknown:before, i.ph-map-trifold.icon-nothing:before, i.ph-map-trifold.icon-sampling:before, i.ph-map-trifold.icon-isolation:before, i.ph-map-trifold.icon-strain:before, i.ph-map-trifold.icon-gps:before, i.ph-map-trifold.icon-location:before, i.ph-map-trifold.icon-add:before, i.ph-map-trifold.icon-left:before, i.ph-map-trifold.icon-dna:before, i.ph-map-trifold.icon-delete:before, i.ph-map-trifold.icon-edit:before, i.ph-map-trifold.icon-info:before {
  content: "\e31a";
}

.ph.ph-markdown-logo:before, i.ph-markdown-logo.icon-dead:before, i.ph-markdown-logo.icon-alive:before, i.ph-markdown-logo.icon-warn:before, i.ph-markdown-logo.icon-sign-out:before, i.ph-markdown-logo.icon-substratum:before, i.ph-markdown-logo.icon-environment:before, i.ph-markdown-logo.icon-user:before, i.ph-markdown-logo.icon-person:before, i.ph-markdown-logo.icon-terrain:before, i.ph-markdown-logo.icon-taxon:before, i.ph-markdown-logo.icon-unknown:before, i.ph-markdown-logo.icon-nothing:before, i.ph-markdown-logo.icon-sampling:before, i.ph-markdown-logo.icon-isolation:before, i.ph-markdown-logo.icon-strain:before, i.ph-markdown-logo.icon-gps:before, i.ph-markdown-logo.icon-location:before, i.ph-markdown-logo.icon-add:before, i.ph-markdown-logo.icon-left:before, i.ph-markdown-logo.icon-dna:before, i.ph-markdown-logo.icon-delete:before, i.ph-markdown-logo.icon-edit:before, i.ph-markdown-logo.icon-info:before {
  content: "\e508";
}

.ph.ph-marker-circle:before, i.ph-marker-circle.icon-dead:before, i.ph-marker-circle.icon-alive:before, i.ph-marker-circle.icon-warn:before, i.ph-marker-circle.icon-sign-out:before, i.ph-marker-circle.icon-substratum:before, i.ph-marker-circle.icon-environment:before, i.ph-marker-circle.icon-user:before, i.ph-marker-circle.icon-person:before, i.ph-marker-circle.icon-terrain:before, i.ph-marker-circle.icon-taxon:before, i.ph-marker-circle.icon-unknown:before, i.ph-marker-circle.icon-nothing:before, i.ph-marker-circle.icon-sampling:before, i.ph-marker-circle.icon-isolation:before, i.ph-marker-circle.icon-strain:before, i.ph-marker-circle.icon-gps:before, i.ph-marker-circle.icon-location:before, i.ph-marker-circle.icon-add:before, i.ph-marker-circle.icon-left:before, i.ph-marker-circle.icon-dna:before, i.ph-marker-circle.icon-delete:before, i.ph-marker-circle.icon-edit:before, i.ph-marker-circle.icon-info:before {
  content: "\e640";
}

.ph.ph-martini:before, i.ph-martini.icon-dead:before, i.ph-martini.icon-alive:before, i.ph-martini.icon-warn:before, i.ph-martini.icon-sign-out:before, i.ph-martini.icon-substratum:before, i.ph-martini.icon-environment:before, i.ph-martini.icon-user:before, i.ph-martini.icon-person:before, i.ph-martini.icon-terrain:before, i.ph-martini.icon-taxon:before, i.ph-martini.icon-unknown:before, i.ph-martini.icon-nothing:before, i.ph-martini.icon-sampling:before, i.ph-martini.icon-isolation:before, i.ph-martini.icon-strain:before, i.ph-martini.icon-gps:before, i.ph-martini.icon-location:before, i.ph-martini.icon-add:before, i.ph-martini.icon-left:before, i.ph-martini.icon-dna:before, i.ph-martini.icon-delete:before, i.ph-martini.icon-edit:before, i.ph-martini.icon-info:before {
  content: "\e31c";
}

.ph.ph-mask-happy:before, i.ph-mask-happy.icon-dead:before, i.ph-mask-happy.icon-alive:before, i.ph-mask-happy.icon-warn:before, i.ph-mask-happy.icon-sign-out:before, i.ph-mask-happy.icon-substratum:before, i.ph-mask-happy.icon-environment:before, i.ph-mask-happy.icon-user:before, i.ph-mask-happy.icon-person:before, i.ph-mask-happy.icon-terrain:before, i.ph-mask-happy.icon-taxon:before, i.ph-mask-happy.icon-unknown:before, i.ph-mask-happy.icon-nothing:before, i.ph-mask-happy.icon-sampling:before, i.ph-mask-happy.icon-isolation:before, i.ph-mask-happy.icon-strain:before, i.ph-mask-happy.icon-gps:before, i.ph-mask-happy.icon-location:before, i.ph-mask-happy.icon-add:before, i.ph-mask-happy.icon-left:before, i.ph-mask-happy.icon-dna:before, i.ph-mask-happy.icon-delete:before, i.ph-mask-happy.icon-edit:before, i.ph-mask-happy.icon-info:before {
  content: "\e9f4";
}

.ph.ph-mask-sad:before, i.ph-mask-sad.icon-dead:before, i.ph-mask-sad.icon-alive:before, i.ph-mask-sad.icon-warn:before, i.ph-mask-sad.icon-sign-out:before, i.ph-mask-sad.icon-substratum:before, i.ph-mask-sad.icon-environment:before, i.ph-mask-sad.icon-user:before, i.ph-mask-sad.icon-person:before, i.ph-mask-sad.icon-terrain:before, i.ph-mask-sad.icon-taxon:before, i.ph-mask-sad.icon-unknown:before, i.ph-mask-sad.icon-nothing:before, i.ph-mask-sad.icon-sampling:before, i.ph-mask-sad.icon-isolation:before, i.ph-mask-sad.icon-strain:before, i.ph-mask-sad.icon-gps:before, i.ph-mask-sad.icon-location:before, i.ph-mask-sad.icon-add:before, i.ph-mask-sad.icon-left:before, i.ph-mask-sad.icon-dna:before, i.ph-mask-sad.icon-delete:before, i.ph-mask-sad.icon-edit:before, i.ph-mask-sad.icon-info:before {
  content: "\eb9e";
}

.ph.ph-mastodon-logo:before, i.ph-mastodon-logo.icon-dead:before, i.ph-mastodon-logo.icon-alive:before, i.ph-mastodon-logo.icon-warn:before, i.ph-mastodon-logo.icon-sign-out:before, i.ph-mastodon-logo.icon-substratum:before, i.ph-mastodon-logo.icon-environment:before, i.ph-mastodon-logo.icon-user:before, i.ph-mastodon-logo.icon-person:before, i.ph-mastodon-logo.icon-terrain:before, i.ph-mastodon-logo.icon-taxon:before, i.ph-mastodon-logo.icon-unknown:before, i.ph-mastodon-logo.icon-nothing:before, i.ph-mastodon-logo.icon-sampling:before, i.ph-mastodon-logo.icon-isolation:before, i.ph-mastodon-logo.icon-strain:before, i.ph-mastodon-logo.icon-gps:before, i.ph-mastodon-logo.icon-location:before, i.ph-mastodon-logo.icon-add:before, i.ph-mastodon-logo.icon-left:before, i.ph-mastodon-logo.icon-dna:before, i.ph-mastodon-logo.icon-delete:before, i.ph-mastodon-logo.icon-edit:before, i.ph-mastodon-logo.icon-info:before {
  content: "\ed68";
}

.ph.ph-math-operations:before, i.ph-math-operations.icon-dead:before, i.ph-math-operations.icon-alive:before, i.ph-math-operations.icon-warn:before, i.ph-math-operations.icon-sign-out:before, i.ph-math-operations.icon-substratum:before, i.ph-math-operations.icon-environment:before, i.ph-math-operations.icon-user:before, i.ph-math-operations.icon-person:before, i.ph-math-operations.icon-terrain:before, i.ph-math-operations.icon-taxon:before, i.ph-math-operations.icon-unknown:before, i.ph-math-operations.icon-nothing:before, i.ph-math-operations.icon-sampling:before, i.ph-math-operations.icon-isolation:before, i.ph-math-operations.icon-strain:before, i.ph-math-operations.icon-gps:before, i.ph-math-operations.icon-location:before, i.ph-math-operations.icon-add:before, i.ph-math-operations.icon-left:before, i.ph-math-operations.icon-dna:before, i.ph-math-operations.icon-delete:before, i.ph-math-operations.icon-edit:before, i.ph-math-operations.icon-info:before {
  content: "\e31e";
}

.ph.ph-matrix-logo:before, i.ph-matrix-logo.icon-dead:before, i.ph-matrix-logo.icon-alive:before, i.ph-matrix-logo.icon-warn:before, i.ph-matrix-logo.icon-sign-out:before, i.ph-matrix-logo.icon-substratum:before, i.ph-matrix-logo.icon-environment:before, i.ph-matrix-logo.icon-user:before, i.ph-matrix-logo.icon-person:before, i.ph-matrix-logo.icon-terrain:before, i.ph-matrix-logo.icon-taxon:before, i.ph-matrix-logo.icon-unknown:before, i.ph-matrix-logo.icon-nothing:before, i.ph-matrix-logo.icon-sampling:before, i.ph-matrix-logo.icon-isolation:before, i.ph-matrix-logo.icon-strain:before, i.ph-matrix-logo.icon-gps:before, i.ph-matrix-logo.icon-location:before, i.ph-matrix-logo.icon-add:before, i.ph-matrix-logo.icon-left:before, i.ph-matrix-logo.icon-dna:before, i.ph-matrix-logo.icon-delete:before, i.ph-matrix-logo.icon-edit:before, i.ph-matrix-logo.icon-info:before {
  content: "\ed64";
}

.ph.ph-medal:before, i.ph-medal.icon-dead:before, i.ph-medal.icon-alive:before, i.ph-medal.icon-warn:before, i.ph-medal.icon-sign-out:before, i.ph-medal.icon-substratum:before, i.ph-medal.icon-environment:before, i.ph-medal.icon-user:before, i.ph-medal.icon-person:before, i.ph-medal.icon-terrain:before, i.ph-medal.icon-taxon:before, i.ph-medal.icon-unknown:before, i.ph-medal.icon-nothing:before, i.ph-medal.icon-sampling:before, i.ph-medal.icon-isolation:before, i.ph-medal.icon-strain:before, i.ph-medal.icon-gps:before, i.ph-medal.icon-location:before, i.ph-medal.icon-add:before, i.ph-medal.icon-left:before, i.ph-medal.icon-dna:before, i.ph-medal.icon-delete:before, i.ph-medal.icon-edit:before, i.ph-medal.icon-info:before {
  content: "\e320";
}

.ph.ph-medal-military:before, i.ph-medal-military.icon-dead:before, i.ph-medal-military.icon-alive:before, i.ph-medal-military.icon-warn:before, i.ph-medal-military.icon-sign-out:before, i.ph-medal-military.icon-substratum:before, i.ph-medal-military.icon-environment:before, i.ph-medal-military.icon-user:before, i.ph-medal-military.icon-person:before, i.ph-medal-military.icon-terrain:before, i.ph-medal-military.icon-taxon:before, i.ph-medal-military.icon-unknown:before, i.ph-medal-military.icon-nothing:before, i.ph-medal-military.icon-sampling:before, i.ph-medal-military.icon-isolation:before, i.ph-medal-military.icon-strain:before, i.ph-medal-military.icon-gps:before, i.ph-medal-military.icon-location:before, i.ph-medal-military.icon-add:before, i.ph-medal-military.icon-left:before, i.ph-medal-military.icon-dna:before, i.ph-medal-military.icon-delete:before, i.ph-medal-military.icon-edit:before, i.ph-medal-military.icon-info:before {
  content: "\ecfc";
}

.ph.ph-medium-logo:before, i.ph-medium-logo.icon-dead:before, i.ph-medium-logo.icon-alive:before, i.ph-medium-logo.icon-warn:before, i.ph-medium-logo.icon-sign-out:before, i.ph-medium-logo.icon-substratum:before, i.ph-medium-logo.icon-environment:before, i.ph-medium-logo.icon-user:before, i.ph-medium-logo.icon-person:before, i.ph-medium-logo.icon-terrain:before, i.ph-medium-logo.icon-taxon:before, i.ph-medium-logo.icon-unknown:before, i.ph-medium-logo.icon-nothing:before, i.ph-medium-logo.icon-sampling:before, i.ph-medium-logo.icon-isolation:before, i.ph-medium-logo.icon-strain:before, i.ph-medium-logo.icon-gps:before, i.ph-medium-logo.icon-location:before, i.ph-medium-logo.icon-add:before, i.ph-medium-logo.icon-left:before, i.ph-medium-logo.icon-dna:before, i.ph-medium-logo.icon-delete:before, i.ph-medium-logo.icon-edit:before, i.ph-medium-logo.icon-info:before {
  content: "\e322";
}

.ph.ph-megaphone:before, i.ph-megaphone.icon-dead:before, i.ph-megaphone.icon-alive:before, i.ph-megaphone.icon-warn:before, i.ph-megaphone.icon-sign-out:before, i.ph-megaphone.icon-substratum:before, i.ph-megaphone.icon-environment:before, i.ph-megaphone.icon-user:before, i.ph-megaphone.icon-person:before, i.ph-megaphone.icon-terrain:before, i.ph-megaphone.icon-taxon:before, i.ph-megaphone.icon-unknown:before, i.ph-megaphone.icon-nothing:before, i.ph-megaphone.icon-sampling:before, i.ph-megaphone.icon-isolation:before, i.ph-megaphone.icon-strain:before, i.ph-megaphone.icon-gps:before, i.ph-megaphone.icon-location:before, i.ph-megaphone.icon-add:before, i.ph-megaphone.icon-left:before, i.ph-megaphone.icon-dna:before, i.ph-megaphone.icon-delete:before, i.ph-megaphone.icon-edit:before, i.ph-megaphone.icon-info:before {
  content: "\e324";
}

.ph.ph-megaphone-simple:before, i.ph-megaphone-simple.icon-dead:before, i.ph-megaphone-simple.icon-alive:before, i.ph-megaphone-simple.icon-warn:before, i.ph-megaphone-simple.icon-sign-out:before, i.ph-megaphone-simple.icon-substratum:before, i.ph-megaphone-simple.icon-environment:before, i.ph-megaphone-simple.icon-user:before, i.ph-megaphone-simple.icon-person:before, i.ph-megaphone-simple.icon-terrain:before, i.ph-megaphone-simple.icon-taxon:before, i.ph-megaphone-simple.icon-unknown:before, i.ph-megaphone-simple.icon-nothing:before, i.ph-megaphone-simple.icon-sampling:before, i.ph-megaphone-simple.icon-isolation:before, i.ph-megaphone-simple.icon-strain:before, i.ph-megaphone-simple.icon-gps:before, i.ph-megaphone-simple.icon-location:before, i.ph-megaphone-simple.icon-add:before, i.ph-megaphone-simple.icon-left:before, i.ph-megaphone-simple.icon-dna:before, i.ph-megaphone-simple.icon-delete:before, i.ph-megaphone-simple.icon-edit:before, i.ph-megaphone-simple.icon-info:before {
  content: "\e642";
}

.ph.ph-member-of:before, i.ph-member-of.icon-dead:before, i.ph-member-of.icon-alive:before, i.ph-member-of.icon-warn:before, i.ph-member-of.icon-sign-out:before, i.ph-member-of.icon-substratum:before, i.ph-member-of.icon-environment:before, i.ph-member-of.icon-user:before, i.ph-member-of.icon-person:before, i.ph-member-of.icon-terrain:before, i.ph-member-of.icon-taxon:before, i.ph-member-of.icon-unknown:before, i.ph-member-of.icon-nothing:before, i.ph-member-of.icon-sampling:before, i.ph-member-of.icon-isolation:before, i.ph-member-of.icon-strain:before, i.ph-member-of.icon-gps:before, i.ph-member-of.icon-location:before, i.ph-member-of.icon-add:before, i.ph-member-of.icon-left:before, i.ph-member-of.icon-dna:before, i.ph-member-of.icon-delete:before, i.ph-member-of.icon-edit:before, i.ph-member-of.icon-info:before {
  content: "\edc2";
}

.ph.ph-memory:before, i.ph-memory.icon-dead:before, i.ph-memory.icon-alive:before, i.ph-memory.icon-warn:before, i.ph-memory.icon-sign-out:before, i.ph-memory.icon-substratum:before, i.ph-memory.icon-environment:before, i.ph-memory.icon-user:before, i.ph-memory.icon-person:before, i.ph-memory.icon-terrain:before, i.ph-memory.icon-taxon:before, i.ph-memory.icon-unknown:before, i.ph-memory.icon-nothing:before, i.ph-memory.icon-sampling:before, i.ph-memory.icon-isolation:before, i.ph-memory.icon-strain:before, i.ph-memory.icon-gps:before, i.ph-memory.icon-location:before, i.ph-memory.icon-add:before, i.ph-memory.icon-left:before, i.ph-memory.icon-dna:before, i.ph-memory.icon-delete:before, i.ph-memory.icon-edit:before, i.ph-memory.icon-info:before {
  content: "\e9c4";
}

.ph.ph-messenger-logo:before, i.ph-messenger-logo.icon-dead:before, i.ph-messenger-logo.icon-alive:before, i.ph-messenger-logo.icon-warn:before, i.ph-messenger-logo.icon-sign-out:before, i.ph-messenger-logo.icon-substratum:before, i.ph-messenger-logo.icon-environment:before, i.ph-messenger-logo.icon-user:before, i.ph-messenger-logo.icon-person:before, i.ph-messenger-logo.icon-terrain:before, i.ph-messenger-logo.icon-taxon:before, i.ph-messenger-logo.icon-unknown:before, i.ph-messenger-logo.icon-nothing:before, i.ph-messenger-logo.icon-sampling:before, i.ph-messenger-logo.icon-isolation:before, i.ph-messenger-logo.icon-strain:before, i.ph-messenger-logo.icon-gps:before, i.ph-messenger-logo.icon-location:before, i.ph-messenger-logo.icon-add:before, i.ph-messenger-logo.icon-left:before, i.ph-messenger-logo.icon-dna:before, i.ph-messenger-logo.icon-delete:before, i.ph-messenger-logo.icon-edit:before, i.ph-messenger-logo.icon-info:before {
  content: "\e6d8";
}

.ph.ph-meta-logo:before, i.ph-meta-logo.icon-dead:before, i.ph-meta-logo.icon-alive:before, i.ph-meta-logo.icon-warn:before, i.ph-meta-logo.icon-sign-out:before, i.ph-meta-logo.icon-substratum:before, i.ph-meta-logo.icon-environment:before, i.ph-meta-logo.icon-user:before, i.ph-meta-logo.icon-person:before, i.ph-meta-logo.icon-terrain:before, i.ph-meta-logo.icon-taxon:before, i.ph-meta-logo.icon-unknown:before, i.ph-meta-logo.icon-nothing:before, i.ph-meta-logo.icon-sampling:before, i.ph-meta-logo.icon-isolation:before, i.ph-meta-logo.icon-strain:before, i.ph-meta-logo.icon-gps:before, i.ph-meta-logo.icon-location:before, i.ph-meta-logo.icon-add:before, i.ph-meta-logo.icon-left:before, i.ph-meta-logo.icon-dna:before, i.ph-meta-logo.icon-delete:before, i.ph-meta-logo.icon-edit:before, i.ph-meta-logo.icon-info:before {
  content: "\ed02";
}

.ph.ph-meteor:before, i.ph-meteor.icon-dead:before, i.ph-meteor.icon-alive:before, i.ph-meteor.icon-warn:before, i.ph-meteor.icon-sign-out:before, i.ph-meteor.icon-substratum:before, i.ph-meteor.icon-environment:before, i.ph-meteor.icon-user:before, i.ph-meteor.icon-person:before, i.ph-meteor.icon-terrain:before, i.ph-meteor.icon-taxon:before, i.ph-meteor.icon-unknown:before, i.ph-meteor.icon-nothing:before, i.ph-meteor.icon-sampling:before, i.ph-meteor.icon-isolation:before, i.ph-meteor.icon-strain:before, i.ph-meteor.icon-gps:before, i.ph-meteor.icon-location:before, i.ph-meteor.icon-add:before, i.ph-meteor.icon-left:before, i.ph-meteor.icon-dna:before, i.ph-meteor.icon-delete:before, i.ph-meteor.icon-edit:before, i.ph-meteor.icon-info:before {
  content: "\e9ba";
}

.ph.ph-metronome:before, i.ph-metronome.icon-dead:before, i.ph-metronome.icon-alive:before, i.ph-metronome.icon-warn:before, i.ph-metronome.icon-sign-out:before, i.ph-metronome.icon-substratum:before, i.ph-metronome.icon-environment:before, i.ph-metronome.icon-user:before, i.ph-metronome.icon-person:before, i.ph-metronome.icon-terrain:before, i.ph-metronome.icon-taxon:before, i.ph-metronome.icon-unknown:before, i.ph-metronome.icon-nothing:before, i.ph-metronome.icon-sampling:before, i.ph-metronome.icon-isolation:before, i.ph-metronome.icon-strain:before, i.ph-metronome.icon-gps:before, i.ph-metronome.icon-location:before, i.ph-metronome.icon-add:before, i.ph-metronome.icon-left:before, i.ph-metronome.icon-dna:before, i.ph-metronome.icon-delete:before, i.ph-metronome.icon-edit:before, i.ph-metronome.icon-info:before {
  content: "\ec8e";
}

.ph.ph-microphone:before, i.ph-microphone.icon-dead:before, i.ph-microphone.icon-alive:before, i.ph-microphone.icon-warn:before, i.ph-microphone.icon-sign-out:before, i.ph-microphone.icon-substratum:before, i.ph-microphone.icon-environment:before, i.ph-microphone.icon-user:before, i.ph-microphone.icon-person:before, i.ph-microphone.icon-terrain:before, i.ph-microphone.icon-taxon:before, i.ph-microphone.icon-unknown:before, i.ph-microphone.icon-nothing:before, i.ph-microphone.icon-sampling:before, i.ph-microphone.icon-isolation:before, i.ph-microphone.icon-strain:before, i.ph-microphone.icon-gps:before, i.ph-microphone.icon-location:before, i.ph-microphone.icon-add:before, i.ph-microphone.icon-left:before, i.ph-microphone.icon-dna:before, i.ph-microphone.icon-delete:before, i.ph-microphone.icon-edit:before, i.ph-microphone.icon-info:before {
  content: "\e326";
}

.ph.ph-microphone-slash:before, i.ph-microphone-slash.icon-dead:before, i.ph-microphone-slash.icon-alive:before, i.ph-microphone-slash.icon-warn:before, i.ph-microphone-slash.icon-sign-out:before, i.ph-microphone-slash.icon-substratum:before, i.ph-microphone-slash.icon-environment:before, i.ph-microphone-slash.icon-user:before, i.ph-microphone-slash.icon-person:before, i.ph-microphone-slash.icon-terrain:before, i.ph-microphone-slash.icon-taxon:before, i.ph-microphone-slash.icon-unknown:before, i.ph-microphone-slash.icon-nothing:before, i.ph-microphone-slash.icon-sampling:before, i.ph-microphone-slash.icon-isolation:before, i.ph-microphone-slash.icon-strain:before, i.ph-microphone-slash.icon-gps:before, i.ph-microphone-slash.icon-location:before, i.ph-microphone-slash.icon-add:before, i.ph-microphone-slash.icon-left:before, i.ph-microphone-slash.icon-dna:before, i.ph-microphone-slash.icon-delete:before, i.ph-microphone-slash.icon-edit:before, i.ph-microphone-slash.icon-info:before {
  content: "\e328";
}

.ph.ph-microphone-stage:before, i.ph-microphone-stage.icon-dead:before, i.ph-microphone-stage.icon-alive:before, i.ph-microphone-stage.icon-warn:before, i.ph-microphone-stage.icon-sign-out:before, i.ph-microphone-stage.icon-substratum:before, i.ph-microphone-stage.icon-environment:before, i.ph-microphone-stage.icon-user:before, i.ph-microphone-stage.icon-person:before, i.ph-microphone-stage.icon-terrain:before, i.ph-microphone-stage.icon-taxon:before, i.ph-microphone-stage.icon-unknown:before, i.ph-microphone-stage.icon-nothing:before, i.ph-microphone-stage.icon-sampling:before, i.ph-microphone-stage.icon-isolation:before, i.ph-microphone-stage.icon-strain:before, i.ph-microphone-stage.icon-gps:before, i.ph-microphone-stage.icon-location:before, i.ph-microphone-stage.icon-add:before, i.ph-microphone-stage.icon-left:before, i.ph-microphone-stage.icon-dna:before, i.ph-microphone-stage.icon-delete:before, i.ph-microphone-stage.icon-edit:before, i.ph-microphone-stage.icon-info:before {
  content: "\e75c";
}

.ph.ph-microscope:before, i.ph-microscope.icon-dead:before, i.ph-microscope.icon-alive:before, i.ph-microscope.icon-warn:before, i.ph-microscope.icon-sign-out:before, i.ph-microscope.icon-substratum:before, i.ph-microscope.icon-environment:before, i.ph-microscope.icon-user:before, i.ph-microscope.icon-person:before, i.ph-microscope.icon-terrain:before, i.ph-microscope.icon-taxon:before, i.ph-microscope.icon-unknown:before, i.ph-microscope.icon-nothing:before, i.ph-microscope.icon-sampling:before, i.ph-microscope.icon-isolation:before, i.ph-microscope.icon-strain:before, i.ph-microscope.icon-gps:before, i.ph-microscope.icon-location:before, i.ph-microscope.icon-add:before, i.ph-microscope.icon-left:before, i.ph-microscope.icon-dna:before, i.ph-microscope.icon-delete:before, i.ph-microscope.icon-edit:before, i.ph-microscope.icon-info:before {
  content: "\ec7a";
}

.ph.ph-microsoft-excel-logo:before, i.ph-microsoft-excel-logo.icon-dead:before, i.ph-microsoft-excel-logo.icon-alive:before, i.ph-microsoft-excel-logo.icon-warn:before, i.ph-microsoft-excel-logo.icon-sign-out:before, i.ph-microsoft-excel-logo.icon-substratum:before, i.ph-microsoft-excel-logo.icon-environment:before, i.ph-microsoft-excel-logo.icon-user:before, i.ph-microsoft-excel-logo.icon-person:before, i.ph-microsoft-excel-logo.icon-terrain:before, i.ph-microsoft-excel-logo.icon-taxon:before, i.ph-microsoft-excel-logo.icon-unknown:before, i.ph-microsoft-excel-logo.icon-nothing:before, i.ph-microsoft-excel-logo.icon-sampling:before, i.ph-microsoft-excel-logo.icon-isolation:before, i.ph-microsoft-excel-logo.icon-strain:before, i.ph-microsoft-excel-logo.icon-gps:before, i.ph-microsoft-excel-logo.icon-location:before, i.ph-microsoft-excel-logo.icon-add:before, i.ph-microsoft-excel-logo.icon-left:before, i.ph-microsoft-excel-logo.icon-dna:before, i.ph-microsoft-excel-logo.icon-delete:before, i.ph-microsoft-excel-logo.icon-edit:before, i.ph-microsoft-excel-logo.icon-info:before {
  content: "\eb6c";
}

.ph.ph-microsoft-outlook-logo:before, i.ph-microsoft-outlook-logo.icon-dead:before, i.ph-microsoft-outlook-logo.icon-alive:before, i.ph-microsoft-outlook-logo.icon-warn:before, i.ph-microsoft-outlook-logo.icon-sign-out:before, i.ph-microsoft-outlook-logo.icon-substratum:before, i.ph-microsoft-outlook-logo.icon-environment:before, i.ph-microsoft-outlook-logo.icon-user:before, i.ph-microsoft-outlook-logo.icon-person:before, i.ph-microsoft-outlook-logo.icon-terrain:before, i.ph-microsoft-outlook-logo.icon-taxon:before, i.ph-microsoft-outlook-logo.icon-unknown:before, i.ph-microsoft-outlook-logo.icon-nothing:before, i.ph-microsoft-outlook-logo.icon-sampling:before, i.ph-microsoft-outlook-logo.icon-isolation:before, i.ph-microsoft-outlook-logo.icon-strain:before, i.ph-microsoft-outlook-logo.icon-gps:before, i.ph-microsoft-outlook-logo.icon-location:before, i.ph-microsoft-outlook-logo.icon-add:before, i.ph-microsoft-outlook-logo.icon-left:before, i.ph-microsoft-outlook-logo.icon-dna:before, i.ph-microsoft-outlook-logo.icon-delete:before, i.ph-microsoft-outlook-logo.icon-edit:before, i.ph-microsoft-outlook-logo.icon-info:before {
  content: "\eb70";
}

.ph.ph-microsoft-powerpoint-logo:before, i.ph-microsoft-powerpoint-logo.icon-dead:before, i.ph-microsoft-powerpoint-logo.icon-alive:before, i.ph-microsoft-powerpoint-logo.icon-warn:before, i.ph-microsoft-powerpoint-logo.icon-sign-out:before, i.ph-microsoft-powerpoint-logo.icon-substratum:before, i.ph-microsoft-powerpoint-logo.icon-environment:before, i.ph-microsoft-powerpoint-logo.icon-user:before, i.ph-microsoft-powerpoint-logo.icon-person:before, i.ph-microsoft-powerpoint-logo.icon-terrain:before, i.ph-microsoft-powerpoint-logo.icon-taxon:before, i.ph-microsoft-powerpoint-logo.icon-unknown:before, i.ph-microsoft-powerpoint-logo.icon-nothing:before, i.ph-microsoft-powerpoint-logo.icon-sampling:before, i.ph-microsoft-powerpoint-logo.icon-isolation:before, i.ph-microsoft-powerpoint-logo.icon-strain:before, i.ph-microsoft-powerpoint-logo.icon-gps:before, i.ph-microsoft-powerpoint-logo.icon-location:before, i.ph-microsoft-powerpoint-logo.icon-add:before, i.ph-microsoft-powerpoint-logo.icon-left:before, i.ph-microsoft-powerpoint-logo.icon-dna:before, i.ph-microsoft-powerpoint-logo.icon-delete:before, i.ph-microsoft-powerpoint-logo.icon-edit:before, i.ph-microsoft-powerpoint-logo.icon-info:before {
  content: "\eace";
}

.ph.ph-microsoft-teams-logo:before, i.ph-microsoft-teams-logo.icon-dead:before, i.ph-microsoft-teams-logo.icon-alive:before, i.ph-microsoft-teams-logo.icon-warn:before, i.ph-microsoft-teams-logo.icon-sign-out:before, i.ph-microsoft-teams-logo.icon-substratum:before, i.ph-microsoft-teams-logo.icon-environment:before, i.ph-microsoft-teams-logo.icon-user:before, i.ph-microsoft-teams-logo.icon-person:before, i.ph-microsoft-teams-logo.icon-terrain:before, i.ph-microsoft-teams-logo.icon-taxon:before, i.ph-microsoft-teams-logo.icon-unknown:before, i.ph-microsoft-teams-logo.icon-nothing:before, i.ph-microsoft-teams-logo.icon-sampling:before, i.ph-microsoft-teams-logo.icon-isolation:before, i.ph-microsoft-teams-logo.icon-strain:before, i.ph-microsoft-teams-logo.icon-gps:before, i.ph-microsoft-teams-logo.icon-location:before, i.ph-microsoft-teams-logo.icon-add:before, i.ph-microsoft-teams-logo.icon-left:before, i.ph-microsoft-teams-logo.icon-dna:before, i.ph-microsoft-teams-logo.icon-delete:before, i.ph-microsoft-teams-logo.icon-edit:before, i.ph-microsoft-teams-logo.icon-info:before {
  content: "\eb66";
}

.ph.ph-microsoft-word-logo:before, i.ph-microsoft-word-logo.icon-dead:before, i.ph-microsoft-word-logo.icon-alive:before, i.ph-microsoft-word-logo.icon-warn:before, i.ph-microsoft-word-logo.icon-sign-out:before, i.ph-microsoft-word-logo.icon-substratum:before, i.ph-microsoft-word-logo.icon-environment:before, i.ph-microsoft-word-logo.icon-user:before, i.ph-microsoft-word-logo.icon-person:before, i.ph-microsoft-word-logo.icon-terrain:before, i.ph-microsoft-word-logo.icon-taxon:before, i.ph-microsoft-word-logo.icon-unknown:before, i.ph-microsoft-word-logo.icon-nothing:before, i.ph-microsoft-word-logo.icon-sampling:before, i.ph-microsoft-word-logo.icon-isolation:before, i.ph-microsoft-word-logo.icon-strain:before, i.ph-microsoft-word-logo.icon-gps:before, i.ph-microsoft-word-logo.icon-location:before, i.ph-microsoft-word-logo.icon-add:before, i.ph-microsoft-word-logo.icon-left:before, i.ph-microsoft-word-logo.icon-dna:before, i.ph-microsoft-word-logo.icon-delete:before, i.ph-microsoft-word-logo.icon-edit:before, i.ph-microsoft-word-logo.icon-info:before {
  content: "\eb6a";
}

.ph.ph-minus:before, i.ph-minus.icon-dead:before, i.ph-minus.icon-alive:before, i.ph-minus.icon-warn:before, i.ph-minus.icon-sign-out:before, i.ph-minus.icon-substratum:before, i.ph-minus.icon-environment:before, i.ph-minus.icon-user:before, i.ph-minus.icon-person:before, i.ph-minus.icon-terrain:before, i.ph-minus.icon-taxon:before, i.ph-minus.icon-unknown:before, i.ph-minus.icon-nothing:before, i.ph-minus.icon-sampling:before, i.ph-minus.icon-isolation:before, i.ph-minus.icon-strain:before, i.ph-minus.icon-gps:before, i.ph-minus.icon-location:before, i.ph-minus.icon-add:before, i.ph-minus.icon-left:before, i.ph-minus.icon-dna:before, i.ph-minus.icon-delete:before, i.ph-minus.icon-edit:before, i.ph-minus.icon-info:before {
  content: "\e32a";
}

.ph.ph-minus-circle:before, i.ph-minus-circle.icon-dead:before, i.ph-minus-circle.icon-alive:before, i.ph-minus-circle.icon-warn:before, i.ph-minus-circle.icon-sign-out:before, i.ph-minus-circle.icon-substratum:before, i.ph-minus-circle.icon-environment:before, i.ph-minus-circle.icon-user:before, i.ph-minus-circle.icon-person:before, i.ph-minus-circle.icon-terrain:before, i.ph-minus-circle.icon-taxon:before, i.ph-minus-circle.icon-unknown:before, i.ph-minus-circle.icon-nothing:before, i.ph-minus-circle.icon-sampling:before, i.ph-minus-circle.icon-isolation:before, i.ph-minus-circle.icon-strain:before, i.ph-minus-circle.icon-gps:before, i.ph-minus-circle.icon-location:before, i.ph-minus-circle.icon-add:before, i.ph-minus-circle.icon-left:before, i.ph-minus-circle.icon-dna:before, i.ph-minus-circle.icon-delete:before, i.ph-minus-circle.icon-edit:before, i.ph-minus-circle.icon-info:before {
  content: "\e32c";
}

.ph.ph-minus-square:before, i.ph-minus-square.icon-dead:before, i.ph-minus-square.icon-alive:before, i.ph-minus-square.icon-warn:before, i.ph-minus-square.icon-sign-out:before, i.ph-minus-square.icon-substratum:before, i.ph-minus-square.icon-environment:before, i.ph-minus-square.icon-user:before, i.ph-minus-square.icon-person:before, i.ph-minus-square.icon-terrain:before, i.ph-minus-square.icon-taxon:before, i.ph-minus-square.icon-unknown:before, i.ph-minus-square.icon-nothing:before, i.ph-minus-square.icon-sampling:before, i.ph-minus-square.icon-isolation:before, i.ph-minus-square.icon-strain:before, i.ph-minus-square.icon-gps:before, i.ph-minus-square.icon-location:before, i.ph-minus-square.icon-add:before, i.ph-minus-square.icon-left:before, i.ph-minus-square.icon-dna:before, i.ph-minus-square.icon-delete:before, i.ph-minus-square.icon-edit:before, i.ph-minus-square.icon-info:before {
  content: "\ed4c";
}

.ph.ph-money:before, i.ph-money.icon-dead:before, i.ph-money.icon-alive:before, i.ph-money.icon-warn:before, i.ph-money.icon-sign-out:before, i.ph-money.icon-substratum:before, i.ph-money.icon-environment:before, i.ph-money.icon-user:before, i.ph-money.icon-person:before, i.ph-money.icon-terrain:before, i.ph-money.icon-taxon:before, i.ph-money.icon-unknown:before, i.ph-money.icon-nothing:before, i.ph-money.icon-sampling:before, i.ph-money.icon-isolation:before, i.ph-money.icon-strain:before, i.ph-money.icon-gps:before, i.ph-money.icon-location:before, i.ph-money.icon-add:before, i.ph-money.icon-left:before, i.ph-money.icon-dna:before, i.ph-money.icon-delete:before, i.ph-money.icon-edit:before, i.ph-money.icon-info:before {
  content: "\e588";
}

.ph.ph-money-wavy:before, i.ph-money-wavy.icon-dead:before, i.ph-money-wavy.icon-alive:before, i.ph-money-wavy.icon-warn:before, i.ph-money-wavy.icon-sign-out:before, i.ph-money-wavy.icon-substratum:before, i.ph-money-wavy.icon-environment:before, i.ph-money-wavy.icon-user:before, i.ph-money-wavy.icon-person:before, i.ph-money-wavy.icon-terrain:before, i.ph-money-wavy.icon-taxon:before, i.ph-money-wavy.icon-unknown:before, i.ph-money-wavy.icon-nothing:before, i.ph-money-wavy.icon-sampling:before, i.ph-money-wavy.icon-isolation:before, i.ph-money-wavy.icon-strain:before, i.ph-money-wavy.icon-gps:before, i.ph-money-wavy.icon-location:before, i.ph-money-wavy.icon-add:before, i.ph-money-wavy.icon-left:before, i.ph-money-wavy.icon-dna:before, i.ph-money-wavy.icon-delete:before, i.ph-money-wavy.icon-edit:before, i.ph-money-wavy.icon-info:before {
  content: "\ee68";
}

.ph.ph-monitor:before, i.ph-monitor.icon-dead:before, i.ph-monitor.icon-alive:before, i.ph-monitor.icon-warn:before, i.ph-monitor.icon-sign-out:before, i.ph-monitor.icon-substratum:before, i.ph-monitor.icon-environment:before, i.ph-monitor.icon-user:before, i.ph-monitor.icon-person:before, i.ph-monitor.icon-terrain:before, i.ph-monitor.icon-taxon:before, i.ph-monitor.icon-unknown:before, i.ph-monitor.icon-nothing:before, i.ph-monitor.icon-sampling:before, i.ph-monitor.icon-isolation:before, i.ph-monitor.icon-strain:before, i.ph-monitor.icon-gps:before, i.ph-monitor.icon-location:before, i.ph-monitor.icon-add:before, i.ph-monitor.icon-left:before, i.ph-monitor.icon-dna:before, i.ph-monitor.icon-delete:before, i.ph-monitor.icon-edit:before, i.ph-monitor.icon-info:before {
  content: "\e32e";
}

.ph.ph-monitor-arrow-up:before, i.ph-monitor-arrow-up.icon-dead:before, i.ph-monitor-arrow-up.icon-alive:before, i.ph-monitor-arrow-up.icon-warn:before, i.ph-monitor-arrow-up.icon-sign-out:before, i.ph-monitor-arrow-up.icon-substratum:before, i.ph-monitor-arrow-up.icon-environment:before, i.ph-monitor-arrow-up.icon-user:before, i.ph-monitor-arrow-up.icon-person:before, i.ph-monitor-arrow-up.icon-terrain:before, i.ph-monitor-arrow-up.icon-taxon:before, i.ph-monitor-arrow-up.icon-unknown:before, i.ph-monitor-arrow-up.icon-nothing:before, i.ph-monitor-arrow-up.icon-sampling:before, i.ph-monitor-arrow-up.icon-isolation:before, i.ph-monitor-arrow-up.icon-strain:before, i.ph-monitor-arrow-up.icon-gps:before, i.ph-monitor-arrow-up.icon-location:before, i.ph-monitor-arrow-up.icon-add:before, i.ph-monitor-arrow-up.icon-left:before, i.ph-monitor-arrow-up.icon-dna:before, i.ph-monitor-arrow-up.icon-delete:before, i.ph-monitor-arrow-up.icon-edit:before, i.ph-monitor-arrow-up.icon-info:before {
  content: "\e58a";
}

.ph.ph-monitor-play:before, i.ph-monitor-play.icon-dead:before, i.ph-monitor-play.icon-alive:before, i.ph-monitor-play.icon-warn:before, i.ph-monitor-play.icon-sign-out:before, i.ph-monitor-play.icon-substratum:before, i.ph-monitor-play.icon-environment:before, i.ph-monitor-play.icon-user:before, i.ph-monitor-play.icon-person:before, i.ph-monitor-play.icon-terrain:before, i.ph-monitor-play.icon-taxon:before, i.ph-monitor-play.icon-unknown:before, i.ph-monitor-play.icon-nothing:before, i.ph-monitor-play.icon-sampling:before, i.ph-monitor-play.icon-isolation:before, i.ph-monitor-play.icon-strain:before, i.ph-monitor-play.icon-gps:before, i.ph-monitor-play.icon-location:before, i.ph-monitor-play.icon-add:before, i.ph-monitor-play.icon-left:before, i.ph-monitor-play.icon-dna:before, i.ph-monitor-play.icon-delete:before, i.ph-monitor-play.icon-edit:before, i.ph-monitor-play.icon-info:before {
  content: "\e58c";
}

.ph.ph-moon:before, i.ph-moon.icon-dead:before, i.ph-moon.icon-alive:before, i.ph-moon.icon-warn:before, i.ph-moon.icon-sign-out:before, i.ph-moon.icon-substratum:before, i.ph-moon.icon-environment:before, i.ph-moon.icon-user:before, i.ph-moon.icon-person:before, i.ph-moon.icon-terrain:before, i.ph-moon.icon-taxon:before, i.ph-moon.icon-unknown:before, i.ph-moon.icon-nothing:before, i.ph-moon.icon-sampling:before, i.ph-moon.icon-isolation:before, i.ph-moon.icon-strain:before, i.ph-moon.icon-gps:before, i.ph-moon.icon-location:before, i.ph-moon.icon-add:before, i.ph-moon.icon-left:before, i.ph-moon.icon-dna:before, i.ph-moon.icon-delete:before, i.ph-moon.icon-edit:before, i.ph-moon.icon-info:before {
  content: "\e330";
}

.ph.ph-moon-stars:before, i.ph-moon-stars.icon-dead:before, i.ph-moon-stars.icon-alive:before, i.ph-moon-stars.icon-warn:before, i.ph-moon-stars.icon-sign-out:before, i.ph-moon-stars.icon-substratum:before, i.ph-moon-stars.icon-environment:before, i.ph-moon-stars.icon-user:before, i.ph-moon-stars.icon-person:before, i.ph-moon-stars.icon-terrain:before, i.ph-moon-stars.icon-taxon:before, i.ph-moon-stars.icon-unknown:before, i.ph-moon-stars.icon-nothing:before, i.ph-moon-stars.icon-sampling:before, i.ph-moon-stars.icon-isolation:before, i.ph-moon-stars.icon-strain:before, i.ph-moon-stars.icon-gps:before, i.ph-moon-stars.icon-location:before, i.ph-moon-stars.icon-add:before, i.ph-moon-stars.icon-left:before, i.ph-moon-stars.icon-dna:before, i.ph-moon-stars.icon-delete:before, i.ph-moon-stars.icon-edit:before, i.ph-moon-stars.icon-info:before {
  content: "\e58e";
}

.ph.ph-moped:before, i.ph-moped.icon-dead:before, i.ph-moped.icon-alive:before, i.ph-moped.icon-warn:before, i.ph-moped.icon-sign-out:before, i.ph-moped.icon-substratum:before, i.ph-moped.icon-environment:before, i.ph-moped.icon-user:before, i.ph-moped.icon-person:before, i.ph-moped.icon-terrain:before, i.ph-moped.icon-taxon:before, i.ph-moped.icon-unknown:before, i.ph-moped.icon-nothing:before, i.ph-moped.icon-sampling:before, i.ph-moped.icon-isolation:before, i.ph-moped.icon-strain:before, i.ph-moped.icon-gps:before, i.ph-moped.icon-location:before, i.ph-moped.icon-add:before, i.ph-moped.icon-left:before, i.ph-moped.icon-dna:before, i.ph-moped.icon-delete:before, i.ph-moped.icon-edit:before, i.ph-moped.icon-info:before {
  content: "\e824";
}

.ph.ph-moped-front:before, i.ph-moped-front.icon-dead:before, i.ph-moped-front.icon-alive:before, i.ph-moped-front.icon-warn:before, i.ph-moped-front.icon-sign-out:before, i.ph-moped-front.icon-substratum:before, i.ph-moped-front.icon-environment:before, i.ph-moped-front.icon-user:before, i.ph-moped-front.icon-person:before, i.ph-moped-front.icon-terrain:before, i.ph-moped-front.icon-taxon:before, i.ph-moped-front.icon-unknown:before, i.ph-moped-front.icon-nothing:before, i.ph-moped-front.icon-sampling:before, i.ph-moped-front.icon-isolation:before, i.ph-moped-front.icon-strain:before, i.ph-moped-front.icon-gps:before, i.ph-moped-front.icon-location:before, i.ph-moped-front.icon-add:before, i.ph-moped-front.icon-left:before, i.ph-moped-front.icon-dna:before, i.ph-moped-front.icon-delete:before, i.ph-moped-front.icon-edit:before, i.ph-moped-front.icon-info:before {
  content: "\e822";
}

.ph.ph-mosque:before, i.ph-mosque.icon-dead:before, i.ph-mosque.icon-alive:before, i.ph-mosque.icon-warn:before, i.ph-mosque.icon-sign-out:before, i.ph-mosque.icon-substratum:before, i.ph-mosque.icon-environment:before, i.ph-mosque.icon-user:before, i.ph-mosque.icon-person:before, i.ph-mosque.icon-terrain:before, i.ph-mosque.icon-taxon:before, i.ph-mosque.icon-unknown:before, i.ph-mosque.icon-nothing:before, i.ph-mosque.icon-sampling:before, i.ph-mosque.icon-isolation:before, i.ph-mosque.icon-strain:before, i.ph-mosque.icon-gps:before, i.ph-mosque.icon-location:before, i.ph-mosque.icon-add:before, i.ph-mosque.icon-left:before, i.ph-mosque.icon-dna:before, i.ph-mosque.icon-delete:before, i.ph-mosque.icon-edit:before, i.ph-mosque.icon-info:before {
  content: "\ecee";
}

.ph.ph-motorcycle:before, i.ph-motorcycle.icon-dead:before, i.ph-motorcycle.icon-alive:before, i.ph-motorcycle.icon-warn:before, i.ph-motorcycle.icon-sign-out:before, i.ph-motorcycle.icon-substratum:before, i.ph-motorcycle.icon-environment:before, i.ph-motorcycle.icon-user:before, i.ph-motorcycle.icon-person:before, i.ph-motorcycle.icon-terrain:before, i.ph-motorcycle.icon-taxon:before, i.ph-motorcycle.icon-unknown:before, i.ph-motorcycle.icon-nothing:before, i.ph-motorcycle.icon-sampling:before, i.ph-motorcycle.icon-isolation:before, i.ph-motorcycle.icon-strain:before, i.ph-motorcycle.icon-gps:before, i.ph-motorcycle.icon-location:before, i.ph-motorcycle.icon-add:before, i.ph-motorcycle.icon-left:before, i.ph-motorcycle.icon-dna:before, i.ph-motorcycle.icon-delete:before, i.ph-motorcycle.icon-edit:before, i.ph-motorcycle.icon-info:before {
  content: "\e80a";
}

.ph.ph-mountains:before, i.ph-mountains.icon-dead:before, i.ph-mountains.icon-alive:before, i.ph-mountains.icon-warn:before, i.ph-mountains.icon-sign-out:before, i.ph-mountains.icon-substratum:before, i.ph-mountains.icon-environment:before, i.ph-mountains.icon-user:before, i.ph-mountains.icon-person:before, i.ph-mountains.icon-terrain:before, i.ph-mountains.icon-taxon:before, i.ph-mountains.icon-unknown:before, i.ph-mountains.icon-nothing:before, i.ph-mountains.icon-sampling:before, i.ph-mountains.icon-isolation:before, i.ph-mountains.icon-strain:before, i.ph-mountains.icon-gps:before, i.ph-mountains.icon-location:before, i.ph-mountains.icon-add:before, i.ph-mountains.icon-left:before, i.ph-mountains.icon-dna:before, i.ph-mountains.icon-delete:before, i.ph-mountains.icon-edit:before, i.ph-mountains.icon-info:before {
  content: "\e7ae";
}

.ph.ph-mouse:before, i.ph-mouse.icon-dead:before, i.ph-mouse.icon-alive:before, i.ph-mouse.icon-warn:before, i.ph-mouse.icon-sign-out:before, i.ph-mouse.icon-substratum:before, i.ph-mouse.icon-environment:before, i.ph-mouse.icon-user:before, i.ph-mouse.icon-person:before, i.ph-mouse.icon-terrain:before, i.ph-mouse.icon-taxon:before, i.ph-mouse.icon-unknown:before, i.ph-mouse.icon-nothing:before, i.ph-mouse.icon-sampling:before, i.ph-mouse.icon-isolation:before, i.ph-mouse.icon-strain:before, i.ph-mouse.icon-gps:before, i.ph-mouse.icon-location:before, i.ph-mouse.icon-add:before, i.ph-mouse.icon-left:before, i.ph-mouse.icon-dna:before, i.ph-mouse.icon-delete:before, i.ph-mouse.icon-edit:before, i.ph-mouse.icon-info:before {
  content: "\e33a";
}

.ph.ph-mouse-left-click:before, i.ph-mouse-left-click.icon-dead:before, i.ph-mouse-left-click.icon-alive:before, i.ph-mouse-left-click.icon-warn:before, i.ph-mouse-left-click.icon-sign-out:before, i.ph-mouse-left-click.icon-substratum:before, i.ph-mouse-left-click.icon-environment:before, i.ph-mouse-left-click.icon-user:before, i.ph-mouse-left-click.icon-person:before, i.ph-mouse-left-click.icon-terrain:before, i.ph-mouse-left-click.icon-taxon:before, i.ph-mouse-left-click.icon-unknown:before, i.ph-mouse-left-click.icon-nothing:before, i.ph-mouse-left-click.icon-sampling:before, i.ph-mouse-left-click.icon-isolation:before, i.ph-mouse-left-click.icon-strain:before, i.ph-mouse-left-click.icon-gps:before, i.ph-mouse-left-click.icon-location:before, i.ph-mouse-left-click.icon-add:before, i.ph-mouse-left-click.icon-left:before, i.ph-mouse-left-click.icon-dna:before, i.ph-mouse-left-click.icon-delete:before, i.ph-mouse-left-click.icon-edit:before, i.ph-mouse-left-click.icon-info:before {
  content: "\e334";
}

.ph.ph-mouse-middle-click:before, i.ph-mouse-middle-click.icon-dead:before, i.ph-mouse-middle-click.icon-alive:before, i.ph-mouse-middle-click.icon-warn:before, i.ph-mouse-middle-click.icon-sign-out:before, i.ph-mouse-middle-click.icon-substratum:before, i.ph-mouse-middle-click.icon-environment:before, i.ph-mouse-middle-click.icon-user:before, i.ph-mouse-middle-click.icon-person:before, i.ph-mouse-middle-click.icon-terrain:before, i.ph-mouse-middle-click.icon-taxon:before, i.ph-mouse-middle-click.icon-unknown:before, i.ph-mouse-middle-click.icon-nothing:before, i.ph-mouse-middle-click.icon-sampling:before, i.ph-mouse-middle-click.icon-isolation:before, i.ph-mouse-middle-click.icon-strain:before, i.ph-mouse-middle-click.icon-gps:before, i.ph-mouse-middle-click.icon-location:before, i.ph-mouse-middle-click.icon-add:before, i.ph-mouse-middle-click.icon-left:before, i.ph-mouse-middle-click.icon-dna:before, i.ph-mouse-middle-click.icon-delete:before, i.ph-mouse-middle-click.icon-edit:before, i.ph-mouse-middle-click.icon-info:before {
  content: "\e338";
}

.ph.ph-mouse-right-click:before, i.ph-mouse-right-click.icon-dead:before, i.ph-mouse-right-click.icon-alive:before, i.ph-mouse-right-click.icon-warn:before, i.ph-mouse-right-click.icon-sign-out:before, i.ph-mouse-right-click.icon-substratum:before, i.ph-mouse-right-click.icon-environment:before, i.ph-mouse-right-click.icon-user:before, i.ph-mouse-right-click.icon-person:before, i.ph-mouse-right-click.icon-terrain:before, i.ph-mouse-right-click.icon-taxon:before, i.ph-mouse-right-click.icon-unknown:before, i.ph-mouse-right-click.icon-nothing:before, i.ph-mouse-right-click.icon-sampling:before, i.ph-mouse-right-click.icon-isolation:before, i.ph-mouse-right-click.icon-strain:before, i.ph-mouse-right-click.icon-gps:before, i.ph-mouse-right-click.icon-location:before, i.ph-mouse-right-click.icon-add:before, i.ph-mouse-right-click.icon-left:before, i.ph-mouse-right-click.icon-dna:before, i.ph-mouse-right-click.icon-delete:before, i.ph-mouse-right-click.icon-edit:before, i.ph-mouse-right-click.icon-info:before {
  content: "\e336";
}

.ph.ph-mouse-scroll:before, i.ph-mouse-scroll.icon-dead:before, i.ph-mouse-scroll.icon-alive:before, i.ph-mouse-scroll.icon-warn:before, i.ph-mouse-scroll.icon-sign-out:before, i.ph-mouse-scroll.icon-substratum:before, i.ph-mouse-scroll.icon-environment:before, i.ph-mouse-scroll.icon-user:before, i.ph-mouse-scroll.icon-person:before, i.ph-mouse-scroll.icon-terrain:before, i.ph-mouse-scroll.icon-taxon:before, i.ph-mouse-scroll.icon-unknown:before, i.ph-mouse-scroll.icon-nothing:before, i.ph-mouse-scroll.icon-sampling:before, i.ph-mouse-scroll.icon-isolation:before, i.ph-mouse-scroll.icon-strain:before, i.ph-mouse-scroll.icon-gps:before, i.ph-mouse-scroll.icon-location:before, i.ph-mouse-scroll.icon-add:before, i.ph-mouse-scroll.icon-left:before, i.ph-mouse-scroll.icon-dna:before, i.ph-mouse-scroll.icon-delete:before, i.ph-mouse-scroll.icon-edit:before, i.ph-mouse-scroll.icon-info:before {
  content: "\e332";
}

.ph.ph-mouse-simple:before, i.ph-mouse-simple.icon-dead:before, i.ph-mouse-simple.icon-alive:before, i.ph-mouse-simple.icon-warn:before, i.ph-mouse-simple.icon-sign-out:before, i.ph-mouse-simple.icon-substratum:before, i.ph-mouse-simple.icon-environment:before, i.ph-mouse-simple.icon-user:before, i.ph-mouse-simple.icon-person:before, i.ph-mouse-simple.icon-terrain:before, i.ph-mouse-simple.icon-taxon:before, i.ph-mouse-simple.icon-unknown:before, i.ph-mouse-simple.icon-nothing:before, i.ph-mouse-simple.icon-sampling:before, i.ph-mouse-simple.icon-isolation:before, i.ph-mouse-simple.icon-strain:before, i.ph-mouse-simple.icon-gps:before, i.ph-mouse-simple.icon-location:before, i.ph-mouse-simple.icon-add:before, i.ph-mouse-simple.icon-left:before, i.ph-mouse-simple.icon-dna:before, i.ph-mouse-simple.icon-delete:before, i.ph-mouse-simple.icon-edit:before, i.ph-mouse-simple.icon-info:before {
  content: "\e644";
}

.ph.ph-music-note:before, i.ph-music-note.icon-dead:before, i.ph-music-note.icon-alive:before, i.ph-music-note.icon-warn:before, i.ph-music-note.icon-sign-out:before, i.ph-music-note.icon-substratum:before, i.ph-music-note.icon-environment:before, i.ph-music-note.icon-user:before, i.ph-music-note.icon-person:before, i.ph-music-note.icon-terrain:before, i.ph-music-note.icon-taxon:before, i.ph-music-note.icon-unknown:before, i.ph-music-note.icon-nothing:before, i.ph-music-note.icon-sampling:before, i.ph-music-note.icon-isolation:before, i.ph-music-note.icon-strain:before, i.ph-music-note.icon-gps:before, i.ph-music-note.icon-location:before, i.ph-music-note.icon-add:before, i.ph-music-note.icon-left:before, i.ph-music-note.icon-dna:before, i.ph-music-note.icon-delete:before, i.ph-music-note.icon-edit:before, i.ph-music-note.icon-info:before {
  content: "\e33c";
}

.ph.ph-music-note-simple:before, i.ph-music-note-simple.icon-dead:before, i.ph-music-note-simple.icon-alive:before, i.ph-music-note-simple.icon-warn:before, i.ph-music-note-simple.icon-sign-out:before, i.ph-music-note-simple.icon-substratum:before, i.ph-music-note-simple.icon-environment:before, i.ph-music-note-simple.icon-user:before, i.ph-music-note-simple.icon-person:before, i.ph-music-note-simple.icon-terrain:before, i.ph-music-note-simple.icon-taxon:before, i.ph-music-note-simple.icon-unknown:before, i.ph-music-note-simple.icon-nothing:before, i.ph-music-note-simple.icon-sampling:before, i.ph-music-note-simple.icon-isolation:before, i.ph-music-note-simple.icon-strain:before, i.ph-music-note-simple.icon-gps:before, i.ph-music-note-simple.icon-location:before, i.ph-music-note-simple.icon-add:before, i.ph-music-note-simple.icon-left:before, i.ph-music-note-simple.icon-dna:before, i.ph-music-note-simple.icon-delete:before, i.ph-music-note-simple.icon-edit:before, i.ph-music-note-simple.icon-info:before {
  content: "\e33e";
}

.ph.ph-music-notes:before, i.ph-music-notes.icon-dead:before, i.ph-music-notes.icon-alive:before, i.ph-music-notes.icon-warn:before, i.ph-music-notes.icon-sign-out:before, i.ph-music-notes.icon-substratum:before, i.ph-music-notes.icon-environment:before, i.ph-music-notes.icon-user:before, i.ph-music-notes.icon-person:before, i.ph-music-notes.icon-terrain:before, i.ph-music-notes.icon-taxon:before, i.ph-music-notes.icon-unknown:before, i.ph-music-notes.icon-nothing:before, i.ph-music-notes.icon-sampling:before, i.ph-music-notes.icon-isolation:before, i.ph-music-notes.icon-strain:before, i.ph-music-notes.icon-gps:before, i.ph-music-notes.icon-location:before, i.ph-music-notes.icon-add:before, i.ph-music-notes.icon-left:before, i.ph-music-notes.icon-dna:before, i.ph-music-notes.icon-delete:before, i.ph-music-notes.icon-edit:before, i.ph-music-notes.icon-info:before {
  content: "\e340";
}

.ph.ph-music-notes-minus:before, i.ph-music-notes-minus.icon-dead:before, i.ph-music-notes-minus.icon-alive:before, i.ph-music-notes-minus.icon-warn:before, i.ph-music-notes-minus.icon-sign-out:before, i.ph-music-notes-minus.icon-substratum:before, i.ph-music-notes-minus.icon-environment:before, i.ph-music-notes-minus.icon-user:before, i.ph-music-notes-minus.icon-person:before, i.ph-music-notes-minus.icon-terrain:before, i.ph-music-notes-minus.icon-taxon:before, i.ph-music-notes-minus.icon-unknown:before, i.ph-music-notes-minus.icon-nothing:before, i.ph-music-notes-minus.icon-sampling:before, i.ph-music-notes-minus.icon-isolation:before, i.ph-music-notes-minus.icon-strain:before, i.ph-music-notes-minus.icon-gps:before, i.ph-music-notes-minus.icon-location:before, i.ph-music-notes-minus.icon-add:before, i.ph-music-notes-minus.icon-left:before, i.ph-music-notes-minus.icon-dna:before, i.ph-music-notes-minus.icon-delete:before, i.ph-music-notes-minus.icon-edit:before, i.ph-music-notes-minus.icon-info:before {
  content: "\ee0c";
}

.ph.ph-music-notes-plus:before, i.ph-music-notes-plus.icon-dead:before, i.ph-music-notes-plus.icon-alive:before, i.ph-music-notes-plus.icon-warn:before, i.ph-music-notes-plus.icon-sign-out:before, i.ph-music-notes-plus.icon-substratum:before, i.ph-music-notes-plus.icon-environment:before, i.ph-music-notes-plus.icon-user:before, i.ph-music-notes-plus.icon-person:before, i.ph-music-notes-plus.icon-terrain:before, i.ph-music-notes-plus.icon-taxon:before, i.ph-music-notes-plus.icon-unknown:before, i.ph-music-notes-plus.icon-nothing:before, i.ph-music-notes-plus.icon-sampling:before, i.ph-music-notes-plus.icon-isolation:before, i.ph-music-notes-plus.icon-strain:before, i.ph-music-notes-plus.icon-gps:before, i.ph-music-notes-plus.icon-location:before, i.ph-music-notes-plus.icon-add:before, i.ph-music-notes-plus.icon-left:before, i.ph-music-notes-plus.icon-dna:before, i.ph-music-notes-plus.icon-delete:before, i.ph-music-notes-plus.icon-edit:before, i.ph-music-notes-plus.icon-info:before {
  content: "\eb7c";
}

.ph.ph-music-notes-simple:before, i.ph-music-notes-simple.icon-dead:before, i.ph-music-notes-simple.icon-alive:before, i.ph-music-notes-simple.icon-warn:before, i.ph-music-notes-simple.icon-sign-out:before, i.ph-music-notes-simple.icon-substratum:before, i.ph-music-notes-simple.icon-environment:before, i.ph-music-notes-simple.icon-user:before, i.ph-music-notes-simple.icon-person:before, i.ph-music-notes-simple.icon-terrain:before, i.ph-music-notes-simple.icon-taxon:before, i.ph-music-notes-simple.icon-unknown:before, i.ph-music-notes-simple.icon-nothing:before, i.ph-music-notes-simple.icon-sampling:before, i.ph-music-notes-simple.icon-isolation:before, i.ph-music-notes-simple.icon-strain:before, i.ph-music-notes-simple.icon-gps:before, i.ph-music-notes-simple.icon-location:before, i.ph-music-notes-simple.icon-add:before, i.ph-music-notes-simple.icon-left:before, i.ph-music-notes-simple.icon-dna:before, i.ph-music-notes-simple.icon-delete:before, i.ph-music-notes-simple.icon-edit:before, i.ph-music-notes-simple.icon-info:before {
  content: "\e342";
}

.ph.ph-navigation-arrow:before, i.ph-navigation-arrow.icon-dead:before, i.ph-navigation-arrow.icon-alive:before, i.ph-navigation-arrow.icon-warn:before, i.ph-navigation-arrow.icon-sign-out:before, i.ph-navigation-arrow.icon-substratum:before, i.ph-navigation-arrow.icon-environment:before, i.ph-navigation-arrow.icon-user:before, i.ph-navigation-arrow.icon-person:before, i.ph-navigation-arrow.icon-terrain:before, i.ph-navigation-arrow.icon-taxon:before, i.ph-navigation-arrow.icon-unknown:before, i.ph-navigation-arrow.icon-nothing:before, i.ph-navigation-arrow.icon-sampling:before, i.ph-navigation-arrow.icon-isolation:before, i.ph-navigation-arrow.icon-strain:before, i.ph-navigation-arrow.icon-gps:before, i.ph-navigation-arrow.icon-location:before, i.ph-navigation-arrow.icon-add:before, i.ph-navigation-arrow.icon-left:before, i.ph-navigation-arrow.icon-dna:before, i.ph-navigation-arrow.icon-delete:before, i.ph-navigation-arrow.icon-edit:before, i.ph-navigation-arrow.icon-info:before {
  content: "\eade";
}

.ph.ph-needle:before, i.ph-needle.icon-dead:before, i.ph-needle.icon-alive:before, i.ph-needle.icon-warn:before, i.ph-needle.icon-sign-out:before, i.ph-needle.icon-substratum:before, i.ph-needle.icon-environment:before, i.ph-needle.icon-user:before, i.ph-needle.icon-person:before, i.ph-needle.icon-terrain:before, i.ph-needle.icon-taxon:before, i.ph-needle.icon-unknown:before, i.ph-needle.icon-nothing:before, i.ph-needle.icon-sampling:before, i.ph-needle.icon-isolation:before, i.ph-needle.icon-strain:before, i.ph-needle.icon-gps:before, i.ph-needle.icon-location:before, i.ph-needle.icon-add:before, i.ph-needle.icon-left:before, i.ph-needle.icon-dna:before, i.ph-needle.icon-delete:before, i.ph-needle.icon-edit:before, i.ph-needle.icon-info:before {
  content: "\e82e";
}

.ph.ph-network:before, i.ph-network.icon-dead:before, i.ph-network.icon-alive:before, i.ph-network.icon-warn:before, i.ph-network.icon-sign-out:before, i.ph-network.icon-substratum:before, i.ph-network.icon-environment:before, i.ph-network.icon-user:before, i.ph-network.icon-person:before, i.ph-network.icon-terrain:before, i.ph-network.icon-taxon:before, i.ph-network.icon-unknown:before, i.ph-network.icon-nothing:before, i.ph-network.icon-sampling:before, i.ph-network.icon-isolation:before, i.ph-network.icon-strain:before, i.ph-network.icon-gps:before, i.ph-network.icon-location:before, i.ph-network.icon-add:before, i.ph-network.icon-left:before, i.ph-network.icon-dna:before, i.ph-network.icon-delete:before, i.ph-network.icon-edit:before, i.ph-network.icon-info:before {
  content: "\edde";
}

.ph.ph-network-slash:before, i.ph-network-slash.icon-dead:before, i.ph-network-slash.icon-alive:before, i.ph-network-slash.icon-warn:before, i.ph-network-slash.icon-sign-out:before, i.ph-network-slash.icon-substratum:before, i.ph-network-slash.icon-environment:before, i.ph-network-slash.icon-user:before, i.ph-network-slash.icon-person:before, i.ph-network-slash.icon-terrain:before, i.ph-network-slash.icon-taxon:before, i.ph-network-slash.icon-unknown:before, i.ph-network-slash.icon-nothing:before, i.ph-network-slash.icon-sampling:before, i.ph-network-slash.icon-isolation:before, i.ph-network-slash.icon-strain:before, i.ph-network-slash.icon-gps:before, i.ph-network-slash.icon-location:before, i.ph-network-slash.icon-add:before, i.ph-network-slash.icon-left:before, i.ph-network-slash.icon-dna:before, i.ph-network-slash.icon-delete:before, i.ph-network-slash.icon-edit:before, i.ph-network-slash.icon-info:before {
  content: "\eddc";
}

.ph.ph-network-x:before, i.ph-network-x.icon-dead:before, i.ph-network-x.icon-alive:before, i.ph-network-x.icon-warn:before, i.ph-network-x.icon-sign-out:before, i.ph-network-x.icon-substratum:before, i.ph-network-x.icon-environment:before, i.ph-network-x.icon-user:before, i.ph-network-x.icon-person:before, i.ph-network-x.icon-terrain:before, i.ph-network-x.icon-taxon:before, i.ph-network-x.icon-unknown:before, i.ph-network-x.icon-nothing:before, i.ph-network-x.icon-sampling:before, i.ph-network-x.icon-isolation:before, i.ph-network-x.icon-strain:before, i.ph-network-x.icon-gps:before, i.ph-network-x.icon-location:before, i.ph-network-x.icon-add:before, i.ph-network-x.icon-left:before, i.ph-network-x.icon-dna:before, i.ph-network-x.icon-delete:before, i.ph-network-x.icon-edit:before, i.ph-network-x.icon-info:before {
  content: "\edda";
}

.ph.ph-newspaper:before, i.ph-newspaper.icon-dead:before, i.ph-newspaper.icon-alive:before, i.ph-newspaper.icon-warn:before, i.ph-newspaper.icon-sign-out:before, i.ph-newspaper.icon-substratum:before, i.ph-newspaper.icon-environment:before, i.ph-newspaper.icon-user:before, i.ph-newspaper.icon-person:before, i.ph-newspaper.icon-terrain:before, i.ph-newspaper.icon-taxon:before, i.ph-newspaper.icon-unknown:before, i.ph-newspaper.icon-nothing:before, i.ph-newspaper.icon-sampling:before, i.ph-newspaper.icon-isolation:before, i.ph-newspaper.icon-strain:before, i.ph-newspaper.icon-gps:before, i.ph-newspaper.icon-location:before, i.ph-newspaper.icon-add:before, i.ph-newspaper.icon-left:before, i.ph-newspaper.icon-dna:before, i.ph-newspaper.icon-delete:before, i.ph-newspaper.icon-edit:before, i.ph-newspaper.icon-info:before {
  content: "\e344";
}

.ph.ph-newspaper-clipping:before, i.ph-newspaper-clipping.icon-dead:before, i.ph-newspaper-clipping.icon-alive:before, i.ph-newspaper-clipping.icon-warn:before, i.ph-newspaper-clipping.icon-sign-out:before, i.ph-newspaper-clipping.icon-substratum:before, i.ph-newspaper-clipping.icon-environment:before, i.ph-newspaper-clipping.icon-user:before, i.ph-newspaper-clipping.icon-person:before, i.ph-newspaper-clipping.icon-terrain:before, i.ph-newspaper-clipping.icon-taxon:before, i.ph-newspaper-clipping.icon-unknown:before, i.ph-newspaper-clipping.icon-nothing:before, i.ph-newspaper-clipping.icon-sampling:before, i.ph-newspaper-clipping.icon-isolation:before, i.ph-newspaper-clipping.icon-strain:before, i.ph-newspaper-clipping.icon-gps:before, i.ph-newspaper-clipping.icon-location:before, i.ph-newspaper-clipping.icon-add:before, i.ph-newspaper-clipping.icon-left:before, i.ph-newspaper-clipping.icon-dna:before, i.ph-newspaper-clipping.icon-delete:before, i.ph-newspaper-clipping.icon-edit:before, i.ph-newspaper-clipping.icon-info:before {
  content: "\e346";
}

.ph.ph-not-equals:before, i.ph-not-equals.icon-dead:before, i.ph-not-equals.icon-alive:before, i.ph-not-equals.icon-warn:before, i.ph-not-equals.icon-sign-out:before, i.ph-not-equals.icon-substratum:before, i.ph-not-equals.icon-environment:before, i.ph-not-equals.icon-user:before, i.ph-not-equals.icon-person:before, i.ph-not-equals.icon-terrain:before, i.ph-not-equals.icon-taxon:before, i.ph-not-equals.icon-unknown:before, i.ph-not-equals.icon-nothing:before, i.ph-not-equals.icon-sampling:before, i.ph-not-equals.icon-isolation:before, i.ph-not-equals.icon-strain:before, i.ph-not-equals.icon-gps:before, i.ph-not-equals.icon-location:before, i.ph-not-equals.icon-add:before, i.ph-not-equals.icon-left:before, i.ph-not-equals.icon-dna:before, i.ph-not-equals.icon-delete:before, i.ph-not-equals.icon-edit:before, i.ph-not-equals.icon-info:before {
  content: "\eda6";
}

.ph.ph-not-member-of:before, i.ph-not-member-of.icon-dead:before, i.ph-not-member-of.icon-alive:before, i.ph-not-member-of.icon-warn:before, i.ph-not-member-of.icon-sign-out:before, i.ph-not-member-of.icon-substratum:before, i.ph-not-member-of.icon-environment:before, i.ph-not-member-of.icon-user:before, i.ph-not-member-of.icon-person:before, i.ph-not-member-of.icon-terrain:before, i.ph-not-member-of.icon-taxon:before, i.ph-not-member-of.icon-unknown:before, i.ph-not-member-of.icon-nothing:before, i.ph-not-member-of.icon-sampling:before, i.ph-not-member-of.icon-isolation:before, i.ph-not-member-of.icon-strain:before, i.ph-not-member-of.icon-gps:before, i.ph-not-member-of.icon-location:before, i.ph-not-member-of.icon-add:before, i.ph-not-member-of.icon-left:before, i.ph-not-member-of.icon-dna:before, i.ph-not-member-of.icon-delete:before, i.ph-not-member-of.icon-edit:before, i.ph-not-member-of.icon-info:before {
  content: "\edae";
}

.ph.ph-not-subset-of:before, i.ph-not-subset-of.icon-dead:before, i.ph-not-subset-of.icon-alive:before, i.ph-not-subset-of.icon-warn:before, i.ph-not-subset-of.icon-sign-out:before, i.ph-not-subset-of.icon-substratum:before, i.ph-not-subset-of.icon-environment:before, i.ph-not-subset-of.icon-user:before, i.ph-not-subset-of.icon-person:before, i.ph-not-subset-of.icon-terrain:before, i.ph-not-subset-of.icon-taxon:before, i.ph-not-subset-of.icon-unknown:before, i.ph-not-subset-of.icon-nothing:before, i.ph-not-subset-of.icon-sampling:before, i.ph-not-subset-of.icon-isolation:before, i.ph-not-subset-of.icon-strain:before, i.ph-not-subset-of.icon-gps:before, i.ph-not-subset-of.icon-location:before, i.ph-not-subset-of.icon-add:before, i.ph-not-subset-of.icon-left:before, i.ph-not-subset-of.icon-dna:before, i.ph-not-subset-of.icon-delete:before, i.ph-not-subset-of.icon-edit:before, i.ph-not-subset-of.icon-info:before {
  content: "\edb0";
}

.ph.ph-not-superset-of:before, i.ph-not-superset-of.icon-dead:before, i.ph-not-superset-of.icon-alive:before, i.ph-not-superset-of.icon-warn:before, i.ph-not-superset-of.icon-sign-out:before, i.ph-not-superset-of.icon-substratum:before, i.ph-not-superset-of.icon-environment:before, i.ph-not-superset-of.icon-user:before, i.ph-not-superset-of.icon-person:before, i.ph-not-superset-of.icon-terrain:before, i.ph-not-superset-of.icon-taxon:before, i.ph-not-superset-of.icon-unknown:before, i.ph-not-superset-of.icon-nothing:before, i.ph-not-superset-of.icon-sampling:before, i.ph-not-superset-of.icon-isolation:before, i.ph-not-superset-of.icon-strain:before, i.ph-not-superset-of.icon-gps:before, i.ph-not-superset-of.icon-location:before, i.ph-not-superset-of.icon-add:before, i.ph-not-superset-of.icon-left:before, i.ph-not-superset-of.icon-dna:before, i.ph-not-superset-of.icon-delete:before, i.ph-not-superset-of.icon-edit:before, i.ph-not-superset-of.icon-info:before {
  content: "\edb2";
}

.ph.ph-notches:before, i.ph-notches.icon-dead:before, i.ph-notches.icon-alive:before, i.ph-notches.icon-warn:before, i.ph-notches.icon-sign-out:before, i.ph-notches.icon-substratum:before, i.ph-notches.icon-environment:before, i.ph-notches.icon-user:before, i.ph-notches.icon-person:before, i.ph-notches.icon-terrain:before, i.ph-notches.icon-taxon:before, i.ph-notches.icon-unknown:before, i.ph-notches.icon-nothing:before, i.ph-notches.icon-sampling:before, i.ph-notches.icon-isolation:before, i.ph-notches.icon-strain:before, i.ph-notches.icon-gps:before, i.ph-notches.icon-location:before, i.ph-notches.icon-add:before, i.ph-notches.icon-left:before, i.ph-notches.icon-dna:before, i.ph-notches.icon-delete:before, i.ph-notches.icon-edit:before, i.ph-notches.icon-info:before {
  content: "\ed3a";
}

.ph.ph-note:before, i.ph-note.icon-dead:before, i.ph-note.icon-alive:before, i.ph-note.icon-warn:before, i.ph-note.icon-sign-out:before, i.ph-note.icon-substratum:before, i.ph-note.icon-environment:before, i.ph-note.icon-user:before, i.ph-note.icon-person:before, i.ph-note.icon-terrain:before, i.ph-note.icon-taxon:before, i.ph-note.icon-unknown:before, i.ph-note.icon-nothing:before, i.ph-note.icon-sampling:before, i.ph-note.icon-isolation:before, i.ph-note.icon-strain:before, i.ph-note.icon-gps:before, i.ph-note.icon-location:before, i.ph-note.icon-add:before, i.ph-note.icon-left:before, i.ph-note.icon-dna:before, i.ph-note.icon-delete:before, i.ph-note.icon-edit:before, i.ph-note.icon-info:before {
  content: "\e348";
}

.ph.ph-note-blank:before, i.ph-note-blank.icon-dead:before, i.ph-note-blank.icon-alive:before, i.ph-note-blank.icon-warn:before, i.ph-note-blank.icon-sign-out:before, i.ph-note-blank.icon-substratum:before, i.ph-note-blank.icon-environment:before, i.ph-note-blank.icon-user:before, i.ph-note-blank.icon-person:before, i.ph-note-blank.icon-terrain:before, i.ph-note-blank.icon-taxon:before, i.ph-note-blank.icon-unknown:before, i.ph-note-blank.icon-nothing:before, i.ph-note-blank.icon-sampling:before, i.ph-note-blank.icon-isolation:before, i.ph-note-blank.icon-strain:before, i.ph-note-blank.icon-gps:before, i.ph-note-blank.icon-location:before, i.ph-note-blank.icon-add:before, i.ph-note-blank.icon-left:before, i.ph-note-blank.icon-dna:before, i.ph-note-blank.icon-delete:before, i.ph-note-blank.icon-edit:before, i.ph-note-blank.icon-info:before {
  content: "\e34a";
}

.ph.ph-note-pencil:before, i.ph-note-pencil.icon-dead:before, i.ph-note-pencil.icon-alive:before, i.ph-note-pencil.icon-warn:before, i.ph-note-pencil.icon-sign-out:before, i.ph-note-pencil.icon-substratum:before, i.ph-note-pencil.icon-environment:before, i.ph-note-pencil.icon-user:before, i.ph-note-pencil.icon-person:before, i.ph-note-pencil.icon-terrain:before, i.ph-note-pencil.icon-taxon:before, i.ph-note-pencil.icon-unknown:before, i.ph-note-pencil.icon-nothing:before, i.ph-note-pencil.icon-sampling:before, i.ph-note-pencil.icon-isolation:before, i.ph-note-pencil.icon-strain:before, i.ph-note-pencil.icon-gps:before, i.ph-note-pencil.icon-location:before, i.ph-note-pencil.icon-add:before, i.ph-note-pencil.icon-left:before, i.ph-note-pencil.icon-dna:before, i.ph-note-pencil.icon-delete:before, i.ph-note-pencil.icon-edit:before, i.ph-note-pencil.icon-info:before {
  content: "\e34c";
}

.ph.ph-notebook:before, i.ph-notebook.icon-dead:before, i.ph-notebook.icon-alive:before, i.ph-notebook.icon-warn:before, i.ph-notebook.icon-sign-out:before, i.ph-notebook.icon-substratum:before, i.ph-notebook.icon-environment:before, i.ph-notebook.icon-user:before, i.ph-notebook.icon-person:before, i.ph-notebook.icon-terrain:before, i.ph-notebook.icon-taxon:before, i.ph-notebook.icon-unknown:before, i.ph-notebook.icon-nothing:before, i.ph-notebook.icon-sampling:before, i.ph-notebook.icon-isolation:before, i.ph-notebook.icon-strain:before, i.ph-notebook.icon-gps:before, i.ph-notebook.icon-location:before, i.ph-notebook.icon-add:before, i.ph-notebook.icon-left:before, i.ph-notebook.icon-dna:before, i.ph-notebook.icon-delete:before, i.ph-notebook.icon-edit:before, i.ph-notebook.icon-info:before {
  content: "\e34e";
}

.ph.ph-notepad:before, i.ph-notepad.icon-dead:before, i.ph-notepad.icon-alive:before, i.ph-notepad.icon-warn:before, i.ph-notepad.icon-sign-out:before, i.ph-notepad.icon-substratum:before, i.ph-notepad.icon-environment:before, i.ph-notepad.icon-user:before, i.ph-notepad.icon-person:before, i.ph-notepad.icon-terrain:before, i.ph-notepad.icon-taxon:before, i.ph-notepad.icon-unknown:before, i.ph-notepad.icon-nothing:before, i.ph-notepad.icon-sampling:before, i.ph-notepad.icon-isolation:before, i.ph-notepad.icon-strain:before, i.ph-notepad.icon-gps:before, i.ph-notepad.icon-location:before, i.ph-notepad.icon-add:before, i.ph-notepad.icon-left:before, i.ph-notepad.icon-dna:before, i.ph-notepad.icon-delete:before, i.ph-notepad.icon-edit:before, i.ph-notepad.icon-info:before {
  content: "\e63e";
}

.ph.ph-notification:before, i.ph-notification.icon-dead:before, i.ph-notification.icon-alive:before, i.ph-notification.icon-warn:before, i.ph-notification.icon-sign-out:before, i.ph-notification.icon-substratum:before, i.ph-notification.icon-environment:before, i.ph-notification.icon-user:before, i.ph-notification.icon-person:before, i.ph-notification.icon-terrain:before, i.ph-notification.icon-taxon:before, i.ph-notification.icon-unknown:before, i.ph-notification.icon-nothing:before, i.ph-notification.icon-sampling:before, i.ph-notification.icon-isolation:before, i.ph-notification.icon-strain:before, i.ph-notification.icon-gps:before, i.ph-notification.icon-location:before, i.ph-notification.icon-add:before, i.ph-notification.icon-left:before, i.ph-notification.icon-dna:before, i.ph-notification.icon-delete:before, i.ph-notification.icon-edit:before, i.ph-notification.icon-info:before {
  content: "\e6fa";
}

.ph.ph-notion-logo:before, i.ph-notion-logo.icon-dead:before, i.ph-notion-logo.icon-alive:before, i.ph-notion-logo.icon-warn:before, i.ph-notion-logo.icon-sign-out:before, i.ph-notion-logo.icon-substratum:before, i.ph-notion-logo.icon-environment:before, i.ph-notion-logo.icon-user:before, i.ph-notion-logo.icon-person:before, i.ph-notion-logo.icon-terrain:before, i.ph-notion-logo.icon-taxon:before, i.ph-notion-logo.icon-unknown:before, i.ph-notion-logo.icon-nothing:before, i.ph-notion-logo.icon-sampling:before, i.ph-notion-logo.icon-isolation:before, i.ph-notion-logo.icon-strain:before, i.ph-notion-logo.icon-gps:before, i.ph-notion-logo.icon-location:before, i.ph-notion-logo.icon-add:before, i.ph-notion-logo.icon-left:before, i.ph-notion-logo.icon-dna:before, i.ph-notion-logo.icon-delete:before, i.ph-notion-logo.icon-edit:before, i.ph-notion-logo.icon-info:before {
  content: "\e9a0";
}

.ph.ph-nuclear-plant:before, i.ph-nuclear-plant.icon-dead:before, i.ph-nuclear-plant.icon-alive:before, i.ph-nuclear-plant.icon-warn:before, i.ph-nuclear-plant.icon-sign-out:before, i.ph-nuclear-plant.icon-substratum:before, i.ph-nuclear-plant.icon-environment:before, i.ph-nuclear-plant.icon-user:before, i.ph-nuclear-plant.icon-person:before, i.ph-nuclear-plant.icon-terrain:before, i.ph-nuclear-plant.icon-taxon:before, i.ph-nuclear-plant.icon-unknown:before, i.ph-nuclear-plant.icon-nothing:before, i.ph-nuclear-plant.icon-sampling:before, i.ph-nuclear-plant.icon-isolation:before, i.ph-nuclear-plant.icon-strain:before, i.ph-nuclear-plant.icon-gps:before, i.ph-nuclear-plant.icon-location:before, i.ph-nuclear-plant.icon-add:before, i.ph-nuclear-plant.icon-left:before, i.ph-nuclear-plant.icon-dna:before, i.ph-nuclear-plant.icon-delete:before, i.ph-nuclear-plant.icon-edit:before, i.ph-nuclear-plant.icon-info:before {
  content: "\ed7c";
}

.ph.ph-number-circle-eight:before, i.ph-number-circle-eight.icon-dead:before, i.ph-number-circle-eight.icon-alive:before, i.ph-number-circle-eight.icon-warn:before, i.ph-number-circle-eight.icon-sign-out:before, i.ph-number-circle-eight.icon-substratum:before, i.ph-number-circle-eight.icon-environment:before, i.ph-number-circle-eight.icon-user:before, i.ph-number-circle-eight.icon-person:before, i.ph-number-circle-eight.icon-terrain:before, i.ph-number-circle-eight.icon-taxon:before, i.ph-number-circle-eight.icon-unknown:before, i.ph-number-circle-eight.icon-nothing:before, i.ph-number-circle-eight.icon-sampling:before, i.ph-number-circle-eight.icon-isolation:before, i.ph-number-circle-eight.icon-strain:before, i.ph-number-circle-eight.icon-gps:before, i.ph-number-circle-eight.icon-location:before, i.ph-number-circle-eight.icon-add:before, i.ph-number-circle-eight.icon-left:before, i.ph-number-circle-eight.icon-dna:before, i.ph-number-circle-eight.icon-delete:before, i.ph-number-circle-eight.icon-edit:before, i.ph-number-circle-eight.icon-info:before {
  content: "\e352";
}

.ph.ph-number-circle-five:before, i.ph-number-circle-five.icon-dead:before, i.ph-number-circle-five.icon-alive:before, i.ph-number-circle-five.icon-warn:before, i.ph-number-circle-five.icon-sign-out:before, i.ph-number-circle-five.icon-substratum:before, i.ph-number-circle-five.icon-environment:before, i.ph-number-circle-five.icon-user:before, i.ph-number-circle-five.icon-person:before, i.ph-number-circle-five.icon-terrain:before, i.ph-number-circle-five.icon-taxon:before, i.ph-number-circle-five.icon-unknown:before, i.ph-number-circle-five.icon-nothing:before, i.ph-number-circle-five.icon-sampling:before, i.ph-number-circle-five.icon-isolation:before, i.ph-number-circle-five.icon-strain:before, i.ph-number-circle-five.icon-gps:before, i.ph-number-circle-five.icon-location:before, i.ph-number-circle-five.icon-add:before, i.ph-number-circle-five.icon-left:before, i.ph-number-circle-five.icon-dna:before, i.ph-number-circle-five.icon-delete:before, i.ph-number-circle-five.icon-edit:before, i.ph-number-circle-five.icon-info:before {
  content: "\e358";
}

.ph.ph-number-circle-four:before, i.ph-number-circle-four.icon-dead:before, i.ph-number-circle-four.icon-alive:before, i.ph-number-circle-four.icon-warn:before, i.ph-number-circle-four.icon-sign-out:before, i.ph-number-circle-four.icon-substratum:before, i.ph-number-circle-four.icon-environment:before, i.ph-number-circle-four.icon-user:before, i.ph-number-circle-four.icon-person:before, i.ph-number-circle-four.icon-terrain:before, i.ph-number-circle-four.icon-taxon:before, i.ph-number-circle-four.icon-unknown:before, i.ph-number-circle-four.icon-nothing:before, i.ph-number-circle-four.icon-sampling:before, i.ph-number-circle-four.icon-isolation:before, i.ph-number-circle-four.icon-strain:before, i.ph-number-circle-four.icon-gps:before, i.ph-number-circle-four.icon-location:before, i.ph-number-circle-four.icon-add:before, i.ph-number-circle-four.icon-left:before, i.ph-number-circle-four.icon-dna:before, i.ph-number-circle-four.icon-delete:before, i.ph-number-circle-four.icon-edit:before, i.ph-number-circle-four.icon-info:before {
  content: "\e35e";
}

.ph.ph-number-circle-nine:before, i.ph-number-circle-nine.icon-dead:before, i.ph-number-circle-nine.icon-alive:before, i.ph-number-circle-nine.icon-warn:before, i.ph-number-circle-nine.icon-sign-out:before, i.ph-number-circle-nine.icon-substratum:before, i.ph-number-circle-nine.icon-environment:before, i.ph-number-circle-nine.icon-user:before, i.ph-number-circle-nine.icon-person:before, i.ph-number-circle-nine.icon-terrain:before, i.ph-number-circle-nine.icon-taxon:before, i.ph-number-circle-nine.icon-unknown:before, i.ph-number-circle-nine.icon-nothing:before, i.ph-number-circle-nine.icon-sampling:before, i.ph-number-circle-nine.icon-isolation:before, i.ph-number-circle-nine.icon-strain:before, i.ph-number-circle-nine.icon-gps:before, i.ph-number-circle-nine.icon-location:before, i.ph-number-circle-nine.icon-add:before, i.ph-number-circle-nine.icon-left:before, i.ph-number-circle-nine.icon-dna:before, i.ph-number-circle-nine.icon-delete:before, i.ph-number-circle-nine.icon-edit:before, i.ph-number-circle-nine.icon-info:before {
  content: "\e364";
}

.ph.ph-number-circle-one:before, i.ph-number-circle-one.icon-dead:before, i.ph-number-circle-one.icon-alive:before, i.ph-number-circle-one.icon-warn:before, i.ph-number-circle-one.icon-sign-out:before, i.ph-number-circle-one.icon-substratum:before, i.ph-number-circle-one.icon-environment:before, i.ph-number-circle-one.icon-user:before, i.ph-number-circle-one.icon-person:before, i.ph-number-circle-one.icon-terrain:before, i.ph-number-circle-one.icon-taxon:before, i.ph-number-circle-one.icon-unknown:before, i.ph-number-circle-one.icon-nothing:before, i.ph-number-circle-one.icon-sampling:before, i.ph-number-circle-one.icon-isolation:before, i.ph-number-circle-one.icon-strain:before, i.ph-number-circle-one.icon-gps:before, i.ph-number-circle-one.icon-location:before, i.ph-number-circle-one.icon-add:before, i.ph-number-circle-one.icon-left:before, i.ph-number-circle-one.icon-dna:before, i.ph-number-circle-one.icon-delete:before, i.ph-number-circle-one.icon-edit:before, i.ph-number-circle-one.icon-info:before {
  content: "\e36a";
}

.ph.ph-number-circle-seven:before, i.ph-number-circle-seven.icon-dead:before, i.ph-number-circle-seven.icon-alive:before, i.ph-number-circle-seven.icon-warn:before, i.ph-number-circle-seven.icon-sign-out:before, i.ph-number-circle-seven.icon-substratum:before, i.ph-number-circle-seven.icon-environment:before, i.ph-number-circle-seven.icon-user:before, i.ph-number-circle-seven.icon-person:before, i.ph-number-circle-seven.icon-terrain:before, i.ph-number-circle-seven.icon-taxon:before, i.ph-number-circle-seven.icon-unknown:before, i.ph-number-circle-seven.icon-nothing:before, i.ph-number-circle-seven.icon-sampling:before, i.ph-number-circle-seven.icon-isolation:before, i.ph-number-circle-seven.icon-strain:before, i.ph-number-circle-seven.icon-gps:before, i.ph-number-circle-seven.icon-location:before, i.ph-number-circle-seven.icon-add:before, i.ph-number-circle-seven.icon-left:before, i.ph-number-circle-seven.icon-dna:before, i.ph-number-circle-seven.icon-delete:before, i.ph-number-circle-seven.icon-edit:before, i.ph-number-circle-seven.icon-info:before {
  content: "\e370";
}

.ph.ph-number-circle-six:before, i.ph-number-circle-six.icon-dead:before, i.ph-number-circle-six.icon-alive:before, i.ph-number-circle-six.icon-warn:before, i.ph-number-circle-six.icon-sign-out:before, i.ph-number-circle-six.icon-substratum:before, i.ph-number-circle-six.icon-environment:before, i.ph-number-circle-six.icon-user:before, i.ph-number-circle-six.icon-person:before, i.ph-number-circle-six.icon-terrain:before, i.ph-number-circle-six.icon-taxon:before, i.ph-number-circle-six.icon-unknown:before, i.ph-number-circle-six.icon-nothing:before, i.ph-number-circle-six.icon-sampling:before, i.ph-number-circle-six.icon-isolation:before, i.ph-number-circle-six.icon-strain:before, i.ph-number-circle-six.icon-gps:before, i.ph-number-circle-six.icon-location:before, i.ph-number-circle-six.icon-add:before, i.ph-number-circle-six.icon-left:before, i.ph-number-circle-six.icon-dna:before, i.ph-number-circle-six.icon-delete:before, i.ph-number-circle-six.icon-edit:before, i.ph-number-circle-six.icon-info:before {
  content: "\e376";
}

.ph.ph-number-circle-three:before, i.ph-number-circle-three.icon-dead:before, i.ph-number-circle-three.icon-alive:before, i.ph-number-circle-three.icon-warn:before, i.ph-number-circle-three.icon-sign-out:before, i.ph-number-circle-three.icon-substratum:before, i.ph-number-circle-three.icon-environment:before, i.ph-number-circle-three.icon-user:before, i.ph-number-circle-three.icon-person:before, i.ph-number-circle-three.icon-terrain:before, i.ph-number-circle-three.icon-taxon:before, i.ph-number-circle-three.icon-unknown:before, i.ph-number-circle-three.icon-nothing:before, i.ph-number-circle-three.icon-sampling:before, i.ph-number-circle-three.icon-isolation:before, i.ph-number-circle-three.icon-strain:before, i.ph-number-circle-three.icon-gps:before, i.ph-number-circle-three.icon-location:before, i.ph-number-circle-three.icon-add:before, i.ph-number-circle-three.icon-left:before, i.ph-number-circle-three.icon-dna:before, i.ph-number-circle-three.icon-delete:before, i.ph-number-circle-three.icon-edit:before, i.ph-number-circle-three.icon-info:before {
  content: "\e37c";
}

.ph.ph-number-circle-two:before, i.ph-number-circle-two.icon-dead:before, i.ph-number-circle-two.icon-alive:before, i.ph-number-circle-two.icon-warn:before, i.ph-number-circle-two.icon-sign-out:before, i.ph-number-circle-two.icon-substratum:before, i.ph-number-circle-two.icon-environment:before, i.ph-number-circle-two.icon-user:before, i.ph-number-circle-two.icon-person:before, i.ph-number-circle-two.icon-terrain:before, i.ph-number-circle-two.icon-taxon:before, i.ph-number-circle-two.icon-unknown:before, i.ph-number-circle-two.icon-nothing:before, i.ph-number-circle-two.icon-sampling:before, i.ph-number-circle-two.icon-isolation:before, i.ph-number-circle-two.icon-strain:before, i.ph-number-circle-two.icon-gps:before, i.ph-number-circle-two.icon-location:before, i.ph-number-circle-two.icon-add:before, i.ph-number-circle-two.icon-left:before, i.ph-number-circle-two.icon-dna:before, i.ph-number-circle-two.icon-delete:before, i.ph-number-circle-two.icon-edit:before, i.ph-number-circle-two.icon-info:before {
  content: "\e382";
}

.ph.ph-number-circle-zero:before, i.ph-number-circle-zero.icon-dead:before, i.ph-number-circle-zero.icon-alive:before, i.ph-number-circle-zero.icon-warn:before, i.ph-number-circle-zero.icon-sign-out:before, i.ph-number-circle-zero.icon-substratum:before, i.ph-number-circle-zero.icon-environment:before, i.ph-number-circle-zero.icon-user:before, i.ph-number-circle-zero.icon-person:before, i.ph-number-circle-zero.icon-terrain:before, i.ph-number-circle-zero.icon-taxon:before, i.ph-number-circle-zero.icon-unknown:before, i.ph-number-circle-zero.icon-nothing:before, i.ph-number-circle-zero.icon-sampling:before, i.ph-number-circle-zero.icon-isolation:before, i.ph-number-circle-zero.icon-strain:before, i.ph-number-circle-zero.icon-gps:before, i.ph-number-circle-zero.icon-location:before, i.ph-number-circle-zero.icon-add:before, i.ph-number-circle-zero.icon-left:before, i.ph-number-circle-zero.icon-dna:before, i.ph-number-circle-zero.icon-delete:before, i.ph-number-circle-zero.icon-edit:before, i.ph-number-circle-zero.icon-info:before {
  content: "\e388";
}

.ph.ph-number-eight:before, i.ph-number-eight.icon-dead:before, i.ph-number-eight.icon-alive:before, i.ph-number-eight.icon-warn:before, i.ph-number-eight.icon-sign-out:before, i.ph-number-eight.icon-substratum:before, i.ph-number-eight.icon-environment:before, i.ph-number-eight.icon-user:before, i.ph-number-eight.icon-person:before, i.ph-number-eight.icon-terrain:before, i.ph-number-eight.icon-taxon:before, i.ph-number-eight.icon-unknown:before, i.ph-number-eight.icon-nothing:before, i.ph-number-eight.icon-sampling:before, i.ph-number-eight.icon-isolation:before, i.ph-number-eight.icon-strain:before, i.ph-number-eight.icon-gps:before, i.ph-number-eight.icon-location:before, i.ph-number-eight.icon-add:before, i.ph-number-eight.icon-left:before, i.ph-number-eight.icon-dna:before, i.ph-number-eight.icon-delete:before, i.ph-number-eight.icon-edit:before, i.ph-number-eight.icon-info:before {
  content: "\e350";
}

.ph.ph-number-five:before, i.ph-number-five.icon-dead:before, i.ph-number-five.icon-alive:before, i.ph-number-five.icon-warn:before, i.ph-number-five.icon-sign-out:before, i.ph-number-five.icon-substratum:before, i.ph-number-five.icon-environment:before, i.ph-number-five.icon-user:before, i.ph-number-five.icon-person:before, i.ph-number-five.icon-terrain:before, i.ph-number-five.icon-taxon:before, i.ph-number-five.icon-unknown:before, i.ph-number-five.icon-nothing:before, i.ph-number-five.icon-sampling:before, i.ph-number-five.icon-isolation:before, i.ph-number-five.icon-strain:before, i.ph-number-five.icon-gps:before, i.ph-number-five.icon-location:before, i.ph-number-five.icon-add:before, i.ph-number-five.icon-left:before, i.ph-number-five.icon-dna:before, i.ph-number-five.icon-delete:before, i.ph-number-five.icon-edit:before, i.ph-number-five.icon-info:before {
  content: "\e356";
}

.ph.ph-number-four:before, i.ph-number-four.icon-dead:before, i.ph-number-four.icon-alive:before, i.ph-number-four.icon-warn:before, i.ph-number-four.icon-sign-out:before, i.ph-number-four.icon-substratum:before, i.ph-number-four.icon-environment:before, i.ph-number-four.icon-user:before, i.ph-number-four.icon-person:before, i.ph-number-four.icon-terrain:before, i.ph-number-four.icon-taxon:before, i.ph-number-four.icon-unknown:before, i.ph-number-four.icon-nothing:before, i.ph-number-four.icon-sampling:before, i.ph-number-four.icon-isolation:before, i.ph-number-four.icon-strain:before, i.ph-number-four.icon-gps:before, i.ph-number-four.icon-location:before, i.ph-number-four.icon-add:before, i.ph-number-four.icon-left:before, i.ph-number-four.icon-dna:before, i.ph-number-four.icon-delete:before, i.ph-number-four.icon-edit:before, i.ph-number-four.icon-info:before {
  content: "\e35c";
}

.ph.ph-number-nine:before, i.ph-number-nine.icon-dead:before, i.ph-number-nine.icon-alive:before, i.ph-number-nine.icon-warn:before, i.ph-number-nine.icon-sign-out:before, i.ph-number-nine.icon-substratum:before, i.ph-number-nine.icon-environment:before, i.ph-number-nine.icon-user:before, i.ph-number-nine.icon-person:before, i.ph-number-nine.icon-terrain:before, i.ph-number-nine.icon-taxon:before, i.ph-number-nine.icon-unknown:before, i.ph-number-nine.icon-nothing:before, i.ph-number-nine.icon-sampling:before, i.ph-number-nine.icon-isolation:before, i.ph-number-nine.icon-strain:before, i.ph-number-nine.icon-gps:before, i.ph-number-nine.icon-location:before, i.ph-number-nine.icon-add:before, i.ph-number-nine.icon-left:before, i.ph-number-nine.icon-dna:before, i.ph-number-nine.icon-delete:before, i.ph-number-nine.icon-edit:before, i.ph-number-nine.icon-info:before {
  content: "\e362";
}

.ph.ph-number-one:before, i.ph-number-one.icon-dead:before, i.ph-number-one.icon-alive:before, i.ph-number-one.icon-warn:before, i.ph-number-one.icon-sign-out:before, i.ph-number-one.icon-substratum:before, i.ph-number-one.icon-environment:before, i.ph-number-one.icon-user:before, i.ph-number-one.icon-person:before, i.ph-number-one.icon-terrain:before, i.ph-number-one.icon-taxon:before, i.ph-number-one.icon-unknown:before, i.ph-number-one.icon-nothing:before, i.ph-number-one.icon-sampling:before, i.ph-number-one.icon-isolation:before, i.ph-number-one.icon-strain:before, i.ph-number-one.icon-gps:before, i.ph-number-one.icon-location:before, i.ph-number-one.icon-add:before, i.ph-number-one.icon-left:before, i.ph-number-one.icon-dna:before, i.ph-number-one.icon-delete:before, i.ph-number-one.icon-edit:before, i.ph-number-one.icon-info:before {
  content: "\e368";
}

.ph.ph-number-seven:before, i.ph-number-seven.icon-dead:before, i.ph-number-seven.icon-alive:before, i.ph-number-seven.icon-warn:before, i.ph-number-seven.icon-sign-out:before, i.ph-number-seven.icon-substratum:before, i.ph-number-seven.icon-environment:before, i.ph-number-seven.icon-user:before, i.ph-number-seven.icon-person:before, i.ph-number-seven.icon-terrain:before, i.ph-number-seven.icon-taxon:before, i.ph-number-seven.icon-unknown:before, i.ph-number-seven.icon-nothing:before, i.ph-number-seven.icon-sampling:before, i.ph-number-seven.icon-isolation:before, i.ph-number-seven.icon-strain:before, i.ph-number-seven.icon-gps:before, i.ph-number-seven.icon-location:before, i.ph-number-seven.icon-add:before, i.ph-number-seven.icon-left:before, i.ph-number-seven.icon-dna:before, i.ph-number-seven.icon-delete:before, i.ph-number-seven.icon-edit:before, i.ph-number-seven.icon-info:before {
  content: "\e36e";
}

.ph.ph-number-six:before, i.ph-number-six.icon-dead:before, i.ph-number-six.icon-alive:before, i.ph-number-six.icon-warn:before, i.ph-number-six.icon-sign-out:before, i.ph-number-six.icon-substratum:before, i.ph-number-six.icon-environment:before, i.ph-number-six.icon-user:before, i.ph-number-six.icon-person:before, i.ph-number-six.icon-terrain:before, i.ph-number-six.icon-taxon:before, i.ph-number-six.icon-unknown:before, i.ph-number-six.icon-nothing:before, i.ph-number-six.icon-sampling:before, i.ph-number-six.icon-isolation:before, i.ph-number-six.icon-strain:before, i.ph-number-six.icon-gps:before, i.ph-number-six.icon-location:before, i.ph-number-six.icon-add:before, i.ph-number-six.icon-left:before, i.ph-number-six.icon-dna:before, i.ph-number-six.icon-delete:before, i.ph-number-six.icon-edit:before, i.ph-number-six.icon-info:before {
  content: "\e374";
}

.ph.ph-number-square-eight:before, i.ph-number-square-eight.icon-dead:before, i.ph-number-square-eight.icon-alive:before, i.ph-number-square-eight.icon-warn:before, i.ph-number-square-eight.icon-sign-out:before, i.ph-number-square-eight.icon-substratum:before, i.ph-number-square-eight.icon-environment:before, i.ph-number-square-eight.icon-user:before, i.ph-number-square-eight.icon-person:before, i.ph-number-square-eight.icon-terrain:before, i.ph-number-square-eight.icon-taxon:before, i.ph-number-square-eight.icon-unknown:before, i.ph-number-square-eight.icon-nothing:before, i.ph-number-square-eight.icon-sampling:before, i.ph-number-square-eight.icon-isolation:before, i.ph-number-square-eight.icon-strain:before, i.ph-number-square-eight.icon-gps:before, i.ph-number-square-eight.icon-location:before, i.ph-number-square-eight.icon-add:before, i.ph-number-square-eight.icon-left:before, i.ph-number-square-eight.icon-dna:before, i.ph-number-square-eight.icon-delete:before, i.ph-number-square-eight.icon-edit:before, i.ph-number-square-eight.icon-info:before {
  content: "\e354";
}

.ph.ph-number-square-five:before, i.ph-number-square-five.icon-dead:before, i.ph-number-square-five.icon-alive:before, i.ph-number-square-five.icon-warn:before, i.ph-number-square-five.icon-sign-out:before, i.ph-number-square-five.icon-substratum:before, i.ph-number-square-five.icon-environment:before, i.ph-number-square-five.icon-user:before, i.ph-number-square-five.icon-person:before, i.ph-number-square-five.icon-terrain:before, i.ph-number-square-five.icon-taxon:before, i.ph-number-square-five.icon-unknown:before, i.ph-number-square-five.icon-nothing:before, i.ph-number-square-five.icon-sampling:before, i.ph-number-square-five.icon-isolation:before, i.ph-number-square-five.icon-strain:before, i.ph-number-square-five.icon-gps:before, i.ph-number-square-five.icon-location:before, i.ph-number-square-five.icon-add:before, i.ph-number-square-five.icon-left:before, i.ph-number-square-five.icon-dna:before, i.ph-number-square-five.icon-delete:before, i.ph-number-square-five.icon-edit:before, i.ph-number-square-five.icon-info:before {
  content: "\e35a";
}

.ph.ph-number-square-four:before, i.ph-number-square-four.icon-dead:before, i.ph-number-square-four.icon-alive:before, i.ph-number-square-four.icon-warn:before, i.ph-number-square-four.icon-sign-out:before, i.ph-number-square-four.icon-substratum:before, i.ph-number-square-four.icon-environment:before, i.ph-number-square-four.icon-user:before, i.ph-number-square-four.icon-person:before, i.ph-number-square-four.icon-terrain:before, i.ph-number-square-four.icon-taxon:before, i.ph-number-square-four.icon-unknown:before, i.ph-number-square-four.icon-nothing:before, i.ph-number-square-four.icon-sampling:before, i.ph-number-square-four.icon-isolation:before, i.ph-number-square-four.icon-strain:before, i.ph-number-square-four.icon-gps:before, i.ph-number-square-four.icon-location:before, i.ph-number-square-four.icon-add:before, i.ph-number-square-four.icon-left:before, i.ph-number-square-four.icon-dna:before, i.ph-number-square-four.icon-delete:before, i.ph-number-square-four.icon-edit:before, i.ph-number-square-four.icon-info:before {
  content: "\e360";
}

.ph.ph-number-square-nine:before, i.ph-number-square-nine.icon-dead:before, i.ph-number-square-nine.icon-alive:before, i.ph-number-square-nine.icon-warn:before, i.ph-number-square-nine.icon-sign-out:before, i.ph-number-square-nine.icon-substratum:before, i.ph-number-square-nine.icon-environment:before, i.ph-number-square-nine.icon-user:before, i.ph-number-square-nine.icon-person:before, i.ph-number-square-nine.icon-terrain:before, i.ph-number-square-nine.icon-taxon:before, i.ph-number-square-nine.icon-unknown:before, i.ph-number-square-nine.icon-nothing:before, i.ph-number-square-nine.icon-sampling:before, i.ph-number-square-nine.icon-isolation:before, i.ph-number-square-nine.icon-strain:before, i.ph-number-square-nine.icon-gps:before, i.ph-number-square-nine.icon-location:before, i.ph-number-square-nine.icon-add:before, i.ph-number-square-nine.icon-left:before, i.ph-number-square-nine.icon-dna:before, i.ph-number-square-nine.icon-delete:before, i.ph-number-square-nine.icon-edit:before, i.ph-number-square-nine.icon-info:before {
  content: "\e366";
}

.ph.ph-number-square-one:before, i.ph-number-square-one.icon-dead:before, i.ph-number-square-one.icon-alive:before, i.ph-number-square-one.icon-warn:before, i.ph-number-square-one.icon-sign-out:before, i.ph-number-square-one.icon-substratum:before, i.ph-number-square-one.icon-environment:before, i.ph-number-square-one.icon-user:before, i.ph-number-square-one.icon-person:before, i.ph-number-square-one.icon-terrain:before, i.ph-number-square-one.icon-taxon:before, i.ph-number-square-one.icon-unknown:before, i.ph-number-square-one.icon-nothing:before, i.ph-number-square-one.icon-sampling:before, i.ph-number-square-one.icon-isolation:before, i.ph-number-square-one.icon-strain:before, i.ph-number-square-one.icon-gps:before, i.ph-number-square-one.icon-location:before, i.ph-number-square-one.icon-add:before, i.ph-number-square-one.icon-left:before, i.ph-number-square-one.icon-dna:before, i.ph-number-square-one.icon-delete:before, i.ph-number-square-one.icon-edit:before, i.ph-number-square-one.icon-info:before {
  content: "\e36c";
}

.ph.ph-number-square-seven:before, i.ph-number-square-seven.icon-dead:before, i.ph-number-square-seven.icon-alive:before, i.ph-number-square-seven.icon-warn:before, i.ph-number-square-seven.icon-sign-out:before, i.ph-number-square-seven.icon-substratum:before, i.ph-number-square-seven.icon-environment:before, i.ph-number-square-seven.icon-user:before, i.ph-number-square-seven.icon-person:before, i.ph-number-square-seven.icon-terrain:before, i.ph-number-square-seven.icon-taxon:before, i.ph-number-square-seven.icon-unknown:before, i.ph-number-square-seven.icon-nothing:before, i.ph-number-square-seven.icon-sampling:before, i.ph-number-square-seven.icon-isolation:before, i.ph-number-square-seven.icon-strain:before, i.ph-number-square-seven.icon-gps:before, i.ph-number-square-seven.icon-location:before, i.ph-number-square-seven.icon-add:before, i.ph-number-square-seven.icon-left:before, i.ph-number-square-seven.icon-dna:before, i.ph-number-square-seven.icon-delete:before, i.ph-number-square-seven.icon-edit:before, i.ph-number-square-seven.icon-info:before {
  content: "\e372";
}

.ph.ph-number-square-six:before, i.ph-number-square-six.icon-dead:before, i.ph-number-square-six.icon-alive:before, i.ph-number-square-six.icon-warn:before, i.ph-number-square-six.icon-sign-out:before, i.ph-number-square-six.icon-substratum:before, i.ph-number-square-six.icon-environment:before, i.ph-number-square-six.icon-user:before, i.ph-number-square-six.icon-person:before, i.ph-number-square-six.icon-terrain:before, i.ph-number-square-six.icon-taxon:before, i.ph-number-square-six.icon-unknown:before, i.ph-number-square-six.icon-nothing:before, i.ph-number-square-six.icon-sampling:before, i.ph-number-square-six.icon-isolation:before, i.ph-number-square-six.icon-strain:before, i.ph-number-square-six.icon-gps:before, i.ph-number-square-six.icon-location:before, i.ph-number-square-six.icon-add:before, i.ph-number-square-six.icon-left:before, i.ph-number-square-six.icon-dna:before, i.ph-number-square-six.icon-delete:before, i.ph-number-square-six.icon-edit:before, i.ph-number-square-six.icon-info:before {
  content: "\e378";
}

.ph.ph-number-square-three:before, i.ph-number-square-three.icon-dead:before, i.ph-number-square-three.icon-alive:before, i.ph-number-square-three.icon-warn:before, i.ph-number-square-three.icon-sign-out:before, i.ph-number-square-three.icon-substratum:before, i.ph-number-square-three.icon-environment:before, i.ph-number-square-three.icon-user:before, i.ph-number-square-three.icon-person:before, i.ph-number-square-three.icon-terrain:before, i.ph-number-square-three.icon-taxon:before, i.ph-number-square-three.icon-unknown:before, i.ph-number-square-three.icon-nothing:before, i.ph-number-square-three.icon-sampling:before, i.ph-number-square-three.icon-isolation:before, i.ph-number-square-three.icon-strain:before, i.ph-number-square-three.icon-gps:before, i.ph-number-square-three.icon-location:before, i.ph-number-square-three.icon-add:before, i.ph-number-square-three.icon-left:before, i.ph-number-square-three.icon-dna:before, i.ph-number-square-three.icon-delete:before, i.ph-number-square-three.icon-edit:before, i.ph-number-square-three.icon-info:before {
  content: "\e37e";
}

.ph.ph-number-square-two:before, i.ph-number-square-two.icon-dead:before, i.ph-number-square-two.icon-alive:before, i.ph-number-square-two.icon-warn:before, i.ph-number-square-two.icon-sign-out:before, i.ph-number-square-two.icon-substratum:before, i.ph-number-square-two.icon-environment:before, i.ph-number-square-two.icon-user:before, i.ph-number-square-two.icon-person:before, i.ph-number-square-two.icon-terrain:before, i.ph-number-square-two.icon-taxon:before, i.ph-number-square-two.icon-unknown:before, i.ph-number-square-two.icon-nothing:before, i.ph-number-square-two.icon-sampling:before, i.ph-number-square-two.icon-isolation:before, i.ph-number-square-two.icon-strain:before, i.ph-number-square-two.icon-gps:before, i.ph-number-square-two.icon-location:before, i.ph-number-square-two.icon-add:before, i.ph-number-square-two.icon-left:before, i.ph-number-square-two.icon-dna:before, i.ph-number-square-two.icon-delete:before, i.ph-number-square-two.icon-edit:before, i.ph-number-square-two.icon-info:before {
  content: "\e384";
}

.ph.ph-number-square-zero:before, i.ph-number-square-zero.icon-dead:before, i.ph-number-square-zero.icon-alive:before, i.ph-number-square-zero.icon-warn:before, i.ph-number-square-zero.icon-sign-out:before, i.ph-number-square-zero.icon-substratum:before, i.ph-number-square-zero.icon-environment:before, i.ph-number-square-zero.icon-user:before, i.ph-number-square-zero.icon-person:before, i.ph-number-square-zero.icon-terrain:before, i.ph-number-square-zero.icon-taxon:before, i.ph-number-square-zero.icon-unknown:before, i.ph-number-square-zero.icon-nothing:before, i.ph-number-square-zero.icon-sampling:before, i.ph-number-square-zero.icon-isolation:before, i.ph-number-square-zero.icon-strain:before, i.ph-number-square-zero.icon-gps:before, i.ph-number-square-zero.icon-location:before, i.ph-number-square-zero.icon-add:before, i.ph-number-square-zero.icon-left:before, i.ph-number-square-zero.icon-dna:before, i.ph-number-square-zero.icon-delete:before, i.ph-number-square-zero.icon-edit:before, i.ph-number-square-zero.icon-info:before {
  content: "\e38a";
}

.ph.ph-number-three:before, i.ph-number-three.icon-dead:before, i.ph-number-three.icon-alive:before, i.ph-number-three.icon-warn:before, i.ph-number-three.icon-sign-out:before, i.ph-number-three.icon-substratum:before, i.ph-number-three.icon-environment:before, i.ph-number-three.icon-user:before, i.ph-number-three.icon-person:before, i.ph-number-three.icon-terrain:before, i.ph-number-three.icon-taxon:before, i.ph-number-three.icon-unknown:before, i.ph-number-three.icon-nothing:before, i.ph-number-three.icon-sampling:before, i.ph-number-three.icon-isolation:before, i.ph-number-three.icon-strain:before, i.ph-number-three.icon-gps:before, i.ph-number-three.icon-location:before, i.ph-number-three.icon-add:before, i.ph-number-three.icon-left:before, i.ph-number-three.icon-dna:before, i.ph-number-three.icon-delete:before, i.ph-number-three.icon-edit:before, i.ph-number-three.icon-info:before {
  content: "\e37a";
}

.ph.ph-number-two:before, i.ph-number-two.icon-dead:before, i.ph-number-two.icon-alive:before, i.ph-number-two.icon-warn:before, i.ph-number-two.icon-sign-out:before, i.ph-number-two.icon-substratum:before, i.ph-number-two.icon-environment:before, i.ph-number-two.icon-user:before, i.ph-number-two.icon-person:before, i.ph-number-two.icon-terrain:before, i.ph-number-two.icon-taxon:before, i.ph-number-two.icon-unknown:before, i.ph-number-two.icon-nothing:before, i.ph-number-two.icon-sampling:before, i.ph-number-two.icon-isolation:before, i.ph-number-two.icon-strain:before, i.ph-number-two.icon-gps:before, i.ph-number-two.icon-location:before, i.ph-number-two.icon-add:before, i.ph-number-two.icon-left:before, i.ph-number-two.icon-dna:before, i.ph-number-two.icon-delete:before, i.ph-number-two.icon-edit:before, i.ph-number-two.icon-info:before {
  content: "\e380";
}

.ph.ph-number-zero:before, i.ph-number-zero.icon-dead:before, i.ph-number-zero.icon-alive:before, i.ph-number-zero.icon-warn:before, i.ph-number-zero.icon-sign-out:before, i.ph-number-zero.icon-substratum:before, i.ph-number-zero.icon-environment:before, i.ph-number-zero.icon-user:before, i.ph-number-zero.icon-person:before, i.ph-number-zero.icon-terrain:before, i.ph-number-zero.icon-taxon:before, i.ph-number-zero.icon-unknown:before, i.ph-number-zero.icon-nothing:before, i.ph-number-zero.icon-sampling:before, i.ph-number-zero.icon-isolation:before, i.ph-number-zero.icon-strain:before, i.ph-number-zero.icon-gps:before, i.ph-number-zero.icon-location:before, i.ph-number-zero.icon-add:before, i.ph-number-zero.icon-left:before, i.ph-number-zero.icon-dna:before, i.ph-number-zero.icon-delete:before, i.ph-number-zero.icon-edit:before, i.ph-number-zero.icon-info:before {
  content: "\e386";
}

.ph.ph-numpad:before, i.ph-numpad.icon-dead:before, i.ph-numpad.icon-alive:before, i.ph-numpad.icon-warn:before, i.ph-numpad.icon-sign-out:before, i.ph-numpad.icon-substratum:before, i.ph-numpad.icon-environment:before, i.ph-numpad.icon-user:before, i.ph-numpad.icon-person:before, i.ph-numpad.icon-terrain:before, i.ph-numpad.icon-taxon:before, i.ph-numpad.icon-unknown:before, i.ph-numpad.icon-nothing:before, i.ph-numpad.icon-sampling:before, i.ph-numpad.icon-isolation:before, i.ph-numpad.icon-strain:before, i.ph-numpad.icon-gps:before, i.ph-numpad.icon-location:before, i.ph-numpad.icon-add:before, i.ph-numpad.icon-left:before, i.ph-numpad.icon-dna:before, i.ph-numpad.icon-delete:before, i.ph-numpad.icon-edit:before, i.ph-numpad.icon-info:before {
  content: "\e3c8";
}

.ph.ph-nut:before, i.ph-nut.icon-dead:before, i.ph-nut.icon-alive:before, i.ph-nut.icon-warn:before, i.ph-nut.icon-sign-out:before, i.ph-nut.icon-substratum:before, i.ph-nut.icon-environment:before, i.ph-nut.icon-user:before, i.ph-nut.icon-person:before, i.ph-nut.icon-terrain:before, i.ph-nut.icon-taxon:before, i.ph-nut.icon-unknown:before, i.ph-nut.icon-nothing:before, i.ph-nut.icon-sampling:before, i.ph-nut.icon-isolation:before, i.ph-nut.icon-strain:before, i.ph-nut.icon-gps:before, i.ph-nut.icon-location:before, i.ph-nut.icon-add:before, i.ph-nut.icon-left:before, i.ph-nut.icon-dna:before, i.ph-nut.icon-delete:before, i.ph-nut.icon-edit:before, i.ph-nut.icon-info:before {
  content: "\e38c";
}

.ph.ph-ny-times-logo:before, i.ph-ny-times-logo.icon-dead:before, i.ph-ny-times-logo.icon-alive:before, i.ph-ny-times-logo.icon-warn:before, i.ph-ny-times-logo.icon-sign-out:before, i.ph-ny-times-logo.icon-substratum:before, i.ph-ny-times-logo.icon-environment:before, i.ph-ny-times-logo.icon-user:before, i.ph-ny-times-logo.icon-person:before, i.ph-ny-times-logo.icon-terrain:before, i.ph-ny-times-logo.icon-taxon:before, i.ph-ny-times-logo.icon-unknown:before, i.ph-ny-times-logo.icon-nothing:before, i.ph-ny-times-logo.icon-sampling:before, i.ph-ny-times-logo.icon-isolation:before, i.ph-ny-times-logo.icon-strain:before, i.ph-ny-times-logo.icon-gps:before, i.ph-ny-times-logo.icon-location:before, i.ph-ny-times-logo.icon-add:before, i.ph-ny-times-logo.icon-left:before, i.ph-ny-times-logo.icon-dna:before, i.ph-ny-times-logo.icon-delete:before, i.ph-ny-times-logo.icon-edit:before, i.ph-ny-times-logo.icon-info:before {
  content: "\e646";
}

.ph.ph-octagon:before, i.ph-octagon.icon-dead:before, i.ph-octagon.icon-alive:before, i.ph-octagon.icon-warn:before, i.ph-octagon.icon-sign-out:before, i.ph-octagon.icon-substratum:before, i.ph-octagon.icon-environment:before, i.ph-octagon.icon-user:before, i.ph-octagon.icon-person:before, i.ph-octagon.icon-terrain:before, i.ph-octagon.icon-taxon:before, i.ph-octagon.icon-unknown:before, i.ph-octagon.icon-nothing:before, i.ph-octagon.icon-sampling:before, i.ph-octagon.icon-isolation:before, i.ph-octagon.icon-strain:before, i.ph-octagon.icon-gps:before, i.ph-octagon.icon-location:before, i.ph-octagon.icon-add:before, i.ph-octagon.icon-left:before, i.ph-octagon.icon-dna:before, i.ph-octagon.icon-delete:before, i.ph-octagon.icon-edit:before, i.ph-octagon.icon-info:before {
  content: "\e38e";
}

.ph.ph-office-chair:before, i.ph-office-chair.icon-dead:before, i.ph-office-chair.icon-alive:before, i.ph-office-chair.icon-warn:before, i.ph-office-chair.icon-sign-out:before, i.ph-office-chair.icon-substratum:before, i.ph-office-chair.icon-environment:before, i.ph-office-chair.icon-user:before, i.ph-office-chair.icon-person:before, i.ph-office-chair.icon-terrain:before, i.ph-office-chair.icon-taxon:before, i.ph-office-chair.icon-unknown:before, i.ph-office-chair.icon-nothing:before, i.ph-office-chair.icon-sampling:before, i.ph-office-chair.icon-isolation:before, i.ph-office-chair.icon-strain:before, i.ph-office-chair.icon-gps:before, i.ph-office-chair.icon-location:before, i.ph-office-chair.icon-add:before, i.ph-office-chair.icon-left:before, i.ph-office-chair.icon-dna:before, i.ph-office-chair.icon-delete:before, i.ph-office-chair.icon-edit:before, i.ph-office-chair.icon-info:before {
  content: "\ea46";
}

.ph.ph-onigiri:before, i.ph-onigiri.icon-dead:before, i.ph-onigiri.icon-alive:before, i.ph-onigiri.icon-warn:before, i.ph-onigiri.icon-sign-out:before, i.ph-onigiri.icon-substratum:before, i.ph-onigiri.icon-environment:before, i.ph-onigiri.icon-user:before, i.ph-onigiri.icon-person:before, i.ph-onigiri.icon-terrain:before, i.ph-onigiri.icon-taxon:before, i.ph-onigiri.icon-unknown:before, i.ph-onigiri.icon-nothing:before, i.ph-onigiri.icon-sampling:before, i.ph-onigiri.icon-isolation:before, i.ph-onigiri.icon-strain:before, i.ph-onigiri.icon-gps:before, i.ph-onigiri.icon-location:before, i.ph-onigiri.icon-add:before, i.ph-onigiri.icon-left:before, i.ph-onigiri.icon-dna:before, i.ph-onigiri.icon-delete:before, i.ph-onigiri.icon-edit:before, i.ph-onigiri.icon-info:before {
  content: "\ee2c";
}

.ph.ph-open-ai-logo:before, i.ph-open-ai-logo.icon-dead:before, i.ph-open-ai-logo.icon-alive:before, i.ph-open-ai-logo.icon-warn:before, i.ph-open-ai-logo.icon-sign-out:before, i.ph-open-ai-logo.icon-substratum:before, i.ph-open-ai-logo.icon-environment:before, i.ph-open-ai-logo.icon-user:before, i.ph-open-ai-logo.icon-person:before, i.ph-open-ai-logo.icon-terrain:before, i.ph-open-ai-logo.icon-taxon:before, i.ph-open-ai-logo.icon-unknown:before, i.ph-open-ai-logo.icon-nothing:before, i.ph-open-ai-logo.icon-sampling:before, i.ph-open-ai-logo.icon-isolation:before, i.ph-open-ai-logo.icon-strain:before, i.ph-open-ai-logo.icon-gps:before, i.ph-open-ai-logo.icon-location:before, i.ph-open-ai-logo.icon-add:before, i.ph-open-ai-logo.icon-left:before, i.ph-open-ai-logo.icon-dna:before, i.ph-open-ai-logo.icon-delete:before, i.ph-open-ai-logo.icon-edit:before, i.ph-open-ai-logo.icon-info:before {
  content: "\e7d2";
}

.ph.ph-option:before, i.ph-option.icon-dead:before, i.ph-option.icon-alive:before, i.ph-option.icon-warn:before, i.ph-option.icon-sign-out:before, i.ph-option.icon-substratum:before, i.ph-option.icon-environment:before, i.ph-option.icon-user:before, i.ph-option.icon-person:before, i.ph-option.icon-terrain:before, i.ph-option.icon-taxon:before, i.ph-option.icon-unknown:before, i.ph-option.icon-nothing:before, i.ph-option.icon-sampling:before, i.ph-option.icon-isolation:before, i.ph-option.icon-strain:before, i.ph-option.icon-gps:before, i.ph-option.icon-location:before, i.ph-option.icon-add:before, i.ph-option.icon-left:before, i.ph-option.icon-dna:before, i.ph-option.icon-delete:before, i.ph-option.icon-edit:before, i.ph-option.icon-info:before {
  content: "\e8a8";
}

.ph.ph-orange:before, i.ph-orange.icon-dead:before, i.ph-orange.icon-alive:before, i.ph-orange.icon-warn:before, i.ph-orange.icon-sign-out:before, i.ph-orange.icon-substratum:before, i.ph-orange.icon-environment:before, i.ph-orange.icon-user:before, i.ph-orange.icon-person:before, i.ph-orange.icon-terrain:before, i.ph-orange.icon-taxon:before, i.ph-orange.icon-unknown:before, i.ph-orange.icon-nothing:before, i.ph-orange.icon-sampling:before, i.ph-orange.icon-isolation:before, i.ph-orange.icon-strain:before, i.ph-orange.icon-gps:before, i.ph-orange.icon-location:before, i.ph-orange.icon-add:before, i.ph-orange.icon-left:before, i.ph-orange.icon-dna:before, i.ph-orange.icon-delete:before, i.ph-orange.icon-edit:before, i.ph-orange.icon-info:before {
  content: "\ee40";
}

.ph.ph-orange-slice:before, i.ph-orange-slice.icon-dead:before, i.ph-orange-slice.icon-alive:before, i.ph-orange-slice.icon-warn:before, i.ph-orange-slice.icon-sign-out:before, i.ph-orange-slice.icon-substratum:before, i.ph-orange-slice.icon-environment:before, i.ph-orange-slice.icon-user:before, i.ph-orange-slice.icon-person:before, i.ph-orange-slice.icon-terrain:before, i.ph-orange-slice.icon-taxon:before, i.ph-orange-slice.icon-unknown:before, i.ph-orange-slice.icon-nothing:before, i.ph-orange-slice.icon-sampling:before, i.ph-orange-slice.icon-isolation:before, i.ph-orange-slice.icon-strain:before, i.ph-orange-slice.icon-gps:before, i.ph-orange-slice.icon-location:before, i.ph-orange-slice.icon-add:before, i.ph-orange-slice.icon-left:before, i.ph-orange-slice.icon-dna:before, i.ph-orange-slice.icon-delete:before, i.ph-orange-slice.icon-edit:before, i.ph-orange-slice.icon-info:before {
  content: "\ed36";
}

.ph.ph-oven:before, i.ph-oven.icon-dead:before, i.ph-oven.icon-alive:before, i.ph-oven.icon-warn:before, i.ph-oven.icon-sign-out:before, i.ph-oven.icon-substratum:before, i.ph-oven.icon-environment:before, i.ph-oven.icon-user:before, i.ph-oven.icon-person:before, i.ph-oven.icon-terrain:before, i.ph-oven.icon-taxon:before, i.ph-oven.icon-unknown:before, i.ph-oven.icon-nothing:before, i.ph-oven.icon-sampling:before, i.ph-oven.icon-isolation:before, i.ph-oven.icon-strain:before, i.ph-oven.icon-gps:before, i.ph-oven.icon-location:before, i.ph-oven.icon-add:before, i.ph-oven.icon-left:before, i.ph-oven.icon-dna:before, i.ph-oven.icon-delete:before, i.ph-oven.icon-edit:before, i.ph-oven.icon-info:before {
  content: "\ed8c";
}

.ph.ph-package:before, i.ph-package.icon-dead:before, i.ph-package.icon-alive:before, i.ph-package.icon-warn:before, i.ph-package.icon-sign-out:before, i.ph-package.icon-substratum:before, i.ph-package.icon-environment:before, i.ph-package.icon-user:before, i.ph-package.icon-person:before, i.ph-package.icon-terrain:before, i.ph-package.icon-taxon:before, i.ph-package.icon-unknown:before, i.ph-package.icon-nothing:before, i.ph-package.icon-sampling:before, i.ph-package.icon-isolation:before, i.ph-package.icon-strain:before, i.ph-package.icon-gps:before, i.ph-package.icon-location:before, i.ph-package.icon-add:before, i.ph-package.icon-left:before, i.ph-package.icon-dna:before, i.ph-package.icon-delete:before, i.ph-package.icon-edit:before, i.ph-package.icon-info:before {
  content: "\e390";
}

.ph.ph-paint-brush:before, i.ph-paint-brush.icon-dead:before, i.ph-paint-brush.icon-alive:before, i.ph-paint-brush.icon-warn:before, i.ph-paint-brush.icon-sign-out:before, i.ph-paint-brush.icon-substratum:before, i.ph-paint-brush.icon-environment:before, i.ph-paint-brush.icon-user:before, i.ph-paint-brush.icon-person:before, i.ph-paint-brush.icon-terrain:before, i.ph-paint-brush.icon-taxon:before, i.ph-paint-brush.icon-unknown:before, i.ph-paint-brush.icon-nothing:before, i.ph-paint-brush.icon-sampling:before, i.ph-paint-brush.icon-isolation:before, i.ph-paint-brush.icon-strain:before, i.ph-paint-brush.icon-gps:before, i.ph-paint-brush.icon-location:before, i.ph-paint-brush.icon-add:before, i.ph-paint-brush.icon-left:before, i.ph-paint-brush.icon-dna:before, i.ph-paint-brush.icon-delete:before, i.ph-paint-brush.icon-edit:before, i.ph-paint-brush.icon-info:before {
  content: "\e6f0";
}

.ph.ph-paint-brush-broad:before, i.ph-paint-brush-broad.icon-dead:before, i.ph-paint-brush-broad.icon-alive:before, i.ph-paint-brush-broad.icon-warn:before, i.ph-paint-brush-broad.icon-sign-out:before, i.ph-paint-brush-broad.icon-substratum:before, i.ph-paint-brush-broad.icon-environment:before, i.ph-paint-brush-broad.icon-user:before, i.ph-paint-brush-broad.icon-person:before, i.ph-paint-brush-broad.icon-terrain:before, i.ph-paint-brush-broad.icon-taxon:before, i.ph-paint-brush-broad.icon-unknown:before, i.ph-paint-brush-broad.icon-nothing:before, i.ph-paint-brush-broad.icon-sampling:before, i.ph-paint-brush-broad.icon-isolation:before, i.ph-paint-brush-broad.icon-strain:before, i.ph-paint-brush-broad.icon-gps:before, i.ph-paint-brush-broad.icon-location:before, i.ph-paint-brush-broad.icon-add:before, i.ph-paint-brush-broad.icon-left:before, i.ph-paint-brush-broad.icon-dna:before, i.ph-paint-brush-broad.icon-delete:before, i.ph-paint-brush-broad.icon-edit:before, i.ph-paint-brush-broad.icon-info:before {
  content: "\e590";
}

.ph.ph-paint-brush-household:before, i.ph-paint-brush-household.icon-dead:before, i.ph-paint-brush-household.icon-alive:before, i.ph-paint-brush-household.icon-warn:before, i.ph-paint-brush-household.icon-sign-out:before, i.ph-paint-brush-household.icon-substratum:before, i.ph-paint-brush-household.icon-environment:before, i.ph-paint-brush-household.icon-user:before, i.ph-paint-brush-household.icon-person:before, i.ph-paint-brush-household.icon-terrain:before, i.ph-paint-brush-household.icon-taxon:before, i.ph-paint-brush-household.icon-unknown:before, i.ph-paint-brush-household.icon-nothing:before, i.ph-paint-brush-household.icon-sampling:before, i.ph-paint-brush-household.icon-isolation:before, i.ph-paint-brush-household.icon-strain:before, i.ph-paint-brush-household.icon-gps:before, i.ph-paint-brush-household.icon-location:before, i.ph-paint-brush-household.icon-add:before, i.ph-paint-brush-household.icon-left:before, i.ph-paint-brush-household.icon-dna:before, i.ph-paint-brush-household.icon-delete:before, i.ph-paint-brush-household.icon-edit:before, i.ph-paint-brush-household.icon-info:before {
  content: "\e6f2";
}

.ph.ph-paint-bucket:before, i.ph-paint-bucket.icon-dead:before, i.ph-paint-bucket.icon-alive:before, i.ph-paint-bucket.icon-warn:before, i.ph-paint-bucket.icon-sign-out:before, i.ph-paint-bucket.icon-substratum:before, i.ph-paint-bucket.icon-environment:before, i.ph-paint-bucket.icon-user:before, i.ph-paint-bucket.icon-person:before, i.ph-paint-bucket.icon-terrain:before, i.ph-paint-bucket.icon-taxon:before, i.ph-paint-bucket.icon-unknown:before, i.ph-paint-bucket.icon-nothing:before, i.ph-paint-bucket.icon-sampling:before, i.ph-paint-bucket.icon-isolation:before, i.ph-paint-bucket.icon-strain:before, i.ph-paint-bucket.icon-gps:before, i.ph-paint-bucket.icon-location:before, i.ph-paint-bucket.icon-add:before, i.ph-paint-bucket.icon-left:before, i.ph-paint-bucket.icon-dna:before, i.ph-paint-bucket.icon-delete:before, i.ph-paint-bucket.icon-edit:before, i.ph-paint-bucket.icon-info:before {
  content: "\e392";
}

.ph.ph-paint-roller:before, i.ph-paint-roller.icon-dead:before, i.ph-paint-roller.icon-alive:before, i.ph-paint-roller.icon-warn:before, i.ph-paint-roller.icon-sign-out:before, i.ph-paint-roller.icon-substratum:before, i.ph-paint-roller.icon-environment:before, i.ph-paint-roller.icon-user:before, i.ph-paint-roller.icon-person:before, i.ph-paint-roller.icon-terrain:before, i.ph-paint-roller.icon-taxon:before, i.ph-paint-roller.icon-unknown:before, i.ph-paint-roller.icon-nothing:before, i.ph-paint-roller.icon-sampling:before, i.ph-paint-roller.icon-isolation:before, i.ph-paint-roller.icon-strain:before, i.ph-paint-roller.icon-gps:before, i.ph-paint-roller.icon-location:before, i.ph-paint-roller.icon-add:before, i.ph-paint-roller.icon-left:before, i.ph-paint-roller.icon-dna:before, i.ph-paint-roller.icon-delete:before, i.ph-paint-roller.icon-edit:before, i.ph-paint-roller.icon-info:before {
  content: "\e6f4";
}

.ph.ph-palette:before, i.ph-palette.icon-dead:before, i.ph-palette.icon-alive:before, i.ph-palette.icon-warn:before, i.ph-palette.icon-sign-out:before, i.ph-palette.icon-substratum:before, i.ph-palette.icon-environment:before, i.ph-palette.icon-user:before, i.ph-palette.icon-person:before, i.ph-palette.icon-terrain:before, i.ph-palette.icon-taxon:before, i.ph-palette.icon-unknown:before, i.ph-palette.icon-nothing:before, i.ph-palette.icon-sampling:before, i.ph-palette.icon-isolation:before, i.ph-palette.icon-strain:before, i.ph-palette.icon-gps:before, i.ph-palette.icon-location:before, i.ph-palette.icon-add:before, i.ph-palette.icon-left:before, i.ph-palette.icon-dna:before, i.ph-palette.icon-delete:before, i.ph-palette.icon-edit:before, i.ph-palette.icon-info:before {
  content: "\e6c8";
}

.ph.ph-panorama:before, i.ph-panorama.icon-dead:before, i.ph-panorama.icon-alive:before, i.ph-panorama.icon-warn:before, i.ph-panorama.icon-sign-out:before, i.ph-panorama.icon-substratum:before, i.ph-panorama.icon-environment:before, i.ph-panorama.icon-user:before, i.ph-panorama.icon-person:before, i.ph-panorama.icon-terrain:before, i.ph-panorama.icon-taxon:before, i.ph-panorama.icon-unknown:before, i.ph-panorama.icon-nothing:before, i.ph-panorama.icon-sampling:before, i.ph-panorama.icon-isolation:before, i.ph-panorama.icon-strain:before, i.ph-panorama.icon-gps:before, i.ph-panorama.icon-location:before, i.ph-panorama.icon-add:before, i.ph-panorama.icon-left:before, i.ph-panorama.icon-dna:before, i.ph-panorama.icon-delete:before, i.ph-panorama.icon-edit:before, i.ph-panorama.icon-info:before {
  content: "\eaa2";
}

.ph.ph-pants:before, i.ph-pants.icon-dead:before, i.ph-pants.icon-alive:before, i.ph-pants.icon-warn:before, i.ph-pants.icon-sign-out:before, i.ph-pants.icon-substratum:before, i.ph-pants.icon-environment:before, i.ph-pants.icon-user:before, i.ph-pants.icon-person:before, i.ph-pants.icon-terrain:before, i.ph-pants.icon-taxon:before, i.ph-pants.icon-unknown:before, i.ph-pants.icon-nothing:before, i.ph-pants.icon-sampling:before, i.ph-pants.icon-isolation:before, i.ph-pants.icon-strain:before, i.ph-pants.icon-gps:before, i.ph-pants.icon-location:before, i.ph-pants.icon-add:before, i.ph-pants.icon-left:before, i.ph-pants.icon-dna:before, i.ph-pants.icon-delete:before, i.ph-pants.icon-edit:before, i.ph-pants.icon-info:before {
  content: "\ec88";
}

.ph.ph-paper-plane:before, i.ph-paper-plane.icon-dead:before, i.ph-paper-plane.icon-alive:before, i.ph-paper-plane.icon-warn:before, i.ph-paper-plane.icon-sign-out:before, i.ph-paper-plane.icon-substratum:before, i.ph-paper-plane.icon-environment:before, i.ph-paper-plane.icon-user:before, i.ph-paper-plane.icon-person:before, i.ph-paper-plane.icon-terrain:before, i.ph-paper-plane.icon-taxon:before, i.ph-paper-plane.icon-unknown:before, i.ph-paper-plane.icon-nothing:before, i.ph-paper-plane.icon-sampling:before, i.ph-paper-plane.icon-isolation:before, i.ph-paper-plane.icon-strain:before, i.ph-paper-plane.icon-gps:before, i.ph-paper-plane.icon-location:before, i.ph-paper-plane.icon-add:before, i.ph-paper-plane.icon-left:before, i.ph-paper-plane.icon-dna:before, i.ph-paper-plane.icon-delete:before, i.ph-paper-plane.icon-edit:before, i.ph-paper-plane.icon-info:before {
  content: "\e394";
}

.ph.ph-paper-plane-right:before, i.ph-paper-plane-right.icon-dead:before, i.ph-paper-plane-right.icon-alive:before, i.ph-paper-plane-right.icon-warn:before, i.ph-paper-plane-right.icon-sign-out:before, i.ph-paper-plane-right.icon-substratum:before, i.ph-paper-plane-right.icon-environment:before, i.ph-paper-plane-right.icon-user:before, i.ph-paper-plane-right.icon-person:before, i.ph-paper-plane-right.icon-terrain:before, i.ph-paper-plane-right.icon-taxon:before, i.ph-paper-plane-right.icon-unknown:before, i.ph-paper-plane-right.icon-nothing:before, i.ph-paper-plane-right.icon-sampling:before, i.ph-paper-plane-right.icon-isolation:before, i.ph-paper-plane-right.icon-strain:before, i.ph-paper-plane-right.icon-gps:before, i.ph-paper-plane-right.icon-location:before, i.ph-paper-plane-right.icon-add:before, i.ph-paper-plane-right.icon-left:before, i.ph-paper-plane-right.icon-dna:before, i.ph-paper-plane-right.icon-delete:before, i.ph-paper-plane-right.icon-edit:before, i.ph-paper-plane-right.icon-info:before {
  content: "\e396";
}

.ph.ph-paper-plane-tilt:before, i.ph-paper-plane-tilt.icon-dead:before, i.ph-paper-plane-tilt.icon-alive:before, i.ph-paper-plane-tilt.icon-warn:before, i.ph-paper-plane-tilt.icon-sign-out:before, i.ph-paper-plane-tilt.icon-substratum:before, i.ph-paper-plane-tilt.icon-environment:before, i.ph-paper-plane-tilt.icon-user:before, i.ph-paper-plane-tilt.icon-person:before, i.ph-paper-plane-tilt.icon-terrain:before, i.ph-paper-plane-tilt.icon-taxon:before, i.ph-paper-plane-tilt.icon-unknown:before, i.ph-paper-plane-tilt.icon-nothing:before, i.ph-paper-plane-tilt.icon-sampling:before, i.ph-paper-plane-tilt.icon-isolation:before, i.ph-paper-plane-tilt.icon-strain:before, i.ph-paper-plane-tilt.icon-gps:before, i.ph-paper-plane-tilt.icon-location:before, i.ph-paper-plane-tilt.icon-add:before, i.ph-paper-plane-tilt.icon-left:before, i.ph-paper-plane-tilt.icon-dna:before, i.ph-paper-plane-tilt.icon-delete:before, i.ph-paper-plane-tilt.icon-edit:before, i.ph-paper-plane-tilt.icon-info:before {
  content: "\e398";
}

.ph.ph-paperclip:before, i.ph-paperclip.icon-dead:before, i.ph-paperclip.icon-alive:before, i.ph-paperclip.icon-warn:before, i.ph-paperclip.icon-sign-out:before, i.ph-paperclip.icon-substratum:before, i.ph-paperclip.icon-environment:before, i.ph-paperclip.icon-user:before, i.ph-paperclip.icon-person:before, i.ph-paperclip.icon-terrain:before, i.ph-paperclip.icon-taxon:before, i.ph-paperclip.icon-unknown:before, i.ph-paperclip.icon-nothing:before, i.ph-paperclip.icon-sampling:before, i.ph-paperclip.icon-isolation:before, i.ph-paperclip.icon-strain:before, i.ph-paperclip.icon-gps:before, i.ph-paperclip.icon-location:before, i.ph-paperclip.icon-add:before, i.ph-paperclip.icon-left:before, i.ph-paperclip.icon-dna:before, i.ph-paperclip.icon-delete:before, i.ph-paperclip.icon-edit:before, i.ph-paperclip.icon-info:before {
  content: "\e39a";
}

.ph.ph-paperclip-horizontal:before, i.ph-paperclip-horizontal.icon-dead:before, i.ph-paperclip-horizontal.icon-alive:before, i.ph-paperclip-horizontal.icon-warn:before, i.ph-paperclip-horizontal.icon-sign-out:before, i.ph-paperclip-horizontal.icon-substratum:before, i.ph-paperclip-horizontal.icon-environment:before, i.ph-paperclip-horizontal.icon-user:before, i.ph-paperclip-horizontal.icon-person:before, i.ph-paperclip-horizontal.icon-terrain:before, i.ph-paperclip-horizontal.icon-taxon:before, i.ph-paperclip-horizontal.icon-unknown:before, i.ph-paperclip-horizontal.icon-nothing:before, i.ph-paperclip-horizontal.icon-sampling:before, i.ph-paperclip-horizontal.icon-isolation:before, i.ph-paperclip-horizontal.icon-strain:before, i.ph-paperclip-horizontal.icon-gps:before, i.ph-paperclip-horizontal.icon-location:before, i.ph-paperclip-horizontal.icon-add:before, i.ph-paperclip-horizontal.icon-left:before, i.ph-paperclip-horizontal.icon-dna:before, i.ph-paperclip-horizontal.icon-delete:before, i.ph-paperclip-horizontal.icon-edit:before, i.ph-paperclip-horizontal.icon-info:before {
  content: "\e592";
}

.ph.ph-parachute:before, i.ph-parachute.icon-dead:before, i.ph-parachute.icon-alive:before, i.ph-parachute.icon-warn:before, i.ph-parachute.icon-sign-out:before, i.ph-parachute.icon-substratum:before, i.ph-parachute.icon-environment:before, i.ph-parachute.icon-user:before, i.ph-parachute.icon-person:before, i.ph-parachute.icon-terrain:before, i.ph-parachute.icon-taxon:before, i.ph-parachute.icon-unknown:before, i.ph-parachute.icon-nothing:before, i.ph-parachute.icon-sampling:before, i.ph-parachute.icon-isolation:before, i.ph-parachute.icon-strain:before, i.ph-parachute.icon-gps:before, i.ph-parachute.icon-location:before, i.ph-parachute.icon-add:before, i.ph-parachute.icon-left:before, i.ph-parachute.icon-dna:before, i.ph-parachute.icon-delete:before, i.ph-parachute.icon-edit:before, i.ph-parachute.icon-info:before {
  content: "\ea7c";
}

.ph.ph-paragraph:before, i.ph-paragraph.icon-dead:before, i.ph-paragraph.icon-alive:before, i.ph-paragraph.icon-warn:before, i.ph-paragraph.icon-sign-out:before, i.ph-paragraph.icon-substratum:before, i.ph-paragraph.icon-environment:before, i.ph-paragraph.icon-user:before, i.ph-paragraph.icon-person:before, i.ph-paragraph.icon-terrain:before, i.ph-paragraph.icon-taxon:before, i.ph-paragraph.icon-unknown:before, i.ph-paragraph.icon-nothing:before, i.ph-paragraph.icon-sampling:before, i.ph-paragraph.icon-isolation:before, i.ph-paragraph.icon-strain:before, i.ph-paragraph.icon-gps:before, i.ph-paragraph.icon-location:before, i.ph-paragraph.icon-add:before, i.ph-paragraph.icon-left:before, i.ph-paragraph.icon-dna:before, i.ph-paragraph.icon-delete:before, i.ph-paragraph.icon-edit:before, i.ph-paragraph.icon-info:before {
  content: "\e960";
}

.ph.ph-parallelogram:before, i.ph-parallelogram.icon-dead:before, i.ph-parallelogram.icon-alive:before, i.ph-parallelogram.icon-warn:before, i.ph-parallelogram.icon-sign-out:before, i.ph-parallelogram.icon-substratum:before, i.ph-parallelogram.icon-environment:before, i.ph-parallelogram.icon-user:before, i.ph-parallelogram.icon-person:before, i.ph-parallelogram.icon-terrain:before, i.ph-parallelogram.icon-taxon:before, i.ph-parallelogram.icon-unknown:before, i.ph-parallelogram.icon-nothing:before, i.ph-parallelogram.icon-sampling:before, i.ph-parallelogram.icon-isolation:before, i.ph-parallelogram.icon-strain:before, i.ph-parallelogram.icon-gps:before, i.ph-parallelogram.icon-location:before, i.ph-parallelogram.icon-add:before, i.ph-parallelogram.icon-left:before, i.ph-parallelogram.icon-dna:before, i.ph-parallelogram.icon-delete:before, i.ph-parallelogram.icon-edit:before, i.ph-parallelogram.icon-info:before {
  content: "\ecc6";
}

.ph.ph-park:before, i.ph-park.icon-dead:before, i.ph-park.icon-alive:before, i.ph-park.icon-warn:before, i.ph-park.icon-sign-out:before, i.ph-park.icon-substratum:before, i.ph-park.icon-environment:before, i.ph-park.icon-user:before, i.ph-park.icon-person:before, i.ph-park.icon-terrain:before, i.ph-park.icon-taxon:before, i.ph-park.icon-unknown:before, i.ph-park.icon-nothing:before, i.ph-park.icon-sampling:before, i.ph-park.icon-isolation:before, i.ph-park.icon-strain:before, i.ph-park.icon-gps:before, i.ph-park.icon-location:before, i.ph-park.icon-add:before, i.ph-park.icon-left:before, i.ph-park.icon-dna:before, i.ph-park.icon-delete:before, i.ph-park.icon-edit:before, i.ph-park.icon-info:before {
  content: "\ecb2";
}

.ph.ph-password:before, i.ph-password.icon-dead:before, i.ph-password.icon-alive:before, i.ph-password.icon-warn:before, i.ph-password.icon-sign-out:before, i.ph-password.icon-substratum:before, i.ph-password.icon-environment:before, i.ph-password.icon-user:before, i.ph-password.icon-person:before, i.ph-password.icon-terrain:before, i.ph-password.icon-taxon:before, i.ph-password.icon-unknown:before, i.ph-password.icon-nothing:before, i.ph-password.icon-sampling:before, i.ph-password.icon-isolation:before, i.ph-password.icon-strain:before, i.ph-password.icon-gps:before, i.ph-password.icon-location:before, i.ph-password.icon-add:before, i.ph-password.icon-left:before, i.ph-password.icon-dna:before, i.ph-password.icon-delete:before, i.ph-password.icon-edit:before, i.ph-password.icon-info:before {
  content: "\e752";
}

.ph.ph-path:before, i.ph-path.icon-dead:before, i.ph-path.icon-alive:before, i.ph-path.icon-warn:before, i.ph-path.icon-sign-out:before, i.ph-path.icon-substratum:before, i.ph-path.icon-environment:before, i.ph-path.icon-user:before, i.ph-path.icon-person:before, i.ph-path.icon-terrain:before, i.ph-path.icon-taxon:before, i.ph-path.icon-unknown:before, i.ph-path.icon-nothing:before, i.ph-path.icon-sampling:before, i.ph-path.icon-isolation:before, i.ph-path.icon-strain:before, i.ph-path.icon-gps:before, i.ph-path.icon-location:before, i.ph-path.icon-add:before, i.ph-path.icon-left:before, i.ph-path.icon-dna:before, i.ph-path.icon-delete:before, i.ph-path.icon-edit:before, i.ph-path.icon-info:before {
  content: "\e39c";
}

.ph.ph-patreon-logo:before, i.ph-patreon-logo.icon-dead:before, i.ph-patreon-logo.icon-alive:before, i.ph-patreon-logo.icon-warn:before, i.ph-patreon-logo.icon-sign-out:before, i.ph-patreon-logo.icon-substratum:before, i.ph-patreon-logo.icon-environment:before, i.ph-patreon-logo.icon-user:before, i.ph-patreon-logo.icon-person:before, i.ph-patreon-logo.icon-terrain:before, i.ph-patreon-logo.icon-taxon:before, i.ph-patreon-logo.icon-unknown:before, i.ph-patreon-logo.icon-nothing:before, i.ph-patreon-logo.icon-sampling:before, i.ph-patreon-logo.icon-isolation:before, i.ph-patreon-logo.icon-strain:before, i.ph-patreon-logo.icon-gps:before, i.ph-patreon-logo.icon-location:before, i.ph-patreon-logo.icon-add:before, i.ph-patreon-logo.icon-left:before, i.ph-patreon-logo.icon-dna:before, i.ph-patreon-logo.icon-delete:before, i.ph-patreon-logo.icon-edit:before, i.ph-patreon-logo.icon-info:before {
  content: "\e98a";
}

.ph.ph-pause:before, i.ph-pause.icon-dead:before, i.ph-pause.icon-alive:before, i.ph-pause.icon-warn:before, i.ph-pause.icon-sign-out:before, i.ph-pause.icon-substratum:before, i.ph-pause.icon-environment:before, i.ph-pause.icon-user:before, i.ph-pause.icon-person:before, i.ph-pause.icon-terrain:before, i.ph-pause.icon-taxon:before, i.ph-pause.icon-unknown:before, i.ph-pause.icon-nothing:before, i.ph-pause.icon-sampling:before, i.ph-pause.icon-isolation:before, i.ph-pause.icon-strain:before, i.ph-pause.icon-gps:before, i.ph-pause.icon-location:before, i.ph-pause.icon-add:before, i.ph-pause.icon-left:before, i.ph-pause.icon-dna:before, i.ph-pause.icon-delete:before, i.ph-pause.icon-edit:before, i.ph-pause.icon-info:before {
  content: "\e39e";
}

.ph.ph-pause-circle:before, i.ph-pause-circle.icon-dead:before, i.ph-pause-circle.icon-alive:before, i.ph-pause-circle.icon-warn:before, i.ph-pause-circle.icon-sign-out:before, i.ph-pause-circle.icon-substratum:before, i.ph-pause-circle.icon-environment:before, i.ph-pause-circle.icon-user:before, i.ph-pause-circle.icon-person:before, i.ph-pause-circle.icon-terrain:before, i.ph-pause-circle.icon-taxon:before, i.ph-pause-circle.icon-unknown:before, i.ph-pause-circle.icon-nothing:before, i.ph-pause-circle.icon-sampling:before, i.ph-pause-circle.icon-isolation:before, i.ph-pause-circle.icon-strain:before, i.ph-pause-circle.icon-gps:before, i.ph-pause-circle.icon-location:before, i.ph-pause-circle.icon-add:before, i.ph-pause-circle.icon-left:before, i.ph-pause-circle.icon-dna:before, i.ph-pause-circle.icon-delete:before, i.ph-pause-circle.icon-edit:before, i.ph-pause-circle.icon-info:before {
  content: "\e3a0";
}

.ph.ph-paw-print:before, i.ph-paw-print.icon-dead:before, i.ph-paw-print.icon-alive:before, i.ph-paw-print.icon-warn:before, i.ph-paw-print.icon-sign-out:before, i.ph-paw-print.icon-substratum:before, i.ph-paw-print.icon-environment:before, i.ph-paw-print.icon-user:before, i.ph-paw-print.icon-person:before, i.ph-paw-print.icon-terrain:before, i.ph-paw-print.icon-taxon:before, i.ph-paw-print.icon-unknown:before, i.ph-paw-print.icon-nothing:before, i.ph-paw-print.icon-sampling:before, i.ph-paw-print.icon-isolation:before, i.ph-paw-print.icon-strain:before, i.ph-paw-print.icon-gps:before, i.ph-paw-print.icon-location:before, i.ph-paw-print.icon-add:before, i.ph-paw-print.icon-left:before, i.ph-paw-print.icon-dna:before, i.ph-paw-print.icon-delete:before, i.ph-paw-print.icon-edit:before, i.ph-paw-print.icon-info:before {
  content: "\e648";
}

.ph.ph-paypal-logo:before, i.ph-paypal-logo.icon-dead:before, i.ph-paypal-logo.icon-alive:before, i.ph-paypal-logo.icon-warn:before, i.ph-paypal-logo.icon-sign-out:before, i.ph-paypal-logo.icon-substratum:before, i.ph-paypal-logo.icon-environment:before, i.ph-paypal-logo.icon-user:before, i.ph-paypal-logo.icon-person:before, i.ph-paypal-logo.icon-terrain:before, i.ph-paypal-logo.icon-taxon:before, i.ph-paypal-logo.icon-unknown:before, i.ph-paypal-logo.icon-nothing:before, i.ph-paypal-logo.icon-sampling:before, i.ph-paypal-logo.icon-isolation:before, i.ph-paypal-logo.icon-strain:before, i.ph-paypal-logo.icon-gps:before, i.ph-paypal-logo.icon-location:before, i.ph-paypal-logo.icon-add:before, i.ph-paypal-logo.icon-left:before, i.ph-paypal-logo.icon-dna:before, i.ph-paypal-logo.icon-delete:before, i.ph-paypal-logo.icon-edit:before, i.ph-paypal-logo.icon-info:before {
  content: "\e98c";
}

.ph.ph-peace:before, i.ph-peace.icon-dead:before, i.ph-peace.icon-alive:before, i.ph-peace.icon-warn:before, i.ph-peace.icon-sign-out:before, i.ph-peace.icon-substratum:before, i.ph-peace.icon-environment:before, i.ph-peace.icon-user:before, i.ph-peace.icon-person:before, i.ph-peace.icon-terrain:before, i.ph-peace.icon-taxon:before, i.ph-peace.icon-unknown:before, i.ph-peace.icon-nothing:before, i.ph-peace.icon-sampling:before, i.ph-peace.icon-isolation:before, i.ph-peace.icon-strain:before, i.ph-peace.icon-gps:before, i.ph-peace.icon-location:before, i.ph-peace.icon-add:before, i.ph-peace.icon-left:before, i.ph-peace.icon-dna:before, i.ph-peace.icon-delete:before, i.ph-peace.icon-edit:before, i.ph-peace.icon-info:before {
  content: "\e3a2";
}

.ph.ph-pen:before, i.ph-pen.icon-dead:before, i.ph-pen.icon-alive:before, i.ph-pen.icon-warn:before, i.ph-pen.icon-sign-out:before, i.ph-pen.icon-substratum:before, i.ph-pen.icon-environment:before, i.ph-pen.icon-user:before, i.ph-pen.icon-person:before, i.ph-pen.icon-terrain:before, i.ph-pen.icon-taxon:before, i.ph-pen.icon-unknown:before, i.ph-pen.icon-nothing:before, i.ph-pen.icon-sampling:before, i.ph-pen.icon-isolation:before, i.ph-pen.icon-strain:before, i.ph-pen.icon-gps:before, i.ph-pen.icon-location:before, i.ph-pen.icon-add:before, i.ph-pen.icon-left:before, i.ph-pen.icon-dna:before, i.ph-pen.icon-delete:before, i.ph-pen.icon-edit:before, i.ph-pen.icon-info:before {
  content: "\e3aa";
}

.ph.ph-pen-nib:before, i.ph-pen-nib.icon-dead:before, i.ph-pen-nib.icon-alive:before, i.ph-pen-nib.icon-warn:before, i.ph-pen-nib.icon-sign-out:before, i.ph-pen-nib.icon-substratum:before, i.ph-pen-nib.icon-environment:before, i.ph-pen-nib.icon-user:before, i.ph-pen-nib.icon-person:before, i.ph-pen-nib.icon-terrain:before, i.ph-pen-nib.icon-taxon:before, i.ph-pen-nib.icon-unknown:before, i.ph-pen-nib.icon-nothing:before, i.ph-pen-nib.icon-sampling:before, i.ph-pen-nib.icon-isolation:before, i.ph-pen-nib.icon-strain:before, i.ph-pen-nib.icon-gps:before, i.ph-pen-nib.icon-location:before, i.ph-pen-nib.icon-add:before, i.ph-pen-nib.icon-left:before, i.ph-pen-nib.icon-dna:before, i.ph-pen-nib.icon-delete:before, i.ph-pen-nib.icon-edit:before, i.ph-pen-nib.icon-info:before {
  content: "\e3ac";
}

.ph.ph-pen-nib-straight:before, i.ph-pen-nib-straight.icon-dead:before, i.ph-pen-nib-straight.icon-alive:before, i.ph-pen-nib-straight.icon-warn:before, i.ph-pen-nib-straight.icon-sign-out:before, i.ph-pen-nib-straight.icon-substratum:before, i.ph-pen-nib-straight.icon-environment:before, i.ph-pen-nib-straight.icon-user:before, i.ph-pen-nib-straight.icon-person:before, i.ph-pen-nib-straight.icon-terrain:before, i.ph-pen-nib-straight.icon-taxon:before, i.ph-pen-nib-straight.icon-unknown:before, i.ph-pen-nib-straight.icon-nothing:before, i.ph-pen-nib-straight.icon-sampling:before, i.ph-pen-nib-straight.icon-isolation:before, i.ph-pen-nib-straight.icon-strain:before, i.ph-pen-nib-straight.icon-gps:before, i.ph-pen-nib-straight.icon-location:before, i.ph-pen-nib-straight.icon-add:before, i.ph-pen-nib-straight.icon-left:before, i.ph-pen-nib-straight.icon-dna:before, i.ph-pen-nib-straight.icon-delete:before, i.ph-pen-nib-straight.icon-edit:before, i.ph-pen-nib-straight.icon-info:before {
  content: "\e64a";
}

.ph.ph-pencil:before, i.ph-pencil.icon-dead:before, i.ph-pencil.icon-alive:before, i.ph-pencil.icon-warn:before, i.ph-pencil.icon-sign-out:before, i.ph-pencil.icon-substratum:before, i.ph-pencil.icon-environment:before, i.ph-pencil.icon-user:before, i.ph-pencil.icon-person:before, i.ph-pencil.icon-terrain:before, i.ph-pencil.icon-taxon:before, i.ph-pencil.icon-unknown:before, i.ph-pencil.icon-nothing:before, i.ph-pencil.icon-sampling:before, i.ph-pencil.icon-isolation:before, i.ph-pencil.icon-strain:before, i.ph-pencil.icon-gps:before, i.ph-pencil.icon-location:before, i.ph-pencil.icon-add:before, i.ph-pencil.icon-left:before, i.ph-pencil.icon-dna:before, i.ph-pencil.icon-delete:before, i.icon-edit:before, i.ph-pencil.icon-info:before {
  content: "\e3ae";
}

.ph.ph-pencil-circle:before, i.ph-pencil-circle.icon-dead:before, i.ph-pencil-circle.icon-alive:before, i.ph-pencil-circle.icon-warn:before, i.ph-pencil-circle.icon-sign-out:before, i.ph-pencil-circle.icon-substratum:before, i.ph-pencil-circle.icon-environment:before, i.ph-pencil-circle.icon-user:before, i.ph-pencil-circle.icon-person:before, i.ph-pencil-circle.icon-terrain:before, i.ph-pencil-circle.icon-taxon:before, i.ph-pencil-circle.icon-unknown:before, i.ph-pencil-circle.icon-nothing:before, i.ph-pencil-circle.icon-sampling:before, i.ph-pencil-circle.icon-isolation:before, i.ph-pencil-circle.icon-strain:before, i.ph-pencil-circle.icon-gps:before, i.ph-pencil-circle.icon-location:before, i.ph-pencil-circle.icon-add:before, i.ph-pencil-circle.icon-left:before, i.ph-pencil-circle.icon-dna:before, i.ph-pencil-circle.icon-delete:before, i.ph-pencil-circle.icon-edit:before, i.ph-pencil-circle.icon-info:before {
  content: "\e3b0";
}

.ph.ph-pencil-line:before, i.ph-pencil-line.icon-dead:before, i.ph-pencil-line.icon-alive:before, i.ph-pencil-line.icon-warn:before, i.ph-pencil-line.icon-sign-out:before, i.ph-pencil-line.icon-substratum:before, i.ph-pencil-line.icon-environment:before, i.ph-pencil-line.icon-user:before, i.ph-pencil-line.icon-person:before, i.ph-pencil-line.icon-terrain:before, i.ph-pencil-line.icon-taxon:before, i.ph-pencil-line.icon-unknown:before, i.ph-pencil-line.icon-nothing:before, i.ph-pencil-line.icon-sampling:before, i.ph-pencil-line.icon-isolation:before, i.ph-pencil-line.icon-strain:before, i.ph-pencil-line.icon-gps:before, i.ph-pencil-line.icon-location:before, i.ph-pencil-line.icon-add:before, i.ph-pencil-line.icon-left:before, i.ph-pencil-line.icon-dna:before, i.ph-pencil-line.icon-delete:before, i.ph-pencil-line.icon-edit:before, i.ph-pencil-line.icon-info:before {
  content: "\e3b2";
}

.ph.ph-pencil-ruler:before, i.ph-pencil-ruler.icon-dead:before, i.ph-pencil-ruler.icon-alive:before, i.ph-pencil-ruler.icon-warn:before, i.ph-pencil-ruler.icon-sign-out:before, i.ph-pencil-ruler.icon-substratum:before, i.ph-pencil-ruler.icon-environment:before, i.ph-pencil-ruler.icon-user:before, i.ph-pencil-ruler.icon-person:before, i.ph-pencil-ruler.icon-terrain:before, i.ph-pencil-ruler.icon-taxon:before, i.ph-pencil-ruler.icon-unknown:before, i.ph-pencil-ruler.icon-nothing:before, i.ph-pencil-ruler.icon-sampling:before, i.ph-pencil-ruler.icon-isolation:before, i.ph-pencil-ruler.icon-strain:before, i.ph-pencil-ruler.icon-gps:before, i.ph-pencil-ruler.icon-location:before, i.ph-pencil-ruler.icon-add:before, i.ph-pencil-ruler.icon-left:before, i.ph-pencil-ruler.icon-dna:before, i.ph-pencil-ruler.icon-delete:before, i.ph-pencil-ruler.icon-edit:before, i.ph-pencil-ruler.icon-info:before {
  content: "\e906";
}

.ph.ph-pencil-simple:before, i.ph-pencil-simple.icon-dead:before, i.ph-pencil-simple.icon-alive:before, i.ph-pencil-simple.icon-warn:before, i.ph-pencil-simple.icon-sign-out:before, i.ph-pencil-simple.icon-substratum:before, i.ph-pencil-simple.icon-environment:before, i.ph-pencil-simple.icon-user:before, i.ph-pencil-simple.icon-person:before, i.ph-pencil-simple.icon-terrain:before, i.ph-pencil-simple.icon-taxon:before, i.ph-pencil-simple.icon-unknown:before, i.ph-pencil-simple.icon-nothing:before, i.ph-pencil-simple.icon-sampling:before, i.ph-pencil-simple.icon-isolation:before, i.ph-pencil-simple.icon-strain:before, i.ph-pencil-simple.icon-gps:before, i.ph-pencil-simple.icon-location:before, i.ph-pencil-simple.icon-add:before, i.ph-pencil-simple.icon-left:before, i.ph-pencil-simple.icon-dna:before, i.ph-pencil-simple.icon-delete:before, i.ph-pencil-simple.icon-edit:before, i.ph-pencil-simple.icon-info:before {
  content: "\e3b4";
}

.ph.ph-pencil-simple-line:before, i.ph-pencil-simple-line.icon-dead:before, i.ph-pencil-simple-line.icon-alive:before, i.ph-pencil-simple-line.icon-warn:before, i.ph-pencil-simple-line.icon-sign-out:before, i.ph-pencil-simple-line.icon-substratum:before, i.ph-pencil-simple-line.icon-environment:before, i.ph-pencil-simple-line.icon-user:before, i.ph-pencil-simple-line.icon-person:before, i.ph-pencil-simple-line.icon-terrain:before, i.ph-pencil-simple-line.icon-taxon:before, i.ph-pencil-simple-line.icon-unknown:before, i.ph-pencil-simple-line.icon-nothing:before, i.ph-pencil-simple-line.icon-sampling:before, i.ph-pencil-simple-line.icon-isolation:before, i.ph-pencil-simple-line.icon-strain:before, i.ph-pencil-simple-line.icon-gps:before, i.ph-pencil-simple-line.icon-location:before, i.ph-pencil-simple-line.icon-add:before, i.ph-pencil-simple-line.icon-left:before, i.ph-pencil-simple-line.icon-dna:before, i.ph-pencil-simple-line.icon-delete:before, i.ph-pencil-simple-line.icon-edit:before, i.ph-pencil-simple-line.icon-info:before {
  content: "\ebc6";
}

.ph.ph-pencil-simple-slash:before, i.ph-pencil-simple-slash.icon-dead:before, i.ph-pencil-simple-slash.icon-alive:before, i.ph-pencil-simple-slash.icon-warn:before, i.ph-pencil-simple-slash.icon-sign-out:before, i.ph-pencil-simple-slash.icon-substratum:before, i.ph-pencil-simple-slash.icon-environment:before, i.ph-pencil-simple-slash.icon-user:before, i.ph-pencil-simple-slash.icon-person:before, i.ph-pencil-simple-slash.icon-terrain:before, i.ph-pencil-simple-slash.icon-taxon:before, i.ph-pencil-simple-slash.icon-unknown:before, i.ph-pencil-simple-slash.icon-nothing:before, i.ph-pencil-simple-slash.icon-sampling:before, i.ph-pencil-simple-slash.icon-isolation:before, i.ph-pencil-simple-slash.icon-strain:before, i.ph-pencil-simple-slash.icon-gps:before, i.ph-pencil-simple-slash.icon-location:before, i.ph-pencil-simple-slash.icon-add:before, i.ph-pencil-simple-slash.icon-left:before, i.ph-pencil-simple-slash.icon-dna:before, i.ph-pencil-simple-slash.icon-delete:before, i.ph-pencil-simple-slash.icon-edit:before, i.ph-pencil-simple-slash.icon-info:before {
  content: "\ecf6";
}

.ph.ph-pencil-slash:before, i.ph-pencil-slash.icon-dead:before, i.ph-pencil-slash.icon-alive:before, i.ph-pencil-slash.icon-warn:before, i.ph-pencil-slash.icon-sign-out:before, i.ph-pencil-slash.icon-substratum:before, i.ph-pencil-slash.icon-environment:before, i.ph-pencil-slash.icon-user:before, i.ph-pencil-slash.icon-person:before, i.ph-pencil-slash.icon-terrain:before, i.ph-pencil-slash.icon-taxon:before, i.ph-pencil-slash.icon-unknown:before, i.ph-pencil-slash.icon-nothing:before, i.ph-pencil-slash.icon-sampling:before, i.ph-pencil-slash.icon-isolation:before, i.ph-pencil-slash.icon-strain:before, i.ph-pencil-slash.icon-gps:before, i.ph-pencil-slash.icon-location:before, i.ph-pencil-slash.icon-add:before, i.ph-pencil-slash.icon-left:before, i.ph-pencil-slash.icon-dna:before, i.ph-pencil-slash.icon-delete:before, i.ph-pencil-slash.icon-edit:before, i.ph-pencil-slash.icon-info:before {
  content: "\ecf8";
}

.ph.ph-pentagon:before, i.ph-pentagon.icon-dead:before, i.ph-pentagon.icon-alive:before, i.ph-pentagon.icon-warn:before, i.ph-pentagon.icon-sign-out:before, i.ph-pentagon.icon-substratum:before, i.ph-pentagon.icon-environment:before, i.ph-pentagon.icon-user:before, i.ph-pentagon.icon-person:before, i.ph-pentagon.icon-terrain:before, i.ph-pentagon.icon-taxon:before, i.ph-pentagon.icon-unknown:before, i.ph-pentagon.icon-nothing:before, i.ph-pentagon.icon-sampling:before, i.ph-pentagon.icon-isolation:before, i.ph-pentagon.icon-strain:before, i.ph-pentagon.icon-gps:before, i.ph-pentagon.icon-location:before, i.ph-pentagon.icon-add:before, i.ph-pentagon.icon-left:before, i.ph-pentagon.icon-dna:before, i.ph-pentagon.icon-delete:before, i.ph-pentagon.icon-edit:before, i.ph-pentagon.icon-info:before {
  content: "\ec7e";
}

.ph.ph-pentagram:before, i.ph-pentagram.icon-dead:before, i.ph-pentagram.icon-alive:before, i.ph-pentagram.icon-warn:before, i.ph-pentagram.icon-sign-out:before, i.ph-pentagram.icon-substratum:before, i.ph-pentagram.icon-environment:before, i.ph-pentagram.icon-user:before, i.ph-pentagram.icon-person:before, i.ph-pentagram.icon-terrain:before, i.ph-pentagram.icon-taxon:before, i.ph-pentagram.icon-unknown:before, i.ph-pentagram.icon-nothing:before, i.ph-pentagram.icon-sampling:before, i.ph-pentagram.icon-isolation:before, i.ph-pentagram.icon-strain:before, i.ph-pentagram.icon-gps:before, i.ph-pentagram.icon-location:before, i.ph-pentagram.icon-add:before, i.ph-pentagram.icon-left:before, i.ph-pentagram.icon-dna:before, i.ph-pentagram.icon-delete:before, i.ph-pentagram.icon-edit:before, i.ph-pentagram.icon-info:before {
  content: "\ec5c";
}

.ph.ph-pepper:before, i.ph-pepper.icon-dead:before, i.ph-pepper.icon-alive:before, i.ph-pepper.icon-warn:before, i.ph-pepper.icon-sign-out:before, i.ph-pepper.icon-substratum:before, i.ph-pepper.icon-environment:before, i.ph-pepper.icon-user:before, i.ph-pepper.icon-person:before, i.ph-pepper.icon-terrain:before, i.ph-pepper.icon-taxon:before, i.ph-pepper.icon-unknown:before, i.ph-pepper.icon-nothing:before, i.ph-pepper.icon-sampling:before, i.ph-pepper.icon-isolation:before, i.ph-pepper.icon-strain:before, i.ph-pepper.icon-gps:before, i.ph-pepper.icon-location:before, i.ph-pepper.icon-add:before, i.ph-pepper.icon-left:before, i.ph-pepper.icon-dna:before, i.ph-pepper.icon-delete:before, i.ph-pepper.icon-edit:before, i.ph-pepper.icon-info:before {
  content: "\e94a";
}

.ph.ph-percent:before, i.ph-percent.icon-dead:before, i.ph-percent.icon-alive:before, i.ph-percent.icon-warn:before, i.ph-percent.icon-sign-out:before, i.ph-percent.icon-substratum:before, i.ph-percent.icon-environment:before, i.ph-percent.icon-user:before, i.ph-percent.icon-person:before, i.ph-percent.icon-terrain:before, i.ph-percent.icon-taxon:before, i.ph-percent.icon-unknown:before, i.ph-percent.icon-nothing:before, i.ph-percent.icon-sampling:before, i.ph-percent.icon-isolation:before, i.ph-percent.icon-strain:before, i.ph-percent.icon-gps:before, i.ph-percent.icon-location:before, i.ph-percent.icon-add:before, i.ph-percent.icon-left:before, i.ph-percent.icon-dna:before, i.ph-percent.icon-delete:before, i.ph-percent.icon-edit:before, i.ph-percent.icon-info:before {
  content: "\e3b6";
}

.ph.ph-person:before, i.ph-person.icon-dead:before, i.ph-person.icon-alive:before, i.ph-person.icon-warn:before, i.ph-person.icon-sign-out:before, i.ph-person.icon-substratum:before, i.ph-person.icon-environment:before, i.ph-person.icon-user:before, i.ph-person.icon-person:before, i.ph-person.icon-terrain:before, i.ph-person.icon-taxon:before, i.ph-person.icon-unknown:before, i.ph-person.icon-nothing:before, i.ph-person.icon-sampling:before, i.ph-person.icon-isolation:before, i.ph-person.icon-strain:before, i.ph-person.icon-gps:before, i.ph-person.icon-location:before, i.ph-person.icon-add:before, i.ph-person.icon-left:before, i.ph-person.icon-dna:before, i.ph-person.icon-delete:before, i.ph-person.icon-edit:before, i.ph-person.icon-info:before {
  content: "\e3a8";
}

.ph.ph-person-arms-spread:before, i.ph-person-arms-spread.icon-dead:before, i.ph-person-arms-spread.icon-alive:before, i.ph-person-arms-spread.icon-warn:before, i.ph-person-arms-spread.icon-sign-out:before, i.ph-person-arms-spread.icon-substratum:before, i.ph-person-arms-spread.icon-environment:before, i.ph-person-arms-spread.icon-user:before, i.ph-person-arms-spread.icon-person:before, i.ph-person-arms-spread.icon-terrain:before, i.ph-person-arms-spread.icon-taxon:before, i.ph-person-arms-spread.icon-unknown:before, i.ph-person-arms-spread.icon-nothing:before, i.ph-person-arms-spread.icon-sampling:before, i.ph-person-arms-spread.icon-isolation:before, i.ph-person-arms-spread.icon-strain:before, i.ph-person-arms-spread.icon-gps:before, i.ph-person-arms-spread.icon-location:before, i.ph-person-arms-spread.icon-add:before, i.ph-person-arms-spread.icon-left:before, i.ph-person-arms-spread.icon-dna:before, i.ph-person-arms-spread.icon-delete:before, i.ph-person-arms-spread.icon-edit:before, i.ph-person-arms-spread.icon-info:before {
  content: "\ecfe";
}

.ph.ph-person-simple:before, i.ph-person-simple.icon-dead:before, i.ph-person-simple.icon-alive:before, i.ph-person-simple.icon-warn:before, i.ph-person-simple.icon-sign-out:before, i.ph-person-simple.icon-substratum:before, i.ph-person-simple.icon-environment:before, i.ph-person-simple.icon-user:before, i.ph-person-simple.icon-person:before, i.ph-person-simple.icon-terrain:before, i.ph-person-simple.icon-taxon:before, i.ph-person-simple.icon-unknown:before, i.ph-person-simple.icon-nothing:before, i.ph-person-simple.icon-sampling:before, i.ph-person-simple.icon-isolation:before, i.ph-person-simple.icon-strain:before, i.ph-person-simple.icon-gps:before, i.ph-person-simple.icon-location:before, i.ph-person-simple.icon-add:before, i.ph-person-simple.icon-left:before, i.ph-person-simple.icon-dna:before, i.ph-person-simple.icon-delete:before, i.ph-person-simple.icon-edit:before, i.ph-person-simple.icon-info:before {
  content: "\e72e";
}

.ph.ph-person-simple-bike:before, i.ph-person-simple-bike.icon-dead:before, i.ph-person-simple-bike.icon-alive:before, i.ph-person-simple-bike.icon-warn:before, i.ph-person-simple-bike.icon-sign-out:before, i.ph-person-simple-bike.icon-substratum:before, i.ph-person-simple-bike.icon-environment:before, i.ph-person-simple-bike.icon-user:before, i.ph-person-simple-bike.icon-person:before, i.ph-person-simple-bike.icon-terrain:before, i.ph-person-simple-bike.icon-taxon:before, i.ph-person-simple-bike.icon-unknown:before, i.ph-person-simple-bike.icon-nothing:before, i.ph-person-simple-bike.icon-sampling:before, i.ph-person-simple-bike.icon-isolation:before, i.ph-person-simple-bike.icon-strain:before, i.ph-person-simple-bike.icon-gps:before, i.ph-person-simple-bike.icon-location:before, i.ph-person-simple-bike.icon-add:before, i.ph-person-simple-bike.icon-left:before, i.ph-person-simple-bike.icon-dna:before, i.ph-person-simple-bike.icon-delete:before, i.ph-person-simple-bike.icon-edit:before, i.ph-person-simple-bike.icon-info:before {
  content: "\e734";
}

.ph.ph-person-simple-circle:before, i.ph-person-simple-circle.icon-dead:before, i.ph-person-simple-circle.icon-alive:before, i.ph-person-simple-circle.icon-warn:before, i.ph-person-simple-circle.icon-sign-out:before, i.ph-person-simple-circle.icon-substratum:before, i.ph-person-simple-circle.icon-environment:before, i.ph-person-simple-circle.icon-user:before, i.ph-person-simple-circle.icon-person:before, i.ph-person-simple-circle.icon-terrain:before, i.ph-person-simple-circle.icon-taxon:before, i.ph-person-simple-circle.icon-unknown:before, i.ph-person-simple-circle.icon-nothing:before, i.ph-person-simple-circle.icon-sampling:before, i.ph-person-simple-circle.icon-isolation:before, i.ph-person-simple-circle.icon-strain:before, i.ph-person-simple-circle.icon-gps:before, i.ph-person-simple-circle.icon-location:before, i.ph-person-simple-circle.icon-add:before, i.ph-person-simple-circle.icon-left:before, i.ph-person-simple-circle.icon-dna:before, i.ph-person-simple-circle.icon-delete:before, i.ph-person-simple-circle.icon-edit:before, i.ph-person-simple-circle.icon-info:before {
  content: "\ee58";
}

.ph.ph-person-simple-hike:before, i.ph-person-simple-hike.icon-dead:before, i.ph-person-simple-hike.icon-alive:before, i.ph-person-simple-hike.icon-warn:before, i.ph-person-simple-hike.icon-sign-out:before, i.ph-person-simple-hike.icon-substratum:before, i.ph-person-simple-hike.icon-environment:before, i.ph-person-simple-hike.icon-user:before, i.ph-person-simple-hike.icon-person:before, i.ph-person-simple-hike.icon-terrain:before, i.ph-person-simple-hike.icon-taxon:before, i.ph-person-simple-hike.icon-unknown:before, i.ph-person-simple-hike.icon-nothing:before, i.ph-person-simple-hike.icon-sampling:before, i.ph-person-simple-hike.icon-isolation:before, i.ph-person-simple-hike.icon-strain:before, i.ph-person-simple-hike.icon-gps:before, i.ph-person-simple-hike.icon-location:before, i.ph-person-simple-hike.icon-add:before, i.ph-person-simple-hike.icon-left:before, i.ph-person-simple-hike.icon-dna:before, i.ph-person-simple-hike.icon-delete:before, i.ph-person-simple-hike.icon-edit:before, i.ph-person-simple-hike.icon-info:before {
  content: "\ed54";
}

.ph.ph-person-simple-run:before, i.ph-person-simple-run.icon-dead:before, i.ph-person-simple-run.icon-alive:before, i.ph-person-simple-run.icon-warn:before, i.ph-person-simple-run.icon-sign-out:before, i.ph-person-simple-run.icon-substratum:before, i.ph-person-simple-run.icon-environment:before, i.ph-person-simple-run.icon-user:before, i.ph-person-simple-run.icon-person:before, i.ph-person-simple-run.icon-terrain:before, i.ph-person-simple-run.icon-taxon:before, i.ph-person-simple-run.icon-unknown:before, i.ph-person-simple-run.icon-nothing:before, i.ph-person-simple-run.icon-sampling:before, i.ph-person-simple-run.icon-isolation:before, i.ph-person-simple-run.icon-strain:before, i.ph-person-simple-run.icon-gps:before, i.ph-person-simple-run.icon-location:before, i.ph-person-simple-run.icon-add:before, i.ph-person-simple-run.icon-left:before, i.ph-person-simple-run.icon-dna:before, i.ph-person-simple-run.icon-delete:before, i.ph-person-simple-run.icon-edit:before, i.ph-person-simple-run.icon-info:before {
  content: "\e730";
}

.ph.ph-person-simple-ski:before, i.ph-person-simple-ski.icon-dead:before, i.ph-person-simple-ski.icon-alive:before, i.ph-person-simple-ski.icon-warn:before, i.ph-person-simple-ski.icon-sign-out:before, i.ph-person-simple-ski.icon-substratum:before, i.ph-person-simple-ski.icon-environment:before, i.ph-person-simple-ski.icon-user:before, i.ph-person-simple-ski.icon-person:before, i.ph-person-simple-ski.icon-terrain:before, i.ph-person-simple-ski.icon-taxon:before, i.ph-person-simple-ski.icon-unknown:before, i.ph-person-simple-ski.icon-nothing:before, i.ph-person-simple-ski.icon-sampling:before, i.ph-person-simple-ski.icon-isolation:before, i.ph-person-simple-ski.icon-strain:before, i.ph-person-simple-ski.icon-gps:before, i.ph-person-simple-ski.icon-location:before, i.ph-person-simple-ski.icon-add:before, i.ph-person-simple-ski.icon-left:before, i.ph-person-simple-ski.icon-dna:before, i.ph-person-simple-ski.icon-delete:before, i.ph-person-simple-ski.icon-edit:before, i.ph-person-simple-ski.icon-info:before {
  content: "\e71c";
}

.ph.ph-person-simple-snowboard:before, i.ph-person-simple-snowboard.icon-dead:before, i.ph-person-simple-snowboard.icon-alive:before, i.ph-person-simple-snowboard.icon-warn:before, i.ph-person-simple-snowboard.icon-sign-out:before, i.ph-person-simple-snowboard.icon-substratum:before, i.ph-person-simple-snowboard.icon-environment:before, i.ph-person-simple-snowboard.icon-user:before, i.ph-person-simple-snowboard.icon-person:before, i.ph-person-simple-snowboard.icon-terrain:before, i.ph-person-simple-snowboard.icon-taxon:before, i.ph-person-simple-snowboard.icon-unknown:before, i.ph-person-simple-snowboard.icon-nothing:before, i.ph-person-simple-snowboard.icon-sampling:before, i.ph-person-simple-snowboard.icon-isolation:before, i.ph-person-simple-snowboard.icon-strain:before, i.ph-person-simple-snowboard.icon-gps:before, i.ph-person-simple-snowboard.icon-location:before, i.ph-person-simple-snowboard.icon-add:before, i.ph-person-simple-snowboard.icon-left:before, i.ph-person-simple-snowboard.icon-dna:before, i.ph-person-simple-snowboard.icon-delete:before, i.ph-person-simple-snowboard.icon-edit:before, i.ph-person-simple-snowboard.icon-info:before {
  content: "\e71e";
}

.ph.ph-person-simple-swim:before, i.ph-person-simple-swim.icon-dead:before, i.ph-person-simple-swim.icon-alive:before, i.ph-person-simple-swim.icon-warn:before, i.ph-person-simple-swim.icon-sign-out:before, i.ph-person-simple-swim.icon-substratum:before, i.ph-person-simple-swim.icon-environment:before, i.ph-person-simple-swim.icon-user:before, i.ph-person-simple-swim.icon-person:before, i.ph-person-simple-swim.icon-terrain:before, i.ph-person-simple-swim.icon-taxon:before, i.ph-person-simple-swim.icon-unknown:before, i.ph-person-simple-swim.icon-nothing:before, i.ph-person-simple-swim.icon-sampling:before, i.ph-person-simple-swim.icon-isolation:before, i.ph-person-simple-swim.icon-strain:before, i.ph-person-simple-swim.icon-gps:before, i.ph-person-simple-swim.icon-location:before, i.ph-person-simple-swim.icon-add:before, i.ph-person-simple-swim.icon-left:before, i.ph-person-simple-swim.icon-dna:before, i.ph-person-simple-swim.icon-delete:before, i.ph-person-simple-swim.icon-edit:before, i.ph-person-simple-swim.icon-info:before {
  content: "\e736";
}

.ph.ph-person-simple-tai-chi:before, i.ph-person-simple-tai-chi.icon-dead:before, i.ph-person-simple-tai-chi.icon-alive:before, i.ph-person-simple-tai-chi.icon-warn:before, i.ph-person-simple-tai-chi.icon-sign-out:before, i.ph-person-simple-tai-chi.icon-substratum:before, i.ph-person-simple-tai-chi.icon-environment:before, i.ph-person-simple-tai-chi.icon-user:before, i.ph-person-simple-tai-chi.icon-person:before, i.ph-person-simple-tai-chi.icon-terrain:before, i.ph-person-simple-tai-chi.icon-taxon:before, i.ph-person-simple-tai-chi.icon-unknown:before, i.ph-person-simple-tai-chi.icon-nothing:before, i.ph-person-simple-tai-chi.icon-sampling:before, i.ph-person-simple-tai-chi.icon-isolation:before, i.ph-person-simple-tai-chi.icon-strain:before, i.ph-person-simple-tai-chi.icon-gps:before, i.ph-person-simple-tai-chi.icon-location:before, i.ph-person-simple-tai-chi.icon-add:before, i.ph-person-simple-tai-chi.icon-left:before, i.ph-person-simple-tai-chi.icon-dna:before, i.ph-person-simple-tai-chi.icon-delete:before, i.ph-person-simple-tai-chi.icon-edit:before, i.ph-person-simple-tai-chi.icon-info:before {
  content: "\ed5c";
}

.ph.ph-person-simple-throw:before, i.ph-person-simple-throw.icon-dead:before, i.ph-person-simple-throw.icon-alive:before, i.ph-person-simple-throw.icon-warn:before, i.ph-person-simple-throw.icon-sign-out:before, i.ph-person-simple-throw.icon-substratum:before, i.ph-person-simple-throw.icon-environment:before, i.ph-person-simple-throw.icon-user:before, i.ph-person-simple-throw.icon-person:before, i.ph-person-simple-throw.icon-terrain:before, i.ph-person-simple-throw.icon-taxon:before, i.ph-person-simple-throw.icon-unknown:before, i.ph-person-simple-throw.icon-nothing:before, i.ph-person-simple-throw.icon-sampling:before, i.ph-person-simple-throw.icon-isolation:before, i.ph-person-simple-throw.icon-strain:before, i.ph-person-simple-throw.icon-gps:before, i.ph-person-simple-throw.icon-location:before, i.ph-person-simple-throw.icon-add:before, i.ph-person-simple-throw.icon-left:before, i.ph-person-simple-throw.icon-dna:before, i.ph-person-simple-throw.icon-delete:before, i.ph-person-simple-throw.icon-edit:before, i.ph-person-simple-throw.icon-info:before {
  content: "\e732";
}

.ph.ph-person-simple-walk:before, i.ph-person-simple-walk.icon-dead:before, i.ph-person-simple-walk.icon-alive:before, i.ph-person-simple-walk.icon-warn:before, i.ph-person-simple-walk.icon-sign-out:before, i.ph-person-simple-walk.icon-substratum:before, i.ph-person-simple-walk.icon-environment:before, i.ph-person-simple-walk.icon-user:before, i.ph-person-simple-walk.icon-person:before, i.ph-person-simple-walk.icon-terrain:before, i.ph-person-simple-walk.icon-taxon:before, i.ph-person-simple-walk.icon-unknown:before, i.ph-person-simple-walk.icon-nothing:before, i.ph-person-simple-walk.icon-sampling:before, i.ph-person-simple-walk.icon-isolation:before, i.ph-person-simple-walk.icon-strain:before, i.ph-person-simple-walk.icon-gps:before, i.ph-person-simple-walk.icon-location:before, i.ph-person-simple-walk.icon-add:before, i.ph-person-simple-walk.icon-left:before, i.ph-person-simple-walk.icon-dna:before, i.ph-person-simple-walk.icon-delete:before, i.ph-person-simple-walk.icon-edit:before, i.ph-person-simple-walk.icon-info:before {
  content: "\e73a";
}

.ph.ph-perspective:before, i.ph-perspective.icon-dead:before, i.ph-perspective.icon-alive:before, i.ph-perspective.icon-warn:before, i.ph-perspective.icon-sign-out:before, i.ph-perspective.icon-substratum:before, i.ph-perspective.icon-environment:before, i.ph-perspective.icon-user:before, i.ph-perspective.icon-person:before, i.ph-perspective.icon-terrain:before, i.ph-perspective.icon-taxon:before, i.ph-perspective.icon-unknown:before, i.ph-perspective.icon-nothing:before, i.ph-perspective.icon-sampling:before, i.ph-perspective.icon-isolation:before, i.ph-perspective.icon-strain:before, i.ph-perspective.icon-gps:before, i.ph-perspective.icon-location:before, i.ph-perspective.icon-add:before, i.ph-perspective.icon-left:before, i.ph-perspective.icon-dna:before, i.ph-perspective.icon-delete:before, i.ph-perspective.icon-edit:before, i.ph-perspective.icon-info:before {
  content: "\ebe6";
}

.ph.ph-phone:before, i.ph-phone.icon-dead:before, i.ph-phone.icon-alive:before, i.ph-phone.icon-warn:before, i.ph-phone.icon-sign-out:before, i.ph-phone.icon-substratum:before, i.ph-phone.icon-environment:before, i.ph-phone.icon-user:before, i.ph-phone.icon-person:before, i.ph-phone.icon-terrain:before, i.ph-phone.icon-taxon:before, i.ph-phone.icon-unknown:before, i.ph-phone.icon-nothing:before, i.ph-phone.icon-sampling:before, i.ph-phone.icon-isolation:before, i.ph-phone.icon-strain:before, i.ph-phone.icon-gps:before, i.ph-phone.icon-location:before, i.ph-phone.icon-add:before, i.ph-phone.icon-left:before, i.ph-phone.icon-dna:before, i.ph-phone.icon-delete:before, i.ph-phone.icon-edit:before, i.ph-phone.icon-info:before {
  content: "\e3b8";
}

.ph.ph-phone-call:before, i.ph-phone-call.icon-dead:before, i.ph-phone-call.icon-alive:before, i.ph-phone-call.icon-warn:before, i.ph-phone-call.icon-sign-out:before, i.ph-phone-call.icon-substratum:before, i.ph-phone-call.icon-environment:before, i.ph-phone-call.icon-user:before, i.ph-phone-call.icon-person:before, i.ph-phone-call.icon-terrain:before, i.ph-phone-call.icon-taxon:before, i.ph-phone-call.icon-unknown:before, i.ph-phone-call.icon-nothing:before, i.ph-phone-call.icon-sampling:before, i.ph-phone-call.icon-isolation:before, i.ph-phone-call.icon-strain:before, i.ph-phone-call.icon-gps:before, i.ph-phone-call.icon-location:before, i.ph-phone-call.icon-add:before, i.ph-phone-call.icon-left:before, i.ph-phone-call.icon-dna:before, i.ph-phone-call.icon-delete:before, i.ph-phone-call.icon-edit:before, i.ph-phone-call.icon-info:before {
  content: "\e3ba";
}

.ph.ph-phone-disconnect:before, i.ph-phone-disconnect.icon-dead:before, i.ph-phone-disconnect.icon-alive:before, i.ph-phone-disconnect.icon-warn:before, i.ph-phone-disconnect.icon-sign-out:before, i.ph-phone-disconnect.icon-substratum:before, i.ph-phone-disconnect.icon-environment:before, i.ph-phone-disconnect.icon-user:before, i.ph-phone-disconnect.icon-person:before, i.ph-phone-disconnect.icon-terrain:before, i.ph-phone-disconnect.icon-taxon:before, i.ph-phone-disconnect.icon-unknown:before, i.ph-phone-disconnect.icon-nothing:before, i.ph-phone-disconnect.icon-sampling:before, i.ph-phone-disconnect.icon-isolation:before, i.ph-phone-disconnect.icon-strain:before, i.ph-phone-disconnect.icon-gps:before, i.ph-phone-disconnect.icon-location:before, i.ph-phone-disconnect.icon-add:before, i.ph-phone-disconnect.icon-left:before, i.ph-phone-disconnect.icon-dna:before, i.ph-phone-disconnect.icon-delete:before, i.ph-phone-disconnect.icon-edit:before, i.ph-phone-disconnect.icon-info:before {
  content: "\e3bc";
}

.ph.ph-phone-incoming:before, i.ph-phone-incoming.icon-dead:before, i.ph-phone-incoming.icon-alive:before, i.ph-phone-incoming.icon-warn:before, i.ph-phone-incoming.icon-sign-out:before, i.ph-phone-incoming.icon-substratum:before, i.ph-phone-incoming.icon-environment:before, i.ph-phone-incoming.icon-user:before, i.ph-phone-incoming.icon-person:before, i.ph-phone-incoming.icon-terrain:before, i.ph-phone-incoming.icon-taxon:before, i.ph-phone-incoming.icon-unknown:before, i.ph-phone-incoming.icon-nothing:before, i.ph-phone-incoming.icon-sampling:before, i.ph-phone-incoming.icon-isolation:before, i.ph-phone-incoming.icon-strain:before, i.ph-phone-incoming.icon-gps:before, i.ph-phone-incoming.icon-location:before, i.ph-phone-incoming.icon-add:before, i.ph-phone-incoming.icon-left:before, i.ph-phone-incoming.icon-dna:before, i.ph-phone-incoming.icon-delete:before, i.ph-phone-incoming.icon-edit:before, i.ph-phone-incoming.icon-info:before {
  content: "\e3be";
}

.ph.ph-phone-list:before, i.ph-phone-list.icon-dead:before, i.ph-phone-list.icon-alive:before, i.ph-phone-list.icon-warn:before, i.ph-phone-list.icon-sign-out:before, i.ph-phone-list.icon-substratum:before, i.ph-phone-list.icon-environment:before, i.ph-phone-list.icon-user:before, i.ph-phone-list.icon-person:before, i.ph-phone-list.icon-terrain:before, i.ph-phone-list.icon-taxon:before, i.ph-phone-list.icon-unknown:before, i.ph-phone-list.icon-nothing:before, i.ph-phone-list.icon-sampling:before, i.ph-phone-list.icon-isolation:before, i.ph-phone-list.icon-strain:before, i.ph-phone-list.icon-gps:before, i.ph-phone-list.icon-location:before, i.ph-phone-list.icon-add:before, i.ph-phone-list.icon-left:before, i.ph-phone-list.icon-dna:before, i.ph-phone-list.icon-delete:before, i.ph-phone-list.icon-edit:before, i.ph-phone-list.icon-info:before {
  content: "\e3cc";
}

.ph.ph-phone-outgoing:before, i.ph-phone-outgoing.icon-dead:before, i.ph-phone-outgoing.icon-alive:before, i.ph-phone-outgoing.icon-warn:before, i.ph-phone-outgoing.icon-sign-out:before, i.ph-phone-outgoing.icon-substratum:before, i.ph-phone-outgoing.icon-environment:before, i.ph-phone-outgoing.icon-user:before, i.ph-phone-outgoing.icon-person:before, i.ph-phone-outgoing.icon-terrain:before, i.ph-phone-outgoing.icon-taxon:before, i.ph-phone-outgoing.icon-unknown:before, i.ph-phone-outgoing.icon-nothing:before, i.ph-phone-outgoing.icon-sampling:before, i.ph-phone-outgoing.icon-isolation:before, i.ph-phone-outgoing.icon-strain:before, i.ph-phone-outgoing.icon-gps:before, i.ph-phone-outgoing.icon-location:before, i.ph-phone-outgoing.icon-add:before, i.ph-phone-outgoing.icon-left:before, i.ph-phone-outgoing.icon-dna:before, i.ph-phone-outgoing.icon-delete:before, i.ph-phone-outgoing.icon-edit:before, i.ph-phone-outgoing.icon-info:before {
  content: "\e3c0";
}

.ph.ph-phone-pause:before, i.ph-phone-pause.icon-dead:before, i.ph-phone-pause.icon-alive:before, i.ph-phone-pause.icon-warn:before, i.ph-phone-pause.icon-sign-out:before, i.ph-phone-pause.icon-substratum:before, i.ph-phone-pause.icon-environment:before, i.ph-phone-pause.icon-user:before, i.ph-phone-pause.icon-person:before, i.ph-phone-pause.icon-terrain:before, i.ph-phone-pause.icon-taxon:before, i.ph-phone-pause.icon-unknown:before, i.ph-phone-pause.icon-nothing:before, i.ph-phone-pause.icon-sampling:before, i.ph-phone-pause.icon-isolation:before, i.ph-phone-pause.icon-strain:before, i.ph-phone-pause.icon-gps:before, i.ph-phone-pause.icon-location:before, i.ph-phone-pause.icon-add:before, i.ph-phone-pause.icon-left:before, i.ph-phone-pause.icon-dna:before, i.ph-phone-pause.icon-delete:before, i.ph-phone-pause.icon-edit:before, i.ph-phone-pause.icon-info:before {
  content: "\e3ca";
}

.ph.ph-phone-plus:before, i.ph-phone-plus.icon-dead:before, i.ph-phone-plus.icon-alive:before, i.ph-phone-plus.icon-warn:before, i.ph-phone-plus.icon-sign-out:before, i.ph-phone-plus.icon-substratum:before, i.ph-phone-plus.icon-environment:before, i.ph-phone-plus.icon-user:before, i.ph-phone-plus.icon-person:before, i.ph-phone-plus.icon-terrain:before, i.ph-phone-plus.icon-taxon:before, i.ph-phone-plus.icon-unknown:before, i.ph-phone-plus.icon-nothing:before, i.ph-phone-plus.icon-sampling:before, i.ph-phone-plus.icon-isolation:before, i.ph-phone-plus.icon-strain:before, i.ph-phone-plus.icon-gps:before, i.ph-phone-plus.icon-location:before, i.ph-phone-plus.icon-add:before, i.ph-phone-plus.icon-left:before, i.ph-phone-plus.icon-dna:before, i.ph-phone-plus.icon-delete:before, i.ph-phone-plus.icon-edit:before, i.ph-phone-plus.icon-info:before {
  content: "\ec56";
}

.ph.ph-phone-slash:before, i.ph-phone-slash.icon-dead:before, i.ph-phone-slash.icon-alive:before, i.ph-phone-slash.icon-warn:before, i.ph-phone-slash.icon-sign-out:before, i.ph-phone-slash.icon-substratum:before, i.ph-phone-slash.icon-environment:before, i.ph-phone-slash.icon-user:before, i.ph-phone-slash.icon-person:before, i.ph-phone-slash.icon-terrain:before, i.ph-phone-slash.icon-taxon:before, i.ph-phone-slash.icon-unknown:before, i.ph-phone-slash.icon-nothing:before, i.ph-phone-slash.icon-sampling:before, i.ph-phone-slash.icon-isolation:before, i.ph-phone-slash.icon-strain:before, i.ph-phone-slash.icon-gps:before, i.ph-phone-slash.icon-location:before, i.ph-phone-slash.icon-add:before, i.ph-phone-slash.icon-left:before, i.ph-phone-slash.icon-dna:before, i.ph-phone-slash.icon-delete:before, i.ph-phone-slash.icon-edit:before, i.ph-phone-slash.icon-info:before {
  content: "\e3c2";
}

.ph.ph-phone-transfer:before, i.ph-phone-transfer.icon-dead:before, i.ph-phone-transfer.icon-alive:before, i.ph-phone-transfer.icon-warn:before, i.ph-phone-transfer.icon-sign-out:before, i.ph-phone-transfer.icon-substratum:before, i.ph-phone-transfer.icon-environment:before, i.ph-phone-transfer.icon-user:before, i.ph-phone-transfer.icon-person:before, i.ph-phone-transfer.icon-terrain:before, i.ph-phone-transfer.icon-taxon:before, i.ph-phone-transfer.icon-unknown:before, i.ph-phone-transfer.icon-nothing:before, i.ph-phone-transfer.icon-sampling:before, i.ph-phone-transfer.icon-isolation:before, i.ph-phone-transfer.icon-strain:before, i.ph-phone-transfer.icon-gps:before, i.ph-phone-transfer.icon-location:before, i.ph-phone-transfer.icon-add:before, i.ph-phone-transfer.icon-left:before, i.ph-phone-transfer.icon-dna:before, i.ph-phone-transfer.icon-delete:before, i.ph-phone-transfer.icon-edit:before, i.ph-phone-transfer.icon-info:before {
  content: "\e3c6";
}

.ph.ph-phone-x:before, i.ph-phone-x.icon-dead:before, i.ph-phone-x.icon-alive:before, i.ph-phone-x.icon-warn:before, i.ph-phone-x.icon-sign-out:before, i.ph-phone-x.icon-substratum:before, i.ph-phone-x.icon-environment:before, i.ph-phone-x.icon-user:before, i.ph-phone-x.icon-person:before, i.ph-phone-x.icon-terrain:before, i.ph-phone-x.icon-taxon:before, i.ph-phone-x.icon-unknown:before, i.ph-phone-x.icon-nothing:before, i.ph-phone-x.icon-sampling:before, i.ph-phone-x.icon-isolation:before, i.ph-phone-x.icon-strain:before, i.ph-phone-x.icon-gps:before, i.ph-phone-x.icon-location:before, i.ph-phone-x.icon-add:before, i.ph-phone-x.icon-left:before, i.ph-phone-x.icon-dna:before, i.ph-phone-x.icon-delete:before, i.ph-phone-x.icon-edit:before, i.ph-phone-x.icon-info:before {
  content: "\e3c4";
}

.ph.ph-phosphor-logo:before, i.ph-phosphor-logo.icon-dead:before, i.ph-phosphor-logo.icon-alive:before, i.ph-phosphor-logo.icon-warn:before, i.ph-phosphor-logo.icon-sign-out:before, i.ph-phosphor-logo.icon-substratum:before, i.ph-phosphor-logo.icon-environment:before, i.ph-phosphor-logo.icon-user:before, i.ph-phosphor-logo.icon-person:before, i.ph-phosphor-logo.icon-terrain:before, i.ph-phosphor-logo.icon-taxon:before, i.ph-phosphor-logo.icon-unknown:before, i.ph-phosphor-logo.icon-nothing:before, i.ph-phosphor-logo.icon-sampling:before, i.ph-phosphor-logo.icon-isolation:before, i.ph-phosphor-logo.icon-strain:before, i.ph-phosphor-logo.icon-gps:before, i.ph-phosphor-logo.icon-location:before, i.ph-phosphor-logo.icon-add:before, i.ph-phosphor-logo.icon-left:before, i.ph-phosphor-logo.icon-dna:before, i.ph-phosphor-logo.icon-delete:before, i.ph-phosphor-logo.icon-edit:before, i.ph-phosphor-logo.icon-info:before {
  content: "\e3ce";
}

.ph.ph-pi:before, i.ph-pi.icon-dead:before, i.ph-pi.icon-alive:before, i.ph-pi.icon-warn:before, i.ph-pi.icon-sign-out:before, i.ph-pi.icon-substratum:before, i.ph-pi.icon-environment:before, i.ph-pi.icon-user:before, i.ph-pi.icon-person:before, i.ph-pi.icon-terrain:before, i.ph-pi.icon-taxon:before, i.ph-pi.icon-unknown:before, i.ph-pi.icon-nothing:before, i.ph-pi.icon-sampling:before, i.ph-pi.icon-isolation:before, i.ph-pi.icon-strain:before, i.ph-pi.icon-gps:before, i.ph-pi.icon-location:before, i.ph-pi.icon-add:before, i.ph-pi.icon-left:before, i.ph-pi.icon-dna:before, i.ph-pi.icon-delete:before, i.ph-pi.icon-edit:before, i.ph-pi.icon-info:before {
  content: "\ec80";
}

.ph.ph-piano-keys:before, i.ph-piano-keys.icon-dead:before, i.ph-piano-keys.icon-alive:before, i.ph-piano-keys.icon-warn:before, i.ph-piano-keys.icon-sign-out:before, i.ph-piano-keys.icon-substratum:before, i.ph-piano-keys.icon-environment:before, i.ph-piano-keys.icon-user:before, i.ph-piano-keys.icon-person:before, i.ph-piano-keys.icon-terrain:before, i.ph-piano-keys.icon-taxon:before, i.ph-piano-keys.icon-unknown:before, i.ph-piano-keys.icon-nothing:before, i.ph-piano-keys.icon-sampling:before, i.ph-piano-keys.icon-isolation:before, i.ph-piano-keys.icon-strain:before, i.ph-piano-keys.icon-gps:before, i.ph-piano-keys.icon-location:before, i.ph-piano-keys.icon-add:before, i.ph-piano-keys.icon-left:before, i.ph-piano-keys.icon-dna:before, i.ph-piano-keys.icon-delete:before, i.ph-piano-keys.icon-edit:before, i.ph-piano-keys.icon-info:before {
  content: "\e9c8";
}

.ph.ph-picnic-table:before, i.ph-picnic-table.icon-dead:before, i.ph-picnic-table.icon-alive:before, i.ph-picnic-table.icon-warn:before, i.ph-picnic-table.icon-sign-out:before, i.ph-picnic-table.icon-substratum:before, i.ph-picnic-table.icon-environment:before, i.ph-picnic-table.icon-user:before, i.ph-picnic-table.icon-person:before, i.ph-picnic-table.icon-terrain:before, i.ph-picnic-table.icon-taxon:before, i.ph-picnic-table.icon-unknown:before, i.ph-picnic-table.icon-nothing:before, i.ph-picnic-table.icon-sampling:before, i.ph-picnic-table.icon-isolation:before, i.ph-picnic-table.icon-strain:before, i.ph-picnic-table.icon-gps:before, i.ph-picnic-table.icon-location:before, i.ph-picnic-table.icon-add:before, i.ph-picnic-table.icon-left:before, i.ph-picnic-table.icon-dna:before, i.ph-picnic-table.icon-delete:before, i.ph-picnic-table.icon-edit:before, i.ph-picnic-table.icon-info:before {
  content: "\ee26";
}

.ph.ph-picture-in-picture:before, i.ph-picture-in-picture.icon-dead:before, i.ph-picture-in-picture.icon-alive:before, i.ph-picture-in-picture.icon-warn:before, i.ph-picture-in-picture.icon-sign-out:before, i.ph-picture-in-picture.icon-substratum:before, i.ph-picture-in-picture.icon-environment:before, i.ph-picture-in-picture.icon-user:before, i.ph-picture-in-picture.icon-person:before, i.ph-picture-in-picture.icon-terrain:before, i.ph-picture-in-picture.icon-taxon:before, i.ph-picture-in-picture.icon-unknown:before, i.ph-picture-in-picture.icon-nothing:before, i.ph-picture-in-picture.icon-sampling:before, i.ph-picture-in-picture.icon-isolation:before, i.ph-picture-in-picture.icon-strain:before, i.ph-picture-in-picture.icon-gps:before, i.ph-picture-in-picture.icon-location:before, i.ph-picture-in-picture.icon-add:before, i.ph-picture-in-picture.icon-left:before, i.ph-picture-in-picture.icon-dna:before, i.ph-picture-in-picture.icon-delete:before, i.ph-picture-in-picture.icon-edit:before, i.ph-picture-in-picture.icon-info:before {
  content: "\e64c";
}

.ph.ph-piggy-bank:before, i.ph-piggy-bank.icon-dead:before, i.ph-piggy-bank.icon-alive:before, i.ph-piggy-bank.icon-warn:before, i.ph-piggy-bank.icon-sign-out:before, i.ph-piggy-bank.icon-substratum:before, i.ph-piggy-bank.icon-environment:before, i.ph-piggy-bank.icon-user:before, i.ph-piggy-bank.icon-person:before, i.ph-piggy-bank.icon-terrain:before, i.ph-piggy-bank.icon-taxon:before, i.ph-piggy-bank.icon-unknown:before, i.ph-piggy-bank.icon-nothing:before, i.ph-piggy-bank.icon-sampling:before, i.ph-piggy-bank.icon-isolation:before, i.ph-piggy-bank.icon-strain:before, i.ph-piggy-bank.icon-gps:before, i.ph-piggy-bank.icon-location:before, i.ph-piggy-bank.icon-add:before, i.ph-piggy-bank.icon-left:before, i.ph-piggy-bank.icon-dna:before, i.ph-piggy-bank.icon-delete:before, i.ph-piggy-bank.icon-edit:before, i.ph-piggy-bank.icon-info:before {
  content: "\ea04";
}

.ph.ph-pill:before, i.ph-pill.icon-dead:before, i.ph-pill.icon-alive:before, i.ph-pill.icon-warn:before, i.ph-pill.icon-sign-out:before, i.ph-pill.icon-substratum:before, i.ph-pill.icon-environment:before, i.ph-pill.icon-user:before, i.ph-pill.icon-person:before, i.ph-pill.icon-terrain:before, i.ph-pill.icon-taxon:before, i.ph-pill.icon-unknown:before, i.ph-pill.icon-nothing:before, i.ph-pill.icon-sampling:before, i.ph-pill.icon-isolation:before, i.ph-pill.icon-strain:before, i.ph-pill.icon-gps:before, i.ph-pill.icon-location:before, i.ph-pill.icon-add:before, i.ph-pill.icon-left:before, i.ph-pill.icon-dna:before, i.ph-pill.icon-delete:before, i.ph-pill.icon-edit:before, i.ph-pill.icon-info:before {
  content: "\e700";
}

.ph.ph-ping-pong:before, i.ph-ping-pong.icon-dead:before, i.ph-ping-pong.icon-alive:before, i.ph-ping-pong.icon-warn:before, i.ph-ping-pong.icon-sign-out:before, i.ph-ping-pong.icon-substratum:before, i.ph-ping-pong.icon-environment:before, i.ph-ping-pong.icon-user:before, i.ph-ping-pong.icon-person:before, i.ph-ping-pong.icon-terrain:before, i.ph-ping-pong.icon-taxon:before, i.ph-ping-pong.icon-unknown:before, i.ph-ping-pong.icon-nothing:before, i.ph-ping-pong.icon-sampling:before, i.ph-ping-pong.icon-isolation:before, i.ph-ping-pong.icon-strain:before, i.ph-ping-pong.icon-gps:before, i.ph-ping-pong.icon-location:before, i.ph-ping-pong.icon-add:before, i.ph-ping-pong.icon-left:before, i.ph-ping-pong.icon-dna:before, i.ph-ping-pong.icon-delete:before, i.ph-ping-pong.icon-edit:before, i.ph-ping-pong.icon-info:before {
  content: "\ea42";
}

.ph.ph-pint-glass:before, i.ph-pint-glass.icon-dead:before, i.ph-pint-glass.icon-alive:before, i.ph-pint-glass.icon-warn:before, i.ph-pint-glass.icon-sign-out:before, i.ph-pint-glass.icon-substratum:before, i.ph-pint-glass.icon-environment:before, i.ph-pint-glass.icon-user:before, i.ph-pint-glass.icon-person:before, i.ph-pint-glass.icon-terrain:before, i.ph-pint-glass.icon-taxon:before, i.ph-pint-glass.icon-unknown:before, i.ph-pint-glass.icon-nothing:before, i.ph-pint-glass.icon-sampling:before, i.ph-pint-glass.icon-isolation:before, i.ph-pint-glass.icon-strain:before, i.ph-pint-glass.icon-gps:before, i.ph-pint-glass.icon-location:before, i.ph-pint-glass.icon-add:before, i.ph-pint-glass.icon-left:before, i.ph-pint-glass.icon-dna:before, i.ph-pint-glass.icon-delete:before, i.ph-pint-glass.icon-edit:before, i.ph-pint-glass.icon-info:before {
  content: "\edd0";
}

.ph.ph-pinterest-logo:before, i.ph-pinterest-logo.icon-dead:before, i.ph-pinterest-logo.icon-alive:before, i.ph-pinterest-logo.icon-warn:before, i.ph-pinterest-logo.icon-sign-out:before, i.ph-pinterest-logo.icon-substratum:before, i.ph-pinterest-logo.icon-environment:before, i.ph-pinterest-logo.icon-user:before, i.ph-pinterest-logo.icon-person:before, i.ph-pinterest-logo.icon-terrain:before, i.ph-pinterest-logo.icon-taxon:before, i.ph-pinterest-logo.icon-unknown:before, i.ph-pinterest-logo.icon-nothing:before, i.ph-pinterest-logo.icon-sampling:before, i.ph-pinterest-logo.icon-isolation:before, i.ph-pinterest-logo.icon-strain:before, i.ph-pinterest-logo.icon-gps:before, i.ph-pinterest-logo.icon-location:before, i.ph-pinterest-logo.icon-add:before, i.ph-pinterest-logo.icon-left:before, i.ph-pinterest-logo.icon-dna:before, i.ph-pinterest-logo.icon-delete:before, i.ph-pinterest-logo.icon-edit:before, i.ph-pinterest-logo.icon-info:before {
  content: "\e64e";
}

.ph.ph-pinwheel:before, i.ph-pinwheel.icon-dead:before, i.ph-pinwheel.icon-alive:before, i.ph-pinwheel.icon-warn:before, i.ph-pinwheel.icon-sign-out:before, i.ph-pinwheel.icon-substratum:before, i.ph-pinwheel.icon-environment:before, i.ph-pinwheel.icon-user:before, i.ph-pinwheel.icon-person:before, i.ph-pinwheel.icon-terrain:before, i.ph-pinwheel.icon-taxon:before, i.ph-pinwheel.icon-unknown:before, i.ph-pinwheel.icon-nothing:before, i.ph-pinwheel.icon-sampling:before, i.ph-pinwheel.icon-isolation:before, i.ph-pinwheel.icon-strain:before, i.ph-pinwheel.icon-gps:before, i.ph-pinwheel.icon-location:before, i.ph-pinwheel.icon-add:before, i.ph-pinwheel.icon-left:before, i.ph-pinwheel.icon-dna:before, i.ph-pinwheel.icon-delete:before, i.ph-pinwheel.icon-edit:before, i.ph-pinwheel.icon-info:before {
  content: "\eb9c";
}

.ph.ph-pipe:before, i.ph-pipe.icon-dead:before, i.ph-pipe.icon-alive:before, i.ph-pipe.icon-warn:before, i.ph-pipe.icon-sign-out:before, i.ph-pipe.icon-substratum:before, i.ph-pipe.icon-environment:before, i.ph-pipe.icon-user:before, i.ph-pipe.icon-person:before, i.ph-pipe.icon-terrain:before, i.ph-pipe.icon-taxon:before, i.ph-pipe.icon-unknown:before, i.ph-pipe.icon-nothing:before, i.ph-pipe.icon-sampling:before, i.ph-pipe.icon-isolation:before, i.ph-pipe.icon-strain:before, i.ph-pipe.icon-gps:before, i.ph-pipe.icon-location:before, i.ph-pipe.icon-add:before, i.ph-pipe.icon-left:before, i.ph-pipe.icon-dna:before, i.ph-pipe.icon-delete:before, i.ph-pipe.icon-edit:before, i.ph-pipe.icon-info:before {
  content: "\ed86";
}

.ph.ph-pipe-wrench:before, i.ph-pipe-wrench.icon-dead:before, i.ph-pipe-wrench.icon-alive:before, i.ph-pipe-wrench.icon-warn:before, i.ph-pipe-wrench.icon-sign-out:before, i.ph-pipe-wrench.icon-substratum:before, i.ph-pipe-wrench.icon-environment:before, i.ph-pipe-wrench.icon-user:before, i.ph-pipe-wrench.icon-person:before, i.ph-pipe-wrench.icon-terrain:before, i.ph-pipe-wrench.icon-taxon:before, i.ph-pipe-wrench.icon-unknown:before, i.ph-pipe-wrench.icon-nothing:before, i.ph-pipe-wrench.icon-sampling:before, i.ph-pipe-wrench.icon-isolation:before, i.ph-pipe-wrench.icon-strain:before, i.ph-pipe-wrench.icon-gps:before, i.ph-pipe-wrench.icon-location:before, i.ph-pipe-wrench.icon-add:before, i.ph-pipe-wrench.icon-left:before, i.ph-pipe-wrench.icon-dna:before, i.ph-pipe-wrench.icon-delete:before, i.ph-pipe-wrench.icon-edit:before, i.ph-pipe-wrench.icon-info:before {
  content: "\ed88";
}

.ph.ph-pix-logo:before, i.ph-pix-logo.icon-dead:before, i.ph-pix-logo.icon-alive:before, i.ph-pix-logo.icon-warn:before, i.ph-pix-logo.icon-sign-out:before, i.ph-pix-logo.icon-substratum:before, i.ph-pix-logo.icon-environment:before, i.ph-pix-logo.icon-user:before, i.ph-pix-logo.icon-person:before, i.ph-pix-logo.icon-terrain:before, i.ph-pix-logo.icon-taxon:before, i.ph-pix-logo.icon-unknown:before, i.ph-pix-logo.icon-nothing:before, i.ph-pix-logo.icon-sampling:before, i.ph-pix-logo.icon-isolation:before, i.ph-pix-logo.icon-strain:before, i.ph-pix-logo.icon-gps:before, i.ph-pix-logo.icon-location:before, i.ph-pix-logo.icon-add:before, i.ph-pix-logo.icon-left:before, i.ph-pix-logo.icon-dna:before, i.ph-pix-logo.icon-delete:before, i.ph-pix-logo.icon-edit:before, i.ph-pix-logo.icon-info:before {
  content: "\ecc2";
}

.ph.ph-pizza:before, i.ph-pizza.icon-dead:before, i.ph-pizza.icon-alive:before, i.ph-pizza.icon-warn:before, i.ph-pizza.icon-sign-out:before, i.ph-pizza.icon-substratum:before, i.ph-pizza.icon-environment:before, i.ph-pizza.icon-user:before, i.ph-pizza.icon-person:before, i.ph-pizza.icon-terrain:before, i.ph-pizza.icon-taxon:before, i.ph-pizza.icon-unknown:before, i.ph-pizza.icon-nothing:before, i.ph-pizza.icon-sampling:before, i.ph-pizza.icon-isolation:before, i.ph-pizza.icon-strain:before, i.ph-pizza.icon-gps:before, i.ph-pizza.icon-location:before, i.ph-pizza.icon-add:before, i.ph-pizza.icon-left:before, i.ph-pizza.icon-dna:before, i.ph-pizza.icon-delete:before, i.ph-pizza.icon-edit:before, i.ph-pizza.icon-info:before {
  content: "\e796";
}

.ph.ph-placeholder:before, i.ph-placeholder.icon-dead:before, i.ph-placeholder.icon-alive:before, i.ph-placeholder.icon-warn:before, i.ph-placeholder.icon-sign-out:before, i.ph-placeholder.icon-substratum:before, i.ph-placeholder.icon-environment:before, i.ph-placeholder.icon-user:before, i.ph-placeholder.icon-person:before, i.ph-placeholder.icon-terrain:before, i.ph-placeholder.icon-taxon:before, i.ph-placeholder.icon-unknown:before, i.ph-placeholder.icon-nothing:before, i.ph-placeholder.icon-sampling:before, i.ph-placeholder.icon-isolation:before, i.ph-placeholder.icon-strain:before, i.ph-placeholder.icon-gps:before, i.ph-placeholder.icon-location:before, i.ph-placeholder.icon-add:before, i.ph-placeholder.icon-left:before, i.ph-placeholder.icon-dna:before, i.ph-placeholder.icon-delete:before, i.ph-placeholder.icon-edit:before, i.ph-placeholder.icon-info:before {
  content: "\e650";
}

.ph.ph-planet:before, i.ph-planet.icon-dead:before, i.ph-planet.icon-alive:before, i.ph-planet.icon-warn:before, i.ph-planet.icon-sign-out:before, i.ph-planet.icon-substratum:before, i.ph-planet.icon-environment:before, i.ph-planet.icon-user:before, i.ph-planet.icon-person:before, i.ph-planet.icon-terrain:before, i.ph-planet.icon-taxon:before, i.ph-planet.icon-unknown:before, i.ph-planet.icon-nothing:before, i.ph-planet.icon-sampling:before, i.ph-planet.icon-isolation:before, i.ph-planet.icon-strain:before, i.ph-planet.icon-gps:before, i.ph-planet.icon-location:before, i.ph-planet.icon-add:before, i.ph-planet.icon-left:before, i.ph-planet.icon-dna:before, i.ph-planet.icon-delete:before, i.ph-planet.icon-edit:before, i.ph-planet.icon-info:before {
  content: "\e652";
}

.ph.ph-plant:before, i.ph-plant.icon-dead:before, i.ph-plant.icon-alive:before, i.ph-plant.icon-warn:before, i.ph-plant.icon-sign-out:before, i.ph-plant.icon-substratum:before, i.ph-plant.icon-environment:before, i.ph-plant.icon-user:before, i.ph-plant.icon-person:before, i.ph-plant.icon-terrain:before, i.ph-plant.icon-taxon:before, i.ph-plant.icon-unknown:before, i.ph-plant.icon-nothing:before, i.ph-plant.icon-sampling:before, i.ph-plant.icon-isolation:before, i.ph-plant.icon-strain:before, i.ph-plant.icon-gps:before, i.ph-plant.icon-location:before, i.ph-plant.icon-add:before, i.ph-plant.icon-left:before, i.ph-plant.icon-dna:before, i.ph-plant.icon-delete:before, i.ph-plant.icon-edit:before, i.ph-plant.icon-info:before {
  content: "\ebae";
}

.ph.ph-play:before, i.ph-play.icon-dead:before, i.ph-play.icon-alive:before, i.ph-play.icon-warn:before, i.ph-play.icon-sign-out:before, i.ph-play.icon-substratum:before, i.ph-play.icon-environment:before, i.ph-play.icon-user:before, i.ph-play.icon-person:before, i.ph-play.icon-terrain:before, i.ph-play.icon-taxon:before, i.ph-play.icon-unknown:before, i.ph-play.icon-nothing:before, i.ph-play.icon-sampling:before, i.ph-play.icon-isolation:before, i.ph-play.icon-strain:before, i.ph-play.icon-gps:before, i.ph-play.icon-location:before, i.ph-play.icon-add:before, i.ph-play.icon-left:before, i.ph-play.icon-dna:before, i.ph-play.icon-delete:before, i.ph-play.icon-edit:before, i.ph-play.icon-info:before {
  content: "\e3d0";
}

.ph.ph-play-circle:before, i.ph-play-circle.icon-dead:before, i.ph-play-circle.icon-alive:before, i.ph-play-circle.icon-warn:before, i.ph-play-circle.icon-sign-out:before, i.ph-play-circle.icon-substratum:before, i.ph-play-circle.icon-environment:before, i.ph-play-circle.icon-user:before, i.ph-play-circle.icon-person:before, i.ph-play-circle.icon-terrain:before, i.ph-play-circle.icon-taxon:before, i.ph-play-circle.icon-unknown:before, i.ph-play-circle.icon-nothing:before, i.ph-play-circle.icon-sampling:before, i.ph-play-circle.icon-isolation:before, i.ph-play-circle.icon-strain:before, i.ph-play-circle.icon-gps:before, i.ph-play-circle.icon-location:before, i.ph-play-circle.icon-add:before, i.ph-play-circle.icon-left:before, i.ph-play-circle.icon-dna:before, i.ph-play-circle.icon-delete:before, i.ph-play-circle.icon-edit:before, i.ph-play-circle.icon-info:before {
  content: "\e3d2";
}

.ph.ph-play-pause:before, i.ph-play-pause.icon-dead:before, i.ph-play-pause.icon-alive:before, i.ph-play-pause.icon-warn:before, i.ph-play-pause.icon-sign-out:before, i.ph-play-pause.icon-substratum:before, i.ph-play-pause.icon-environment:before, i.ph-play-pause.icon-user:before, i.ph-play-pause.icon-person:before, i.ph-play-pause.icon-terrain:before, i.ph-play-pause.icon-taxon:before, i.ph-play-pause.icon-unknown:before, i.ph-play-pause.icon-nothing:before, i.ph-play-pause.icon-sampling:before, i.ph-play-pause.icon-isolation:before, i.ph-play-pause.icon-strain:before, i.ph-play-pause.icon-gps:before, i.ph-play-pause.icon-location:before, i.ph-play-pause.icon-add:before, i.ph-play-pause.icon-left:before, i.ph-play-pause.icon-dna:before, i.ph-play-pause.icon-delete:before, i.ph-play-pause.icon-edit:before, i.ph-play-pause.icon-info:before {
  content: "\e8be";
}

.ph.ph-playlist:before, i.ph-playlist.icon-dead:before, i.ph-playlist.icon-alive:before, i.ph-playlist.icon-warn:before, i.ph-playlist.icon-sign-out:before, i.ph-playlist.icon-substratum:before, i.ph-playlist.icon-environment:before, i.ph-playlist.icon-user:before, i.ph-playlist.icon-person:before, i.ph-playlist.icon-terrain:before, i.ph-playlist.icon-taxon:before, i.ph-playlist.icon-unknown:before, i.ph-playlist.icon-nothing:before, i.ph-playlist.icon-sampling:before, i.ph-playlist.icon-isolation:before, i.ph-playlist.icon-strain:before, i.ph-playlist.icon-gps:before, i.ph-playlist.icon-location:before, i.ph-playlist.icon-add:before, i.ph-playlist.icon-left:before, i.ph-playlist.icon-dna:before, i.ph-playlist.icon-delete:before, i.ph-playlist.icon-edit:before, i.ph-playlist.icon-info:before {
  content: "\e6aa";
}

.ph.ph-plug:before, i.ph-plug.icon-dead:before, i.ph-plug.icon-alive:before, i.ph-plug.icon-warn:before, i.ph-plug.icon-sign-out:before, i.ph-plug.icon-substratum:before, i.ph-plug.icon-environment:before, i.ph-plug.icon-user:before, i.ph-plug.icon-person:before, i.ph-plug.icon-terrain:before, i.ph-plug.icon-taxon:before, i.ph-plug.icon-unknown:before, i.ph-plug.icon-nothing:before, i.ph-plug.icon-sampling:before, i.ph-plug.icon-isolation:before, i.ph-plug.icon-strain:before, i.ph-plug.icon-gps:before, i.ph-plug.icon-location:before, i.ph-plug.icon-add:before, i.ph-plug.icon-left:before, i.ph-plug.icon-dna:before, i.ph-plug.icon-delete:before, i.ph-plug.icon-edit:before, i.ph-plug.icon-info:before {
  content: "\e946";
}

.ph.ph-plug-charging:before, i.ph-plug-charging.icon-dead:before, i.ph-plug-charging.icon-alive:before, i.ph-plug-charging.icon-warn:before, i.ph-plug-charging.icon-sign-out:before, i.ph-plug-charging.icon-substratum:before, i.ph-plug-charging.icon-environment:before, i.ph-plug-charging.icon-user:before, i.ph-plug-charging.icon-person:before, i.ph-plug-charging.icon-terrain:before, i.ph-plug-charging.icon-taxon:before, i.ph-plug-charging.icon-unknown:before, i.ph-plug-charging.icon-nothing:before, i.ph-plug-charging.icon-sampling:before, i.ph-plug-charging.icon-isolation:before, i.ph-plug-charging.icon-strain:before, i.ph-plug-charging.icon-gps:before, i.ph-plug-charging.icon-location:before, i.ph-plug-charging.icon-add:before, i.ph-plug-charging.icon-left:before, i.ph-plug-charging.icon-dna:before, i.ph-plug-charging.icon-delete:before, i.ph-plug-charging.icon-edit:before, i.ph-plug-charging.icon-info:before {
  content: "\eb5c";
}

.ph.ph-plugs:before, i.ph-plugs.icon-dead:before, i.ph-plugs.icon-alive:before, i.ph-plugs.icon-warn:before, i.ph-plugs.icon-sign-out:before, i.ph-plugs.icon-substratum:before, i.ph-plugs.icon-environment:before, i.ph-plugs.icon-user:before, i.ph-plugs.icon-person:before, i.ph-plugs.icon-terrain:before, i.ph-plugs.icon-taxon:before, i.ph-plugs.icon-unknown:before, i.ph-plugs.icon-nothing:before, i.ph-plugs.icon-sampling:before, i.ph-plugs.icon-isolation:before, i.ph-plugs.icon-strain:before, i.ph-plugs.icon-gps:before, i.ph-plugs.icon-location:before, i.ph-plugs.icon-add:before, i.ph-plugs.icon-left:before, i.ph-plugs.icon-dna:before, i.ph-plugs.icon-delete:before, i.ph-plugs.icon-edit:before, i.ph-plugs.icon-info:before {
  content: "\eb56";
}

.ph.ph-plugs-connected:before, i.ph-plugs-connected.icon-dead:before, i.ph-plugs-connected.icon-alive:before, i.ph-plugs-connected.icon-warn:before, i.ph-plugs-connected.icon-sign-out:before, i.ph-plugs-connected.icon-substratum:before, i.ph-plugs-connected.icon-environment:before, i.ph-plugs-connected.icon-user:before, i.ph-plugs-connected.icon-person:before, i.ph-plugs-connected.icon-terrain:before, i.ph-plugs-connected.icon-taxon:before, i.ph-plugs-connected.icon-unknown:before, i.ph-plugs-connected.icon-nothing:before, i.ph-plugs-connected.icon-sampling:before, i.ph-plugs-connected.icon-isolation:before, i.ph-plugs-connected.icon-strain:before, i.ph-plugs-connected.icon-gps:before, i.ph-plugs-connected.icon-location:before, i.ph-plugs-connected.icon-add:before, i.ph-plugs-connected.icon-left:before, i.ph-plugs-connected.icon-dna:before, i.ph-plugs-connected.icon-delete:before, i.ph-plugs-connected.icon-edit:before, i.ph-plugs-connected.icon-info:before {
  content: "\eb5a";
}

.ph.ph-plus:before, i.ph-plus.icon-dead:before, i.ph-plus.icon-alive:before, i.ph-plus.icon-warn:before, i.ph-plus.icon-sign-out:before, i.ph-plus.icon-substratum:before, i.ph-plus.icon-environment:before, i.ph-plus.icon-user:before, i.ph-plus.icon-person:before, i.ph-plus.icon-terrain:before, i.ph-plus.icon-taxon:before, i.ph-plus.icon-unknown:before, i.ph-plus.icon-nothing:before, i.ph-plus.icon-sampling:before, i.ph-plus.icon-isolation:before, i.ph-plus.icon-strain:before, i.ph-plus.icon-gps:before, i.ph-plus.icon-location:before, i.icon-add:before, i.ph-plus.icon-left:before, i.ph-plus.icon-dna:before, i.ph-plus.icon-delete:before, i.ph-plus.icon-edit:before, i.ph-plus.icon-info:before {
  content: "\e3d4";
}

.ph.ph-plus-circle:before, i.ph-plus-circle.icon-dead:before, i.ph-plus-circle.icon-alive:before, i.ph-plus-circle.icon-warn:before, i.ph-plus-circle.icon-sign-out:before, i.ph-plus-circle.icon-substratum:before, i.ph-plus-circle.icon-environment:before, i.ph-plus-circle.icon-user:before, i.ph-plus-circle.icon-person:before, i.ph-plus-circle.icon-terrain:before, i.ph-plus-circle.icon-taxon:before, i.ph-plus-circle.icon-unknown:before, i.ph-plus-circle.icon-nothing:before, i.ph-plus-circle.icon-sampling:before, i.ph-plus-circle.icon-isolation:before, i.ph-plus-circle.icon-strain:before, i.ph-plus-circle.icon-gps:before, i.ph-plus-circle.icon-location:before, i.ph-plus-circle.icon-add:before, i.ph-plus-circle.icon-left:before, i.ph-plus-circle.icon-dna:before, i.ph-plus-circle.icon-delete:before, i.ph-plus-circle.icon-edit:before, i.ph-plus-circle.icon-info:before {
  content: "\e3d6";
}

.ph.ph-plus-minus:before, i.ph-plus-minus.icon-dead:before, i.ph-plus-minus.icon-alive:before, i.ph-plus-minus.icon-warn:before, i.ph-plus-minus.icon-sign-out:before, i.ph-plus-minus.icon-substratum:before, i.ph-plus-minus.icon-environment:before, i.ph-plus-minus.icon-user:before, i.ph-plus-minus.icon-person:before, i.ph-plus-minus.icon-terrain:before, i.ph-plus-minus.icon-taxon:before, i.ph-plus-minus.icon-unknown:before, i.ph-plus-minus.icon-nothing:before, i.ph-plus-minus.icon-sampling:before, i.ph-plus-minus.icon-isolation:before, i.ph-plus-minus.icon-strain:before, i.ph-plus-minus.icon-gps:before, i.ph-plus-minus.icon-location:before, i.ph-plus-minus.icon-add:before, i.ph-plus-minus.icon-left:before, i.ph-plus-minus.icon-dna:before, i.ph-plus-minus.icon-delete:before, i.ph-plus-minus.icon-edit:before, i.ph-plus-minus.icon-info:before {
  content: "\e3d8";
}

.ph.ph-plus-square:before, i.ph-plus-square.icon-dead:before, i.ph-plus-square.icon-alive:before, i.ph-plus-square.icon-warn:before, i.ph-plus-square.icon-sign-out:before, i.ph-plus-square.icon-substratum:before, i.ph-plus-square.icon-environment:before, i.ph-plus-square.icon-user:before, i.ph-plus-square.icon-person:before, i.ph-plus-square.icon-terrain:before, i.ph-plus-square.icon-taxon:before, i.ph-plus-square.icon-unknown:before, i.ph-plus-square.icon-nothing:before, i.ph-plus-square.icon-sampling:before, i.ph-plus-square.icon-isolation:before, i.ph-plus-square.icon-strain:before, i.ph-plus-square.icon-gps:before, i.ph-plus-square.icon-location:before, i.ph-plus-square.icon-add:before, i.ph-plus-square.icon-left:before, i.ph-plus-square.icon-dna:before, i.ph-plus-square.icon-delete:before, i.ph-plus-square.icon-edit:before, i.ph-plus-square.icon-info:before {
  content: "\ed4a";
}

.ph.ph-poker-chip:before, i.ph-poker-chip.icon-dead:before, i.ph-poker-chip.icon-alive:before, i.ph-poker-chip.icon-warn:before, i.ph-poker-chip.icon-sign-out:before, i.ph-poker-chip.icon-substratum:before, i.ph-poker-chip.icon-environment:before, i.ph-poker-chip.icon-user:before, i.ph-poker-chip.icon-person:before, i.ph-poker-chip.icon-terrain:before, i.ph-poker-chip.icon-taxon:before, i.ph-poker-chip.icon-unknown:before, i.ph-poker-chip.icon-nothing:before, i.ph-poker-chip.icon-sampling:before, i.ph-poker-chip.icon-isolation:before, i.ph-poker-chip.icon-strain:before, i.ph-poker-chip.icon-gps:before, i.ph-poker-chip.icon-location:before, i.ph-poker-chip.icon-add:before, i.ph-poker-chip.icon-left:before, i.ph-poker-chip.icon-dna:before, i.ph-poker-chip.icon-delete:before, i.ph-poker-chip.icon-edit:before, i.ph-poker-chip.icon-info:before {
  content: "\e594";
}

.ph.ph-police-car:before, i.ph-police-car.icon-dead:before, i.ph-police-car.icon-alive:before, i.ph-police-car.icon-warn:before, i.ph-police-car.icon-sign-out:before, i.ph-police-car.icon-substratum:before, i.ph-police-car.icon-environment:before, i.ph-police-car.icon-user:before, i.ph-police-car.icon-person:before, i.ph-police-car.icon-terrain:before, i.ph-police-car.icon-taxon:before, i.ph-police-car.icon-unknown:before, i.ph-police-car.icon-nothing:before, i.ph-police-car.icon-sampling:before, i.ph-police-car.icon-isolation:before, i.ph-police-car.icon-strain:before, i.ph-police-car.icon-gps:before, i.ph-police-car.icon-location:before, i.ph-police-car.icon-add:before, i.ph-police-car.icon-left:before, i.ph-police-car.icon-dna:before, i.ph-police-car.icon-delete:before, i.ph-police-car.icon-edit:before, i.ph-police-car.icon-info:before {
  content: "\ec4a";
}

.ph.ph-polygon:before, i.ph-polygon.icon-dead:before, i.ph-polygon.icon-alive:before, i.ph-polygon.icon-warn:before, i.ph-polygon.icon-sign-out:before, i.ph-polygon.icon-substratum:before, i.ph-polygon.icon-environment:before, i.ph-polygon.icon-user:before, i.ph-polygon.icon-person:before, i.ph-polygon.icon-terrain:before, i.ph-polygon.icon-taxon:before, i.ph-polygon.icon-unknown:before, i.ph-polygon.icon-nothing:before, i.ph-polygon.icon-sampling:before, i.ph-polygon.icon-isolation:before, i.ph-polygon.icon-strain:before, i.ph-polygon.icon-gps:before, i.ph-polygon.icon-location:before, i.ph-polygon.icon-add:before, i.ph-polygon.icon-left:before, i.ph-polygon.icon-dna:before, i.ph-polygon.icon-delete:before, i.ph-polygon.icon-edit:before, i.ph-polygon.icon-info:before {
  content: "\e6d0";
}

.ph.ph-popcorn:before, i.ph-popcorn.icon-dead:before, i.ph-popcorn.icon-alive:before, i.ph-popcorn.icon-warn:before, i.ph-popcorn.icon-sign-out:before, i.ph-popcorn.icon-substratum:before, i.ph-popcorn.icon-environment:before, i.ph-popcorn.icon-user:before, i.ph-popcorn.icon-person:before, i.ph-popcorn.icon-terrain:before, i.ph-popcorn.icon-taxon:before, i.ph-popcorn.icon-unknown:before, i.ph-popcorn.icon-nothing:before, i.ph-popcorn.icon-sampling:before, i.ph-popcorn.icon-isolation:before, i.ph-popcorn.icon-strain:before, i.ph-popcorn.icon-gps:before, i.ph-popcorn.icon-location:before, i.ph-popcorn.icon-add:before, i.ph-popcorn.icon-left:before, i.ph-popcorn.icon-dna:before, i.ph-popcorn.icon-delete:before, i.ph-popcorn.icon-edit:before, i.ph-popcorn.icon-info:before {
  content: "\eb4e";
}

.ph.ph-popsicle:before, i.ph-popsicle.icon-dead:before, i.ph-popsicle.icon-alive:before, i.ph-popsicle.icon-warn:before, i.ph-popsicle.icon-sign-out:before, i.ph-popsicle.icon-substratum:before, i.ph-popsicle.icon-environment:before, i.ph-popsicle.icon-user:before, i.ph-popsicle.icon-person:before, i.ph-popsicle.icon-terrain:before, i.ph-popsicle.icon-taxon:before, i.ph-popsicle.icon-unknown:before, i.ph-popsicle.icon-nothing:before, i.ph-popsicle.icon-sampling:before, i.ph-popsicle.icon-isolation:before, i.ph-popsicle.icon-strain:before, i.ph-popsicle.icon-gps:before, i.ph-popsicle.icon-location:before, i.ph-popsicle.icon-add:before, i.ph-popsicle.icon-left:before, i.ph-popsicle.icon-dna:before, i.ph-popsicle.icon-delete:before, i.ph-popsicle.icon-edit:before, i.ph-popsicle.icon-info:before {
  content: "\ebbe";
}

.ph.ph-potted-plant:before, i.ph-potted-plant.icon-dead:before, i.ph-potted-plant.icon-alive:before, i.ph-potted-plant.icon-warn:before, i.ph-potted-plant.icon-sign-out:before, i.ph-potted-plant.icon-substratum:before, i.ph-potted-plant.icon-environment:before, i.ph-potted-plant.icon-user:before, i.ph-potted-plant.icon-person:before, i.ph-potted-plant.icon-terrain:before, i.ph-potted-plant.icon-taxon:before, i.ph-potted-plant.icon-unknown:before, i.ph-potted-plant.icon-nothing:before, i.ph-potted-plant.icon-sampling:before, i.ph-potted-plant.icon-isolation:before, i.ph-potted-plant.icon-strain:before, i.ph-potted-plant.icon-gps:before, i.ph-potted-plant.icon-location:before, i.ph-potted-plant.icon-add:before, i.ph-potted-plant.icon-left:before, i.ph-potted-plant.icon-dna:before, i.ph-potted-plant.icon-delete:before, i.ph-potted-plant.icon-edit:before, i.ph-potted-plant.icon-info:before {
  content: "\ec22";
}

.ph.ph-power:before, i.ph-power.icon-dead:before, i.ph-power.icon-alive:before, i.ph-power.icon-warn:before, i.ph-power.icon-sign-out:before, i.ph-power.icon-substratum:before, i.ph-power.icon-environment:before, i.ph-power.icon-user:before, i.ph-power.icon-person:before, i.ph-power.icon-terrain:before, i.ph-power.icon-taxon:before, i.ph-power.icon-unknown:before, i.ph-power.icon-nothing:before, i.ph-power.icon-sampling:before, i.ph-power.icon-isolation:before, i.ph-power.icon-strain:before, i.ph-power.icon-gps:before, i.ph-power.icon-location:before, i.ph-power.icon-add:before, i.ph-power.icon-left:before, i.ph-power.icon-dna:before, i.ph-power.icon-delete:before, i.ph-power.icon-edit:before, i.ph-power.icon-info:before {
  content: "\e3da";
}

.ph.ph-prescription:before, i.ph-prescription.icon-dead:before, i.ph-prescription.icon-alive:before, i.ph-prescription.icon-warn:before, i.ph-prescription.icon-sign-out:before, i.ph-prescription.icon-substratum:before, i.ph-prescription.icon-environment:before, i.ph-prescription.icon-user:before, i.ph-prescription.icon-person:before, i.ph-prescription.icon-terrain:before, i.ph-prescription.icon-taxon:before, i.ph-prescription.icon-unknown:before, i.ph-prescription.icon-nothing:before, i.ph-prescription.icon-sampling:before, i.ph-prescription.icon-isolation:before, i.ph-prescription.icon-strain:before, i.ph-prescription.icon-gps:before, i.ph-prescription.icon-location:before, i.ph-prescription.icon-add:before, i.ph-prescription.icon-left:before, i.ph-prescription.icon-dna:before, i.ph-prescription.icon-delete:before, i.ph-prescription.icon-edit:before, i.ph-prescription.icon-info:before {
  content: "\e7a2";
}

.ph.ph-presentation:before, i.ph-presentation.icon-dead:before, i.ph-presentation.icon-alive:before, i.ph-presentation.icon-warn:before, i.ph-presentation.icon-sign-out:before, i.ph-presentation.icon-substratum:before, i.ph-presentation.icon-environment:before, i.ph-presentation.icon-user:before, i.ph-presentation.icon-person:before, i.ph-presentation.icon-terrain:before, i.ph-presentation.icon-taxon:before, i.ph-presentation.icon-unknown:before, i.ph-presentation.icon-nothing:before, i.ph-presentation.icon-sampling:before, i.ph-presentation.icon-isolation:before, i.ph-presentation.icon-strain:before, i.ph-presentation.icon-gps:before, i.ph-presentation.icon-location:before, i.ph-presentation.icon-add:before, i.ph-presentation.icon-left:before, i.ph-presentation.icon-dna:before, i.ph-presentation.icon-delete:before, i.ph-presentation.icon-edit:before, i.ph-presentation.icon-info:before {
  content: "\e654";
}

.ph.ph-presentation-chart:before, i.ph-presentation-chart.icon-dead:before, i.ph-presentation-chart.icon-alive:before, i.ph-presentation-chart.icon-warn:before, i.ph-presentation-chart.icon-sign-out:before, i.ph-presentation-chart.icon-substratum:before, i.ph-presentation-chart.icon-environment:before, i.ph-presentation-chart.icon-user:before, i.ph-presentation-chart.icon-person:before, i.ph-presentation-chart.icon-terrain:before, i.ph-presentation-chart.icon-taxon:before, i.ph-presentation-chart.icon-unknown:before, i.ph-presentation-chart.icon-nothing:before, i.ph-presentation-chart.icon-sampling:before, i.ph-presentation-chart.icon-isolation:before, i.ph-presentation-chart.icon-strain:before, i.ph-presentation-chart.icon-gps:before, i.ph-presentation-chart.icon-location:before, i.ph-presentation-chart.icon-add:before, i.ph-presentation-chart.icon-left:before, i.ph-presentation-chart.icon-dna:before, i.ph-presentation-chart.icon-delete:before, i.ph-presentation-chart.icon-edit:before, i.ph-presentation-chart.icon-info:before {
  content: "\e656";
}

.ph.ph-printer:before, i.ph-printer.icon-dead:before, i.ph-printer.icon-alive:before, i.ph-printer.icon-warn:before, i.ph-printer.icon-sign-out:before, i.ph-printer.icon-substratum:before, i.ph-printer.icon-environment:before, i.ph-printer.icon-user:before, i.ph-printer.icon-person:before, i.ph-printer.icon-terrain:before, i.ph-printer.icon-taxon:before, i.ph-printer.icon-unknown:before, i.ph-printer.icon-nothing:before, i.ph-printer.icon-sampling:before, i.ph-printer.icon-isolation:before, i.ph-printer.icon-strain:before, i.ph-printer.icon-gps:before, i.ph-printer.icon-location:before, i.ph-printer.icon-add:before, i.ph-printer.icon-left:before, i.ph-printer.icon-dna:before, i.ph-printer.icon-delete:before, i.ph-printer.icon-edit:before, i.ph-printer.icon-info:before {
  content: "\e3dc";
}

.ph.ph-prohibit:before, i.ph-prohibit.icon-dead:before, i.ph-prohibit.icon-alive:before, i.ph-prohibit.icon-warn:before, i.ph-prohibit.icon-sign-out:before, i.ph-prohibit.icon-substratum:before, i.ph-prohibit.icon-environment:before, i.ph-prohibit.icon-user:before, i.ph-prohibit.icon-person:before, i.ph-prohibit.icon-terrain:before, i.ph-prohibit.icon-taxon:before, i.ph-prohibit.icon-unknown:before, i.ph-prohibit.icon-nothing:before, i.ph-prohibit.icon-sampling:before, i.ph-prohibit.icon-isolation:before, i.ph-prohibit.icon-strain:before, i.ph-prohibit.icon-gps:before, i.ph-prohibit.icon-location:before, i.ph-prohibit.icon-add:before, i.ph-prohibit.icon-left:before, i.ph-prohibit.icon-dna:before, i.ph-prohibit.icon-delete:before, i.ph-prohibit.icon-edit:before, i.ph-prohibit.icon-info:before {
  content: "\e3de";
}

.ph.ph-prohibit-inset:before, i.ph-prohibit-inset.icon-dead:before, i.ph-prohibit-inset.icon-alive:before, i.ph-prohibit-inset.icon-warn:before, i.ph-prohibit-inset.icon-sign-out:before, i.ph-prohibit-inset.icon-substratum:before, i.ph-prohibit-inset.icon-environment:before, i.ph-prohibit-inset.icon-user:before, i.ph-prohibit-inset.icon-person:before, i.ph-prohibit-inset.icon-terrain:before, i.ph-prohibit-inset.icon-taxon:before, i.ph-prohibit-inset.icon-unknown:before, i.ph-prohibit-inset.icon-nothing:before, i.ph-prohibit-inset.icon-sampling:before, i.ph-prohibit-inset.icon-isolation:before, i.ph-prohibit-inset.icon-strain:before, i.ph-prohibit-inset.icon-gps:before, i.ph-prohibit-inset.icon-location:before, i.ph-prohibit-inset.icon-add:before, i.ph-prohibit-inset.icon-left:before, i.ph-prohibit-inset.icon-dna:before, i.ph-prohibit-inset.icon-delete:before, i.ph-prohibit-inset.icon-edit:before, i.ph-prohibit-inset.icon-info:before {
  content: "\e3e0";
}

.ph.ph-projector-screen:before, i.ph-projector-screen.icon-dead:before, i.ph-projector-screen.icon-alive:before, i.ph-projector-screen.icon-warn:before, i.ph-projector-screen.icon-sign-out:before, i.ph-projector-screen.icon-substratum:before, i.ph-projector-screen.icon-environment:before, i.ph-projector-screen.icon-user:before, i.ph-projector-screen.icon-person:before, i.ph-projector-screen.icon-terrain:before, i.ph-projector-screen.icon-taxon:before, i.ph-projector-screen.icon-unknown:before, i.ph-projector-screen.icon-nothing:before, i.ph-projector-screen.icon-sampling:before, i.ph-projector-screen.icon-isolation:before, i.ph-projector-screen.icon-strain:before, i.ph-projector-screen.icon-gps:before, i.ph-projector-screen.icon-location:before, i.ph-projector-screen.icon-add:before, i.ph-projector-screen.icon-left:before, i.ph-projector-screen.icon-dna:before, i.ph-projector-screen.icon-delete:before, i.ph-projector-screen.icon-edit:before, i.ph-projector-screen.icon-info:before {
  content: "\e658";
}

.ph.ph-projector-screen-chart:before, i.ph-projector-screen-chart.icon-dead:before, i.ph-projector-screen-chart.icon-alive:before, i.ph-projector-screen-chart.icon-warn:before, i.ph-projector-screen-chart.icon-sign-out:before, i.ph-projector-screen-chart.icon-substratum:before, i.ph-projector-screen-chart.icon-environment:before, i.ph-projector-screen-chart.icon-user:before, i.ph-projector-screen-chart.icon-person:before, i.ph-projector-screen-chart.icon-terrain:before, i.ph-projector-screen-chart.icon-taxon:before, i.ph-projector-screen-chart.icon-unknown:before, i.ph-projector-screen-chart.icon-nothing:before, i.ph-projector-screen-chart.icon-sampling:before, i.ph-projector-screen-chart.icon-isolation:before, i.ph-projector-screen-chart.icon-strain:before, i.ph-projector-screen-chart.icon-gps:before, i.ph-projector-screen-chart.icon-location:before, i.ph-projector-screen-chart.icon-add:before, i.ph-projector-screen-chart.icon-left:before, i.ph-projector-screen-chart.icon-dna:before, i.ph-projector-screen-chart.icon-delete:before, i.ph-projector-screen-chart.icon-edit:before, i.ph-projector-screen-chart.icon-info:before {
  content: "\e65a";
}

.ph.ph-pulse:before, i.ph-pulse.icon-dead:before, i.ph-pulse.icon-alive:before, i.ph-pulse.icon-warn:before, i.ph-pulse.icon-sign-out:before, i.ph-pulse.icon-substratum:before, i.ph-pulse.icon-environment:before, i.ph-pulse.icon-user:before, i.ph-pulse.icon-person:before, i.ph-pulse.icon-terrain:before, i.ph-pulse.icon-taxon:before, i.ph-pulse.icon-unknown:before, i.ph-pulse.icon-nothing:before, i.ph-pulse.icon-sampling:before, i.ph-pulse.icon-isolation:before, i.ph-pulse.icon-strain:before, i.ph-pulse.icon-gps:before, i.ph-pulse.icon-location:before, i.ph-pulse.icon-add:before, i.ph-pulse.icon-left:before, i.ph-pulse.icon-dna:before, i.ph-pulse.icon-delete:before, i.ph-pulse.icon-edit:before, i.ph-pulse.icon-info:before {
  content: "\e000";
}

.ph.ph-activity:before, i.ph-activity.icon-dead:before, i.ph-activity.icon-alive:before, i.ph-activity.icon-warn:before, i.ph-activity.icon-sign-out:before, i.ph-activity.icon-substratum:before, i.ph-activity.icon-environment:before, i.ph-activity.icon-user:before, i.ph-activity.icon-person:before, i.ph-activity.icon-terrain:before, i.ph-activity.icon-taxon:before, i.ph-activity.icon-unknown:before, i.ph-activity.icon-nothing:before, i.ph-activity.icon-sampling:before, i.ph-activity.icon-isolation:before, i.ph-activity.icon-strain:before, i.ph-activity.icon-gps:before, i.ph-activity.icon-location:before, i.ph-activity.icon-add:before, i.ph-activity.icon-left:before, i.ph-activity.icon-dna:before, i.ph-activity.icon-delete:before, i.ph-activity.icon-edit:before, i.ph-activity.icon-info:before {
  content: "\e000";
}

.ph.ph-push-pin:before, i.ph-push-pin.icon-dead:before, i.ph-push-pin.icon-alive:before, i.ph-push-pin.icon-warn:before, i.ph-push-pin.icon-sign-out:before, i.ph-push-pin.icon-substratum:before, i.ph-push-pin.icon-environment:before, i.ph-push-pin.icon-user:before, i.ph-push-pin.icon-person:before, i.ph-push-pin.icon-terrain:before, i.ph-push-pin.icon-taxon:before, i.ph-push-pin.icon-unknown:before, i.ph-push-pin.icon-nothing:before, i.ph-push-pin.icon-sampling:before, i.ph-push-pin.icon-isolation:before, i.ph-push-pin.icon-strain:before, i.ph-push-pin.icon-gps:before, i.ph-push-pin.icon-location:before, i.ph-push-pin.icon-add:before, i.ph-push-pin.icon-left:before, i.ph-push-pin.icon-dna:before, i.ph-push-pin.icon-delete:before, i.ph-push-pin.icon-edit:before, i.ph-push-pin.icon-info:before {
  content: "\e3e2";
}

.ph.ph-push-pin-simple:before, i.ph-push-pin-simple.icon-dead:before, i.ph-push-pin-simple.icon-alive:before, i.ph-push-pin-simple.icon-warn:before, i.ph-push-pin-simple.icon-sign-out:before, i.ph-push-pin-simple.icon-substratum:before, i.ph-push-pin-simple.icon-environment:before, i.ph-push-pin-simple.icon-user:before, i.ph-push-pin-simple.icon-person:before, i.ph-push-pin-simple.icon-terrain:before, i.ph-push-pin-simple.icon-taxon:before, i.ph-push-pin-simple.icon-unknown:before, i.ph-push-pin-simple.icon-nothing:before, i.ph-push-pin-simple.icon-sampling:before, i.ph-push-pin-simple.icon-isolation:before, i.ph-push-pin-simple.icon-strain:before, i.ph-push-pin-simple.icon-gps:before, i.ph-push-pin-simple.icon-location:before, i.ph-push-pin-simple.icon-add:before, i.ph-push-pin-simple.icon-left:before, i.ph-push-pin-simple.icon-dna:before, i.ph-push-pin-simple.icon-delete:before, i.ph-push-pin-simple.icon-edit:before, i.ph-push-pin-simple.icon-info:before {
  content: "\e65c";
}

.ph.ph-push-pin-simple-slash:before, i.ph-push-pin-simple-slash.icon-dead:before, i.ph-push-pin-simple-slash.icon-alive:before, i.ph-push-pin-simple-slash.icon-warn:before, i.ph-push-pin-simple-slash.icon-sign-out:before, i.ph-push-pin-simple-slash.icon-substratum:before, i.ph-push-pin-simple-slash.icon-environment:before, i.ph-push-pin-simple-slash.icon-user:before, i.ph-push-pin-simple-slash.icon-person:before, i.ph-push-pin-simple-slash.icon-terrain:before, i.ph-push-pin-simple-slash.icon-taxon:before, i.ph-push-pin-simple-slash.icon-unknown:before, i.ph-push-pin-simple-slash.icon-nothing:before, i.ph-push-pin-simple-slash.icon-sampling:before, i.ph-push-pin-simple-slash.icon-isolation:before, i.ph-push-pin-simple-slash.icon-strain:before, i.ph-push-pin-simple-slash.icon-gps:before, i.ph-push-pin-simple-slash.icon-location:before, i.ph-push-pin-simple-slash.icon-add:before, i.ph-push-pin-simple-slash.icon-left:before, i.ph-push-pin-simple-slash.icon-dna:before, i.ph-push-pin-simple-slash.icon-delete:before, i.ph-push-pin-simple-slash.icon-edit:before, i.ph-push-pin-simple-slash.icon-info:before {
  content: "\e65e";
}

.ph.ph-push-pin-slash:before, i.ph-push-pin-slash.icon-dead:before, i.ph-push-pin-slash.icon-alive:before, i.ph-push-pin-slash.icon-warn:before, i.ph-push-pin-slash.icon-sign-out:before, i.ph-push-pin-slash.icon-substratum:before, i.ph-push-pin-slash.icon-environment:before, i.ph-push-pin-slash.icon-user:before, i.ph-push-pin-slash.icon-person:before, i.ph-push-pin-slash.icon-terrain:before, i.ph-push-pin-slash.icon-taxon:before, i.ph-push-pin-slash.icon-unknown:before, i.ph-push-pin-slash.icon-nothing:before, i.ph-push-pin-slash.icon-sampling:before, i.ph-push-pin-slash.icon-isolation:before, i.ph-push-pin-slash.icon-strain:before, i.ph-push-pin-slash.icon-gps:before, i.ph-push-pin-slash.icon-location:before, i.ph-push-pin-slash.icon-add:before, i.ph-push-pin-slash.icon-left:before, i.ph-push-pin-slash.icon-dna:before, i.ph-push-pin-slash.icon-delete:before, i.ph-push-pin-slash.icon-edit:before, i.ph-push-pin-slash.icon-info:before {
  content: "\e3e4";
}

.ph.ph-puzzle-piece:before, i.ph-puzzle-piece.icon-dead:before, i.ph-puzzle-piece.icon-alive:before, i.ph-puzzle-piece.icon-warn:before, i.ph-puzzle-piece.icon-sign-out:before, i.ph-puzzle-piece.icon-substratum:before, i.ph-puzzle-piece.icon-environment:before, i.ph-puzzle-piece.icon-user:before, i.ph-puzzle-piece.icon-person:before, i.ph-puzzle-piece.icon-terrain:before, i.ph-puzzle-piece.icon-taxon:before, i.ph-puzzle-piece.icon-unknown:before, i.ph-puzzle-piece.icon-nothing:before, i.ph-puzzle-piece.icon-sampling:before, i.ph-puzzle-piece.icon-isolation:before, i.ph-puzzle-piece.icon-strain:before, i.ph-puzzle-piece.icon-gps:before, i.ph-puzzle-piece.icon-location:before, i.ph-puzzle-piece.icon-add:before, i.ph-puzzle-piece.icon-left:before, i.ph-puzzle-piece.icon-dna:before, i.ph-puzzle-piece.icon-delete:before, i.ph-puzzle-piece.icon-edit:before, i.ph-puzzle-piece.icon-info:before {
  content: "\e596";
}

.ph.ph-qr-code:before, i.ph-qr-code.icon-dead:before, i.ph-qr-code.icon-alive:before, i.ph-qr-code.icon-warn:before, i.ph-qr-code.icon-sign-out:before, i.ph-qr-code.icon-substratum:before, i.ph-qr-code.icon-environment:before, i.ph-qr-code.icon-user:before, i.ph-qr-code.icon-person:before, i.ph-qr-code.icon-terrain:before, i.ph-qr-code.icon-taxon:before, i.ph-qr-code.icon-unknown:before, i.ph-qr-code.icon-nothing:before, i.ph-qr-code.icon-sampling:before, i.ph-qr-code.icon-isolation:before, i.ph-qr-code.icon-strain:before, i.ph-qr-code.icon-gps:before, i.ph-qr-code.icon-location:before, i.ph-qr-code.icon-add:before, i.ph-qr-code.icon-left:before, i.ph-qr-code.icon-dna:before, i.ph-qr-code.icon-delete:before, i.ph-qr-code.icon-edit:before, i.ph-qr-code.icon-info:before {
  content: "\e3e6";
}

.ph.ph-question:before, i.ph-question.icon-dead:before, i.ph-question.icon-alive:before, i.ph-question.icon-warn:before, i.ph-question.icon-sign-out:before, i.ph-question.icon-substratum:before, i.ph-question.icon-environment:before, i.ph-question.icon-user:before, i.ph-question.icon-person:before, i.ph-question.icon-terrain:before, i.ph-question.icon-taxon:before, i.icon-unknown:before, i.ph-question.icon-nothing:before, i.ph-question.icon-sampling:before, i.ph-question.icon-isolation:before, i.ph-question.icon-strain:before, i.ph-question.icon-gps:before, i.ph-question.icon-location:before, i.ph-question.icon-add:before, i.ph-question.icon-left:before, i.ph-question.icon-dna:before, i.ph-question.icon-delete:before, i.ph-question.icon-edit:before, i.ph-question.icon-info:before {
  content: "\e3e8";
}

.ph.ph-question-mark:before, i.ph-question-mark.icon-dead:before, i.ph-question-mark.icon-alive:before, i.ph-question-mark.icon-warn:before, i.ph-question-mark.icon-sign-out:before, i.ph-question-mark.icon-substratum:before, i.ph-question-mark.icon-environment:before, i.ph-question-mark.icon-user:before, i.ph-question-mark.icon-person:before, i.ph-question-mark.icon-terrain:before, i.ph-question-mark.icon-taxon:before, i.ph-question-mark.icon-unknown:before, i.ph-question-mark.icon-nothing:before, i.ph-question-mark.icon-sampling:before, i.ph-question-mark.icon-isolation:before, i.ph-question-mark.icon-strain:before, i.ph-question-mark.icon-gps:before, i.ph-question-mark.icon-location:before, i.ph-question-mark.icon-add:before, i.ph-question-mark.icon-left:before, i.ph-question-mark.icon-dna:before, i.ph-question-mark.icon-delete:before, i.ph-question-mark.icon-edit:before, i.ph-question-mark.icon-info:before {
  content: "\e3e9";
}

.ph.ph-queue:before, i.ph-queue.icon-dead:before, i.ph-queue.icon-alive:before, i.ph-queue.icon-warn:before, i.ph-queue.icon-sign-out:before, i.ph-queue.icon-substratum:before, i.ph-queue.icon-environment:before, i.ph-queue.icon-user:before, i.ph-queue.icon-person:before, i.ph-queue.icon-terrain:before, i.ph-queue.icon-taxon:before, i.ph-queue.icon-unknown:before, i.ph-queue.icon-nothing:before, i.ph-queue.icon-sampling:before, i.ph-queue.icon-isolation:before, i.ph-queue.icon-strain:before, i.ph-queue.icon-gps:before, i.ph-queue.icon-location:before, i.ph-queue.icon-add:before, i.ph-queue.icon-left:before, i.ph-queue.icon-dna:before, i.ph-queue.icon-delete:before, i.ph-queue.icon-edit:before, i.ph-queue.icon-info:before {
  content: "\e6ac";
}

.ph.ph-quotes:before, i.ph-quotes.icon-dead:before, i.ph-quotes.icon-alive:before, i.ph-quotes.icon-warn:before, i.ph-quotes.icon-sign-out:before, i.ph-quotes.icon-substratum:before, i.ph-quotes.icon-environment:before, i.ph-quotes.icon-user:before, i.ph-quotes.icon-person:before, i.ph-quotes.icon-terrain:before, i.ph-quotes.icon-taxon:before, i.ph-quotes.icon-unknown:before, i.ph-quotes.icon-nothing:before, i.ph-quotes.icon-sampling:before, i.ph-quotes.icon-isolation:before, i.ph-quotes.icon-strain:before, i.ph-quotes.icon-gps:before, i.ph-quotes.icon-location:before, i.ph-quotes.icon-add:before, i.ph-quotes.icon-left:before, i.ph-quotes.icon-dna:before, i.ph-quotes.icon-delete:before, i.ph-quotes.icon-edit:before, i.ph-quotes.icon-info:before {
  content: "\e660";
}

.ph.ph-rabbit:before, i.ph-rabbit.icon-dead:before, i.ph-rabbit.icon-alive:before, i.ph-rabbit.icon-warn:before, i.ph-rabbit.icon-sign-out:before, i.ph-rabbit.icon-substratum:before, i.ph-rabbit.icon-environment:before, i.ph-rabbit.icon-user:before, i.ph-rabbit.icon-person:before, i.ph-rabbit.icon-terrain:before, i.ph-rabbit.icon-taxon:before, i.ph-rabbit.icon-unknown:before, i.ph-rabbit.icon-nothing:before, i.ph-rabbit.icon-sampling:before, i.ph-rabbit.icon-isolation:before, i.ph-rabbit.icon-strain:before, i.ph-rabbit.icon-gps:before, i.ph-rabbit.icon-location:before, i.ph-rabbit.icon-add:before, i.ph-rabbit.icon-left:before, i.ph-rabbit.icon-dna:before, i.ph-rabbit.icon-delete:before, i.ph-rabbit.icon-edit:before, i.ph-rabbit.icon-info:before {
  content: "\eac2";
}

.ph.ph-racquet:before, i.ph-racquet.icon-dead:before, i.ph-racquet.icon-alive:before, i.ph-racquet.icon-warn:before, i.ph-racquet.icon-sign-out:before, i.ph-racquet.icon-substratum:before, i.ph-racquet.icon-environment:before, i.ph-racquet.icon-user:before, i.ph-racquet.icon-person:before, i.ph-racquet.icon-terrain:before, i.ph-racquet.icon-taxon:before, i.ph-racquet.icon-unknown:before, i.ph-racquet.icon-nothing:before, i.ph-racquet.icon-sampling:before, i.ph-racquet.icon-isolation:before, i.ph-racquet.icon-strain:before, i.ph-racquet.icon-gps:before, i.ph-racquet.icon-location:before, i.ph-racquet.icon-add:before, i.ph-racquet.icon-left:before, i.ph-racquet.icon-dna:before, i.ph-racquet.icon-delete:before, i.ph-racquet.icon-edit:before, i.ph-racquet.icon-info:before {
  content: "\ee02";
}

.ph.ph-radical:before, i.ph-radical.icon-dead:before, i.ph-radical.icon-alive:before, i.ph-radical.icon-warn:before, i.ph-radical.icon-sign-out:before, i.ph-radical.icon-substratum:before, i.ph-radical.icon-environment:before, i.ph-radical.icon-user:before, i.ph-radical.icon-person:before, i.ph-radical.icon-terrain:before, i.ph-radical.icon-taxon:before, i.ph-radical.icon-unknown:before, i.ph-radical.icon-nothing:before, i.ph-radical.icon-sampling:before, i.ph-radical.icon-isolation:before, i.ph-radical.icon-strain:before, i.ph-radical.icon-gps:before, i.ph-radical.icon-location:before, i.ph-radical.icon-add:before, i.ph-radical.icon-left:before, i.ph-radical.icon-dna:before, i.ph-radical.icon-delete:before, i.ph-radical.icon-edit:before, i.ph-radical.icon-info:before {
  content: "\e3ea";
}

.ph.ph-radio:before, i.ph-radio.icon-dead:before, i.ph-radio.icon-alive:before, i.ph-radio.icon-warn:before, i.ph-radio.icon-sign-out:before, i.ph-radio.icon-substratum:before, i.ph-radio.icon-environment:before, i.ph-radio.icon-user:before, i.ph-radio.icon-person:before, i.ph-radio.icon-terrain:before, i.ph-radio.icon-taxon:before, i.ph-radio.icon-unknown:before, i.ph-radio.icon-nothing:before, i.ph-radio.icon-sampling:before, i.ph-radio.icon-isolation:before, i.ph-radio.icon-strain:before, i.ph-radio.icon-gps:before, i.ph-radio.icon-location:before, i.ph-radio.icon-add:before, i.ph-radio.icon-left:before, i.ph-radio.icon-dna:before, i.ph-radio.icon-delete:before, i.ph-radio.icon-edit:before, i.ph-radio.icon-info:before {
  content: "\e77e";
}

.ph.ph-radio-button:before, i.ph-radio-button.icon-dead:before, i.ph-radio-button.icon-alive:before, i.ph-radio-button.icon-warn:before, i.ph-radio-button.icon-sign-out:before, i.ph-radio-button.icon-substratum:before, i.ph-radio-button.icon-environment:before, i.ph-radio-button.icon-user:before, i.ph-radio-button.icon-person:before, i.ph-radio-button.icon-terrain:before, i.ph-radio-button.icon-taxon:before, i.ph-radio-button.icon-unknown:before, i.ph-radio-button.icon-nothing:before, i.ph-radio-button.icon-sampling:before, i.ph-radio-button.icon-isolation:before, i.ph-radio-button.icon-strain:before, i.ph-radio-button.icon-gps:before, i.ph-radio-button.icon-location:before, i.ph-radio-button.icon-add:before, i.ph-radio-button.icon-left:before, i.ph-radio-button.icon-dna:before, i.ph-radio-button.icon-delete:before, i.ph-radio-button.icon-edit:before, i.ph-radio-button.icon-info:before {
  content: "\eb08";
}

.ph.ph-radioactive:before, i.ph-radioactive.icon-dead:before, i.ph-radioactive.icon-alive:before, i.ph-radioactive.icon-warn:before, i.ph-radioactive.icon-sign-out:before, i.ph-radioactive.icon-substratum:before, i.ph-radioactive.icon-environment:before, i.ph-radioactive.icon-user:before, i.ph-radioactive.icon-person:before, i.ph-radioactive.icon-terrain:before, i.ph-radioactive.icon-taxon:before, i.ph-radioactive.icon-unknown:before, i.ph-radioactive.icon-nothing:before, i.ph-radioactive.icon-sampling:before, i.ph-radioactive.icon-isolation:before, i.ph-radioactive.icon-strain:before, i.ph-radioactive.icon-gps:before, i.ph-radioactive.icon-location:before, i.ph-radioactive.icon-add:before, i.ph-radioactive.icon-left:before, i.ph-radioactive.icon-dna:before, i.ph-radioactive.icon-delete:before, i.ph-radioactive.icon-edit:before, i.ph-radioactive.icon-info:before {
  content: "\e9dc";
}

.ph.ph-rainbow:before, i.ph-rainbow.icon-dead:before, i.ph-rainbow.icon-alive:before, i.ph-rainbow.icon-warn:before, i.ph-rainbow.icon-sign-out:before, i.ph-rainbow.icon-substratum:before, i.ph-rainbow.icon-environment:before, i.ph-rainbow.icon-user:before, i.ph-rainbow.icon-person:before, i.ph-rainbow.icon-terrain:before, i.ph-rainbow.icon-taxon:before, i.ph-rainbow.icon-unknown:before, i.ph-rainbow.icon-nothing:before, i.ph-rainbow.icon-sampling:before, i.ph-rainbow.icon-isolation:before, i.ph-rainbow.icon-strain:before, i.ph-rainbow.icon-gps:before, i.ph-rainbow.icon-location:before, i.ph-rainbow.icon-add:before, i.ph-rainbow.icon-left:before, i.ph-rainbow.icon-dna:before, i.ph-rainbow.icon-delete:before, i.ph-rainbow.icon-edit:before, i.ph-rainbow.icon-info:before {
  content: "\e598";
}

.ph.ph-rainbow-cloud:before, i.ph-rainbow-cloud.icon-dead:before, i.ph-rainbow-cloud.icon-alive:before, i.ph-rainbow-cloud.icon-warn:before, i.ph-rainbow-cloud.icon-sign-out:before, i.ph-rainbow-cloud.icon-substratum:before, i.ph-rainbow-cloud.icon-environment:before, i.ph-rainbow-cloud.icon-user:before, i.ph-rainbow-cloud.icon-person:before, i.ph-rainbow-cloud.icon-terrain:before, i.ph-rainbow-cloud.icon-taxon:before, i.ph-rainbow-cloud.icon-unknown:before, i.ph-rainbow-cloud.icon-nothing:before, i.ph-rainbow-cloud.icon-sampling:before, i.ph-rainbow-cloud.icon-isolation:before, i.ph-rainbow-cloud.icon-strain:before, i.ph-rainbow-cloud.icon-gps:before, i.ph-rainbow-cloud.icon-location:before, i.ph-rainbow-cloud.icon-add:before, i.ph-rainbow-cloud.icon-left:before, i.ph-rainbow-cloud.icon-dna:before, i.ph-rainbow-cloud.icon-delete:before, i.ph-rainbow-cloud.icon-edit:before, i.ph-rainbow-cloud.icon-info:before {
  content: "\e59a";
}

.ph.ph-ranking:before, i.ph-ranking.icon-dead:before, i.ph-ranking.icon-alive:before, i.ph-ranking.icon-warn:before, i.ph-ranking.icon-sign-out:before, i.ph-ranking.icon-substratum:before, i.ph-ranking.icon-environment:before, i.ph-ranking.icon-user:before, i.ph-ranking.icon-person:before, i.ph-ranking.icon-terrain:before, i.ph-ranking.icon-taxon:before, i.ph-ranking.icon-unknown:before, i.ph-ranking.icon-nothing:before, i.ph-ranking.icon-sampling:before, i.ph-ranking.icon-isolation:before, i.ph-ranking.icon-strain:before, i.ph-ranking.icon-gps:before, i.ph-ranking.icon-location:before, i.ph-ranking.icon-add:before, i.ph-ranking.icon-left:before, i.ph-ranking.icon-dna:before, i.ph-ranking.icon-delete:before, i.ph-ranking.icon-edit:before, i.ph-ranking.icon-info:before {
  content: "\ed62";
}

.ph.ph-read-cv-logo:before, i.ph-read-cv-logo.icon-dead:before, i.ph-read-cv-logo.icon-alive:before, i.ph-read-cv-logo.icon-warn:before, i.ph-read-cv-logo.icon-sign-out:before, i.ph-read-cv-logo.icon-substratum:before, i.ph-read-cv-logo.icon-environment:before, i.ph-read-cv-logo.icon-user:before, i.ph-read-cv-logo.icon-person:before, i.ph-read-cv-logo.icon-terrain:before, i.ph-read-cv-logo.icon-taxon:before, i.ph-read-cv-logo.icon-unknown:before, i.ph-read-cv-logo.icon-nothing:before, i.ph-read-cv-logo.icon-sampling:before, i.ph-read-cv-logo.icon-isolation:before, i.ph-read-cv-logo.icon-strain:before, i.ph-read-cv-logo.icon-gps:before, i.ph-read-cv-logo.icon-location:before, i.ph-read-cv-logo.icon-add:before, i.ph-read-cv-logo.icon-left:before, i.ph-read-cv-logo.icon-dna:before, i.ph-read-cv-logo.icon-delete:before, i.ph-read-cv-logo.icon-edit:before, i.ph-read-cv-logo.icon-info:before {
  content: "\ed0c";
}

.ph.ph-receipt:before, i.ph-receipt.icon-dead:before, i.ph-receipt.icon-alive:before, i.ph-receipt.icon-warn:before, i.ph-receipt.icon-sign-out:before, i.ph-receipt.icon-substratum:before, i.ph-receipt.icon-environment:before, i.ph-receipt.icon-user:before, i.ph-receipt.icon-person:before, i.ph-receipt.icon-terrain:before, i.ph-receipt.icon-taxon:before, i.ph-receipt.icon-unknown:before, i.ph-receipt.icon-nothing:before, i.ph-receipt.icon-sampling:before, i.ph-receipt.icon-isolation:before, i.ph-receipt.icon-strain:before, i.ph-receipt.icon-gps:before, i.ph-receipt.icon-location:before, i.ph-receipt.icon-add:before, i.ph-receipt.icon-left:before, i.ph-receipt.icon-dna:before, i.ph-receipt.icon-delete:before, i.ph-receipt.icon-edit:before, i.ph-receipt.icon-info:before {
  content: "\e3ec";
}

.ph.ph-receipt-x:before, i.ph-receipt-x.icon-dead:before, i.ph-receipt-x.icon-alive:before, i.ph-receipt-x.icon-warn:before, i.ph-receipt-x.icon-sign-out:before, i.ph-receipt-x.icon-substratum:before, i.ph-receipt-x.icon-environment:before, i.ph-receipt-x.icon-user:before, i.ph-receipt-x.icon-person:before, i.ph-receipt-x.icon-terrain:before, i.ph-receipt-x.icon-taxon:before, i.ph-receipt-x.icon-unknown:before, i.ph-receipt-x.icon-nothing:before, i.ph-receipt-x.icon-sampling:before, i.ph-receipt-x.icon-isolation:before, i.ph-receipt-x.icon-strain:before, i.ph-receipt-x.icon-gps:before, i.ph-receipt-x.icon-location:before, i.ph-receipt-x.icon-add:before, i.ph-receipt-x.icon-left:before, i.ph-receipt-x.icon-dna:before, i.ph-receipt-x.icon-delete:before, i.ph-receipt-x.icon-edit:before, i.ph-receipt-x.icon-info:before {
  content: "\ed40";
}

.ph.ph-record:before, i.ph-record.icon-dead:before, i.ph-record.icon-alive:before, i.ph-record.icon-warn:before, i.ph-record.icon-sign-out:before, i.ph-record.icon-substratum:before, i.ph-record.icon-environment:before, i.ph-record.icon-user:before, i.ph-record.icon-person:before, i.ph-record.icon-terrain:before, i.ph-record.icon-taxon:before, i.ph-record.icon-unknown:before, i.ph-record.icon-nothing:before, i.ph-record.icon-sampling:before, i.ph-record.icon-isolation:before, i.ph-record.icon-strain:before, i.ph-record.icon-gps:before, i.ph-record.icon-location:before, i.ph-record.icon-add:before, i.ph-record.icon-left:before, i.ph-record.icon-dna:before, i.ph-record.icon-delete:before, i.ph-record.icon-edit:before, i.ph-record.icon-info:before {
  content: "\e3ee";
}

.ph.ph-rectangle:before, i.ph-rectangle.icon-dead:before, i.ph-rectangle.icon-alive:before, i.ph-rectangle.icon-warn:before, i.ph-rectangle.icon-sign-out:before, i.ph-rectangle.icon-substratum:before, i.ph-rectangle.icon-environment:before, i.ph-rectangle.icon-user:before, i.ph-rectangle.icon-person:before, i.ph-rectangle.icon-terrain:before, i.ph-rectangle.icon-taxon:before, i.ph-rectangle.icon-unknown:before, i.ph-rectangle.icon-nothing:before, i.ph-rectangle.icon-sampling:before, i.ph-rectangle.icon-isolation:before, i.ph-rectangle.icon-strain:before, i.ph-rectangle.icon-gps:before, i.ph-rectangle.icon-location:before, i.ph-rectangle.icon-add:before, i.ph-rectangle.icon-left:before, i.ph-rectangle.icon-dna:before, i.ph-rectangle.icon-delete:before, i.ph-rectangle.icon-edit:before, i.ph-rectangle.icon-info:before {
  content: "\e3f0";
}

.ph.ph-rectangle-dashed:before, i.ph-rectangle-dashed.icon-dead:before, i.ph-rectangle-dashed.icon-alive:before, i.ph-rectangle-dashed.icon-warn:before, i.ph-rectangle-dashed.icon-sign-out:before, i.ph-rectangle-dashed.icon-substratum:before, i.ph-rectangle-dashed.icon-environment:before, i.ph-rectangle-dashed.icon-user:before, i.ph-rectangle-dashed.icon-person:before, i.ph-rectangle-dashed.icon-terrain:before, i.ph-rectangle-dashed.icon-taxon:before, i.ph-rectangle-dashed.icon-unknown:before, i.ph-rectangle-dashed.icon-nothing:before, i.ph-rectangle-dashed.icon-sampling:before, i.ph-rectangle-dashed.icon-isolation:before, i.ph-rectangle-dashed.icon-strain:before, i.ph-rectangle-dashed.icon-gps:before, i.ph-rectangle-dashed.icon-location:before, i.ph-rectangle-dashed.icon-add:before, i.ph-rectangle-dashed.icon-left:before, i.ph-rectangle-dashed.icon-dna:before, i.ph-rectangle-dashed.icon-delete:before, i.ph-rectangle-dashed.icon-edit:before, i.ph-rectangle-dashed.icon-info:before {
  content: "\e3f2";
}

.ph.ph-recycle:before, i.ph-recycle.icon-dead:before, i.ph-recycle.icon-alive:before, i.ph-recycle.icon-warn:before, i.ph-recycle.icon-sign-out:before, i.ph-recycle.icon-substratum:before, i.ph-recycle.icon-environment:before, i.ph-recycle.icon-user:before, i.ph-recycle.icon-person:before, i.ph-recycle.icon-terrain:before, i.ph-recycle.icon-taxon:before, i.ph-recycle.icon-unknown:before, i.ph-recycle.icon-nothing:before, i.ph-recycle.icon-sampling:before, i.ph-recycle.icon-isolation:before, i.ph-recycle.icon-strain:before, i.ph-recycle.icon-gps:before, i.ph-recycle.icon-location:before, i.ph-recycle.icon-add:before, i.ph-recycle.icon-left:before, i.ph-recycle.icon-dna:before, i.ph-recycle.icon-delete:before, i.ph-recycle.icon-edit:before, i.ph-recycle.icon-info:before {
  content: "\e75a";
}

.ph.ph-reddit-logo:before, i.ph-reddit-logo.icon-dead:before, i.ph-reddit-logo.icon-alive:before, i.ph-reddit-logo.icon-warn:before, i.ph-reddit-logo.icon-sign-out:before, i.ph-reddit-logo.icon-substratum:before, i.ph-reddit-logo.icon-environment:before, i.ph-reddit-logo.icon-user:before, i.ph-reddit-logo.icon-person:before, i.ph-reddit-logo.icon-terrain:before, i.ph-reddit-logo.icon-taxon:before, i.ph-reddit-logo.icon-unknown:before, i.ph-reddit-logo.icon-nothing:before, i.ph-reddit-logo.icon-sampling:before, i.ph-reddit-logo.icon-isolation:before, i.ph-reddit-logo.icon-strain:before, i.ph-reddit-logo.icon-gps:before, i.ph-reddit-logo.icon-location:before, i.ph-reddit-logo.icon-add:before, i.ph-reddit-logo.icon-left:before, i.ph-reddit-logo.icon-dna:before, i.ph-reddit-logo.icon-delete:before, i.ph-reddit-logo.icon-edit:before, i.ph-reddit-logo.icon-info:before {
  content: "\e59c";
}

.ph.ph-repeat:before, i.ph-repeat.icon-dead:before, i.ph-repeat.icon-alive:before, i.ph-repeat.icon-warn:before, i.ph-repeat.icon-sign-out:before, i.ph-repeat.icon-substratum:before, i.ph-repeat.icon-environment:before, i.ph-repeat.icon-user:before, i.ph-repeat.icon-person:before, i.ph-repeat.icon-terrain:before, i.ph-repeat.icon-taxon:before, i.ph-repeat.icon-unknown:before, i.ph-repeat.icon-nothing:before, i.ph-repeat.icon-sampling:before, i.ph-repeat.icon-isolation:before, i.ph-repeat.icon-strain:before, i.ph-repeat.icon-gps:before, i.ph-repeat.icon-location:before, i.ph-repeat.icon-add:before, i.ph-repeat.icon-left:before, i.ph-repeat.icon-dna:before, i.ph-repeat.icon-delete:before, i.ph-repeat.icon-edit:before, i.ph-repeat.icon-info:before {
  content: "\e3f6";
}

.ph.ph-repeat-once:before, i.ph-repeat-once.icon-dead:before, i.ph-repeat-once.icon-alive:before, i.ph-repeat-once.icon-warn:before, i.ph-repeat-once.icon-sign-out:before, i.ph-repeat-once.icon-substratum:before, i.ph-repeat-once.icon-environment:before, i.ph-repeat-once.icon-user:before, i.ph-repeat-once.icon-person:before, i.ph-repeat-once.icon-terrain:before, i.ph-repeat-once.icon-taxon:before, i.ph-repeat-once.icon-unknown:before, i.ph-repeat-once.icon-nothing:before, i.ph-repeat-once.icon-sampling:before, i.ph-repeat-once.icon-isolation:before, i.ph-repeat-once.icon-strain:before, i.ph-repeat-once.icon-gps:before, i.ph-repeat-once.icon-location:before, i.ph-repeat-once.icon-add:before, i.ph-repeat-once.icon-left:before, i.ph-repeat-once.icon-dna:before, i.ph-repeat-once.icon-delete:before, i.ph-repeat-once.icon-edit:before, i.ph-repeat-once.icon-info:before {
  content: "\e3f8";
}

.ph.ph-replit-logo:before, i.ph-replit-logo.icon-dead:before, i.ph-replit-logo.icon-alive:before, i.ph-replit-logo.icon-warn:before, i.ph-replit-logo.icon-sign-out:before, i.ph-replit-logo.icon-substratum:before, i.ph-replit-logo.icon-environment:before, i.ph-replit-logo.icon-user:before, i.ph-replit-logo.icon-person:before, i.ph-replit-logo.icon-terrain:before, i.ph-replit-logo.icon-taxon:before, i.ph-replit-logo.icon-unknown:before, i.ph-replit-logo.icon-nothing:before, i.ph-replit-logo.icon-sampling:before, i.ph-replit-logo.icon-isolation:before, i.ph-replit-logo.icon-strain:before, i.ph-replit-logo.icon-gps:before, i.ph-replit-logo.icon-location:before, i.ph-replit-logo.icon-add:before, i.ph-replit-logo.icon-left:before, i.ph-replit-logo.icon-dna:before, i.ph-replit-logo.icon-delete:before, i.ph-replit-logo.icon-edit:before, i.ph-replit-logo.icon-info:before {
  content: "\eb8a";
}

.ph.ph-resize:before, i.ph-resize.icon-dead:before, i.ph-resize.icon-alive:before, i.ph-resize.icon-warn:before, i.ph-resize.icon-sign-out:before, i.ph-resize.icon-substratum:before, i.ph-resize.icon-environment:before, i.ph-resize.icon-user:before, i.ph-resize.icon-person:before, i.ph-resize.icon-terrain:before, i.ph-resize.icon-taxon:before, i.ph-resize.icon-unknown:before, i.ph-resize.icon-nothing:before, i.ph-resize.icon-sampling:before, i.ph-resize.icon-isolation:before, i.ph-resize.icon-strain:before, i.ph-resize.icon-gps:before, i.ph-resize.icon-location:before, i.ph-resize.icon-add:before, i.ph-resize.icon-left:before, i.ph-resize.icon-dna:before, i.ph-resize.icon-delete:before, i.ph-resize.icon-edit:before, i.ph-resize.icon-info:before {
  content: "\ed6e";
}

.ph.ph-rewind:before, i.ph-rewind.icon-dead:before, i.ph-rewind.icon-alive:before, i.ph-rewind.icon-warn:before, i.ph-rewind.icon-sign-out:before, i.ph-rewind.icon-substratum:before, i.ph-rewind.icon-environment:before, i.ph-rewind.icon-user:before, i.ph-rewind.icon-person:before, i.ph-rewind.icon-terrain:before, i.ph-rewind.icon-taxon:before, i.ph-rewind.icon-unknown:before, i.ph-rewind.icon-nothing:before, i.ph-rewind.icon-sampling:before, i.ph-rewind.icon-isolation:before, i.ph-rewind.icon-strain:before, i.ph-rewind.icon-gps:before, i.ph-rewind.icon-location:before, i.ph-rewind.icon-add:before, i.ph-rewind.icon-left:before, i.ph-rewind.icon-dna:before, i.ph-rewind.icon-delete:before, i.ph-rewind.icon-edit:before, i.ph-rewind.icon-info:before {
  content: "\e6a8";
}

.ph.ph-rewind-circle:before, i.ph-rewind-circle.icon-dead:before, i.ph-rewind-circle.icon-alive:before, i.ph-rewind-circle.icon-warn:before, i.ph-rewind-circle.icon-sign-out:before, i.ph-rewind-circle.icon-substratum:before, i.ph-rewind-circle.icon-environment:before, i.ph-rewind-circle.icon-user:before, i.ph-rewind-circle.icon-person:before, i.ph-rewind-circle.icon-terrain:before, i.ph-rewind-circle.icon-taxon:before, i.ph-rewind-circle.icon-unknown:before, i.ph-rewind-circle.icon-nothing:before, i.ph-rewind-circle.icon-sampling:before, i.ph-rewind-circle.icon-isolation:before, i.ph-rewind-circle.icon-strain:before, i.ph-rewind-circle.icon-gps:before, i.ph-rewind-circle.icon-location:before, i.ph-rewind-circle.icon-add:before, i.ph-rewind-circle.icon-left:before, i.ph-rewind-circle.icon-dna:before, i.ph-rewind-circle.icon-delete:before, i.ph-rewind-circle.icon-edit:before, i.ph-rewind-circle.icon-info:before {
  content: "\e3fa";
}

.ph.ph-road-horizon:before, i.ph-road-horizon.icon-dead:before, i.ph-road-horizon.icon-alive:before, i.ph-road-horizon.icon-warn:before, i.ph-road-horizon.icon-sign-out:before, i.ph-road-horizon.icon-substratum:before, i.ph-road-horizon.icon-environment:before, i.ph-road-horizon.icon-user:before, i.ph-road-horizon.icon-person:before, i.ph-road-horizon.icon-terrain:before, i.ph-road-horizon.icon-taxon:before, i.ph-road-horizon.icon-unknown:before, i.ph-road-horizon.icon-nothing:before, i.ph-road-horizon.icon-sampling:before, i.ph-road-horizon.icon-isolation:before, i.ph-road-horizon.icon-strain:before, i.ph-road-horizon.icon-gps:before, i.ph-road-horizon.icon-location:before, i.ph-road-horizon.icon-add:before, i.ph-road-horizon.icon-left:before, i.ph-road-horizon.icon-dna:before, i.ph-road-horizon.icon-delete:before, i.ph-road-horizon.icon-edit:before, i.ph-road-horizon.icon-info:before {
  content: "\e838";
}

.ph.ph-robot:before, i.ph-robot.icon-dead:before, i.ph-robot.icon-alive:before, i.ph-robot.icon-warn:before, i.ph-robot.icon-sign-out:before, i.ph-robot.icon-substratum:before, i.ph-robot.icon-environment:before, i.ph-robot.icon-user:before, i.ph-robot.icon-person:before, i.ph-robot.icon-terrain:before, i.ph-robot.icon-taxon:before, i.ph-robot.icon-unknown:before, i.ph-robot.icon-nothing:before, i.ph-robot.icon-sampling:before, i.ph-robot.icon-isolation:before, i.ph-robot.icon-strain:before, i.ph-robot.icon-gps:before, i.ph-robot.icon-location:before, i.ph-robot.icon-add:before, i.ph-robot.icon-left:before, i.ph-robot.icon-dna:before, i.ph-robot.icon-delete:before, i.ph-robot.icon-edit:before, i.ph-robot.icon-info:before {
  content: "\e762";
}

.ph.ph-rocket:before, i.ph-rocket.icon-dead:before, i.ph-rocket.icon-alive:before, i.ph-rocket.icon-warn:before, i.ph-rocket.icon-sign-out:before, i.ph-rocket.icon-substratum:before, i.ph-rocket.icon-environment:before, i.ph-rocket.icon-user:before, i.ph-rocket.icon-person:before, i.ph-rocket.icon-terrain:before, i.ph-rocket.icon-taxon:before, i.ph-rocket.icon-unknown:before, i.ph-rocket.icon-nothing:before, i.ph-rocket.icon-sampling:before, i.ph-rocket.icon-isolation:before, i.ph-rocket.icon-strain:before, i.ph-rocket.icon-gps:before, i.ph-rocket.icon-location:before, i.ph-rocket.icon-add:before, i.ph-rocket.icon-left:before, i.ph-rocket.icon-dna:before, i.ph-rocket.icon-delete:before, i.ph-rocket.icon-edit:before, i.ph-rocket.icon-info:before {
  content: "\e3fc";
}

.ph.ph-rocket-launch:before, i.ph-rocket-launch.icon-dead:before, i.ph-rocket-launch.icon-alive:before, i.ph-rocket-launch.icon-warn:before, i.ph-rocket-launch.icon-sign-out:before, i.ph-rocket-launch.icon-substratum:before, i.ph-rocket-launch.icon-environment:before, i.ph-rocket-launch.icon-user:before, i.ph-rocket-launch.icon-person:before, i.ph-rocket-launch.icon-terrain:before, i.ph-rocket-launch.icon-taxon:before, i.ph-rocket-launch.icon-unknown:before, i.ph-rocket-launch.icon-nothing:before, i.ph-rocket-launch.icon-sampling:before, i.ph-rocket-launch.icon-isolation:before, i.ph-rocket-launch.icon-strain:before, i.ph-rocket-launch.icon-gps:before, i.ph-rocket-launch.icon-location:before, i.ph-rocket-launch.icon-add:before, i.ph-rocket-launch.icon-left:before, i.ph-rocket-launch.icon-dna:before, i.ph-rocket-launch.icon-delete:before, i.ph-rocket-launch.icon-edit:before, i.ph-rocket-launch.icon-info:before {
  content: "\e3fe";
}

.ph.ph-rows:before, i.ph-rows.icon-dead:before, i.ph-rows.icon-alive:before, i.ph-rows.icon-warn:before, i.ph-rows.icon-sign-out:before, i.ph-rows.icon-substratum:before, i.ph-rows.icon-environment:before, i.ph-rows.icon-user:before, i.ph-rows.icon-person:before, i.ph-rows.icon-terrain:before, i.ph-rows.icon-taxon:before, i.ph-rows.icon-unknown:before, i.ph-rows.icon-nothing:before, i.ph-rows.icon-sampling:before, i.ph-rows.icon-isolation:before, i.ph-rows.icon-strain:before, i.ph-rows.icon-gps:before, i.ph-rows.icon-location:before, i.ph-rows.icon-add:before, i.ph-rows.icon-left:before, i.ph-rows.icon-dna:before, i.ph-rows.icon-delete:before, i.ph-rows.icon-edit:before, i.ph-rows.icon-info:before {
  content: "\e5a2";
}

.ph.ph-rows-plus-bottom:before, i.ph-rows-plus-bottom.icon-dead:before, i.ph-rows-plus-bottom.icon-alive:before, i.ph-rows-plus-bottom.icon-warn:before, i.ph-rows-plus-bottom.icon-sign-out:before, i.ph-rows-plus-bottom.icon-substratum:before, i.ph-rows-plus-bottom.icon-environment:before, i.ph-rows-plus-bottom.icon-user:before, i.ph-rows-plus-bottom.icon-person:before, i.ph-rows-plus-bottom.icon-terrain:before, i.ph-rows-plus-bottom.icon-taxon:before, i.ph-rows-plus-bottom.icon-unknown:before, i.ph-rows-plus-bottom.icon-nothing:before, i.ph-rows-plus-bottom.icon-sampling:before, i.ph-rows-plus-bottom.icon-isolation:before, i.ph-rows-plus-bottom.icon-strain:before, i.ph-rows-plus-bottom.icon-gps:before, i.ph-rows-plus-bottom.icon-location:before, i.ph-rows-plus-bottom.icon-add:before, i.ph-rows-plus-bottom.icon-left:before, i.ph-rows-plus-bottom.icon-dna:before, i.ph-rows-plus-bottom.icon-delete:before, i.ph-rows-plus-bottom.icon-edit:before, i.ph-rows-plus-bottom.icon-info:before {
  content: "\e59e";
}

.ph.ph-rows-plus-top:before, i.ph-rows-plus-top.icon-dead:before, i.ph-rows-plus-top.icon-alive:before, i.ph-rows-plus-top.icon-warn:before, i.ph-rows-plus-top.icon-sign-out:before, i.ph-rows-plus-top.icon-substratum:before, i.ph-rows-plus-top.icon-environment:before, i.ph-rows-plus-top.icon-user:before, i.ph-rows-plus-top.icon-person:before, i.ph-rows-plus-top.icon-terrain:before, i.ph-rows-plus-top.icon-taxon:before, i.ph-rows-plus-top.icon-unknown:before, i.ph-rows-plus-top.icon-nothing:before, i.ph-rows-plus-top.icon-sampling:before, i.ph-rows-plus-top.icon-isolation:before, i.ph-rows-plus-top.icon-strain:before, i.ph-rows-plus-top.icon-gps:before, i.ph-rows-plus-top.icon-location:before, i.ph-rows-plus-top.icon-add:before, i.ph-rows-plus-top.icon-left:before, i.ph-rows-plus-top.icon-dna:before, i.ph-rows-plus-top.icon-delete:before, i.ph-rows-plus-top.icon-edit:before, i.ph-rows-plus-top.icon-info:before {
  content: "\e5a0";
}

.ph.ph-rss:before, i.ph-rss.icon-dead:before, i.ph-rss.icon-alive:before, i.ph-rss.icon-warn:before, i.ph-rss.icon-sign-out:before, i.ph-rss.icon-substratum:before, i.ph-rss.icon-environment:before, i.ph-rss.icon-user:before, i.ph-rss.icon-person:before, i.ph-rss.icon-terrain:before, i.ph-rss.icon-taxon:before, i.ph-rss.icon-unknown:before, i.ph-rss.icon-nothing:before, i.ph-rss.icon-sampling:before, i.ph-rss.icon-isolation:before, i.ph-rss.icon-strain:before, i.ph-rss.icon-gps:before, i.ph-rss.icon-location:before, i.ph-rss.icon-add:before, i.ph-rss.icon-left:before, i.ph-rss.icon-dna:before, i.ph-rss.icon-delete:before, i.ph-rss.icon-edit:before, i.ph-rss.icon-info:before {
  content: "\e400";
}

.ph.ph-rss-simple:before, i.ph-rss-simple.icon-dead:before, i.ph-rss-simple.icon-alive:before, i.ph-rss-simple.icon-warn:before, i.ph-rss-simple.icon-sign-out:before, i.ph-rss-simple.icon-substratum:before, i.ph-rss-simple.icon-environment:before, i.ph-rss-simple.icon-user:before, i.ph-rss-simple.icon-person:before, i.ph-rss-simple.icon-terrain:before, i.ph-rss-simple.icon-taxon:before, i.ph-rss-simple.icon-unknown:before, i.ph-rss-simple.icon-nothing:before, i.ph-rss-simple.icon-sampling:before, i.ph-rss-simple.icon-isolation:before, i.ph-rss-simple.icon-strain:before, i.ph-rss-simple.icon-gps:before, i.ph-rss-simple.icon-location:before, i.ph-rss-simple.icon-add:before, i.ph-rss-simple.icon-left:before, i.ph-rss-simple.icon-dna:before, i.ph-rss-simple.icon-delete:before, i.ph-rss-simple.icon-edit:before, i.ph-rss-simple.icon-info:before {
  content: "\e402";
}

.ph.ph-rug:before, i.ph-rug.icon-dead:before, i.ph-rug.icon-alive:before, i.ph-rug.icon-warn:before, i.ph-rug.icon-sign-out:before, i.ph-rug.icon-substratum:before, i.ph-rug.icon-environment:before, i.ph-rug.icon-user:before, i.ph-rug.icon-person:before, i.ph-rug.icon-terrain:before, i.ph-rug.icon-taxon:before, i.ph-rug.icon-unknown:before, i.ph-rug.icon-nothing:before, i.ph-rug.icon-sampling:before, i.ph-rug.icon-isolation:before, i.ph-rug.icon-strain:before, i.ph-rug.icon-gps:before, i.ph-rug.icon-location:before, i.ph-rug.icon-add:before, i.ph-rug.icon-left:before, i.ph-rug.icon-dna:before, i.ph-rug.icon-delete:before, i.ph-rug.icon-edit:before, i.ph-rug.icon-info:before {
  content: "\ea1a";
}

.ph.ph-ruler:before, i.ph-ruler.icon-dead:before, i.ph-ruler.icon-alive:before, i.ph-ruler.icon-warn:before, i.ph-ruler.icon-sign-out:before, i.ph-ruler.icon-substratum:before, i.ph-ruler.icon-environment:before, i.ph-ruler.icon-user:before, i.ph-ruler.icon-person:before, i.ph-ruler.icon-terrain:before, i.ph-ruler.icon-taxon:before, i.ph-ruler.icon-unknown:before, i.ph-ruler.icon-nothing:before, i.ph-ruler.icon-sampling:before, i.ph-ruler.icon-isolation:before, i.ph-ruler.icon-strain:before, i.ph-ruler.icon-gps:before, i.ph-ruler.icon-location:before, i.ph-ruler.icon-add:before, i.ph-ruler.icon-left:before, i.ph-ruler.icon-dna:before, i.ph-ruler.icon-delete:before, i.ph-ruler.icon-edit:before, i.ph-ruler.icon-info:before {
  content: "\e6b8";
}

.ph.ph-sailboat:before, i.ph-sailboat.icon-dead:before, i.ph-sailboat.icon-alive:before, i.ph-sailboat.icon-warn:before, i.ph-sailboat.icon-sign-out:before, i.ph-sailboat.icon-substratum:before, i.ph-sailboat.icon-environment:before, i.ph-sailboat.icon-user:before, i.ph-sailboat.icon-person:before, i.ph-sailboat.icon-terrain:before, i.ph-sailboat.icon-taxon:before, i.ph-sailboat.icon-unknown:before, i.ph-sailboat.icon-nothing:before, i.ph-sailboat.icon-sampling:before, i.ph-sailboat.icon-isolation:before, i.ph-sailboat.icon-strain:before, i.ph-sailboat.icon-gps:before, i.ph-sailboat.icon-location:before, i.ph-sailboat.icon-add:before, i.ph-sailboat.icon-left:before, i.ph-sailboat.icon-dna:before, i.ph-sailboat.icon-delete:before, i.ph-sailboat.icon-edit:before, i.ph-sailboat.icon-info:before {
  content: "\e78a";
}

.ph.ph-scales:before, i.ph-scales.icon-dead:before, i.ph-scales.icon-alive:before, i.ph-scales.icon-warn:before, i.ph-scales.icon-sign-out:before, i.ph-scales.icon-substratum:before, i.ph-scales.icon-environment:before, i.ph-scales.icon-user:before, i.ph-scales.icon-person:before, i.ph-scales.icon-terrain:before, i.ph-scales.icon-taxon:before, i.ph-scales.icon-unknown:before, i.ph-scales.icon-nothing:before, i.ph-scales.icon-sampling:before, i.ph-scales.icon-isolation:before, i.ph-scales.icon-strain:before, i.ph-scales.icon-gps:before, i.ph-scales.icon-location:before, i.ph-scales.icon-add:before, i.ph-scales.icon-left:before, i.ph-scales.icon-dna:before, i.ph-scales.icon-delete:before, i.ph-scales.icon-edit:before, i.ph-scales.icon-info:before {
  content: "\e750";
}

.ph.ph-scan:before, i.ph-scan.icon-dead:before, i.ph-scan.icon-alive:before, i.ph-scan.icon-warn:before, i.ph-scan.icon-sign-out:before, i.ph-scan.icon-substratum:before, i.ph-scan.icon-environment:before, i.ph-scan.icon-user:before, i.ph-scan.icon-person:before, i.ph-scan.icon-terrain:before, i.ph-scan.icon-taxon:before, i.ph-scan.icon-unknown:before, i.ph-scan.icon-nothing:before, i.ph-scan.icon-sampling:before, i.ph-scan.icon-isolation:before, i.ph-scan.icon-strain:before, i.ph-scan.icon-gps:before, i.ph-scan.icon-location:before, i.ph-scan.icon-add:before, i.ph-scan.icon-left:before, i.ph-scan.icon-dna:before, i.ph-scan.icon-delete:before, i.ph-scan.icon-edit:before, i.ph-scan.icon-info:before {
  content: "\ebb6";
}

.ph.ph-scan-smiley:before, i.ph-scan-smiley.icon-dead:before, i.ph-scan-smiley.icon-alive:before, i.ph-scan-smiley.icon-warn:before, i.ph-scan-smiley.icon-sign-out:before, i.ph-scan-smiley.icon-substratum:before, i.ph-scan-smiley.icon-environment:before, i.ph-scan-smiley.icon-user:before, i.ph-scan-smiley.icon-person:before, i.ph-scan-smiley.icon-terrain:before, i.ph-scan-smiley.icon-taxon:before, i.ph-scan-smiley.icon-unknown:before, i.ph-scan-smiley.icon-nothing:before, i.ph-scan-smiley.icon-sampling:before, i.ph-scan-smiley.icon-isolation:before, i.ph-scan-smiley.icon-strain:before, i.ph-scan-smiley.icon-gps:before, i.ph-scan-smiley.icon-location:before, i.ph-scan-smiley.icon-add:before, i.ph-scan-smiley.icon-left:before, i.ph-scan-smiley.icon-dna:before, i.ph-scan-smiley.icon-delete:before, i.ph-scan-smiley.icon-edit:before, i.ph-scan-smiley.icon-info:before {
  content: "\ebb4";
}

.ph.ph-scissors:before, i.ph-scissors.icon-dead:before, i.ph-scissors.icon-alive:before, i.ph-scissors.icon-warn:before, i.ph-scissors.icon-sign-out:before, i.ph-scissors.icon-substratum:before, i.ph-scissors.icon-environment:before, i.ph-scissors.icon-user:before, i.ph-scissors.icon-person:before, i.ph-scissors.icon-terrain:before, i.ph-scissors.icon-taxon:before, i.ph-scissors.icon-unknown:before, i.ph-scissors.icon-nothing:before, i.ph-scissors.icon-sampling:before, i.ph-scissors.icon-isolation:before, i.ph-scissors.icon-strain:before, i.ph-scissors.icon-gps:before, i.ph-scissors.icon-location:before, i.ph-scissors.icon-add:before, i.ph-scissors.icon-left:before, i.ph-scissors.icon-dna:before, i.ph-scissors.icon-delete:before, i.ph-scissors.icon-edit:before, i.ph-scissors.icon-info:before {
  content: "\eae0";
}

.ph.ph-scooter:before, i.ph-scooter.icon-dead:before, i.ph-scooter.icon-alive:before, i.ph-scooter.icon-warn:before, i.ph-scooter.icon-sign-out:before, i.ph-scooter.icon-substratum:before, i.ph-scooter.icon-environment:before, i.ph-scooter.icon-user:before, i.ph-scooter.icon-person:before, i.ph-scooter.icon-terrain:before, i.ph-scooter.icon-taxon:before, i.ph-scooter.icon-unknown:before, i.ph-scooter.icon-nothing:before, i.ph-scooter.icon-sampling:before, i.ph-scooter.icon-isolation:before, i.ph-scooter.icon-strain:before, i.ph-scooter.icon-gps:before, i.ph-scooter.icon-location:before, i.ph-scooter.icon-add:before, i.ph-scooter.icon-left:before, i.ph-scooter.icon-dna:before, i.ph-scooter.icon-delete:before, i.ph-scooter.icon-edit:before, i.ph-scooter.icon-info:before {
  content: "\e820";
}

.ph.ph-screencast:before, i.ph-screencast.icon-dead:before, i.ph-screencast.icon-alive:before, i.ph-screencast.icon-warn:before, i.ph-screencast.icon-sign-out:before, i.ph-screencast.icon-substratum:before, i.ph-screencast.icon-environment:before, i.ph-screencast.icon-user:before, i.ph-screencast.icon-person:before, i.ph-screencast.icon-terrain:before, i.ph-screencast.icon-taxon:before, i.ph-screencast.icon-unknown:before, i.ph-screencast.icon-nothing:before, i.ph-screencast.icon-sampling:before, i.ph-screencast.icon-isolation:before, i.ph-screencast.icon-strain:before, i.ph-screencast.icon-gps:before, i.ph-screencast.icon-location:before, i.ph-screencast.icon-add:before, i.ph-screencast.icon-left:before, i.ph-screencast.icon-dna:before, i.ph-screencast.icon-delete:before, i.ph-screencast.icon-edit:before, i.ph-screencast.icon-info:before {
  content: "\e404";
}

.ph.ph-screwdriver:before, i.ph-screwdriver.icon-dead:before, i.ph-screwdriver.icon-alive:before, i.ph-screwdriver.icon-warn:before, i.ph-screwdriver.icon-sign-out:before, i.ph-screwdriver.icon-substratum:before, i.ph-screwdriver.icon-environment:before, i.ph-screwdriver.icon-user:before, i.ph-screwdriver.icon-person:before, i.ph-screwdriver.icon-terrain:before, i.ph-screwdriver.icon-taxon:before, i.ph-screwdriver.icon-unknown:before, i.ph-screwdriver.icon-nothing:before, i.ph-screwdriver.icon-sampling:before, i.ph-screwdriver.icon-isolation:before, i.ph-screwdriver.icon-strain:before, i.ph-screwdriver.icon-gps:before, i.ph-screwdriver.icon-location:before, i.ph-screwdriver.icon-add:before, i.ph-screwdriver.icon-left:before, i.ph-screwdriver.icon-dna:before, i.ph-screwdriver.icon-delete:before, i.ph-screwdriver.icon-edit:before, i.ph-screwdriver.icon-info:before {
  content: "\e86e";
}

.ph.ph-scribble:before, i.ph-scribble.icon-dead:before, i.ph-scribble.icon-alive:before, i.ph-scribble.icon-warn:before, i.ph-scribble.icon-sign-out:before, i.ph-scribble.icon-substratum:before, i.ph-scribble.icon-environment:before, i.ph-scribble.icon-user:before, i.ph-scribble.icon-person:before, i.ph-scribble.icon-terrain:before, i.ph-scribble.icon-taxon:before, i.ph-scribble.icon-unknown:before, i.ph-scribble.icon-nothing:before, i.ph-scribble.icon-sampling:before, i.ph-scribble.icon-isolation:before, i.ph-scribble.icon-strain:before, i.ph-scribble.icon-gps:before, i.ph-scribble.icon-location:before, i.ph-scribble.icon-add:before, i.ph-scribble.icon-left:before, i.ph-scribble.icon-dna:before, i.ph-scribble.icon-delete:before, i.ph-scribble.icon-edit:before, i.ph-scribble.icon-info:before {
  content: "\e806";
}

.ph.ph-scribble-loop:before, i.ph-scribble-loop.icon-dead:before, i.ph-scribble-loop.icon-alive:before, i.ph-scribble-loop.icon-warn:before, i.ph-scribble-loop.icon-sign-out:before, i.ph-scribble-loop.icon-substratum:before, i.ph-scribble-loop.icon-environment:before, i.ph-scribble-loop.icon-user:before, i.ph-scribble-loop.icon-person:before, i.ph-scribble-loop.icon-terrain:before, i.ph-scribble-loop.icon-taxon:before, i.ph-scribble-loop.icon-unknown:before, i.ph-scribble-loop.icon-nothing:before, i.ph-scribble-loop.icon-sampling:before, i.ph-scribble-loop.icon-isolation:before, i.ph-scribble-loop.icon-strain:before, i.ph-scribble-loop.icon-gps:before, i.ph-scribble-loop.icon-location:before, i.ph-scribble-loop.icon-add:before, i.ph-scribble-loop.icon-left:before, i.ph-scribble-loop.icon-dna:before, i.ph-scribble-loop.icon-delete:before, i.ph-scribble-loop.icon-edit:before, i.ph-scribble-loop.icon-info:before {
  content: "\e662";
}

.ph.ph-scroll:before, i.ph-scroll.icon-dead:before, i.ph-scroll.icon-alive:before, i.ph-scroll.icon-warn:before, i.ph-scroll.icon-sign-out:before, i.ph-scroll.icon-substratum:before, i.ph-scroll.icon-environment:before, i.ph-scroll.icon-user:before, i.ph-scroll.icon-person:before, i.ph-scroll.icon-terrain:before, i.ph-scroll.icon-taxon:before, i.ph-scroll.icon-unknown:before, i.ph-scroll.icon-nothing:before, i.ph-scroll.icon-sampling:before, i.ph-scroll.icon-isolation:before, i.ph-scroll.icon-strain:before, i.ph-scroll.icon-gps:before, i.ph-scroll.icon-location:before, i.ph-scroll.icon-add:before, i.ph-scroll.icon-left:before, i.ph-scroll.icon-dna:before, i.ph-scroll.icon-delete:before, i.ph-scroll.icon-edit:before, i.ph-scroll.icon-info:before {
  content: "\eb7a";
}

.ph.ph-seal:before, i.ph-seal.icon-dead:before, i.ph-seal.icon-alive:before, i.ph-seal.icon-warn:before, i.ph-seal.icon-sign-out:before, i.ph-seal.icon-substratum:before, i.ph-seal.icon-environment:before, i.ph-seal.icon-user:before, i.ph-seal.icon-person:before, i.ph-seal.icon-terrain:before, i.ph-seal.icon-taxon:before, i.ph-seal.icon-unknown:before, i.ph-seal.icon-nothing:before, i.ph-seal.icon-sampling:before, i.ph-seal.icon-isolation:before, i.ph-seal.icon-strain:before, i.ph-seal.icon-gps:before, i.ph-seal.icon-location:before, i.ph-seal.icon-add:before, i.ph-seal.icon-left:before, i.ph-seal.icon-dna:before, i.ph-seal.icon-delete:before, i.ph-seal.icon-edit:before, i.ph-seal.icon-info:before {
  content: "\e604";
}

.ph.ph-circle-wavy:before, i.ph-circle-wavy.icon-dead:before, i.ph-circle-wavy.icon-alive:before, i.ph-circle-wavy.icon-warn:before, i.ph-circle-wavy.icon-sign-out:before, i.ph-circle-wavy.icon-substratum:before, i.ph-circle-wavy.icon-environment:before, i.ph-circle-wavy.icon-user:before, i.ph-circle-wavy.icon-person:before, i.ph-circle-wavy.icon-terrain:before, i.ph-circle-wavy.icon-taxon:before, i.ph-circle-wavy.icon-unknown:before, i.ph-circle-wavy.icon-nothing:before, i.ph-circle-wavy.icon-sampling:before, i.ph-circle-wavy.icon-isolation:before, i.ph-circle-wavy.icon-strain:before, i.ph-circle-wavy.icon-gps:before, i.ph-circle-wavy.icon-location:before, i.ph-circle-wavy.icon-add:before, i.ph-circle-wavy.icon-left:before, i.ph-circle-wavy.icon-dna:before, i.ph-circle-wavy.icon-delete:before, i.ph-circle-wavy.icon-edit:before, i.ph-circle-wavy.icon-info:before {
  content: "\e604";
}

.ph.ph-seal-check:before, i.ph-seal-check.icon-dead:before, i.ph-seal-check.icon-alive:before, i.ph-seal-check.icon-warn:before, i.ph-seal-check.icon-sign-out:before, i.ph-seal-check.icon-substratum:before, i.ph-seal-check.icon-environment:before, i.ph-seal-check.icon-user:before, i.ph-seal-check.icon-person:before, i.ph-seal-check.icon-terrain:before, i.ph-seal-check.icon-taxon:before, i.ph-seal-check.icon-unknown:before, i.ph-seal-check.icon-nothing:before, i.ph-seal-check.icon-sampling:before, i.ph-seal-check.icon-isolation:before, i.ph-seal-check.icon-strain:before, i.ph-seal-check.icon-gps:before, i.ph-seal-check.icon-location:before, i.ph-seal-check.icon-add:before, i.ph-seal-check.icon-left:before, i.ph-seal-check.icon-dna:before, i.ph-seal-check.icon-delete:before, i.ph-seal-check.icon-edit:before, i.ph-seal-check.icon-info:before {
  content: "\e606";
}

.ph.ph-circle-wavy-check:before, i.ph-circle-wavy-check.icon-dead:before, i.ph-circle-wavy-check.icon-alive:before, i.ph-circle-wavy-check.icon-warn:before, i.ph-circle-wavy-check.icon-sign-out:before, i.ph-circle-wavy-check.icon-substratum:before, i.ph-circle-wavy-check.icon-environment:before, i.ph-circle-wavy-check.icon-user:before, i.ph-circle-wavy-check.icon-person:before, i.ph-circle-wavy-check.icon-terrain:before, i.ph-circle-wavy-check.icon-taxon:before, i.ph-circle-wavy-check.icon-unknown:before, i.ph-circle-wavy-check.icon-nothing:before, i.ph-circle-wavy-check.icon-sampling:before, i.ph-circle-wavy-check.icon-isolation:before, i.ph-circle-wavy-check.icon-strain:before, i.ph-circle-wavy-check.icon-gps:before, i.ph-circle-wavy-check.icon-location:before, i.ph-circle-wavy-check.icon-add:before, i.ph-circle-wavy-check.icon-left:before, i.ph-circle-wavy-check.icon-dna:before, i.ph-circle-wavy-check.icon-delete:before, i.ph-circle-wavy-check.icon-edit:before, i.ph-circle-wavy-check.icon-info:before {
  content: "\e606";
}

.ph.ph-seal-percent:before, i.ph-seal-percent.icon-dead:before, i.ph-seal-percent.icon-alive:before, i.ph-seal-percent.icon-warn:before, i.ph-seal-percent.icon-sign-out:before, i.ph-seal-percent.icon-substratum:before, i.ph-seal-percent.icon-environment:before, i.ph-seal-percent.icon-user:before, i.ph-seal-percent.icon-person:before, i.ph-seal-percent.icon-terrain:before, i.ph-seal-percent.icon-taxon:before, i.ph-seal-percent.icon-unknown:before, i.ph-seal-percent.icon-nothing:before, i.ph-seal-percent.icon-sampling:before, i.ph-seal-percent.icon-isolation:before, i.ph-seal-percent.icon-strain:before, i.ph-seal-percent.icon-gps:before, i.ph-seal-percent.icon-location:before, i.ph-seal-percent.icon-add:before, i.ph-seal-percent.icon-left:before, i.ph-seal-percent.icon-dna:before, i.ph-seal-percent.icon-delete:before, i.ph-seal-percent.icon-edit:before, i.ph-seal-percent.icon-info:before {
  content: "\e60a";
}

.ph.ph-seal-question:before, i.ph-seal-question.icon-dead:before, i.ph-seal-question.icon-alive:before, i.ph-seal-question.icon-warn:before, i.ph-seal-question.icon-sign-out:before, i.ph-seal-question.icon-substratum:before, i.ph-seal-question.icon-environment:before, i.ph-seal-question.icon-user:before, i.ph-seal-question.icon-person:before, i.ph-seal-question.icon-terrain:before, i.ph-seal-question.icon-taxon:before, i.ph-seal-question.icon-unknown:before, i.ph-seal-question.icon-nothing:before, i.ph-seal-question.icon-sampling:before, i.ph-seal-question.icon-isolation:before, i.ph-seal-question.icon-strain:before, i.ph-seal-question.icon-gps:before, i.ph-seal-question.icon-location:before, i.ph-seal-question.icon-add:before, i.ph-seal-question.icon-left:before, i.ph-seal-question.icon-dna:before, i.ph-seal-question.icon-delete:before, i.ph-seal-question.icon-edit:before, i.ph-seal-question.icon-info:before {
  content: "\e608";
}

.ph.ph-circle-wavy-question:before, i.ph-circle-wavy-question.icon-dead:before, i.ph-circle-wavy-question.icon-alive:before, i.ph-circle-wavy-question.icon-warn:before, i.ph-circle-wavy-question.icon-sign-out:before, i.ph-circle-wavy-question.icon-substratum:before, i.ph-circle-wavy-question.icon-environment:before, i.ph-circle-wavy-question.icon-user:before, i.ph-circle-wavy-question.icon-person:before, i.ph-circle-wavy-question.icon-terrain:before, i.ph-circle-wavy-question.icon-taxon:before, i.ph-circle-wavy-question.icon-unknown:before, i.ph-circle-wavy-question.icon-nothing:before, i.ph-circle-wavy-question.icon-sampling:before, i.ph-circle-wavy-question.icon-isolation:before, i.ph-circle-wavy-question.icon-strain:before, i.ph-circle-wavy-question.icon-gps:before, i.ph-circle-wavy-question.icon-location:before, i.ph-circle-wavy-question.icon-add:before, i.ph-circle-wavy-question.icon-left:before, i.ph-circle-wavy-question.icon-dna:before, i.ph-circle-wavy-question.icon-delete:before, i.ph-circle-wavy-question.icon-edit:before, i.ph-circle-wavy-question.icon-info:before {
  content: "\e608";
}

.ph.ph-seal-warning:before, i.ph-seal-warning.icon-dead:before, i.ph-seal-warning.icon-alive:before, i.ph-seal-warning.icon-warn:before, i.ph-seal-warning.icon-sign-out:before, i.ph-seal-warning.icon-substratum:before, i.ph-seal-warning.icon-environment:before, i.ph-seal-warning.icon-user:before, i.ph-seal-warning.icon-person:before, i.ph-seal-warning.icon-terrain:before, i.ph-seal-warning.icon-taxon:before, i.ph-seal-warning.icon-unknown:before, i.ph-seal-warning.icon-nothing:before, i.ph-seal-warning.icon-sampling:before, i.ph-seal-warning.icon-isolation:before, i.ph-seal-warning.icon-strain:before, i.ph-seal-warning.icon-gps:before, i.ph-seal-warning.icon-location:before, i.ph-seal-warning.icon-add:before, i.ph-seal-warning.icon-left:before, i.ph-seal-warning.icon-dna:before, i.ph-seal-warning.icon-delete:before, i.ph-seal-warning.icon-edit:before, i.ph-seal-warning.icon-info:before {
  content: "\e60c";
}

.ph.ph-circle-wavy-warning:before, i.ph-circle-wavy-warning.icon-dead:before, i.ph-circle-wavy-warning.icon-alive:before, i.ph-circle-wavy-warning.icon-warn:before, i.ph-circle-wavy-warning.icon-sign-out:before, i.ph-circle-wavy-warning.icon-substratum:before, i.ph-circle-wavy-warning.icon-environment:before, i.ph-circle-wavy-warning.icon-user:before, i.ph-circle-wavy-warning.icon-person:before, i.ph-circle-wavy-warning.icon-terrain:before, i.ph-circle-wavy-warning.icon-taxon:before, i.ph-circle-wavy-warning.icon-unknown:before, i.ph-circle-wavy-warning.icon-nothing:before, i.ph-circle-wavy-warning.icon-sampling:before, i.ph-circle-wavy-warning.icon-isolation:before, i.ph-circle-wavy-warning.icon-strain:before, i.ph-circle-wavy-warning.icon-gps:before, i.ph-circle-wavy-warning.icon-location:before, i.ph-circle-wavy-warning.icon-add:before, i.ph-circle-wavy-warning.icon-left:before, i.ph-circle-wavy-warning.icon-dna:before, i.ph-circle-wavy-warning.icon-delete:before, i.ph-circle-wavy-warning.icon-edit:before, i.ph-circle-wavy-warning.icon-info:before {
  content: "\e60c";
}

.ph.ph-seat:before, i.ph-seat.icon-dead:before, i.ph-seat.icon-alive:before, i.ph-seat.icon-warn:before, i.ph-seat.icon-sign-out:before, i.ph-seat.icon-substratum:before, i.ph-seat.icon-environment:before, i.ph-seat.icon-user:before, i.ph-seat.icon-person:before, i.ph-seat.icon-terrain:before, i.ph-seat.icon-taxon:before, i.ph-seat.icon-unknown:before, i.ph-seat.icon-nothing:before, i.ph-seat.icon-sampling:before, i.ph-seat.icon-isolation:before, i.ph-seat.icon-strain:before, i.ph-seat.icon-gps:before, i.ph-seat.icon-location:before, i.ph-seat.icon-add:before, i.ph-seat.icon-left:before, i.ph-seat.icon-dna:before, i.ph-seat.icon-delete:before, i.ph-seat.icon-edit:before, i.ph-seat.icon-info:before {
  content: "\eb8e";
}

.ph.ph-seatbelt:before, i.ph-seatbelt.icon-dead:before, i.ph-seatbelt.icon-alive:before, i.ph-seatbelt.icon-warn:before, i.ph-seatbelt.icon-sign-out:before, i.ph-seatbelt.icon-substratum:before, i.ph-seatbelt.icon-environment:before, i.ph-seatbelt.icon-user:before, i.ph-seatbelt.icon-person:before, i.ph-seatbelt.icon-terrain:before, i.ph-seatbelt.icon-taxon:before, i.ph-seatbelt.icon-unknown:before, i.ph-seatbelt.icon-nothing:before, i.ph-seatbelt.icon-sampling:before, i.ph-seatbelt.icon-isolation:before, i.ph-seatbelt.icon-strain:before, i.ph-seatbelt.icon-gps:before, i.ph-seatbelt.icon-location:before, i.ph-seatbelt.icon-add:before, i.ph-seatbelt.icon-left:before, i.ph-seatbelt.icon-dna:before, i.ph-seatbelt.icon-delete:before, i.ph-seatbelt.icon-edit:before, i.ph-seatbelt.icon-info:before {
  content: "\edfe";
}

.ph.ph-security-camera:before, i.ph-security-camera.icon-dead:before, i.ph-security-camera.icon-alive:before, i.ph-security-camera.icon-warn:before, i.ph-security-camera.icon-sign-out:before, i.ph-security-camera.icon-substratum:before, i.ph-security-camera.icon-environment:before, i.ph-security-camera.icon-user:before, i.ph-security-camera.icon-person:before, i.ph-security-camera.icon-terrain:before, i.ph-security-camera.icon-taxon:before, i.ph-security-camera.icon-unknown:before, i.ph-security-camera.icon-nothing:before, i.ph-security-camera.icon-sampling:before, i.ph-security-camera.icon-isolation:before, i.ph-security-camera.icon-strain:before, i.ph-security-camera.icon-gps:before, i.ph-security-camera.icon-location:before, i.ph-security-camera.icon-add:before, i.ph-security-camera.icon-left:before, i.ph-security-camera.icon-dna:before, i.ph-security-camera.icon-delete:before, i.ph-security-camera.icon-edit:before, i.ph-security-camera.icon-info:before {
  content: "\eca4";
}

.ph.ph-selection:before, i.ph-selection.icon-dead:before, i.ph-selection.icon-alive:before, i.ph-selection.icon-warn:before, i.ph-selection.icon-sign-out:before, i.ph-selection.icon-substratum:before, i.ph-selection.icon-environment:before, i.ph-selection.icon-user:before, i.ph-selection.icon-person:before, i.ph-selection.icon-terrain:before, i.ph-selection.icon-taxon:before, i.ph-selection.icon-unknown:before, i.ph-selection.icon-nothing:before, i.ph-selection.icon-sampling:before, i.ph-selection.icon-isolation:before, i.ph-selection.icon-strain:before, i.ph-selection.icon-gps:before, i.ph-selection.icon-location:before, i.ph-selection.icon-add:before, i.ph-selection.icon-left:before, i.ph-selection.icon-dna:before, i.ph-selection.icon-delete:before, i.ph-selection.icon-edit:before, i.ph-selection.icon-info:before {
  content: "\e69a";
}

.ph.ph-selection-all:before, i.ph-selection-all.icon-dead:before, i.ph-selection-all.icon-alive:before, i.ph-selection-all.icon-warn:before, i.ph-selection-all.icon-sign-out:before, i.ph-selection-all.icon-substratum:before, i.ph-selection-all.icon-environment:before, i.ph-selection-all.icon-user:before, i.ph-selection-all.icon-person:before, i.ph-selection-all.icon-terrain:before, i.ph-selection-all.icon-taxon:before, i.ph-selection-all.icon-unknown:before, i.ph-selection-all.icon-nothing:before, i.ph-selection-all.icon-sampling:before, i.ph-selection-all.icon-isolation:before, i.ph-selection-all.icon-strain:before, i.ph-selection-all.icon-gps:before, i.ph-selection-all.icon-location:before, i.ph-selection-all.icon-add:before, i.ph-selection-all.icon-left:before, i.ph-selection-all.icon-dna:before, i.ph-selection-all.icon-delete:before, i.ph-selection-all.icon-edit:before, i.ph-selection-all.icon-info:before {
  content: "\e746";
}

.ph.ph-selection-background:before, i.ph-selection-background.icon-dead:before, i.ph-selection-background.icon-alive:before, i.ph-selection-background.icon-warn:before, i.ph-selection-background.icon-sign-out:before, i.ph-selection-background.icon-substratum:before, i.ph-selection-background.icon-environment:before, i.ph-selection-background.icon-user:before, i.ph-selection-background.icon-person:before, i.ph-selection-background.icon-terrain:before, i.ph-selection-background.icon-taxon:before, i.ph-selection-background.icon-unknown:before, i.ph-selection-background.icon-nothing:before, i.ph-selection-background.icon-sampling:before, i.ph-selection-background.icon-isolation:before, i.ph-selection-background.icon-strain:before, i.ph-selection-background.icon-gps:before, i.ph-selection-background.icon-location:before, i.ph-selection-background.icon-add:before, i.ph-selection-background.icon-left:before, i.ph-selection-background.icon-dna:before, i.ph-selection-background.icon-delete:before, i.ph-selection-background.icon-edit:before, i.ph-selection-background.icon-info:before {
  content: "\eaf8";
}

.ph.ph-selection-foreground:before, i.ph-selection-foreground.icon-dead:before, i.ph-selection-foreground.icon-alive:before, i.ph-selection-foreground.icon-warn:before, i.ph-selection-foreground.icon-sign-out:before, i.ph-selection-foreground.icon-substratum:before, i.ph-selection-foreground.icon-environment:before, i.ph-selection-foreground.icon-user:before, i.ph-selection-foreground.icon-person:before, i.ph-selection-foreground.icon-terrain:before, i.ph-selection-foreground.icon-taxon:before, i.ph-selection-foreground.icon-unknown:before, i.ph-selection-foreground.icon-nothing:before, i.ph-selection-foreground.icon-sampling:before, i.ph-selection-foreground.icon-isolation:before, i.ph-selection-foreground.icon-strain:before, i.ph-selection-foreground.icon-gps:before, i.ph-selection-foreground.icon-location:before, i.ph-selection-foreground.icon-add:before, i.ph-selection-foreground.icon-left:before, i.ph-selection-foreground.icon-dna:before, i.ph-selection-foreground.icon-delete:before, i.ph-selection-foreground.icon-edit:before, i.ph-selection-foreground.icon-info:before {
  content: "\eaf6";
}

.ph.ph-selection-inverse:before, i.ph-selection-inverse.icon-dead:before, i.ph-selection-inverse.icon-alive:before, i.ph-selection-inverse.icon-warn:before, i.ph-selection-inverse.icon-sign-out:before, i.ph-selection-inverse.icon-substratum:before, i.ph-selection-inverse.icon-environment:before, i.ph-selection-inverse.icon-user:before, i.ph-selection-inverse.icon-person:before, i.ph-selection-inverse.icon-terrain:before, i.ph-selection-inverse.icon-taxon:before, i.ph-selection-inverse.icon-unknown:before, i.ph-selection-inverse.icon-nothing:before, i.ph-selection-inverse.icon-sampling:before, i.ph-selection-inverse.icon-isolation:before, i.ph-selection-inverse.icon-strain:before, i.ph-selection-inverse.icon-gps:before, i.ph-selection-inverse.icon-location:before, i.ph-selection-inverse.icon-add:before, i.ph-selection-inverse.icon-left:before, i.ph-selection-inverse.icon-dna:before, i.ph-selection-inverse.icon-delete:before, i.ph-selection-inverse.icon-edit:before, i.ph-selection-inverse.icon-info:before {
  content: "\e744";
}

.ph.ph-selection-plus:before, i.ph-selection-plus.icon-dead:before, i.ph-selection-plus.icon-alive:before, i.ph-selection-plus.icon-warn:before, i.ph-selection-plus.icon-sign-out:before, i.ph-selection-plus.icon-substratum:before, i.ph-selection-plus.icon-environment:before, i.ph-selection-plus.icon-user:before, i.ph-selection-plus.icon-person:before, i.ph-selection-plus.icon-terrain:before, i.ph-selection-plus.icon-taxon:before, i.ph-selection-plus.icon-unknown:before, i.ph-selection-plus.icon-nothing:before, i.ph-selection-plus.icon-sampling:before, i.ph-selection-plus.icon-isolation:before, i.ph-selection-plus.icon-strain:before, i.ph-selection-plus.icon-gps:before, i.ph-selection-plus.icon-location:before, i.ph-selection-plus.icon-add:before, i.ph-selection-plus.icon-left:before, i.ph-selection-plus.icon-dna:before, i.ph-selection-plus.icon-delete:before, i.ph-selection-plus.icon-edit:before, i.ph-selection-plus.icon-info:before {
  content: "\e69c";
}

.ph.ph-selection-slash:before, i.ph-selection-slash.icon-dead:before, i.ph-selection-slash.icon-alive:before, i.ph-selection-slash.icon-warn:before, i.ph-selection-slash.icon-sign-out:before, i.ph-selection-slash.icon-substratum:before, i.ph-selection-slash.icon-environment:before, i.ph-selection-slash.icon-user:before, i.ph-selection-slash.icon-person:before, i.ph-selection-slash.icon-terrain:before, i.ph-selection-slash.icon-taxon:before, i.ph-selection-slash.icon-unknown:before, i.ph-selection-slash.icon-nothing:before, i.ph-selection-slash.icon-sampling:before, i.ph-selection-slash.icon-isolation:before, i.ph-selection-slash.icon-strain:before, i.ph-selection-slash.icon-gps:before, i.ph-selection-slash.icon-location:before, i.ph-selection-slash.icon-add:before, i.ph-selection-slash.icon-left:before, i.ph-selection-slash.icon-dna:before, i.ph-selection-slash.icon-delete:before, i.ph-selection-slash.icon-edit:before, i.ph-selection-slash.icon-info:before {
  content: "\e69e";
}

.ph.ph-shapes:before, i.ph-shapes.icon-dead:before, i.ph-shapes.icon-alive:before, i.ph-shapes.icon-warn:before, i.ph-shapes.icon-sign-out:before, i.ph-shapes.icon-substratum:before, i.ph-shapes.icon-environment:before, i.ph-shapes.icon-user:before, i.ph-shapes.icon-person:before, i.ph-shapes.icon-terrain:before, i.ph-shapes.icon-taxon:before, i.ph-shapes.icon-unknown:before, i.ph-shapes.icon-nothing:before, i.ph-shapes.icon-sampling:before, i.ph-shapes.icon-isolation:before, i.ph-shapes.icon-strain:before, i.ph-shapes.icon-gps:before, i.ph-shapes.icon-location:before, i.ph-shapes.icon-add:before, i.ph-shapes.icon-left:before, i.ph-shapes.icon-dna:before, i.ph-shapes.icon-delete:before, i.ph-shapes.icon-edit:before, i.ph-shapes.icon-info:before {
  content: "\ec5e";
}

.ph.ph-share:before, i.ph-share.icon-dead:before, i.ph-share.icon-alive:before, i.ph-share.icon-warn:before, i.ph-share.icon-sign-out:before, i.ph-share.icon-substratum:before, i.ph-share.icon-environment:before, i.ph-share.icon-user:before, i.ph-share.icon-person:before, i.ph-share.icon-terrain:before, i.ph-share.icon-taxon:before, i.ph-share.icon-unknown:before, i.ph-share.icon-nothing:before, i.ph-share.icon-sampling:before, i.ph-share.icon-isolation:before, i.ph-share.icon-strain:before, i.ph-share.icon-gps:before, i.ph-share.icon-location:before, i.ph-share.icon-add:before, i.ph-share.icon-left:before, i.ph-share.icon-dna:before, i.ph-share.icon-delete:before, i.ph-share.icon-edit:before, i.ph-share.icon-info:before {
  content: "\e406";
}

.ph.ph-share-fat:before, i.ph-share-fat.icon-dead:before, i.ph-share-fat.icon-alive:before, i.ph-share-fat.icon-warn:before, i.ph-share-fat.icon-sign-out:before, i.ph-share-fat.icon-substratum:before, i.ph-share-fat.icon-environment:before, i.ph-share-fat.icon-user:before, i.ph-share-fat.icon-person:before, i.ph-share-fat.icon-terrain:before, i.ph-share-fat.icon-taxon:before, i.ph-share-fat.icon-unknown:before, i.ph-share-fat.icon-nothing:before, i.ph-share-fat.icon-sampling:before, i.ph-share-fat.icon-isolation:before, i.ph-share-fat.icon-strain:before, i.ph-share-fat.icon-gps:before, i.ph-share-fat.icon-location:before, i.ph-share-fat.icon-add:before, i.ph-share-fat.icon-left:before, i.ph-share-fat.icon-dna:before, i.ph-share-fat.icon-delete:before, i.ph-share-fat.icon-edit:before, i.ph-share-fat.icon-info:before {
  content: "\ed52";
}

.ph.ph-share-network:before, i.ph-share-network.icon-dead:before, i.ph-share-network.icon-alive:before, i.ph-share-network.icon-warn:before, i.ph-share-network.icon-sign-out:before, i.ph-share-network.icon-substratum:before, i.ph-share-network.icon-environment:before, i.ph-share-network.icon-user:before, i.ph-share-network.icon-person:before, i.ph-share-network.icon-terrain:before, i.ph-share-network.icon-taxon:before, i.ph-share-network.icon-unknown:before, i.ph-share-network.icon-nothing:before, i.ph-share-network.icon-sampling:before, i.ph-share-network.icon-isolation:before, i.ph-share-network.icon-strain:before, i.ph-share-network.icon-gps:before, i.ph-share-network.icon-location:before, i.ph-share-network.icon-add:before, i.ph-share-network.icon-left:before, i.ph-share-network.icon-dna:before, i.ph-share-network.icon-delete:before, i.ph-share-network.icon-edit:before, i.ph-share-network.icon-info:before {
  content: "\e408";
}

.ph.ph-shield:before, i.ph-shield.icon-dead:before, i.ph-shield.icon-alive:before, i.ph-shield.icon-warn:before, i.ph-shield.icon-sign-out:before, i.ph-shield.icon-substratum:before, i.ph-shield.icon-environment:before, i.ph-shield.icon-user:before, i.ph-shield.icon-person:before, i.ph-shield.icon-terrain:before, i.ph-shield.icon-taxon:before, i.ph-shield.icon-unknown:before, i.ph-shield.icon-nothing:before, i.ph-shield.icon-sampling:before, i.ph-shield.icon-isolation:before, i.ph-shield.icon-strain:before, i.ph-shield.icon-gps:before, i.ph-shield.icon-location:before, i.ph-shield.icon-add:before, i.ph-shield.icon-left:before, i.ph-shield.icon-dna:before, i.ph-shield.icon-delete:before, i.ph-shield.icon-edit:before, i.ph-shield.icon-info:before {
  content: "\e40a";
}

.ph.ph-shield-check:before, i.ph-shield-check.icon-dead:before, i.ph-shield-check.icon-alive:before, i.ph-shield-check.icon-warn:before, i.ph-shield-check.icon-sign-out:before, i.ph-shield-check.icon-substratum:before, i.ph-shield-check.icon-environment:before, i.ph-shield-check.icon-user:before, i.ph-shield-check.icon-person:before, i.ph-shield-check.icon-terrain:before, i.ph-shield-check.icon-taxon:before, i.ph-shield-check.icon-unknown:before, i.ph-shield-check.icon-nothing:before, i.ph-shield-check.icon-sampling:before, i.ph-shield-check.icon-isolation:before, i.ph-shield-check.icon-strain:before, i.ph-shield-check.icon-gps:before, i.ph-shield-check.icon-location:before, i.ph-shield-check.icon-add:before, i.ph-shield-check.icon-left:before, i.ph-shield-check.icon-dna:before, i.ph-shield-check.icon-delete:before, i.ph-shield-check.icon-edit:before, i.ph-shield-check.icon-info:before {
  content: "\e40c";
}

.ph.ph-shield-checkered:before, i.ph-shield-checkered.icon-dead:before, i.ph-shield-checkered.icon-alive:before, i.ph-shield-checkered.icon-warn:before, i.ph-shield-checkered.icon-sign-out:before, i.ph-shield-checkered.icon-substratum:before, i.ph-shield-checkered.icon-environment:before, i.ph-shield-checkered.icon-user:before, i.ph-shield-checkered.icon-person:before, i.ph-shield-checkered.icon-terrain:before, i.ph-shield-checkered.icon-taxon:before, i.ph-shield-checkered.icon-unknown:before, i.ph-shield-checkered.icon-nothing:before, i.ph-shield-checkered.icon-sampling:before, i.ph-shield-checkered.icon-isolation:before, i.ph-shield-checkered.icon-strain:before, i.ph-shield-checkered.icon-gps:before, i.ph-shield-checkered.icon-location:before, i.ph-shield-checkered.icon-add:before, i.ph-shield-checkered.icon-left:before, i.ph-shield-checkered.icon-dna:before, i.ph-shield-checkered.icon-delete:before, i.ph-shield-checkered.icon-edit:before, i.ph-shield-checkered.icon-info:before {
  content: "\e708";
}

.ph.ph-shield-chevron:before, i.ph-shield-chevron.icon-dead:before, i.ph-shield-chevron.icon-alive:before, i.ph-shield-chevron.icon-warn:before, i.ph-shield-chevron.icon-sign-out:before, i.ph-shield-chevron.icon-substratum:before, i.ph-shield-chevron.icon-environment:before, i.ph-shield-chevron.icon-user:before, i.ph-shield-chevron.icon-person:before, i.ph-shield-chevron.icon-terrain:before, i.ph-shield-chevron.icon-taxon:before, i.ph-shield-chevron.icon-unknown:before, i.ph-shield-chevron.icon-nothing:before, i.ph-shield-chevron.icon-sampling:before, i.ph-shield-chevron.icon-isolation:before, i.ph-shield-chevron.icon-strain:before, i.ph-shield-chevron.icon-gps:before, i.ph-shield-chevron.icon-location:before, i.ph-shield-chevron.icon-add:before, i.ph-shield-chevron.icon-left:before, i.ph-shield-chevron.icon-dna:before, i.ph-shield-chevron.icon-delete:before, i.ph-shield-chevron.icon-edit:before, i.ph-shield-chevron.icon-info:before {
  content: "\e40e";
}

.ph.ph-shield-plus:before, i.ph-shield-plus.icon-dead:before, i.ph-shield-plus.icon-alive:before, i.ph-shield-plus.icon-warn:before, i.ph-shield-plus.icon-sign-out:before, i.ph-shield-plus.icon-substratum:before, i.ph-shield-plus.icon-environment:before, i.ph-shield-plus.icon-user:before, i.ph-shield-plus.icon-person:before, i.ph-shield-plus.icon-terrain:before, i.ph-shield-plus.icon-taxon:before, i.ph-shield-plus.icon-unknown:before, i.ph-shield-plus.icon-nothing:before, i.ph-shield-plus.icon-sampling:before, i.ph-shield-plus.icon-isolation:before, i.ph-shield-plus.icon-strain:before, i.ph-shield-plus.icon-gps:before, i.ph-shield-plus.icon-location:before, i.ph-shield-plus.icon-add:before, i.ph-shield-plus.icon-left:before, i.ph-shield-plus.icon-dna:before, i.ph-shield-plus.icon-delete:before, i.ph-shield-plus.icon-edit:before, i.ph-shield-plus.icon-info:before {
  content: "\e706";
}

.ph.ph-shield-slash:before, i.ph-shield-slash.icon-dead:before, i.ph-shield-slash.icon-alive:before, i.ph-shield-slash.icon-warn:before, i.ph-shield-slash.icon-sign-out:before, i.ph-shield-slash.icon-substratum:before, i.ph-shield-slash.icon-environment:before, i.ph-shield-slash.icon-user:before, i.ph-shield-slash.icon-person:before, i.ph-shield-slash.icon-terrain:before, i.ph-shield-slash.icon-taxon:before, i.ph-shield-slash.icon-unknown:before, i.ph-shield-slash.icon-nothing:before, i.ph-shield-slash.icon-sampling:before, i.ph-shield-slash.icon-isolation:before, i.ph-shield-slash.icon-strain:before, i.ph-shield-slash.icon-gps:before, i.ph-shield-slash.icon-location:before, i.ph-shield-slash.icon-add:before, i.ph-shield-slash.icon-left:before, i.ph-shield-slash.icon-dna:before, i.ph-shield-slash.icon-delete:before, i.ph-shield-slash.icon-edit:before, i.ph-shield-slash.icon-info:before {
  content: "\e410";
}

.ph.ph-shield-star:before, i.ph-shield-star.icon-dead:before, i.ph-shield-star.icon-alive:before, i.ph-shield-star.icon-warn:before, i.ph-shield-star.icon-sign-out:before, i.ph-shield-star.icon-substratum:before, i.ph-shield-star.icon-environment:before, i.ph-shield-star.icon-user:before, i.ph-shield-star.icon-person:before, i.ph-shield-star.icon-terrain:before, i.ph-shield-star.icon-taxon:before, i.ph-shield-star.icon-unknown:before, i.ph-shield-star.icon-nothing:before, i.ph-shield-star.icon-sampling:before, i.ph-shield-star.icon-isolation:before, i.ph-shield-star.icon-strain:before, i.ph-shield-star.icon-gps:before, i.ph-shield-star.icon-location:before, i.ph-shield-star.icon-add:before, i.ph-shield-star.icon-left:before, i.ph-shield-star.icon-dna:before, i.ph-shield-star.icon-delete:before, i.ph-shield-star.icon-edit:before, i.ph-shield-star.icon-info:before {
  content: "\ec34";
}

.ph.ph-shield-warning:before, i.ph-shield-warning.icon-dead:before, i.ph-shield-warning.icon-alive:before, i.ph-shield-warning.icon-warn:before, i.ph-shield-warning.icon-sign-out:before, i.ph-shield-warning.icon-substratum:before, i.ph-shield-warning.icon-environment:before, i.ph-shield-warning.icon-user:before, i.ph-shield-warning.icon-person:before, i.ph-shield-warning.icon-terrain:before, i.ph-shield-warning.icon-taxon:before, i.ph-shield-warning.icon-unknown:before, i.ph-shield-warning.icon-nothing:before, i.ph-shield-warning.icon-sampling:before, i.ph-shield-warning.icon-isolation:before, i.ph-shield-warning.icon-strain:before, i.ph-shield-warning.icon-gps:before, i.ph-shield-warning.icon-location:before, i.ph-shield-warning.icon-add:before, i.ph-shield-warning.icon-left:before, i.ph-shield-warning.icon-dna:before, i.ph-shield-warning.icon-delete:before, i.ph-shield-warning.icon-edit:before, i.ph-shield-warning.icon-info:before {
  content: "\e412";
}

.ph.ph-shipping-container:before, i.ph-shipping-container.icon-dead:before, i.ph-shipping-container.icon-alive:before, i.ph-shipping-container.icon-warn:before, i.ph-shipping-container.icon-sign-out:before, i.ph-shipping-container.icon-substratum:before, i.ph-shipping-container.icon-environment:before, i.ph-shipping-container.icon-user:before, i.ph-shipping-container.icon-person:before, i.ph-shipping-container.icon-terrain:before, i.ph-shipping-container.icon-taxon:before, i.ph-shipping-container.icon-unknown:before, i.ph-shipping-container.icon-nothing:before, i.ph-shipping-container.icon-sampling:before, i.ph-shipping-container.icon-isolation:before, i.ph-shipping-container.icon-strain:before, i.ph-shipping-container.icon-gps:before, i.ph-shipping-container.icon-location:before, i.ph-shipping-container.icon-add:before, i.ph-shipping-container.icon-left:before, i.ph-shipping-container.icon-dna:before, i.ph-shipping-container.icon-delete:before, i.ph-shipping-container.icon-edit:before, i.ph-shipping-container.icon-info:before {
  content: "\e78c";
}

.ph.ph-shirt-folded:before, i.ph-shirt-folded.icon-dead:before, i.ph-shirt-folded.icon-alive:before, i.ph-shirt-folded.icon-warn:before, i.ph-shirt-folded.icon-sign-out:before, i.ph-shirt-folded.icon-substratum:before, i.ph-shirt-folded.icon-environment:before, i.ph-shirt-folded.icon-user:before, i.ph-shirt-folded.icon-person:before, i.ph-shirt-folded.icon-terrain:before, i.ph-shirt-folded.icon-taxon:before, i.ph-shirt-folded.icon-unknown:before, i.ph-shirt-folded.icon-nothing:before, i.ph-shirt-folded.icon-sampling:before, i.ph-shirt-folded.icon-isolation:before, i.ph-shirt-folded.icon-strain:before, i.ph-shirt-folded.icon-gps:before, i.ph-shirt-folded.icon-location:before, i.ph-shirt-folded.icon-add:before, i.ph-shirt-folded.icon-left:before, i.ph-shirt-folded.icon-dna:before, i.ph-shirt-folded.icon-delete:before, i.ph-shirt-folded.icon-edit:before, i.ph-shirt-folded.icon-info:before {
  content: "\ea92";
}

.ph.ph-shooting-star:before, i.ph-shooting-star.icon-dead:before, i.ph-shooting-star.icon-alive:before, i.ph-shooting-star.icon-warn:before, i.ph-shooting-star.icon-sign-out:before, i.ph-shooting-star.icon-substratum:before, i.ph-shooting-star.icon-environment:before, i.ph-shooting-star.icon-user:before, i.ph-shooting-star.icon-person:before, i.ph-shooting-star.icon-terrain:before, i.ph-shooting-star.icon-taxon:before, i.ph-shooting-star.icon-unknown:before, i.ph-shooting-star.icon-nothing:before, i.ph-shooting-star.icon-sampling:before, i.ph-shooting-star.icon-isolation:before, i.ph-shooting-star.icon-strain:before, i.ph-shooting-star.icon-gps:before, i.ph-shooting-star.icon-location:before, i.ph-shooting-star.icon-add:before, i.ph-shooting-star.icon-left:before, i.ph-shooting-star.icon-dna:before, i.ph-shooting-star.icon-delete:before, i.ph-shooting-star.icon-edit:before, i.ph-shooting-star.icon-info:before {
  content: "\ecfa";
}

.ph.ph-shopping-bag:before, i.ph-shopping-bag.icon-dead:before, i.ph-shopping-bag.icon-alive:before, i.ph-shopping-bag.icon-warn:before, i.ph-shopping-bag.icon-sign-out:before, i.ph-shopping-bag.icon-substratum:before, i.ph-shopping-bag.icon-environment:before, i.ph-shopping-bag.icon-user:before, i.ph-shopping-bag.icon-person:before, i.ph-shopping-bag.icon-terrain:before, i.ph-shopping-bag.icon-taxon:before, i.ph-shopping-bag.icon-unknown:before, i.ph-shopping-bag.icon-nothing:before, i.ph-shopping-bag.icon-sampling:before, i.ph-shopping-bag.icon-isolation:before, i.ph-shopping-bag.icon-strain:before, i.ph-shopping-bag.icon-gps:before, i.ph-shopping-bag.icon-location:before, i.ph-shopping-bag.icon-add:before, i.ph-shopping-bag.icon-left:before, i.ph-shopping-bag.icon-dna:before, i.ph-shopping-bag.icon-delete:before, i.ph-shopping-bag.icon-edit:before, i.ph-shopping-bag.icon-info:before {
  content: "\e416";
}

.ph.ph-shopping-bag-open:before, i.ph-shopping-bag-open.icon-dead:before, i.ph-shopping-bag-open.icon-alive:before, i.ph-shopping-bag-open.icon-warn:before, i.ph-shopping-bag-open.icon-sign-out:before, i.ph-shopping-bag-open.icon-substratum:before, i.ph-shopping-bag-open.icon-environment:before, i.ph-shopping-bag-open.icon-user:before, i.ph-shopping-bag-open.icon-person:before, i.ph-shopping-bag-open.icon-terrain:before, i.ph-shopping-bag-open.icon-taxon:before, i.ph-shopping-bag-open.icon-unknown:before, i.ph-shopping-bag-open.icon-nothing:before, i.ph-shopping-bag-open.icon-sampling:before, i.ph-shopping-bag-open.icon-isolation:before, i.ph-shopping-bag-open.icon-strain:before, i.ph-shopping-bag-open.icon-gps:before, i.ph-shopping-bag-open.icon-location:before, i.ph-shopping-bag-open.icon-add:before, i.ph-shopping-bag-open.icon-left:before, i.ph-shopping-bag-open.icon-dna:before, i.ph-shopping-bag-open.icon-delete:before, i.ph-shopping-bag-open.icon-edit:before, i.ph-shopping-bag-open.icon-info:before {
  content: "\e418";
}

.ph.ph-shopping-cart:before, i.ph-shopping-cart.icon-dead:before, i.ph-shopping-cart.icon-alive:before, i.ph-shopping-cart.icon-warn:before, i.ph-shopping-cart.icon-sign-out:before, i.ph-shopping-cart.icon-substratum:before, i.ph-shopping-cart.icon-environment:before, i.ph-shopping-cart.icon-user:before, i.ph-shopping-cart.icon-person:before, i.ph-shopping-cart.icon-terrain:before, i.ph-shopping-cart.icon-taxon:before, i.ph-shopping-cart.icon-unknown:before, i.ph-shopping-cart.icon-nothing:before, i.ph-shopping-cart.icon-sampling:before, i.ph-shopping-cart.icon-isolation:before, i.ph-shopping-cart.icon-strain:before, i.ph-shopping-cart.icon-gps:before, i.ph-shopping-cart.icon-location:before, i.ph-shopping-cart.icon-add:before, i.ph-shopping-cart.icon-left:before, i.ph-shopping-cart.icon-dna:before, i.ph-shopping-cart.icon-delete:before, i.ph-shopping-cart.icon-edit:before, i.ph-shopping-cart.icon-info:before {
  content: "\e41e";
}

.ph.ph-shopping-cart-simple:before, i.ph-shopping-cart-simple.icon-dead:before, i.ph-shopping-cart-simple.icon-alive:before, i.ph-shopping-cart-simple.icon-warn:before, i.ph-shopping-cart-simple.icon-sign-out:before, i.ph-shopping-cart-simple.icon-substratum:before, i.ph-shopping-cart-simple.icon-environment:before, i.ph-shopping-cart-simple.icon-user:before, i.ph-shopping-cart-simple.icon-person:before, i.ph-shopping-cart-simple.icon-terrain:before, i.ph-shopping-cart-simple.icon-taxon:before, i.ph-shopping-cart-simple.icon-unknown:before, i.ph-shopping-cart-simple.icon-nothing:before, i.ph-shopping-cart-simple.icon-sampling:before, i.ph-shopping-cart-simple.icon-isolation:before, i.ph-shopping-cart-simple.icon-strain:before, i.ph-shopping-cart-simple.icon-gps:before, i.ph-shopping-cart-simple.icon-location:before, i.ph-shopping-cart-simple.icon-add:before, i.ph-shopping-cart-simple.icon-left:before, i.ph-shopping-cart-simple.icon-dna:before, i.ph-shopping-cart-simple.icon-delete:before, i.ph-shopping-cart-simple.icon-edit:before, i.ph-shopping-cart-simple.icon-info:before {
  content: "\e420";
}

.ph.ph-shovel:before, i.ph-shovel.icon-dead:before, i.ph-shovel.icon-alive:before, i.ph-shovel.icon-warn:before, i.ph-shovel.icon-sign-out:before, i.ph-shovel.icon-substratum:before, i.ph-shovel.icon-environment:before, i.ph-shovel.icon-user:before, i.ph-shovel.icon-person:before, i.ph-shovel.icon-terrain:before, i.ph-shovel.icon-taxon:before, i.ph-shovel.icon-unknown:before, i.ph-shovel.icon-nothing:before, i.ph-shovel.icon-sampling:before, i.ph-shovel.icon-isolation:before, i.ph-shovel.icon-strain:before, i.ph-shovel.icon-gps:before, i.ph-shovel.icon-location:before, i.ph-shovel.icon-add:before, i.ph-shovel.icon-left:before, i.ph-shovel.icon-dna:before, i.ph-shovel.icon-delete:before, i.ph-shovel.icon-edit:before, i.ph-shovel.icon-info:before {
  content: "\e9e6";
}

.ph.ph-shower:before, i.ph-shower.icon-dead:before, i.ph-shower.icon-alive:before, i.ph-shower.icon-warn:before, i.ph-shower.icon-sign-out:before, i.ph-shower.icon-substratum:before, i.ph-shower.icon-environment:before, i.ph-shower.icon-user:before, i.ph-shower.icon-person:before, i.ph-shower.icon-terrain:before, i.ph-shower.icon-taxon:before, i.ph-shower.icon-unknown:before, i.ph-shower.icon-nothing:before, i.ph-shower.icon-sampling:before, i.ph-shower.icon-isolation:before, i.ph-shower.icon-strain:before, i.ph-shower.icon-gps:before, i.ph-shower.icon-location:before, i.ph-shower.icon-add:before, i.ph-shower.icon-left:before, i.ph-shower.icon-dna:before, i.ph-shower.icon-delete:before, i.ph-shower.icon-edit:before, i.ph-shower.icon-info:before {
  content: "\e776";
}

.ph.ph-shrimp:before, i.ph-shrimp.icon-dead:before, i.ph-shrimp.icon-alive:before, i.ph-shrimp.icon-warn:before, i.ph-shrimp.icon-sign-out:before, i.ph-shrimp.icon-substratum:before, i.ph-shrimp.icon-environment:before, i.ph-shrimp.icon-user:before, i.ph-shrimp.icon-person:before, i.ph-shrimp.icon-terrain:before, i.ph-shrimp.icon-taxon:before, i.ph-shrimp.icon-unknown:before, i.ph-shrimp.icon-nothing:before, i.ph-shrimp.icon-sampling:before, i.ph-shrimp.icon-isolation:before, i.ph-shrimp.icon-strain:before, i.ph-shrimp.icon-gps:before, i.ph-shrimp.icon-location:before, i.ph-shrimp.icon-add:before, i.ph-shrimp.icon-left:before, i.ph-shrimp.icon-dna:before, i.ph-shrimp.icon-delete:before, i.ph-shrimp.icon-edit:before, i.ph-shrimp.icon-info:before {
  content: "\eab4";
}

.ph.ph-shuffle:before, i.ph-shuffle.icon-dead:before, i.ph-shuffle.icon-alive:before, i.ph-shuffle.icon-warn:before, i.ph-shuffle.icon-sign-out:before, i.ph-shuffle.icon-substratum:before, i.ph-shuffle.icon-environment:before, i.ph-shuffle.icon-user:before, i.ph-shuffle.icon-person:before, i.ph-shuffle.icon-terrain:before, i.ph-shuffle.icon-taxon:before, i.ph-shuffle.icon-unknown:before, i.ph-shuffle.icon-nothing:before, i.ph-shuffle.icon-sampling:before, i.ph-shuffle.icon-isolation:before, i.ph-shuffle.icon-strain:before, i.ph-shuffle.icon-gps:before, i.ph-shuffle.icon-location:before, i.ph-shuffle.icon-add:before, i.ph-shuffle.icon-left:before, i.ph-shuffle.icon-dna:before, i.ph-shuffle.icon-delete:before, i.ph-shuffle.icon-edit:before, i.ph-shuffle.icon-info:before {
  content: "\e422";
}

.ph.ph-shuffle-angular:before, i.ph-shuffle-angular.icon-dead:before, i.ph-shuffle-angular.icon-alive:before, i.ph-shuffle-angular.icon-warn:before, i.ph-shuffle-angular.icon-sign-out:before, i.ph-shuffle-angular.icon-substratum:before, i.ph-shuffle-angular.icon-environment:before, i.ph-shuffle-angular.icon-user:before, i.ph-shuffle-angular.icon-person:before, i.ph-shuffle-angular.icon-terrain:before, i.ph-shuffle-angular.icon-taxon:before, i.ph-shuffle-angular.icon-unknown:before, i.ph-shuffle-angular.icon-nothing:before, i.ph-shuffle-angular.icon-sampling:before, i.ph-shuffle-angular.icon-isolation:before, i.ph-shuffle-angular.icon-strain:before, i.ph-shuffle-angular.icon-gps:before, i.ph-shuffle-angular.icon-location:before, i.ph-shuffle-angular.icon-add:before, i.ph-shuffle-angular.icon-left:before, i.ph-shuffle-angular.icon-dna:before, i.ph-shuffle-angular.icon-delete:before, i.ph-shuffle-angular.icon-edit:before, i.ph-shuffle-angular.icon-info:before {
  content: "\e424";
}

.ph.ph-shuffle-simple:before, i.ph-shuffle-simple.icon-dead:before, i.ph-shuffle-simple.icon-alive:before, i.ph-shuffle-simple.icon-warn:before, i.ph-shuffle-simple.icon-sign-out:before, i.ph-shuffle-simple.icon-substratum:before, i.ph-shuffle-simple.icon-environment:before, i.ph-shuffle-simple.icon-user:before, i.ph-shuffle-simple.icon-person:before, i.ph-shuffle-simple.icon-terrain:before, i.ph-shuffle-simple.icon-taxon:before, i.ph-shuffle-simple.icon-unknown:before, i.ph-shuffle-simple.icon-nothing:before, i.ph-shuffle-simple.icon-sampling:before, i.ph-shuffle-simple.icon-isolation:before, i.ph-shuffle-simple.icon-strain:before, i.ph-shuffle-simple.icon-gps:before, i.ph-shuffle-simple.icon-location:before, i.ph-shuffle-simple.icon-add:before, i.ph-shuffle-simple.icon-left:before, i.ph-shuffle-simple.icon-dna:before, i.ph-shuffle-simple.icon-delete:before, i.ph-shuffle-simple.icon-edit:before, i.ph-shuffle-simple.icon-info:before {
  content: "\e426";
}

.ph.ph-sidebar:before, i.ph-sidebar.icon-dead:before, i.ph-sidebar.icon-alive:before, i.ph-sidebar.icon-warn:before, i.ph-sidebar.icon-sign-out:before, i.ph-sidebar.icon-substratum:before, i.ph-sidebar.icon-environment:before, i.ph-sidebar.icon-user:before, i.ph-sidebar.icon-person:before, i.ph-sidebar.icon-terrain:before, i.ph-sidebar.icon-taxon:before, i.ph-sidebar.icon-unknown:before, i.ph-sidebar.icon-nothing:before, i.ph-sidebar.icon-sampling:before, i.ph-sidebar.icon-isolation:before, i.ph-sidebar.icon-strain:before, i.ph-sidebar.icon-gps:before, i.ph-sidebar.icon-location:before, i.ph-sidebar.icon-add:before, i.ph-sidebar.icon-left:before, i.ph-sidebar.icon-dna:before, i.ph-sidebar.icon-delete:before, i.ph-sidebar.icon-edit:before, i.ph-sidebar.icon-info:before {
  content: "\eab6";
}

.ph.ph-sidebar-simple:before, i.ph-sidebar-simple.icon-dead:before, i.ph-sidebar-simple.icon-alive:before, i.ph-sidebar-simple.icon-warn:before, i.ph-sidebar-simple.icon-sign-out:before, i.ph-sidebar-simple.icon-substratum:before, i.ph-sidebar-simple.icon-environment:before, i.ph-sidebar-simple.icon-user:before, i.ph-sidebar-simple.icon-person:before, i.ph-sidebar-simple.icon-terrain:before, i.ph-sidebar-simple.icon-taxon:before, i.ph-sidebar-simple.icon-unknown:before, i.ph-sidebar-simple.icon-nothing:before, i.ph-sidebar-simple.icon-sampling:before, i.ph-sidebar-simple.icon-isolation:before, i.ph-sidebar-simple.icon-strain:before, i.ph-sidebar-simple.icon-gps:before, i.ph-sidebar-simple.icon-location:before, i.ph-sidebar-simple.icon-add:before, i.ph-sidebar-simple.icon-left:before, i.ph-sidebar-simple.icon-dna:before, i.ph-sidebar-simple.icon-delete:before, i.ph-sidebar-simple.icon-edit:before, i.ph-sidebar-simple.icon-info:before {
  content: "\ec24";
}

.ph.ph-sigma:before, i.ph-sigma.icon-dead:before, i.ph-sigma.icon-alive:before, i.ph-sigma.icon-warn:before, i.ph-sigma.icon-sign-out:before, i.ph-sigma.icon-substratum:before, i.ph-sigma.icon-environment:before, i.ph-sigma.icon-user:before, i.ph-sigma.icon-person:before, i.ph-sigma.icon-terrain:before, i.ph-sigma.icon-taxon:before, i.ph-sigma.icon-unknown:before, i.ph-sigma.icon-nothing:before, i.ph-sigma.icon-sampling:before, i.ph-sigma.icon-isolation:before, i.ph-sigma.icon-strain:before, i.ph-sigma.icon-gps:before, i.ph-sigma.icon-location:before, i.ph-sigma.icon-add:before, i.ph-sigma.icon-left:before, i.ph-sigma.icon-dna:before, i.ph-sigma.icon-delete:before, i.ph-sigma.icon-edit:before, i.ph-sigma.icon-info:before {
  content: "\eab8";
}

.ph.ph-sign-in:before, i.ph-sign-in.icon-dead:before, i.ph-sign-in.icon-alive:before, i.ph-sign-in.icon-warn:before, i.ph-sign-in.icon-sign-out:before, i.ph-sign-in.icon-substratum:before, i.ph-sign-in.icon-environment:before, i.ph-sign-in.icon-user:before, i.ph-sign-in.icon-person:before, i.ph-sign-in.icon-terrain:before, i.ph-sign-in.icon-taxon:before, i.ph-sign-in.icon-unknown:before, i.ph-sign-in.icon-nothing:before, i.ph-sign-in.icon-sampling:before, i.ph-sign-in.icon-isolation:before, i.ph-sign-in.icon-strain:before, i.ph-sign-in.icon-gps:before, i.ph-sign-in.icon-location:before, i.ph-sign-in.icon-add:before, i.ph-sign-in.icon-left:before, i.ph-sign-in.icon-dna:before, i.ph-sign-in.icon-delete:before, i.ph-sign-in.icon-edit:before, i.ph-sign-in.icon-info:before {
  content: "\e428";
}

.ph.ph-sign-out:before, i.ph-sign-out.icon-dead:before, i.ph-sign-out.icon-alive:before, i.ph-sign-out.icon-warn:before, i.icon-sign-out:before, i.ph-sign-out.icon-substratum:before, i.ph-sign-out.icon-environment:before, i.ph-sign-out.icon-user:before, i.ph-sign-out.icon-person:before, i.ph-sign-out.icon-terrain:before, i.ph-sign-out.icon-taxon:before, i.ph-sign-out.icon-unknown:before, i.ph-sign-out.icon-nothing:before, i.ph-sign-out.icon-sampling:before, i.ph-sign-out.icon-isolation:before, i.ph-sign-out.icon-strain:before, i.ph-sign-out.icon-gps:before, i.ph-sign-out.icon-location:before, i.ph-sign-out.icon-add:before, i.ph-sign-out.icon-left:before, i.ph-sign-out.icon-dna:before, i.ph-sign-out.icon-delete:before, i.ph-sign-out.icon-edit:before, i.ph-sign-out.icon-info:before {
  content: "\e42a";
}

.ph.ph-signature:before, i.ph-signature.icon-dead:before, i.ph-signature.icon-alive:before, i.ph-signature.icon-warn:before, i.ph-signature.icon-sign-out:before, i.ph-signature.icon-substratum:before, i.ph-signature.icon-environment:before, i.ph-signature.icon-user:before, i.ph-signature.icon-person:before, i.ph-signature.icon-terrain:before, i.ph-signature.icon-taxon:before, i.ph-signature.icon-unknown:before, i.ph-signature.icon-nothing:before, i.ph-signature.icon-sampling:before, i.ph-signature.icon-isolation:before, i.ph-signature.icon-strain:before, i.ph-signature.icon-gps:before, i.ph-signature.icon-location:before, i.ph-signature.icon-add:before, i.ph-signature.icon-left:before, i.ph-signature.icon-dna:before, i.ph-signature.icon-delete:before, i.ph-signature.icon-edit:before, i.ph-signature.icon-info:before {
  content: "\ebac";
}

.ph.ph-signpost:before, i.ph-signpost.icon-dead:before, i.ph-signpost.icon-alive:before, i.ph-signpost.icon-warn:before, i.ph-signpost.icon-sign-out:before, i.ph-signpost.icon-substratum:before, i.ph-signpost.icon-environment:before, i.ph-signpost.icon-user:before, i.ph-signpost.icon-person:before, i.ph-signpost.icon-terrain:before, i.ph-signpost.icon-taxon:before, i.ph-signpost.icon-unknown:before, i.ph-signpost.icon-nothing:before, i.ph-signpost.icon-sampling:before, i.ph-signpost.icon-isolation:before, i.ph-signpost.icon-strain:before, i.ph-signpost.icon-gps:before, i.ph-signpost.icon-location:before, i.ph-signpost.icon-add:before, i.ph-signpost.icon-left:before, i.ph-signpost.icon-dna:before, i.ph-signpost.icon-delete:before, i.ph-signpost.icon-edit:before, i.ph-signpost.icon-info:before {
  content: "\e89c";
}

.ph.ph-sim-card:before, i.ph-sim-card.icon-dead:before, i.ph-sim-card.icon-alive:before, i.ph-sim-card.icon-warn:before, i.ph-sim-card.icon-sign-out:before, i.ph-sim-card.icon-substratum:before, i.ph-sim-card.icon-environment:before, i.ph-sim-card.icon-user:before, i.ph-sim-card.icon-person:before, i.ph-sim-card.icon-terrain:before, i.ph-sim-card.icon-taxon:before, i.ph-sim-card.icon-unknown:before, i.ph-sim-card.icon-nothing:before, i.ph-sim-card.icon-sampling:before, i.ph-sim-card.icon-isolation:before, i.ph-sim-card.icon-strain:before, i.ph-sim-card.icon-gps:before, i.ph-sim-card.icon-location:before, i.ph-sim-card.icon-add:before, i.ph-sim-card.icon-left:before, i.ph-sim-card.icon-dna:before, i.ph-sim-card.icon-delete:before, i.ph-sim-card.icon-edit:before, i.ph-sim-card.icon-info:before {
  content: "\e664";
}

.ph.ph-siren:before, i.ph-siren.icon-dead:before, i.ph-siren.icon-alive:before, i.ph-siren.icon-warn:before, i.ph-siren.icon-sign-out:before, i.ph-siren.icon-substratum:before, i.ph-siren.icon-environment:before, i.ph-siren.icon-user:before, i.ph-siren.icon-person:before, i.ph-siren.icon-terrain:before, i.ph-siren.icon-taxon:before, i.ph-siren.icon-unknown:before, i.ph-siren.icon-nothing:before, i.ph-siren.icon-sampling:before, i.ph-siren.icon-isolation:before, i.ph-siren.icon-strain:before, i.ph-siren.icon-gps:before, i.ph-siren.icon-location:before, i.ph-siren.icon-add:before, i.ph-siren.icon-left:before, i.ph-siren.icon-dna:before, i.ph-siren.icon-delete:before, i.ph-siren.icon-edit:before, i.ph-siren.icon-info:before {
  content: "\e9b8";
}

.ph.ph-sketch-logo:before, i.ph-sketch-logo.icon-dead:before, i.ph-sketch-logo.icon-alive:before, i.ph-sketch-logo.icon-warn:before, i.ph-sketch-logo.icon-sign-out:before, i.ph-sketch-logo.icon-substratum:before, i.ph-sketch-logo.icon-environment:before, i.ph-sketch-logo.icon-user:before, i.ph-sketch-logo.icon-person:before, i.ph-sketch-logo.icon-terrain:before, i.ph-sketch-logo.icon-taxon:before, i.ph-sketch-logo.icon-unknown:before, i.ph-sketch-logo.icon-nothing:before, i.ph-sketch-logo.icon-sampling:before, i.ph-sketch-logo.icon-isolation:before, i.ph-sketch-logo.icon-strain:before, i.ph-sketch-logo.icon-gps:before, i.ph-sketch-logo.icon-location:before, i.ph-sketch-logo.icon-add:before, i.ph-sketch-logo.icon-left:before, i.ph-sketch-logo.icon-dna:before, i.ph-sketch-logo.icon-delete:before, i.ph-sketch-logo.icon-edit:before, i.ph-sketch-logo.icon-info:before {
  content: "\e42c";
}

.ph.ph-skip-back:before, i.ph-skip-back.icon-dead:before, i.ph-skip-back.icon-alive:before, i.ph-skip-back.icon-warn:before, i.ph-skip-back.icon-sign-out:before, i.ph-skip-back.icon-substratum:before, i.ph-skip-back.icon-environment:before, i.ph-skip-back.icon-user:before, i.ph-skip-back.icon-person:before, i.ph-skip-back.icon-terrain:before, i.ph-skip-back.icon-taxon:before, i.ph-skip-back.icon-unknown:before, i.ph-skip-back.icon-nothing:before, i.ph-skip-back.icon-sampling:before, i.ph-skip-back.icon-isolation:before, i.ph-skip-back.icon-strain:before, i.ph-skip-back.icon-gps:before, i.ph-skip-back.icon-location:before, i.ph-skip-back.icon-add:before, i.ph-skip-back.icon-left:before, i.ph-skip-back.icon-dna:before, i.ph-skip-back.icon-delete:before, i.ph-skip-back.icon-edit:before, i.ph-skip-back.icon-info:before {
  content: "\e5a4";
}

.ph.ph-skip-back-circle:before, i.ph-skip-back-circle.icon-dead:before, i.ph-skip-back-circle.icon-alive:before, i.ph-skip-back-circle.icon-warn:before, i.ph-skip-back-circle.icon-sign-out:before, i.ph-skip-back-circle.icon-substratum:before, i.ph-skip-back-circle.icon-environment:before, i.ph-skip-back-circle.icon-user:before, i.ph-skip-back-circle.icon-person:before, i.ph-skip-back-circle.icon-terrain:before, i.ph-skip-back-circle.icon-taxon:before, i.ph-skip-back-circle.icon-unknown:before, i.ph-skip-back-circle.icon-nothing:before, i.ph-skip-back-circle.icon-sampling:before, i.ph-skip-back-circle.icon-isolation:before, i.ph-skip-back-circle.icon-strain:before, i.ph-skip-back-circle.icon-gps:before, i.ph-skip-back-circle.icon-location:before, i.ph-skip-back-circle.icon-add:before, i.ph-skip-back-circle.icon-left:before, i.ph-skip-back-circle.icon-dna:before, i.ph-skip-back-circle.icon-delete:before, i.ph-skip-back-circle.icon-edit:before, i.ph-skip-back-circle.icon-info:before {
  content: "\e42e";
}

.ph.ph-skip-forward:before, i.ph-skip-forward.icon-dead:before, i.ph-skip-forward.icon-alive:before, i.ph-skip-forward.icon-warn:before, i.ph-skip-forward.icon-sign-out:before, i.ph-skip-forward.icon-substratum:before, i.ph-skip-forward.icon-environment:before, i.ph-skip-forward.icon-user:before, i.ph-skip-forward.icon-person:before, i.ph-skip-forward.icon-terrain:before, i.ph-skip-forward.icon-taxon:before, i.ph-skip-forward.icon-unknown:before, i.ph-skip-forward.icon-nothing:before, i.ph-skip-forward.icon-sampling:before, i.ph-skip-forward.icon-isolation:before, i.ph-skip-forward.icon-strain:before, i.ph-skip-forward.icon-gps:before, i.ph-skip-forward.icon-location:before, i.ph-skip-forward.icon-add:before, i.ph-skip-forward.icon-left:before, i.ph-skip-forward.icon-dna:before, i.ph-skip-forward.icon-delete:before, i.ph-skip-forward.icon-edit:before, i.ph-skip-forward.icon-info:before {
  content: "\e5a6";
}

.ph.ph-skip-forward-circle:before, i.ph-skip-forward-circle.icon-dead:before, i.ph-skip-forward-circle.icon-alive:before, i.ph-skip-forward-circle.icon-warn:before, i.ph-skip-forward-circle.icon-sign-out:before, i.ph-skip-forward-circle.icon-substratum:before, i.ph-skip-forward-circle.icon-environment:before, i.ph-skip-forward-circle.icon-user:before, i.ph-skip-forward-circle.icon-person:before, i.ph-skip-forward-circle.icon-terrain:before, i.ph-skip-forward-circle.icon-taxon:before, i.ph-skip-forward-circle.icon-unknown:before, i.ph-skip-forward-circle.icon-nothing:before, i.ph-skip-forward-circle.icon-sampling:before, i.ph-skip-forward-circle.icon-isolation:before, i.ph-skip-forward-circle.icon-strain:before, i.ph-skip-forward-circle.icon-gps:before, i.ph-skip-forward-circle.icon-location:before, i.ph-skip-forward-circle.icon-add:before, i.ph-skip-forward-circle.icon-left:before, i.ph-skip-forward-circle.icon-dna:before, i.ph-skip-forward-circle.icon-delete:before, i.ph-skip-forward-circle.icon-edit:before, i.ph-skip-forward-circle.icon-info:before {
  content: "\e430";
}

.ph.ph-skull:before, i.ph-skull.icon-dead:before, i.ph-skull.icon-alive:before, i.ph-skull.icon-warn:before, i.ph-skull.icon-sign-out:before, i.ph-skull.icon-substratum:before, i.ph-skull.icon-environment:before, i.ph-skull.icon-user:before, i.ph-skull.icon-person:before, i.ph-skull.icon-terrain:before, i.ph-skull.icon-taxon:before, i.ph-skull.icon-unknown:before, i.ph-skull.icon-nothing:before, i.ph-skull.icon-sampling:before, i.ph-skull.icon-isolation:before, i.ph-skull.icon-strain:before, i.ph-skull.icon-gps:before, i.ph-skull.icon-location:before, i.ph-skull.icon-add:before, i.ph-skull.icon-left:before, i.ph-skull.icon-dna:before, i.ph-skull.icon-delete:before, i.ph-skull.icon-edit:before, i.ph-skull.icon-info:before {
  content: "\e916";
}

.ph.ph-skype-logo:before, i.ph-skype-logo.icon-dead:before, i.ph-skype-logo.icon-alive:before, i.ph-skype-logo.icon-warn:before, i.ph-skype-logo.icon-sign-out:before, i.ph-skype-logo.icon-substratum:before, i.ph-skype-logo.icon-environment:before, i.ph-skype-logo.icon-user:before, i.ph-skype-logo.icon-person:before, i.ph-skype-logo.icon-terrain:before, i.ph-skype-logo.icon-taxon:before, i.ph-skype-logo.icon-unknown:before, i.ph-skype-logo.icon-nothing:before, i.ph-skype-logo.icon-sampling:before, i.ph-skype-logo.icon-isolation:before, i.ph-skype-logo.icon-strain:before, i.ph-skype-logo.icon-gps:before, i.ph-skype-logo.icon-location:before, i.ph-skype-logo.icon-add:before, i.ph-skype-logo.icon-left:before, i.ph-skype-logo.icon-dna:before, i.ph-skype-logo.icon-delete:before, i.ph-skype-logo.icon-edit:before, i.ph-skype-logo.icon-info:before {
  content: "\e8dc";
}

.ph.ph-slack-logo:before, i.ph-slack-logo.icon-dead:before, i.ph-slack-logo.icon-alive:before, i.ph-slack-logo.icon-warn:before, i.ph-slack-logo.icon-sign-out:before, i.ph-slack-logo.icon-substratum:before, i.ph-slack-logo.icon-environment:before, i.ph-slack-logo.icon-user:before, i.ph-slack-logo.icon-person:before, i.ph-slack-logo.icon-terrain:before, i.ph-slack-logo.icon-taxon:before, i.ph-slack-logo.icon-unknown:before, i.ph-slack-logo.icon-nothing:before, i.ph-slack-logo.icon-sampling:before, i.ph-slack-logo.icon-isolation:before, i.ph-slack-logo.icon-strain:before, i.ph-slack-logo.icon-gps:before, i.ph-slack-logo.icon-location:before, i.ph-slack-logo.icon-add:before, i.ph-slack-logo.icon-left:before, i.ph-slack-logo.icon-dna:before, i.ph-slack-logo.icon-delete:before, i.ph-slack-logo.icon-edit:before, i.ph-slack-logo.icon-info:before {
  content: "\e5a8";
}

.ph.ph-sliders:before, i.ph-sliders.icon-dead:before, i.ph-sliders.icon-alive:before, i.ph-sliders.icon-warn:before, i.ph-sliders.icon-sign-out:before, i.ph-sliders.icon-substratum:before, i.ph-sliders.icon-environment:before, i.ph-sliders.icon-user:before, i.ph-sliders.icon-person:before, i.ph-sliders.icon-terrain:before, i.ph-sliders.icon-taxon:before, i.ph-sliders.icon-unknown:before, i.ph-sliders.icon-nothing:before, i.ph-sliders.icon-sampling:before, i.ph-sliders.icon-isolation:before, i.ph-sliders.icon-strain:before, i.ph-sliders.icon-gps:before, i.ph-sliders.icon-location:before, i.ph-sliders.icon-add:before, i.ph-sliders.icon-left:before, i.ph-sliders.icon-dna:before, i.ph-sliders.icon-delete:before, i.ph-sliders.icon-edit:before, i.ph-sliders.icon-info:before {
  content: "\e432";
}

.ph.ph-sliders-horizontal:before, i.ph-sliders-horizontal.icon-dead:before, i.ph-sliders-horizontal.icon-alive:before, i.ph-sliders-horizontal.icon-warn:before, i.ph-sliders-horizontal.icon-sign-out:before, i.ph-sliders-horizontal.icon-substratum:before, i.ph-sliders-horizontal.icon-environment:before, i.ph-sliders-horizontal.icon-user:before, i.ph-sliders-horizontal.icon-person:before, i.ph-sliders-horizontal.icon-terrain:before, i.ph-sliders-horizontal.icon-taxon:before, i.ph-sliders-horizontal.icon-unknown:before, i.ph-sliders-horizontal.icon-nothing:before, i.ph-sliders-horizontal.icon-sampling:before, i.ph-sliders-horizontal.icon-isolation:before, i.ph-sliders-horizontal.icon-strain:before, i.ph-sliders-horizontal.icon-gps:before, i.ph-sliders-horizontal.icon-location:before, i.ph-sliders-horizontal.icon-add:before, i.ph-sliders-horizontal.icon-left:before, i.ph-sliders-horizontal.icon-dna:before, i.ph-sliders-horizontal.icon-delete:before, i.ph-sliders-horizontal.icon-edit:before, i.ph-sliders-horizontal.icon-info:before {
  content: "\e434";
}

.ph.ph-slideshow:before, i.ph-slideshow.icon-dead:before, i.ph-slideshow.icon-alive:before, i.ph-slideshow.icon-warn:before, i.ph-slideshow.icon-sign-out:before, i.ph-slideshow.icon-substratum:before, i.ph-slideshow.icon-environment:before, i.ph-slideshow.icon-user:before, i.ph-slideshow.icon-person:before, i.ph-slideshow.icon-terrain:before, i.ph-slideshow.icon-taxon:before, i.ph-slideshow.icon-unknown:before, i.ph-slideshow.icon-nothing:before, i.ph-slideshow.icon-sampling:before, i.ph-slideshow.icon-isolation:before, i.ph-slideshow.icon-strain:before, i.ph-slideshow.icon-gps:before, i.ph-slideshow.icon-location:before, i.ph-slideshow.icon-add:before, i.ph-slideshow.icon-left:before, i.ph-slideshow.icon-dna:before, i.ph-slideshow.icon-delete:before, i.ph-slideshow.icon-edit:before, i.ph-slideshow.icon-info:before {
  content: "\ed32";
}

.ph.ph-smiley:before, i.ph-smiley.icon-dead:before, i.icon-alive:before, i.ph-smiley.icon-warn:before, i.ph-smiley.icon-sign-out:before, i.ph-smiley.icon-substratum:before, i.ph-smiley.icon-environment:before, i.ph-smiley.icon-user:before, i.ph-smiley.icon-person:before, i.ph-smiley.icon-terrain:before, i.ph-smiley.icon-taxon:before, i.ph-smiley.icon-unknown:before, i.ph-smiley.icon-nothing:before, i.ph-smiley.icon-sampling:before, i.ph-smiley.icon-isolation:before, i.ph-smiley.icon-strain:before, i.ph-smiley.icon-gps:before, i.ph-smiley.icon-location:before, i.ph-smiley.icon-add:before, i.ph-smiley.icon-left:before, i.ph-smiley.icon-dna:before, i.ph-smiley.icon-delete:before, i.ph-smiley.icon-edit:before, i.ph-smiley.icon-info:before {
  content: "\e436";
}

.ph.ph-smiley-angry:before, i.ph-smiley-angry.icon-dead:before, i.ph-smiley-angry.icon-alive:before, i.ph-smiley-angry.icon-warn:before, i.ph-smiley-angry.icon-sign-out:before, i.ph-smiley-angry.icon-substratum:before, i.ph-smiley-angry.icon-environment:before, i.ph-smiley-angry.icon-user:before, i.ph-smiley-angry.icon-person:before, i.ph-smiley-angry.icon-terrain:before, i.ph-smiley-angry.icon-taxon:before, i.ph-smiley-angry.icon-unknown:before, i.ph-smiley-angry.icon-nothing:before, i.ph-smiley-angry.icon-sampling:before, i.ph-smiley-angry.icon-isolation:before, i.ph-smiley-angry.icon-strain:before, i.ph-smiley-angry.icon-gps:before, i.ph-smiley-angry.icon-location:before, i.ph-smiley-angry.icon-add:before, i.ph-smiley-angry.icon-left:before, i.ph-smiley-angry.icon-dna:before, i.ph-smiley-angry.icon-delete:before, i.ph-smiley-angry.icon-edit:before, i.ph-smiley-angry.icon-info:before {
  content: "\ec62";
}

.ph.ph-smiley-blank:before, i.ph-smiley-blank.icon-dead:before, i.ph-smiley-blank.icon-alive:before, i.ph-smiley-blank.icon-warn:before, i.ph-smiley-blank.icon-sign-out:before, i.ph-smiley-blank.icon-substratum:before, i.ph-smiley-blank.icon-environment:before, i.ph-smiley-blank.icon-user:before, i.ph-smiley-blank.icon-person:before, i.ph-smiley-blank.icon-terrain:before, i.ph-smiley-blank.icon-taxon:before, i.ph-smiley-blank.icon-unknown:before, i.ph-smiley-blank.icon-nothing:before, i.ph-smiley-blank.icon-sampling:before, i.ph-smiley-blank.icon-isolation:before, i.ph-smiley-blank.icon-strain:before, i.ph-smiley-blank.icon-gps:before, i.ph-smiley-blank.icon-location:before, i.ph-smiley-blank.icon-add:before, i.ph-smiley-blank.icon-left:before, i.ph-smiley-blank.icon-dna:before, i.ph-smiley-blank.icon-delete:before, i.ph-smiley-blank.icon-edit:before, i.ph-smiley-blank.icon-info:before {
  content: "\e438";
}

.ph.ph-smiley-meh:before, i.ph-smiley-meh.icon-dead:before, i.ph-smiley-meh.icon-alive:before, i.ph-smiley-meh.icon-warn:before, i.ph-smiley-meh.icon-sign-out:before, i.ph-smiley-meh.icon-substratum:before, i.ph-smiley-meh.icon-environment:before, i.ph-smiley-meh.icon-user:before, i.ph-smiley-meh.icon-person:before, i.ph-smiley-meh.icon-terrain:before, i.ph-smiley-meh.icon-taxon:before, i.ph-smiley-meh.icon-unknown:before, i.ph-smiley-meh.icon-nothing:before, i.ph-smiley-meh.icon-sampling:before, i.ph-smiley-meh.icon-isolation:before, i.ph-smiley-meh.icon-strain:before, i.ph-smiley-meh.icon-gps:before, i.ph-smiley-meh.icon-location:before, i.ph-smiley-meh.icon-add:before, i.ph-smiley-meh.icon-left:before, i.ph-smiley-meh.icon-dna:before, i.ph-smiley-meh.icon-delete:before, i.ph-smiley-meh.icon-edit:before, i.ph-smiley-meh.icon-info:before {
  content: "\e43a";
}

.ph.ph-smiley-melting:before, i.ph-smiley-melting.icon-dead:before, i.ph-smiley-melting.icon-alive:before, i.ph-smiley-melting.icon-warn:before, i.ph-smiley-melting.icon-sign-out:before, i.ph-smiley-melting.icon-substratum:before, i.ph-smiley-melting.icon-environment:before, i.ph-smiley-melting.icon-user:before, i.ph-smiley-melting.icon-person:before, i.ph-smiley-melting.icon-terrain:before, i.ph-smiley-melting.icon-taxon:before, i.ph-smiley-melting.icon-unknown:before, i.ph-smiley-melting.icon-nothing:before, i.ph-smiley-melting.icon-sampling:before, i.ph-smiley-melting.icon-isolation:before, i.ph-smiley-melting.icon-strain:before, i.ph-smiley-melting.icon-gps:before, i.ph-smiley-melting.icon-location:before, i.ph-smiley-melting.icon-add:before, i.ph-smiley-melting.icon-left:before, i.ph-smiley-melting.icon-dna:before, i.ph-smiley-melting.icon-delete:before, i.ph-smiley-melting.icon-edit:before, i.ph-smiley-melting.icon-info:before {
  content: "\ee56";
}

.ph.ph-smiley-nervous:before, i.ph-smiley-nervous.icon-dead:before, i.ph-smiley-nervous.icon-alive:before, i.ph-smiley-nervous.icon-warn:before, i.ph-smiley-nervous.icon-sign-out:before, i.ph-smiley-nervous.icon-substratum:before, i.ph-smiley-nervous.icon-environment:before, i.ph-smiley-nervous.icon-user:before, i.ph-smiley-nervous.icon-person:before, i.ph-smiley-nervous.icon-terrain:before, i.ph-smiley-nervous.icon-taxon:before, i.ph-smiley-nervous.icon-unknown:before, i.ph-smiley-nervous.icon-nothing:before, i.ph-smiley-nervous.icon-sampling:before, i.ph-smiley-nervous.icon-isolation:before, i.ph-smiley-nervous.icon-strain:before, i.ph-smiley-nervous.icon-gps:before, i.ph-smiley-nervous.icon-location:before, i.ph-smiley-nervous.icon-add:before, i.ph-smiley-nervous.icon-left:before, i.ph-smiley-nervous.icon-dna:before, i.ph-smiley-nervous.icon-delete:before, i.ph-smiley-nervous.icon-edit:before, i.ph-smiley-nervous.icon-info:before {
  content: "\e43c";
}

.ph.ph-smiley-sad:before, i.ph-smiley-sad.icon-dead:before, i.ph-smiley-sad.icon-alive:before, i.ph-smiley-sad.icon-warn:before, i.ph-smiley-sad.icon-sign-out:before, i.ph-smiley-sad.icon-substratum:before, i.ph-smiley-sad.icon-environment:before, i.ph-smiley-sad.icon-user:before, i.ph-smiley-sad.icon-person:before, i.ph-smiley-sad.icon-terrain:before, i.ph-smiley-sad.icon-taxon:before, i.ph-smiley-sad.icon-unknown:before, i.ph-smiley-sad.icon-nothing:before, i.ph-smiley-sad.icon-sampling:before, i.ph-smiley-sad.icon-isolation:before, i.ph-smiley-sad.icon-strain:before, i.ph-smiley-sad.icon-gps:before, i.ph-smiley-sad.icon-location:before, i.ph-smiley-sad.icon-add:before, i.ph-smiley-sad.icon-left:before, i.ph-smiley-sad.icon-dna:before, i.ph-smiley-sad.icon-delete:before, i.ph-smiley-sad.icon-edit:before, i.ph-smiley-sad.icon-info:before {
  content: "\e43e";
}

.ph.ph-smiley-sticker:before, i.ph-smiley-sticker.icon-dead:before, i.ph-smiley-sticker.icon-alive:before, i.ph-smiley-sticker.icon-warn:before, i.ph-smiley-sticker.icon-sign-out:before, i.ph-smiley-sticker.icon-substratum:before, i.ph-smiley-sticker.icon-environment:before, i.ph-smiley-sticker.icon-user:before, i.ph-smiley-sticker.icon-person:before, i.ph-smiley-sticker.icon-terrain:before, i.ph-smiley-sticker.icon-taxon:before, i.ph-smiley-sticker.icon-unknown:before, i.ph-smiley-sticker.icon-nothing:before, i.ph-smiley-sticker.icon-sampling:before, i.ph-smiley-sticker.icon-isolation:before, i.ph-smiley-sticker.icon-strain:before, i.ph-smiley-sticker.icon-gps:before, i.ph-smiley-sticker.icon-location:before, i.ph-smiley-sticker.icon-add:before, i.ph-smiley-sticker.icon-left:before, i.ph-smiley-sticker.icon-dna:before, i.ph-smiley-sticker.icon-delete:before, i.ph-smiley-sticker.icon-edit:before, i.ph-smiley-sticker.icon-info:before {
  content: "\e440";
}

.ph.ph-smiley-wink:before, i.ph-smiley-wink.icon-dead:before, i.ph-smiley-wink.icon-alive:before, i.ph-smiley-wink.icon-warn:before, i.ph-smiley-wink.icon-sign-out:before, i.ph-smiley-wink.icon-substratum:before, i.ph-smiley-wink.icon-environment:before, i.ph-smiley-wink.icon-user:before, i.ph-smiley-wink.icon-person:before, i.ph-smiley-wink.icon-terrain:before, i.ph-smiley-wink.icon-taxon:before, i.ph-smiley-wink.icon-unknown:before, i.ph-smiley-wink.icon-nothing:before, i.ph-smiley-wink.icon-sampling:before, i.ph-smiley-wink.icon-isolation:before, i.ph-smiley-wink.icon-strain:before, i.ph-smiley-wink.icon-gps:before, i.ph-smiley-wink.icon-location:before, i.ph-smiley-wink.icon-add:before, i.ph-smiley-wink.icon-left:before, i.ph-smiley-wink.icon-dna:before, i.ph-smiley-wink.icon-delete:before, i.ph-smiley-wink.icon-edit:before, i.ph-smiley-wink.icon-info:before {
  content: "\e666";
}

.ph.ph-smiley-x-eyes:before, i.icon-dead:before, i.ph-smiley-x-eyes.icon-alive:before, i.ph-smiley-x-eyes.icon-warn:before, i.ph-smiley-x-eyes.icon-sign-out:before, i.ph-smiley-x-eyes.icon-substratum:before, i.ph-smiley-x-eyes.icon-environment:before, i.ph-smiley-x-eyes.icon-user:before, i.ph-smiley-x-eyes.icon-person:before, i.ph-smiley-x-eyes.icon-terrain:before, i.ph-smiley-x-eyes.icon-taxon:before, i.ph-smiley-x-eyes.icon-unknown:before, i.ph-smiley-x-eyes.icon-nothing:before, i.ph-smiley-x-eyes.icon-sampling:before, i.ph-smiley-x-eyes.icon-isolation:before, i.ph-smiley-x-eyes.icon-strain:before, i.ph-smiley-x-eyes.icon-gps:before, i.ph-smiley-x-eyes.icon-location:before, i.ph-smiley-x-eyes.icon-add:before, i.ph-smiley-x-eyes.icon-left:before, i.ph-smiley-x-eyes.icon-dna:before, i.ph-smiley-x-eyes.icon-delete:before, i.ph-smiley-x-eyes.icon-edit:before, i.ph-smiley-x-eyes.icon-info:before {
  content: "\e442";
}

.ph.ph-snapchat-logo:before, i.ph-snapchat-logo.icon-dead:before, i.ph-snapchat-logo.icon-alive:before, i.ph-snapchat-logo.icon-warn:before, i.ph-snapchat-logo.icon-sign-out:before, i.ph-snapchat-logo.icon-substratum:before, i.ph-snapchat-logo.icon-environment:before, i.ph-snapchat-logo.icon-user:before, i.ph-snapchat-logo.icon-person:before, i.ph-snapchat-logo.icon-terrain:before, i.ph-snapchat-logo.icon-taxon:before, i.ph-snapchat-logo.icon-unknown:before, i.ph-snapchat-logo.icon-nothing:before, i.ph-snapchat-logo.icon-sampling:before, i.ph-snapchat-logo.icon-isolation:before, i.ph-snapchat-logo.icon-strain:before, i.ph-snapchat-logo.icon-gps:before, i.ph-snapchat-logo.icon-location:before, i.ph-snapchat-logo.icon-add:before, i.ph-snapchat-logo.icon-left:before, i.ph-snapchat-logo.icon-dna:before, i.ph-snapchat-logo.icon-delete:before, i.ph-snapchat-logo.icon-edit:before, i.ph-snapchat-logo.icon-info:before {
  content: "\e668";
}

.ph.ph-sneaker:before, i.ph-sneaker.icon-dead:before, i.ph-sneaker.icon-alive:before, i.ph-sneaker.icon-warn:before, i.ph-sneaker.icon-sign-out:before, i.ph-sneaker.icon-substratum:before, i.ph-sneaker.icon-environment:before, i.ph-sneaker.icon-user:before, i.ph-sneaker.icon-person:before, i.ph-sneaker.icon-terrain:before, i.ph-sneaker.icon-taxon:before, i.ph-sneaker.icon-unknown:before, i.ph-sneaker.icon-nothing:before, i.ph-sneaker.icon-sampling:before, i.ph-sneaker.icon-isolation:before, i.ph-sneaker.icon-strain:before, i.ph-sneaker.icon-gps:before, i.ph-sneaker.icon-location:before, i.ph-sneaker.icon-add:before, i.ph-sneaker.icon-left:before, i.ph-sneaker.icon-dna:before, i.ph-sneaker.icon-delete:before, i.ph-sneaker.icon-edit:before, i.ph-sneaker.icon-info:before {
  content: "\e80c";
}

.ph.ph-sneaker-move:before, i.ph-sneaker-move.icon-dead:before, i.ph-sneaker-move.icon-alive:before, i.ph-sneaker-move.icon-warn:before, i.ph-sneaker-move.icon-sign-out:before, i.ph-sneaker-move.icon-substratum:before, i.ph-sneaker-move.icon-environment:before, i.ph-sneaker-move.icon-user:before, i.ph-sneaker-move.icon-person:before, i.ph-sneaker-move.icon-terrain:before, i.ph-sneaker-move.icon-taxon:before, i.ph-sneaker-move.icon-unknown:before, i.ph-sneaker-move.icon-nothing:before, i.ph-sneaker-move.icon-sampling:before, i.ph-sneaker-move.icon-isolation:before, i.ph-sneaker-move.icon-strain:before, i.ph-sneaker-move.icon-gps:before, i.ph-sneaker-move.icon-location:before, i.ph-sneaker-move.icon-add:before, i.ph-sneaker-move.icon-left:before, i.ph-sneaker-move.icon-dna:before, i.ph-sneaker-move.icon-delete:before, i.ph-sneaker-move.icon-edit:before, i.ph-sneaker-move.icon-info:before {
  content: "\ed60";
}

.ph.ph-snowflake:before, i.ph-snowflake.icon-dead:before, i.ph-snowflake.icon-alive:before, i.ph-snowflake.icon-warn:before, i.ph-snowflake.icon-sign-out:before, i.ph-snowflake.icon-substratum:before, i.ph-snowflake.icon-environment:before, i.ph-snowflake.icon-user:before, i.ph-snowflake.icon-person:before, i.ph-snowflake.icon-terrain:before, i.ph-snowflake.icon-taxon:before, i.ph-snowflake.icon-unknown:before, i.ph-snowflake.icon-nothing:before, i.ph-snowflake.icon-sampling:before, i.ph-snowflake.icon-isolation:before, i.ph-snowflake.icon-strain:before, i.ph-snowflake.icon-gps:before, i.ph-snowflake.icon-location:before, i.ph-snowflake.icon-add:before, i.ph-snowflake.icon-left:before, i.ph-snowflake.icon-dna:before, i.ph-snowflake.icon-delete:before, i.ph-snowflake.icon-edit:before, i.ph-snowflake.icon-info:before {
  content: "\e5aa";
}

.ph.ph-soccer-ball:before, i.ph-soccer-ball.icon-dead:before, i.ph-soccer-ball.icon-alive:before, i.ph-soccer-ball.icon-warn:before, i.ph-soccer-ball.icon-sign-out:before, i.ph-soccer-ball.icon-substratum:before, i.ph-soccer-ball.icon-environment:before, i.ph-soccer-ball.icon-user:before, i.ph-soccer-ball.icon-person:before, i.ph-soccer-ball.icon-terrain:before, i.ph-soccer-ball.icon-taxon:before, i.ph-soccer-ball.icon-unknown:before, i.ph-soccer-ball.icon-nothing:before, i.ph-soccer-ball.icon-sampling:before, i.ph-soccer-ball.icon-isolation:before, i.ph-soccer-ball.icon-strain:before, i.ph-soccer-ball.icon-gps:before, i.ph-soccer-ball.icon-location:before, i.ph-soccer-ball.icon-add:before, i.ph-soccer-ball.icon-left:before, i.ph-soccer-ball.icon-dna:before, i.ph-soccer-ball.icon-delete:before, i.ph-soccer-ball.icon-edit:before, i.ph-soccer-ball.icon-info:before {
  content: "\e716";
}

.ph.ph-sock:before, i.ph-sock.icon-dead:before, i.ph-sock.icon-alive:before, i.ph-sock.icon-warn:before, i.ph-sock.icon-sign-out:before, i.ph-sock.icon-substratum:before, i.ph-sock.icon-environment:before, i.ph-sock.icon-user:before, i.ph-sock.icon-person:before, i.ph-sock.icon-terrain:before, i.ph-sock.icon-taxon:before, i.ph-sock.icon-unknown:before, i.ph-sock.icon-nothing:before, i.ph-sock.icon-sampling:before, i.ph-sock.icon-isolation:before, i.ph-sock.icon-strain:before, i.ph-sock.icon-gps:before, i.ph-sock.icon-location:before, i.ph-sock.icon-add:before, i.ph-sock.icon-left:before, i.ph-sock.icon-dna:before, i.ph-sock.icon-delete:before, i.ph-sock.icon-edit:before, i.ph-sock.icon-info:before {
  content: "\ecce";
}

.ph.ph-solar-panel:before, i.ph-solar-panel.icon-dead:before, i.ph-solar-panel.icon-alive:before, i.ph-solar-panel.icon-warn:before, i.ph-solar-panel.icon-sign-out:before, i.ph-solar-panel.icon-substratum:before, i.ph-solar-panel.icon-environment:before, i.ph-solar-panel.icon-user:before, i.ph-solar-panel.icon-person:before, i.ph-solar-panel.icon-terrain:before, i.ph-solar-panel.icon-taxon:before, i.ph-solar-panel.icon-unknown:before, i.ph-solar-panel.icon-nothing:before, i.ph-solar-panel.icon-sampling:before, i.ph-solar-panel.icon-isolation:before, i.ph-solar-panel.icon-strain:before, i.ph-solar-panel.icon-gps:before, i.ph-solar-panel.icon-location:before, i.ph-solar-panel.icon-add:before, i.ph-solar-panel.icon-left:before, i.ph-solar-panel.icon-dna:before, i.ph-solar-panel.icon-delete:before, i.ph-solar-panel.icon-edit:before, i.ph-solar-panel.icon-info:before {
  content: "\ed7a";
}

.ph.ph-solar-roof:before, i.ph-solar-roof.icon-dead:before, i.ph-solar-roof.icon-alive:before, i.ph-solar-roof.icon-warn:before, i.ph-solar-roof.icon-sign-out:before, i.ph-solar-roof.icon-substratum:before, i.ph-solar-roof.icon-environment:before, i.ph-solar-roof.icon-user:before, i.ph-solar-roof.icon-person:before, i.ph-solar-roof.icon-terrain:before, i.ph-solar-roof.icon-taxon:before, i.ph-solar-roof.icon-unknown:before, i.ph-solar-roof.icon-nothing:before, i.ph-solar-roof.icon-sampling:before, i.ph-solar-roof.icon-isolation:before, i.ph-solar-roof.icon-strain:before, i.ph-solar-roof.icon-gps:before, i.ph-solar-roof.icon-location:before, i.ph-solar-roof.icon-add:before, i.ph-solar-roof.icon-left:before, i.ph-solar-roof.icon-dna:before, i.ph-solar-roof.icon-delete:before, i.ph-solar-roof.icon-edit:before, i.ph-solar-roof.icon-info:before {
  content: "\ed7b";
}

.ph.ph-sort-ascending:before, i.ph-sort-ascending.icon-dead:before, i.ph-sort-ascending.icon-alive:before, i.ph-sort-ascending.icon-warn:before, i.ph-sort-ascending.icon-sign-out:before, i.ph-sort-ascending.icon-substratum:before, i.ph-sort-ascending.icon-environment:before, i.ph-sort-ascending.icon-user:before, i.ph-sort-ascending.icon-person:before, i.ph-sort-ascending.icon-terrain:before, i.ph-sort-ascending.icon-taxon:before, i.ph-sort-ascending.icon-unknown:before, i.ph-sort-ascending.icon-nothing:before, i.ph-sort-ascending.icon-sampling:before, i.ph-sort-ascending.icon-isolation:before, i.ph-sort-ascending.icon-strain:before, i.ph-sort-ascending.icon-gps:before, i.ph-sort-ascending.icon-location:before, i.ph-sort-ascending.icon-add:before, i.ph-sort-ascending.icon-left:before, i.ph-sort-ascending.icon-dna:before, i.ph-sort-ascending.icon-delete:before, i.ph-sort-ascending.icon-edit:before, i.ph-sort-ascending.icon-info:before {
  content: "\e444";
}

.ph.ph-sort-descending:before, i.ph-sort-descending.icon-dead:before, i.ph-sort-descending.icon-alive:before, i.ph-sort-descending.icon-warn:before, i.ph-sort-descending.icon-sign-out:before, i.ph-sort-descending.icon-substratum:before, i.ph-sort-descending.icon-environment:before, i.ph-sort-descending.icon-user:before, i.ph-sort-descending.icon-person:before, i.ph-sort-descending.icon-terrain:before, i.ph-sort-descending.icon-taxon:before, i.ph-sort-descending.icon-unknown:before, i.ph-sort-descending.icon-nothing:before, i.ph-sort-descending.icon-sampling:before, i.ph-sort-descending.icon-isolation:before, i.ph-sort-descending.icon-strain:before, i.ph-sort-descending.icon-gps:before, i.ph-sort-descending.icon-location:before, i.ph-sort-descending.icon-add:before, i.ph-sort-descending.icon-left:before, i.ph-sort-descending.icon-dna:before, i.ph-sort-descending.icon-delete:before, i.ph-sort-descending.icon-edit:before, i.ph-sort-descending.icon-info:before {
  content: "\e446";
}

.ph.ph-soundcloud-logo:before, i.ph-soundcloud-logo.icon-dead:before, i.ph-soundcloud-logo.icon-alive:before, i.ph-soundcloud-logo.icon-warn:before, i.ph-soundcloud-logo.icon-sign-out:before, i.ph-soundcloud-logo.icon-substratum:before, i.ph-soundcloud-logo.icon-environment:before, i.ph-soundcloud-logo.icon-user:before, i.ph-soundcloud-logo.icon-person:before, i.ph-soundcloud-logo.icon-terrain:before, i.ph-soundcloud-logo.icon-taxon:before, i.ph-soundcloud-logo.icon-unknown:before, i.ph-soundcloud-logo.icon-nothing:before, i.ph-soundcloud-logo.icon-sampling:before, i.ph-soundcloud-logo.icon-isolation:before, i.ph-soundcloud-logo.icon-strain:before, i.ph-soundcloud-logo.icon-gps:before, i.ph-soundcloud-logo.icon-location:before, i.ph-soundcloud-logo.icon-add:before, i.ph-soundcloud-logo.icon-left:before, i.ph-soundcloud-logo.icon-dna:before, i.ph-soundcloud-logo.icon-delete:before, i.ph-soundcloud-logo.icon-edit:before, i.ph-soundcloud-logo.icon-info:before {
  content: "\e8de";
}

.ph.ph-spade:before, i.ph-spade.icon-dead:before, i.ph-spade.icon-alive:before, i.ph-spade.icon-warn:before, i.ph-spade.icon-sign-out:before, i.ph-spade.icon-substratum:before, i.ph-spade.icon-environment:before, i.ph-spade.icon-user:before, i.ph-spade.icon-person:before, i.ph-spade.icon-terrain:before, i.ph-spade.icon-taxon:before, i.ph-spade.icon-unknown:before, i.ph-spade.icon-nothing:before, i.ph-spade.icon-sampling:before, i.ph-spade.icon-isolation:before, i.ph-spade.icon-strain:before, i.ph-spade.icon-gps:before, i.ph-spade.icon-location:before, i.ph-spade.icon-add:before, i.ph-spade.icon-left:before, i.ph-spade.icon-dna:before, i.ph-spade.icon-delete:before, i.ph-spade.icon-edit:before, i.ph-spade.icon-info:before {
  content: "\e448";
}

.ph.ph-sparkle:before, i.ph-sparkle.icon-dead:before, i.ph-sparkle.icon-alive:before, i.ph-sparkle.icon-warn:before, i.ph-sparkle.icon-sign-out:before, i.ph-sparkle.icon-substratum:before, i.ph-sparkle.icon-environment:before, i.ph-sparkle.icon-user:before, i.ph-sparkle.icon-person:before, i.ph-sparkle.icon-terrain:before, i.ph-sparkle.icon-taxon:before, i.ph-sparkle.icon-unknown:before, i.ph-sparkle.icon-nothing:before, i.ph-sparkle.icon-sampling:before, i.ph-sparkle.icon-isolation:before, i.ph-sparkle.icon-strain:before, i.ph-sparkle.icon-gps:before, i.ph-sparkle.icon-location:before, i.ph-sparkle.icon-add:before, i.ph-sparkle.icon-left:before, i.ph-sparkle.icon-dna:before, i.ph-sparkle.icon-delete:before, i.ph-sparkle.icon-edit:before, i.ph-sparkle.icon-info:before {
  content: "\e6a2";
}

.ph.ph-speaker-hifi:before, i.ph-speaker-hifi.icon-dead:before, i.ph-speaker-hifi.icon-alive:before, i.ph-speaker-hifi.icon-warn:before, i.ph-speaker-hifi.icon-sign-out:before, i.ph-speaker-hifi.icon-substratum:before, i.ph-speaker-hifi.icon-environment:before, i.ph-speaker-hifi.icon-user:before, i.ph-speaker-hifi.icon-person:before, i.ph-speaker-hifi.icon-terrain:before, i.ph-speaker-hifi.icon-taxon:before, i.ph-speaker-hifi.icon-unknown:before, i.ph-speaker-hifi.icon-nothing:before, i.ph-speaker-hifi.icon-sampling:before, i.ph-speaker-hifi.icon-isolation:before, i.ph-speaker-hifi.icon-strain:before, i.ph-speaker-hifi.icon-gps:before, i.ph-speaker-hifi.icon-location:before, i.ph-speaker-hifi.icon-add:before, i.ph-speaker-hifi.icon-left:before, i.ph-speaker-hifi.icon-dna:before, i.ph-speaker-hifi.icon-delete:before, i.ph-speaker-hifi.icon-edit:before, i.ph-speaker-hifi.icon-info:before {
  content: "\ea08";
}

.ph.ph-speaker-high:before, i.ph-speaker-high.icon-dead:before, i.ph-speaker-high.icon-alive:before, i.ph-speaker-high.icon-warn:before, i.ph-speaker-high.icon-sign-out:before, i.ph-speaker-high.icon-substratum:before, i.ph-speaker-high.icon-environment:before, i.ph-speaker-high.icon-user:before, i.ph-speaker-high.icon-person:before, i.ph-speaker-high.icon-terrain:before, i.ph-speaker-high.icon-taxon:before, i.ph-speaker-high.icon-unknown:before, i.ph-speaker-high.icon-nothing:before, i.ph-speaker-high.icon-sampling:before, i.ph-speaker-high.icon-isolation:before, i.ph-speaker-high.icon-strain:before, i.ph-speaker-high.icon-gps:before, i.ph-speaker-high.icon-location:before, i.ph-speaker-high.icon-add:before, i.ph-speaker-high.icon-left:before, i.ph-speaker-high.icon-dna:before, i.ph-speaker-high.icon-delete:before, i.ph-speaker-high.icon-edit:before, i.ph-speaker-high.icon-info:before {
  content: "\e44a";
}

.ph.ph-speaker-low:before, i.ph-speaker-low.icon-dead:before, i.ph-speaker-low.icon-alive:before, i.ph-speaker-low.icon-warn:before, i.ph-speaker-low.icon-sign-out:before, i.ph-speaker-low.icon-substratum:before, i.ph-speaker-low.icon-environment:before, i.ph-speaker-low.icon-user:before, i.ph-speaker-low.icon-person:before, i.ph-speaker-low.icon-terrain:before, i.ph-speaker-low.icon-taxon:before, i.ph-speaker-low.icon-unknown:before, i.ph-speaker-low.icon-nothing:before, i.ph-speaker-low.icon-sampling:before, i.ph-speaker-low.icon-isolation:before, i.ph-speaker-low.icon-strain:before, i.ph-speaker-low.icon-gps:before, i.ph-speaker-low.icon-location:before, i.ph-speaker-low.icon-add:before, i.ph-speaker-low.icon-left:before, i.ph-speaker-low.icon-dna:before, i.ph-speaker-low.icon-delete:before, i.ph-speaker-low.icon-edit:before, i.ph-speaker-low.icon-info:before {
  content: "\e44c";
}

.ph.ph-speaker-none:before, i.ph-speaker-none.icon-dead:before, i.ph-speaker-none.icon-alive:before, i.ph-speaker-none.icon-warn:before, i.ph-speaker-none.icon-sign-out:before, i.ph-speaker-none.icon-substratum:before, i.ph-speaker-none.icon-environment:before, i.ph-speaker-none.icon-user:before, i.ph-speaker-none.icon-person:before, i.ph-speaker-none.icon-terrain:before, i.ph-speaker-none.icon-taxon:before, i.ph-speaker-none.icon-unknown:before, i.ph-speaker-none.icon-nothing:before, i.ph-speaker-none.icon-sampling:before, i.ph-speaker-none.icon-isolation:before, i.ph-speaker-none.icon-strain:before, i.ph-speaker-none.icon-gps:before, i.ph-speaker-none.icon-location:before, i.ph-speaker-none.icon-add:before, i.ph-speaker-none.icon-left:before, i.ph-speaker-none.icon-dna:before, i.ph-speaker-none.icon-delete:before, i.ph-speaker-none.icon-edit:before, i.ph-speaker-none.icon-info:before {
  content: "\e44e";
}

.ph.ph-speaker-simple-high:before, i.ph-speaker-simple-high.icon-dead:before, i.ph-speaker-simple-high.icon-alive:before, i.ph-speaker-simple-high.icon-warn:before, i.ph-speaker-simple-high.icon-sign-out:before, i.ph-speaker-simple-high.icon-substratum:before, i.ph-speaker-simple-high.icon-environment:before, i.ph-speaker-simple-high.icon-user:before, i.ph-speaker-simple-high.icon-person:before, i.ph-speaker-simple-high.icon-terrain:before, i.ph-speaker-simple-high.icon-taxon:before, i.ph-speaker-simple-high.icon-unknown:before, i.ph-speaker-simple-high.icon-nothing:before, i.ph-speaker-simple-high.icon-sampling:before, i.ph-speaker-simple-high.icon-isolation:before, i.ph-speaker-simple-high.icon-strain:before, i.ph-speaker-simple-high.icon-gps:before, i.ph-speaker-simple-high.icon-location:before, i.ph-speaker-simple-high.icon-add:before, i.ph-speaker-simple-high.icon-left:before, i.ph-speaker-simple-high.icon-dna:before, i.ph-speaker-simple-high.icon-delete:before, i.ph-speaker-simple-high.icon-edit:before, i.ph-speaker-simple-high.icon-info:before {
  content: "\e450";
}

.ph.ph-speaker-simple-low:before, i.ph-speaker-simple-low.icon-dead:before, i.ph-speaker-simple-low.icon-alive:before, i.ph-speaker-simple-low.icon-warn:before, i.ph-speaker-simple-low.icon-sign-out:before, i.ph-speaker-simple-low.icon-substratum:before, i.ph-speaker-simple-low.icon-environment:before, i.ph-speaker-simple-low.icon-user:before, i.ph-speaker-simple-low.icon-person:before, i.ph-speaker-simple-low.icon-terrain:before, i.ph-speaker-simple-low.icon-taxon:before, i.ph-speaker-simple-low.icon-unknown:before, i.ph-speaker-simple-low.icon-nothing:before, i.ph-speaker-simple-low.icon-sampling:before, i.ph-speaker-simple-low.icon-isolation:before, i.ph-speaker-simple-low.icon-strain:before, i.ph-speaker-simple-low.icon-gps:before, i.ph-speaker-simple-low.icon-location:before, i.ph-speaker-simple-low.icon-add:before, i.ph-speaker-simple-low.icon-left:before, i.ph-speaker-simple-low.icon-dna:before, i.ph-speaker-simple-low.icon-delete:before, i.ph-speaker-simple-low.icon-edit:before, i.ph-speaker-simple-low.icon-info:before {
  content: "\e452";
}

.ph.ph-speaker-simple-none:before, i.ph-speaker-simple-none.icon-dead:before, i.ph-speaker-simple-none.icon-alive:before, i.ph-speaker-simple-none.icon-warn:before, i.ph-speaker-simple-none.icon-sign-out:before, i.ph-speaker-simple-none.icon-substratum:before, i.ph-speaker-simple-none.icon-environment:before, i.ph-speaker-simple-none.icon-user:before, i.ph-speaker-simple-none.icon-person:before, i.ph-speaker-simple-none.icon-terrain:before, i.ph-speaker-simple-none.icon-taxon:before, i.ph-speaker-simple-none.icon-unknown:before, i.ph-speaker-simple-none.icon-nothing:before, i.ph-speaker-simple-none.icon-sampling:before, i.ph-speaker-simple-none.icon-isolation:before, i.ph-speaker-simple-none.icon-strain:before, i.ph-speaker-simple-none.icon-gps:before, i.ph-speaker-simple-none.icon-location:before, i.ph-speaker-simple-none.icon-add:before, i.ph-speaker-simple-none.icon-left:before, i.ph-speaker-simple-none.icon-dna:before, i.ph-speaker-simple-none.icon-delete:before, i.ph-speaker-simple-none.icon-edit:before, i.ph-speaker-simple-none.icon-info:before {
  content: "\e454";
}

.ph.ph-speaker-simple-slash:before, i.ph-speaker-simple-slash.icon-dead:before, i.ph-speaker-simple-slash.icon-alive:before, i.ph-speaker-simple-slash.icon-warn:before, i.ph-speaker-simple-slash.icon-sign-out:before, i.ph-speaker-simple-slash.icon-substratum:before, i.ph-speaker-simple-slash.icon-environment:before, i.ph-speaker-simple-slash.icon-user:before, i.ph-speaker-simple-slash.icon-person:before, i.ph-speaker-simple-slash.icon-terrain:before, i.ph-speaker-simple-slash.icon-taxon:before, i.ph-speaker-simple-slash.icon-unknown:before, i.ph-speaker-simple-slash.icon-nothing:before, i.ph-speaker-simple-slash.icon-sampling:before, i.ph-speaker-simple-slash.icon-isolation:before, i.ph-speaker-simple-slash.icon-strain:before, i.ph-speaker-simple-slash.icon-gps:before, i.ph-speaker-simple-slash.icon-location:before, i.ph-speaker-simple-slash.icon-add:before, i.ph-speaker-simple-slash.icon-left:before, i.ph-speaker-simple-slash.icon-dna:before, i.ph-speaker-simple-slash.icon-delete:before, i.ph-speaker-simple-slash.icon-edit:before, i.ph-speaker-simple-slash.icon-info:before {
  content: "\e456";
}

.ph.ph-speaker-simple-x:before, i.ph-speaker-simple-x.icon-dead:before, i.ph-speaker-simple-x.icon-alive:before, i.ph-speaker-simple-x.icon-warn:before, i.ph-speaker-simple-x.icon-sign-out:before, i.ph-speaker-simple-x.icon-substratum:before, i.ph-speaker-simple-x.icon-environment:before, i.ph-speaker-simple-x.icon-user:before, i.ph-speaker-simple-x.icon-person:before, i.ph-speaker-simple-x.icon-terrain:before, i.ph-speaker-simple-x.icon-taxon:before, i.ph-speaker-simple-x.icon-unknown:before, i.ph-speaker-simple-x.icon-nothing:before, i.ph-speaker-simple-x.icon-sampling:before, i.ph-speaker-simple-x.icon-isolation:before, i.ph-speaker-simple-x.icon-strain:before, i.ph-speaker-simple-x.icon-gps:before, i.ph-speaker-simple-x.icon-location:before, i.ph-speaker-simple-x.icon-add:before, i.ph-speaker-simple-x.icon-left:before, i.ph-speaker-simple-x.icon-dna:before, i.ph-speaker-simple-x.icon-delete:before, i.ph-speaker-simple-x.icon-edit:before, i.ph-speaker-simple-x.icon-info:before {
  content: "\e458";
}

.ph.ph-speaker-slash:before, i.ph-speaker-slash.icon-dead:before, i.ph-speaker-slash.icon-alive:before, i.ph-speaker-slash.icon-warn:before, i.ph-speaker-slash.icon-sign-out:before, i.ph-speaker-slash.icon-substratum:before, i.ph-speaker-slash.icon-environment:before, i.ph-speaker-slash.icon-user:before, i.ph-speaker-slash.icon-person:before, i.ph-speaker-slash.icon-terrain:before, i.ph-speaker-slash.icon-taxon:before, i.ph-speaker-slash.icon-unknown:before, i.ph-speaker-slash.icon-nothing:before, i.ph-speaker-slash.icon-sampling:before, i.ph-speaker-slash.icon-isolation:before, i.ph-speaker-slash.icon-strain:before, i.ph-speaker-slash.icon-gps:before, i.ph-speaker-slash.icon-location:before, i.ph-speaker-slash.icon-add:before, i.ph-speaker-slash.icon-left:before, i.ph-speaker-slash.icon-dna:before, i.ph-speaker-slash.icon-delete:before, i.ph-speaker-slash.icon-edit:before, i.ph-speaker-slash.icon-info:before {
  content: "\e45a";
}

.ph.ph-speaker-x:before, i.ph-speaker-x.icon-dead:before, i.ph-speaker-x.icon-alive:before, i.ph-speaker-x.icon-warn:before, i.ph-speaker-x.icon-sign-out:before, i.ph-speaker-x.icon-substratum:before, i.ph-speaker-x.icon-environment:before, i.ph-speaker-x.icon-user:before, i.ph-speaker-x.icon-person:before, i.ph-speaker-x.icon-terrain:before, i.ph-speaker-x.icon-taxon:before, i.ph-speaker-x.icon-unknown:before, i.ph-speaker-x.icon-nothing:before, i.ph-speaker-x.icon-sampling:before, i.ph-speaker-x.icon-isolation:before, i.ph-speaker-x.icon-strain:before, i.ph-speaker-x.icon-gps:before, i.ph-speaker-x.icon-location:before, i.ph-speaker-x.icon-add:before, i.ph-speaker-x.icon-left:before, i.ph-speaker-x.icon-dna:before, i.ph-speaker-x.icon-delete:before, i.ph-speaker-x.icon-edit:before, i.ph-speaker-x.icon-info:before {
  content: "\e45c";
}

.ph.ph-speedometer:before, i.ph-speedometer.icon-dead:before, i.ph-speedometer.icon-alive:before, i.ph-speedometer.icon-warn:before, i.ph-speedometer.icon-sign-out:before, i.ph-speedometer.icon-substratum:before, i.ph-speedometer.icon-environment:before, i.ph-speedometer.icon-user:before, i.ph-speedometer.icon-person:before, i.ph-speedometer.icon-terrain:before, i.ph-speedometer.icon-taxon:before, i.ph-speedometer.icon-unknown:before, i.ph-speedometer.icon-nothing:before, i.ph-speedometer.icon-sampling:before, i.ph-speedometer.icon-isolation:before, i.ph-speedometer.icon-strain:before, i.ph-speedometer.icon-gps:before, i.ph-speedometer.icon-location:before, i.ph-speedometer.icon-add:before, i.ph-speedometer.icon-left:before, i.ph-speedometer.icon-dna:before, i.ph-speedometer.icon-delete:before, i.ph-speedometer.icon-edit:before, i.ph-speedometer.icon-info:before {
  content: "\ee74";
}

.ph.ph-sphere:before, i.ph-sphere.icon-dead:before, i.ph-sphere.icon-alive:before, i.ph-sphere.icon-warn:before, i.ph-sphere.icon-sign-out:before, i.ph-sphere.icon-substratum:before, i.ph-sphere.icon-environment:before, i.ph-sphere.icon-user:before, i.ph-sphere.icon-person:before, i.ph-sphere.icon-terrain:before, i.ph-sphere.icon-taxon:before, i.ph-sphere.icon-unknown:before, i.ph-sphere.icon-nothing:before, i.ph-sphere.icon-sampling:before, i.ph-sphere.icon-isolation:before, i.ph-sphere.icon-strain:before, i.ph-sphere.icon-gps:before, i.ph-sphere.icon-location:before, i.ph-sphere.icon-add:before, i.ph-sphere.icon-left:before, i.ph-sphere.icon-dna:before, i.ph-sphere.icon-delete:before, i.ph-sphere.icon-edit:before, i.ph-sphere.icon-info:before {
  content: "\ee66";
}

.ph.ph-spinner:before, i.ph-spinner.icon-dead:before, i.ph-spinner.icon-alive:before, i.ph-spinner.icon-warn:before, i.ph-spinner.icon-sign-out:before, i.ph-spinner.icon-substratum:before, i.ph-spinner.icon-environment:before, i.ph-spinner.icon-user:before, i.ph-spinner.icon-person:before, i.ph-spinner.icon-terrain:before, i.ph-spinner.icon-taxon:before, i.ph-spinner.icon-unknown:before, i.ph-spinner.icon-nothing:before, i.ph-spinner.icon-sampling:before, i.ph-spinner.icon-isolation:before, i.ph-spinner.icon-strain:before, i.ph-spinner.icon-gps:before, i.ph-spinner.icon-location:before, i.ph-spinner.icon-add:before, i.ph-spinner.icon-left:before, i.ph-spinner.icon-dna:before, i.ph-spinner.icon-delete:before, i.ph-spinner.icon-edit:before, i.ph-spinner.icon-info:before {
  content: "\e66a";
}

.ph.ph-spinner-ball:before, i.ph-spinner-ball.icon-dead:before, i.ph-spinner-ball.icon-alive:before, i.ph-spinner-ball.icon-warn:before, i.ph-spinner-ball.icon-sign-out:before, i.ph-spinner-ball.icon-substratum:before, i.ph-spinner-ball.icon-environment:before, i.ph-spinner-ball.icon-user:before, i.ph-spinner-ball.icon-person:before, i.ph-spinner-ball.icon-terrain:before, i.ph-spinner-ball.icon-taxon:before, i.ph-spinner-ball.icon-unknown:before, i.ph-spinner-ball.icon-nothing:before, i.ph-spinner-ball.icon-sampling:before, i.ph-spinner-ball.icon-isolation:before, i.ph-spinner-ball.icon-strain:before, i.ph-spinner-ball.icon-gps:before, i.ph-spinner-ball.icon-location:before, i.ph-spinner-ball.icon-add:before, i.ph-spinner-ball.icon-left:before, i.ph-spinner-ball.icon-dna:before, i.ph-spinner-ball.icon-delete:before, i.ph-spinner-ball.icon-edit:before, i.ph-spinner-ball.icon-info:before {
  content: "\ee28";
}

.ph.ph-spinner-gap:before, i.ph-spinner-gap.icon-dead:before, i.ph-spinner-gap.icon-alive:before, i.ph-spinner-gap.icon-warn:before, i.ph-spinner-gap.icon-sign-out:before, i.ph-spinner-gap.icon-substratum:before, i.ph-spinner-gap.icon-environment:before, i.ph-spinner-gap.icon-user:before, i.ph-spinner-gap.icon-person:before, i.ph-spinner-gap.icon-terrain:before, i.ph-spinner-gap.icon-taxon:before, i.ph-spinner-gap.icon-unknown:before, i.ph-spinner-gap.icon-nothing:before, i.ph-spinner-gap.icon-sampling:before, i.ph-spinner-gap.icon-isolation:before, i.ph-spinner-gap.icon-strain:before, i.ph-spinner-gap.icon-gps:before, i.ph-spinner-gap.icon-location:before, i.ph-spinner-gap.icon-add:before, i.ph-spinner-gap.icon-left:before, i.ph-spinner-gap.icon-dna:before, i.ph-spinner-gap.icon-delete:before, i.ph-spinner-gap.icon-edit:before, i.ph-spinner-gap.icon-info:before {
  content: "\e66c";
}

.ph.ph-spiral:before, i.ph-spiral.icon-dead:before, i.ph-spiral.icon-alive:before, i.ph-spiral.icon-warn:before, i.ph-spiral.icon-sign-out:before, i.ph-spiral.icon-substratum:before, i.ph-spiral.icon-environment:before, i.ph-spiral.icon-user:before, i.ph-spiral.icon-person:before, i.ph-spiral.icon-terrain:before, i.ph-spiral.icon-taxon:before, i.ph-spiral.icon-unknown:before, i.ph-spiral.icon-nothing:before, i.ph-spiral.icon-sampling:before, i.ph-spiral.icon-isolation:before, i.ph-spiral.icon-strain:before, i.ph-spiral.icon-gps:before, i.ph-spiral.icon-location:before, i.ph-spiral.icon-add:before, i.ph-spiral.icon-left:before, i.ph-spiral.icon-dna:before, i.ph-spiral.icon-delete:before, i.ph-spiral.icon-edit:before, i.ph-spiral.icon-info:before {
  content: "\e9fa";
}

.ph.ph-split-horizontal:before, i.ph-split-horizontal.icon-dead:before, i.ph-split-horizontal.icon-alive:before, i.ph-split-horizontal.icon-warn:before, i.ph-split-horizontal.icon-sign-out:before, i.ph-split-horizontal.icon-substratum:before, i.ph-split-horizontal.icon-environment:before, i.ph-split-horizontal.icon-user:before, i.ph-split-horizontal.icon-person:before, i.ph-split-horizontal.icon-terrain:before, i.ph-split-horizontal.icon-taxon:before, i.ph-split-horizontal.icon-unknown:before, i.ph-split-horizontal.icon-nothing:before, i.ph-split-horizontal.icon-sampling:before, i.ph-split-horizontal.icon-isolation:before, i.ph-split-horizontal.icon-strain:before, i.ph-split-horizontal.icon-gps:before, i.ph-split-horizontal.icon-location:before, i.ph-split-horizontal.icon-add:before, i.ph-split-horizontal.icon-left:before, i.ph-split-horizontal.icon-dna:before, i.ph-split-horizontal.icon-delete:before, i.ph-split-horizontal.icon-edit:before, i.ph-split-horizontal.icon-info:before {
  content: "\e872";
}

.ph.ph-split-vertical:before, i.ph-split-vertical.icon-dead:before, i.ph-split-vertical.icon-alive:before, i.ph-split-vertical.icon-warn:before, i.ph-split-vertical.icon-sign-out:before, i.ph-split-vertical.icon-substratum:before, i.ph-split-vertical.icon-environment:before, i.ph-split-vertical.icon-user:before, i.ph-split-vertical.icon-person:before, i.ph-split-vertical.icon-terrain:before, i.ph-split-vertical.icon-taxon:before, i.ph-split-vertical.icon-unknown:before, i.ph-split-vertical.icon-nothing:before, i.ph-split-vertical.icon-sampling:before, i.ph-split-vertical.icon-isolation:before, i.ph-split-vertical.icon-strain:before, i.ph-split-vertical.icon-gps:before, i.ph-split-vertical.icon-location:before, i.ph-split-vertical.icon-add:before, i.ph-split-vertical.icon-left:before, i.ph-split-vertical.icon-dna:before, i.ph-split-vertical.icon-delete:before, i.ph-split-vertical.icon-edit:before, i.ph-split-vertical.icon-info:before {
  content: "\e876";
}

.ph.ph-spotify-logo:before, i.ph-spotify-logo.icon-dead:before, i.ph-spotify-logo.icon-alive:before, i.ph-spotify-logo.icon-warn:before, i.ph-spotify-logo.icon-sign-out:before, i.ph-spotify-logo.icon-substratum:before, i.ph-spotify-logo.icon-environment:before, i.ph-spotify-logo.icon-user:before, i.ph-spotify-logo.icon-person:before, i.ph-spotify-logo.icon-terrain:before, i.ph-spotify-logo.icon-taxon:before, i.ph-spotify-logo.icon-unknown:before, i.ph-spotify-logo.icon-nothing:before, i.ph-spotify-logo.icon-sampling:before, i.ph-spotify-logo.icon-isolation:before, i.ph-spotify-logo.icon-strain:before, i.ph-spotify-logo.icon-gps:before, i.ph-spotify-logo.icon-location:before, i.ph-spotify-logo.icon-add:before, i.ph-spotify-logo.icon-left:before, i.ph-spotify-logo.icon-dna:before, i.ph-spotify-logo.icon-delete:before, i.ph-spotify-logo.icon-edit:before, i.ph-spotify-logo.icon-info:before {
  content: "\e66e";
}

.ph.ph-spray-bottle:before, i.ph-spray-bottle.icon-dead:before, i.ph-spray-bottle.icon-alive:before, i.ph-spray-bottle.icon-warn:before, i.ph-spray-bottle.icon-sign-out:before, i.ph-spray-bottle.icon-substratum:before, i.ph-spray-bottle.icon-environment:before, i.ph-spray-bottle.icon-user:before, i.ph-spray-bottle.icon-person:before, i.ph-spray-bottle.icon-terrain:before, i.ph-spray-bottle.icon-taxon:before, i.ph-spray-bottle.icon-unknown:before, i.ph-spray-bottle.icon-nothing:before, i.ph-spray-bottle.icon-sampling:before, i.ph-spray-bottle.icon-isolation:before, i.ph-spray-bottle.icon-strain:before, i.ph-spray-bottle.icon-gps:before, i.ph-spray-bottle.icon-location:before, i.ph-spray-bottle.icon-add:before, i.ph-spray-bottle.icon-left:before, i.ph-spray-bottle.icon-dna:before, i.ph-spray-bottle.icon-delete:before, i.ph-spray-bottle.icon-edit:before, i.ph-spray-bottle.icon-info:before {
  content: "\e7e4";
}

.ph.ph-square:before, i.ph-square.icon-dead:before, i.ph-square.icon-alive:before, i.ph-square.icon-warn:before, i.ph-square.icon-sign-out:before, i.ph-square.icon-substratum:before, i.ph-square.icon-environment:before, i.ph-square.icon-user:before, i.ph-square.icon-person:before, i.ph-square.icon-terrain:before, i.ph-square.icon-taxon:before, i.ph-square.icon-unknown:before, i.ph-square.icon-nothing:before, i.ph-square.icon-sampling:before, i.ph-square.icon-isolation:before, i.ph-square.icon-strain:before, i.ph-square.icon-gps:before, i.ph-square.icon-location:before, i.ph-square.icon-add:before, i.ph-square.icon-left:before, i.ph-square.icon-dna:before, i.ph-square.icon-delete:before, i.ph-square.icon-edit:before, i.ph-square.icon-info:before {
  content: "\e45e";
}

.ph.ph-square-half:before, i.ph-square-half.icon-dead:before, i.ph-square-half.icon-alive:before, i.ph-square-half.icon-warn:before, i.ph-square-half.icon-sign-out:before, i.ph-square-half.icon-substratum:before, i.ph-square-half.icon-environment:before, i.ph-square-half.icon-user:before, i.ph-square-half.icon-person:before, i.ph-square-half.icon-terrain:before, i.ph-square-half.icon-taxon:before, i.ph-square-half.icon-unknown:before, i.ph-square-half.icon-nothing:before, i.ph-square-half.icon-sampling:before, i.ph-square-half.icon-isolation:before, i.ph-square-half.icon-strain:before, i.ph-square-half.icon-gps:before, i.ph-square-half.icon-location:before, i.ph-square-half.icon-add:before, i.ph-square-half.icon-left:before, i.ph-square-half.icon-dna:before, i.ph-square-half.icon-delete:before, i.ph-square-half.icon-edit:before, i.ph-square-half.icon-info:before {
  content: "\e462";
}

.ph.ph-square-half-bottom:before, i.ph-square-half-bottom.icon-dead:before, i.ph-square-half-bottom.icon-alive:before, i.ph-square-half-bottom.icon-warn:before, i.ph-square-half-bottom.icon-sign-out:before, i.ph-square-half-bottom.icon-substratum:before, i.ph-square-half-bottom.icon-environment:before, i.ph-square-half-bottom.icon-user:before, i.ph-square-half-bottom.icon-person:before, i.ph-square-half-bottom.icon-terrain:before, i.ph-square-half-bottom.icon-taxon:before, i.ph-square-half-bottom.icon-unknown:before, i.ph-square-half-bottom.icon-nothing:before, i.ph-square-half-bottom.icon-sampling:before, i.ph-square-half-bottom.icon-isolation:before, i.ph-square-half-bottom.icon-strain:before, i.ph-square-half-bottom.icon-gps:before, i.ph-square-half-bottom.icon-location:before, i.ph-square-half-bottom.icon-add:before, i.ph-square-half-bottom.icon-left:before, i.ph-square-half-bottom.icon-dna:before, i.ph-square-half-bottom.icon-delete:before, i.ph-square-half-bottom.icon-edit:before, i.ph-square-half-bottom.icon-info:before {
  content: "\eb16";
}

.ph.ph-square-logo:before, i.ph-square-logo.icon-dead:before, i.ph-square-logo.icon-alive:before, i.ph-square-logo.icon-warn:before, i.ph-square-logo.icon-sign-out:before, i.ph-square-logo.icon-substratum:before, i.ph-square-logo.icon-environment:before, i.ph-square-logo.icon-user:before, i.ph-square-logo.icon-person:before, i.ph-square-logo.icon-terrain:before, i.ph-square-logo.icon-taxon:before, i.ph-square-logo.icon-unknown:before, i.ph-square-logo.icon-nothing:before, i.ph-square-logo.icon-sampling:before, i.ph-square-logo.icon-isolation:before, i.ph-square-logo.icon-strain:before, i.ph-square-logo.icon-gps:before, i.ph-square-logo.icon-location:before, i.ph-square-logo.icon-add:before, i.ph-square-logo.icon-left:before, i.ph-square-logo.icon-dna:before, i.ph-square-logo.icon-delete:before, i.ph-square-logo.icon-edit:before, i.ph-square-logo.icon-info:before {
  content: "\e690";
}

.ph.ph-square-split-horizontal:before, i.ph-square-split-horizontal.icon-dead:before, i.ph-square-split-horizontal.icon-alive:before, i.ph-square-split-horizontal.icon-warn:before, i.ph-square-split-horizontal.icon-sign-out:before, i.ph-square-split-horizontal.icon-substratum:before, i.ph-square-split-horizontal.icon-environment:before, i.ph-square-split-horizontal.icon-user:before, i.ph-square-split-horizontal.icon-person:before, i.ph-square-split-horizontal.icon-terrain:before, i.ph-square-split-horizontal.icon-taxon:before, i.ph-square-split-horizontal.icon-unknown:before, i.ph-square-split-horizontal.icon-nothing:before, i.ph-square-split-horizontal.icon-sampling:before, i.ph-square-split-horizontal.icon-isolation:before, i.ph-square-split-horizontal.icon-strain:before, i.ph-square-split-horizontal.icon-gps:before, i.ph-square-split-horizontal.icon-location:before, i.ph-square-split-horizontal.icon-add:before, i.ph-square-split-horizontal.icon-left:before, i.ph-square-split-horizontal.icon-dna:before, i.ph-square-split-horizontal.icon-delete:before, i.ph-square-split-horizontal.icon-edit:before, i.ph-square-split-horizontal.icon-info:before {
  content: "\e870";
}

.ph.ph-square-split-vertical:before, i.ph-square-split-vertical.icon-dead:before, i.ph-square-split-vertical.icon-alive:before, i.ph-square-split-vertical.icon-warn:before, i.ph-square-split-vertical.icon-sign-out:before, i.ph-square-split-vertical.icon-substratum:before, i.ph-square-split-vertical.icon-environment:before, i.ph-square-split-vertical.icon-user:before, i.ph-square-split-vertical.icon-person:before, i.ph-square-split-vertical.icon-terrain:before, i.ph-square-split-vertical.icon-taxon:before, i.ph-square-split-vertical.icon-unknown:before, i.ph-square-split-vertical.icon-nothing:before, i.ph-square-split-vertical.icon-sampling:before, i.ph-square-split-vertical.icon-isolation:before, i.ph-square-split-vertical.icon-strain:before, i.ph-square-split-vertical.icon-gps:before, i.ph-square-split-vertical.icon-location:before, i.ph-square-split-vertical.icon-add:before, i.ph-square-split-vertical.icon-left:before, i.ph-square-split-vertical.icon-dna:before, i.ph-square-split-vertical.icon-delete:before, i.ph-square-split-vertical.icon-edit:before, i.ph-square-split-vertical.icon-info:before {
  content: "\e874";
}

.ph.ph-squares-four:before, i.ph-squares-four.icon-dead:before, i.ph-squares-four.icon-alive:before, i.ph-squares-four.icon-warn:before, i.ph-squares-four.icon-sign-out:before, i.ph-squares-four.icon-substratum:before, i.ph-squares-four.icon-environment:before, i.ph-squares-four.icon-user:before, i.ph-squares-four.icon-person:before, i.ph-squares-four.icon-terrain:before, i.ph-squares-four.icon-taxon:before, i.ph-squares-four.icon-unknown:before, i.ph-squares-four.icon-nothing:before, i.ph-squares-four.icon-sampling:before, i.ph-squares-four.icon-isolation:before, i.ph-squares-four.icon-strain:before, i.ph-squares-four.icon-gps:before, i.ph-squares-four.icon-location:before, i.ph-squares-four.icon-add:before, i.ph-squares-four.icon-left:before, i.ph-squares-four.icon-dna:before, i.ph-squares-four.icon-delete:before, i.ph-squares-four.icon-edit:before, i.ph-squares-four.icon-info:before {
  content: "\e464";
}

.ph.ph-stack:before, i.ph-stack.icon-dead:before, i.ph-stack.icon-alive:before, i.ph-stack.icon-warn:before, i.ph-stack.icon-sign-out:before, i.ph-stack.icon-substratum:before, i.ph-stack.icon-environment:before, i.ph-stack.icon-user:before, i.ph-stack.icon-person:before, i.ph-stack.icon-terrain:before, i.ph-stack.icon-taxon:before, i.ph-stack.icon-unknown:before, i.ph-stack.icon-nothing:before, i.ph-stack.icon-sampling:before, i.ph-stack.icon-isolation:before, i.ph-stack.icon-strain:before, i.ph-stack.icon-gps:before, i.ph-stack.icon-location:before, i.ph-stack.icon-add:before, i.ph-stack.icon-left:before, i.ph-stack.icon-dna:before, i.ph-stack.icon-delete:before, i.ph-stack.icon-edit:before, i.ph-stack.icon-info:before {
  content: "\e466";
}

.ph.ph-stack-minus:before, i.ph-stack-minus.icon-dead:before, i.ph-stack-minus.icon-alive:before, i.ph-stack-minus.icon-warn:before, i.ph-stack-minus.icon-sign-out:before, i.ph-stack-minus.icon-substratum:before, i.ph-stack-minus.icon-environment:before, i.ph-stack-minus.icon-user:before, i.ph-stack-minus.icon-person:before, i.ph-stack-minus.icon-terrain:before, i.ph-stack-minus.icon-taxon:before, i.ph-stack-minus.icon-unknown:before, i.ph-stack-minus.icon-nothing:before, i.ph-stack-minus.icon-sampling:before, i.ph-stack-minus.icon-isolation:before, i.ph-stack-minus.icon-strain:before, i.ph-stack-minus.icon-gps:before, i.ph-stack-minus.icon-location:before, i.ph-stack-minus.icon-add:before, i.ph-stack-minus.icon-left:before, i.ph-stack-minus.icon-dna:before, i.ph-stack-minus.icon-delete:before, i.ph-stack-minus.icon-edit:before, i.ph-stack-minus.icon-info:before {
  content: "\edf4";
}

.ph.ph-stack-overflow-logo:before, i.ph-stack-overflow-logo.icon-dead:before, i.ph-stack-overflow-logo.icon-alive:before, i.ph-stack-overflow-logo.icon-warn:before, i.ph-stack-overflow-logo.icon-sign-out:before, i.ph-stack-overflow-logo.icon-substratum:before, i.ph-stack-overflow-logo.icon-environment:before, i.ph-stack-overflow-logo.icon-user:before, i.ph-stack-overflow-logo.icon-person:before, i.ph-stack-overflow-logo.icon-terrain:before, i.ph-stack-overflow-logo.icon-taxon:before, i.ph-stack-overflow-logo.icon-unknown:before, i.ph-stack-overflow-logo.icon-nothing:before, i.ph-stack-overflow-logo.icon-sampling:before, i.ph-stack-overflow-logo.icon-isolation:before, i.ph-stack-overflow-logo.icon-strain:before, i.ph-stack-overflow-logo.icon-gps:before, i.ph-stack-overflow-logo.icon-location:before, i.ph-stack-overflow-logo.icon-add:before, i.ph-stack-overflow-logo.icon-left:before, i.ph-stack-overflow-logo.icon-dna:before, i.ph-stack-overflow-logo.icon-delete:before, i.ph-stack-overflow-logo.icon-edit:before, i.ph-stack-overflow-logo.icon-info:before {
  content: "\eb78";
}

.ph.ph-stack-plus:before, i.ph-stack-plus.icon-dead:before, i.ph-stack-plus.icon-alive:before, i.ph-stack-plus.icon-warn:before, i.ph-stack-plus.icon-sign-out:before, i.ph-stack-plus.icon-substratum:before, i.ph-stack-plus.icon-environment:before, i.ph-stack-plus.icon-user:before, i.ph-stack-plus.icon-person:before, i.ph-stack-plus.icon-terrain:before, i.ph-stack-plus.icon-taxon:before, i.ph-stack-plus.icon-unknown:before, i.ph-stack-plus.icon-nothing:before, i.ph-stack-plus.icon-sampling:before, i.ph-stack-plus.icon-isolation:before, i.ph-stack-plus.icon-strain:before, i.ph-stack-plus.icon-gps:before, i.ph-stack-plus.icon-location:before, i.ph-stack-plus.icon-add:before, i.ph-stack-plus.icon-left:before, i.ph-stack-plus.icon-dna:before, i.ph-stack-plus.icon-delete:before, i.ph-stack-plus.icon-edit:before, i.ph-stack-plus.icon-info:before {
  content: "\edf6";
}

.ph.ph-stack-simple:before, i.ph-stack-simple.icon-dead:before, i.ph-stack-simple.icon-alive:before, i.ph-stack-simple.icon-warn:before, i.ph-stack-simple.icon-sign-out:before, i.icon-substratum:before, i.ph-stack-simple.icon-environment:before, i.ph-stack-simple.icon-user:before, i.ph-stack-simple.icon-person:before, i.ph-stack-simple.icon-terrain:before, i.ph-stack-simple.icon-taxon:before, i.ph-stack-simple.icon-unknown:before, i.ph-stack-simple.icon-nothing:before, i.ph-stack-simple.icon-sampling:before, i.ph-stack-simple.icon-isolation:before, i.ph-stack-simple.icon-strain:before, i.ph-stack-simple.icon-gps:before, i.ph-stack-simple.icon-location:before, i.ph-stack-simple.icon-add:before, i.ph-stack-simple.icon-left:before, i.ph-stack-simple.icon-dna:before, i.ph-stack-simple.icon-delete:before, i.ph-stack-simple.icon-edit:before, i.ph-stack-simple.icon-info:before {
  content: "\e468";
}

.ph.ph-stairs:before, i.ph-stairs.icon-dead:before, i.ph-stairs.icon-alive:before, i.ph-stairs.icon-warn:before, i.ph-stairs.icon-sign-out:before, i.ph-stairs.icon-substratum:before, i.ph-stairs.icon-environment:before, i.ph-stairs.icon-user:before, i.ph-stairs.icon-person:before, i.ph-stairs.icon-terrain:before, i.ph-stairs.icon-taxon:before, i.ph-stairs.icon-unknown:before, i.ph-stairs.icon-nothing:before, i.ph-stairs.icon-sampling:before, i.ph-stairs.icon-isolation:before, i.ph-stairs.icon-strain:before, i.ph-stairs.icon-gps:before, i.ph-stairs.icon-location:before, i.ph-stairs.icon-add:before, i.ph-stairs.icon-left:before, i.ph-stairs.icon-dna:before, i.ph-stairs.icon-delete:before, i.ph-stairs.icon-edit:before, i.ph-stairs.icon-info:before {
  content: "\e8ec";
}

.ph.ph-stamp:before, i.ph-stamp.icon-dead:before, i.ph-stamp.icon-alive:before, i.ph-stamp.icon-warn:before, i.ph-stamp.icon-sign-out:before, i.ph-stamp.icon-substratum:before, i.ph-stamp.icon-environment:before, i.ph-stamp.icon-user:before, i.ph-stamp.icon-person:before, i.ph-stamp.icon-terrain:before, i.ph-stamp.icon-taxon:before, i.ph-stamp.icon-unknown:before, i.ph-stamp.icon-nothing:before, i.ph-stamp.icon-sampling:before, i.ph-stamp.icon-isolation:before, i.ph-stamp.icon-strain:before, i.ph-stamp.icon-gps:before, i.ph-stamp.icon-location:before, i.ph-stamp.icon-add:before, i.ph-stamp.icon-left:before, i.ph-stamp.icon-dna:before, i.ph-stamp.icon-delete:before, i.ph-stamp.icon-edit:before, i.ph-stamp.icon-info:before {
  content: "\ea48";
}

.ph.ph-standard-definition:before, i.ph-standard-definition.icon-dead:before, i.ph-standard-definition.icon-alive:before, i.ph-standard-definition.icon-warn:before, i.ph-standard-definition.icon-sign-out:before, i.ph-standard-definition.icon-substratum:before, i.ph-standard-definition.icon-environment:before, i.ph-standard-definition.icon-user:before, i.ph-standard-definition.icon-person:before, i.ph-standard-definition.icon-terrain:before, i.ph-standard-definition.icon-taxon:before, i.ph-standard-definition.icon-unknown:before, i.ph-standard-definition.icon-nothing:before, i.ph-standard-definition.icon-sampling:before, i.ph-standard-definition.icon-isolation:before, i.ph-standard-definition.icon-strain:before, i.ph-standard-definition.icon-gps:before, i.ph-standard-definition.icon-location:before, i.ph-standard-definition.icon-add:before, i.ph-standard-definition.icon-left:before, i.ph-standard-definition.icon-dna:before, i.ph-standard-definition.icon-delete:before, i.ph-standard-definition.icon-edit:before, i.ph-standard-definition.icon-info:before {
  content: "\ea90";
}

.ph.ph-star:before, i.ph-star.icon-dead:before, i.ph-star.icon-alive:before, i.ph-star.icon-warn:before, i.ph-star.icon-sign-out:before, i.ph-star.icon-substratum:before, i.ph-star.icon-environment:before, i.ph-star.icon-user:before, i.ph-star.icon-person:before, i.ph-star.icon-terrain:before, i.ph-star.icon-taxon:before, i.ph-star.icon-unknown:before, i.ph-star.icon-nothing:before, i.ph-star.icon-sampling:before, i.ph-star.icon-isolation:before, i.ph-star.icon-strain:before, i.ph-star.icon-gps:before, i.ph-star.icon-location:before, i.ph-star.icon-add:before, i.ph-star.icon-left:before, i.ph-star.icon-dna:before, i.ph-star.icon-delete:before, i.ph-star.icon-edit:before, i.ph-star.icon-info:before {
  content: "\e46a";
}

.ph.ph-star-and-crescent:before, i.ph-star-and-crescent.icon-dead:before, i.ph-star-and-crescent.icon-alive:before, i.ph-star-and-crescent.icon-warn:before, i.ph-star-and-crescent.icon-sign-out:before, i.ph-star-and-crescent.icon-substratum:before, i.ph-star-and-crescent.icon-environment:before, i.ph-star-and-crescent.icon-user:before, i.ph-star-and-crescent.icon-person:before, i.ph-star-and-crescent.icon-terrain:before, i.ph-star-and-crescent.icon-taxon:before, i.ph-star-and-crescent.icon-unknown:before, i.ph-star-and-crescent.icon-nothing:before, i.ph-star-and-crescent.icon-sampling:before, i.ph-star-and-crescent.icon-isolation:before, i.ph-star-and-crescent.icon-strain:before, i.ph-star-and-crescent.icon-gps:before, i.ph-star-and-crescent.icon-location:before, i.ph-star-and-crescent.icon-add:before, i.ph-star-and-crescent.icon-left:before, i.ph-star-and-crescent.icon-dna:before, i.ph-star-and-crescent.icon-delete:before, i.ph-star-and-crescent.icon-edit:before, i.ph-star-and-crescent.icon-info:before {
  content: "\ecf4";
}

.ph.ph-star-four:before, i.ph-star-four.icon-dead:before, i.ph-star-four.icon-alive:before, i.ph-star-four.icon-warn:before, i.ph-star-four.icon-sign-out:before, i.ph-star-four.icon-substratum:before, i.ph-star-four.icon-environment:before, i.ph-star-four.icon-user:before, i.ph-star-four.icon-person:before, i.ph-star-four.icon-terrain:before, i.ph-star-four.icon-taxon:before, i.ph-star-four.icon-unknown:before, i.ph-star-four.icon-nothing:before, i.ph-star-four.icon-sampling:before, i.ph-star-four.icon-isolation:before, i.ph-star-four.icon-strain:before, i.ph-star-four.icon-gps:before, i.ph-star-four.icon-location:before, i.ph-star-four.icon-add:before, i.ph-star-four.icon-left:before, i.ph-star-four.icon-dna:before, i.ph-star-four.icon-delete:before, i.ph-star-four.icon-edit:before, i.ph-star-four.icon-info:before {
  content: "\e6a4";
}

.ph.ph-star-half:before, i.ph-star-half.icon-dead:before, i.ph-star-half.icon-alive:before, i.ph-star-half.icon-warn:before, i.ph-star-half.icon-sign-out:before, i.ph-star-half.icon-substratum:before, i.ph-star-half.icon-environment:before, i.ph-star-half.icon-user:before, i.ph-star-half.icon-person:before, i.ph-star-half.icon-terrain:before, i.ph-star-half.icon-taxon:before, i.ph-star-half.icon-unknown:before, i.ph-star-half.icon-nothing:before, i.ph-star-half.icon-sampling:before, i.ph-star-half.icon-isolation:before, i.ph-star-half.icon-strain:before, i.ph-star-half.icon-gps:before, i.ph-star-half.icon-location:before, i.ph-star-half.icon-add:before, i.ph-star-half.icon-left:before, i.ph-star-half.icon-dna:before, i.ph-star-half.icon-delete:before, i.ph-star-half.icon-edit:before, i.ph-star-half.icon-info:before {
  content: "\e70a";
}

.ph.ph-star-of-david:before, i.ph-star-of-david.icon-dead:before, i.ph-star-of-david.icon-alive:before, i.ph-star-of-david.icon-warn:before, i.ph-star-of-david.icon-sign-out:before, i.ph-star-of-david.icon-substratum:before, i.ph-star-of-david.icon-environment:before, i.ph-star-of-david.icon-user:before, i.ph-star-of-david.icon-person:before, i.ph-star-of-david.icon-terrain:before, i.ph-star-of-david.icon-taxon:before, i.ph-star-of-david.icon-unknown:before, i.ph-star-of-david.icon-nothing:before, i.ph-star-of-david.icon-sampling:before, i.ph-star-of-david.icon-isolation:before, i.ph-star-of-david.icon-strain:before, i.ph-star-of-david.icon-gps:before, i.ph-star-of-david.icon-location:before, i.ph-star-of-david.icon-add:before, i.ph-star-of-david.icon-left:before, i.ph-star-of-david.icon-dna:before, i.ph-star-of-david.icon-delete:before, i.ph-star-of-david.icon-edit:before, i.ph-star-of-david.icon-info:before {
  content: "\e89e";
}

.ph.ph-steam-logo:before, i.ph-steam-logo.icon-dead:before, i.ph-steam-logo.icon-alive:before, i.ph-steam-logo.icon-warn:before, i.ph-steam-logo.icon-sign-out:before, i.ph-steam-logo.icon-substratum:before, i.ph-steam-logo.icon-environment:before, i.ph-steam-logo.icon-user:before, i.ph-steam-logo.icon-person:before, i.ph-steam-logo.icon-terrain:before, i.ph-steam-logo.icon-taxon:before, i.ph-steam-logo.icon-unknown:before, i.ph-steam-logo.icon-nothing:before, i.ph-steam-logo.icon-sampling:before, i.ph-steam-logo.icon-isolation:before, i.ph-steam-logo.icon-strain:before, i.ph-steam-logo.icon-gps:before, i.ph-steam-logo.icon-location:before, i.ph-steam-logo.icon-add:before, i.ph-steam-logo.icon-left:before, i.ph-steam-logo.icon-dna:before, i.ph-steam-logo.icon-delete:before, i.ph-steam-logo.icon-edit:before, i.ph-steam-logo.icon-info:before {
  content: "\ead4";
}

.ph.ph-steering-wheel:before, i.ph-steering-wheel.icon-dead:before, i.ph-steering-wheel.icon-alive:before, i.ph-steering-wheel.icon-warn:before, i.ph-steering-wheel.icon-sign-out:before, i.ph-steering-wheel.icon-substratum:before, i.ph-steering-wheel.icon-environment:before, i.ph-steering-wheel.icon-user:before, i.ph-steering-wheel.icon-person:before, i.ph-steering-wheel.icon-terrain:before, i.ph-steering-wheel.icon-taxon:before, i.ph-steering-wheel.icon-unknown:before, i.ph-steering-wheel.icon-nothing:before, i.ph-steering-wheel.icon-sampling:before, i.ph-steering-wheel.icon-isolation:before, i.ph-steering-wheel.icon-strain:before, i.ph-steering-wheel.icon-gps:before, i.ph-steering-wheel.icon-location:before, i.ph-steering-wheel.icon-add:before, i.ph-steering-wheel.icon-left:before, i.ph-steering-wheel.icon-dna:before, i.ph-steering-wheel.icon-delete:before, i.ph-steering-wheel.icon-edit:before, i.ph-steering-wheel.icon-info:before {
  content: "\e9ac";
}

.ph.ph-steps:before, i.ph-steps.icon-dead:before, i.ph-steps.icon-alive:before, i.ph-steps.icon-warn:before, i.ph-steps.icon-sign-out:before, i.ph-steps.icon-substratum:before, i.ph-steps.icon-environment:before, i.ph-steps.icon-user:before, i.ph-steps.icon-person:before, i.ph-steps.icon-terrain:before, i.ph-steps.icon-taxon:before, i.ph-steps.icon-unknown:before, i.ph-steps.icon-nothing:before, i.ph-steps.icon-sampling:before, i.ph-steps.icon-isolation:before, i.ph-steps.icon-strain:before, i.ph-steps.icon-gps:before, i.ph-steps.icon-location:before, i.ph-steps.icon-add:before, i.ph-steps.icon-left:before, i.ph-steps.icon-dna:before, i.ph-steps.icon-delete:before, i.ph-steps.icon-edit:before, i.ph-steps.icon-info:before {
  content: "\ecbe";
}

.ph.ph-stethoscope:before, i.ph-stethoscope.icon-dead:before, i.ph-stethoscope.icon-alive:before, i.ph-stethoscope.icon-warn:before, i.ph-stethoscope.icon-sign-out:before, i.ph-stethoscope.icon-substratum:before, i.ph-stethoscope.icon-environment:before, i.ph-stethoscope.icon-user:before, i.ph-stethoscope.icon-person:before, i.ph-stethoscope.icon-terrain:before, i.ph-stethoscope.icon-taxon:before, i.ph-stethoscope.icon-unknown:before, i.ph-stethoscope.icon-nothing:before, i.ph-stethoscope.icon-sampling:before, i.ph-stethoscope.icon-isolation:before, i.ph-stethoscope.icon-strain:before, i.ph-stethoscope.icon-gps:before, i.ph-stethoscope.icon-location:before, i.ph-stethoscope.icon-add:before, i.ph-stethoscope.icon-left:before, i.ph-stethoscope.icon-dna:before, i.ph-stethoscope.icon-delete:before, i.ph-stethoscope.icon-edit:before, i.ph-stethoscope.icon-info:before {
  content: "\e7ea";
}

.ph.ph-sticker:before, i.ph-sticker.icon-dead:before, i.ph-sticker.icon-alive:before, i.ph-sticker.icon-warn:before, i.ph-sticker.icon-sign-out:before, i.ph-sticker.icon-substratum:before, i.ph-sticker.icon-environment:before, i.ph-sticker.icon-user:before, i.ph-sticker.icon-person:before, i.ph-sticker.icon-terrain:before, i.ph-sticker.icon-taxon:before, i.ph-sticker.icon-unknown:before, i.ph-sticker.icon-nothing:before, i.ph-sticker.icon-sampling:before, i.ph-sticker.icon-isolation:before, i.ph-sticker.icon-strain:before, i.ph-sticker.icon-gps:before, i.ph-sticker.icon-location:before, i.ph-sticker.icon-add:before, i.ph-sticker.icon-left:before, i.ph-sticker.icon-dna:before, i.ph-sticker.icon-delete:before, i.ph-sticker.icon-edit:before, i.ph-sticker.icon-info:before {
  content: "\e5ac";
}

.ph.ph-stool:before, i.ph-stool.icon-dead:before, i.ph-stool.icon-alive:before, i.ph-stool.icon-warn:before, i.ph-stool.icon-sign-out:before, i.ph-stool.icon-substratum:before, i.ph-stool.icon-environment:before, i.ph-stool.icon-user:before, i.ph-stool.icon-person:before, i.ph-stool.icon-terrain:before, i.ph-stool.icon-taxon:before, i.ph-stool.icon-unknown:before, i.ph-stool.icon-nothing:before, i.ph-stool.icon-sampling:before, i.ph-stool.icon-isolation:before, i.ph-stool.icon-strain:before, i.ph-stool.icon-gps:before, i.ph-stool.icon-location:before, i.ph-stool.icon-add:before, i.ph-stool.icon-left:before, i.ph-stool.icon-dna:before, i.ph-stool.icon-delete:before, i.ph-stool.icon-edit:before, i.ph-stool.icon-info:before {
  content: "\ea44";
}

.ph.ph-stop:before, i.ph-stop.icon-dead:before, i.ph-stop.icon-alive:before, i.ph-stop.icon-warn:before, i.ph-stop.icon-sign-out:before, i.ph-stop.icon-substratum:before, i.ph-stop.icon-environment:before, i.ph-stop.icon-user:before, i.ph-stop.icon-person:before, i.ph-stop.icon-terrain:before, i.ph-stop.icon-taxon:before, i.ph-stop.icon-unknown:before, i.ph-stop.icon-nothing:before, i.ph-stop.icon-sampling:before, i.ph-stop.icon-isolation:before, i.ph-stop.icon-strain:before, i.ph-stop.icon-gps:before, i.ph-stop.icon-location:before, i.ph-stop.icon-add:before, i.ph-stop.icon-left:before, i.ph-stop.icon-dna:before, i.ph-stop.icon-delete:before, i.ph-stop.icon-edit:before, i.ph-stop.icon-info:before {
  content: "\e46c";
}

.ph.ph-stop-circle:before, i.ph-stop-circle.icon-dead:before, i.ph-stop-circle.icon-alive:before, i.ph-stop-circle.icon-warn:before, i.ph-stop-circle.icon-sign-out:before, i.ph-stop-circle.icon-substratum:before, i.ph-stop-circle.icon-environment:before, i.ph-stop-circle.icon-user:before, i.ph-stop-circle.icon-person:before, i.ph-stop-circle.icon-terrain:before, i.ph-stop-circle.icon-taxon:before, i.ph-stop-circle.icon-unknown:before, i.ph-stop-circle.icon-nothing:before, i.ph-stop-circle.icon-sampling:before, i.ph-stop-circle.icon-isolation:before, i.ph-stop-circle.icon-strain:before, i.ph-stop-circle.icon-gps:before, i.ph-stop-circle.icon-location:before, i.ph-stop-circle.icon-add:before, i.ph-stop-circle.icon-left:before, i.ph-stop-circle.icon-dna:before, i.ph-stop-circle.icon-delete:before, i.ph-stop-circle.icon-edit:before, i.ph-stop-circle.icon-info:before {
  content: "\e46e";
}

.ph.ph-storefront:before, i.ph-storefront.icon-dead:before, i.ph-storefront.icon-alive:before, i.ph-storefront.icon-warn:before, i.ph-storefront.icon-sign-out:before, i.ph-storefront.icon-substratum:before, i.ph-storefront.icon-environment:before, i.ph-storefront.icon-user:before, i.ph-storefront.icon-person:before, i.ph-storefront.icon-terrain:before, i.ph-storefront.icon-taxon:before, i.ph-storefront.icon-unknown:before, i.ph-storefront.icon-nothing:before, i.ph-storefront.icon-sampling:before, i.ph-storefront.icon-isolation:before, i.ph-storefront.icon-strain:before, i.ph-storefront.icon-gps:before, i.ph-storefront.icon-location:before, i.ph-storefront.icon-add:before, i.ph-storefront.icon-left:before, i.ph-storefront.icon-dna:before, i.ph-storefront.icon-delete:before, i.ph-storefront.icon-edit:before, i.ph-storefront.icon-info:before {
  content: "\e470";
}

.ph.ph-strategy:before, i.ph-strategy.icon-dead:before, i.ph-strategy.icon-alive:before, i.ph-strategy.icon-warn:before, i.ph-strategy.icon-sign-out:before, i.ph-strategy.icon-substratum:before, i.ph-strategy.icon-environment:before, i.ph-strategy.icon-user:before, i.ph-strategy.icon-person:before, i.ph-strategy.icon-terrain:before, i.ph-strategy.icon-taxon:before, i.ph-strategy.icon-unknown:before, i.ph-strategy.icon-nothing:before, i.ph-strategy.icon-sampling:before, i.ph-strategy.icon-isolation:before, i.ph-strategy.icon-strain:before, i.ph-strategy.icon-gps:before, i.ph-strategy.icon-location:before, i.ph-strategy.icon-add:before, i.ph-strategy.icon-left:before, i.ph-strategy.icon-dna:before, i.ph-strategy.icon-delete:before, i.ph-strategy.icon-edit:before, i.ph-strategy.icon-info:before {
  content: "\ea3a";
}

.ph.ph-stripe-logo:before, i.ph-stripe-logo.icon-dead:before, i.ph-stripe-logo.icon-alive:before, i.ph-stripe-logo.icon-warn:before, i.ph-stripe-logo.icon-sign-out:before, i.ph-stripe-logo.icon-substratum:before, i.ph-stripe-logo.icon-environment:before, i.ph-stripe-logo.icon-user:before, i.ph-stripe-logo.icon-person:before, i.ph-stripe-logo.icon-terrain:before, i.ph-stripe-logo.icon-taxon:before, i.ph-stripe-logo.icon-unknown:before, i.ph-stripe-logo.icon-nothing:before, i.ph-stripe-logo.icon-sampling:before, i.ph-stripe-logo.icon-isolation:before, i.ph-stripe-logo.icon-strain:before, i.ph-stripe-logo.icon-gps:before, i.ph-stripe-logo.icon-location:before, i.ph-stripe-logo.icon-add:before, i.ph-stripe-logo.icon-left:before, i.ph-stripe-logo.icon-dna:before, i.ph-stripe-logo.icon-delete:before, i.ph-stripe-logo.icon-edit:before, i.ph-stripe-logo.icon-info:before {
  content: "\e698";
}

.ph.ph-student:before, i.ph-student.icon-dead:before, i.ph-student.icon-alive:before, i.ph-student.icon-warn:before, i.ph-student.icon-sign-out:before, i.ph-student.icon-substratum:before, i.ph-student.icon-environment:before, i.ph-student.icon-user:before, i.ph-student.icon-person:before, i.ph-student.icon-terrain:before, i.ph-student.icon-taxon:before, i.ph-student.icon-unknown:before, i.ph-student.icon-nothing:before, i.ph-student.icon-sampling:before, i.ph-student.icon-isolation:before, i.ph-student.icon-strain:before, i.ph-student.icon-gps:before, i.ph-student.icon-location:before, i.ph-student.icon-add:before, i.ph-student.icon-left:before, i.ph-student.icon-dna:before, i.ph-student.icon-delete:before, i.ph-student.icon-edit:before, i.ph-student.icon-info:before {
  content: "\e73e";
}

.ph.ph-subset-of:before, i.ph-subset-of.icon-dead:before, i.ph-subset-of.icon-alive:before, i.ph-subset-of.icon-warn:before, i.ph-subset-of.icon-sign-out:before, i.ph-subset-of.icon-substratum:before, i.ph-subset-of.icon-environment:before, i.ph-subset-of.icon-user:before, i.ph-subset-of.icon-person:before, i.ph-subset-of.icon-terrain:before, i.ph-subset-of.icon-taxon:before, i.ph-subset-of.icon-unknown:before, i.ph-subset-of.icon-nothing:before, i.ph-subset-of.icon-sampling:before, i.ph-subset-of.icon-isolation:before, i.ph-subset-of.icon-strain:before, i.ph-subset-of.icon-gps:before, i.ph-subset-of.icon-location:before, i.ph-subset-of.icon-add:before, i.ph-subset-of.icon-left:before, i.ph-subset-of.icon-dna:before, i.ph-subset-of.icon-delete:before, i.ph-subset-of.icon-edit:before, i.ph-subset-of.icon-info:before {
  content: "\edc0";
}

.ph.ph-subset-proper-of:before, i.ph-subset-proper-of.icon-dead:before, i.ph-subset-proper-of.icon-alive:before, i.ph-subset-proper-of.icon-warn:before, i.ph-subset-proper-of.icon-sign-out:before, i.ph-subset-proper-of.icon-substratum:before, i.ph-subset-proper-of.icon-environment:before, i.ph-subset-proper-of.icon-user:before, i.ph-subset-proper-of.icon-person:before, i.ph-subset-proper-of.icon-terrain:before, i.ph-subset-proper-of.icon-taxon:before, i.ph-subset-proper-of.icon-unknown:before, i.ph-subset-proper-of.icon-nothing:before, i.ph-subset-proper-of.icon-sampling:before, i.ph-subset-proper-of.icon-isolation:before, i.ph-subset-proper-of.icon-strain:before, i.ph-subset-proper-of.icon-gps:before, i.ph-subset-proper-of.icon-location:before, i.ph-subset-proper-of.icon-add:before, i.ph-subset-proper-of.icon-left:before, i.ph-subset-proper-of.icon-dna:before, i.ph-subset-proper-of.icon-delete:before, i.ph-subset-proper-of.icon-edit:before, i.ph-subset-proper-of.icon-info:before {
  content: "\edb6";
}

.ph.ph-subtitles:before, i.ph-subtitles.icon-dead:before, i.ph-subtitles.icon-alive:before, i.ph-subtitles.icon-warn:before, i.ph-subtitles.icon-sign-out:before, i.ph-subtitles.icon-substratum:before, i.ph-subtitles.icon-environment:before, i.ph-subtitles.icon-user:before, i.ph-subtitles.icon-person:before, i.ph-subtitles.icon-terrain:before, i.ph-subtitles.icon-taxon:before, i.ph-subtitles.icon-unknown:before, i.ph-subtitles.icon-nothing:before, i.ph-subtitles.icon-sampling:before, i.ph-subtitles.icon-isolation:before, i.ph-subtitles.icon-strain:before, i.ph-subtitles.icon-gps:before, i.ph-subtitles.icon-location:before, i.ph-subtitles.icon-add:before, i.ph-subtitles.icon-left:before, i.ph-subtitles.icon-dna:before, i.ph-subtitles.icon-delete:before, i.ph-subtitles.icon-edit:before, i.ph-subtitles.icon-info:before {
  content: "\e1a8";
}

.ph.ph-subtitles-slash:before, i.ph-subtitles-slash.icon-dead:before, i.ph-subtitles-slash.icon-alive:before, i.ph-subtitles-slash.icon-warn:before, i.ph-subtitles-slash.icon-sign-out:before, i.ph-subtitles-slash.icon-substratum:before, i.ph-subtitles-slash.icon-environment:before, i.ph-subtitles-slash.icon-user:before, i.ph-subtitles-slash.icon-person:before, i.ph-subtitles-slash.icon-terrain:before, i.ph-subtitles-slash.icon-taxon:before, i.ph-subtitles-slash.icon-unknown:before, i.ph-subtitles-slash.icon-nothing:before, i.ph-subtitles-slash.icon-sampling:before, i.ph-subtitles-slash.icon-isolation:before, i.ph-subtitles-slash.icon-strain:before, i.ph-subtitles-slash.icon-gps:before, i.ph-subtitles-slash.icon-location:before, i.ph-subtitles-slash.icon-add:before, i.ph-subtitles-slash.icon-left:before, i.ph-subtitles-slash.icon-dna:before, i.ph-subtitles-slash.icon-delete:before, i.ph-subtitles-slash.icon-edit:before, i.ph-subtitles-slash.icon-info:before {
  content: "\e1a6";
}

.ph.ph-subtract:before, i.ph-subtract.icon-dead:before, i.ph-subtract.icon-alive:before, i.ph-subtract.icon-warn:before, i.ph-subtract.icon-sign-out:before, i.ph-subtract.icon-substratum:before, i.ph-subtract.icon-environment:before, i.ph-subtract.icon-user:before, i.ph-subtract.icon-person:before, i.ph-subtract.icon-terrain:before, i.ph-subtract.icon-taxon:before, i.ph-subtract.icon-unknown:before, i.ph-subtract.icon-nothing:before, i.ph-subtract.icon-sampling:before, i.ph-subtract.icon-isolation:before, i.ph-subtract.icon-strain:before, i.ph-subtract.icon-gps:before, i.ph-subtract.icon-location:before, i.ph-subtract.icon-add:before, i.ph-subtract.icon-left:before, i.ph-subtract.icon-dna:before, i.ph-subtract.icon-delete:before, i.ph-subtract.icon-edit:before, i.ph-subtract.icon-info:before {
  content: "\ebd6";
}

.ph.ph-subtract-square:before, i.ph-subtract-square.icon-dead:before, i.ph-subtract-square.icon-alive:before, i.ph-subtract-square.icon-warn:before, i.ph-subtract-square.icon-sign-out:before, i.ph-subtract-square.icon-substratum:before, i.ph-subtract-square.icon-environment:before, i.ph-subtract-square.icon-user:before, i.ph-subtract-square.icon-person:before, i.ph-subtract-square.icon-terrain:before, i.ph-subtract-square.icon-taxon:before, i.ph-subtract-square.icon-unknown:before, i.ph-subtract-square.icon-nothing:before, i.ph-subtract-square.icon-sampling:before, i.ph-subtract-square.icon-isolation:before, i.ph-subtract-square.icon-strain:before, i.ph-subtract-square.icon-gps:before, i.ph-subtract-square.icon-location:before, i.ph-subtract-square.icon-add:before, i.ph-subtract-square.icon-left:before, i.ph-subtract-square.icon-dna:before, i.ph-subtract-square.icon-delete:before, i.ph-subtract-square.icon-edit:before, i.ph-subtract-square.icon-info:before {
  content: "\ebd4";
}

.ph.ph-subway:before, i.ph-subway.icon-dead:before, i.ph-subway.icon-alive:before, i.ph-subway.icon-warn:before, i.ph-subway.icon-sign-out:before, i.ph-subway.icon-substratum:before, i.ph-subway.icon-environment:before, i.ph-subway.icon-user:before, i.ph-subway.icon-person:before, i.ph-subway.icon-terrain:before, i.ph-subway.icon-taxon:before, i.ph-subway.icon-unknown:before, i.ph-subway.icon-nothing:before, i.ph-subway.icon-sampling:before, i.ph-subway.icon-isolation:before, i.ph-subway.icon-strain:before, i.ph-subway.icon-gps:before, i.ph-subway.icon-location:before, i.ph-subway.icon-add:before, i.ph-subway.icon-left:before, i.ph-subway.icon-dna:before, i.ph-subway.icon-delete:before, i.ph-subway.icon-edit:before, i.ph-subway.icon-info:before {
  content: "\e498";
}

.ph.ph-suitcase:before, i.ph-suitcase.icon-dead:before, i.ph-suitcase.icon-alive:before, i.ph-suitcase.icon-warn:before, i.ph-suitcase.icon-sign-out:before, i.ph-suitcase.icon-substratum:before, i.ph-suitcase.icon-environment:before, i.ph-suitcase.icon-user:before, i.ph-suitcase.icon-person:before, i.ph-suitcase.icon-terrain:before, i.ph-suitcase.icon-taxon:before, i.ph-suitcase.icon-unknown:before, i.ph-suitcase.icon-nothing:before, i.ph-suitcase.icon-sampling:before, i.ph-suitcase.icon-isolation:before, i.ph-suitcase.icon-strain:before, i.ph-suitcase.icon-gps:before, i.ph-suitcase.icon-location:before, i.ph-suitcase.icon-add:before, i.ph-suitcase.icon-left:before, i.ph-suitcase.icon-dna:before, i.ph-suitcase.icon-delete:before, i.ph-suitcase.icon-edit:before, i.ph-suitcase.icon-info:before {
  content: "\e5ae";
}

.ph.ph-suitcase-rolling:before, i.ph-suitcase-rolling.icon-dead:before, i.ph-suitcase-rolling.icon-alive:before, i.ph-suitcase-rolling.icon-warn:before, i.ph-suitcase-rolling.icon-sign-out:before, i.ph-suitcase-rolling.icon-substratum:before, i.ph-suitcase-rolling.icon-environment:before, i.ph-suitcase-rolling.icon-user:before, i.ph-suitcase-rolling.icon-person:before, i.ph-suitcase-rolling.icon-terrain:before, i.ph-suitcase-rolling.icon-taxon:before, i.ph-suitcase-rolling.icon-unknown:before, i.ph-suitcase-rolling.icon-nothing:before, i.ph-suitcase-rolling.icon-sampling:before, i.ph-suitcase-rolling.icon-isolation:before, i.ph-suitcase-rolling.icon-strain:before, i.ph-suitcase-rolling.icon-gps:before, i.ph-suitcase-rolling.icon-location:before, i.ph-suitcase-rolling.icon-add:before, i.ph-suitcase-rolling.icon-left:before, i.ph-suitcase-rolling.icon-dna:before, i.ph-suitcase-rolling.icon-delete:before, i.ph-suitcase-rolling.icon-edit:before, i.ph-suitcase-rolling.icon-info:before {
  content: "\e9b0";
}

.ph.ph-suitcase-simple:before, i.ph-suitcase-simple.icon-dead:before, i.ph-suitcase-simple.icon-alive:before, i.ph-suitcase-simple.icon-warn:before, i.ph-suitcase-simple.icon-sign-out:before, i.ph-suitcase-simple.icon-substratum:before, i.ph-suitcase-simple.icon-environment:before, i.ph-suitcase-simple.icon-user:before, i.ph-suitcase-simple.icon-person:before, i.ph-suitcase-simple.icon-terrain:before, i.ph-suitcase-simple.icon-taxon:before, i.ph-suitcase-simple.icon-unknown:before, i.ph-suitcase-simple.icon-nothing:before, i.ph-suitcase-simple.icon-sampling:before, i.ph-suitcase-simple.icon-isolation:before, i.ph-suitcase-simple.icon-strain:before, i.ph-suitcase-simple.icon-gps:before, i.ph-suitcase-simple.icon-location:before, i.ph-suitcase-simple.icon-add:before, i.ph-suitcase-simple.icon-left:before, i.ph-suitcase-simple.icon-dna:before, i.ph-suitcase-simple.icon-delete:before, i.ph-suitcase-simple.icon-edit:before, i.ph-suitcase-simple.icon-info:before {
  content: "\e5b0";
}

.ph.ph-sun:before, i.ph-sun.icon-dead:before, i.ph-sun.icon-alive:before, i.ph-sun.icon-warn:before, i.ph-sun.icon-sign-out:before, i.ph-sun.icon-substratum:before, i.ph-sun.icon-environment:before, i.ph-sun.icon-user:before, i.ph-sun.icon-person:before, i.ph-sun.icon-terrain:before, i.ph-sun.icon-taxon:before, i.ph-sun.icon-unknown:before, i.ph-sun.icon-nothing:before, i.ph-sun.icon-sampling:before, i.ph-sun.icon-isolation:before, i.ph-sun.icon-strain:before, i.ph-sun.icon-gps:before, i.ph-sun.icon-location:before, i.ph-sun.icon-add:before, i.ph-sun.icon-left:before, i.ph-sun.icon-dna:before, i.ph-sun.icon-delete:before, i.ph-sun.icon-edit:before, i.ph-sun.icon-info:before {
  content: "\e472";
}

.ph.ph-sun-dim:before, i.ph-sun-dim.icon-dead:before, i.ph-sun-dim.icon-alive:before, i.ph-sun-dim.icon-warn:before, i.ph-sun-dim.icon-sign-out:before, i.ph-sun-dim.icon-substratum:before, i.ph-sun-dim.icon-environment:before, i.ph-sun-dim.icon-user:before, i.ph-sun-dim.icon-person:before, i.ph-sun-dim.icon-terrain:before, i.ph-sun-dim.icon-taxon:before, i.ph-sun-dim.icon-unknown:before, i.ph-sun-dim.icon-nothing:before, i.ph-sun-dim.icon-sampling:before, i.ph-sun-dim.icon-isolation:before, i.ph-sun-dim.icon-strain:before, i.ph-sun-dim.icon-gps:before, i.ph-sun-dim.icon-location:before, i.ph-sun-dim.icon-add:before, i.ph-sun-dim.icon-left:before, i.ph-sun-dim.icon-dna:before, i.ph-sun-dim.icon-delete:before, i.ph-sun-dim.icon-edit:before, i.ph-sun-dim.icon-info:before {
  content: "\e474";
}

.ph.ph-sun-horizon:before, i.ph-sun-horizon.icon-dead:before, i.ph-sun-horizon.icon-alive:before, i.ph-sun-horizon.icon-warn:before, i.ph-sun-horizon.icon-sign-out:before, i.ph-sun-horizon.icon-substratum:before, i.ph-sun-horizon.icon-environment:before, i.ph-sun-horizon.icon-user:before, i.ph-sun-horizon.icon-person:before, i.ph-sun-horizon.icon-terrain:before, i.ph-sun-horizon.icon-taxon:before, i.ph-sun-horizon.icon-unknown:before, i.ph-sun-horizon.icon-nothing:before, i.ph-sun-horizon.icon-sampling:before, i.ph-sun-horizon.icon-isolation:before, i.ph-sun-horizon.icon-strain:before, i.ph-sun-horizon.icon-gps:before, i.ph-sun-horizon.icon-location:before, i.ph-sun-horizon.icon-add:before, i.ph-sun-horizon.icon-left:before, i.ph-sun-horizon.icon-dna:before, i.ph-sun-horizon.icon-delete:before, i.ph-sun-horizon.icon-edit:before, i.ph-sun-horizon.icon-info:before {
  content: "\e5b6";
}

.ph.ph-sunglasses:before, i.ph-sunglasses.icon-dead:before, i.ph-sunglasses.icon-alive:before, i.ph-sunglasses.icon-warn:before, i.ph-sunglasses.icon-sign-out:before, i.ph-sunglasses.icon-substratum:before, i.ph-sunglasses.icon-environment:before, i.ph-sunglasses.icon-user:before, i.ph-sunglasses.icon-person:before, i.ph-sunglasses.icon-terrain:before, i.ph-sunglasses.icon-taxon:before, i.ph-sunglasses.icon-unknown:before, i.ph-sunglasses.icon-nothing:before, i.ph-sunglasses.icon-sampling:before, i.ph-sunglasses.icon-isolation:before, i.ph-sunglasses.icon-strain:before, i.ph-sunglasses.icon-gps:before, i.ph-sunglasses.icon-location:before, i.ph-sunglasses.icon-add:before, i.ph-sunglasses.icon-left:before, i.ph-sunglasses.icon-dna:before, i.ph-sunglasses.icon-delete:before, i.ph-sunglasses.icon-edit:before, i.ph-sunglasses.icon-info:before {
  content: "\e816";
}

.ph.ph-superset-of:before, i.ph-superset-of.icon-dead:before, i.ph-superset-of.icon-alive:before, i.ph-superset-of.icon-warn:before, i.ph-superset-of.icon-sign-out:before, i.ph-superset-of.icon-substratum:before, i.ph-superset-of.icon-environment:before, i.ph-superset-of.icon-user:before, i.ph-superset-of.icon-person:before, i.ph-superset-of.icon-terrain:before, i.ph-superset-of.icon-taxon:before, i.ph-superset-of.icon-unknown:before, i.ph-superset-of.icon-nothing:before, i.ph-superset-of.icon-sampling:before, i.ph-superset-of.icon-isolation:before, i.ph-superset-of.icon-strain:before, i.ph-superset-of.icon-gps:before, i.ph-superset-of.icon-location:before, i.ph-superset-of.icon-add:before, i.ph-superset-of.icon-left:before, i.ph-superset-of.icon-dna:before, i.ph-superset-of.icon-delete:before, i.ph-superset-of.icon-edit:before, i.ph-superset-of.icon-info:before {
  content: "\edb8";
}

.ph.ph-superset-proper-of:before, i.ph-superset-proper-of.icon-dead:before, i.ph-superset-proper-of.icon-alive:before, i.ph-superset-proper-of.icon-warn:before, i.ph-superset-proper-of.icon-sign-out:before, i.ph-superset-proper-of.icon-substratum:before, i.ph-superset-proper-of.icon-environment:before, i.ph-superset-proper-of.icon-user:before, i.ph-superset-proper-of.icon-person:before, i.ph-superset-proper-of.icon-terrain:before, i.ph-superset-proper-of.icon-taxon:before, i.ph-superset-proper-of.icon-unknown:before, i.ph-superset-proper-of.icon-nothing:before, i.ph-superset-proper-of.icon-sampling:before, i.ph-superset-proper-of.icon-isolation:before, i.ph-superset-proper-of.icon-strain:before, i.ph-superset-proper-of.icon-gps:before, i.ph-superset-proper-of.icon-location:before, i.ph-superset-proper-of.icon-add:before, i.ph-superset-proper-of.icon-left:before, i.ph-superset-proper-of.icon-dna:before, i.ph-superset-proper-of.icon-delete:before, i.ph-superset-proper-of.icon-edit:before, i.ph-superset-proper-of.icon-info:before {
  content: "\edb4";
}

.ph.ph-swap:before, i.ph-swap.icon-dead:before, i.ph-swap.icon-alive:before, i.ph-swap.icon-warn:before, i.ph-swap.icon-sign-out:before, i.ph-swap.icon-substratum:before, i.ph-swap.icon-environment:before, i.ph-swap.icon-user:before, i.ph-swap.icon-person:before, i.ph-swap.icon-terrain:before, i.ph-swap.icon-taxon:before, i.ph-swap.icon-unknown:before, i.ph-swap.icon-nothing:before, i.ph-swap.icon-sampling:before, i.ph-swap.icon-isolation:before, i.ph-swap.icon-strain:before, i.ph-swap.icon-gps:before, i.ph-swap.icon-location:before, i.ph-swap.icon-add:before, i.ph-swap.icon-left:before, i.ph-swap.icon-dna:before, i.ph-swap.icon-delete:before, i.ph-swap.icon-edit:before, i.ph-swap.icon-info:before {
  content: "\e83c";
}

.ph.ph-swatches:before, i.ph-swatches.icon-dead:before, i.ph-swatches.icon-alive:before, i.ph-swatches.icon-warn:before, i.ph-swatches.icon-sign-out:before, i.ph-swatches.icon-substratum:before, i.ph-swatches.icon-environment:before, i.ph-swatches.icon-user:before, i.ph-swatches.icon-person:before, i.ph-swatches.icon-terrain:before, i.ph-swatches.icon-taxon:before, i.ph-swatches.icon-unknown:before, i.ph-swatches.icon-nothing:before, i.ph-swatches.icon-sampling:before, i.ph-swatches.icon-isolation:before, i.ph-swatches.icon-strain:before, i.ph-swatches.icon-gps:before, i.ph-swatches.icon-location:before, i.ph-swatches.icon-add:before, i.ph-swatches.icon-left:before, i.ph-swatches.icon-dna:before, i.ph-swatches.icon-delete:before, i.ph-swatches.icon-edit:before, i.ph-swatches.icon-info:before {
  content: "\e5b8";
}

.ph.ph-swimming-pool:before, i.ph-swimming-pool.icon-dead:before, i.ph-swimming-pool.icon-alive:before, i.ph-swimming-pool.icon-warn:before, i.ph-swimming-pool.icon-sign-out:before, i.ph-swimming-pool.icon-substratum:before, i.ph-swimming-pool.icon-environment:before, i.ph-swimming-pool.icon-user:before, i.ph-swimming-pool.icon-person:before, i.ph-swimming-pool.icon-terrain:before, i.ph-swimming-pool.icon-taxon:before, i.ph-swimming-pool.icon-unknown:before, i.ph-swimming-pool.icon-nothing:before, i.ph-swimming-pool.icon-sampling:before, i.ph-swimming-pool.icon-isolation:before, i.ph-swimming-pool.icon-strain:before, i.ph-swimming-pool.icon-gps:before, i.ph-swimming-pool.icon-location:before, i.ph-swimming-pool.icon-add:before, i.ph-swimming-pool.icon-left:before, i.ph-swimming-pool.icon-dna:before, i.ph-swimming-pool.icon-delete:before, i.ph-swimming-pool.icon-edit:before, i.ph-swimming-pool.icon-info:before {
  content: "\ecb6";
}

.ph.ph-sword:before, i.ph-sword.icon-dead:before, i.ph-sword.icon-alive:before, i.ph-sword.icon-warn:before, i.ph-sword.icon-sign-out:before, i.ph-sword.icon-substratum:before, i.ph-sword.icon-environment:before, i.ph-sword.icon-user:before, i.ph-sword.icon-person:before, i.ph-sword.icon-terrain:before, i.ph-sword.icon-taxon:before, i.ph-sword.icon-unknown:before, i.ph-sword.icon-nothing:before, i.ph-sword.icon-sampling:before, i.ph-sword.icon-isolation:before, i.ph-sword.icon-strain:before, i.ph-sword.icon-gps:before, i.ph-sword.icon-location:before, i.ph-sword.icon-add:before, i.ph-sword.icon-left:before, i.ph-sword.icon-dna:before, i.ph-sword.icon-delete:before, i.ph-sword.icon-edit:before, i.ph-sword.icon-info:before {
  content: "\e5ba";
}

.ph.ph-synagogue:before, i.ph-synagogue.icon-dead:before, i.ph-synagogue.icon-alive:before, i.ph-synagogue.icon-warn:before, i.ph-synagogue.icon-sign-out:before, i.ph-synagogue.icon-substratum:before, i.ph-synagogue.icon-environment:before, i.ph-synagogue.icon-user:before, i.ph-synagogue.icon-person:before, i.ph-synagogue.icon-terrain:before, i.ph-synagogue.icon-taxon:before, i.ph-synagogue.icon-unknown:before, i.ph-synagogue.icon-nothing:before, i.ph-synagogue.icon-sampling:before, i.ph-synagogue.icon-isolation:before, i.ph-synagogue.icon-strain:before, i.ph-synagogue.icon-gps:before, i.ph-synagogue.icon-location:before, i.ph-synagogue.icon-add:before, i.ph-synagogue.icon-left:before, i.ph-synagogue.icon-dna:before, i.ph-synagogue.icon-delete:before, i.ph-synagogue.icon-edit:before, i.ph-synagogue.icon-info:before {
  content: "\ecec";
}

.ph.ph-syringe:before, i.ph-syringe.icon-dead:before, i.ph-syringe.icon-alive:before, i.ph-syringe.icon-warn:before, i.ph-syringe.icon-sign-out:before, i.ph-syringe.icon-substratum:before, i.ph-syringe.icon-environment:before, i.ph-syringe.icon-user:before, i.ph-syringe.icon-person:before, i.ph-syringe.icon-terrain:before, i.ph-syringe.icon-taxon:before, i.ph-syringe.icon-unknown:before, i.ph-syringe.icon-nothing:before, i.ph-syringe.icon-sampling:before, i.ph-syringe.icon-isolation:before, i.ph-syringe.icon-strain:before, i.ph-syringe.icon-gps:before, i.ph-syringe.icon-location:before, i.ph-syringe.icon-add:before, i.ph-syringe.icon-left:before, i.ph-syringe.icon-dna:before, i.ph-syringe.icon-delete:before, i.ph-syringe.icon-edit:before, i.ph-syringe.icon-info:before {
  content: "\e968";
}

.ph.ph-t-shirt:before, i.ph-t-shirt.icon-dead:before, i.ph-t-shirt.icon-alive:before, i.ph-t-shirt.icon-warn:before, i.ph-t-shirt.icon-sign-out:before, i.ph-t-shirt.icon-substratum:before, i.ph-t-shirt.icon-environment:before, i.ph-t-shirt.icon-user:before, i.ph-t-shirt.icon-person:before, i.ph-t-shirt.icon-terrain:before, i.ph-t-shirt.icon-taxon:before, i.ph-t-shirt.icon-unknown:before, i.ph-t-shirt.icon-nothing:before, i.ph-t-shirt.icon-sampling:before, i.ph-t-shirt.icon-isolation:before, i.ph-t-shirt.icon-strain:before, i.ph-t-shirt.icon-gps:before, i.ph-t-shirt.icon-location:before, i.ph-t-shirt.icon-add:before, i.ph-t-shirt.icon-left:before, i.ph-t-shirt.icon-dna:before, i.ph-t-shirt.icon-delete:before, i.ph-t-shirt.icon-edit:before, i.ph-t-shirt.icon-info:before {
  content: "\e670";
}

.ph.ph-table:before, i.ph-table.icon-dead:before, i.ph-table.icon-alive:before, i.ph-table.icon-warn:before, i.ph-table.icon-sign-out:before, i.ph-table.icon-substratum:before, i.ph-table.icon-environment:before, i.ph-table.icon-user:before, i.ph-table.icon-person:before, i.ph-table.icon-terrain:before, i.ph-table.icon-taxon:before, i.ph-table.icon-unknown:before, i.ph-table.icon-nothing:before, i.ph-table.icon-sampling:before, i.ph-table.icon-isolation:before, i.ph-table.icon-strain:before, i.ph-table.icon-gps:before, i.ph-table.icon-location:before, i.ph-table.icon-add:before, i.ph-table.icon-left:before, i.ph-table.icon-dna:before, i.ph-table.icon-delete:before, i.ph-table.icon-edit:before, i.ph-table.icon-info:before {
  content: "\e476";
}

.ph.ph-tabs:before, i.ph-tabs.icon-dead:before, i.ph-tabs.icon-alive:before, i.ph-tabs.icon-warn:before, i.ph-tabs.icon-sign-out:before, i.ph-tabs.icon-substratum:before, i.ph-tabs.icon-environment:before, i.ph-tabs.icon-user:before, i.ph-tabs.icon-person:before, i.ph-tabs.icon-terrain:before, i.ph-tabs.icon-taxon:before, i.ph-tabs.icon-unknown:before, i.ph-tabs.icon-nothing:before, i.ph-tabs.icon-sampling:before, i.ph-tabs.icon-isolation:before, i.ph-tabs.icon-strain:before, i.ph-tabs.icon-gps:before, i.ph-tabs.icon-location:before, i.ph-tabs.icon-add:before, i.ph-tabs.icon-left:before, i.ph-tabs.icon-dna:before, i.ph-tabs.icon-delete:before, i.ph-tabs.icon-edit:before, i.ph-tabs.icon-info:before {
  content: "\e778";
}

.ph.ph-tag:before, i.ph-tag.icon-dead:before, i.ph-tag.icon-alive:before, i.ph-tag.icon-warn:before, i.ph-tag.icon-sign-out:before, i.ph-tag.icon-substratum:before, i.ph-tag.icon-environment:before, i.ph-tag.icon-user:before, i.ph-tag.icon-person:before, i.ph-tag.icon-terrain:before, i.ph-tag.icon-taxon:before, i.ph-tag.icon-unknown:before, i.ph-tag.icon-nothing:before, i.ph-tag.icon-sampling:before, i.ph-tag.icon-isolation:before, i.ph-tag.icon-strain:before, i.ph-tag.icon-gps:before, i.ph-tag.icon-location:before, i.ph-tag.icon-add:before, i.ph-tag.icon-left:before, i.ph-tag.icon-dna:before, i.ph-tag.icon-delete:before, i.ph-tag.icon-edit:before, i.ph-tag.icon-info:before {
  content: "\e478";
}

.ph.ph-tag-chevron:before, i.ph-tag-chevron.icon-dead:before, i.ph-tag-chevron.icon-alive:before, i.ph-tag-chevron.icon-warn:before, i.ph-tag-chevron.icon-sign-out:before, i.ph-tag-chevron.icon-substratum:before, i.ph-tag-chevron.icon-environment:before, i.ph-tag-chevron.icon-user:before, i.ph-tag-chevron.icon-person:before, i.ph-tag-chevron.icon-terrain:before, i.ph-tag-chevron.icon-taxon:before, i.ph-tag-chevron.icon-unknown:before, i.ph-tag-chevron.icon-nothing:before, i.ph-tag-chevron.icon-sampling:before, i.ph-tag-chevron.icon-isolation:before, i.ph-tag-chevron.icon-strain:before, i.ph-tag-chevron.icon-gps:before, i.ph-tag-chevron.icon-location:before, i.ph-tag-chevron.icon-add:before, i.ph-tag-chevron.icon-left:before, i.ph-tag-chevron.icon-dna:before, i.ph-tag-chevron.icon-delete:before, i.ph-tag-chevron.icon-edit:before, i.ph-tag-chevron.icon-info:before {
  content: "\e672";
}

.ph.ph-tag-simple:before, i.ph-tag-simple.icon-dead:before, i.ph-tag-simple.icon-alive:before, i.ph-tag-simple.icon-warn:before, i.ph-tag-simple.icon-sign-out:before, i.ph-tag-simple.icon-substratum:before, i.ph-tag-simple.icon-environment:before, i.ph-tag-simple.icon-user:before, i.ph-tag-simple.icon-person:before, i.ph-tag-simple.icon-terrain:before, i.ph-tag-simple.icon-taxon:before, i.ph-tag-simple.icon-unknown:before, i.ph-tag-simple.icon-nothing:before, i.ph-tag-simple.icon-sampling:before, i.ph-tag-simple.icon-isolation:before, i.ph-tag-simple.icon-strain:before, i.ph-tag-simple.icon-gps:before, i.ph-tag-simple.icon-location:before, i.ph-tag-simple.icon-add:before, i.ph-tag-simple.icon-left:before, i.ph-tag-simple.icon-dna:before, i.ph-tag-simple.icon-delete:before, i.ph-tag-simple.icon-edit:before, i.ph-tag-simple.icon-info:before {
  content: "\e47a";
}

.ph.ph-target:before, i.ph-target.icon-dead:before, i.ph-target.icon-alive:before, i.ph-target.icon-warn:before, i.ph-target.icon-sign-out:before, i.ph-target.icon-substratum:before, i.ph-target.icon-environment:before, i.ph-target.icon-user:before, i.ph-target.icon-person:before, i.ph-target.icon-terrain:before, i.ph-target.icon-taxon:before, i.ph-target.icon-unknown:before, i.ph-target.icon-nothing:before, i.ph-target.icon-sampling:before, i.ph-target.icon-isolation:before, i.ph-target.icon-strain:before, i.ph-target.icon-gps:before, i.ph-target.icon-location:before, i.ph-target.icon-add:before, i.ph-target.icon-left:before, i.ph-target.icon-dna:before, i.ph-target.icon-delete:before, i.ph-target.icon-edit:before, i.ph-target.icon-info:before {
  content: "\e47c";
}

.ph.ph-taxi:before, i.ph-taxi.icon-dead:before, i.ph-taxi.icon-alive:before, i.ph-taxi.icon-warn:before, i.ph-taxi.icon-sign-out:before, i.ph-taxi.icon-substratum:before, i.ph-taxi.icon-environment:before, i.ph-taxi.icon-user:before, i.ph-taxi.icon-person:before, i.ph-taxi.icon-terrain:before, i.ph-taxi.icon-taxon:before, i.ph-taxi.icon-unknown:before, i.ph-taxi.icon-nothing:before, i.ph-taxi.icon-sampling:before, i.ph-taxi.icon-isolation:before, i.ph-taxi.icon-strain:before, i.ph-taxi.icon-gps:before, i.ph-taxi.icon-location:before, i.ph-taxi.icon-add:before, i.ph-taxi.icon-left:before, i.ph-taxi.icon-dna:before, i.ph-taxi.icon-delete:before, i.ph-taxi.icon-edit:before, i.ph-taxi.icon-info:before {
  content: "\e902";
}

.ph.ph-tea-bag:before, i.ph-tea-bag.icon-dead:before, i.ph-tea-bag.icon-alive:before, i.ph-tea-bag.icon-warn:before, i.ph-tea-bag.icon-sign-out:before, i.ph-tea-bag.icon-substratum:before, i.ph-tea-bag.icon-environment:before, i.ph-tea-bag.icon-user:before, i.ph-tea-bag.icon-person:before, i.ph-tea-bag.icon-terrain:before, i.ph-tea-bag.icon-taxon:before, i.ph-tea-bag.icon-unknown:before, i.ph-tea-bag.icon-nothing:before, i.ph-tea-bag.icon-sampling:before, i.ph-tea-bag.icon-isolation:before, i.ph-tea-bag.icon-strain:before, i.ph-tea-bag.icon-gps:before, i.ph-tea-bag.icon-location:before, i.ph-tea-bag.icon-add:before, i.ph-tea-bag.icon-left:before, i.ph-tea-bag.icon-dna:before, i.ph-tea-bag.icon-delete:before, i.ph-tea-bag.icon-edit:before, i.ph-tea-bag.icon-info:before {
  content: "\e8e6";
}

.ph.ph-telegram-logo:before, i.ph-telegram-logo.icon-dead:before, i.ph-telegram-logo.icon-alive:before, i.ph-telegram-logo.icon-warn:before, i.ph-telegram-logo.icon-sign-out:before, i.ph-telegram-logo.icon-substratum:before, i.ph-telegram-logo.icon-environment:before, i.ph-telegram-logo.icon-user:before, i.ph-telegram-logo.icon-person:before, i.ph-telegram-logo.icon-terrain:before, i.ph-telegram-logo.icon-taxon:before, i.ph-telegram-logo.icon-unknown:before, i.ph-telegram-logo.icon-nothing:before, i.ph-telegram-logo.icon-sampling:before, i.ph-telegram-logo.icon-isolation:before, i.ph-telegram-logo.icon-strain:before, i.ph-telegram-logo.icon-gps:before, i.ph-telegram-logo.icon-location:before, i.ph-telegram-logo.icon-add:before, i.ph-telegram-logo.icon-left:before, i.ph-telegram-logo.icon-dna:before, i.ph-telegram-logo.icon-delete:before, i.ph-telegram-logo.icon-edit:before, i.ph-telegram-logo.icon-info:before {
  content: "\e5bc";
}

.ph.ph-television:before, i.ph-television.icon-dead:before, i.ph-television.icon-alive:before, i.ph-television.icon-warn:before, i.ph-television.icon-sign-out:before, i.ph-television.icon-substratum:before, i.ph-television.icon-environment:before, i.ph-television.icon-user:before, i.ph-television.icon-person:before, i.ph-television.icon-terrain:before, i.ph-television.icon-taxon:before, i.ph-television.icon-unknown:before, i.ph-television.icon-nothing:before, i.ph-television.icon-sampling:before, i.ph-television.icon-isolation:before, i.ph-television.icon-strain:before, i.ph-television.icon-gps:before, i.ph-television.icon-location:before, i.ph-television.icon-add:before, i.ph-television.icon-left:before, i.ph-television.icon-dna:before, i.ph-television.icon-delete:before, i.ph-television.icon-edit:before, i.ph-television.icon-info:before {
  content: "\e754";
}

.ph.ph-television-simple:before, i.ph-television-simple.icon-dead:before, i.ph-television-simple.icon-alive:before, i.ph-television-simple.icon-warn:before, i.ph-television-simple.icon-sign-out:before, i.ph-television-simple.icon-substratum:before, i.ph-television-simple.icon-environment:before, i.ph-television-simple.icon-user:before, i.ph-television-simple.icon-person:before, i.ph-television-simple.icon-terrain:before, i.ph-television-simple.icon-taxon:before, i.ph-television-simple.icon-unknown:before, i.ph-television-simple.icon-nothing:before, i.ph-television-simple.icon-sampling:before, i.ph-television-simple.icon-isolation:before, i.ph-television-simple.icon-strain:before, i.ph-television-simple.icon-gps:before, i.ph-television-simple.icon-location:before, i.ph-television-simple.icon-add:before, i.ph-television-simple.icon-left:before, i.ph-television-simple.icon-dna:before, i.ph-television-simple.icon-delete:before, i.ph-television-simple.icon-edit:before, i.ph-television-simple.icon-info:before {
  content: "\eae6";
}

.ph.ph-tennis-ball:before, i.ph-tennis-ball.icon-dead:before, i.ph-tennis-ball.icon-alive:before, i.ph-tennis-ball.icon-warn:before, i.ph-tennis-ball.icon-sign-out:before, i.ph-tennis-ball.icon-substratum:before, i.ph-tennis-ball.icon-environment:before, i.ph-tennis-ball.icon-user:before, i.ph-tennis-ball.icon-person:before, i.ph-tennis-ball.icon-terrain:before, i.ph-tennis-ball.icon-taxon:before, i.ph-tennis-ball.icon-unknown:before, i.ph-tennis-ball.icon-nothing:before, i.ph-tennis-ball.icon-sampling:before, i.ph-tennis-ball.icon-isolation:before, i.ph-tennis-ball.icon-strain:before, i.ph-tennis-ball.icon-gps:before, i.ph-tennis-ball.icon-location:before, i.ph-tennis-ball.icon-add:before, i.ph-tennis-ball.icon-left:before, i.ph-tennis-ball.icon-dna:before, i.ph-tennis-ball.icon-delete:before, i.ph-tennis-ball.icon-edit:before, i.ph-tennis-ball.icon-info:before {
  content: "\e720";
}

.ph.ph-tent:before, i.ph-tent.icon-dead:before, i.ph-tent.icon-alive:before, i.ph-tent.icon-warn:before, i.ph-tent.icon-sign-out:before, i.ph-tent.icon-substratum:before, i.ph-tent.icon-environment:before, i.ph-tent.icon-user:before, i.ph-tent.icon-person:before, i.ph-tent.icon-terrain:before, i.ph-tent.icon-taxon:before, i.ph-tent.icon-unknown:before, i.ph-tent.icon-nothing:before, i.ph-tent.icon-sampling:before, i.ph-tent.icon-isolation:before, i.ph-tent.icon-strain:before, i.ph-tent.icon-gps:before, i.ph-tent.icon-location:before, i.ph-tent.icon-add:before, i.ph-tent.icon-left:before, i.ph-tent.icon-dna:before, i.ph-tent.icon-delete:before, i.ph-tent.icon-edit:before, i.ph-tent.icon-info:before {
  content: "\e8ba";
}

.ph.ph-terminal:before, i.ph-terminal.icon-dead:before, i.ph-terminal.icon-alive:before, i.ph-terminal.icon-warn:before, i.ph-terminal.icon-sign-out:before, i.ph-terminal.icon-substratum:before, i.ph-terminal.icon-environment:before, i.ph-terminal.icon-user:before, i.ph-terminal.icon-person:before, i.ph-terminal.icon-terrain:before, i.ph-terminal.icon-taxon:before, i.ph-terminal.icon-unknown:before, i.ph-terminal.icon-nothing:before, i.ph-terminal.icon-sampling:before, i.ph-terminal.icon-isolation:before, i.ph-terminal.icon-strain:before, i.ph-terminal.icon-gps:before, i.ph-terminal.icon-location:before, i.ph-terminal.icon-add:before, i.ph-terminal.icon-left:before, i.ph-terminal.icon-dna:before, i.ph-terminal.icon-delete:before, i.ph-terminal.icon-edit:before, i.ph-terminal.icon-info:before {
  content: "\e47e";
}

.ph.ph-terminal-window:before, i.ph-terminal-window.icon-dead:before, i.ph-terminal-window.icon-alive:before, i.ph-terminal-window.icon-warn:before, i.ph-terminal-window.icon-sign-out:before, i.ph-terminal-window.icon-substratum:before, i.ph-terminal-window.icon-environment:before, i.ph-terminal-window.icon-user:before, i.ph-terminal-window.icon-person:before, i.ph-terminal-window.icon-terrain:before, i.ph-terminal-window.icon-taxon:before, i.ph-terminal-window.icon-unknown:before, i.ph-terminal-window.icon-nothing:before, i.ph-terminal-window.icon-sampling:before, i.ph-terminal-window.icon-isolation:before, i.ph-terminal-window.icon-strain:before, i.ph-terminal-window.icon-gps:before, i.ph-terminal-window.icon-location:before, i.ph-terminal-window.icon-add:before, i.ph-terminal-window.icon-left:before, i.ph-terminal-window.icon-dna:before, i.ph-terminal-window.icon-delete:before, i.ph-terminal-window.icon-edit:before, i.ph-terminal-window.icon-info:before {
  content: "\eae8";
}

.ph.ph-test-tube:before, i.ph-test-tube.icon-dead:before, i.ph-test-tube.icon-alive:before, i.ph-test-tube.icon-warn:before, i.ph-test-tube.icon-sign-out:before, i.ph-test-tube.icon-substratum:before, i.ph-test-tube.icon-environment:before, i.ph-test-tube.icon-user:before, i.ph-test-tube.icon-person:before, i.ph-test-tube.icon-terrain:before, i.ph-test-tube.icon-taxon:before, i.ph-test-tube.icon-unknown:before, i.ph-test-tube.icon-nothing:before, i.ph-test-tube.icon-sampling:before, i.ph-test-tube.icon-isolation:before, i.ph-test-tube.icon-strain:before, i.ph-test-tube.icon-gps:before, i.ph-test-tube.icon-location:before, i.ph-test-tube.icon-add:before, i.ph-test-tube.icon-left:before, i.ph-test-tube.icon-dna:before, i.ph-test-tube.icon-delete:before, i.ph-test-tube.icon-edit:before, i.ph-test-tube.icon-info:before {
  content: "\e7a0";
}

.ph.ph-text-a-underline:before, i.ph-text-a-underline.icon-dead:before, i.ph-text-a-underline.icon-alive:before, i.ph-text-a-underline.icon-warn:before, i.ph-text-a-underline.icon-sign-out:before, i.ph-text-a-underline.icon-substratum:before, i.ph-text-a-underline.icon-environment:before, i.ph-text-a-underline.icon-user:before, i.ph-text-a-underline.icon-person:before, i.ph-text-a-underline.icon-terrain:before, i.ph-text-a-underline.icon-taxon:before, i.ph-text-a-underline.icon-unknown:before, i.ph-text-a-underline.icon-nothing:before, i.ph-text-a-underline.icon-sampling:before, i.ph-text-a-underline.icon-isolation:before, i.ph-text-a-underline.icon-strain:before, i.ph-text-a-underline.icon-gps:before, i.ph-text-a-underline.icon-location:before, i.ph-text-a-underline.icon-add:before, i.ph-text-a-underline.icon-left:before, i.ph-text-a-underline.icon-dna:before, i.ph-text-a-underline.icon-delete:before, i.ph-text-a-underline.icon-edit:before, i.ph-text-a-underline.icon-info:before {
  content: "\ed34";
}

.ph.ph-text-aa:before, i.ph-text-aa.icon-dead:before, i.ph-text-aa.icon-alive:before, i.ph-text-aa.icon-warn:before, i.ph-text-aa.icon-sign-out:before, i.ph-text-aa.icon-substratum:before, i.ph-text-aa.icon-environment:before, i.ph-text-aa.icon-user:before, i.ph-text-aa.icon-person:before, i.ph-text-aa.icon-terrain:before, i.ph-text-aa.icon-taxon:before, i.ph-text-aa.icon-unknown:before, i.ph-text-aa.icon-nothing:before, i.ph-text-aa.icon-sampling:before, i.ph-text-aa.icon-isolation:before, i.ph-text-aa.icon-strain:before, i.ph-text-aa.icon-gps:before, i.ph-text-aa.icon-location:before, i.ph-text-aa.icon-add:before, i.ph-text-aa.icon-left:before, i.ph-text-aa.icon-dna:before, i.ph-text-aa.icon-delete:before, i.ph-text-aa.icon-edit:before, i.ph-text-aa.icon-info:before {
  content: "\e6ee";
}

.ph.ph-text-align-center:before, i.ph-text-align-center.icon-dead:before, i.ph-text-align-center.icon-alive:before, i.ph-text-align-center.icon-warn:before, i.ph-text-align-center.icon-sign-out:before, i.ph-text-align-center.icon-substratum:before, i.ph-text-align-center.icon-environment:before, i.ph-text-align-center.icon-user:before, i.ph-text-align-center.icon-person:before, i.ph-text-align-center.icon-terrain:before, i.ph-text-align-center.icon-taxon:before, i.ph-text-align-center.icon-unknown:before, i.ph-text-align-center.icon-nothing:before, i.ph-text-align-center.icon-sampling:before, i.ph-text-align-center.icon-isolation:before, i.ph-text-align-center.icon-strain:before, i.ph-text-align-center.icon-gps:before, i.ph-text-align-center.icon-location:before, i.ph-text-align-center.icon-add:before, i.ph-text-align-center.icon-left:before, i.ph-text-align-center.icon-dna:before, i.ph-text-align-center.icon-delete:before, i.ph-text-align-center.icon-edit:before, i.ph-text-align-center.icon-info:before {
  content: "\e480";
}

.ph.ph-text-align-justify:before, i.ph-text-align-justify.icon-dead:before, i.ph-text-align-justify.icon-alive:before, i.ph-text-align-justify.icon-warn:before, i.ph-text-align-justify.icon-sign-out:before, i.ph-text-align-justify.icon-substratum:before, i.ph-text-align-justify.icon-environment:before, i.ph-text-align-justify.icon-user:before, i.ph-text-align-justify.icon-person:before, i.ph-text-align-justify.icon-terrain:before, i.ph-text-align-justify.icon-taxon:before, i.ph-text-align-justify.icon-unknown:before, i.ph-text-align-justify.icon-nothing:before, i.ph-text-align-justify.icon-sampling:before, i.ph-text-align-justify.icon-isolation:before, i.ph-text-align-justify.icon-strain:before, i.ph-text-align-justify.icon-gps:before, i.ph-text-align-justify.icon-location:before, i.ph-text-align-justify.icon-add:before, i.ph-text-align-justify.icon-left:before, i.ph-text-align-justify.icon-dna:before, i.ph-text-align-justify.icon-delete:before, i.ph-text-align-justify.icon-edit:before, i.ph-text-align-justify.icon-info:before {
  content: "\e482";
}

.ph.ph-text-align-left:before, i.ph-text-align-left.icon-dead:before, i.ph-text-align-left.icon-alive:before, i.ph-text-align-left.icon-warn:before, i.ph-text-align-left.icon-sign-out:before, i.ph-text-align-left.icon-substratum:before, i.ph-text-align-left.icon-environment:before, i.ph-text-align-left.icon-user:before, i.ph-text-align-left.icon-person:before, i.ph-text-align-left.icon-terrain:before, i.ph-text-align-left.icon-taxon:before, i.ph-text-align-left.icon-unknown:before, i.ph-text-align-left.icon-nothing:before, i.ph-text-align-left.icon-sampling:before, i.ph-text-align-left.icon-isolation:before, i.ph-text-align-left.icon-strain:before, i.ph-text-align-left.icon-gps:before, i.ph-text-align-left.icon-location:before, i.ph-text-align-left.icon-add:before, i.ph-text-align-left.icon-left:before, i.ph-text-align-left.icon-dna:before, i.ph-text-align-left.icon-delete:before, i.ph-text-align-left.icon-edit:before, i.ph-text-align-left.icon-info:before {
  content: "\e484";
}

.ph.ph-text-align-right:before, i.ph-text-align-right.icon-dead:before, i.ph-text-align-right.icon-alive:before, i.ph-text-align-right.icon-warn:before, i.ph-text-align-right.icon-sign-out:before, i.ph-text-align-right.icon-substratum:before, i.ph-text-align-right.icon-environment:before, i.ph-text-align-right.icon-user:before, i.ph-text-align-right.icon-person:before, i.ph-text-align-right.icon-terrain:before, i.ph-text-align-right.icon-taxon:before, i.ph-text-align-right.icon-unknown:before, i.ph-text-align-right.icon-nothing:before, i.ph-text-align-right.icon-sampling:before, i.ph-text-align-right.icon-isolation:before, i.ph-text-align-right.icon-strain:before, i.ph-text-align-right.icon-gps:before, i.ph-text-align-right.icon-location:before, i.ph-text-align-right.icon-add:before, i.ph-text-align-right.icon-left:before, i.ph-text-align-right.icon-dna:before, i.ph-text-align-right.icon-delete:before, i.ph-text-align-right.icon-edit:before, i.ph-text-align-right.icon-info:before {
  content: "\e486";
}

.ph.ph-text-b:before, i.ph-text-b.icon-dead:before, i.ph-text-b.icon-alive:before, i.ph-text-b.icon-warn:before, i.ph-text-b.icon-sign-out:before, i.ph-text-b.icon-substratum:before, i.ph-text-b.icon-environment:before, i.ph-text-b.icon-user:before, i.ph-text-b.icon-person:before, i.ph-text-b.icon-terrain:before, i.ph-text-b.icon-taxon:before, i.ph-text-b.icon-unknown:before, i.ph-text-b.icon-nothing:before, i.ph-text-b.icon-sampling:before, i.ph-text-b.icon-isolation:before, i.ph-text-b.icon-strain:before, i.ph-text-b.icon-gps:before, i.ph-text-b.icon-location:before, i.ph-text-b.icon-add:before, i.ph-text-b.icon-left:before, i.ph-text-b.icon-dna:before, i.ph-text-b.icon-delete:before, i.ph-text-b.icon-edit:before, i.ph-text-b.icon-info:before {
  content: "\e5be";
}

.ph.ph-text-bolder:before, i.ph-text-bolder.icon-dead:before, i.ph-text-bolder.icon-alive:before, i.ph-text-bolder.icon-warn:before, i.ph-text-bolder.icon-sign-out:before, i.ph-text-bolder.icon-substratum:before, i.ph-text-bolder.icon-environment:before, i.ph-text-bolder.icon-user:before, i.ph-text-bolder.icon-person:before, i.ph-text-bolder.icon-terrain:before, i.ph-text-bolder.icon-taxon:before, i.ph-text-bolder.icon-unknown:before, i.ph-text-bolder.icon-nothing:before, i.ph-text-bolder.icon-sampling:before, i.ph-text-bolder.icon-isolation:before, i.ph-text-bolder.icon-strain:before, i.ph-text-bolder.icon-gps:before, i.ph-text-bolder.icon-location:before, i.ph-text-bolder.icon-add:before, i.ph-text-bolder.icon-left:before, i.ph-text-bolder.icon-dna:before, i.ph-text-bolder.icon-delete:before, i.ph-text-bolder.icon-edit:before, i.ph-text-bolder.icon-info:before {
  content: "\e5be";
}

.ph.ph-text-columns:before, i.ph-text-columns.icon-dead:before, i.ph-text-columns.icon-alive:before, i.ph-text-columns.icon-warn:before, i.ph-text-columns.icon-sign-out:before, i.ph-text-columns.icon-substratum:before, i.ph-text-columns.icon-environment:before, i.ph-text-columns.icon-user:before, i.ph-text-columns.icon-person:before, i.ph-text-columns.icon-terrain:before, i.ph-text-columns.icon-taxon:before, i.ph-text-columns.icon-unknown:before, i.ph-text-columns.icon-nothing:before, i.ph-text-columns.icon-sampling:before, i.ph-text-columns.icon-isolation:before, i.ph-text-columns.icon-strain:before, i.ph-text-columns.icon-gps:before, i.ph-text-columns.icon-location:before, i.ph-text-columns.icon-add:before, i.ph-text-columns.icon-left:before, i.ph-text-columns.icon-dna:before, i.ph-text-columns.icon-delete:before, i.ph-text-columns.icon-edit:before, i.ph-text-columns.icon-info:before {
  content: "\ec96";
}

.ph.ph-text-h:before, i.ph-text-h.icon-dead:before, i.ph-text-h.icon-alive:before, i.ph-text-h.icon-warn:before, i.ph-text-h.icon-sign-out:before, i.ph-text-h.icon-substratum:before, i.ph-text-h.icon-environment:before, i.ph-text-h.icon-user:before, i.ph-text-h.icon-person:before, i.ph-text-h.icon-terrain:before, i.ph-text-h.icon-taxon:before, i.ph-text-h.icon-unknown:before, i.ph-text-h.icon-nothing:before, i.ph-text-h.icon-sampling:before, i.ph-text-h.icon-isolation:before, i.ph-text-h.icon-strain:before, i.ph-text-h.icon-gps:before, i.ph-text-h.icon-location:before, i.ph-text-h.icon-add:before, i.ph-text-h.icon-left:before, i.ph-text-h.icon-dna:before, i.ph-text-h.icon-delete:before, i.ph-text-h.icon-edit:before, i.ph-text-h.icon-info:before {
  content: "\e6ba";
}

.ph.ph-text-h-five:before, i.ph-text-h-five.icon-dead:before, i.ph-text-h-five.icon-alive:before, i.ph-text-h-five.icon-warn:before, i.ph-text-h-five.icon-sign-out:before, i.ph-text-h-five.icon-substratum:before, i.ph-text-h-five.icon-environment:before, i.ph-text-h-five.icon-user:before, i.ph-text-h-five.icon-person:before, i.ph-text-h-five.icon-terrain:before, i.ph-text-h-five.icon-taxon:before, i.ph-text-h-five.icon-unknown:before, i.ph-text-h-five.icon-nothing:before, i.ph-text-h-five.icon-sampling:before, i.ph-text-h-five.icon-isolation:before, i.ph-text-h-five.icon-strain:before, i.ph-text-h-five.icon-gps:before, i.ph-text-h-five.icon-location:before, i.ph-text-h-five.icon-add:before, i.ph-text-h-five.icon-left:before, i.ph-text-h-five.icon-dna:before, i.ph-text-h-five.icon-delete:before, i.ph-text-h-five.icon-edit:before, i.ph-text-h-five.icon-info:before {
  content: "\e6c4";
}

.ph.ph-text-h-four:before, i.ph-text-h-four.icon-dead:before, i.ph-text-h-four.icon-alive:before, i.ph-text-h-four.icon-warn:before, i.ph-text-h-four.icon-sign-out:before, i.ph-text-h-four.icon-substratum:before, i.ph-text-h-four.icon-environment:before, i.ph-text-h-four.icon-user:before, i.ph-text-h-four.icon-person:before, i.ph-text-h-four.icon-terrain:before, i.ph-text-h-four.icon-taxon:before, i.ph-text-h-four.icon-unknown:before, i.ph-text-h-four.icon-nothing:before, i.ph-text-h-four.icon-sampling:before, i.ph-text-h-four.icon-isolation:before, i.ph-text-h-four.icon-strain:before, i.ph-text-h-four.icon-gps:before, i.ph-text-h-four.icon-location:before, i.ph-text-h-four.icon-add:before, i.ph-text-h-four.icon-left:before, i.ph-text-h-four.icon-dna:before, i.ph-text-h-four.icon-delete:before, i.ph-text-h-four.icon-edit:before, i.ph-text-h-four.icon-info:before {
  content: "\e6c2";
}

.ph.ph-text-h-one:before, i.ph-text-h-one.icon-dead:before, i.ph-text-h-one.icon-alive:before, i.ph-text-h-one.icon-warn:before, i.ph-text-h-one.icon-sign-out:before, i.ph-text-h-one.icon-substratum:before, i.ph-text-h-one.icon-environment:before, i.ph-text-h-one.icon-user:before, i.ph-text-h-one.icon-person:before, i.ph-text-h-one.icon-terrain:before, i.ph-text-h-one.icon-taxon:before, i.ph-text-h-one.icon-unknown:before, i.ph-text-h-one.icon-nothing:before, i.ph-text-h-one.icon-sampling:before, i.ph-text-h-one.icon-isolation:before, i.ph-text-h-one.icon-strain:before, i.ph-text-h-one.icon-gps:before, i.ph-text-h-one.icon-location:before, i.ph-text-h-one.icon-add:before, i.ph-text-h-one.icon-left:before, i.ph-text-h-one.icon-dna:before, i.ph-text-h-one.icon-delete:before, i.ph-text-h-one.icon-edit:before, i.ph-text-h-one.icon-info:before {
  content: "\e6bc";
}

.ph.ph-text-h-six:before, i.ph-text-h-six.icon-dead:before, i.ph-text-h-six.icon-alive:before, i.ph-text-h-six.icon-warn:before, i.ph-text-h-six.icon-sign-out:before, i.ph-text-h-six.icon-substratum:before, i.ph-text-h-six.icon-environment:before, i.ph-text-h-six.icon-user:before, i.ph-text-h-six.icon-person:before, i.ph-text-h-six.icon-terrain:before, i.ph-text-h-six.icon-taxon:before, i.ph-text-h-six.icon-unknown:before, i.ph-text-h-six.icon-nothing:before, i.ph-text-h-six.icon-sampling:before, i.ph-text-h-six.icon-isolation:before, i.ph-text-h-six.icon-strain:before, i.ph-text-h-six.icon-gps:before, i.ph-text-h-six.icon-location:before, i.ph-text-h-six.icon-add:before, i.ph-text-h-six.icon-left:before, i.ph-text-h-six.icon-dna:before, i.ph-text-h-six.icon-delete:before, i.ph-text-h-six.icon-edit:before, i.ph-text-h-six.icon-info:before {
  content: "\e6c6";
}

.ph.ph-text-h-three:before, i.ph-text-h-three.icon-dead:before, i.ph-text-h-three.icon-alive:before, i.ph-text-h-three.icon-warn:before, i.ph-text-h-three.icon-sign-out:before, i.ph-text-h-three.icon-substratum:before, i.ph-text-h-three.icon-environment:before, i.ph-text-h-three.icon-user:before, i.ph-text-h-three.icon-person:before, i.ph-text-h-three.icon-terrain:before, i.ph-text-h-three.icon-taxon:before, i.ph-text-h-three.icon-unknown:before, i.ph-text-h-three.icon-nothing:before, i.ph-text-h-three.icon-sampling:before, i.ph-text-h-three.icon-isolation:before, i.ph-text-h-three.icon-strain:before, i.ph-text-h-three.icon-gps:before, i.ph-text-h-three.icon-location:before, i.ph-text-h-three.icon-add:before, i.ph-text-h-three.icon-left:before, i.ph-text-h-three.icon-dna:before, i.ph-text-h-three.icon-delete:before, i.ph-text-h-three.icon-edit:before, i.ph-text-h-three.icon-info:before {
  content: "\e6c0";
}

.ph.ph-text-h-two:before, i.ph-text-h-two.icon-dead:before, i.ph-text-h-two.icon-alive:before, i.ph-text-h-two.icon-warn:before, i.ph-text-h-two.icon-sign-out:before, i.ph-text-h-two.icon-substratum:before, i.ph-text-h-two.icon-environment:before, i.ph-text-h-two.icon-user:before, i.ph-text-h-two.icon-person:before, i.ph-text-h-two.icon-terrain:before, i.ph-text-h-two.icon-taxon:before, i.ph-text-h-two.icon-unknown:before, i.ph-text-h-two.icon-nothing:before, i.ph-text-h-two.icon-sampling:before, i.ph-text-h-two.icon-isolation:before, i.ph-text-h-two.icon-strain:before, i.ph-text-h-two.icon-gps:before, i.ph-text-h-two.icon-location:before, i.ph-text-h-two.icon-add:before, i.ph-text-h-two.icon-left:before, i.ph-text-h-two.icon-dna:before, i.ph-text-h-two.icon-delete:before, i.ph-text-h-two.icon-edit:before, i.ph-text-h-two.icon-info:before {
  content: "\e6be";
}

.ph.ph-text-indent:before, i.ph-text-indent.icon-dead:before, i.ph-text-indent.icon-alive:before, i.ph-text-indent.icon-warn:before, i.ph-text-indent.icon-sign-out:before, i.ph-text-indent.icon-substratum:before, i.ph-text-indent.icon-environment:before, i.ph-text-indent.icon-user:before, i.ph-text-indent.icon-person:before, i.ph-text-indent.icon-terrain:before, i.ph-text-indent.icon-taxon:before, i.ph-text-indent.icon-unknown:before, i.ph-text-indent.icon-nothing:before, i.ph-text-indent.icon-sampling:before, i.ph-text-indent.icon-isolation:before, i.ph-text-indent.icon-strain:before, i.ph-text-indent.icon-gps:before, i.ph-text-indent.icon-location:before, i.ph-text-indent.icon-add:before, i.ph-text-indent.icon-left:before, i.ph-text-indent.icon-dna:before, i.ph-text-indent.icon-delete:before, i.ph-text-indent.icon-edit:before, i.ph-text-indent.icon-info:before {
  content: "\ea1e";
}

.ph.ph-text-italic:before, i.ph-text-italic.icon-dead:before, i.ph-text-italic.icon-alive:before, i.ph-text-italic.icon-warn:before, i.ph-text-italic.icon-sign-out:before, i.ph-text-italic.icon-substratum:before, i.ph-text-italic.icon-environment:before, i.ph-text-italic.icon-user:before, i.ph-text-italic.icon-person:before, i.ph-text-italic.icon-terrain:before, i.ph-text-italic.icon-taxon:before, i.ph-text-italic.icon-unknown:before, i.ph-text-italic.icon-nothing:before, i.ph-text-italic.icon-sampling:before, i.ph-text-italic.icon-isolation:before, i.ph-text-italic.icon-strain:before, i.ph-text-italic.icon-gps:before, i.ph-text-italic.icon-location:before, i.ph-text-italic.icon-add:before, i.ph-text-italic.icon-left:before, i.ph-text-italic.icon-dna:before, i.ph-text-italic.icon-delete:before, i.ph-text-italic.icon-edit:before, i.ph-text-italic.icon-info:before {
  content: "\e5c0";
}

.ph.ph-text-outdent:before, i.ph-text-outdent.icon-dead:before, i.ph-text-outdent.icon-alive:before, i.ph-text-outdent.icon-warn:before, i.ph-text-outdent.icon-sign-out:before, i.ph-text-outdent.icon-substratum:before, i.ph-text-outdent.icon-environment:before, i.ph-text-outdent.icon-user:before, i.ph-text-outdent.icon-person:before, i.ph-text-outdent.icon-terrain:before, i.ph-text-outdent.icon-taxon:before, i.ph-text-outdent.icon-unknown:before, i.ph-text-outdent.icon-nothing:before, i.ph-text-outdent.icon-sampling:before, i.ph-text-outdent.icon-isolation:before, i.ph-text-outdent.icon-strain:before, i.ph-text-outdent.icon-gps:before, i.ph-text-outdent.icon-location:before, i.ph-text-outdent.icon-add:before, i.ph-text-outdent.icon-left:before, i.ph-text-outdent.icon-dna:before, i.ph-text-outdent.icon-delete:before, i.ph-text-outdent.icon-edit:before, i.ph-text-outdent.icon-info:before {
  content: "\ea1c";
}

.ph.ph-text-strikethrough:before, i.ph-text-strikethrough.icon-dead:before, i.ph-text-strikethrough.icon-alive:before, i.ph-text-strikethrough.icon-warn:before, i.ph-text-strikethrough.icon-sign-out:before, i.ph-text-strikethrough.icon-substratum:before, i.ph-text-strikethrough.icon-environment:before, i.ph-text-strikethrough.icon-user:before, i.ph-text-strikethrough.icon-person:before, i.ph-text-strikethrough.icon-terrain:before, i.ph-text-strikethrough.icon-taxon:before, i.ph-text-strikethrough.icon-unknown:before, i.ph-text-strikethrough.icon-nothing:before, i.ph-text-strikethrough.icon-sampling:before, i.ph-text-strikethrough.icon-isolation:before, i.ph-text-strikethrough.icon-strain:before, i.ph-text-strikethrough.icon-gps:before, i.ph-text-strikethrough.icon-location:before, i.ph-text-strikethrough.icon-add:before, i.ph-text-strikethrough.icon-left:before, i.ph-text-strikethrough.icon-dna:before, i.ph-text-strikethrough.icon-delete:before, i.ph-text-strikethrough.icon-edit:before, i.ph-text-strikethrough.icon-info:before {
  content: "\e5c2";
}

.ph.ph-text-subscript:before, i.ph-text-subscript.icon-dead:before, i.ph-text-subscript.icon-alive:before, i.ph-text-subscript.icon-warn:before, i.ph-text-subscript.icon-sign-out:before, i.ph-text-subscript.icon-substratum:before, i.ph-text-subscript.icon-environment:before, i.ph-text-subscript.icon-user:before, i.ph-text-subscript.icon-person:before, i.ph-text-subscript.icon-terrain:before, i.ph-text-subscript.icon-taxon:before, i.ph-text-subscript.icon-unknown:before, i.ph-text-subscript.icon-nothing:before, i.ph-text-subscript.icon-sampling:before, i.ph-text-subscript.icon-isolation:before, i.ph-text-subscript.icon-strain:before, i.ph-text-subscript.icon-gps:before, i.ph-text-subscript.icon-location:before, i.ph-text-subscript.icon-add:before, i.ph-text-subscript.icon-left:before, i.ph-text-subscript.icon-dna:before, i.ph-text-subscript.icon-delete:before, i.ph-text-subscript.icon-edit:before, i.ph-text-subscript.icon-info:before {
  content: "\ec98";
}

.ph.ph-text-superscript:before, i.ph-text-superscript.icon-dead:before, i.ph-text-superscript.icon-alive:before, i.ph-text-superscript.icon-warn:before, i.ph-text-superscript.icon-sign-out:before, i.ph-text-superscript.icon-substratum:before, i.ph-text-superscript.icon-environment:before, i.ph-text-superscript.icon-user:before, i.ph-text-superscript.icon-person:before, i.ph-text-superscript.icon-terrain:before, i.ph-text-superscript.icon-taxon:before, i.ph-text-superscript.icon-unknown:before, i.ph-text-superscript.icon-nothing:before, i.ph-text-superscript.icon-sampling:before, i.ph-text-superscript.icon-isolation:before, i.ph-text-superscript.icon-strain:before, i.ph-text-superscript.icon-gps:before, i.ph-text-superscript.icon-location:before, i.ph-text-superscript.icon-add:before, i.ph-text-superscript.icon-left:before, i.ph-text-superscript.icon-dna:before, i.ph-text-superscript.icon-delete:before, i.ph-text-superscript.icon-edit:before, i.ph-text-superscript.icon-info:before {
  content: "\ec9a";
}

.ph.ph-text-t:before, i.ph-text-t.icon-dead:before, i.ph-text-t.icon-alive:before, i.ph-text-t.icon-warn:before, i.ph-text-t.icon-sign-out:before, i.ph-text-t.icon-substratum:before, i.ph-text-t.icon-environment:before, i.ph-text-t.icon-user:before, i.ph-text-t.icon-person:before, i.ph-text-t.icon-terrain:before, i.ph-text-t.icon-taxon:before, i.ph-text-t.icon-unknown:before, i.ph-text-t.icon-nothing:before, i.ph-text-t.icon-sampling:before, i.ph-text-t.icon-isolation:before, i.ph-text-t.icon-strain:before, i.ph-text-t.icon-gps:before, i.ph-text-t.icon-location:before, i.ph-text-t.icon-add:before, i.ph-text-t.icon-left:before, i.ph-text-t.icon-dna:before, i.ph-text-t.icon-delete:before, i.ph-text-t.icon-edit:before, i.ph-text-t.icon-info:before {
  content: "\e48a";
}

.ph.ph-text-t-slash:before, i.ph-text-t-slash.icon-dead:before, i.ph-text-t-slash.icon-alive:before, i.ph-text-t-slash.icon-warn:before, i.ph-text-t-slash.icon-sign-out:before, i.ph-text-t-slash.icon-substratum:before, i.ph-text-t-slash.icon-environment:before, i.ph-text-t-slash.icon-user:before, i.ph-text-t-slash.icon-person:before, i.ph-text-t-slash.icon-terrain:before, i.ph-text-t-slash.icon-taxon:before, i.ph-text-t-slash.icon-unknown:before, i.ph-text-t-slash.icon-nothing:before, i.ph-text-t-slash.icon-sampling:before, i.ph-text-t-slash.icon-isolation:before, i.ph-text-t-slash.icon-strain:before, i.ph-text-t-slash.icon-gps:before, i.ph-text-t-slash.icon-location:before, i.ph-text-t-slash.icon-add:before, i.ph-text-t-slash.icon-left:before, i.ph-text-t-slash.icon-dna:before, i.ph-text-t-slash.icon-delete:before, i.ph-text-t-slash.icon-edit:before, i.ph-text-t-slash.icon-info:before {
  content: "\e488";
}

.ph.ph-text-underline:before, i.ph-text-underline.icon-dead:before, i.ph-text-underline.icon-alive:before, i.ph-text-underline.icon-warn:before, i.ph-text-underline.icon-sign-out:before, i.ph-text-underline.icon-substratum:before, i.ph-text-underline.icon-environment:before, i.ph-text-underline.icon-user:before, i.ph-text-underline.icon-person:before, i.ph-text-underline.icon-terrain:before, i.ph-text-underline.icon-taxon:before, i.ph-text-underline.icon-unknown:before, i.ph-text-underline.icon-nothing:before, i.ph-text-underline.icon-sampling:before, i.ph-text-underline.icon-isolation:before, i.ph-text-underline.icon-strain:before, i.ph-text-underline.icon-gps:before, i.ph-text-underline.icon-location:before, i.ph-text-underline.icon-add:before, i.ph-text-underline.icon-left:before, i.ph-text-underline.icon-dna:before, i.ph-text-underline.icon-delete:before, i.ph-text-underline.icon-edit:before, i.ph-text-underline.icon-info:before {
  content: "\e5c4";
}

.ph.ph-textbox:before, i.ph-textbox.icon-dead:before, i.ph-textbox.icon-alive:before, i.ph-textbox.icon-warn:before, i.ph-textbox.icon-sign-out:before, i.ph-textbox.icon-substratum:before, i.ph-textbox.icon-environment:before, i.ph-textbox.icon-user:before, i.ph-textbox.icon-person:before, i.ph-textbox.icon-terrain:before, i.ph-textbox.icon-taxon:before, i.ph-textbox.icon-unknown:before, i.ph-textbox.icon-nothing:before, i.ph-textbox.icon-sampling:before, i.ph-textbox.icon-isolation:before, i.ph-textbox.icon-strain:before, i.ph-textbox.icon-gps:before, i.ph-textbox.icon-location:before, i.ph-textbox.icon-add:before, i.ph-textbox.icon-left:before, i.ph-textbox.icon-dna:before, i.ph-textbox.icon-delete:before, i.ph-textbox.icon-edit:before, i.ph-textbox.icon-info:before {
  content: "\eb0a";
}

.ph.ph-thermometer:before, i.ph-thermometer.icon-dead:before, i.ph-thermometer.icon-alive:before, i.ph-thermometer.icon-warn:before, i.ph-thermometer.icon-sign-out:before, i.ph-thermometer.icon-substratum:before, i.ph-thermometer.icon-environment:before, i.ph-thermometer.icon-user:before, i.ph-thermometer.icon-person:before, i.ph-thermometer.icon-terrain:before, i.ph-thermometer.icon-taxon:before, i.ph-thermometer.icon-unknown:before, i.ph-thermometer.icon-nothing:before, i.ph-thermometer.icon-sampling:before, i.ph-thermometer.icon-isolation:before, i.ph-thermometer.icon-strain:before, i.ph-thermometer.icon-gps:before, i.ph-thermometer.icon-location:before, i.ph-thermometer.icon-add:before, i.ph-thermometer.icon-left:before, i.ph-thermometer.icon-dna:before, i.ph-thermometer.icon-delete:before, i.ph-thermometer.icon-edit:before, i.ph-thermometer.icon-info:before {
  content: "\e5c6";
}

.ph.ph-thermometer-cold:before, i.ph-thermometer-cold.icon-dead:before, i.ph-thermometer-cold.icon-alive:before, i.ph-thermometer-cold.icon-warn:before, i.ph-thermometer-cold.icon-sign-out:before, i.ph-thermometer-cold.icon-substratum:before, i.ph-thermometer-cold.icon-environment:before, i.ph-thermometer-cold.icon-user:before, i.ph-thermometer-cold.icon-person:before, i.ph-thermometer-cold.icon-terrain:before, i.ph-thermometer-cold.icon-taxon:before, i.ph-thermometer-cold.icon-unknown:before, i.ph-thermometer-cold.icon-nothing:before, i.ph-thermometer-cold.icon-sampling:before, i.ph-thermometer-cold.icon-isolation:before, i.ph-thermometer-cold.icon-strain:before, i.ph-thermometer-cold.icon-gps:before, i.ph-thermometer-cold.icon-location:before, i.ph-thermometer-cold.icon-add:before, i.ph-thermometer-cold.icon-left:before, i.ph-thermometer-cold.icon-dna:before, i.ph-thermometer-cold.icon-delete:before, i.ph-thermometer-cold.icon-edit:before, i.ph-thermometer-cold.icon-info:before {
  content: "\e5c8";
}

.ph.ph-thermometer-hot:before, i.ph-thermometer-hot.icon-dead:before, i.ph-thermometer-hot.icon-alive:before, i.ph-thermometer-hot.icon-warn:before, i.ph-thermometer-hot.icon-sign-out:before, i.ph-thermometer-hot.icon-substratum:before, i.ph-thermometer-hot.icon-environment:before, i.ph-thermometer-hot.icon-user:before, i.ph-thermometer-hot.icon-person:before, i.ph-thermometer-hot.icon-terrain:before, i.ph-thermometer-hot.icon-taxon:before, i.ph-thermometer-hot.icon-unknown:before, i.ph-thermometer-hot.icon-nothing:before, i.ph-thermometer-hot.icon-sampling:before, i.ph-thermometer-hot.icon-isolation:before, i.ph-thermometer-hot.icon-strain:before, i.ph-thermometer-hot.icon-gps:before, i.ph-thermometer-hot.icon-location:before, i.ph-thermometer-hot.icon-add:before, i.ph-thermometer-hot.icon-left:before, i.ph-thermometer-hot.icon-dna:before, i.ph-thermometer-hot.icon-delete:before, i.ph-thermometer-hot.icon-edit:before, i.ph-thermometer-hot.icon-info:before {
  content: "\e5ca";
}

.ph.ph-thermometer-simple:before, i.ph-thermometer-simple.icon-dead:before, i.ph-thermometer-simple.icon-alive:before, i.ph-thermometer-simple.icon-warn:before, i.ph-thermometer-simple.icon-sign-out:before, i.ph-thermometer-simple.icon-substratum:before, i.ph-thermometer-simple.icon-environment:before, i.ph-thermometer-simple.icon-user:before, i.ph-thermometer-simple.icon-person:before, i.ph-thermometer-simple.icon-terrain:before, i.ph-thermometer-simple.icon-taxon:before, i.ph-thermometer-simple.icon-unknown:before, i.ph-thermometer-simple.icon-nothing:before, i.ph-thermometer-simple.icon-sampling:before, i.ph-thermometer-simple.icon-isolation:before, i.ph-thermometer-simple.icon-strain:before, i.ph-thermometer-simple.icon-gps:before, i.ph-thermometer-simple.icon-location:before, i.ph-thermometer-simple.icon-add:before, i.ph-thermometer-simple.icon-left:before, i.ph-thermometer-simple.icon-dna:before, i.ph-thermometer-simple.icon-delete:before, i.ph-thermometer-simple.icon-edit:before, i.ph-thermometer-simple.icon-info:before {
  content: "\e5cc";
}

.ph.ph-threads-logo:before, i.ph-threads-logo.icon-dead:before, i.ph-threads-logo.icon-alive:before, i.ph-threads-logo.icon-warn:before, i.ph-threads-logo.icon-sign-out:before, i.ph-threads-logo.icon-substratum:before, i.ph-threads-logo.icon-environment:before, i.ph-threads-logo.icon-user:before, i.ph-threads-logo.icon-person:before, i.ph-threads-logo.icon-terrain:before, i.ph-threads-logo.icon-taxon:before, i.ph-threads-logo.icon-unknown:before, i.ph-threads-logo.icon-nothing:before, i.ph-threads-logo.icon-sampling:before, i.ph-threads-logo.icon-isolation:before, i.ph-threads-logo.icon-strain:before, i.ph-threads-logo.icon-gps:before, i.ph-threads-logo.icon-location:before, i.ph-threads-logo.icon-add:before, i.ph-threads-logo.icon-left:before, i.ph-threads-logo.icon-dna:before, i.ph-threads-logo.icon-delete:before, i.ph-threads-logo.icon-edit:before, i.ph-threads-logo.icon-info:before {
  content: "\ed9e";
}

.ph.ph-three-d:before, i.ph-three-d.icon-dead:before, i.ph-three-d.icon-alive:before, i.ph-three-d.icon-warn:before, i.ph-three-d.icon-sign-out:before, i.ph-three-d.icon-substratum:before, i.ph-three-d.icon-environment:before, i.ph-three-d.icon-user:before, i.ph-three-d.icon-person:before, i.ph-three-d.icon-terrain:before, i.ph-three-d.icon-taxon:before, i.ph-three-d.icon-unknown:before, i.ph-three-d.icon-nothing:before, i.ph-three-d.icon-sampling:before, i.ph-three-d.icon-isolation:before, i.ph-three-d.icon-strain:before, i.ph-three-d.icon-gps:before, i.ph-three-d.icon-location:before, i.ph-three-d.icon-add:before, i.ph-three-d.icon-left:before, i.ph-three-d.icon-dna:before, i.ph-three-d.icon-delete:before, i.ph-three-d.icon-edit:before, i.ph-three-d.icon-info:before {
  content: "\ea5a";
}

.ph.ph-thumbs-down:before, i.ph-thumbs-down.icon-dead:before, i.ph-thumbs-down.icon-alive:before, i.ph-thumbs-down.icon-warn:before, i.ph-thumbs-down.icon-sign-out:before, i.ph-thumbs-down.icon-substratum:before, i.ph-thumbs-down.icon-environment:before, i.ph-thumbs-down.icon-user:before, i.ph-thumbs-down.icon-person:before, i.ph-thumbs-down.icon-terrain:before, i.ph-thumbs-down.icon-taxon:before, i.ph-thumbs-down.icon-unknown:before, i.ph-thumbs-down.icon-nothing:before, i.ph-thumbs-down.icon-sampling:before, i.ph-thumbs-down.icon-isolation:before, i.ph-thumbs-down.icon-strain:before, i.ph-thumbs-down.icon-gps:before, i.ph-thumbs-down.icon-location:before, i.ph-thumbs-down.icon-add:before, i.ph-thumbs-down.icon-left:before, i.ph-thumbs-down.icon-dna:before, i.ph-thumbs-down.icon-delete:before, i.ph-thumbs-down.icon-edit:before, i.ph-thumbs-down.icon-info:before {
  content: "\e48c";
}

.ph.ph-thumbs-up:before, i.ph-thumbs-up.icon-dead:before, i.ph-thumbs-up.icon-alive:before, i.ph-thumbs-up.icon-warn:before, i.ph-thumbs-up.icon-sign-out:before, i.ph-thumbs-up.icon-substratum:before, i.ph-thumbs-up.icon-environment:before, i.ph-thumbs-up.icon-user:before, i.ph-thumbs-up.icon-person:before, i.ph-thumbs-up.icon-terrain:before, i.ph-thumbs-up.icon-taxon:before, i.ph-thumbs-up.icon-unknown:before, i.ph-thumbs-up.icon-nothing:before, i.ph-thumbs-up.icon-sampling:before, i.ph-thumbs-up.icon-isolation:before, i.ph-thumbs-up.icon-strain:before, i.ph-thumbs-up.icon-gps:before, i.ph-thumbs-up.icon-location:before, i.ph-thumbs-up.icon-add:before, i.ph-thumbs-up.icon-left:before, i.ph-thumbs-up.icon-dna:before, i.ph-thumbs-up.icon-delete:before, i.ph-thumbs-up.icon-edit:before, i.ph-thumbs-up.icon-info:before {
  content: "\e48e";
}

.ph.ph-ticket:before, i.ph-ticket.icon-dead:before, i.ph-ticket.icon-alive:before, i.ph-ticket.icon-warn:before, i.ph-ticket.icon-sign-out:before, i.ph-ticket.icon-substratum:before, i.ph-ticket.icon-environment:before, i.ph-ticket.icon-user:before, i.ph-ticket.icon-person:before, i.ph-ticket.icon-terrain:before, i.ph-ticket.icon-taxon:before, i.ph-ticket.icon-unknown:before, i.ph-ticket.icon-nothing:before, i.ph-ticket.icon-sampling:before, i.ph-ticket.icon-isolation:before, i.ph-ticket.icon-strain:before, i.ph-ticket.icon-gps:before, i.ph-ticket.icon-location:before, i.ph-ticket.icon-add:before, i.ph-ticket.icon-left:before, i.ph-ticket.icon-dna:before, i.ph-ticket.icon-delete:before, i.ph-ticket.icon-edit:before, i.ph-ticket.icon-info:before {
  content: "\e490";
}

.ph.ph-tidal-logo:before, i.ph-tidal-logo.icon-dead:before, i.ph-tidal-logo.icon-alive:before, i.ph-tidal-logo.icon-warn:before, i.ph-tidal-logo.icon-sign-out:before, i.ph-tidal-logo.icon-substratum:before, i.ph-tidal-logo.icon-environment:before, i.ph-tidal-logo.icon-user:before, i.ph-tidal-logo.icon-person:before, i.ph-tidal-logo.icon-terrain:before, i.ph-tidal-logo.icon-taxon:before, i.ph-tidal-logo.icon-unknown:before, i.ph-tidal-logo.icon-nothing:before, i.ph-tidal-logo.icon-sampling:before, i.ph-tidal-logo.icon-isolation:before, i.ph-tidal-logo.icon-strain:before, i.ph-tidal-logo.icon-gps:before, i.ph-tidal-logo.icon-location:before, i.ph-tidal-logo.icon-add:before, i.ph-tidal-logo.icon-left:before, i.ph-tidal-logo.icon-dna:before, i.ph-tidal-logo.icon-delete:before, i.ph-tidal-logo.icon-edit:before, i.ph-tidal-logo.icon-info:before {
  content: "\ed1c";
}

.ph.ph-tiktok-logo:before, i.ph-tiktok-logo.icon-dead:before, i.ph-tiktok-logo.icon-alive:before, i.ph-tiktok-logo.icon-warn:before, i.ph-tiktok-logo.icon-sign-out:before, i.ph-tiktok-logo.icon-substratum:before, i.ph-tiktok-logo.icon-environment:before, i.ph-tiktok-logo.icon-user:before, i.ph-tiktok-logo.icon-person:before, i.ph-tiktok-logo.icon-terrain:before, i.ph-tiktok-logo.icon-taxon:before, i.ph-tiktok-logo.icon-unknown:before, i.ph-tiktok-logo.icon-nothing:before, i.ph-tiktok-logo.icon-sampling:before, i.ph-tiktok-logo.icon-isolation:before, i.ph-tiktok-logo.icon-strain:before, i.ph-tiktok-logo.icon-gps:before, i.ph-tiktok-logo.icon-location:before, i.ph-tiktok-logo.icon-add:before, i.ph-tiktok-logo.icon-left:before, i.ph-tiktok-logo.icon-dna:before, i.ph-tiktok-logo.icon-delete:before, i.ph-tiktok-logo.icon-edit:before, i.ph-tiktok-logo.icon-info:before {
  content: "\eaf2";
}

.ph.ph-tilde:before, i.ph-tilde.icon-dead:before, i.ph-tilde.icon-alive:before, i.ph-tilde.icon-warn:before, i.ph-tilde.icon-sign-out:before, i.ph-tilde.icon-substratum:before, i.ph-tilde.icon-environment:before, i.ph-tilde.icon-user:before, i.ph-tilde.icon-person:before, i.ph-tilde.icon-terrain:before, i.ph-tilde.icon-taxon:before, i.ph-tilde.icon-unknown:before, i.ph-tilde.icon-nothing:before, i.ph-tilde.icon-sampling:before, i.ph-tilde.icon-isolation:before, i.ph-tilde.icon-strain:before, i.ph-tilde.icon-gps:before, i.ph-tilde.icon-location:before, i.ph-tilde.icon-add:before, i.ph-tilde.icon-left:before, i.ph-tilde.icon-dna:before, i.ph-tilde.icon-delete:before, i.ph-tilde.icon-edit:before, i.ph-tilde.icon-info:before {
  content: "\eda8";
}

.ph.ph-timer:before, i.ph-timer.icon-dead:before, i.ph-timer.icon-alive:before, i.ph-timer.icon-warn:before, i.ph-timer.icon-sign-out:before, i.ph-timer.icon-substratum:before, i.ph-timer.icon-environment:before, i.ph-timer.icon-user:before, i.ph-timer.icon-person:before, i.ph-timer.icon-terrain:before, i.ph-timer.icon-taxon:before, i.ph-timer.icon-unknown:before, i.ph-timer.icon-nothing:before, i.ph-timer.icon-sampling:before, i.ph-timer.icon-isolation:before, i.ph-timer.icon-strain:before, i.ph-timer.icon-gps:before, i.ph-timer.icon-location:before, i.ph-timer.icon-add:before, i.ph-timer.icon-left:before, i.ph-timer.icon-dna:before, i.ph-timer.icon-delete:before, i.ph-timer.icon-edit:before, i.ph-timer.icon-info:before {
  content: "\e492";
}

.ph.ph-tip-jar:before, i.ph-tip-jar.icon-dead:before, i.ph-tip-jar.icon-alive:before, i.ph-tip-jar.icon-warn:before, i.ph-tip-jar.icon-sign-out:before, i.ph-tip-jar.icon-substratum:before, i.ph-tip-jar.icon-environment:before, i.ph-tip-jar.icon-user:before, i.ph-tip-jar.icon-person:before, i.ph-tip-jar.icon-terrain:before, i.ph-tip-jar.icon-taxon:before, i.ph-tip-jar.icon-unknown:before, i.ph-tip-jar.icon-nothing:before, i.ph-tip-jar.icon-sampling:before, i.ph-tip-jar.icon-isolation:before, i.ph-tip-jar.icon-strain:before, i.ph-tip-jar.icon-gps:before, i.ph-tip-jar.icon-location:before, i.ph-tip-jar.icon-add:before, i.ph-tip-jar.icon-left:before, i.ph-tip-jar.icon-dna:before, i.ph-tip-jar.icon-delete:before, i.ph-tip-jar.icon-edit:before, i.ph-tip-jar.icon-info:before {
  content: "\e7e2";
}

.ph.ph-tipi:before, i.ph-tipi.icon-dead:before, i.ph-tipi.icon-alive:before, i.ph-tipi.icon-warn:before, i.ph-tipi.icon-sign-out:before, i.ph-tipi.icon-substratum:before, i.ph-tipi.icon-environment:before, i.ph-tipi.icon-user:before, i.ph-tipi.icon-person:before, i.ph-tipi.icon-terrain:before, i.ph-tipi.icon-taxon:before, i.ph-tipi.icon-unknown:before, i.ph-tipi.icon-nothing:before, i.ph-tipi.icon-sampling:before, i.ph-tipi.icon-isolation:before, i.ph-tipi.icon-strain:before, i.ph-tipi.icon-gps:before, i.ph-tipi.icon-location:before, i.ph-tipi.icon-add:before, i.ph-tipi.icon-left:before, i.ph-tipi.icon-dna:before, i.ph-tipi.icon-delete:before, i.ph-tipi.icon-edit:before, i.ph-tipi.icon-info:before {
  content: "\ed30";
}

.ph.ph-tire:before, i.ph-tire.icon-dead:before, i.ph-tire.icon-alive:before, i.ph-tire.icon-warn:before, i.ph-tire.icon-sign-out:before, i.ph-tire.icon-substratum:before, i.ph-tire.icon-environment:before, i.ph-tire.icon-user:before, i.ph-tire.icon-person:before, i.ph-tire.icon-terrain:before, i.ph-tire.icon-taxon:before, i.ph-tire.icon-unknown:before, i.ph-tire.icon-nothing:before, i.ph-tire.icon-sampling:before, i.ph-tire.icon-isolation:before, i.ph-tire.icon-strain:before, i.ph-tire.icon-gps:before, i.ph-tire.icon-location:before, i.ph-tire.icon-add:before, i.ph-tire.icon-left:before, i.ph-tire.icon-dna:before, i.ph-tire.icon-delete:before, i.ph-tire.icon-edit:before, i.ph-tire.icon-info:before {
  content: "\edd2";
}

.ph.ph-toggle-left:before, i.ph-toggle-left.icon-dead:before, i.ph-toggle-left.icon-alive:before, i.ph-toggle-left.icon-warn:before, i.ph-toggle-left.icon-sign-out:before, i.ph-toggle-left.icon-substratum:before, i.ph-toggle-left.icon-environment:before, i.ph-toggle-left.icon-user:before, i.ph-toggle-left.icon-person:before, i.ph-toggle-left.icon-terrain:before, i.ph-toggle-left.icon-taxon:before, i.ph-toggle-left.icon-unknown:before, i.ph-toggle-left.icon-nothing:before, i.ph-toggle-left.icon-sampling:before, i.ph-toggle-left.icon-isolation:before, i.ph-toggle-left.icon-strain:before, i.ph-toggle-left.icon-gps:before, i.ph-toggle-left.icon-location:before, i.ph-toggle-left.icon-add:before, i.ph-toggle-left.icon-left:before, i.ph-toggle-left.icon-dna:before, i.ph-toggle-left.icon-delete:before, i.ph-toggle-left.icon-edit:before, i.ph-toggle-left.icon-info:before {
  content: "\e674";
}

.ph.ph-toggle-right:before, i.ph-toggle-right.icon-dead:before, i.ph-toggle-right.icon-alive:before, i.ph-toggle-right.icon-warn:before, i.ph-toggle-right.icon-sign-out:before, i.ph-toggle-right.icon-substratum:before, i.ph-toggle-right.icon-environment:before, i.ph-toggle-right.icon-user:before, i.ph-toggle-right.icon-person:before, i.ph-toggle-right.icon-terrain:before, i.ph-toggle-right.icon-taxon:before, i.ph-toggle-right.icon-unknown:before, i.ph-toggle-right.icon-nothing:before, i.ph-toggle-right.icon-sampling:before, i.ph-toggle-right.icon-isolation:before, i.ph-toggle-right.icon-strain:before, i.ph-toggle-right.icon-gps:before, i.ph-toggle-right.icon-location:before, i.ph-toggle-right.icon-add:before, i.ph-toggle-right.icon-left:before, i.ph-toggle-right.icon-dna:before, i.ph-toggle-right.icon-delete:before, i.ph-toggle-right.icon-edit:before, i.ph-toggle-right.icon-info:before {
  content: "\e676";
}

.ph.ph-toilet:before, i.ph-toilet.icon-dead:before, i.ph-toilet.icon-alive:before, i.ph-toilet.icon-warn:before, i.ph-toilet.icon-sign-out:before, i.ph-toilet.icon-substratum:before, i.ph-toilet.icon-environment:before, i.ph-toilet.icon-user:before, i.ph-toilet.icon-person:before, i.ph-toilet.icon-terrain:before, i.ph-toilet.icon-taxon:before, i.ph-toilet.icon-unknown:before, i.ph-toilet.icon-nothing:before, i.ph-toilet.icon-sampling:before, i.ph-toilet.icon-isolation:before, i.ph-toilet.icon-strain:before, i.ph-toilet.icon-gps:before, i.ph-toilet.icon-location:before, i.ph-toilet.icon-add:before, i.ph-toilet.icon-left:before, i.ph-toilet.icon-dna:before, i.ph-toilet.icon-delete:before, i.ph-toilet.icon-edit:before, i.ph-toilet.icon-info:before {
  content: "\e79a";
}

.ph.ph-toilet-paper:before, i.ph-toilet-paper.icon-dead:before, i.ph-toilet-paper.icon-alive:before, i.ph-toilet-paper.icon-warn:before, i.ph-toilet-paper.icon-sign-out:before, i.ph-toilet-paper.icon-substratum:before, i.ph-toilet-paper.icon-environment:before, i.ph-toilet-paper.icon-user:before, i.ph-toilet-paper.icon-person:before, i.ph-toilet-paper.icon-terrain:before, i.ph-toilet-paper.icon-taxon:before, i.ph-toilet-paper.icon-unknown:before, i.ph-toilet-paper.icon-nothing:before, i.ph-toilet-paper.icon-sampling:before, i.ph-toilet-paper.icon-isolation:before, i.ph-toilet-paper.icon-strain:before, i.ph-toilet-paper.icon-gps:before, i.ph-toilet-paper.icon-location:before, i.ph-toilet-paper.icon-add:before, i.ph-toilet-paper.icon-left:before, i.ph-toilet-paper.icon-dna:before, i.ph-toilet-paper.icon-delete:before, i.ph-toilet-paper.icon-edit:before, i.ph-toilet-paper.icon-info:before {
  content: "\e79c";
}

.ph.ph-toolbox:before, i.ph-toolbox.icon-dead:before, i.ph-toolbox.icon-alive:before, i.ph-toolbox.icon-warn:before, i.ph-toolbox.icon-sign-out:before, i.ph-toolbox.icon-substratum:before, i.ph-toolbox.icon-environment:before, i.ph-toolbox.icon-user:before, i.ph-toolbox.icon-person:before, i.ph-toolbox.icon-terrain:before, i.ph-toolbox.icon-taxon:before, i.ph-toolbox.icon-unknown:before, i.ph-toolbox.icon-nothing:before, i.ph-toolbox.icon-sampling:before, i.ph-toolbox.icon-isolation:before, i.ph-toolbox.icon-strain:before, i.ph-toolbox.icon-gps:before, i.ph-toolbox.icon-location:before, i.ph-toolbox.icon-add:before, i.ph-toolbox.icon-left:before, i.ph-toolbox.icon-dna:before, i.ph-toolbox.icon-delete:before, i.ph-toolbox.icon-edit:before, i.ph-toolbox.icon-info:before {
  content: "\eca0";
}

.ph.ph-tooth:before, i.ph-tooth.icon-dead:before, i.ph-tooth.icon-alive:before, i.ph-tooth.icon-warn:before, i.ph-tooth.icon-sign-out:before, i.ph-tooth.icon-substratum:before, i.ph-tooth.icon-environment:before, i.ph-tooth.icon-user:before, i.ph-tooth.icon-person:before, i.ph-tooth.icon-terrain:before, i.ph-tooth.icon-taxon:before, i.ph-tooth.icon-unknown:before, i.ph-tooth.icon-nothing:before, i.ph-tooth.icon-sampling:before, i.ph-tooth.icon-isolation:before, i.ph-tooth.icon-strain:before, i.ph-tooth.icon-gps:before, i.ph-tooth.icon-location:before, i.ph-tooth.icon-add:before, i.ph-tooth.icon-left:before, i.ph-tooth.icon-dna:before, i.ph-tooth.icon-delete:before, i.ph-tooth.icon-edit:before, i.ph-tooth.icon-info:before {
  content: "\e9cc";
}

.ph.ph-tornado:before, i.ph-tornado.icon-dead:before, i.ph-tornado.icon-alive:before, i.ph-tornado.icon-warn:before, i.ph-tornado.icon-sign-out:before, i.ph-tornado.icon-substratum:before, i.ph-tornado.icon-environment:before, i.ph-tornado.icon-user:before, i.ph-tornado.icon-person:before, i.ph-tornado.icon-terrain:before, i.ph-tornado.icon-taxon:before, i.ph-tornado.icon-unknown:before, i.ph-tornado.icon-nothing:before, i.ph-tornado.icon-sampling:before, i.ph-tornado.icon-isolation:before, i.ph-tornado.icon-strain:before, i.ph-tornado.icon-gps:before, i.ph-tornado.icon-location:before, i.ph-tornado.icon-add:before, i.ph-tornado.icon-left:before, i.ph-tornado.icon-dna:before, i.ph-tornado.icon-delete:before, i.ph-tornado.icon-edit:before, i.ph-tornado.icon-info:before {
  content: "\e88c";
}

.ph.ph-tote:before, i.ph-tote.icon-dead:before, i.ph-tote.icon-alive:before, i.ph-tote.icon-warn:before, i.ph-tote.icon-sign-out:before, i.ph-tote.icon-substratum:before, i.ph-tote.icon-environment:before, i.ph-tote.icon-user:before, i.ph-tote.icon-person:before, i.ph-tote.icon-terrain:before, i.ph-tote.icon-taxon:before, i.ph-tote.icon-unknown:before, i.ph-tote.icon-nothing:before, i.ph-tote.icon-sampling:before, i.ph-tote.icon-isolation:before, i.ph-tote.icon-strain:before, i.ph-tote.icon-gps:before, i.ph-tote.icon-location:before, i.ph-tote.icon-add:before, i.ph-tote.icon-left:before, i.ph-tote.icon-dna:before, i.ph-tote.icon-delete:before, i.ph-tote.icon-edit:before, i.ph-tote.icon-info:before {
  content: "\e494";
}

.ph.ph-tote-simple:before, i.ph-tote-simple.icon-dead:before, i.ph-tote-simple.icon-alive:before, i.ph-tote-simple.icon-warn:before, i.ph-tote-simple.icon-sign-out:before, i.ph-tote-simple.icon-substratum:before, i.ph-tote-simple.icon-environment:before, i.ph-tote-simple.icon-user:before, i.ph-tote-simple.icon-person:before, i.ph-tote-simple.icon-terrain:before, i.ph-tote-simple.icon-taxon:before, i.ph-tote-simple.icon-unknown:before, i.ph-tote-simple.icon-nothing:before, i.ph-tote-simple.icon-sampling:before, i.ph-tote-simple.icon-isolation:before, i.ph-tote-simple.icon-strain:before, i.ph-tote-simple.icon-gps:before, i.ph-tote-simple.icon-location:before, i.ph-tote-simple.icon-add:before, i.ph-tote-simple.icon-left:before, i.ph-tote-simple.icon-dna:before, i.ph-tote-simple.icon-delete:before, i.ph-tote-simple.icon-edit:before, i.ph-tote-simple.icon-info:before {
  content: "\e678";
}

.ph.ph-towel:before, i.ph-towel.icon-dead:before, i.ph-towel.icon-alive:before, i.ph-towel.icon-warn:before, i.ph-towel.icon-sign-out:before, i.ph-towel.icon-substratum:before, i.ph-towel.icon-environment:before, i.ph-towel.icon-user:before, i.ph-towel.icon-person:before, i.ph-towel.icon-terrain:before, i.ph-towel.icon-taxon:before, i.ph-towel.icon-unknown:before, i.ph-towel.icon-nothing:before, i.ph-towel.icon-sampling:before, i.ph-towel.icon-isolation:before, i.ph-towel.icon-strain:before, i.ph-towel.icon-gps:before, i.ph-towel.icon-location:before, i.ph-towel.icon-add:before, i.ph-towel.icon-left:before, i.ph-towel.icon-dna:before, i.ph-towel.icon-delete:before, i.ph-towel.icon-edit:before, i.ph-towel.icon-info:before {
  content: "\ede6";
}

.ph.ph-tractor:before, i.ph-tractor.icon-dead:before, i.ph-tractor.icon-alive:before, i.ph-tractor.icon-warn:before, i.ph-tractor.icon-sign-out:before, i.ph-tractor.icon-substratum:before, i.ph-tractor.icon-environment:before, i.ph-tractor.icon-user:before, i.ph-tractor.icon-person:before, i.ph-tractor.icon-terrain:before, i.ph-tractor.icon-taxon:before, i.ph-tractor.icon-unknown:before, i.ph-tractor.icon-nothing:before, i.ph-tractor.icon-sampling:before, i.ph-tractor.icon-isolation:before, i.ph-tractor.icon-strain:before, i.ph-tractor.icon-gps:before, i.ph-tractor.icon-location:before, i.ph-tractor.icon-add:before, i.ph-tractor.icon-left:before, i.ph-tractor.icon-dna:before, i.ph-tractor.icon-delete:before, i.ph-tractor.icon-edit:before, i.ph-tractor.icon-info:before {
  content: "\ec6e";
}

.ph.ph-trademark:before, i.ph-trademark.icon-dead:before, i.ph-trademark.icon-alive:before, i.ph-trademark.icon-warn:before, i.ph-trademark.icon-sign-out:before, i.ph-trademark.icon-substratum:before, i.ph-trademark.icon-environment:before, i.ph-trademark.icon-user:before, i.ph-trademark.icon-person:before, i.ph-trademark.icon-terrain:before, i.ph-trademark.icon-taxon:before, i.ph-trademark.icon-unknown:before, i.ph-trademark.icon-nothing:before, i.ph-trademark.icon-sampling:before, i.ph-trademark.icon-isolation:before, i.ph-trademark.icon-strain:before, i.ph-trademark.icon-gps:before, i.ph-trademark.icon-location:before, i.ph-trademark.icon-add:before, i.ph-trademark.icon-left:before, i.ph-trademark.icon-dna:before, i.ph-trademark.icon-delete:before, i.ph-trademark.icon-edit:before, i.ph-trademark.icon-info:before {
  content: "\e9f0";
}

.ph.ph-trademark-registered:before, i.ph-trademark-registered.icon-dead:before, i.ph-trademark-registered.icon-alive:before, i.ph-trademark-registered.icon-warn:before, i.ph-trademark-registered.icon-sign-out:before, i.ph-trademark-registered.icon-substratum:before, i.ph-trademark-registered.icon-environment:before, i.ph-trademark-registered.icon-user:before, i.ph-trademark-registered.icon-person:before, i.ph-trademark-registered.icon-terrain:before, i.ph-trademark-registered.icon-taxon:before, i.ph-trademark-registered.icon-unknown:before, i.ph-trademark-registered.icon-nothing:before, i.ph-trademark-registered.icon-sampling:before, i.ph-trademark-registered.icon-isolation:before, i.ph-trademark-registered.icon-strain:before, i.ph-trademark-registered.icon-gps:before, i.ph-trademark-registered.icon-location:before, i.ph-trademark-registered.icon-add:before, i.ph-trademark-registered.icon-left:before, i.ph-trademark-registered.icon-dna:before, i.ph-trademark-registered.icon-delete:before, i.ph-trademark-registered.icon-edit:before, i.ph-trademark-registered.icon-info:before {
  content: "\e3f4";
}

.ph.ph-traffic-cone:before, i.ph-traffic-cone.icon-dead:before, i.ph-traffic-cone.icon-alive:before, i.ph-traffic-cone.icon-warn:before, i.ph-traffic-cone.icon-sign-out:before, i.ph-traffic-cone.icon-substratum:before, i.ph-traffic-cone.icon-environment:before, i.ph-traffic-cone.icon-user:before, i.ph-traffic-cone.icon-person:before, i.ph-traffic-cone.icon-terrain:before, i.ph-traffic-cone.icon-taxon:before, i.ph-traffic-cone.icon-unknown:before, i.ph-traffic-cone.icon-nothing:before, i.ph-traffic-cone.icon-sampling:before, i.ph-traffic-cone.icon-isolation:before, i.ph-traffic-cone.icon-strain:before, i.ph-traffic-cone.icon-gps:before, i.ph-traffic-cone.icon-location:before, i.ph-traffic-cone.icon-add:before, i.ph-traffic-cone.icon-left:before, i.ph-traffic-cone.icon-dna:before, i.ph-traffic-cone.icon-delete:before, i.ph-traffic-cone.icon-edit:before, i.ph-traffic-cone.icon-info:before {
  content: "\e9a8";
}

.ph.ph-traffic-sign:before, i.ph-traffic-sign.icon-dead:before, i.ph-traffic-sign.icon-alive:before, i.ph-traffic-sign.icon-warn:before, i.ph-traffic-sign.icon-sign-out:before, i.ph-traffic-sign.icon-substratum:before, i.ph-traffic-sign.icon-environment:before, i.ph-traffic-sign.icon-user:before, i.ph-traffic-sign.icon-person:before, i.ph-traffic-sign.icon-terrain:before, i.ph-traffic-sign.icon-taxon:before, i.ph-traffic-sign.icon-unknown:before, i.ph-traffic-sign.icon-nothing:before, i.ph-traffic-sign.icon-sampling:before, i.ph-traffic-sign.icon-isolation:before, i.ph-traffic-sign.icon-strain:before, i.ph-traffic-sign.icon-gps:before, i.ph-traffic-sign.icon-location:before, i.ph-traffic-sign.icon-add:before, i.ph-traffic-sign.icon-left:before, i.ph-traffic-sign.icon-dna:before, i.ph-traffic-sign.icon-delete:before, i.ph-traffic-sign.icon-edit:before, i.ph-traffic-sign.icon-info:before {
  content: "\e67a";
}

.ph.ph-traffic-signal:before, i.ph-traffic-signal.icon-dead:before, i.ph-traffic-signal.icon-alive:before, i.ph-traffic-signal.icon-warn:before, i.ph-traffic-signal.icon-sign-out:before, i.ph-traffic-signal.icon-substratum:before, i.ph-traffic-signal.icon-environment:before, i.ph-traffic-signal.icon-user:before, i.ph-traffic-signal.icon-person:before, i.ph-traffic-signal.icon-terrain:before, i.ph-traffic-signal.icon-taxon:before, i.ph-traffic-signal.icon-unknown:before, i.ph-traffic-signal.icon-nothing:before, i.ph-traffic-signal.icon-sampling:before, i.ph-traffic-signal.icon-isolation:before, i.ph-traffic-signal.icon-strain:before, i.ph-traffic-signal.icon-gps:before, i.ph-traffic-signal.icon-location:before, i.ph-traffic-signal.icon-add:before, i.ph-traffic-signal.icon-left:before, i.ph-traffic-signal.icon-dna:before, i.ph-traffic-signal.icon-delete:before, i.ph-traffic-signal.icon-edit:before, i.ph-traffic-signal.icon-info:before {
  content: "\e9aa";
}

.ph.ph-train:before, i.ph-train.icon-dead:before, i.ph-train.icon-alive:before, i.ph-train.icon-warn:before, i.ph-train.icon-sign-out:before, i.ph-train.icon-substratum:before, i.ph-train.icon-environment:before, i.ph-train.icon-user:before, i.ph-train.icon-person:before, i.ph-train.icon-terrain:before, i.ph-train.icon-taxon:before, i.ph-train.icon-unknown:before, i.ph-train.icon-nothing:before, i.ph-train.icon-sampling:before, i.ph-train.icon-isolation:before, i.ph-train.icon-strain:before, i.ph-train.icon-gps:before, i.ph-train.icon-location:before, i.ph-train.icon-add:before, i.ph-train.icon-left:before, i.ph-train.icon-dna:before, i.ph-train.icon-delete:before, i.ph-train.icon-edit:before, i.ph-train.icon-info:before {
  content: "\e496";
}

.ph.ph-train-regional:before, i.ph-train-regional.icon-dead:before, i.ph-train-regional.icon-alive:before, i.ph-train-regional.icon-warn:before, i.ph-train-regional.icon-sign-out:before, i.ph-train-regional.icon-substratum:before, i.ph-train-regional.icon-environment:before, i.ph-train-regional.icon-user:before, i.ph-train-regional.icon-person:before, i.ph-train-regional.icon-terrain:before, i.ph-train-regional.icon-taxon:before, i.ph-train-regional.icon-unknown:before, i.ph-train-regional.icon-nothing:before, i.ph-train-regional.icon-sampling:before, i.ph-train-regional.icon-isolation:before, i.ph-train-regional.icon-strain:before, i.ph-train-regional.icon-gps:before, i.ph-train-regional.icon-location:before, i.ph-train-regional.icon-add:before, i.ph-train-regional.icon-left:before, i.ph-train-regional.icon-dna:before, i.ph-train-regional.icon-delete:before, i.ph-train-regional.icon-edit:before, i.ph-train-regional.icon-info:before {
  content: "\e49e";
}

.ph.ph-train-simple:before, i.ph-train-simple.icon-dead:before, i.ph-train-simple.icon-alive:before, i.ph-train-simple.icon-warn:before, i.ph-train-simple.icon-sign-out:before, i.ph-train-simple.icon-substratum:before, i.ph-train-simple.icon-environment:before, i.ph-train-simple.icon-user:before, i.ph-train-simple.icon-person:before, i.ph-train-simple.icon-terrain:before, i.ph-train-simple.icon-taxon:before, i.ph-train-simple.icon-unknown:before, i.ph-train-simple.icon-nothing:before, i.ph-train-simple.icon-sampling:before, i.ph-train-simple.icon-isolation:before, i.ph-train-simple.icon-strain:before, i.ph-train-simple.icon-gps:before, i.ph-train-simple.icon-location:before, i.ph-train-simple.icon-add:before, i.ph-train-simple.icon-left:before, i.ph-train-simple.icon-dna:before, i.ph-train-simple.icon-delete:before, i.ph-train-simple.icon-edit:before, i.ph-train-simple.icon-info:before {
  content: "\e4a0";
}

.ph.ph-tram:before, i.ph-tram.icon-dead:before, i.ph-tram.icon-alive:before, i.ph-tram.icon-warn:before, i.ph-tram.icon-sign-out:before, i.ph-tram.icon-substratum:before, i.ph-tram.icon-environment:before, i.ph-tram.icon-user:before, i.ph-tram.icon-person:before, i.ph-tram.icon-terrain:before, i.ph-tram.icon-taxon:before, i.ph-tram.icon-unknown:before, i.ph-tram.icon-nothing:before, i.ph-tram.icon-sampling:before, i.ph-tram.icon-isolation:before, i.ph-tram.icon-strain:before, i.ph-tram.icon-gps:before, i.ph-tram.icon-location:before, i.ph-tram.icon-add:before, i.ph-tram.icon-left:before, i.ph-tram.icon-dna:before, i.ph-tram.icon-delete:before, i.ph-tram.icon-edit:before, i.ph-tram.icon-info:before {
  content: "\e9ec";
}

.ph.ph-translate:before, i.ph-translate.icon-dead:before, i.ph-translate.icon-alive:before, i.ph-translate.icon-warn:before, i.ph-translate.icon-sign-out:before, i.ph-translate.icon-substratum:before, i.ph-translate.icon-environment:before, i.ph-translate.icon-user:before, i.ph-translate.icon-person:before, i.ph-translate.icon-terrain:before, i.ph-translate.icon-taxon:before, i.ph-translate.icon-unknown:before, i.ph-translate.icon-nothing:before, i.ph-translate.icon-sampling:before, i.ph-translate.icon-isolation:before, i.ph-translate.icon-strain:before, i.ph-translate.icon-gps:before, i.ph-translate.icon-location:before, i.ph-translate.icon-add:before, i.ph-translate.icon-left:before, i.ph-translate.icon-dna:before, i.ph-translate.icon-delete:before, i.ph-translate.icon-edit:before, i.ph-translate.icon-info:before {
  content: "\e4a2";
}

.ph.ph-trash:before, i.ph-trash.icon-dead:before, i.ph-trash.icon-alive:before, i.ph-trash.icon-warn:before, i.ph-trash.icon-sign-out:before, i.ph-trash.icon-substratum:before, i.ph-trash.icon-environment:before, i.ph-trash.icon-user:before, i.ph-trash.icon-person:before, i.ph-trash.icon-terrain:before, i.ph-trash.icon-taxon:before, i.ph-trash.icon-unknown:before, i.ph-trash.icon-nothing:before, i.ph-trash.icon-sampling:before, i.ph-trash.icon-isolation:before, i.ph-trash.icon-strain:before, i.ph-trash.icon-gps:before, i.ph-trash.icon-location:before, i.ph-trash.icon-add:before, i.ph-trash.icon-left:before, i.ph-trash.icon-dna:before, i.icon-delete:before, i.ph-trash.icon-edit:before, i.ph-trash.icon-info:before {
  content: "\e4a6";
}

.ph.ph-trash-simple:before, i.ph-trash-simple.icon-dead:before, i.ph-trash-simple.icon-alive:before, i.ph-trash-simple.icon-warn:before, i.ph-trash-simple.icon-sign-out:before, i.ph-trash-simple.icon-substratum:before, i.ph-trash-simple.icon-environment:before, i.ph-trash-simple.icon-user:before, i.ph-trash-simple.icon-person:before, i.ph-trash-simple.icon-terrain:before, i.ph-trash-simple.icon-taxon:before, i.ph-trash-simple.icon-unknown:before, i.ph-trash-simple.icon-nothing:before, i.ph-trash-simple.icon-sampling:before, i.ph-trash-simple.icon-isolation:before, i.ph-trash-simple.icon-strain:before, i.ph-trash-simple.icon-gps:before, i.ph-trash-simple.icon-location:before, i.ph-trash-simple.icon-add:before, i.ph-trash-simple.icon-left:before, i.ph-trash-simple.icon-dna:before, i.ph-trash-simple.icon-delete:before, i.ph-trash-simple.icon-edit:before, i.ph-trash-simple.icon-info:before {
  content: "\e4a8";
}

.ph.ph-tray:before, i.ph-tray.icon-dead:before, i.ph-tray.icon-alive:before, i.ph-tray.icon-warn:before, i.ph-tray.icon-sign-out:before, i.ph-tray.icon-substratum:before, i.ph-tray.icon-environment:before, i.ph-tray.icon-user:before, i.ph-tray.icon-person:before, i.ph-tray.icon-terrain:before, i.ph-tray.icon-taxon:before, i.ph-tray.icon-unknown:before, i.ph-tray.icon-nothing:before, i.ph-tray.icon-sampling:before, i.ph-tray.icon-isolation:before, i.ph-tray.icon-strain:before, i.ph-tray.icon-gps:before, i.ph-tray.icon-location:before, i.ph-tray.icon-add:before, i.ph-tray.icon-left:before, i.ph-tray.icon-dna:before, i.ph-tray.icon-delete:before, i.ph-tray.icon-edit:before, i.ph-tray.icon-info:before {
  content: "\e4aa";
}

.ph.ph-tray-arrow-down:before, i.ph-tray-arrow-down.icon-dead:before, i.ph-tray-arrow-down.icon-alive:before, i.ph-tray-arrow-down.icon-warn:before, i.ph-tray-arrow-down.icon-sign-out:before, i.ph-tray-arrow-down.icon-substratum:before, i.ph-tray-arrow-down.icon-environment:before, i.ph-tray-arrow-down.icon-user:before, i.ph-tray-arrow-down.icon-person:before, i.ph-tray-arrow-down.icon-terrain:before, i.ph-tray-arrow-down.icon-taxon:before, i.ph-tray-arrow-down.icon-unknown:before, i.ph-tray-arrow-down.icon-nothing:before, i.ph-tray-arrow-down.icon-sampling:before, i.ph-tray-arrow-down.icon-isolation:before, i.ph-tray-arrow-down.icon-strain:before, i.ph-tray-arrow-down.icon-gps:before, i.ph-tray-arrow-down.icon-location:before, i.ph-tray-arrow-down.icon-add:before, i.ph-tray-arrow-down.icon-left:before, i.ph-tray-arrow-down.icon-dna:before, i.ph-tray-arrow-down.icon-delete:before, i.ph-tray-arrow-down.icon-edit:before, i.ph-tray-arrow-down.icon-info:before {
  content: "\e010";
}

.ph.ph-archive-tray:before, i.ph-archive-tray.icon-dead:before, i.ph-archive-tray.icon-alive:before, i.ph-archive-tray.icon-warn:before, i.ph-archive-tray.icon-sign-out:before, i.ph-archive-tray.icon-substratum:before, i.ph-archive-tray.icon-environment:before, i.ph-archive-tray.icon-user:before, i.ph-archive-tray.icon-person:before, i.ph-archive-tray.icon-terrain:before, i.ph-archive-tray.icon-taxon:before, i.ph-archive-tray.icon-unknown:before, i.ph-archive-tray.icon-nothing:before, i.ph-archive-tray.icon-sampling:before, i.ph-archive-tray.icon-isolation:before, i.ph-archive-tray.icon-strain:before, i.ph-archive-tray.icon-gps:before, i.ph-archive-tray.icon-location:before, i.ph-archive-tray.icon-add:before, i.ph-archive-tray.icon-left:before, i.ph-archive-tray.icon-dna:before, i.ph-archive-tray.icon-delete:before, i.ph-archive-tray.icon-edit:before, i.ph-archive-tray.icon-info:before {
  content: "\e010";
}

.ph.ph-tray-arrow-up:before, i.ph-tray-arrow-up.icon-dead:before, i.ph-tray-arrow-up.icon-alive:before, i.ph-tray-arrow-up.icon-warn:before, i.ph-tray-arrow-up.icon-sign-out:before, i.ph-tray-arrow-up.icon-substratum:before, i.ph-tray-arrow-up.icon-environment:before, i.ph-tray-arrow-up.icon-user:before, i.ph-tray-arrow-up.icon-person:before, i.ph-tray-arrow-up.icon-terrain:before, i.ph-tray-arrow-up.icon-taxon:before, i.ph-tray-arrow-up.icon-unknown:before, i.ph-tray-arrow-up.icon-nothing:before, i.ph-tray-arrow-up.icon-sampling:before, i.ph-tray-arrow-up.icon-isolation:before, i.ph-tray-arrow-up.icon-strain:before, i.ph-tray-arrow-up.icon-gps:before, i.ph-tray-arrow-up.icon-location:before, i.ph-tray-arrow-up.icon-add:before, i.ph-tray-arrow-up.icon-left:before, i.ph-tray-arrow-up.icon-dna:before, i.ph-tray-arrow-up.icon-delete:before, i.ph-tray-arrow-up.icon-edit:before, i.ph-tray-arrow-up.icon-info:before {
  content: "\ee52";
}

.ph.ph-treasure-chest:before, i.ph-treasure-chest.icon-dead:before, i.ph-treasure-chest.icon-alive:before, i.ph-treasure-chest.icon-warn:before, i.ph-treasure-chest.icon-sign-out:before, i.ph-treasure-chest.icon-substratum:before, i.ph-treasure-chest.icon-environment:before, i.ph-treasure-chest.icon-user:before, i.ph-treasure-chest.icon-person:before, i.ph-treasure-chest.icon-terrain:before, i.ph-treasure-chest.icon-taxon:before, i.ph-treasure-chest.icon-unknown:before, i.ph-treasure-chest.icon-nothing:before, i.ph-treasure-chest.icon-sampling:before, i.ph-treasure-chest.icon-isolation:before, i.ph-treasure-chest.icon-strain:before, i.ph-treasure-chest.icon-gps:before, i.ph-treasure-chest.icon-location:before, i.ph-treasure-chest.icon-add:before, i.ph-treasure-chest.icon-left:before, i.ph-treasure-chest.icon-dna:before, i.ph-treasure-chest.icon-delete:before, i.ph-treasure-chest.icon-edit:before, i.ph-treasure-chest.icon-info:before {
  content: "\ede2";
}

.ph.ph-tree:before, i.ph-tree.icon-dead:before, i.ph-tree.icon-alive:before, i.ph-tree.icon-warn:before, i.ph-tree.icon-sign-out:before, i.ph-tree.icon-substratum:before, i.ph-tree.icon-environment:before, i.ph-tree.icon-user:before, i.ph-tree.icon-person:before, i.ph-tree.icon-terrain:before, i.ph-tree.icon-taxon:before, i.ph-tree.icon-unknown:before, i.ph-tree.icon-nothing:before, i.ph-tree.icon-sampling:before, i.ph-tree.icon-isolation:before, i.ph-tree.icon-strain:before, i.ph-tree.icon-gps:before, i.ph-tree.icon-location:before, i.ph-tree.icon-add:before, i.ph-tree.icon-left:before, i.ph-tree.icon-dna:before, i.ph-tree.icon-delete:before, i.ph-tree.icon-edit:before, i.ph-tree.icon-info:before {
  content: "\e6da";
}

.ph.ph-tree-evergreen:before, i.ph-tree-evergreen.icon-dead:before, i.ph-tree-evergreen.icon-alive:before, i.ph-tree-evergreen.icon-warn:before, i.ph-tree-evergreen.icon-sign-out:before, i.ph-tree-evergreen.icon-substratum:before, i.ph-tree-evergreen.icon-environment:before, i.ph-tree-evergreen.icon-user:before, i.ph-tree-evergreen.icon-person:before, i.ph-tree-evergreen.icon-terrain:before, i.ph-tree-evergreen.icon-taxon:before, i.ph-tree-evergreen.icon-unknown:before, i.ph-tree-evergreen.icon-nothing:before, i.ph-tree-evergreen.icon-sampling:before, i.ph-tree-evergreen.icon-isolation:before, i.ph-tree-evergreen.icon-strain:before, i.ph-tree-evergreen.icon-gps:before, i.ph-tree-evergreen.icon-location:before, i.ph-tree-evergreen.icon-add:before, i.ph-tree-evergreen.icon-left:before, i.ph-tree-evergreen.icon-dna:before, i.ph-tree-evergreen.icon-delete:before, i.ph-tree-evergreen.icon-edit:before, i.ph-tree-evergreen.icon-info:before {
  content: "\e6dc";
}

.ph.ph-tree-palm:before, i.ph-tree-palm.icon-dead:before, i.ph-tree-palm.icon-alive:before, i.ph-tree-palm.icon-warn:before, i.ph-tree-palm.icon-sign-out:before, i.ph-tree-palm.icon-substratum:before, i.icon-environment:before, i.ph-tree-palm.icon-user:before, i.ph-tree-palm.icon-person:before, i.ph-tree-palm.icon-terrain:before, i.ph-tree-palm.icon-taxon:before, i.ph-tree-palm.icon-unknown:before, i.ph-tree-palm.icon-nothing:before, i.ph-tree-palm.icon-sampling:before, i.ph-tree-palm.icon-isolation:before, i.ph-tree-palm.icon-strain:before, i.ph-tree-palm.icon-gps:before, i.ph-tree-palm.icon-location:before, i.ph-tree-palm.icon-add:before, i.ph-tree-palm.icon-left:before, i.ph-tree-palm.icon-dna:before, i.ph-tree-palm.icon-delete:before, i.ph-tree-palm.icon-edit:before, i.ph-tree-palm.icon-info:before {
  content: "\e91a";
}

.ph.ph-tree-structure:before, i.ph-tree-structure.icon-dead:before, i.ph-tree-structure.icon-alive:before, i.ph-tree-structure.icon-warn:before, i.ph-tree-structure.icon-sign-out:before, i.ph-tree-structure.icon-substratum:before, i.ph-tree-structure.icon-environment:before, i.ph-tree-structure.icon-user:before, i.ph-tree-structure.icon-person:before, i.ph-tree-structure.icon-terrain:before, i.icon-taxon:before, i.ph-tree-structure.icon-unknown:before, i.ph-tree-structure.icon-nothing:before, i.ph-tree-structure.icon-sampling:before, i.ph-tree-structure.icon-isolation:before, i.ph-tree-structure.icon-strain:before, i.ph-tree-structure.icon-gps:before, i.ph-tree-structure.icon-location:before, i.ph-tree-structure.icon-add:before, i.ph-tree-structure.icon-left:before, i.ph-tree-structure.icon-dna:before, i.ph-tree-structure.icon-delete:before, i.ph-tree-structure.icon-edit:before, i.ph-tree-structure.icon-info:before {
  content: "\e67c";
}

.ph.ph-tree-view:before, i.ph-tree-view.icon-dead:before, i.ph-tree-view.icon-alive:before, i.ph-tree-view.icon-warn:before, i.ph-tree-view.icon-sign-out:before, i.ph-tree-view.icon-substratum:before, i.ph-tree-view.icon-environment:before, i.ph-tree-view.icon-user:before, i.ph-tree-view.icon-person:before, i.ph-tree-view.icon-terrain:before, i.ph-tree-view.icon-taxon:before, i.ph-tree-view.icon-unknown:before, i.ph-tree-view.icon-nothing:before, i.ph-tree-view.icon-sampling:before, i.ph-tree-view.icon-isolation:before, i.ph-tree-view.icon-strain:before, i.ph-tree-view.icon-gps:before, i.ph-tree-view.icon-location:before, i.ph-tree-view.icon-add:before, i.ph-tree-view.icon-left:before, i.ph-tree-view.icon-dna:before, i.ph-tree-view.icon-delete:before, i.ph-tree-view.icon-edit:before, i.ph-tree-view.icon-info:before {
  content: "\ee48";
}

.ph.ph-trend-down:before, i.ph-trend-down.icon-dead:before, i.ph-trend-down.icon-alive:before, i.ph-trend-down.icon-warn:before, i.ph-trend-down.icon-sign-out:before, i.ph-trend-down.icon-substratum:before, i.ph-trend-down.icon-environment:before, i.ph-trend-down.icon-user:before, i.ph-trend-down.icon-person:before, i.ph-trend-down.icon-terrain:before, i.ph-trend-down.icon-taxon:before, i.ph-trend-down.icon-unknown:before, i.ph-trend-down.icon-nothing:before, i.ph-trend-down.icon-sampling:before, i.ph-trend-down.icon-isolation:before, i.ph-trend-down.icon-strain:before, i.ph-trend-down.icon-gps:before, i.ph-trend-down.icon-location:before, i.ph-trend-down.icon-add:before, i.ph-trend-down.icon-left:before, i.ph-trend-down.icon-dna:before, i.ph-trend-down.icon-delete:before, i.ph-trend-down.icon-edit:before, i.ph-trend-down.icon-info:before {
  content: "\e4ac";
}

.ph.ph-trend-up:before, i.ph-trend-up.icon-dead:before, i.ph-trend-up.icon-alive:before, i.ph-trend-up.icon-warn:before, i.ph-trend-up.icon-sign-out:before, i.ph-trend-up.icon-substratum:before, i.ph-trend-up.icon-environment:before, i.ph-trend-up.icon-user:before, i.ph-trend-up.icon-person:before, i.ph-trend-up.icon-terrain:before, i.ph-trend-up.icon-taxon:before, i.ph-trend-up.icon-unknown:before, i.ph-trend-up.icon-nothing:before, i.ph-trend-up.icon-sampling:before, i.ph-trend-up.icon-isolation:before, i.ph-trend-up.icon-strain:before, i.ph-trend-up.icon-gps:before, i.ph-trend-up.icon-location:before, i.ph-trend-up.icon-add:before, i.ph-trend-up.icon-left:before, i.ph-trend-up.icon-dna:before, i.ph-trend-up.icon-delete:before, i.ph-trend-up.icon-edit:before, i.ph-trend-up.icon-info:before {
  content: "\e4ae";
}

.ph.ph-triangle:before, i.ph-triangle.icon-dead:before, i.ph-triangle.icon-alive:before, i.ph-triangle.icon-warn:before, i.ph-triangle.icon-sign-out:before, i.ph-triangle.icon-substratum:before, i.ph-triangle.icon-environment:before, i.ph-triangle.icon-user:before, i.ph-triangle.icon-person:before, i.ph-triangle.icon-terrain:before, i.ph-triangle.icon-taxon:before, i.ph-triangle.icon-unknown:before, i.ph-triangle.icon-nothing:before, i.ph-triangle.icon-sampling:before, i.ph-triangle.icon-isolation:before, i.ph-triangle.icon-strain:before, i.ph-triangle.icon-gps:before, i.ph-triangle.icon-location:before, i.ph-triangle.icon-add:before, i.ph-triangle.icon-left:before, i.ph-triangle.icon-dna:before, i.ph-triangle.icon-delete:before, i.ph-triangle.icon-edit:before, i.ph-triangle.icon-info:before {
  content: "\e4b0";
}

.ph.ph-triangle-dashed:before, i.ph-triangle-dashed.icon-dead:before, i.ph-triangle-dashed.icon-alive:before, i.ph-triangle-dashed.icon-warn:before, i.ph-triangle-dashed.icon-sign-out:before, i.ph-triangle-dashed.icon-substratum:before, i.ph-triangle-dashed.icon-environment:before, i.ph-triangle-dashed.icon-user:before, i.ph-triangle-dashed.icon-person:before, i.ph-triangle-dashed.icon-terrain:before, i.ph-triangle-dashed.icon-taxon:before, i.ph-triangle-dashed.icon-unknown:before, i.ph-triangle-dashed.icon-nothing:before, i.ph-triangle-dashed.icon-sampling:before, i.ph-triangle-dashed.icon-isolation:before, i.ph-triangle-dashed.icon-strain:before, i.ph-triangle-dashed.icon-gps:before, i.ph-triangle-dashed.icon-location:before, i.ph-triangle-dashed.icon-add:before, i.ph-triangle-dashed.icon-left:before, i.ph-triangle-dashed.icon-dna:before, i.ph-triangle-dashed.icon-delete:before, i.ph-triangle-dashed.icon-edit:before, i.ph-triangle-dashed.icon-info:before {
  content: "\e4b2";
}

.ph.ph-trolley:before, i.ph-trolley.icon-dead:before, i.ph-trolley.icon-alive:before, i.ph-trolley.icon-warn:before, i.ph-trolley.icon-sign-out:before, i.ph-trolley.icon-substratum:before, i.ph-trolley.icon-environment:before, i.ph-trolley.icon-user:before, i.ph-trolley.icon-person:before, i.ph-trolley.icon-terrain:before, i.ph-trolley.icon-taxon:before, i.ph-trolley.icon-unknown:before, i.ph-trolley.icon-nothing:before, i.ph-trolley.icon-sampling:before, i.ph-trolley.icon-isolation:before, i.ph-trolley.icon-strain:before, i.ph-trolley.icon-gps:before, i.ph-trolley.icon-location:before, i.ph-trolley.icon-add:before, i.ph-trolley.icon-left:before, i.ph-trolley.icon-dna:before, i.ph-trolley.icon-delete:before, i.ph-trolley.icon-edit:before, i.ph-trolley.icon-info:before {
  content: "\e5b2";
}

.ph.ph-trolley-suitcase:before, i.ph-trolley-suitcase.icon-dead:before, i.ph-trolley-suitcase.icon-alive:before, i.ph-trolley-suitcase.icon-warn:before, i.ph-trolley-suitcase.icon-sign-out:before, i.ph-trolley-suitcase.icon-substratum:before, i.ph-trolley-suitcase.icon-environment:before, i.ph-trolley-suitcase.icon-user:before, i.ph-trolley-suitcase.icon-person:before, i.ph-trolley-suitcase.icon-terrain:before, i.ph-trolley-suitcase.icon-taxon:before, i.ph-trolley-suitcase.icon-unknown:before, i.ph-trolley-suitcase.icon-nothing:before, i.ph-trolley-suitcase.icon-sampling:before, i.ph-trolley-suitcase.icon-isolation:before, i.ph-trolley-suitcase.icon-strain:before, i.ph-trolley-suitcase.icon-gps:before, i.ph-trolley-suitcase.icon-location:before, i.ph-trolley-suitcase.icon-add:before, i.ph-trolley-suitcase.icon-left:before, i.ph-trolley-suitcase.icon-dna:before, i.ph-trolley-suitcase.icon-delete:before, i.ph-trolley-suitcase.icon-edit:before, i.ph-trolley-suitcase.icon-info:before {
  content: "\e5b4";
}

.ph.ph-trophy:before, i.ph-trophy.icon-dead:before, i.ph-trophy.icon-alive:before, i.ph-trophy.icon-warn:before, i.ph-trophy.icon-sign-out:before, i.ph-trophy.icon-substratum:before, i.ph-trophy.icon-environment:before, i.ph-trophy.icon-user:before, i.ph-trophy.icon-person:before, i.ph-trophy.icon-terrain:before, i.ph-trophy.icon-taxon:before, i.ph-trophy.icon-unknown:before, i.ph-trophy.icon-nothing:before, i.ph-trophy.icon-sampling:before, i.ph-trophy.icon-isolation:before, i.ph-trophy.icon-strain:before, i.ph-trophy.icon-gps:before, i.ph-trophy.icon-location:before, i.ph-trophy.icon-add:before, i.ph-trophy.icon-left:before, i.ph-trophy.icon-dna:before, i.ph-trophy.icon-delete:before, i.ph-trophy.icon-edit:before, i.ph-trophy.icon-info:before {
  content: "\e67e";
}

.ph.ph-truck:before, i.ph-truck.icon-dead:before, i.ph-truck.icon-alive:before, i.ph-truck.icon-warn:before, i.ph-truck.icon-sign-out:before, i.ph-truck.icon-substratum:before, i.ph-truck.icon-environment:before, i.ph-truck.icon-user:before, i.ph-truck.icon-person:before, i.ph-truck.icon-terrain:before, i.ph-truck.icon-taxon:before, i.ph-truck.icon-unknown:before, i.ph-truck.icon-nothing:before, i.ph-truck.icon-sampling:before, i.ph-truck.icon-isolation:before, i.ph-truck.icon-strain:before, i.ph-truck.icon-gps:before, i.ph-truck.icon-location:before, i.ph-truck.icon-add:before, i.ph-truck.icon-left:before, i.ph-truck.icon-dna:before, i.ph-truck.icon-delete:before, i.ph-truck.icon-edit:before, i.ph-truck.icon-info:before {
  content: "\e4b4";
}

.ph.ph-truck-trailer:before, i.ph-truck-trailer.icon-dead:before, i.ph-truck-trailer.icon-alive:before, i.ph-truck-trailer.icon-warn:before, i.ph-truck-trailer.icon-sign-out:before, i.ph-truck-trailer.icon-substratum:before, i.ph-truck-trailer.icon-environment:before, i.ph-truck-trailer.icon-user:before, i.ph-truck-trailer.icon-person:before, i.ph-truck-trailer.icon-terrain:before, i.ph-truck-trailer.icon-taxon:before, i.ph-truck-trailer.icon-unknown:before, i.ph-truck-trailer.icon-nothing:before, i.ph-truck-trailer.icon-sampling:before, i.ph-truck-trailer.icon-isolation:before, i.ph-truck-trailer.icon-strain:before, i.ph-truck-trailer.icon-gps:before, i.ph-truck-trailer.icon-location:before, i.ph-truck-trailer.icon-add:before, i.ph-truck-trailer.icon-left:before, i.ph-truck-trailer.icon-dna:before, i.ph-truck-trailer.icon-delete:before, i.ph-truck-trailer.icon-edit:before, i.ph-truck-trailer.icon-info:before {
  content: "\e4b6";
}

.ph.ph-tumblr-logo:before, i.ph-tumblr-logo.icon-dead:before, i.ph-tumblr-logo.icon-alive:before, i.ph-tumblr-logo.icon-warn:before, i.ph-tumblr-logo.icon-sign-out:before, i.ph-tumblr-logo.icon-substratum:before, i.ph-tumblr-logo.icon-environment:before, i.ph-tumblr-logo.icon-user:before, i.ph-tumblr-logo.icon-person:before, i.ph-tumblr-logo.icon-terrain:before, i.ph-tumblr-logo.icon-taxon:before, i.ph-tumblr-logo.icon-unknown:before, i.ph-tumblr-logo.icon-nothing:before, i.ph-tumblr-logo.icon-sampling:before, i.ph-tumblr-logo.icon-isolation:before, i.ph-tumblr-logo.icon-strain:before, i.ph-tumblr-logo.icon-gps:before, i.ph-tumblr-logo.icon-location:before, i.ph-tumblr-logo.icon-add:before, i.ph-tumblr-logo.icon-left:before, i.ph-tumblr-logo.icon-dna:before, i.ph-tumblr-logo.icon-delete:before, i.ph-tumblr-logo.icon-edit:before, i.ph-tumblr-logo.icon-info:before {
  content: "\e8d4";
}

.ph.ph-twitch-logo:before, i.ph-twitch-logo.icon-dead:before, i.ph-twitch-logo.icon-alive:before, i.ph-twitch-logo.icon-warn:before, i.ph-twitch-logo.icon-sign-out:before, i.ph-twitch-logo.icon-substratum:before, i.ph-twitch-logo.icon-environment:before, i.ph-twitch-logo.icon-user:before, i.ph-twitch-logo.icon-person:before, i.ph-twitch-logo.icon-terrain:before, i.ph-twitch-logo.icon-taxon:before, i.ph-twitch-logo.icon-unknown:before, i.ph-twitch-logo.icon-nothing:before, i.ph-twitch-logo.icon-sampling:before, i.ph-twitch-logo.icon-isolation:before, i.ph-twitch-logo.icon-strain:before, i.ph-twitch-logo.icon-gps:before, i.ph-twitch-logo.icon-location:before, i.ph-twitch-logo.icon-add:before, i.ph-twitch-logo.icon-left:before, i.ph-twitch-logo.icon-dna:before, i.ph-twitch-logo.icon-delete:before, i.ph-twitch-logo.icon-edit:before, i.ph-twitch-logo.icon-info:before {
  content: "\e5ce";
}

.ph.ph-twitter-logo:before, i.ph-twitter-logo.icon-dead:before, i.ph-twitter-logo.icon-alive:before, i.ph-twitter-logo.icon-warn:before, i.ph-twitter-logo.icon-sign-out:before, i.ph-twitter-logo.icon-substratum:before, i.ph-twitter-logo.icon-environment:before, i.ph-twitter-logo.icon-user:before, i.ph-twitter-logo.icon-person:before, i.ph-twitter-logo.icon-terrain:before, i.ph-twitter-logo.icon-taxon:before, i.ph-twitter-logo.icon-unknown:before, i.ph-twitter-logo.icon-nothing:before, i.ph-twitter-logo.icon-sampling:before, i.ph-twitter-logo.icon-isolation:before, i.ph-twitter-logo.icon-strain:before, i.ph-twitter-logo.icon-gps:before, i.ph-twitter-logo.icon-location:before, i.ph-twitter-logo.icon-add:before, i.ph-twitter-logo.icon-left:before, i.ph-twitter-logo.icon-dna:before, i.ph-twitter-logo.icon-delete:before, i.ph-twitter-logo.icon-edit:before, i.ph-twitter-logo.icon-info:before {
  content: "\e4ba";
}

.ph.ph-umbrella:before, i.ph-umbrella.icon-dead:before, i.ph-umbrella.icon-alive:before, i.ph-umbrella.icon-warn:before, i.ph-umbrella.icon-sign-out:before, i.ph-umbrella.icon-substratum:before, i.ph-umbrella.icon-environment:before, i.ph-umbrella.icon-user:before, i.ph-umbrella.icon-person:before, i.ph-umbrella.icon-terrain:before, i.ph-umbrella.icon-taxon:before, i.ph-umbrella.icon-unknown:before, i.ph-umbrella.icon-nothing:before, i.ph-umbrella.icon-sampling:before, i.ph-umbrella.icon-isolation:before, i.ph-umbrella.icon-strain:before, i.ph-umbrella.icon-gps:before, i.ph-umbrella.icon-location:before, i.ph-umbrella.icon-add:before, i.ph-umbrella.icon-left:before, i.ph-umbrella.icon-dna:before, i.ph-umbrella.icon-delete:before, i.ph-umbrella.icon-edit:before, i.ph-umbrella.icon-info:before {
  content: "\e684";
}

.ph.ph-umbrella-simple:before, i.ph-umbrella-simple.icon-dead:before, i.ph-umbrella-simple.icon-alive:before, i.ph-umbrella-simple.icon-warn:before, i.ph-umbrella-simple.icon-sign-out:before, i.ph-umbrella-simple.icon-substratum:before, i.ph-umbrella-simple.icon-environment:before, i.ph-umbrella-simple.icon-user:before, i.ph-umbrella-simple.icon-person:before, i.ph-umbrella-simple.icon-terrain:before, i.ph-umbrella-simple.icon-taxon:before, i.ph-umbrella-simple.icon-unknown:before, i.ph-umbrella-simple.icon-nothing:before, i.ph-umbrella-simple.icon-sampling:before, i.ph-umbrella-simple.icon-isolation:before, i.ph-umbrella-simple.icon-strain:before, i.ph-umbrella-simple.icon-gps:before, i.ph-umbrella-simple.icon-location:before, i.ph-umbrella-simple.icon-add:before, i.ph-umbrella-simple.icon-left:before, i.ph-umbrella-simple.icon-dna:before, i.ph-umbrella-simple.icon-delete:before, i.ph-umbrella-simple.icon-edit:before, i.ph-umbrella-simple.icon-info:before {
  content: "\e686";
}

.ph.ph-union:before, i.ph-union.icon-dead:before, i.ph-union.icon-alive:before, i.ph-union.icon-warn:before, i.ph-union.icon-sign-out:before, i.ph-union.icon-substratum:before, i.ph-union.icon-environment:before, i.ph-union.icon-user:before, i.ph-union.icon-person:before, i.ph-union.icon-terrain:before, i.ph-union.icon-taxon:before, i.ph-union.icon-unknown:before, i.ph-union.icon-nothing:before, i.ph-union.icon-sampling:before, i.ph-union.icon-isolation:before, i.ph-union.icon-strain:before, i.ph-union.icon-gps:before, i.ph-union.icon-location:before, i.ph-union.icon-add:before, i.ph-union.icon-left:before, i.ph-union.icon-dna:before, i.ph-union.icon-delete:before, i.ph-union.icon-edit:before, i.ph-union.icon-info:before {
  content: "\edbe";
}

.ph.ph-unite:before, i.ph-unite.icon-dead:before, i.ph-unite.icon-alive:before, i.ph-unite.icon-warn:before, i.ph-unite.icon-sign-out:before, i.ph-unite.icon-substratum:before, i.ph-unite.icon-environment:before, i.ph-unite.icon-user:before, i.ph-unite.icon-person:before, i.ph-unite.icon-terrain:before, i.ph-unite.icon-taxon:before, i.ph-unite.icon-unknown:before, i.ph-unite.icon-nothing:before, i.ph-unite.icon-sampling:before, i.ph-unite.icon-isolation:before, i.ph-unite.icon-strain:before, i.ph-unite.icon-gps:before, i.ph-unite.icon-location:before, i.ph-unite.icon-add:before, i.ph-unite.icon-left:before, i.ph-unite.icon-dna:before, i.ph-unite.icon-delete:before, i.ph-unite.icon-edit:before, i.ph-unite.icon-info:before {
  content: "\e87e";
}

.ph.ph-unite-square:before, i.ph-unite-square.icon-dead:before, i.ph-unite-square.icon-alive:before, i.ph-unite-square.icon-warn:before, i.ph-unite-square.icon-sign-out:before, i.ph-unite-square.icon-substratum:before, i.ph-unite-square.icon-environment:before, i.ph-unite-square.icon-user:before, i.ph-unite-square.icon-person:before, i.ph-unite-square.icon-terrain:before, i.ph-unite-square.icon-taxon:before, i.ph-unite-square.icon-unknown:before, i.ph-unite-square.icon-nothing:before, i.ph-unite-square.icon-sampling:before, i.ph-unite-square.icon-isolation:before, i.ph-unite-square.icon-strain:before, i.ph-unite-square.icon-gps:before, i.ph-unite-square.icon-location:before, i.ph-unite-square.icon-add:before, i.ph-unite-square.icon-left:before, i.ph-unite-square.icon-dna:before, i.ph-unite-square.icon-delete:before, i.ph-unite-square.icon-edit:before, i.ph-unite-square.icon-info:before {
  content: "\e878";
}

.ph.ph-upload:before, i.ph-upload.icon-dead:before, i.ph-upload.icon-alive:before, i.ph-upload.icon-warn:before, i.ph-upload.icon-sign-out:before, i.ph-upload.icon-substratum:before, i.ph-upload.icon-environment:before, i.ph-upload.icon-user:before, i.ph-upload.icon-person:before, i.ph-upload.icon-terrain:before, i.ph-upload.icon-taxon:before, i.ph-upload.icon-unknown:before, i.ph-upload.icon-nothing:before, i.ph-upload.icon-sampling:before, i.ph-upload.icon-isolation:before, i.ph-upload.icon-strain:before, i.ph-upload.icon-gps:before, i.ph-upload.icon-location:before, i.ph-upload.icon-add:before, i.ph-upload.icon-left:before, i.ph-upload.icon-dna:before, i.ph-upload.icon-delete:before, i.ph-upload.icon-edit:before, i.ph-upload.icon-info:before {
  content: "\e4be";
}

.ph.ph-upload-simple:before, i.ph-upload-simple.icon-dead:before, i.ph-upload-simple.icon-alive:before, i.ph-upload-simple.icon-warn:before, i.ph-upload-simple.icon-sign-out:before, i.ph-upload-simple.icon-substratum:before, i.ph-upload-simple.icon-environment:before, i.ph-upload-simple.icon-user:before, i.ph-upload-simple.icon-person:before, i.ph-upload-simple.icon-terrain:before, i.ph-upload-simple.icon-taxon:before, i.ph-upload-simple.icon-unknown:before, i.ph-upload-simple.icon-nothing:before, i.ph-upload-simple.icon-sampling:before, i.ph-upload-simple.icon-isolation:before, i.ph-upload-simple.icon-strain:before, i.ph-upload-simple.icon-gps:before, i.ph-upload-simple.icon-location:before, i.ph-upload-simple.icon-add:before, i.ph-upload-simple.icon-left:before, i.ph-upload-simple.icon-dna:before, i.ph-upload-simple.icon-delete:before, i.ph-upload-simple.icon-edit:before, i.ph-upload-simple.icon-info:before {
  content: "\e4c0";
}

.ph.ph-usb:before, i.ph-usb.icon-dead:before, i.ph-usb.icon-alive:before, i.ph-usb.icon-warn:before, i.ph-usb.icon-sign-out:before, i.ph-usb.icon-substratum:before, i.ph-usb.icon-environment:before, i.ph-usb.icon-user:before, i.ph-usb.icon-person:before, i.ph-usb.icon-terrain:before, i.ph-usb.icon-taxon:before, i.ph-usb.icon-unknown:before, i.ph-usb.icon-nothing:before, i.ph-usb.icon-sampling:before, i.ph-usb.icon-isolation:before, i.ph-usb.icon-strain:before, i.ph-usb.icon-gps:before, i.ph-usb.icon-location:before, i.ph-usb.icon-add:before, i.ph-usb.icon-left:before, i.ph-usb.icon-dna:before, i.ph-usb.icon-delete:before, i.ph-usb.icon-edit:before, i.ph-usb.icon-info:before {
  content: "\e956";
}

.ph.ph-user:before, i.ph-user.icon-dead:before, i.ph-user.icon-alive:before, i.ph-user.icon-warn:before, i.ph-user.icon-sign-out:before, i.ph-user.icon-substratum:before, i.ph-user.icon-environment:before, i.ph-user.icon-user:before, i.icon-person:before, i.ph-user.icon-terrain:before, i.ph-user.icon-taxon:before, i.ph-user.icon-unknown:before, i.ph-user.icon-nothing:before, i.ph-user.icon-sampling:before, i.ph-user.icon-isolation:before, i.ph-user.icon-strain:before, i.ph-user.icon-gps:before, i.ph-user.icon-location:before, i.ph-user.icon-add:before, i.ph-user.icon-left:before, i.ph-user.icon-dna:before, i.ph-user.icon-delete:before, i.ph-user.icon-edit:before, i.ph-user.icon-info:before {
  content: "\e4c2";
}

.ph.ph-user-check:before, i.ph-user-check.icon-dead:before, i.ph-user-check.icon-alive:before, i.ph-user-check.icon-warn:before, i.ph-user-check.icon-sign-out:before, i.ph-user-check.icon-substratum:before, i.ph-user-check.icon-environment:before, i.ph-user-check.icon-user:before, i.ph-user-check.icon-person:before, i.ph-user-check.icon-terrain:before, i.ph-user-check.icon-taxon:before, i.ph-user-check.icon-unknown:before, i.ph-user-check.icon-nothing:before, i.ph-user-check.icon-sampling:before, i.ph-user-check.icon-isolation:before, i.ph-user-check.icon-strain:before, i.ph-user-check.icon-gps:before, i.ph-user-check.icon-location:before, i.ph-user-check.icon-add:before, i.ph-user-check.icon-left:before, i.ph-user-check.icon-dna:before, i.ph-user-check.icon-delete:before, i.ph-user-check.icon-edit:before, i.ph-user-check.icon-info:before {
  content: "\eafa";
}

.ph.ph-user-circle:before, i.ph-user-circle.icon-dead:before, i.ph-user-circle.icon-alive:before, i.ph-user-circle.icon-warn:before, i.ph-user-circle.icon-sign-out:before, i.ph-user-circle.icon-substratum:before, i.ph-user-circle.icon-environment:before, i.icon-user:before, i.ph-user-circle.icon-person:before, i.ph-user-circle.icon-terrain:before, i.ph-user-circle.icon-taxon:before, i.ph-user-circle.icon-unknown:before, i.ph-user-circle.icon-nothing:before, i.ph-user-circle.icon-sampling:before, i.ph-user-circle.icon-isolation:before, i.ph-user-circle.icon-strain:before, i.ph-user-circle.icon-gps:before, i.ph-user-circle.icon-location:before, i.ph-user-circle.icon-add:before, i.ph-user-circle.icon-left:before, i.ph-user-circle.icon-dna:before, i.ph-user-circle.icon-delete:before, i.ph-user-circle.icon-edit:before, i.ph-user-circle.icon-info:before {
  content: "\e4c4";
}

.ph.ph-user-circle-check:before, i.ph-user-circle-check.icon-dead:before, i.ph-user-circle-check.icon-alive:before, i.ph-user-circle-check.icon-warn:before, i.ph-user-circle-check.icon-sign-out:before, i.ph-user-circle-check.icon-substratum:before, i.ph-user-circle-check.icon-environment:before, i.ph-user-circle-check.icon-user:before, i.ph-user-circle-check.icon-person:before, i.ph-user-circle-check.icon-terrain:before, i.ph-user-circle-check.icon-taxon:before, i.ph-user-circle-check.icon-unknown:before, i.ph-user-circle-check.icon-nothing:before, i.ph-user-circle-check.icon-sampling:before, i.ph-user-circle-check.icon-isolation:before, i.ph-user-circle-check.icon-strain:before, i.ph-user-circle-check.icon-gps:before, i.ph-user-circle-check.icon-location:before, i.ph-user-circle-check.icon-add:before, i.ph-user-circle-check.icon-left:before, i.ph-user-circle-check.icon-dna:before, i.ph-user-circle-check.icon-delete:before, i.ph-user-circle-check.icon-edit:before, i.ph-user-circle-check.icon-info:before {
  content: "\ec38";
}

.ph.ph-user-circle-dashed:before, i.ph-user-circle-dashed.icon-dead:before, i.ph-user-circle-dashed.icon-alive:before, i.ph-user-circle-dashed.icon-warn:before, i.ph-user-circle-dashed.icon-sign-out:before, i.ph-user-circle-dashed.icon-substratum:before, i.ph-user-circle-dashed.icon-environment:before, i.ph-user-circle-dashed.icon-user:before, i.ph-user-circle-dashed.icon-person:before, i.ph-user-circle-dashed.icon-terrain:before, i.ph-user-circle-dashed.icon-taxon:before, i.ph-user-circle-dashed.icon-unknown:before, i.ph-user-circle-dashed.icon-nothing:before, i.ph-user-circle-dashed.icon-sampling:before, i.ph-user-circle-dashed.icon-isolation:before, i.ph-user-circle-dashed.icon-strain:before, i.ph-user-circle-dashed.icon-gps:before, i.ph-user-circle-dashed.icon-location:before, i.ph-user-circle-dashed.icon-add:before, i.ph-user-circle-dashed.icon-left:before, i.ph-user-circle-dashed.icon-dna:before, i.ph-user-circle-dashed.icon-delete:before, i.ph-user-circle-dashed.icon-edit:before, i.ph-user-circle-dashed.icon-info:before {
  content: "\ec36";
}

.ph.ph-user-circle-gear:before, i.ph-user-circle-gear.icon-dead:before, i.ph-user-circle-gear.icon-alive:before, i.ph-user-circle-gear.icon-warn:before, i.ph-user-circle-gear.icon-sign-out:before, i.ph-user-circle-gear.icon-substratum:before, i.ph-user-circle-gear.icon-environment:before, i.ph-user-circle-gear.icon-user:before, i.ph-user-circle-gear.icon-person:before, i.ph-user-circle-gear.icon-terrain:before, i.ph-user-circle-gear.icon-taxon:before, i.ph-user-circle-gear.icon-unknown:before, i.ph-user-circle-gear.icon-nothing:before, i.ph-user-circle-gear.icon-sampling:before, i.ph-user-circle-gear.icon-isolation:before, i.ph-user-circle-gear.icon-strain:before, i.ph-user-circle-gear.icon-gps:before, i.ph-user-circle-gear.icon-location:before, i.ph-user-circle-gear.icon-add:before, i.ph-user-circle-gear.icon-left:before, i.ph-user-circle-gear.icon-dna:before, i.ph-user-circle-gear.icon-delete:before, i.ph-user-circle-gear.icon-edit:before, i.ph-user-circle-gear.icon-info:before {
  content: "\e4c6";
}

.ph.ph-user-circle-minus:before, i.ph-user-circle-minus.icon-dead:before, i.ph-user-circle-minus.icon-alive:before, i.ph-user-circle-minus.icon-warn:before, i.ph-user-circle-minus.icon-sign-out:before, i.ph-user-circle-minus.icon-substratum:before, i.ph-user-circle-minus.icon-environment:before, i.ph-user-circle-minus.icon-user:before, i.ph-user-circle-minus.icon-person:before, i.ph-user-circle-minus.icon-terrain:before, i.ph-user-circle-minus.icon-taxon:before, i.ph-user-circle-minus.icon-unknown:before, i.ph-user-circle-minus.icon-nothing:before, i.ph-user-circle-minus.icon-sampling:before, i.ph-user-circle-minus.icon-isolation:before, i.ph-user-circle-minus.icon-strain:before, i.ph-user-circle-minus.icon-gps:before, i.ph-user-circle-minus.icon-location:before, i.ph-user-circle-minus.icon-add:before, i.ph-user-circle-minus.icon-left:before, i.ph-user-circle-minus.icon-dna:before, i.ph-user-circle-minus.icon-delete:before, i.ph-user-circle-minus.icon-edit:before, i.ph-user-circle-minus.icon-info:before {
  content: "\e4c8";
}

.ph.ph-user-circle-plus:before, i.ph-user-circle-plus.icon-dead:before, i.ph-user-circle-plus.icon-alive:before, i.ph-user-circle-plus.icon-warn:before, i.ph-user-circle-plus.icon-sign-out:before, i.ph-user-circle-plus.icon-substratum:before, i.ph-user-circle-plus.icon-environment:before, i.ph-user-circle-plus.icon-user:before, i.ph-user-circle-plus.icon-person:before, i.ph-user-circle-plus.icon-terrain:before, i.ph-user-circle-plus.icon-taxon:before, i.ph-user-circle-plus.icon-unknown:before, i.ph-user-circle-plus.icon-nothing:before, i.ph-user-circle-plus.icon-sampling:before, i.ph-user-circle-plus.icon-isolation:before, i.ph-user-circle-plus.icon-strain:before, i.ph-user-circle-plus.icon-gps:before, i.ph-user-circle-plus.icon-location:before, i.ph-user-circle-plus.icon-add:before, i.ph-user-circle-plus.icon-left:before, i.ph-user-circle-plus.icon-dna:before, i.ph-user-circle-plus.icon-delete:before, i.ph-user-circle-plus.icon-edit:before, i.ph-user-circle-plus.icon-info:before {
  content: "\e4ca";
}

.ph.ph-user-focus:before, i.ph-user-focus.icon-dead:before, i.ph-user-focus.icon-alive:before, i.ph-user-focus.icon-warn:before, i.ph-user-focus.icon-sign-out:before, i.ph-user-focus.icon-substratum:before, i.ph-user-focus.icon-environment:before, i.ph-user-focus.icon-user:before, i.ph-user-focus.icon-person:before, i.ph-user-focus.icon-terrain:before, i.ph-user-focus.icon-taxon:before, i.ph-user-focus.icon-unknown:before, i.ph-user-focus.icon-nothing:before, i.ph-user-focus.icon-sampling:before, i.ph-user-focus.icon-isolation:before, i.ph-user-focus.icon-strain:before, i.ph-user-focus.icon-gps:before, i.ph-user-focus.icon-location:before, i.ph-user-focus.icon-add:before, i.ph-user-focus.icon-left:before, i.ph-user-focus.icon-dna:before, i.ph-user-focus.icon-delete:before, i.ph-user-focus.icon-edit:before, i.ph-user-focus.icon-info:before {
  content: "\e6fc";
}

.ph.ph-user-gear:before, i.ph-user-gear.icon-dead:before, i.ph-user-gear.icon-alive:before, i.ph-user-gear.icon-warn:before, i.ph-user-gear.icon-sign-out:before, i.ph-user-gear.icon-substratum:before, i.ph-user-gear.icon-environment:before, i.ph-user-gear.icon-user:before, i.ph-user-gear.icon-person:before, i.ph-user-gear.icon-terrain:before, i.ph-user-gear.icon-taxon:before, i.ph-user-gear.icon-unknown:before, i.ph-user-gear.icon-nothing:before, i.ph-user-gear.icon-sampling:before, i.ph-user-gear.icon-isolation:before, i.ph-user-gear.icon-strain:before, i.ph-user-gear.icon-gps:before, i.ph-user-gear.icon-location:before, i.ph-user-gear.icon-add:before, i.ph-user-gear.icon-left:before, i.ph-user-gear.icon-dna:before, i.ph-user-gear.icon-delete:before, i.ph-user-gear.icon-edit:before, i.ph-user-gear.icon-info:before {
  content: "\e4cc";
}

.ph.ph-user-list:before, i.ph-user-list.icon-dead:before, i.ph-user-list.icon-alive:before, i.ph-user-list.icon-warn:before, i.ph-user-list.icon-sign-out:before, i.ph-user-list.icon-substratum:before, i.ph-user-list.icon-environment:before, i.ph-user-list.icon-user:before, i.ph-user-list.icon-person:before, i.ph-user-list.icon-terrain:before, i.ph-user-list.icon-taxon:before, i.ph-user-list.icon-unknown:before, i.ph-user-list.icon-nothing:before, i.ph-user-list.icon-sampling:before, i.ph-user-list.icon-isolation:before, i.ph-user-list.icon-strain:before, i.ph-user-list.icon-gps:before, i.ph-user-list.icon-location:before, i.ph-user-list.icon-add:before, i.ph-user-list.icon-left:before, i.ph-user-list.icon-dna:before, i.ph-user-list.icon-delete:before, i.ph-user-list.icon-edit:before, i.ph-user-list.icon-info:before {
  content: "\e73c";
}

.ph.ph-user-minus:before, i.ph-user-minus.icon-dead:before, i.ph-user-minus.icon-alive:before, i.ph-user-minus.icon-warn:before, i.ph-user-minus.icon-sign-out:before, i.ph-user-minus.icon-substratum:before, i.ph-user-minus.icon-environment:before, i.ph-user-minus.icon-user:before, i.ph-user-minus.icon-person:before, i.ph-user-minus.icon-terrain:before, i.ph-user-minus.icon-taxon:before, i.ph-user-minus.icon-unknown:before, i.ph-user-minus.icon-nothing:before, i.ph-user-minus.icon-sampling:before, i.ph-user-minus.icon-isolation:before, i.ph-user-minus.icon-strain:before, i.ph-user-minus.icon-gps:before, i.ph-user-minus.icon-location:before, i.ph-user-minus.icon-add:before, i.ph-user-minus.icon-left:before, i.ph-user-minus.icon-dna:before, i.ph-user-minus.icon-delete:before, i.ph-user-minus.icon-edit:before, i.ph-user-minus.icon-info:before {
  content: "\e4ce";
}

.ph.ph-user-plus:before, i.ph-user-plus.icon-dead:before, i.ph-user-plus.icon-alive:before, i.ph-user-plus.icon-warn:before, i.ph-user-plus.icon-sign-out:before, i.ph-user-plus.icon-substratum:before, i.ph-user-plus.icon-environment:before, i.ph-user-plus.icon-user:before, i.ph-user-plus.icon-person:before, i.ph-user-plus.icon-terrain:before, i.ph-user-plus.icon-taxon:before, i.ph-user-plus.icon-unknown:before, i.ph-user-plus.icon-nothing:before, i.ph-user-plus.icon-sampling:before, i.ph-user-plus.icon-isolation:before, i.ph-user-plus.icon-strain:before, i.ph-user-plus.icon-gps:before, i.ph-user-plus.icon-location:before, i.ph-user-plus.icon-add:before, i.ph-user-plus.icon-left:before, i.ph-user-plus.icon-dna:before, i.ph-user-plus.icon-delete:before, i.ph-user-plus.icon-edit:before, i.ph-user-plus.icon-info:before {
  content: "\e4d0";
}

.ph.ph-user-rectangle:before, i.ph-user-rectangle.icon-dead:before, i.ph-user-rectangle.icon-alive:before, i.ph-user-rectangle.icon-warn:before, i.ph-user-rectangle.icon-sign-out:before, i.ph-user-rectangle.icon-substratum:before, i.ph-user-rectangle.icon-environment:before, i.ph-user-rectangle.icon-user:before, i.ph-user-rectangle.icon-person:before, i.ph-user-rectangle.icon-terrain:before, i.ph-user-rectangle.icon-taxon:before, i.ph-user-rectangle.icon-unknown:before, i.ph-user-rectangle.icon-nothing:before, i.ph-user-rectangle.icon-sampling:before, i.ph-user-rectangle.icon-isolation:before, i.ph-user-rectangle.icon-strain:before, i.ph-user-rectangle.icon-gps:before, i.ph-user-rectangle.icon-location:before, i.ph-user-rectangle.icon-add:before, i.ph-user-rectangle.icon-left:before, i.ph-user-rectangle.icon-dna:before, i.ph-user-rectangle.icon-delete:before, i.ph-user-rectangle.icon-edit:before, i.ph-user-rectangle.icon-info:before {
  content: "\e4d2";
}

.ph.ph-user-sound:before, i.ph-user-sound.icon-dead:before, i.ph-user-sound.icon-alive:before, i.ph-user-sound.icon-warn:before, i.ph-user-sound.icon-sign-out:before, i.ph-user-sound.icon-substratum:before, i.ph-user-sound.icon-environment:before, i.ph-user-sound.icon-user:before, i.ph-user-sound.icon-person:before, i.ph-user-sound.icon-terrain:before, i.ph-user-sound.icon-taxon:before, i.ph-user-sound.icon-unknown:before, i.ph-user-sound.icon-nothing:before, i.ph-user-sound.icon-sampling:before, i.ph-user-sound.icon-isolation:before, i.ph-user-sound.icon-strain:before, i.ph-user-sound.icon-gps:before, i.ph-user-sound.icon-location:before, i.ph-user-sound.icon-add:before, i.ph-user-sound.icon-left:before, i.ph-user-sound.icon-dna:before, i.ph-user-sound.icon-delete:before, i.ph-user-sound.icon-edit:before, i.ph-user-sound.icon-info:before {
  content: "\eca8";
}

.ph.ph-user-square:before, i.ph-user-square.icon-dead:before, i.ph-user-square.icon-alive:before, i.ph-user-square.icon-warn:before, i.ph-user-square.icon-sign-out:before, i.ph-user-square.icon-substratum:before, i.ph-user-square.icon-environment:before, i.ph-user-square.icon-user:before, i.ph-user-square.icon-person:before, i.ph-user-square.icon-terrain:before, i.ph-user-square.icon-taxon:before, i.ph-user-square.icon-unknown:before, i.ph-user-square.icon-nothing:before, i.ph-user-square.icon-sampling:before, i.ph-user-square.icon-isolation:before, i.ph-user-square.icon-strain:before, i.ph-user-square.icon-gps:before, i.ph-user-square.icon-location:before, i.ph-user-square.icon-add:before, i.ph-user-square.icon-left:before, i.ph-user-square.icon-dna:before, i.ph-user-square.icon-delete:before, i.ph-user-square.icon-edit:before, i.ph-user-square.icon-info:before {
  content: "\e4d4";
}

.ph.ph-user-switch:before, i.ph-user-switch.icon-dead:before, i.ph-user-switch.icon-alive:before, i.ph-user-switch.icon-warn:before, i.ph-user-switch.icon-sign-out:before, i.ph-user-switch.icon-substratum:before, i.ph-user-switch.icon-environment:before, i.ph-user-switch.icon-user:before, i.ph-user-switch.icon-person:before, i.ph-user-switch.icon-terrain:before, i.ph-user-switch.icon-taxon:before, i.ph-user-switch.icon-unknown:before, i.ph-user-switch.icon-nothing:before, i.ph-user-switch.icon-sampling:before, i.ph-user-switch.icon-isolation:before, i.ph-user-switch.icon-strain:before, i.ph-user-switch.icon-gps:before, i.ph-user-switch.icon-location:before, i.ph-user-switch.icon-add:before, i.ph-user-switch.icon-left:before, i.ph-user-switch.icon-dna:before, i.ph-user-switch.icon-delete:before, i.ph-user-switch.icon-edit:before, i.ph-user-switch.icon-info:before {
  content: "\e756";
}

.ph.ph-users:before, i.ph-users.icon-dead:before, i.ph-users.icon-alive:before, i.ph-users.icon-warn:before, i.ph-users.icon-sign-out:before, i.ph-users.icon-substratum:before, i.ph-users.icon-environment:before, i.ph-users.icon-user:before, i.ph-users.icon-person:before, i.ph-users.icon-terrain:before, i.ph-users.icon-taxon:before, i.ph-users.icon-unknown:before, i.ph-users.icon-nothing:before, i.ph-users.icon-sampling:before, i.ph-users.icon-isolation:before, i.ph-users.icon-strain:before, i.ph-users.icon-gps:before, i.ph-users.icon-location:before, i.ph-users.icon-add:before, i.ph-users.icon-left:before, i.ph-users.icon-dna:before, i.ph-users.icon-delete:before, i.ph-users.icon-edit:before, i.ph-users.icon-info:before {
  content: "\e4d6";
}

.ph.ph-users-four:before, i.ph-users-four.icon-dead:before, i.ph-users-four.icon-alive:before, i.ph-users-four.icon-warn:before, i.ph-users-four.icon-sign-out:before, i.ph-users-four.icon-substratum:before, i.ph-users-four.icon-environment:before, i.ph-users-four.icon-user:before, i.ph-users-four.icon-person:before, i.ph-users-four.icon-terrain:before, i.ph-users-four.icon-taxon:before, i.ph-users-four.icon-unknown:before, i.ph-users-four.icon-nothing:before, i.ph-users-four.icon-sampling:before, i.ph-users-four.icon-isolation:before, i.ph-users-four.icon-strain:before, i.ph-users-four.icon-gps:before, i.ph-users-four.icon-location:before, i.ph-users-four.icon-add:before, i.ph-users-four.icon-left:before, i.ph-users-four.icon-dna:before, i.ph-users-four.icon-delete:before, i.ph-users-four.icon-edit:before, i.ph-users-four.icon-info:before {
  content: "\e68c";
}

.ph.ph-users-three:before, i.ph-users-three.icon-dead:before, i.ph-users-three.icon-alive:before, i.ph-users-three.icon-warn:before, i.ph-users-three.icon-sign-out:before, i.ph-users-three.icon-substratum:before, i.ph-users-three.icon-environment:before, i.ph-users-three.icon-user:before, i.ph-users-three.icon-person:before, i.ph-users-three.icon-terrain:before, i.ph-users-three.icon-taxon:before, i.ph-users-three.icon-unknown:before, i.ph-users-three.icon-nothing:before, i.ph-users-three.icon-sampling:before, i.ph-users-three.icon-isolation:before, i.ph-users-three.icon-strain:before, i.ph-users-three.icon-gps:before, i.ph-users-three.icon-location:before, i.ph-users-three.icon-add:before, i.ph-users-three.icon-left:before, i.ph-users-three.icon-dna:before, i.ph-users-three.icon-delete:before, i.ph-users-three.icon-edit:before, i.ph-users-three.icon-info:before {
  content: "\e68e";
}

.ph.ph-van:before, i.ph-van.icon-dead:before, i.ph-van.icon-alive:before, i.ph-van.icon-warn:before, i.ph-van.icon-sign-out:before, i.ph-van.icon-substratum:before, i.ph-van.icon-environment:before, i.ph-van.icon-user:before, i.ph-van.icon-person:before, i.ph-van.icon-terrain:before, i.ph-van.icon-taxon:before, i.ph-van.icon-unknown:before, i.ph-van.icon-nothing:before, i.ph-van.icon-sampling:before, i.ph-van.icon-isolation:before, i.ph-van.icon-strain:before, i.ph-van.icon-gps:before, i.ph-van.icon-location:before, i.ph-van.icon-add:before, i.ph-van.icon-left:before, i.ph-van.icon-dna:before, i.ph-van.icon-delete:before, i.ph-van.icon-edit:before, i.ph-van.icon-info:before {
  content: "\e826";
}

.ph.ph-vault:before, i.ph-vault.icon-dead:before, i.ph-vault.icon-alive:before, i.ph-vault.icon-warn:before, i.ph-vault.icon-sign-out:before, i.ph-vault.icon-substratum:before, i.ph-vault.icon-environment:before, i.ph-vault.icon-user:before, i.ph-vault.icon-person:before, i.ph-vault.icon-terrain:before, i.ph-vault.icon-taxon:before, i.ph-vault.icon-unknown:before, i.ph-vault.icon-nothing:before, i.ph-vault.icon-sampling:before, i.ph-vault.icon-isolation:before, i.ph-vault.icon-strain:before, i.ph-vault.icon-gps:before, i.ph-vault.icon-location:before, i.ph-vault.icon-add:before, i.ph-vault.icon-left:before, i.ph-vault.icon-dna:before, i.ph-vault.icon-delete:before, i.ph-vault.icon-edit:before, i.ph-vault.icon-info:before {
  content: "\e76e";
}

.ph.ph-vector-three:before, i.ph-vector-three.icon-dead:before, i.ph-vector-three.icon-alive:before, i.ph-vector-three.icon-warn:before, i.ph-vector-three.icon-sign-out:before, i.ph-vector-three.icon-substratum:before, i.ph-vector-three.icon-environment:before, i.ph-vector-three.icon-user:before, i.ph-vector-three.icon-person:before, i.ph-vector-three.icon-terrain:before, i.ph-vector-three.icon-taxon:before, i.ph-vector-three.icon-unknown:before, i.ph-vector-three.icon-nothing:before, i.ph-vector-three.icon-sampling:before, i.ph-vector-three.icon-isolation:before, i.ph-vector-three.icon-strain:before, i.ph-vector-three.icon-gps:before, i.ph-vector-three.icon-location:before, i.ph-vector-three.icon-add:before, i.ph-vector-three.icon-left:before, i.ph-vector-three.icon-dna:before, i.ph-vector-three.icon-delete:before, i.ph-vector-three.icon-edit:before, i.ph-vector-three.icon-info:before {
  content: "\ee62";
}

.ph.ph-vector-two:before, i.ph-vector-two.icon-dead:before, i.ph-vector-two.icon-alive:before, i.ph-vector-two.icon-warn:before, i.ph-vector-two.icon-sign-out:before, i.ph-vector-two.icon-substratum:before, i.ph-vector-two.icon-environment:before, i.ph-vector-two.icon-user:before, i.ph-vector-two.icon-person:before, i.ph-vector-two.icon-terrain:before, i.ph-vector-two.icon-taxon:before, i.ph-vector-two.icon-unknown:before, i.ph-vector-two.icon-nothing:before, i.ph-vector-two.icon-sampling:before, i.ph-vector-two.icon-isolation:before, i.ph-vector-two.icon-strain:before, i.ph-vector-two.icon-gps:before, i.ph-vector-two.icon-location:before, i.ph-vector-two.icon-add:before, i.ph-vector-two.icon-left:before, i.ph-vector-two.icon-dna:before, i.ph-vector-two.icon-delete:before, i.ph-vector-two.icon-edit:before, i.ph-vector-two.icon-info:before {
  content: "\ee64";
}

.ph.ph-vibrate:before, i.ph-vibrate.icon-dead:before, i.ph-vibrate.icon-alive:before, i.ph-vibrate.icon-warn:before, i.ph-vibrate.icon-sign-out:before, i.ph-vibrate.icon-substratum:before, i.ph-vibrate.icon-environment:before, i.ph-vibrate.icon-user:before, i.ph-vibrate.icon-person:before, i.ph-vibrate.icon-terrain:before, i.ph-vibrate.icon-taxon:before, i.ph-vibrate.icon-unknown:before, i.ph-vibrate.icon-nothing:before, i.ph-vibrate.icon-sampling:before, i.ph-vibrate.icon-isolation:before, i.ph-vibrate.icon-strain:before, i.ph-vibrate.icon-gps:before, i.ph-vibrate.icon-location:before, i.ph-vibrate.icon-add:before, i.ph-vibrate.icon-left:before, i.ph-vibrate.icon-dna:before, i.ph-vibrate.icon-delete:before, i.ph-vibrate.icon-edit:before, i.ph-vibrate.icon-info:before {
  content: "\e4d8";
}

.ph.ph-video:before, i.ph-video.icon-dead:before, i.ph-video.icon-alive:before, i.ph-video.icon-warn:before, i.ph-video.icon-sign-out:before, i.ph-video.icon-substratum:before, i.ph-video.icon-environment:before, i.ph-video.icon-user:before, i.ph-video.icon-person:before, i.ph-video.icon-terrain:before, i.ph-video.icon-taxon:before, i.ph-video.icon-unknown:before, i.ph-video.icon-nothing:before, i.ph-video.icon-sampling:before, i.ph-video.icon-isolation:before, i.ph-video.icon-strain:before, i.ph-video.icon-gps:before, i.ph-video.icon-location:before, i.ph-video.icon-add:before, i.ph-video.icon-left:before, i.ph-video.icon-dna:before, i.ph-video.icon-delete:before, i.ph-video.icon-edit:before, i.ph-video.icon-info:before {
  content: "\e740";
}

.ph.ph-video-camera:before, i.ph-video-camera.icon-dead:before, i.ph-video-camera.icon-alive:before, i.ph-video-camera.icon-warn:before, i.ph-video-camera.icon-sign-out:before, i.ph-video-camera.icon-substratum:before, i.ph-video-camera.icon-environment:before, i.ph-video-camera.icon-user:before, i.ph-video-camera.icon-person:before, i.ph-video-camera.icon-terrain:before, i.ph-video-camera.icon-taxon:before, i.ph-video-camera.icon-unknown:before, i.ph-video-camera.icon-nothing:before, i.ph-video-camera.icon-sampling:before, i.ph-video-camera.icon-isolation:before, i.ph-video-camera.icon-strain:before, i.ph-video-camera.icon-gps:before, i.ph-video-camera.icon-location:before, i.ph-video-camera.icon-add:before, i.ph-video-camera.icon-left:before, i.ph-video-camera.icon-dna:before, i.ph-video-camera.icon-delete:before, i.ph-video-camera.icon-edit:before, i.ph-video-camera.icon-info:before {
  content: "\e4da";
}

.ph.ph-video-camera-slash:before, i.ph-video-camera-slash.icon-dead:before, i.ph-video-camera-slash.icon-alive:before, i.ph-video-camera-slash.icon-warn:before, i.ph-video-camera-slash.icon-sign-out:before, i.ph-video-camera-slash.icon-substratum:before, i.ph-video-camera-slash.icon-environment:before, i.ph-video-camera-slash.icon-user:before, i.ph-video-camera-slash.icon-person:before, i.ph-video-camera-slash.icon-terrain:before, i.ph-video-camera-slash.icon-taxon:before, i.ph-video-camera-slash.icon-unknown:before, i.ph-video-camera-slash.icon-nothing:before, i.ph-video-camera-slash.icon-sampling:before, i.ph-video-camera-slash.icon-isolation:before, i.ph-video-camera-slash.icon-strain:before, i.ph-video-camera-slash.icon-gps:before, i.ph-video-camera-slash.icon-location:before, i.ph-video-camera-slash.icon-add:before, i.ph-video-camera-slash.icon-left:before, i.ph-video-camera-slash.icon-dna:before, i.ph-video-camera-slash.icon-delete:before, i.ph-video-camera-slash.icon-edit:before, i.ph-video-camera-slash.icon-info:before {
  content: "\e4dc";
}

.ph.ph-video-conference:before, i.ph-video-conference.icon-dead:before, i.ph-video-conference.icon-alive:before, i.ph-video-conference.icon-warn:before, i.ph-video-conference.icon-sign-out:before, i.ph-video-conference.icon-substratum:before, i.ph-video-conference.icon-environment:before, i.ph-video-conference.icon-user:before, i.ph-video-conference.icon-person:before, i.ph-video-conference.icon-terrain:before, i.ph-video-conference.icon-taxon:before, i.ph-video-conference.icon-unknown:before, i.ph-video-conference.icon-nothing:before, i.ph-video-conference.icon-sampling:before, i.ph-video-conference.icon-isolation:before, i.ph-video-conference.icon-strain:before, i.ph-video-conference.icon-gps:before, i.ph-video-conference.icon-location:before, i.ph-video-conference.icon-add:before, i.ph-video-conference.icon-left:before, i.ph-video-conference.icon-dna:before, i.ph-video-conference.icon-delete:before, i.ph-video-conference.icon-edit:before, i.ph-video-conference.icon-info:before {
  content: "\edce";
}

.ph.ph-vignette:before, i.ph-vignette.icon-dead:before, i.ph-vignette.icon-alive:before, i.ph-vignette.icon-warn:before, i.ph-vignette.icon-sign-out:before, i.ph-vignette.icon-substratum:before, i.ph-vignette.icon-environment:before, i.ph-vignette.icon-user:before, i.ph-vignette.icon-person:before, i.ph-vignette.icon-terrain:before, i.ph-vignette.icon-taxon:before, i.ph-vignette.icon-unknown:before, i.ph-vignette.icon-nothing:before, i.ph-vignette.icon-sampling:before, i.ph-vignette.icon-isolation:before, i.ph-vignette.icon-strain:before, i.ph-vignette.icon-gps:before, i.ph-vignette.icon-location:before, i.ph-vignette.icon-add:before, i.ph-vignette.icon-left:before, i.ph-vignette.icon-dna:before, i.ph-vignette.icon-delete:before, i.ph-vignette.icon-edit:before, i.ph-vignette.icon-info:before {
  content: "\eba2";
}

.ph.ph-vinyl-record:before, i.ph-vinyl-record.icon-dead:before, i.ph-vinyl-record.icon-alive:before, i.ph-vinyl-record.icon-warn:before, i.ph-vinyl-record.icon-sign-out:before, i.ph-vinyl-record.icon-substratum:before, i.ph-vinyl-record.icon-environment:before, i.ph-vinyl-record.icon-user:before, i.ph-vinyl-record.icon-person:before, i.ph-vinyl-record.icon-terrain:before, i.ph-vinyl-record.icon-taxon:before, i.ph-vinyl-record.icon-unknown:before, i.ph-vinyl-record.icon-nothing:before, i.ph-vinyl-record.icon-sampling:before, i.ph-vinyl-record.icon-isolation:before, i.ph-vinyl-record.icon-strain:before, i.ph-vinyl-record.icon-gps:before, i.ph-vinyl-record.icon-location:before, i.ph-vinyl-record.icon-add:before, i.ph-vinyl-record.icon-left:before, i.ph-vinyl-record.icon-dna:before, i.ph-vinyl-record.icon-delete:before, i.ph-vinyl-record.icon-edit:before, i.ph-vinyl-record.icon-info:before {
  content: "\ecac";
}

.ph.ph-virtual-reality:before, i.ph-virtual-reality.icon-dead:before, i.ph-virtual-reality.icon-alive:before, i.ph-virtual-reality.icon-warn:before, i.ph-virtual-reality.icon-sign-out:before, i.ph-virtual-reality.icon-substratum:before, i.ph-virtual-reality.icon-environment:before, i.ph-virtual-reality.icon-user:before, i.ph-virtual-reality.icon-person:before, i.ph-virtual-reality.icon-terrain:before, i.ph-virtual-reality.icon-taxon:before, i.ph-virtual-reality.icon-unknown:before, i.ph-virtual-reality.icon-nothing:before, i.ph-virtual-reality.icon-sampling:before, i.ph-virtual-reality.icon-isolation:before, i.ph-virtual-reality.icon-strain:before, i.ph-virtual-reality.icon-gps:before, i.ph-virtual-reality.icon-location:before, i.ph-virtual-reality.icon-add:before, i.ph-virtual-reality.icon-left:before, i.ph-virtual-reality.icon-dna:before, i.ph-virtual-reality.icon-delete:before, i.ph-virtual-reality.icon-edit:before, i.ph-virtual-reality.icon-info:before {
  content: "\e7b8";
}

.ph.ph-virus:before, i.ph-virus.icon-dead:before, i.ph-virus.icon-alive:before, i.ph-virus.icon-warn:before, i.ph-virus.icon-sign-out:before, i.ph-virus.icon-substratum:before, i.ph-virus.icon-environment:before, i.ph-virus.icon-user:before, i.ph-virus.icon-person:before, i.ph-virus.icon-terrain:before, i.ph-virus.icon-taxon:before, i.ph-virus.icon-unknown:before, i.ph-virus.icon-nothing:before, i.ph-virus.icon-sampling:before, i.ph-virus.icon-isolation:before, i.icon-strain:before, i.ph-virus.icon-gps:before, i.ph-virus.icon-location:before, i.ph-virus.icon-add:before, i.ph-virus.icon-left:before, i.ph-virus.icon-dna:before, i.ph-virus.icon-delete:before, i.ph-virus.icon-edit:before, i.ph-virus.icon-info:before {
  content: "\e7d6";
}

.ph.ph-visor:before, i.ph-visor.icon-dead:before, i.ph-visor.icon-alive:before, i.ph-visor.icon-warn:before, i.ph-visor.icon-sign-out:before, i.ph-visor.icon-substratum:before, i.ph-visor.icon-environment:before, i.ph-visor.icon-user:before, i.ph-visor.icon-person:before, i.ph-visor.icon-terrain:before, i.ph-visor.icon-taxon:before, i.ph-visor.icon-unknown:before, i.ph-visor.icon-nothing:before, i.ph-visor.icon-sampling:before, i.ph-visor.icon-isolation:before, i.ph-visor.icon-strain:before, i.ph-visor.icon-gps:before, i.ph-visor.icon-location:before, i.ph-visor.icon-add:before, i.ph-visor.icon-left:before, i.ph-visor.icon-dna:before, i.ph-visor.icon-delete:before, i.ph-visor.icon-edit:before, i.ph-visor.icon-info:before {
  content: "\ee2a";
}

.ph.ph-voicemail:before, i.ph-voicemail.icon-dead:before, i.ph-voicemail.icon-alive:before, i.ph-voicemail.icon-warn:before, i.ph-voicemail.icon-sign-out:before, i.ph-voicemail.icon-substratum:before, i.ph-voicemail.icon-environment:before, i.ph-voicemail.icon-user:before, i.ph-voicemail.icon-person:before, i.ph-voicemail.icon-terrain:before, i.ph-voicemail.icon-taxon:before, i.ph-voicemail.icon-unknown:before, i.ph-voicemail.icon-nothing:before, i.ph-voicemail.icon-sampling:before, i.ph-voicemail.icon-isolation:before, i.ph-voicemail.icon-strain:before, i.ph-voicemail.icon-gps:before, i.ph-voicemail.icon-location:before, i.ph-voicemail.icon-add:before, i.ph-voicemail.icon-left:before, i.ph-voicemail.icon-dna:before, i.ph-voicemail.icon-delete:before, i.ph-voicemail.icon-edit:before, i.ph-voicemail.icon-info:before {
  content: "\e4de";
}

.ph.ph-volleyball:before, i.ph-volleyball.icon-dead:before, i.ph-volleyball.icon-alive:before, i.ph-volleyball.icon-warn:before, i.ph-volleyball.icon-sign-out:before, i.ph-volleyball.icon-substratum:before, i.ph-volleyball.icon-environment:before, i.ph-volleyball.icon-user:before, i.ph-volleyball.icon-person:before, i.ph-volleyball.icon-terrain:before, i.ph-volleyball.icon-taxon:before, i.ph-volleyball.icon-unknown:before, i.ph-volleyball.icon-nothing:before, i.ph-volleyball.icon-sampling:before, i.ph-volleyball.icon-isolation:before, i.ph-volleyball.icon-strain:before, i.ph-volleyball.icon-gps:before, i.ph-volleyball.icon-location:before, i.ph-volleyball.icon-add:before, i.ph-volleyball.icon-left:before, i.ph-volleyball.icon-dna:before, i.ph-volleyball.icon-delete:before, i.ph-volleyball.icon-edit:before, i.ph-volleyball.icon-info:before {
  content: "\e726";
}

.ph.ph-wall:before, i.ph-wall.icon-dead:before, i.ph-wall.icon-alive:before, i.ph-wall.icon-warn:before, i.ph-wall.icon-sign-out:before, i.ph-wall.icon-substratum:before, i.ph-wall.icon-environment:before, i.ph-wall.icon-user:before, i.ph-wall.icon-person:before, i.ph-wall.icon-terrain:before, i.ph-wall.icon-taxon:before, i.ph-wall.icon-unknown:before, i.ph-wall.icon-nothing:before, i.ph-wall.icon-sampling:before, i.ph-wall.icon-isolation:before, i.ph-wall.icon-strain:before, i.ph-wall.icon-gps:before, i.ph-wall.icon-location:before, i.ph-wall.icon-add:before, i.ph-wall.icon-left:before, i.ph-wall.icon-dna:before, i.ph-wall.icon-delete:before, i.ph-wall.icon-edit:before, i.ph-wall.icon-info:before {
  content: "\e688";
}

.ph.ph-wallet:before, i.ph-wallet.icon-dead:before, i.ph-wallet.icon-alive:before, i.ph-wallet.icon-warn:before, i.ph-wallet.icon-sign-out:before, i.ph-wallet.icon-substratum:before, i.ph-wallet.icon-environment:before, i.ph-wallet.icon-user:before, i.ph-wallet.icon-person:before, i.ph-wallet.icon-terrain:before, i.ph-wallet.icon-taxon:before, i.ph-wallet.icon-unknown:before, i.ph-wallet.icon-nothing:before, i.ph-wallet.icon-sampling:before, i.ph-wallet.icon-isolation:before, i.ph-wallet.icon-strain:before, i.ph-wallet.icon-gps:before, i.ph-wallet.icon-location:before, i.ph-wallet.icon-add:before, i.ph-wallet.icon-left:before, i.ph-wallet.icon-dna:before, i.ph-wallet.icon-delete:before, i.ph-wallet.icon-edit:before, i.ph-wallet.icon-info:before {
  content: "\e68a";
}

.ph.ph-warehouse:before, i.ph-warehouse.icon-dead:before, i.ph-warehouse.icon-alive:before, i.ph-warehouse.icon-warn:before, i.ph-warehouse.icon-sign-out:before, i.ph-warehouse.icon-substratum:before, i.ph-warehouse.icon-environment:before, i.ph-warehouse.icon-user:before, i.ph-warehouse.icon-person:before, i.ph-warehouse.icon-terrain:before, i.ph-warehouse.icon-taxon:before, i.ph-warehouse.icon-unknown:before, i.ph-warehouse.icon-nothing:before, i.ph-warehouse.icon-sampling:before, i.ph-warehouse.icon-isolation:before, i.ph-warehouse.icon-strain:before, i.ph-warehouse.icon-gps:before, i.ph-warehouse.icon-location:before, i.ph-warehouse.icon-add:before, i.ph-warehouse.icon-left:before, i.ph-warehouse.icon-dna:before, i.ph-warehouse.icon-delete:before, i.ph-warehouse.icon-edit:before, i.ph-warehouse.icon-info:before {
  content: "\ecd4";
}

.ph.ph-warning:before, i.ph-warning.icon-dead:before, i.ph-warning.icon-alive:before, i.icon-warn:before, i.ph-warning.icon-sign-out:before, i.ph-warning.icon-substratum:before, i.ph-warning.icon-environment:before, i.ph-warning.icon-user:before, i.ph-warning.icon-person:before, i.ph-warning.icon-terrain:before, i.ph-warning.icon-taxon:before, i.ph-warning.icon-unknown:before, i.ph-warning.icon-nothing:before, i.ph-warning.icon-sampling:before, i.ph-warning.icon-isolation:before, i.ph-warning.icon-strain:before, i.ph-warning.icon-gps:before, i.ph-warning.icon-location:before, i.ph-warning.icon-add:before, i.ph-warning.icon-left:before, i.ph-warning.icon-dna:before, i.ph-warning.icon-delete:before, i.ph-warning.icon-edit:before, i.ph-warning.icon-info:before {
  content: "\e4e0";
}

.ph.ph-warning-circle:before, i.ph-warning-circle.icon-dead:before, i.ph-warning-circle.icon-alive:before, i.ph-warning-circle.icon-warn:before, i.ph-warning-circle.icon-sign-out:before, i.ph-warning-circle.icon-substratum:before, i.ph-warning-circle.icon-environment:before, i.ph-warning-circle.icon-user:before, i.ph-warning-circle.icon-person:before, i.ph-warning-circle.icon-terrain:before, i.ph-warning-circle.icon-taxon:before, i.ph-warning-circle.icon-unknown:before, i.ph-warning-circle.icon-nothing:before, i.ph-warning-circle.icon-sampling:before, i.ph-warning-circle.icon-isolation:before, i.ph-warning-circle.icon-strain:before, i.ph-warning-circle.icon-gps:before, i.ph-warning-circle.icon-location:before, i.ph-warning-circle.icon-add:before, i.ph-warning-circle.icon-left:before, i.ph-warning-circle.icon-dna:before, i.ph-warning-circle.icon-delete:before, i.ph-warning-circle.icon-edit:before, i.ph-warning-circle.icon-info:before {
  content: "\e4e2";
}

.ph.ph-warning-diamond:before, i.ph-warning-diamond.icon-dead:before, i.ph-warning-diamond.icon-alive:before, i.ph-warning-diamond.icon-warn:before, i.ph-warning-diamond.icon-sign-out:before, i.ph-warning-diamond.icon-substratum:before, i.ph-warning-diamond.icon-environment:before, i.ph-warning-diamond.icon-user:before, i.ph-warning-diamond.icon-person:before, i.ph-warning-diamond.icon-terrain:before, i.ph-warning-diamond.icon-taxon:before, i.ph-warning-diamond.icon-unknown:before, i.ph-warning-diamond.icon-nothing:before, i.ph-warning-diamond.icon-sampling:before, i.ph-warning-diamond.icon-isolation:before, i.ph-warning-diamond.icon-strain:before, i.ph-warning-diamond.icon-gps:before, i.ph-warning-diamond.icon-location:before, i.ph-warning-diamond.icon-add:before, i.ph-warning-diamond.icon-left:before, i.ph-warning-diamond.icon-dna:before, i.ph-warning-diamond.icon-delete:before, i.ph-warning-diamond.icon-edit:before, i.ph-warning-diamond.icon-info:before {
  content: "\e7fc";
}

.ph.ph-warning-octagon:before, i.ph-warning-octagon.icon-dead:before, i.ph-warning-octagon.icon-alive:before, i.ph-warning-octagon.icon-warn:before, i.ph-warning-octagon.icon-sign-out:before, i.ph-warning-octagon.icon-substratum:before, i.ph-warning-octagon.icon-environment:before, i.ph-warning-octagon.icon-user:before, i.ph-warning-octagon.icon-person:before, i.ph-warning-octagon.icon-terrain:before, i.ph-warning-octagon.icon-taxon:before, i.ph-warning-octagon.icon-unknown:before, i.ph-warning-octagon.icon-nothing:before, i.ph-warning-octagon.icon-sampling:before, i.ph-warning-octagon.icon-isolation:before, i.ph-warning-octagon.icon-strain:before, i.ph-warning-octagon.icon-gps:before, i.ph-warning-octagon.icon-location:before, i.ph-warning-octagon.icon-add:before, i.ph-warning-octagon.icon-left:before, i.ph-warning-octagon.icon-dna:before, i.ph-warning-octagon.icon-delete:before, i.ph-warning-octagon.icon-edit:before, i.ph-warning-octagon.icon-info:before {
  content: "\e4e4";
}

.ph.ph-washing-machine:before, i.ph-washing-machine.icon-dead:before, i.ph-washing-machine.icon-alive:before, i.ph-washing-machine.icon-warn:before, i.ph-washing-machine.icon-sign-out:before, i.ph-washing-machine.icon-substratum:before, i.ph-washing-machine.icon-environment:before, i.ph-washing-machine.icon-user:before, i.ph-washing-machine.icon-person:before, i.ph-washing-machine.icon-terrain:before, i.ph-washing-machine.icon-taxon:before, i.ph-washing-machine.icon-unknown:before, i.ph-washing-machine.icon-nothing:before, i.ph-washing-machine.icon-sampling:before, i.ph-washing-machine.icon-isolation:before, i.ph-washing-machine.icon-strain:before, i.ph-washing-machine.icon-gps:before, i.ph-washing-machine.icon-location:before, i.ph-washing-machine.icon-add:before, i.ph-washing-machine.icon-left:before, i.ph-washing-machine.icon-dna:before, i.ph-washing-machine.icon-delete:before, i.ph-washing-machine.icon-edit:before, i.ph-washing-machine.icon-info:before {
  content: "\ede8";
}

.ph.ph-watch:before, i.ph-watch.icon-dead:before, i.ph-watch.icon-alive:before, i.ph-watch.icon-warn:before, i.ph-watch.icon-sign-out:before, i.ph-watch.icon-substratum:before, i.ph-watch.icon-environment:before, i.ph-watch.icon-user:before, i.ph-watch.icon-person:before, i.ph-watch.icon-terrain:before, i.ph-watch.icon-taxon:before, i.ph-watch.icon-unknown:before, i.ph-watch.icon-nothing:before, i.ph-watch.icon-sampling:before, i.ph-watch.icon-isolation:before, i.ph-watch.icon-strain:before, i.ph-watch.icon-gps:before, i.ph-watch.icon-location:before, i.ph-watch.icon-add:before, i.ph-watch.icon-left:before, i.ph-watch.icon-dna:before, i.ph-watch.icon-delete:before, i.ph-watch.icon-edit:before, i.ph-watch.icon-info:before {
  content: "\e4e6";
}

.ph.ph-wave-sawtooth:before, i.ph-wave-sawtooth.icon-dead:before, i.ph-wave-sawtooth.icon-alive:before, i.ph-wave-sawtooth.icon-warn:before, i.ph-wave-sawtooth.icon-sign-out:before, i.ph-wave-sawtooth.icon-substratum:before, i.ph-wave-sawtooth.icon-environment:before, i.ph-wave-sawtooth.icon-user:before, i.ph-wave-sawtooth.icon-person:before, i.ph-wave-sawtooth.icon-terrain:before, i.ph-wave-sawtooth.icon-taxon:before, i.ph-wave-sawtooth.icon-unknown:before, i.ph-wave-sawtooth.icon-nothing:before, i.ph-wave-sawtooth.icon-sampling:before, i.ph-wave-sawtooth.icon-isolation:before, i.ph-wave-sawtooth.icon-strain:before, i.ph-wave-sawtooth.icon-gps:before, i.ph-wave-sawtooth.icon-location:before, i.ph-wave-sawtooth.icon-add:before, i.ph-wave-sawtooth.icon-left:before, i.ph-wave-sawtooth.icon-dna:before, i.ph-wave-sawtooth.icon-delete:before, i.ph-wave-sawtooth.icon-edit:before, i.ph-wave-sawtooth.icon-info:before {
  content: "\ea9c";
}

.ph.ph-wave-sine:before, i.ph-wave-sine.icon-dead:before, i.ph-wave-sine.icon-alive:before, i.ph-wave-sine.icon-warn:before, i.ph-wave-sine.icon-sign-out:before, i.ph-wave-sine.icon-substratum:before, i.ph-wave-sine.icon-environment:before, i.ph-wave-sine.icon-user:before, i.ph-wave-sine.icon-person:before, i.ph-wave-sine.icon-terrain:before, i.ph-wave-sine.icon-taxon:before, i.ph-wave-sine.icon-unknown:before, i.ph-wave-sine.icon-nothing:before, i.ph-wave-sine.icon-sampling:before, i.ph-wave-sine.icon-isolation:before, i.ph-wave-sine.icon-strain:before, i.ph-wave-sine.icon-gps:before, i.ph-wave-sine.icon-location:before, i.ph-wave-sine.icon-add:before, i.ph-wave-sine.icon-left:before, i.ph-wave-sine.icon-dna:before, i.ph-wave-sine.icon-delete:before, i.ph-wave-sine.icon-edit:before, i.ph-wave-sine.icon-info:before {
  content: "\ea9a";
}

.ph.ph-wave-square:before, i.ph-wave-square.icon-dead:before, i.ph-wave-square.icon-alive:before, i.ph-wave-square.icon-warn:before, i.ph-wave-square.icon-sign-out:before, i.ph-wave-square.icon-substratum:before, i.ph-wave-square.icon-environment:before, i.ph-wave-square.icon-user:before, i.ph-wave-square.icon-person:before, i.ph-wave-square.icon-terrain:before, i.ph-wave-square.icon-taxon:before, i.ph-wave-square.icon-unknown:before, i.ph-wave-square.icon-nothing:before, i.ph-wave-square.icon-sampling:before, i.ph-wave-square.icon-isolation:before, i.ph-wave-square.icon-strain:before, i.ph-wave-square.icon-gps:before, i.ph-wave-square.icon-location:before, i.ph-wave-square.icon-add:before, i.ph-wave-square.icon-left:before, i.ph-wave-square.icon-dna:before, i.ph-wave-square.icon-delete:before, i.ph-wave-square.icon-edit:before, i.ph-wave-square.icon-info:before {
  content: "\ea9e";
}

.ph.ph-wave-triangle:before, i.ph-wave-triangle.icon-dead:before, i.ph-wave-triangle.icon-alive:before, i.ph-wave-triangle.icon-warn:before, i.ph-wave-triangle.icon-sign-out:before, i.ph-wave-triangle.icon-substratum:before, i.ph-wave-triangle.icon-environment:before, i.ph-wave-triangle.icon-user:before, i.ph-wave-triangle.icon-person:before, i.ph-wave-triangle.icon-terrain:before, i.ph-wave-triangle.icon-taxon:before, i.ph-wave-triangle.icon-unknown:before, i.ph-wave-triangle.icon-nothing:before, i.ph-wave-triangle.icon-sampling:before, i.ph-wave-triangle.icon-isolation:before, i.ph-wave-triangle.icon-strain:before, i.ph-wave-triangle.icon-gps:before, i.ph-wave-triangle.icon-location:before, i.ph-wave-triangle.icon-add:before, i.ph-wave-triangle.icon-left:before, i.ph-wave-triangle.icon-dna:before, i.ph-wave-triangle.icon-delete:before, i.ph-wave-triangle.icon-edit:before, i.ph-wave-triangle.icon-info:before {
  content: "\eaa0";
}

.ph.ph-waveform:before, i.ph-waveform.icon-dead:before, i.ph-waveform.icon-alive:before, i.ph-waveform.icon-warn:before, i.ph-waveform.icon-sign-out:before, i.ph-waveform.icon-substratum:before, i.ph-waveform.icon-environment:before, i.ph-waveform.icon-user:before, i.ph-waveform.icon-person:before, i.ph-waveform.icon-terrain:before, i.ph-waveform.icon-taxon:before, i.ph-waveform.icon-unknown:before, i.ph-waveform.icon-nothing:before, i.ph-waveform.icon-sampling:before, i.ph-waveform.icon-isolation:before, i.ph-waveform.icon-strain:before, i.ph-waveform.icon-gps:before, i.ph-waveform.icon-location:before, i.ph-waveform.icon-add:before, i.ph-waveform.icon-left:before, i.ph-waveform.icon-dna:before, i.ph-waveform.icon-delete:before, i.ph-waveform.icon-edit:before, i.ph-waveform.icon-info:before {
  content: "\e802";
}

.ph.ph-waveform-slash:before, i.ph-waveform-slash.icon-dead:before, i.ph-waveform-slash.icon-alive:before, i.ph-waveform-slash.icon-warn:before, i.ph-waveform-slash.icon-sign-out:before, i.ph-waveform-slash.icon-substratum:before, i.ph-waveform-slash.icon-environment:before, i.ph-waveform-slash.icon-user:before, i.ph-waveform-slash.icon-person:before, i.ph-waveform-slash.icon-terrain:before, i.ph-waveform-slash.icon-taxon:before, i.ph-waveform-slash.icon-unknown:before, i.ph-waveform-slash.icon-nothing:before, i.ph-waveform-slash.icon-sampling:before, i.ph-waveform-slash.icon-isolation:before, i.ph-waveform-slash.icon-strain:before, i.ph-waveform-slash.icon-gps:before, i.ph-waveform-slash.icon-location:before, i.ph-waveform-slash.icon-add:before, i.ph-waveform-slash.icon-left:before, i.ph-waveform-slash.icon-dna:before, i.ph-waveform-slash.icon-delete:before, i.ph-waveform-slash.icon-edit:before, i.ph-waveform-slash.icon-info:before {
  content: "\e800";
}

.ph.ph-waves:before, i.ph-waves.icon-dead:before, i.ph-waves.icon-alive:before, i.ph-waves.icon-warn:before, i.ph-waves.icon-sign-out:before, i.ph-waves.icon-substratum:before, i.ph-waves.icon-environment:before, i.ph-waves.icon-user:before, i.ph-waves.icon-person:before, i.ph-waves.icon-terrain:before, i.ph-waves.icon-taxon:before, i.ph-waves.icon-unknown:before, i.ph-waves.icon-nothing:before, i.ph-waves.icon-sampling:before, i.ph-waves.icon-isolation:before, i.ph-waves.icon-strain:before, i.ph-waves.icon-gps:before, i.ph-waves.icon-location:before, i.ph-waves.icon-add:before, i.ph-waves.icon-left:before, i.ph-waves.icon-dna:before, i.ph-waves.icon-delete:before, i.ph-waves.icon-edit:before, i.ph-waves.icon-info:before {
  content: "\e6de";
}

.ph.ph-webcam:before, i.ph-webcam.icon-dead:before, i.ph-webcam.icon-alive:before, i.ph-webcam.icon-warn:before, i.ph-webcam.icon-sign-out:before, i.ph-webcam.icon-substratum:before, i.ph-webcam.icon-environment:before, i.ph-webcam.icon-user:before, i.ph-webcam.icon-person:before, i.ph-webcam.icon-terrain:before, i.ph-webcam.icon-taxon:before, i.ph-webcam.icon-unknown:before, i.ph-webcam.icon-nothing:before, i.ph-webcam.icon-sampling:before, i.ph-webcam.icon-isolation:before, i.ph-webcam.icon-strain:before, i.ph-webcam.icon-gps:before, i.ph-webcam.icon-location:before, i.ph-webcam.icon-add:before, i.ph-webcam.icon-left:before, i.ph-webcam.icon-dna:before, i.ph-webcam.icon-delete:before, i.ph-webcam.icon-edit:before, i.ph-webcam.icon-info:before {
  content: "\e9b2";
}

.ph.ph-webcam-slash:before, i.ph-webcam-slash.icon-dead:before, i.ph-webcam-slash.icon-alive:before, i.ph-webcam-slash.icon-warn:before, i.ph-webcam-slash.icon-sign-out:before, i.ph-webcam-slash.icon-substratum:before, i.ph-webcam-slash.icon-environment:before, i.ph-webcam-slash.icon-user:before, i.ph-webcam-slash.icon-person:before, i.ph-webcam-slash.icon-terrain:before, i.ph-webcam-slash.icon-taxon:before, i.ph-webcam-slash.icon-unknown:before, i.ph-webcam-slash.icon-nothing:before, i.ph-webcam-slash.icon-sampling:before, i.ph-webcam-slash.icon-isolation:before, i.ph-webcam-slash.icon-strain:before, i.ph-webcam-slash.icon-gps:before, i.ph-webcam-slash.icon-location:before, i.ph-webcam-slash.icon-add:before, i.ph-webcam-slash.icon-left:before, i.ph-webcam-slash.icon-dna:before, i.ph-webcam-slash.icon-delete:before, i.ph-webcam-slash.icon-edit:before, i.ph-webcam-slash.icon-info:before {
  content: "\ecdc";
}

.ph.ph-webhooks-logo:before, i.ph-webhooks-logo.icon-dead:before, i.ph-webhooks-logo.icon-alive:before, i.ph-webhooks-logo.icon-warn:before, i.ph-webhooks-logo.icon-sign-out:before, i.ph-webhooks-logo.icon-substratum:before, i.ph-webhooks-logo.icon-environment:before, i.ph-webhooks-logo.icon-user:before, i.ph-webhooks-logo.icon-person:before, i.ph-webhooks-logo.icon-terrain:before, i.ph-webhooks-logo.icon-taxon:before, i.ph-webhooks-logo.icon-unknown:before, i.ph-webhooks-logo.icon-nothing:before, i.ph-webhooks-logo.icon-sampling:before, i.ph-webhooks-logo.icon-isolation:before, i.ph-webhooks-logo.icon-strain:before, i.ph-webhooks-logo.icon-gps:before, i.ph-webhooks-logo.icon-location:before, i.ph-webhooks-logo.icon-add:before, i.ph-webhooks-logo.icon-left:before, i.ph-webhooks-logo.icon-dna:before, i.ph-webhooks-logo.icon-delete:before, i.ph-webhooks-logo.icon-edit:before, i.ph-webhooks-logo.icon-info:before {
  content: "\ecae";
}

.ph.ph-wechat-logo:before, i.ph-wechat-logo.icon-dead:before, i.ph-wechat-logo.icon-alive:before, i.ph-wechat-logo.icon-warn:before, i.ph-wechat-logo.icon-sign-out:before, i.ph-wechat-logo.icon-substratum:before, i.ph-wechat-logo.icon-environment:before, i.ph-wechat-logo.icon-user:before, i.ph-wechat-logo.icon-person:before, i.ph-wechat-logo.icon-terrain:before, i.ph-wechat-logo.icon-taxon:before, i.ph-wechat-logo.icon-unknown:before, i.ph-wechat-logo.icon-nothing:before, i.ph-wechat-logo.icon-sampling:before, i.ph-wechat-logo.icon-isolation:before, i.ph-wechat-logo.icon-strain:before, i.ph-wechat-logo.icon-gps:before, i.ph-wechat-logo.icon-location:before, i.ph-wechat-logo.icon-add:before, i.ph-wechat-logo.icon-left:before, i.ph-wechat-logo.icon-dna:before, i.ph-wechat-logo.icon-delete:before, i.ph-wechat-logo.icon-edit:before, i.ph-wechat-logo.icon-info:before {
  content: "\e8d2";
}

.ph.ph-whatsapp-logo:before, i.ph-whatsapp-logo.icon-dead:before, i.ph-whatsapp-logo.icon-alive:before, i.ph-whatsapp-logo.icon-warn:before, i.ph-whatsapp-logo.icon-sign-out:before, i.ph-whatsapp-logo.icon-substratum:before, i.ph-whatsapp-logo.icon-environment:before, i.ph-whatsapp-logo.icon-user:before, i.ph-whatsapp-logo.icon-person:before, i.ph-whatsapp-logo.icon-terrain:before, i.ph-whatsapp-logo.icon-taxon:before, i.ph-whatsapp-logo.icon-unknown:before, i.ph-whatsapp-logo.icon-nothing:before, i.ph-whatsapp-logo.icon-sampling:before, i.ph-whatsapp-logo.icon-isolation:before, i.ph-whatsapp-logo.icon-strain:before, i.ph-whatsapp-logo.icon-gps:before, i.ph-whatsapp-logo.icon-location:before, i.ph-whatsapp-logo.icon-add:before, i.ph-whatsapp-logo.icon-left:before, i.ph-whatsapp-logo.icon-dna:before, i.ph-whatsapp-logo.icon-delete:before, i.ph-whatsapp-logo.icon-edit:before, i.ph-whatsapp-logo.icon-info:before {
  content: "\e5d0";
}

.ph.ph-wheelchair:before, i.ph-wheelchair.icon-dead:before, i.ph-wheelchair.icon-alive:before, i.ph-wheelchair.icon-warn:before, i.ph-wheelchair.icon-sign-out:before, i.ph-wheelchair.icon-substratum:before, i.ph-wheelchair.icon-environment:before, i.ph-wheelchair.icon-user:before, i.ph-wheelchair.icon-person:before, i.ph-wheelchair.icon-terrain:before, i.ph-wheelchair.icon-taxon:before, i.ph-wheelchair.icon-unknown:before, i.ph-wheelchair.icon-nothing:before, i.ph-wheelchair.icon-sampling:before, i.ph-wheelchair.icon-isolation:before, i.ph-wheelchair.icon-strain:before, i.ph-wheelchair.icon-gps:before, i.ph-wheelchair.icon-location:before, i.ph-wheelchair.icon-add:before, i.ph-wheelchair.icon-left:before, i.ph-wheelchair.icon-dna:before, i.ph-wheelchair.icon-delete:before, i.ph-wheelchair.icon-edit:before, i.ph-wheelchair.icon-info:before {
  content: "\e4e8";
}

.ph.ph-wheelchair-motion:before, i.ph-wheelchair-motion.icon-dead:before, i.ph-wheelchair-motion.icon-alive:before, i.ph-wheelchair-motion.icon-warn:before, i.ph-wheelchair-motion.icon-sign-out:before, i.ph-wheelchair-motion.icon-substratum:before, i.ph-wheelchair-motion.icon-environment:before, i.ph-wheelchair-motion.icon-user:before, i.ph-wheelchair-motion.icon-person:before, i.ph-wheelchair-motion.icon-terrain:before, i.ph-wheelchair-motion.icon-taxon:before, i.ph-wheelchair-motion.icon-unknown:before, i.ph-wheelchair-motion.icon-nothing:before, i.ph-wheelchair-motion.icon-sampling:before, i.ph-wheelchair-motion.icon-isolation:before, i.ph-wheelchair-motion.icon-strain:before, i.ph-wheelchair-motion.icon-gps:before, i.ph-wheelchair-motion.icon-location:before, i.ph-wheelchair-motion.icon-add:before, i.ph-wheelchair-motion.icon-left:before, i.ph-wheelchair-motion.icon-dna:before, i.ph-wheelchair-motion.icon-delete:before, i.ph-wheelchair-motion.icon-edit:before, i.ph-wheelchair-motion.icon-info:before {
  content: "\e89a";
}

.ph.ph-wifi-high:before, i.ph-wifi-high.icon-dead:before, i.ph-wifi-high.icon-alive:before, i.ph-wifi-high.icon-warn:before, i.ph-wifi-high.icon-sign-out:before, i.ph-wifi-high.icon-substratum:before, i.ph-wifi-high.icon-environment:before, i.ph-wifi-high.icon-user:before, i.ph-wifi-high.icon-person:before, i.ph-wifi-high.icon-terrain:before, i.ph-wifi-high.icon-taxon:before, i.ph-wifi-high.icon-unknown:before, i.ph-wifi-high.icon-nothing:before, i.ph-wifi-high.icon-sampling:before, i.ph-wifi-high.icon-isolation:before, i.ph-wifi-high.icon-strain:before, i.ph-wifi-high.icon-gps:before, i.ph-wifi-high.icon-location:before, i.ph-wifi-high.icon-add:before, i.ph-wifi-high.icon-left:before, i.ph-wifi-high.icon-dna:before, i.ph-wifi-high.icon-delete:before, i.ph-wifi-high.icon-edit:before, i.ph-wifi-high.icon-info:before {
  content: "\e4ea";
}

.ph.ph-wifi-low:before, i.ph-wifi-low.icon-dead:before, i.ph-wifi-low.icon-alive:before, i.ph-wifi-low.icon-warn:before, i.ph-wifi-low.icon-sign-out:before, i.ph-wifi-low.icon-substratum:before, i.ph-wifi-low.icon-environment:before, i.ph-wifi-low.icon-user:before, i.ph-wifi-low.icon-person:before, i.ph-wifi-low.icon-terrain:before, i.ph-wifi-low.icon-taxon:before, i.ph-wifi-low.icon-unknown:before, i.ph-wifi-low.icon-nothing:before, i.ph-wifi-low.icon-sampling:before, i.ph-wifi-low.icon-isolation:before, i.ph-wifi-low.icon-strain:before, i.ph-wifi-low.icon-gps:before, i.ph-wifi-low.icon-location:before, i.ph-wifi-low.icon-add:before, i.ph-wifi-low.icon-left:before, i.ph-wifi-low.icon-dna:before, i.ph-wifi-low.icon-delete:before, i.ph-wifi-low.icon-edit:before, i.ph-wifi-low.icon-info:before {
  content: "\e4ec";
}

.ph.ph-wifi-medium:before, i.ph-wifi-medium.icon-dead:before, i.ph-wifi-medium.icon-alive:before, i.ph-wifi-medium.icon-warn:before, i.ph-wifi-medium.icon-sign-out:before, i.ph-wifi-medium.icon-substratum:before, i.ph-wifi-medium.icon-environment:before, i.ph-wifi-medium.icon-user:before, i.ph-wifi-medium.icon-person:before, i.ph-wifi-medium.icon-terrain:before, i.ph-wifi-medium.icon-taxon:before, i.ph-wifi-medium.icon-unknown:before, i.ph-wifi-medium.icon-nothing:before, i.ph-wifi-medium.icon-sampling:before, i.ph-wifi-medium.icon-isolation:before, i.ph-wifi-medium.icon-strain:before, i.ph-wifi-medium.icon-gps:before, i.ph-wifi-medium.icon-location:before, i.ph-wifi-medium.icon-add:before, i.ph-wifi-medium.icon-left:before, i.ph-wifi-medium.icon-dna:before, i.ph-wifi-medium.icon-delete:before, i.ph-wifi-medium.icon-edit:before, i.ph-wifi-medium.icon-info:before {
  content: "\e4ee";
}

.ph.ph-wifi-none:before, i.ph-wifi-none.icon-dead:before, i.ph-wifi-none.icon-alive:before, i.ph-wifi-none.icon-warn:before, i.ph-wifi-none.icon-sign-out:before, i.ph-wifi-none.icon-substratum:before, i.ph-wifi-none.icon-environment:before, i.ph-wifi-none.icon-user:before, i.ph-wifi-none.icon-person:before, i.ph-wifi-none.icon-terrain:before, i.ph-wifi-none.icon-taxon:before, i.ph-wifi-none.icon-unknown:before, i.ph-wifi-none.icon-nothing:before, i.ph-wifi-none.icon-sampling:before, i.ph-wifi-none.icon-isolation:before, i.ph-wifi-none.icon-strain:before, i.ph-wifi-none.icon-gps:before, i.ph-wifi-none.icon-location:before, i.ph-wifi-none.icon-add:before, i.ph-wifi-none.icon-left:before, i.ph-wifi-none.icon-dna:before, i.ph-wifi-none.icon-delete:before, i.ph-wifi-none.icon-edit:before, i.ph-wifi-none.icon-info:before {
  content: "\e4f0";
}

.ph.ph-wifi-slash:before, i.ph-wifi-slash.icon-dead:before, i.ph-wifi-slash.icon-alive:before, i.ph-wifi-slash.icon-warn:before, i.ph-wifi-slash.icon-sign-out:before, i.ph-wifi-slash.icon-substratum:before, i.ph-wifi-slash.icon-environment:before, i.ph-wifi-slash.icon-user:before, i.ph-wifi-slash.icon-person:before, i.ph-wifi-slash.icon-terrain:before, i.ph-wifi-slash.icon-taxon:before, i.ph-wifi-slash.icon-unknown:before, i.ph-wifi-slash.icon-nothing:before, i.ph-wifi-slash.icon-sampling:before, i.ph-wifi-slash.icon-isolation:before, i.ph-wifi-slash.icon-strain:before, i.ph-wifi-slash.icon-gps:before, i.ph-wifi-slash.icon-location:before, i.ph-wifi-slash.icon-add:before, i.ph-wifi-slash.icon-left:before, i.ph-wifi-slash.icon-dna:before, i.ph-wifi-slash.icon-delete:before, i.ph-wifi-slash.icon-edit:before, i.ph-wifi-slash.icon-info:before {
  content: "\e4f2";
}

.ph.ph-wifi-x:before, i.ph-wifi-x.icon-dead:before, i.ph-wifi-x.icon-alive:before, i.ph-wifi-x.icon-warn:before, i.ph-wifi-x.icon-sign-out:before, i.ph-wifi-x.icon-substratum:before, i.ph-wifi-x.icon-environment:before, i.ph-wifi-x.icon-user:before, i.ph-wifi-x.icon-person:before, i.ph-wifi-x.icon-terrain:before, i.ph-wifi-x.icon-taxon:before, i.ph-wifi-x.icon-unknown:before, i.ph-wifi-x.icon-nothing:before, i.ph-wifi-x.icon-sampling:before, i.ph-wifi-x.icon-isolation:before, i.ph-wifi-x.icon-strain:before, i.ph-wifi-x.icon-gps:before, i.ph-wifi-x.icon-location:before, i.ph-wifi-x.icon-add:before, i.ph-wifi-x.icon-left:before, i.ph-wifi-x.icon-dna:before, i.ph-wifi-x.icon-delete:before, i.ph-wifi-x.icon-edit:before, i.ph-wifi-x.icon-info:before {
  content: "\e4f4";
}

.ph.ph-wind:before, i.ph-wind.icon-dead:before, i.ph-wind.icon-alive:before, i.ph-wind.icon-warn:before, i.ph-wind.icon-sign-out:before, i.ph-wind.icon-substratum:before, i.ph-wind.icon-environment:before, i.ph-wind.icon-user:before, i.ph-wind.icon-person:before, i.ph-wind.icon-terrain:before, i.ph-wind.icon-taxon:before, i.ph-wind.icon-unknown:before, i.ph-wind.icon-nothing:before, i.ph-wind.icon-sampling:before, i.ph-wind.icon-isolation:before, i.ph-wind.icon-strain:before, i.ph-wind.icon-gps:before, i.ph-wind.icon-location:before, i.ph-wind.icon-add:before, i.ph-wind.icon-left:before, i.ph-wind.icon-dna:before, i.ph-wind.icon-delete:before, i.ph-wind.icon-edit:before, i.ph-wind.icon-info:before {
  content: "\e5d2";
}

.ph.ph-windmill:before, i.ph-windmill.icon-dead:before, i.ph-windmill.icon-alive:before, i.ph-windmill.icon-warn:before, i.ph-windmill.icon-sign-out:before, i.ph-windmill.icon-substratum:before, i.ph-windmill.icon-environment:before, i.ph-windmill.icon-user:before, i.ph-windmill.icon-person:before, i.ph-windmill.icon-terrain:before, i.ph-windmill.icon-taxon:before, i.ph-windmill.icon-unknown:before, i.ph-windmill.icon-nothing:before, i.ph-windmill.icon-sampling:before, i.ph-windmill.icon-isolation:before, i.ph-windmill.icon-strain:before, i.ph-windmill.icon-gps:before, i.ph-windmill.icon-location:before, i.ph-windmill.icon-add:before, i.ph-windmill.icon-left:before, i.ph-windmill.icon-dna:before, i.ph-windmill.icon-delete:before, i.ph-windmill.icon-edit:before, i.ph-windmill.icon-info:before {
  content: "\e9f8";
}

.ph.ph-windows-logo:before, i.ph-windows-logo.icon-dead:before, i.ph-windows-logo.icon-alive:before, i.ph-windows-logo.icon-warn:before, i.ph-windows-logo.icon-sign-out:before, i.ph-windows-logo.icon-substratum:before, i.ph-windows-logo.icon-environment:before, i.ph-windows-logo.icon-user:before, i.ph-windows-logo.icon-person:before, i.ph-windows-logo.icon-terrain:before, i.ph-windows-logo.icon-taxon:before, i.ph-windows-logo.icon-unknown:before, i.ph-windows-logo.icon-nothing:before, i.ph-windows-logo.icon-sampling:before, i.ph-windows-logo.icon-isolation:before, i.ph-windows-logo.icon-strain:before, i.ph-windows-logo.icon-gps:before, i.ph-windows-logo.icon-location:before, i.ph-windows-logo.icon-add:before, i.ph-windows-logo.icon-left:before, i.ph-windows-logo.icon-dna:before, i.ph-windows-logo.icon-delete:before, i.ph-windows-logo.icon-edit:before, i.ph-windows-logo.icon-info:before {
  content: "\e692";
}

.ph.ph-wine:before, i.ph-wine.icon-dead:before, i.ph-wine.icon-alive:before, i.ph-wine.icon-warn:before, i.ph-wine.icon-sign-out:before, i.ph-wine.icon-substratum:before, i.ph-wine.icon-environment:before, i.ph-wine.icon-user:before, i.ph-wine.icon-person:before, i.ph-wine.icon-terrain:before, i.ph-wine.icon-taxon:before, i.ph-wine.icon-unknown:before, i.ph-wine.icon-nothing:before, i.ph-wine.icon-sampling:before, i.ph-wine.icon-isolation:before, i.ph-wine.icon-strain:before, i.ph-wine.icon-gps:before, i.ph-wine.icon-location:before, i.ph-wine.icon-add:before, i.ph-wine.icon-left:before, i.ph-wine.icon-dna:before, i.ph-wine.icon-delete:before, i.ph-wine.icon-edit:before, i.ph-wine.icon-info:before {
  content: "\e6b2";
}

.ph.ph-wrench:before, i.ph-wrench.icon-dead:before, i.ph-wrench.icon-alive:before, i.ph-wrench.icon-warn:before, i.ph-wrench.icon-sign-out:before, i.ph-wrench.icon-substratum:before, i.ph-wrench.icon-environment:before, i.ph-wrench.icon-user:before, i.ph-wrench.icon-person:before, i.ph-wrench.icon-terrain:before, i.ph-wrench.icon-taxon:before, i.ph-wrench.icon-unknown:before, i.ph-wrench.icon-nothing:before, i.ph-wrench.icon-sampling:before, i.ph-wrench.icon-isolation:before, i.ph-wrench.icon-strain:before, i.ph-wrench.icon-gps:before, i.ph-wrench.icon-location:before, i.ph-wrench.icon-add:before, i.ph-wrench.icon-left:before, i.ph-wrench.icon-dna:before, i.ph-wrench.icon-delete:before, i.ph-wrench.icon-edit:before, i.ph-wrench.icon-info:before {
  content: "\e5d4";
}

.ph.ph-x:before, i.ph-x.icon-dead:before, i.ph-x.icon-alive:before, i.ph-x.icon-warn:before, i.ph-x.icon-sign-out:before, i.ph-x.icon-substratum:before, i.ph-x.icon-environment:before, i.ph-x.icon-user:before, i.ph-x.icon-person:before, i.ph-x.icon-terrain:before, i.ph-x.icon-taxon:before, i.ph-x.icon-unknown:before, i.ph-x.icon-nothing:before, i.ph-x.icon-sampling:before, i.ph-x.icon-isolation:before, i.ph-x.icon-strain:before, i.ph-x.icon-gps:before, i.ph-x.icon-location:before, i.ph-x.icon-add:before, i.ph-x.icon-left:before, i.ph-x.icon-dna:before, i.ph-x.icon-delete:before, i.ph-x.icon-edit:before, i.ph-x.icon-info:before {
  content: "\e4f6";
}

.ph.ph-x-circle:before, i.ph-x-circle.icon-dead:before, i.ph-x-circle.icon-alive:before, i.ph-x-circle.icon-warn:before, i.ph-x-circle.icon-sign-out:before, i.ph-x-circle.icon-substratum:before, i.ph-x-circle.icon-environment:before, i.ph-x-circle.icon-user:before, i.ph-x-circle.icon-person:before, i.ph-x-circle.icon-terrain:before, i.ph-x-circle.icon-taxon:before, i.ph-x-circle.icon-unknown:before, i.ph-x-circle.icon-nothing:before, i.ph-x-circle.icon-sampling:before, i.ph-x-circle.icon-isolation:before, i.ph-x-circle.icon-strain:before, i.ph-x-circle.icon-gps:before, i.ph-x-circle.icon-location:before, i.ph-x-circle.icon-add:before, i.ph-x-circle.icon-left:before, i.ph-x-circle.icon-dna:before, i.ph-x-circle.icon-delete:before, i.ph-x-circle.icon-edit:before, i.ph-x-circle.icon-info:before {
  content: "\e4f8";
}

.ph.ph-x-logo:before, i.ph-x-logo.icon-dead:before, i.ph-x-logo.icon-alive:before, i.ph-x-logo.icon-warn:before, i.ph-x-logo.icon-sign-out:before, i.ph-x-logo.icon-substratum:before, i.ph-x-logo.icon-environment:before, i.ph-x-logo.icon-user:before, i.ph-x-logo.icon-person:before, i.ph-x-logo.icon-terrain:before, i.ph-x-logo.icon-taxon:before, i.ph-x-logo.icon-unknown:before, i.ph-x-logo.icon-nothing:before, i.ph-x-logo.icon-sampling:before, i.ph-x-logo.icon-isolation:before, i.ph-x-logo.icon-strain:before, i.ph-x-logo.icon-gps:before, i.ph-x-logo.icon-location:before, i.ph-x-logo.icon-add:before, i.ph-x-logo.icon-left:before, i.ph-x-logo.icon-dna:before, i.ph-x-logo.icon-delete:before, i.ph-x-logo.icon-edit:before, i.ph-x-logo.icon-info:before {
  content: "\e4bc";
}

.ph.ph-x-square:before, i.ph-x-square.icon-dead:before, i.ph-x-square.icon-alive:before, i.ph-x-square.icon-warn:before, i.ph-x-square.icon-sign-out:before, i.ph-x-square.icon-substratum:before, i.ph-x-square.icon-environment:before, i.ph-x-square.icon-user:before, i.ph-x-square.icon-person:before, i.ph-x-square.icon-terrain:before, i.ph-x-square.icon-taxon:before, i.ph-x-square.icon-unknown:before, i.ph-x-square.icon-nothing:before, i.ph-x-square.icon-sampling:before, i.ph-x-square.icon-isolation:before, i.ph-x-square.icon-strain:before, i.ph-x-square.icon-gps:before, i.ph-x-square.icon-location:before, i.ph-x-square.icon-add:before, i.ph-x-square.icon-left:before, i.ph-x-square.icon-dna:before, i.ph-x-square.icon-delete:before, i.ph-x-square.icon-edit:before, i.ph-x-square.icon-info:before {
  content: "\e4fa";
}

.ph.ph-yarn:before, i.ph-yarn.icon-dead:before, i.ph-yarn.icon-alive:before, i.ph-yarn.icon-warn:before, i.ph-yarn.icon-sign-out:before, i.ph-yarn.icon-substratum:before, i.ph-yarn.icon-environment:before, i.ph-yarn.icon-user:before, i.ph-yarn.icon-person:before, i.ph-yarn.icon-terrain:before, i.ph-yarn.icon-taxon:before, i.ph-yarn.icon-unknown:before, i.ph-yarn.icon-nothing:before, i.ph-yarn.icon-sampling:before, i.ph-yarn.icon-isolation:before, i.ph-yarn.icon-strain:before, i.ph-yarn.icon-gps:before, i.ph-yarn.icon-location:before, i.ph-yarn.icon-add:before, i.ph-yarn.icon-left:before, i.ph-yarn.icon-dna:before, i.ph-yarn.icon-delete:before, i.ph-yarn.icon-edit:before, i.ph-yarn.icon-info:before {
  content: "\ed9a";
}

.ph.ph-yin-yang:before, i.ph-yin-yang.icon-dead:before, i.ph-yin-yang.icon-alive:before, i.ph-yin-yang.icon-warn:before, i.ph-yin-yang.icon-sign-out:before, i.ph-yin-yang.icon-substratum:before, i.ph-yin-yang.icon-environment:before, i.ph-yin-yang.icon-user:before, i.ph-yin-yang.icon-person:before, i.ph-yin-yang.icon-terrain:before, i.ph-yin-yang.icon-taxon:before, i.ph-yin-yang.icon-unknown:before, i.ph-yin-yang.icon-nothing:before, i.ph-yin-yang.icon-sampling:before, i.ph-yin-yang.icon-isolation:before, i.ph-yin-yang.icon-strain:before, i.ph-yin-yang.icon-gps:before, i.ph-yin-yang.icon-location:before, i.ph-yin-yang.icon-add:before, i.ph-yin-yang.icon-left:before, i.ph-yin-yang.icon-dna:before, i.ph-yin-yang.icon-delete:before, i.ph-yin-yang.icon-edit:before, i.ph-yin-yang.icon-info:before {
  content: "\e92a";
}

.ph.ph-youtube-logo:before, i.ph-youtube-logo.icon-dead:before, i.ph-youtube-logo.icon-alive:before, i.ph-youtube-logo.icon-warn:before, i.ph-youtube-logo.icon-sign-out:before, i.ph-youtube-logo.icon-substratum:before, i.ph-youtube-logo.icon-environment:before, i.ph-youtube-logo.icon-user:before, i.ph-youtube-logo.icon-person:before, i.ph-youtube-logo.icon-terrain:before, i.ph-youtube-logo.icon-taxon:before, i.ph-youtube-logo.icon-unknown:before, i.ph-youtube-logo.icon-nothing:before, i.ph-youtube-logo.icon-sampling:before, i.ph-youtube-logo.icon-isolation:before, i.ph-youtube-logo.icon-strain:before, i.ph-youtube-logo.icon-gps:before, i.ph-youtube-logo.icon-location:before, i.ph-youtube-logo.icon-add:before, i.ph-youtube-logo.icon-left:before, i.ph-youtube-logo.icon-dna:before, i.ph-youtube-logo.icon-delete:before, i.ph-youtube-logo.icon-edit:before, i.ph-youtube-logo.icon-info:before {
  content: "\e4fc";
}

body,
html {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.full-height {
  min-height: 100vh;
}

#flash {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
}

.menubar {
  position: relative;
}

form.button_to {
  display: inline-block !important;
}

.btn-xs {
  padding: 0.125rem 0.5rem;
}

.map-container {
  height: 500px;
}
.map-container .map {
  height: 100%;
}

.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}

i.icon, i.icon-dead, i.icon-alive, i.icon-warn, i.icon-sign-out, i.icon-substratum, i.icon-environment, i.icon-user, i.icon-person, i.icon-terrain, i.icon-taxon, i.icon-unknown, i.icon-nothing, i.icon-sampling, i.icon-isolation, i.icon-strain, i.icon-gps, i.icon-location, i.icon-add, i.icon-left, i.icon-dna, i.icon-delete, i.icon-edit, i.icon-info {
  vertical-align: text-bottom;
}

.glightbox-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999 !important;
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: none;
}

.glightbox-container.inactive {
  display: none;
}

.glightbox-container .gcontainer {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
}

.glightbox-container .gslider {
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.glightbox-container .gslide {
  width: 100%;
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
}

.glightbox-container .gslide.current {
  opacity: 1;
  z-index: 99999;
  position: relative;
}

.glightbox-container .gslide.prev {
  opacity: 1;
  z-index: 9999;
}

.glightbox-container .gslide-inner-content {
  width: 100%;
}

.glightbox-container .ginner-container {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 100%;
  margin: auto;
  height: 100vh;
}

.glightbox-container .ginner-container.gvideo-container {
  width: 100%;
}

.glightbox-container .ginner-container.desc-bottom,
.glightbox-container .ginner-container.desc-top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.glightbox-container .ginner-container.desc-left,
.glightbox-container .ginner-container.desc-right {
  max-width: 100% !important;
}

.gslide iframe,
.gslide video {
  outline: none !important;
  border: none;
  min-height: 165px;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: auto;
  touch-action: auto;
}

.gslide:not(.current) {
  pointer-events: none;
}

.gslide-image {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.gslide-image img {
  max-height: 100vh;
  display: block;
  padding: 0;
  float: none;
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: 100vw;
  width: auto;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  -ms-touch-action: none;
  touch-action: none;
  margin: auto;
  min-width: 200px;
}

.desc-top .gslide-image img,
.desc-bottom .gslide-image img {
  width: auto;
}

.desc-left .gslide-image img,
.desc-right .gslide-image img {
  width: auto;
  max-width: 100%;
}

.gslide-image img.zoomable {
  position: relative;
}

.gslide-image img.dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
  -webkit-transition: none;
  transition: none;
}

.gslide-video {
  position: relative;
  max-width: 100vh;
  width: 100% !important;
}

.gslide-video .plyr__poster-enabled.plyr--loading .plyr__poster {
  display: none;
}

.gslide-video .gvideo-wrapper {
  width: 100%;
  /* max-width: 160vmin; */
  margin: auto;
}

.gslide-video::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.34);
  display: none;
}

.gslide-video.playing::before {
  display: none;
}

.gslide-video.fullscreen {
  max-width: 100% !important;
  min-width: 100%;
  height: 75vh;
}

.gslide-video.fullscreen video {
  max-width: 100% !important;
  width: 100% !important;
}

.gslide-inline {
  background: #fff;
  text-align: left;
  max-height: calc(100vh - 40px);
  overflow: auto;
  max-width: 100%;
  margin: auto;
}

.gslide-inline .ginlined-content {
  padding: 20px;
  width: 100%;
}

.gslide-inline .dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
  -webkit-transition: none;
  transition: none;
}

.ginlined-content {
  overflow: auto;
  display: block !important;
  opacity: 1;
}

.gslide-external {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-width: 100%;
  background: #fff;
  padding: 0;
  overflow: auto;
  max-height: 75vh;
  height: 100%;
}

.gslide-media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
}

.zoomed .gslide-media {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.desc-top .gslide-media,
.desc-bottom .gslide-media {
  margin: 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.gslide-description {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
}

.gslide-description.description-left,
.gslide-description.description-right {
  max-width: 100%;
}

.gslide-description.description-bottom,
.gslide-description.description-top {
  margin: 0 auto;
  width: 100%;
}

.gslide-description p {
  margin-bottom: 12px;
}

.gslide-description p:last-child {
  margin-bottom: 0;
}

.zoomed .gslide-description {
  display: none;
}

.glightbox-button-hidden {
  display: none;
}

/*
 * Description for mobiles
 * something like facebook does the description
 * for the photos
*/
.glightbox-mobile .glightbox-container .gslide-description {
  height: auto !important;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 19px 11px;
  max-width: 100vw !important;
  -webkit-box-ordinal-group: 3 !important;
  -ms-flex-order: 2 !important;
  order: 2 !important;
  max-height: 78vh;
  overflow: auto !important;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.75)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  padding-bottom: 50px;
}

.glightbox-mobile .glightbox-container .gslide-title {
  color: #fff;
  font-size: 1em;
}

.glightbox-mobile .glightbox-container .gslide-desc {
  color: #a1a1a1;
}

.glightbox-mobile .glightbox-container .gslide-desc a {
  color: #fff;
  font-weight: bold;
}

.glightbox-mobile .glightbox-container .gslide-desc * {
  color: inherit;
}

.glightbox-mobile .glightbox-container .gslide-desc .desc-more {
  color: #fff;
  opacity: 0.4;
}

.gdesc-open .gslide-media {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: 0.4;
}

.gdesc-open .gdesc-inner {
  padding-bottom: 30px;
}

.gdesc-closed .gslide-media {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: 1;
}

.greset {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.gabsolute {
  position: absolute;
}

.grelative {
  position: relative;
}

.glightbox-desc {
  display: none !important;
}

.glightbox-open {
  overflow: hidden;
}

.gloader {
  height: 25px;
  width: 25px;
  -webkit-animation: lightboxLoader 0.8s infinite linear;
  animation: lightboxLoader 0.8s infinite linear;
  border: 2px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  position: absolute;
  display: block;
  z-index: 9999;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 47%;
}

.goverlay {
  width: 100%;
  height: calc(100vh + 1px);
  position: fixed;
  top: -1px;
  left: 0;
  background: #000;
  will-change: opacity;
}

.glightbox-mobile .goverlay {
  background: #000;
}

.gprev,
.gnext,
.gclose {
  z-index: 99999;
  cursor: pointer;
  width: 26px;
  height: 44px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.gprev svg,
.gnext svg,
.gclose svg {
  display: block;
  width: 25px;
  height: auto;
  margin: 0;
  padding: 0;
}

.gprev.disabled,
.gnext.disabled,
.gclose.disabled {
  opacity: 0.1;
}

.gprev .garrow,
.gnext .garrow,
.gclose .garrow {
  stroke: #fff;
}

.gbtn.focused {
  outline: 2px solid #0f3d81;
}

iframe.wait-autoplay {
  opacity: 0;
}

.glightbox-closing .gnext,
.glightbox-closing .gprev,
.glightbox-closing .gclose {
  opacity: 0 !important;
}

/*Skin */
.glightbox-clean .gslide-description {
  background: #fff;
}

.glightbox-clean .gdesc-inner {
  padding: 22px 20px;
}

.glightbox-clean .gslide-title {
  font-size: 1em;
  font-weight: normal;
  font-family: arial;
  color: #000;
  margin-bottom: 19px;
  line-height: 1.4em;
}

.glightbox-clean .gslide-desc {
  font-size: 0.86em;
  margin-bottom: 0;
  font-family: arial;
  line-height: 1.4em;
}

.glightbox-clean .gslide-video {
  background: #000;
}

.glightbox-clean .gprev,
.glightbox-clean .gnext,
.glightbox-clean .gclose {
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
}

.glightbox-clean .gprev path,
.glightbox-clean .gnext path,
.glightbox-clean .gclose path {
  fill: #fff;
}

.glightbox-clean .gprev {
  position: absolute;
  top: -100%;
  left: 30px;
  width: 40px;
  height: 50px;
}

.glightbox-clean .gnext {
  position: absolute;
  top: -100%;
  right: 30px;
  width: 40px;
  height: 50px;
}

.glightbox-clean .gclose {
  width: 35px;
  height: 35px;
  top: 15px;
  right: 10px;
  position: absolute;
}

.glightbox-clean .gclose svg {
  width: 18px;
  height: auto;
}

.glightbox-clean .gclose:hover {
  opacity: 1;
}

/*CSS Animations*/
.gfadeIn {
  -webkit-animation: gfadeIn 0.5s ease;
  animation: gfadeIn 0.5s ease;
}

.gfadeOut {
  -webkit-animation: gfadeOut 0.5s ease;
  animation: gfadeOut 0.5s ease;
}

.gslideOutLeft {
  -webkit-animation: gslideOutLeft 0.3s ease;
  animation: gslideOutLeft 0.3s ease;
}

.gslideInLeft {
  -webkit-animation: gslideInLeft 0.3s ease;
  animation: gslideInLeft 0.3s ease;
}

.gslideOutRight {
  -webkit-animation: gslideOutRight 0.3s ease;
  animation: gslideOutRight 0.3s ease;
}

.gslideInRight {
  -webkit-animation: gslideInRight 0.3s ease;
  animation: gslideInRight 0.3s ease;
}

.gzoomIn {
  -webkit-animation: gzoomIn 0.5s ease;
  animation: gzoomIn 0.5s ease;
}

.gzoomOut {
  -webkit-animation: gzoomOut 0.5s ease;
  animation: gzoomOut 0.5s ease;
}

@-webkit-keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes gfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes gfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes gfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes gfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes gslideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
  }
  to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes gslideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
  }
  to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden;
  }
}
@-webkit-keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0;
  }
}
@keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0;
  }
}
@-webkit-keyframes gzoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}
@keyframes gzoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes gzoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@keyframes gzoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@media (min-width: 769px) {
  .glightbox-container .ginner-container {
    width: auto;
    height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .glightbox-container .ginner-container.desc-top .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .glightbox-container .ginner-container.desc-top .gslide-image,
  .glightbox-container .ginner-container.desc-top .gslide-image img {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .glightbox-container .ginner-container.desc-left .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .glightbox-container .ginner-container.desc-left .gslide-image {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .gslide-image img {
    max-height: 97vh;
    max-width: 100%;
  }
  .gslide-image img.zoomable {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }
  .zoomed .gslide-image img.zoomable {
    cursor: -webkit-grab;
    cursor: grab;
  }
  .gslide-inline {
    max-height: 95vh;
  }
  .gslide-external {
    max-height: 100vh;
  }
  .gslide-description.description-left,
  .gslide-description.description-right {
    max-width: 275px;
  }
  .glightbox-open {
    height: auto;
  }
  .goverlay {
    background: rgba(0, 0, 0, 0.92);
  }
  .glightbox-clean .gslide-media {
    -webkit-box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.65);
    box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.65);
  }
  .glightbox-clean .description-left .gdesc-inner,
  .glightbox-clean .description-right .gdesc-inner {
    position: absolute;
    height: 100%;
    overflow-y: auto;
  }
  .glightbox-clean .gprev,
  .glightbox-clean .gnext,
  .glightbox-clean .gclose {
    background-color: rgba(0, 0, 0, 0.32);
  }
  .glightbox-clean .gprev:hover,
  .glightbox-clean .gnext:hover,
  .glightbox-clean .gclose:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .glightbox-clean .gprev {
    top: 45%;
  }
  .glightbox-clean .gnext {
    top: 45%;
  }
}
@media (min-width: 992px) {
  .glightbox-clean .gclose {
    opacity: 0.7;
    right: 20px;
  }
}
@media screen and (max-height: 420px) {
  .goverlay {
    background: #000;
  }
}
.card.image .action-button {
  opacity: 0.2;
}
.card.image:hover .action-button {
  opacity: 1;
}

.autoclose-bar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  height: 3px;
}
